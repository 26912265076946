'use strict';
import { OgModal } from "../../utils/global/ogModal";
import { ScopeFunctions } from '../../utils/global/scopeFunctions';
import CommonFunctions from '../../utils/CommonFunctionUtil';
import _ from 'underscore';

angular.module('dialog').directive('ogEventScheduleModal', [
    'EventSchedule',
    '$rootScope',
    '$state',
    '$timeout',
    '$translate',
    'EventEnum',
    'FieldChangedSvc',
    function (EventSchedule, $rootScope, $state, $timeout, $translate, EventEnum, FieldChangedSvc) {
        return new OgModal({
            name: 'event:schedule',
            type: 'custom',
            controller: function ($scope) {
                $scope.eventScheduleForm = {};
                ScopeFunctions.addToScope($scope);
                $scope.eventDetails = {};
                $scope.usersInEvent = [];
                $scope.initDialog = function (data) {
                    $scope.editDenied = false;
                    if ($scope.eventScheduleForm) {
                        $scope.eventScheduleForm.schedule.$setUntouched();
                    }
                    $scope.eventScheduleModel = {
                        confirmationRequired: false,
                        actionRequired: data.event.actionRequired,
                        supplierConfirmationPending: data.event.supplierConfirmationPending,
                        editType: data.event.editType,
                        isTimePairValid: true,
                    };

                    $scope.eventModal = {
                        supplyAmountTypes: [
                            { type: 'PIECES', name: $translate.instant('amount.type.PIECES') },
                            { type: 'PEOPLE', name: $translate.instant('amount.type.PEOPLE') },
                            { type: 'SAME_AS_EVENT', name: $translate.instant('amount.type.SAME_AS_EVENT') },
                        ],
                        offerPartCategoryTypes: [
                            { type: 'ACCOMMODATION', name: $translate.instant('part.category.type.ACCOMMODATION') },
                            { type: 'OTHERS', name: $translate.instant('part.category.type.OTHERS') },
                        ],
                        isNew: data.createNew,
                    };
                    angular.copy(data.eventDetails, $scope.eventDetails);
                    if ($scope.eventDetails && $scope.eventDetails.usersInEvent) {
                        $scope.usersInEvent = $scope.eventDetails.usersInEvent;
                    }
                    if (!$scope.eventModal.isNew) {
                        $scope.startProgress();
                        EventSchedule.getOfferPart(data.event.referenceId, data.event.offerId, function (response) {
                            var eventScheduleForPart = response.eventScheduleForPart;
                            $scope.eventData = angular.extend({}, angular.fromJson(eventScheduleForPart.data));
                            $scope.referenceId = eventScheduleForPart.referenceId;
                            $scope.dataLoaded($scope.eventData);
                            if ($scope.eventData.defaultCommentByCreator || !$scope.eventData.commentByCreator) {
                                $scope.commentEditMode = true;
                            } else {
                                $scope.commentEditMode = false;
                            }
                            $scope.endProgress();
                        });
                    } else if ($scope.eventModal.isNew) {
                        var addedNewRequest = _.where($scope.eventDetails.requests, { id: 0 }); // jshint ignore:line
                        if (!addedNewRequest.length) {
                            $scope.eventDetails.requests.push({
                                id: 0,
                                name: $translate.instant('calendar.modal.create.new.content'),
                            });
                        }
                        var eventFromDate = data.eventDetails.fromDate;
                        var metaData = {
                            dateTimeTo: data.event.end ? new Date(data.event.end).getTime() - eventFromDate : 0,
                            dateTimeFrom: data.event.start
                                ? new Date(data.event.start).getTime() - eventFromDate
                                : 0,
                            eventFromDate: eventFromDate, //base Date
                            requestId: data.event.requestId,
                        };
                        $scope.commentEditMode = true;
                        $scope.eventData = metaData;
                        $scope.eventData.timeless = data.timeless;
                        $scope.eventData.amountType = { name: 'PIECES' }; // set PIECES as default while creating new
                        $scope.dataLoaded($scope.eventData);
                    }

                    $scope.okCallBack = data.okCallBack;
                    $scope.deleteCallBack = data.deleteCallBack;
                    $scope.syncDates = true;
                    $scope.hoursToAdd = 1; // Add default hours from 'from-date' to 'to-date' in dateTime pair.
                    $scope.checkIfEditable();
                };

                $scope.clearDialog = function () {
                    $scope.okCallBack = null;
                    $scope.cancelCallBack = null;
                    $scope.deleteCallBack = null;
                    $scope.okLabel = null;
                    $scope.cancelLabel = null;
                    $scope.message = null;
                    $scope.messageArgs = null;
                };

                $scope.ok = function (form) {
                    if (!form.$valid) {
                        CommonFunctions.touchFormFields(form);
                        return;
                    }
                    var okCallBack = $scope.okCallBack;
                    if (okCallBack) {
                        okCallBack($scope.data);
                    }
                    $scope.close();
                };

                $scope.cancel = function () {
                    var cancelCallBack = $scope.cancelCallBack;
                    $scope.close();
                    if (cancelCallBack) {
                        cancelCallBack();
                    }
                };
                $scope.openOfferPart = function (id) {
                    var data = {
                        createNew: false,
                        event: {
                            referenceId: id,
                        },
                    };
                    $scope.initDialog(data);
                };

                $scope.checkIfEditable = function () {
                    $scope.eventScheduleModel.confirmationRequired = false;
                    if (!$scope.data) {
                        return;
                    }
                    var selectedRequest = _.find($scope.eventDetails.requests, { id: $scope.data.requestId });
                    var editType;
                    var editTypeName;

                    if (selectedRequest) {
                        editType = selectedRequest.editType;
                    }
                    if (editType) {
                        editTypeName = selectedRequest.editType.name;
                    }
                    $scope.eventScheduleModel.editType = editType;
                    if ($scope.eventScheduleModel.editType) {
                        $scope.editDenied =
                            ['DENIED', 'DENIED_ACTION_REQUIRED', 'DENIED_SUPPLIER_LOCKED'].indexOf(
                                $scope.eventScheduleModel.editType.name
                            ) > -1;
                    }
                    if (editTypeName === 'NOTIFY_BEFORE_EDIT') {
                        $scope.eventScheduleModel.confirmationRequired = true;
                    } else if (editTypeName === 'EDITABLE') {
                        return;
                    }
                    /*else if (editTypeName === 'DENIED') {
           $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, {
           message: 'request.offer.part.adding.new.row.alert.denied',
           okCallBack: function(){
           $scope.data.requestId = '';
           },
           cancelCallBack: function(){
           $scope.data.requestId = '';
           }
           });
           }*/
                };

                $scope.delete = function () {
                    var deleteCallBack = $scope.deleteCallBack;
                    OgModal.open('confirmation', {
                        message: 'request.delete.part.confirm',
                        okCallBack: function () {
                            $scope.close();
                            if (deleteCallBack) {
                                deleteCallBack();
                            }
                        },
                    });
                };
                $scope.cancelChange = function () {
                    $scope.dataLoaded($scope.originalData);
                };
                $scope.toggleCommentEditMode = function () {
                    $scope.commentEditMode = !$scope.commentEditMode;
                };

                $scope.dataLoaded = function (data) {
                    $scope.data = angular.copy(data);
                    $scope.originalData = angular.copy(data);

                    FieldChangedSvc.reset();
                    FieldChangedSvc.init(angular.copy(data));
                    $scope.viewUpdateButton = false;
                };

                $scope.handleOnchanged = function (modalName, currentValue) {
                    FieldChangedSvc.checkOnChanged(modalName, currentValue);
                    $scope.viewUpdateButton = FieldChangedSvc.isFieldChanged();
                };

                $scope.amountTypeChanged = function () {
                    if ($scope.data.amountType.name == 'SAME_AS_EVENT') {
                        $scope.data.amount = $scope.eventDetails.participantAmount;
                    }
                };

                $scope.viewChanges = function (requestId, offerId) {
                    $scope.close();
                    viewRequestFn(requestId, offerId);
                };

                $scope.showCommentNotification = function () {
                    return (
                        $scope.data &&
                        $scope.data.defaultCommentByCreator &&
                        !FieldChangedSvc.isParticularFieldChanged('commentByCreator')
                    );
                };

                $scope.saveDisabled = function () {
                    var isTimePairValid = $scope.eventScheduleModel && $scope.eventScheduleModel.isTimePairValid;
                    if ($scope.data && $scope.data.timeless) {
                        isTimePairValid = true;
                    }
                    return $scope.editDenied || !$scope.viewUpdateButton || !isTimePairValid;
                };

                function viewRequestFn(requestId, offerId) {
                    //added 1 sec to let this modal close and open another modal. This fixes the scrollable issue
                    $timeout(() => {
                        $rootScope.$broadcast(EventEnum.OPEN_SUPPLIER_PREVIEW_MODAL, {
                            offerId: offerId,
                            requestId: requestId,
                        });
                    }, 1000);
                }
            },
        });
    },
]);

