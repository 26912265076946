/*
(function() {
  'use strict';

  angular.module('centEventApplication').directive('ogParticipantImportModal',
    ['$rootScope', 'Participant', 'ProcessLog', function($rootScope, Participant, ProcessLog) {
      return new OgModal({
        name: 'participant:import',
        type: 'custom',
        controller: function($scope) {

          $scope.form = 'ParticipantImportForm';
          $scope.uploadInProgress = false;
          $scope.errorMessages = null;

          $scope.initDialog = function(data) {
            $scope.evetId = data.eventId;
            getCurrentProcessStatus(data.eventId);
            $scope.successCallback = data.successCallback;
            $scope.dataLoaded({});
          };

          $scope.clearDialog = function() {
            $scope.evetId = null;
            $scope.successCallback = null;
            $scope.errorMessages = null;
          };


          setInterval(function(){
            getCurrentProcessStatus($scope.eventId);
          }, 12000);

          function getCurrentProcessStatus(eventID) {
            ProcessLog.get({ referenceId: eventID, name: 'PARTICIPANT_EXCEL_IMPORT' }, (resp) => {
              $scope.uploadInProgress = resp.status.name === 'RUNNING';
            }, (err) => {
              console.log(err);
            });
          }

          $scope.importFromFile = function() {
            $scope.uploadInProgress = true;
            var formData = new FormData();
            formData.append('file', $scope.data.file);
            Participant.importFromFile($scope.evetId, formData, function() {
              getCurrentProcessStatus($scope.eventId);
              if ($scope.successCallback) {
                $scope.successCallback();
              }
              $scope.close();
            }, function(err) {
              $scope.errorMessages = CommonFunctions.errorMessages(err, true);
              $scope.uploadInProgress = false;
            });
            $scope.data.file = null;
            $("#file").val("");
          };
        }
      });
    }]);


*/
