import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

(function () {
    /* global angular */
    'use strict';
    angular.module('ogCustomerUserActivityTracker').component('ogCustomerUserActivityTracker', {
        bindings: {
            page: '<',
        },
        controller: [
            '$scope',
            '$rootScope',
            'CustomerUser',
            function ($scope, $rootScope, CustomerUser) {
                ScopeFunctions.addToScope($scope);

                this.$onInit = function () {
                    $scope.page = this.page;
                    initFn();
                }

                function initFn() {
                    CustomerUser.createCustomerUserActivityTracker(
                        { page: $scope.page },
                        (resp) => {},
                        $scope.endWithError()
                    );
                }
            },
        ],
    });
})();
