'use strict';

angular.module('core.existingServices').factory('EventSite', [
    '$resource',
    function ($resource) {
        var eventSite = $resource(
            'api/v1/eventSite/details/:eventId/:type',
            {eventId: '@eventId', type: '@type'},
            {
                getDetail: {method: 'GET', isArray: false},
                updatePublicUrl: {method: 'POST', isArray: false},
            }
        );
        var eventSitePublic = $resource(
            'api/v1/eventSite/:eventId/sharePublicly',
            {eventId: '@eventId'},
            {
                publicUrl: {method: 'POST', isArray: false},
            }
        );
        var getTypeFromUrlSlug = $resource(
            'api/v1/eventSite/public/:urlSlug',
            {urlSlug: '@urlSlug'},
            {
                getDetail: {method: 'GET', isArray: false},
            }
        );
        var deleteEventSiteFile = $resource(
            'api/v1/file/delete/:fileId',
            {fileId: '@fileId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );

        return {
            getEventSiteDetails: function (jsonObject, successCallback, errorCallback) {
                return eventSite.getDetail(jsonObject, successCallback, errorCallback);
            },
            updatePublicUrl: function (jsonObject, successCallback, errorCallback) {
                return eventSitePublic.publicUrl(jsonObject, successCallback, errorCallback);
            },
            getTypeFromUrlName: function (jsonObject, successCallback, errorCallback) {
                return getTypeFromUrlSlug.getDetail(jsonObject, successCallback, errorCallback);
            },
            delete: function (fileId, successCallback, errorCallback) {
                deleteEventSiteFile['delete']({fileId: fileId}, successCallback, errorCallback);
            },
        };
    },
]);

