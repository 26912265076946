import controller from './og.additional.questions.controller';

(function () {
    'use strict';
    angular.module('dnQuestions').directive('dnText', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    dnModel: '=',
                    className: '=',
                    dnDisabled: '=',
                    required: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.text.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnTextCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnRadio', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.radio.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnRadioCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnTextArea', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.textarea.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnTextAreaCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnDate', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    label: '=',
                    required: '=',
                    dnModel: '=',
                    className: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.date.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnDateCtrl',
                bindToController: true,
            };
        },
    ]);
    angular.module('dnQuestions').directive('dnEmail', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.email.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnEmailCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnCheckBox', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.checkbox.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnCheckBoxCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnDropDown', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    dnModel: '=',
                    className: '=',
                    dnDisabled: '=',
                    required: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.dropdown.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnDropDownCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnNumber', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    dnModel: '=',
                    className: '=',
                    dnDisabled: '=',
                    required: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.number.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnNumberCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnLabel', [
        function () {
            return {
                scope: {
                    question: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.label.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnLabelCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnButton', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    dnModel: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.button.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnButtonCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnIconRadio', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.icon.radio.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnIconRadioCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnIconMultiRadio', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.icon.multi.radio.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnIconMultiRadioCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnMultiCheckBox', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    onClick: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.multi.checkbox.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnMultiCheckBoxCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnIconMultiCheckBox', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    onClick: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.icon.multi.checkbox.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnIconMultiCheckBoxCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnTextWithAmountType', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    onClick: '=',
                    isCreatorLabel: '=',
                    participantAmount: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.text.with.amount.type.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnTextWithAmountTypeCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnTextWithGoogleAutosuggest', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    label: '=',
                    onChange: '=',
                    onClick: '=',
                    isCreatorLabel: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.text.with.google.autosuggest.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnTextWithGoogleAutosuggestCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnPrice', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    onClick: '=',
                    isCreatorLabel: '=',
                    participantAmount: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/dn.price.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnPriceCtrl',
                bindToController: true,
            };
        },
    ]);
})();
