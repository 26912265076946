'use strict';
import { ScopeFunctions } from '../../../../../../utils/global/scopeFunctions';
import queryDisplaySettingsModal from '../../../../../modals/modals2.0/query-display-settings';
import confirmationModal from '../../../../../modals/modals2.0/conformation-modal';
import _ from 'underscore';
import {OgModal} from "../../../../../../utils/global/ogModal";

// Register `participants.registration.form` component, along with its associated controller and template
angular.
    module('participants.registration.form').
    component('participantsregistrationform', {
        templateUrl: './app/components/modules/event/event_detail/participants/registration_form/registration_form.view.html',
        controllerAs: 'ParticipantsRegFormCtrl',
        controller: [
            '$scope',
            'EventParticipantCustomQuery',
            '$stateParams',
            'ParticipantBasicQuery',
            'ogScrollService',
            '$timeout',
            '$rootScope',
            '$translate',
            'ProcessLog',
            'ModalService',
            'Event',
            'Participant',
            'ParticipantService',
            'CommonFunctions',
            function ParticipantsRegFormController(
                $scope,
                EventParticipantCustomQuery,
                $stateParams,
                ParticipantBasicQuery,
                ogScrollService,
                $timeout,
                $rootScope,
                $translate,
                ProcessLog,
                ModalService,
                Event,
                Participant,
                ParticipantService,
                CommonFunctions
            ) {
                var vm = this;
                vm.viewModal = false;
                vm.eventId = $stateParams.eventId;
                vm.parseInt = parseInt;
                vm.english = false;
                vm.swedish = false;
                vm.norwegian = false;
                vm.danish = false;
                vm.finnish = false;

                vm.buttonStates = {
                    create: 'participant.registration.button.create',
                    update: 'participant.registration.button.update',
                    cancel: 'participant.registration.button.cancel',
                    processing: 'participant.registration.button.processing',
                };
                vm.multiSelectBasicSettings = {
                    displayProp: 'detailNameEnglish',
                    showCheckAll: false,
                    showUncheckAll: false,
                };
                vm.createQnBtnState = vm.buttonStates.create;
                vm.updateQnBtnState = vm.buttonStates.update;
                vm.cancelUpdateQnBtnState = vm.buttonStates.cancel;
                ScopeFunctions.addToScope($scope);

                vm.addOptionLength = addOptionLengthFn;
                vm.cancelUpdateQuestions = cancelUpdateQuestionsFn;
                vm.createCustomQuery = createCustomQueryFn;
                vm.dropBasicInfoCallback = dropBasicInfoCallbackFn;
                vm.dropCustomInfoCallback = dropCustomInfoCallbackFn;
                vm.editCustomQuestions = editCustomQuestionsFn;
                vm.getOptionValues = getOptionValuesFn;
                vm.getQuestionLabel = getQuestionLabelFn;
                vm.listCustomQueries = listCustomQueriesFn;
                vm.listSelectedQueries = listSelectedQueriesFn;
                vm.openQueryDisplaySettingsModal = openQueryDisplaySettingsModalFn;
                //vm.registrationFormPreview = registrationFormPreviewFn;
                vm.refreshUploadingStatus = refreshUploadingStatusFn;
                vm.removeCustomQuestionsOption = removeCustomQuestionsOptionFn;
                vm.removeSelectedInfo = removeSelectedInfoFn;
                vm.isAlwaysMandatory = isAlwaysMandatoryFn;
                vm.updateBasicQuery = updateBasicQueryFn;
                vm.updateBasicQueryInBatch = updateBasicQueryInBatchFn;
                vm.updateCustomRequiredQuery = updateCustomRequiredQueryFn;
                vm.updateRequiredCustomQuery = updateRequiredCustomQueryFn;
                vm.updateCustomQuestion = updateCustomQuestionFn;
                vm.updateCustomQuery = updateCustomQueryFn;
                vm.updateBasicInfoRequiredQuery = updateBasicInfoRequiredQueryFn;
                vm.getInformation = getInformationFn;
                vm.isRequired = isRequiredFn;
                vm.init = initFn;

                function initFn() {
                    Event.getEventUsedSMSService(vm.eventId, function (data) {
                        vm.useSMS = data.useSMS;
                    });
                    Participant.getParticipantLanguage(vm.eventId, function (data) {
                        vm.participantDefaultLocale = data.participantDefaultLocale;
                        vm.participantSupportLocale = data.participantSupportLocale.split('~');
                        if (vm.participantDefaultLocale === 'en_US' || vm.participantSupportLocale[0] === 'en_US') {
                            vm.english = true;
                        }
                        if (vm.participantDefaultLocale === 'sv_SV' || vm.participantSupportLocale[1] === 'sv_SV') {
                            vm.swedish = true;
                        }
                        if (vm.participantDefaultLocale === 'nb_NB' || vm.participantSupportLocale[2] === 'nb_NB') {
                            vm.norwegian = true;
                        }
                        if (vm.participantDefaultLocale === 'da_DK' || vm.participantSupportLocale[3] === 'da_DK') {
                            vm.danish = true;
                        }
                        if (vm.participantDefaultLocale === 'fi_FI' || vm.participantSupportLocale[4] === 'fi_FI') {
                            vm.finnish = true;
                        }
                    });
                }

                function getOptionValuesFn(options, lang) {
                    if (options && options.indexOf('~') >= 0) {
                        if (lang === 'en_US') {
                            return options.split('~')[0];
                        }
                        if (lang === 'sv_SV') {
                            return options.split('~')[1];
                        }
                        if (lang === 'nb_NB') {
                            return options.split('~')[2];
                        }
                        if (lang === 'da_DK') {
                            return options.split('~')[3];
                        }
                        if (lang === 'fi_FI') {
                            return options.split('~')[4];
                        }
                    } else {
                        return options;
                    }
                }

                function isAlwaysMandatoryFn(query) {
                    if (query.itIsMandatory) {
                        query.eventParticipantBasicQuery.isMandatory = true;
                        return true;
                    }
                }

                function addOptionLengthFn() {
                    vm.customQuestions.optionLength.push(vm.customQuestions.optionLength.length + 1);
                    vm.formErrorMessage = '';
                }

                function cancelUpdateQuestionsFn() {
                    initCustomQuestions();
                }

                function isRequiredFn(englishText, swedishText, norwegianText, danishText, finnishText) {
                    return (
                        (englishText && !swedishText) ||
                        (englishText && !norwegianText) ||
                        (englishText && !danishText) ||
                        (englishText && !finnishText) ||
                        (swedishText && !englishText) ||
                        (swedishText && !norwegianText) ||
                        (swedishText && !danishText) ||
                        (swedishText && !finnishText) ||
                        (norwegianText && !englishText) ||
                        (norwegianText && !swedishText) ||
                        (norwegianText && !danishText) ||
                        (norwegianText && !finnishText)
                    );
                }

                function displayRequiredMessage() {
                    let opts = getOptionsValues()['options'].split('~');
                    let showMessage = opts[0].split('#').length !== opts[1].split('#').length;
                    if (showMessage) {
                        vm.formErrorMessage = 'participant.registration.form.required.form.message';
                    }
                }

                function createCustomQueryFn() {
                    if (!vm.registrationForm.$valid) {
                        CommonFunctions.touchFormFields(vm.registrationForm);
                        // displayRequiredMessage();
                        return;
                    }

                    var query = {
                        detailNameEnglish: vm.customQuestions.questionEnglish,
                        detailNameSwedish: vm.customQuestions.questionSweden,
                        detailNameNorwegian: vm.customQuestions.questionNorwegian,
                        detailNameDanish: vm.customQuestions.questionDanish,
                        detailNameFinnish: vm.customQuestions.questionFinnish,
                        type: vm.customQuestions.type,
                        displayAsOption: true,
                        optionValues: getOptionsValues()['options'],
                        isChecked: true,
                        isMandatory: false,
                        eventAdditionalQueryId: null,
                        displayOrder: vm.queryList.length + 1,
                    };
                    if (
                        (query.detailNameEnglish ||
                            query.detailNameSwedish ||
                            query.detailNameNorwegian ||
                            query.detailNameDanish ||
                            query.detailNameFinnish) &&
                        query.type.name
                    ) {
                        if (query.type.name === 'DROPDOWN' || query.type.name === 'MULTISELECT') {
                            let _options = query.optionValues.split('~');
                            let _englishOptions = _options ? _options[0] : null;
                            let _swedishOptions = _options.length > 1 && _options[1] ? _options[1] : _englishOptions;
                            let _norwegianOptions = _options.length > 2 && _options[2] ? _options[2] : _swedishOptions;
                            let _danishOptions = _options.length > 3 && _options[3] ? _options[3] : _swedishOptions;
                            let _finnishOptions = _options.length > 4 && _options[4] ? _options[4] : _swedishOptions;
                            let _error =
                                query.type.name === 'DROPDOWN'
                                    ? 'participant.registration.form.invalid.form.message'
                                    : 'participant.registration.form.invalid.multiselect.form.message';
                            if (
                                !_englishOptions &&
                                !_swedishOptions &&
                                !_norwegianOptions &&
                                !_danishOptions &&
                                !_finnishOptions
                            ) {
                                vm.formErrorMessage = _error;
                                return;
                            }
                            if (_englishOptions) {
                                if (_englishOptions.split('#').length < 2) {
                                    vm.formErrorMessage = _error;
                                    return;
                                }
                            }
                            if (_swedishOptions) {
                                if (_swedishOptions.split('#').length < 2) {
                                    vm.formErrorMessage = _error;
                                    return;
                                }
                            }
                            if (_norwegianOptions) {
                                if (_norwegianOptions.split('#').length < 2) {
                                    vm.formErrorMessage = _error;
                                    return;
                                }
                            }
                            if (_danishOptions) {
                                if (_danishOptions.split('#').length < 2) {
                                    vm.formErrorMessage = _error;
                                    return;
                                }
                            }
                            if (_finnishOptions) {
                                if (_finnishOptions.split('#').length < 2) {
                                    vm.formErrorMessage = _error;
                                    return;
                                }
                            }
                        }
                        vm.createQnBtnState = vm.buttonStates.processing;
                        EventParticipantCustomQuery.save(
                            { eventId: vm.eventId },
                            query,
                            function (response) {
                                vm.queryList.push(response);
                                vm.listSelectedQueries();
                                vm.formErrorMessage = '';
                                $timeout(function () {
                                    initCustomQuestions();
                                    vm.registrationForm.$setPristine();
                                    vm.registrationForm.$setUntouched();
                                    vm.createQnBtnState = vm.buttonStates.create;
                                    ogScrollService.scrollToElement('custom-question-fields');
                                }, 0);
                            },
                            function (error) {
                                $scope.endWithAlert(error);
                                initCustomQuestions();
                                vm.createQnBtnState = vm.buttonStates.create;
                            }
                        );
                    } else {
                        return;
                    }
                }

                function splitOptionsValues(options) {
                    if (options) {
                        let opts = options.split('~');
                        vm.customQuestions.options['english'] = opts[0] ? opts[0].split('#') : [];
                        vm.customQuestions.options['swedish'] = opts[1] ? opts[1].split('#') : [];
                        vm.customQuestions.options['norwegian'] = opts[2] ? opts[2].split('#') : [];
                        vm.customQuestions.options['danish'] = opts[3] ? opts[3].split('#') : [];
                        vm.customQuestions.options['finnish'] = opts[4] ? opts[4].split('#') : [];
                        if (opts[0]) {
                            vm.customQuestions.optionLength = opts[0].split('#');
                        } else if (opts[1]) {
                            vm.customQuestions.optionLength = opts[1].split('#');
                        } else if (opts[2]) {
                            vm.customQuestions.optionLength = opts[2].split('#');
                        } else if (opts[3]) {
                            vm.customQuestions.optionLength = opts[3].split('#');
                        } else if (opts[4]) {
                            vm.customQuestions.optionLength = opts[4].split('#');
                        } else {
                            vm.customQuestions.optionLength = [];
                        }
                    }
                }

                function dropBasicInfoCallbackFn() {
                    var responseData = [];
                    if (vm.basicInfo && vm.basicInfo.lists) {
                        if (vm.basicInfo.lists.left) {
                            let order = -1;
                            vm.basicInfo.lists.left.forEach(function (data) {
                                order = order + 2;
                                data.eventParticipantBasicQuery.displayOrder = order;
                                responseData.push(data);
                            });
                        }
                        vm.updateBasicQueryInBatch(responseData);
                    }
                }

                function dropCustomInfoCallbackFn() {
                    var responseData = [];
                    if (vm.additionalInfo && vm.additionalInfo.lists) {
                        if (vm.additionalInfo.lists.left) {
                            let order = -1;
                            vm.additionalInfo.lists.left.forEach(function (data) {
                                order = order + 2;
                                data.displayOrder = order;
                                responseData.push(data);
                            });
                        }
                        updateCustomQueryInBatchFn(responseData);
                    }
                }

                function editCustomQuestionsFn(data) {
                    if (data) {
                        vm.customQuestions.questionEnglish = data.detailNameEnglish;
                        vm.customQuestions.questionSweden = data.detailNameSwedish;
                        vm.customQuestions.questionNorwegian = data.detailNameNorwegian;
                        vm.customQuestions.questionDanish = data.detailNameDanish;
                        vm.customQuestions.questionFinnish = data.detailNameFinnish;
                        vm.customQuestions.type = data.type;
                        splitOptionsValues(data.optionValues);
                        vm.customQuestions.id = data.id;
                        vm.originalCustomQuestions = angular.copy(vm.customQuestions);
                    }
                    $timeout(function () {
                        ogScrollService.scrollToElement('custom-question');
                    }, 0);
                }

                function getQuestionLabelFn(item) {
                    return ParticipantService.getQuestionLabel(item, $rootScope.langSelected);
                }

                function isParticipantsImportingFn(eventId) {
                    ProcessLog.get(
                        { referenceId: eventId, name: 'PARTICIPANT_EXCEL_IMPORT' },
                        (resp) => {
                            vm.participantUploding = resp.status && resp.status.name === 'RUNNING';
                            if (resp.status && resp.status.name === 'GOT_ERROR') {
                                let _error = angular.fromJson(resp.errorLog);
                                vm.participantsUploadErrors = CommonFunctions.errorMessages(_error, true);
                            }
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }

                function listCustomQueriesFn() {
                    EventParticipantCustomQuery.list(
                        { eventId: vm.eventId },
                        function (response) {
                            vm.queryList = response;
                        },
                        {}
                    );
                }

                function listSelectedQueriesFn() {
                    if (!$scope.isInProgress('queryInfo')) {
                        $scope.startProgress('queryInfo');
                    }
                    EventParticipantCustomQuery.customSelectedQueries(
                        { eventId: vm.eventId },
                        function (response) {
                            $scope.endProgress('queryInfo');
                            vm.customSelectedQueryList = response;
                            vm.additionalInfo = {
                                selected: null,
                                lists: {
                                    left: response,
                                },
                            };
                        },
                        {}
                    );
                }

                function openQueryDisplaySettingsModalFn(query, type) {
                    if(vm.viewModal) {
                        return;
                    }
                    vm.viewModal = true;
                    let data = {
                        query: query,
                        queryList: vm.selectedBasicQueryList.concat(vm.customSelectedQueryList),
                        type: type,
                    };

                    let queryDisplaySettingsModalData = queryDisplaySettingsModal;
                    queryDisplaySettingsModalData.inputs = { data };

                    ModalService.showModal(queryDisplaySettingsModalData).then((modal) => {
                        vm.viewModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }

                function refreshUploadingStatusFn() {
                    isParticipantsImportingFn(vm.eventId);
                }

                function removeCustomQuestionsOptionFn(index) {
                    if (vm.customQuestions.options['english'].length) {
                        vm.customQuestions.options['english'].splice(index, 1);
                    }
                    if (vm.customQuestions.options['swedish'].length) {
                        vm.customQuestions.options['swedish'].splice(index, 1);
                    }
                    if (vm.customQuestions.options['norwegian'].length) {
                        vm.customQuestions.options['norwegian'].splice(index, 1);
                    }
                    if (vm.customQuestions.options['danish'].length) {
                        vm.customQuestions.options['danish'].splice(index, 1);
                    }
                    if (vm.customQuestions.options['finnish'].length) {
                        vm.customQuestions.options['finnish'].splice(index, 1);
                    }
                    vm.customQuestions.optionLength.splice(0, 1);
                    vm.formErrorMessage = '';
                }

                function removeSelectedInfoFn(data, id, isBasicInfo) {
                    vm.loading = true;
                    if (isBasicInfo) {
                        if (data.detailNameEnglish === 'Email') {
                            if(vm.viewModal) {
                                return;
                            }
                            vm.viewModal = true;
                            let message =
                                $translate.instant('participant.registration.email.delete.preview.message') +
                                $translate.instant('common.translation.dynamic.sms.cost.text') +
                                ').';
                            let data = {
                                message: message,
                                okCallBack: function () {
                                    removeBasicSelectedList({ id: id });
                                },
                                cancelCallBack: function () {
                                    vm.loading = false;
                                },
                                closeCallBack: function () {
                                    vm.loading = false;
                                },
                            };
                            let confirmationModalData = confirmationModal;
                            confirmationModalData.inputs = { data };

                            ModalService.showModal(confirmationModalData).then((modal) => {
                                vm.viewModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        } else {
                            removeBasicSelectedList({ id: id });
                        }
                    } else {
                        removeCustomSelectedList({ id: id });
                    }
                }

                function updateBasicQueryInBatchFn(optionsForEvent) {
                    ParticipantBasicQuery.updateInBatch(
                        vm.eventId,
                        { optionsForEvent: optionsForEvent },
                        function (response) {
                            vm.selectedBasicQueryList = response;
                            $scope.endProgress();
                        },
                        vm.endWithAlert
                    );
                }

                function updateCustomRequiredQueryFn(data) {
                    var index = _.findIndex(vm.customSelectedQueryList, { id: data.id });
                    vm.updateRequiredCustomQuery(data, function (error, success) {
                        if (error || !success) {
                            vm.customSelectedQueryList[index].isMandatory = !vm.customSelectedQueryList[index].isMandatory;
                        }
                    });
                }

                function updateBasicInfoRequiredQueryFn(data) {
                    var index = _.findIndex(vm.participantBasicQueryList, { id: data.id });
                    vm.updateBasicQuery(data, function (error, success) {
                        if (error || !success) {
                            vm.participantBasicQueryList[index].eventParticipantBasicQuery.isMandatory =
                                !vm.participantBasicQueryList[index].isMandatory;
                        }
                    });
                }

                function updateCustomQuestionFn(id) {
                    var index = _.findIndex(vm.customSelectedQueryList, { id: id });
                    if (index != -1) {
                        if (!vm.registrationForm.$valid) {
                            CommonFunctions.touchFormFields(vm.registrationForm);
                            return;
                        }
                        var data = angular.copy(vm.customSelectedQueryList[index]);
                        data.detailNameEnglish = vm.customQuestions.questionEnglish;
                        data.detailNameSwedish = vm.customQuestions.questionSweden;
                        data.detailNameNorwegian = vm.customQuestions.questionNorwegian;
                        data.detailNameDanish = vm.customQuestions.questionDanish;
                        data.detailNameFinnish = vm.customQuestions.questionFinnish;
                        data.type = vm.customQuestions.type;
                        data.optionValues = getOptionsValues()['options'];
                        data.force2Update = getOptionsValues()['force2Update'];

                        if (data.type.name === 'DROPDOWN' || data.type.name === 'MULTISELECT') {
                            let _options = data.optionValues.split('~');
                            let _englishOptions = _options ? _options[0] : null;
                            let _swedishOptions = _options.length > 1 ? _options[1] : null;
                            let _norwegianOptions = _options.length > 2 ? _options[2] : null;
                            let _danishOptions = _options.length > 3 ? _options[3] : null;
                            let _finnishOptions = _options.length > 4 ? _options[4] : null;
                            let _error =
                                data.type.name === 'DROPDOWN'
                                    ? 'participant.registration.form.invalid.form.message'
                                    : 'participant.registration.form.invalid.multiselect.form.message';
                            if (
                                !_englishOptions &&
                                !_swedishOptions &&
                                !_norwegianOptions &&
                                !_danishOptions &&
                                !_finnishOptions
                            ) {
                                vm.formErrorMessage = _error;
                                return;
                            }

                            if (_englishOptions) {
                                if (_englishOptions.split('#').length < 2) {
                                    vm.updateQnBtnState = this.buttonStates.update;
                                    vm.formErrorMessage = _error;
                                    return;
                                }
                            }
                            if (_swedishOptions) {
                                if (_swedishOptions.split('#').length < 2) {
                                    vm.updateQnBtnState = this.buttonStates.update;
                                    vm.formErrorMessage = _error;
                                    return;
                                }
                            }
                            if (_norwegianOptions) {
                                if (_norwegianOptions.split('#').length < 2) {
                                    vm.updateQnBtnState = this.buttonStates.update;
                                    vm.formErrorMessage = _error;
                                    return;
                                }
                            }
                            if (_danishOptions) {
                                if (_danishOptions.split('#').length < 2) {
                                    vm.updateQnBtnState = this.buttonStates.update;
                                    vm.formErrorMessage = _error;
                                    return;
                                }
                            }
                            if (_finnishOptions) {
                                if (_finnishOptions.split('#').length < 2) {
                                    vm.updateQnBtnState = this.buttonStates.update;
                                    vm.formErrorMessage = _error;
                                    return;
                                }
                            }
                        }
                        vm.updateQnBtnState = vm.buttonStates.processing;
                        vm.updateCustomQuery(data, function (error, success) {
                            if (!error && success) {
                                vm.formErrorMessage = '';
                                $timeout(function () {
                                    vm.listSelectedQueries();
                                    initCustomQuestions();
                                    vm.registrationForm.$setPristine();
                                    vm.registrationForm.$setUntouched();
                                    vm.updateQnBtnState = vm.buttonStates.update;
                                }, 0);
                            } else {
                                $scope.endWithAlert(error);
                                initCustomQuestions();
                                vm.updateQnBtnState = vm.buttonStates.update;
                            }
                        });
                    } else {
                        vm.formErrorMessage = 'Query not found to update';
                    }
                }

                function getOptionsValues() {
                    let englishOptions = '';
                    let swedishOptions = '';
                    let norwegianOptions = '';
                    let danishOptions = '';
                    let finnishOptions = '';
                    let _onlyAddedEnglishOption = false, _onlyAddedSwedishOption = false, _onlyAddedNorwegianOption = false, _onlyAddedDanishOption = false, _onlyAddedFinnishOption = false;
                    if (vm.customQuestions.options['english'].length > 0) {
                        let eOpts = vm.customQuestions.options['english'].filter(function (opt) {
                            return opt && opt !== '';
                        });
                        let oEOpts = vm.originalCustomQuestions.options['english'].filter(function (opt) {
                            return opt && opt !== '';
                        });
                        if (eOpts.length !== oEOpts.length) {
                            let _diffIndexes = [];
                            eOpts.filter(function(i) {
                                if (oEOpts.indexOf(i) < 0) {
                                    _diffIndexes.push(eOpts.indexOf(i));
                                }
                            });
                            let _updatedEnglishOption = _.find(_diffIndexes, (df) => {return parseInt(df) < oEOpts.length});
                            _onlyAddedEnglishOption = _.find(_diffIndexes, (df) => {return parseInt(df) >= oEOpts.length}) && !_updatedEnglishOption;
                        }
                        englishOptions = eOpts.join('#');
                    } else {
                        _onlyAddedEnglishOption = true;
                    }
                    if (vm.customQuestions.options['swedish'].length > 0) {
                        let sOpts = vm.customQuestions.options['swedish'].filter(function (opt) {
                            return opt && opt !== '';
                        });
                        let oSOpts = vm.originalCustomQuestions.options['swedish'].filter(function (opt) {
                            return opt && opt !== '';
                        });
                        if (sOpts.length !== oSOpts.length) {
                            let _diffIndexes = [];
                            sOpts.filter(function(i) {
                                if (oSOpts.indexOf(i) < 0) {
                                    _diffIndexes.push(sOpts.indexOf(i));
                                }
                            });
                            let _updatedSwedishOption = _.find(_diffIndexes, (df) => {return parseInt(df) < oSOpts.length});
                            _onlyAddedSwedishOption = _.find(_diffIndexes, (df) => {return parseInt(df) >= oSOpts.length}) && !_updatedSwedishOption;
                        }
                        swedishOptions = sOpts.join('#');
                    } else {
                        _onlyAddedSwedishOption = true;
                    }
                    if (vm.customQuestions.options['norwegian'].length > 0) {
                        let nOpts = vm.customQuestions.options['norwegian'].filter(function (opt) {
                            return opt && opt !== '';
                        });
                        let oNOpts = vm.originalCustomQuestions.options['norwegian'].filter(function (opt) {
                            return opt && opt !== '';
                        });
                        if (nOpts.length !== oNOpts.length) {
                            let _diffIndexes = [];
                            nOpts.filter(function(i) {
                                if (oNOpts.indexOf(i) < 0) {
                                    _diffIndexes.push(nOpts.indexOf(i));
                                }
                            });
                            let _updatedNorwegianOption = _.find(_diffIndexes, (df) => {return parseInt(df) < oNOpts.length});
                            _onlyAddedNorwegianOption = _.find(_diffIndexes, (df) => {return parseInt(df) >= oNOpts.length}) && !_updatedNorwegianOption;
                        }
                        norwegianOptions = nOpts.join('#');
                    } else {
                        _onlyAddedNorwegianOption = true;
                    }
                    if (vm.customQuestions.options['danish'].length > 0) {
                        let dOpts = vm.customQuestions.options['danish'].filter(function (opt) {
                            return opt && opt !== '';
                        });
                        let oDOpts = vm.originalCustomQuestions.options['danish'].filter(function (opt) {
                            return opt && opt !== '';
                        });
                        if (dOpts.length !== oDOpts.length) {
                            let _diffIndexes = [];
                            dOpts.filter(function(i) {
                                if (oDOpts.indexOf(i) < 0) {
                                    _diffIndexes.push(dOpts.indexOf(i));
                                }
                            });
                            let _updatedDanishOption = _.find(_diffIndexes, (df) => {return parseInt(df) < oDOpts.length});
                            _onlyAddedDanishOption = _.find(_diffIndexes, (df) => {return parseInt(df) >= oDOpts.length}) && !_updatedDanishOption;
                        }
                        danishOptions = dOpts.join('#');
                    } else {
                        _onlyAddedDanishOption = true;
                    }
                    if (vm.customQuestions.options['finnish'].length > 0) {
                        let fOpts = vm.customQuestions.options['finnish'].filter(function (opt) {
                            return opt && opt !== '';
                        });
                        let oFOpts = vm.originalCustomQuestions.options['finnish'].filter(function (opt) {
                            return opt && opt !== '';
                        });
                        if (fOpts.length !== oFOpts.length) {
                            let _diffIndexes = [];
                            fOpts.filter(function(i) {
                                if (oFOpts.indexOf(i) < 0) {
                                    _diffIndexes.push(fOpts.indexOf(i));
                                }
                            });
                            let _updatedFinnishOption = _.find(_diffIndexes, (df) => {return parseInt(df) < oFOpts.length});
                            _onlyAddedFinnishOption = _.find(_diffIndexes, (df) => {return parseInt(df) >= oFOpts.length}) && !_updatedFinnishOption;
                        }
                        finnishOptions = fOpts.join('#');
                    } else {
                        _onlyAddedFinnishOption = true;
                    }
                    let _force2Update = _onlyAddedEnglishOption && _onlyAddedSwedishOption && _onlyAddedNorwegianOption && _onlyAddedDanishOption && _onlyAddedFinnishOption;
                    let _options = [englishOptions, swedishOptions, norwegianOptions, danishOptions, finnishOptions].join('~');
                    return {"options": _options, "force2Update": _force2Update};
                }

                function updateCustomQueryFn(query, cb) {
                    EventParticipantCustomQuery.update(
                        { queryId: query.id },
                        query,
                        function (response) {
                            updateQueryList(response);
                            vm.loading = false;
                            if (typeof cb == 'function') {
                                cb(null, true);
                            }
                        },
                        function (error) {
                            if (
                                error &&
                                error.data &&
                                error.data.localisedMessages &&
                                error.data.localisedMessages[0].code === 20003
                            ) {
                                var map = {};
                                if ($rootScope.langSelected === 'en_US') {
                                    map.fieldName = query.detailNameEnglish;
                                }
                                if ($rootScope.langSelected === 'sv_SV') {
                                    map.fieldName = query.detailNameSwedish;
                                }
                                if ($rootScope.langSelected === 'nb_NB') {
                                    map.fieldName = query.detailNameNorwegian;
                                }
                                if ($rootScope.langSelected === 'da_DK') {
                                    map.fieldName = query.detailNameDanish;
                                }
                                if ($rootScope.langSelected === 'fi_FI') {
                                    map.fieldName = query.detailNameFinnish;
                                }
                                OgModal.open('confirmation', {
                                    message: error.data.localisedMessages[0].messageKey,
                                    messageArgs: map,
                                    okCallBack: function () {
                                        query.force2Update = true;
                                        vm.updateCustomQuery(query, cb);
                                    },
                                    cancelCallBack: function () {
                                        vm.loading = false;
                                        cb(null, false);
                                    },
                                    closeCallBack: function () {
                                        vm.loading = false;
                                        cb(null, false);
                                    },
                                });
                            } else {
                                cb(error, false);
                            }
                        }
                    );
                }

                function updateRequiredCustomQueryFn(query, cb) {
                    EventParticipantCustomQuery.update(
                        { queryId: query.id },
                        query,
                        function (response) {
                            updateQueryList(response);
                            if (typeof cb == 'function') {
                                cb(null, true);
                            }
                        },
                        function (error) {
                            if (
                                error &&
                                error.data &&
                                error.data.localisedMessages &&
                                error.data.localisedMessages[0].code === 20003
                            ) {
                                var map = {};
                                if ($rootScope.langSelected === 'en_US') {
                                    map.fieldName = query.detailNameEnglish;
                                }
                                if ($rootScope.langSelected === 'sv_SV') {
                                    map.fieldName = query.detailNameSwedish;
                                }
                                if ($rootScope.langSelected === 'nb_NB') {
                                    map.fieldName = query.detailNameNorwegian;
                                }
                                if ($rootScope.langSelected === 'da_DK') {
                                    map.fieldName = query.detailNameDanish;
                                }
                                if ($rootScope.langSelected === 'fi_FI') {
                                    map.fieldName = query.detailNameFinnish;
                                }
                                query.force2Update = true;
                                vm.updateRequiredCustomQuery(query, cb);
                            } else {
                                cb(error, false);
                            }
                        }
                    );
                }

                var removeCustomSelectedList = function (data) {
                    var index = _.findIndex(vm.queryList, { id: data.id });
                    if (index !== -1) {
                        var obj = vm.queryList[index];
                        obj.isChecked = false;

                        vm.updateCustomQuery(obj, function (error, isUpdated) {
                            if (error || !isUpdated) {
                                vm.loading = false;
                                vm.queryList[index].isChecked = true;
                                $scope.endWithAlert(error);
                            } else {
                                vm.listSelectedQueries();
                            }
                        });
                    }
                };

                var removeBasicSelectedList = function (data) {
                    var index = _.findIndex(vm.participantBasicQueryList, { id: data.id });
                    if (index !== -1) {
                        var obj = angular.copy(vm.participantBasicQueryList[index]);
                        obj.isChecked = false;
                        vm.updateBasicQuery(obj, function (error, isUpdated, data) {
                            if (error || !isUpdated) {
                                vm.participantBasicQueryList[index].isChecked = true;
                                $scope.endWithAlert(error);
                            } else {
                                vm.participantBasicQueryList[index].isChecked = false;
                                setBasicInfoList(data);
                            }
                        });
                    }
                };

                function getInformationFn(fieldName) {
                    if (fieldName === 'Email' || fieldName === 'Phone') {
                        return $translate.instant('default.registration.email.phone.info.text');
                    }
                    return '';
                }

                function initCustomQuestions() {
                    vm.customQuestions = {};
                    vm.customeQuestionsType = [
                        'TEXT',
                        'DROPDOWN',
                        'NUMBER',
                        'DATE',
                        'DATERANGE',
                        'EMAIL',
                        'CHECKBOX',
                        'TEXTAREA',
                        'TELEPHONE',
                        'MULTISELECT',
                    ];
                    vm.customQuestions.optionLength = [1];
                    vm.customQuestions.options = {};
                    vm.customQuestions.options['english'] = [];
                    vm.customQuestions.options['swedish'] = [];
                    vm.customQuestions.options['norwegian'] = [];
                    vm.customQuestions.options['danish'] = [];
                    vm.customQuestions.options['finnish'] = [];
                    vm.originalCustomQuestions = angular.copy(vm.customQuestions);
                }

                initCustomQuestions();

                function updateQueryList(query) {
                    angular.forEach(vm.queryList, function (queryObj, key) {
                        if (query.id === queryObj.id) {
                            vm.queryList[key] = query;
                        }
                    });
                }

                function updateBasicQueryFn(option, cb) {
                    ParticipantBasicQuery.updateOptionForEvent(
                        vm.eventId,
                        option,
                        function (response) {
                            $scope.endProgress();
                            vm.loading = false;
                            if (typeof cb == 'function') {
                                cb(null, true, response);
                            }
                        },
                        function (error) {
                            if (
                                error &&
                                error.data &&
                                error.data.localisedMessages &&
                                error.data.localisedMessages[0].code === 20003
                            ) {
                                var map = {};
                                if ($rootScope.langSelected === 'en_US') {
                                    map.fieldName = option.detailNameEnglish;
                                }
                                if ($rootScope.langSelected === 'sv_SV') {
                                    map.fieldName = option.detailNameSwedish;
                                }
                                if ($rootScope.langSelected === 'nb_NB') {
                                    map.fieldName = option.detailNameNorwegian;
                                }
                                if ($rootScope.langSelected === 'da_DK') {
                                    map.fieldName = option.detailNameDanish;
                                }
                                if ($rootScope.langSelected === 'fi_FI') {
                                    map.fieldName = option.detailNameFinnish;
                                }
                                let data = {
                                    message: error.data.localisedMessages[0].message,
                                    messageArgs: map,
                                    okCallBack: function () {
                                        option.force2Update = true;
                                        vm.updateBasicQuery(option, cb);
                                    },
                                    cancelCallBack: function () {
                                        vm.loading = false;
                                        cb(null, false);
                                    },
                                    closeCallBack: function () {
                                        vm.loading = false;
                                        cb(null, false);
                                    },
                                };

                                let confirmationModalData = confirmationModal;
                                confirmationModalData.inputs = { data };

                                ModalService.showModal(confirmationModalData).then((modal) => {
                                    modal.element.modal();
                                    modal.close.then(() => {
                                        console.log('modal is closed!');
                                    });
                                });
                            } else {
                                cb(error, false);
                            }
                        }
                    );
                }

                function updateCustomQueryInBatchFn(updatedQueryList) {
                    EventParticipantCustomQuery.updateInBatch(
                        vm.eventId,
                        updatedQueryList,
                        function (response) {
                            vm.customSelectedQueryList = response;
                            $scope.endProgress();
                        },
                        vm.endWithAlert
                    );
                }

                function setBasicInfoList(eventBasicQueryList) {
                    var selected = _.where(eventBasicQueryList, { isChecked: true });
                    selected = _.sortBy(selected, function (d) {
                        return d.eventParticipantBasicQuery.displayOrder;
                    });
                    vm.selectedBasicQueryList = selected;
                    vm.basicInfo = {
                        selected: null,
                        lists: {
                            left: selected,
                        },
                    };
                }

                function getParticipantBasicQueryFn() {
                    if (!$scope.isInProgress('queryInfo')) {
                        $scope.startProgress('queryInfo');
                    }
                    ParticipantBasicQuery.getForEvent(
                        vm.eventId,
                        function (response) {
                            vm.participantBasicQueryList = response;
                            setBasicInfoList(response);
                            $scope.endProgress('queryInfo');
                            vm.loading = false;
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                vm.multiSelectBasicEvents = {
                    onItemSelect: function (data) {
                        var index = _.findIndex(vm.participantBasicQueryList, { id: data.id });
                        if (index !== -1) {
                            data.isChecked = true;
                            data.eventParticipantBasicQuery = {};
                            data.eventParticipantBasicQuery.isMandatory = data.isMandatory ? true : false;
                            var _max = _.max(vm.basicInfo.lists.left, (b) => {
                                return b.eventParticipantBasicQuery.displayOrder;
                            });
                            var _size = _max.eventParticipantBasicQuery.displayOrder;
                            data.eventParticipantBasicQuery.displayOrder = _size + 1;
                            vm.updateBasicQuery(data, function (error, isUpdated, data) {
                                if (error && !isUpdated) {
                                    vm.participantBasicQueryList[index].isChecked = false;
                                } else {
                                    vm.participantBasicQueryList[index].isChecked = true;
                                    setBasicInfoList(data);
                                }
                            });
                        }
                    },
                    onItemDeselect: function (data) {
                        removeBasicSelectedList(data, true);
                    },
                };

                vm.multiSelectCustomEvents = {
                    onItemSelect: function (data) {
                        var index = _.findIndex(vm.queryList, { id: data.id });
                        if (index !== -1) {
                            var obj = data;
                            obj.isChecked = true;
                            obj.isMandatory = obj.isMandatory ? true : false;
                            obj.displayOrder = vm.additionalInfo.lists.right.length;
                            vm.updateCustomQuery(obj, function (error, isUpdated) {
                                if (!error && isUpdated) {
                                    vm.listSelectedQueries();
                                } else {
                                    vm.queryList[index].isChecked = false;
                                }
                            });
                        }
                    },
                    onItemDeselect: function (data) {
                        removeCustomSelectedList(data);
                    },
                };

                isParticipantsImportingFn(vm.eventId);
                getParticipantBasicQueryFn();
                vm.listSelectedQueries();
                vm.listCustomQueries();
                vm.init();
            }
        ]
    });
