(function () {
    'use strict';

    angular.module('ogSearchResultTile').directive('ogSearchResultTile', [
        '$rootScope',
        function ($rootScope) {
            return {
                restrict: 'EA',
                transclude: true,
                templateUrl: './app/components/directives/search_result_tile/og_search_result_tile.directive.view.html',
                controller: [
                    '$scope',
                    function ($scope) {
                        var $img_to_move = jQuery(
                            ' <span class="animation-cart heart-dark"><i class="fa fa-heart"></i></span>'
                        );

                        $scope.addContentAndAnimate = function (index, item) {
                            var cart = jQuery('.animate-to');
                            var imgDragOffset = jQuery('.' + index).offset();
                            console.log($img_to_move.not(':animated'));
                            if (imgDragOffset && $img_to_move.not(':animated')) {
                                var imgclone = $img_to_move
                                    .offset({ left: imgDragOffset.left, top: imgDragOffset.top })
                                    .css({
                                        //  'opacity': '0.5',
                                        position: 'absolute',
                                        height: '40px',
                                        width: '50px',
                                        'z-index': '100000000',
                                    })
                                    .appendTo(jQuery('body'))
                                    .animate(
                                        {
                                            top: cart.offset().top,
                                            left: cart.offset().left - 10,
                                            width: 50,
                                            height: 40,
                                        },
                                        {
                                            duration: 800,
                                            easing: 'linear',
                                        }
                                    );

                                imgclone.animate(
                                    {
                                        width: 0,
                                        height: 0,
                                    },
                                    function () {
                                        $scope.nonSearchParams.addToSelectedItems(item);
                                        $rootScope.safeApply($scope);
                                        jQuery(this).detach();
                                    }
                                );
                            }
                            //   $scope.nonSearchParams.addToSelectedItems(item);
                        };
                    },
                ],
            };
        },
    ]);
})();
