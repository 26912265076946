/**
 * Don't put this content inside function()
 */
'use strict';
import CommonFunctions from '../../utils/CommonFunctionUtil'

const OFFER_STATUS_CONDITION_MESSAGE_MAP = {
    NEW: 'calendar.request.unsent',
    EDITED_BY_CREATOR: 'calendar.request.unsent',
    EDITED_BY_CREATOR_ALREADY_SENT: 'calendar.waiting',
    SENT: 'calendar.waiting',
    SENT_ALREADY_SENT: 'calendar.waiting.after.resent',
    SENT_ALREADY_ANSWERED: 'calendar.waiting.after.answered.resent',
    BOOKED_WAITING_FOR_SUPPLIER: 'calendar.waiting',
    FINISHED_WAITING_FOR_SUPPLIER: 'calendar.waiting',
    CANCELED: 'CANCELED',
    CANCELED_AND_FINISHED: 'CANCELED_AND_FINISHED',
    DECLINED: 'DECLINED',
};

const OFFER_STATUS_BUTTON_MESSAGE_MAP = {
    EDITED_BY_CREATOR_ALREADY_ANSWERED: 'event.content.sidebar.content.for.answered',
    ANSWERED: 'event.content.sidebar.content.for.answered',
    DECLINED: 'event.content.sidebar.content.for.declined',
    BOOKED: 'event.content.sidebar.content.for.booked',
    BOOKED_WAITING_FOR_CONFIRMATION: 'event.content.sidebar.content.for.booked',
    BOOKED_LOCKED_BY_CREATOR: 'event.content.sidebar.content.for.booked',
    BOOKED_LOCKED_BY_SUPPLIER: 'event.content.sidebar.content.for.booked',
    BOOKED_WAITING_FOR_CREATOR: 'event.content.sidebar.content.for.booked',
    BOOKED_WAITING_FOR_SUPPLIER: 'event.content.sidebar.content.for.booked.waiting.for.supplier',
    APPROVED: 'event.content.sidebar.content.for.approved',
    FINISHED: 'event.content.sidebar.content.for.finished',
    FINISHED_WAITING_FOR_CREATOR: 'event.content.sidebar.content.for.finished',
    FINISHED_WAITING_FOR_SUPPLIER: 'event.content.sidebar.content.for.finished',
    CANCELED_AND_APPROVED: 'event.content.sidebar.content.for.approved',
    CANCELED_AND_FINISHED: 'event.content.sidebar.content.for.finished',
    CANCELED_WAITING_FOR_CREATOR: 'event.content.sidebar.content.for.finished',
    CANCELED_WAITING_FOR_SUPPLIER: 'event.content.sidebar.content.for.finished',
};

export const offerStatusMessageUtil = {
    getStatusButtonKey: function (requestOffer) {
        var key = requestOffer.statusDetailed.name;
        if (requestOffer.status.name === 'EDITED_BY_CREATOR') {
            var isAlreadyAnsweredOffer = CommonFunctions.hasIntersectValue(['ANSWERED'], requestOffer.statusLogs);
            var isAlreadySentOffer = CommonFunctions.hasIntersectValue(['SENT'], requestOffer.statusLogs);

            if (isAlreadyAnsweredOffer) {
                return key + '_ALREADY_ANSWERED';
            }
            if (isAlreadySentOffer) {
                return key + '_ALREADY_SENT';
            }
        }
        return key;
    },

    getStatusButtonMessage: function (requestOffer) {
        var key = offerStatusMessageUtil.getStatusButtonKey(requestOffer);
        return OFFER_STATUS_BUTTON_MESSAGE_MAP[key];
    },

    getStatusMessageKey: function (requestOffer) {
        var key = requestOffer.status.name;
        if (requestOffer.status.name === 'SENT' || requestOffer.status.name === 'EDITED_BY_CREATOR') {
            var isAlreadyAnsweredOffer = CommonFunctions.hasIntersectValue(['ANSWERED'], requestOffer.statusLogs);
            var isAlreadySentOffer = CommonFunctions.hasIntersectValue(['SENT'], requestOffer.statusLogs);

            if (isAlreadyAnsweredOffer) {
                return key + '_ALREADY_ANSWERED';
            }
            if (isAlreadySentOffer) {
                return key + '_ALREADY_SENT';
            }
        }
        return key;
    },

    getStatusMessage: function (requestOffer) {
        var mapKey = offerStatusMessageUtil.getStatusMessageKey(requestOffer);
        return OFFER_STATUS_CONDITION_MESSAGE_MAP[mapKey];
    },
};
