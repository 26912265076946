/**
 * Created by kshitij on 8/7/17.
 */

import controller from './og_accommodation_calendar_box.controller';

(function () {
    'use strict';
    angular.module('accommodation.calendar.box').directive('ogAccommodationCalendarBox', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    room: '=',
                    numberOfBeds: '=',
                    isCustom: '=',
                    readOnly: '=',
                    emptyRoomsText: '=',
                    openAccommodationModalCallback: '&',
                },
                templateUrl:
                    './app/components/directives/accommodation_calendar_box/og_accommodation_calendar_box.view.html',
                controller,
                controllerAs: 'AccommodationCalendarBoxCtrl',
                bindToController: true,
            };
        },
    ]);
})();
