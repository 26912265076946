import googleAddressUtil from '../../../utils/GoogleAddressUtil';
import GoogleAddressUtil from '../../../utils/GoogleAddressUtil';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

class AddSupplierFromMapCtrl {
    constructor(
        $scope,
        $rootScope,
        Supplier,
        $translate,
        SupplierDetailService,
        $element,
        $timeout,
        SupplierDetailInfo,
        CommonFile,
        CommonFunctions
    ) {
        this._injected = {
            $scope,
            $rootScope,
            Supplier,
            $translate,
            SupplierDetailService,
            $element,
            $timeout,
            SupplierDetailInfo,
            CommonFile,
        };

        this.publicUrl = CommonFunctions.getPublicURL();
        ScopeFunctions.addToScope($scope);
        this.disableInfiniteScroll = false;
        this.getSlidingImages = this.getSlidingImages.bind(this);
        this.updateRemoteUrlOnAddressChange = this.updateRemoteUrlOnAddressChange.bind(this);
        this.showSupplierDetails = this.showSupplierDetails.bind(this);
        this.updateSelectedSupplierName = this.updateSelectedSupplierName.bind(this);
        this.updateOfficeAddress = this.updateOfficeAddress.bind(this);
        this.searchSupplierAPI = this.searchSupplierAPI.bind(this);
        this.advancedFilter = false;
        SupplierDetailService.setDisplaySearchFilterInMap(true);
        this.baseRemoteUrl = CommonFile.getBaseUrl() + 'api/v1/supplier/getSupplierDropdownList';

        this.$onInit = function () {
            this.supplierDetailArray = this.searchParams.supplierDetailArray || [];
            if (this.searchParams.searchContractedSupplier) {
                delete this.searchParams.searchContractedSupplier;
            }
            if (this.searchParams.categoryIds) {
                this.remoteUrl = new URL(this.baseRemoteUrl);
                this.remoteUrl.searchParams.append('categoryIds', this.searchParams.categoryIds);
                if (this.searchParams.isThereAccomAndConf) {
                    this.remoteUrl.searchParams.append('isThereAccomAndConf', this.isThereAccomAndConf);
                }
                if (this.searchParams.isThereConferenceOnly) {
                    this.remoteUrl.searchParams.append('isThereConferenceOnly', this.isThereConferenceOnly);
                }
                if (this.searchParams.officeAddress && this.searchParams.officeAddress.municipality) {
                    this.remoteUrl.searchParams.append('municipality', this.searchParams.officeAddress.municipality);
                }
                if (this.searchParams.officeAddress && this.searchParams.officeAddress.town) {
                    this.remoteUrl.searchParams.append('town', this.searchParams.officeAddress.town);
                }
                if (this.searchParams.officeAddress && this.searchParams.officeAddress.state) {
                    this.remoteUrl.searchParams.append('state', this.searchParams.officeAddress.state);
                }
                if (this.searchParams.officeAddress && this.searchParams.officeAddress.country) {
                    this.remoteUrl.searchParams.append('country', this.searchParams.bounds.country);
                }
                if (this.searchParams.bounds && this.searchParams.bounds.minLat) {
                    this.remoteUrl.searchParams.append('minLat', this.searchParams.bounds.minLat);
                }
                if (this.searchParams.bounds && this.searchParams.bounds.maxLat) {
                    this.remoteUrl.searchParams.append('maxLat', this.searchParams.bounds.maxLat);
                }
                if (this.searchParams.bounds && this.searchParams.bounds.minLng) {
                    this.remoteUrl.searchParams.append('minLng', this.searchParams.bounds.minLng);
                }
                if (this.searchParams.bounds && this.searchParams.bounds.maxLng) {
                    this.remoteUrl.searchParams.append('maxLng', this.searchParams.bounds.maxLng);
                }
                if (this.searchParams.location && this.searchParams.location.lat) {
                    this.remoteUrl.searchParams.append('lat', this.searchParams.location.lat);
                }
                if (this.searchParams.location && this.searchParams.location.lng) {
                    this.remoteUrl.searchParams.append('lng', this.searchParams.location.lng);
                }
                if (this.searchParams.withChildLocation) {
                    this.remoteUrl.searchParams.append('withChildLocation', this.searchParams.withChildLocation);
                }
            }
            this.searchSupplier();
        }
    }

    showSupplierDetails(supplier) {
        const { SupplierDetailService } = this._injected;
        this.selectedSupplier = supplier;
        SupplierDetailService.setSupplierDetailStatus(1);
        this.disableInfiniteScroll = true;
    }

    shouldDisplaySupplierDetails() {
        const { SupplierDetailService } = this._injected;
        return SupplierDetailService.getSupplierDetailStatus();
    }

    shouldDisplaySearchFilterInMap() {
        const { SupplierDetailService } = this._injected;
        let _shouldDisplayDetails = this.shouldDisplaySupplierDetails();
        let _shouldDisplaySearchFilters = SupplierDetailService.getDisplaySearchFilterInMap();
        return !_shouldDisplayDetails && _shouldDisplaySearchFilters;
    }

    backToMap() {
        const { SupplierDetailService } = this._injected;
        SupplierDetailService.setSupplierDetailStatus(null);
    }

    getSlidingImages() {
        this.slidingList = [];
        if (this.selectedSupplier && this.selectedSupplier.presentation) {
            let presentationImages = this.selectedSupplier.presentation.presentationImages;
            if (presentationImages.length > 0) {
                for (let k in presentationImages) {
                    if (presentationImages.hasOwnProperty(k)) {
                        this.slidingList.push({ image: presentationImages[k].fileStoreId });
                    }
                }
            }
        }
    }

    closeMapView() {
        this.closeMapViewCallBackFn(
            this.searchParams.text,
            this.searchParams.officeAddress,
            this.searchParams.bounds,
            this.searchParams.location
        );
    }

    isSupplierAlreadyAdded() {
        if (_.findIndex(this.addedSupplierList, { id: this.selectedSupplier.id }) > -1) {
            return true;
        }
    }

    closeSelectedSupplierPreview() {
        this.selectedSupplier = null;
    }

    updateOfficeAddress() {
        let searchAddress;
        let _displayAddress = angular.copy(this.searchParams.officeAddress.displayAddress);
        if (_displayAddress) {
            window.googleMapsCallback(() => {
                var geocoder = new google.maps.Geocoder();
                let _correctedDisplayAddress = GoogleAddressUtil.getCorrectedDisplayAddressManually(_displayAddress);
                geocoder.geocode({address: _correctedDisplayAddress}, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        var place = GoogleAddressUtil.findPlaceFromResults(results);
                        searchAddress = googleAddressUtil.parseGoogleAddress(place);
                        this.searchParams.officeAddress.municipality = searchAddress.municipality;
                        this.searchParams.officeAddress.town = searchAddress.town;
                        this.searchParams.officeAddress.state = searchAddress.state;
                        this.searchParams.officeAddress.country = searchAddress.country;
                        this.remoteUrl.searchParams.set('municipality', searchAddress.municipality);
                        this.remoteUrl.searchParams.set('town', searchAddress.town);
                        this.remoteUrl.searchParams.set('state', searchAddress.state);
                        this.remoteUrl.searchParams.set('country', searchAddress.country);
                        let _bounds = place.geometry.bounds;
                        if (_bounds) {
                            let _swLat = _bounds.getSouthWest().lat();
                            let _swLng = _bounds.getSouthWest().lng();
                            let _neLat = _bounds.getNorthEast().lat();
                            let _neLng = _bounds.getNorthEast().lng();
                            this.remoteUrl.searchParams.set('minLng', _swLng);
                            this.remoteUrl.searchParams.set('maxLng', _neLng);
                            this.remoteUrl.searchParams.set('minLat', _swLat);
                            this.remoteUrl.searchParams.set('maxLat', _neLat);
                            this.searchParams.bounds = {
                                minLng: _swLng,
                                maxLng: _neLng,
                                minLat: _swLat,
                                maxLat: _neLat,
                            };
                            if (place.geometry.location) {
                                this.remoteUrl.searchParams.set('lat', place.geometry.location.lat());
                                this.remoteUrl.searchParams.set('lng', place.geometry.location.lng());
                                this.searchParams.location = {
                                    lat: place.geometry.location.lat(),
                                    lng: place.geometry.location.lng(),
                                };
                            }
                        } else if (place.geometry.location) {
                            this.remoteUrl.searchParams.set('minLng', '');
                            this.remoteUrl.searchParams.set('maxLng', '');
                            this.remoteUrl.searchParams.set('minLat', '');
                            this.remoteUrl.searchParams.set('maxLat', '');
                            this.remoteUrl.searchParams.set('lat', place.geometry.location.lat());
                            this.remoteUrl.searchParams.set('lng', place.geometry.location.lng());
                            this.searchParams.location = {
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                            };
                            this.searchParams.bounds = {};
                        }
                    }
                });
            });
        } else {
            this.remoteUrl.searchParams.set('municipality', '');
            this.remoteUrl.searchParams.set('town', '');
            this.remoteUrl.searchParams.set('state', '');
            this.remoteUrl.searchParams.set('country', '');
            this.remoteUrl.searchParams.set('minLat', '');
            this.remoteUrl.searchParams.set('maxLat', '');
            this.remoteUrl.searchParams.set('minLng', '');
            this.remoteUrl.searchParams.set('maxLng', '');
            this.remoteUrl.searchParams.set('lat', '');
            this.remoteUrl.searchParams.set('lng', '');
            this.searchParams.officeAddress.displayAddress = null;
            this.searchParams.officeAddress = {};
            this.searchParams.bounds = {};
            this.searchParams.location = {};
        }
    }

    updateRemoteUrlOnAddressChange() {
        const { $timeout } = this._injected;
        $timeout(() => {
            this.updateOfficeAddress();
        }, 1000);
    }

    searchSupplier() {
        this.searchParams.filterCategoryWiseInSupplierModal = false;
        this.getAdvanceFilter();
        setTimeout(() => {
            let _displayAddress = angular.copy(this.searchParams.officeAddress.displayAddress);
            if (_displayAddress) {
                let _correctedDisplayAddress = GoogleAddressUtil.getCorrectedDisplayAddressManually(_displayAddress);
                window.googleMapsCallback(() => {
                    var geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ address: _correctedDisplayAddress }, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK) {
                            var place = GoogleAddressUtil.findPlaceFromResults(results);
                            this.searchParams.officeAddress = googleAddressUtil.parseGoogleAddress(place);
                            this.searchParams.officeAddress.displayAddress = _displayAddress;
                            this.searchParams.officeAddress.searchAddress = googleAddressUtil.prepareGeocodeParameters(
                                this.searchParams.officeAddress
                            );
                            let _bounds = place.geometry.bounds;
                            if (_bounds) {
                                let _swLat = _bounds.getSouthWest().lat();
                                let _swLng = _bounds.getSouthWest().lng();
                                let _neLat = _bounds.getNorthEast().lat();
                                let _neLng = _bounds.getNorthEast().lng();
                                this.searchParams.bounds = {
                                    minLng: _swLng,
                                    maxLng: _neLng,
                                    minLat: _swLat,
                                    maxLat: _neLat,
                                };
                                if (place.geometry.location) {
                                    this.searchParams.location = {
                                        lat: place.geometry.location.lat(),
                                        lng: place.geometry.location.lng(),
                                    };
                                }
                            } else if (place.geometry.location) {
                                this.searchParams.location = {
                                    lat: place.geometry.location.lat(),
                                    lng: place.geometry.location.lng(),
                                };
                                this.searchParams.bounds = {};
                            }
                        }
                        this.searchSupplierAPI();
                    });
                });
            } else {
                this.searchParams.officeAddress = {};
                this.searchParams.bounds = {};
                this.searchParams.location = {};
                this.searchSupplierAPI();
            }
        }, 700);
    }

    searchSupplierAPI() {
        const { Supplier, $scope } = this._injected;
        this.searchParams.isSuggestedSupplierSelected = JSON.parse(localStorage.getItem('isSuggestedSupplierSelected'));

        this.removePreviousMarkers();
        $scope.startProgress();
        Supplier.listSuppliersLocation(
            this.searchParams,
            (successResponse) => {
                this.supplierLocations = successResponse.locations;
                this.searchParams.supplierSearchFromMap = true; //To optimize supplier search on map only when searching not during mapZoom or mapDrag
                localStorage.setItem('isSuggestedSupplierSelected', 'false');
                $scope.$broadcast('supplier.for.map.has.been.pulled', this.searchParams, this.supplierLocations);
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    getAdvanceFilter() {
        this.supplierDetailArray = [];
        if (this.request.supplierDetails) {
            angular.forEach(this.request.supplierDetails.supplierDetailGroups, (group) => {
                angular.forEach(group.supplierDetailFilterGroups, (supplierDetailFilterGroup) => {
                    angular.forEach(supplierDetailFilterGroup.supplierDetails, (supplierDetail) => {
                        if (supplierDetail.value && Number(supplierDetail.value) !== 0) {
                            this.supplierDetailArray.push(supplierDetail);
                        }
                    });
                });
            });
        }
    }

    showAdvancedFilterSection() {
        const { $scope, SupplierDetailInfo } = this._injected;
        if (this.request && !this.request.supplierDetails) {
            $scope.startProgress('add-supplier-modal');
            SupplierDetailInfo.getSupplierDetailsFilter(
                this.request.selectedCategoryId,
                (response) => {
                    this.request.supplierDetails = response;
                    $scope.endProgress('add-supplier-modal');
                },
                $scope.endWithErrorOverlay('add-supplier-modal')
            );
        }
        this.advancedFilter = true;
        this.setActive = true;
        this.resizeAdvanceFilterBodyHeight();
    }

    hideAdvancedFilterSection() {
        this.advancedFilter = false;
        this.setActive = false;
    }

    resizeAdvanceFilterBodyHeight(delayTime = 0) {
        const { $element, $timeout } = this._injected;
        $timeout(function () {
            let totalMarginForTopAndBottom = 155;
            let modalBody = $element.find('.select-supplier-map');
            let advanceFilterBodyHeight = modalBody.innerHeight() - totalMarginForTopAndBottom;
            let advanceFilterBody = $element.find('.advance-filter-body');
            advanceFilterBody.css('max-height', advanceFilterBodyHeight + 'px');
        }, delayTime);
    }

    translate(name, code) {
        const { $translate } = this._injected;
        if (code) {
            let translatedText = $translate.instant(code);
            if (translatedText !== code) {
                return translatedText;
            }
        }
        return name;
    }

    getSupplierDetailOptions(supplierDetail) {
        supplierDetail.value = (supplierDetail.value || 0).toString();
        supplierDetail.options = {
            floor: 0,
            ceil: supplierDetail.maxValue,
            step: supplierDetail.step,
            // translate: (value) => {
            //     if (supplierDetail.unitDetails) {
            //         return supplierDetail.unitDetails.prefixed
            //             ? supplierDetail.unitDetails.unit + value
            //             : value + supplierDetail.unitDetails.unit;
            //     }
            //     return value;
            // },
        };
        return supplierDetail.options;
    }

    clearAdvanceSearchFilters() {
        this.setActive = false;
        this.advancedFilter = false;
        angular.forEach(this.request.supplierDetails.supplierDetailGroups, (group) => {
            angular.forEach(group.supplierDetailFilterGroups, (supplierDetailFilterGroup) => {
                angular.forEach(supplierDetailFilterGroup.supplierDetails, (supplierDetail) => {
                    supplierDetail.value = null;
                });
            });
        });
        this.doFreshSupplierSearch();
    }

    clearParticularAdvanceSearchFilter(supplierDetailToBeRemoved, index) {
        supplierDetailToBeRemoved.value = null;
        this.supplierDetailArray.splice(index, 1);
        this.doFreshSupplierSearch();
    }

    doFreshSupplierSearch() {
        this.advancedFilter = false;
        this.searchParams.supplierDetails = this.request.supplierDetails;
        this.searchSupplier();
    }

    updateSelectedSupplierName(supplierName) {
        this.searchParams.text = supplierName;
    }

    removePreviousMarkers() {
        const { $rootScope } = this._injected;
        if ($rootScope.markers && $rootScope.markers.length > 0) {
            for (let i = 0; i < $rootScope.markers.length; i++) {
                $rootScope.markers[i].setMap(null);
            }
        }
        $rootScope.markers = [];
    }
}

AddSupplierFromMapCtrl.$inject = [
    '$scope',
    '$rootScope',
    'Supplier',
    '$translate',
    'SupplierDetailService',
    '$element',
    '$timeout',
    'SupplierDetailInfo',
    'CommonFile',
    'CommonFunctions',
];
export default AddSupplierFromMapCtrl;
