'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogChangePasswordModal', [
    '$rootScope',
    'AuthenticationService',
    function ($rootScope, AuthenticationService) {
        return new OgModal({
            name: 'change:password',
            type: 'custom',
            controller: function ($scope) {
                $scope.success = false;
                $scope.data = {};
                ScopeFunctions.addToScope($scope);
                $scope.requestNewPassword = function (userNameEmail) {
                        if (userNameEmail) {
                            $scope.startProgress();
                            AuthenticationService.requestNewPassword(
                                { username: userNameEmail, form: 'PasswordForm' },
                                (resp) => {
                                    $rootScope.$broadcast('dialog:password:change:success');
                                    $scope.success = true;
                                    $scope.userEmail = resp.userName;
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay()
                            );
                        }
                    };
                    $scope.$on('change_password_dialog:close', function () {
                        $scope.closeDialog = true;
                    });
                    $scope.close = function () {
                        $scope.success = false;
                        this.data.userNameEmail = '';
                    };
                },
            });
        },
    ]);

