/**
 * Created by sampurna on 2020-04-22.
 */
import _ from 'underscore';

angular.module('core.existingServices').factory('DynamicFormUtilService', [
    '$q',
    function ($q) {
        function checkRequiredSync(_parts, callbackfn, isRequiredFieldUnanswered) {
            let mainDeferred = $q.defer();
            var _unansweredPartsList = _.filter(_parts, (part) => {
                return part.hasAdditionalQuestionsUnanswered;
            });
            if (isRequiredFieldUnanswered) {
                mainDeferred.resolve(false);
                callbackfn(_unansweredPartsList);
            } else if (!isRequiredFieldUnanswered && _unansweredPartsList.length > 0) {
                mainDeferred.resolve(false);
                callbackfn(_unansweredPartsList);
            } else {
                mainDeferred.resolve(true);
            }
            return mainDeferred.promise;
        }

        function requiredUnanswered(_parts) {
            var _unansweredParts = _.find(_parts, (part) => {
                return part.hasAdditionalQuestionsUnanswered;
            });
            return !!_unansweredParts;
        }

        function isRequiredQuestionUnAnswered(questions) {
            let isUnAnswered = false;
            if (questions) {
                angular.forEach(questions, (list) => {
                    let _fieldValue = list.type.name === 'TEXT_WITH_AMOUNT_TYPE' ? list.fieldValue ? list.fieldValue.split('~')[0] : null : list.fieldValue;
                    let _strLogicToShow = list.showHide;
                    if (!_strLogicToShow) {
                        if (list.required && !_fieldValue) {
                            list.requiredLabel = 'Required field';
                            isUnAnswered = true;
                        }
                        if (list.required && _fieldValue && list.requiredLabel) {
                            list.requiredLabel = '';
                        }
                    } else {
                        let stringArray = _strLogicToShow.split('~');
                        let shouldShow = stringArray[0] === 'SHOWON';
                        let shouldHide = stringArray[0] === 'HIDEON';
                        let _reference = stringArray[1];
                        if (shouldShow) {
                            let _question = _.find(questions, (item) => {
                                return item.field === _reference;
                            });
                            if (_question.fieldValue) {
                                if (list.required && !_fieldValue) {
                                    list.requiredLabel = 'Required field';
                                    isUnAnswered = true;
                                }
                                if (list.required && _fieldValue && list.requiredLabel) {
                                    list.requiredLabel = '';
                                }
                            }
                        }
                        if (shouldHide) {
                            let _question = _.find(questions, (item) => {
                                return item.field === _reference;
                            });
                            if (!_question.fieldValue) {
                                if (list.required && !_fieldValue) {
                                    list.requiredLabel = 'Required field';
                                    isUnAnswered = true;
                                }
                                if (list.required && _fieldValue && list.requiredLabel) {
                                    list.requiredLabel = '';
                                }
                            }
                        }
                    }
                });
            }
            return isUnAnswered;
        }

        function compareValues(o1, o2) {
            var diff = {};
            var tmp = null;
            if (JSON.stringify(o1) === JSON.stringify(o2)) return;

            for (var k in o1) {
                if (Array.isArray(o1[k]) && Array.isArray(o2[k])) {
                    tmp = o1[k].reduce(function (p, c, i) {
                        var _t = compareValues(c, o2[k][i]);
                        if (!_.isEmpty(_t)) {
                            p.push(_t);
                        }
                        return p;
                    }, []);
                    if (Object.keys(tmp).length > 0) diff[k] = tmp;
                } else if (typeof o1[k] === 'object' && typeof o2[k] === 'object') {
                    tmp = compareValues(o1[k], o2[k]);
                    if (tmp && Object.keys(tmp) > 0) diff[k] = tmp;
                } else if (o1[k] !== o2[k]) {
                    diff[k] = o2[k];
                }
            }
            return diff;
        }

        function getFieldValuesFn(listValues, lang, fieldValue) {
            if (listValues && listValues.indexOf('~') >= 0 && fieldValue) {
                let _enListValues = listValues
                    .split('~')[0]
                    .split(',')
                    .map((item) => item.trim());
                let _swListValues = listValues
                    .split('~')[1]
                    .split(',')
                    .map((item) => item.trim());
                let _nbListValues = listValues
                    .split('~')[2]
                    .split(',')
                    .map((item) => item.trim());
                let _dkListValues = !!listValues.split('~')[3]
                    ? listValues
                        .split('~')[3]
                        .split(',')
                        .map((item) => item.trim())
                    : '';
                let _fiListValues = !!listValues.split('~')[4]
                    ? listValues
                        .split('~')[4]
                        .split(',')
                        .map((item) => item.trim())
                    : '';
                let _fieldValues = Array.isArray(fieldValue) ? fieldValue : fieldValue.split('~');
                let _indexes = [];
                for (let _i = 0; _i < _fieldValues.length; _i++) {
                    let _index = _enListValues.indexOf(_fieldValues[_i]);
                    if (_index < 0) {
                        _index = _swListValues.indexOf(_fieldValues[_i]);
                    }
                    if (_index < 0) {
                        _index = _nbListValues.indexOf(_fieldValues[_i]);
                    }
                    if (_index < 0) {
                        _index = _dkListValues.indexOf(_fieldValues[_i]);
                    }
                    if (_index < 0) {
                        _index = _fiListValues.indexOf(_fieldValues[_i]);
                    }
                    if (_index > -1) {
                        _indexes.push(_index);
                    }
                }
                if (_indexes.length > 0) {
                    let _result = [];
                    for (let _i = 0; _i < _indexes.length; _i++) {
                        if (lang === 'en_US') {
                            _result.push(_enListValues[_indexes[_i]]);
                        }
                        if (lang === 'sv_SV') {
                            _result.push(_swListValues[_indexes[_i]]);
                        }
                        if (lang === 'nb_NB') {
                            _result.push(_nbListValues[_indexes[_i]]);
                        }
                        if (lang === 'da_DK') {
                            _result.push(_dkListValues[_indexes[_i]]);
                        }
                        if (lang === 'fi_FI') {
                            _result.push(_fiListValues[_indexes[_i]]);
                        }
                    }
                    return _result.join('~');
                }
            } else {
                return fieldValue;
            }
        }

        return {
            checkRequired: function (_parts, callbackFn, isRequiredFieldUnanswered) {
                return checkRequiredSync(_parts, callbackFn, isRequiredFieldUnanswered);
            },
            isRequiredQuestionUnAnswered: function (questions) {
                return isRequiredQuestionUnAnswered(questions);
            },
            isEquals: function (originalPart, part) {
                return compareValues(originalPart, part);
            },
            requiredUnanswered: function (_parts) {
                return requiredUnanswered(_parts);
            },
            getFieldValue: function (listValues, lang, fieldValue) {
                return getFieldValuesFn(listValues, lang, fieldValue);
            },
        };
    },
]);

