'use strict';

angular.module('core.existingServices').factory('Activity', [
    '$resource',
    '$log',
    '$http',
    function ($resource, $log, $http) {
        var v1Uri = 'api/v1/activity';
        var v1Rest = $resource(
            v1Uri,
            {},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1EventRest = $resource(
            v1Uri + '/event/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1RequestRest = $resource(
            v1Uri + '/request/:requestId',
            {requestId: '@requestId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1OfferRest = $resource(
            v1Uri + '/offer/:offerId',
            {offerId: '@offerId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1SupplierRest = $resource(
            v1Uri + '/supplier/:supplierId',
            {supplierId: '@supplierId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1ChatMessageUri = 'api/v1/chatMessage';
        var v1ChatMessageRest = $resource(
            v1ChatMessageUri + '/:id',
            {id: '@id'},
            {
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: false},
                delete: {method: 'DELETE'},
            }
        );
        var v1ChatMessageMarkSeenRest = $resource(
            v1ChatMessageUri + '/markAsSeen/:id',
            {id: '@id'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );
        var v1PaginatedEventRest = $resource(
            v1Uri + '/paginated/event/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1PaginateRest = $resource(
            v1Uri + '/paginated',
            {},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1PaginatedOfferRest = $resource(
            v1Uri + '/paginated/offer/:offerId',
            {offerId: '@offerId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );

        var update = function (jsonObject, successCallback, errorCallback) {
            return v1ChatMessageRest.update({id: jsonObject.id}, jsonObject, successCallback, errorCallback);
        };

        var uploadFileRest = $resource(
            'api/v1/presentation/:slideId/updateProperty',
            {slideId: '@slideId'},
            {
                uploadFile: {method: 'POST', isArray: false},
            }
        );

        var rest = function (restUrl, formData, successCallback, errorCallback) {
            $http
                .post(restUrl, formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                })
        };

        var save = function (jsonObject, successCallback, errorCallback) {
            if (jsonObject.event && jsonObject.event.id) {
                jsonObject.eventId = jsonObject.event.id;
            }
            if (jsonObject.request && jsonObject.request.id) {
                jsonObject.requestId = jsonObject.request.id;
            }
            if (jsonObject.offer && jsonObject.offer.id) {
                jsonObject.offerId = jsonObject.offer.id;
            }
            var uri = v1ChatMessageUri + '/addMessage';
            var createFor = 'EVENT_USERS';
            var paramMap = {eventId: jsonObject.eventId};
            if (jsonObject.requestId) {
                createFor = 'REQUEST';
                paramMap.requestId = jsonObject.requestId;
            }

            if (
                jsonObject.offerId &&
                (angular.isNumber(jsonObject.offerId) || jsonObject.offerId.indexOf('user_') !== 0)
            ) {
                createFor = 'OFFER';
                paramMap.offerId = jsonObject.offerId;
            }

            var formData = new FormData();
            if (jsonObject.attachedFiles) {
                for (var i = 1; i <= jsonObject.attachedFiles.length; i++) {
                    formData.append('file' + i, jsonObject.attachedFiles[i - 1]);
                }
            }

            if (jsonObject.event && jsonObject.event.id) {
                jsonObject.eventId = jsonObject.event.id;
            }
            if (jsonObject.request && jsonObject.request.id) {
                jsonObject.requestId = jsonObject.request.id;
            }
            if (jsonObject.offer && jsonObject.offer.id) {
                jsonObject.offerId = jsonObject.offer.id;
            }

            formData.append('eventId', jsonObject.eventId);
            formData.append('requestId', jsonObject.requestId);
            formData.append('offerId', jsonObject.offerId);
            formData.append('messageBody', jsonObject.messageBody);
            formData.append('isTask', jsonObject.isTask);
            formData.append('dueDate', jsonObject.dueDate);
            formData.append('responsibles', JSON.stringify(jsonObject.responsibles));
            formData.append('createFor', createFor);
            if (createFor === 'EVENT_USERS') {
                //add reciepeint only if it is personal message
                formData.append('eventOwnerId', jsonObject.eventOwnerId);
            }
            formData.append('toAllEventCreators', jsonObject.toAllEventCreators);
            formData.append('isReplyMessage', jsonObject.isReplyMessage);
            formData.append('parentMessageId', jsonObject.parentMessageId);
            formData.append('privateMessage', jsonObject.privateMessage);
            formData.append('isSharedEventSummary', jsonObject.isSharedEventSummary);

            return rest(uri, formData, successCallback, errorCallback);
        };

        var saveEventSummaryMessage = function (jsonObject, successCallback, errorCallback) {
            var uri = v1ChatMessageUri + '/addEventSummaryMessage';

            var formData = new FormData();
            if (jsonObject.attachedFiles) {
                for (var i = 1; i <= jsonObject.attachedFiles.length; i++) {
                    formData.append('file' + i, jsonObject.attachedFiles[i - 1]);
                }
            }
            formData.append('eventId', jsonObject.eventId);
            formData.append('messageBody', jsonObject.messageBody);
            formData.append('isReplyMessage', jsonObject.isReplyMessage);
            formData.append('eventCreator', jsonObject.eventCreator);
            formData.append('parentMessageId', jsonObject.parentMessageId);
            formData.append('eventOwnerId', jsonObject.eventOwnerId);

            return rest(uri, formData, successCallback, errorCallback);
        };

        var offerActivityCountRest = $resource(
            v1Uri + '/offer/:offerId/activityCount',
            {offerId: '@offerId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var v1EventAllActivitiesRest = $resource(
            v1Uri + '/event/:eventId/allactivities',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var v1OfferAllActivitiesRest = $resource(
            v1Uri + '/offer/:offerId/allactivities',
            {offerId: '@offerId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var v1EventSummaryRest = $resource(
            v1Uri + '/event/eventSummaryMessages/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );

        return {
            list: function (jsonObject, successCallback, errorCallback) {
                var resource = v1Rest;
                if (jsonObject.eventId) {
                    resource = v1EventRest;
                }
                if (jsonObject.requestId) {
                    resource = v1RequestRest;
                }
                if (jsonObject.offerId) {
                    resource = v1OfferRest;
                }
                if (jsonObject.supplierId) {
                    resource = v1SupplierRest;
                }
                return resource.list(jsonObject, successCallback, errorCallback);
            },
            get: function (jsonObject, successCallback, errorCallback) {
                return v1ChatMessageRest.get(jsonObject, successCallback, errorCallback);
            },
            save: function (jsonObject, successCallback, errorCallback) {
                if (jsonObject.id) {
                    return update(jsonObject, successCallback, errorCallback);
                } else {
                    return save(jsonObject, successCallback, errorCallback);
                }
            },
            markAsRead: function (jsonObject, successCallback, errorCallback) {
                v1ChatMessageMarkSeenRest.update({id: jsonObject.id}, jsonObject, successCallback, errorCallback);
            },
            update: update,
            delete: function (jsonObject, successCallback, errorCallback) {
                return v1ChatMessageRest['delete'](jsonObject, successCallback, errorCallback);
            },
            getPaginatedList: function (jsonObject, successCallback, errorCallback) {
                var resource = v1PaginateRest;
                if (jsonObject.eventId) {
                    resource = v1PaginatedEventRest;
                }
                if (jsonObject.offerId) {
                    resource = v1PaginatedOfferRest;
                }
                return resource.list(jsonObject, successCallback, errorCallback);
            },
            // todo : remove this and use Presentation.uploadItem instead
            uploadItem: function (slideId, itemType, value, successCallback, errorCallback) {
                uploadFileRest.uploadFile(
                    {slideId: slideId},
                    {itemType: itemType, value: value},
                    successCallback,
                    errorCallback
                );
            },
            getOfferActivityCount: function (jsonObject, successCallback, errorCallback) {
                offerActivityCountRest.get(jsonObject, successCallback, errorCallback);
            },
            getAllActivities: function (jsonObject, successCallback, errorCallback) {
                var resource = v1Rest;
                if (jsonObject.eventId) {
                    resource = v1EventAllActivitiesRest;
                }
                if (jsonObject.offerId) {
                    resource = v1OfferAllActivitiesRest;
                }
                return resource.get(jsonObject, successCallback, errorCallback);
            },
            saveEventSummaryMessage: function (jsonObject, successCallback, errorCallback) {
                return saveEventSummaryMessage(jsonObject, successCallback, errorCallback);
            },
            listEventSummaryMessage: function (jsonObject, successCallback, errorCallback) {
                return v1EventSummaryRest.list(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

