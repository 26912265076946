'use strict';
import { OgModal } from "../../utils/global/ogModal";
import CommonFunctions from '../../utils/CommonFunctionUtil';

angular.module('dialog').directive('ogSuppliersMapModal', [
    '$rootScope',
    'Survey',
    function ($rootScope, Survey) {
        return new OgModal({
            name: 'suppliers:map',
            type: 'custom',
            content: 'map',
            controller: function ($scope) {
                $scope.initDialog = function (params) {
                    $scope.selectedSupplier = null;
                    $scope.supplierNonSearchParams = params.supplierNonSearchParams;
                    $scope.templates = params.templates;
                    $scope.templateSelected = params.templateSelected;
                    $scope.$broadcast('suppliers:has:been:pulled', params.suppliers);
                    CommonFunctions.setSameHeight('login-flex-height');
                };

                $scope.$on('supplier:selected', function (event, data) {
                    $scope.selectedSupplier = data.selectedSupplier;
                    Survey.forSupplier(
                        { id: data.selectedSupplier.id, withCommentsOnly: true },
                        function (result) {
                            $scope.surveys = result.surveys;
                        },
                        $scope.endWithErrorOverlay
                    );
                });

                $scope.$on('selected:template:changed', function () {
                    $scope.selectedSupplier = null;
                });
            },
        });
    },
]);

