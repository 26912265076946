'use strict';
import {OgModal} from "../../utils/global/ogModal";

angular.module('dialog').directive('ogRequestOfferPresentationModal', [
    '$compile',
    'Presentation',
    '$rootScope',
    '$timeout',
    'EventEnum',
    function ($compile, Presentation, $rootScope, $timeout, EventEnum) {
        function errorDialog(err) {
            var msg;
            if (err.data.localisedMessages) {
                msg = err.data.localisedMessages[0].message;
                } else {
                    msg = 'connection.error';
                }
                var errDialogSettings = {
                    message: msg,
                };

                $timeout(function () {
                    $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, errDialogSettings);
                }, 500);
            }

            return new OgModal({
                //ogRequestOfferPresentationModal
                name: 'request:offer:presentation',
                type: 'custom',
                controller: function ($scope) {
                    $scope.showDialog = false;
                    $scope.hideBackLink = true;

                    $scope.initDialog = function (data) {
                        $scope.supplierId = data.supplierId;
                        $scope.offerId = data.offerId;
                        $scope.presentationList = [];
                        $scope.selectedPresentation = null;
                        Presentation.getBySupplier(
                            data.supplierId,
                            function (response) {
                                angular.forEach(response, function (presentation) {
                                    if (!presentation.requestOffer || presentation.requestOffer.id != $scope.offerId) {
                                        $scope.presentationList.push(presentation);
                                    }
                                });
                                $scope.showDialog = true;
                            },
                            $rootScope.alertSimple
                        );
                    };

                    $scope.selectedPresentation = null;

                    $scope.selectPresentation = function (presentation) {
                        if (presentation) {
                            $scope.selectedPresentation = presentation;
                        }
                    };

                    $scope.clearPresentationDialog = function () {
                        $scope.showDialog = false;
                        $scope.selectedPresentation = null;
                        if (!$scope.$$phase && !$rootScope.$$phase) {
                            $scope.$apply();
                        }
                    };

                    $scope.updateOfferPresentation = function () {
                        $rootScope.$broadcast('requestoffer:presentation:dialog:update:offer');
                    };

                    $scope.choosePresentation = function () {
                        if ($scope.selectedPresentation) {
                            var data = {
                                requestOfferId: $scope.offerId,
                                presentationId: $scope.selectedPresentation.id,
                            };
                            Presentation.offerChoosePresentation(
                                data,
                                function () {
                                    $scope.updateOfferPresentation();
                                    $scope.clearPresentationDialog();
                                },
                                function (err) {
                                    $scope.clearPresentationDialog();
                                    errorDialog(err);
                                }
                            );
                        }
                    };

                    $scope.createNewPresentation = function () {
                        Presentation.offerChoosePresentation(
                            { requestOfferId: $scope.offerId },
                            function () {
                                $scope.updateOfferPresentation();
                                $scope.clearPresentationDialog();
                            },
                            function (err) {
                                $scope.clearPresentationDialog();
                                errorDialog(err);
                            }
                        );
                    };

                    $scope.filterPresentationList = function (item) {
                        if (item.requestOffer && item.requestOffer.id == $scope.offerId) {
                            return false;
                        }
                        return true;
                    };
                },
            });
        },
    ]);

