import {ScopeFunctions} from "../../../utils/global/scopeFunctions";

class emailSettingsCtrl {
    constructor($scope, $rootScope, UserGroup) {
        this._injected = {
            $scope,
            $rootScope,
            UserGroup,
        };
        ScopeFunctions.addToScope($scope);

        this.emailSettings = [];
        this.originalEmailSettings = [];
        this.selectedEmailSettings = [];
        this.allEmailSettings = false;
        this.showErrorSuccessMessages = false;
        this.form = 'emailSettingsForm';
        this.$onInit = function () {
            this.groupId = this.group.id;
            this.init();
        }
    }

    init() {
        const { $scope, UserGroup } = this._injected;
        $scope.startProgress('emailSettingsForm');
        UserGroup.getEmailSettings(
            { groupId: this.groupId },
            (response) => {
                this.emailSettings = response;

                response.forEach((setting) => {
                    if (setting.isEnabled) {
                        this.selectedEmailSettings.push(setting.id);
                        this.originalEmailSettings.push(setting.id);
                    }
                });
                this.allEmailSettings = this.isAllSelected();

                $scope.endProgress('emailSettingsForm');
            },
            $scope.endWithErrorOverlay('emailSettingsForm')
        );
    }

    enableUpdateButton() {
        return (
            this.originalEmailSettings.length === this.selectedEmailSettings.length &&
            this.originalEmailSettings.every((val) => this.selectedEmailSettings.includes(val))
        );
    }

    selectAll() {
        if (this.allEmailSettings === true) {
            this.emailSettings.forEach((setting) => {
                setting.isEnabled = true;
                if (!this.selectedEmailSettings.includes(setting.id)) {
                    this.selectedEmailSettings.push(setting.id);
                }
            });
        } else {
            this.emailSettings.forEach((setting) => {
                setting.isEnabled = false;
            });
            this.selectedEmailSettings = [];
        }
    }

    handleSelectReminder(setting) {
        if (setting.isEnabled && !this.selectedEmailSettings.includes(setting.id)) {
            this.selectedEmailSettings.push(setting.id);
        } else {
            this.selectedEmailSettings = this.selectedEmailSettings.filter((e) => e !== setting.id);
        }
        this.allEmailSettings = this.isAllSelected();
    }

    isAllSelected() {
        return this.emailSettings.length === this.selectedEmailSettings.length;
    }

    updateEmailSettings() {
        this.errorSuccessMessages = [];
        const { $scope, UserGroup } = this._injected;
        $scope.startProgress('emailSettingsForm');
        try {
            UserGroup.updateEmailSetting(
                {
                    groupId: this.groupId,
                    selectedIds: this.selectedEmailSettings,
                },
                (response) => {
                    let _successMsg = {};
                    _successMsg.messageKey = 'event.presentation.password.updated';
                    _successMsg.type = 'SUCCESS';
                    this.errorSuccessMessages.push(_successMsg);
                    this.showErrorSuccessMessages = true;
                    this.emailSettings = response;
                    this.selectedEmailSettings = [];
                    this.originalEmailSettings = [];
                    response.forEach((setting) => {
                        if (setting.isEnabled) {
                            this.selectedEmailSettings.push(setting.id);
                            this.originalEmailSettings.push(setting.id);
                        }
                    });
                    this.allEmailSettings = this.isAllSelected();
                    $scope.endProgress('emailSettingsForm');
                },
                $scope.endWithErrorOverlay('emailSettingsForm')
            );
        } catch (error) {}
    }
}

emailSettingsCtrl.$inject = ['$scope', '$rootScope', 'UserGroup'];
export default emailSettingsCtrl;
