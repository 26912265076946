import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

(function () {
    'use strict';

    angular.module('ogFooter').directive('ogFooter', [
        function () {
            return {
                templateUrl: './app/components/directives/footer/og.footer.view.html',
                restrict: 'EA',
                controller: [
                    '$scope',
                    'GlobalOption',
                    '$rootScope',
                    function ($scope, GlobalOption, $rootScope) {
                        ScopeFunctions.addToScope($scope);

                        $scope.loadFooterText = function () {
                            $scope.startProgress();
                            GlobalOption.get(
                                'FOOTER_CREDIT',
                                (resp) => {
                                    extract(resp);
                                    $scope.endProgress();
                                },
                                $scope.endWithError
                            );
                        };

                        function extract(footer) {
                            var footerHtml = footer[$rootScope.langSelected]
                                ? footer[$rootScope.langSelected]
                                : footer['sv_SV']
                                    ? footer['sv_SV']
                                    : '&copy; {{currentYear}} Event Logic All Rights Reserved.';
                            $scope.footerHtml = footerHtml.replace(/{{currentYear}}/, new Date().getFullYear());
                        }

                        $scope.loadFooterText();
                    },
                ],
                link: function (scope) {
                    scope.currentYear = new Date().getFullYear();
                },
            };
        },
    ]);
})();
