import EventDetailsData from '../../../core/existingServices/data_store/EventDetailsData';
import eventSummaryShareModal from '../../modals/modals2.0/event_summary_share';
import bookedContentConfirmationModal from '../../modals/modals2.0/booked_content_confirmation';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import { OgModal } from "../../../utils/global/ogModal";
import _ from 'underscore';
import { DownloadUtilFunctions } from '../../../utils/global/DownloadUtilFunction';

(function () {
    /* global angular */
    'use strict';
    const OFFER_VALIDITY_LIST = [5, 10, 15, 20, 30, 40, 50, 60, 70, 80];
    angular.module('ogEventSummary').directive('ogEventSummary', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/event_summary/og_event_summary.view.html',
            replace: true,
            scope: {
                event: '=',
                supplierLocations: '=',
                eventPresentationId: '=', //TODO check and remove eventPresentationId
                isPublic: '=',
                eventPresentationData: '=',
                eventCreator: '=',
                sharedPublicly: '=',
                decisionMaker: '=',
                sharedUserLists: '=',
                currentDecisionMaker: '=',
            },
            controllerAs: 'ogEventSummaryCtrl',
            bindToController: true,
            controller: [
                '$scope',
                '$rootScope',
                '$stateParams',
                '$location',
                '$log',
                '$sce',
                'AuthenticationService',
                'Event',
                'RequestOffer',
                'EventPresentation',
                'EventSummary',
                'BrowserDetectService',
                'EventEnum',
                'ClientSelectedContent',
                'DateService',
                'ModalService',
                'TimezoneService',
                'RequestOfferStatusUtils',
                function (
                    $scope,
                    $rootScope,
                    $stateParams,
                    $location,
                    $log,
                    $sce,
                    AuthenticationService,
                    Event,
                    RequestOffer,
                    EventPresentation,
                    EventSummary,
                    BrowserDetectService,
                    EventEnum,
                    ClientSelectedContent,
                    DateService,
                    ModalService,
                    TimezoneService,
                    RequestOfferStatusUtils
                ) {
                    var vm = this;
                    var eventId = 0;
                    vm.viewModal = false;

                    this.$onInit = function () {
                        vm = this;
                        vm.OFFER_VALIDITY_LIST = OFFER_VALIDITY_LIST;
                        vm.edit = editFn;
                        vm.init = initFn;
                        vm.openMap = openMapFn;
                        vm.saveDecisionMaker = saveDecisionMakerFn;
                        vm.switchLanguage = switchLanguageFn;
                        vm.startPrint = BrowserDetectService.getPrintSummary;
                        vm.share = shareFn;
                        vm.sharePublicly = sharePubliclyFn;
                        vm.openSchedule = openScheduleFn;
                        vm.openBudget = openBudgetFn;
                        vm.shareEventSummary = shareEventSummaryFn;
                        vm.sendContentBookedConfirmation = sendContentBookedConfirmationFn;
                        vm.getConfDate = getConfDateFn;
                        vm.toggleEditMode = toggleEditModeFn;
                        vm.uploadInformation = uploadInformationFn;
                        vm.trustAsHtml = trustAsHtmlFn;
                        vm.cancelInformationEdit = cancelInformationEditFn;
                        vm.calculateExpirationDate = calculateExpirationDateFn;
                        vm.parseDateInLocaleFormatWithoutTime = parseDateInLocaleFormatWithoutTimeFn;
                        vm.tabChanged = tabChangedFn;
                        vm.getBookingTerms = getBookingTermsFn;
                        vm.seeBookingTerms = seeBookingTermsFn;
                        vm.editBookingTerms = editBookingTermsFn;
                        vm.proceedBookingTermUpdate = proceedBookingTermUpdateFn;
                        vm.cancelBooking = cancelBookingFn;
                        vm.updateBookingTerms = updateBookingTermsFn;
                        vm.sendAcceptedOffer = sendAcceptedOfferFn;
                        vm.exportDecisionMakerHistoryToPDF = exportDecisionMakerHistoryToPDFFn;
                        vm.showMessageBodyForRecipients = false;
                        vm.showDecisionMakerBody = false;
                        vm.isSummary = true;
                        vm.isDecisionMaker = vm.decisionMaker;
                        vm.loadingComplete = false;
                        vm.scheduleOpened = false;
                        vm.editBookingTerm = false;
                        vm.proceedBookingTerm = false;
                        vm.getDateSpan = DateService.getDateSpan;
                        vm.enableAgent = vm.event && vm.event.ownerGroup && vm.event.ownerGroup.showMarkup;
                        vm.decisionMakerHistoryList = [];
                        eventId = vm.event.id
                        ScopeFunctions.addToScope($scope);
                        vm.getBookingTerms();

                        vm.init();
                    };


                    function initFn() {
                        vm.types = ['CREATOR_PRESENTATION', 'PARTICIPANTS_PRESENTATION'];
                        vm.recipientLang = 'sv_SV';
                        vm._decisionMaker = vm.currentDecisionMaker;
                        if (vm.enableAgent) {
                            if (vm.event.acceptedDate) {
                                if (vm.isPublic) {
                                    $scope.startProgress();
                                    ClientSelectedContent.getAllSelectedContentForEvent(
                                        vm.event.id,
                                        (response) => {
                                            vm.decisionLastUpdated = response.lastUpdated;
                                            if (
                                                response &&
                                                response.currentOffer &&
                                                response.currentOffer.requests &&
                                                response.currentOffer.requests.length > 0
                                            ) {
                                                vm.event.requests = response.currentOffer.requests;
                                                vm.event.participantAmount =
                                                    response.currentOffer.event.participantAmount;
                                                vm.event.fromDate = response.currentOffer.event.fromDate;
                                                vm.event.toDate = response.currentOffer.event.toDate;
                                                if (vm.decisionMaker) {
                                                    angular.forEach(vm.event.requests, (request) => {
                                                        let _request = _.find(vm.event.requests, (r) => {
                                                            return r.id === request.id;
                                                        });
                                                        if (_request) {
                                                            angular.forEach(request.requestOffers, (requestOffer) => {
                                                                let _offer = _.find(_request.requestOffers, (ro) => {
                                                                    return ro.id === requestOffer.id;
                                                                });
                                                                if (_offer) {
                                                                    let _offerParts = requestOffer.offerParts;
                                                                    angular.forEach(_offerParts, (rop) => {
                                                                        let _offerPart = _.find(
                                                                            _request.offerParts,
                                                                            (op) => {
                                                                                return (
                                                                                    op.referenceId === rop.referenceId
                                                                                );
                                                                            }
                                                                        );
                                                                        if (
                                                                            _offerPart &&
                                                                            _offerPart.hasRequestOfferPartOptions
                                                                        ) {
                                                                            let _offer = _.find(
                                                                                _offerPart.offerWiseData,
                                                                                (owd) => {
                                                                                    return (
                                                                                        owd.offer.id === requestOffer.id
                                                                                    );
                                                                                }
                                                                            );
                                                                            if (_offer) {
                                                                                angular.forEach(
                                                                                    rop.offerPartOptions,
                                                                                    (opo) => {
                                                                                        let _offerPartOption = _.find(
                                                                                            _offer.offerPartOptions,
                                                                                            (option) => {
                                                                                                return (
                                                                                                    opo.id === option.id
                                                                                                );
                                                                                            }
                                                                                        );
                                                                                        if (_offerPartOption) {
                                                                                            opo.isSelected =
                                                                                                _offerPartOption.isSelected;
                                                                                        }
                                                                                    }
                                                                                );
                                                                            }
                                                                        }
                                                                    });
                                                                }
                                                            });
                                                        }
                                                    });
                                                }
                                            }
                                            vm.loadingComplete = true;
                                            if (vm.decisionMaker) {
                                                ClientSelectedContent.getDecisionMakerHistory(
                                                    vm.event.id,
                                                    (response) => {
                                                        vm.decisionMakerHistoryList = response;
                                                    },
                                                    $scope.endWithError()
                                                );
                                            }
                                            $scope.endProgress();
                                        },
                                        $scope.endWithAlert
                                    );
                                } else {
                                    vm.loadingComplete = true;
                                }
                            } else {
                                $scope.startProgress();
                                ClientSelectedContent.getAllSelectedContentForEvent(
                                    vm.event.id,
                                    (response) => {
                                        vm.decisionLastUpdated = response.lastUpdated;
                                        if (
                                            response &&
                                            response.currentOffer &&
                                            response.currentOffer.requests &&
                                            response.currentOffer.requests.length > 0
                                        ) {
                                            angular.forEach(vm.event.requests, (request) => {
                                                let _request = _.find(response.currentOffer.requests, (r) => {
                                                    return r.id === request.id;
                                                });
                                                if (_request) {
                                                    request.selectedOfferId = _request.selectedOfferId;
                                                    angular.forEach(request.offerParts, (offerPart) => {
                                                        let _offerPart = _.find(_request.offerParts, (op) => {
                                                            return op.referenceId === offerPart.referenceId;
                                                        });
                                                        if (_offerPart) {
                                                            offerPart.selected = _offerPart.selected;
                                                            if (_offerPart.hasRequestOfferPartOptions) {
                                                                let _offer = _.find(_offerPart.offerWiseData, (owd) => {
                                                                    return owd.offer.id === request.selectedOfferId;
                                                                });
                                                                if (_offer) {
                                                                    offerPart.offerPartOptions =
                                                                        _offer.offerPartOptions;
                                                                }
                                                            }
                                                        }
                                                    });
                                                    angular.forEach(request.requestOffers, (requestOffer) => {
                                                        let _offer = _.find(_request.requestOffers, (ro) => {
                                                            return ro.id === requestOffer.id;
                                                        });
                                                        if (_offer) {
                                                            let _offerParts = requestOffer.offerParts;
                                                            angular.forEach(_offerParts, (rop) => {
                                                                let _offerPart = _.find(_request.offerParts, (op) => {
                                                                    return op.referenceId === rop.referenceId;
                                                                });
                                                                if (
                                                                    _offerPart &&
                                                                    _offerPart.hasRequestOfferPartOptions
                                                                ) {
                                                                    let _offer = _.find(
                                                                        _offerPart.offerWiseData,
                                                                        (owd) => {
                                                                            return owd.offer.id === requestOffer.id;
                                                                        }
                                                                    );
                                                                    if (_offer) {
                                                                        angular.forEach(rop.offerPartOptions, (opo) => {
                                                                            let _offerPartOption = _.find(
                                                                                _offer.offerPartOptions,
                                                                                (option) => {
                                                                                    return opo.id === option.id;
                                                                                }
                                                                            );
                                                                            if (_offerPartOption) {
                                                                                opo.isSelected =
                                                                                    _offerPartOption.isSelected;
                                                                            }
                                                                        });
                                                                    }
                                                                }
                                                            });
                                                        }
                                                    });
                                                }
                                            });
                                        }
                                        vm.loadingComplete = true;
                                        if (vm.decisionMaker) {
                                            ClientSelectedContent.getDecisionMakerHistory(
                                                vm.event.id,
                                                (response) => {
                                                    vm.decisionMakerHistoryList = response;
                                                },
                                                $scope.endWithError()
                                            );
                                        }
                                        $scope.endProgress();
                                    },
                                    $scope.endWithAlert
                                );
                            }
                        } else {
                            if (vm.decisionMaker) {
                                if (vm.event.acceptedDate) {
                                    $scope.startProgress();
                                    ClientSelectedContent.getAllSelectedContentForEvent(
                                        vm.event.id,
                                        (response) => {
                                            vm.decisionLastUpdated = response.lastUpdated;
                                            if (
                                                response &&
                                                response.currentOffer &&
                                                response.currentOffer.requests &&
                                                response.currentOffer.requests.length > 0
                                            ) {
                                                angular.forEach(vm.event.requests, (request) => {
                                                    let _request = _.find(response.currentOffer.requests, (r) => {
                                                        return r.id === request.id;
                                                    });
                                                    if (_request) {
                                                        request.selectedOfferId = _request.selectedOfferId;
                                                        angular.forEach(request.offerParts, (offerPart) => {
                                                            let _offerPart = _.find(_request.offerParts, (op) => {
                                                                return op.referenceId === offerPart.referenceId;
                                                            });
                                                            if (_offerPart) {
                                                                offerPart.selected = _offerPart.selected;
                                                                if (_offerPart.hasRequestOfferPartOptions) {
                                                                    let _offer = _.find(
                                                                        _offerPart.offerWiseData,
                                                                        (owd) => {
                                                                            return (
                                                                                owd.offer.id === request.selectedOfferId
                                                                            );
                                                                        }
                                                                    );
                                                                    if (_offer) {
                                                                        offerPart.offerPartOptions =
                                                                            _offer.offerPartOptions;
                                                                    }
                                                                }
                                                            }
                                                        });
                                                        angular.forEach(request.requestOffers, (requestOffer) => {
                                                            let _offer = _.find(_request.requestOffers, (ro) => {
                                                                return ro.id === requestOffer.id;
                                                            });
                                                            if (_offer) {
                                                                let _offerParts = requestOffer.offerParts;
                                                                angular.forEach(_offerParts, (rop) => {
                                                                    let _offerPart = _.find(
                                                                        _request.offerParts,
                                                                        (op) => {
                                                                            return op.referenceId === rop.referenceId;
                                                                        }
                                                                    );
                                                                    if (
                                                                        _offerPart &&
                                                                        _offerPart.hasRequestOfferPartOptions
                                                                    ) {
                                                                        let _offer = _.find(
                                                                            _offerPart.offerWiseData,
                                                                            (owd) => {
                                                                                return owd.offer.id === requestOffer.id;
                                                                            }
                                                                        );
                                                                        if (_offer) {
                                                                            angular.forEach(
                                                                                rop.offerPartOptions,
                                                                                (opo) => {
                                                                                    let _offerPartOption = _.find(
                                                                                        _offer.offerPartOptions,
                                                                                        (option) => {
                                                                                            return opo.id === option.id;
                                                                                        }
                                                                                    );
                                                                                    if (_offerPartOption) {
                                                                                        opo.isSelected =
                                                                                            _offerPartOption.isSelected;
                                                                                    }
                                                                                }
                                                                            );
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                            vm.loadingComplete = true;
                                            $scope.endProgress();
                                        },
                                        $scope.endWithAlert
                                    );
                                } else {
                                    vm.loadingComplete = true;
                                }
                            } else {
                                vm.loadingComplete = true;
                            }
                        }
                        vm.isAnyRequestBookedInEvent = isAnyRequestBookedInEventFn(vm.event.requests);
                        vm.isThereAnyRequestWithOffer = isThereAnyRequestWithOfferFn(vm.event.requests);
                        EventDetailsData.setEventDetails(vm.event);
                        if (vm.currentDecisionMaker && vm.currentDecisionMaker.email) {
                            vm.newDecisionMaker = vm.currentDecisionMaker.email;
                        }
                        if (vm.event.acceptedDate) {
                            vm.acceptOffer = true;
                        }
                    }

                    function isThereAnyRequestWithOfferFn(requests) {
                        let _isThereAnyRequestWithOffer = false;
                        angular.forEach(requests, (request) => {
                            if (
                                !_isThereAnyRequestWithOffer &&
                                request &&
                                isAnyRequestOfferAnswered(request.requestOffers)
                            ) {
                                _isThereAnyRequestWithOffer = true;
                            }
                        });
                        return _isThereAnyRequestWithOffer;
                    }

                    function isAnyRequestOfferAnswered(offers) {
                        let requestHasInternalOffer = offers.some((offer) => offer.statusDetailed.name === 'INTERNAL');
                        let requestHasOfferAnswered = offers.some((offer) => offer.alreadyAnswered === true);
                        return requestHasInternalOffer || requestHasOfferAnswered;
                    }

                    function isAnyRequestBookedInEventFn(requests) {
                        let _isAnyRequestBooked = false;
                        angular.forEach(requests, (request) => {
                            if (!_isAnyRequestBooked && request && isAnyRequestOfferBooked(request.requestOffers)) {
                                _isAnyRequestBooked = true;
                            }
                        });
                        return _isAnyRequestBooked;
                    }

                    function isAnyRequestOfferBooked(offers) {
                        let requestHasInternalOffer = offers.some((offer) => offer.statusDetailed.name === 'INTERNAL');
                        let requestHasOfferInBookedStates = offers.some(
                            (offer) => RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(offer.status.name) > -1
                        );
                        return requestHasInternalOffer || requestHasOfferInBookedStates;
                    }

                    function editFn() {
                        $location.url('/event/' + eventId);
                    }

                    function openScheduleFn() {
                        $scope.startProgress();
                        EventSummary.schedule(
                            { eventId: eventId },
                            (response) => {
                                vm.eventSchedule = response.eventSchedule;
                                vm.scheduleOpened = true;
                                tabChangedFn();
                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay
                        );
                    }

                    function openBudgetFn() {
                        vm.scheduleOpened = false;
                    }

                    function openMapFn() {
                        if (vm.supplierLocations.length === 1) {
                            if (vm.supplierLocations[0].mapUrl) {
                                OgModal.open('map', { mapUrl: vm.supplierLocations[0].mapUrl });
                            } else if (vm.supplierLocations[0].latitudeAndLongitude) {
                                OgModal.open('map', { latLng: vm.supplierLocations[0].latitudeAndLongitude });
                            }
                        } else if (vm.supplierLocations.length > 1) {
                            OgModal.open('map', { mapCanvas: vm.supplierLocations });
                        }
                    }

                    function shareFn() {
                        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                            return;
                        }
                        $scope.startProgress();
                        EventSummary.shareByEmail(
                            {
                                email: vm.email,
                                id: eventId,
                                decisionMaker: vm.isDecisionMaker,
                                recipientLang: vm.recipientLang,
                                form: 'eventPresentationForm',
                                message: vm.messageBody,
                            },
                            function (response) {
                                $scope.endProgress();
                                $rootScope.$broadcast('dialog:alert:open', {
                                    message: 'event.presentation.invitation.successfully.send',
                                });
                                vm.sharedUserLists = response.sharedLists;
                                vm._decisionMaker = response.currentDecisionMaker;
                                vm.email = '';
                                vm.isDecisionMaker = false;
                                vm.shareBudgetForm.$setPristine();
                                vm.shareBudgetForm.$setUntouched();
                            },
                            $scope.endWithError
                        );
                    }

                    function saveDecisionMakerFn() {
                        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                            return;
                        }
                        $scope.startProgress();
                        EventSummary.saveDecisionMaker(
                            {
                                email: vm.newDecisionMaker,
                                id: eventId,
                                decisionMaker: true,
                            },
                            function (response) {
                                $scope.endProgress();
                                $rootScope.$broadcast('dialog:alert:open', {
                                    message: 'event.presentation.decision.maker.successfully.updated',
                                });
                                vm._decisionMaker = response[0];
                                vm.changeDecisionMaker = false;
                            },
                            $scope.endWithError
                        );
                    }

                    function switchLanguageFn(lang) {
                        vm.recipientLang = lang;
                    }

                    function sharePubliclyFn() {
                        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                            return;
                        }
                        OgModal.open('event:site:share', { eventId: eventId, type: 'EVENT_SUMMARY' });
                    }

                    function shareEventSummaryFn() {
                        if (!AuthenticationService.checkIfUserVerifiedAndAlert() || vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        let data = {
                            eventId: eventId,
                            acceptedDate: vm.event.acceptedDate,
                            sharedUserLists: vm.sharedUserLists,
                            currentDecisionMaker: vm.currentDecisionMaker,
                            type: 'EVENT_SUMMARY',
                        };
                        let eventSummaryShareModalData = eventSummaryShareModal;
                        eventSummaryShareModalData.inputs = { data };
                        ModalService.showModal(eventSummaryShareModalData).then((modal) => {
                            vm.viewModal = false;
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                    }

                    function sendContentBookedConfirmationFn() {
                        if (!AuthenticationService.checkIfUserVerifiedAndAlert() || vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        let _agentName = AuthenticationService.getUserData().name;
                        let data = {
                            eventId: eventId,
                            eventName: vm.event.name,
                            agentName: _agentName,
                            okCallBack: function (confirmationDate) {
                                if (confirmationDate) {
                                    vm.getConfDate(confirmationDate);
                                }
                            }.bind(this),
                        };
                        let bookedContentConfirmationModalData = bookedContentConfirmationModal;
                        bookedContentConfirmationModalData.inputs = { data };
                        ModalService.showModal(bookedContentConfirmationModalData).then((modal) => {
                            vm.viewModal = false;
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                    }

                    function getConfDateFn(confirmationDate) {
                        vm.event.confirmationDate = confirmationDate;
                        return TimezoneService.formatDate(
                            new Date(parseInt(confirmationDate)),
                            DateService.getDateTimeFormatByLocale()
                        );
                    }

                    function toggleEditModeFn() {
                        if (!vm.isPublic) {
                            vm.editInformation = !vm.editInformation;
                        }
                    }

                    function exportDecisionMakerHistoryToPDFFn(decisionMakerHash) {
                        let _downloadUrl =
                            '/api/v1/clientSelectedContent/' +
                            eventId +
                            '/' +
                            decisionMakerHash +
                            '/exportDecisionMakerHistory';
                        DownloadUtilFunctions.downloadFile(_downloadUrl);
                    }

                    function uploadInformationFn() {
                        $scope.startProgress();
                        Event.uploadInformation(
                            { eventId: vm.event.id, information: vm.event.additionalInformation },
                            (response) => {
                                vm.event.additionalInformation = response.information;
                                vm.editInformation = !vm.editInformation;
                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay()
                        );
                    }

                    function trustAsHtmlFn(string) {
                        return $sce.trustAsHtml(string);
                    }

                    function calculateExpirationDateFn(reservationCancelDays) {
                        if (reservationCancelDays === 0) {
                            return;
                        }
                        vm.event.expirationDate = new Date().getTime() + reservationCancelDays * 24 * 60 * 60 * 1000;
                        $scope.startProgress();
                        Event.updateReservationCancelDays(
                            { eventId: vm.event.id, reservationCancelDays: reservationCancelDays },
                            (response) => {
                                vm.event.reservationCancelDays = response.reservationCancelDays;
                                vm.event.expirationDate = response.expirationDate;
                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay()
                        );
                    }

                    function parseDateInLocaleFormatWithoutTimeFn(date) {
                        return TimezoneService.formatDate(
                            TimezoneService.toLocalTime(date),
                            DateService.getDateFormatByLocale()
                        );
                    }

                    function cancelInformationEditFn() {
                        vm.editInformation = false;
                    }

                    function tabChangedFn() {
                        $rootScope.$broadcast(EventEnum.TAB_CHANGED_IN_EVENT_SUMMARY, {});
                    }

                    function getBookingTermsFn() {
                        $scope.startProgress();
                        Event.getBookingTerms(
                            vm.event.id,
                            (resp) => {
                                vm.bookingTerms = resp.bookingTerms;
                                $scope.endProgress();
                            },
                            $scope.endWithError
                        );
                    }

                    function seeBookingTermsFn() {
                        $rootScope.$broadcast('dialog:alert:open', {
                            message: vm.bookingTerms,
                        });
                    }

                    function editBookingTermsFn() {
                        vm.editBookingTerm = true;
                    }

                    function proceedBookingTermUpdateFn() {
                        vm.editBookingTerm = false;
                        vm.proceedBookingTerm = true;
                    }

                    function cancelBookingFn() {
                        vm.editBookingTerm = false;
                        vm.proceedBookingTerm = false;
                    }

                    function updateBookingTermsFn(proceedForFuture) {
                        vm.editBookingTerm = false;
                        vm.proceedBookingTerm = false;
                        $scope.startProgress();
                        Event.updateBookingTerms(
                            {
                                eventId: vm.event.id,
                                bookingTerms: vm.bookingTerms,
                                proceedForFuture: proceedForFuture,
                            },
                            (response) => {
                                vm.bookingTerms = response.bookingTerms;
                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay()
                        );
                    }

                    function sendAcceptedOfferFn() {
                        $scope.startProgress();
                        ClientSelectedContent.save(
                            vm.event.requests,
                            (response) => {
                                vm.selectedContentList = response;
                                vm.event.acceptedDate = new Date();
                                vm.acceptOffer = true;
                                if (vm.enableAgent) {
                                    ClientSelectedContent.getDecisionMakerHistory(
                                        vm.event.id,
                                        (response) => {
                                            vm.decisionMakerHistoryList = response;
                                        },
                                        $scope.endWithError()
                                    );
                                }
                                $rootScope.$broadcast('dialog:alert:open', {
                                    message: 'agent.enabled.successfully.booked.by.client',
                                });
                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay()
                        );
                    }

                    $rootScope.setShowDiffColumn = function (yesNo) {
                        if (!vm.showBudgetDiffColumn) {
                            vm.showBudgetDiffColumn = yesNo;
                        }
                    };

                    $scope.$on('event:update:event', function (event, data) {
                        vm.event = data;
                    });

                    var sharedUsersListChanged = $rootScope.$on(
                        EventEnum.SHARED_USERS_LIST_CHANGED,
                        function (event, data) {
                            if (data) {
                                vm.sharedUserLists = data;
                            }
                        }.bind(this)
                    );

                    var currentDecisionMakerChanged = $rootScope.$on(
                        EventEnum.CURRENT_DECISION_MAKER_CHANGED,
                        function (event, data) {
                            if (data) {
                                vm.currentDecisionMaker = data;
                            }
                        }.bind(this)
                    );

                    $scope.$on('$destroy', sharedUsersListChanged);
                    $scope.$on('$destroy', currentDecisionMakerChanged);

                    var reopenOfferToDecisionMaker = $rootScope.$on(
                        EventEnum.REOPEN_OFFER_TO_DECISION_MAKER,
                        function (event, data) {
                            vm.event.acceptedDate = data;
                        }.bind(this)
                    );

                    $scope.$on('$destroy', reopenOfferToDecisionMaker);
                },
            ],
        };
    });
})();
