export default function (appModule) {
    appModule.config([
        '$stateProvider',
        '$locationProvider',
        '$urlRouterProvider',
        function ($stateProvider, $locationProvider, $urlRouterProvider) {
            $urlRouterProvider.when('/eventPresentationRo/:hash/participate/:participate', [
                '$match',
                '$location',
                function ($match, $location) {
                    var path = '/eventPresentationRo/' + $match.hash + '?participate=' + $match.participate;
                    $location.url(path);
                },
            ]);

            //Overridden to support older URLs before angular 1.5 refactoring
            /*Overridden Start*/
            $urlRouterProvider.when('/eventPresentationRo/:hash', '/shared/eventPresentationRo/:hash');
            /*Overridden Ends*/

            $stateProvider
                .state('shared', {
                    url: '/shared',
                    abstract: true, // has children view
                    template: '<ui-view>',
                })

                .state('shared.eventPresentationnew', {
                    url: '/presentationnew/:urlSlug',
                    template: '<event-presentation></event-presentation>'
                })
                .state('shared.presentation', {
                    url: '/event_presentation/:urlSlug',
                    template: '<event-presentation></event-presentation>'
                })
                .state('shared.eventSummary', {
                    url: '/event_summary/:urlSlug',
                    template: '<shared-event-summary></shared-event-summary>'
                })
                .state('shared.presentation.registration', {
                    template: '<shared-public-registration></shared-public-registration>',
                })
                .state('shared.eventCreate', {
                    url: '/create_event?locale',
                    template: '<shared-create-event></shared-create-event>'
                })
                .state('shared.eventCreateExpressBooking', {
                    url: '/create_event/expressBooking?redirectUrl&locale',
                    template: '<shared-create-event-express-booking></shared-create-event-express-booking>'
                })
                .state('shared.landing', {
                    url: '/landing?lang&&targetUrl&&eventName',
                    template: '<shared-public-landing></shared-public-landing>'
                })
                .state('shared.eventPresentation', {
                    url: '/presentation/:urlSlug',
                    template: '<event-presentation></event-presentation>'
                });
        },
    ]);
}