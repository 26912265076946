/**
 * Created by kshitij on 10/30/17.
 */

'use strict';
import _ from 'underscore';
import {UtilFunctions} from '../../utils/global/utilFunctions'

angular.module('core.existingServices').factory('ParticipantService', [
    '$rootScope',
    function ($rootScope) {
        return {
            showQuestion: function (question, listOfQuestions, lang) {
                let _strLogicToShow = question.showHideLogic;
                if (!_strLogicToShow) {
                    return true;
                }
                let stringArray = _strLogicToShow.split('~');
                let shouldShow = stringArray[0] === 'SHOWON';
                let shouldHide = stringArray[0] === 'HIDEON';
                let _reference = stringArray[1];
                let prefLang = stringArray[3];
                let _valueShouldBe, _refObj;

                _refObj = _.find(listOfQuestions, (g) => {
                    return g.detailNameEnglish === _reference;
                });
                if (!_refObj) {
                    _refObj = _.find(listOfQuestions, (g) => {
                        return g.detailNameSwedish === _reference;
                    });
                }
                if (!_refObj) {
                    _refObj = _.find(listOfQuestions, (g) => {
                        return g.detailNameNorwegian === _reference;
                    });
                }
                if (!_refObj) {
                    _refObj = _.find(listOfQuestions, (g) => {
                        return g.detailNameDanish === _reference;
                    });
                }
                if (!_refObj) {
                    _refObj = _.find(listOfQuestions, (g) => {
                        return g.detailNameFinnish === _reference;
                    });
                }

                if (_refObj.type.name === 'DROPDOWN') {
                    if (lang === 'en_US') {
                        _valueShouldBe = UtilFunctions.normalizeToUpperCase(stringArray[2].split('^')[0]);
                    } else if (lang === 'sv_SV') {
                        _valueShouldBe = UtilFunctions.normalizeToUpperCase(stringArray[2].split('^')[1]);
                    } else if (lang === 'nb_NB') {
                        _valueShouldBe = UtilFunctions.normalizeToUpperCase(stringArray[2].split('^')[2]);
                    } else if (lang === 'da_DK') {
                        _valueShouldBe = UtilFunctions.normalizeToUpperCase(stringArray[2].split('^')[3]);
                    } else {
                        _valueShouldBe = UtilFunctions.normalizeToUpperCase(stringArray[2].split('^')[4]);
                    }
                } else if (_refObj.type.name === 'MULTISELECT') {
                    _valueShouldBe = stringArray[2].split('^');
                    if (shouldShow) {
                        return _.isEqual(_.sortBy(_valueShouldBe), _.sortBy(_refObj.fieldValue));
                    }
                    if (shouldHide) {
                        return !_.isEqual(_.sortBy(_valueShouldBe), _.sortBy(_refObj.fieldValue));
                    }
                } else {
                    _valueShouldBe = UtilFunctions.normalizeToUpperCase(stringArray[2]);
                }

                if (shouldShow) {
                    let _value = _refObj.fieldValue;
                    _value = _value ? UtilFunctions.normalizeToUpperCase(_value) : null;
                    if (_refObj.showHideLogic) {
                        return _value === _valueShouldBe && this.showQuestion(_refObj, listOfQuestions, lang);
                    }
                    return _value === _valueShouldBe;
                }

                if (shouldHide) {
                    let _value = _refObj.fieldValue;
                    _value = _value ? UtilFunctions.normalizeToUpperCase(_value) : null;
                    if (_refObj.showHideLogic) {
                        return _value !== _valueShouldBe || this.showQuestion(_refObj, listOfQuestions, lang);
                    }
                    return _value !== _valueShouldBe;
                }

                return true;
            },
            getQuestionLabel: function (question, langSelected) {
                let _eng = question.detailNameEnglish;
                let _sv = question.detailNameSwedish;
                let _nb = question.detailNameNorwegian;
                let _da = question.detailNameDanish;
                let _fi = question.detailNameFinnish;
                if (langSelected === 'en_US') {
                    return _eng ? _eng : _sv ? _sv : _nb ? _nb : _da ? _da : _fi;
                } else if (langSelected === 'sv_SV') {
                    return _sv ? _sv : _eng ? _eng : _nb ? _nb : _da ? _da : _fi;
                } else if (langSelected === 'nb_NB') {
                    return _nb ? _nb : _eng ? _eng : _sv ? _sv : _da ? _da : _fi;
                } else if (langSelected === 'da_DK') {
                    return _da ? _da : _eng ? _eng : _sv ? _sv : _nb ? _nb : _fi;
                } else {
                    return _fi ? _fi : _eng ? _eng : _sv ? _sv : _nb ? _nb : _da;
                }
            },
        };
    },
]);

