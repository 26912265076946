'use strict';

angular.module('core.existingServices').factory('EventSummary', [
    '$resource',
    '$http',
    function ($resource, $http) {
        var eventSummaryForCreatorRest = $resource(
            'api/v1/event/summary/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var eventScheduleRest = $resource(
            'api/v1/event/summary/public/schedule/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var eventSummaryShareRest = $resource(
            'api/v1/event/summary/:id/share',
            {id: '@id'},
            {
                shareByEmail: {method: 'POST', isArray: false},
            }
        );

        var eventSummarySaveDecisionMakerRest = $resource(
            'api/v1/event/summary/:id/saveDecisionMaker',
            {id: '@id'},
            {
                saveDecisionMaker: {method: 'POST', isArray: false},
            }
        );

        var eventSummaryRemoveUser = $resource(
            'api/v1/event/summary/:id/removeUser',
            {id: '@id'},
            {
                removeUser: {method: 'POST', isArray: false},
            }
        );

        var eventSummaryPageBySlug = $resource(
            'api/v1/event/summary/public/:urlSlug',
            {urlSlug: '@urlSlug'},
            {
                getData: {method: 'GET', isArray: false},
            }
        );

        var eventSummaryReopenOffer = $resource(
            'api/v1/event/summary/:id/reopenOffer',
            {id: '@id'},
            {
                reopenOffer: {method: 'POST', isArray: false},
            }
        );

        var rest = function (restUrl, formData, successCallback, errorCallback) {
            $http
                .post(restUrl, formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };

        var sendEventSummaryConfirmation = function (jsonObject, successCallback, errorCallback) {
            var uri = 'api/v1/event/summary/sendConfirmation';

            var formData = new FormData();
            if (jsonObject.attachedFiles) {
                for (var i = 1; i <= jsonObject.attachedFiles.length; i++) {
                    formData.append('file' + i, jsonObject.attachedFiles[i - 1]);
                }
            }
            formData.append('eventId', jsonObject.eventId);
            formData.append('sendToMyself', jsonObject.sendToMyself);
            formData.append('message', jsonObject.message);

            return rest(uri, formData, successCallback, errorCallback);
        };

        return {
            eventSummaryForCreatorRest: function (jsonObject, successCallback, errorCallback) {
                return eventSummaryForCreatorRest.get(jsonObject, successCallback, errorCallback);
            },
            schedule: function (jsonObject, successCallback, errorCallback) {
                return eventScheduleRest.get(jsonObject, successCallback, errorCallback);
            },
            shareByEmail: function (jsonObject, successCallback, errorCallback) {
                return eventSummaryShareRest.shareByEmail({}, jsonObject, successCallback, errorCallback);
            },
            saveDecisionMaker: function (jsonObject, successCallback, errorCallback) {
                return eventSummarySaveDecisionMakerRest.saveDecisionMaker(
                    {},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            removeUser: function (jsonObject, successCallback, errorCallback) {
                return eventSummaryRemoveUser.removeUser({}, jsonObject, successCallback, errorCallback);
            },
            eventSummaryPageByUrlSlug: function (jsonObject, successCallBack, errorCallBack) {
                return eventSummaryPageBySlug.getData(jsonObject, successCallBack, errorCallBack);
            },
            sendConfirmation: function (jsonObject, successCallback, errorCallback) {
                return sendEventSummaryConfirmation(jsonObject, successCallback, errorCallback);
            },
            reopenOffer: function (jsonObject, successCallback, errorCallback) {
                return eventSummaryReopenOffer.reopenOffer({}, jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

