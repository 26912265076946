'use strict';
import { OgModal } from "../../utils/global/ogModal";
import CommonFunctions from '../../utils/CommonFunctionUtil';

angular.module('dialog').directive('ogCreateEventUsingTemplateOrGuideModal', [
    '$rootScope',
    '$window',
    function ($rootScope, $window) {
        return new OgModal({
            name: 'create:event:using:template:or:guide',
            type: 'custom',
            controller: function ($scope) {
                $scope.initDialog = function () {
                    // $scope.publicUrl = data.publicUrl;
                };

                $scope.redirectToPublicSearchPage = function () {
                    $window.location.href = CommonFunctions.getPublicURL() + '/search';
                };
            },
        });
    },
]);

