(function () {
    'use strict';

    /**
     * WARNING!!! [Vulnerable Code], Do not Change this code without Supervision.
     */

    angular.module('ogUiDate').directive('ogUiDate', [
        '$rootScope',
        'TimezoneService',
        '$translate',
        '$timeout',
        function ($rootScope, TimezoneService, $translate, $timeout) {
            function getDate(tabIndex) {
                return jQuery('#ui-date' + tabIndex);
            }

            function setupDate(scope) {
                $timeout(function () {
                    var dateTime = new Date(TimezoneService.toLocalTime(scope.date));
                    getDate(scope.tabIndex).datepicker('setDate', scope.date ? new Date(dateTime) : null);
                    setDateText(scope);
                });
            }

            function resetDate(scope) {
                $timeout(function () {
                    getDate(scope.tabIndex).datepicker('setDate', null);
                });
            }

            function updateDate(scope) {
                scope.date = TimezoneService.fromLocalTime(getDateTime(scope.tabIndex)).getTime();
                setDateText(scope);
                $rootScope.safeApply(scope);
            }

            function getDateTime(tabIndex) {
                var dateTime = getDate(tabIndex).datepicker('getDate');
                return dateTime;
            }

            function setDateText(scope) {
                if (scope.date) {
                    scope.dateText =
                        $rootScope.langSelected === 'en_US'
                            ? moment(scope.date).format('DD/MM/YYYY')
                            : moment(scope.date).format('YYYY-MM-DD');
                } else {
                    scope.dateText = null;
                }
            }

            return {
                scope: {
                    date: '=',
                    showIcon: '=',
                    disableField: '=',
                    onchanged: '=onchanged',
                    modelName: '@modelName',
                    required: '=required',
                    startingDate: '=startingDate',
                    showYearMonthOption: '=showYearMonthOption',
                    name: '@name',
                },
                transclude: true,
                priority: 10,
                templateUrl: './app/components/directives/og_ui_date/og_ui_date.view.html',
                link: function (scope) {
                    scope.tabIndex = (Math.random() * 6).toString().replace(/\./g, '_');
                    //use this model wisely, the calendar updates to the startingDate on changed.
                    scope.$watch('startingDate', function (newVal, oldValue) {
                        if (newVal && newVal != oldValue) {
                            scope.date = newVal;
                            setDateText(scope);
                            $timeout(function () {
                                jQuery('#ui-date' + scope.tabIndex).datepicker('setDate', new Date(newVal));
                            }, 10);
                        }
                    });

                    scope.$watch('date', function (newValue, oldValue) {
                        // reset date to empty for no value;
                        if (typeof newValue !== 'undefined' && !newValue) {
                            resetDate(scope);
                        }
                        // To remove conflict with onselct events updateDate()
                        // Addresses angular bug newval=oldval in intial load
                        if (newValue && (typeof oldValue === typeof undefined || newValue === oldValue)) {
                            scope.dateText =
                                $rootScope.langSelected === 'en_US' // To sync scope.date with ng-model
                                    ? moment(newValue).format('DD/MM/YYYY')
                                    : moment(newValue).format('YYYY-MM-DD');
                            setupDate(scope);
                            setDateText(scope);
                        }
                        if (newValue && scope.onchanged && typeof (scope.onchanged === 'function')) {
                            scope.onchanged(scope.modelName, newValue);
                        }
                    });

                    scope.updateDate = function () {
                        if (scope.dateText) {
                            try {
                                jQuery.datepicker.parseDate(getDateFormat(), scope.dateText);
                                updateDate(scope);
                            } catch (e) {
                                console.log(e + ' is not valid date. Please endter valid date.');
                            }
                        } else {
                            scope.date = undefined;
                        }
                    };

                    $timeout(function () {
                        jQuery('#ui-date' + scope.tabIndex).datepicker({
                            dateFormat: getDateFormat(),
                            changeYear: scope.showYearMonthOption,
                            changeMonth: scope.showYearMonthOption,
                            yearRange: '-100:+5', //100 years ago and 5 years from now
                            defaultDate: new Date(scope._startingDate),
                            beforeShow: function (input, inst) {
                                let _isModal = jQuery('.modal-backdrop').length;
                                if(_isModal) {
                                    $timeout(()=> {
                                        let _offestTop = $("#" + inst.id).offset().top - $(window).scrollTop() + $("#" + inst.id)[0].getBoundingClientRect().height;
                                        inst.dpDiv.css({
                                            'top': _offestTop
                                        })
                                    })
                                }
                            },
                            onSelect: function () {
                                updateDate(scope);
                            },
                        });
                    }, 0);

                    function getDateFormat() {
                        return $rootScope.langSelected === 'en_US' ? 'dd/mm/yy' : 'yy-mm-dd';
                    }

                    function setFormats(lang, tabIndex) {
                        setDatePickerFormat(lang, tabIndex);
                    }

                    function setDatePickerFormat(lang, tabIndex) {
                        if (lang === 'en_US') {
                            getDate(tabIndex).datepicker('option', 'dateFormat', 'dd/mm/yy');
                        } else {
                            getDate(tabIndex).datepicker('option', 'dateFormat', 'yy-mm-dd');
                        }
                    }

                    //Trigger datetime format change on language change
                    scope.$on('lang:change', function (event, data) {
                        setFormats(data.lang, scope.tabIndex);
                        $timeout(function () {
                            setupDate(scope);
                        });
                    });

                    jQuery('.icon-calendar').on('click', function () {
                        jQuery(this).siblings('input').datepicker('show');
                    });
                },
            };
        },
    ]);
})();
