'use strict';

angular.module('core.existingServices').factory('ParticipantSummary', ParticipantSummaryFn);
ParticipantSummaryFn.$inject = ['$resource'];

function ParticipantSummaryFn($resource) {
    var summary = $resource(
        'api/v1/participantSummary/:eventId',
        {eventId: '@eventId'},
        {
            get: {method: 'GET', isArray: false},
        }
    );

    var registeredParticipants = $resource(
        'api/v1/participantSummary/:eventId/latestRegisteredParticipants',
        {eventId: '@eventId'},
        {
            list: {method: 'POST', isArray: false},
        }
    );

    return {
        getSummary: function (eventId, successCallback, errorCallback) {
            return summary.get({eventId: eventId}, successCallback, errorCallback);
        },
        listRegisteredParticipants: function (eventId, jsonObject, successCallback, errorCallback) {
            return registeredParticipants.list({eventId: eventId}, jsonObject, successCallback, errorCallback);
        },
    };
}

