/**
 * Created by kshitij on 12/8/17.
 */
import amountAndPriceService from '../../../utils/price_calculate/PriceCalculationCreatorUtil';
import currencyUtil from '../../../utils/CurrencyUtil';
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';
import {UtilFunctions} from "../../../utils/global/utilFunctions";

import customMessageModal from '../../../components/modals/modals2.0/custom-message-modal';

class ParticipantTicketsCtrl {
    constructor($scope, EventTicket, ModalService, StripeElements) {
        this._injected = {
            $scope,
            EventTicket,
            ModalService,
            StripeElements,
        };

        ScopeFunctions.addToScope($scope);
        this.$onInit = () => {
            this.participantTicket = {};
            this.card = this.card || {};
            this.cardValid = this.cardValid || {};
            this.fortNoxDetails = this.fortNoxDetails || {};
            this.stripeElement.on('change', (event) => {
                var errorElement = document.getElementById('card-errors');
                if (event.error) {
                    errorElement.textContent = event.error.message;
                } else {
                    errorElement.textContent = '';
                }
            });
        }
    }

    getVatAmount(ticket) {
        let vatAmount =
            UtilFunctions.isGreaterOrEqualToZero(ticket.price) && UtilFunctions.isGreaterOrEqualToZero(ticket.vat)
                ? (ticket.price * ticket.vat) / 100
                : 0;
        return amountAndPriceService.round(parseFloat(vatAmount));
    }

    getPriceWithVatLocalisedWithCurrency(ticket) {
        let priceWithoutVat = UtilFunctions.isGreaterOrEqualToZero(ticket.price) ? parseFloat(ticket.price) : 0;
        let priceWithVat = priceWithoutVat + this.getVatAmount(ticket);
        let currency = ticket.currency || currencyUtil.getDefaultCurrency();
        return amountAndPriceService.getAmountLocalisedWithCurrency(priceWithVat, currency, 2);
    }

    getTicketsAmountAvailable(ticket) {
        const { EventTicket, $scope } = this._injected;
        if (ticket.soldOut) {
            return 'Sold Out';
        }
    }

    updateParticipantTicket() {
        this.updateSelectedTicketCallback({ selectedTicket: this.participantTicket });
    }

    isFreeTicket() {
        if (this.participantTicket.price == 0) {
            this.freeTicket({ freeTicket: true });
            return true;
        }
        this.freeTicket({ freeTicket: false });
        return false;
    }

    shouldShowCreditCardForm() {
        return this.paymentMethod == 'CREDIT_CARD' && this.participantAttendingUnconfirmed;
    }

    shouldShowFortNoxDetailsForm() {
        return this.paymentMethod == 'INVOICE' && this.participantAttendingUnconfirmed;
    }

    showTermsAndConditions() {
        const { ModalService } = this._injected;

        let data = {
            title: 'ticket.terms.and.conditons.modal.heading',
            message: this.termsAndConditions,
        };

        let customMessageModalData = customMessageModal;
        customMessageModal.inputs = { data };

        ModalService.showModal(customMessageModalData).then((modal) => {
            modal.element.modal();
            modal.close.then(() => {
                console.log('modal is closed!');
            });
        });
    }

    validateCreditCardNumber() {
        this.cardValid.number = /^\d{16}$/.test(this.card.number);
        return this.cardValid.number;
    }

    validateCreditCardCvc() {
        this.cardValid.cvc = /^\d{3,4}$/.test(this.card.cvc);
        return this.cardValid.cvc;
    }

    range(n, start, numberOfDigits = 0) {
        start = start || 1;
        return UtilFunctions.getRange(n, start, numberOfDigits);
    }

    monthRange() {
        const START = 1;
        const NO_OF_MONTHS = 12;
        const NUMBER_OF_DIGITS = 2;
        return this.range(NO_OF_MONTHS, START, NUMBER_OF_DIGITS);
    }

    yearRange() {
        let dateToday = new Date().getFullYear();
        let startRange = dateToday;
        let endRange = dateToday + 15;
        return this.range(endRange, startRange);
    }
}
ParticipantTicketsCtrl.$inject = ['$scope', 'EventTicket', 'ModalService', 'StripeElements'];
export default ParticipantTicketsCtrl;
