/**
 * Created by kshitij on 7/28/17.
 */
(function () {
    'use strict';
    angular.module('accommodation.input').directive('ogAccommodationInput', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    accommodation: '=',
                    room: '=',
                    numberOfBeds: '=',
                    defaultName: '=',
                    defaultNameCode: '=',
                    colorCode: '=',
                    isDisabled: '=',
                    canCancelRoom: '=',
                    changeForAll: '=',
                    incrementAmountCallback: '&',
                    decrementAmountCallback: '&',
                    synchronizeRoomsCallback: '&',
                    cancelRoomCallback: '&',
                    deleteRoomCallback: '&',
                    updateRoomNameCallback: '&',
                    fieldChanged: '=',
                },
                templateUrl: './app/components/directives/accommodation_input/og_accommodation_input.view.html',

                controller: [
                    '$scope',
                    '$timeout',
                    'EventEnum',
                    '$rootScope',
                    function ($scope, $timeout, EventEnum, $rootScope) {
                        $scope.addPartComment = false;
                        $scope.originalOtherRooms = angular.copy($scope.accommodation.otherRooms);

                        $rootScope.$on(EventEnum.EVENT_AND_REQUESTS_RELOAD, function () {
                            $scope.originalOtherRooms = angular.copy($scope.accommodation.otherRooms);
                        });

                        $scope.addRoomName = function (part) {
                            this.synchronizeRooms(part.amount, null);
                            if (angular.isDefined(this.fieldChanged)) {
                                this.fieldChanged();
                            }
                        };

                        $scope.decrementAmount = function (part) {
                            if (!$scope.isDisabled && part.amount > 0) {
                                $scope.decrementAmountCallback({ part: part });
                                this.synchronizeRooms(part.amount, null);
                            }
                        };

                        $scope.incrementAmount = function (part) {
                            if (part.amount >= 99999) {
                                return;
                            }
                            if (!$scope.isDisabled) {
                                $scope.incrementAmountCallback({ part: part });
                                this.synchronizeRooms(part.amount, null);
                            }
                        };

                        $scope.synchronizeRooms = function (amount, commentByCreator) {
                            $scope.synchronizeRoomsCallback({ amount: amount, commentByCreator: commentByCreator });
                            if (angular.isDefined(this.fieldChanged)) {
                                this.fieldChanged();
                            }
                            $scope.synchronizeRoomsCallback({ amount: amount, commentByCreator: commentByCreator });
                        };

                        $scope.addComment = function (room) {
                            $scope.closeAllTooltip();
                            room.addPartComment = true;
                            let myElement = document.getElementById('accommodation-cmt-input-field');
                            if (myElement) {
                                document.getElementById('accommodation-cmt-tooltip').scrollTop = myElement.offsetTop;
                            }
                        };

                        $scope.range = function (n) {
                            return UtilFunctions.getRange(n);
                        };

                        $scope.getIcon = function (n) {
                            switch (n) {
                                case 1:
                                    return 'assets/app/customer/event/calendar/single_room.svg';
                                    break;
                                case 2:
                                    return 'assets/app/customer/event/calendar/double_room.svg';
                                    break;
                                case 3:
                                    return 'assets/app/customer/event/calendar/suite_room.svg';
                                    break;
                                default:
                                    break;
                            }
                        };

                        $scope.cancelCustomRoom = function (part) {
                            $scope.cancelRoomCallback();
                            // if (angular.isDefined(this.fieldChanged)) {
                            //   this.fieldChanged();
                            // }
                            this.synchronizeRooms(part.amount, null);
                        };

                        $scope.canRemoveRoom = function () {
                            if ($scope.changeForAll > 0) {
                                return false;
                            }
                            return $scope.room && $scope.room.id > 0 && !$scope.canCancelRoom && !$scope.isDisabled;
                        };

                        $scope.removeRoom = function () {
                            $scope.confirmRemoveRoom = true;
                        };

                        $scope.cancelRemoveRoom = function () {
                            $scope.confirmRemoveRoom = false;
                        };

                        $scope.doRemoveRoom = function () {
                            $scope.confirmRemoveRoom = false;
                            $scope.deleteRoomCallback({ room: $scope.room });
                        };

                        $scope.updateRoomName = function () {
                            if ($scope.room.id) {
                                $scope.updateRoomNameCallback({ room: $scope.room });
                            } else {
                                $scope.room.showNameEditTooltip = false;
                            }
                        };

                        $scope.isRoomNameEmpty = () => {
                            if ($scope.room && !$scope.room.name) {
                                return true;
                            }
                            return false;
                        };

                        $scope.editRoomName = function (room) {
                            $scope.originalRoom = angular.copy(room);
                            $scope.closeAllTooltip();
                            room.showNameEditTooltip = true;
                            room.nameEditable = true;
                            room.showNameSaveIcon = true;
                        };

                        $scope.closeAllTooltip = function () {
                            $scope.accommodation.singleRoom.addPartComment = false;
                            $scope.accommodation.doubleRoom.addPartComment = false;
                            $scope.accommodation.quadRoom.addPartComment = false;
                            $scope.accommodation.suite.addPartComment = false;
                            $scope.accommodation.tripleRoom.addPartComment = false;
                            $scope.accommodation.quadRoom.nameEditable = false;
                            $scope.accommodation.suite.nameEditable = false;
                            $scope.accommodation.tripleRoom.nameEditable = false;
                            angular.forEach($scope.accommodation.otherRooms, (room) => {
                                if (
                                    room.id &&
                                    $scope.originalRoom &&
                                    room.id === $scope.originalRoom.id &&
                                    room.name !== $scope.originalRoom.name
                                ) {
                                    room.name = $scope.originalRoom.name;
                                } else if (
                                    room._tempId &&
                                    $scope.originalRoom &&
                                    room._tempId === $scope.originalRoom._tempId &&
                                    room.name !== $scope.originalRoom.name
                                ) {
                                    room.name = $scope.originalRoom.name;
                                }
                                room.addPartComment = false;
                                room.showNameEditTooltip = false;
                                room.nameEditable = false;
                                room.showNameSaveIcon = false;
                            });
                        };
                    },
                ],
            };
        },
    ]);
})();
