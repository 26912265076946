import controller from './og_auto_complete_address.controller';

(function () {
    'use strict';

    angular.module('autoCompleteAddress').directive('autoCompleteAddress', [
        function () {
            return {
                scope: {
                    address: '=',
                    isDisabled: '=',
                    manualEntry: '=',
                    locationChangeCallback: '&',
                },
                templateUrl: './app/components/directives/autocomplete-address/og_auto_complete_address.view.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogAutocompleteAddressCtrl',
                bindToController: true,
            };
        },
    ]);
})();
