'use strict';
import {ScopeFunctions} from "../../../../../../../utils/global/scopeFunctions";
import {OgModal} from "../../../../../../../utils/global/ogModal";

// Register `participants.invitation` component, along with its associated controller and template

const BASE_URL = './app/components/modules/event/event_detail/participants/invitation/design-invitation';
angular.module('participants.invitation.design').component('participantinvitationdesign', {
    templateUrl: './app/components/modules/event/event_detail/participants/invitation/design-invitation/invitation.view.html',
    controllerAs: 'invitationDesignCtrl',
    controller: [
            '$location',
            '$scope',
            '$rootScope',
            '$state',
            'EventPresentation',
            'AuthenticationService',
            '$stateParams',
            'Event',
            'UserGroup',
            'ActiveTabService',
            'EventEnum',
            'CommonFunctions',
            function InvitationDesignController(
                $location,
                $scope,
                $rootScope,
                $state,
                EventPresentation,
                AuthenticationService,
                $stateParams,
                Event,
                UserGroup,
                ActiveTabService,
                EventEnum,
                CommonFunctions
            ) {
                var vm = this;
                vm.baseUrl = BASE_URL;
                vm.alreadyAnsweredQuestions = false;
                vm.init = initFn;
                vm.setLocation = setLocationFn;
                vm.sharePublicly = sharePubliclyFn;
                vm.showHideMapInInvitation = showHideMapInInvitationFn;
                vm.updateEventPresentationName = updateEventPresentationNameFn;
                vm.setTemplateUrl = setTemplateUrlFn;
                vm.saveWillProcureUserFeedback = saveWillProcureUserFeedbackFn;
                vm.saveNoProcurementUserFeedback = saveNoProcurementUserFeedbackFn;
                vm.setSoftBlockOption = setSoftBlockOptionFn;
                vm.setActive = setActiveFn;
                vm.sendInvitationBlockerEmailForManagement = sendInvitationBlockerEmailForManagementFn;
                vm.openInvitationTab = openInvitationTabFn;

                vm.eventId = $stateParams.eventId;

                ScopeFunctions.addToScope($scope);
                vm.init();

                function initFn() {
                    vm.data = { email: '' };
                    $scope.startProgress({ showContent: false });
                    EventPresentation.getInvitationPresentationForEvent(
                        { eventId: vm.eventId },
                        updatePresentationDetails,
                        $scope.endWithErrorOverlay
                    );
                    Event.hasAnyRequestsSentInEventOrAlreadyAnswered(
                        vm.eventId,
                        function (data) {
                            vm.hasAnyRequestsSentInEvent = data.hasAnyRequestsSentInEvent;
                            vm.alreadyAnsweredQuestions = data.alreadyAnsweredQuestions;
                        },
                        $scope.endWithErrorOverlay
                    );
                    UserGroup.isInvitationBlocked(
                        { eventId: vm.eventId },
                        ((response) => {
                            vm.isInvitationBlocked = response.isInvitationBlocked;
                        }).bind(vm),
                        (error) => { },
                        $scope.endWithErrorOverlay()
                    );
                    vm.templateUrl = vm.baseUrl + '/soft_block_invitation/soft-block-invitation.view.html';
                    setSoftBlockOptionFn('WILL_PROCURE');
                }

                function setLocationFn() {
                    OgModal.open('change:invitation:details', { type: 'LOCATION', eventId: vm.eventId });
                }

                function sharePubliclyFn() {
                    if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                        return;
                    }
                    OgModal.open('event:site:share', { eventId: vm.eventId, type: 'EVENT_PRESENTATION' });
                }

                function setTemplateUrlFn(url) {
                    vm.templateUrl = vm.baseUrl + url;
                }

                function saveWillProcureUserFeedbackFn() {
                    if (!vm.willProcureFeedbackForm.$valid) {
                        CommonFunctions.touchFormFields(vm.willProcureFeedbackForm);
                        return;
                    }
                    var params = {
                        startInvitationFeedback: vm.startInvitationFeedback,
                        plannedProcurementDate: vm.plannedProcurementDate,
                    };
                    Event.saveWillProcureInvitationFeedback(vm.eventId, params, function (response) {
                        vm.alreadyAnsweredQuestions = true;
                    });
                }

                function saveNoProcurementUserFeedbackFn() {
                    if (!vm.noProcurementFeedbackForm.$valid) {
                        CommonFunctions.touchFormFields(vm.noProcurementFeedbackForm);
                        return;
                    }
                    var params = {
                        participantManagementOnlyFeedback: vm.participantManagementOnlyFeedback,
                    };
                    Event.saveNoProcurementFeedback(vm.eventId, params, function () {
                        console.log('updated ...', vm.eventId);
                    });
                }

                function setSoftBlockOptionFn(type) {
                    vm.softBlockType = '';
                    vm.softBlock = {
                        willProcure: false,
                        participantManagementOnly: false,
                        startProcurement: false,
                        sendInvitationFirst: false,
                        goToBillingDetails: false,
                    };
                    vm.softBlockType = type;
                    if (vm.softBlockType == 'WILL_PROCURE') {
                        vm.softBlock.willProcure = true;
                    }
                    if (vm.softBlockType == 'PARTICIPANT_MANAGEMENT_ONLY') {
                        vm.softBlock.participantManagementOnly = true;
                    }
                    if (vm.softBlockType == 'GO_TO_BILLING_DETAILS') {
                        vm.softBlock.goToBillingDetails = true;
                    }
                    if (vm.softBlockType == 'RENDER_SUCCESS_MESSAGE') {
                        vm.softBlock.renderSuccessMessage = true;
                    }
                    if (vm.softBlockType == 'START_PROCUREMENT') {
                        vm.softBlock.startProcurement = true;
                    }
                    if (vm.softBlockType == 'SEND_INVITATION_FIRST') {
                        vm.softBlock.sendInvitationFirst = true;
                    }
                }

                function setActiveFn(tab) {
                    ActiveTabService.setEventDetailTab(tab);
                }

                function showHideMapInInvitationFn(value) {
                    vm.eventPresentation.showMap = value;
                    $scope.startProgress();
                    EventPresentation.update(vm.eventPresentation, updatePresentationDetails);
                }

                function updateEventPresentationNameFn(name) {
                    vm.eventPresentation.name = name;
                    $scope.startProgress();
                    EventPresentation.update(vm.eventPresentation, updatePresentationDetails);
                }

                function updatePresentationDetails(response) {
                    vm.eventPresentation = response.eventPresentationDetails.eventPresentation;
                    vm.selectedPresentation = response.eventPresentationDetails.slide;
                    vm.showMapInInvitation = { checked: response.eventPresentationDetails.eventPresentation.showMap };
                    vm.invitationDetails = response.invitationDetails;
                    $scope.endProgress();
                }

                function sendInvitationBlockerEmailForManagementFn(type) {
                    if (!vm.invitationBlockerForm.$valid) {
                        CommonFunctions.touchFormFields(vm.invitationBlockerForm);
                        return;
                    }
                    const params = {
                        company: vm.company,
                        orgnumber: vm.orgnumber,
                        reference: vm.reference,
                        street: vm.street,
                        zipcode: vm.zipcode,
                        town: vm.town,
                        markingpo: vm.markingpo,
                        invoiceemail: vm.invoiceemail,
                        fullname: vm.fullname,
                        emailaddress: vm.emailaddress,
                        phone: vm.phone,
                        eventId: vm.eventId,
                        lang: $rootScope.langSelected,
                    };
                    $scope.startProgress();
                    Event.sendEmailForInvitationBlockerManagement(
                        { params },
                        function (response) {
                            if (response.isMailSent) {
                                $rootScope.$broadcast(EventEnum.EVENT_PARTICIPANT_SOFT_BLOCK_CHANGED, false);
                            }
                            $scope.endProgress();
                        },
                        function (error) {
                            console.log('Something went wrong while sending email : ', error);
                        }
                    );

                    // just to make sure everything works, previous form is completely removed and value is there.
                    const data = {
                        participantManagementOnlyFeedback: 'Some Value',
                    };
                    Event.saveNoProcurementFeedback(vm.eventId, data, function () { });
                    // $rootScope.$broadcast(EventEnum.EVENT_PARTICIPANT_SOFT_BLOCK_CHANGED, true);
                    setSoftBlockOptionFn(type);
                }

                function openInvitationTabFn() {
                    $state.go('event.detail.participants.invitation');
                    // $location.url('event/'+vm.eventId+'/participants/design_invitation/form');
                    //setTemplateUrlFn(url);
                    // vm.alreadyAnsweredQuestions = true;
                }

                var invitationUpdateEvent = $rootScope.$on('invitation:details:update', function (event, data) {
                    vm.invitationDetails = data;
                    vm.showMapInInvitation = { checked: data.showMap };
                });

                $scope.$on('$destroy', invitationUpdateEvent);
            }
        ]
    });
