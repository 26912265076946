import controller from './og_crop_image.controller';

(function () {
    'use strict';
    angular.module('ogCropImage').directive('ogCropImage', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    imageTitle: '=',
                    category: '=',
                    previewDetails: '=',
                    cropDetails: '=',
                    presentationLogoType: '=',
                    presentationType: '=',
                    presentationId: '=',
                    showPreview: '@',
                    updateImageCallback: '&',
                    cancelUpdateImageCallback: '&',
                },
                templateUrl: './app/components/directives/crop_image/og_crop_image.view.html',
                controller,
                controllerAs: 'OgCropImageCtrl',
                bindToController: true,
            };
        },
    ]);
})();
