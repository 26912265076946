(function () {
    /* global angular */
    /*This is used in editSupplier tab of edit profile view*/
    'use strict';
    angular.module('ogOfficeAddress').directive('ogOfficeAddress', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/office_address/og.office.address.view.html',
            replace: true,
            controllerAs: 'officeAddressCtrl',
            scope: {
                addressId: '=',
                addressType: '=addressType',
                createCallback: '&',
            },
            bindToController: true,
            controller: [
                '$log',
                '$scope',
                '$rootScope',
                'Address',
                'FieldChangedSvc',
                function ($log, $scope, $rootScope, Address, FieldChangedSvc) {
                    var vm = this;

                    vm.handleOnchanged = handleOnchangedFn;
                    vm.init = initFn;
                    vm.update = updateFn;

                    vm.form = 'officeAddressForm';

                    vm.init();
                    var fieldChanged = FieldChangedSvc;

                    function handleOnchangedFn(modalName, currentValue) {
                        fieldChanged.checkOnChanged(modalName, currentValue);
                        vm.viewUpdateButton = fieldChanged.isFieldChanged();
                        return;
                    }

                    function initFn() {
                        if (vm.addressId) {
                            Address.get(
                                { id: vm.addressId },
                                function (data) {
                                    vm.officeAddress = data;
                                    vm.viewUpdateButton = false;
                                    fieldChanged.init(angular.copy(data));
                                },
                                $scope.endWithError
                            );
                        }
                    }

                    function updateFn() {
                        if (vm.officeAddress.id) {
                            Address.update(
                                vm.officeAddress,
                                function (address) {
                                    vm.officeAddress = address;
                                    fieldChanged.reset();
                                    fieldChanged.init(angular.copy(address));
                                    vm.viewUpdateButton = false;
                                    $rootScope.formSuccess(vm.form, 'dialog.address.update.success');
                                },
                                $scope.endWithError
                            );
                        } else {
                            vm.createCallback({ form: vm.form, address: vm.officeAddress });
                        }
                    }
                },
            ],
        };
    });
})();
