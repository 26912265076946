'use strict';
angular.module('core.existingServices').factory('EventPresentationRo', [
    '$resource',
    function ($resource) {
        var eventPresentationRest = $resource(
            'api/v1/event/presentation/ro/:hash',
            {hash: '@hash'},
            {
                get: {method: 'POST', isArray: false},
            }
        );
        var eventPresentationParticipateRest = $resource(
            'api/v1/event/presentation/ro/participate/:urlSlug',
            {urlSlug: '@urlSlug'},
            {
                participate: {method: 'POST', isArray: false},
            }
        );
        var eventPresentationNotParticipateRest = $resource(
            'api/v1/event/presentation/ro/declineParticipation/:hash',
            {hash: '@hash'},
            {
                decline: {method: 'POST', isArray: false},
            }
        );

        return {
            get: function (jsonObject, successCallback, errorCallback) {
                return eventPresentationRest.get(jsonObject, successCallback, errorCallback);
            },
            participate: function (jsonObject, successCallback, errorCallback) {
                return eventPresentationParticipateRest.participate(jsonObject, successCallback, errorCallback);
            },
            declineParticipation: function (jsonObject, successCallback, errorCallback) {
                return eventPresentationNotParticipateRest.decline(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

