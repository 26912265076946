import addEditAccommodationModal from '../add-edit-accommodation';
import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import { DownloadUtilFunctions } from '../../../../utils/global/DownloadUtilFunction';
import { UtilFunctions } from "../../../../utils/global/utilFunctions";
import { OfferPartUtilFunctions } from "../../../../utils/global/OfferPartUtilFunctions";
import CommonFunctions from "../../../../utils/CommonFunctionUtil";
import _ from 'underscore';

const APPLY_BG_COLOR_OFFER_STATUS_LIST = [
    'BOOKED_LOCKED_BY_CREATOR',
    'BOOKED_WAITING_FOR_SUPPLIER',
    'BOOKED_WAITING_FOR_CREATOR',
    'NEW',
    'SENT',
    'EDITED_BY_CREATOR',
    'FINISHED_WAITING_FOR_CREATOR',
];

const DRAW_STRIKE_THROUGH_LINE = [
    'NOT_DELIVERABLE',
    'ADDED_WAITING_FOR_CREATOR_DECLINED',
    'ADDED_WAITING_FOR_SUPPLIER_DECLINED',
    'DELETED_WAITING_FOR_SUPPLIER',
    'DELETED_BY_CREATOR',
];
const EDITED_BY_CREATOR_PART_STATE = ['EDITED_BY_CREATOR'];
const CATEGORY_NAMECODE_LIST = ['common.translate.category.hotel.&.conference', 'common.translate.category.venues'];
import amountAndPriceService from '../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import requestOfferPartUtil from '../../../../utils/request_offer_parts/RequestOfferPartsUtil';
import tooltipUtil from '../../../../utils/negotiation_notification/NegotiationTooltipCreatorUtil';
import conformationModal from '../conformation-modal';
import AccommodationUtil from '../../../../utils/accommodation/AccommodationUtil';

class OfferPreviewModalCtrl {
    constructor(
        $element,
        $rootScope,
        $scope,
        $timeout,
        $translate,
        $sce,
        $window,
        close,
        data,
        EventEnum,
        GlobalConstants,
        Request,
        RequestOffer,
        RequestOfferPart,
        EventFinalDetails,
        PopoverTypeEnum,
        AuthenticationService,
        NotificationService,
        RequestOfferStatusUtils,
        Activity,
        Note,
        ogScrollService,
        ModalName,
        ModalStatusStore,
        ModalService,
        FilledRequestOfferPartComment,
        DateService,
        TimezoneService,
        DynamicFormUtilService,
        TranslationService,
        TooltipValueService,
        GlobalOption,
        UserGroupSettings,
        ClientService,
        Client,
        RequestOfferPartOption,
        SupplierResources,
        FilledSupplierResourceField,
        CommonFile,
        FileService,
        CustomTranslationService
    ) {
        this._injected = {
            $element,
            $rootScope,
            $scope,
            $timeout,
            $translate,
            $sce,
            $window,
            close,
            data,
            EventEnum,
            GlobalConstants,
            Request,
            RequestOffer,
            RequestOfferPart,
            EventFinalDetails,
            PopoverTypeEnum,
            AuthenticationService,
            NotificationService,
            RequestOfferStatusUtils,
            Activity,
            Note,
            ogScrollService,
            ModalName,
            ModalStatusStore,
            ModalService,
            FilledRequestOfferPartComment,
            DateService,
            TimezoneService,
            DynamicFormUtilService,
            TranslationService,
            TooltipValueService,
            GlobalOption,
            UserGroupSettings,
            ClientService,
            Client,
            RequestOfferPartOption,
            SupplierResources,
            FilledSupplierResourceField,
            CommonFile,
            FileService,
            CustomTranslationService,
        };
        ScopeFunctions.addToScope($scope);
        let _offer = {};
        this.event = {};
        this.request = {};
        angular.copy(data.event, this.event);
        angular.copy(data.request, this.request);
        angular.copy(data.offer, _offer);
        this.agreementNumber = data.offer.agreementNumber;
        this.bookDirectly = data.bookDirectly;
        this.callback = data.callback;
        this.isThereAccommodation = data.isThereAccommodation;
        this.isThereConference = data.isThereConference;
        this.isThereConferenceCategory = data.isThereConferenceCategory;
        this.categoryIds = data.categoryIds;
        this.selectedOfferChanged(_offer);
        this.newActivity = null; // to avoid the recursive loop in watch ogOfferActivities directiv
        this.HIDE_CHANGE_TOOLTIP_PART_STATUS = ['EDITED_WAITING_FOR_CREATOR', 'EDITED_WAITING_FOR_SUPPLIER'];

        //needed to track if this modal is open or not
        ModalStatusStore.setModalOpen(ModalName.OFFER_PREVIEW_MODAL);
        $scope.startProgress(this.request.id);
        //holds the partId being edited
        this.offerPartId = null;
        this.offerPartsWithActions = [];
        this.offerPartOptionsWithActions = [];
        this.offerPartOptionsChangeList = [];
        this.originalOfferPartOptionsList = [];
        this.messageForm = {};
        this.billingAddressForm = {};
        this.clearMessageNotesSection();
        this.setDisplayActivityType('message');
        this.showActivites = false;
        this.showMessagesSection = false;
        this.showBillingInfoSection = false;
        this.isResendRequest = false;
        this.resendRequestMsg = null;
        this.viewPresentation = false;
        this.acceptAccountVerificationAlert = this.acceptAccountVerificationAlert.bind(this);
        this.acceptOfferPartCallback = this.acceptOfferPart.bind(this);
        this.addANewOfferPartCallback = this.addANewOfferPart.bind(this);
        this.splitAccommodationPart = this.splitAccommodationPart.bind(this);
        this.cancelBookedLockedStateChanges = this.cancelBookedLockedStateChanges.bind(this);
        this.cancelRequestOffer = this.cancelRequestOffer.bind(this);
        this.changeOfferStatusCallback = this.changeOfferStatus.bind(this);
        this.changeRequestOfferStatus = this.changeRequestOfferStatus.bind(this);
        this.changeRowIdCallback = this.changeRowId.bind(this);
        this.confirmDeleteRequestOfferPartCallback = this.confirmDeleteRequestOfferPart.bind(this);
        this.createMessageNoteCallback = this.createMessageNoteCallback.bind(this);
        this.declineOfferPartCallback = this.declineOfferPart.bind(this);
        this.deleteOfferPartCallback = this.deleteOfferPart.bind(this);
        this.getOriginalOfferPart = this.getOriginalOfferPart.bind(this);
        this.cancellationFeeSort = this.cancellationFeeSort.bind(this);
        this.hideMessagesSection = this.hideMessagesSection.bind(this);
        this.processRequestOffers = this.processRequestOffers.bind(this);
        this.refreshAndProcessRequestOffers = this.refreshAndProcessRequestOffers.bind(this);
        this.revertBookedLockedStateChangesCancellation = this.revertBookedLockedStateChangesCancellation.bind(this);
        this.undoOfferCancellation = this.undoOfferCancellation.bind(this);
        this.undoOfferPartDeletionCallback = this.undoOfferPartDeletion.bind(this);
        this.viewAmountLocalisedWithCurrency = this.viewAmountLocalisedWithCurrency.bind(this);
        this.revertAcceptedDeclinedChangedParts = this.revertAcceptedDeclinedChangedParts.bind(this);
        this.updateOfferAndOfferParts = this.updateOfferAndOfferParts.bind(this);
        this.updatePartCallback = this.updatePartCallback.bind(this);
        this.trustAsHtml = this.trustAsHtml.bind(this);
        this.htmlToPlaintext = this.htmlToPlaintext.bind(this);
        this.isOfferInCanceledOrDeclinedState = this.isOfferInCanceledOrDeclinedState.bind(this);
        this._updateOffer = this._updateOffer.bind(this);
        this.expandAdditionalQuestionsPart = this.expandAdditionalQuestionsPart.bind(this);
        this.addRequiredLabel = this.addRequiredLabel.bind(this);
        this.closeAnyWay = this.closeAnyWay.bind(this);
        this.checkIfAdditionalInfoChanges = this.checkIfAdditionalInfoChanges.bind(this);
        this.checkPartInfoChanges = this.checkPartInfoChanges.bind(this);
        this.checkFinalDetailsHasChanged = this.checkFinalDetailsHasChanged.bind(this);
        this.resendRequest = this.resendRequest.bind(this);
        this.openConfirmationModal = this.openConfirmationModal.bind(this);
        this.getOfferPartComment = this.getOfferPartComment.bind(this);
        this.getChangesBeforeSend = this.getChangesBeforeSend.bind(this);
        this.getChangesAfterSend = this.getChangesAfterSend.bind(this);
        this.getOfferPartTitle = this.getOfferPartTitle.bind(this);
        this.getOfferPartAdditionalQuestionClass = this.getOfferPartAdditionalQuestionClass.bind(this);
        this.scrollToBookOfferForm = this.scrollToBookOfferForm.bind(this);
        this.offerPartOptionsWithActionsCallback = this.offerPartOptionsWithActionsCallback.bind(this);
        this.getClientName = this.getClientName.bind(this);
        this.openAccommodationCallback = this.openAccommodationCallback.bind(this);
        this.isAnyPartOptionSelected = this.isAnyPartOptionSelected.bind(this);
        this.getPriceFromPartOption = this.getPriceFromPartOption.bind(this);
        this.getRequestPartOptionDifference = this.getRequestPartOptionDifference.bind(this);
        this.getRequestPartOptionTotalPrice = this.getRequestPartOptionTotalPrice.bind(this);
        this.toggleResourceOptionSelected = this.toggleResourceOptionSelected.bind(this);
        this.cancelToggleResourceOptionSelected = this.cancelToggleResourceOptionSelected.bind(this);
        this.addToResourceOptionChangeList = this.addToResourceOptionChangeList.bind(this);
        this.getResourcePresentation = this.getResourcePresentation.bind(this);
        this.getSupplierResourceFormClass = this.getSupplierResourceFormClass.bind(this);
        this.closeResourcePresentationPopover = this.closeResourcePresentationPopover.bind(this);
        this.closeInlineRowAddition = this.closeInlineRowAddition.bind(this);
        this.disableAccommodationPart = this.disableAccommodationPartFn.bind(this);
        this.getConferenceActivityLocation = this.getConferenceActivityLocation.bind(this);
        this.showAgreementReferenceUpdateLink = this.showAgreementReferenceUpdateLinkFn.bind(this);
        this.showOfferAgreementText = this.showOfferAgreementTextFn.bind(this);

        this.isOfferInFromBookedStates = this.isOfferInFromBookedStates.bind(this);
        this.getFormatedDate = this.getFormatedDate.bind(this);
        this.checkPaidOnSiteColInFinishedState = this.checkPaidOnSiteColInFinishedState.bind(this);
        this.checkForVatLimit = this.checkForVatLimit.bind(this);
        this.updateInternalVat = this.updateInternalVat.bind(this);
        this.updateSupplierAgreementNumberInOffer = this.updateSupplierAgreementNumberInOfferFn.bind(this);
        this.showUpdateAgreementNumberModal = this.showUpdateAgreementNumberModalFn.bind(this);
        this.hideUpdateAgreementNumberModal = this.hideUpdateAgreementNumberModalFn.bind(this);
        this.isNonCommissionAgreementPricesSettingEnabledInUserGroup = this.isNonCommissionAgreementPricesSettingEnabledInUserGroupFn.bind(this);
        this.getOfferPreviewAgreementNumberText = this.getOfferPreviewAgreementNumberTextFn.bind(this);
        this.getAmount = amountAndPriceService.getAmount;
        this.getAmountLocalisedWithCurrency = amountAndPriceService.viewPrice;
        this.getDifference = amountAndPriceService.getDifference;
        this.getPrice = amountAndPriceService.getPrice;
        this.getTotalPrice = amountAndPriceService.getTotalPrice;
        this.getOfferTotalPrice = amountAndPriceService.getOfferTotalPrice;
        this.getOfferOriginalPrice = amountAndPriceService.getOfferOriginalPrice;
        this.inputPlaceholderString = $translate.instant('offer.type.message.to.supplier'); // 'offer.make.a.note'
        this.loading = false;
        this.finalDetailsSharedDate = data.offer.finalDetailsSharedDate;
        this.attachedFile = null;
        this.hideTime = false;
        this.hasAdditionalQuestions = false;
        this.additionalInfoChangesBeforeSendList = [];
        this.additionalInfoChangesAfterSendList = [];
        this.updateSelectedCurrency = this.updateSelectedCurrencyFn.bind(this);
        this.selectedCurrency = null;
        this.currencyList = [];
        this.showResourcePresentation = false;
        this.isUsingClientBillingAddressAndIsEL = false;
        this.getClientName();
        this.showUndeleteBtn = false;
        this.showApprovedFinalPricesOverlay = this.offer.status.name === 'APPROVED' ? true : false;
        this.approvedMessageUserGroupWise = null;
        this.customStatusAprrovedMessage = CustomTranslationService.translate(
            'Custom Message',
            'common.final.prices.approved.corporate.custom.screen.message'
        );
        this.isclientBillingAddress = false;
        this.showPaidOnsiteCol = this.checkPaidOnSiteColInFinishedState() && this.paidOnsitePrice;
        this.updateAgreementNumberModal = false;
        this.agreementNumber = '';

        $timeout(() => {
            this.activeTab = data.activeTab; // OFFER_CONTENT, PRICE_COMPARISON
        }, 250);

        let selectedOfferIdChanged = function () {
            let _currentUserGroup = AuthenticationService.getUserData().userGroup;
            let _groupName = _currentUserGroup.name ? _currentUserGroup.name : $translate.instant('user.group.default');
            this.finalDetailsArgs = { event: this.event.name, userGroup: _groupName };
            this.setIsUsingClientBillingAddress(_currentUserGroup.userGroupBillingAddressEnum);
            this.approvedMessageUserGroupWise = _currentUserGroup.isUserGroupPriceApprovedMessageEnabled;
            if (!this.approvedMessageUserGroupWise) {
                this.handleClientBillingAddress(_currentUserGroup);
            }
            this.processRequestOffers();
        }.bind(this);

        $scope.$watch(() => this.selectedOfferId, selectedOfferIdChanged);
        if (data.isMessageLink) {
            this.displayMessagesSection();
        }

        $scope.$watch(
            'offerPreviewController.selectedMessage',
            function (value) {
                if (value) {
                    this.requestSupplierMessage = value;
                } else {
                    if (this.request.supplierMessage) {
                        this.requestSupplierMessage = this.request.supplierMessage;
                    } else {
                        $scope.startProgress(this.request.id);
                        GlobalOption.get(
                            'DEFAULT_REQUEST_MESSAGE',
                            (resp) => {
                                this.requestSupplierMessage = resp[$rootScope.langSelected]
                                    ? resp[$rootScope.langSelected]
                                    : $translate.instant('default.message.to.supplier', {
                                        p0: this.getResponsibleUser(),
                                    });
                                $scope.endProgress(this.request.id);
                            },
                            $scope.endWithErrorOverlay(this.request.id)
                        );
                    }
                }
            }.bind(this)
        );

        let resizeBodyHeight = function () {
            this.resizeModalBodyHeight();
        }.bind(this);

        var onHeaderLoaded = $scope.$watch('offerPreviewController.headerLoaded()', function (newValue) {
            if (newValue) {
                resizeBodyHeight();
                onHeaderLoaded();
            }
        });
        angular.element($window).bind('resize', resizeBodyHeight);
        //unbind the resize listener
        $scope.$on('$destroy', function () {
            angular.element($window).unbind('resize', resizeBodyHeight);
        });

        let isSelectedValueChanged = $rootScope.$on(
            'part:option:is:selected:value:changed',
            function (event, data) {
                let _partWithOptions = _.filter(this.offer.requestOfferParts, (offerPart) => {
                    return offerPart.offerPartOptions.length > 0;
                });
                if (_partWithOptions && _partWithOptions.length > 0) {
                    angular.forEach(_partWithOptions, (partWithOption) => {
                        let _offerPartOptions = partWithOption.offerPartOptions;
                        let _selectedOfferPartOption = _.find(_offerPartOptions, (o) => {
                            return o.id === data.offerPartOptionId;
                        });
                        if (_selectedOfferPartOption) {
                            _selectedOfferPartOption.isSelected = data.isSelected;
                            angular.forEach(this.originalOfferPartOptionsList, (originalOfferPartOption) => {
                                if (_selectedOfferPartOption.id === originalOfferPartOption.id) {
                                    originalOfferPartOption.isSelected = _selectedOfferPartOption.isSelected;
                                    this.updateOffer(partWithOption, data);
                                }
                            });
                        }
                    });
                }
            }.bind(this)
        );
        $scope.$on('$destroy', isSelectedValueChanged);

        let isRequestOfferPartOptionUpdated = $rootScope.$on(
            'request:offer:part:option:updated',
            function (event, data) {
                NotificationService.setNotificationData(this.event.id);
                let supplierMessage = this.offer ? this.offer.supplierMessage : null;
                this.offer = data.offer;
                this.offer.requestOfferParts = angular.copy(this.originalOfferPartsList);
                this.agreementNumber = data.agreementNumber;
                if (supplierMessage) {
                    this.offer.supplierMessage = supplierMessage;
                }
                if (!this.offer.bookingTerms) {
                    this.loadBookingTerms();
                }
                this.isOfferInFinishedState = CommonFunctions.isItFinishedOffer(this.offer);
                this.isOfferInCanceledState = CommonFunctions.isItCanceledOffer(this.offer);
                this.showPaidOnsiteCol = this.checkPaidOnSiteColInFinishedState() && this.paidOnsitePrice;
                this.getOfferCanceledAmount = amountAndPriceService.getOfferCanceledAmount(
                    this.offer,
                    this.offer.requestOfferParts,
                    this.includeVat
                );
            }.bind(this)
        );
        $scope.$on('$destroy', isRequestOfferPartOptionUpdated);

        let isRequestOfferPartOptionDeleted = $rootScope.$on(
            'request:offer:part:option:deleted',
            function (event, data) {
                NotificationService.setNotificationData(this.event.id);
                let supplierMessage = this.offer ? this.offer.supplierMessage : null;
                this.offer = data.offer;
                this.offer.requestOfferParts = angular.copy(this.originalOfferPartsList);
                this.agreementNumber = data.offer.agreementNumber;
                if (supplierMessage) {
                    this.offer.supplierMessage = supplierMessage;
                }
                if (!this.offer.bookingTerms) {
                    this.loadBookingTerms();
                }
                this.isOfferInFinishedState = CommonFunctions.isItFinishedOffer(this.offer);
                this.isOfferInCanceledState = CommonFunctions.isItCanceledOffer(this.offer);
                this.showPaidOnsiteCol = this.checkPaidOnSiteColInFinishedState() && this.paidOnsitePrice;
                this.getOfferCanceledAmount = amountAndPriceService.getOfferCanceledAmount(
                    this.offer,
                    this.offer.requestOfferParts,
                    this.includeVat
                );
            }.bind(this)
        );
        $scope.$on('$destroy', isRequestOfferPartOptionDeleted);

        let isOfferPartOptionPresentationDisplayed = $rootScope.$on(
            'part:option:open:presentation',
            function (event, data) {
                this.getResourcePresentation(data);
            }.bind(this)
        );
        $scope.$on('$destroy', isOfferPartOptionPresentationDisplayed);
        $scope.$on(
            'accommodation.overlapped',
            function (event, data) {
                this.accommodationOverlapped = data.accommodationOverlapped;
            }.bind(this)
        );

        let confirmDeleteToggle = $rootScope.$on(
            'confirm:delete:toggle:part:option',
            function (event, data) {
                this.offer.requestOfferParts.forEach((partOffer) => {
                    if (partOffer.id !== data.partId) {
                        partOffer.showDeleteConfirmation = false;
                    }
                });
            }.bind(this)
        );

        $scope.$on('$destroy', function () {
            confirmDeleteToggle();
        });

        this.shouldShowBillingInfo();
        this.finalDetailsDataChanged = false;
        this.showSuccessMessage = false;
        this.showSaveSuccessMessage = false;
        this.activitySubTab = 'ALL_ACTIVITIES_REGULAR';
    }

    getResourcePresentation(data) {
        const { $scope, SupplierResources } = this._injected;
        $scope.startProgress();
        this.showResourcePresentation = true;
        if (data.resourceId) {
            SupplierResources.getResource(
                data.resourceId,
                (data) => {
                    this.resource = data;
                    if (
                        this.resource.offerPartTypeNameCode === 'common.translate.offer.part.type.conference.room' ||
                        this.resource.offerPartTypeNameCode === 'common.translate.offer.part.type.conference.activities'
                    ) {
                        this.isResourceConferenceActivity = true;
                    }
                    this.getFilledSupplierResourceField(this.resource);
                    $scope.endProgress();
                },
                $scope.endWithErrorOverlay()
            );
        } else {
            let _data = {
                newResourceName: data.resourceName,
                nameCode: data.offerPartTypeNameCode,
                supplierId: this.offer.supplier.id,
                resourceStatus: 'PRIVATE',
            };
            SupplierResources.createNewResource(
                _data,
                (res) => {
                    this.resource = res;
                    if (
                        this.resource.offerPartTypeNameCode === 'common.translate.offer.part.type.conference.room' ||
                        this.resource.offerPartTypeNameCode === 'common.translate.offer.part.type.conference.activities'
                    ) {
                        this.isResourceConferenceActivity = true;
                    }
                    this.getFilledSupplierResourceField(this.resource);
                    $scope.endProgress();
                },
                $scope.endWithError
            );
        }
    }

    getFilledSupplierResourceField(resource) {
        const { FilledSupplierResourceField, $scope, DynamicFormUtilService, $rootScope, $translate } = this._injected;
        this.filledSupplierResourceFieldList = [];
        FilledSupplierResourceField.list(
            'ALL',
            resource.offerPartTypeNameCode,
            resource.id,
            resource.supplier.id,
            (resp) => {
                this.list = resp;
                angular.forEach(this.list, (list) => {
                    if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                        let _listValuesCode = list.listValueCodes;
                        let _listValues = $translate.instant(_listValuesCode);
                        let _fieldValue;
                        if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                            _fieldValue = false;
                        } else {
                            _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                        }
                        list.fieldValue = DynamicFormUtilService.getFieldValue(
                            _listValues,
                            $rootScope.langSelected,
                            _fieldValue
                        );
                    }
                    if (['ICON_RADIO', 'ICON_MULTI_CHECKBOX'].indexOf(list.type.name) > -1) {
                        let _iconList = list.iconList;
                        angular.forEach(_iconList, function (icon) {
                            icon.name = $translate.instant(icon.nameCode);
                        });
                    }
                });
                let _resourceArray = { resource: resource, list: this.list };
                this.filledSupplierResourceFieldList.push(_resourceArray);
                $scope.endProgress();
            },
            function (e) {
                console.log('error ', e);
                $scope.endWithError();
            }
        );
    }

    getSupplierResourceFormClass(resourceData) {
        if (resourceData && resourceData.offerPartTypeNameCode) {
            switch (resourceData.offerPartTypeNameCode) {
                case 'common.translate.offer.part.type.conference.room':
                    return 'conference-room-form';
                    break;
                default:
                    return 'conference-room-form';
                    break;
            }
        }
    }

    closeResourcePresentationPopover() {
        this.showResourcePresentation = false;
        this.resource = null;
    }

    viewPriceLocalisedWithCurrency(price, currency, digitsAfterDecimal = 0) {
        return amountAndPriceService.viewPrice(price, currency, digitsAfterDecimal);
    }

    viewAmountLocalisedWithCurrency(price, currency, digitsAfterDecimal = 0) {
        var _price = this.getAmountLocalisedWithCurrency(price, currency, digitsAfterDecimal);
        return !!_price ? _price : 0;
    }

    acceptAccountVerificationAlert() {
        let params = { showAlert: false };
        this.prepareParamsForNegotiationAlert(params);
    }

    trustAsHtml(string) {
        const { $sce } = this._injected;
        return $sce.trustAsHtml(string);
    }

    getClientName() {
        const { Client } = this._injected;
        Client.getName(
            {},
            (resp) => {
                this.clientName = resp.clientName.trim().replaceAll(' ', '').toLowerCase();
                this.getBillingAddressPath(this.clientName);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    getBillingAddressPath(clientName) {
        this.billingAddressPath = './app/components/modals/modals2.0/offer-preview/partials/' + clientName + '-billing-address-form.html';
    }

    fieldValueHasBeenChanged(offerPart, oldValue, newValue) {
        let partStatus = offerPart.status.name;
        if (partStatus !== 'ACCEPTED') {
            return this.hasFieldValueChanged(oldValue, newValue);
        }
        return false;
    }

    fieldAmountValueHasBeenChanged(offerPart, oldValue, newValue) {
        let partStatus = offerPart.status.name;
        if (partStatus !== 'ACCEPTED') {
            return this.hasAmountFieldValueChanged(oldValue, newValue);
        }
        return false;
    }

    fieldValueHasBeenChangedForDescription(offerPart) {
        let partStatus = offerPart.status.name;
        if (partStatus !== 'ACCEPTED') {
            return (offerPart.oldCommentByCreator !== offerPart.commentByCreator) != null;
        }
        return false;
    }

    getPriceDifference() {
        let _totalPrice = this.getOfferTotalPrice(this.offer, this.offer.requestOfferParts);
        let _originalTotalPrice = this.getOfferOriginalPrice(this.offer.requestOfferParts);
        return _totalPrice - _originalTotalPrice;
    }

    hasFieldValueChanged(oldValue, newValue) {
        return OfferPartUtilFunctions.checkOfferPartHasOldValueAndChanged(oldValue, newValue);
    }

    hasAmountFieldValueChanged(oldValue, newValue) {
        return OfferPartUtilFunctions.checkOfferPartAmountHasOldValueAndChanged(oldValue, newValue);
    }

    hasDescriptionChanged(offerPart) {
        var descriptionCodeChanged =
            offerPart.oldCommentByCreator != null &&
            this.fieldValueHasBeenChanged(offerPart, offerPart.oldCommentByCreator, offerPart.commentByCreator);
        if (descriptionCodeChanged) {
            return true;
        }
        return this.fieldValueHasBeenChangedForDescription(offerPart);
    }

    hasNameChanged(offerPart) {
        var nameCodeChanged =
            offerPart.oldNameCode != null &&
            this.fieldValueHasBeenChanged(offerPart, offerPart.oldNameCode, offerPart.nameCode);
        if (nameCodeChanged) {
            return true;
        }
        return (
            offerPart.oldNameCode == null && this.fieldValueHasBeenChanged(offerPart, offerPart.oldName, offerPart.name)
        );
    }

    cancelAccommodationOverlay() {
        this.openAccommodationModal = false;
        this.closeInlineRowAddition();
    }

    acceptOfferPart(offerPart) {
        let offerPartMap = { offerPart: offerPart, action: 'ACCEPTED_BY_CREATOR' };
        this.removeOfferPartFromMap(offerPart);
        this.offerPartsWithActions.push(offerPartMap);

        var originalOfferPart = this.getOriginalOfferPart(offerPart);
        OfferPreviewModalCtrl.reassignOriginalValuesToOfferPart(offerPart, originalOfferPart);
        if (originalOfferPart.status.name === 'EDITED_WAITING_FOR_CREATOR') {
            offerPart.status.name = 'ACCEPTED';
        }
        if (originalOfferPart.status.name === 'ADDED_WAITING_FOR_CREATOR') {
            offerPart.status.name = 'ACCEPTED';
        }
        if (originalOfferPart.status.name === 'DELETED_WAITING_FOR_CREATOR') {
            offerPart.status.name = 'NOT_DELIVERABLE';
        }
        this.flagForAcceptedWithChanges = undefined;

        this.checkIfActionHasBeenTakenForAllSupplierChanges();
        if (this.offer.status.name === 'BOOKED_WAITING_FOR_CREATOR' && !this.disableSendActionForCreator()) {
            this.disableOfferStatusButton = false;
        }
    }

    actionOnRowEdit(offerPart) {
        this.offerPartId = 'd' + offerPart.id;
        this.changeRowId();
    }

    showAlertMessage(offerPart) {
        const { $translate } = this._injected;
        if (this.anyOfferInBookedLockedBySupplierState()) {
            this.message = $translate.instant('request.offer.supplier.locked.update.by.creator.notification');
            return true;
        } else if (CommonFunctions.doAlertBeforeUpdate(offerPart)) {
            this.message = $translate.instant('request.offer.part.change.alert');
            return true;
        }
        return false;
    }

    addANewOfferPart(offerPart) {
        if (this.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
            offerPart.status = { name: 'ADDED_BY_CREATOR' };
            offerPart.editable = { name: 'EDITABLE' };
            offerPart.newPrice = offerPart.price;
            offerPart.deletable = true;
            let _isAccommodation = offerPart.offerPartCategory === 'ACCOMMODATION';
            if (_isAccommodation) {
                this.splitAccommodationPart(offerPart);
            } else {
                var offerPartMap = { offerPart: offerPart, action: 'ADDED_BY_CREATOR' };
                this.offerPartsWithActions.push(offerPartMap);
                this.offer.requestOfferParts.push(offerPart);
                this.checkAndAddNewOfferPart(offerPart);
            }
        } else {
            this.createPart(offerPart);
        }
    }

    checkForVatLimit(offerPart) {
        if (offerPart.vat.length >= 3 && !offerPart.vat.includes('.')) {
            offerPart.vat = Math.floor(offerPart.vat / 10);
        }
    }

    checkAndAddNewOfferPart(offerPart) {
        let _date = this.getFormatedDate(offerPart.dateCreated);
        if (offerPart && !offerPart.timeless) {
            let _timedPart = this.filteredTimedOfferParts.find((part) => {
                let _defaultDate = this.getFormatedDate(part.config.defaultDate);
                if (_defaultDate === _date) {
                    part.partData[0].parts.push(offerPart);
                    return part;
                }
            });
            if (!_timedPart) {
                let _newTimedOfferPart = {
                    config: {
                        defaultDate: offerPart.dateCreated,
                    },
                    day: AccommodationUtil.getDayIndex(offerPart.dateTimeFrom) + 1,
                    hasOfferParts: true,
                    partData: [
                        {
                            parts: [offerPart],
                        },
                    ],
                };
                this.filteredTimedOfferParts.push(_newTimedOfferPart);
            }
        } else {
            let _timelessPart = this.filteredTimelessOfferParts.find((part) => {
                let _defaultDate = this.getFormatedDate(part.config.defaultDate);
                if (_defaultDate === _date) {
                    part.partData[0].parts.push(offerPart);
                    return part;
                }
            });
            if (!_timelessPart) {
                let _newTimelessOfferPart = {
                    config: {
                        defaultDate: offerPart.dateCreated,
                    },
                    hasOfferParts: true,
                    partData: [
                        {
                            parts: [offerPart],
                        },
                    ],
                };
                this.filteredTimelessOfferParts.push(_newTimelessOfferPart);
            }
        }
    }

    anyOfferInBookedLockedBySupplierState() {
        return this.request.requestOffers.some((offer) => offer.status.name === 'BOOKED_LOCKED_BY_SUPPLIER');
    }

    //creator applies changes done in BOOKED_LOCKED_BY_CREATOR state
    applyBookedLockedStateChanges(offer) {
        const { $scope, $timeout, EventEnum, RequestOffer } = this._injected;
        $scope.startProgress(this.request.id);
        RequestOffer.applyBookedLockedStateChanges(
            offer.id,
            (data) => {
                this.updateOfferAndOfferParts(data);
                let requestId = this.request.id;
                $scope.$broadcast('update:offer:in:offer:part:option', {
                    requestOffers: data.requestOffers,
                });
                $timeout(function () {
                    $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                        requestId: requestId,
                        offerId: offer.id,
                    });
                });
            },
            $scope.endWithErrorOverlay(this.request.id)
        );
    }

    //send all the editing done by creator in BOOKED_WAITING_FOR_CREATOR state
    applyCreatorEditing(closeModal = false) {
        const { $scope, $timeout, EventEnum, Request, RequestOfferPart, NotificationService } = this._injected;
        let _offerPartsAndOptionsWithActions = {
            offerPartsWithActions: this.offerPartsWithActions,
            offerPartOptionsWithActions: this.offerPartOptionsWithActions,
        };
        if (this.offerPartsWithActions.length > 0 || this.offerPartOptionsWithActions.length > 0) {
            $scope.startProgress(this.request.id);
            let endWithErrorOverlay = $scope.endWithErrorOverlay(this.request.id);
            RequestOfferPart.applyCreatorActionsOnSupplierChanges(
                this.offer.id,
                _offerPartsAndOptionsWithActions,
                () => {
                    $scope.endProgress(this.request.id);
                    $scope.startProgress(this.request.id);
                    Request.getStructureForDetail(
                        {
                            requestId: this.request.id,
                            offerId: this.offer.id,
                        },
                        (response) => {
                            this.processRequestOffers(response);
                            $timeout(() => {
                                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                                    requestId: this.request.id,
                                    offerId: this.offer.id,
                                });
                            }, 400);
                            if (closeModal) {
                                this.closeAnyWay();
                            }
                        },
                        $scope.endWithErrorOverlay(this.request.id)
                    );
                    NotificationService.setNotificationData(this.event.id);
                },
                (error) => {
                    this.revertAcceptedDeclinedChangedParts();
                    endWithErrorOverlay(error);
                }
            );
        }
    }

    revertAcceptedDeclinedChangedParts() {
        angular.copy(this.originalOfferPartsList, this.offer.requestOfferParts);
        //revert edited contents
        this.offerPartsWithActions = _.without(
            this.offerPartsWithActions,
            _.findWhere(this.offerPartsWithActions, {
                action: 'EDITED_BY_CREATOR',
            })
        );
    }

    applyRowBgColor(offer, part) {
        if (this.isOfferInCanceledState) {
            return part.status.name;
        }
        let _state = tooltipUtil.getPartNotificationState(offer, part);
        return _state;
    }

    exportFinalDetailsFiles(fileStore) {
        let _downloadUrl = '/api/v1/file/getFile/' + fileStore.id;
        const { FileService } = this._injected;
        FileService.downloadFile(_downloadUrl, fileStore.filename);
    }

    approveFinalOffer() {
        const { $scope, EventEnum, RequestOffer } = this._injected;
        // eventController data
        $scope.startProgress(this.request.id);
        RequestOffer.approveFinalOffer(
            { offerId: this.selectedOfferId },
            (response) => {
                this.processRequestOffers(response);
                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                    requestId: this.request.id,
                    offerId: this.offer.id,
                });
                this.showApprovedFinalPricesOverlay = true;
                $scope.endProgress(this.request.id);
            },
            $scope.endWithErrorOverlay(this.request.id)
        );
    }

    approveCancellationFees() {
        const { $scope, EventEnum, RequestOffer } = this._injected;
        // eventController data
        $scope.startProgress(this.request.id);
        RequestOffer.approveCancellationFees(
            { offerId: this.selectedOfferId },
            (response) => {
                this.processRequestOffers(response);
                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                    requestId: this.request.id,
                    offerId: this.offer.id,
                });
                $scope.endProgress(this.request.id);
            },
            $scope.endWithErrorOverlay(this.request.id)
        );
    }

    isAnyPartOptionSelected(offerParts) {
        let _partWithOptions = _.filter(offerParts, (offerPart) => {
            return offerPart.offerPartOptions.length > 0;
        });
        let _optionSelectedOnPart = 0;
        angular.forEach(_partWithOptions, (partWithOption) => {
            let _offerPartOptions = partWithOption.offerPartOptions;
            let _isSelected = _.find(_offerPartOptions, (o) => {
                return o.isSelected === true;
            });
            if (_isSelected) {
                _optionSelectedOnPart += 1;
            }
        });
        return _partWithOptions.length === _optionSelectedOnPart;
    }

    getPriceFromPartOption(offer, offerPart) {
        let _totalPrice = 0;
        let _selectedOfferPartOptions = _.filter(offerPart.offerPartOptions, (offerPartOption) => {
            return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
        });
        angular.forEach(_selectedOfferPartOptions, (_selectedOfferPartOption) => {
            _totalPrice += amountAndPriceService.getRequestOfferPartOptionPrice(
                offer,
                _selectedOfferPartOption,
                offerPart
            );
        });
        return _totalPrice;
    }

    getRequestPartOptionDifference(offer, offerPart) {
        let _totalDifference = 0;
        let _selectedOfferPartOptions = _.filter(offerPart.offerPartOptions, (offerPartOption) => {
            return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
        });
        angular.forEach(_selectedOfferPartOptions, (_selectedOfferPartOption) => {
            _totalDifference += amountAndPriceService.getRequestPartOptionDifference(offer, _selectedOfferPartOption);
        });
        return _totalDifference;
    }

    getRequestPartOptionTotalPrice(offer, offerPart) {
        let _offerPartOptions = _.filter(offerPart.offerPartOptions, (offerPartOption) => {
            return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
        });
        let _totalPrice = 0;
        angular.forEach(_offerPartOptions, (_offerPartOption) => {
            _totalPrice += amountAndPriceService.getRequestPartOptionTotalPrice(offer, _offerPartOption);
        });
        return _totalPrice;
    }

    bookRequestOffer() {
        const { $scope, $rootScope } = this._injected;
        if (!this.billingAddressForm.billingAddressInfo.$valid) {
            CommonFunctions.touchFormFields(this.billingAddressForm.billingAddressInfo);
            return;
        }
        /*
        if (!this.isAnyPartOptionSelected(this.offer.requestOfferParts)) {
            console.log("must select one option")
            return;
        }
        */

        $scope.startProgress(this.eventId);
        this.changeRequestOfferStatus(this.offer, 'BOOKED_WAITING_FOR_CONFIRMATION', undefined, this.billingAddress);
        let _supplier = this.offer.supplier ? this.offer.supplier.name : '';
        let _price = this.getAmountLocalisedWithCurrency(
            this.getOfferTotalPrice(this.offer, this.offer.requestOfferParts),
            this.offer.currency
        );
        $rootScope.$emit('event:user:action', {
            action: 'creator.booked.supplier',
            data: { offer: this.offer.id, supplier: _supplier, revenue: _price },
        });
        $scope.endProgress(this.eventId);
        this.billingAddressForm.billingAddressInfo.$setUntouched();
        this.showBillingInfoSection = false;
        this.disableOfferStatusButton = false;
        // this.clearBillingAddressDetails();
        this.scrollToModalTop();
    }

    //creator cancels changes done in BOOKED_LOCKED_BY_CREATOR state
    cancelBookedLockedStateChanges() {
        const { $rootScope, RequestOffer, Request, $scope, EventEnum } = this._injected;
        let offerIdList = [this.offer.id];
        let eventId = this.event.id;
        $scope.startProgress(this.request.id);
        RequestOffer.bookOfferListInBulk(
            eventId,
            offerIdList,
            () => {
                Request.getStructureForDetail(
                    { requestId: this.request.id, offerId: this.offer.id },
                    (response) => {
                        if (response) {
                            this.processRequestOffers(response);
                            $scope.$broadcast('revert:offer:part:option:changes', {
                                requestOfferParts: response.offerParts,
                            });
                        }
                    },
                    $scope.endWithErrorOverlay(this.request.id)
                );

                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                    requestId: this.request.id,
                    offerId: this.offer.id,
                });
            },
            $rootScope.endWithErrorOverlay
        );

        let negotiationParams = {
            showAlert: false,
            disableOfferStatusButton: false,
        };
        this.prepareParamsForNegotiationAlert(negotiationParams);
    }

    cancelRequestOffer() {
        this.changeRequestOfferStatus(this.offer, 'CANCELED');
        let params = { showAlert: false };
        this.prepareParamsForNegotiationAlert(params);
        this.offerHasBeenAnswered = false;
        this.setActiveTab('OFFER_CONTENT');
    }

    canDeleteOffer(offer, offerCount) {
        const { RequestOfferStatusUtils } = this._injected;
        if (offer.statusDetailed.name !== 'INTERNAL') {
            let statusLogs = offer.statusLogs;
            let _offerPreviouslyBooked = statusLogs.some(
                (log) => RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(log) !== -1
            );
            return offer && offer.deletable && offerCount >= 1 && !_offerPreviouslyBooked;
        }
    }

    openConfirmationModal() {
        const { ModalService, AuthenticationService } = this._injected;
        var vm = this;
        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
            return;
        }

        let confirmationModalReference = conformationModal;
        let data = {
            message: 'dialog.notification.resend.request.conformation',
            canAddCustomMessage: true,
            okCallBack: function (response) {
                vm.resendRequest(response);
            },
            okLabel: 'dialog.notification.send',
        };
        confirmationModalReference.inputs = { data };
        ModalService.showModal(confirmationModalReference).then((modal) => {
            modal.element.modal();
            modal.close.then(() => {
                console.log('modal is closed!');
            });
        });
    }

    resendRequest(msg) {
        let data = { offer: this.offer, status: 'SENT' };
        this.isResendRequest = true;
        this.resendRequestMsg = msg;
        this.changeOfferStatus(data);
    }

    changeOfferStatus(data) {
        const { RequestOffer, AuthenticationService, NotificationService } = this._injected;
        this.showResourceConfirmation = false;
        if (!AuthenticationService.isUserVerified()) {
            let params = {
                showAlert: true,
                alertMessage: 'dialog.user.not.activated',
                okCallback: this.acceptAccountVerificationAlert,
                disableOfferStatusButton: true,
            };
            this.prepareParamsForNegotiationAlert(params);
            return;
        }

        if (data) {
            let offer = data.offer;
            offer.isFromNotification = data.isFromNotification;

            if (this.checkBeforeUpdate(data)) {
                var status = data.status;
                switch (status) {
                    case 'SENT':
                        if (offer.statusDetailed.name === 'EDITED_BY_CREATOR') {
                            this.changeRequestOfferStatus(offer, 'SENT');
                        } else {
                            offer.supplierMessage = this.requestSupplierMessage;
                            if (
                                this.isNonCommissionAgreementPricesSettingEnabledInUserGroupFn() &&
                                !offer.agreementNumber && offer.supplier.hasHotellOrLokalerCategory
                            ) {
                                this.updateAgreementNumberModal = true;
                            } else {
                                this.changeRequestOfferStatus(offer, 'SENT', offer.supplierMessage);
                            }
                        }
                        break;
                    case 'BOOKED':
                        if (offer.statusDetailed.name === 'BOOKED_LOCKED_BY_CREATOR') {
                            let params = {
                                showAlert: true,
                                alertMessage: 'request.offer.part.changes.cancel.notification',
                                okCallback: this.cancelBookedLockedStateChanges,
                                cancelCallback: this.revertBookedLockedStateChangesCancellation,
                                disableOfferStatusButton: true,
                            };
                            this.prepareParamsForNegotiationAlert(params);
                        } else if (offer.statusDetailed.name === 'BOOKED_LOCKED_BY_SUPPLIER') {
                            this.offerToUnlock = offer;
                            this.unlockBookedLockedBySupplier();
                        } else if (offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
                            angular.forEach(this.offer.requestOfferParts, (n) => {
                                if (this.isOfferPartInCreatorWaitingStates(n) && n.hasRequestOfferPartOptions) {
                                    this.acceptOfferPart(n);
                                }
                            });
                            if (this.disableSendActionForCreator()) {
                                this.scrollToAlertSection();
                                this.actionForSupplierChangesPending = true;
                            } else {
                                this.applyCreatorEditing();
                            }
                        } else {
                            this.changeRequestOfferStatus(offer, status);
                        }
                        break;
                    case 'BOOKED_WAITING_FOR_SUPPLIER':
                        this.applyBookedLockedStateChanges(offer);
                        break;
                    case 'BOOKED_LOCKED_BY_CREATOR':
                        this.doOfferReEditing(offer);
                        break;
                    case 'CANCELED':
                        let params = {
                            showAlert: true,
                            alertMessage: 'request.offer.cancel.notification',
                            okCallback: this.cancelRequestOffer,
                            cancelCallback: this.undoOfferCancellation,
                            disableOfferStatusButton: true,
                            canAddCustomMessage: true,
                            userMessage: this.userMessage,
                        };
                        this.prepareParamsForNegotiationAlert(params);
                        this.scrollToOfferNegotiationAlertMessage();
                        break;
                    case 'BOOKED_WAITING_FOR_CONFIRMATION':
                        if (!this._isAlreadyBooked(offer)) {
                            this.showResourceSelectionConfirmation();
                            this.getBillingAddress(offer.id);
                            this.scrollToBookOfferForm();
                        } else {
                            this.changeRequestOfferStatus(offer, status);
                        }
                        break;

                    case 'RE_OPEN_OFFER':
                        this.openConfirmationModal();
                        break;
                    default:
                        this.changeRequestOfferStatus(offer, status);
                        break;
                }
            }
        }
    }

    showResourceSelectionConfirmation() {
        if (this.offer.requestOfferParts.length > 0) {
            let _hasResource = _.filter(this.offer.requestOfferParts, (item) => {
                return item.hasRequestOfferPartOptions;
            });
            if (_hasResource) {
                let _containsResourceOptions = _.find(_hasResource, (options) => {
                    return options.offerPartOptions.length > 0;
                });
                if (_containsResourceOptions) {
                    this.resourceOfferParts = _hasResource;
                    this.showResourceConfirmation = true;
                }
            }
        }
    }

    toggleResourceOptionSelected() {
        const { $scope, RequestOfferPartOption, $rootScope, EventEnum } = this._injected;
        if (this.offerPartOptionsChangeList.length > 0) {
            $scope.startProgress(this.request.id);
            let _params = { offerPartOptionsChangeList: this.offerPartOptionsChangeList };
            RequestOfferPartOption.updatePartOptionListSelection(
                _params,
                (resp) => {
                    this.offer.requestOfferParts.forEach((p) => {
                        if (p.hasRequestOfferPartOptions) {
                            angular.forEach(p.offerPartOptions, (requestOfferPartOption) => {
                                angular.forEach(this.offerPartOptionsChangeList, (changedOfferPartOption) => {
                                    if (requestOfferPartOption.id === changedOfferPartOption.id) {
                                        requestOfferPartOption.isSelected = changedOfferPartOption.isSelected;
                                    }
                                });
                            });
                        }
                    });
                    $rootScope.$broadcast(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                    $rootScope.$broadcast('part:option:list:is:selected:value:changed', {
                        offerId: this.offer.id,
                        offerPartOptionsChangeList: this.offerPartOptionsChangeList,
                    });
                    this.showResourceConfirmation = false;
                    this.offerPartOptionsChangeList = [];
                    $scope.endProgress(this.request.id);
                },
                $scope.endWithErrorOverlay()
            );
        } else {
            this.showResourceConfirmation = false;
        }
    }

    cancelToggleResourceOptionSelected() {
        this.offer.requestOfferParts.forEach((p) => {
            if (p.hasRequestOfferPartOptions) {
                angular.forEach(p.offerPartOptions, (requestOfferPartOption) => {
                    angular.forEach(this.originalOfferPartOptionsList, (originalOfferPartOption) => {
                        if (requestOfferPartOption.id === originalOfferPartOption.id) {
                            requestOfferPartOption.isSelected = originalOfferPartOption.isSelected;
                        }
                    });
                });
            }
        });
        this.undoBookRequestOffer();
        this.showResourceConfirmation = false;
        this.offerPartOptionsChangeList = [];
    }

    addToResourceOptionChangeList(offerPartOption) {
        let _offerPartOption = _.find(this.offerPartOptionsChangeList, (o) => {
            return o.id === offerPartOption.id;
        });
        if (_offerPartOption) {
            let _index = _.findIndex(this.offerPartOptionsChangeList, { id: offerPartOption.id });
            this.offerPartOptionsChangeList.splice(_index, 1);
        }
        let _data = { id: offerPartOption.id, isSelected: offerPartOption.isSelected };
        this.offerPartOptionsChangeList.push(_data);
    }

    _isAlreadyBooked(offer) {
        let _statusLogs = offer.statusLogs;
        let _isAlreadyConfirmed = _statusLogs.indexOf('BOOKED') > -1;
        return _isAlreadyConfirmed;
    }

    expandAdditionalQuestionsPart(partsToExpand) {
        const { $timeout } = this._injected;
        let part = partsToExpand[0];
        this.offerPartId = 'd' + part.id;
        this.changeRowId();
        let previewBody = jQuery('#offer-preview, #offer-preview-modal-body');
        previewBody.scrollTop(0);
        $timeout(() => {
            let _elementId = '#content-row-' + part.id;
            let _topHeight = jQuery(_elementId).position().top;
            previewBody.animate({ scrollTop: _topHeight }, 'slow');
        }, 200);
        this.addRequiredLabel(part);
    }

    requiredUnanswered() {
        let { DynamicFormUtilService } = this._injected;
        if (this.offer) {
            let _parts = this.offer.requestOfferParts;
            return DynamicFormUtilService.requiredUnanswered(_parts);
        }
        return false;
    }

    addRequiredLabel(offerPart) {
        let listValues = offerPart.list;
        //Doesnot have additional questions
        if (listValues && listValues.length < 1) {
            return true;
        }
        angular.forEach(listValues, (question) => {
            if (question.required && !question.fieldValue) {
                question.requiredLabel = 'Required field';
            }
        });
    }

    _updateOffer(offer, params) {
        const { $scope, EventEnum, RequestOffer } = this._injected;
        this.offer.agreementNumber = this.agreementNumber;
        $scope.startProgress(this.request.id);
        RequestOffer.update(
            params,
            (response) => {
                this.processRequestOffers(response);
                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                    requestId: this.request.id,
                    offerId: offer.id,
                });
                this.shouldShowBillingInfo();
            },
            $scope.endWithErrorOverlay(this.request.id)
        );
    }

    changeRequestOfferStatus(offer, status, supplierMessage, billingAddress) {
        const { $rootScope } = this._injected;
        var params = {
            eventId: this.eventId,
            requestId: this.requestId,
            offerId: offer.id,
            status: status,
        };
        if (supplierMessage !== null) {
            params.supplierMessage = supplierMessage;
        }
        if (billingAddress !== null) {
            params.billingAddress = billingAddress;
        }
        if (this.isResendRequest) {
            params.isResendRequest = true;
            params.message = this.resendRequestMsg;
        }
        if (this.userMessage) {
            params.userMessage = this.userMessage;
        }
        if (status === 'SENT') {
            let { DynamicFormUtilService } = this._injected;
            let _parts = offer.offerParts;
            let isRequiredFieldUnanswered = false;
            angular.forEach(this.offer.requestOfferParts, (parentList) => {
                if (parentList.list) {
                    angular.forEach(parentList.list, (list) => {
                        if (list.type.name !== 'TEXT_WITH_AMOUNT_TYPE') {
                            let _strLogicToShow = list.showHide;
                            if (!_strLogicToShow) {
                                if (list.required && !list.fieldValue) {
                                    isRequiredFieldUnanswered = true;
                                }
                            } else {
                                let stringArray = _strLogicToShow.split('~');
                                let shouldShow = stringArray[0] === 'SHOWON';
                                let shouldHide = stringArray[0] === 'HIDEON';
                                let _reference = stringArray[1];
                                if (shouldShow) {
                                    let _question = _.find(parentList.list, (item) => {
                                        return item.field === _reference;
                                    });
                                    if (_question.fieldValue) {
                                        if (list.required && !list.fieldValue) {
                                            isRequiredFieldUnanswered = true;
                                        }
                                    }
                                }
                                if (shouldHide) {
                                    let _question = _.find(parentList.list, (item) => {
                                        return item.field === _reference;
                                    });
                                    if (!_question.fieldValue) {
                                        if (list.required && !list.fieldValue) {
                                            isRequiredFieldUnanswered = true;
                                        }
                                    }
                                }
                            }
                        }
                    });
                }
            });
            DynamicFormUtilService.checkRequired(
                _parts,
                this.expandAdditionalQuestionsPart,
                isRequiredFieldUnanswered
            ).then((result) => {
                if (result) {
                    this._updateOffer(offer, params);
                }
            });
        } else {
            this._updateOffer(offer, params);
        }

        if (status === 'SENT') {
            $rootScope.$emit('event:user:action', {
                action: 'sent.offer',
                data: {
                    'Event id': this.eventId,
                    'Module name': this.request.name,
                    'Module id': this.requestId,
                    'Supplier id': this.offer.supplier.id,
                    'Offer id': offer.id,
                },
            });
            //   $scope.$emit('request:sent');
        } else if (status === 'BOOKED') {
            $rootScope.$emit('event:user:action', {
                action: 'booked.offer',
                data: {
                    'Event id': this.eventId,
                    'Module name': this.request.name,
                    'Module id': this.requestId,
                    'Supplier id': this.offer.supplier.id,
                    'Offer id': offer.id,
                },
            });
        }
    }

    changeRowId() {
        const { $rootScope } = this._injected;
        $rootScope.offerPartEditId = this.offerPartId;
    }

    //broadcast repeating execution too many times due to multiple times initializing this controller issue fixed
    // by this method;

    checkBeforeUpdate(data) {
        var access;
        if (!angular.isDefined(data.isFromNotification)) {
            access = this.selectedOfferId == data.offer.id;
        } else {
            access = data.offer.request.id == this.offer.request.id;
        }
        return access;
    }

    checkIfActionHasBeenTakenForAllSupplierChanges() {
        const { $scope } = this._injected;
        const acceptableOfferPartStatusesToRespondSupplier = [
            'ACCEPTED',
            'DECLINED',
            'EDITED_WAITING_FOR_SUPPLIER',
            'ADDED_WAITING_FOR_CREATOR_DECLINED',
            'ACCEPTED_BY_CREATOR_WITH_CHANGES',
            'NOT_DELIVERABLE',
            'DELETED_WAITING_FOR_CREATOR_DECLINED',
            'EDITED_WAITING_FOR_SUPPLIER_DECLINED',
        ];
        let offerCanBeSent = this.offer.requestOfferParts.every(
            (offerPart) => acceptableOfferPartStatusesToRespondSupplier.indexOf(offerPart.status.name) > -1
        );
        if (offerCanBeSent) {
            $scope.$emit('action:taken:for:all:supplier:changes', { offer: this.offer });
        }
    }

    static checkIfFieldValueChanged(offerPart, originalOfferPart) {
        if (!offerPart.id) {
            return true;
        }
        let _additionalFieldValueChanged = false;
        if (offerPart.list && originalOfferPart.list) {
            angular.forEach(offerPart.list, (partListValue) => {
                angular.forEach(originalOfferPart.list, (originalPartListValue) => {
                    if (
                        partListValue.id === originalPartListValue.id &&
                        partListValue.fieldValue !== originalPartListValue.fieldValue &&
                        !_additionalFieldValueChanged
                    ) {
                        _additionalFieldValueChanged = true;
                    }
                });
            });
        }
        return (
            offerPart.name != originalOfferPart.name ||
            offerPart.description != originalOfferPart.description ||
            offerPart.amount != originalOfferPart.amount ||
            offerPart.amountType != originalOfferPart.amountType.name ||
            offerPart.dateTimeFrom != originalOfferPart.dateTimeFrom ||
            offerPart.dateTimeTo != originalOfferPart.dateTimeTo ||
            _additionalFieldValueChanged
        );
    }

    clearAttachedFiles() {
        this.attachedFiles = { uploads: null, links: null };
        this.clearAll = true;
    }

    clearBillingAddressDetails() {
        this.billingAddress = '';
    }

    clearMessageNotesSection() {
        this.messageBody = '';
        this.clearAttachedFiles();
    }

    closeAnyWay() {
        const { close, $element, ModalStatusStore, ModalName } = this._injected;
        //set the modal as closed
        ModalStatusStore.setModalClosed(ModalName.OFFER_PREVIEW_MODAL);

        $element.modal('hide');
        close(null, 500);
    }

    confirmDeleteRequestOfferPart(offerPart) {
        const { $translate, $rootScope } = this._injected;
        if (offerPart.editable.name === 'DENIED' || this.offer.statusDetailed.name === 'CANCELED') {
            return;
        }
        this.selectedRequestOfferPart = offerPart;
        let offerInBookedLockedBySupplierState = this.anyOfferInBookedLockedBySupplierState();
        if (!offerInBookedLockedBySupplierState) {
            this.message = $translate.instant('request.offer.part.delete.notification');
            if (CommonFunctions.doAlertBeforeUpdate(offerPart)) {
                this.message =
                    $translate.instant('request.offer.part.delete.notification.warning') + '<br />' + this.message;
            }
        }
        offerPart.showDeleteConfirmation = !offerPart.showDeleteConfirmation;
        $rootScope.$broadcast('confirm:delete:toggle:part:option', { partId: offerPart.id });
    }

    createMessage() {
        if (!this.messageForm.newMessageForm.$valid) {
            CommonFunctions.touchFormFields(this.messageForm.newMessageForm);
            return;
        }
        let compiledMessageBody = this.messageBody;
        let plainMessage = this.htmlToPlaintext(compiledMessageBody);
        if (!plainMessage || plainMessage.length <= 0 || plainMessage.charCodeAt(0) === 8203) {
            return;
        }
        this.loading = true;
        if (this.attachedFiles.links !== null && this.attachedFiles.links.length > 0) {
            compiledMessageBody += '\n\n';
            angular.forEach(this.attachedFiles.links, function (link) {
                compiledMessageBody +=
                    '<a href="' +
                    link.fileUrl +
                    '" target="_blank"><i class="glyphicon glyphicon-paperclip"></i> ' +
                    link.name +
                    '</a>' +
                    '\n';
            });
        }
        const { Activity } = this._injected;
        Activity.save(
            {
                eventId: this.event.id,
                requestId: this.request.id,
                offerId: this.selectedOfferId,
                messageBody: compiledMessageBody,
                privateMessage: false,
                attachedFiles: this.attachedFiles.uploads,
            },
            this.createMessageNoteCallback
        );
    }

    htmlToPlaintext(text) {
        let plainText = text ? String(text).replace(/<[^>]+>/gm, '') : '';
        return plainText.trim();
    }

    createMessageNoteCallback(response) {
        if (this.showActivites) {
            this.newActivity = response;
        } else {
            this.showActivites = true;
        }
        this.loading = false;
        this.messageForm.newMessageForm.$setPristine();
        this.messageForm.newMessageForm.$setUntouched();
        this.clearMessageNotesSection();
        this.getMessageCount();
    }

    createNote() {
        if (!this.messageForm.newMessageForm.$valid) {
            CommonFunctions.touchFormFields(this.messageForm.newMessageForm);
            return;
        }
        this.loading = true;
        this.showActivites = true;

        let compiledMessageBody = this.messageBody;
        const { Note } = this._injected;
        Note.save(
            {
                eventId: this.event.id,
                requestId: this.request.id,
                offerId: this.selectedOfferId,
                messageBody: compiledMessageBody,
            },
            this.createMessageNoteCallback
        );
    }

    createPart(data) {
        //TODO(KB) create button is disabled on BOOKED LOCKED BY SUPPLIER state
        if (this.offer.statusDetailed.name == 'BOOKED_LOCKED_BY_SUPPLIER') {
            this.offerPartToCreate = data;
            this.unlockBookedLockedBySupplierAndApplyCreatorChanges(this.offer);
        } else {
            this.createRequestOfferPart(data);
        }
    }

    createRequestOfferPart(data) {
        const { $scope, RequestOfferPart } = this._injected;
        $scope.startProgress(this.request.id);
        RequestOfferPart.createByRequestOfferId(
            this.offer.id,
            data,
            this.refreshAndProcessRequestOffers.bind(this),
            $scope.endWithErrorOverlay(this.request.id)
        );
        //   this.insertUpdateFlag = true;
    }

    declineOfferPart(offerPart) {
        var offerPartMap = { offerPart: offerPart, action: 'DECLINED_BY_CREATOR' };
        this.removeOfferPartFromMap(offerPart);
        this.offerPartsWithActions.push(offerPartMap);

        var originalOfferPart = this.getOriginalOfferPart(offerPart);
        OfferPreviewModalCtrl.reassignOriginalValuesToOfferPart(offerPart, originalOfferPart);
        if (originalOfferPart.status.name == 'EDITED_WAITING_FOR_CREATOR') {
            offerPart.status.name = 'DECLINED';
        }
        if (originalOfferPart.status.name == 'ADDED_WAITING_FOR_CREATOR') {
            offerPart.status.name = 'ADDED_WAITING_FOR_CREATOR_DECLINED';
        }
        if (originalOfferPart.status.name == 'DELETED_WAITING_FOR_CREATOR') {
            offerPart.status.name = 'DELETED_WAITING_FOR_CREATOR_DECLINED';
        }
        this.flagForAcceptedWithChanges = undefined;

        this.checkIfActionHasBeenTakenForAllSupplierChanges();
        if (this.offer.status.name === 'BOOKED_WAITING_FOR_CREATOR' && !this.disableSendActionForCreator()) {
            this.disableOfferStatusButton = false;
        }
    }

    offerPartOptionsWithActionsCallback(offerPartOptionsWithActions) {
        this.offerPartOptionsWithActions.push(offerPartOptionsWithActions);
        if (this.offer.status.name === 'BOOKED_WAITING_FOR_CREATOR' && !this.disableSendActionForCreator()) {
            this.disableOfferStatusButton = false;
        }
    }

    deleteOffer() {
        const { EventEnum, RequestOffer, $scope, $rootScope } = this._injected;
        this.deleteOfferConfirmation = false;
        RequestOffer['delete'](
            this.offer.id,
            function (response) {
                $rootScope.$broadcast(EventEnum.SUPPLIER_REMOVED_FROM_PANEL, response);
                $rootScope.$broadcast(EventEnum.EVENT_DETAIL_PAGE_RELOAD);
            },
            $scope.endWithErrorOverlay()
        );
        this.close();
    }

    deleteOfferPart() {
        if (this.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
            if (this.selectedRequestOfferPart.id) {
                let offerPartMap = { offerPart: this.selectedRequestOfferPart, action: 'DELETED_BY_CREATOR' };
                this.removeOfferPartFromMap(this.selectedRequestOfferPart);
                this.offerPartsWithActions.push(offerPartMap);

                let originalOfferPart = this.getOriginalOfferPart(this.selectedRequestOfferPart);
                OfferPreviewModalCtrl.reassignOriginalValuesToOfferPart(
                    this.selectedRequestOfferPart,
                    originalOfferPart
                );
                this.selectedRequestOfferPart.oldName = originalOfferPart.oldName;
                this.selectedRequestOfferPart.oldDescription = originalOfferPart.oldDescription;
                this.selectedRequestOfferPart.oldAmount = originalOfferPart.oldAmount;
                this.selectedRequestOfferPart.oldAmountType = originalOfferPart.oldAmountType;
                this.selectedRequestOfferPart.oldDateTimeFrom = originalOfferPart.oldDateTimeFrom;
                this.selectedRequestOfferPart.oldDateTimeTo = originalOfferPart.oldDateTimeTo;
                this.selectedRequestOfferPart.oldPrice = originalOfferPart.oldPrice;
                this.selectedRequestOfferPart.status.name = 'DELETED_WAITING_FOR_SUPPLIER';

                this.ROPUndoDeleteId = 'undelete' + this.selectedRequestOfferPart.id;
            } else {
                this.offer.requestOfferParts.forEach((n, i) => {
                    if (this.selectedRequestOfferPart == n) {
                        this.offer.requestOfferParts.splice(i, 1);
                    }
                });
            }
        } else if (this.offer.statusDetailed.name === 'BOOKED_LOCKED_BY_SUPPLIER') {
            this.isOfferPartToDelete = true;
            this.unlockBookedLockedBySupplierAndApplyCreatorChanges(this.offer);
        } else {
            this.deleteRequestOfferPart();
        }
    }

    deleteRequestOfferPart() {
        const { $scope, RequestOfferPart } = this._injected;
        $scope.startProgress(this.request.id);
        //   this.insertUpdateFlag = false;

        RequestOfferPart['delete'](
            this.selectedRequestOfferPart.id,
            this.refreshAndProcessRequestOffers.bind(this),
            $scope.endWithErrorOverlay(this.request.id)
        );
    }

    disableOfferPartInfo(offerPart) {
        if (
            ['BOOKED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_CREATOR'].indexOf(this.offer.statusDetailed.name) > -1
        ) {
            if (offerPart.status.name === 'ADDED_BY_CREATOR') {
                this.ROPInfo = undefined;
                return;
            }
            if (
                this.isOfferPartInCreatorWaitingStates(offerPart) ||
                this.isOfferPartInSupplierWaitingStates(offerPart) ||
                this.isOfferPartInSupplierDeclinedStates(offerPart)
            ) {
                this.ROPInfo = undefined;
            }
        }
    }

    disableSendActionForCreator() {
        let sendButtonDisabled = false;
        this.offer.requestOfferParts.forEach((offerPart) => {
            if (
                this.isOfferPartInToAnswerStates(offerPart) &&
                !sendButtonDisabled &&
                !offerPart.hasRequestOfferPartOptions
            ) {
                let rowIsInRowAndActionMap = false;
                this.offerPartsWithActions.forEach((offerPartWithAction) => {
                    if (!rowIsInRowAndActionMap) {
                        if (offerPart.id === offerPartWithAction.offerPart.id) {
                            rowIsInRowAndActionMap = true;
                        }
                    }
                });
                if (!rowIsInRowAndActionMap) {
                    sendButtonDisabled = true;
                }
            }
        });
        if (!sendButtonDisabled) {
            this.offer.requestOfferParts.forEach((offerPart) => {
                if (offerPart.hasRequestOfferPartOptions) {
                    angular.forEach(offerPart.offerPartOptions, (offerPartOption) => {
                        if (this.isOfferPartOptionInToAnswerStates(offerPartOption) && !sendButtonDisabled) {
                            let rowOptionIsInRowAndActionMap = false;
                            angular.forEach(this.offerPartOptionsWithActions, function (offerPartOptionWithAction) {
                                if (!rowOptionIsInRowAndActionMap) {
                                    if (offerPartOption.id === offerPartOptionWithAction.offerPartOption.id) {
                                        rowOptionIsInRowAndActionMap = true;
                                    }
                                }
                            });
                            if (!rowOptionIsInRowAndActionMap) {
                                sendButtonDisabled = true;
                            }
                        }
                    });
                }
            });
        }
        return sendButtonDisabled;
    }

    displayDiffCol(offer, parts) {
        this.showDiffColumn = offer.statusDetailed.name === 'FINISHED_WAITING_FOR_CREATOR';
    }

    displayOfferPartChangeToolTip(offerPart, oldValue, newValue) {
        if (
            this.fieldValueHasBeenChanged(offerPart, oldValue, newValue) &&
            !this.displayOfferPartChangeSummary(offerPart)
        ) {
            return !this.hideToolTipForGivenPartStatuses(offerPart.status);
        }
        return false;
    }

    displayOfferPartChangeToolTipForName(offerPart) {
        if (this.hasNameChanged(offerPart) && !this.displayOfferPartChangeSummary(offerPart)) {
            return !this.hideToolTipForGivenPartStatuses(offerPart.status);
        }
        return false;
    }

    displayOfferPartChangeToolTipForDescription(offerPart) {
        if (this.hasDescriptionChanged(offerPart) && !this.displayOfferPartChangeSummary(offerPart)) {
            return !this.hideToolTipForGivenPartStatuses(offerPart.status);
        }
        return false;
    }

    displayFieldChangedForDate(offerPart) {
        if (this.fieldChangedForDate(offerPart) && !this.displayOfferPartChangeSummary(offerPart)) {
            return !this.hideToolTipForGivenPartStatuses(offerPart.status);
        }
        return false;
    }

    displayOfferPartChangeToolTipForAdditionalQuestionsBeforeSend(offerPart) {
        if (this.hasAdditionalInfoChangedBeforeSend(offerPart.id) && !this.displayOfferPartChangeSummary(offerPart)) {
            return !this.hideToolTipForGivenPartStatuses(offerPart.status);
        }
        return false;
    }

    displayOfferPartChangeToolTipForAdditionalQuestionsAfterSend(offerPart) {
        if (this.hasAdditionalInfoChangedAfterSend(offerPart.id) && !this.displayOfferPartChangeSummary(offerPart)) {
            return !this.hideToolTipForGivenPartStatuses(offerPart.status);
        }
        return false;
    }

    showTooltipAdditionalQuestionsChanged(offerPart) {
        let afterSent =
            this.displayOfferPartChangeToolTipForAdditionalQuestionsAfterSend(offerPart) &&
            (offerPart.offer.status.name === 'SENT' || offerPart.offer.status.name === 'BOOKED_WAITING_FOR_SUPPLIER');
        let beforeSent =
            this.displayOfferPartChangeToolTipForAdditionalQuestionsBeforeSend(offerPart) &&
            offerPart.status.name === 'EDITED_BY_CREATOR';
        return afterSent || beforeSent;
    }

    getTooltipValueAdditionalQuestions(offerPart) {
        const { $translate } = this._injected;
        let msgString;
        let afterSent =
            this.displayOfferPartChangeToolTipForAdditionalQuestionsAfterSend(offerPart) &&
            (offerPart.offer.status.name === 'SENT' || offerPart.offer.status.name === 'BOOKED_WAITING_FOR_SUPPLIER');
        let beforeSent =
            this.displayOfferPartChangeToolTipForAdditionalQuestionsBeforeSend(offerPart) &&
            offerPart.status.name === 'EDITED_BY_CREATOR';
        if (afterSent) {
            let oldValueString = '<h6>' + $translate.instant('common.old.value') + ':' + '</h6>';
            msgString = oldValueString.concat(this.getChangesAfterSend(offerPart));
        }
        if (beforeSent) {
            let oldValueString = '<h6>' + $translate.instant('common.old.value') + ':' + '</h6>';
            msgString = oldValueString.concat(this.getChangesBeforeSend(offerPart));
        }
        return msgString;
    }

    getTooltipValuePartSpanSeveralDays() {
        const { $translate } = this._injected;
        return $translate.instant('common.tooltip.part.span.for.several.days');
    }

    getTooltipValueName(offerPart) {
        const { TooltipValueService } = this._injected;
        return TooltipValueService.getTooltipValueName(offerPart);
    }

    getTooltipValueAmount(offerPart) {
        const { TooltipValueService } = this._injected;
        let oldAmount = this.getOldValueForChangedTitle(offerPart, 'AMOUNT');
        return TooltipValueService.getTooltipValueAmount(offerPart, oldAmount);
    }

    getTooltipValueAfterFinished(offerPart, type) {
        const { TooltipValueService } = this._injected;
        let oldString;
        let newString;
        switch (type) {
            case 'AMOUNT':
                oldString = this.getOldValueForChangedTitle(offerPart, type);
                newString = amountAndPriceService.getAmount(this.offer, offerPart);
                break;
            case 'PRICE':
                oldString = this.viewAmountLocalisedWithCurrency(
                    this.getOldValueForChangedTitle(offerPart, type),
                    this.offer.currency
                );
                newString = this.viewAmountLocalisedWithCurrency(
                    amountAndPriceService.getPrice(this.offer, offerPart),
                    this.offer.currency
                );
                break;
        }
        return TooltipValueService.getTooltipValueAfterFinished(oldString, newString, type);
    }

    getTooltipValueDate(offerPart) {
        const { TooltipValueService } = this._injected;
        let params = {
            fromDate: offerPart.oldDateTimeFrom,
            toDate: offerPart.oldDateTimeTo,
            baseDate: this.event.fromDate,
            absolute: true,
            showTime: true,
            isInvitation: false,
        };
        return TooltipValueService.getTooltipValueDate(params);
    }

    getTooltipValuePrice(offerPart) {
        const { TooltipValueService } = this._injected;
        let oldPriceString = this.viewAmountLocalisedWithCurrency(
            this.getOldValueForChangedTitle(offerPart, 'PRICE'),
            this.offer.currency
        );
        return TooltipValueService.getTooltipValuePrice(oldPriceString);
    }

    getTooltipValueInfoMessageByStatus(status) {
        const { TooltipValueService } = this._injected;
        return TooltipValueService.getInfoMessageByStatus(status);
    }

    hideToolTipForGivenPartStatuses(status) {
        if (this.offer.status.name === 'BOOKED_WAITING_FOR_CREATOR') {
            if (_.contains(this.HIDE_CHANGE_TOOLTIP_PART_STATUS, status.name.trim())) {
                return true;
            }
        }
        return false;
    }

    importAccommodationList() {
        const { $rootScope } = this._injected;
        let _downloadUrl = '';
        if ($rootScope.langSelected === 'en_US') {
            _downloadUrl = 'api/v1/file/downloadable/accommodation_list.xlsx';
        } else {
            _downloadUrl = 'api/v1/file/downloadable/boendlista.xlsx';
        }
        DownloadUtilFunctions.downloadFile(_downloadUrl);
    }

    displayMessagesSection() {
        this.showMessagesSection = true;
        // this.showActivites = true;
    }

    doOfferReEditing(offer) {
        const { $scope, Request, RequestOffer } = this._injected;
        RequestOffer.doOfferReEditing(
            offer.id,
            function () {
                $scope.startProgress(this.request.id);
                Request.getStructureForDetail(
                    { requestId: this.requestId, offerId: offer.id },
                    this.processRequestOffers,
                    $scope.endWithErrorOverlay(this.request.id)
                );
            },
            $scope.endWithErrorOverlay(this.request.id)
        );
    }

    doStrikeThrough(offerPart) {
        if (CommonFunctions.hasIntersectValue(DRAW_STRIKE_THROUGH_LINE, [offerPart.status.name])) {
            return true;
        }
        if (offerPart.status.name === 'CANCELED' && this.isOfferInCanceledState) {
            return true;
        }
        if (offerPart.status.name == 'DELETED_WAITING_FOR_CREATOR') {
            return this.offer.statusDetailed.name != 'BOOKED_LOCKED_BY_SUPPLIER';
        }
    }

    enableOfferPartInfo(offerPart) {
        if (
            ['BOOKED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_CREATOR'].indexOf(this.offer.statusDetailed.name) > -1
        ) {
            //removed because when creator makes own changes, the info popup can't be seen on hover
            //TODO check if this results in other issues KBajracharya
            /*if(offerPart.status.name == 'EDITED_WAITING_FOR_SUPPLIER'){
             $scope.ROPInfo = 0;
             return;
             }*/
            if (offerPart.status.name === 'ADDED_BY_CREATOR') {
                //newly added offerpart has no id, so as to make hover result particular to that row only
                this.ROPInfo = 'info' + offerPart.name;
                return;
            }
            if (
                this.isOfferPartInCreatorWaitingStates(offerPart) ||
                this.isOfferPartInSupplierWaitingStates(offerPart) ||
                this.isOfferPartInSupplierDeclinedStates(offerPart)
            ) {
                this.ROPInfo = 'info' + offerPart.id;
            }
        }
    }

    fieldChangedForDate(offerPart) {
        var hasFromDateChanged = OfferPartUtilFunctions.checkOfferPartHasOldValueAndChanged(
            offerPart.oldDateTimeFrom,
            offerPart.dateTimeFrom
        );
        var hasToDateChanged = OfferPartUtilFunctions.checkOfferPartHasOldValueAndChanged(
            offerPart.oldDateTimeTo,
            offerPart.dateTimeTo
        );
        return offerPart.status.name !== 'ADDED_WAITING_FOR_SUPPLIER' && (hasFromDateChanged || hasToDateChanged);
    }

    fieldChangedForPrice(offerPart) {
        if (offerPart.status.name === 'ADDED_BY_CREATOR') {
            return false;
        }
        var originalOfferPart = this.getOriginalOfferPart(offerPart);
        if (originalOfferPart.status.name === 'ADDED_WAITING_FOR_CREATOR') {
            return false;
        }
        let _price = amountAndPriceService.getPrice(this.offer, offerPart);
        let _oldPrice = amountAndPriceService.getOldPrice(this.offer, offerPart);
        if (this.isOfferPartInCreatorWaitingStates(offerPart)) {
            return _price !== _oldPrice;
        } else {
            let _oldPrice = offerPart.oldPrice;
            let _isOldPriceExists = UtilFunctions.isDefinedAndNotNull(_oldPrice);
            let _newPrice = offerPart.price || 0;
            return _isOldPriceExists && _newPrice !== _oldPrice;
        }
    }

    hasAdditionalInfoChangedBeforeSend(offerPartId) {
        let hasAdditionalInfoChangedBeforeSend = false;
        angular.forEach(this.additionalInfoChangesBeforeSendList, (list) => {
            if (!hasAdditionalInfoChangedBeforeSend && list.offerPart.id === offerPartId) {
                hasAdditionalInfoChangedBeforeSend = true;
            }
        });
        return hasAdditionalInfoChangedBeforeSend;
    }

    hasAdditionalInfoChangedAfterSend(offerPartId) {
        let hasAdditionalInfoChangedAfterSend = false;
        angular.forEach(this.additionalInfoChangesAfterSendList, (list) => {
            if (!hasAdditionalInfoChangedAfterSend && list.offerPart.id === offerPartId) {
                hasAdditionalInfoChangedAfterSend = true;
            }
        });
        return hasAdditionalInfoChangedAfterSend;
    }

    getBillingAddress(offerId) {
        const {
            RequestOffer,
            $scope,
            $rootScope,
            $translate,
            UserGroupSettings,
            AuthenticationService,
            ClientService,
        } = this._injected;
        let _userGroupId = AuthenticationService.getUserData().userGroup.id;
        this.getClientName();
        $scope.startProgress(this.request.id);
        UserGroupSettings.get(
            { type: 'MARKING_LABEL', userGroupId: _userGroupId },
            (resp) => {
                this.markingLabel = resp[$rootScope.langSelected];
                if (!this.markingLabel) {
                    this.markingLabel = $translate.instant('common.billing.address.marking');
                }
                $scope.endProgress(this.request.id);
            },
            $scope.endWithErrorOverlay(this.request.id)
        );
        $scope.startProgress(this.request.id);
        RequestOffer.getBillingAddress(
            offerId,
            function (response) {
                this.useEventLogicBillingAddress = response.useEventLogicBillingAddress;
                this.billingAddress = response.billingAddress;

                if (response.useEventLogicBillingAddress) {
                    this.billingAddress.marking = this.event.id + ' / ' + offerId;
                }

                this.showBillingInfoSection = true;
                this.disableOfferStatusButton = true;
                if (this.useEventLogicBillingAddress) {
                    this.disableBillingAddress = true;
                    this.disableMarking = false;
                }
                if (!this.billingAddress) {
                    this.initBillingAddressForm();
                }
                $scope.endProgress(this.request.id);
            }.bind(this),
            $scope.endWithErrorOverlay(this.request.id)
        );
    }

    getConfirmationTriggerType(offerPart) {
        let { PopoverTypeEnum } = this._injected;
        let triggerType = PopoverTypeEnum.CLICK;
        if (offerPart.editable.name === 'DENIED' || this.offer.statusDetailed.name === 'CANCELED') {
            triggerType = PopoverTypeEnum.NONE;
        }
        return triggerType;
    }

    getConfirmationTriggerOnAdd(offer) {
        let { PopoverTypeEnum } = this._injected;
        let triggerType = PopoverTypeEnum.NONE;
        if (CommonFunctions.doAlertBeforeUpdate(offer.offerParts[0])) {
            triggerType = PopoverTypeEnum.CLICK;
        }
        return triggerType;
    }

    getMessageCount() {
        const { Activity, $scope } = this._injected;
        $scope.startProgress(this.request.id);
        Activity.getOfferActivityCount(
            { offerId: this.selectedOfferId, type: 'message' },
            (response) => {
                this.messageCount = response.totalCount;
                $scope.endProgress(this.request.id);
            },
            $scope.endWithErrorOverlay(this.request.id)
        );
    }

    getOldValueForChangedTitle(offerPart, type) {
        let value = 0;
        switch (type) {
            case 'AMOUNT':
                value = amountAndPriceService.getOldAmountValue(this.offer, offerPart);
                break;
            case 'PRICE':
                value = amountAndPriceService.getOldPriceValue(this.offer, offerPart);
                if (amountAndPriceService.shouldIncludeVatOnPrice()) {
                    value = amountAndPriceService.calculatePriceFromPriceAndVat(value, offerPart.vat);
                }
                break;
        }
        return UtilFunctions.isUndefinedOrNull(value) ? 0 : value;
    }

    getOriginalOfferPart(offerPart) {
        let originalOfferPart;
        if (!offerPart) {
            return undefined;
        }
        this.originalOfferPartsList.forEach((n) => {
            if (offerPart.id === n.id) {
                originalOfferPart = n;
            }
        });
        let _originalOfferPart = _.find(this.offer.requestOfferParts, (rop) => {
            return rop.id === offerPart.id;
        });
        if (originalOfferPart && !originalOfferPart.list && _originalOfferPart && _originalOfferPart.list) {
            originalOfferPart.list = _originalOfferPart.list;
        }
        return originalOfferPart;
    }

    cancellationFeeSort(offerPart) {
        if (offerPart && offerPart.nameCode === 'common.translate.offer.part.type.cancellation.fee') {
            return true;
        }
    }

    displayOfferPartChangeSummary(offerPart) {
        const { $rootScope } = this._injected;
        let conditionOne = !(
            $rootScope.offerPartEditId === 'd' + offerPart.id && this.offer.statusDetailed.name !== 'CANCELED'
        );
        let conditionTwo =
            this.ROPInfo === 'info' + offerPart.id ||
            (offerPart.status.name !== 'ADDED_BY_CREATOR' && this.ROPInfo === 'info' + offerPart.name);
        return conditionOne && conditionTwo;
    }

    getOtherOffers() {
        const { $scope, RequestOffer } = this._injected;
        let offerIdList = [this.selectedOfferId];
        $scope.startProgress(this.request.id);
        RequestOffer.getMessageOptions(
            { eventId: this.event.id, offerIdList: offerIdList },
            function (response) {
                this.otherOffers = response.otherOffers;
                if (this.bookDirectly) {
                    this.showResourceSelectionConfirmation();
                    this.getBillingAddress(this.selectedOfferId);
                }
                $scope.endProgress(this.request.id);
            }.bind(this),
            $scope.endWithErrorOverlay
        );
    }

    getEventFinalDetails(offerId) {
        const { $scope, EventFinalDetails, RequestOffer } = this._injected;
        $scope.startProgress(this.request.id);
        let params = {
            eventId: this.event.id,
            offerId: offerId,
            isThereConference: this.isThereConference || this.isThereConferenceCategory,
        };
        EventFinalDetails.getEventFinalDetails(
            params,
            function (response) {
                this.eventFinalDetails = response;

                this.currencyList = response.currencyList;
                this.selectedCurrency = response.selectedCurrency;
                if (this.selectedCurrency) {
                    this.selectedCurrencyId = this.selectedCurrency.id;
                } else {
                    this.selectedCurrency = this.currencyList.find((currency) => currency.iso === 'SEK');
                    this.selectedCurrencyId = this.selectedCurrency.id;
                    RequestOffer.updateCurrency(this.offer.id, { currencyId: this.selectedCurrencyId });
                }
                this.originalEventDetails = angular.copy(response);
                this.hideTime = response.hideTime;
                $scope.endProgress(this.request.id);
            }.bind(this),
            $scope.endWithErrorOverlay
        );
    }

    saveFinalDetails(shared) {
        const { $scope, EventFinalDetails, EventEnum } = this._injected;
        $scope.startProgress(this.request.id);
        this.eventFinalDetails.isThereConference = this.isThereConference || this.isThereConferenceCategory;
        let params = {
            eventId: this.event.id,
            offerId: this.selectedOfferId,
            eventFinalDetails: this.eventFinalDetails,
            offerParts: this.offer.requestOfferParts,
            attachedFile: this.attachedFile,
            categories: this.request.categories,
            module: 'FINAL_DETAILS',
            finalDetailsShared: shared,
            hideTime: this.hideTime,
        };
        EventFinalDetails.saveEventFinalDetails(
            params,
            function (response) {
                this.originalEventDetails = angular.copy(response);
                this.eventFinalDetails = response;
                this.additionalInfoUpdated = false;
                this.checkPartInfoChanged = false;
                this.attachedFile = null;
                this.hideTime = false;
                if (response.finalDetailsSharedDate) {
                    this.showSuccessMessage = true;
                    $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                        requestId: this.request.id,
                        offerId: this.offer.id,
                    });
                } else {
                    this.showSaveSuccessMessage = true;
                }
                $scope.endProgress(this.request.id);
            }.bind(this),
            $scope.endWithErrorOverlay
        );
    }

    isOfferInBookedState(offer) {
        const { RequestOfferStatusUtils } = this._injected;
        return RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(offer.status.name) > -1;
    }

    isOfferInFinishedOrApprovedState(offer) {
        const { RequestOfferStatusUtils } = this._injected;
        return RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf(offer.status.name) > -1;
    }

    getResponsibleUser() {
        // checks if event responsibleUser is null before defining name attribute on it
        if (this.event.responsibleUser) {
            return this.event.responsibleUser.name;
        }
    }

    hasCommentOnPart(part) {
        if (part.commentByCreator) {
            return true;
        }
        return part.comments && part.comments[0].hasSupplierComment;
    }

    getDynamicCommentIconClass(part) {
        if (part.defaultCommentByCreator) {
            return 'fa fa-commenting danger';
        }
        if (this.hasCommentOnPart(part)) {
            return 'fa fa-commenting commented';
        } else {
            return 'fa fa-comment-o';
        }
    }

    hasValChanged(offerPart, type) {
        if (offerPart.status.name === 'ADDED_BY_CREATOR') {
            return false;
        }
        if (this.isOfferPartInCreatorWaitingStates(offerPart) || this.isOfferPartInSupplierDeclinedStates(offerPart)) {
            var originalOfferPart = this.getOriginalOfferPart(offerPart);
            switch (type) {
                case 'NAME':
                    return offerPart.name != originalOfferPart.name;
                case 'DESCRIPTION':
                    return offerPart.description != originalOfferPart.description;
                case 'AMOUNT':
                    return offerPart.amount != originalOfferPart.amount;
                case 'AMOUNT_TYPE':
                    return offerPart.amountType.name != originalOfferPart.amountType.name;
                case 'DATE_TIME':
                    return (
                        offerPart.dateTimeFrom != originalOfferPart.dateTimeFrom ||
                        offerPart.dateTimeTo != originalOfferPart.dateTimeTo
                    );
                case 'PRICE':
                    return offerPart.newPrice != originalOfferPart.price;
                default:
                    return false;
            }
        }
    }

    headerLoaded() {
        const { $element } = this._injected;
        var headerElement = $element.find('#offer-preview-modal-header');
        return headerElement && headerElement.innerHeight() > 0 && true;
    }

    hideDeleteRowBtn(offerPart) {
        return (
            this.isOfferInFinishedState ||
            this.isOfferPartDeletedByCreator(offerPart) ||
            this.isOfferInCanceledState ||
            this.offer.statusDetailed.name === 'CANCELED' ||
            this.showCreatorOptions(offerPart)
        );
    }

    isOfferPartDeletedByCreator(offerPart) {
        return ['DELETED_BY_CREATOR', 'DELETED_WAITING_FOR_SUPPLIER'].indexOf(offerPart.status.name) > -1;
    }

    hideMessagesSection() {
        this.showMessagesSection = false;
        this.showActivites = false;
        if (this.displayActivityType === 'all') {
            this.displayActivityType = 'message';
        }
        this.scrollToMessageSection();
    }

    initBillingAddressForm() {
        const { AuthenticationService } = this._injected;
        // $scope.billingAddressForm.billingAddressInfo.$setUntouched();
        this.currentUserGroup = AuthenticationService.getUserData().userGroup;
        if (this.currentUserGroup.userGroupBillingAddress) {
            this.billingAddress = this.currentUserGroup.userGroupBillingAddress;
        }

        if (!this.billingAddress) {
            this.billingAddress = {};
        }
        if (!this.billingAddress.company) {
            this.billingAddress.company = this.currentUserGroup.name;
        }

        this.originalBillingAddress = this.currentUserGroup.userGroupBillingAddress;
    }

    isGreaterOrEqualToZero(num) {
        return UtilFunctions.isGreaterOrEqualToZero(num);
    }

    shouldShowBillingInfo() {
        const { RequestOfferStatusUtils } = this._injected;
        this.showBillingInfo = RequestOfferStatusUtils.SHOW_BILLING_INFO.indexOf(this.offer.status.name) > -1;
    }

    isOfferInCanceledFinishedOrApprovedState() {
        const { RequestOfferStatusUtils } = this._injected;
        return RequestOfferStatusUtils.ALL_CANCELED_FINISHED_STATUS.indexOf(this.offer.status.name) > -1;
    }

    isOfferPartInCreatorDeclinedStates(offerPart) {
        const { GlobalConstants } = this._injected;
        let offerPartIsInCreatorDeclinedStates = false;
        let originalOfferPart = this.getOriginalOfferPart(offerPart);
        GlobalConstants.CREATOR_DECLINED_STATES.forEach((state) => {
            if (!offerPartIsInCreatorDeclinedStates) {
                if (originalOfferPart.status.name === state) {
                    offerPartIsInCreatorDeclinedStates = true;
                }
            }
        });
        return offerPartIsInCreatorDeclinedStates;
    }

    isOfferPartInCreatorWaitingStates(offerPart) {
        if (offerPart.status.name == 'ADDED_BY_CREATOR') {
            return false;
        }
        const { GlobalConstants } = this._injected;
        let offerPartIsInCreatorWaitingStates = false;
        let originalOfferPart = this.getOriginalOfferPart(offerPart);
        GlobalConstants.CREATOR_WAITING_STATES.forEach((state) => {
            if (!offerPartIsInCreatorWaitingStates) {
                if (originalOfferPart.status.name === state) {
                    offerPartIsInCreatorWaitingStates = true;
                }
            }
        });
        return offerPartIsInCreatorWaitingStates;
    }

    isOfferPartInSupplierDeclinedStates(offerPart) {
        const { GlobalConstants } = this._injected;
        let offerPartIsInSupplierDeclinedStates = false;
        let originalOfferPart = this.getOriginalOfferPart(offerPart);
        GlobalConstants.SUPPLIER_DECLINED_STATES.forEach((state) => {
            if (!offerPartIsInSupplierDeclinedStates) {
                if (originalOfferPart.status.name === state) {
                    offerPartIsInSupplierDeclinedStates = true;
                }
            }
        });
        return offerPartIsInSupplierDeclinedStates;
    }

    isOfferPartInSupplierWaitingStates(offerPart) {
        const { GlobalConstants } = this._injected;
        let offerPartIsInSupplierWaitingStates = false;
        GlobalConstants.SUPPLIER_WAITING_STATES.forEach((state) => {
            if (!offerPartIsInSupplierWaitingStates) {
                if (offerPart.status.name === state) {
                    offerPartIsInSupplierWaitingStates = true;
                }
            }
        });
        return offerPartIsInSupplierWaitingStates;
    }

    isOfferPartInToAnswerStates(offerPart) {
        const { GlobalConstants } = this._injected;
        if (offerPart.status.name == 'ADDED_BY_CREATOR') {
            return false;
        }
        var offerPartIsInToAnswerStates = false;
        var originalOfferPart = this.getOriginalOfferPart(offerPart);
        GlobalConstants.CREATOR_TO_ANSWER_STATES.forEach((state) => {
            if (!offerPartIsInToAnswerStates) {
                if (originalOfferPart.status.name === state) {
                    offerPartIsInToAnswerStates = true;
                }
            }
        });
        return offerPartIsInToAnswerStates;
    }

    isOfferPartOptionInToAnswerStates(offerPartOption) {
        const { GlobalConstants } = this._injected;
        if (offerPartOption.status.name === 'ADDED_BY_CREATOR') {
            return false;
        }
        let _offerPartOptionIsInToAnswerStates = false;
        GlobalConstants.CREATOR_TO_ANSWER_STATES.forEach((state) => {
            if (!_offerPartOptionIsInToAnswerStates) {
                if (offerPartOption.status.name === state) {
                    _offerPartOptionIsInToAnswerStates = true;
                }
            }
        });
        return _offerPartOptionIsInToAnswerStates;
    }

    isOfferInCanceledOrDeclinedState() {
        if (this.offer) {
            return this.offer.status.name === 'DECLINED' || this.offer.status.name === 'CANCELED';
        }
        return false;
    }

    isUndefinedOrNull(val) {
        return UtilFunctions.isUndefinedOrNull(val);
    }

    openInlineRowAddition(offer) {
        const { $translate } = this._injected;
        this.offerPartId = 'o' + offer.id;
        this.message = $translate.instant('request.offer.part.adding.new.row.alert');
        this.changeRowId();
    }

    closeInlineRowAddition(offer) {
        const { $translate } = this._injected;
        this.offerPartId = 'o' + 0;
        this.message = $translate.instant('request.offer.part.adding.new.row.alert');
        this.changeRowId();
    }

    parseDateInLocaleFormat(date) {
        const { DateService, TimezoneService } = this._injected;
        let dateFormat = DateService.getDateFormatByLocale();
        return TimezoneService.formatDate(new Date(parseInt(date)), dateFormat);
    }

    prepareParamsForNegotiationAlert(params) {
        this.showAlert = params.showAlert;
        this.alertMessage = params.alertMessage;
        this.okCallback = params.okCallback;
        this.cancelCallback = params.cancelCallback;
        this.disableOfferStatusButton = params.disableOfferStatusButton;
        this.canAddCustomMessage = params.canAddCustomMessage;
        this.userMessage = params.userMessage;
    }

    /**
     * Here, the response should always be se.eventlogic.centevent.dtos.RequestJsonDTO
     * @param response
     */
    processRequestOffers(response) {
        this.includeVat = amountAndPriceService.shouldIncludeVatOnPrice();
        //On update comments supplier messages should be stored
        let supplierMessage = this.offer ? this.offer.supplierMessage : null;
        const { NotificationService, $scope, $rootScope } = this._injected;
        if (response) {
            this.request = response;
            NotificationService.setNotificationData(this.event.id);
        }
        this.request.requestOffers.forEach((item) => {
            if (item.id === this.selectedOfferId) {
                this.offer = item;
                if (supplierMessage) {
                    this.offer.supplierMessage = supplierMessage;
                }
                if (!this.offer.bookingTerms) {
                    this.loadBookingTerms();
                }
            }
        });

        this.supplier = this.offer.supplier ? this.offer.supplier : null;
        this.agreementNumber = this.offer.agreementNumber;
        this.offerPartSchedule = this.offer.offerPartSchedule;
        this.timedOfferParts = this.offer.offerPartSchedule.timedOfferParts;
        this.timelessOfferParts = this.offer.offerPartSchedule.timelessOfferParts;
        this.beforeEventAccommodations = this.offer.offerPartSchedule.beforeEventAccommodations;
        this.afterEventAccommodations = this.offer.offerPartSchedule.afterEventAccommodations;
        this.filteredTimedOfferParts = this.getPartData(this.timedOfferParts);
        this.processOfferParts(this.filteredTimedOfferParts);
        this.filteredTimelessOfferParts = this.getPartData(this.timelessOfferParts);
        this.processOfferParts(this.filteredTimelessOfferParts);
        this.filteredBeforeEventAccommodations = this.getPartData(this.beforeEventAccommodations);
        this.filteredAfterEventAccommodations = this.getPartData(this.afterEventAccommodations);
        this.offer.requestOfferParts = this.getAllRequestOfferPartsFromSchedule();
        angular.forEach(this.offer.requestOfferParts, (part) => {
            if (!this.hasAdditionalQuestions && part.hasAdditionalQuestions) {
                this.hasAdditionalQuestions = true;
            }
        });
        this.offerTotalPrice = this.getOfferTotalPrice(this.offer, this.offer.requestOfferParts);
        this.paidOnsitePrice = amountAndPriceService.getPaidOnsitePrice(this.offer, this.offer.requestOfferParts);
        this.outstandingAmount = amountAndPriceService.getOutstandingAmount(this.offerTotalPrice, this.paidOnsitePrice);

        // this.getOfferPartComment();
        this.processParts();
        this.processPartOptions();
        this.originalOfferPartsList = angular.copy(this.offer.requestOfferParts);
        $rootScope.offerPartEditId = 0;
        this.displayDiffCol(this.offer, this.offer.requestOfferParts);
        this.isOfferInFinishedState = CommonFunctions.isItFinishedOffer(this.offer);
        this.isOfferInCanceledState = CommonFunctions.isItCanceledOffer(this.offer);
        this.showPaidOnsiteCol = this.checkPaidOnSiteColInFinishedState() && this.paidOnsitePrice;
        this.getOfferCanceledAmount = amountAndPriceService.getOfferCanceledAmount(
            this.offer,
            this.offer.requestOfferParts,
            this.includeVat
        );
        if (this.offer.status.name === 'BOOKED_WAITING_FOR_CREATOR' && this.disableSendActionForCreator()) {
            this.disableOfferStatusButton = true;
        }
        this.isResendRequest = false;
        this.resendRequestMsg = null;
        if (this.offer.status.name === 'FINISHED_WAITING_FOR_SUPPLIER') {
            this.paidOnsitePrice = false;
        }
        $scope.endProgress(this.request.id);
    }

    checkPaidOnSiteColInFinishedState() {
        return CommonFunctions.hasIntersectValue(
            [this.offer.status.name],
            ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR', 'APPROVED']
        );
    }

    setOfferPartColWidthFn(col) {
        switch (col) {
            case 'name':
                if (
                    (this.showPaidOnsiteCol && this.showDiffColumn) ||
                    ((this.request.isInternal || this.offer.statusDetailed.name === 'INTERNAL') && this.includeVat)
                ) {
                    return '25%';
                } else if (this.showPaidOnsiteCol && !this.showDiffColumn) {
                    return '29%';
                } else {
                    return '30%';
                }
                break;
            case 'amount':
                if (this.showPaidOnsiteCol) {
                    return '11%';
                } else {
                    return '15%';
                }
                break;
            case 'date':
                if (this.showPaidOnsiteCol) {
                    return '18%';
                } else {
                    return '20%';
                }
                break;
            case 'unit.price':
                if (
                    this.showPaidOnsiteCol ||
                    ((this.request.isInternal || this.offer.statusDetailed.name === 'INTERNAL') && this.includeVat)
                ) {
                    return '13%';
                } else {
                    return '15%';
                }
                break;
            case 'total.price':
                if (this.showPaidOnsiteCol) {
                    return '15%';
                } else if (
                    (this.request.isInternal || this.offer.statusDetailed.name === 'INTERNAL') &&
                    this.includeVat
                ) {
                    return '18%';
                } else {
                    return '20%';
                }
                break;
        }
    }

    getPartData(offerPartsData) {
        if (offerPartsData) {
            let filteredParts = [];
            angular.forEach(offerPartsData, (parts) => {
                let _filteredAccommodations = [];
                let _fiteredParts = requestOfferPartUtil.filterOfferPartsForCreator(
                    this.offer,
                    parts.partData[0].parts
                );
                angular.copy(_fiteredParts, parts.partData[0].parts);
                _filteredAccommodations = this.getAccommodationParts(parts.partData[0]);
                parts.partData[0].accommodations = _filteredAccommodations;
                parts.hasOfferParts = _fiteredParts.length > 0 || _filteredAccommodations.length > 0;
                filteredParts.push(parts);
            });
            return filteredParts;
        }
    }

    getAllRequestOfferPartsFromSchedule() {
        let _parts = [];
        angular.forEach(this.filteredTimedOfferParts, (_part) => {
            let _data = _part.partData[0];
            _parts.push(..._data.accommodations);
            _parts.push(..._data.parts);
        });

        angular.forEach(this.filteredTimelessOfferParts, (_part) => {
            let _data = _part.partData[0];
            _parts.push(..._data.parts);
        });

        angular.forEach(this.filteredBeforeEventAccommodations, (_part) => {
            let _data = _part.partData[0];
            _parts.push(..._data.accommodations);
        });

        angular.forEach(this.filteredAfterEventAccommodations, (_part) => {
            let _data = _part.partData[0];
            _parts.push(..._data.accommodations);
        });
        return _parts;
    }

    getAccommodationParts(partData) {
        let accommodationParts = [];
        if (partData.singleRoom) {
            accommodationParts.push(partData.singleRoom);
        }
        if (partData.doubleRoom) {
            accommodationParts.push(partData.doubleRoom);
        }
        if (partData.otherAccommodations && partData.otherAccommodations.length > 0) {
            angular.forEach(partData.otherAccommodations, (otherAccommodation) => {
                accommodationParts.push(otherAccommodation);
            });
        }
        let filteredAccommodationParts = requestOfferPartUtil.filterOfferPartsForCreator(
            this.offer,
            accommodationParts
        );
        this.processOfferPartsSchedule(filteredAccommodationParts);
        return filteredAccommodationParts;
    }

    checkAccommodationParts(partData) {
        if (
            partData &&
            (partData.singleRoom ||
                partData.doubleRoom ||
                (partData.otherAccommodations && partData.otherAccommodations.length > 0))
        ) {
            return true;
        } else {
            return false;
        }
    }

    processOfferParts(offerParts) {
        if (offerParts && offerParts.length > 0) {
            offerParts.forEach((p) => {
                this.processOfferPartsSchedule(p.partData[0].parts);
            });
        } else {
            return;
        }
    }

    processOfferPartsSchedule(parts) {
        this.processEventScheduleParts(parts);
        this.getEventScheduleOfferPartComment(parts);
        this.processEventSchedulePartOptions(parts);
    }

    getFormatedDate(date) {
        return moment(date).format('DD MMM');
    }

    processEventScheduleParts(offerParts) {
        offerParts.forEach((p) => {
            p.newPrice = p.price;
            if (EDITED_BY_CREATOR_PART_STATE.indexOf(p.status.name) > -1) {
                p.oldName = p.name;
                p.oldNameCode = p.nameCode;
                p.oldDateTimeFrom = p.dateTimeFrom;
                p.oldDateTimeTo = p.dateTimeTo;
                p.oldAmount = p.actualAmount;
                p.oldMultiplicator = p.multiplicator;
                p.oldAmountType = p.amountType;
                p.oldPartType = p.partType;

                p.name = p.creatorName ? p.creatorName : p.name;
                p.nameCode = p.creatorNameCode ? p.creatorNameCode : p.nameCode;
                p.dateTimeFrom = p.creatorDateTimeFrom ? p.creatorDateTimeFrom : p.dateTimeFrom;
                p.dateTimeTo = p.creatorDateTimeTo ? p.creatorDateTimeTo : p.dateTimeTo;
                p.amount = p.creatorAmount ? p.creatorAmount : p.actualAmount;
                p.multiplicator = p.creatorMultiplicator ? p.creatorMultiplicator : p.multiplicator;
                p.amountType = p.creatorAmountType ? p.creatorAmountType : p.amountType;
                p.partType = p.creatorPartType ? p.creatorPartType : p.partType;
            }
        });
    }

    getEventScheduleOfferPartComment(offerParts) {
        const { $scope, $rootScope, $translate, FilledRequestOfferPartComment, DynamicFormUtilService } =
            this._injected;
        angular.forEach(offerParts, (part) => {
            if (part.hasAdditionalQuestions) {
                this.additionalInfoChangesBeforeSendList = [];
                this.additionalInfoChangesAfterSendList = [];
                $scope.startProgress(this.request.id);
                FilledRequestOfferPartComment.list(
                    'OFFER_PART_LEVEL',
                    part.nameCode,
                    part.id,
                    (resp) => {
                        part.list = resp;
                        angular.forEach(resp, (list) => {
                            if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                                let _listValuesCode = list.listValueCodes;
                                let _listValues = $translate.instant(_listValuesCode);
                                list.fieldValue = DynamicFormUtilService.getFieldValue(
                                    _listValues,
                                    $rootScope.langSelected,
                                    list.fieldValue
                                );
                                list.creatorFieldValue = DynamicFormUtilService.getFieldValue(
                                    _listValues,
                                    $rootScope.langSelected,
                                    list.creatorFieldValue
                                );
                                list.oldFieldValue = DynamicFormUtilService.getFieldValue(
                                    _listValues,
                                    $rootScope.langSelected,
                                    list.oldFieldValue
                                );
                            }
                            if (list.fieldValue && list.oldFieldValue && list.fieldValue !== list.oldFieldValue) {
                                if (['MULTICHECKBOX'].indexOf(list.type.name) > -1) {
                                    list.oldFieldValue = list.oldFieldValue.split('~').join(', ');
                                }
                                if (['TEXT_WITH_AMOUNT_TYPE'].indexOf(list.type.name) !== -1) {
                                    list.oldFieldValue = list.oldFieldValue
                                        .split('~')[0]
                                        .concat(' ')
                                        .concat($translate.instant('common.people'));
                                }
                                this.additionalInfoChangesAfterSendList.push(list);
                            }
                            if (
                                list.fieldValue &&
                                list.creatorFieldValue &&
                                list.fieldValue !== list.creatorFieldValue
                            ) {
                                if (['MULTICHECKBOX'].indexOf(list.type.name) > -1) {
                                    list.fieldValue = list.fieldValue.split('~').join(', ');
                                }
                                if (['TEXT_WITH_AMOUNT_TYPE'].indexOf(list.type.name) !== -1) {
                                    list.fieldValue = list.fieldValue
                                        .split('~')[0]
                                        .concat(' ')
                                        .concat($translate.instant('common.people'));
                                }
                                this.additionalInfoChangesBeforeSendList.push(list);
                            }
                            if (['ICON_RADIO', 'ICON_MULTI_CHECKBOX'].indexOf(list.type.name) > -1) {
                                let _iconList = list.iconList;
                                angular.forEach(_iconList, function (icon) {
                                    icon.name = $translate.instant(icon.nameCode);
                                });
                            }
                        });
                        $scope.endProgress(this.request.id);
                    },
                    function (e) {
                        $scope.endWithErrorOverlay();
                        console.log('error ', e);
                    }
                );
            }
        });
    }

    processEventSchedulePartOptions(offerParts) {
        offerParts.forEach((p) => {
            if (p.hasRequestOfferPartOptions) {
                angular.forEach(p.offerPartOptions, (offerPartOption) => {
                    let _data = { id: offerPartOption.id, isSelected: offerPartOption.isSelected };
                    this.originalOfferPartOptionsList.push(_data);
                });
            }
        });
    }

    getOfferPartComment(offerParts) {
        const { $scope, $rootScope, $translate, FilledRequestOfferPartComment, DynamicFormUtilService } =
            this._injected;
        angular.forEach(this.offer.requestOfferParts, (part) => {
            if (part.hasAdditionalQuestions) {
                this.additionalInfoChangesBeforeSendList = [];
                this.additionalInfoChangesAfterSendList = [];
                $scope.startProgress(this.request.id);
                FilledRequestOfferPartComment.list(
                    'OFFER_PART_LEVEL',
                    part.nameCode,
                    part.id,
                    (resp) => {
                        part.list = resp;
                        angular.forEach(resp, (list) => {
                            if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                                let _listValuesCode = list.listValueCodes;
                                let _listValues = $translate.instant(_listValuesCode);
                                list.fieldValue = DynamicFormUtilService.getFieldValue(
                                    _listValues,
                                    $rootScope.langSelected,
                                    list.fieldValue
                                );
                                list.creatorFieldValue = DynamicFormUtilService.getFieldValue(
                                    _listValues,
                                    $rootScope.langSelected,
                                    list.creatorFieldValue
                                );
                                list.oldFieldValue = DynamicFormUtilService.getFieldValue(
                                    _listValues,
                                    $rootScope.langSelected,
                                    list.oldFieldValue
                                );
                            }
                            if (list.fieldValue && list.oldFieldValue && list.fieldValue !== list.oldFieldValue) {
                                if (['MULTICHECKBOX'].indexOf(list.type.name) > -1) {
                                    list.oldFieldValue = list.oldFieldValue.split('~').join(', ');
                                }
                                if (['TEXT_WITH_AMOUNT_TYPE'].indexOf(list.type.name) !== -1) {
                                    list.oldFieldValue = list.oldFieldValue
                                        .split('~')[0]
                                        .concat(' ')
                                        .concat($translate.instant('common.people'));
                                }
                                this.additionalInfoChangesAfterSendList.push(list);
                            }
                            if (
                                list.fieldValue &&
                                list.creatorFieldValue &&
                                list.fieldValue !== list.creatorFieldValue
                            ) {
                                if (['MULTICHECKBOX'].indexOf(list.type.name) > -1) {
                                    list.fieldValue = list.fieldValue.split('~').join(', ');
                                }
                                if (['TEXT_WITH_AMOUNT_TYPE'].indexOf(list.type.name) !== -1) {
                                    list.fieldValue = list.fieldValue
                                        .split('~')[0]
                                        .concat(' ')
                                        .concat($translate.instant('common.people'));
                                }
                                this.additionalInfoChangesBeforeSendList.push(list);
                            }
                            if (['ICON_RADIO', 'ICON_MULTI_CHECKBOX'].indexOf(list.type.name) > -1) {
                                let _iconList = list.iconList;
                                angular.forEach(_iconList, function (icon) {
                                    icon.name = $translate.instant(icon.nameCode);
                                });
                            }
                        });
                        $scope.endProgress(this.request.id);
                    },
                    function (e) {
                        $scope.endWithErrorOverlay();
                        console.log('error ', e);
                    }
                );
            }
        });
    }

    getChangesBeforeSend(offerPart) {
        const { $translate } = this._injected;
        let result = '';
        for (let i = 0; i < this.additionalInfoChangesBeforeSendList.length; i++) {
            if (this.additionalInfoChangesBeforeSendList[i].offerPart.id === offerPart.id) {
                result +=
                    '<b>' +
                    $translate.instant(this.additionalInfoChangesBeforeSendList[i].supplierLabelCode) +
                    '</b>' +
                    ': ' +
                    this.additionalInfoChangesBeforeSendList[i].fieldValue +
                    '<br>  ';
            }
        }
        this.additionalInfoChangesBeforeSend = result.substring(0, result.length - 2);
        return this.additionalInfoChangesBeforeSend;
    }

    getChangesAfterSend(offerPart) {
        const { $translate } = this._injected;
        let result = '';
        for (let i = 0; i < this.additionalInfoChangesAfterSendList.length; i++) {
            if (this.additionalInfoChangesAfterSendList[i].offerPart.id === offerPart.id) {
                result +=
                    '<b>' +
                    $translate.instant(this.additionalInfoChangesAfterSendList[i].supplierLabelCode) +
                    '</b>' +
                    ': ' +
                    this.additionalInfoChangesAfterSendList[i].oldFieldValue +
                    '<br>  ';
            }
        }
        this.additionalInfoChangesAfterSend = result.substring(0, result.length - 2);
        return this.additionalInfoChangesAfterSend;
    }

    processParts() {
        this.offer.requestOfferParts.forEach((p) => {
            p.newPrice = p.price;
            if (EDITED_BY_CREATOR_PART_STATE.indexOf(p.status.name) > -1) {
                p.oldName = p.name;
                p.oldNameCode = p.nameCode;
                p.oldDateTimeFrom = p.dateTimeFrom;
                p.oldDateTimeTo = p.dateTimeTo;
                p.oldAmount = p.actualAmount;
                p.oldMultiplicator = p.multiplicator;
                p.oldAmountType = p.amountType;
                p.oldPartType = p.partType;

                p.name = p.creatorName ? p.creatorName : p.name;
                p.nameCode = p.creatorNameCode ? p.creatorNameCode : p.nameCode;
                p.dateTimeFrom = p.creatorDateTimeFrom ? p.creatorDateTimeFrom : p.dateTimeFrom;
                p.dateTimeTo = p.creatorDateTimeTo ? p.creatorDateTimeTo : p.dateTimeTo;
                p.amount = p.creatorAmount ? p.creatorAmount : p.actualAmount;
                p.multiplicator = p.creatorMultiplicator ? p.creatorMultiplicator : p.multiplicator;
                p.amountType = p.creatorAmountType ? p.creatorAmountType : p.amountType;
                p.partType = p.creatorPartType ? p.creatorPartType : p.partType;
            }
            if (this.offer.status.name === 'FINISHED_WAITING_FOR_SUPPLIER') {
                p.finalPaidOnsite = false;
            }
        });
    }

    processPartOptions() {
        this.offer.requestOfferParts.forEach((p) => {
            if (p.hasRequestOfferPartOptions) {
                angular.forEach(p.offerPartOptions, (offerPartOption) => {
                    if (this.offer.status.name === 'FINISHED_WAITING_FOR_SUPPLIER') {
                        offerPartOption.finalPaidOnsite = false;
                    }
                    let _data = { id: offerPartOption.id, isSelected: offerPartOption.isSelected };
                    this.originalOfferPartOptionsList.push(_data);
                });
            }
        });
    }

    static reassignOriginalValuesToOfferPart(offerPart, originalOfferPart) {
        offerPart.name = originalOfferPart.name;
        offerPart.description = originalOfferPart.description;
        offerPart.amount = originalOfferPart.amount;
        offerPart.amountType.name = originalOfferPart.amountType.name;
        offerPart.dateTimeFrom = originalOfferPart.dateTimeFrom;
        offerPart.dateTimeTo = originalOfferPart.dateTimeTo;
        offerPart.newPrice = originalOfferPart.price;
        offerPart.price = originalOfferPart.price;
    }

    /**
     * call this whenever the body of modal changes. This will autofit the size of supplier
     * @param delayTime
     */
    resizeModalBodyHeight(delayTime = 1000) {
        const { $window, $element, $timeout } = this._injected;
        $timeout(function () {
            let windowHeight = $window.innerHeight;
            let modalHeader = $element.find('#offer-preview-modal-header');
            let totalMarginForTopAndBottom = 24;
            let bodyHeight = windowHeight - modalHeader.innerHeight() - totalMarginForTopAndBottom;
            let modalBody = $element.find('#offer-preview-modal-body');
            modalBody.css('height', bodyHeight + 'px');
        }, delayTime);
    }

    viewSupplierPresentation(view) {
        this.viewPresentation = view;
    }

    redirectToSupplierPublicPage(supplierUrl) {
        const { $window } = this._injected;
        $window.open(CommonFunctions.getPublicURL() + '/leverantor/' + supplierUrl, '_blank');
    }

    refreshAndProcessRequestOffers(response) {
        const { $scope, EventEnum } = this._injected;
        $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
            requestId: this.request.id,
            offerId: response.requestOffers[0].id,
        });
        this.processRequestOffers(response);
    }

    removeOfferPartFromMap(offerPart) {
        this.offerPartsWithActions.forEach((rowAndAction, i) => {
            if (rowAndAction.offerPart.id === offerPart.id) {
                this.offerPartsWithActions.splice(i, 1);
            }
        });
    }

    revertBookedLockedStateChangesCancellation() {
        let negotiationParams = {
            showAlert: false,
            disableOfferStatusButton: false,
        };
        this.prepareParamsForNegotiationAlert(negotiationParams);
    }

    scrollToMessageSection() {
        const { $timeout } = this._injected;
        let _topHeight = jQuery('#supplierMessageSection').position().top;
        jQuery('#offer-preview, #offer-preview-modal-body').animate({ scrollTop: _topHeight }, 'slow');
    }

    scrollToBookOfferForm() {
        const { $timeout } = this._injected;
        $timeout(() => {
            document.querySelector('#book-offer-content').scrollIntoView({
                behavior: 'smooth',
            });
        }, 2500);
    }

    scrollToAlertSection() {
        const { ogScrollService } = this._injected;
        ogScrollService.scrollToElementInModal(
            '#offer-preview-modal-body',
            '#alert-block-for-action-not-taken',
            'slow'
        );
    }

    scrollToModalTop() {
        const { ogScrollService } = this._injected;
        ogScrollService.scrollToContainer('offer-preview-modal-body');
    }

    scrollToChangeAlertSection() {
        const { ogScrollService } = this._injected;
        ogScrollService.scrollTopToContainer('offer-preview-modal-body', 400, 'slow');
    }

    scrollToChangeActionTakenAlertSection() {
        const { ogScrollService } = this._injected;
        ogScrollService.scrollToElementInModal(
            'offer-preview-modal-body',
            '#showAllActionTakenOnChangesAlertBox',
            'slow'
        );
    }

    scrollToOfferNegotiationAlertMessage() {
        const { $timeout } = this._injected;
        $timeout(() => {
            document.querySelector('#offer-negotiation-alert-message').scrollIntoView({
                behavior: 'smooth',
            });
        }, 500);
    }

    isOfferWaitingForUpdatedOfferBeforeBooking() {
        return this.offer.statusDetailed.name === 'SENT' && this.offer.alreadyAnswered;
    }

    loadBookingTerms() {
        const { $scope, $rootScope, GlobalOption } = this._injected;
        // $scope.startProgress(this.request.id);
        GlobalOption.get(
            'BOOKING_TERMS',
            (resp) => {
                this.offer.bookingTerms = resp[$rootScope.langSelected];
                // $scope.endProgress(this.request.id)
            },
            $scope.endWithErrorOverlay
        );
    }

    selectedOfferChanged(offer) {
        const { RequestOfferStatusUtils } = this._injected;
        this.selectedOfferId = offer.id;
        this.offer = offer;
        this.offerHasBeenAnswered = RequestOfferStatusUtils.ALL_AFTER_ANSWERED_STATUS.indexOf(offer.status.name) > -1;
        this.showBookingTermsSection = false;
        this.showCovidPreventiveMeasuresSection = false;
        this.showActivites = false;
        this.getMessageCount();
        this.getOtherOffers();
        this.getEventFinalDetails(offer.id);
        if (!this.offer.bookingTerms) {
            this.loadBookingTerms();
        }
        this.agreementNumber = offer.agreementNumber;
    }

    confirmWithSupplierAsYes() {
        const { $scope, RequestOffer } = this._injected;
        this.offer.confirmWithSupplier = true;
        RequestOffer.confirmInvalidOfferWithSupplier(
            this.offer.id,
            { confirmWithSupplier: true },
            (res) => {
                if (this.callback) {
                    this.callback(res);
                }
            },
            $scope.endWithErrorOverlay
        );
    }

    sendSupplierMessage() {
        this.displayMessagesSection();
        this.scrollToMessageSection();
    }

    close() {
        this.checkFinalDetailsHasChanged();
        if (this.activeTab === 'FINAL_DETAILS' && this.finalDetailsDataChanged) {
            this.closeConfirmation = true;
            this.scrollToChangeAlertSection();
        } else if (
            !this.disableSendActionForCreator() &&
            this.offer.status.name === 'BOOKED_WAITING_FOR_CREATOR' &&
            this.activeTab === 'OFFER_CONTENT'
        ) {
            this.showAllActionTakenOnChangesAlert = true;
            this.scrollToChangeActionTakenAlertSection();
        } else {
            this.closeAnyWay();
        }
    }

    closeMsg() {
        this.showSuccessMessage = false;
    }

    closeSaveMsg() {
        this.showSaveSuccessMessage = false;
    }

    checkIfAdditionalInfoChanges(val) {
        this.additionalInfoUpdated = val;
    }

    checkPartInfoChanges(val) {
        this.checkPartInfoChanged = val;
    }

    setActiveTab(tabName) {
        this.checkFinalDetailsHasChanged();
        if (this.activeTab === 'FINAL_DETAILS') {
            if (this.finalDetailsDataChanged) {
                this.tabChangeConfirmation = true;
                this.pendingStatus = tabName;
            } else {
                this.setActiveTabAny(tabName);
            }
        } else {
            this.setActiveTabAny(tabName);
        }
    }

    setActivityActiveTab(tabName) {
        this.activitySubTab = tabName;
    }

    setActiveTabAny(tabName) {
        this.tabChangeConfirmation = false;
        this.closeConfirmation = false;
        this.pendingStatus = null;
        this.activeTab = tabName;
    }

    checkFinalDetailsHasChanged() {
        let _yes = _.isEqual(this.eventFinalDetails, this.originalEventDetails);
        this.finalDetailsDataChanged =
            !_yes || this.additionalInfoUpdated || this.checkPartInfoChanged || this.attachedFile;
        return this.finalDetailsDataChanged;
    }

    setDisplayActivityType(type) {
        const { $translate } = this._injected;
        if (this.displayActivityType == type) {
            if (type == 'all') {
                this.displayActivityType = 'message';
                this.showActivites = false;
                return;
            } else {
                this.showActivites = !this.showActivites;
            }
        } else {
            this.showActivites = true;
        }
        this.displayActivityType = type;
        if (this.displayActivityType === 'NOTE') {
            this.inputPlaceholderString = $translate.instant('offer.make.a.note');
        } else {
            this.inputPlaceholderString = $translate.instant('offer.type.message.to.supplier');
        }
    }

    showAmountChangeToolTip(offerPart) {
        var finishedStatus = CommonFunctions.hasIntersectValue(
            [this.offer.status.name],
            ['FINISHED_WAITING_FOR_CREATOR']
        );
        var hasAmountChangedInFinished = offerPart.finalAmount !== offerPart.amount;
        if (this.hideToolTipForGivenPartStatuses(offerPart.status)) {
            return false;
        }
        return finishedStatus && hasAmountChangedInFinished;
    }

    showBookingTerms() {
        this.showBookingTermsSection = !this.showBookingTermsSection;
    }

    showCovidPreventiveMeasures() {
        if (this.offer.covidMeasureFiles.length > 0 || this.offer.covidPreventionMeasures) {
            this.showCovidPreventiveMeasuresSection = !this.showCovidPreventiveMeasuresSection;
        }
    }

    showCreateMessageNoteSection() {
        return this.displayActivityType === 'message' || this.displayActivityType === 'NOTE';
    }

    showCreatorOptions(offerPart) {
        if (offerPart.status.name === 'ADDED_BY_CREATOR') {
            return false;
        }
        if (this.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
            return (
                this.isOfferPartInCreatorWaitingStates(offerPart) || this.isOfferPartInSupplierDeclinedStates(offerPart)
            );
        }
    }

    showInfoTooltip(offer, offerPart) {
        if (offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
            return (
                this.isOfferPartInSupplierWaitingStates(offerPart) || this.isOfferPartInCreatorDeclinedStates(offerPart)
            );
        }
        return false;
    }

    showPriceChangeToolTip(offerPart) {
        if (this.hideToolTipForGivenPartStatuses(offerPart.status)) {
            return false;
        }

        let _showPriceForFinished = this.showPriceChangeToolTipForFinishedOffer(offerPart);
        let _isOfferStatusMatched = CommonFunctions.hasIntersectValue([this.offer.status.name], ['ANSWERED', 'SENT']);
        let _oldPrice = offerPart.oldPrice;
        let _newPrice = offerPart.price || 0;
        let _changed = _oldPrice !== _newPrice;
        let _isOldPriceExists = UtilFunctions.isDefinedAndNotNull(_oldPrice);
        let _showPriceForAnswered = _isOldPriceExists && _changed && _isOfferStatusMatched;

        return _showPriceForFinished || _showPriceForAnswered;
    }

    showPriceChangeToolTipForFinishedOffer(offerPart) {
        var correctOfferStatus = CommonFunctions.hasIntersectValue(
            [this.offer.status.name],
            ['FINISHED_WAITING_FOR_CREATOR']
        );
        var correctFinishedCondition = offerPart.finalPrice > 0 && offerPart.finalPrice !== offerPart.price;
        return correctOfferStatus && correctFinishedCondition;
    }

    showPriceChangeToolTipForOfferByStatus(offerPart, statusList) {
        if (this.hideToolTipForGivenPartStatuses(offerPart.status)) {
            return false;
        }
        var correctOfferStatus = CommonFunctions.hasIntersectValue([this.offer.status.name], statusList);
        var displayOfferSummary = this.displayOfferPartChangeSummary(offerPart);
        return (
            correctOfferStatus &&
            offerPart.oldPrice > 0 &&
            offerPart.oldPrice !== offerPart.price &&
            !displayOfferSummary
        );
    }

    showSupplierChatSection() {
        const { RequestOfferStatusUtils } = this._injected;
        return RequestOfferStatusUtils.CANNOT_CHAT_WITH_SUPPLIER_STATUS.indexOf(this.offer.status.name) === -1;
    }

    undoBookRequestOffer() {
        this.showBillingInfoSection = false;
        this.disableOfferStatusButton = false;
        this.billingAddressForm.billingAddressInfo.$setUntouched();
        this.scrollToModalTop();
    }

    undoOfferCancellation() {
        let params = {
            showAlert: false,
            disableOfferStatusButton:
                this.offer.status.name === 'BOOKED_WAITING_FOR_CREATOR' && this.disableSendActionForCreator(),
        };
        this.prepareParamsForNegotiationAlert(params);
    }

    undoOfferPartDeletion(offerPart) {
        this.removeOfferPartFromMap(offerPart);
        var originalOfferPart = this.getOriginalOfferPart(offerPart);
        this.offer.requestOfferParts.forEach((n) => {
            if (n.id == offerPart.id) {
                if (originalOfferPart.status.name === 'ADDED_WAITING_FOR_SUPPLIER_DECLINED') {
                    n.status.name = 'ADDED_WAITING_FOR_SUPPLIER_DECLINED';
                } else if (originalOfferPart.status.name === 'DELETED_WAITING_FOR_SUPPLIER_DECLINED') {
                    n.status.name = 'DELETED_WAITING_FOR_SUPPLIER_DECLINED';
                } else {
                    n.status.name = 'ACCEPTED';
                }
            }
        });
        this.ROPUndoDeleteId = 'delete' + offerPart.id;
    }

    unlockBookedLockedBySupplier() {
        const { $scope, RequestOffer } = this._injected;
        RequestOffer.unlockBookedLockedBySupplier(
            this.offerToUnlock.id,
            function () {
                $scope.startProgress(this.request.id);
                if (this.offerPartToUpdate) {
                    this.updateRequestOfferPart(this.offerPartToUpdate);
                }
                if (this.offerPartToCreate) {
                    this.createRequestOfferPart(this.offerPartToCreate);
                }
                if (this.isOfferPartToDelete) {
                    this.deleteRequestOfferPart();
                }
                $scope.endProgress();
            }.bind(this),
            $scope.endWithErrorOverlay(this.request.id)
        );
    }

    unlockBookedLockedBySupplierAndApplyCreatorChanges(offer) {
        this.offerToUnlock = offer;
        this.unlockBookedLockedBySupplier();
    }

    updateOfferAndOfferParts(data) {
        this.request.requestOffers.forEach((offer, index) => {
            if (offer.id == data.id) {
                this.request.requestOffers.splice(index, 1, data);
            }
        });
        this.offer = data.requestOffers[0];
        if (!this.offer.bookingTerms) {
            this.loadBookingTerms();
        }
        this.offer.requestOfferParts = this.getAllRequestOfferPartsFromSchedule();
        this.originalOfferPartsList = angular.copy(this.offer.requestOfferParts);
        this.agreementNumber = this.offer.agreementNumber;
        this.refreshAndProcessRequestOffers(data);
    }

    updateOfferPartOnClientSideOnly(offerPart) {
        const { $rootScope } = this._injected;
        let originalOfferPart = this.getOriginalOfferPart(offerPart);
        originalOfferPart.list = offerPart.list;
        if (this.isOfferPartInCreatorWaitingStates(offerPart)) {
            this.offer.requestOfferParts.forEach((n) => {
                if (n.id === offerPart.id) {
                    n = this.storeOfferPartValues(originalOfferPart, offerPart, n);
                    if (n.status.name !== 'ADDED_WAITING_FOR_CREATOR') {
                        n.status.name = 'EDITED_WAITING_FOR_SUPPLIER';
                        offerPart.status.name = 'EDITED_WAITING_FOR_SUPPLIER';
                    } else {
                        n.status.name = 'ACCEPTED_BY_CREATOR_WITH_CHANGES';
                        offerPart.status.name = 'ACCEPTED_BY_CREATOR_WITH_CHANGES';
                    }
                    if (this.hasNameChanged(n)) {
                        n.nameCode = null;
                    }
                }
            });
            if (OfferPreviewModalCtrl.checkIfFieldValueChanged(offerPart, originalOfferPart)) {
                let offerPartMap = { offerPart: offerPart, action: 'ACCEPTED_BY_CREATOR_WITH_CHANGES' };
                this.removeOfferPartFromMap(offerPart);
                this.offerPartsWithActions.push(offerPartMap);
                this.flagForAcceptedWithChanges = 'edit' + offerPart.id;
                if (this.offer.status.name === 'BOOKED_WAITING_FOR_CREATOR' && !this.disableSendActionForCreator()) {
                    this.disableOfferStatusButton = false;
                }
                this.checkIfActionHasBeenTakenForAllSupplierChanges();
            } else {
                this.removeOfferPartFromMap(offerPart);
                this.flagForAcceptedWithChanges = undefined;
            }
        } else {
            this.offer.requestOfferParts.forEach((n) => {
                if (offerPart.id === n.id) {
                    n = this.storeOfferPartValues(originalOfferPart, offerPart, n);
                    if (OfferPreviewModalCtrl.checkIfFieldValueChanged(offerPart, originalOfferPart)) {
                        if (n.status.name === 'ADDED_WAITING_FOR_SUPPLIER_DECLINED') {
                            n.status.name = 'ADDED_WAITING_FOR_SUPPLIER';
                        } else {
                            n.status.name = 'EDITED_WAITING_FOR_SUPPLIER';
                        }
                    } else {
                        n.status.name = originalOfferPart.status.name;
                    }
                    if (this.hasNameChanged(n)) {
                        n.nameCode = null;
                    }
                }
            });
            if (OfferPreviewModalCtrl.checkIfFieldValueChanged(offerPart, originalOfferPart)) {
                var offerPartMap = { offerPart: offerPart, action: 'EDITED_BY_CREATOR' };
                this.removeOfferPartFromMap(offerPart);
                this.offerPartsWithActions.push(offerPartMap);
                this.flagForAcceptedWithChanges = 'edit' + offerPart.id;
                if (this.offer.status.name === 'BOOKED_WAITING_FOR_CREATOR' && !this.disableSendActionForCreator()) {
                    this.disableOfferStatusButton = false;
                }
            } else {
                this.removeOfferPartFromMap(offerPart);
                this.flagForAcceptedWithChanges = undefined;
            }
        }
        $rootScope.offerPartEditId = 0;
    }

    storeOfferPartValues(originalOfferPart, offerPart, n) {
        if (n.name != offerPart.name) {
            n.oldName = originalOfferPart.name;
            n.name = offerPart.name;
        }
        if (n.description != offerPart.description) {
            n.oldDescription = originalOfferPart.description;
            n.description = offerPart.description;
        }
        if (n.amount != offerPart.amount) {
            n.oldAmount = originalOfferPart.amount;
            n.amount = offerPart.amount;
        }
        if (n.amountType.name != offerPart.amountType.name) {
            n.oldAmountType = { name: originalOfferPart.amountType.name };
            n.amountType.name = offerPart.amountType.name;
        }
        if (n.dateTimeFrom != offerPart.dateTimeFrom) {
            n.oldDateTimeFrom = originalOfferPart.dateTimeFrom;
            n.dateTimeFrom = offerPart.dateTimeFrom;
        }
        if (n.dateTimeTo != offerPart.dateTimeTo) {
            n.oldDateTimeTo = originalOfferPart.dateTimeTo;
            n.dateTimeTo = offerPart.dateTimeTo;
        }
        if (n.price != offerPart.price) {
            n.oldPrice = originalOfferPart.newPrice ? n.newPrice : 0;
            n.newPrice = offerPart.price;
            n.price = offerPart.price;
        }

        return n;
    }

    toggleBillingInfoSection() {
        if (!this.showBillingInfoSection) {
            this.getBillingAddress(this.offer.id);
            if (this.offer.status.name === 'APPROVED') {
                this.disableBillingAddress = true;
                this.disableMarking = true;
            }
        }
        this.shouldShowBillingInfo();
        this.showBillingInfoSection = !this.showBillingInfoSection;
    }

    updateBillingAddress() {
        const { $scope, RequestOffer } = this._injected;
        if (!this.billingAddressForm.billingAddressInfo.$valid) {
            CommonFunctions.touchFormFields(this.billingAddressForm.billingAddressInfo);
            return;
        }
        $scope.startProgress();
        RequestOffer.updateBillingAddress(
            this.offer.id,
            this.billingAddress,
            function (response) {
                this.billingAddress = response;
                this.toggleBillingInfoSection();
                $scope.endProgress();
            }.bind(this)
        );
    }

    updatePartCallback(data) {
        if (this.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
            this.updateOfferPartOnClientSideOnly(data);
        } else {
            if (this.offer.statusDetailed.name === 'BOOKED_LOCKED_BY_SUPPLIER') {
                this.offerPartToUpdate = data;
                this.unlockBookedLockedBySupplierAndApplyCreatorChanges(this.offer);
            } else {
                this.updateRequestOfferPart(data);
            }
            // this.insertUpdateFlag = true;
        }
    }

    openAccommodationCallback() {
        const { $rootScope, ModalService, EventEnum, $timeout } = this._injected;
        this.close();

        let data = {
            eventId: this.event.id,
            requestId: this.request.id,
            successCallBack: function (res) {
                $rootScope.$broadcast(EventEnum.EVENT_AND_REQUESTS_RELOAD);
            },
        };

        let addEditAccommodationModalData = addEditAccommodationModal;
        addEditAccommodationModalData.inputs = { data };

        $timeout(() => {
            ModalService.showModal(addEditAccommodationModalData).then((modal) => {
                modal.element.modal();
                modal.close.then(() => {
                    console.log('modal is closed!');
                });
            });
        }, 900);
    }

    updateRequestOfferPart(data) {
        const { $scope, EventEnum, RequestOfferPart } = this._injected;
        $scope.startProgress(this.request.id);
        RequestOfferPart.update(
            data.id,
            data,
            function (response) {
                this.processRequestOffers(response);
                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                    requestId: this.request.id,
                    offerId: this.offer.id,
                });
            }.bind(this),
            $scope.endWithErrorOverlay(this.request.id)
        );
    }

    disableAccommodationPartFn(data, offer) {
        if (data.category && data.category.name === 'ACCOMMODATION') {
            if (data) {
                if (data.status.name == 'ACCEPTED' && offer.statusDetailed.name == 'SENT') {
                    //disable update
                    return true;
                }
                let state = tooltipUtil.getPartNotificationState(offer, data);
                if (state) {
                    if (state == 'ADDED_BY_ME') {
                        if (data.status.name != 'ADDED_WAITING_FOR_CREATOR') {
                            //should be able to update newly added parts
                            return false;
                        }
                    }
                }
            }
            return true;
        }
        return false;
    }

    updateInternalPrice(reqOffPart) {
        const { $scope, RequestOfferPart, $timeout, EventEnum } = this._injected;
        var price = parseFloat(reqOffPart.newPrice);
        if (isNaN(price)) {
            price = 0;
        }
        if (price !== reqOffPart.price) {
            var oldPrice = reqOffPart.price;
            reqOffPart.price = price;
            $scope.startProgress(this.request.id);
            //this.insertUpdateFlag = true;

            RequestOfferPart.update(
                reqOffPart.id,
                reqOffPart,
                (resp) => {
                    this.processRequestOffers(resp);
                    $timeout(() => {
                        $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                            requestId: this.request.id,
                            offerId: this.offer.id,
                        });
                    }, 400);
                    $scope.endProgress();
                },
                (error) => {
                    reqOffPart.price = oldPrice;
                    $scope.endWithErrorOverlay(this.request.id);
                }
            );
        }
    }

    updateInternalVat(reqOffPart) {
        const { $scope, RequestOfferPart, $timeout, EventEnum } = this._injected;
        let vat = parseFloat(reqOffPart.vat);
        if (isNaN(vat)) {
            vat = 0;
        }
        if (vat !== reqOffPart.vat) {
            let oldVat = reqOffPart.vat;
            reqOffPart.vat = vat;
            $scope.startProgress(this.request.id);

            RequestOfferPart.update(
                reqOffPart.id,
                reqOffPart,
                (resp) => {
                    this.processRequestOffers(resp);
                    $timeout(() => {
                        $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                            requestId: this.request.id,
                            offerId: this.offer.id,
                        });
                    }, 400);
                    $scope.endProgress();
                },
                (error) => {
                    reqOffPart.vat = oldVat;
                    $scope.endWithErrorOverlay(this.request.id);
                }
            );
        }
    }

    getIconName(fileName) {
        let fileNameSplit = fileName.split('.');
        let extension = fileNameSplit.slice(-1)[0];
        switch (extension) {
            case 'pdf':
                return 'pdf-file-icon';
            case 'pptx':
                return 'powerpoint-file-icon';
            case 'docx':
            case 'doc':
                return 'word-file-icon';
            case 'xlsx':
            case 'xls':
            case 'xlsm':
            case 'xlsb':
                return 'excel-file-icon';
            case 'jpg':
            case 'jpeg':
            case 'png':
                return 'image-file-icon';
            default:
                return 'file-icon';
        }
    }

    getOfferPartTitle(part) {
        const { $translate } = this._injected;
        if (part && part.nameCode) {
            switch (part.nameCode) {
                case 'common.translate.offer.part.type.bus.(outward.journey)':
                case 'common.translate.offer.part.type.bus.(home.journey)':
                case 'common.translate.offer.part.type.bus.trip':
                    return $translate.instant('common.translate.request.bus.transport');
                    break;
                case 'common.translate.offer.part.type.breakout.room':
                    return $translate.instant('common.translate.request.breakout.room.information');
                    break;
                default:
                    return $translate.instant('common.translate.conference01.supplier.label.conference.information');
                    break;
            }
        }
    }

    getOfferPartAdditionalQuestionClass(resourceData) {
        if (resourceData && resourceData.nameCode) {
            switch (resourceData.nameCode) {
                case 'common.translate.offer.part.type.bus.(outward.journey)':
                case 'common.translate.offer.part.type.bus.(home.journey)':
                case 'common.translate.offer.part.type.bus.trip':
                    return 'bus-transport-form';
                    break;
                default:
                    break;
            }
        }
    }

    updateSelectedCurrencyFn() {
        const { RequestOffer, $rootScope, EventEnum } = this._injected;
        this.selectedCurrency = _.findWhere(this.currencyList, { id: this.selectedCurrencyId });
        this.selectedCurrencyId = this.selectedCurrency.id;
        this.offer.currency = this.selectedCurrency;
        RequestOffer.updateCurrency(this.offer.id, { currencyId: this.selectedCurrencyId });
        // Broadcast changes to replace the currency in offer as offer is in another controller.
        $rootScope.$broadcast(EventEnum.OFFER_CURRENCY_CHANGED, {
            currency: this.selectedCurrency,
            offerId: this.offer.id,
        });
    }

    isOfferInFromBookedStates() {
        const { RequestOfferStatusUtils } = this._injected;
        return (
            this.offer &&
            this.offer.status &&
            RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(this.offer.status.name) > -1
        );
    }

    showOfferStatusButton() {
        return (
            this.offer &&
            this.offer.status &&
            ['ANSWERED', 'BOOKED_WAITING_FOR_CREATOR'].indexOf(this.offer.status.name) > -1
        );
    }

    setIsUsingClientBillingAddress(userGroupBillingAddressEnum) {
        this.isUsingClientBillingAddressAndIsEL = UtilFunctions.isClientBillingAddress(
            userGroupBillingAddressEnum.name
        );
    }

    handleClientBillingAddress(userGroup) {
        let clientBillingAddressName = userGroup.userGroupBillingAddressEnum.name;
        if (clientBillingAddressName === 'DEFAULT' && userGroup.parentUserGroups) {
            this.checkParentClientBillingAddressName(userGroup.parentUserGroups);
        } else {
            this.isclientBillingAddress = UtilFunctions.isClientBillingAddress(
                userGroup.userGroupBillingAddressEnum.name
            );
        }
    }

    checkParentClientBillingAddressName(parentUserGroup) {
        let parentClientBillingAddressName = parentUserGroup.userGroupBillingAddressEnum.name;
        if (parentClientBillingAddressName !== 'DEFAULT') {
            this.isclientBillingAddress = UtilFunctions.isClientBillingAddress(
                parentUserGroup.userGroupBillingAddressEnum.name
            );
        } else {
            if (parentUserGroup.parentGroup !== null) {
                this.checkParentClientBillingAddressName(parentUserGroup.parentGroup);
            }
        }
    }

    splitAccommodationPart(offerPart) {
        let _currentIndex = AccommodationUtil.getDayIndex(offerPart.dateTimeFrom);
        let _days = AccommodationUtil.getDateDiff(offerPart.dateTimeFrom, offerPart.dateTimeTo);
        let _upperIndex = _currentIndex + _days;
        if (_upperIndex <= _currentIndex) {
            _upperIndex = _currentIndex + 1;
        }
        for (let _i = _currentIndex; _i < _upperIndex; _i++) {
            let _currentPart = angular.copy(offerPart);
            let _startDate = AccommodationUtil.getDateTimeFrom(_i);
            let _endDate = AccommodationUtil.getDateTimeTo(_i);
            _currentPart.dateTimeFrom = _startDate;
            _currentPart.dateTimeTo = _endDate;
            var offerPartMap = { offerPart: _currentPart, action: 'ADDED_BY_CREATOR' };
            this.offerPartsWithActions.push(offerPartMap);
            this.offer.requestOfferParts.push(offerPart);
            this.checkAndAddNewAccommodationPart(_currentPart, _i);
        }
    }

    checkAndAddNewAccommodationPart(offerPart, _index) {
        let _date = this.getFormatedDate(offerPart.dateCreated);
        if (_index < 0) {
            let _beforeAccommodationPart = this.filteredBeforeEventAccommodations.find((part) => {
                let _defaultDate = this.getFormatedDate(part.config.defaultDate);
                if (_defaultDate === _date) {
                    part.partData[0].accommodations.unshift(offerPart);
                    return part;
                }
            });
            if (!_beforeAccommodationPart) {
                let _newBeforeAccommodationPart = {
                    config: {
                        defaultDate: offerPart.dateCreated,
                    },
                    hasOfferParts: true,
                    partData: [
                        {
                            accommodations: [offerPart],
                        },
                    ],
                };
                this.filteredBeforeEventAccommodations.unshift(_newBeforeAccommodationPart);
            }
        } else {
            let _timedAccommodationPart = this.filteredTimedOfferParts.find((part) => {
                let _defaultDate = this.getFormatedDate(part.config.defaultDate);
                if (_defaultDate === _date) {
                    part.partData[0].accommodations.push(offerPart);
                    return part;
                }
            });
            if (!_timedAccommodationPart) {
                let _afterAccommodationPart = this.filteredAfterEventAccommodations.find((part) => {
                    let _defaultDate = this.getFormatedDate(part.config.defaultDate);
                    if (_defaultDate === _date) {
                        part.partData[0].accommodations.push(offerPart);
                        return part;
                    }
                });
                if (!_afterAccommodationPart) {
                    let _newAfterAccommodationPart = {
                        config: {
                            defaultDate: offerPart.dateCreated,
                        },
                        hasOfferParts: true,
                        partData: [
                            {
                                accommodations: [offerPart],
                            },
                        ],
                    };
                    this.filteredAfterEventAccommodations.push(_newAfterAccommodationPart);
                }
            }
        }
    }

    getImageUrl(fileId) {
        const { CommonFile } = this._injected;
        return CommonFile.getCommonFile(fileId);
    }

    hideEndScreenOverlay() {
        this.showApprovedFinalPricesOverlay = false;
    }

    updateOffer(partWithOption, data) {
        let offerPart = _.find(this.offer.requestOfferParts, (part) => {
            return part.id === partWithOption.id;
        });
        if (!offerPart) {
            return;
        }
        let offerPartOption = _.find(offerPart.offerPartOptions, (option) => {
            return option.id === data.offerPartOptionId;
        });
        if (offerPartOption) {
            offerPartOption.isSelected = data.isSelected;
        }
    }

    getConferenceActivityLocation(lists) {
        let _location = _.find(lists, (list) => {
            if (list.type.name === 'TEXT_WITH_GOOGLE_AUTOSUGGEST') {
                return list;
            }
        });
        if (_location) {
            return _location.fieldValue;
        } else {
            return '-';
        }
    }

    updateSupplierAgreementNumberInOfferFn() {
        const { RequestOffer, $scope, $rootScope } = this._injected;
        $scope.startProgress(this.request.id);
        RequestOffer.updateSupplierAgreementNumber(
            {
                offerId: this.offer.id,
                agreementNumber: this.agreementNumber,
            },
            (res) => {
                this.offer.agreementNumber = res.agreementNumber;
                this.updateAgreementNumberModal = false;
                $rootScope.$broadcast('offer:agreement:number:updated', {
                    offerId: this.offer.id,
                    agreementNumber: res.agreementNumber,
                });
                if (this.offer.status.name === 'NEW') {
                    this.changeRequestOfferStatus(this.offer, 'SENT', this.offer.supplierMessage);
                }
            },
            $scope.endProgress(this.request.id),
            $scope.endWithAlert
        );
    }

    showUpdateAgreementNumberModalFn() {
        this.updateAgreementNumberModal = true;
    }

    hideUpdateAgreementNumberModalFn() {
        this.agreementNumber = this.offer.agreementNumber;
        this.updateAgreementNumberModal = false;
    }

    isNonCommissionAgreementPricesSettingEnabledInUserGroupFn() {
        const { AuthenticationService } = this._injected;
        return AuthenticationService.getUserData().userGroup.isUserGroupNonCommissionAgreementPricesEnabled;
    }

    getOfferPreviewAgreementNumberTextFn() {
        const { $translate } = this._injected;
        return $translate.instant('common.translate.creator.offer.preview.agreement.number.text', {
            p0: this.offer.agreementNumber,
        });
    }

    showAgreementReferenceUpdateLinkFn() {
        return ['SENT', 'CANCELED'].indexOf(this.offer.status.name) > -1 && !this.offer.isAgreementPriceGiven && this.offer.supplier.hasHotellOrLokalerCategory;
    }

    showOfferAgreementTextFn() {
        return (
            this.isNonCommissionAgreementPricesSettingEnabledInUserGroup() &&
            this.offer.agreementNumber &&
            this.offer.alreadyAnswered &&
            this.offer.isAgreementPriceGiven &&
            !(this.offer.status.name === 'NEW') &&
            !(this.offer.status.name === 'SENT') &&
            this.offer.supplier.hasHotellOrLokalerCategory
        );
    }
}

OfferPreviewModalCtrl.$inject = [
    '$element',
    '$rootScope',
    '$scope',
    '$timeout',
    '$translate',
    '$sce',
    '$window',
    'close',
    'data',
    'EventEnum',
    'GlobalConstants',
    'Request',
    'RequestOffer',
    'RequestOfferPart',
    'EventFinalDetails',
    'PopoverTypeEnum',
    'AuthenticationService',
    'NotificationService',
    'RequestOfferStatusUtils',
    'Activity',
    'Note',
    'ogScrollService',
    'ModalName',
    'ModalStatusStore',
    'ModalService',
    'FilledRequestOfferPartComment',
    'DateService',
    'TimezoneService',
    'DynamicFormUtilService',
    'TranslationService',
    'TooltipValueService',
    'GlobalOption',
    'UserGroupSettings',
    'ClientService',
    'Client',
    'RequestOfferPartOption',
    'SupplierResources',
    'FilledSupplierResourceField',
    'CommonFile',
    'FileService',
    'CustomTranslationService'
];
export default OfferPreviewModalCtrl;
