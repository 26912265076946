(function () {
    'use strict';
    angular.module('ogErrorId').directive('ogErrorId', [
        function () {
            return {
                restrict: 'A',
                replace: false,
                scope: {
                    error: '=ogErrorId',
                },
                template: '<div ng-show="id()" style="font-size: 8px; font-style: italic">({{id()}})</div>',
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.id = function () {
                            if ($scope.error) {
                                var status = $scope.error.status;
                                if (status == 401 || status == 403) {
                                    return null;
                                }
                                var id = null;
                                var data = $scope.error.data;
                                if (!data) {
                                    data = $scope.error;
                                }
                                if (data && data.id) {
                                    id = data.id;
                                }
                                return id;
                            }
                        };
                    },
                ],
            };
        },
    ]);
})();
