import { UtilFunctions } from "./global/utilFunctions";

export const getLocalToken = () => {
    let _sessionToken = sessionStorage["authToken"];
    return _sessionToken ? _sessionToken : localStorage["authToken"];
}

export const getLocalToken_temp = () => {
    let tmpToken = localStorage["tempToken"];
    return tmpToken? atob(tmpToken) : null;
}

/**
 * If there is already sessionStorage, should not replace it by localStorage.
 * Otherwise on hard refresh sessionStorage will be replace by localStorage
 */
export const setLocalToken = (value) => {
    let _sessionToken = sessionStorage["authToken"];
    if (!_sessionToken) {
        localStorage["authToken"] = value;
    }
}

export const setLocalToken_temp = (value) => {
    localStorage["tempToken"] = btoa(value);
}

export const clearLocalToken = () => {
    localStorage.clear();
    sessionStorage.clear();
}

/*
This function is execute when we login as user/supplier from admin.
 sessionStorage can hold values per tab so we can handle multiple values as per the tab
 */
export const setSessionToken = (value) => {
    sessionStorage["authToken"] = value;
}

export const getHttpConfig = () => {
    let _token = getLocalToken();
    if (UtilFunctions.isDefinedAndNotNull(_token)) {
        return {
            headers: {
                'X-Auth-Token': getLocalToken()
            }
        };
    }
}

export const getAuthenticateHttpConfig = () => {
    return {
        ignoreAuthModule: true
    };
}

export const removeParam = (key, sourceURL) => {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}

export const isSafari = () => {
    return false;
    // return (/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
}

