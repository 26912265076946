'use strict';
import { OgModal } from "../../utils/global/ogModal";
import { ScopeFunctions } from "../../utils/global/scopeFunctions";
import CommonFunctions from '../../utils/CommonFunctionUtil';

angular.module('dialog').directive('ogChangeInvitationDetailsModal', [
    '$rootScope',
    'Event',
    '$timeout',
    function ($rootScope, Event, $timeout) {
        return new OgModal({
            name: 'change:invitation:details',
            type: 'custom',
            controller: function ($scope) {
                $scope.changeInvitationForms = {};
                $scope.timezoneList = moment.tz.names();
                ScopeFunctions.addToScope($scope);

                $scope.initDialog = function (params) {
                    $scope.type = params.type;
                    $scope.eventId = params.eventId;
                    $scope.event = {};
                    $scope.event.isOnline = params.isOnline;
                    $scope.startProgress();
                    Event.getEventInvitationDetails(
                        $scope.eventId,
                        (response) => {
                            $scope.invitationDetails = response;
                            if (!response.invitationAddress) {
                                $scope.location = {};
                                $scope.location.fullAddress = '';
                                $scope.showFullAddressOptions = false;
                            } else {
                                $scope.location = response.invitationAddress;
                                $scope.showFullAddressOptions = true;
                            }
                            $scope.invitationDetails.timeZone = $scope.invitationDetails.timeZone
                                ? $scope.invitationDetails.timeZone
                                : 'Europe/Stockholm';
                            $scope.loadMultipleSelect();
                            $scope.newLocation = angular.copy($scope.location);
                            $scope.invitationDetails.showToDateField = !response.isSameFromAndToDate;
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                };

                $scope.loadMultipleSelect = function () {
                    $().ready(function () {
                        $('#time-zone-select').select2();
                    });
                };

                $scope.handleOnLocationChange = function (newLocation) {
                    $scope.newLocation = angular.copy(newLocation);
                };

                $scope.updateInvitationDetails = function () {
                    $scope.location = angular.copy($scope.newLocation);
                    if ($scope.type == 'ORGANIZER') {
                        if (!$scope.changeInvitationForms.formForOrganizer.$valid) {
                            CommonFunctions.touchFormFields($scope.changeInvitationForms.formForOrganizer);
                            return;
                        }
                    }
                    if (!$scope.invitationDetails.showToDateField) {
                        //reset toDate if there is hideToDateField true
                        $scope.invitationDetails.invitationToDate = angular.copy(
                            $scope.invitationDetails.invitationDate
                        );
                    }
                    var invitationDetails = $scope.invitationDetails;
                    if (
                        $scope.location.name ||
                        $scope.location.address1 ||
                        $scope.location.address2 ||
                        $scope.location.state ||
                        $scope.location.city ||
                        $scope.location.postalCode
                    ) {
                        invitationDetails.invitationAddress = {
                            name: $scope.location.name,
                            address1: $scope.location.address1,
                            address2: $scope.location.address2,
                            state: $scope.location.state,
                            city: $scope.location.city,
                            postalCode: $scope.location.postalCode,
                            country: $scope.location.country,
                            geoCoordinates: $scope.location.geoCoordinates,
                        };
                    } else {
                        invitationDetails.invitationAddress = {
                            name: null,
                            address1: null,
                            address2: null,
                            state: null,
                            city: null,
                            postalCode: null,
                            country: null,
                            geoCoordinates: null,
                        };
                    }
                    Event.updateEventInvitationDetails(
                        $scope.eventId,
                        invitationDetails,
                        function (data) {
                            $rootScope.$broadcast('invitation:details:update', data);
                        },
                        $scope.endWithError
                    );
                    $scope.close();
                };

                $scope.useOrganizerAsDataController = function () {
                    var _data = {
                        name: $scope.invitationDetails.organizer.company,
                        email: $scope.invitationDetails.organizer.email,
                    };
                    $scope.invitationDetails.dataAccessHandler = angular.copy(_data);
                };

                $scope.updateIsOnline = function () {
                    var data = {
                        isOnline: $scope.event.isOnline,
                    };
                    $scope.startProgress();
                    Event.updateIsEventOnline(
                        $scope.eventId,
                        data,
                        (data) => {
                            $rootScope.$broadcast('invitation:details:update', data.invitationDetails);
                            $scope.endProgress();
                        },
                        $scope.endWithError
                    );
                };

                $scope.isUpdateDisabled = function () {
                    return $scope.event && $scope.event.isOnline && $scope.type === 'LOCATION';
                };
            },
        });
    },
]);

