import controller from './og_design_for_confirmation_email.controller';

(function () {
    'use strict';
    angular.module('ogDesignForConfirmationEmail').directive('ogDesignForConfirmationEmail', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    jsondata: '=jsondata',
                    editable: '=editable',
                    invitationDetails: '=invitationDetails',
                    latitudeAndLongitude: '=latitudeAndLongitude',
                    registerCallback: '=registerCallback',
                    showRegisterButton: '=showRegisterButton',
                    declineParticipationCallback: '=declineParticipationCallback',
                },
                templateUrl:
                    './app/components/directives/design_for_confirmation_email/og_design_for_confirmation_email.view.html',
                controller,
                controllerAs: 'DesignForConfirmationEmailCtrl',
                bindToController: true,
            };
        },
    ]);
})();
