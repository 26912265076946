/**
 * Created by kshitij on 11/30/17.
 */


'use strict';

angular.module('core.existingServices').factory('EventTicket', [
    '$resource',
    function ($resource) {
        var eventTicketsRest = $resource(
            'api/v1/eventTicket/event/:eventId',
            {eventId: '@eventId'},
            {
                create: {method: 'POST', isArray: false},
                get: {method: 'GET', isArray: true},
                update: {method: 'PUT', isArray: false},
            }
        );
        var eventTicketRest = $resource(
            'api/v1/eventTicket/event/:eventId/ticket/:ticketId',
            {eventId: '@eventId', tickedId: '@ticketId'},
            {
                update: {method: 'PUT', isArray: false},
                delete: {method: 'DELETE', isArray: false},
            }
        );

        return {
            create: function (eventId, jsonObject, successCallback, errorCallback) {
                eventTicketsRest.create({eventId: eventId}, jsonObject, successCallback, errorCallback);
            },
            getTickets: function (eventId, successCallback, errorCallback) {
                eventTicketsRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            update: function (eventId, ticketId, jsonObject, successCallback, errorCallback) {
                eventTicketRest.update(
                    {eventId: eventId, ticketId: ticketId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            delete: function (eventId, ticketId, successCallback, errorCallback) {
                eventTicketRest.delete({eventId: eventId, ticketId: ticketId}, successCallback, errorCallback);
            },
        };
    },
]);

