export default function (appModule) {
    appModule.config([
        '$routeProvider',
        '$locationProvider',
        '$stateProvider',
        '$urlRouterProvider',
        function ($routeProvider, $locationProvider, $stateProvider, $urlRouterProvider) {
            $urlRouterProvider.when('/event/:eventId/budget', '/event/:eventId/budget/list');
            $stateProvider
                .state('event.detail.budget.list', {
                    url: '/list',
                    template: '<budget-table></budget-table>',
                })
                .state('event.detail.budget.markup', {
                    url: '/markup',
                    template: '<markup></markup>',
                })
                .state('event.detail.budget.client_election', {
                    url: '/client_selection',
                    template: '<client-selection></client-selection>'
                });
        },
    ]);
}
