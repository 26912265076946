import {getHttpConfig, getLocalToken, getLocalToken_temp, setLocalToken} from "../../../../utils";

angular.module('mfa.verify').component('mfaVerify', {
    templateUrl: './app/components/modules/auth/mfa_verify/mfa_verify.template.html',
    controller: ['$http','$stateParams', '$rootScope', '$state', 'AuthenticationService', 'EventEnum', '$scope', '$timeout',
        function MfaVerifyController(
            $http,
            $stateParams,
            $rootScope,
            $state,
            AuthenticationService,
            EventEnum,
            $scope,
            $timeout
        ) {
            var vm = this;

            vm.verifyMFACode = verifyMFACodeFn;
            vm.resendMfaCode = resendMfaCodeFn;
            vm.loginData = angular.copy($stateParams.resp);
            vm.mfaCode = ''
            vm.switchToEn = switchToEnFn;
            vm.switchToSv = switchToSvFn;
            vm.switchToNb = switchToNbFn;
            vm.switchToDa = switchToDaFn;
            vm.switchToFi = switchToFiFn;
            vm.redirectMe = redirectMeFn;
            vm.displayInvalidCodeMsg = false;
            vm.codeSentMsg = false;
            initFn();

            if ($stateParams.locale) {
                if ($stateParams.locale === 'en') {
                    vm.switchToEn();
                } else if ($stateParams.locale === 'sv') {
                    vm.switchToSv();
                } else if ($stateParams.locale === 'nb') {
                    vm.switchToNb();
                } else if ($stateParams.locale === 'da') {
                    vm.switchToDa();
                } else {
                    vm.switchToFi();
                }
            } else {
                vm.activeEn = $rootScope.langSelected === 'en_US' ? 'active' : '';
                vm.activeSv = $rootScope.langSelected === 'sv_SV' ? 'active' : '';
                vm.activeNb = $rootScope.langSelected === 'nb_Nb' ? 'active' : '';
                vm.activeDk = $rootScope.langSelected === 'da_DK' ? 'active' : '';
                vm.activeFi = $rootScope.langSelected === 'fi_FI' ? 'active' : '';
            }

            function initFn() {
                if(!vm.loginData){
                    let _authToken = getLocalToken_temp();
                    $http
                        .get('login/getPostLoginData',{
                            headers: {
                                'X-Auth-Token': _authToken
                            }
                        }).then(function (response) {
                        vm.loginData = angular.copy(response.data);
                        vm.maskedNumber = angular.copy(maskPhoneNumber(vm.loginData.user.phone))
                    }, function (error) {
                    });
                }else{
                    vm.maskedNumber = angular.copy(maskPhoneNumber(vm.loginData.user.phone))
                }
            }

            function maskPhoneNumber(number){
                let num = String(number)
                if(num && num.length > 5){
                    var length = num.length-3
                    var middle = '*'.repeat(length)
                    return num[0]+middle+num[num.length-2]+num[num.length-1]
                }else {
                    return num;
                }

            }

            function verifyMFACodeFn() {
                if(!vm.mfaCode || (vm.mfaCode && vm.mfaCode.toString().length !== 6)){
                    vm.displayInvalidCodeMsg = true;
                    return true
                }
                let data = vm.loginData
                var params = {
                    code: vm.mfaCode,
                    username: data.user.email,
                };
                AuthenticationService.verifyMfaCode(params, function (res) {
                    if (res.success) {
                        vm.displayInvalidCodeMsg = false;
                        setLocalToken(getLocalToken_temp());
                        $rootScope.$emit('event:user:action', { action: 'user.loggedin', data: data.user });
                        $rootScope.$broadcast(EventEnum.USER_GROUP_CHANGED, data.userGroup);
                    } else {
                        vm.displayInvalidCodeMsg = true;
                    }
                });

            }

            function resendMfaCodeFn() {
                let data = vm.loginData
                var params = {
                    username: data.user.email,
                };
                AuthenticationService.resendMfaCode(params, function (res) {
                    if (res) {
                        vm.codeSentMsg  = true;
                        $timeout(()=> {
                            vm.codeSentMsg  = false;
                        }, 5000);
                        // setLocalToken(getLocalToken_temp());
                        // $rootScope.$broadcast(EventEnum.USER_GROUP_CHANGED, data.userGroup);
                    }
                });

            }

            function switchToEnFn() {
                $rootScope.switchLang('en_US');
                vm.activeEn = 'active';
                vm.activeSv = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToSvFn() {
                $rootScope.switchLang('sv_SV');
                vm.activeSv = 'active';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToNbFn() {
                $rootScope.switchLang('nb_NB');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = 'active';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToDaFn() {
                $rootScope.switchLang('nb_NB');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = 'active';
                vm.activeFi = '';
            }

            function switchToFiFn() {
                $rootScope.switchLang('fi_FI');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = 'active';
            }

            function redirectMeFn() {
                AuthenticationService.logout().then(redirectToHome);
            }

            function redirectToHome() {
                $state.go('auth.login');
            }

            $scope.$watch('$ctrl.mfaCode', function (value) {
                if(!value) {
                    vm.displayInvalidCodeMsg = false;
                }

            });
        }]
});

