'use strict';
//This might contain duplicate code from og_content_and_supplier.directive.js. When implementing the new sidebar in
// supplier as well as budget tab, this will replace og_content_and_supplier.directive.js.

import { ScopeFunctions } from "../../../../../../../utils/global/scopeFunctions";
import designBadgeLogoModal from '../../../../../../modals/modals2.0/design-badge-logo';
import designBadgeBackgroundModal from '../../../../../../modals/modals2.0/design-badge-background';
import badgePreviewModal from '../../../../../../modals/modals2.0/badge-preview-modal';
import _ from "underscore";
import {OgModal} from "../../../../../../../utils/global/ogModal";

const DEFAULT_WIDTH_IN_MM = 100;
const DEFAULT_HEIGHT_IN_MM = 100;
const DEFAULT_LOGO_WIDTH_IN_PX = 150;
const DEFAULT_LOGO_HEIGHT_IN_PX = 112.5;
const FONT_SIZES = [12, 16, 20, 24, 28, 32, 36, 48, 60, 70, 80, 90, 100, 120];

angular.
    module('participants.design.badge').
    component('participantinvitationdesignbadge', {
        templateUrl: './app/components/modules/event/event_detail/participants/invitation/design-badge/drag_drop.view.html',
        controllerAs: 'dragDropCtrl',
        controller: [
            '$q',
            '$rootScope',
            '$scope',
            'BadgeTemplate',
            '$stateParams',
            '$sce',
            'EventPresentation',
            'ModalService',
            'EventEnum',
            'Presentation',
            'ParticipantService',
            '$state',
            '$timeout',
            '$translate',
            function DragDropController(
                $q,
                $rootScope,
                $scope,
                BadgeTemplate,
                $stateParams,
                $sce,
                EventPresentation,
                ModalService,
                EventEnum,
                Presentation,
                ParticipantService,
                $state,
                $timeout,
                $translate,
                CustomerUser
            ) {
                var vm = this;
                vm.viewModal = false;
                vm.isDesignChanged = false;
                vm.addedBasicFields = [];
                vm.addedCustomFields = [];
                vm.addedFields = {};
                // vm.CustomFieldVisible = false;
                vm.heightInMM = DEFAULT_HEIGHT_IN_MM;
                vm.widthInMM = DEFAULT_WIDTH_IN_MM;
                vm.oldHeigthInMM = angular.copy(vm.heightInMM);
                vm.oldWidthInMM = angular.copy(vm.widthInMM);
                vm.canvasDPI = 300;
                vm.index = 0;
                // vm.addLogo = false;
                vm.fontSizes = FONT_SIZES;
                vm.viewDesignBadgeBackgroundModal = false;
                let draggableOutLines =
                    '<span class="topline"></span>' +
                    '<span class="rightline"></span>' +
                    '<span class="botline"></span>' +
                    '<span class="leftline"></span>';
                vm.openDialogBadgePreview = openDialogBadgePreviewFn;
                vm.eventId = $stateParams.eventId;
                // vm.setAsBackground = false;
                vm.saveTemplate = saveTemplateFn;
                vm.getTemplate = getTemplateFn;
                vm.deleteTemplate = deleteTemplateConfirmationFn;
                vm.changeSize = changeSizeFn;
                // vm.setBackground = setBackgroundFn;
                vm.opendesignBadgeLogoModal = openDesignBadgeLogoModalFn;
                vm.openDesignBadgeBackgroundModal = openDesignBadgeBackgroundModalFn;
                vm.addCustomField = addCustomFieldFn;
                vm.addDesignFields = addDesignFieldsFn;
                vm.init = initFn;
                vm.getParticipantQueries = getParticipantQueriesFn;
                vm.deleteImage = deleteImageFn;
                vm.deleteBackgroundImage = deleteBackgroundImageFn;
                vm.removeImageFromCanvas = removeImageFromCanvasFn;
                vm.addOrShowBadgeLogo = addOrShowBadgeLogoFn;
                vm.showBackgroundImageEditOptions = showBackgroundImageEditOptionsFn;
                vm.addOrShowBackgroundImage = addOrShowBackgroundImageFn;
                vm.getDefaultTemplate = getDefaultTemplateFn;
                vm.getQuestionLabel = getQuestionLabelFn;
                vm.isFieldAdded = isFieldAddedFn;
                vm.toggleBold = toggleBoldFn;
                vm.toggleCustomBold = toggleCustomBoldFn;
                vm.toggleTextDecoration = toggleTextDecorationFn;
                vm.toggleCustomTextDecoration = toggleCustomTextDecorationFn;
                vm.trashMe = trashMeFn;
                vm.trashMeDynamic = trashMeDynamicFn;
                vm.toggleLogo = toggleLogoFn;
                // vm.editFontSize = editFontSizeFn;

                ScopeFunctions.addToScope($scope);
                initFn();

                function initFn() {
                    vm.langSelected = $rootScope.langSelected;
                    resetVariableValues();
                    vm.multiplier = getMultiplier();
                    getTemplateFn();
                }

                function resetVariableValues() {
                    vm.dragObject = null;
                    vm.editObject = null;
                    vm.mouseOffset = null;
                    // let containerWidth = null;
                    // let containerHeight = null;
                    // let toppos = null;
                    // let leftpos = null;
                    vm.minWidth = 50;
                    vm.minWidthInMM = 50;
                    vm.minHeight = 50;
                    vm.minHeightInMM = 50;
                    vm.maxWidth = DEFAULT_WIDTH_IN_MM;
                    vm.maxWidthInMM = DEFAULT_WIDTH_IN_MM;
                    vm.maxHeight = DEFAULT_HEIGHT_IN_MM;
                    vm.maxHeightInMM = DEFAULT_HEIGHT_IN_MM;
                    vm.valueTop = 20;
                    // let imageHeight = 0;
                    // let imageWidth = 0;
                    // let _imgDefaultLeft = 0;
                    // let _imgDefaultTop = 0;
                    vm.top = 0;
                    vm.topmover = 53;
                    vm.fixedValRatio = 62.5 / 100; //for ratio
                    vm.fixedUValRatio = 100 / 62.5; //for opposite ratio
                    vm.textFontSize = 24;
                    vm.textFontSizeForSmallCanvas = 16;
                    vm.fontSizeMarginForSmallCanvas = 70; //If canvas size is less than 80 need to decrease font size
                    // let _customField = null;
                }

                function initOnHtml() {
                    initializeDragDropArea();
                    setBackgroundImage();
                    setLogoImage();
                }

                function isFontWeightBoldFn(fontWeight) {
                    //IE doesnot support 'bold' as fontweight and chrome doesnot support 700
                    return fontWeight == 700 || fontWeight == 'bold';
                }

                function setCanvasSize() {
                    vm.height = eval(vm.multiplier * vm.heightInMM).toFixed();
                    vm.width = eval(vm.multiplier * vm.widthInMM).toFixed();
                    vm.dragDropContainerAreaStyle = {
                        display: 'inline-block',
                        position: 'relative',
                        background: '#fff',
                        border: '1px dotted var(--field-border)',
                        overflow: 'hidden',
                        breakInside: 'avoid',
                        width: vm.width,
                        height: vm.height,
                    };
                    // jQuery("#dragDropContainerArea").css("width", vm.width + "px");
                    // jQuery("#dragDropContainerArea").css("height", vm.height + "px");
                }

                function initializeDragDropArea() {
                    vm.topmover = vm.topmover * vm.fixedValRatio;
                    vm.minWidth = eval(vm.multiplier * vm.minWidth).toFixed();
                    vm.minHeight = eval(vm.multiplier * vm.minHeight).toFixed();
                    vm.maxWidth = eval(vm.multiplier * vm.maxWidth).toFixed();
                    vm.maxHeight = eval(vm.multiplier * vm.maxHeight).toFixed();
                    setCanvasSize();
                    // window.addEventListener('load', function() {

                    makeDragAndDropAble(true);
                    makeImageSectionResizable();

                    /*For custom field*/
                    jQuery('#editFontSize').change(function () {
                        jQuery('#customField0').css('font-size', jQuery(this).val() * vm.fixedUValRatio + 'px');
                    });
                    jQuery('#editFontColor').change(function () {
                        jQuery('#customField0').css('color', jQuery(this).val());
                    });
                    jQuery('#editFontText').keyup(function () {
                        let _customField = jQuery('#customField0');
                        let _firstCustomFieldSpan = jQuery('span:first', _customField);
                        _firstCustomFieldSpan.text(jQuery(this).val());
                    });

                    // toggleBoldFn();

                    // jQuery('#editFontWeight').off().on('click', function () {
                    //     let _fw = jQuery('#customField0').css('font-weight');
                    //     let _isBold = isFontWeightBoldFn(_fw);
                    //     _isBold ? jQuery('#customField0').css('font-weight', "normal") : jQuery('#customField0').css('font-weight',
                    //         "bold");
                    //     (!_isBold) ? jQuery("#editFontWeight").addClass("active") : jQuery("#editFontWeight").removeClass("active");
                    // });

                    // jQuery('#editTextDecoration').off().on('click', function () {
                    //     let _customField = jQuery('#customField0');
                    //     let _firstCustomFieldSpan = jQuery('span:first', _customField);
                    //     (_firstCustomFieldSpan.css('text-decoration').substring(0, 9) === 'underline') ? _firstCustomFieldSpan.css(
                    //         'text-decoration', "none") : _firstCustomFieldSpan.css('text-decoration', "underline");
                    //     (_firstCustomFieldSpan.css("text-decoration").substring(0, 9) === "underline") ? jQuery(
                    //         "#editTextDecoration").addClass("active") : jQuery("#editTextDecoration").removeClass("active");
                    // });

                    // jQuery('#trashMe').on('click', function () {
                    //     vm.top -= vm.topmover;
                    //     jQuery('#customField0').remove();
                    //     jQuery('#customFieldEditBox').hide("slow");
                    //     removeAddedIcon("#customFieldMenu");
                    //     vm.index = 0;
                    // });

                    /*custom field ends here and dynamic fields start*/

                    // editFontSizeFn();
                    jQuery('#editDynamicFontSize').change(function () {
                        jQuery(vm.editObject).css('font-size', jQuery(this).val() * vm.fixedUValRatio + 'px');
                    });
                    jQuery('#editDynamicFontColor').change(function () {
                        jQuery(vm.editObject).css('color', jQuery(this).val());
                    });

                    // jQuery('#editDynamicFontWeight').off().on('click', function () {
                    //     let _fontWeight = jQuery(vm.editObject).css('font-weight');
                    //     let _isCurrentlyBold = isFontWeightBoldFn(_fontWeight);
                    //     _isCurrentlyBold ? jQuery(vm.editObject).css('font-weight', "normal") : jQuery(vm.editObject).css('font-weight',
                    //         "bold");
                    //     _isCurrentlyBold ? jQuery("#editDynamicFontWeight").removeClass("active") : jQuery(
                    //         "#editDynamicFontWeight").addClass("active")
                    // });

                    // jQuery('#editDynamicTextDecoration').off().on('click', function () {
                    //     let _firstSpan = jQuery('span:first', vm.editObject);
                    //     let _isCurrentlyUnderlined = (_firstSpan.css('text-decoration').substring(0, 9) === 'underline');
                    //     _isCurrentlyUnderlined ? _firstSpan.css('text-decoration', "none") : _firstSpan.css(
                    //         'text-decoration', "underline");
                    //     _isCurrentlyUnderlined ? jQuery("#editDynamicTextDecoration").removeClass("active") : jQuery(
                    //         "#editDynamicTextDecoration").addClass("active");
                    // });

                    // jQuery('#trashMeDynamic').on('click', function () {
                    //     vm.top -= vm.topmover;
                    //     jQuery('#dynamicEditBox').hide("slow");
                    //     var att_id = jQuery(vm.editObject).attr("data-menu");
                    //     if (jQuery(att_id)) {
                    //         removeAddedIcon("#" + att_id);
                    //     }
                    //     removeItemFromAddedListById(jQuery(vm.editObject).attr("id"));
                    //     jQuery(vm.editObject).remove();
                    //
                    //     vm.editObject = null;
                    // });
                    /*dynamic fields end here*/

                    //image functions
                    // jQuery("#myPhoto").click(function (ev) {
                    //     jQuery("#customFieldEditBox").hide("slow");
                    //     jQuery("#dynamicEditBox").hide("slow");
                    //     jQuery("#dynamicBackgroundImage").hide("slow");
                    //     jQuery("#dynamicImage").show('slow');
                    // });

                    jQuery(document).click(function (event) {
                        let targetId = event.target.id;
                        if (targetId.indexOf('basicMenu_') == 0 && jQuery(event.target).closest('#' + targetId).length) {
                            jQuery('#dynamicEditBox').show('fast');
                        } else if (
                            targetId.indexOf('customFieldMenu') == 0 &&
                            jQuery(event.target).closest('#' + targetId).length
                        ) {
                            jQuery('#dynamicEditBox').hide('fast');
                            jQuery('#dynamicImage').hide('fast');
                            jQuery('#dynamicBackgroundImage').hide('fast');
                            jQuery('#customFieldEditBox').show('fast');
                        } else if (
                            targetId.indexOf('myPhoto_menu') == 0 &&
                            jQuery(event.target).closest('#' + targetId).length
                        ) {
                            jQuery('#dynamicEditBox').hide('fast');
                            jQuery('#customFieldEditBox').hide('fast');
                            jQuery('#dynamicBackgroundImage').hide('fast');
                            jQuery('#dynamicImage').show('fast');
                        } else if (
                            targetId.indexOf('background_menu') == 0 &&
                            jQuery(event.target).closest('#' + targetId).length
                        ) {
                            jQuery('#dynamicEditBox').hide('fast');
                            jQuery('#customFieldEditBox').hide('fast');
                            jQuery('#dynamicImage').hide('fast');
                            jQuery('#dynamicBackgroundImage').show('fast');
                        } else {
                            if (!jQuery(event.target).closest('#dragDropContainerArea').length) {
                                if (!jQuery(event.target).closest('#customFieldEditBox').length) {
                                    jQuery('#customFieldEditBox').hide('fast');
                                }
                                if (!jQuery(event.target).closest('#dynamicEditBox').length) {
                                    jQuery('#dynamicEditBox').hide('sloweditDynamicFontSize');
                                }
                                if (!jQuery(event.target).closest('#dynamicImage').length) {
                                    jQuery('#dynamicImage').hide('fast');
                                }
                                if (!jQuery(event.target).closest('#dynamicBackgroundImage').length) {
                                    jQuery('#dynamicBackgroundImage').hide('fast');
                                }
                            }
                        }
                    });

                    setBackgroundImage();
                    if (vm.isNewTemplate && !vm.isTemplateUpdated) {
                        vm.getDefaultTemplate();
                    }
                }

                // function editFontSizeFn() {
                //     jQuery("#editDynamicFontSize").change(function () {
                //         jQuery(vm.editObject).css("font-size", (jQuery(this).val() * vm.fixedUValRatio) + "px");
                //     });
                // }

                function toggleBoldFn() {
                    let _fontWeight = jQuery(vm.editObject).css('font-weight');
                    let _isCurrentlyBold = isFontWeightBoldFn(_fontWeight);
                    _isCurrentlyBold
                        ? jQuery(vm.editObject).css('font-weight', 'normal')
                        : jQuery(vm.editObject).css('font-weight', 'bold');
                    _isCurrentlyBold
                        ? jQuery('#editDynamicFontWeight').removeClass('active')
                        : jQuery('#editDynamicFontWeight').addClass('active');
                }

                function toggleCustomBoldFn() {
                    let _fw = jQuery('#customField0').css('font-weight');
                    let _isBold = isFontWeightBoldFn(_fw);
                    _isBold
                        ? jQuery('#customField0').css('font-weight', 'normal')
                        : jQuery('#customField0').css('font-weight', 'bold');
                    !_isBold ? jQuery('#editFontWeight').addClass('active') : jQuery('#editFontWeight').removeClass('active');
                }

                function toggleTextDecorationFn() {
                    let _firstSpan = jQuery('span:first', vm.editObject);
                    let _isCurrentlyUnderlined = _firstSpan.css('text-decoration').substring(0, 9) === 'underline';
                    _isCurrentlyUnderlined
                        ? _firstSpan.css('text-decoration', 'none')
                        : _firstSpan.css('text-decoration', 'underline');
                    _isCurrentlyUnderlined
                        ? jQuery('#editDynamicTextDecoration').removeClass('active')
                        : jQuery('#editDynamicTextDecoration').addClass('active');
                }

                function toggleCustomTextDecorationFn() {
                    let _customField = jQuery('#customField0');
                    let _firstCustomFieldSpan = jQuery('span:first', _customField);
                    _firstCustomFieldSpan.css('text-decoration').substring(0, 9) === 'underline'
                        ? _firstCustomFieldSpan.css('text-decoration', 'none')
                        : _firstCustomFieldSpan.css('text-decoration', 'underline');
                    _firstCustomFieldSpan.css('text-decoration').substring(0, 9) === 'underline'
                        ? jQuery('#editTextDecoration').addClass('active')
                        : jQuery('#editTextDecoration').removeClass('active');
                }

                function trashMeFn() {
                    vm.top -= vm.topmover;
                    jQuery('#customField0').remove();
                    jQuery('#customFieldEditBox').hide('fast');
                    removeAddedIcon('#customFieldMenu');
                    vm.index = 0;
                }

                function trashMeDynamicFn() {
                    vm.top -= vm.topmover;
                    jQuery('#dynamicEditBox').hide('fast');
                    var att_id = jQuery(vm.editObject).attr('data-menu');
                    if (jQuery(att_id)) {
                        removeAddedIcon('#' + att_id);
                    }
                    removeItemFromAddedListById(jQuery(vm.editObject).attr('id'));
                    jQuery(vm.editObject).remove();

                    vm.editObject = null;
                }

                function toggleLogoFn() {
                    jQuery('#customFieldEditBox').hide('fast');
                    jQuery('#dynamicEditBox').hide('fast');
                    jQuery('#dynamicBackgroundImage').hide('fast');
                    jQuery('#dynamicImage').show('fast');
                }

                function openDesignBadgeLogoModalFn() {
                    if(vm.viewModal) {
                        return
                    }
                    vm.viewModal = true;
                    let data = {
                        presentationId: vm.slide.id,
                        successCallBack: function () {
                            vm.init();
                            vm.isTemplateUpdated = true;
                        },
                    };
                    let designBadgeLogoData = designBadgeLogoModal;
                    designBadgeLogoData.inputs = { data };
                    ModalService.showModal(designBadgeLogoData).then((modal) => {
                        vm.viewModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }

                function openDesignBadgeBackgroundModalFn() {
                    if(vm.viewModal) {
                        return
                    }
                    vm.viewModal = true;
                    let data = {
                        presentationId: vm.slide.id,
                        successCallBack: function () {
                            vm.init();
                            vm.isTemplateUpdated = true;
                        },
                    };
                    let designBadgeBackgroundData = designBadgeBackgroundModal;
                    designBadgeBackgroundData.inputs = { data };
                    ModalService.showModal(designBadgeBackgroundData).then((modal) => {
                        vm.viewModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }

                function rgb2hex(rgb) {
                    rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

                    function hex(x) {
                        return ('0' + parseInt(x).toString(16)).slice(-2);
                    }

                    return '#' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
                }

                function addDesignFieldsFn(type, query, isDesignChanged = true, addedFromDefaultTemplate = false) {
                    vm.isDesignChanged = isDesignChanged;
                    let data_id = query.id;
                    let data_value = ParticipantService.getQuestionLabel(query, $rootScope.langSelected);
                    let _identifierClassName = '';
                    if (type === 0) {
                        vm.addedBasicFields.push(query);
                        _identifierClassName = 'basic_replaceable_item_' + query.eventParticipantBasicQuery.id;
                    } else if (1 === type) {
                        vm.addedCustomFields.push(query);
                        _identifierClassName = 'custom_replaceable_item_' + query.id;
                    } else if (2 === type) {
                        query.eventParticipantBasicQuery = { id: 'name' };
                        query.id = 'fullName';
                        query.detailNameEnglish = 'Full Name';
                        data_id = query.id;
                        data_value =
                            $rootScope.langSelected === 'en_US'
                                ? 'First & Last Name'
                                : $rootScope.langSelected === 'sv_SV'
                                    ? 'Förnamn & efternamn'
                                    : $rootScope.langSelected === 'nb_NB'
                                        ? 'For- og etternavn'
                                        : $rootScope.langSelected === 'da_DK'
                                            ? 'For- & efternavn'
                                            : 'Etu- & sukunimi';
                        vm.addedBasicFields.push(query);
                        _identifierClassName = 'basic_replaceable_item_' + query.eventParticipantBasicQuery.id;
                    }

                    let _dataValueSpan = "<span class='" + _identifierClassName + "'>" + data_value + ' </span>';
                    let menuText = type == 0 || type == 2 ? 'basic' : 'custom';
                    let this_id = '#' + menuText + 'Design_' + data_id;
                    let att_id = menuText + 'Menu_' + data_id;

                    if (jQuery(this_id).attr('data-menu') != att_id) {
                        let nowTop = vm.top + vm.topmover;
                        let _fontSize =
                            vm.heightInMM < vm.fontSizeMarginForSmallCanvas || vm.widthInMM < vm.fontSizeMarginForSmallCanvas
                                ? vm.textFontSizeForSmallCanvas
                                : vm.textFontSize;
                        _fontSize = _fontSize * vm.fixedUValRatio;
                        jQuery('#dragDropContainerArea').append(
                            "<div class='menu-content' id='" +
                            menuText +
                            'Design_' +
                            data_id +
                            "' data-menu='" +
                            menuText +
                            'Menu_' +
                            data_id +
                            "'  style='cursor:pointer; z-index:1;" +
                            ' font-size:' +
                            _fontSize +
                            'px; font-family: @font-proxbold-rail; ' +
                            'font-weight: bold; left:5px; position:relative; text-decoration: none; ' +
                            "color: #000000;'>" +
                            _dataValueSpan +
                            draggableOutLines +
                            '</div>'
                        );
                        showAddedIcon('#' + att_id);
                        vm.top = nowTop;

                        jQuery(this_id).click(function (ev) {
                            ev.stopPropagation();
                            showBasicFieldsEditBox(this);
                            vm.editObject = this;
                        });
                        makeDragAndDropAble(false);
                    }
                    if (!addedFromDefaultTemplate) {
                        vm.editObject = this_id;
                        showBasicFieldsEditBox(vm.editObject);
                    }
                }

                function showBasicFieldsEditBox(editObject) {
                    let _fontSize = parseFloat(jQuery(editObject).css('font-size')) * vm.fixedValRatio;
                    var colorOfText = rgb2hex(jQuery(editObject).css('color')).toUpperCase().substring(0, 7);
                    jQuery('#editDynamicFontSize').val(_fontSize);
                    jQuery('#editDynamicFontColor').val(colorOfText);

                    let _fontWeight = jQuery(editObject).css('font-weight');
                    let _isCurrentlyBold = isFontWeightBoldFn(_fontWeight);
                    let _firstCustomFieldSpan = jQuery('span:first', editObject);
                    let _isCurrentlyUnderlined = _firstCustomFieldSpan.css('text-decoration').substring(0, 9) === 'underline';
                    let editDynamicFontWeightElement = jQuery('#editDynamicFontWeight');
                    _isCurrentlyBold
                        ? editDynamicFontWeightElement.addClass('active')
                        : editDynamicFontWeightElement.removeClass('active');
                    let editDynamicTextDecorationElement = jQuery('#editDynamicTextDecoration');
                    _isCurrentlyUnderlined
                        ? editDynamicTextDecorationElement.addClass('active')
                        : editDynamicTextDecorationElement.removeClass('active');

                    jQuery('#customFieldEditBox').hide('fast');
                    jQuery('#dynamicImage').hide('fast');
                    jQuery('#dynamicBackgroundImage').hide('fast');
                    let dynamicEditBox = jQuery('#dynamicEditBox');
                    if (dynamicEditBox.css('display', 'none')) {
                        jQuery('#editWhatContent').text(jQuery(editObject).text());
                        dynamicEditBox.show('fast');
                    } else {
                        dynamicEditBox.hide('fast');
                        jQuery('#editWhatContent').text(jQuery(editObject).text());
                        dynamicEditBox.show('fast');
                    }
                }

                function showCustomFieldsEditBox() {
                    let _customColorOfText = rgb2hex(jQuery('#customField0').css('color')).toUpperCase().substring(0, 7);
                    //assign default style values to the boxes
                    jQuery('#editFontSize option[value=24]').attr('selected', 'selected');
                    jQuery('#editFontColor option[value=#000000]').attr('selected', 'selected');
                    let _fontWeight = jQuery('#customField0').css('font-weight');
                    let _isCurrentlyBold = isFontWeightBoldFn(_fontWeight);
                    let _firstCustomFieldSpan = jQuery('span:first', jQuery('#customField0'));
                    let _isCurrentlyUnderlined = _firstCustomFieldSpan.css('text-decoration').substring(0, 9) === 'underline';

                    _isCurrentlyBold
                        ? jQuery('#editFontWeight').addClass('active')
                        : jQuery('#editFontWeight').removeClass('active');
                    _isCurrentlyUnderlined
                        ? jQuery('#editTextDecoration').addClass('active')
                        : jQuery('#editTextDecoration').removeClass('active');
                    jQuery('#editFontColor').val(_customColorOfText);

                    jQuery('#dynamicEditBox').hide('fast');
                    jQuery('#dynamicImage').hide('fast');
                    jQuery('#dynamicBackgroundImage').hide('fast');
                    jQuery('#customFieldEditBox').show('fast');
                }

                function addCustomFieldFn() {
                    vm.customFieldName = $translate.instant('design.badge.custom.field');
                    vm.isDesignChanged = true;
                    vm.top += vm.topmover;
                    if (vm.index == 0) {
                        let _fontSize =
                            vm.heightInMM < vm.fontSizeMarginForSmallCanvas || vm.widthInMM < vm.fontSizeMarginForSmallCanvas
                                ? vm.textFontSizeForSmallCanvas
                                : vm.textFontSize;
                        _fontSize = _fontSize * vm.fixedUValRatio;
                        let _dataValueSpan = '<span>' + vm.customFieldName + '</span>';
                        jQuery('#dragDropContainerArea').append(
                            "<div class='menu-content' id='customField" +
                            vm.index +
                            "' style='cursor:pointer; z-index:1; font-size:" +
                            _fontSize +
                            'px;' +
                            'font-family: @font-proxbold-rail; font-weight: bold; left:5px;' +
                            " text-decoration: none; color: #000000;'>" +
                            _dataValueSpan +
                            draggableOutLines +
                            '</div>'
                        );
                        showAddedIcon('#customFieldMenu');
                        jQuery('#customField' + vm.index)
                            .off()
                            .on('click', function () {
                                //Prevent outer click function event
                                if (!e) {
                                    var e = window.event;
                                }
                                e.cancelBubble = true;
                                if (e.stopPropagation) {
                                    e.stopPropagation();
                                }
                                showCustomFieldsEditBox();
                            });

                        makeDragAndDropAble(false);

                        showCustomFieldsEditBox();
                    }
                    vm.index += 1;
                }

                function getDefaultTemplateFn() {
                    jQuery('.menu-content').remove(); //remove all existing content
                    vm.addDesignFields(2, {}, false, true); //add full name
                    let company = _.findWhere(vm.basicQueryForEvent, { detailNameEnglish: 'Company' });
                    if (company) {
                        vm.addDesignFields(0, company, false, true); //add company if it exists
                    }
                    vm.isDesignChanged = false;
                }

                function getParticipantQueriesFn() {
                    $scope.startProgress();
                    EventPresentation.getSelectedQueriesAndInvitationsForEvent(
                        { eventId: vm.eventId },
                        function (response) {
                            vm.basicQueryForEvent = response.basicQueriesForEvent;
                            vm.customQueryForEvent = response.customQueriesForEvent;
                            vm.presentationDetails = response.presentationDetails;
                            vm.slide = response.presentationDetails.eventPresentationDetails.slide;
                            vm.logo = response.logo;
                            vm.originalLogo = response.logo;
                            vm.backgroundImage = response.backgroundImage;
                            vm.originalBackgroundImage = response.backgroundImage;
                            initOnHtml();
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function getQuestionLabelFn(query) {
                    return ParticipantService.getQuestionLabel(query, $rootScope.langSelected);
                }

                function changeSizeFn() {
                    let _wRatio = vm.widthInMM / vm.oldWidthInMM;
                    let _hRatio = vm.heightInMM / vm.oldHeigthInMM;

                    vm.isDesignChanged = true;
                    setNewWidth();
                    setNewHeight();
                    setRelativeTopLeftForEachDiv(_hRatio, _wRatio);
                    setCanvasSize();
                    setBackgroundImage();
                    setDefaultFontSize();
                    vm.oldHeigthInMM = angular.copy(vm.heightInMM);
                    vm.oldWidthInMM = angular.copy(vm.widthInMM);
                    return false;
                }

                function setRelativeTopLeftForEachDiv(heightIncreaseRatio, widthIncreaseRatio) {
                    jQuery('#dragDropContainerArea')
                        .children('div')
                        .each(function () {
                            let _currentLeft = jQuery(this).css('left');
                            let _currentTop = jQuery(this).css('top');
                            let _newLeft = parseFloat(_currentLeft) * widthIncreaseRatio;
                            let _newTop = parseFloat(_currentTop) * heightIncreaseRatio;
                            jQuery(this)
                                .css('top', _newTop + 'px')
                                .css('left', _newLeft + 'px');
                            if (jQuery(this).attr('id') == 'myPhoto') {
                                let imgWidth = $('#myImage').width();
                                let _newWidth = imgWidth * widthIncreaseRatio;
                                let imgHeight = $('#myImage').height();
                                let _newHeight = imgHeight * heightIncreaseRatio;
                                let _widthDiff = _newWidth - imgWidth;
                                let _heightDiff = _newHeight - imgHeight;

                                if (_heightDiff) {
                                    //  _newTop = _newTop - _heightDiff;
                                }

                                if (_widthDiff) {
                                    _newLeft = _newLeft + _widthDiff;
                                }

                                jQuery(this)
                                    .css('top', _newTop + 'px')
                                    .css('left', _newLeft + 'px');
                            }
                        });
                }

                function setNewWidth() {
                    if (vm.widthInMM < vm.minWidthInMM) {
                        vm.widthInMM = vm.minWidthInMM;
                    } else if (vm.widthInMM > vm.maxWidthInMM) {
                        vm.widthInMM = vm.maxWidthInMM;
                    }
                }

                function setNewHeight() {
                    if (vm.heightInMM < vm.minHeightInMM) {
                        vm.heightInMM = vm.minHeightInMM;
                    } else if (vm.heightInMM > vm.maxHeightInMM) {
                        vm.heightInMM = vm.maxHeightInMM;
                    }
                }

                function setDefaultFontSize() {
                    //If height or width of canvas is less than 80 need to reset default font size
                    let _fontSize =
                        vm.heightInMM < vm.fontSizeMarginForSmallCanvas || vm.widthInMM < vm.fontSizeMarginForSmallCanvas
                            ? vm.textFontSizeForSmallCanvas
                            : vm.textFontSize;
                    let _designFontSize = _fontSize * vm.fixedUValRatio;
                    jQuery('#dragDropContainerArea')
                        .children('div')
                        .each(function () {
                            jQuery(this).css('font-size', _designFontSize + 'px');
                        });
                    // _textFontSize = _fontSize;
                }

                function mouseCoords(ev) {
                    if (ev.pageX || ev.pageY) {
                        return { x: ev.pageX, y: ev.pageY };
                    }
                    return {
                        x: ev.clientX + document.body.scrollLeft - document.body.clientLeft,
                        y: ev.clientY + document.body.scrollTop - document.body.clientTop,
                    };
                }

                function getMouseOffset(target, ev) {
                    ev = ev || window.event;
                    var docPos = getPosition(target);
                    var mousePos = mouseCoords(ev);

                    return { x: mousePos.x - docPos.x, y: mousePos.y - docPos.y };
                }

                function getPosition(e) {
                    var left = 0;
                    var top = 0;

                    while (e.offsetParent) {
                        left += e.offsetLeft;
                        top += e.offsetTop;
                        e = e.offsetParent;
                    }
                    left += e.offsetLeft;
                    top += e.offsetTop;
                    return { x: left, y: top };
                }

                function saveTemplateFn() {
                    //need to remove resizable before saving the template
                    jQuery('#myImage').resizable('destroy');
                    let _htmlTemplate = $('#dragDropContainerArea').wrap('').parent().html();
                    let addedFields = {
                        basicFields: [],
                        customFields: [],
                    };

                    angular.forEach(vm.addedBasicFields, function (addedBasicField) {
                        addedFields.basicFields.push({
                            id: addedBasicField.id,
                            detailNameEnglish: addedBasicField.detailNameEnglish,
                            detailNameSwedish: addedBasicField.detailNameSwedish,
                            detailNameNorwegian: addedBasicField.detailNameNorwegian,
                        });
                    });

                    angular.forEach(vm.addedCustomFields, function (addedCustomField) {
                        addedFields.customFields.push({
                            id: addedCustomField.id,
                            detailNameEnglish: addedCustomField.detailNameEnglish,
                            detailNameSwedish: addedCustomField.detailNameSwedish,
                            detailNameNorwegian: addedCustomField.detailNameNorwegian,
                        });
                    });

                    let _data = {
                        html: _htmlTemplate,
                        eventId: vm.eventId,
                        height: vm.heightInMM,
                        width: vm.widthInMM,
                        addedFields: addedFields,
                    };
                    $scope.startProgress();
                    BadgeTemplate.update(
                        _data,
                        (resp) => {
                            vm.htmlTemplate = $sce.trustAsHtml(resp.html);
                            vm.heightInMM = resp.height;
                            vm.widthInMM = resp.width;
                            vm.oldHeigthInMM = angular.copy(vm.heightInMM);
                            vm.oldWidthInMM = angular.copy(vm.widthInMM);
                            vm.addedFields = resp.addedFields;
                            vm.isDesignChanged = false;
                            $scope.endProgress();
                            initFn();
                        },
                        $scope.endWithErrorOverlay()
                    );
                }

                function getTemplateFn() {
                    let _data = { eventId: vm.eventId };
                    $scope.startProgress();
                    BadgeTemplate.get(
                        _data,
                        (resp) => {
                            if (resp.id) {
                                vm.htmlTemplate = $sce.trustAsHtml(resp.html);
                                vm.heightInMM = resp.height;
                                vm.widthInMM = resp.width;
                                vm.oldHeigthInMM = angular.copy(vm.heightInMM);
                                vm.oldWidthInMM = angular.copy(vm.widthInMM);
                            }
                            vm.addedFields = resp.addedFields;
                            vm.isNewTemplate = !resp.id;
                            getParticipantQueriesFn();
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay()
                    );
                }

                function getMultiplier() {
                    return eval(vm.canvasDPI / 25.4) * vm.fixedValRatio;
                }

                function makeTextDraggable() {
                    jQuery(function () {
                        jQuery('.menu-content').draggable({
                            containment: '#dragDropContainerArea',
                            drag: function () {
                                jQuery('body').css('overflow', 'hidden');
                                jQuery(this).find(jQuery('.topline')).css('display', 'block');
                                jQuery(this).find(jQuery('.rightline')).css('display', 'block');
                                jQuery(this).find(jQuery('.botline')).css('display', 'block');
                                jQuery(this).find(jQuery('.leftline')).css('display', 'block');
                            },
                            start: function () {
                                jQuery('body').css('overflow', 'hidden');
                                jQuery(this).find(jQuery('.topline')).css('display', 'block');
                                jQuery(this).find(jQuery('.rightline')).css('display', 'block');
                                jQuery(this).find(jQuery('.botline')).css('display', 'block');
                                jQuery(this).find(jQuery('.leftline')).css('display', 'block');
                            },
                            stop: function () {
                                jQuery('body').css('overflow', 'initial');
                                jQuery(this).find(jQuery('.topline')).css('display', 'none');
                                jQuery(this).find(jQuery('.rightline')).css('display', 'none');
                                jQuery(this).find(jQuery('.botline')).css('display', 'none');
                                jQuery(this).find(jQuery('.leftline')).css('display', 'none');
                            },
                        });
                    });
                }

                function makeLogoDraggable() {
                    jQuery(function () {
                        jQuery('#myPhoto').draggable({
                            containment: '#dragDropContainerArea',
                            drag: function () {
                                jQuery(this).find(jQuery('.topline')).css('display', 'block');
                                jQuery(this).find(jQuery('.rightline')).css('display', 'block');
                                jQuery(this).find(jQuery('.botline')).css('display', 'block');
                                jQuery(this).find(jQuery('.leftline')).css('display', 'block');
                            },
                            start: function () {
                                jQuery(this).find(jQuery('.topline')).css('display', 'block');
                                jQuery(this).find(jQuery('.rightline')).css('display', 'block');
                                jQuery(this).find(jQuery('.botline')).css('display', 'block');
                                jQuery(this).find(jQuery('.leftline')).css('display', 'block');
                            },
                            stop: function () {
                                jQuery(this).find(jQuery('.topline')).css('display', 'none');
                                jQuery(this).find(jQuery('.rightline')).css('display', 'none');
                                jQuery(this).find(jQuery('.botline')).css('display', 'none');
                                jQuery(this).find(jQuery('.leftline')).css('display', 'none');
                            },
                        });
                    });
                }

                function makeImageSectionResizable() {
                    jQuery('#myImage').resizable({
                        alsoResize: '#myPhoto',
                        //handles: "all",
                        minHeight: 80,
                        // todo
                        maxHeight: eval(vm.heightInMM * vm.multiplier - 2).toFixed(),
                        minWidth: 80,
                        maxWidth: eval(vm.widthInMM * vm.multiplier - 2).toFixed(),
                    });
                }

                function deleteTemplateFn() {
                    $scope.startProgress();
                    BadgeTemplate['delete'](
                        vm.eventId,
                        (resp) => {
                            vm.htmlTemplate = null;
                            vm.widthInMM = 100;
                            vm.heightInMM = 100;
                            vm.oldHeigthInMM = angular.copy(vm.heightInMM);
                            vm.oldWidthInMM = angular.copy(vm.widthInMM);
                            $scope.endProgress();
                            initFn();
                        },
                        (data) => {
                            $rootScope.alert(data);
                        }
                    );
                }

                $rootScope.$on('presentation:badgeUrl:changed', function (event, data) {
                    vm.isDesignChanged = true;
                    jQuery('#myPhoto').show();
                });

                $rootScope.$on('presentation:badgeBackgroundUrl:changed', function (event, data) {
                    vm.isDesignChanged = true;
                    setBackgroundImage();
                });

                function setLogoImage() {
                    if (vm.logo) {
                        document.getElementById('myImage').src = 'data:image/png;base64,' + vm.logo;
                        setDefaultImageSize();
                        let logo = jQuery('#myImage');
                        logo.show();
                    } else {
                        jQuery('#myPhoto').hide();
                        jQuery('#myImage').hide();
                    }
                }

                function setBackgroundImage() {
                    if (vm.backgroundImage) {
                        document.getElementById('backgroundImage').src = 'data:image/png;base64,' + vm.backgroundImage;
                        jQuery('#backgroundImage')
                            .css('left', '0px')
                            .css('top', '0px')
                            .css('right', '0px')
                            .css('bottom', '0px')
                            .css('width', vm.width - 2 + 'px')
                            .css('height', vm.height - 2 + 'px');
                        jQuery('#backgroundPhoto')
                            .css('left', '0px')
                            .css('top', '0px')
                            .css('right', '0px')
                            .css('bottom', '0px')
                            .width(vm.width + 'px')
                            .height(vm.height + 'px')
                            .css('display', 'block');
                        jQuery('#backgroundPhoto .ui-wrapper')
                            .width(vm.width + 'px')
                            .height(vm.height + 'px');
                        showAddedIcon('#background_menu');
                    } else {
                        jQuery('#backgroundPhoto').css('display', 'none');
                        removeAddedIcon('#background_menu');
                    }
                }

                var $locationChangeStartUnbind = $scope.$on(
                    '$stateChangeStart',
                    function (event, toState, toParams, fromState, fromParams) {
                        if (toState != fromState && vm.isDesignChanged) {
                            if (!vm.confirmLeave) {
                                event.preventDefault();
                                $rootScope.$broadcast('cancelStateChange');
                                OgModal.open('confirmation', {
                                    message: 'unsaved.changes.confirm.leave',
                                    okCallBack: function () {
                                        vm.confirmLeave = true;
                                        $state.go(toState.name);
                                    },
                                    cancelCallBack: function () {
                                        $rootScope.$broadcast('keepBadgeDesignActive');
                                    },
                                    closeCallBack: function () {
                                        $rootScope.$broadcast('keepBadgeDesignActive');
                                    },
                                });
                            } else {
                                return true;
                            }
                        }
                    }
                );

                $scope.$on('$destroy', function () {
                    $locationChangeStartUnbind();
                });

                function getHtmlAsStringForPreview() {
                    let _str = '';
                    _str +=
                        "<div id='dragDropContainerArea' style=' width:" +
                        vm.widthInMM +
                        'mm; display: inline-block; ' +
                        'height:' +
                        vm.heightInMM +
                        'mm; position: relative; ' +
                        "border: 1px dotted var(--field-border); page-break-inside: avoid;overflow: hidden;'>";
                    jQuery('#dragDropContainerArea')
                        .children('div')
                        .each(function () {
                            if (this.id !== 'ngBindHtmlSection') {
                                if (this.id === 'myPhoto') {
                                    let _isPhotoSetAsDisplayInCanvas = jQuery(this).css('display') === 'block';
                                    if (_isPhotoSetAsDisplayInCanvas) {
                                        let _imgLeft = getNormalizedWidth(parseFloat(jQuery(this).css('left')));
                                        let _imgTop = getNormalizedHeight(parseFloat(jQuery(this).css('top')));
                                        let _imgWidth = getNormalizedWidth(parseFloat(jQuery(this).css('width')));
                                        let _imgHeight = getNormalizedWidth(parseFloat(jQuery(this).css('height')));

                                        let _imgSrc = jQuery(this).find('img').attr('src');
                                        _str +=
                                            "<div id='myPhoto' style='position: absolute; " +
                                            'top: ' +
                                            _imgTop +
                                            'mm; left: ' +
                                            _imgLeft +
                                            'mm; z-index: 0;' +
                                            ' width: ' +
                                            _imgWidth +
                                            'mm; height: ' +
                                            _imgHeight +
                                            'mm;' +
                                            "cursor: move;'><img id='myImage' " +
                                            "style='height:" +
                                            _imgHeight +
                                            'mm; width: ' +
                                            _imgWidth +
                                            "mm;'" +
                                            " src='" +
                                            _imgSrc +
                                            "'/></div>";
                                    }
                                } else if (this.id === 'backgroundPhoto') {
                                    let _isPhotoSetAsDisplayInCanvas = jQuery(this).css('display') === 'block';
                                    if (_isPhotoSetAsDisplayInCanvas) {
                                        let _imgSrc = jQuery(this).find('img').attr('src');
                                        _str +=
                                            "<div id='backgroundPhoto' style='position: absolute; " +
                                            'top: ' +
                                            0 +
                                            'mm; left: ' +
                                            0 +
                                            'mm; z-index: 0;' +
                                            ' width: ' +
                                            vm.widthInMM +
                                            'mm; height: ' +
                                            vm.heightInMM +
                                            'mm;' +
                                            "cursor: move;'><img id='backgroundImage' " +
                                            "style='height:" +
                                            vm.heightInMM +
                                            'mm; width: ' +
                                            vm.widthInMM +
                                            "mm;'" +
                                            " src='" +
                                            _imgSrc +
                                            "'/></div>";
                                    }
                                } else {
                                    let _divLeft = getNormalizedWidth(parseFloat(jQuery(this).css('left')));
                                    let _divTop = getNormalizedHeight(parseFloat(jQuery(this).css('top')));
                                    let _divFontSize = parseFloat(jQuery(this).css('font-size')) * vm.fixedValRatio;
                                    let _divFontWeight = jQuery(this).css('font-weight');
                                    let _divDecoration = jQuery(this).css('text-decoration');
                                    let _divColor = jQuery(this).css('color');
                                    let _divDataValue = jQuery(this).html();
                                    let _divId = this.id;
                                    _str +=
                                        "<div id='" +
                                        _divId +
                                        "' style='cursor:pointer; z-index:1;" +
                                        ' font-size:' +
                                        _divFontSize +
                                        'px; font-family: @font-proxbold-rail; ' +
                                        'font-weight: ' +
                                        _divFontWeight +
                                        '; left:' +
                                        _divLeft +
                                        'mm; top:' +
                                        _divTop +
                                        'mm; ' +
                                        'position:relative; ' +
                                        'text-decoration: ' +
                                        _divDecoration +
                                        '; ' +
                                        'color: ' +
                                        _divColor +
                                        ";'>" +
                                        _divDataValue +
                                        '</div>';
                                }
                            }
                        });
                    _str += '</div>';
                    return _str;
                }

                function openDialogBadgePreviewFn() {
                    if(vm.viewModal) {
                        return
                    }
                    vm.viewModal = true;
                    let _htmlTemplate = getHtmlAsStringForPreview();
                    let data = {
                        eventId: vm.eventId,
                        htmlTemplate: _htmlTemplate,
                        addedBasicFields: vm.basicQueryForEvent,
                        addedCustomFields: vm.customQueryForEvent,
                    };
                    let _badgePrintPreviewModal = badgePreviewModal;
                    _badgePrintPreviewModal.inputs = { data };
                    ModalService.showModal(_badgePrintPreviewModal).then((modal) => {
                        vm.viewModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }

                function removeImageFromCanvasFn() {
                    if (vm.slide.badgeUrl) {
                        removeAddedIcon('#myPhoto_menu');
                    }
                    vm.logo = null;
                    jQuery('#myPhoto').hide();
                }

                function deleteImageFn() {
                    if (vm.slide.badgeUrl) {
                        $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, {
                            message: 'picture.delete.confirm.badge',
                            okCallBack: function () {
                                $scope.startProgress();
                                Presentation.deleteImage({ currentImg: vm.slide.badgeUrl }, function (response) {
                                    //clear img src with 1x1 pixel - https://stackoverflow.com/questions/10865689/clear-an-img-with-jquery#comment-14156659
                                    jQuery('#myImage').attr(
                                        'src',
                                        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABA‌​AACAUwAOw=='
                                    );
                                    initFn();
                                    removeAddedIcon('#myPhoto_menu');
                                    $scope.endProgress();
                                });
                            },
                        });
                    } else {
                        vm.removeImageFromCanvas();
                    }
                    jQuery('#dynamicImage').hide('fast');
                }

                function deleteBackgroundImageFn() {
                    if (vm.slide.badgeBackgroundUrl) {
                        $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, {
                            message: 'picture.delete.confirm.badge',
                            okCallBack: function () {
                                Presentation.deleteImage({ currentImg: vm.slide.badgeBackgroundUrl }, function (response) {
                                    //clear img src with 1x1 pixel - https://stackoverflow.com/questions/10865689/clear-an-img-with-jquery#comment-14156659
                                    jQuery('#backgroundImage').attr(
                                        'src',
                                        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABA‌​AACAUwAOw=='
                                    );
                                    initFn();
                                    removeAddedIcon('#background_menu');
                                });
                            },
                        });
                    }
                }

                function deleteTemplateConfirmationFn() {
                    $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, {
                        message: 'template.delete.confirm.badge',
                        okCallBack: () => {
                            deleteTemplateFn();
                            $scope.deleteImages().then(function (r) {
                                initFn();
                            });
                        },
                    });
                }

                $scope.deleteImages = function () {
                    var deferred = $q.defer();
                    if (vm.slide.badgeUrl && vm.slide.badgeBackgroundUrl) {
                        removeLogoAndBackgroundImg();
                    } else {
                        if (vm.slide.badgeBackgroundUrl) {
                            removeBackgroundImg();
                        } else {
                            if (vm.slide.badgeUrl) {
                                removeLogo();
                            } else {
                                vm.removeImageFromCanvas();
                            }
                            jQuery('#dynamicImage').hide('fast');
                        }
                    }
                    deferred.resolve();
                    return deferred.promise;
                };

                function removeBackgroundImg() {
                    Presentation.deleteImage({ currentImg: vm.slide.badgeBackgroundUrl }, function (response) {
                        jQuery('#backgroundImage').attr(
                            'src',
                            'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABA‌​AACAUwAOw=='
                        );
                        initFn();
                        removeAddedIcon('#background_menu');
                    });
                }

                function removeLogo() {
                    Presentation.deleteImage({ currentImg: vm.slide.badgeUrl }, function (response) {
                        jQuery('#myImage').attr(
                            'src',
                            'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABA‌​AACAUwAOw=='
                        );
                        initFn();
                        removeAddedIcon('#myPhoto_menu');
                    });
                }

                function removeLogoAndBackgroundImg() {
                    Presentation.deleteImage(
                        { currentImg: [vm.slide.badgeUrl, vm.slide.badgeBackgroundUrl] },
                        function (response) {
                            jQuery('#backgroundImage').attr(
                                'src',
                                'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABA‌​AACAUwAOw=='
                            );
                            jQuery('#myImage').attr(
                                'src',
                                'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABA‌​AACAUwAOw=='
                            );
                            initFn();
                            removeAddedIcon('#background_menu');
                            removeAddedIcon('#myPhoto_menu');
                        }
                    );
                    jQuery('#dynamicImage').hide('fast');
                }

                function makeDragAndDropAble(bindClickFunctionality) {
                    jQuery('#dragDropContainerArea')
                        .children('div')
                        .each(function () {
                            vm.valueTop += vm.topmover;
                            let _divId = this.id;
                            if (vm.isNewTemplate) {
                                let image = jQuery('#myImage');
                                let imageDefaultLeftPosition = getImageDefaultLeftPosition(vm.width, image.width());
                                jQuery('#myPhoto')
                                    .css('top', '0px')
                                    .css('left', imageDefaultLeftPosition + 'px')
                                    .css('height', image.height() + 'px')
                                    .css('width', image.width() + 'px');
                            }
                            if (_divId.indexOf('basicDesign') > -1 && bindClickFunctionality) {
                                jQuery(this).on('click', function (ev) {
                                    if (_divId !== 'backgroundPhoto') {
                                        ev.stopPropagation();
                                    } else {
                                        vm.showBackgroundImageEditOptions();
                                    }
                                    if (_divId !== 'myPhoto' && _divId !== 'backgroundPhoto') {
                                        showBasicFieldsEditBox(this);
                                    }
                                    vm.editObject = this;
                                });
                            }
                            if (_divId.indexOf('customDesign') > -1 && bindClickFunctionality) {
                                jQuery(this).on('click', function (ev) {
                                    if (_divId !== 'backgroundPhoto') {
                                        ev.stopPropagation();
                                    } else {
                                        vm.showBackgroundImageEditOptions();
                                    }
                                    if (_divId !== 'myPhoto' && _divId !== 'backgroundPhoto') {
                                        showBasicFieldsEditBox(this);
                                    }
                                    vm.editObject = this;
                                });
                            }
                            if (_divId.indexOf('customField') > -1 && bindClickFunctionality) {
                                jQuery(this).on('click', function (ev) {
                                    if (_divId !== 'backgroundPhoto') {
                                        ev.stopPropagation();
                                    } else {
                                        vm.showBackgroundImageEditOptions();
                                    }
                                    if (_divId !== 'myPhoto' && _divId !== 'backgroundPhoto') {
                                        showCustomFieldsEditBox();
                                    }
                                    vm.editObject = this;
                                });
                            }
                            makeLogoDraggable();
                            makeTextDraggable();
                            if (vm.slide && vm.slide.badgeUrl) {
                                showAddedIcon('#myPhoto_menu');
                            }
                        });
                }

                function addOrShowBadgeLogoFn() {
                    if (!vm.logo) {
                        vm.opendesignBadgeLogoModal();
                    }
                }

                function addOrShowBackgroundImageFn() {
                    if (!vm.backgroundImage) {
                        vm.openDesignBadgeBackgroundModal();
                    }
                }

                function showBackgroundImageEditOptionsFn() {
                    jQuery('#customFieldEditBox').hide('fast');
                    jQuery('#dynamicEditBox').hide('fast');
                    jQuery('#dynamicImage').hide('fast');
                    jQuery('#dynamicBackgroundImage').show('fast');
                }

                function showAddedIcon(itemId) {
                    jQuery(itemId + ' > i')
                        .removeClass('fa-plus-square-o')
                        .addClass('fa-check fa-check-green pull-right');
                    jQuery(itemId).css('cursor', 'default');
                }

                function removeItemFromAddedListById(itemId) {
                    let queryTypeWithId = itemId.split('_');
                    let queryType = queryTypeWithId[0];
                    let queryId = queryTypeWithId[1];
                    if (queryType === 'basicDesign') {
                        let indexOfBasicQueryToRemove = _.findIndex(vm.addedBasicFields, { id: queryId });
                        if (indexOfBasicQueryToRemove) {
                            vm.addedBasicFields.splice(indexOfBasicQueryToRemove, 1);
                        }
                        if (vm.addedFields) {
                            let indexOfQueryToRemove = _.findIndex(vm.addedFields.basicQueries, { id: queryId });
                            vm.addedFields.basicQueries.splice(indexOfQueryToRemove, 1);
                        }
                    } else if (queryType === 'customDesign') {
                        let indexOfCustomQueryToRemove = _.findIndex(vm.addedCustomFields, { id: queryId });
                        if (indexOfCustomQueryToRemove) {
                            vm.addedCustomFields.splice(indexOfCustomQueryToRemove, 1);
                        }
                        /*if (vm.addedFields) {
                            let indexOfQueryToRemove = _.findIndex(vm.addedFields.customQueries, {id: queryId});
                            vm.addedFields.customQueries.splice(indexOfQueryToRemove, 1);
                        }*/
                    }
                }

                function removeAddedIcon(itemId) {
                    jQuery(itemId + ' > i')
                        .removeClass('fa-check fa-check-green pull-right')
                        .addClass('fa-plus-square-o');
                    jQuery(itemId).css('cursor', 'pointer');
                }

                function getImageDefaultLeftPosition(canvasWidth, imageWidth) {
                    return canvasWidth - imageWidth;
                }

                function getNormalizedWidth(designWidth) {
                    let widthRatio = vm.widthInMM / vm.width;
                    return Math.round(designWidth * widthRatio);
                }

                function getNormalizedHeight(designHeight) {
                    let heightRatio = vm.heightInMM / vm.height;
                    return Math.round(designHeight * heightRatio);
                }

                function fixImageSize() {
                    var img = document.getElementById('myImage');
                    img.onload = function () {
                        jQuery('#myPhoto')
                            .css('top', '0px')
                            .css('height', DEFAULT_LOGO_HEIGHT_IN_PX + 'px')
                            .css('width', DEFAULT_LOGO_WIDTH_IN_PX + 'px')
                            .css('left', getImageDefaultLeftPosition(vm.width, DEFAULT_LOGO_WIDTH_IN_PX));
                        jQuery('#myPhoto .ui-wrapper')
                            .width(DEFAULT_LOGO_WIDTH_IN_PX + 'px')
                            .height(DEFAULT_LOGO_HEIGHT_IN_PX + 'px');
                        jQuery('#myImage')
                            .css('top', '0px')
                            .css('height', DEFAULT_LOGO_HEIGHT_IN_PX + 'px')
                            .css('width', DEFAULT_LOGO_WIDTH_IN_PX + 'px')
                            .css('left', getImageDefaultLeftPosition(vm.width, DEFAULT_LOGO_WIDTH_IN_PX));
                    };
                }

                function setDefaultImageSize() {
                    if (vm.isNewTemplate) {
                        fixImageSize();
                    }
                }

                function isFieldAddedFn(fieldId) {
                    let indexOfField = _.findIndex(vm.addedFields.basicQueries, (a) => {
                        return a.id == fieldId;
                    });
                    return indexOfField != -1;
                }
            }
        ]
    });
