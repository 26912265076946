'use strict';

angular.
    module('core.customTranslationLoaderService').
    factory('CustomTranslationLoaderService', ['$http', '$translate', '$q',
        function ($http, $translate, $q) {
            console.log("inside transtion loader service");
            var languages = [];

            var convert = function (messages) {
                var newMessages = {};
                angular.forEach(messages, function (value, key) {
                    if (value) {
                        newMessages[key] = value.replace(/\{(\d+)\}/g, '{{p$1}}');
                    } else {
                        newMessages[key] = '';
                    }
                })
                return newMessages;
            }

            return {
                setLanguage: function (langKey) {
                    langKey = langKey ? langKey : localStorage.getItem('language') || 'sv_SV';
                    localStorage.setItem('language', langKey);
                    var langShort = langKey && langKey.substring(0, langKey.indexOf('_'));
                    langShort = (langShort === 'nb') ? 'no' : langShort;
                    var deferred = $q.defer();
                    console.log("here");
                    $http.get('api/v1/commonTranslation/getTranslation?prefLanguage=' + langShort)
                        .then((res) => {
                            let data = res.data;
                            languages = convert(data.languages[langShort]);
                            $translate.use(langKey);
                            deferred.resolve();
                        }, function (message) {
                            deferred.resolve(message);
                            console.log(message, ':::: ERROR ON GETTING TRANSLATIONS ::::');
                        })
                    return deferred.promise;
                },

                getLanguages: function () {
                    return languages;
                }
            }
        }
    ]);
