import _ from 'underscore';

class OgLocationDropdownController {
    constructor($timeout, $scope, $translate, CommonFile) {
        this._injected = { $timeout, $scope, $translate, CommonFile };

        if (!this.minLength) {
            this.minLength = 0;
        }
        this.uniqueId = Math.random();

        $scope.$watch('ogLocationDropdownCtrl.open', (newval) => {
            if (newval) {
                $timeout(() => {
                    let identifier = this.uniqueId + '_value';
                    var searchInput = document.getElementById(identifier);
                    searchInput.focus();
                });
            }
        });

        this.select = this.select.bind(this);
        this.getInitialValue = this.getInitialValue.bind(this);
        this.remoteUrlRequest = this.remoteUrlRequest.bind(this);
        this.translateGeoTags = this.translateGeoTags.bind(this);
        this.geoTagRemoteUrl = CommonFile.getBaseUrl() + 'api/v1/geotag/listGeoTags';
    }

    getInitialValue() {
        if (!this.selected) {
            if (this.otherSelectedValue) {
                return this.otherSelectedValue;
            }
            return;
        }
        if (angular.isString(this.selected)) {
            return this.selected;
        }

        return _.findWhere(this.list, { id: this.selected });
    }

    select(selected) {
        if (selected) {
            this.selected = selected.originalObject.id;
            this.updateSelectedGeoTagIdCallback({
                geoTagId: this.selected,
                geoTag: null,
                geoTagText: selected.originalObject.name,
            });
        } else {
            this.selected = null;
        }
    }

    //https://github.com/ghiden/angucomplete-alt
    //access scope variables and functions as this.$parent.ogLocationDropdownCtrl
    getGeoTagFromSearchString($item) {
        let _geoTagId = UtilFunctions.getListItemIdFromSearchText(this.$parent.ogLocationDropdownCtrl.list, $item);
        if (_geoTagId) {
            this.selected = _geoTagId;
            this.$parent.ogLocationDropdownCtrl.updateSelectedGeoTagIdCallback({
                geoTagId: this.selected,
                geoTag: null,
                geoTagText: $item,
            });
        } else {
            this.$parent.ogLocationDropdownCtrl.updateSelectedGeoTagIdCallback({ geoTagId: null, geoTagText: $item });
        }
    }

    remoteUrlRequest(str) {
        return { name: str };
    }

    translateGeoTags(resp) {
        const { $translate } = this._injected;
        this.geoTags = resp;
        angular.forEach(this.geoTags, (geotag) => {
            if (geotag.nameCode) {
                geotag.translatedName = $translate.instant(geotag.nameCode);
            } else {
                geotag.translatedName = geotag.name;
            }
        });
        return this.geoTags;
    }
}
OgLocationDropdownController.$inject = ['$timeout', '$scope', '$translate', 'CommonFile'];
export default OgLocationDropdownController;
