import amountAndPriceService from '../../../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import { ScopeFunctions } from "../../../../../../utils/global/scopeFunctions";
import { DownloadUtilFunctions } from '../../../../../../utils/global/DownloadUtilFunction';
import _ from 'underscore';

(function () {
    'use strict';
    angular.module('client-selection').component('clientSelection', {
        templateUrl: './app/components/modules/event/event_detail/budget/client_selection/client_selection.view.html',
        controllerAs: 'clientSelectionCtrl',
        controller: [
            '$scope',
            '$stateParams',
            'Event',
            'ClientSelectedContent',
            'BudgetDataShareService',
            'AuthenticationService',
            'EventSummary',
            'BrowserDetectService',
            'EventScheduleDataStore',
            function ClientSelectionController(
                $scope,
                $stateParams,
                Event,
                ClientSelectedContent,
                BudgetDataShareService,
                AuthenticationService,
                EventSummary,
                BrowserDetectService,
                EventScheduleDataStore
            ) {
                ScopeFunctions.addToScope($scope);
                let vm = this;
                var eventId = $stateParams.eventId;
                vm.showClientSelectedContent = false;
                vm.decisionMakerHistoryList = [];
                vm.init = initFn;
                vm.getTotalPriceForBudgetByCurrency = getTotalPriceForBudgetByCurrencyFn;
                vm.exportEventSummaryToPDF = exportEventSummaryToPDFFn;
                vm.exportDecisionMakerHistoryToPDF = exportDecisionMakerHistoryToPDFFn;
                vm.getDiscountForBudget = getDiscountForBudgetFn;
                vm.getAmountLocalisedWithCurrency = amountAndPriceService.getAmountLocalisedWithCurrency;
                vm.enableAgent = AuthenticationService.getUserData().userGroup.showMarkup;

                vm.init();

                function initFn() {
                    $scope.startProgress('budget-table');
                    Event.getEventMarkupDetails(
                        eventId,
                        function (response) {
                            vm.event = response;
                            vm.requests = vm.event.requests;
                            ClientSelectedContent.getAllSelectedContentForEvent(
                                eventId,
                                (response) => {
                                    if (
                                        response &&
                                        response.currentOffer &&
                                        response.currentOffer.requests &&
                                        response.currentOffer.requests.length > 0
                                    ) {
                                        vm.showClientSelectedContent = true;
                                        vm.event.requests = response.currentOffer.requests;
                                        vm.event.participantAmount = response.currentOffer.event.participantAmount;
                                        vm.event.fromDate = response.currentOffer.event.fromDate;
                                        vm.event.toDate = response.currentOffer.event.toDate;
                                        vm.event.ownerGroup = response.currentOffer.event.ownerGroup;
                                        vm.supplierDiscountEnabled = vm.event && vm.event.ownerGroup && (vm.event.ownerGroup.supplierDiscount.name === 'TRUE' || vm.event.ownerGroup.supplierDiscount === 'TRUE');
                                        vm.requests = response.currentOffer.requests;
                                        angular.forEach(vm.requests, (request) => {
                                            let _request = _.find(vm.requests, (r) => {
                                                return r.id === request.id;
                                            });
                                            if (_request) {
                                                angular.forEach(request.requestOffers, (requestOffer) => {
                                                    let _offer = _.find(_request.requestOffers, (ro) => {
                                                        return ro.id === requestOffer.id;
                                                    });
                                                    if (_offer) {
                                                        let _offerParts = requestOffer.offerParts;
                                                        angular.forEach(_offerParts, (rop) => {
                                                            let _offerPart = _.find(_request.offerParts, (op) => {
                                                                return op.referenceId === rop.referenceId;
                                                            });
                                                            if (_offerPart && _offerPart.hasRequestOfferPartOptions) {
                                                                let _offer = _.find(_offerPart.offerWiseData, (owd) => {
                                                                    return owd.offer.id === requestOffer.id;
                                                                });
                                                                if (_offer) {
                                                                    angular.forEach(rop.offerPartOptions, (opo) => {
                                                                        let _offerPartOption = _.find(
                                                                            _offer.offerPartOptions,
                                                                            (option) => {
                                                                                return opo.id === option.id;
                                                                            }
                                                                        );
                                                                        if (_offerPartOption) {
                                                                            opo.isSelected = _offerPartOption.isSelected;
                                                                        }
                                                                    });
                                                                }
                                                            }
                                                        });
                                                    }
                                                });
                                            }
                                        });
                                        if (vm.enableAgent) {
                                            ClientSelectedContent.getDecisionMakerHistory(
                                                eventId,
                                                (response) => {
                                                    vm.decisionMakerHistoryList = response;
                                                },
                                                $scope.endWithError()
                                            );
                                        }
                                    }
                                    $scope.endProgress('budget-table');
                                },
                                $scope.endWithError()
                            );
                        },
                        $scope.endWithError()
                    );
                }

                function getTotalPriceForBudgetByCurrencyFn() {
                    return BudgetDataShareService.getTotalPriceForBudgetByCurrency();
                }

                function getDiscountForBudgetFn() {
                    let _totalPriceForBudget = BudgetDataShareService.getTotalPriceForBudgetByCurrency()[0];
                    let _discount = 0;
                    if (_totalPriceForBudget) {
                        _discount = _totalPriceForBudget.discount;
                    }
                    return _discount;
                }

                function exportDecisionMakerHistoryToPDFFn(decisionMakerHash) {
                    let _downloadUrl =
                        '/api/v1/clientSelectedContent/' + eventId + '/' + decisionMakerHash + '/exportDecisionMakerHistory';
                    DownloadUtilFunctions.downloadFile(_downloadUrl);
                }

                function exportEventSummaryToPDFFn() {
                    BrowserDetectService.setPrintSummary(true);
                    setTimeout(function () {
                        print();
                    }, 100);
                }

                function print() {
                    let isItIE = BrowserDetectService.isItIE();
                    if (!!isItIE) {
                        jQuery('#shared-event-budget').printElement({
                            leaveOpen: false,
                            printMode: 'popup',
                            overrideElementCSS: ['/app/print-css/schedule-print.css'],
                        });
                    } else {
                        jQuery('#shared-event-budget').printThis({
                            importCSS: false,
                            importStyle: false,
                            loadCSS: '/app/print-css/schedule-print.css',
                            printDelay: '3000',
                        });
                    }
                }
            }
        ]
    });
})();
