'use strict';

const ONE_DAY_MILLISECONDS = 86400000;

angular.module('core.existingServices').factory('DateService', [
    '$rootScope',
    'TimezoneService',
    function ($rootScope, TimezoneService) {
        return {
            extractLocalizedDateFromTimeStamp: function (timeStamp) {
                var date = new Date(timeStamp);
                var day = date.getDate();
                var monthIndex = date.getMonth();
                var year = date.getFullYear();
                return (
                    day +
                    ' ' +
                    ($rootScope.langSelected == 'sv_SV'
                        ? this.getSwedishShortMonthName(monthIndex)
                        : this.getEnglishShortMonthName(monthIndex)) +
                    ', ' +
                    year
                );
            },

            getEnglishShortMonthName: function (monthIndex) {
                var monthNames = [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                ];

                return monthNames[monthIndex];
            },

            getSwedishShortMonthName: function (monthIndex) {
                var monthNames = [
                    'jan',
                    'feb',
                    'mars',
                    'april',
                    'maj',
                    'juni',
                    'juli',
                    'aug',
                    'sept',
                    'okt',
                    'nov',
                    'dec',
                ];

                return monthNames[monthIndex];
            },

            getDateSpan: function (fromDate, toDate, year) {
                var output = '';
                var from = moment(fromDate);
                var to = moment(toDate);

                if (from.format('YYYYMMDD') === to.format('YYYYMMDD')) {
                    output = to.format('D MMM');
                } else if (from.month() === to.month() && from.year() === to.year()) {
                    output += from.date() + ' - ' + to.format('D MMM');
                } else if (from.year() === to.year()) {
                    output += from.format('D MMM') + ' - ' + to.format('D MMM');
                } else if (from.year() !== to.year()) {
                    output += from.format('D MMM YYYY') + ' - ' + to.format('D MMM YYYY');
                }
                if (output.slice(output.length - 1) === '.') {
                    //Remove last . if present
                    output = output.slice(0, -1);
                }

                if (year && from.year() === to.year()) {
                    output += ', ' + to.format('YYYY');
                }

                return output;
            },

            getEventProgressDateSpan: function (fromDate) {
                var date = '';
                if (fromDate) {
                    var from = moment(fromDate);
                    date = from.format('D. MMM');
                }
                return date;
            },

            getDateFormatByLocale: function () {
                let lang = $rootScope.langSelected;
                if (lang === 'sv_SV' || lang === 'nb_NB' || lang === 'da_DK' || lang === 'fi_FI') {
                    return 'YYYY-MM-DD';
                } else {
                    return 'DD/MM/YYYY';
                }
            },

            getResourceCalendarDateFormat: function () {
                return 'YYYY-MM-DD';
            },

            parseDateInLocaleFormat: function (timestamp) {
                let dateFormat = this.getDateFormatByLocale();
                return TimezoneService.formatDate(new Date(parseInt(timestamp)), dateFormat);
            },
            getDateTimeFormatByLocale: function () {
                let lang = $rootScope.langSelected;
                if (lang === 'sv_SV' || lang === 'nb_NB' || lang === 'da_DK' || lang === 'fi_FI') {
                    return 'YYYY-MM-DD HH:mm';
                } else {
                    return 'DD/MM/YYYY hh:mm a';
                }
            },
            parseDateTimeInLocaleFormat: function (timestamp) {
                let dateFormat = this.getDateTimeFormatByLocale();
                return TimezoneService.formatDate(new Date(parseInt(timestamp)), dateFormat);
            },
            isTimeElapsedGreaterThanADay: function (timestamp) {
                let currentTimestamp = new Date().getTime();
                let test = currentTimestamp - timestamp;
                return test > ONE_DAY_MILLISECONDS;
            },
        };
    },
]);

