import controller from './og.dynamic.form.controller';

(function () {
    'use strict';

    angular.module('ogDynamicForm').directive('ogDynamicForm', [
        function () {
            return {
                scope: {
                    list: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                    onChangeCallback: '=',
                    isChanged: '&',
                    hasAnsweredAdditionalQuestion: '=',
                    args: '=',
                    participantAmount: '=',
                    requestNameCode: '=',
                },
                templateUrl: './app/components/directives/dynamic-form/og.dynamic.form.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogDynamicFormCtrl',
                bindToController: true,
            };
        },
    ]);
})();
