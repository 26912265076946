(function () {
    'use strict';

    angular.module('ogMultiSelect').directive('ogMultiSelect', [
        '$document',
        'ParticipantService',
        function ($document, ParticipantService) {
            return {
                restrict: 'EA',
                templateUrl: './app/components/directives/multi_select/og_multi_select.view.html',
                controllerAs: 'multiSelectController',
                bindToController: true,
                scope: {
                    options: '=',
                    onItemSelect: '=?',
                    onItemDeselect: '=?',
                },
                controller: [
                    '$scope',
                    '$timeout',
                    '$rootScope',
                    function ($scope, $timeout, $rootScope) {
                        var vm = this;
                        vm.toggleItem = toggleItemFn;
                        vm.getQuestionLabel = getQuestionLabelFn;
                        vm.lang = $rootScope.langSelected;
                        function toggleItemFn(item) {
                            if (item.isChecked && typeof vm.onItemDeselect === 'function') {
                                $timeout(function () {
                                    vm.onItemDeselect(item);
                                }, 0);
                            } else if (!item.isChecked && typeof vm.onItemSelect === 'function') {
                                $timeout(function () {
                                    vm.onItemSelect(item);
                                }, 0);
                            }
                        }

                        function getQuestionLabelFn(item) {
                            return ParticipantService.getQuestionLabel(item, vm.lang);
                        }

                        $scope.$on('lang:change', function (evt, data) {
                            vm.lang = data.lang;
                        });
                    },
                ],
                link: function (scope, element) {
                    scope.isdropdownVisible = false;
                    scope.toggleSelect = function () {
                        scope.isdropdownVisible = false;
                        scope.isdropdownVisible = !scope.isdropdownVisible;
                    };
                    scope.toggleUnSelect = function () {
                        scope.isdropdownVisible = !scope.isdropdownVisible;
                    };

                    $document.bind('click', function (event) {
                        var isClickedElementChildOfPopup = element.find(event.target).length > 0;
                        if (isClickedElementChildOfPopup) {
                            return;
                        }
                        scope.isdropdownVisible = false;
                        scope.$apply();
                    });
                },
            };
        },
    ]);
})();
