'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogParticipantRegistrationFormPreviewModal', [
    function () {
        return new OgModal({
            name: 'participant:registration:form:preview',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                $scope.preview = false;
                $scope.participantData = {};

                $scope.initDialog = function (data) {
                    $scope.startProgress();
                        $scope.preview = data.preview;
                        $scope.participantData.useSMS = data.useSMS;
                        $scope.participantData.participantBasicQueryInfoList = data.participantBasicQueryInfoList;
                        $scope.participantData.participantAdditionalQueryInfoList =
                            data.participantAdditionalQueryInfoList;
                        $scope.endProgress();
                    };

                    $scope.clearDialog = function () {
                        $scope.preview = false;
                        $scope.participantData = {};
                    };
                },
            });
        },
    ]);

