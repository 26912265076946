/**
 * Created by kshitij on 9/13/17.
 */

class CurrencyUtil {
    constructor() {
    }

    static addSymbol(amount, currency) {
        if (currency.prefixed) {
            return currency.symbol + ' ' + amount;
        }
        return amount + ' ' + currency.symbol;
    }

    //https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript#answer-2901298
    getAmountLocalisedWithCurrency(amount, currency, digitsAfterDecimal = 0) {
        let integerAndDecimalParts = Number.isInteger(+amount) !== true ? amount && amount.toFixed(digitsAfterDecimal) : amount;
        if (integerAndDecimalParts == null) {
            return null;
        }
        integerAndDecimalParts = integerAndDecimalParts.toString().split('.');
        integerAndDecimalParts[0] = integerAndDecimalParts[0].replace(
            /\B(?=(\d{3})+(?!\d))/g,
            currency.thousandsSeparator
        );
        let decimalSeparatedAmount = integerAndDecimalParts.join(currency.decimalSeparator);
        return CurrencyUtil.addSymbol(decimalSeparatedAmount, currency);
    }

    //If need to display 0 value as well e.g:- 0 Kr
    viewPrice(amount, currency, digitsAfterDecimal = 0) {
        amount = amount ? amount : 0;
        let integerAndDecimalParts = digitsAfterDecimal ? amount && amount.toFixed(digitsAfterDecimal) : amount;
        integerAndDecimalParts = integerAndDecimalParts.toString().split('.');
        integerAndDecimalParts[0] = integerAndDecimalParts[0].replace(
            /\B(?=(\d{3})+(?!\d))/g,
            currency.thousandsSeparator
        );
        let decimalSeparatedAmount = integerAndDecimalParts.join(currency.decimalSeparator);
        return CurrencyUtil.addSymbol(decimalSeparatedAmount, currency);
    }

    //Swedish Krona
    getDefaultCurrency() {
        return {
            decimalSeparator: '.',
            iso: 'SEK',
            name: 'Swedish krona',
            prefixed: false,
            symbol: 'kr',
            thousandsSeparator: ' ',
        };
    }
}

const currency = new CurrencyUtil();
export default currency;
