import {ScopeFunctions} from "../../../utils/global/scopeFunctions";
import _ from 'underscore';

const CONFERENCE_CATEGORIES = [
    'common.translate.category.hotel.&.conference',
    'common.translate.category.conference.room',
];
class OgPresentationProfileSettingsController {
    constructor(
        $rootScope,
        $scope,
        $element,
        $location,
        $timeout,
        $translate,
        FieldChangedSvc,
        EventEnum,
        AuthenticationService,
        SupplierDetailInfo,
        Event,
        CustomerUser,
        UserGroup,
        Presentation,
        CommonPresentation,
        SupplierResources,
        SupplierSlot,
        CommonFile
    ) {
        this._injected = {
            $rootScope,
            $scope,
            $element,
            $location,
            $timeout,
            $translate,
            FieldChangedSvc,
            EventEnum,
            AuthenticationService,
            SupplierDetailInfo,
            Event,
            CustomerUser,
            UserGroup,
            Presentation,
            CommonPresentation,
            SupplierResources,
            SupplierSlot,
            CommonFile,
        };
        ScopeFunctions.addToScope($scope);
        this.editDetails = false;
        this.setImageAndDescription = false;
        this.roleGranted = false;
        this.showResourceEditModule = false;
        this.showDefaultResourceEditModule = false;
        this.remoteUrl = CommonFile.getBaseUrl() + 'api/v1/offerPartType';
        this.isThereConference = false;
        this.isThereConferenceActivity = false;
        this.displaySupplierDetails = 'Hotel_details';

        //todo(SC) calculate this value as per the resource

        this.$onInit = function () {
            this.data = { supplierId: this.group.supplier.id };
            this.getSupplierDetailInfo();
            this.getSupplierPresentation();
            this.getSupplierSlots();
        }
    }

    getSupplierResourceFormClass(resourceData) {
        switch (resourceData.offerPartTypeNameCode) {
            case 'common.translate.offer.part.type.conference.room':
                return 'conference-room-form';
                break;
            case 'common.translate.offer.part.type.conference.activities':
                return 'conference-activity-form';
                break;
        }
    }

    getSupplierDetailInfo() {
        const { SupplierDetailInfo, $scope } = this._injected;
        $scope.startProgress();
        SupplierDetailInfo.get(
            this.group.supplier.id,
            (response) => {
                this.supplierDetailGroups = response.supplierDetailGroups;
                this.selectedCategory = response.selectedCategory;
                if (this.selectedCategory) {
                    this.isThereConference = CONFERENCE_CATEGORIES.indexOf(this.selectedCategory.nameCode) > -1;
                }
                this.isThereConferenceActivity = response.canSupplierAddActivityResource;
                this.supportedTabs = response.supportedTabs;
                this.sortDetailsList();
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    getSupplierPresentation() {
        const { $scope, CommonPresentation, $rootScope } = this._injected;
        $scope.startProgress();
        if (this.group.supplier) {
            CommonPresentation.get(
                { supplierId: this.group.supplier.id },
                (presentation) => {
                    this.presentation = presentation;
                    this.presentation.allResources = presentation.allResources;
                    $rootScope.$emit('get:supplier:presentation', this.presentation);
                    $scope.endProgress();
                },
                $scope.endWithError
            );
        }
    }

    getSupplierSlots() {
        const { $scope, SupplierSlot } = this._injected;
        $scope.startProgress();
        if (this.group.supplier) {
            var _partTypeCode = 'common.translate.offer.part.type.conference.room';
            SupplierSlot.listSlots(
                this.group.supplier.id,
                _partTypeCode,
                (response) => {
                    $scope.endProgress();
                    this.supplierSlots = response;
                },
                $scope.endWithError
            );
        }
    }

    getSupplierResourceImage(resource) {
        if (resource && resource.pinnedImageId) {
            return resource.pinnedImageId;
        }
        if (resource && resource.resourceImages) {
            return resource.resourceImages ? _.values(resource.resourceImages)[0] : null;
        }
        return null;
    }

    backAndRefreshResource() {
        const { SupplierResources, $scope } = this._injected;
        $scope.startProgress();
        SupplierResources.getResources(
            { supplierId: this.group.supplier.id },
            (res) => {
                this.presentation.allResources = res;
                this.showResourceEditModule = false;
                this.showDefaultResourceEditModule = false;
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }

    editPresentationDetails() {
        this.editDetails = true;
        this.setImageAndDescription = true;
    }

    updatePresentationDetails() {
        const { $scope, SupplierDetailInfo, $rootScope } = this._injected;
        this.setImageAndDescription = false;
        if (this.group.supplier) {
            let _supplierDetailsData = this.removeEmptyValuesFromDetailsList();
            if (_supplierDetailsData != undefined) {
                $scope.startProgress();
                SupplierDetailInfo.update(
                    this.group.supplier.id,
                    { supplierDetailGroups: _supplierDetailsData },
                    (response) => {
                        this.supplierDetailGroups = response.supplierDetailGroups;
                        this.editDetails = false;
                        this.sortDetailsList();
                        if (this.successCallBack) {
                            this.successCallBack();
                        }
                        $scope.endProgress();
                    },
                    $scope.endWithErrorOverlay
                );
            } else {
                this.editDetails = false;
            }
        }
    }

    translateDynamicFields(name, code) {
        const { $translate } = this._injected;
        if (code) {
            let translatedText = $translate.instant(code);
            if (translatedText !== code) {
                return translatedText;
            }
        }
        return name;
    }

    sortDetailsList() {
        angular.forEach(this.supplierDetailGroups, (group) => {
            let _finalList = [];
            let _maxOrder = _.max(group.supplierDetails, _.property('sdsdgDisplayOrder'));
            _maxOrder = _maxOrder ? _maxOrder.sdsdgDisplayOrder : group.supplierDetails.length;
            for (let i = 0; i < _maxOrder; i++) {
                let _obj = _.find(group.supplierDetails, (o) => {
                    return o.sdsdgDisplayOrder === i + 1;
                });
                if (!_obj) {
                    _obj = { sdsdgDisplayOrder: i + 1 };
                    _obj.isEmpty = true;
                }
                _finalList.push(_obj);
            }
            group.supplierDetails = _finalList;
        });
    }

    removeEmptyValuesFromDetailsList() {
        let _supplierDetailsData = angular.copy(this.supplierDetailGroups);
        angular.forEach(_supplierDetailsData, (group, $index) => {
            let _finalList = _.without(
                group.supplierDetails,
                _.findWhere(group.supplierDetails, {
                    isEmpty: true,
                })
            );
            group.supplierDetails = _finalList;
        });
        return _supplierDetailsData;
    }

    /*updateSelectedPartName(name, nameCode) {
        this.data.name = name;
        this.data.translatedName = name;
        this.data.nameCode = nameCode;
    }*/

    addNewResource(nameCode, name = null) {
        const { SupplierResources, $scope } = this._injected;
        $scope.startProgress();
        this.data.newResourceName = name;
        this.data.nameCode = nameCode;
        let data = this.data;
        SupplierResources.addResource(
            data,
            (res) => {
                this.presentation.conferenceRoomResources = this.presentation.conferenceRoomResources || [];
                this.presentation.conferenceRoomResources.push(res);
                // this.showResourceAddModule = false;
                this.showResourceEdit(res);
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }

    addNewDefaultResource(nameCode, name) {
        const { SupplierResources, $scope } = this._injected;
        $scope.startProgress();
        this.data.newResourceName = name;
        this.data.nameCode = nameCode;
        let data = this.data;
        SupplierResources.addResource(
            data,
            (res) => {
                this.presentation.allResources[nameCode] = this.presentation.allResources[nameCode] || [];
                this.presentation.allResources[nameCode].push(res);
                this.showResourceEdit(res);
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }

    showResourceEdit(resource) {
        if (resource) {
            this.resourceData = resource;
            if (resource.offerPartTypeNameCode === 'common.translate.offer.part.type.conference.room') {
                this.showResourceEditModule = true;
                this.showDefaultResourceEditModule = false;
            } else {
                this.showResourceEditModule = false;
                this.showDefaultResourceEditModule = true;
            }
        }
    }

    updateResourceStatus(resource, status) {
        const { SupplierResources, $scope } = this._injected;
        let params = {
            resourceId: resource.id,
            resourceStatus: status,
        };
        if (resource) {
            $scope.startProgress();
            SupplierResources.updateResourceStatus(
                params,
                (data) => {
                    let resIndex = this.presentation.allResources[resource.offerPartTypeNameCode].findIndex(
                        (_res) => _res.id === data.id
                    );
                    this.presentation.allResources[resource.offerPartTypeNameCode][resIndex] = data;
                    $scope.endProgress();
                },
                $scope.endWithError
            );
        }
    }

    deleteResourceConfirmation(resource) {
        resource.showRemoveResourceConfirmationMessage = true;
        this.removeResourceId = resource.id;
    }

    resetRemoveResource(resource) {
        resource.showRemoveResourceConfirmationMessage = false;
        this.removeResourceId = null;
    }

    deleteResource(resource) {
        const { SupplierResources, $scope } = this._injected;
        let data = {
            resourceId: this.removeResourceId,
            supplierId: this.group.supplier.id,
        };
        $scope.startProgress();
        SupplierResources.deleteResource(
            data,
            (resources) => {
                this.presentation.allResources[resource.offerPartTypeNameCode] =
                    resources[resource.offerPartTypeNameCode];
                this.resetRemoveResource(resource);
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }

    previewPresentationView() {
        this.viewPresentationCallback({ displaySupplierDetailsTab: this.displaySupplierDetails });
    }

    setPresenationTabActive(tabName) {
        this.displaySupplierDetails = tabName;
    }

    getAllMatchingResources(nameCode) {
        return this.presentation.allResources[nameCode];
    }

    getImageUrl(fileId) {
        const { CommonFile } = this._injected;
        return CommonFile.getCommonFile(fileId);
    }
}

OgPresentationProfileSettingsController.$inject = [
    '$rootScope',
    '$scope',
    '$element',
    '$location',
    '$timeout',
    '$translate',
    'FieldChangedSvc',
    'EventEnum',
    'AuthenticationService',
    'SupplierDetailInfo',
    'Event',
    'CustomerUser',
    'UserGroup',
    'Presentation',
    'CommonPresentation',
    'SupplierResources',
    'SupplierSlot',
    'CommonFile',
];
export default OgPresentationProfileSettingsController;
