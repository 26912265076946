import { UtilFunctions } from "../../../utils/global/utilFunctions";
const MAXIMUM_CATEGORY_NAME_LENGTH = 50;

class OgCategoryDropdownController {
    constructor($scope, $document, $element, $sce, $rootScope, Event, CommonFile, CommonFunctions) {
        this._injected = { $scope, $document, $element, $sce, $rootScope, Event, CommonFile, CommonFunctions };
        this.listVisible = false;

        let documentClick = function (event) {
            if ($element.find(event.target).length > 0) {
                return;
            }
            this.listVisible = false;
        }.bind(this);

        $document.on('click', documentClick);

        //unbind the click listener
        $scope.$on('$destroy', function () {
            $document.off('click', documentClick);
        });
    }

    onChange(selected) {
        this.selected = selected;
        this.listVisible = false;
    }

    iconUrl(category) {
        const { CommonFile } = this._injected;
        if (category.isTemplate) {
            //TODO(SC) PULL Template's category Icon
            return 'assets/event_guide_category_default_icon.svg';
        } else if (category.icon) {
            return CommonFile.getBaseUrl() + 'api/v1/file/getCommonFile/' + category.icon.id;
        } else {
            return 'assets/event_guide_category_default_icon.svg';
        }
    }

    removeTemplateConfrimation(category) {
        this.showRemoveTemplateConfirmation = true;
        this.categroy = category;
    }

    toggle() {
        this.listVisible = !this.listVisible;
    }

    getWidthLength(totalListCount) {
        let width = '100%';
        if (totalListCount > 2) {
            width = 118 * totalListCount + 14 + 'px';
        }
        if (totalListCount >= 4) {
            width = 486;
            if (totalListCount > 8) {
                width = width + 17;
            }
            width = width + 'px';
        }
        return width;
    }

    getTranslatedCategoryName(category) {
        return this._trimCategoryNameBeautify(this._getTranslatedCategoryName(category));
    }

    _getTranslatedCategoryName(category) {
        const { CommonFunctions } = this._injected;
        return CommonFunctions.getTranslatedTextFromCode(category.name, category.nameCode);
    }

    _trimCategoryNameBeautify(categoryName) {
        return UtilFunctions.trimStringBeautify(categoryName, MAXIMUM_CATEGORY_NAME_LENGTH);
    }
}

OgCategoryDropdownController.$inject = ['$scope', '$document', '$element', '$sce', '$rootScope', 'Event', 'CommonFile', 'CommonFunctions'];
export default OgCategoryDropdownController;
