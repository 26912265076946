(function () {
    'use strict';
    angular.module('core.userGroupSetting').factory('UserGroupSettings', [
        '$resource',
        function ($resource) {
            var v1SelectedRest = $resource(
                'api/v1/userGroupSettings/get',
                {},
                {
                    get: {method: 'POST', isArray: false},
                }
            );

            return {
                get: function (params, successCallback, errorCallback) {
                    return v1SelectedRest.get(params, successCallback, errorCallback);
                },
            };
        },
    ]);
})();
