import {ScopeFunctions} from "../../../../../../utils/global/scopeFunctions";
(function () {
    'use strict';
    angular.module('creatorRatingDetail').component('creatorRatingDetail', {
        templateUrl: './app/components/modules/event/event_detail/rating/creator/creator.view.html',
        controllerAs: 'creatorRatingCtrl',
        controller: ['$scope', '$stateParams', '$timeout', 'Survey', 'ogScrollService',
            function CreatorRatingDetailController($scope, $stateParams, $timeout, Survey, ogScrollService) {
                var vm = this;
                vm.editLoggedUserSurveys = editLoggedUserSurveysFn;
                vm.hideDetailView = hideDetailViewFn;
                vm.init = initFn;
                vm.showAllRatings = showAllRatingsFn;
                vm.showCreatorRatings = showCreatorRatingsFn;
                vm.submitSurvey = submitSurveyFn;

                vm.surveysForDetail = [];

                var eventId = $stateParams.eventId;

                var detailViews = [
                    { name: 'CREATOR_EDIT_SURVEY', show: false },
                    { name: 'CREATOR_EVENT_SURVEYS', show: false },
                    { name: 'CREATOR_OFFER_SURVEYS', show: false },
                ];

                vm.currentDetailView = detailViews[0];

                ScopeFunctions.addToScope($scope);
                vm.init();

                function initFn() {
                    $scope.startProgress();
                    Survey.surveysForCreators(
                        { eventId: eventId },
                        function (response) {
                            vm.eventSurveys = response.eventSurveys;
                            vm.requestOfferSurveys = response.requestOfferSurveys;
                            vm.loggedUserSurveys = response.loggedUserSurveys;
                            vm.currentDetailView = detailViews[0];
                            if (vm.loggedUserSurveys && !vm.loggedUserSurveys.rated) {
                                editLoggedUserSurveysFn();
                            }
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function editLoggedUserSurveysFn() {
                    showDetails(detailViews[0], vm.loggedUserSurveys);
                }

                function hideDetailViewFn() {
                    vm.currentDetailView.show = false;
                }

                function scrollTo(id) {
                    $timeout(function () {
                        ogScrollService.scrollToElement(id);
                    }, 0);
                }

                function showAllRatingsFn() {
                    showDetails(detailViews[1], vm.eventSurveys.surveys);
                }

                function showCreatorRatingsFn(surveys) {
                    showDetails(detailViews[2], surveys);
                }

                function showDetails(view, surveys) {
                    hideDetailViewFn();
                    vm.currentDetailView = view;
                    vm.currentDetailView.show = true;
                    vm.surveysForDetail = surveys;
                    scrollTo('surveyDetails');
                }

                function submitSurveyFn() {
                    Survey.updateAllSurveysForAUser(vm.loggedUserSurveys, function (response) {
                        hideDetailViewFn();
                        vm.loggedUserSurveys = response;
                        initFn();
                        scrollTo('creatorSurveyHeader');
                    });
                }
            }]
    });
})();
