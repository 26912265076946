(function () {
    'use strict';
    /**
     * created by schhantyal
     * to replace the ng-transclude by og-transclude
     * ng-transclude sometimes loss the scope of directive in transcluded html , it overcomes this issue
     */
    angular.module('ogTransclude').directive('ogTransclude', function () {
        return {
            compile: function (tElement, tAttrs, transclude) {
                return function (scope, iElement) {
                    transclude(scope.$new(), function (clone) {
                        iElement.append(clone);
                    });
                };
            },
        };
    });
})();
