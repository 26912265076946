import customMessageModal from '../../../../components/modals/modals2.0/custom-message-modal';
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';
import {OgModal} from "../../../../utils/global/ogModal";

angular.module('shared.public.registration').component('sharedPublicRegistration', {
    templateUrl: './app/components/modules/shared/public_registration/public.registration.view.html',
    controller: [
        '$q',
        '$scope',
        '$stateParams',
        '$state',
        '$location',
        'Participant',
        'EventPresentationRo',
        'EventPresentation',
        '$translate',
        'Event',
        'ModalService',
        'FileService',
        'StripeElements',
        '$rootScope',
        'GlobalOption',
        'ClientService',
        'CommonFile',
        'CommonFunctions',
        function PublicRegistrationController(
            $q,
            $scope,
            $stateParams,
            $state,
            $location,
            Participant,
            EventPresentationRo,
            EventPresentation,
            $translate,
            Event,
            ModalService,
            FileService,
            StripeElements,
            $rootScope,
            GlobalOption,
            ClientService,
            CommonFile,
            CommonFunctions
        ) {
            var vm = this;
            vm.init = initFn;
            vm.formSubmitted = false;
            vm.acceptParticipation = acceptParticipationFn;
            vm.downloadFile = downloadFileFn;
            vm.downloadAll = downloadAllFn;
            vm.showPrivacyTerms = showPrivacyTermsFn;
            vm.reOpenInvitaion = reOpenInvitaionFn;
            vm.updateSelectedTicket = updateSelectedTicketFn;
            vm.freeTicket = freeTicketFn;
            vm.isTicketDetailsFilledAndAccepted = isTicketDetailsFilledAndAcceptedFn;
            vm.langShort = langShortFn;
            vm.editParticipantRegistration = editParticipantRegistrationFn;
            vm.data = {};
            vm.form = 'confirmationForm';
            vm.participantConfirmed = false;
            vm.showForm = false;
            vm.buyTicketFromStripe = true;
            vm.card = {};
            vm.uploadedFiles = [];
            vm.fortNoxDetails = {};
            ScopeFunctions.addToScope($scope);

            vm.init();
            vm.langShort($rootScope.langSelected);
            var elements = StripeElements.elements({locale: vm.stripeLocal});
            vm.element = elements.create('card', {
                style: {
                    base: {
                        color: '#32325D',
                        fontWeight: 500,
                        fontFamily: 'Inter UI, Open Sans, Segoe UI, sans-serif',
                        fontSize: '16px',
                        fontSmoothing: 'antialiased',
                    },
                },
            });

            function acceptParticipationFn() {
                if (!vm.confirmationForm.$valid) {
                    CommonFunctions.touchFormFields(vm.confirmationForm);
                    return;
                }
                generateHashAndConfirmParticipation();
            }

            function generateHashAndConfirmParticipation() {
                var deferred = $q.defer();
                if (vm.hash === undefined || !vm.hash) {
                    EventPresentation.externalAccessBySlug(
                        {urlSlug: $stateParams.urlSlug},
                        (response) => {
                            var exAccess = angular.copy(response);
                            vm.hash = exAccess.hash;
                            var data = angular.copy(vm.data.participant);
                            data.hash = vm.hash;
                            data.attending = 'ATTENDING';
                            data.eventId = vm.data.event.id;
                            data.form = vm.form;
                            data.participantAdditionalInfoList = angular.copy(vm.data.participantAdditionalInfoList);
                            data.participantBasicQueryInfoList = angular.copy(vm.data.participantBasicQueryInfoList);
                            data.participantAdditionalQueryInfoList = angular.copy(
                                vm.data.participantAdditionalQueryInfoList
                            );
                            getTicketDetailsAndConfirmParticipation(data);
                            vm.formSubmitted = true;
                            deferred.resolve(response);
                        },
                        (err) => {
                            $scope.endWithErrorOverlay(err);
                            deferred.reject('PAGE_NOT_FOUND');
                        }
                    );
                } else {
                    var data = angular.copy(vm.data.participant);
                    data.hash = vm.hash;
                    data.attending = 'ATTENDING';
                    data.eventId = vm.data.event.id;
                    data.form = vm.form;
                    data.participantAdditionalInfoList = angular.copy(vm.data.participantAdditionalInfoList);
                    data.participantBasicQueryInfoList = angular.copy(vm.data.participantBasicQueryInfoList);
                    data.participantAdditionalQueryInfoList = angular.copy(vm.data.participantAdditionalQueryInfoList);
                    getTicketDetailsAndConfirmParticipation(data);
                    vm.formSubmitted = true;
                }
                return deferred.promise;
            }

            function initFn() {
                $scope.startProgress();
                vm.data = {};
                if (window.localStorage['participantHash']) {
                    vm.hash = window.localStorage['participantHash'];
                    Participant.getByHash(
                        {hash: vm.hash},
                        function (data) {
                            setParticipantDataFn(data);
                            vm.participantConfirmed = true;
                            vm.showForm = false;
                        },
                        function (error) {
                            if (error.status === 500) {
                                $state.go('link.expired');
                            }
                            $scope.endWithErrorOverlay();
                        }
                    );
                } else {
                    Participant.getPublicParticipantDetails(
                        {urlSlug: $stateParams.urlSlug},
                        function (data) {
                            setParticipantDataFn(data);
                            vm.showForm = true;
                        },
                        function (error) {
                            if (error.status === 500) {
                                $state.go('link.expired');
                            }
                            $scope.endWithErrorOverlay();
                        }
                    );
                }
                vm.clientName = ClientService.getClientName();
                loadSupportEmail();
                loadSupportPhone();
            }

            function setParticipantDataFn(data) {
                vm.data = data;
                vm.alreadyCheckedPrivacyPolicy = angular.copy(data.participant.checkedPrivacyPolicy);
                vm.contactEmail = data.contactEmail;
                vm.isParticipantExceed = data.isParticipantExceed;
                vm.eventSiteUrl = data.eventSiteUrl;
                vm.emailReadonly = !!data.participant.email;
                vm.invitationDetails = data.invitationDetails;
                if (vm.isParticipantExceed) {
                    if (
                        vm.isParticipantExceed &&
                        vm.data.participant.attendingEnum &&
                        vm.data.participant.attendingEnum.name !== 'ATTENDING'
                    ) {
                        if (vm.data.participant.attendingEnum && vm.data.participant.attendingEnum.name === 'NOT_REPLIED') {
                            OgModal.open('alert', {message: 'participate.registered.invitation.PENDING'});
                        } else {
                            OgModal.open('alert', {message: 'participant.exceed.notification'});
                        }
                    } else {
                        OgModal.open('alert', {message: 'participant.exceed.notification'});
                    }
                }
                $scope.endProgress();
                listAllFilesFn();
            }

            function listAllFilesFn() {
                $scope.startProgress();
                FileService.listAllFiles(
                    {eventId: vm.data.event.id},
                    (res) => {
                        vm.uploadedFiles = angular.copy(res.data);
                        $scope.endProgress();
                    },
                    $scope.endWithError
                );
            }

            function getTicketDetailsAndConfirmParticipation(data) {
                let _isAlreadyRegistered = data.attendingEnum && data.attendingEnum.name === 'ATTENDING';
                let _isThereNoTickets = !vm.data.eventTickets || vm.data.eventTickets.length <= 0;
                if (_isThereNoTickets || vm.isParticipantExceed || _isAlreadyRegistered) {
                    confirmParticipation(data);
                    return;
                }
                let ticketDetails = getTicketDetails();
                if (vm.data.paymentMethod.name === 'INVOICE') {
                    ticketDetails.fortNoxDetails = angular.copy(vm.fortNoxDetails);
                    data.participantTicket = ticketDetails;
                    confirmParticipation(data);
                } else if (vm.data.paymentMethod.name === 'CREDIT_CARD') {
                    chargeStripeFn(data);
                }
            }

            function downloadFileFn(url, filename) {
                FileService.downloadFile(url, filename);
            }

            function downloadAllFn() {
                if (vm.uploadedFiles.length === 0) return;
                var url = CommonFile.getBaseUrl() + 'api/v1/eventFileStore/downloadAllFiles/' + vm.data.event.id;
                FileService.downloadFile(url, 'eventlogic.zip');
            }

            function loadSupportEmail() {
                $scope.startProgress();
                GlobalOption.get(
                    'SUPPORT_EMAIL',
                    (resp) => {
                        vm.supportEmail = resp[$rootScope.langSelected]
                            ? resp[$rootScope.langSelected]
                            : resp['sv_SV']
                                ? resp['sv_SV']
                                : 'support@eventlogic.se';
                        $scope.endProgress();
                    },
                    $scope.endWithError
                );
            }

            function loadSupportPhone() {
                $scope.startProgress();
                GlobalOption.get(
                    'SUPPORT_PHONE',
                    (resp) => {
                        vm.supportPhone = resp[$rootScope.langSelected]
                            ? resp[$rootScope.langSelected]
                            : resp['sv_SV']
                                ? resp['sv_SV']
                                : '+46(0)31-83 20 20';
                        $scope.endProgress();
                    },
                    $scope.endWithError
                );
            }

            function confirmParticipation(data) {
                $scope.startProgress({showContent: true});
                Participant.confirm(
                    data,
                    function (res) {
                        $scope.endProgress();
                        vm.data.participant = res;
                        if (vm.data.participant.attending === 'NOT_REPLIED') {
                            vm.showForm = true;
                        } else {
                            vm.participantConfirmed = true;
                            vm.showForm = false;
                            window.localStorage.setItem('participantHash', vm.hash);
                        }
                    },
                    $scope.endWithAlert()
                );
            }

            function editParticipantRegistrationFn() {
                $state.go('participant_details.registration', {hash: vm.hash});
            }

            function getTicketDetails() {
                return {
                    eventId: vm.data.event.id,
                    ticket: vm.selectedTicket,
                    paymentMethod: vm.data.paymentMethod.name,
                };
            }

            function chargeStripeFn(data) {
                $scope.startProgress({showContent: true});
                return StripeElements.createPaymentMethod({
                    type: 'card',
                    card: vm.element,
                })
                    .then((response) => {
                        console.log('token created for card ending in ', response.paymentMethod.card.last4);
                        $scope.endProgress();
                        submitPaymentStripeFn(data, response);
                    })
                    .then((payment) => {
                        vm.errorMessage = null;
                        console.log('successfully submitted payment');
                    })
                    .catch((err) => {
                        $scope.endProgress();
                        if (err.type && /^Stripe/.test(err.type)) {
                            vm.errorMessage = err.message;
                            vm.successMessage = null;
                            console.log('Stripe error: ', err.message);
                            OgModal.open('alert', {message: 'stripe.information.stripe.error'});
                        } else {
                            vm.errorMessage = err.message;
                            vm.successMessage = null;
                            console.log('Other error occurred, possibly with your API', err.message);
                            OgModal.open('alert', {message: 'stripe.information.provided.incorrect'});
                        }
                    });
            }

            function submitPaymentStripeFn(data, response) {
                let ticketDetails = getTicketDetails();
                ticketDetails.card = {
                    cardNumber: response.paymentMethod.card.last4,
                    cvc: vm.card.cvc,
                    monthOfExpiry: response.paymentMethod.card.exp_month,
                    yearOfExpiry: response.paymentMethod.card.exp_year,
                    token: response.paymentMethod.id,
                };
                data.participantTicket = ticketDetails;
                confirmParticipation(data);
            }

            function showPrivacyTermsFn(eventId) {
                $scope.startProgress();
                Event.getTermsAndConditions(
                    eventId,
                    function (response) {
                        let data = {
                            title: 'privacy.policy.title',
                            message: response.termsAndConditions,
                        };
                        let customMessageModalData = customMessageModal;
                        customMessageModalData.inputs = {data};

                        ModalService.showModal(customMessageModalData).then((modal) => {
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                        $scope.endProgress();
                    }.bind(this),
                    function (error) {
                        $scope.endProgress();
                    }
                );
            }

            function reOpenInvitaionFn() {
                var data = angular.copy(vm.data.participant);
                data.attending = 'NOT_REPLIED';
                data.hash = hash;
                data.participantAdditionalInfoList = angular.copy(vm.data.participantAdditionalInfoList);
                data.participantBasicQueryInfoList = angular.copy(vm.data.participantBasicQueryInfoList);
                data.confirm = $location.search().confirm;
                getTicketDetailsAndConfirmParticipation(data);
            }

            function updateSelectedTicketFn(selectedTicket) {
                vm.selectedTicket = selectedTicket;
            }

            function freeTicketFn(freeTicket) {
                vm.isFreeTicket = freeTicket;
            }

            function isCardValid() {
                return (
                    vm.card.number &&
                    vm.card.cvc &&
                    vm.card.exp_month &&
                    vm.card.exp_year &&
                    vm.cardValid.number &&
                    vm.cardValid.cvc
                );
            }

            function isFortNoxDetailsValid() {
                return vm.fortNoxDetails.companyName && vm.fortNoxDetails.street && vm.fortNoxDetails.city;
            }

            function isTicketDetailsFilledAndAcceptedFn() {
                //For attending participant update we dont need to check it for update
                if (vm.data.participant.attendingEnum && vm.data.participant.attendingEnum.name === 'ATTENDING') {
                    return true;
                }
                if (vm.data.eventTickets.length > 0 && !vm.isParticipantExceed && !vm.isFreeTicket) {
                    let detailsFilledAndAccepted;
                    if (vm.data.paymentMethod.name == 'INVOICE') {
                        detailsFilledAndAccepted = vm.selectedTicket && vm.selectedTicket.id && isFortNoxDetailsValid();
                    } else if (vm.data.paymentMethod.name == 'CREDIT_CARD') {
                        // detailsFilledAndAccepted = vm.selectedTicket && vm.selectedTicket.id && isCardValid();
                        detailsFilledAndAccepted = vm.selectedTicket && vm.selectedTicket.id;
                    }
                    if (vm.data.sellerInfo.termsAndConditions) {
                        detailsFilledAndAccepted = detailsFilledAndAccepted && vm.termsAccepted;
                    }
                    return detailsFilledAndAccepted;
                }
                return true;
            }

            function langShortFn(langSelected) {
                var lang = langSelected;
                if (lang) {
                    vm.stripeLocal = lang.substring(0, lang.indexOf('_'));
                }
            }

            $scope.$on('lang:change', function (event, data) {
                vm.languageSelected = data.lang;
                langShortFn(vm.languageSelected);
            });

            // A 'hack' to suppress Safari warning. This warning occurs when closing browser window/tab after a textarea has
            // been changed.
            window.onbeforeunload = function () {
            };
        }]
})
