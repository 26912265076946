/**
 * Don't put this content inside function()
 */

export const OgModal = function (params) {
    this.dialogName = params.name;
    this.dialogType = params.type;
    this.dialogContent = params.content;

    if (params.selector) {
        this.dialogSelector = params.selector;
    } else {
        this.dialogSelector = '#og-' + this.dialogName.replace(/:/g, '-') + '-modal';
    }
    this.topSelector = '#og-top-' + this.dialogName.replace(/:/g, '-') + '-modal';

    if (params.template) {
        this.templateUrl = params.template;
    } else {
        this.templateUrl = this.dialogName.replace(/:/g, '_') + '_modal';
    }
    this.templateUrl = './app/components/modals/dialog/' + this.templateUrl + '.html';

    if (params.link) {
        this.link = params.link;
    } else {
        this.link = function () {
        };
    }

    this.scope = {};

    var self = this;
    this.controller = [
        '$scope',
        function ($scope) {
            self.commonController.bind(self)($scope);
            params.controller($scope);
        },
    ];

    this.compile = function (element, attrs) {
        if (self.dialogType == 'custom') {
            var orig = element.html();
            element.html(
                '<div class="modal fade" data-keyboard="false" data-backdrop="static"' +
                ' data-keyboard="false"' +
                ' data-backdrop="static" id="og-' +
                this.dialogName.replace(/:/g, '-') +
                '-modal"' +
                ' style="z-index: 1100;">' +
                orig +
                '</div>'
            );
            if (self.dialogContent == 'map') {
                element.addClass('map-modal');
            }
        }
    };
}
OgModal.prototype.commonController = function ($scope) {
    var self = this;

    $scope.initDialog = function (data) {
    };

    $scope.refreshDialog = function () {
    };

    $scope.dialogOpened = function () {
    };

    $scope._clearDialog = function () {
        if ($scope.data) {
            $scope.data = null;
        }
        if ($scope.originalData) {
            $scope.originalData = null;
        }
        if ($scope.form) {
            ValidationService.clear($scope.form);
        }
        $scope.inProgress = false;
        $scope.clearDialog();
    };

    $scope.clearDialog = function () {
    };

    $scope.close = function () {
        if (self.dialogType == 'custom') {
            self._closeDialog();
            self.automaticClosing = false;
            $scope._closed();
            jQuery('body').removeClass('modal-open');
            jQuery('.modal-backdrop').remove();
        } else {
            jQuery(self.dialogSelector).modal('hide');
        }
    };

    $scope._closed = function () {
        if (!self.automaticClosing) {
            OgModal.closed(self.dialogName);
            $scope._clearDialog();
        }
        $scope._isOpen = false;
    };

    jQuery(self.dialogSelector).bind('hide.bs.modal', $scope._closed);

    self._openDialog = function (data) {
        self.automaticClosing = false;
        if (data) {
            $scope.initDialog(data);
        } else {
            $scope.refreshDialog();
        }
        if (self.dialogType == 'custom') {
            jQuery(self.dialogSelector).addClass('opened');
            jQuery(self.dialogSelector).modal('show');
        } else {
            jQuery(self.dialogSelector).modal('show');
        }
        OgModal.opened(self.dialogName);
        $scope.dialogOpened();
        $scope._isOpen = true;
    };

    self._closeDialog = function () {
        self.automaticClosing = true;
        if (self.dialogType == 'custom') {
            jQuery(self.topSelector).removeClass('opened');
            jQuery(self.dialogSelector).modal('hide');
        } else {
            jQuery(self.dialogSelector).modal('hide');
        }
    };

    OgModal.register(this);

    $scope.$on('dialog:' + self.dialogName + ':open', function (guiEvent, data) {
        OgModal.open(self.dialogName, data);
    });
};

OgModal.stack = [];
OgModal.open = function (name, params) {
    if (OgModal.stack.length > 0) {
        var openDialog = OgModal.stack[OgModal.stack.length - 1];
        dialogs[openDialog]._closeDialog();
    }
    $timeout(function () {
        var dialog = dialogs[name];
        if (dialog) {
            if (params == null) {
                params = {};
            }
            dialog._openDialog(params);
        } else {
            //TODO(JK) delete fallback for old dialogs
            //$log.error('Unknown dialog ' + name);
        }
    }, 200);
};

OgModal.opened = function (name) {
    if (OgModal.stack.length === 0 || OgModal.stack[OgModal.stack.length - 1] !== name) {
        OgModal.stack.push(name);
    }
};

OgModal.closed = function (name) {
    if (OgModal.stack.length > 0) {
        var openDialog = OgModal.stack[OgModal.stack.length - 1];
        if (openDialog === name) {
            OgModal.stack.pop();
        }
        if (OgModal.stack.length > 0) {
            $timeout(function () {
                var previous = OgModal.stack[OgModal.stack.length - 1];
                dialogs[previous]._openDialog();
            }, 200);
        }
    }
};

var dialogs = {};

OgModal.register = function (dialog) {
    dialogs[dialog.dialogName] = dialog;
};