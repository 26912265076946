import amountAndPriceService from '../../../utils/price_calculate/PriceCalculationSupplierUtil';
import tooltipPartStatusUtil from '../../../utils/negotiation_notification/NegotiationTooltipPartStatusUtil';
import {UtilFunctions} from "../../../utils/global/utilFunctions";

(function () {
    'use strict';

    angular.module('ogSupplierOfferPartSummary').directive('ogSupplierOfferPartSummary', [
        '$translate',
        'TranslationService',
        function ($translate, TranslationService) {
            return {
                restrict: 'EA',
                scope: {
                    currency: '=',
                    offerPart: '=',
                    offer: '=',
                    originalOfferPart: '=',
                    additionalInfoChanges: '=',
                    event: '=',
                    isOfferPartOption: '=',
                },
                /*jshint ignore:start */
                templateUrl:
                    './app/components/directives/supplier_offer_part_summary/og_supplier_offer_part_summary.directive.view.html',
                /*jshint ignore:end */
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.updateRowSummary = function () {
                            $scope.changedFields = [];

                            let _originalPriceInclVat = amountAndPriceService.getPriceInclVat(
                                $scope.offer,
                                $scope.originalOfferPart
                            );
                            let _currentPriceInclVat = amountAndPriceService.getPriceInclVat(
                                $scope.offer,
                                $scope.offerPart
                            );
                            let _originalOldPriceInclVat = amountAndPriceService.getOldPriceInclVat(
                                $scope.offer,
                                $scope.originalOfferPart
                            );
                            let _currentOldPriceInclVat = amountAndPriceService.getOldPriceInclVat(
                                $scope.offer,
                                $scope.offerPart
                            );

                            if (
                                tooltipPartStatusUtil.ABS($scope.offerPart) ||
                                tooltipPartStatusUtil.DBS($scope.offerPart) ||
                                tooltipPartStatusUtil.AWFC($scope.offerPart) ||
                                tooltipPartStatusUtil.DWFC($scope.offerPart)
                            ) {
                                return;
                            }

                            if (
                                tooltipPartStatusUtil.ACCEPTED($scope.originalOfferPart) ||
                                tooltipPartStatusUtil.AWFS($scope.originalOfferPart)
                            ) {
                                if (tooltipPartStatusUtil.EBS($scope.offerPart)) {
                                    if ($scope.originalOfferPart.name !== $scope.offerPart.name) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.name'),
                                            originalValue: $scope.originalOfferPart.name,
                                            originalCodeValue: $scope.originalOfferPart.nameCode,
                                            supplierValue: $scope.offerPart.name,
                                            supplierCodeValue: $scope.offerPart.nameCode,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.commentBySupplier !==
                                        $scope.offerPart.commentBySupplier
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.description'),
                                            originalValue: $scope.originalOfferPart.commentBySupplier,
                                            supplierValue: $scope.offerPart.commentBySupplier,
                                        });
                                    }
                                    if ($scope.originalOfferPart.amount !== $scope.offerPart.amount) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.amount,
                                            supplierValue: $scope.offerPart.amount,
                                        });
                                    }
                                    if ($scope.originalOfferPart.amountType.name !== $scope.offerPart.amountType.name) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue: $scope.getAmountTypeName(
                                                $scope.originalOfferPart.amountType
                                            ),
                                            supplierValue: $scope.getAmountTypeName($scope.offerPart.amountType),
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.dateTimeFrom !== $scope.offerPart.dateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.offerPart.dateTimeTo
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.originalOfferPart.dateTimeFrom,
                                                $scope.originalOfferPart.dateTimeTo,
                                            ],
                                            supplierValue: [$scope.offerPart.dateTimeFrom, $scope.offerPart.dateTimeTo],
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.price !== $scope.offerPart.price &&
                                        !$scope.offer.vatIncludedInPrice
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: amountAndPriceService.getPrice(
                                                $scope.offer,
                                                $scope.originalOfferPart
                                            ),
                                            supplierValue: amountAndPriceService.getPrice(
                                                $scope.offer,
                                                $scope.offerPart
                                            ),
                                        });
                                    }
                                    if (
                                        _originalPriceInclVat !== _currentPriceInclVat &&
                                        $scope.offer.vatIncludedInPrice
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: _originalPriceInclVat,
                                            supplierValue: _currentPriceInclVat,
                                        });
                                    }
                                }
                                if (tooltipPartStatusUtil.ACCEPTED($scope.offerPart)) {
                                    if (
                                        $scope.offerPart.oldPrice !== $scope.offerPart.price &&
                                        !$scope.offer.vatIncludedInPrice
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: UtilFunctions.isGreaterOrEqualToZero(
                                                $scope.offerPart.oldPrice
                                            )
                                                ? $scope.offerPart.oldPrice
                                                : $scope.offerPart.price,
                                            supplierValue: UtilFunctions.isGreaterOrEqualToZero(
                                                $scope.offerPart.newPrice
                                            )
                                                ? $scope.offerPart.newPrice
                                                : $scope.offerPart.price,
                                        });
                                    }
                                    if (
                                        $scope.offerPart.oldDateTimeFrom &&
                                        $scope.offerPart.oldDateTimeTo &&
                                        ($scope.offerPart.dateTimeFrom !== $scope.offerPart.oldDateTimeFrom ||
                                            $scope.offerPart.dateTimeTo !== $scope.offerPart.oldDateTimeTo)
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.offerPart.oldDateTimeFrom,
                                                $scope.offerPart.oldDateTimeTo,
                                            ],
                                            supplierValue: [$scope.offerPart.dateTimeFrom, $scope.offerPart.dateTimeTo],
                                        });
                                    }
                                    if (
                                        _currentOldPriceInclVat !== _currentPriceInclVat &&
                                        $scope.offer.vatIncludedInPrice
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: UtilFunctions.isGreaterOrEqualToZero(_currentOldPriceInclVat)
                                                ? _currentOldPriceInclVat
                                                : _currentPriceInclVat,
                                            supplierValue: UtilFunctions.isGreaterOrEqualToZero(
                                                $scope.offerPart.newPriceInclVat
                                            )
                                                ? $scope.offerPart.newPriceInclVat
                                                : $scope.offerPart.price,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.oldAmount &&
                                        ($scope.originalOfferPart.amount !== $scope.offerPart.amount ||
                                            $scope.originalOfferPart.amount !== $scope.originalOfferPart.oldAmount)
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.oldAmount
                                                ? $scope.originalOfferPart.oldAmount
                                                : '-',
                                            supplierValue: $scope.offerPart.amount,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.amountType.name !== $scope.offerPart.amountType.name ||
                                        ($scope.originalOfferPart.oldAmountType &&
                                            $scope.originalOfferPart.amountType.name !==
                                                $scope.originalOfferPart.oldAmountType.name)
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue:
                                                $scope.originalOfferPart.oldAmountType &&
                                                $scope.originalOfferPart.oldAmountType.name
                                                    ? $scope.getAmountTypeName($scope.originalOfferPart.oldAmountType)
                                                    : '-',
                                            supplierValue: $scope.getAmountTypeName($scope.offerPart.amountType),
                                        });
                                    }
                                }
                            }

                            if (tooltipPartStatusUtil.EWFS_EWFCD_AWFCD_DWFCD($scope.originalOfferPart)) {
                                if (tooltipPartStatusUtil.EBS($scope.offerPart)) {
                                    if (
                                        $scope.originalOfferPart.name !== $scope.offerPart.name ||
                                        $scope.originalOfferPart.name !== $scope.originalOfferPart.oldName
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.name'),
                                            originalValue: $scope.originalOfferPart.oldName,
                                            originalCodeValue: $scope.originalOfferPart.oldNameCode,
                                            creatorValue:
                                                $scope.originalOfferPart.name === $scope.originalOfferPart.oldName
                                                    ? '-'
                                                    : $scope.originalOfferPart.creatorName,
                                            creatorCodeValue:
                                                $scope.originalOfferPart.nameCode !== null &&
                                                $scope.originalOfferPart.nameCode ===
                                                    $scope.originalOfferPart.oldNameCode
                                                    ? '-'
                                                    : $scope.originalOfferPart.creatorNameCode,
                                            supplierValue:
                                                $scope.originalOfferPart.name === $scope.offerPart.name
                                                    ? '-'
                                                    : $scope.offerPart.name,
                                            supplierCodeValue:
                                                $scope.originalOfferPart.nameCode !== null &&
                                                $scope.originalOfferPart.nameCode === $scope.offerPart.nameCode
                                                    ? '-'
                                                    : $scope.offerPart.nameCode,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.amount !== $scope.offerPart.amount ||
                                        $scope.originalOfferPart.amount !== $scope.originalOfferPart.oldAmount
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.oldAmount,
                                            creatorValue:
                                                $scope.originalOfferPart.amount === $scope.originalOfferPart.oldAmount
                                                    ? '-'
                                                    : $scope.originalOfferPart.creatorAmount,
                                            supplierValue:
                                                $scope.originalOfferPart.amount === $scope.offerPart.amount
                                                    ? '-'
                                                    : $scope.offerPart.amount,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.amountType.name !== $scope.offerPart.amountType.name ||
                                        ($scope.originalOfferPart.oldAmountType &&
                                            $scope.originalOfferPart.amountType.name !==
                                                $scope.originalOfferPart.oldAmountType.name)
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue:
                                                $scope.originalOfferPart.oldAmountType &&
                                                $scope.originalOfferPart.oldAmountType.name
                                                    ? $scope.getAmountTypeName($scope.originalOfferPart.oldAmountType)
                                                    : '-',
                                            creatorValue:
                                                $scope.originalOfferPart.oldAmountType &&
                                                $scope.originalOfferPart.amountType.name ===
                                                    $scope.originalOfferPart.oldAmountType.name
                                                    ? '-'
                                                    : $scope.getAmountTypeName(
                                                          $scope.originalOfferPart.creatorAmountType
                                                      ),
                                            supplierValue:
                                                $scope.originalOfferPart.amountType.name ===
                                                $scope.offerPart.amountType.name
                                                    ? '-'
                                                    : $scope.getAmountTypeName($scope.offerPart.amountType),
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.dateTimeFrom !== $scope.offerPart.dateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.offerPart.dateTimeTo ||
                                        $scope.originalOfferPart.dateTimeFrom !==
                                            $scope.originalOfferPart.oldDateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.originalOfferPart.oldDateTimeTo
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.originalOfferPart.oldDateTimeFrom,
                                                $scope.originalOfferPart.oldDateTimeTo,
                                            ],
                                            creatorValue:
                                                $scope.originalOfferPart.dateTimeFrom ===
                                                    $scope.originalOfferPart.oldDateTimeFrom &&
                                                $scope.originalOfferPart.dateTimeTo ===
                                                    $scope.originalOfferPart.oldDateTimeTo
                                                    ? '-'
                                                    : [
                                                          $scope.originalOfferPart.creatorDateTimeFrom,
                                                          $scope.originalOfferPart.creatorDateTimeTo,
                                                      ],
                                            supplierValue:
                                                $scope.originalOfferPart.dateTimeFrom ===
                                                    $scope.offerPart.dateTimeFrom &&
                                                $scope.originalOfferPart.dateTimeTo === $scope.offerPart.dateTimeTo
                                                    ? '-'
                                                    : [$scope.offerPart.dateTimeFrom, $scope.offerPart.dateTimeTo],
                                        });
                                    }
                                    //TODO(SC) creator never changed price. Need to remove after check
                                    if (
                                        ($scope.originalOfferPart.price !== $scope.offerPart.price ||
                                            $scope.originalOfferPart.price !== $scope.originalOfferPart.oldPrice) &&
                                        !$scope.offer.vatIncludedInPrice
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: amountAndPriceService.getOldPriceValue(
                                                null,
                                                $scope.originalOfferPart
                                            ),
                                            creatorValue:
                                                $scope.originalOfferPart.price === $scope.originalOfferPart.oldPrice
                                                    ? '-'
                                                    : $scope.originalOfferPart.creatorPrice,
                                            supplierValue:
                                                $scope.originalOfferPart.price === $scope.offerPart.price
                                                    ? '-'
                                                    : amountAndPriceService.getPrice($scope.offer, $scope.offerPart),
                                        });
                                    }
                                } else if (tooltipPartStatusUtil.EWFS_ACCEPTED_DECLINED($scope.offerPart)) {
                                    if ($scope.originalOfferPart.name !== $scope.originalOfferPart.oldName) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.name'),
                                            originalValue: $scope.originalOfferPart.oldName,
                                            originalCodeValue: $scope.originalOfferPart.oldNameCode,
                                            creatorValue: $scope.originalOfferPart.name,
                                            creatorCodeValue: $scope.originalOfferPart.nameCode,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.commentByCreator !==
                                        $scope.originalOfferPart.oldCommentByCreator
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.description'),
                                            originalValue: $scope.originalOfferPart.oldCommentByCreator,
                                            creatorValue: TranslationService.translate(
                                                $scope.originalOfferPart.commentByCreator,
                                                $scope.originalOfferPart.commentByCreatorCode
                                            ),
                                        });
                                    }
                                    if ($scope.originalOfferPart.amount !== $scope.originalOfferPart.oldAmount) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.oldAmount,
                                            creatorValue: $scope.originalOfferPart.amount,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.oldAmountType &&
                                        $scope.originalOfferPart.amountType.name !==
                                        $scope.originalOfferPart.oldAmountType.name
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue: $scope.getAmountTypeName(
                                                $scope.originalOfferPart.oldAmountType
                                            ),
                                            creatorValue: $scope.getAmountTypeName($scope.originalOfferPart.amountType),
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.dateTimeFrom !==
                                        $scope.originalOfferPart.oldDateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.originalOfferPart.oldDateTimeTo
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.originalOfferPart.oldDateTimeFrom,
                                                $scope.originalOfferPart.oldDateTimeTo,
                                            ],
                                            creatorValue: [
                                                $scope.originalOfferPart.dateTimeFrom,
                                                $scope.originalOfferPart.dateTimeTo,
                                            ],
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.price &&
                                        $scope.originalOfferPart.oldPrice &&
                                        $scope.originalOfferPart.price !== $scope.originalOfferPart.oldPrice &&
                                        !$scope.offer.vatIncludedInPrice &&
                                        $scope.offer.status.name !== 'SENT'
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: amountAndPriceService.getOldPriceValue(
                                                null,
                                                $scope.originalOfferPart
                                            ),
                                            creatorValue: amountAndPriceService.getPrice(
                                                $scope.offer,
                                                $scope.originalOfferPart
                                            ),
                                        });
                                    }
                                    if (
                                        _originalPriceInclVat &&
                                        _originalPriceInclVat !== _originalOldPriceInclVat &&
                                        $scope.offer.vatIncludedInPrice &&
                                        $scope.offer.status.name !== 'SENT'
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: _originalOldPriceInclVat,
                                            creatorValue: _originalPriceInclVat,
                                        });
                                    }
                                    if ($scope.originalOfferPart.hasAdditionalQuestions) {
                                        angular.forEach($scope.additionalInfoChanges, (list) => {
                                            if ($scope.offerPart.nameCode === list.code && list.offerPart && $scope.offerPart.id === list.offerPart.id) {
                                                $scope.changedFields.push({
                                                    name: $translate.instant(list.supplierLabelCode),
                                                    originalValue: $scope.getOldFieldValue(list),
                                                    creatorValue: $scope.getFieldValue(list),
                                                });
                                            }
                                        });
                                    }
                                }
                            }

                            if (tooltipPartStatusUtil.EWFC_EWFSD_AWFSD_DWFSD($scope.originalOfferPart)) {
                                if (tooltipPartStatusUtil.EBS($scope.offerPart)) {
                                    if (
                                        $scope.originalOfferPart.name !== $scope.offerPart.name ||
                                        $scope.originalOfferPart.name !== $scope.originalOfferPart.oldName
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.name'),
                                            originalValue: $scope.originalOfferPart.oldName,
                                            originalCodeValue: $scope.originalOfferPart.oldNameCode,
                                            creatorValue:
                                                $scope.originalOfferPart.name === $scope.originalOfferPart.oldName
                                                    ? '-'
                                                    : $scope.originalOfferPart.creatorName,
                                            creatorCodeValue:
                                                $scope.originalOfferPart.nameCode !== null &&
                                                $scope.originalOfferPart.nameCode ===
                                                $scope.originalOfferPart.oldNameCode
                                                    ? '-'
                                                    : $scope.originalOfferPart.creatorNameCode,
                                            supplierValue:
                                                $scope.originalOfferPart.name === $scope.offerPart.name
                                                    ? '-'
                                                    : $scope.offerPart.name,
                                            supplierCodeValue:
                                                $scope.originalOfferPart.nameCode !== null &&
                                                $scope.originalOfferPart.nameCode === $scope.offerPart.nameCode
                                                    ? '-'
                                                    : $scope.offerPart.nameCode,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.amount !== $scope.offerPart.amount ||
                                        $scope.originalOfferPart.amount !== $scope.originalOfferPart.oldAmount
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.oldAmount,
                                            creatorValue:
                                                $scope.originalOfferPart.amount === $scope.originalOfferPart.oldAmount
                                                    ? '-'
                                                    : $scope.originalOfferPart.creatorAmount,
                                            supplierValue:
                                                $scope.originalOfferPart.amount === $scope.offerPart.amount
                                                    ? '-'
                                                    : $scope.offerPart.amount,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.amountType.name !== $scope.offerPart.amountType.name ||
                                        ($scope.originalOfferPart.oldAmountType &&
                                            $scope.originalOfferPart.amountType.name !==
                                            $scope.originalOfferPart.oldAmountType.name)
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue:
                                                $scope.originalOfferPart.oldAmountType &&
                                                $scope.originalOfferPart.oldAmountType.name
                                                    ? $scope.getAmountTypeName($scope.originalOfferPart.oldAmountType)
                                                    : '-',
                                            creatorValue:
                                                $scope.originalOfferPart.oldAmountType &&
                                                $scope.originalOfferPart.amountType.name ===
                                                $scope.originalOfferPart.oldAmountType.name
                                                    ? '-'
                                                    : $scope.getAmountTypeName(
                                                        $scope.originalOfferPart.creatorAmountType
                                                    ),
                                            supplierValue:
                                                $scope.originalOfferPart.amountType.name ===
                                                $scope.offerPart.amountType.name
                                                    ? '-'
                                                    : $scope.getAmountTypeName($scope.offerPart.amountType),
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.dateTimeFrom !== $scope.offerPart.dateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.offerPart.dateTimeTo ||
                                        $scope.originalOfferPart.dateTimeFrom !==
                                        $scope.originalOfferPart.oldDateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.originalOfferPart.oldDateTimeTo
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.originalOfferPart.oldDateTimeFrom,
                                                $scope.originalOfferPart.oldDateTimeTo,
                                            ],
                                            creatorValue:
                                                $scope.originalOfferPart.dateTimeFrom ===
                                                $scope.originalOfferPart.oldDateTimeFrom &&
                                                $scope.originalOfferPart.dateTimeTo ===
                                                $scope.originalOfferPart.oldDateTimeTo
                                                    ? '-'
                                                    : [
                                                        $scope.originalOfferPart.creatorDateTimeFrom,
                                                        $scope.originalOfferPart.creatorDateTimeTo,
                                                    ],
                                            supplierValue:
                                                $scope.originalOfferPart.dateTimeFrom ===
                                                $scope.offerPart.dateTimeFrom &&
                                                $scope.originalOfferPart.dateTimeTo === $scope.offerPart.dateTimeTo
                                                    ? '-'
                                                    : [$scope.offerPart.dateTimeFrom, $scope.offerPart.dateTimeTo],
                                        });
                                    }
                                    //TODO(SC) creator never changed price. Need to remove after check
                                    if (
                                        ($scope.originalOfferPart.price !== $scope.offerPart.price ||
                                            $scope.originalOfferPart.price !== $scope.originalOfferPart.oldPrice) &&
                                        !$scope.offer.vatIncludedInPrice
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: amountAndPriceService.getOldPriceValue(
                                                null,
                                                $scope.originalOfferPart
                                            ),
                                            creatorValue:
                                                $scope.originalOfferPart.price === $scope.originalOfferPart.oldPrice
                                                    ? '-'
                                                    : $scope.originalOfferPart.creatorPrice,
                                            supplierValue:
                                                $scope.originalOfferPart.price === $scope.offerPart.price
                                                    ? '-'
                                                    : amountAndPriceService.getPrice($scope.offer, $scope.offerPart),
                                        });
                                    }
                                } else if (tooltipPartStatusUtil.EWFC_ACCEPTED_DECLINED($scope.offerPart)) {
                                    if ($scope.originalOfferPart.name !== $scope.originalOfferPart.oldName) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.name'),
                                            originalValue: $scope.originalOfferPart.oldName,
                                            originalCodeValue: $scope.originalOfferPart.oldNameCode,
                                            creatorValue: $scope.originalOfferPart.name,
                                            creatorCodeValue: $scope.originalOfferPart.nameCode,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.commentByCreator !==
                                        $scope.originalOfferPart.oldCommentByCreator
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.description'),
                                            originalValue: $scope.originalOfferPart.oldCommentByCreator,
                                            creatorValue: TranslationService.translate(
                                                $scope.originalOfferPart.commentByCreator,
                                                $scope.originalOfferPart.commentByCreatorCode
                                            ),
                                        });
                                    }
                                    if ($scope.originalOfferPart.amount !== $scope.originalOfferPart.oldAmount) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.oldAmount,
                                            creatorValue: $scope.originalOfferPart.amount,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.amount !== $scope.originalOfferPart.finalAmount &&
                                        $scope.offer &&
                                        $scope.offer.status &&
                                        ['FINISHED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_SUPPLIER'].indexOf(
                                            $scope.offer.status.name
                                        ) > -1
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.amount,
                                            creatorValue: $scope.originalOfferPart.finalAmount,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.oldAmountType &&
                                        $scope.originalOfferPart.amountType.name !==
                                        $scope.originalOfferPart.oldAmountType.name
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue: $scope.getAmountTypeName(
                                                $scope.originalOfferPart.oldAmountType
                                            ),
                                            creatorValue: $scope.getAmountTypeName($scope.originalOfferPart.amountType),
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.dateTimeFrom !==
                                        $scope.originalOfferPart.oldDateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.originalOfferPart.oldDateTimeTo
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.originalOfferPart.oldDateTimeFrom,
                                                $scope.originalOfferPart.oldDateTimeTo,
                                            ],
                                            creatorValue: [
                                                $scope.originalOfferPart.dateTimeFrom,
                                                $scope.originalOfferPart.dateTimeTo,
                                            ],
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.price !== $scope.originalOfferPart.oldPrice &&
                                        !$scope.offer.vatIncludedInPrice
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: amountAndPriceService.getOldPriceValue(
                                                null,
                                                $scope.originalOfferPart
                                            ),
                                            creatorValue: amountAndPriceService.getPrice(
                                                $scope.offer,
                                                $scope.originalOfferPart
                                            ),
                                        });
                                    }
                                    if (
                                        _originalPriceInclVat !== _originalOldPriceInclVat &&
                                        $scope.offer.vatIncludedInPrice &&
                                        $scope.offer &&
                                        $scope.offer.status &&
                                        ['FINISHED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_SUPPLIER'].indexOf(
                                            $scope.offer.status.name
                                        ) === -1
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: _originalOldPriceInclVat,
                                            creatorValue: _originalPriceInclVat,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.price !== $scope.originalOfferPart.finalPrice &&
                                        $scope.offer &&
                                        $scope.offer.status &&
                                        ['FINISHED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_SUPPLIER'].indexOf(
                                            $scope.offer.status.name
                                        ) > -1 &&
                                        !$scope.offer.vatIncludedInPrice
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: $scope.originalOfferPart.price,
                                            creatorValue: $scope.originalOfferPart.finalPrice,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.newPriceInclVat !==
                                        $scope.originalOfferPart.finalPriceInclVat &&
                                        $scope.offer &&
                                        $scope.offer.status &&
                                        ['FINISHED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_SUPPLIER'].indexOf(
                                            $scope.offer.status.name
                                        ) > -1 &&
                                        $scope.offer.vatIncludedInPrice
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: $scope.originalOfferPart.newPriceInclVat,
                                            creatorValue: $scope.originalOfferPart.finalPriceInclVat,
                                        });
                                    }
                                    if ($scope.originalOfferPart.hasAdditionalQuestions) {
                                        angular.forEach($scope.additionalInfoChanges, (list) => {
                                            if (list.offerPart && $scope.originalOfferPart.id === list.offerPart.id) {
                                                $scope.changedFields.push({
                                                    name: $translate.instant(list.supplierLabelCode),
                                                    originalValue: $scope.getOldFieldValue(list),
                                                    creatorValue: $scope.getFieldValue(list),
                                                });
                                            }
                                        });
                                    }
                                    if ($scope.offerPart.finalPaidOnsite) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.paid.onsite'),
                                            originalValue: $translate.instant('common.paid.onsite.false.value'),
                                            creatorValue: $scope.offerPart.finalPaidOnsite
                                                ? $translate.instant('common.paid.onsite.true.value')
                                                : $translate.instant('common.paid.onsite.false.value'),
                                        });
                                    }
                                }
                            }

                            if (tooltipPartStatusUtil.EBS($scope.originalOfferPart)) {
                                if (tooltipPartStatusUtil.EBS($scope.offerPart)) {
                                    if ($scope.offerPart.name !== $scope.offerPart.oldName) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.name'),
                                            originalValue: $scope.offerPart.oldName,
                                            originalCodeValue: $scope.offerPart.nameCode,
                                            supplierValue: $scope.offerPart.name,
                                            supplierCodeValue: $scope.offerPart.nameCode,
                                        });
                                    }
                                    if ($scope.offerPart.commentBySupplier !== $scope.offerPart.oldCommentBySupplier) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.description'),
                                            originalValue: $scope.offerPart.oldCommentBySupplier,
                                            supplierValue: $scope.offerPart.commentBySupplier,
                                        });
                                    }
                                    if ($scope.offerPart.amount !== $scope.offerPart.oldAmount) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.offerPart.oldAmount,
                                            supplierValue: $scope.offerPart.amount,
                                        });
                                    }
                                    if ($scope.offerPart.amountType.name !== $scope.offerPart.oldAmountType.name) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue: $scope.getAmountTypeName($scope.offerPart.oldAmountType),
                                            supplierValue: $scope.getAmountTypeName($scope.offerPart.amountType),
                                        });
                                    }
                                    if (
                                        $scope.offerPart.dateTimeFrom !== $scope.offerPart.oldDateTimeFrom ||
                                        $scope.offerPart.dateTimeTo !== $scope.offerPart.oldDateTimeTo
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.offerPart.oldDateTimeFrom,
                                                $scope.offerPart.oldDateTimeTo,
                                            ],
                                            supplierValue: [$scope.offerPart.dateTimeFrom, $scope.offerPart.dateTimeTo],
                                        });
                                    }
                                    if (
                                        $scope.offerPart.price !== $scope.offerPart.newPrice &&
                                        !$scope.offer.vatIncludedInPrice
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: UtilFunctions.isGreaterOrEqualToZero(
                                                $scope.offerPart.oldPrice
                                            )
                                                ? $scope.offerPart.oldPrice
                                                : $scope.offerPart.price,
                                            supplierValue: UtilFunctions.isGreaterOrEqualToZero(
                                                $scope.offerPart.newPrice
                                            )
                                                ? $scope.offerPart.newPrice
                                                : $scope.offerPart.price,
                                        });
                                    }
                                    if (
                                        _currentPriceInclVat !== _currentOldPriceInclVat &&
                                        $scope.offer.vatIncludedInPrice &&
                                        !$scope.isOfferPartOption
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: UtilFunctions.isGreaterOrEqualToZero(_currentOldPriceInclVat)
                                                ? _currentOldPriceInclVat
                                                : _currentPriceInclVat,
                                            supplierValue: UtilFunctions.isGreaterOrEqualToZero(_currentPriceInclVat)
                                                ? _currentPriceInclVat
                                                : $scope.offerPart.newPriceInclVat,
                                        });
                                    }
                                    if ($scope.isOfferPartOption) {
                                        if (
                                            $scope.offerPart.price !== $scope.offerPart.oldPrice &&
                                            !$scope.offer.vatIncludedInPrice
                                        ) {
                                            $scope.changedFields.push({
                                                name: $translate.instant('common.price'),
                                                originalValue: UtilFunctions.isGreaterOrEqualToZero(
                                                    $scope.offerPart.oldPrice
                                                )
                                                    ? $scope.offerPart.oldPrice
                                                    : $scope.offerPart.price,
                                                supplierValue: UtilFunctions.isGreaterOrEqualToZero(
                                                    $scope.offerPart.newPrice
                                                )
                                                    ? $scope.offerPart.newPrice
                                                    : $scope.offerPart.price,
                                            });
                                        }
                                        if (
                                            _currentPriceInclVat !== _currentOldPriceInclVat &&
                                            $scope.offer.vatIncludedInPrice
                                        ) {
                                            $scope.changedFields.push({
                                                name: $translate.instant('common.price'),
                                                originalValue: UtilFunctions.isGreaterOrEqualToZero(
                                                    _currentOldPriceInclVat
                                                )
                                                    ? _currentOldPriceInclVat
                                                    : _currentPriceInclVat,
                                                supplierValue: UtilFunctions.isGreaterOrEqualToZero(
                                                    $scope.offerPart.newPriceInclVat
                                                )
                                                    ? $scope.offerPart.newPriceInclVat
                                                    : _currentPriceInclVat,
                                            });
                                        }
                                    }
                                }
                            }
                            //TODO conditions matched to (tooltipPartStatusUtil.ACCEPTED($scope.originalOfferPart) && tooltipPartStatusUtil.AWFS($scope.originalOfferPart)) check and remove
                            /*if (tooltipPartStatusUtil.AWFS($scope.originalOfferPart)) {
                    if (tooltipPartStatusUtil.EBS($scope.offerPart)) {
                      if ($scope.originalOfferPart.name !== $scope.offerPart.name) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.name'),
                          originalValue: $scope.originalOfferPart.name,
                          originalCodeValue: $scope.originalOfferPart.nameCode,
                          creatorValue: $scope.originalOfferPart.creatorName,
                          creatorCodeValue: $scope.originalOfferPart.creatorNameCode,
                          supplierValue: $scope.offerPart.name,
                          supplierCodeValue: $scope.offerPart.nameCode
                        });
                      }
                      if ($scope.originalOfferPart.amount !== $scope.offerPart.amount) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.amount'),
                          originalValue: $scope.originalOfferPart.amount,
                          creatorValue: $scope.originalOfferPart.creatorAmount,
                          supplierValue: $scope.offerPart.amount
                        });
                      }
                      if ($scope.originalOfferPart.amountType.name !== $scope.offerPart.amountType.name) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.amountType'),
                          originalValue: $scope.getAmountTypeName($scope.originalOfferPart.amountType),
                          creatorValue: $scope.getAmountTypeName($scope.originalOfferPart.creatorAmountType),
                          supplierValue:$scope.getAmountTypeName($scope.offerPart.amountType)
                        });
                      }
                      if ($scope.originalOfferPart.dateTimeFrom !== $scope.offerPart.dateTimeFrom ||
                          $scope.originalOfferPart.dateTimeTo !== $scope.offerPart.dateTimeTo) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.date'),
                          originalValue: [$scope.originalOfferPart.dateTimeFrom, $scope.originalOfferPart.dateTimeTo],
                          creatorValue: [$scope.originalOfferPart.creatorDateTimeFrom,
                            $scope.originalOfferPart.creatorDateTimeTo
                          ],
                          supplierValue: [$scope.offerPart.dateTimeFrom, $scope.offerPart.dateTimeTo]
                        });
                      }
                      //TODO(SC) CREATOR NEVER CHANGE PRICE, NEED TO REMOVE AFTER CHECK
                      if ($scope.originalOfferPart.price !== $scope.offerPart.price) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.price'),
                          originalValue: amountAndPriceService.getOldPriceValue(null, $scope.originalOfferPart),
                          creatorValue: $scope.originalOfferPart.creatorPrice,
                          supplierValue: amountAndPriceService.getPrice(null, $scope.offerPart)
                        });
                      }
                    }
                    //TODO no uses in view, check and remove
                    else if (tooltipPartStatusUtil.EWFS_ACCEPTED_DECLINED($scope.offerPart)) {
                      if ($scope.originalOfferPart.name !== $scope.originalOfferPart.oldName) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.name'),
                          originalValue: $scope.originalOfferPart.oldName,
                          originalCodeValue: $scope.originalOfferPart.oldNameCode,
                          creatorValue: $scope.originalOfferPart.name,
                          creatorCodeValue: $scope.originalOfferPart.nameCode
                        });
                      }
                      if ($scope.originalOfferPart.commentByCreator !== $scope.originalOfferPart.oldCommentByCreator) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.description'),
                          originalValue: $scope.originalOfferPart.oldCommentByCreator,
                          creatorValue: TranslationService.translate($scope.originalOfferPart.commentByCreator, $scope.originalOfferPart.commentByCreatorCode)
                        });
                      }
                      if ($scope.originalOfferPart.amount !== $scope.originalOfferPart.oldAmount) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.amount'),
                          originalValue: $scope.originalOfferPart.oldAmount,
                          creatorValue: $scope.originalOfferPart.amount
                        });
                      }
                      if ($scope.originalOfferPart.oldAmountType &&
                          $scope.originalOfferPart.amountType.name !== $scope.originalOfferPart.oldAmountType.name) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.amountType'),
                          originalValue: $scope.getAmountTypeName($scope.originalOfferPart.oldAmountType),
                          creatorValue: $scope.getAmountTypeName($scope.originalOfferPart.amountType)
                        });
                      }
                      if ($scope.originalOfferPart.dateTimeFrom !== $scope.originalOfferPart.oldDateTimeFrom) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.date'),
                          originalValue: [$scope.originalOfferPart.oldDateTimeFrom, $scope.originalOfferPart.oldDateTimeTo],
                          creatorValue: [$scope.originalOfferPart.dateTimeFrom, $scope.originalOfferPart.dateTimeTo]
                        });
                      }
                      if ($scope.originalOfferPart.price !== $scope.originalOfferPart.oldPrice) {
                        $scope.changedFields.push({
                          name: $translate.instant('common.price'),
                          originalValue: amountAndPriceService.getOldPriceValue(null, $scope.originalOfferPart),
                          creatorValue: amountAndPriceService.getPrice(null, $scope.originalOfferPart)
                        });
                      }
                    }
                  }*/
                        };

                        $scope.getAmountTypeName = function (amountType) {
                            if (amountType) {
                                if (amountType.name === 'SAME_AS_EVENT') {
                                    return 'PEOPLE';
                                }
                                return amountType.name;
                            }
                            return;
                        };

                        $scope.isChangedFieldName = function (label) {
                            return label === $translate.instant('common.name');
                        };

                        $scope.isChangedFieldDescription = function (label) {
                            return label === $translate.instant('common.description');
                        };

                        $scope.isChangedFieldDate = function (label) {
                            return label === $translate.instant('common.date');
                        };

                        $scope.isChangedFieldAmount = function (label) {
                            return label === $translate.instant('common.amount');
                        };

                        $scope.isChangedFieldAmountType = function (label) {
                            return label === $translate.instant('common.amountType');
                        };

                        $scope.isChangedFieldPrice = function (label) {
                            return label === $translate.instant('common.price');
                        };

                        $scope.isChangedFieldPaidOnsite = function (label) {
                            return label === $translate.instant('common.paid.onsite');
                        };

                        $scope.getAmountLocalisedWithCurrency = function (price, currency) {
                            if (!UtilFunctions.isDefinedAndNotNull(price) || price === '-') {
                                return '-';
                            }
                            return amountAndPriceService.viewPrice(price, currency);
                        };

                        $scope.getOldFieldValue = function (list) {
                            if (['MULTICHECKBOX'].indexOf(list.type.name) > -1) {
                                return list.oldFieldValue.split('~').join(', ');
                            } else if (['TEXT_WITH_AMOUNT_TYPE'].indexOf(list.type.name) !== -1) {
                                return list.oldFieldValue
                                    .split('~')[0]
                                    .concat(' ')
                                    .concat($translate.instant('common.people'));
                            } else {
                                return list.oldFieldValue;
                            }
                        };

                        $scope.getFieldValue = function (list) {
                            if (['MULTICHECKBOX'].indexOf(list.type.name) > -1) {
                                return list.fieldValue.split('~').join(', ');
                            } else if (['TEXT_WITH_AMOUNT_TYPE'].indexOf(list.type.name) !== -1) {
                                return list.fieldValue
                                    .split('~')[0]
                                    .concat(' ')
                                    .concat($translate.instant('common.people'));
                            } else {
                                return list.fieldValue;
                            }
                        };
                    },
                ],
                link: function ($scope) {
                    $scope.$watch('offerPart.name', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.commentByCreator', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.amount', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.amountType.name', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.dateTimeFrom', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.dateTimeTo', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.price', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.finalPaidOnsite', function () {
                        $scope.updateRowSummary();
                    });
                },
            };
        },
    ]);
})();
