(function () {
    'use strict';

    /**
     * Requires jquery.rateit.js extension.
     * Usage: <div og-rate-it survey="survey" callback="rated(survey)></div>
     * Or:    <div og-rate-it item-id="id" value="score" callback="rated(survey)"></div>
     */

    angular.module('ogRateIt').directive('ogRateIt', [
        '$log',
        '$timeout',
        function ($log, $timeout) {
            return {
                restrict: 'A',
                scope: {
                    callback: '&',
                    readonly: '=',
                    // either survey needs to be set
                    survey: '=',
                    // or id and value following
                    itemId: '=',
                    value: '=',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.ratedCallback = function (value) {
                            $timeout(function () {
                                var survey = $scope.survey;
                                if (survey) {
                                    survey.score = value;
                                } else {
                                    survey = { id: $scope.itemId, score: value };
                                }
                                $scope.callback({ survey: survey });
                            });
                        };
                    },
                ],
                link: function (scope, element, attrs) {
                    jQuery(element).rateit();
                    scope.$watch('survey', function () {
                        if (scope.survey) {
                            jQuery(element).rateit('value', scope.survey.score);
                            jQuery(element).rateit('step', 1);
                        }
                    });
                    scope.$watch('value', function () {
                        if (scope.value) {
                            jQuery(element).rateit('value', scope.value);
                        }
                    });
                    scope.$watch('readonly', function () {
                        if (scope.readonly) {
                            jQuery(element).rateit('ispreset', true);
                            jQuery(element).rateit('readonly', true);
                        }
                    });
                    jQuery(element).bind('rated', function () {
                        scope.ratedCallback(jQuery(element).rateit('value'));
                    });
                    jQuery(element).bind('reset', function () {
                        scope.ratedCallback(0);
                    });
                    jQuery(element).bind('over', function (event, value) {
                        jQuery(element).attr('title', value);
                    });
                    if (attrs.step) {
                        jQuery(element).rateit('step', attrs.step);
                    }
                    if (attrs.resetable === 'false') {
                        jQuery(element).rateit('resetable', false);
                    }
                },
            };
        },
    ]);
})();
