import { ScopeFunctions } from '../../../utils/global/scopeFunctions';

(function () {
    /* global angular */
    'use strict';
    angular.module('ogEventSummaryPrint').directive('ogEventSummaryPrint', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/event_summary_print/og_event_summary_print.view.html',
            replace: true,
            scope: {
                showMarkedPrice: '=',
                event: '=',
                decisionMaker: '=',
                eventCreator: '=',
                isPublic: '=',
                sharedPublicly: '=',
                requests: '=',
            },
            controllerAs: 'ogEventSummaryPrintCtrl',
            bindToController: true,
            controller: [
                '$scope',
                '$rootScope',
                'ClientService',
                'EventScheduleDataStore',
                '$sce',
                'DateService',
                'TimezoneService',
                'Event',
                'CommonFunctions',
                function (
                    $scope,
                    $rootScope,
                    ClientService,
                    EventScheduleDataStore,
                    $sce,
                    DateService,
                    TimezoneService,
                    Event,
                    CommonFunctions
                ) {
                    var vm = this;
                    this.$onInit = function () {
                        vm = this;

                        vm.init = initFn;
                        vm.formatClientName = formatClientNameFn;
                        vm.trustAsHtml = trustAsHtmlFn;
                        vm.parseDateInLocaleFormatWithoutTime = parseDateInLocaleFormatWithoutTimeFn;
                        vm.showMessageBodyForRecipients = false;
                        vm.isSummary = true;
                        vm.agentEnabled = vm.event && vm.event.ownerGroup && vm.event.ownerGroup.showMarkup;

                        ScopeFunctions.addToScope($scope);

                        vm.init();

                    }

                    function initFn() {
                        vm.types = ['CREATOR_PRESENTATION', 'PARTICIPANTS_PRESENTATION'];
                        // $scope.clientName = ClientService.getClientName();
                        // vm.formattedClientName = formatClientNameFn($scope.clientName);
                        vm.eventSchedule = EventScheduleDataStore.getSchedule();
                        getBookingTermsFn();
                    }

                    function formatClientNameFn(clientName) {
                        return CommonFunctions.formatClientName(clientName);
                    }

                    function trustAsHtmlFn(string) {
                        return $sce.trustAsHtml(string);
                    }

                    function parseDateInLocaleFormatWithoutTimeFn(date) {
                        return TimezoneService.formatDate(
                            TimezoneService.toLocalTime(date),
                            DateService.getDateFormatByLocale()
                        );
                    }

                    function getBookingTermsFn() {
                        Event.getBookingTerms(
                            vm.event.id,
                            (resp) => {
                                vm.bookingTerms = resp.bookingTerms;
                                console.log('booking terms:::', vm.bookingTerms);
                            },
                            $scope.endWithError
                        );
                    }
                },
            ],
        };
    });
})();
