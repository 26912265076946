'use strict';

angular.module('core.translation').service('TranslationService', [
    '$translate',
    function ($translate) {
        function translate(txt, code) {
            if (code) {
                let translatedText = $translate.instant(code);
                if (translatedText !== code) {
                    return translatedText;
                }
            }
            return txt;
        }

        return {
            translate: function (txt, code) {
                return translate(txt, code);
            },
        };
    },
]);
