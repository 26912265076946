/**
 * Created by sampurnachhantyal on 9/17/18.
 */
import alertModal from '../../modals/modals2.0/alert-modal';
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';
import {OgModal} from "../../../utils/global/ogModal";

class DesignForEventSiteCtrl {
    constructor(
        $scope,
        FieldChangedSvc,
        $rootScope,
        DateService,
        TimezoneService,
        $sce,
        $stateParams,
        Event,
        FileService,
        $location,
        Presentation,
        EventSite,
        ModalService,
        $translate,
        GlobalOption,
        ClientService
    ) {
        this._injected = {
            $scope,
            FieldChangedSvc,
            $rootScope,
            DateService,
            TimezoneService,
            $sce,
            $stateParams,
            Event,
            FileService,
            $location,
            Presentation,
            EventSite,
            ModalService,
            $translate,
            GlobalOption,
            ClientService,
        };

        this.hash = $stateParams.hash;
        $scope.$watch('DesignForEventInfoSiteCtrl.invitationDetails', (data) => {
            if (data) {
                this.eventId = data.eventId;
                this.getRegistrationExpiryDate();
                this.listAllFiles();
            }
        });
        this.itemType = undefined;
        ScopeFunctions.addToScope($scope);
        this.resetEditModes();
        this.loadSupportEmail();
        this.loadSupportPhone();
        this.clientName = ClientService.getClientDisplayName();
        this.lang = $rootScope.langSelected;
        this.listAllFiles = this.listAllFiles.bind(this);
        this.parseUploadResponse = this.parseUploadResponse.bind(this);
        this.deleteFileFn = this.deleteFileFn.bind(this);
    }

    resetEditModes() {
        this.edit = {
            description: false,
            alertChangeDescription: false,
        };
    }

    toggleEditMode(key) {
        this.edit[key] = !this.edit[key];
        this.edit['alertChangeDescription'] = this.edit['description'] && !!this.jsondata.description;
    }

    toggleAlertMode(key) {
        this.edit[key] = !this.edit[key];
    }

    getRegistrationExpiryDate() {
        const { DateService, Event } = this._injected;
        Event.getRegistrationExpiryDate(this.eventId, (data) => {
            if (data.registrationExpiryDate !== null) {
                return (this.registrationExpiryDate = DateService.parseDateInLocaleFormat(data.registrationExpiryDate));
            } else {
                return null;
            }
        });
    }

    uploadDescription() {
        const { Presentation, $scope } = this._injected;
        this.itemType = 'description';
        Presentation.uploadSiteInfoItem(
            this.jsondata.id,
            this.itemType,
            this.jsondata[this.itemType],
            this.parseUploadResponse,
            $scope.endWithError
        );
    }

    parseUploadResponse(response) {
        this.toggleEditMode(this.itemType);
        this.jsondata = response;
        this.itemType = undefined;
    }

    getFormattedDate(dateTime) {
        const { DateService } = this._injected;
        return DateService.extractLocalizedDateFromTimeStamp(dateTime);
    }

    getLocalizedDate(dateTime) {
        const { TimezoneService } = this._injected;
        return TimezoneService.toLocalTime(dateTime);
    }

    trustAsHtml(string) {
        const { $sce } = this._injected;
        return $sce.trustAsHtml(string);
    }

    getAlertMessage() {
        const { $translate } = this._injected;
        let alertMessage = $translate.instant('invitation.info.changed.alert.message1');
        return this.trustAsHtml(alertMessage);
    }

    getTimeFormat() {
        const { $rootScope } = this._injected;
        return $rootScope.langSelected === 'sv_SV' ? 'HH:mm' : 'hh:mm a';
    }

    listAllFiles() {
        const { FileService, $scope } = this._injected;
        FileService.listAllFiles(
            { eventId: this.eventId },
            (res) => {
                this.uploadedFiles = angular.copy(res.data);
            },
            $scope.endWithError
        );
    }

    fileUpload() {
        OgModal.open('file:upload', {
            types: ['*'],
            eventId: this.eventId,
            okCallBack: this.listAllFiles,
        });
    }

    downloadFile(url, filename) {
        const { FileService } = this._injected;
        FileService.downloadFile(url, filename);
    }

    deleteFile(id) {
        OgModal.open('confirmation', {
            message: 'default.button.delete.confirm.message',
            okCallBack: () => {
                this.deleteFileFn(id);
            },
        });
    }

    deleteFileFn(id) {
        const { $scope, EventSite } = this._injected;
        $scope.startProgress();
        EventSite['delete'](
            id,
            () => {
                $scope.endProgress();
                this.listAllFiles();
            },
            $scope.endWithErrorOverlay
        );
    }

    downloadAll() {
        const { FileService, $location } = this._injected;
        if (this.uploadedFiles.length === 0) return;
        let _url = '/api/v1/eventFileStore/downloadAllFiles/' + this.eventId;
        FileService.downloadFile(_url, 'eventlogic.zip');
    }

    loadSupportEmail() {
        const { GlobalOption, $scope, $rootScope } = this._injected;
        $scope.startProgress();
        GlobalOption.get(
            'SUPPORT_EMAIL',
            (resp) => {
                this.supportEmail = resp[$rootScope.langSelected]
                    ? resp[$rootScope.langSelected]
                    : resp['sv_SV']
                    ? resp['sv_SV']
                    : 'support@eventlogic.se';
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }

    loadSupportPhone() {
        const { GlobalOption, $scope, $rootScope } = this._injected;
        $scope.startProgress();
        GlobalOption.get(
            'SUPPORT_PHONE',
            (resp) => {
                this.supportPhone = resp[$rootScope.langSelected]
                    ? resp[$rootScope.langSelected]
                    : resp['sv_SV']
                    ? resp['sv_SV']
                    : '+46(0)31-83 20 20';
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }
}
DesignForEventSiteCtrl.$inject = [
    '$scope',
    'FieldChangedSvc',
    '$rootScope',
    'DateService',
    'TimezoneService',
    '$sce',
    '$stateParams',
    'Event',
    'FileService',
    '$location',
    'Presentation',
    'EventSite',
    'ModalService',
    '$translate',
    'GlobalOption',
    'ClientService',
];
export default DesignForEventSiteCtrl;
