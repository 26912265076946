import {ScopeFunctions} from "../../../../utils/global/scopeFunctions";
import {OgModal} from "../../../../utils/global/ogModal";
import {setLocalToken} from "../../../../utils/index";

'use strict';

angular
    .module('registerInvitation')
    .component('registerInvitation', {
        templateUrl: './app/components/modules/auth/register_invitation/register_invitation.view.html',
        controllerAs: 'invitationRegisterCtrl',
        controller: [
            '$scope',
            'AuthenticationService',
            '$http',
            '$stateParams',
            'ExternalAccessService',
            '$rootScope',
            'EventEnum',
            '$translate',
            'CustomerUser',

            function RegistrationInvitationController(
                $scope,
                AuthenticationService,
                $http,
                $stateParams,
                ExternalAccessService,
                $rootScope,
                EventEnum,
                $translate,
                CustomerUser
            ) {
                var vm = this;

                ScopeFunctions.addToScope($scope);

                vm.agreeTerms = false;
                vm.data = {};
                vm.hideChangedFieldIcon = true;
                vm.form = 'registrationForm';
                vm.registered = false;

                vm.passwordsNotMatch = passwordsNotMatchFn;
                vm.validatePassword = validatePasswordFn;
                vm.showPasswordStrength = showPasswordStrengthFn;
                vm.register = registerFn;
                vm.showTerms = showTermsFn;
                vm.switchToEn = switchToEnFn;
                vm.switchToSv = switchToSvFn;
                vm.switchToNb = switchToNbFn;
                vm.switchToDa = switchToDaFn;
                vm.switchToFi = switchToFiFn;
                vm.activeEn = $rootScope.langSelected === 'en_US' ? 'active' : '';
                vm.activeSv = $rootScope.langSelected === 'sv_SV' ? 'active' : '';
                vm.activeNb = $rootScope.langSelected === 'nb_NB' ? 'active' : '';
                vm.activeDk = $rootScope.langSelected === 'da_DK' ? 'active' : '';
                vm.activeFi = $rootScope.langSelected === 'fi_FI' ? 'active' : '';

                vm.data.hash = $stateParams.invitationId;
                vm.showPresentation = $stateParams.showPresentation;
                vm.generatedPassword = '';
                vm.getGeneratedPassword = getGeneratedPasswordFn;
                vm.fillPasswordFields = fillPasswordFieldsFn;
                $scope.startProgress(); // To check

                function passwordsNotMatchFn() {
                    return vm.data && (vm.data.password || vm.data.password2) && vm.data.password !== vm.data.password2;
                }

                function validatePasswordFn() {
                    if (!vm.data.password) {
                        return true;
                    }
                    let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)');
                    return regex.test(vm.data.password);
                }

                function showPasswordStrengthFn(password) {
                    if (password) {
                        //Uppercase Words only
                        if (password.match(/[A-Z]/) != null) {
                            jQuery('#uppercase-text .progress-text').addClass('active');
                        } else {
                            jQuery('#uppercase-text .progress-text').removeClass('active');
                        }
                        //Lowercase words only
                        if (password.match(/[a-z]/) != null) {
                            jQuery('#lowercase-text .progress-text').addClass('active');
                        } else {
                            jQuery('#lowercase-text .progress-text').removeClass('active');
                        }
                        //Digits only
                        if (password.match(/[0-9]/) != null) {
                            jQuery('#number-in-text .progress-text').addClass('active');
                        } else {
                            jQuery('#number-in-text .progress-text').removeClass('active');
                        }
                        //Password length
                        if (password.length > 7) {
                            jQuery('#eight-characters-long .progress-text').addClass('active');
                        } else {
                            jQuery('#eight-characters-long .progress-text').removeClass('active');
                        }
                    } else {
                        jQuery('.progress-bar-wrap .progress-bar-item .progress-text').each(function () {
                            jQuery(this).removeClass('active');
                        });
                    }
                }

                // fetch the suggested password and set to scope
                function getGeneratedPasswordFn() {
                    CustomerUser.getPassword(
                        function (result) {
                            vm.generatedPassword = result.generatedPassword;
                        },
                        function (error) {
                            console.log('>>> Error has occurred while getting generated password from API ' + error);
                        }
                    );
                }

                //fills the password with the suggested password
                function fillPasswordFieldsFn() {
                    vm.data.password = vm.generatedPassword;
                    vm.data.password2 = vm.generatedPassword;
                }

                function registerFn() {
                    if (vm.passwordsNotMatch() || !vm.validatePassword()) {
                        return;
                    }
                    $scope.startProgress();
                    $http
                        .post('api/v1/register', vm.data)
                        .then(function (response) {
                            $scope.endProgress();
                            vm.registered = true;
                            AuthenticationService.login(vm.data.email, vm.data.password).then(function (data) {
                                setLocalToken(data.access_token);
                                if (data && data.user && data.user.id) {
                                    var _data = {userData: data};
                                    var _userGroup = _data.userData.userGroup;
                                    _userGroup.showPresentation = vm.showPresentation;
                                    $rootScope.$broadcast(EventEnum.USER_GROUP_CHANGED, _data.userData.userGroup);
                                    $rootScope.$emit('event:user:action', {action: 'user.registered', data: _data});
                                } else {
                                    console.log('Not handled-001');
                                }
                            });
                            //TODO $rootScope.$emit( 'event:user:action', { action: 'user.registered', data: ... } );
                        }, $scope.endWithAlert);
                }

                function showTermsFn() {
                    var selectedLanguage = $rootScope.langSelected;
                    if (!selectedLanguage) {
                        selectedLanguage = $stateParams.locale === 'en' ? 'en_US' : 'sv_SV';
                    }
                    OgModal.open('user:terms', { langSelected: selectedLanguage });
                }

                function switchToEnFn() {
                    $rootScope.switchLang('en_US');
                    vm.activeEn = 'active';
                    vm.activeSv = '';
                    vm.activeNb = '';
                    vm.activeDk = '';
                    vm.activeFi = '';
                }

                function switchToSvFn() {
                    $rootScope.switchLang('sv_SV');
                    vm.activeSv = 'active';
                    vm.activeEn = '';
                    vm.activeNb = '';
                    vm.activeDk = '';
                    vm.activeFi = '';
                }

                function switchToNbFn() {
                    $rootScope.switchLang('nb_NB');
                    vm.activeSv = '';
                    vm.activeEn = '';
                    vm.activeNb = 'active';
                    vm.activeDk = '';
                    vm.activeFi = '';
                }

                function switchToDaFn() {
                    $rootScope.switchLang('da_DK');
                    vm.activeSv = '';
                    vm.activeEn = '';
                    vm.activeNb = '';
                    vm.activeDk = 'active';
                    vm.activeFi = '';
                }

                function switchToFiFn() {
                    $rootScope.switchLang('fi_FI');
                    vm.activeSv = '';
                    vm.activeEn = '';
                    vm.activeNb = '';
                    vm.activeDk = '';
                    vm.activeFi = 'active';
                }

                ExternalAccessService.verify(
                    $stateParams.invitationId,
                    vm.data,
                    function () {
                        $scope.endProgress();
                    },
                    $scope.endWithAlert
                );
            }
        ]

    });
