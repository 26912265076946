import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

class ogSupplierDefaultResourceModalForm {
    constructor($scope, $translate, $rootScope, SupplierResources, FileService, CommonFile) {
        this._injected = {
            $scope,
            $translate,
            $rootScope,
            SupplierResources,
            FileService,
            CommonFile,
        };
        this.setImageAndDescription = false;
        this.setImageSectionOpen = false;
        this.offerPartType = { name: '', nameCode: '' };
        ScopeFunctions.addToScope($scope);
        this.clearAll = false;
        this.attachedFiles = { uploads: null, links: null };

        this.remoteUrl = CommonFile.getBaseUrl() + 'api/v1/offerPartType';

        $scope.$watch('supplierDefaultResourceModalFormCtrl.resourceData', (newValue, oldValue) => {
            if (newValue) {
                this.resource = newValue;
                this.resourceName = this.resource.name;
                this.resourceDescription = this.resource.description;
                this.includes = this.resource.includes;
                this.resourceAttachments = this.resource.resourceAttachments;
                this.serviceAreas = this.resource.serviceAreas;
                this.offerPartType['nameCode'] = this.resource.offerPartTypeNameCode;
                if (this.offerPartType['nameCode']) {
                    this.offerPartType['name'] = $translate.instant(this.offerPartType['nameCode']);
                }
            }
        });
    }

    save() {
        const { $scope, SupplierResources, $translate, $rootScope } = this._injected;
        this.offerPartType['nameCode'] = this.resourceData.offerPartTypeNameCode;
        $scope.startProgress();
        let _params = {
            resourceId: this.resourceData.id,
            supplierId: this.supplierId,
            attachedFiles: this.attachedFiles.uploads,
            includes: this.includes,
            resourceName: this.resourceName,
            pricePerPerson: this.resourceData.pricePerPerson,
            currency: this.resourceData.currency,
            resourceStatus: this.resourceData.resourceStatus.name,
            offerPartTypeNameCode: this.offerPartType['nameCode'],
            resourceDescription: this.resourceDescription,
            serviceAreas: this.serviceAreas,
        };
        SupplierResources.updateSupplierResource(
            _params,
            (data) => {
                this.resourceName = data.name;
                this.resourceDescription = data.description;
                this.offerPartType['nameCode'] = data.offerPartTypeNameCode;
                if (this.offerPartType['nameCode']) {
                    this.offerPartType['name'] = $translate.instant(this.offerPartType['nameCode']);
                }
                this.clearAll = true;
                $rootScope.$broadcast('supplier:resource:updated', {
                    resourceId: data.id,
                    resourceName: data.name,
                    requestOfferPartOptionId: this.requestOfferPartOptionId,
                    requestOfferPartOptionDummyId: this.requestOfferPartOptionDummyId,
                    isResourceUpdated: data.isResourceUpdated,
                    isResourcePublic: data.isResourcePublic,
                });
                this.goBack();
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    deleteAttachment(currentAttachmentId) {
        const { $scope, SupplierResources } = this._injected;
        $scope.startProgress();
        var data = {
            resourceId: this.resourceData.id,
            currentAttachmentId: currentAttachmentId,
            supplierId: this.supplierId,
        };
        SupplierResources.deleteAttachment(
            data,
            (response) => {
                this.resourceAttachments = response.resourceAttachments;
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    downloadAttachment(url, filename) {
        const { FileService } = this._injected;
        FileService.downloadFile(url, filename);
    }

    updateSelectedPartName(name, nameCode) {
        this.offerPartType['name'] = name;
        this.offerPartType['nameCode'] = nameCode;
    }

    goBack() {
        this.closeCallback();
    }

    imageSectionOpen(res) {
        this.setImageSectionOpen = res;
    }

    updateResourceFile(formData) {
        const { SupplierResources, $scope } = this._injected;
        formData.append('supplierId', this.supplierId);
        SupplierResources.updateResourceImage(
            formData,
            (data) => {
                this.showImageCropSection = false;
                this.resource.resourceImages = data.resourceImages;
                // this.refreshImage();
                this.imageSectionOpen({ isImgAddOpen: false });
            },
            $scope.endWithErrorOverlay
        );
    }

    refreshImage() {
        let oldImage = this.resource.resourceImages[this.targetImage];
        let newImage = this.resource.resourceImages ? _.values(this.resource.resourceImages)[0] : null;
        this.updateCurrentImg(oldImage, newImage);
    }

    updateCurrentImg(oldImageId, newImageId) {
        if (this.currentImg === oldImageId || oldImageId === undefined) {
            this.currentImg = newImageId;
        }
    }

    resetCropImageSection() {
        this.targetImage = null;
        this.targetImageUrl = null;
        this.showImageCropSection = false;
        this.imageSectionOpen({ isImgAddOpen: false });
    }
}
ogSupplierDefaultResourceModalForm.$inject = [
    '$scope',
    '$translate',
    '$rootScope',
    'SupplierResources',
    'FileService',
    'CommonFile',
];
export default ogSupplierDefaultResourceModalForm;
