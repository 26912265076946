'use strict';

import { ScopeFunctions } from '../../../../../utils/global/scopeFunctions';
import EventsHome from '../events.component';

// Register `List` component, along with its associated controller and template
angular.module('list').component('list', {
    templateUrl: './app/components/modules/event/events/list/home.template.html',
    controllerAs: 'eventListCtrl',
    require: {
        parentCtrl: '^events'
    },
    controller: [
        '$scope',
        function EventListController(
            $scope
        ) {
            var vm = this;

            vm.filteredEvents = [];
            vm.currentPage = 1;
            vm.page = 0;
            vm.searchResult = false;
            vm.showClear = false;
            vm.totalCount = 0;
            vm.params = {};
            vm.gridView = true;
            vm.numPerPage = 6;
            vm.listView = true;

            this.$onInit = function () {
                vm.eventsCtrl = vm.parentCtrl;
            };

            ScopeFunctions.addToScope($scope);
        }
    ]
});
