/**
 * Created by sampurnachhantyal on 9/7/17.
 */

import _ from 'underscore';

class GoogleAddressUtil {
    constructor() {
        this.parseGoogleAddress = this.parseGoogleAddress.bind(this);
    }

    prepareGeocodeParameters(address) {
        if (!address) {
            return '';
        }
        let _result = '',
            _displayAddress = '',
            _b,
            _pref = '';
        if (address.displayAddress) {
            _displayAddress = address.displayAddress;
            _b = _displayAddress.split(/[, ]+/);
        }
        if (address.town) {
            _result += ' ' + address.town;
        }
        if (address.municipality) {
            _result += ' ' + address.municipality;
        }
        if (address.state) {
            _result += ' ' + address.state;
        }
        if (address.country) {
            _result += ' ' + address.country;
        }
        let _a = _result.split(/[, ]+/);
        if (_b) {
            _pref = this.union(_a, _b);
        }
        let _res = _pref ? _pref + _a.join(' ') : _a.join(' ');
        return _res;
    }

    //Get union from two list of arrays a = [stockholm, gotaland, gothenburg], b = [stockholm, sweden] , result = [stockholm, gotaland, gothenburg, sweden]
    union(a, b) {
        let _v = '';
        _.each(b, (t) => {
            let _x = a
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            let _y = t
                .toString()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            if (!_.contains(_x, _y)) {
                _v = _v + ' ' + _y;
            }
        });
        return _v;
    }

    parseGoogleAddress(place) {
        let _address = place.address_components;
        let _parsedAddress = {};
        if (_address) {
            let _street = _.find(_address, (comp) => {
                return comp.types[0] === 'street_number';
            });
            _parsedAddress.street = _street ? _street.long_name : undefined;

            let route = _.find(_address, (comp) => {
                return comp.types[0] === 'route';
            });
            _parsedAddress.route = route ? route.long_name : undefined;

            let locality = _.find(_address, (comp) => {
                return comp.types[0] === 'locality' || comp.types[0] === 'postal_town';
            });
            _parsedAddress.town = locality ? locality.long_name : undefined;

            let administrative_area_level_1 = _.find(_address, (comp) => {
                return comp.types[0] === 'administrative_area_level_1';
            });
            _parsedAddress.state = administrative_area_level_1 ? administrative_area_level_1.long_name : undefined;

            let administrative_area_level_2 = _.find(_address, (comp) => {
                return comp.types[0] === 'administrative_area_level_2';
            });
            _parsedAddress.municipality = administrative_area_level_2
                ? administrative_area_level_2.long_name
                : undefined;

            let country = _.find(_address, (comp) => {
                return comp.types[0] === 'country';
            });
            _parsedAddress.country = country ? country.long_name : undefined;

            let postal_code = _.find(_address, (comp) => {
                return comp.types[0] === 'postal_code';
            });
            _parsedAddress.zipCode = postal_code ? postal_code.long_name : undefined;
        }
        if (place.geometry) {
            _parsedAddress.geoCoordinates = place.geometry.location.lat() + ',' + place.geometry.location.lng();
            _parsedAddress.latitude = place.geometry.location.lat();
            _parsedAddress.longitude = place.geometry.location.lng();
        }
        if (_parsedAddress.route) {
            _parsedAddress.streetAddress = _parsedAddress.route;
            if (_parsedAddress.street) {
                _parsedAddress.streetAddress = _parsedAddress.streetAddress + ' ' + _parsedAddress.street;
            }
        }
        return _parsedAddress;
    }

    deAccentSearchText(text) {
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    getCorrectedDisplayAddressManually(displayAddress) {
        let _displayAddress = this.deAccentSearchText(displayAddress)
            .replaceAll(' ', '')
            .replaceAll(',', '')
            .toLowerCase();
        if (['bastad', 'bastadsweden', 'bastadsverige'].indexOf(_displayAddress) > -1) {
            _displayAddress = 'Båstad Municipality, Sweden';
        } else if (['are', 'aresweden', 'aresverige'].indexOf(_displayAddress) > -1) {
            _displayAddress = 'Åre Jamtland County Sweden';
        } else {
            _displayAddress = displayAddress;
        }
        return _displayAddress;
    }

    prepareDistanceCalRequest(filledCommentList) {
        const fromField = _.find(filledCommentList, (q) => {
            return q.label === 'From';
        });
        const viaField = _.find(filledCommentList, (q) => {
            return q.label === 'Via';
        });
        const toField = _.find(filledCommentList, (q) => {
            return q.label === 'To';
        });
        const from = fromField.fieldValue ? fromField.fieldValue : '-';
        const via = viaField.fieldValue ? viaField.fieldValue : '-';
        const to = toField.fieldValue ? toField.fieldValue : '-';
        return {
            origins: [from, via],
            destinations: [via, to],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
        };
    }

    parseAddressesDistance(response) {
        let _parsedDistance = null;
        let from_via = response.rows[0].elements[0];
        let from_to = response.rows[0].elements[1];
        // let via_via = response.rows[1].elements[0]
        let via_to = response.rows[1].elements[1];
        if (from_via.status === 'OK' && via_to.status === 'OK') {
            _parsedDistance = from_via.distance.value + via_to.distance.value;
        } else if (from_to.status === 'OK') {
            _parsedDistance = from_to.distance.value;
        }
        return _parsedDistance;
    }

    findPlaceFromResults(results) {
        let _place = null;
        if (results) {
            _place = _.find(results, (o) => {
                return (_.findIndex(o.types, (t) => {
                    return (t === 'administrative_area_level_1')
                }) > -1)
            });
            if (!_place) {
                _place = _.find(results, (o) => {
                    return (_.findIndex(o.types, (t) => {
                        return (t === 'administrative_area_level_2')
                    }) > -1)
                });
            }
            _place = _place ? _place : results [0];
        }
        return _place;
    }
}

const googleAddressUtil = new GoogleAddressUtil();
export default googleAddressUtil;
