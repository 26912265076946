import amountAndPriceService from '../../../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import { ScopeFunctions } from "../../../../../../utils/global/scopeFunctions";
import _ from 'underscore';

(function () {
    'use strict';
    angular.module('markup').component('markup', {
        templateUrl: './app/components/modules/event/event_detail/budget/markup/markup.view.html',
        controllerAs: 'markupCtrl',
        controller: [
            '$scope',
            '$stateParams',
            'Event',
            '$rootScope',
            'EventEnum',
            'BudgetDataShareService',
            function MarkupController(
                $scope,
                $stateParams,
                Event,
                $rootScope,
                EventEnum,
                BudgetDataShareService
            ) {
                ScopeFunctions.addToScope($scope);
                let vm = this;
                var eventId = $stateParams.eventId;
                vm.init = initFn;
                vm.showSetEventMarkupValue = false;
                vm.totalPriceForBudgetByCurrencyArr = false;
                vm.updateEventMarkupValue = updateEventMarkupValueFn;
                vm.setEventMarkupValue = setEventMarkupValueFn;
                vm.getTotalPriceForBudgetByCurrency = getTotalPriceForBudgetByCurrencyFn;
                vm.getAmountLocalisedWithCurrency = amountAndPriceService.getAmountLocalisedWithCurrency;
                vm.getMarkupFromTotalPriceAndCustomerPrice = amountAndPriceService.getMarkupFromPriceAndCustomerPrice;
                vm.getMarkupFromPriceAndCustomerPriceWithCurrency =
                    amountAndPriceService.getMarkupFromPriceAndCustomerPriceWithCurrency;

                vm.init();

                function initFn() {
                    $scope.startProgress('markup-table');
                    Event.getEventMarkupDetails(
                        eventId,
                        function (response) {
                            vm.event = response;
                            vm.event.markupValue = parseFloat(vm.event.markupValue);
                            vm.requests = vm.event.requests;
                            vm.selectedCurrencySymbol = vm.requests[0].requestOffers[0].currency.symbol;
                            $scope.endProgress('markup-table');
                        },
                        $scope.endWithError()
                    );
                }

                function updateEventMarkupValueFn() {
                    $scope.startProgress('markup-table');
                    let params = {
                        markupValue: vm.event.markupValue,
                    };
                    Event.updateEventMarkupValue(
                        eventId,
                        params,
                        function (response) {
                            vm.showSetEventMarkupValue = false;
                            vm.event = response;
                            vm.event.markupValue = parseFloat(vm.event.markupValue);
                            vm.requests = vm.event.requests;
                            $scope.endProgress('markup-table');
                        },
                        $scope.endWithError()
                    );
                }

                function setEventMarkupValueFn() {
                    vm.showSetEventMarkupValue = !vm.showSetEventMarkupValue;
                }

                function getTotalPriceForBudgetByCurrencyFn() {
                    if (BudgetDataShareService.getTotalPriceForBudgetByCurrency()) {
                        vm.totalPriceForBudgetByCurrencyArr = true;
                    }
                    return BudgetDataShareService.getTotalPriceForBudgetByCurrency();
                }

                const requestChangedBgt = $rootScope.$on(EventEnum.REQUEST_CHANGED_IN_BUDGET, function (evt, data) {
                    let _requests = angular.copy(vm.requests);
                    _.extend(_.findWhere(_requests, { id: data.request.id }), data.request);
                    vm.requests = _requests;
                });

                $scope.$on('$destroy', requestChangedBgt);
            }
        ]
    });
})();
