'use strict';
import {OgModal} from "../../utils/global/ogModal";

angular.module('dialog').directive('ogNewRequestSwitchModal', [
    function () {
        return new OgModal({
            name: 'new:request:switch',
            type: 'custom',
            controller: function ($scope) {
                $scope.initDialog = function (params) {
                    $scope.params = params;
                };

                $scope.useTemplate = function () {
                        $scope.close();
                        $scope.params.templateCallback();
                    };

                    $scope.newRequest = function () {
                        $scope.close();
                        $scope.params.newRequestCallback();
                    };
                },
            });
        },
    ]);

