import controller from './og_supplier_default_resource_modal_form.controller';

(function () {
    'use strict';
    angular.module('ogSupplierDefaultResourceModalForm').directive('ogSupplierDefaultResourceModalForm', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    supplierId: '=',
                    resourceData: '=',
                    showPartName: '=',
                    closeCallback: '&',
                    editResource: '=',
                    requestOfferPartOptionId: '=',
                    requestOfferPartOptionDummyId: '=',
                    resourcePresentationClass: '=',
                },
                templateUrl:
                    './app/components/directives/supplier_default_resource_modal_form/og_supplier_default_resource_modal_form.view.html',
                controller,
                controllerAs: 'supplierDefaultResourceModalFormCtrl',
                bindToController: true,
            };
        },
    ]);
})();
