import { ScopeFunctions } from "../../../../utils/global/scopeFunctions";
import _ from 'underscore';

const INITIAL_USER_GROUP_SIZE = 10;
const USER_GROUP_SEARCH_SIZE = 10;
class ProfileSettings {
    constructor(
        $rootScope,
        $scope,
        data,
        close,
        $element,
        $location,
        $timeout,
        $translate,
        FieldChangedSvc,
        EventEnum,
        AuthenticationService,
        Event,
        CustomerUser,
        UserGroup,
        $window,
        CommonFunctions
    ) {
        this._injected = {
            $rootScope,
            $scope,
            data,
            close,
            $element,
            $location,
            $timeout,
            $translate,
            FieldChangedSvc,
            EventEnum,
            AuthenticationService,
            Event,
            CustomerUser,
            UserGroup,
            $window,
            CommonFunctions
        };

        ScopeFunctions.addToScope($scope);

        this.downArrow = false;
        this.rightArrow = true;
        this.triggerGroupSetting = false;
        this.showDeleteUserGroupConfirmation = false;
        this.sortedUserGroups = [];
        this.selectedGroupId = data.selectedGroupId;
        $scope.deleteMessage = { type: null, key: null };
        this.openPresentationByDefault = !!data.openPresentationByDefault;
        this.openGroupMembersTab = data.openGroupMembersTab;
        this.focusOnEmailField = data.focusOnEmailField;
        this.centralBooking = data.centralBooking;
        this.supplierUserGroup = data.supplierUserGroup;
        this.getCurrentCustomerProfile();
        this.UserCredentialsDialog = {};
        this.searchParam = {
            max: 10,
            offset: 0,
        };
        this.viewPresentation = false;
        this.resizeModalBodyHeight();

        let resizeBodyHeight = function () {
            this.resizeModalBodyHeight();
        }.bind(this);

        var onHeaderLoaded = $scope.$watch('profileSettingController.headerLoaded()', function (newValue) {
            if (newValue) {
                resizeBodyHeight();
                onHeaderLoaded();
            }
        });

        const supplierPresentationAddressChanged = $rootScope.$on(
            'supplier:presentation:address:changed',
            function (event, data) {
                this.selectedUserGroup.supplier.officeAddress.displayAddress = data.officeAddress.displayAddress;
                this.selectedUserGroup.supplier.presentation.mapUrl = data.presentation.mapUrl;
            }.bind(this)
        );
        $scope.$on('$destroy', supplierPresentationAddressChanged);

        const getSupplierPresentation = $rootScope.$on(
            'get:supplier:presentation',
            function (event, data) {
                this.selectedUserGroup.supplier.presentation = data;
            }.bind(this)
        );
        $scope.$on('$destroy', getSupplierPresentation);

        angular.element($window).bind('resize', resizeBodyHeight);
        //unbind the resize listener
        $scope.$on('$destroy', function () {
            angular.element($window).unbind('resize', resizeBodyHeight);
        });

        var userGroupCreateUpdateEvent = $rootScope.$on('user.group.create.update.success', (event, data) => {
            if (data && this.selectedUserGroup.name !== data.name) {
                this.selectedUserGroup.name = data.name;
            }
        });
        $scope.$on('$destroy', userGroupCreateUpdateEvent);
    }

    credentialsUpdateAllowed() {
        if (this.inProgress) {
            return false;
        }
        if (!this.data || !this.originalData || !this.validatePassword()) {
            return false;
        }
        if (this.data && this.data.newPassword) {
            return !this.passwordMissing() && !this.passwordsNotMatch();
        }
    }

    changeSelectedUserGroup(group) {
        this.updateSelectedUserGroup(group);
        this.defaultGroupTabOpen = 'GROUP';
    }

    toggleUserGroupExpanded(group) {
        group.expanded = !group.expanded;
        group.showLoadMoreChild = false;
        group.childrenUserGroups = [];
        if (group.expanded) {
            const { UserGroup } = this._injected;
            let params = {
                groupId: group.id,
                offset: 0,
                max: USER_GROUP_SEARCH_SIZE,
            };
            UserGroup.getChildren(params, (response) => {
                group.childrenUserGroups = response.childGroupList;
                group.showLoadMoreChild = response.childGroupTotal > group.childrenUserGroups.length;
            });
        }
    }

    sortUserGroups(userGroups) {
        const { $translate } = this._injected;
        if (userGroups) {
            let _userGroupsWithoutPrivateGroup = _.filter(userGroups, (item) => {
                return item && item.name;
            });
            _userGroupsWithoutPrivateGroup = _.sortBy(_userGroupsWithoutPrivateGroup, (o) => {
                o.deletable = true;
                return o.name;
            });

            let _privateGroup = _.find(userGroups, (g) => {
                return !g.name;
            });
            if (_privateGroup) {
                _privateGroup.name = $translate.instant('user.group.events.shared.with.me');
                _privateGroup.deletable = false;
                _userGroupsWithoutPrivateGroup.splice(0, 0, _privateGroup);
            }
            return _userGroupsWithoutPrivateGroup;
        }
        return [];
    }

    userDataChanged() {
        let hasOriginalData = this.originalData;
        if (hasOriginalData) {
            let hasFirstName = this.data.firstName;
            let hasLastName = this.data.lastName;
            let hasFirstNameChanged = this.originalData.firstName !== this.data.firstName;
            let hasLastNameChanged = this.originalData.lastName !== this.data.lastName;
            let hasEmail = this.data.email;
            let hasEmailChanged = this.originalData.email !== this.data.email;
            let hasPhoneChanged = this.originalData.phone !== this.data.phone;
            // let hasDailyDigestChanged = this.originalData.dailyDigest !== this.data.dailyDigest;
            let hasActivityUpdatesChanged = this.originalData.activityUpdates !== this.data.activityUpdates;
            return (
                hasEmail &&
                hasFirstName &&
                hasLastName &&
                (hasFirstNameChanged ||
                    hasLastNameChanged ||
                    hasEmailChanged ||
                    hasPhoneChanged ||
                    hasActivityUpdatesChanged)
            );
        }
        return false;
    }

    generalInfo() {
        this.generalInformationView = true;
        this.passwordChangeView = false;
        this.myGroupsView = false;
        this.showPresentation = false;
        this.defaultGroupTabOpen = null;
        this.errorSuccessMessages = null;
        this.data.password = null;
        this.data.newPassword = null;
        this.data.newPasswordRepeat = null;
    }

    changePass() {
        this.generalInformationView = false;
        this.passwordChangeView = true;
        this.myGroupsView = false;
        this.showPresentation = false;
        this.defaultGroupTabOpen = null;
    }

    myGroup() {
        this.generalInformationView = false;
        this.passwordChangeView = false;
        this.myGroupsView = true;
        this.showPresentation = false;
        this.errorSuccessMessages = null;
        this.data.password = null;
        this.data.newPassword = null;
        this.data.newPasswordRepeat = null;
    }

    getCurrentCustomerProfile() {
        const { $scope, CustomerUser } = this._injected;
        $scope.startProgress();
        CustomerUser.getCurrentCustomerProfile(
            {},
            function (data) {
                this.loadData(data);
                $scope.endProgress();
            }.bind(this),
            $scope.endWithErrorOverlay()
        );
    }

    loadData(data) {
        this.data = angular.copy(data);
        this.originalData = angular.copy(data);
        this.totalUGResult = data.totalUserGroupsCount;
        this.showLoadMore = this.totalUGResult > INITIAL_USER_GROUP_SIZE;
        this.sortedUserGroups = this.sortUserGroups(data.userGroups);
        if (this.selectedGroupId) {
            this.myGroup();
            let _selectedGroup = _.find(this.sortedUserGroups, (group) => {
                return group.id === parseInt(this.selectedGroupId);
            });
            if (!_selectedGroup) {
                const { $scope, UserGroup } = this._injected;
                UserGroup.get(
                    this.selectedGroupId,
                    (res) => {
                        this.updateSelectedUserGroup(res);
                    },
                    $scope.endWithErrorOverlay
                );
            } else {
                this.updateSelectedUserGroup(_selectedGroup);
            }
        } else if (this.centralBooking) {
            this.myGroup();
            let _selectedGroup = this.supplierUserGroup;
            this.updateSelectedUserGroup(_selectedGroup);
        } else {
            this.generalInfo();
            let _selectedGroup = _.find(this.sortedUserGroups, (group) => {
                return group.id === parseInt(this.data.selectedUserGroup.id);
            });
            this.updateSelectedUserGroup(_selectedGroup);
        }
        if (this.openPresentationByDefault) {
            this.openPresentation();
            this.openPresentationByDefault = false;
        }

        if (this.openGroupMembersTab) {
            this.defaultGroupTabOpen = 'GROUP';
            this.myGroup();
            this.openGroupMembersTab = false;
        }
        let _expandParentUserGroup = _.find(data.userGroups, function (item) {
            if (item.childrenUserGroups.length > 0) {
                let _expandChildUserGroup = _.find(item.childrenUserGroups, function (subItem) {
                    return subItem.id === data.selectedUserGroup.id;
                });
                if (_expandChildUserGroup) {
                    return item.id;
                }
            }
        });
        if (_expandParentUserGroup) {
            this.toggleUserGroupExpanded(_expandParentUserGroup);
        }
    }

    loadMore() {
        const { UserGroup } = this._injected;
        let offset = this.sortedUserGroups.length ? this.sortedUserGroups.length : 0;
        if (offset >= this.totalUGResult) {
            return;
        }

        let params = {
            userId: this.data.id,
            offset: offset,
            max: USER_GROUP_SEARCH_SIZE,
        };

        UserGroup.searchUserGroups(params, (response) => {
            this.searchParam.max = response.max;
            this.searchParam.offset = response.offset;
            let _moreGroups = this.sortUserGroups(response.refUserGroupList);
            this.sortedUserGroups = [...this.sortedUserGroups, ..._moreGroups];
            this.showLoadMore = this.totalUGResult > this.sortedUserGroups.length;
        });
    }

    loadMoreChildren(group) {
        console.log(group, 'group');
        const { UserGroup } = this._injected;
        let offset = group.childrenUserGroups.length ? group.childrenUserGroups.length : 0;
        let params = {
            groupId: group.id,
            offset: offset,
            max: USER_GROUP_SEARCH_SIZE,
        };
        UserGroup.getChildren(params, (response) => {
            console.log(response, 'response');
            angular.forEach(response.childGroupList, (userGroup) => {
                group.childrenUserGroups.push(userGroup);
            });
            group.showLoadMoreChild = response.childGroupTotal > group.childrenUserGroups.length;
        });
    }

    openImageCropDialog() {
        OgModal.open('image:crop', { data: this.data });
    }

    openPresentation() {
        let _selectedGroup = _.find(this.sortedUserGroups, (group) => {
            return group.id === parseInt(this.data.selectedUserGroup.id);
        });
        if (this.defaultGroupTabOpen === 'PRESENTATION') {
            return;
        }
        this.defaultGroupTabOpen = 'PRESENTATION';
        this.myGroup();
        this.updateSelectedUserGroup(_selectedGroup);
    }
    passwordMissing() {
        return this.data && !this.data.password;
    }

    validatePassword() {
        if (!this.data.newPassword) {
            return true;
        }
        let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)');
        return regex.test(this.data.newPassword);
    }

    showPasswordStrength(password) {
        if (password) {
            //Uppercase Words only
            if (password.match(/[A-Z]/) != null) {
                jQuery('#uppercase-text .progress-text').addClass('active');
            } else {
                jQuery('#uppercase-text .progress-text').removeClass('active');
            }
            //Lowercase words only
            if (password.match(/[a-z]/) != null) {
                jQuery('#lowercase-text .progress-text').addClass('active');
            } else {
                jQuery('#lowercase-text .progress-text').removeClass('active');
            }
            //Digits only
            if (password.match(/[0-9]/) != null) {
                jQuery('#number-in-text .progress-text').addClass('active');
            } else {
                jQuery('#number-in-text .progress-text').removeClass('active');
            }
            //Password length
            if (password.length > 7) {
                jQuery('#eight-characters-long .progress-text').addClass('active');
            } else {
                jQuery('#eight-characters-long .progress-text').removeClass('active');
            }
        } else {
            jQuery('.progress-bar-wrap .progress-bar-item .progress-text').each(function () {
                jQuery(this).removeClass('active');
            });
        }
    }

    checkSupplier() {
        const { AuthenticationService } = this._injected;
        this.userData = AuthenticationService.getUserData();
        if (this.userData && this.userData.userGroup && this.userData.userGroup.supplier) {
            return true;
        }
        return false;
    }

    passwordsNotMatch() {
        if (this.data && this.data.newPassword) {
            return this.data.newPassword != this.data.newPasswordRepeat;
        }
    }

    updateGeneralInfoOnly() {
        if (!this.profileDetail.$valid) {
            return;
        }
        this.data.generalInfoOnly = true;
        this.data.credentialsOnly = false;
        this.updateUserProfile();
    }

    updateCredentialsOnly() {
        this.data.credentialsOnly = true;
        this.data.generalInfoOnly = false;
        this.updateUserProfile();
    }

    updateSelectedUserGroup(userGroup, createNewGroup = false) {
        this.selectedUserGroup = userGroup;
        this.downArrow = true;
        this.rightArrow = false;
        this.createNewGroup = createNewGroup;
        ProfileSettings.setGroupMembersAsSelectedTab();
    }

    static setGroupMembersAsSelectedTab() {
        jQuery('.groupTabs>ul>li.active').removeClass('active');
        jQuery('.groupTabs>ul>li#group_members_tab_header').addClass('active');

        jQuery('.groupTabsContent>div.active').removeClass('active');
        jQuery('.groupTabsContent>div#group_members').addClass('active');
        jQuery('.collapse').collapse('show');
    }

    createGroupTab() {
        this.updateSelectedUserGroup({}, true);
    }

    setShowDeleteUserGroupConfirmation() {
        this.showDeleteUserGroupConfirmation = true;
    }

    resetShowDeleteUserGroupConfirmation() {
        this.showDeleteUserGroupConfirmation = false;
    }

    resetDeleteMessage() {
        const { $scope } = this._injected;
        $scope.deleteMessage = { type: null, key: null };
    }

    removeUserGroup() {
        const { $scope, UserGroup } = this._injected;
        $scope.startProgress();
        UserGroup.delete(
            this.selectedUserGroup.id,
            ((data) => {
                this.selectedGroupId = null;
                this.loadData(data);
                this.resetShowDeleteUserGroupConfirmation();
                $scope.endProgress();
            }).bind(this),
            $scope.endWithErrorOverlay()
        );
    }

    handleOnCreateNewGroup(userGroup) {
        this.selectedGroupId = userGroup.id;
        this.getCurrentCustomerProfile();
    }

    handleOnUpdateGroup(userGroup) {
        let updatedUserGroupIndex = _.findIndex(this.sortedUserGroups, { id: userGroup.id });
        this.sortedUserGroups.splice(updatedUserGroupIndex, 1, userGroup);
    }

    handleOnViewPresentation(displaySupplierDetailsTab) {
        this.viewPresentation = true;
        this.displaySupplierDetailsTab = displaySupplierDetailsTab;
    }

    updateUserProfile() {
        this.errorSuccessMessages = [];
        const { $scope, CustomerUser, CommonFunctions } = this._injected;
        $scope.startProgress();
        CustomerUser.updateUserProfile(
            this.data,
            (data) => {
                let _successMsg = {};
                _successMsg.messageKey = 'event.presentation.password.updated';
                _successMsg.type = 'SUCCESS';
                this.errorSuccessMessages.push(_successMsg);
                this.originalData = angular.copy(this.data);
                if (this.data.credentialsOnly) {
                    this.data.password = null;
                    this.data.newPassword = null;
                    this.data.newPasswordRepeat = null;
                    this.UserCredentialsDialog.$setPristine();
                    this.UserCredentialsDialog.$setUntouched();
                }
                $scope.endProgress();
            },
            (err) => {
                if (this.data.credentialsOnly) {
                    this.data.password = null;
                    this.data.newPassword = null;
                    this.data.newPasswordRepeat = null;
                    this.UserCredentialsDialog.$setPristine();
                    this.UserCredentialsDialog.$setUntouched();
                }
                this.errorSuccessMessages = CommonFunctions.errorMessages(err, true);
                $scope.endProgress();
            }
        );
    }

    updateProfilePictureCallback(formData) {
        const { $rootScope, $scope, CustomerUser, EventEnum } = this._injected;
        CustomerUser.updateUserProfilePicture(
            formData,
            function (data) {
                this.showImageCropSection = false;
                $rootScope.$broadcast(EventEnum.USER_PROFILE_PICTURE_CHANGED, data);
            }.bind(this),
            $scope.endWithErrorOverlay()
        );
    }

    successCallBack() {
        this.close();
    }

    headerLoaded() {
        const { $element } = this._injected;
        var headerElement = $element.find('#profile-settings-modal-header');
        return headerElement && headerElement.innerHeight() > 0 && true;
    }

    resizeModalBodyHeight(delayTime = 0) {
        const { $window, $element, $timeout } = this._injected;
        $timeout(function () {
            let windowHeight = $window.innerHeight;
            let modalHeader = $element.find('#profile-settings-modal-header');
            // we need to keep margin on top and bottom section, Also see select-supplier-modal.css for top-margin
            //https://app.asana.com/0/14368931590528/292465599612174
            let totalMarginForTopAndBottom = 24;
            let bodyHeight = windowHeight - modalHeader.innerHeight() - totalMarginForTopAndBottom;
            let modalBody = $element.find('#profile-settings-modal-body');
            modalBody.css('height', bodyHeight + 'px');
        }, delayTime);
    }

    // fetch the suggested password and set to scope
    getGeneratedPassword() {
        const { $scope, CustomerUser } = this._injected;
        CustomerUser.getPassword(
            function (result) {
                $scope.generatedPassword = result.generatedPassword;
            },
            function (error) {
                console.log('>>> Error has occurred while getting generated password from API ' + error);
            }
        );
    }

    //fills the password with the suggested
    fillPasswordFields() {
        const { $scope } = this._injected;
        this.data.newPassword = $scope.generatedPassword;
        this.data.newPasswordRepeat = $scope.generatedPassword;
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

ProfileSettings.$inject = [
    '$rootScope',
    '$scope',
    'data',
    'close',
    '$element',
    '$location',
    '$timeout',
    '$translate',
    'FieldChangedSvc',
    'EventEnum',
    'AuthenticationService',
    'Event',
    'CustomerUser',
    'UserGroup',
    '$window',
    'CommonFunctions'
];
export default ProfileSettings;
