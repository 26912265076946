/**
 * Created by Amol on 6/22/2021.
 */
import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import { DiacriticsUtil } from '../../../../utils/global/DiacriticsUtil';
import CommonFunctions from '../../../../utils/CommonFunctionUtil';
import _ from 'underscore';

class EventSummaryShareModalCtrl {
    constructor(
        $scope,
        $rootScope,
        $element,
        data,
        $log,
        Presentation,
        close,
        CommonPresentation,
        AuthenticationService,
        EventSummary,
        EventSite,
        EventEnum,
        UserGroupSettings
    ) {
        this._injected = {
            $scope,
            $rootScope,
            $element,
            data,
            $log,
            Presentation,
            close,
            CommonPresentation,
            AuthenticationService,
            EventSummary,
            EventSite,
            EventEnum,
            UserGroupSettings,
        };
        ScopeFunctions.addToScope($scope);
        this.sentKey = 'SENT';
        this.sentUpdateKey = 'SENT_UPDATE';

        this.sharedUserLists = data.sharedUserLists;
        this.eventId = data.eventId;
        this.acceptedDate = data.acceptedDate;
        this.type = data.type;
        this.currentDecisionMaker = data.currentDecisionMaker;
        this.sharedSuccessMessage = false;
        this.newDecisionMaker = false;
        this.emailLength = [1];
        this.emailList = [];
        this.emailListWithDecisionMaker = [];
        this.removeSharedUserConfirmation = false;
        this.enableAgent = AuthenticationService.getUserData().userGroup.showMarkup;
        this.initFn();

        this.languageSelected = $rootScope.langSelected;
        $scope.$on(
            'lang:change',
            function (evt, data) {
                this.languageSelected = data.lang;
            }.bind(this)
        );
    }

    switchLanguage(lang) {
        this.recipientLangRequired = false;
        this.recipientLang = lang;
    }

    parseBoolean(bool) {
        return String(bool) === 'true';
    }

    initFn() {
        const { EventSite, $scope, $rootScope, AuthenticationService, UserGroupSettings } = this._injected;
        let initData = { eventId: this.eventId, type: this.type };
        let _userGroupId = AuthenticationService.getUserData().userGroup.id;
        $scope.startProgress();
        UserGroupSettings.get(
            { type: 'EVENT_SUMMARY_FIELD_REQUIRED', userGroupId: _userGroupId },
            (resp) => {
                this.eventSummaryFieldRequired = resp[$rootScope.langSelected]
                    ? this.parseBoolean(resp[$rootScope.langSelected])
                    : this.parseBoolean(resp['sv_SV']);
                this.recipientLangRequired = this.eventSummaryFieldRequired;
                if (this.eventSummaryFieldRequired) {
                    this.recipientLang = '';
                } else {
                    this.recipientLang = 'sv_SV';
                }
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
        $scope.startProgress();
        EventSite.getEventSiteDetails(
            initData,
            (response) => {
                $scope.endProgress();
                this.eventName = response.name;
                this.sharePublicly = response.sharePublicly;
                this.publicUrl = response.url;
                this.createShareableLink = this.sharePublicly;
            },
            $scope.endWithError
        );
    }

    shareEventSummary(type) {
        const { AuthenticationService, EventSummary, EventEnum, $scope, $rootScope } = this._injected;
        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
            return;
        }
        if (!this.messageBodyForm.$valid) {
            CommonFunctions.touchFormFields(this.messageBodyForm);
            return;
        }
        $scope.startProgress();
        this.finalizeEmailList();
        EventSummary.shareByEmail(
            {
                emailListWithDecisionMaker: this.emailListWithDecisionMaker,
                id: this.eventId,
                recipientLang: this.recipientLang,
                message: this.messageBody,
            },
            (response) => {
                $scope.endProgress();
                this.sharedUserLists = response.sharedLists;
                this.sharedSuccessMessage = true;
                $rootScope.$broadcast(EventEnum.SHARED_USERS_LIST_CHANGED, this.sharedUserLists);
                this.currentDecisionMaker = response.currentDecisionMaker;
                $rootScope.$broadcast(EventEnum.CURRENT_DECISION_MAKER_CHANGED, this.currentDecisionMaker);
                this.email = '';
                this.isDecisionMaker = false;
                this.sharedType = type;
            },
            $scope.endWithErrorOverlay()
        );
    }

    finalizeEmailList() {
        let _decisionMaker = _.find(this.emailListWithDecisionMaker, (d) => {
            return d.isDecisionMaker === true;
        });
        if (_decisionMaker) {
            this.emailList = _.without(
                CommonFunctions.convertToLowerCase(this.emailList),
                _decisionMaker.email.toLowerCase()
            );
        }
        angular.forEach(this.emailList, (email) => {
            if (email) {
                let _emailObj = { email: email.toLowerCase(), isDecisionMaker: false };
                this.emailListWithDecisionMaker.push(_emailObj);
            }
        });
    }

    refreshEmail(email, index) {
        this.userAlreadyExists = false;
        this.sharedUserEmailLists = [];
        _.filter(this.sharedUserLists, (user) => {
            this.sharedUserEmailLists.push(user.email);
        });
        if (email === '' && index === this.decisionMakerSelected) {
            this.decisionMakerSelected = -1;
            this.emailListWithDecisionMaker = [];
        }
        if (
            this.sharedUserLists.length > 0 &&
            CommonFunctions.hasIntersectValue(
                CommonFunctions.convertToLowerCase(this.emailList),
                CommonFunctions.convertToLowerCase(this.sharedUserEmailLists)
            )
        ) {
            this.userAlreadyExists = true;
        }
    }

    setDecisionMakerConfirmation(user) {
        if (this.currentDecisionMaker && user.email === this.currentDecisionMaker.email) {
            return;
        }
        user.showDecisionMakerConfirmation = true;
    }

    resetDecisionMakerConfirmation(user) {
        user.showDecisionMakerConfirmation = false;
    }

    saveDecisionMakerFn(user, email) {
        const { AuthenticationService, EventSummary, EventEnum, $scope, $rootScope } = this._injected;
        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
            return;
        }
        if (this.currentDecisionMaker && email === this.currentDecisionMaker.email) {
            return;
        }
        let previousDecisionMaker = angular.copy(this.currentDecisionMaker);
        $scope.startProgress();
        EventSummary.saveDecisionMaker(
            {
                email: email,
                id: this.eventId,
                decisionMaker: true,
            },
            (response) => {
                this.currentDecisionMaker = response.currentDecisionMaker;
                this.newDecisionMaker = false;
                user.showDecisionMakerConfirmation = false;
                let _arr = this.sharedUserLists;
                for (var i = _arr.length - 1; i >= 0; i--) {
                    if (_arr[i].email === this.currentDecisionMaker.email) {
                        _arr[i].decisionMaker = true;
                    }
                    if (_arr[i].email === previousDecisionMaker.email) {
                        _arr[i].decisionMaker = false;
                    }
                }
                $rootScope.$broadcast(EventEnum.SHARED_USERS_LIST_CHANGED, this.sharedUserLists);
                $rootScope.$broadcast(EventEnum.CURRENT_DECISION_MAKER_CHANGED, this.currentDecisionMaker);
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }

    setShowDeleteUserConfirmation(user) {
        user.showDeleteUserConfirmation = true;
    }

    resetShowDeleteUserConfirmation(user) {
        user.showDeleteUserConfirmation = false;
    }

    setReopenOfferConfirmation(user) {
        user.showReopenOfferConfirmation = true;
    }

    resetReopenOfferConfirmation(user) {
        user.showReopenOfferConfirmation = false;
    }

    reopenOffer(sharedUser) {
        const { AuthenticationService, EventSummary, EventEnum, $scope, $rootScope } = this._injected;
        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
            return;
        }
        $scope.startProgress();
        EventSummary.reopenOffer(
            {
                id: this.eventId,
            },
            (response) => {
                this.resetReopenOfferConfirmation(sharedUser);
                this.acceptedDate = response.acceptedDate;
                $rootScope.$broadcast(EventEnum.REOPEN_OFFER_TO_DECISION_MAKER, this.acceptedDate);
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }

    removeSharedUser(email) {
        const { AuthenticationService, EventSummary, EventEnum, $scope, $rootScope } = this._injected;
        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
            return;
        }
        $scope.startProgress();
        EventSummary.removeUser(
            {
                email: email,
                id: this.eventId,
            },
            () => {
                let _arr = this.sharedUserLists;
                for (var i = _arr.length - 1; i >= 0; i--) {
                    if (_arr[i].email === email) {
                        _arr.splice(i, 1);
                    }
                }
                $rootScope.$broadcast(EventEnum.SHARED_USERS_LIST_CHANGED, this.sharedUserLists);
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }

    createShareableLinkFn() {
        const { $scope, EventSite } = this._injected;
        this.createShareableLink = !this.createShareableLink;
        var normalizedEventName = DiacriticsUtil.normalizeText(this.eventName);
        $scope.startProgress();
        var reqData = angular.extend(
            {},
            { eventId: this.eventId, type: this.type },
            {
                name: normalizedEventName,
                sharePublicly: this.createShareableLink,
            }
        );
        EventSite.updatePublicUrl(
            reqData,
            (response) => {
                this.publicUrl = response.url;
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }

    addEmailLength() {
        this.emailLength.push(this.emailLength.length + 1);
    }

    removeEmailFromList(index) {
        if (index === this.decisionMakerSelected) {
            this.decisionMakerSelected = -1;
            this.emailListWithDecisionMaker = [];
            this.newDecisionMaker = false;
        }
        if (this.emailLength.length == 1) {
            this.emailList = [];
        } else {
            if (this.emailList.length) {
                this.emailList.splice(index, 1);
            }
            this.emailLength.splice(0, 1);
        }
    }

    setDecisionMaker(decisionMakerEmail, index) {
        if (decisionMakerEmail) {
            this.emailListWithDecisionMaker = [];
            this.decisionMakerSelected = index;
            this.newDecisionMaker = true;
            angular.forEach(this.emailList, (email) => {
                if (decisionMakerEmail === email) {
                    let _emailObj = { email: email, isDecisionMaker: true };
                    this.emailListWithDecisionMaker.push(_emailObj);
                }
            });
        }
    }

    copyToClipboard() {
        var btnCopy = document.getElementById('btn-copy');
        var copyElement = document.createElement('textarea');
        copyElement.style.position = 'fixed';
        copyElement.style.opacity = '0';
        copyElement.textContent = this.publicUrl;
        var body = document.getElementsByTagName('body')[0];
        body.appendChild(copyElement);
        copyElement.select();
        document.execCommand('copy');
        body.removeChild(copyElement);

        btnCopy.classList.add('copied');
        var temp = setInterval(function () {
            btnCopy.classList.remove('copied');
            clearInterval(temp);
        }, 1000);
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

EventSummaryShareModalCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$element',
    'data',
    '$log',
    'Presentation',
    'close',
    'CommonPresentation',
    'AuthenticationService',
    'EventSummary',
    'EventSite',
    'EventEnum',
    'UserGroupSettings',
];
export default EventSummaryShareModalCtrl;
