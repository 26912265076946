class OgTextAngularEditorController {
    constructor($scope, $rootScope) {
        this._injected = { $scope, $rootScope };

        this.toolbarOptions = [['bold', 'italics', 'underline']];

        this.textChangeCallback = this.textChangeCallback.bind(this);
    }

    textChangeCallback() {
        if (this.textOnChangeCallback) {
            this.textOnChangeCallback();
        }
    }
}

OgTextAngularEditorController.$inject = ['$scope', '$rootScope'];
export default OgTextAngularEditorController;
