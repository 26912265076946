import { environment } from "../../../environments/environment";
export default function (appModule) {
    appModule.run([
        '$rootScope',
        '$translate',
        '$timeout',
        '$location',
        'AuthenticationService',
        'CustomerUser',
        'Page',
        'ServerUrlService',
        'Participant',
        'amMoment',
        'xdLocalStorage',
        'CustomTranslationLoaderService',
        'CommonFile',
        'CommonFunctions',
        'ValidationService',
        '$http',
        'ClientService',
        function (
            $rootScope,
            $translate,
            $timeout,
            $location,
            AuthenticationService,
            CustomerUser,
            Page,
            ServerUrlService,
            Participant,
            amMoment,
            xdLocalStorage,
            CustomTranslationLoaderService,
            CommonFile,
            CommonFunctions,
            ValidationService,
            $http,
            ClientService
        ) {
            let ___token = '';
            const apiUrl = environment.apiUrl;
            $rootScope.languageLoading = undefined;
            $rootScope.clientShowHideDataLoading = undefined;
            $rootScope.switchLang = function (lang, guiOnly, participantHash, fromPublicSite) {
                $rootScope.langSelected = lang;
                $rootScope.stateLoading = true;
                $rootScope.languageLoading = !angular.isDefined($rootScope.languageLoading);
                CustomTranslationLoaderService.setLanguage(apiUrl, lang).then(function () {
                    $rootScope.languageLoading = false;
                    var langShort = lang && lang.substring(0, lang.indexOf('_'));
                    moment.locale(langShort);
                    amMoment.changeLanguage(langShort);
                    var datePickerLang = langShort;
                    if (datePickerLang === 'en') {
                        datePickerLang = '';
                    }
                    jQuery.datepicker.setDefaults(jQuery.datepicker.regional[datePickerLang]);

                    if (!guiOnly && AuthenticationService.isLogged() && !participantHash) {
                        CustomerUser.updateSettings(
                            { prefLang: lang },
                            function (resp) {
                                AuthenticationService.updatePrefLang(resp.prefLang);
                            },
                            CommonFunctions.alert
                        );
                    }
                    if (participantHash && !fromPublicSite) {
                        Participant.updateCurrentParticipantLang(
                            {
                                hash: participantHash,
                                locale: lang,
                            },
                            function () {
                            },
                            CommonFunctions.alert
                        );
                    }
                    $rootScope.stateLoading = false;
                    $rootScope.$broadcast('lang:change', { lang: lang, langShort: langShort });
                });
            };
            if (!$rootScope.publicURL) {
                Page.publicURL(
                    {},
                    function (response) {
                        ServerUrlService.setPublicURL(response.publicURL);
                    },
                     (err) => {
                        console.log('public url error: ' + err);
                    }
                );
            }

            if (!$rootScope.langSelected) {
                let selectedLang = localStorage.getItem('language');
                if (selectedLang) {
                    $rootScope.switchLang(selectedLang, true);
                } else {
                    $rootScope.switchLang('sv_SV', true);
                }
            }

            /**
             * 1. Maintain history of previous and current page
             * 2. Set language for UI only if the URL as lang param
             */
            $rootScope.$on('$stateChangeSuccess', function (ev, to, toParams, from) {
                $rootScope.previousState = from.name;
                $rootScope.currentState = to.name;

                $timeout(function () {
                    var lang = $location.search().lang;
                    if (lang !== null) {
                        var langChangeUrls = ['auth', 'invitation'];
                        var path = $location.path();
                        // change the lang only when the page is external access page
                        if (path && langChangeUrls.includes(path)) {
                            $rootScope.switchLang(lang, true);
                        }
                    }
                }, 100);
            });

            $rootScope.rights = {
                FULL: 'FULL',
                LIMITED: 'LIMITED',
                NONE: 'NONE',
            };

            $rootScope.bodyClassForController = function ($scope, cls) {
                $('body').addClass(cls);

                $scope.$on('$destroy', function () {
                    $('body').removeClass(cls);
                });
            };

            xdLocalStorage
                .init({
                    iframeUrl: 'assets/iframe/iframe.html',
                })
                .then(function () {
                    console.log('Got iframe ready');
                    window.xdLocalStorage.getItem('accessToken', function (data) {
                        if (___token === data.value) {
                            localStorage.setItem('authToken', data.value);
                        }
                    });
                });

            $rootScope.clearToken = function () {
                // window.xdLocalStorage.removeItem('accessToken');
            };

            $rootScope.setToken = function (token) {
                ___token = token;
            };

            $rootScope.getImageUrl = function (fileId) {
                return CommonFile.getFile(fileId);
            };

            $rootScope.getBaseUrl = function () {
                return CommonFile.getBaseUrl();
            };

            // Application Setup

            $rootScope.safeApply = function ($scope) {
                if (!$scope.$$phase && !$rootScope.$$phase) {
                    $scope.$apply();
                }
            };

            var transformRequest = function (data, headersGetter) {
                var headers = headersGetter();
                if (data && data.form) {
                    ValidationService.clear(data.form);
                    headers['Validation-Form'] = data.form;
                }
                headers['timestamp'] = new Date().getTime();
                return angular.toJson(data);
            };

            let callbacks = [];
            let googleMapsInitialized = false;
            window.onGoogleMapsReady = function () {
                if (typeof angular !== 'undefined') {
                    googleMapsInitialized = true;
                    angular.forEach(callbacks, function (callback) {
                        callback();
                    });
                    callbacks = [];
                } else {
                    setTimeout(window.onGoogleMapsReady, 100);
                }
            };

            window.googleMapsCallback = function (callback) {
                if (googleMapsInitialized) {
                    callback();
                } else {
                    callbacks.push(callback);
                }
            };

            function lazyLoadScripts(srcs) {
                while (srcs.length) {
                    var src = srcs.shift();
                    var s = document.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = src;
                    document.getElementsByTagName('body')[0].appendChild(s);
                }
            }

            function loadHtmlScript() {
                fetch(environment.apiUrl + 'api/v1/config/gtmBody')
                    .then((response) => {
                        return response.text();
                    })
                    .then((html) => {
                        const parser = new DOMParser();
                        const doc = parser.parseFromString(html, 'text/html');
                        const _iframe = doc.querySelector('iframe');
                        const _noscript = document.createElement('noscript');
                        _noscript.appendChild(_iframe);
                        document.querySelector('body').appendChild(_noscript);
                    })
                    .catch((err) => {
                        console.log('err', err);
                    })
            }

            lazyLoadScripts([
                'https://maps.googleapis.com/maps/api/js?key=AIzaSyDS7icInMlwnmMakS488nV91llqnXBAPZ8&libraries=places&sensor=false&callback=onGoogleMapsReady&language=en',
                'https://apis.google.com/js/api.js',
                environment.apiUrl + 'api/v1/config/gtmHead',
                environment.apiUrl + 'api/v1/config/segment',
                environment.apiUrl + 'api/v1/config/intercom',
            ]);

            function loadOverrideCss() {
                jQuery.ajax({
                    url: environment.apiUrl + 'public/cdn/overrideCss',
                    type: 'get',
                    contentType: 'text/css',
                    success: function (data) {
                        let overrideCssSelector = document.createElement('style');
                        overrideCssSelector.rel = 'stylesheet';
                        overrideCssSelector.innerText = data;
                        document.head.appendChild(overrideCssSelector);
                    },
                });
            }

            function loadShowHideCss() {
                jQuery.ajax({
                    url: environment.apiUrl + 'public/cdn/showHideCss',
                    type: 'get',
                    contentType: 'text/css',
                    success: function (data) {
                        let overrideCssSelector = document.createElement('style');
                        overrideCssSelector.rel = 'stylesheet';
                        overrideCssSelector.innerText = data;
                        document.head.appendChild(overrideCssSelector);
                    },
                });
            }


            function loadClientSettingsData() {
                jQuery.ajax({
                    url: environment.apiUrl + 'api/v1/client/getClientSettings',
                    type: 'get',
                    contentType: 'application/json',
                    success: function (response) {
                        ClientService.setClientSettingsData(response);
                    },
                });
            }

            loadHtmlScript();
            loadOverrideCss();
            loadShowHideCss();
            loadClientSettingsData();

            // Application Setup End

            $http.defaults.transformRequest = transformRequest;

            $rootScope.alert = CommonFunctions.alert;
            $rootScope.alertInline = CommonFunctions.alertInline;
            $rootScope.formError = CommonFunctions.formError;
            $rootScope.formSuccess = CommonFunctions.formSuccess;
            $rootScope.formInfo = CommonFunctions.formInfo;
            $rootScope.alertSimple = CommonFunctions.alertSimple;
        },
    ]);
}
