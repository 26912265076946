'use strict';
angular.module('core.existingServices').factory('ExternalAccessService', [
    '$http',
    function ($http) {

        return {
            verify: function (hash, scope, successCallback, errorCallback) {
                var getInfo = $http.get('api/v1/register/verify', {
                    params: {invitationId: hash},
                });
                getInfo.then(function (resp) {
                    let data = resp.data;
                    scope.invitationValid = !!data.valid;
                    scope.invitationExists = !!data.exists;
                    scope.email = data.email;
                    scope.company = data.company;
                    scope.companyDisabled = !!data.company;
                    scope.invitationType = data.type;
                    scope.validated = true;
                    if (successCallback) {
                        successCallback();
                    }
                }, function () {
                    if (errorCallback) {
                        getInfo.error(errorCallback);//todo(SC) need double check
                    }
                })
            },
            getOauthDetails: function (scope) {
                var getDetails = $http.get('api/v1/register/getoAuthDetails');
                getDetails.then(function (resp) {
                    let data = resp.data;
                    scope.email = data.email;
                    scope.oAuthValid = data.valid;
                    scope.firstName = data.firstName;
                    scope.lastName = data.lastName;
                });
            },
        };
    },
]);

