import contactUsModal from '../../modals/modals2.0/contact-us-modal';

(function () {
    'use strict';

    angular.module('ogDynamicElement').directive('ogDynamicElement', [
        '$compile',
        'AuthenticationService',
        'ModalService',
        function ($compile, $translate) {
            return {
                restrict: 'E',
                scope: {
                    message: '=',
                    translationCode: '=',
                },
                replace: true,
                link: function (scope, element, attrs) {
                    scope.loadTemplate = function () {
                        scope.getTranslatedText();
                        let template = $compile(scope.translatedText)(scope);
                        element.html(template);
                    }
                    scope.loadTemplate();
                },
                controller: [
                    '$scope',
                    '$translate',
                    '$timeout',
                    function ($scope, $translate, $timeout) {
                        $scope.getTranslatedText = function () {
                            $scope.translatedText = $scope.translationCode ? $translate.instant($scope.translationCode) : $scope.message;
                        }
                        $scope.$on('lang:change', () => {
                            $timeout(()=>{
                                $scope.loadTemplate();
                            })
                        });
                    },
                ],
            };
        },
    ]);
})();
