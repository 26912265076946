import 'angular-route';
import 'angular-ui-router';
import 'angular-translate';
import "angular-ui-router/lib/legacy/stateEvents";
import 'angularjs-slider';
import 'angularjs-stripe-elements';
import 'textangular';
import 'angular-strap';
import 'angular-ui-bootstrap';

import './core/core.module';
import './components/directives/directive.module';
import './components/modals/modals2.0/modals.module'
import './components/modules/eventlogic.modules.module';

import './components/modules/event/events/events.module';
import './components/modules/event/events/events.component';

import './components/modules/event/events/messages/messages.module';
import './components/modules/event/events/messages/messages.component';

import './components/navigation/navigation.module';
import './components/navigation/navigation.component';

import '../vendor/cross-domain-local-storage/ng-xdLocalStorage.min'
import '../vendor/angular-tree-view/eaTreeView.min.js'

import './components/directives/og_jcrop/og_jcrop.module'

import '../vendor/multipleDatePicker/multipleDatePicker.min.js'

import '../vendor/ui-calendar/calendar-directive'

import '../../node_modules/angular-ui-tinymce/dist/tinymce.min'

// Define the `centEventApplication` module
export default angular.module('centEventApplication', [
    'ngRoute',
    'ui.router',
    'ui.bootstrap',
    'ogJcrop',
    'ui.calendar',
    'ui.router.state.events',
    'pascalprecht.translate',
    'core',
    'directive',
    'modals',
    'login',
    'register',
    'link.expired',
    'events',
    'eventlogic.modules',
    'messages',
    'navigation',
    'angularMoment',
    'xdLocalStorage',
    'ngSanitize',
    'angularModalService',
    'ea.treeview',
    'angucomplete-alt',
    'textAngular',
    'multipleDatePicker',
    'rzSlider',
    'pageslide-directive',
    'ui.tinymce',
    'angular-intro',
    'afkl.lazyImage',
    'infinite-scroll',
    'ui.sortable',
    'angularjs-stripe-elements',
    'mgcrea.ngStrap'
]);