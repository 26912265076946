angular.
    module('event.request.presentation').
    component('eventRequestPresentation', {
        templateUrl: './app/components/modules/event/event_request_presentation/event_request_presentation.template.html',
        controllerAs: 'presentationCtrl',
        controller: [
            '$scope', '$routeParams', '$location', 'Presentation', 'RequestOffer',
            function PresentationController(
                $scope, $routeParams, $location, Presentation, RequestOffer
            ) {

                var vm = this;
                vm.backToPreviousPage = backToPreviousPageFn;
                vm.backToPreviousPageAdmin = backToPreviousPageAdminFn;
                vm.errorCallback = errorCallbackFn;
                vm.successCallback = successCallbackFn;

                (function init() {
                    vm.params = {
                        requestId: $routeParams.requestId,
                        supplierId: $routeParams.supplierId,
                        categoryId: $routeParams.categoryId,
                        supplierCategoryId: $routeParams.supplierCategoryId,
                        offerId: $routeParams.offerId,
                        eventId: $routeParams.eventId,
                        getOrCreate: true,
                    };
                    if (vm.params.requestId && vm.params.supplierId && !vm.params.offerId) {
                        RequestOffer.get({ requestId: vm.params.requestId, supplierId: vm.params.supplierId }, function (data) {
                            vm.params.offerId = data.id;
                        });
                    }
                    Presentation.get(vm.params, vm.successCallback, vm.errorCallback);
                })();

                function backToPreviousPageFn(backUrl) {
                    $location.url(backUrl);
                }

                function backToPreviousPageAdminFn(backUrl) {
                    window.location.href = backUrl;
                }

                function errorCallbackFn(error) {
                    vm.error = error;
                }

                function successCallbackFn(result) {
                    vm.presentation = result;
                }
            }
        ]
    });
