
export default function (appModule) {
    //used for the constants in a global scope
    appModule.constant('EventEnum', {
        /**
         * Details:
         * Broadcast to add request to an event
         */
        ADD_REQUEST_TO_EVENT: 'event:add:request',

        /**
         * Details:
         * Broadcast when make the supplier tab active in event overview.
         * Used to scroll to particular offer/request
         */

        MAKE_SUPPLIER_TAB_ACTIVE: 'supplier:tab:active',

        NOTE_TAB_CLICKED_ON_HOME_PAGE: 'note_tab_clicked_on_home_page',

        /**
         * Details:
         * Broadcast when offer's status is changed
         * Listened ON:
         *
         */
        OFFER_STATUS_CHANGE: 'offer:status:change',

        OPEN_CONFIRMATION_DIALOG: 'dialog:confirmation:open',

        /**
         * Details:
         * Broadcast when the status of participant is changed. e.g. when sending invitation
         *
         * Listened On: ParticipantListController
         */
        PARTICIPANTS_STATUS_CHANGED: 'participants:status:changed',

        /**
         * Details:
         * Broadcast when we should refresh after negotiation to reflect the result to other controllers too
         * Listened ON: eventController
         *
         */
        REQUEST_OFFER_STATUS_CHANGED_SUCCESS: 'request:offer:status:changed.success',

        /**
         * Details:
         * Broadcast when supplier is deleted from event details page right side panel
         * Listened ON:
         * 1. Supplier offer directive
         * 2. Schedule view
         */
        SUPPLIER_REMOVED_FROM_PANEL: 'supplier:removed:from:panel',

        /*
         *Details:
         * Throws on: It is broadcasted during user login, user changes userGroup, user edits user profile data
         * and when the user logs out.
         *
         * Listened on:
         * When broadcasted, the message is listened :
         * to get the data of logged user and to refresh the user data on the page.
         */
        USER_DATA_CHANGED: 'user:data:changed',

        /*
         *Details:
         * Throws on:It is broadcasted when the user group is changed.
         *
         * Listened on:
         * When broadcasted, the message is listened :
         * to get the data of logged user and to refresh the user data on the page.
         */
        USER_GROUP_CHANGED_SILENT: 'user:group:changed:silent',

        /*
         *Details:
         * Throws on: It is broadcasted when the user group is changed, during user login and during the registration of
         * new user from an invitation.
         *
         * Listened on:
         * When broadcasted, the message is listened :
         * to get the data of logged user. And to refresh the user data on the page.
         *
         * */
        USER_GROUP_CHANGED: 'user:group:changed',

        /*
         *Details:
         * Throws on: It is broadcasted when a user is added or removed from the UserGroup.
         *
         * Listened on:
         * When broadcasted, the message is listened :
         * to refresh the data on the page.
         *
         * */
        USER_IN_GROUP_DATA_CHANGED: 'usersInGroup:data:changed',

        /**
         * Details:
         * Thrown on : when user's profile image is updated
         *
         * Listened on:
         * 1. User in group list in events home page
         * 2. In the top bar menu
         */
        USER_PROFILE_PICTURE_CHANGED: 'user:profile:picture:changed',

        /**
         * Details:
         * Broadcast when to show the particular offer in supplier view
         */
        VIEW_SUPPLIER_OFFER: 'view:supplier:offer',

        OPEN_ALERT_DIALOG: 'dialog:alert:open',

        OPEN_SUPPLIER_PREVIEW_MODAL: 'open:supplier:preview:modal',

        EVENT_DETAIL_PAGE_RELOAD: 'event:detail:page:reload',

        EVENT_AND_REQUESTS_RELOAD: 'event:and:requests:reload',

        EVENT_GUIDE_INTRO_START: 'event:guide:intro:start',

        EVENT_GUIDE_GET_STARTED_NOTIFICATION_OPEN: 'event:guide:get:started:notification:open',

        REQUEST_CHANGED_IN_BUDGET: 'REQUEST_CHANGED_IN_BUDGET',

        TAB_CHANGED_IN_EVENT_SUMMARY: 'TAB_CHANGED_IN_EVENT_SUMMARY',

        MESSAGE_THREAD_CHANGED: 'MESSAGE_THREAD_CHANGED',

        SHARED_USERS_LIST_CHANGED: 'SHARED_USERS_LIST_CHANGED',

        CURRENT_DECISION_MAKER_CHANGED: 'CURRENT_DECISION_MAKER_CHANGED',

        REOPEN_OFFER_TO_DECISION_MAKER: 'REOPEN_OFFER_TO_DECISION_MAKER',

        EVENT_ADDRESS_CHANGED: 'EVENT_ADDRESS_CHANGED',

        OFFER_CURRENCY_CHANGED: 'OFFER_CURRENCY_CHANGED',

        EVENT_VAT_SETTING_CHANGED: 'EVENT_VAT_SETTING_CHANGED',
        EVENT_PARTICIPANT_SEND_INVITATION_CHANGED: 'EVENT_PARTICIPANT_SEND_INVITATION_CHANGED',
        EVENT_PARTICIPANT_SOFT_BLOCK_CHANGED: 'EVENT_PARTICIPANT_SOFT_BLOCK_CHANGED',

        REQUEST_OFFERS_CLOSE_PROCESS_STATUS_CHECK: 'EVENT_CLOSE_PROCESS_STATUS_CHECK',
        REQUEST_OFFERS_CLOSE_PROCESS_DONE: 'REQUEST_OFFERS_CLOSE_PROCESS_DONE',
        OPEN_ACCOMMODATION_MODAL: 'open:accommodation:modal',
    });
}