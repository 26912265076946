(function () {
    'use strict';

    angular.module('ogBackButton').directive('ogBackButton', [
        '$window',
        function ($window) {
            // Returns the version of Internet Explorer or a -1 (indicating the use of another browser).
            function getInternetExplorerVersion() {
                var rv = -1; // Return value assumes failure.
                if (navigator.appName == 'Microsoft Internet Explorer') {
                    var ua = navigator.userAgent;
                    var re = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
                    if (re.exec(ua) !== null) {
                        rv = parseFloat(RegExp.$1);
                    }
                }
                return rv;
            }

            return {
                template:
                    '<div class="clickable-link link" ng-click="back()"><b>&laquo; {{message | translate}}</b></div>',
                replace: true,
                scope: {
                    backSteps: '@ogIeBackSteps',
                    message: '@ogMessageKey',
                },
                link: function (scope) {
                    if (!scope.message) {
                        scope.message = 'common.back';
                    }

                    scope.back = function () {
                        if (getInternetExplorerVersion() == -1 || !scope.backSteps) {
                            scope.backSteps = 1;
                        }
                        for (var i = 0; i < scope.backSteps; i++) {
                            $window.history.back();
                        }
                    };
                },
            };
        },
    ]);
})();
