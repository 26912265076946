'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from '../../utils/global/scopeFunctions';

angular.module('dialog').directive('ogParticipantSendToModal', [
    '$rootScope',
    '$translate',
    'AuthenticationService',
    function ($rootScope, $translate, AuthenticationService) {
        return new OgModal({
            name: 'participant:sendTo',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);

                    $scope.initDialog = function (data) {
                        $scope.successCallback = data.successCallback;
                        $scope.fileFormat = data.fileFormat;
                        $scope.extension = data.extension;
                        $scope.title = 'dialog.participant.sendTo.title';
                        $scope.data = {
                            sendTo: '',
                        };
                        updateLoggedUserEmail();
                    };

                    function updateLoggedUserEmail() {
                        var userData = AuthenticationService.getUserData();
                        if (userData !== null) {
                            $scope.data.sendTo = userData.email;
                        }
                    }

                    $scope.processMe = function () {
                        $scope.successCallback($scope.fileFormat, $scope.extension, $scope.data.sendTo);
                        $scope.close();
                    };

                    $scope.disableMe = function () {
                        return $scope.data && !$scope.data.sendTo;
                    };

                    $scope.closeMe = function () {
                        $scope.close();
                    };

                    $scope.languageSelected = $rootScope.langSelected;

                    $scope.$on('lang:change', function (event, data) {
                        $scope.languageSelected = data.lang;
                    });
                },
            });
        },
    ]);

