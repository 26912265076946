(function () {
    /* global angular */
    'use strict';

    angular.module('core.serverUrl').factory('ServerUrlService', ServerUrlServiceFn);
    ServerUrlServiceFn.$inject = ['$rootScope'];

    function ServerUrlServiceFn($rootScope) {
        function setPublicURL(url) {
            $rootScope.publicURL = url;
        }

        return {
            setPublicURL: function (url) {
                return setPublicURL(url);
            },
        };
    }
})();
