(function () {
    'use strict';

    angular.module('ogSecUserRoleGranted').directive('ogSecUserRoleGranted', [
        'AuthenticationService',
        function (AuthenticationService) {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    grantedRoles: '@',
                    show: '=show',
                },
                controller: [
                    '$scope', 'CommonFunctions',
                    function ($scope, CommonFunctions) {
                        $scope.userRoles = AuthenticationService.getUserRoles();
                        $scope.show = CommonFunctions.hasIntersectValue($scope.userRoles, $scope.grantedRoles);
                    },
                ],
            };
        },
    ]);
})();
