import {OgModal} from "../../../utils/global/ogModal";

(function () {
    'use strict';
    angular.module('ogEntityRating').directive('ogEntityRating', [
        function () {
            return {
                templateUrl: './app/components/directives/entity_rating/og_entity_rating.view.html',
                scope: {
                    entity: '=',
                    notClickable: '=',
                    commentsLink: '@',
                },

                controller: [
                    '$scope',
                    'CommonFunctions',
                    function ($scope, CommonFunctions) {
                        $scope.showComments = function () {
                            if (!$scope.notClickable && $scope.commentsLink == 'NONE') {
                                OgModal.open('comments', {entity: $scope.entity});
                                CommonFunctions.stopPropagation();
                            }
                        };
                    },
                ],
            };
        },
    ]);
})();
