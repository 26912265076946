'use strict';

angular.module('core.supplierAvailabilityCheck').factory('SupplierAvailabilityCheck', [
    '$resource',
    function ($resource) {
        var v1Rest = $resource(
            'api/v1/supplierAvailabilityCheck/:referenceId',
            {referenceId: '@referenceId'},
            {
                get: {method: 'GET', isArray: true},
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var v1Delete = $resource(
            'api/v1/supplierAvailabilityCheck/:referenceId',
            {referenceId: '@referenceId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );

        return {
            get: function (jsonObject, successCallback, errorCallback) {
                return v1Rest.get(jsonObject, successCallback, errorCallback);
            },
            delete: function (referenceId, success, error) {
                console.log(referenceId, referenceId);
                return v1Delete['delete']({referenceId: referenceId}, success, error);
            },
        };
    },
]);
