(function () {
    'use strict';

    angular.module('ogRequiredExplanation').directive('ogRequiredExplanation', [
        function () {
            return {
                restrict: 'EA',
                template:
                    '<div class="notranslate"><strong class="danger">*</strong> = {{"required.explanation" |translate }}</div>',
                replace: false,
            };
        },
    ]);
})();
