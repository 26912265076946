import controller from './og_input_dropdown.controller';

(function () {
    'use strict';

    angular.module('ogInputDropdown').directive('ogInputDropdown', [
        function () {
            return {
                scope: {
                    placeholder: '@',
                    list: '=',
                    selected: '=',
                    property: '@',
                    open: '=',
                    disabled: '=',
                    onChange: '=',
                    callBackParams: '=',
                    type: '=',
                    clearFieldCallback: '&',
                },
                templateUrl: './app/components/directives/dropdown/editDropDown/og_input_dropdown.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogDropdownCtrl',
                bindToController: true,
            };
        },
    ]);
})();
