/**
 * Don't put this content inside function()
 */
'use strict';
export const OfferPartUtilFunctions = {
    checkOfferPartHasOldValueAndChanged: function (oldValue, newValue) {
        return oldValue && oldValue !== newValue;
    },
    checkOfferPartAmountHasOldValueAndChanged: function (oldValue, newValue) {
        return (oldValue || oldValue === 0) && oldValue !== newValue;
    },
};
