import {ScopeFunctions} from "../../../../../../utils/global/scopeFunctions";
(function () {
    'use strict';
    angular
        .module('participantsRatingDetail')
        .component('participantsRatingDetail', {
            templateUrl: './app/components/modules/event/event_detail/rating/participant/participant.view.html',
            controllerAs: 'participantsRatingDetailCtrl',
            controller: ['$scope', '$stateParams', '$timeout', 'Survey', 'ogScrollService',
                function ParticipantsRatingDetailController($scope, $stateParams, $timeout, Survey, ogScrollService){
                    var vm = this;
                    vm.init = initFn;

                    vm.hideDetailView = hideDetailViewFn;
                    vm.showAllRatings = showAllRatingsFn;
                    vm.showParticipantRatings = showParticipantRatingsFn;

                    var eventId = $stateParams.eventId;

                    var detailViews = [
                        { name: 'PARTICIPANT_EVENT_SURVEYS', show: false },
                        { name: 'PARTICIPANT_OFFER_SURVEYS', show: false },
                    ];

                    vm.currentDetailView = detailViews[0];

                    ScopeFunctions.addToScope($scope);
                    vm.init();

                    function initFn() {
                        $scope.startProgress();
                        Survey.surveysForParticipants(
                            { eventId: eventId },
                            function (response) {
                                vm.eventSurveys = response.eventSurveys;
                                vm.requestOfferSurveys = response.requestOfferSurveys;
                                vm.currentDetailView = detailViews[0];
                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay
                        );
                    }

                    function hideDetailViewFn() {
                        vm.currentDetailView.show = false;
                    }

                    function scrollTo(id) {
                        $timeout(function () {
                            ogScrollService.scrollToElement(id);
                        }, 0);
                    }

                    function showAllRatingsFn() {
                        showDetails(detailViews[0], vm.eventSurveys.surveys);
                    }

                    function showDetails(view, surveys) {
                        hideDetailViewFn();
                        vm.currentDetailView = view;
                        vm.currentDetailView.show = true;
                        vm.surveysForDetail = surveys;
                        scrollTo('surveyDetails');
                    }

                    function showParticipantRatingsFn(surveys) {
                        showDetails(detailViews[1], surveys);
                    }
                }]
        });
})();
