import CommonFunctions from "../../../utils/CommonFunctionUtil";
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('ogClientShowHideSetting').directive('ogClientShowHideSetting', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    grantedClientSettings: '@',
                    show: '=show',
                },
                controller: [
                    '$scope', 'ClientService', 'CommonFunctions',
                    function ($scope, ClientService, CommonFunctions) {
                        this.$onInit = function () {
                            $scope.clientSettingListData = ClientService.getClientSettingsData();
                            if(!_.isEmpty($scope.clientSettingListData)) {
                                $scope.show = showHideSetting($scope.clientSettingListData);
                            } else {
                                ClientService.loadClientSettingsData().then((data) => {
                                    $scope.show = showHideSetting(data);
                                })
                            }
                        }

                        function showHideSetting(data) {
                            let clientSettings = data.clientSettings;
                            return CommonFunctions.hasIntersectValue(clientSettings, $scope.grantedClientSettings);
                        }
                    },
                ],
            };
        },
    ]);
})();
