(function () {
    'use strict';

    angular.module('ogRowOfferPartOptionActionPopover').directive('ogRowOfferPartOptionActionPopover', [
        '$compile',
        '$templateCache',
        function ($compile, $templateCache) {
            var getTemplate = function () {
                return $templateCache.get(
                    'offer_part_option_action_popover.html'
                );
            };
            return {
                restrict: 'A',
                transclude: true,
                template: '<span ng-transclude></span>',
                scope: {
                    offerPartOption: '=',
                    doOfferPartOptionAccept: '=',
                    doOfferPartOptionDecline: '=',
                    openInlineOfferPartOptionEdit: '=',
                    flagForAcceptedWithChanges: '=',
                    originalOfferPartOption: '=',
                    placement: '@',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.accept = function (offerPartOption) {
                            $scope.doOfferPartOptionAccept(offerPartOption);
                            jQuery('[data-original-title]').popover('hide');
                        };

                        $scope.decline = function (offerPartOption) {
                            $scope.doOfferPartOptionDecline(offerPartOption);
                            jQuery('[data-original-title]').popover('hide');
                        };

                        $scope.edit = function (offerPartOption) {
                            $scope.openInlineOfferPartOptionEdit(offerPartOption);
                            jQuery('[data-original-title]').popover('hide');
                        };
                    },
                ],
                link: function (scope, element) {
                    var popOverContent;
                    var html = getTemplate();
                    popOverContent = $compile(html)(scope);

                    var options = {
                        content: popOverContent,
                        placement: scope.placement,
                        html: true,
                        title: ' ',
                    };
                    jQuery(element).popover(options);

                    scope.close = function () {
                        element.popover('toggle');
                        element.triggerHandler('click');
                    };

                    element.on('click', function () {
                        jQuery('.pophover').not(element).popover('hide'); //all but this
                    });

                    scope.closePopOver = function () {
                        jQuery(element).popover('hide');
                    };
                },
            };
        },
    ]);
})();
