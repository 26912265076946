import controller from './og_presentation_profile_settings.controller';

(function () {
    'use strict';

    angular.module('presentation-profile-settings').component('ogPresentationProfileSettings', {
        bindings: {
            group: '<',
            successCallBack: '&',
            viewPresentationCallback: '&',
        },
        templateUrl:
            './app/components/directives/presentation_profile_settings/og_presentation_profile_settings.view.html',
        controller,
        controllerAs: 'ogPresentationProfileSettingsCtrl',
    });
})();
