/**
 * Created by Amol on 01/08/22.
 */
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class ParticipantConfirmEmailPreviewModalController {
    constructor($element, close, $scope, data) {
        this._injected = {$element, close, $scope, data};
        ScopeFunctions.addToScope($scope);
        this.data = data.info;
    }

    close(startGuide = false) {
        const {close, $element} = this._injected;
        $element.modal('hide');
        close(startGuide, 500);
    }
}
ParticipantConfirmEmailPreviewModalController.$inject = ['$element', 'close', '$scope', 'data'];
export default ParticipantConfirmEmailPreviewModalController;
