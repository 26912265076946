(function () {
    'use strict';

    angular.module('ogSupplierMapCanvas').directive('ogSupplierMapCanvas', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    suppliers: '=ogSupplierMapCanvas',
                    doRender: '=ogMapInView',
                    select: '=ogSelectCallback',
                    disableControl: '@',
                    matchHeight: '@',
                },
                // SupplierMapController copied
                controller: [
                    '$scope',
                    '$log',
                    '$compile',
                    '$timeout',
                    'GlobalConstants',
                    function ($scope, $log, $compile, $timeout, GlobalConstants) {
                        var map, clusterer, infoWindow;
                        var markers = [];
                        $scope.currentSupplier = null;

                        $scope.removeMap = function () {
                            if (map) {
                                map = undefined;
                            }

                            if (clusterer) {
                                $scope.clearMarkers();
                                clusterer = undefined;
                            }
                        };

                        $scope.createMap = function (canvas, latLng, zoom) {
                            var enableControl = !$scope.disableControl;
                            if (latLng && zoom) {
                                var mapOptions = {
                                    center: latLng,
                                    zoom: zoom,
                                    disableDefaultUI: enableControl,
                                    zoomControl: enableControl,
                                    scaleControl: enableControl,
                                    streetViewControl: enableControl,
                                    mapTypeControl: enableControl,
                                    scrollwheel: enableControl,
                                    draggable: enableControl,
                                    disableDoubleClickZoom: !enableControl,
                                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                                };
                                if (!map) {
                                    map = new google.maps.Map(canvas);
                                }
                                map.setOptions(mapOptions);

                                $('.map-modal').on('shown.bs.modal', function () {
                                    mapOptions.zoom = GlobalConstants.DEFAULT_MAP_ZOOM;
                                    map.setOptions(mapOptions);
                                    google.maps.event.trigger(map, 'resize');
                                    map.setCenter(mapOptions.center);
                                });
                            }
                        };

                        window.googleMapsCallback(function () {
                            google.maps.event.addDomListener(window, 'resize', function () {
                                var center = map.getCenter();
                                google.maps.event.trigger(map, 'resize');
                                map.setCenter(center);
                            });
                        });

                        function addMarker(supplier, latLng) {
                            var marker = new google.maps.Marker({ position: latLng });
                            clusterer.addMarker(marker);
                            google.maps.event.addListener(marker, 'click', function () {
                                $timeout(function () {
                                    $scope.currentSupplier = supplier;
                                    $scope.showInfoWindow(marker);
                                });
                            });
                            return marker;
                        }

                        $scope.renderSuppliers = function (suppliers) {
                            window.googleMapsCallback(function () {
                                angular.forEach(suppliers, function (supplier) {
                                    if (supplier) {
                                        if (supplier.latitudeAndLongitude) {
                                            var latLng = new google.maps.LatLng(
                                                supplier.latitudeAndLongitude[0],
                                                supplier.latitudeAndLongitude[1]
                                            );
                                            markers.push(addMarker(supplier, latLng));
                                        } else if (supplier.address) {
                                            new google.maps.Geocoder().geocode(
                                                { address: supplier.address },
                                                function (results, status) {
                                                    if (status === google.maps.GeocoderStatus.OK) {
                                                        markers.push(addMarker(supplier, results[0].geometry.location));
                                                    } else {
                                                        console.err(
                                                            'Error resolving address ' +
                                                            supplier.address +
                                                            '. Returned status is ' +
                                                            status
                                                        );
                                                    }
                                                }
                                            );
                                        }
                                    }
                                });
                                clusterer = new markerClusterer.MarkerClusterer({ map, markers });
                            });
                        };

                        $scope.showInfoWindow = function (marker) {
                            var content =
                                '<div class="info-window-content">' +
                                '<b>{{currentSupplier.name}}</b>' +
                                '<p ng-bind-html="currentSupplier.description"></p>' +
                                '<button class="button tiny" ng-show="select" ng-click="select(currentSupplier)">Add</button>' +
                                '</div>';

                            if (!infoWindow) {
                                infoWindow = new google.maps.InfoWindow({
                                    content: content,
                                });

                                google.maps.event.addListener(infoWindow, 'domready', function () {
                                    $timeout(function () {
                                        $compile($('.info-window-content')[0])($scope);
                                    });
                                });
                            } else {
                                infoWindow.close();
                                infoWindow.setContent(content);
                            }

                            infoWindow.open(map, marker);
                        };

                        $scope.clearMarkers = function () {
                            if (clusterer) {
                                clusterer.clearMarkers();
                            }
                            markers = [];
                        };
                    },
                ],
                link: function (scope, element) {
                    scope.isImageLoaded = false;
                    scope.$on('event:template:image:height', function () {
                        createMapForSupplier(true);
                        addMarkerForSupplier(scope.suppliers);
                    });

                    scope.$watch('doRender', function (val) {
                        if (!scope.matchHeight) {
                            createMapForSupplier(val);
                        }
                    });

                    scope.$watch('suppliers', function (value) {
                        if (!scope.matchHeight) {
                            addMarkerForSupplier(value);
                        }
                    });

                    function createMapForSupplier(val) {
                        if (val) {
                            var canvas = element.context;
                            window.googleMapsCallback(function () {
                                scope.createMap(canvas, new google.maps.LatLng(59.667741, 14.545898), 5);
                            });
                        } else if (!val) {
                            element.empty();
                            element.removeAttr('style');
                            scope.removeMap();
                        }
                    }

                    function addMarkerForSupplier(value) {
                        if (scope.doRender) {
                            if (value) {
                                scope.clearMarkers();
                                scope.renderSuppliers(value);
                            } else {
                                element.empty();
                                element.removeAttr('style');
                                scope.removeMap();
                            }
                        }
                    }
                },
            };
        },
    ]);
})();
