import supplierPresentationModal from '../../modals/modals2.0/supplier-presentation-modal';
import { UtilFunctions } from '../../../utils/global/utilFunctions';
import _ from 'underscore';

const OFFER_STATUS_TO_EXCLUDE = ['CANCELED', 'DECLINED'];
(function () {
    'use strict';

    angular.module('ogEventSupplierSummary').directive('ogEventSupplierSummary', [
        'Presentation',
        'DateService',
        'ModalService',
        'CommonFile',
        function (Presentation, DateService, ModalService, CommonFile) {
            return {
                scope: {
                    request: '=',
                    eventId: '=',
                    isPublic: '=',
                    agentEnabled: '=',
                },
                templateUrl:
                    './app/components/directives/event_supplier_summary/og.event.supplier.summary.view.html',
                controller: [
                    '$scope',
                    '$rootScope',
                    '$sce',
                    function ($scope, $rootScope, $sce) {
                        $scope.loading = false;
                        $scope.viewSupplierPresentationModal = false;
                        $scope.openSupplierPresentation = function (supplier, offerId, supplierId) {
                            if ($scope.loading || $scope.viewSupplierPresentationModal) {
                                return;
                            }
                            $scope.viewSupplierPresentationModal = true;
                            $scope.loading = true;
                            Presentation.get(
                                { supplierId: supplierId },
                                function (presentation) {
                                    $scope.loading = false;
                                    let data = {
                                        supplier: supplier,
                                        presentation: presentation,
                                        supplierId: supplierId,
                                    };

                                    let supplierPresentationModalData = supplierPresentationModal;
                                    supplierPresentationModalData.inputs = { data };
                                    ModalService.showModal(supplierPresentationModalData).then((modal) => {
                                        $scope.viewSupplierPresentationModal = false;
                                        modal.element.modal();
                                        modal.close.then(() => {
                                            console.log('modal is closed!');
                                        });
                                    });
                                },
                                $scope.endWithAlert
                            );
                        };

                        function setLanguage(rootLang) {
                            let lang;
                            switch (rootLang) {
                                case 'sv_SV':
                                    lang = 'SWEDISH';
                                    break;
                                case 'nb_NB':
                                    lang = 'NORWEGIAN';
                                    break;
                                default:
                                    lang = 'ENGLISH';
                            }
                            return lang;
                        }

                        $scope.filterOnlyWithSupplierAndNotCancelled = function (item) {
                            return !!item.supplier && OFFER_STATUS_TO_EXCLUDE.indexOf(item.status.name) < 0;
                        };

                        $scope.getPresentationImage = function (offer) {
                            if (offer && offer.presentation) {
                                let presentationImages = offer.presentation.presentationImages;
                                if (presentationImages.length > 0) {
                                    let imgFeatured = _.find(presentationImages, (img) => {
                                        return img.featured === true;
                                    });
                                    if (imgFeatured) {
                                        return imgFeatured.fileStoreId;
                                    }
                                    return presentationImages[0].fileStoreId;
                                }
                            }
                            return null;
                        };

                        $scope.getPresentationDescription = function (presentation) {
                            if (presentation) {
                                const lang = setLanguage($rootScope.langSelected);
                                const localisedDescriptions = presentation.localisedDescriptions;
                                var _localDesc, _engDesc, _swedDesc, _norDesc;

                                _localDesc = _.find(localisedDescriptions, (desc) => {
                                    return desc.language === lang;
                                });

                                if (!_localDesc) {
                                    _engDesc = _.find(localisedDescriptions, (desc) => {
                                        return desc.language === 'ENGLISH';
                                    });
                                }
                                if (!_engDesc) {
                                    _swedDesc = _.find(localisedDescriptions, (desc) => {
                                        return desc.language === 'SWEDISH';
                                    });
                                }

                                if (!_swedDesc) {
                                    _norDesc = _.find(localisedDescriptions, (desc) => {
                                        return desc.language === 'NORWEGIAN';
                                    });
                                }

                                if (_localDesc) {
                                    presentation.description = _localDesc.text;
                                } else if (_engDesc) {
                                    presentation.description = _engDesc.text;
                                } else if (_swedDesc) {
                                    presentation.description = _swedDesc.text;
                                } else if (_norDesc) {
                                    presentation.description = _norDesc.text;
                                } else {
                                    presentation.description = '';
                                }

                                return UtilFunctions.limitText(presentation.description, 150);
                            }
                        };

                        $scope.parseDateInLocaleFormat = function (timestamp) {
                            return DateService.parseDateInLocaleFormat(timestamp);
                        };

                        $scope.getImageUrl = function (fileId) {
                            return CommonFile.getCommonFile(fileId);
                        };
                    },
                ],
            };
        },
    ]);
})();
