import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import { OgModal } from "../../../utils/global/ogModal";
import _ from 'underscore';

class AddContentSideBarCtrl {
    constructor(
        $scope,
        $timeout,
        $window,
        Page,
        Request,
        Event,
        EventEnum,
        GlobalConstants,
        RequestOfferStatusUtils,
        $translate,
        ogMatchHeightService,
        $rootScope,
        CommonFunctions
    ) {
        this._injected = {
            $scope,
            $timeout,
            $window,
            Page,
            Request,
            Event,
            EventEnum,
            GlobalConstants,
            RequestOfferStatusUtils,
            $translate,
            ogMatchHeightService,
            $rootScope,
            CommonFunctions,
        };

        ScopeFunctions.addToScope($scope);

        this.addedTemplateList = [];
        this.$onInit = function () {
            this.getContentGuidePage();
            this.listTemplates();
        }

        this.deleteRequest = this.deleteRequest.bind(this);

        $scope.$watch('AddContentSideBarCtrl.category', (newVal) => {
            if (newVal) {
                this.categoryId = newVal.id;
                this.searchRequestTemplate(true); // On category changed, clear search text. Task:: https://app.asana.com/0/1122760533352173/1156333507903321
            }
        });

        let matchHeightFn = function () {
            this.matchHeightManually();
        }.bind(this);

        angular.element($window).bind('resize', matchHeightFn);
        //unbind the resize listener
        $scope.$on('$destroy', function () {
            angular.element($window).unbind('resize', matchHeightFn);
        });
    }

    getContentGuidePage() {
        const { Page, $scope, $translate } = this._injected;
        $scope.startProgress('add-content-side-bar');
        Page.contentGuidePage(
            { eventId: this.event.id },
            (response) => {
                this.categoryList = response.categories.childCategories;
                let selectCategory = {
                    id: 0,
                    name: $translate.instant('add.content.select.category'),
                    // nameCode: 'common.translate.category.select.category'
                };
                //  this.categories = [selectCategory, ...this.categoryList];
                this.categories = [
                    selectCategory,
                    ..._.sortBy(this.categoryList, function (o) {
                        return o.name;
                    }),
                ];
                $scope.endProgress('add-content-side-bar');
            },
            $scope.endWithErrorOverlay('add-content-side-bar')
        );
    }

    listTemplates() {
        const { Request, $scope, $timeout } = this._injected;
        let searchParams = {
            recommended: true,
            sorting: { direction: 'asc', field: 'priority' },
        };
        $scope.startProgress('add-content-side-bar');
        Request.listTemplates(
            searchParams,
            (resp) => {
                this.requestTemplates = resp.list;
                $timeout(
                    function () {
                        this.matchTemplateTilesHeight();
                    }.bind(this)
                );
                $scope.endProgress('add-content-side-bar');
            },
            $scope.endWithError('add-content-side-bar')
        );
    }

    matchHeightManually() {
        /**
         * Height of #match-height-side-bar should be min-height of both content and side bar, jquery MatchHeight not
         * working because of absolute position of side bar
         */
        var height = jQuery('#match-height-side-bar').prop('scrollHeight');
        jQuery('.calendar-and-add-content-sidebar-match-height').css('min-height', height + 'px');
        this.matchTemplateTilesHeight();
    }

    matchTemplateTilesHeight() {
        const { ogMatchHeightService } = this._injected;
        ogMatchHeightService.matchHeightByClass('.request-template-tiles');
    }

    searchRequestTemplate(removeSearchText = false) {
        let searchParams = {
            sorting: { direction: 'desc', field: 'score' },
            categoryId: this.categoryId,
        };
        if (!removeSearchText) {
            searchParams.text = this.searchText;
        } else {
            this.searchText = '';
        }

        if (this.categoryId < 1) {
            searchParams.recommended = true;
        }

        let { $scope, $timeout, Request, ogMatchHeightService } = this._injected;
        $scope.startProgress('add-content-side-bar');
        Request.listTemplates(
            searchParams,
            (resp) => {
                this.requestTemplates = resp.list;
                $timeout(
                    function () {
                        this.matchTemplateTilesHeight();
                        ogMatchHeightService.matchContentHeightWithSidebar();
                    }.bind(this)
                );
                $scope.endProgress('add-content-side-bar');
            },
            $scope.endWithError('add-content-side-bar')
        );
    }

    addRequestTemplate(request) {
        let { $scope, Event, EventEnum, $rootScope } = this._injected;

        $scope.startProgress('add-content-side-bar');
        let requestParam = {
            id: request.id,
            name: request.name,
            categoryIds: request.categoryIds,
        };
        var data = {
            requests: [{ request: requestParam }],
        };

        Event.addOrUpdateContent(
            this.event.id,
            data,
            function () {
                $scope.$emit(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                $rootScope.$emit('event:user:action', { action: 'content.added.in.event', data: requestParam });
                $scope.endProgress('add-content-side-bar');
            },
            $scope.endWithAlert('add-content-side-bar')
        );

        this.addedTemplateList.push(request);
        this.matchHeightManually();
    }

    deleteRequest() {
        let { $scope, Request, EventEnum } = this._injected;
        $scope.startProgress('add-content-side-bar');
        Request['delete'](
            this.event.id,
            this.tobeDeletedRequest.id,
            {},
            function () {
                $scope.$emit(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                $scope.endProgress('add-content-side-bar');
            },
            $scope.endWithErrorOverlay('add-content-side-bar')
        );

        this.matchHeightManually();
    }

    deleteConfirmationOpen(request) {
        this.tobeDeletedRequest = request;
        if (this.isRequestDeletable(request)) {
            OgModal.open('confirmation', { message: 'request.delete.confirm', okCallBack: this.deleteRequest });
        } else {
            OgModal.open('alert', { message: 'request.cannot.delete' });
        }
    }

    isRequestDeletable(request) {
        return request.isInternal || !this.isAnyRequestOfferBooked(request.requestOffers);
    }

    isAnyRequestOfferBooked(offers) {
        const { RequestOfferStatusUtils } = this._injected;
        let requestHasNonInternalOffer = offers.some((offer) => offer.statusDetailed.name != 'INTERNAL');
        let requestHasOfferInBookedStates = offers.some(
            (offer) => RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(offer.status.name) > -1
        );
        return requestHasNonInternalOffer && requestHasOfferInBookedStates;
    }

    isThisTemplateAlreadyAdded(requestTemplate) {
        let addedRequest = _.findWhere(this.addedTemplateList, { id: requestTemplate.id });
        return addedRequest ? addedRequest : false;
    }

    finishedThisSideBar() {
        this.displayAddContentSideBar = false;
    }

    onEnterFunction(keyEvent) {
        if (keyEvent.which === 13) {
            this.searchRequestTemplate();
        }
    }

    getTranslatedCategoryName() {
        const { CommonFunctions } = this._injected;
        return CommonFunctions.getTranslatedTextFromCode(this.category.name, this.category.nameCode);
    }
}
AddContentSideBarCtrl.$inject = [
    '$scope',
    '$timeout',
    '$window',
    'Page',
    'Request',
    'Event',
    'EventEnum',
    'GlobalConstants',
    'RequestOfferStatusUtils',
    '$translate',
    'ogMatchHeightService',
    '$rootScope',
    'CommonFunctions',
];
export default AddContentSideBarCtrl;
