(function () {
    'use strict';

    angular.module('ogSecondaryLogo').directive('ogSecondaryLogo', [
        'AuthenticationService',
        'EventEnum',
        'ClientService',
        'CommonFile',
        function (AuthenticationService, EventEnum, ClientService, CommonFile) {
            return {
                templateUrl: './app/components/directives/secondary_logo/og.secondary.logo.directive.view.html',
                restrict: 'EA',
                controller: [
                    '$scope',
                    function ($scope) {
                        var vm = this;

                        vm.refreshUserData = refreshUserDataFn;
                        vm.getClientData = getClientDataFn;
                        vm.getSecondaryLogo = getSecondaryLogoFn;
                        vm.refreshUserData();
                        vm.getClientData();
                        vm.getSecondaryLogo();

                        function refreshUserDataFn() {
                            $scope.userData = AuthenticationService.getUserData();
                        }

                        function getClientDataFn() {
                            $scope.client = ClientService.get();
                        }

                        function getSecondaryLogoFn() {
                            if ($scope.client.secondaryLogoId) {
                                $scope.secondaryLogoId = $scope.client.secondaryLogoId;
                            } else {
                                $scope.secondaryLogoId = $scope.client.logoId;
                            }
                        }

                        $scope.getImageUrl = function (fileId) {
                            return CommonFile.getCommonFile(fileId);
                        };

                        $scope.$on(EventEnum.USER_DATA_CHANGED, vm.refreshUserData);
                        $scope.$on(EventEnum.USER_DATA_CHANGED, vm.getClientData);
                    },
                ],
            };
        },
    ]);
})();
