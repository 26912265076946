/**
 * Created by Diwakar on 5/16/2017.
 */
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class FullBookingTermsModalCtrl {
    constructor($element, $rootScope, $scope, close, data, $translate) {
        this._injected = {
            $element,
            $rootScope,
            $scope,
            close,
            data,
            $translate,
        };

        ScopeFunctions.addToScope($scope);
        this.bookingTerms = data.bookingTerms;
        this.isUsingClientBillingAddressAndIsEL = data.isUsingClientBillingAddressAndIsEL;
    }

    getMessage() {
        let { $translate } = this._injected;
        if (this.isUsingClientBillingAddressAndIsEL) {
            return $translate.instant('common.translation.dynamic.commission.text.terms.client.billing.address');
        }
        return $translate.instant('common.translation.dynamic.commission.text.terms');
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

FullBookingTermsModalCtrl.$inject = ['$element', '$rootScope', '$scope', 'close', 'data', '$translate'];
export default FullBookingTermsModalCtrl;
