/* global angular */
'use strict';

angular.module('core.requestOffer').factory('RequestOffer', [
    '$resource',
    '$log',
    '$http',
    function ($resource, $log, $http) {
        var v1Uri = 'api/v1/offer/:offerId';
        var v1Rest = $resource(
            v1Uri,
            { offerId: '@offerId' },
            {
                list: { method: 'POST', isArray: false },
                get: { method: 'GET', isArray: false },
                update: { method: 'PUT' },
                delete: { method: 'DELETE', isArray: false },
            }
        );
        var sendOfferToCustomerRest = $resource(
            v1Uri + '/sendOfferToCustomer',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var v1RestInvalidateConfirmation = $resource(
            v1Uri + '/invalidateConfirmation',
            { offerId: '@offerId' },
            {
                do: { method: 'GET' },
            }
        );
        var offerUsersRest = $resource(
            'api/v1/offer/users/:offerId',
            { offerId: '@offerId' },
            {
                list: { method: 'GET', isArray: true },
            }
        );
        var v1RestPrice = $resource(
            v1Uri + '/price',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT' },
            }
        );
        var v1RequestRest = $resource(
            'api/v1/offer/request/:requestId',
            { requestId: '@requestId' },
            {
                list: { method: 'GET', isArray: true },
            }
        );
        var v1offerRest = $resource(
            'api/v1/offer/:offerId/getMessageToFinanceDept',
            { offerId: '@offerId' },
            {
                get: { method: 'GET', isArray: true },
            }
        );
        var v1offerRests = $resource(
            'api/v1/offer/:offerId/createMessageToFinanceDept',
            { offerId: '@offerId' },
            {
                create: { method: 'PUT' },
            }
        );
        var v1RequestSupplierRest = $resource(
            'api/v1/offer/request/:requestId/supplier/:supplierId',
            { requestId: '@requestId', supplierId: '@supplierId' },
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var v1RequestOfferAdd = $resource(
            'api/v1/offer/request/:requestId/offer/:offerId',
            { requestId: '@requestId', offerId: '@offerId' },
            {
                save: { method: 'POST', isArray: false },
            }
        );
        var v1RequestOfferMessageOptions = $resource(
            'api/v1/offer/getOtherRequestOffers',
            {},
            {
                getMessageOptions: { method: 'POST', isArray: false },
            }
        );
        var v1RequestOfferNearingDeadlineRest = $resource(
            'api/v1/offer/offersNearingDeadline',
            {},
            {
                list: { method: 'GET', isArray: true },
            }
        );
        var v1RequestOfferApplyChangesRest = $resource(
            v1Uri + '/applyChangesToOfferBooking',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var applyCreatorChangesInBulk = $resource(
            v1Uri + '/applyCreatorChangesInBulk',
            {},
            {
                update: { method: 'PUT' },
            }
        );
        var discardEditedByCreatorChanges = $resource(
            v1Uri + '/discardEditedByCreatorChanges',
            {},
            {
                update: { method: 'PUT' },
            }
        );
        var applyEditedOwnChangesByCreator = $resource(
            v1Uri + '/applyEditedOwnChangesByCreator',
            {},
            {
                update: { method: 'PUT' },
            }
        );
        var bookOfferListInBulk = $resource(
            v1Uri + '/bookOfferListInBulk',
            {},
            {
                update: { method: 'PUT' },
            }
        );
        var discardOwnChangesBySupplier = $resource(
            v1Uri + '/discardOwnChangesBySupplier',
            {},
            {
                update: { method: 'PUT' },
            }
        );
        var v1RequestOfferReEditRest = $resource(
            v1Uri + '/doOfferReEditing',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var v1RequestOfferUnlockSupplierBookedLockRest = $resource(
            v1Uri + '/unlockBookedLockedBySupplier',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var v1SendRequestOffers = $resource(
            v1Uri + '/sendRequestOffers',
            {},
            {
                update: { method: 'PUT' },
            }
        );
        var v1RequestOfferSendFinalPrice = $resource(
            v1Uri + '/sendFinalOffer',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var v1SendCancellationFees = $resource(
            v1Uri + '/sendCancellationFees',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var v1RequestOfferUpdateCurrency = $resource(
            v1Uri + '/updateCurrency',
            { offerId: '@offerId' },
            {
                update: { method: 'POST', isArray: false },
            }
        );
        var v1RequestOfferApproveFinalOffer = $resource(
            v1Uri + '/approveFinalOffer',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var v1ApproveCancellationFees = $resource(
            v1Uri + '/approveCancellationFees',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var v1supplierAgreeTermsAndConditions = $resource(
            'api/v1/offer/:offerId/supplierAgreeTermsAndConditions',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var v1confirmWithSupplier = $resource(
            'api/v1/offer/:offerId/confirmInvalidOfferWithSupplier',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var v1getSupplierAgreeTermsAndConditions = $resource(
            'api/v1/offer/:offerId/getSupplierAgreeTermsAndConditions',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var v1EmailMessageToFinanceDept = $resource(
            v1Uri + '/emailMessageToFinanceDept',
            { offerId: '@offerId' },
            {
                update: { method: 'POST' },
            }
        );
        var v1requestOfferSchedule = $resource(
            'api/v1/offer/requestOfferSchedule',
            {},
            {
                get: { method: 'GET', isArray: true },
            }
        );
        var requestOffersForMessageByEventIdRest = $resource(
            'api/v1/offer/getOffersForMessageByEventId/:eventId',
            { eventId: '@eventId' },
            {
                list: { method: 'GET', isArray: true },
            }
        );
        var billingAddress = $resource(
            'api/v1/offer/:offerId/billingAddress',
            { offerId: '@offerId' },
            {
                get: { method: 'GET', isArray: false },
                update: { method: 'PUT', isArray: false },
            }
        );

        var v1UpdateSupplierResponsibleUser = $resource(
            'api/v1/offer/:offerId/updateResponsibleUser',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT' },
            }
        );

        var v1RequestOfferUpdateDiscount = $resource(
            v1Uri + '/updateDiscount',
            { offerId: '@offerId' },
            {
                update: { method: 'POST', isArray: false },
            }
        );

        var rest = function (restUrl, formData, successCallback, errorCallback) {
            $http
                .post(restUrl, formData, {
                    transformRequest: angular.identity,
                    headers: { 'Content-Type': undefined },
                })
                .then(function (resp) {
                        successCallback(resp.data);
                    }, function (error) {
                        errorCallback(error);
                    });
                };

        var uploadFinalPriceAttachments = function (jsonObject, successCallback, errorCallback) {
            var uri = 'api/v1/offer/' + jsonObject.offerId + '/uploadFinalPriceAttachments';
            var formData = new FormData();
            if (jsonObject.finalDetailsFiles) {
                for (var i = 1; i <= jsonObject.finalDetailsFiles.length; i++) {
                    formData.append('file' + i, jsonObject.finalDetailsFiles[i - 1]);
                }
            }
            formData.append('offerId', jsonObject.offerId);
            return rest(uri, formData, successCallback, errorCallback);
        };

        var save = function (jsonObject, successCallback, errorCallback) {
            var uri = 'api/v1/offer/updateCovidAdaptationMeasures';
            var formData = new FormData();
            if (jsonObject.covidMeasureFiles) {
                for (var i = 1; i <= jsonObject.covidMeasureFiles.length; i++) {
                    formData.append('file' + i, jsonObject.covidMeasureFiles[i - 1]);
                }
            }
            formData.append('offerId', jsonObject.offerId);
            formData.append('hasCovidPreventionMeasures', jsonObject.hasCovidPreventionMeasures);
            formData.append('covidPreventionMeasures', jsonObject.covidPreventionMeasures);

            return rest(uri, formData, successCallback, errorCallback);
        };

        var notificationSettings = $resource(
            'api/v1/offer/:offerId/notificationSettings',
            { offerId: '@offerId' },
            {
                get: { method: 'GET', isArray: false },
                update: { method: 'PUT', isArray: false },
            }
        );

        var notificationEmail = $resource(
            'api/v1/offer/:offerId/notificationEmail',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT', isArray: false },
            }
        );

        var discardAllChangesByCreator = $resource(
            v1Uri + '/discardAllChangesByCreator',
            {},
            {
                update: { method: 'PUT' },
            }
        );
        var applyAllChangesByCreator = $resource(
            v1Uri + '/applyAllChangesByCreator',
            {},
            {
                update: { method: 'PUT' },
            }
        );
        var changeStatusToSent = $resource(
            'api/v1/offer/changeStatusToSent',
            {},
            {
                put: { method: 'PUT', isArray: false },
            }
        );
        var v1UpdateBookingSupplierResponsibleUser = $resource(
            'api/v1/offer/:offerId/updateResponsibleUserForBooking',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT' },
            }
        );
        var v1UpdateManagingSupplierResponsibleUser = $resource(
            'api/v1/offer/:offerId/updateResponsibleUserForManaging',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT' },
            }
        );
        var v1UpdateHandOverDateAndSendEmail = $resource(
            'api/v1/offer/:offerId/updateHandOverDateAndSendEmail',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT' },
            }
        );
        var v1AddNewResponsibleUser = $resource(
            'api/v1/offer/:offerId/addNewResponsibleUser',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT' },
            }
        );
        var v1UpdateIncludeResponsibleBookingSupplierUser = $resource(
            'api/v1/offer/:offerId/updateIncludeResponsibleBookingSupplierUser',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT' },
            }
        );
        var v1UpdateInternalReference = $resource(
            'api/v1/offer/:offerId/updateInternalReference',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT' },
            }
        );

        var v1deleteCovidFiles = $resource(
            'api/v1/offer/:offerId/deleteCovidFile/:fileId',
            { offerId: '@offerId', fileId: '@fileId' },
            {
                delete: { method: 'DELETE' },
            }
        );

        var v1deleteFinalDetailsFiles = $resource(
            'api/v1/offer/deleteFinalDetailsFile/:fileId',
            { fileId: '@fileId' },
            {
                delete: { method: 'DELETE', isArray: true },
            }
        );

        var v1RevertFinalPriceChanges = $resource(
            'api/v1/offer/:offerId/revertFinalPriceChanges',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT', isArray: true },
            }
        );

        var v1UpdateCancelPeriodEndDate = $resource(
            'api/v1/offer/updateCancelPeriodEndDate',
            {},
            {
                update: { method: 'PUT' },
            }
        );

        var v1offerLogRest = $resource(
            'api/v1/offer/:offerId/log',
            { offerId: '@offerId' },
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var v1EmailSupplierContactRest = $resource(
            v1Uri + '/supplierContactUs',
            { offerId: '@offerId' },
            {
                send: { method: 'POST' },
            }
        );

        var v1UpdateVatIncludedInPrice = $resource(
            'api/v1/offer/:offerId/updateVatIncludedInPrice',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT' },
            }
        );

        var v1UpdateSupplierAgreementNumber = $resource(
            'api/v1/offer/:offerId/updateSupplierAgreementNumber',
            { offerId: '@offerId' },
            {
                update: { method: 'PUT' },
            }
        );

        return {
            list: function (jsonObject, successCallback, errorCallback) {
                var resource = v1Rest;
                if (jsonObject.requestId) {
                    resource = v1RequestRest;
                }
                return resource.list(jsonObject, successCallback, errorCallback);
            },
            get: function (jsonObject, successCallback, errorCallback) {
                var resource = v1Rest;
                if (jsonObject.requestId && jsonObject.supplierId) {
                    resource = v1RequestSupplierRest;
                }
                return resource.get(jsonObject, successCallback, errorCallback);
            },
            update: function (jsonObject, successCallback, errorCallback) {
                var resource = v1Rest;
                if (jsonObject.price) {
                    resource = v1RestPrice;
                }
                return resource.update(jsonObject, successCallback, errorCallback);
            },
            getUsers: function (jsonObject, success, error) {
                return offerUsersRest.list(jsonObject, success, error);
            },
            sendOfferToCustomer: function (jsonObject, successCallback, errorCallback) {
                return sendOfferToCustomerRest.update(jsonObject, successCallback, errorCallback);
            },
            invalidateConfirmation: function (jsonObject, successCallback, errorCallback) {
                return v1RestInvalidateConfirmation.do(jsonObject, successCallback, errorCallback);
            },
            delete: function (offerId, successCallback, errorCallback) {
                v1Rest['delete']({ offerId: offerId }, successCallback, errorCallback);
            },
            add: function (params, jsonObject, successCallback, errorCallback) {
                return v1RequestOfferAdd.save(params, jsonObject, successCallback, errorCallback);
            },
            getMessageOptions: function (params, successCallback, errorCallback) {
                return v1RequestOfferMessageOptions.getMessageOptions(params, successCallback, errorCallback);
            },
            getv1offerRest: function (offerId, successCallback, errorCallback) {
                return v1offerRest.get({ offerId: offerId }, successCallback, errorCallback);
            },
            createMessage: function (jsonObject, successCallback, errorCallback) {
                return v1offerRests.create(
                    { offerId: jsonObject.offerId },
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            getOffersNearingDeadline: function (successCallback, errorCallback) {
                return v1RequestOfferNearingDeadlineRest.list(successCallback, errorCallback);
            },
            applyBookedLockedStateChanges: function (offerId, successCallback, errorCallback) {
                return v1RequestOfferApplyChangesRest.update({ offerId: offerId }, successCallback, errorCallback);
            },
            applyCreatorChangesInBulk: function (eventId, offerIdList, successCallback, errorCallback) {
                return applyCreatorChangesInBulk.update(
                    { offerIdList: offerIdList, eventId: eventId },
                    successCallback,
                    errorCallback
                );
            },
            bookOfferListInBulk: function (eventId, offerIdList, successCallback, errorCallback) {
                return bookOfferListInBulk.update(
                    { offerIdList: offerIdList, eventId: eventId },
                    successCallback,
                    errorCallback
                );
            },
            discardOwnChangesBySupplier: function (offerIdList, successCallback, errorCallback) {
                return discardOwnChangesBySupplier.update(
                    { offerIdList: offerIdList },
                    successCallback,
                    errorCallback
                );
            },
            discardEditedByCreatorChanges: function (eventId, offerIdList, successCallback, errorCallback) {
                return discardEditedByCreatorChanges.update(
                    { offerIdList: offerIdList, eventId: eventId },
                    successCallback,
                    errorCallback
                );
            },
            applyEditedOwnChangesByCreator: function (eventId, offerIdList, successCallback, errorCallback) {
                return applyEditedOwnChangesByCreator.update(
                    { eventId: eventId, offerIdList: offerIdList },
                    successCallback,
                    errorCallback
                );
            },
            doOfferReEditing: function (offerId, successCallback, errorCallback) {
                return v1RequestOfferReEditRest.update({ offerId: offerId }, successCallback, errorCallback);
            },
            unlockBookedLockedBySupplier: function (offerId, successCallback, errorCallback) {
                return v1RequestOfferUnlockSupplierBookedLockRest.update(
                    { offerId: offerId },
                    successCallback,
                    errorCallback
                );
            },
            sendRequestOffers: function (jsonObject, successCallback, errorCallback) {
                return v1SendRequestOffers.update(jsonObject, successCallback, errorCallback);
            },
            requestOfferSchedule: function (params, successCallback, errorCallback) {
                return v1requestOfferSchedule.get(params, successCallback, errorCallback);
            },
            sendFinalPrice: function (params, successCallback, errorCallback) {
                return v1RequestOfferSendFinalPrice.update(params, successCallback, errorCallback);
            },
            uploadFinalPriceAttachments: function (params, successCallback, errorCallback) {
                return uploadFinalPriceAttachments(params, successCallback, errorCallback);
            },
            deleteFinalDetailsFile: function (jsonObject, successCallback, errorCallback) {
                return v1deleteFinalDetailsFiles['delete'](jsonObject, successCallback, errorCallback);
            },
            sendCancellationFees: function (params, successCallback, errorCallback) {
                return v1SendCancellationFees.update(params, successCallback, errorCallback);
            },
            updateCurrency: function (offerId, params, successCallback, errorCallback) {
                return v1RequestOfferUpdateCurrency.update(
                    { offerId: offerId },
                    params,
                    successCallback,
                    errorCallback
                );
            },
            approveFinalOffer: function (params, successCallback, errorCallback) {
                return v1RequestOfferApproveFinalOffer.update(params, successCallback, errorCallback);
            },
            approveCancellationFees: function (params, successCallback, errorCallback) {
                return v1ApproveCancellationFees.update(params, successCallback, errorCallback);
            },
            supplierAgreeTermsAndConditions: function (offerId, params, successCallback, errorCallback) {
                return v1supplierAgreeTermsAndConditions.update(
                    { offerId: offerId },
                    params,
                    successCallback,
                    errorCallback
                );
            },
            getSupplierAgreeTermsAndConditions: function (offerId, successCallback, errorCallback) {
                return v1getSupplierAgreeTermsAndConditions.get(
                    { offerId: offerId },
                    successCallback,
                    errorCallback
                );
            },
            requestOffersForMessageByEventId: function (eventId, successCallback, errorCallback) {
                return requestOffersForMessageByEventIdRest.list(
                    { eventId: eventId },
                    successCallback,
                    errorCallback
                );
            },
            getBillingAddress: function (offerId, successCallback, errorCallback) {
                return billingAddress.get({ offerId: offerId }, successCallback, errorCallback);
            },
            updateBillingAddress: function (offerId, jsonObject, successCallback, errorCallback) {
                return billingAddress.update({ offerId: offerId }, jsonObject, successCallback, errorCallback);
            },
            updateSupplierResponsibleUser: function (jsonObject, successCallback, errorCallback) {
                return v1UpdateSupplierResponsibleUser.update(jsonObject, successCallback, errorCallback);
            },
            updateSupplierCovidAdaptationMeasures: function (jsonObject, successCallback, errorCallback) {
                return save(jsonObject, successCallback, errorCallback);
            },
            getNotificationSettings: function (offerId, successCallback, errorCallback) {
                return notificationSettings.get({ offerId: offerId }, successCallback, errorCallback);
            },
            updateNotificationEmail: function (jsonObject, successCallback, errorCallback) {
                return notificationEmail.update(jsonObject, successCallback, errorCallback);
            },
            updateNotificationSettings: function (jsonObject, successCallback, errorCallback) {
                return notificationSettings.update(jsonObject, successCallback, errorCallback);
            },
            emailMessageToFinanceDept: function (params, successCallback, errorCallback) {
                return v1EmailMessageToFinanceDept.update(params, successCallback, errorCallback);
            },
            discardAllChangesByCreator: function (editedIds, lockedIds, successCallback, errorCallback) {
                return discardAllChangesByCreator.update(
                    { editedIds: editedIds, lockedIds: lockedIds },
                    successCallback,
                    errorCallback
                );
            },
            confirmInvalidOfferWithSupplier: function (offerId, params, successCallback, errorCallback) {
                return v1confirmWithSupplier.update({ offerId: offerId }, params, successCallback, errorCallback);
            },
            applyAllChangesByCreator: function (editedIds, lockedIds, successCallback, errorCallback) {
                return applyAllChangesByCreator.update(
                    { editedIds: editedIds, lockedIds: lockedIds },
                    successCallback,
                    errorCallback
                );
            },
            changeStatusToSent: function (data, successCallback, errorCallback) {
                return changeStatusToSent.put(data, successCallback, errorCallback);
            },
            updateBookingSupplierResponsibleUser: function (jsonObject, successCallback, errorCallback) {
                return v1UpdateBookingSupplierResponsibleUser.update(jsonObject, successCallback, errorCallback);
            },
            updateIncludeResponsibleBookingSupplierUser: function (jsonObject, successCallback, errorCallback) {
                return v1UpdateIncludeResponsibleBookingSupplierUser.update(
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            updateManagingSupplierResponsibleUser: function (jsonObject, successCallback, errorCallback) {
                return v1UpdateManagingSupplierResponsibleUser.update(jsonObject, successCallback, errorCallback);
            },
            updateHandOverDateAndSendEmail: function (jsonObject, successCallback, errorCallback) {
                return v1UpdateHandOverDateAndSendEmail.update(jsonObject, successCallback, errorCallback);
            },
            addNewResponsibleUser: function (jsonObject, successCallback, errorCallback) {
                return v1AddNewResponsibleUser.update(jsonObject, successCallback, errorCallback);
            },
            updateInternalReference: function (jsonObject, successCallback, errorCallback) {
                return v1UpdateInternalReference.update(jsonObject, successCallback, errorCallback);
            },
            deleteCovidFiles: function (jsonObject, successCallback, errorCallback) {
                return v1deleteCovidFiles['delete'](jsonObject, successCallback, errorCallback);
            },
            revertFinalPriceChanges: function (offerId, successCallback, errorCallback) {
                return v1RevertFinalPriceChanges.update({ offerId: offerId }, successCallback, errorCallback);
            },
            updateCancelPeriodEndDate: function (params, successCallback, errorCallback) {
                return v1UpdateCancelPeriodEndDate.update(params, successCallback, errorCallback);
            },
            offerLog: function (jsonObject, success, error) {
                return v1offerLogRest.get(jsonObject, success, error);
            },
            updateVatIncludedInPrice: function (jsonObject, successCallback, errorCallback) {
                return v1UpdateVatIncludedInPrice.update(jsonObject, successCallback, errorCallback);
            },
            updateSupplierAgreementNumber: function (jsonObject, successCallback, errorCallback) {
                return v1UpdateSupplierAgreementNumber.update(jsonObject, successCallback, errorCallback);
            },
            supplierContactUs: function (offerId, jsonObject, success, error) {
                return v1EmailSupplierContactRest.send({offerId: offerId}, jsonObject, success, error);
            },
            updateDiscount: function (offerId, jsonObject, successCallback, errorCallback) {
                return v1RequestOfferUpdateDiscount.update({ offerId: offerId }, jsonObject, successCallback, errorCallback);
            },

            // for Karma tests
            getRequestOfferUri: v1Uri,
        };
    },
]);

