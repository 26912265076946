(function () {
    'use strict';

    angular.module('ogGooglePlaces').directive('ogGooglePlaces', function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                location: '=',
                showFullAddressOptions: '=',
                locationChangeCallback: '&',
                isOnline: '=',
            },
            templateUrl: './app/components/directives/google_places/og_google_places.view.html',
            link: function ($scope, element) {
                $scope.tabIndex = (Math.random() * 6).toString().replace(/\./g, '_');

                window.googleMapsCallback(function () {
                    // The use of unique Id for google places option causes error in LIVE
                    // Apparently, google API tries to add the "autocomplete" effect before the element gets ready
                    var autocomplete = new google.maps.places.Autocomplete($('#google_places_ac')[0], {});
                    google.maps.event.addListener(autocomplete, 'place_changed', function () {
                        var place = autocomplete.getPlace();
                        $scope.location.name = place.name;
                        $scope.setLocationDetails(place);
                    });
                });

                $scope.resetAddress = function () {
                    $scope.resetAllAddress();
                    $scope.location.name = '';
                    //$scope.showFullAddressOptions = false;
                };

                $scope.handleOnChanged = function (locationNameChanged) {
                    $scope.locationChangeCallback({ newLocation: $scope.location });
                    if ($scope.location && locationNameChanged) {
                        var location = $scope.location.name;
                        window.googleMapsCallback(function () {
                            var geocoder = new google.maps.Geocoder();
                            geocoder.geocode({ address: location }, function (results, status) {
                                if (status === google.maps.GeocoderStatus.OK) {
                                    var place = results[0];
                                    $scope.setLocationDetails(place);
                                    element.click();
                                }
                            });
                        });
                    }
                };

                $scope.enableManualEntry = function () {
                    $scope.manualEntry = true;
                };

                $scope.resetAllAddress = function () {
                    $scope.location.city = '';
                    $scope.location.state = '';
                    $scope.location.country = '';
                    $scope.location.postalCode = '';
                    $scope.location.geoCoordinates = '';
                    $scope.location.address1 = '';
                    $scope.location.address2 = '';
                };

                $scope.setLocationDetails = function (place) {
                    if (place) {
                        $scope.showFullAddressOptions = true;
                    }
                    $scope.resetAllAddress();
                    $scope.location.geoCoordinates =
                        place.geometry.location.lat() + ',' + place.geometry.location.lng();
                    var route, streetNo;
                    for (var i = 0; i < place.address_components.length; i++) {
                        var placeType = place.address_components[i].types[0];
                        var longName = place.address_components[i].long_name;
                        var shortName = place.address_components[i].short_name;
                        if (placeType === 'street_number') {
                            streetNo = longName;
                        } else if (placeType === 'route') {
                            route = longName;
                        } else if (placeType === 'postal_town') {
                            $scope.location.city = longName;
                        } else if (placeType === 'administrative_area_level_1') {
                            $scope.location.state = shortName;
                        } else if (placeType === 'country') {
                            $scope.location.country = longName;
                        } else if (placeType === 'postal_code') {
                            $scope.location.postalCode = longName;
                        }
                    }
                    if (route) {
                        $scope.location.address1 = route;
                        if (streetNo) {
                            $scope.location.address1 = $scope.location.address1 + ' ' + streetNo;
                        }
                    }
                    $scope.$apply();
                };
            },
        };
    });
})();
