'use strict';

angular.module('core.existingServices').factory('Event', [
    '$resource',
    '$http',
    function ($resource, $http) {
        var eventRest = $resource(
            'api/v1/event/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: false},
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: false},
                save: {method: 'POST', isArray: false},
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var eventMaxParticipantRest = $resource(
            'api/v1/event/maxParticipant/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var eventUpdateDateRest = $resource(
            'api/v1/event/updateDate/:eventId',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var eventUpdateAmountRest = $resource(
            'api/v1/event/updateAmount/:eventId',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var creatorHomePageRest = $resource(
            'api/v1/event/creatorHomePage',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var eventStructureForDetailRest = $resource(
            'api/v1/event/:eventId/structureForDetail',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var getRequestsRest = $resource(
            'api/v1/event/:eventId/getRequests',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var eventShareRest = $resource(
            'api/v1/event/:eventId/share',
            {eventId: '@eventId'},
            {
                save: {method: 'POST', isArray: false},
            }
        );
        var eventDeleteUserRest = $resource(
            'api/v1/event/:eventId/user/:userId',
            {eventId: '@eventId', userId: '@userId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );
        var eventDeletePendingUserRest = $resource(
            'api/v1/event/user',
            {},
            {delete: {method: 'DELETE', isArray: false}}
        );
        var eventUsersRest = $resource(
            'api/v1/event/users/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var eventTemplateRest = $resource(
            'api/v1/event/template',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );
        var searchTemplatesRest = $resource(
            'api/v1/event/templates/search',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );
        var eventFromTemplateRest = $resource(
            'api/v1/event/fromTemplate',
            {},
            {
                save: {method: 'POST', isArray: false},
            }
        );
        var eventFromGuideRest = $resource(
            'api/v1/event/fromGuide',
            {},
            {
                save: {method: 'POST', isArray: false},
            }
        );
        var addFromTemplateRest = $resource(
            'api/v1/event/:eventId/fromTemplate',
            {},
            {
                add: {method: 'POST', isArray: false},
            }
        );
        var sendSurveyRest = $resource(
            'api/v1/event/:eventId/sendSurvey',
            {eventId: '@eventId'},
            {
                send: {method: 'POST', isArray: false},
            }
        );
        var checkParticipantAmountUpdateRest = $resource(
            'api/v1/event/:id/checkParticipantAmountUpdate',
            {id: '@id'},
            {
                check: {method: 'GET', isArray: false},
            }
        );
        var checkEventStatusUpdateRest = $resource(
            'api/v1/event/:eventId/checkEventStatusUpdate',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var checkEventDateUpdateRest = $resource(
            'api/v1/event/:id/checkEventDateUpdate',
            {id: '@id'},
            {
                check: {method: 'GET', isArray: false},
            }
        );
        var cancelActiveOffersRest = $resource(
            'api/v1/event/:eventId/cancelActiveOffers',
            {eventId: '@eventId'},
            {
                put: {method: 'PUT', isArray: false},
            }
        );
        var eventResponsibleUsersRest = $resource(
            'api/v1/event/eventResponsibleMembers',
            {},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var eventParticipantsShareDetailsRest = $resource(
            'api/v1/event/:eventId/participantsShareDetailsForEvent',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var addOrUpdateContentRest = $resource(
            'api/v1/event/:eventId/addOrUpdateContent',
            {eventId: '@eventId'},
            {
                do: {method: 'POST', isArray: false},
            }
        );
        var getContentRest = $resource(
            'api/v1/event/:eventId/getContent/:requestId',
            {eventId: '@eventId', requestId: '@requestId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var eventsForParticipantImport = $resource(
            'api/v1/event/allEvents',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var updateEventImage = function (formData, successCallback, errorCallback) {
            $http
                .post('api/v1/event/updateEventImage', formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };
        var updateBannerImage = function (formData, successCallback, errorCallback) {
            $http
                .post('api/v1/event/updateBannerImage', formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };
        var uploadTaxRelatedFileRest = function (params, successCallback, errorCallback) {
            var formData = new FormData();
            formData.append('file', params.attachedFile);
            formData.append('eventId', params.eventId);

            $http
                .post('api/v1/event/uploadTaxRelatedFiles', formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };
        var taxRelatedFileDeleteUserRest = $resource(
            'api/v1/event/deleteTaxRelatedFile/:eventTaxRelatedFileId',
            {eventTaxRelatedFileId: '@eventTaxRelatedFileId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );
        var eventInvitationDetailsRest = $resource(
            'api/v1/event/eventInvitationDetails/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
                put: {method: 'PUT', isArray: false},
            }
        );

        var eventDetailsRest = $resource(
            'api/v1/event/:eventId/details',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var eventRegistrationExpiryDateRest = $resource(
            'api/v1/event/:eventId/registrationExpiryDate',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: false},
            }
        );
        var findTemplateRest = $resource(
            'api/v1/event/templates/find',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );
        var findAllEventsRest = $resource(
            'api/v1/event/find',
            {},
            {
                findAll: {method: 'POST', isArray: false},
            }
        );
        var notificationSettings = $resource(
            'api/v1/event/:eventId/notificationSettings',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: false},
            }
        );
        var termsAndConditions = $resource(
            'api/v1/event/public/:eventId/termsAndConditions',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var updateTermsAndConditions = $resource(
            'api/v1/event/:eventId/termsAndConditions',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var bookingTerms = $resource(
            'api/v1/event/public/:eventId/bookingTerms',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var updateBookingTerms = $resource(
            'api/v1/event/:eventId/bookingTerms',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var findAllEvents = $resource(
            'api/v1/event/findAllEvents',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );

        var findAllEventsForManager = $resource(
            'api/v1/event/findAllEventsForManager',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );

        var participantCount = $resource(
            'api/v1/event/participantCount/:eventId',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var sms = $resource(
            'api/v1/event/smsService/:eventId',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: false},
                list: {method: 'GET', isArray: false},
            }
        );
        var defaultMessage = $resource(
            'api/v1/event/:eventId/defaultMessage',
            {eventId: '@eventId'},
            {
                show: {method: 'GET', isArray: false},
                hide: {method: 'PUT', isArray: false},
            }
        );
        var blockPaymentOption = $resource(
            'api/v1/event/:eventId/getBlockPaymentOption',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var hasAnyRequestsSentInEventOrAlreadyAnswered = $resource(
            'api/v1/event/:eventId/hasAnyRequestsSentInEventOrAlreadyAnswered',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var saveWillProcureInvitationFeedback = $resource(
            'api/v1/event/saveWillProcureInvitationFeedback/:eventId',
            {eventId: '@eventId'},
            {
                put: {method: 'PUT', isArray: false},
            }
        );
        var saveNoProcurementFeedback = $resource(
            'api/v1/event/saveNoProcurementFeedback/:eventId',
            {eventId: '@eventId'},
            {
                put: {method: 'PUT', isArray: false},
            }
        );
        var sendExpressBookingEmailAndCreateEventRest = $resource(
            'api/v1/event/sendExpressBookingEmailAndCreateEvent',
            {},
            {
                put: {method: 'PUT', isArray: false},
            }
        );
        var updateIsEventOnline = $resource(
            'api/v1/event/updateIsEventOnline/:eventId',
            {eventId: '@eventId'},
            {
                put: {method: 'PUT', isArray: false},
            }
        );
        var updateVatSetting = $resource(
            'api/v1/event/updateVatSetting/:eventId',
            {eventId: '@eventId'},
            {
                put: {method: 'PUT', isArray: false},
            }
        );
        var getEventMarkupDetailsRest = $resource(
            'api/v1/event/getEventMarkupDetails/:eventId',
            {eventId: '@eventId'},
            {
                put: {method: 'GET', isArray: false},
            }
        );
        var updateEventMarkupValueRest = $resource(
            'api/v1/event/updateEventMarkupValue/:eventId',
            {eventId: '@eventId'},
            {
                put: {method: 'PUT', isArray: false},
            }
        );
        var getSummarySharedUsersForBudgetRest = $resource(
            'api/v1/event/getSummarySharedUsersForBudget/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var uploadInformation = $resource(
            'api/v1/event/:eventId/uploadInformation',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );
        var updateReservationCancelDays = $resource(
            'api/v1/event/:eventId/updateReservationCancelDays',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );
        var deleteEventTemplate = $resource(
            'api/v1/event/:eventId/deleteEventTemplate',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        let sendEmailForInvitationBlockerManagement = $resource(
            'api/v1/event/sendEmailForInvitationBlocker/management',
            {},
            {send: {method: 'POST', isArray: false}}
        );

        let findEventByEventHashId = $resource(
            'api/v1/event/findEventByHashId/:eventHashId',
            {eventHashId: '@eventHashId'},
            {
                get: {method: 'GET', isArray: false},
            }

        );

        return {
            list: function (jsonObject, successCallback, errorCallback) {
                return eventRest.list(jsonObject, successCallback, errorCallback);
            },
            searchTemplates: function (jsonObject, successCallback, errorCallback) {
                return searchTemplatesRest.list(jsonObject, successCallback, errorCallback);
            },
            creatorHomePage: function (successCallback, errorCallback) {
                return creatorHomePageRest.get({}, successCallback, errorCallback);
            },
            get: function (eventId, success, error) {
                return eventRest.get({eventId: eventId}, success, error);
            },
            getMaxParticipant: function (eventId, success, error) {
                return eventMaxParticipantRest.get({eventId: eventId}, success, error);
            },
            getStructureForDetail: function (eventId, success, error) {
                return eventStructureForDetailRest.get({eventId: eventId}, success, error);
            },
            getRequests: function (eventId, success, error) {
                return getRequestsRest.get({eventId: eventId}, success, error);
            },
            search: function (eventStatus, pattern, successCallback, errorCallback) {
                return eventRest.list({status: eventStatus, pattern: pattern}, successCallback, errorCallback);
            },
            update: function (event, successCallback, errorCallback) {
                return eventRest.update({eventId: event.id}, event, successCallback, errorCallback);
            },
            updateDate: function (event, successCallback, errorCallback) {
                return eventUpdateDateRest.update({eventId: event.id}, event, successCallback, errorCallback);
            },
            updateAmount: function (event, successCallback, errorCallback) {
                return eventUpdateAmountRest.update({eventId: event.id}, event, successCallback, errorCallback);
            },
            save: function (data, successCallback, errorCallback) {
                var resource = eventRest;
                if (data.templateId) {
                    resource = eventFromTemplateRest;
                }
                return resource.save({}, data, successCallback, errorCallback);
            },
            create: function (data, successCallback, errorCallback) {
                var resource = eventRest;
                if (data.templateId) {
                    resource = eventFromGuideRest;
                }
                return resource.save({}, data, successCallback, errorCallback);
            },
            addFromEventTemplate: function (params, successCallback, errorCallback) {
                return addFromTemplateRest.add({eventId: params.eventId}, params, successCallback, errorCallback);
            },
            saveTemplate: function (data, success, errorCallback) {
                return eventTemplateRest.save({}, data, success, errorCallback);
            },
            getUsers: function (jsonObject, success, error) {
                return eventUsersRest.list(jsonObject, success, error);
            },
            share: function (eventId, jsonObject, successCallback, errorCallback) {
                return eventShareRest.save({eventId: eventId}, jsonObject, successCallback, errorCallback);
            },
            removeSharedUser: function (jsonObject, successCallback, errorCallback) {
                return eventDeleteUserRest['delete'](jsonObject, successCallback, errorCallback);
            },
            removePendingUser: function (jsonObject, successCallback, errorCallback) {
                return eventDeletePendingUserRest['delete'](jsonObject, successCallback, errorCallback);
            },
            sendSurvey: function (eventId, successCallback, errorCallback) {
                return sendSurveyRest.send({eventId: eventId}, successCallback, errorCallback);
            },
            delete: function (eventId, success, error) {
                return eventRest['delete']({eventId: eventId}, success, error);
            },
            checkParticipantAmountUpdate: function (jsonObject, success, error) {
                return checkParticipantAmountUpdateRest.check(jsonObject, success, error);
            },
            checkEventStatusUpdate: function (jsonObject, successCallback, errorCallback) {
                return checkEventStatusUpdateRest.get(jsonObject, successCallback, errorCallback);
            },
            checkEventDateUpdate: function (jsonObject, success, error) {
                return checkEventDateUpdateRest.check(jsonObject, success, error);
            },
            cancelActiveOffers: function (eventId, data, successCallback, errorCallback) {
                return cancelActiveOffersRest.put({eventId: eventId}, data, successCallback, errorCallback);
            },
            getEventResponsibleUsers: function (jsonObject, successCallback, errorCallback) {
                return eventResponsibleUsersRest.list(jsonObject, successCallback, errorCallback);
            },
            getParticipantsShareDetailsForEvent: function (eventId, successCallback, errorCallback) {
                return eventParticipantsShareDetailsRest.list({eventId: eventId}, successCallback, errorCallback);
            },
            addOrUpdateContent: function (eventId, data, successCallback, errorCallback) {
                return addOrUpdateContentRest.do({eventId: eventId}, data, successCallback, errorCallback);
            },
            getContent: function (eventId, requestId, successCallback, errorCallback) {
                return getContentRest.get(
                    {eventId: eventId, requestId: requestId},
                    successCallback,
                    errorCallback
                );
            },
            getAllEvents: function (eventId, successCallback, errorCallback) {
                return eventsForParticipantImport.list({eventId: eventId}, successCallback, errorCallback);
            },
            updateEventImage: function (formData, successCallback, errorCallback) {
                return updateEventImage(formData, successCallback, errorCallback);
            },
            updateBannerImage: function (formData, successCallback, errorCallback) {
                return updateBannerImage(formData, successCallback, errorCallback);
            },
            uploadTaxRelatedFile: function (params, successCallback, errorCallback) {
                return uploadTaxRelatedFileRest(params, successCallback, errorCallback);
            },
            deleteTaxRelatedFile: function (eventTaxRelatedFileId, successCallback, errorCallback) {
                return taxRelatedFileDeleteUserRest['delete'](
                    {eventTaxRelatedFileId: eventTaxRelatedFileId},
                    successCallback,
                    errorCallback
                );
            },
            checkPictureCropDetails: function (imageurl) {
                if (!imageurl) {
                    return false;
                }
                if (!imageurl.coordinates[4] || !imageurl.coordinates[5]) {
                    return false;
                }
                return true;
            },
            getEventInvitationDetails: function (eventId, successCallback, errorCallback) {
                eventInvitationDetailsRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            updateEventInvitationDetails: function (eventId, data, successCallback, errorCallback) {
                eventInvitationDetailsRest.put({eventId: eventId}, data, successCallback, errorCallback);
            },
            getEventDetails: function (eventId, successCallback, errorCallback) {
                eventDetailsRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            getRegistrationExpiryDate: function (eventId, successCallback, errorCallback) {
                eventRegistrationExpiryDateRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            updateRegistrationExpiryDate: function (eventId, data, successCallback, errorCallback) {
                eventRegistrationExpiryDateRest.update({eventId: eventId}, data, successCallback, errorCallback);
            },
            findTemplate: function (jsonObject, successCallback, errorCallback) {
                return findTemplateRest.list(jsonObject, successCallback, errorCallback);
            },
            findAllEventsRest: function (jsonObject, successCallback, errorCallback) {
                return findAllEventsRest.findAll(jsonObject, successCallback, errorCallback);
            },
            getNotificationSettings: function (eventId, successCallback, errorCallback) {
                return notificationSettings.get({eventId: eventId}, successCallback, errorCallback);
            },
            updateNotificationSettings: function (jsonObject, successCallback, errorCallback) {
                return notificationSettings.update(jsonObject, successCallback, errorCallback);
            },
            getTermsAndConditions: function (eventId, successCallback, errorCallback) {
                return termsAndConditions.get({eventId: eventId}, successCallback, errorCallback);
            },
            updateTermsAndConditions: function (jsonObject, successCallback, errorCallback) {
                return updateTermsAndConditions.update(
                    {eventId: jsonObject.eventId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            getBookingTerms: function (eventId, successCallback, errorCallback) {
                return bookingTerms.get({eventId: eventId}, successCallback, errorCallback);
            },
            updateBookingTerms: function (jsonObject, successCallback, errorCallback) {
                return updateBookingTerms.update(
                    {eventId: jsonObject.eventId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            findAllEvents: function (jsonObject, successCallback, errorCallback) {
                return findAllEvents.list(jsonObject, successCallback, errorCallback);
            },

            findAllEventsForManager: function (jsonObject, successCallback, errorCallback) {
                return findAllEventsForManager.list(jsonObject, successCallback, errorCallback);
            },

            updateMaxParticipant: function (jsonObject, successCallback, errorCallback) {
                return participantCount.update(jsonObject, successCallback, errorCallback);
            },
            updateSMSService: function (jsonObject, successCallback, errorCallback) {
                return sms.update(jsonObject, successCallback, errorCallback);
            },
            getEventUsedSMSService: function (eventId, successCallback, errorCallback) {
                return sms.list({eventId: eventId}, successCallback, errorCallback);
            },
            showDefaultMessage: function (eventId, successCallback, errorCallback) {
                return defaultMessage.show({eventId: eventId}, successCallback, errorCallback);
            },
            hideDefaultMessage: function (eventId, successCallback, errorCallback) {
                return defaultMessage.hide({eventId: eventId}, successCallback, errorCallback);
            },
            getBlockPaymentOption: function (eventId, successCallback, errorCallback) {
                return blockPaymentOption.get({eventId: eventId}, successCallback, errorCallback);
            },
            hasAnyRequestsSentInEventOrAlreadyAnswered: function (eventId, successCallback, errorCallback) {
                return hasAnyRequestsSentInEventOrAlreadyAnswered.get(
                    {eventId: eventId},
                    successCallback,
                    errorCallback
                );
            },
            saveWillProcureInvitationFeedback: function (eventId, params, successCallback, errorCallback) {
                return saveWillProcureInvitationFeedback.put(
                    {eventId: eventId},
                    params,
                    successCallback,
                    errorCallback
                );
            },
            saveNoProcurementFeedback: function (eventId, params, successCallback, errorCallback) {
                return saveNoProcurementFeedback.put({eventId: eventId}, params, successCallback, errorCallback);
            },
            sendExpressBookingEmailAndCreateEvent: function (data, successCallback, errorCallback) {
                return sendExpressBookingEmailAndCreateEventRest.put({}, data, successCallback, errorCallback);
            },
            updateIsEventOnline: function (eventId, data, successCallback, errorCallback) {
                return updateIsEventOnline.put({eventId: eventId}, data, successCallback, errorCallback);
            },

            updateVatSetting: function (eventId, data, successCallback, errorCallback) {
                return updateVatSetting.put({eventId: eventId}, data, successCallback, errorCallback);
            },

            getEventMarkupDetails: function (eventId, successCallback, errorCallback) {
                return getEventMarkupDetailsRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            updateEventMarkupValue: function (eventId, data, successCallback, errorCallback) {
                return updateEventMarkupValueRest.put({eventId: eventId}, data, successCallback, errorCallback);
            },
            getSummarySharedUsersForBudget: function (jsonObject, successCallback, errorCallback) {
                return getSummarySharedUsersForBudgetRest.get(jsonObject, successCallback, errorCallback);
            },
            uploadInformation: function (jsonObject, successCallback, errorCallback) {
                return uploadInformation.update(
                    {eventId: jsonObject.eventId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            updateReservationCancelDays: function (jsonObject, successCallback, errorCallback) {
                return updateReservationCancelDays.update(
                    {eventId: jsonObject.eventId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            deleteEventTemplate: function (jsonObject, successCallback, errorCallback) {
                return deleteEventTemplate.update(
                    {eventId: jsonObject.eventId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            sendEmailForInvitationBlockerManagement: function (jsonObject, successCallback, errorCallback) {
                return sendEmailForInvitationBlockerManagement.send(jsonObject, successCallback, errorCallback);
            },
            findEventByEventHashId: function (eventId, successCallback, errorCallback) {
                return findEventByEventHashId.get({eventHashId: eventId}, successCallback, errorCallback);
            },
        };
    },
]);

