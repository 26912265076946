/* global angular */
'use strict';

angular.module('core.existingServices').factory('HintService', function () {
    var reloadHint;

    function setReloadHint(reload) {
        reloadHint = reload;
    }

    function getReloadHint() {
        return reloadHint;
    }

    return {
        setReloadHint: function (reload) {
            return setReloadHint(reload);
        },
        getReloadHint: function () {
            return getReloadHint();
        },
    };
});

