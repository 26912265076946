'use strict';

import {ScopeFunctions} from '../../utils/global/scopeFunctions';
import {OgModal} from "../../utils/global/ogModal";

angular.module('dialog').directive('ogUserTermsModal', [
    '$rootScope',
    '$translate',
    '$stateParams',
    'GlobalOption',
    function ($rootScope, $translate, $stateParams, GlobalOption) {
        return new OgModal({
            name: 'user:terms',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);

                $scope.initDialog = function (data) {
                    $scope.languageSelected = data.langSelected;
                    $scope.setUserTermsTabActive = true;
                    $scope.setSupplierTermsTabActive = false;
                    loadTermsOfUseFn();
                };

                $scope.$on('lang:change', function (event, data) {
                    $scope.languageSelected = data.lang;
                });

                function loadTermsOfUseFn() {
                    $scope.startProgress();
                    GlobalOption.get(
                        'TERMS_OF_USE',
                        (resp) => {
                            $scope.termsOfUse = resp[$scope.languageSelected];
                            $scope.endProgress();
                        },
                        $scope.endWithError
                    );
                }

                $scope.loadSupplierTermsOfUseFn = function () {
                    $scope.setUserTermsTabActive = false;
                    $scope.setSupplierTermsTabActive = true;
                    $scope.startProgress();
                    GlobalOption.get(
                        'SUPPLIER_TERMS_OF_USE',
                        (resp) => {
                            $scope.supplierTermsOfUse = resp[$scope.languageSelected];
                            $scope.endProgress();
                        },
                        $scope.endWithError
                    );
                }
            },
        });
    },
]);

