export const getEnvVariables = () => {
    var apiUrl = "";
    var baseUrl = '/';
    var environment = "STAGE";
    switch (window.location.hostname) {
        case 'app.localhost':
        case 'localhost':
            apiUrl = environment === 'LOCAL' ? 'http://app.localhost:8080/' : 'https://admin-qat.eventlogic.se/';
            break;
        case 'bigtravel.localhost':
            apiUrl = environment === 'LOCAL' ? 'http://bigtravel.localhost:8080/' : 'https://admin-qat-bigtravel.eventlogic.se/';
            break;
        case 'skanskamoten.localhost':
            apiUrl = environment === 'LOCAL' ? 'http://skanskamoten.localhost:8080/' : 'https://admin-qat-skanskamoten.eventlogic.se/';
            break;
        case 'qat-stage.eventlogic.se':
        case 'qat-stage2.eventlogic.se':
            apiUrl = 'https://admin-qat.eventlogic.se/';
            break;
        case 'qat-bigtravel.eventlogic.se':
        case 'qat-bigtravel2.eventlogic.se':
            apiUrl = 'https://admin-qat-bigtravel.eventlogic.se/';
            break;
        case 'qat-skanskamoten.eventlogic.se':
            apiUrl = 'https://admin-qat-skanskamoten.eventlogic.se/';
            break;
        case 'uat-stage.eventlogic.se':
            apiUrl = 'https://admin-uat.eventlogic.se/';
            break;
        case 'uat-bigtravel.eventlogic.se':
            apiUrl = 'https://admin-uat-bigtravel.eventlogic.se/';
            break;
        case 'uat-skanskamoten.eventlogic.se':
            apiUrl = 'https://admin-uat-skanskamoten.eventlogic.se/';
            break;
        case 'app.eventlogic.se':
            apiUrl = 'https://admin.eventlogic.se/';
            break;
        case 'app.bigtravel.se':
            apiUrl = 'https://admin.bigtravel.se/';
            break;
        case 'app.skanskamoten.se':
            apiUrl = 'https://admin.skanskamoten.se/';
            break;
        case 'dr-app.eventlogic.se':
            apiUrl = 'https://dr-admin.eventlogic.se/';
            break;
        case 'dr-bigtravel-app.eventlogic.se':
            apiUrl = 'https://dr-bigtravel-admin.eventlogic.se/';
            break;
        case 'dr-skanskamoten-app.eventlogic.se':
            apiUrl = 'https://dr-skanskamoten-admin.eventlogic.se/';
            break;
        default:
            apiUrl = 'https://admin-qat.eventlogic.se/';
            break;
    }
        
    return {
        apiUrl: apiUrl,
        baseUrl: baseUrl
    }
}