class EventCreateWelcome {
    constructor($element, close, $scope) {
        this._injected = { $element, close, $scope };
    }

    startGuide() {
        this.close(true);
    }

    skipGuide() {
        this.close(false);
    }

    close(startGuide = false) {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(startGuide, 500);
    }
}
EventCreateWelcome.$inject = ['$element', 'close', '$scope'];
export default EventCreateWelcome;
