'use strict';
import { OgModal } from "../../utils/global/ogModal";
import { ScopeFunctions } from "../../utils/global/scopeFunctions";
import CommonFunctions from '../../utils/CommonFunctionUtil';
import _ from 'underscore';

angular.module('dialog').directive('ogParticipantEmailModal', [
    '$rootScope',
    '$translate',
    '$sce',
    'AuthenticationService',
    'FieldChangedSvc',
    'Participant',
    'ParticipantActivity',
    'Event',
    'ProcessLog',
    '$routeParams',
    'EventEnum',
    '$state',
    '$timeout',
    'ogScrollService',
    function (
        $rootScope,
        $translate,
        $sce,
        AuthenticationService,
        FieldChangedSvc,
        Participant,
        ParticipantActivity,
        Event,
        ProcessLog,
        $routeParams,
        EventEnum,
        $state,
        $timeout,
        ogScrollService
    ) {
        return new OgModal({
            name: 'participant:email',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                var subjectCache;
                var eventIdCache;
                var fieldChanged = FieldChangedSvc;
                $scope.changeInvitationForms = {};
                $scope.initDialog = function (params) {
                    $scope.showSendInvitationConfirmation = false;
                    $scope.eventId = params.eventId;
                    $scope.sendGroupMessageCondition = params.sendGroupMessageCondition;
                    Event.getEventUsedSMSService($scope.eventId, function (data) {
                        $scope.useSMS = data.useSMS;
                    });
                    $scope.startProgress();
                    Event.getEventInvitationDetails(
                        $scope.eventId,
                        function (response) {
                            $scope.invitationDetails = response;
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                    getCurrentProcessStatus($scope.eventId);
                    $scope.invitationSuccessMessage = {};
                    $scope.orgizerDetailsNeededMessage = false;
                    $scope.showSMSActivationMessage = false;
                    $scope.showSuccessMessage = false;
                    $scope.showHideSuccessMessage = false;
                    $scope.openOrganizerDetails = false;
                    $scope.successMessage = null;
                    $scope.smsInfoLabel =
                        $translate.instant('participant.invite.by.sms.info') +
                        $translate.instant('common.translation.dynamic.sms.cost.text') +
                        ')';
                    $scope.clearAttachedFiles();
                    if (params.type === 'CUSTOM') {
                        $scope.originalSelectedParticipants = angular.copy(params.selectedParticipants);
                        $scope.selectedParticipants = params.selectedParticipants;
                    }
                    Event.getNotificationSettings(params.eventId, function (response) {
                        $scope.notificationEmail = response.participantActivityNotificationEmail;
                        $scope.notificationType = response.notificationFrequency.name;
                    });
                    $scope.successCallback = params.successCallback;
                    $scope.participantSettingsCallback = params.participantSettingsCallback;
                    $scope.type = params.type;
                    $scope.attachments = params.eventMessage ? params.eventMessage.attachments : null;
                    $scope.readOnly = false;
                    $scope.disableSenderName =
                        AuthenticationService.getUserData() &&
                        AuthenticationService.getUserData().userGroup &&
                        AuthenticationService.getUserData().userGroup.userGroupDisableSenderName &&
                        AuthenticationService.getUserData().userGroup.userGroupDisableSenderName.name === 'TRUE';
                    if (params.hash) {
                        let _eventMessage = params.eventMessage;
                        $scope.email = {};
                        $scope.email.emailSenderName = _eventMessage.emailSenderName;
                        $scope.email.smsSenderName = _eventMessage.smsSenderName;
                        $scope.email.replyToEmail = _eventMessage.replyToEmail;
                        $scope.email.subject = _eventMessage.emailSubject;
                        $scope.email.invitation = true;
                        $scope.email.eventSite = _eventMessage.isEventSite;
                        $scope.email.sendTo = 'CUSTOM';
                        $scope.email.includeRegistrationLink = _eventMessage.isPageLink;
                        $scope.email.show = _eventMessage.emailPage;
                        $scope.email.sendSMS = $scope.useSMS;
                        $scope.email.eventId = $scope.eventId;
                        $scope.email.provider = _eventMessage.provider.name;
                        $scope.email.sendToByStatus = _eventMessage.sendToByStatus
                            ? _eventMessage.sendToByStatus.name
                            : undefined;
                        $scope.email.sendGroupMessage = _eventMessage.sendGroupMessage;
                        $scope.email.messageBySMS = _eventMessage.messageBySMS;
                        $scope.email.messageByEmail = _eventMessage.messageByEmail;
                        $scope.email.addLinkToSms = _eventMessage.addLinkToSms;
                        $scope.email.selectedParticipants = $scope.selectedParticipants;
                        $scope.email.smsMessage = _eventMessage.smsMessage;
                        $scope.email.message = _eventMessage.emailMessage;
                        $scope.email.isDraft = _eventMessage.isDraft;
                        if ($scope.email.isDraft) {
                            $scope.originalParticipants = angular.copy(params.participants);
                            $scope.participants = params.participants;
                            $scope.participantIdList = getParticipantsIdList($scope.participants);
                            $scope.email.participantIds = getParticipantsIdList($scope.selectedParticipants);
                            $scope.loadMultipleSelect();
                        }
                        $scope.email.isScheduled = _eventMessage.isScheduled;
                        if ($scope.email.isScheduled) {
                            $scope.email.scheduleDate = _eventMessage.scheduleDate;
                        }
                        $scope.email.hash = _eventMessage.hash;
                        $scope.sendGroupMessageCondition = _eventMessage.sendGroupMessage;
                        $scope.readOnly = params.readOnly;
                        return;
                    }
                    // check if the event is same for saving the the subject and message.if the event is different then reset
                    // for other event
                    if ($scope.eventId != eventIdCache) {
                        subjectCache = '';
                    }

                    let _emailSubject = subjectCache
                        ? subjectCache
                        : $translate.instant('email.event.participant.invitation.subject');

                    Participant.getInvitationAdditionalConfigForEvent(
                        params.eventId,
                        function (response) {
                            $scope.email.emailSenderName = response.emailSenderName;
                            $scope.email.smsSenderName = response.smsSenderName;
                            $scope.email.replyToEmail = response.replyToEmail;
                            $scope.defaultSenderName = response.defaultSenderName;
                            $scope.defaultReplyToEmail = response.defaultReplyToEmail;
                            if ($scope.type === 'INVITATION') {
                                /*if(response.invitationEmailSubject){
                                    $scope.email.subject = angular.copy(response.invitationEmailSubject);
                                }else {
                                    $scope.email.subject = _emailSubject;
                                }*/
                                $scope.email.subject = _emailSubject;
                            }
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );

                    if ($scope.type == 'INVITATION') {
                        $scope.email = {
                            sendTo: 'NOT_INVITED',
                            invitation: true,
                            eventSite: true,
                            sendSMS: $scope.useSMS,
                            includeRegistrationLink: true,
                            show: 'showRegistrationPage',
                        };
                    } else if ($scope.type == 'EVENT_REMINDER') {
                        $scope.email = {
                            sendTo: 'ATTENDING',
                            invitation: true,
                            eventSite: true,
                            sendSMS: $scope.useSMS,
                            includeRegistrationLink: true,
                            show: 'showInfoPage',
                        };
                    } else if ($scope.type == 'EVENT_SITE') {
                        $scope.email = {
                            sendTo: 'ALL',
                            invitation: true,
                            eventSite: true,
                            sendSMS: $scope.useSMS,
                            includeRegistrationLink: true,
                            show: 'showInfoPage',
                        };
                    } else if ($scope.type == 'CUSTOM') {
                        $scope.email = {
                            sendTo: 'CUSTOM',
                            invitation: true,
                            eventSite: false,
                            sendSMS: $scope.useSMS,
                            includeRegistrationLink: true,
                            show: 'showInfoPage',
                        };
                    } else {
                        $scope.email = {
                            sendTo: 'ALL',
                            eventSite: false,
                            sendSMS: $scope.useSMS,
                            includeRegistrationLink: false,
                            show: 'showInfoPage',
                        };
                    }
                    $scope.email.eventId = params.eventId;
                    $scope.email.provider = 'BY_EMAIL_ONLY';
                    $scope.email.sendToByStatus = undefined;
                    $scope.email.sendGroupMessage = $scope.sendGroupMessageCondition;
                    $scope.email.messageBySMS = false;
                    $scope.email.messageByEmail = false;
                    $scope.email.addLinkToSms = false;
                    $scope.isInvitationSentBySms = false;
                    $scope.email.page = $scope.getPageTemplate(params.type);
                    $scope.email.selectedParticipants = $scope.selectedParticipants;
                    $scope.email.useDefault = true;
                    $scope.data = {};
                };

                $scope.closeDialog = function () {
                    subjectCache = $scope.email.subject;
                    eventIdCache = $routeParams.eventId;
                    $scope.close();
                };

                // these 2 methods are used at invitation parts
                $scope.getDefaultMessage = function () {
                    return $translate.instant('default.sms.message');
                };

                $scope.toggleDefaultMessage = function () {
                    if (
                        $scope.email.provider === 'BY_SMS_ONLY' ||
                        $scope.email.provider === 'BY_EMAIL_AND_SMS_BOTH'
                    ) {
                        $scope.email.message = $scope.getDefaultMessage();
                        $scope.isInvitationSentBySms = true;
                    } else {
                        $scope.email.message = null;
                        $scope.isInvitationSentBySms = false;
                    }
                    $scope.showSMSActivationMessageOnMyself = false;
                    $scope.showSMSActivationMessage = false;
                    $scope.showSendInvitationConfirmation = false;
                };

                // these 2 methods are used at message/custom parts
                $scope.getDefaultCustomMessage = function () {
                    return $translate.instant('default.email.sms.message');
                };

                $scope.toggleDefaultCustomMessage = function () {
                    if (
                        $scope.email.messageBySMS ||
                        $scope.email.provider === 'BY_SMS_ONLY' ||
                        $scope.email.provider === 'BY_EMAIL_AND_SMS_BOTH'
                    ) {
                        $scope.email.smsMessage = $scope.getDefaultCustomMessage();
                        $scope.email.useDefault = false;
                        $scope.email.addLinkToSms = true;
                    } else {
                        $scope.email.smsMessage = null;
                        $scope.email.useDefault = true;
                        $scope.email.addLinkToSms = false;
                    }
                    if ($scope.email.isDraft) {
                        $scope.loadMultipleSelect();
                    }
                };

                $scope.handleOnchanged = function (modalName, currentValue) {
                    fieldChanged.checkOnChanged(modalName, currentValue);
                    let _selectedParticipants = [];
                    angular.forEach(currentValue, function (id) {
                        let participant = getParticipantById(id);
                        if (participant) {
                            let pObject = getParticipantObject(participant);
                            _selectedParticipants.push(pObject);
                        }
                    });
                    $scope.email.selectedParticipants = _selectedParticipants;
                };

                $scope.loadMultipleSelect = function () {
                    $timeout(function () {
                        $().ready(function () {
                            $('#participantEdit').select2();
                        });
                    }, 500);
                };

                function getParticipantObject(participant) {
                    return {
                        id: participant.id,
                        name: participant.name,
                        email: participant.email,
                        phone: participant.phone,
                    };
                }

                function getParticipantsIdList(participants) {
                    let participantsList = [];
                    angular.forEach(participants, function (participant) {
                        participantsList.push(participant.id + '');
                    });
                    return participantsList;
                }

                $scope.getParticipantNameById = function (id) {
                    let participant = getParticipantById(id);
                    if (participant) {
                        return $scope.getParticipantName(participant);
                    }
                };

                function getParticipantById(id) {
                    return _.find($scope.originalParticipants, (p) => {
                        return p.id + '' === id + '';
                    });
                }

                $scope.trustAsHtml = function (string) {
                    return $sce.trustAsHtml(string);
                };

                $scope.getSMSMessageDescription = function () {
                    return $translate.instant('invitation.sms.message.description');
                };

                $scope.getEnterEmailText = function () {
                    return $translate.instant('participant.enter.email.text');
                };

                $scope.getEmailAddrText = function () {
                    return $translate.instant('participant.enter.email.addr.text');
                };

                $scope.toggleAddLinkToSms = function () { };

                $scope.toggleMessageBySMS = function () {
                    if (
                        $scope.type === 'CUSTOM' &&
                        $scope.email &&
                        !$scope.email.smsMessage &&
                        $scope.email.useDefault &&
                        !$scope.email.hash
                    ) {
                        $scope.toggleDefaultCustomMessage();
                    }
                };

                $scope.toggleMessageByEmail = function () { };

                $scope.getPageTemplate = function (type) {
                    if (!type) {
                        return;
                    }
                    let page = 'email';
                    let prefix = './app/components/modals/dialog/message-templates/';
                    if ($scope.email && $scope.email.provider === 'BY_SMS_ONLY') {
                        page = 'sms';
                    } else if ($scope.email && $scope.email.provider === 'BY_EMAIL_AND_SMS_BOTH') {
                        page = 'email_sms';
                    }
                    return angular.lowercase(prefix + type + '_' + page + '_template.html');
                };

                $scope.resetEmailSmsBothActivationBtn = function () {
                    $scope.email.messageBySMS = false;
                    $scope.email.messageByEmail = false;
                };

                $scope.changeShowPage = function () {
                    if ($scope.type === 'CUSTOM') {
                        if ($scope.email.eventSite === true) {
                            $scope.email.show = 'showRegistrationPage';
                            $scope.email.includeRegistrationLink = false;
                            $scope.email.hideRegistrationButtons = false;
                        }
                    }
                };

                $scope.disableLinks = function () {
                    return (
                        $scope.email !== undefined && $scope.email.eventSite === true && $scope.type === 'CUSTOM'
                    );
                };

                $scope.toggleShowPage = function () {
                    if ($scope.type === 'CUSTOM') {
                        if ($scope.email.includeRegistrationLink === true) {
                            $scope.email.show = 'showInfoPage';
                            $scope.email.eventSite = false;
                        }
                    }
                };

                $scope.activateSMSAndDoSend = function () {
                    $scope.startProgress();
                    Event.updateSMSService(
                        { eventId: $scope.eventId, useSMS: true },
                        (response) => {
                            $scope.useSMS = response.useSMS;
                            $scope.email.sendSMS = $scope.useSMS;
                            $scope.doSend();
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                };

                $scope.send = function () {
                    if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                        return;
                    }
                    getOrganizerDetailsFn();
                };

                $scope.openOrganizerDetailsSection = function () {
                    $scope.openOrganizerDetails = true;
                    $scope.orgizerDetailsNeededMessage = false;
                };

                $scope.closeOrganizerDetailsSection = function () {
                    $scope.openOrganizerDetails = false;
                    $scope.orgizerDetailsNeededMessage = true;
                };

                $scope.updateOrganizerDetails = function () {
                    $scope.openOrganizerDetails = false;
                    if (!$scope.changeInvitationForms.formForOrganizer.$valid) {
                        CommonFunctions.touchFormFields($scope.changeInvitationForms.formForOrganizer);
                        return;
                    }
                    var invitationDetails = $scope.invitationDetails;
                    $scope.startProgress();
                    Event.updateEventInvitationDetails(
                        $scope.eventId,
                        invitationDetails,
                        function (data) {
                            $rootScope.$broadcast('invitation:details:update', data);
                            $scope.endProgress();
                        },
                        $scope.endWithError
                    );
                };

                $scope.useOrganizerAsDataController = function () {
                    var _data = {
                        name: $scope.invitationDetails.organizer.company,
                        email: $scope.invitationDetails.organizer.email,
                    };
                    $scope.invitationDetails.dataAccessHandler = angular.copy(_data);
                };

                $scope.saveDraft = function () {
                    if ($scope.attachedFiles.maxSizeExceeded === true) {
                        return;
                    }
                    if ($scope.attachedFiles.links !== null && $scope.attachedFiles.links.length > 0) {
                        if ($scope.email.message) {
                            //clear all existing attachments
                            $scope.email.message = $scope.email.message.replace(
                                /<a.+?(?=glyphicon glyphicon-paperclip).+?(?=a>)a>/g,
                                ''
                            );
                            $scope.email.message += '\n\n';
                        }
                        angular.forEach($scope.attachedFiles.links, function (link) {
                            var attachment =
                                '<a href="' +
                                link.fileUrl +
                                '" target="_blank"><i class="glyphicon glyphicon-paperclip"></i> ' +
                                link.name +
                                '</a>' +
                                '\n';
                            if ($scope.email.message) {
                                $scope.email.message += attachment;
                            } else {
                                $scope.email.message = attachment;
                            }
                        });
                    }
                    let params = getParamsForEmail(false);
                    params['source'] = 'draft';
                    params['senderName'] = $scope.email.emailSenderName;
                    params['smsSenderName'] = $scope.email.smsSenderName;
                    $scope.startProgress();
                    Participant.saveEventMessage(
                        params,
                        eventMessageSaveSuccessCallback,
                        $scope.endWithErrorOverlay
                    );
                };

                $scope.doSend = function () {
                    if ($scope.attachedFiles.maxSizeExceeded === true) {
                        return;
                    }
                    if ($scope.attachedFiles.links !== null && $scope.attachedFiles.links.length > 0) {
                        if ($scope.email.message) {
                            //clear all existing attachments
                            $scope.email.message = $scope.email.message.replace(
                                /<a.+?(?=glyphicon glyphicon-paperclip).+?(?=a>)a>/g,
                                ''
                            );
                            $scope.email.message += '\n\n';
                        }
                        angular.forEach($scope.attachedFiles.links, function (link) {
                            var attachment =
                                '<a href="' +
                                link.fileUrl +
                                '" target="_blank"><i class="glyphicon glyphicon-paperclip"></i> ' +
                                link.name +
                                '</a>' +
                                '\n';
                            if ($scope.email.message) {
                                $scope.email.message += attachment;
                            } else {
                                $scope.email.message = attachment;
                            }
                        });
                    }

                    $scope.startProgress();
                    var params = getParamsForEmail(false);
                    Participant.email(
                        params,
                        (resp) => {
                            if ($scope.successCallback) {
                                $scope.successCallback();
                            }
                            $scope.participantsCount = resp;
                            $scope.showSuccessMessage = true;
                            if ($scope.showSuccessMessage) {
                                scrollTo();
                            }
                            $scope.showHideSuccessMessage = false;
                            $scope.processLogStatus = 'RUNNING_WITH_MESSAGE';
                            $scope.unsubscribedParticipantsCount = 0;
                            if (resp.participantsCount < 1) {
                                $scope.participantEmailSuccessMessageText =
                                    'participant.email.running.zero.participants';
                            } else {
                                $scope.participantEmailSuccessMessageText = 'participant.email.running';
                            }
                            $scope.participantEmailSuccessMessage = {
                                messageKey: $scope.participantEmailSuccessMessageText,
                                params: $scope.participantsCount,
                            };
                            $scope.endProgress();
                        },
                        function (name, errorDetails) {
                            $scope.close();
                            $scope.endWithAlert(name, errorDetails);
                        }
                    );
                };

                function getCurrentProcessStatus(eventId) {
                    if (!eventId) {
                        return;
                    }
                    ProcessLog.get(
                        { referenceId: eventId, name: 'PARTICIPANT_INVITATION_EMAIL' },
                        (resp) => {
                            if (resp.status) {
                                $scope.processLogStatus = resp.status.name;
                            }
                            if (resp.status && resp.status.name === 'RUNNING_WITH_MESSAGE') {
                                let _error = angular.fromJson(resp.errorLog);
                                $scope.participantsCount = CommonFunctions.errorMessages(_error, true)[0].params.p0;
                                $scope.participantEmailSuccessMessageText = 'participant.email.running';
                                $scope.participantEmailSuccessMessage = {
                                    messageKey: $scope.participantEmailSuccessMessageText,
                                    params: { participantsCount: $scope.participantsCount },
                                };
                                $scope.showSuccessMessage = true;
                                $scope.showHideSuccessMessage = false;
                                $scope.unsubscribedParticipantsCount = 0;
                                if ($scope.showSuccessMessage) {
                                    scrollTo();
                                }
                            } else if (resp.status && resp.status.name === 'GOT_ERROR') {
                                let _error = angular.fromJson(resp.errorLog);
                                $scope.participantEmailSuccessMessage = CommonFunctions.errorMessages(
                                    _error,
                                    true
                                )[0];
                                $scope.showSuccessMessage = true;
                                $scope.showHideSuccessMessage = true;
                                if ($scope.showSuccessMessage) {
                                    scrollTo();
                                }
                            } /*else if (resp.status && resp.status.name === 'SUCCESS_WITH_MESSAGE') {
                                    let _error = angular.fromJson(resp.errorLog);
                                    $scope.participantEmailSuccessMessage = CommonFunctions.errorMessages(_error, true)[0];
                                    $scope.unsubscribedParticipantsCount = $scope.participantEmailSuccessMessage.params.p1;
                                    $scope.showHideSuccessMessage = true;
                                    $scope.showSuccessMessage = true;
                                    if ($scope.showSuccessMessage) {
                                        scrollTo();
                                    }
                                }*/
                        },
                        (err) => {
                            console.log('.>>', err);
                        }
                    );
                }

                function shouldWeActivateSMSFn() {
                    $scope.showSMSActivationMessage = !$scope.useSMS;
                }

                function getOrganizerDetailsFn() {
                    if (
                        $scope.invitationDetails.dataAccessHandler.name &&
                        $scope.invitationDetails.dataAccessHandler.email
                    ) {
                        $scope.showSendInvitationConfirmation = true;
                    } else {
                        $scope.orgizerDetailsNeededMessage = true;
                    }
                    if ($scope.email.provider !== 'BY_EMAIL_ONLY') {
                        shouldWeActivateSMSFn();
                    }
                }

                function hasOrganizerDetails() {
                    return (
                        $scope.invitationDetails.dataAccessHandler.name &&
                        $scope.invitationDetails.dataAccessHandler.email
                    );
                }

                function getParamsForEmail(selfEmail, participants) {
                    var selectedParticipants = [];
                    let _participants = selfEmail ? participants : $scope.email.selectedParticipants;
                    angular.forEach(_participants, function (participant) {
                        selectedParticipants.push(participant.email ? participant.email : participant.phone);
                    });
                    let _params = {
                        message: $scope.email.message,
                        eventId: $scope.email.eventId,
                        sendSMS: $scope.email.sendSMS,
                        type: $scope.type,
                        eventSite: $scope.email.provider !== 'BY_SMS_ONLY' ? $scope.email.eventSite : false,
                        hideRegistrationButtons: $scope.email.hideRegistrationButtons,
                        messageProvider: $scope.email.provider,
                        sendToByStatus: $scope.email.sendToByStatus,
                        sendGroupMessage: $scope.email.sendGroupMessage,
                        messageBySMS: $scope.email.messageBySMS,
                        messageByEmail: $scope.email.messageByEmail,
                        includeRegistrationLink:
                            $scope.email.provider !== 'BY_SMS_ONLY' ? $scope.email.includeRegistrationLink : false,
                        isInvitationSentBySms: $scope.isInvitationSentBySms,
                        showInfoPage: $scope.email.show === 'showInfoPage',
                        invitation: $scope.email.invitation,
                        selectedParticipants: selectedParticipants,
                        sendTo: selfEmail ? 'MYSELF' : $scope.email.sendTo,
                        emailSenderName: $scope.email.emailSenderName,
                        smsSenderName: $scope.email.smsSenderName,
                        replyToEmail: $scope.email.replyToEmail,
                        subject: $scope.email.subject,
                        attachedFiles: $scope.attachedFiles.uploads,
                        attachments: $scope.attachments,
                    };
                    if ($scope.type === 'CUSTOM') {
                        _params['smsMessage'] = $scope.email.smsMessage;
                        _params['addLinkToSms'] = $scope.email.addLinkToSms;
                        if ($scope.email.hash) {
                            _params['hash'] = $scope.email.hash;
                        }
                    }
                    return _params;
                }

                function scrollTo() {
                    let _elementId = '#successAlertBox';
                    $timeout(() => {
                        let _topHeight = jQuery(_elementId).position().top;
                        jQuery('#og-participant-email-modal').animate({ scrollTop: _topHeight }, 'slow');
                    }, 500);
                }

                function participantEmailSuccessCallback(result) {
                    $scope.endProgress();
                    $scope.showSendInvitationConfirmation = false;
                    $scope.resetEmailSmsBothActivationBtn();
                    if ($scope.successCallback) {
                        $scope.successCallback();
                    }
                    $scope.unsubscribedParticipantsCount = result.unsubCount;
                    $scope.participantEmailSuccessMessageText = 'participant.email.success';
                    if ($scope.email.invitation && $scope.email.sendTo != 'CUSTOM') {
                        $scope.participantEmailSuccessMessageText = 'participant.invite.success';
                    }
                    $scope.participantEmailSuccessMessage = {
                        messageKey: $scope.participantEmailSuccessMessageText,
                        params: result,
                    };
                    $scope.showSuccessMessage = true;
                    if ($scope.showSuccessMessage) {
                        scrollTo();
                    }
                    /*$timeout(function() {
                            /!*OgModal.open('alert', { message: message, params: result });*!/

                        }, 500);*/
                }

                $scope.cancelSend = function () {
                    $scope.showSendInvitationConfirmation = false;
                };

                $scope.cancelSendToMySelf = function () {
                    $scope.showSMSActivationMessageOnMyself = false;
                };

                $scope.sendToMyself = function () {
                    if ($scope.email.provider !== 'BY_EMAIL_ONLY' && !$scope.useSMS && $scope.email.selfPhone) {
                        $scope.showSMSActivationMessageOnMyself = true;
                        return;
                    }
                    $scope.startProgress();
                    let _participants = [{ email: $scope.email.selfEmail, phone: $scope.email.selfPhone }];
                    let _params = getParamsForEmail(true, _participants);
                    _params['sendToMeEmail'] = $scope.email.selfEmail;
                    _params['sendToMePhone'] = $scope.email.selfPhone;
                    Participant.email(_params, selfEmailSuccessCallback, $scope.endWithErrorOverlay);
                };

                $scope.activateSMSAndSendToMyself = function () {
                    $scope.startProgress();
                    Event.updateSMSService(
                        { eventId: $scope.eventId, useSMS: true },
                        (response) => {
                            $scope.useSMS = response.useSMS;
                            $scope.email.sendSMS = $scope.useSMS;
                            $scope.showSMSActivationMessageOnMyself = false;
                            $scope.sendToMyself();
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                };

                function selfEmailSuccessCallback(result) {
                    $scope.endProgress();
                    var message = 'participant.email.myself.success';
                    if ($scope.email.invitation && $scope.email.sendTo !== 'CUSTOM') {
                        message = 'participant.invite.myself.success';
                    }
                    resetSelfMessage();
                    $scope.successMessage = $translate.instant(message);
                }

                function resetSelfMessage() {
                    $scope.email.selfEmail = null;
                    $scope.email.selfPhone = null;
                }

                $scope.getSelfEmailSuccessMessage = function () {
                    if ($scope.successMessage) return $scope.successMessage;
                    else return '';
                };

                $scope.resetSelfEmailSuccessMessage = function () {
                    if ($scope.successMessage) {
                        $scope.successMessage = null;
                    }
                };

                $scope.scrollToParticipantSettings = function () {
                    $scope.close();
                    $scope.participantSettingsCallback();
                };

                $scope.disableSendingEmail = function () {
                    return (
                        $scope.email &&
                        (($scope.email.provider === 'BY_EMAIL_AND_SMS_BOTH' && !$scope.email.message) ||
                            ($scope.email.provider === 'BY_SMS_ONLY' && !$scope.email.message))
                    );
                };

                $scope.disableSendingCustomEmail = function () {
                    return (
                        $scope.disableSaveDraft() ||
                        ($scope.email &&
                            ((!$scope.email.sendGroupMessage &&
                                $scope.email.selectedParticipants &&
                                $scope.email.selectedParticipants.length === 0) ||
                                ($scope.email.sendGroupMessage && !$scope.email.sendToByStatus)))
                    );
                };

                $scope.disableSaveDraft = function () {
                    return (
                        $scope.email &&
                        (($scope.email.sendTo === 'CUSTOM' &&
                            !$scope.email.eventSite &&
                            !$scope.email.includeRegistrationLink &&
                            $scope.email.provider !== 'BY_SMS_ONLY' &&
                            !$scope.email.message) ||
                            (($scope.email.messageBySMS ||
                                $scope.email.provider === 'BY_SMS_ONLY' ||
                                $scope.email.provider === 'BY_EMAIL_AND_SMS_BOTH') &&
                                !$scope.email.smsMessage))
                    );
                };

                $scope.disableSavingScheduleMessage = function () {
                    return (
                        $scope.disableSendingCustomEmail() ||
                        ($scope.email && !$scope.email.scheduleDate && !$scope.email.scheduleTime)
                    );
                };

                $scope.scheduleMessage = function () {
                    if (!hasOrganizerDetails()) {
                        getOrganizerDetailsFn();
                        return;
                    }
                    $scope.startProgress();
                    let params = getParamsForEmail(false);
                    params['scheduleDate'] = $scope.email.scheduleDate;
                    let _date = new Date();
                    params['scheduleGmtDate'] = new Date(
                        $scope.email.scheduleDate + _date.getTimezoneOffset() * 60000
                    ).getTime(); //converted into gmt
                    params['scheduleTime'] = $scope.email.scheduleTime;
                    params['source'] = 'schedule';
                    params['senderName'] = $scope.email.emailSenderName;
                    params['smsSenderName'] = $scope.email.smsSenderName;
                    Participant.saveEventMessage(
                        params,
                        (res) => {
                            eventMessageSaveSuccessCallback(res, 'schedule');
                        },
                        $scope.endWithErrorOverlay
                    );
                };

                function eventMessageSaveSuccessCallback(result, source) {
                    $scope.endProgress();
                    $scope.resetEmailSmsBothActivationBtn();
                    if ($scope.successCallback) {
                        $scope.successCallback();
                    }
                    var message = 'participant.email.save.draft';
                    if (source === 'schedule') {
                        message = 'participant.email.save.schedule';
                    }
                    $scope.close();
                    $timeout(function () {
                        OgModal.open('alert', { message: message, params: result });
                    }, 2000);
                }

                $scope.disableSelfEmail = function () {
                    return (
                        $scope.email &&
                        (($scope.email.provider === 'BY_EMAIL_AND_SMS_BOTH' &&
                            !($scope.email.selfEmail || $scope.email.selfPhone)) ||
                            ($scope.email.provider === 'BY_SMS_ONLY' && !$scope.email.selfPhone) ||
                            ($scope.email.provider === 'BY_EMAIL_ONLY' && !$scope.email.selfEmail))
                    );
                };

                $scope.getParticipantName = function (participant) {
                    var participantHasName =
                        participant && participant.name && participant.name.replace(/\s/g, '').length > 0;
                    return participantHasName
                        ? participant.name
                        : participant.email
                            ? participant.email
                            : participant.phone;
                };

                $scope.clearAttachedFiles = function () {
                    $scope.attachedFiles = { uploads: null, links: null };
                };

                $scope.updateSelectedFiles = function (fileSelect) {
                    $scope.attachedFiles = fileSelect;
                    $scope.attachments = [];
                };

                $scope.hideSuccessMessage = function () {
                    $scope.showSuccessMessage = false;
                    if ($scope.processLogStatus === 'GOT_ERROR') {
                        $scope.startProgress();
                        ProcessLog.markProcessAsFinished(
                            { referenceId: $scope.eventId, name: 'PARTICIPANT_INVITATION_EMAIL' },
                            (resp) => {
                                $scope.endProgress();
                                $scope.processLogStatus = resp.status.name;
                            },
                            $scope.endWithError()
                        );
                    }
                };
            },
        });
    },
]);

