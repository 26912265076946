(function () {
    'use strict';

    angular.module('ogLocationInput').directive('ogLocationInput', [
        '$timeout',
        function ($timeout) {
            return {
                restrict: 'EA',
                templateUrl: './app/components/directives/location_input/og_location_input.view.html',
                scope: {
                    geoTags: '=',
                    geoTagId: '=',
                    onChange: '&',
                    placeholder: '@',
                    onchanged: '=',
                    modelName: '@',
                    icon: '@',
                    open: '=',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.$watch('geoTags', function () {
                            $scope._geoTags = $scope.geoTags;
                        });

                        $scope.geoTagChanged = function (geoTag) {
                            if (geoTag) {
                                $scope.geoTagId = geoTag.id;
                            } else {
                                $scope.geoTagId = null;
                            }
                            if (typeof $scope.onchanged == 'function') {
                                $scope.onchanged($scope.modelName, $scope.geoTagId);
                            }
                            $timeout(function () {
                                $scope.onChange(geoTag);
                            });
                        };
                    },
                ],
                compile: function (element, attrs) {
                    if (!attrs.icon) {
                        attrs.icon = 'caret-down';
                    }
                },
            };
        },
    ]);
})();
