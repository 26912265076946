'use strict';

angular.module('core.userInfoStatus').factory('UserInfoStatus', [
    '$resource',
    function ($resource) {
        var exitEventGuide = $resource(
            'api/v1/userInfoStatus/exitEventGuide',
            {},
            {
                update: {method: 'POST', isArray: false},
            }
        );

        var exitOrganizerInfo = $resource(
            'api/v1/userInfoStatus/exitOrganizerInfo',
            {},
            {
                update: {method: 'POST', isArray: false},
            }
        );
        var acceptedNewTerms = $resource(
            'api/v1/userInfoStatus/acceptedNewTerms',
            {},
            {
                update: {method: 'POST', isArray: false},
            }
        );
        var newTermsAndConditionInfo = $resource(
            'api/v1/userInfoStatus/newTermsAndConditionInfo',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var getUserInfoStatus = $resource(
            'api/v1/userInfoStatus/getUserInfoStatus',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            exitEventGuide: function (successCallback, errorCallback) {
                return exitEventGuide.update(successCallback, errorCallback);
            },
            exitOrganizerInfo: function (successCallback, errorCallback) {
                return exitOrganizerInfo.update(successCallback, errorCallback);
            },
            acceptedNewTerms: function (successCallback, errorCallback) {
                return acceptedNewTerms.update(successCallback, errorCallback);
            },
            newTermsAndConditionInfo: function (successCallback, errorCallback) {
                return newTermsAndConditionInfo.get(successCallback, errorCallback);
            },
            getUserInfoStatus: function (successCallback, errorCallback) {
                return getUserInfoStatus.get(successCallback, errorCallback);
            },
        };
    },
]);
