'use strict';
import {ScopeFunctions} from '../../../../../../utils/global/scopeFunctions';

angular.module('offer.detail.message').component('supplierMessages', {
    templateUrl: './app/components/modules/supplier/offer/offer_detail/message/message.view.html',
    controller: [
        '$scope',
        '$stateParams',
        'ActiveTabService',
        function OfferMessageController($scope, $stateParams, ActiveTabService) {
            var vm = this;
            vm.init = initFn;
            vm.getActiveTab = getActiveTabFn;
            vm.offerId = $stateParams.offerId;

            ScopeFunctions.addToScope($scope);

            vm.init();

            function initFn() {
            }

            function getActiveTabFn() {
                return ActiveTabService.getOfferDetailTab();
            }
        }
    ]
});
