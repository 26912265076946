'use strict';

import '../../../directives/field_input/og_field_input.module';
import '../../../directives/field_input/og_field_input.directive';

import '../../../../utils/global/common_function/common.function.module'
import '../../../../utils/global/common_function/common.function.service'

// Define the `login` module
angular.module('register', [
    'ngRoute',
    'util.commonFunction',
    'core.authenticationService',
    'core.globalOption',
    'ogFieldInput'
]);
