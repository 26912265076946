/**
 * Created by kshitij on 10/16/17.
 */

import controller from './og_badge_menu_item.controller';

angular.module('ogBadgeMenuItem').component('ogBadgeMenuItem', {
    bindings: {
        itemId: '=',
        query: '=',
        lang: '=',
        prefix: '=', //basicMenu or customMenu,
        type: '=',
        isItemAdded: '=',
        addDesignFieldsCallback: '&',
    },
    templateUrl: './app/components/directives/badge_menu_item/og_badge_menu_item.view.html',
    controller,
    controllerAs: 'BadgeMenuItemCtrl',
});
