import {ScopeFunctions} from "../../../../utils/global/scopeFunctions";

angular.
    module('event.create').
    component('createEvent', {
        templateUrl: './app/components/modules/event/eventCreate/eventCreate.template.html',
        controllerAs: 'createEventCtrl',
        controller: [
            '$location', '$rootScope', '$scope', 'Event', 'GeoTag', 'TimezoneService', 'CommonFunctions',
            function CreateEventController(
                $location, $rootScope, $scope, Event, GeoTag, TimezoneService, CommonFunctions
            ) {

                var vm = this;

                vm.eventSubmit = eventSubmitFn;
                vm.data = {};

                ScopeFunctions.addToScope($scope);

                (function () {
                    // initialize event from-date and to-date pickers
                    initializeDatePickers();
                    loadGeoTags();
                })();

                function eventSubmitFn(redirectToContentGuide) {
                    if (!vm.createEventForm.$valid) {
                        CommonFunctions.touchFormFields(vm.createEventForm);
                        return;
                    } else {
                        $scope.startProgress();
                        Event.save(
                            vm.data,
                            function (data) {
                                var beforeStartDate = new Date(data.fromDate).getTime() - new Date(data.dateCreated).getTime();
                                $rootScope.$emit('event:user:action', {
                                    action: 'create.event',
                                    data: {
                                        'No. participants': data.participantAmount,
                                        'Event length': 1 + Math.round((data.toDate - data.fromDate) / (3600 * 24 * 1000)),
                                        'Days before start': Math.floor(beforeStartDate / (1000 * 60 * 60 * 24)),
                                    },
                                });

                                $scope.endProgress();
                                if (vm.successCallback != null) {
                                    vm.successCallback();
                                }
                                if (!redirectToContentGuide) {
                                    $location.url('/event/' + data.id);
                                } else {
                                    $location.url('/event/' + data.id + '/guide');
                                }
                            },
                            $scope.endWithError
                        );
                    }
                }

                function initializeDatePickers() {
                    getFromDatePicker().datepicker({
                        dateFormat: getDateFormat(),
                        onSelect: function (dateText) {
                            getToDatePicker().datepicker('option', 'minDate', dateText);
                            updateFromDate();
                            setupFromDate();
                        },
                    });

                    getToDatePicker().datepicker({
                        dateFormat: getDateFormat(),
                        onSelect: function () {
                            updateToDate();
                            setupToDate();
                        },
                    });

                    getFromDatePicker().on('change', function () {
                        updateFromDate();
                        setupFromDate();
                    });

                    getToDatePicker().on('change', function () {
                        updateToDate();
                        setupToDate();
                    });

                    // also show date-picker on calendar icon click
                    jQuery('.icon-calendar').on('click', function () {
                        jQuery(this).siblings('input').datepicker('show');
                    });
                }

                function getDateFormat() {
                    return $rootScope.langSelected == 'sv_SV' ? 'yy-mm-dd' : 'dd/mm/yy';
                }

                function getFromDatePicker() {
                    return jQuery('#fromDatePicker');
                }

                function getToDatePicker() {
                    return jQuery('#toDatePicker');
                }

                function loadGeoTags() {
                    $scope.startProgress();
                    GeoTag.getMap(
                        {},
                        function (result) {
                            vm.geoTags = result;
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function setupFromDate() {
                    var fromDate = new Date(TimezoneService.toLocalTime(vm.data.fromDate));
                    getFromDatePicker().datepicker('setDate', new Date(fromDate));
                }

                function setupToDate() {
                    var toDate = new Date(TimezoneService.toLocalTime(vm.data.toDate));
                    getToDatePicker().datepicker('setDate', new Date(toDate));
                }

                function updateFromDate() {
                    if (vm.data) {
                        vm.data.fromDate = TimezoneService.fromLocalTime(getFromDatePicker().datepicker('getDate')).getTime();
                        $rootScope.safeApply($scope);
                    }
                }

                function updateToDate() {
                    if (vm.data) {
                        vm.data.toDate = TimezoneService.fromLocalTime(getToDatePicker().datepicker('getDate')).getTime();
                        $rootScope.safeApply($scope);
                    }
                }
            }
        ]
    });
