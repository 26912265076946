(function () {
    'use strict';

    angular.module('activityMessage').directive('ogActivityMessage', [
        '$compile',
        '$translate',
        '$rootScope',
        'TimezoneService',
        'LinkService',
        'CommonFunctions',
        '$timeout',
        function ($compile, $translate, $rootScope, TimezoneService, LinkService, CommonFunctions, $timeout) {
            function format(message) {
                var localisedText = $translate.instant(message.text);
                var links = message.links;
                angular.forEach(links, function (value, key) {
                    var linkGroup = createLinkGroup(value, message.task);
                    if (linkGroup) {
                        localisedText = localisedText.replace('{' + key + '}', linkGroup);
                    }
                });

                // replace the {Responsibles} key of message, if there is, with the task responsibles' names
                if (localisedText && localisedText.indexOf('{Responsibles}') > 0) {
                    if (message.task) {
                        var responsiblesList = [];
                        angular.forEach(message.task.responsibles, function (responsible) {
                            responsiblesList.push(responsible.name);
                        });
                        localisedText = localisedText.replace('{Responsibles}', responsiblesList.join(', '));
                    }
                }
                var argsMap = message.argsMap;
                angular.forEach(argsMap, function (value, key) {
                    if (value) {
                        var lastFour = key.substr(key.length - 4); // => "Date"
                        if (lastFour && lastFour.toLowerCase() === 'date') {
                            value = TimezoneService.formatDate(new Date(parseInt(value)), getDateDisplayFormatFn());
                        }
                        localisedText = localisedText.replace('{' + key + '}', value);
                    }
                });

                return localisedText;
                // return cleanHTML(localisedText); //TODO(BT) might be fixes done for XSS; generated a issue, need to recheck and fix.
            }

            function getDateDisplayFormatFn() {
                return $rootScope.langSelected == 'sv_SV' ? 'YYYY-MM-DD' : 'DD/MM/YYYY';
            }

            function createLinkGroup(subLinks, task) {
                var linkGroup = '';
                var index = 0;

                angular.forEach(subLinks, function (param) {
                    var singleLink = createLink(param, task);
                    if (index > 0) {
                        linkGroup += ', ';
                    }
                    if (singleLink) {
                        linkGroup += singleLink;
                        index++;
                    }
                });

                return linkGroup;
            }

            function createLink(param) {
                var link = LinkService.createHref(param);
                var fragment = CommonFunctions.getTranslatedTextFromCode(param.text, param.textCode);
                if (link) {
                    return fragment;
                } else {
                    if (fragment && fragment.indexOf('localise:') === 0) {
                        fragment = fragment.substr('localise:'.length);
                        fragment = $translate.instant(fragment);
                    }
                    return fragment;
                }
            }

            function cleanHTML(str) {
                return str
                    .replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&#39;')
                    .replace(/\//g, '&#x2F;');
            }

            return {
                restrict: 'EA',
                replace: true,
                template: '<div class="pd-b-10"></div>',
                scope: {
                    message: '=ogActivityMessage',
                },
                controller: ['$scope', function ($scope) {
                    $scope.updateTask = function () {
                        $scope.$emit('task/update', $scope.message);
                    };
                },
                ],
                link: function ($scope, element) {
                    var formatMessage = function (value) {
                        element.html('');
                        if (value) {
                            $timeout( ()=> {
                                var formattedBody = '<span>' + format($scope.message) + '</span>';

                                if (
                                    $scope.message.type &&
                                    $scope.message.type.name != 'ACTIVITY_MESSAGE' &&
                                    $scope.message.task &&
                                    $scope.message.task.done
                                ) {
                                    formattedBody =
                                        '<span class="message-task-complete">' + format($scope.message) + '</span>';
                                }
                                element.append($compile(formattedBody)($scope))
                                //  $compile(formattedBody)($scope).append(jQuery(element));
                            }, 0);

                        }
                    };

                    $scope.$watch('message', formatMessage);
                    $scope.$on('lang:change', formatMessage);
                },
            };
        },
    ]);
})();
