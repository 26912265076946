(function () {
    'use strict';

    angular.module('ogFormField').directive('ogFormField', [
        function () {
            function toggleError(element, error) {
                if (!element) {
                    return;
                }
                if (error) {
                    element.addClass('alert alert-warning');
                } else {
                    element.removeClass('alert alert-warning');
                    element.find('span small').empty();
                }
            }

            return {
                template: '<small ng-repeat="error in errors">{{error}}</small>',
                scope: {
                    field: '@ogFormField',
                    form: '@parentForm',
                    message: '@message',
                    show: '=show',
                },
                link: function ($scope, element) {
                    if ($scope.message) {
                        $scope.$watch('show', function () {
                            $scope.errors = $scope.show ? [$scope.message] : null;
                            toggleError(element.parent(), $scope.show);
                        });
                    } else {
                        if (!$scope.form) {
                            console.error('parent-form attribute is not specified for field ', $scope.field);
                        }

                        $scope.$on(
                            'validation:form:error:' + $scope.form + ':field:' + $scope.field,
                            function (event, errors) {
                                $scope.errors = errors;
                                var errorsPresent = errors && errors.length > 0;
                                toggleError(element.parent(), errorsPresent);
                            }
                        );

                        var clear = function () {
                            $scope.errors = null;
                            toggleError(element.parent(), false);
                        };

                        $scope.$on('validation:form:' + $scope.form + ':clear', clear);
                        $scope.$on('validation:form:success:' + $scope.form, clear);
                        $scope.$on('validation:form:info:' + $scope.form, clear);
                    }
                },
            };
        },
    ]);
})();
