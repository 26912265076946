'use strict';
angular.module('core.page').factory('Page', [
    '$resource',
    function ($resource) {
        var creatorHomePage = $resource(
            'api/v1/page/creatorHomePage',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var managersHomePage = $resource(
            'api/v1/page/managersHomePage',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var eventDetailPage = $resource(
            'api/v1/page/eventDetailPage',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );

        var supplierHomePage = $resource(
            'api/v1/page/supplierHomePage',
            {},
            {
                list: { method: 'POST', isArray: false },
            }
        );

        var supplierDetailPage = $resource(
            'api/v1/page/supplierDetailPage',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var landingPage = $resource(
            'api/v1/page/landing',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );

        var contentGuidePage = $resource(
            'api/v1/page/contentGuidePage',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var serverURL = $resource(
            'api/v1/page/serverURL',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );

        var publicURL = $resource(
            'api/v1/page/publicURL',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var confirmationPage = $resource(
            'api/v1/page/confirmationPage',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var thankYouConfirmationPage = $resource(
            'api/v1/page/thankYouconfirmationPage',
            {},
            {
                get: { method: 'GET', isArray: false },
            }
        );

        return {
            creatorHomePage: function (successCallback, errorCallback) {
                return creatorHomePage.get({}, successCallback, errorCallback);
            },
            managersHomePage: function (params, successCallback, errorCallback) {
                return managersHomePage.get(params, successCallback, errorCallback);
            },
            eventDetailPage: function (params, successCallback, errorCallback) {
                return eventDetailPage.get(params, successCallback, errorCallback);
            },
            supplierHomePage: function (params, successCallback, errorCallback) {
                return supplierHomePage.list(params, successCallback, errorCallback);
            },
            supplierDetailPage: function (params, successCallback, errorCallback) {
                return supplierDetailPage.get(params, successCallback, errorCallback);
            },
            landingPage: function (params, successCallback, errorCallback) {
                return landingPage.get(params, successCallback, errorCallback);
            },
            contentGuidePage: function (params, successCallback, errorCallback) {
                return contentGuidePage.get(params, successCallback, errorCallback);
            },
            serverURL: function (params, successCallBack, errorCallBack) {
                return serverURL.get(params, successCallBack, errorCallBack);
            },
            publicURL: function (params, successCallBack, errorCallBack) {
                return publicURL.get(params, successCallBack, errorCallBack);
            },
            confirmationPage: function (params, successCallBack, errorCallBack) {
                return confirmationPage.get(params, successCallBack, errorCallBack);
            },
            thankYouConfirmationPage: function (params, successCallBack, errorCallBack) {
                return thankYouConfirmationPage.get(params, successCallBack, errorCallBack);
            },
        };
    },
]);