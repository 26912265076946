(function () {
    'use strict';

    angular.module('ogRichText').directive('ogRichText', [
        '$timeout',
        function ($timeout) {
            return {
                restrict: 'EA',
                replace: true,
                link: function (scope, element, attrs) {
                    var height = attrs.ogHeight || 100;
                    var limitItems = attrs.ogLimitItems || false;
                    var ownValue;

                    var changeEvent = function () {
                        var id = element[0].id;
                        ownValue = tinyMCE.get(id).getContent();
                        $timeout(function () {
                            if (attrs.ogModel) {
                                scope.$eval(attrs.ogModel + "= '" + ownValue.replace(/'/g, "\\'") + "'");
                            }
                            if (attrs.ogChange) {
                                scope.$eval(attrs.ogChange);
                            }
                        });
                    };

                    var tinyMCESettings = {
                        height: height,
                        menubar: false,
                        browser_spellcheck: true,
                        cache_suffix: '?v=4.1.6',
                        setup: function (editor) {
                            editor.on('change', changeEvent);
                            editor.on('keyup', changeEvent);
                            editor.on('blur', changeEvent);
                        },
                        valid_elements:
                            '' +
                            'a[href|target=_blank],' +
                            'b,' +
                            'br,' +
                            'em,' +
                            'font[color|face|size],' +
                            'img[src|id|width|height|align|hspace|vspace],' +
                            'i,' +
                            'ol,' +
                            'ul,' +
                            'li[style],' +
                            'p[style|align|class],' +
                            'h1[style],' +
                            'h2[style],' +
                            'h3[style],' +
                            'h4[style],' +
                            'h5[style],' +
                            'h6[style],' +
                            'span[class],' +
                            'strong,' +
                            'textformat[blockindent|indent|leading|leftmargin|rightmargin|tabstops],' +
                            'u',
                        plugins: ['autolink link'],
                        //                    ,
                        //                    plugins: [
                        //                        "link code"
                        //                    ],
                        //                    target_list: [
                        //                        {title: 'New window', value: '_blank'}
                        //                    ],
                        //                    convert_urls: false
                        relative_urls: false,
                        remove_script_host: false,
                        /* jshint ignore:start */
                        //Added because ng-bind-html didn't accept "text-align='center'" so replaced with align="center"
                        //See http://stackoverflow.com/questions/1140652/tinymce-use-div-align-center-instead-of-div-style-text-align-center
                        /* jshint ignore:end */
                        formats: {
                            alignleft: {
                                selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
                                attributes: { align: 'left' },
                            },
                            aligncenter: {
                                selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
                                attributes: { align: 'center' },
                            },
                            alignright: {
                                selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
                                attributes: { align: 'right' },
                            },
                            alignjustify: {
                                selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
                                attributes: { align: 'justify' },
                            },
                        },
                    };

                    tinyMCE.baseURL = '/js/external/tinymce';
                    tinyMCE.suffix = '.min';

                    if (limitItems == 'true') {
                        tinyMCESettings.toolbar = 'alignleft aligncenter alignright alignjustify';
                    }

                    jQuery(element).tinymce(tinyMCESettings);

                    scope.$watch(attrs.ogModel, function () {
                        var value = scope.$eval(attrs.ogModel);
                        if (value != ownValue) {
                            ownValue = value;
                            var id = element[0].id;
                            if (value == null) {
                                value = '';
                            }
                            tinyMCE.get(id).setContent(value);
                        }
                    });
                },
            };
        },
    ]);
})();
