import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class AddConferenceActivityModalCtrl {
    constructor(
        data,
        $scope,
        close,
        $element,
        $rootScope,
        $translate,
        ActivityService,
        FilledRequestOfferPartComment,
        TimezoneService
    ) {
        this._injected = {
            data,
            $scope,
            close,
            $element,
            $rootScope,
            $translate,
            ActivityService,
            FilledRequestOfferPartComment,
            TimezoneService,
        };

        ScopeFunctions.addToScope($scope);

        this.activity = {
            eventId: 0,
            name: 'Conference activity',
            amount: 0,
            amountType: 'SAME_AS_EVENT',
            dayOfEvent: 0,
            timeFrom: 61200000,
            timeTo: 64800000,
            selectedCategories: [],
            list: [],
            limit: 3,
            eventFromDate: 1664409600000,
        };
        this.validated = {
            step2: false,
            step3: false,
            step4: false,
        };
        this.days = [];
        this.fromDate = '';
        this.toDate = '';
        this.questions = [];
        this.updatedList = [];
        this.activities = [];
        this.hideAddButton = true;
        this.hideNextButton = true;
        this.count = 0;
        this.hoursToAdd = 1;
        this.message = '';
        this.step = 1;
        this.isHtml = false;
        this.data = data;
        this.init(data);
    }

    init(data) {
        const { $translate } = this._injected;
        if (data.message) {
            this.message = $translate.instant(data.message, data.params);
        }
        if (data.messageTitle) {
            this.messageTitle = $translate.instant(data.messageTitle, data.params);
        }

        this.isHtml = data.isHtml;
        this.getNoOfDays();
        this.activity.eventDate = data.fromDate;
    }

    ok() {
        this.addActivity(this.activity.eventFromDate);
    }

    handleOnchanged(modalName, currentValue) {
        // if (modalName === "timeFrom") { console.log(modalName, currentValue, 'conference activity controller') }
    }

    getNoOfDays() {
        const { TimezoneService } = this._injected;

        let noOfDays = 0;
        this.fromDate = new Date(TimezoneService.toLocalTime(this.data.fromDate));
        this.toDate = new Date(TimezoneService.toLocalTime(this.data.toDate));

        let differenceInTime = this.toDate.getTime() - this.fromDate.getTime();
        noOfDays = differenceInTime / (1000 * 3600 * 24) + 1;
        for (let i = 1; i <= noOfDays; i++) {
            this.days = [
                ...this.days,
                {
                    displayDate: this.formatDate(
                        new Date(TimezoneService.toLocalTime(this.data.fromDate)).setDate(this.fromDate.getDate() + i)
                    ),
                    id: i,
                },
            ];
        }
        if (noOfDays === 1) {
            this.activity.dayOfEvent = 1;
        }
        return noOfDays;
    }

    handleDayChange() {}

    formatDate(date) {
        const { TimezoneService } = this._injected;

        let newDate = new Date(TimezoneService.toLocalTime(date));
        const month = newDate.toLocaleString('default', { month: 'short' });
        newDate = newDate.getDate() + ' ' + month;
        return newDate;
    }

    getDynamicQuestions() {
        const { $scope, FilledRequestOfferPartComment, $translate } = this._injected;

        $scope.startProgress();
        FilledRequestOfferPartComment.list(
            'OFFER_PART_LEVEL',
            'common.translate.offer.part.type.conference.activities',
            null,
            (response) => {
                if (this.count > 0) {
                    this.questions = this.updatedList;
                } else {
                    this.questions = response;
                    this.updatedList = response;
                    this.count = 1;
                }
                if (this.step === 4 && this.data.requestTypeCode !== 'common.translate.request.conference.hotel') {
                    this.questions[3].fieldValue = this.getFieldValuesFn(
                        $translate.instant('common.translate.conference.activity05.listValue.supplier.source'),
                        'External Providers',
                        1
                    );
                    this.questions[3].requestTypeCode = this.data.requestTypeCode;
                    this.validated.step4 = true;
                }
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    getActivies() {
        const { $scope, ActivityService } = this._injected;

        $scope.startProgress();
        ActivityService.fetchActivity(
            {},
            (response) => {
                let _activities = response.categoryList.sort((a, b) => a.id - b.id);
                if (_activities.length > 0) {
                    let _index = _activities.findIndex((x) => x.nameCode === 'common.translate.category.activities');
                    let _item = _activities[_index];
                    _item.nameCode = 'common.translate.category.all.activities';
                    _activities[_index] = _item;
                }
                this.activities = _activities;
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    addActivity(payload) {
        const { $scope, ActivityService } = this._injected;
        $scope.startProgress();
        ActivityService.addActivity(
            {},
            {
                name: 'Conference activity',
                eventId: parseInt(this.data.eventId),
                amount: this.data.participants,
                amountType: 'SAME_AS_EVENT',
                dayOfEvent: parseInt(this.activity.dayOfEvent),
                dateTimeFrom: this.activity.timeFrom,
                dateTimeTo: this.activity.timeTo,
                selectedCategories: this.activity.selectedCategories,
                list: this.questions,
            },
            (response) => {
                this.data.okCallBack();
                this._close();
            },
            $scope.endWithErrorOverlay()
        );
    }

    next() {
        this.step = this.step + 1;
        if (this.step === 2 || this.step === 4) {
            this.getDynamicQuestions();
        }
        if (this.step === 3) {
            this.activities.length === 0 && this.getActivies();
        }
        if (this.questions.length === 0) {
            this.questions = this.updatedList;
        }
    }

    prev() {
        this.step = this.step - 1;
        if (this.step === 2 || this.step === 4) {
            this.getDynamicQuestions();
        }
        if (this.questions.length === 0) {
            this.questions = this.updatedList;
        }
    }

    changedDetails(changed) {
        let arr = [];
        setTimeout(() => {
            this.questions.forEach((question, index) => {
                if (this.step === 2 && index < 3) {
                    this.validated.step2 = true;
                    if (question.fieldValue === null || question.fieldValue === false) {
                        arr.push(null);
                    }
                }
                if (this.step === 4 && index === 3) {
                    this.validated.step4 = true;
                    if (question.fieldValue === null || question.fieldValue === false) {
                        arr.push(null);
                    }
                }
            });
            if (this.step === 2 && arr.includes(null)) {
                this.validated.step2 = false;
            }
            if (this.step === 4 && arr.includes(null)) {
                this.validated.step4 = false;
            }
        }, 0);
        this.updatedList = this.questions;
    }

    handleCheckboxChange(activity) {
        if (activity.isChecked) {
            if (this.activity.selectedCategories.length === 1 && this.activity.selectedCategories.includes(94)) {
                this.activity.selectedCategories = [];
            }
            if (this.activity.limit && activity.id === 94) {
                this.activity.selectedCategories = [activity.id];
                this.validated.step3 = true;
                return;
            }
            if (this.activity.selectedCategories) {
                this.activity.selectedCategories = [...this.activity.selectedCategories, activity.id];
            } else {
                this.activity.selectedCategories = [activity.id];
            }
        } else {
            this.activity.selectedCategories = this.activity.selectedCategories.filter((e) => e !== activity.id);
        }
        if (this.activity.selectedCategories.length !== 0) {
            this.validated.step3 = true;
            return;
        }
        this.validated.step3 = false;
    }

    handleCheckboxDisable(activity) {
        if (this.activity.selectedCategories.includes(94) && activity.id !== 94) {
            return false;
        }
        return this.activity.selectedCategories.length === this.activity.limit &&
        !this.activity.selectedCategories.includes(activity.id) &&
        activity.id !== 94
            ? true
            : false;
    }

    handleChecked(activity) {
        if (this.activity.selectedCategories.includes(activity.id)) {
            activity.isChecked = true;
            return true;
        }
        activity.isChecked = false;
        return false;
    }

    _close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }

    getFieldValuesFn(listValues, fieldValue, index) {
        const { $rootScope } = this._injected;
        const lang = $rootScope.langSelected;

        if (listValues && listValues.indexOf('~') >= 0 && fieldValue) {
            let _enListValues = listValues.split('~')[0].split(',');
            let _swListValues = listValues.split('~')[1].split(',');

            let _nbListValues = listValues.split('~')[2].split(',');

            let _dkListValues = !!listValues.split('~')[3] ? listValues.split('~')[3].split(',') : '';
            let _fiListValues = !!listValues.split('~')[4] ? listValues.split('~')[4].split(',') : '';

            if (lang === 'en_US') {
                return _enListValues[index];
            }
            if (lang === 'sv_SV') {
                return _swListValues[index];
            }
            if (lang === 'fi_FI') {
                return _fiListValues[index];
            }
            if (lang === 'nb_NB') {
                return _nbListValues[index];
            }
            if (lang === 'da_DK') {
                return _dkListValues[index];
            }
        } else {
            return fieldValue;
        }
    }
}

AddConferenceActivityModalCtrl.$inject = [
    'data',
    '$scope',
    'close',
    '$element',
    '$rootScope',
    '$translate',
    'ActivityService',
    'FilledRequestOfferPartComment',
    'TimezoneService',
];
export default AddConferenceActivityModalCtrl;
