'use strict';
//see https://github.com/oblador/angular-scroll for more details
angular.module('core.existingServices').service('ogScrollService', [
    '$document',
    'GlobalConstants',
    function ($document, GlobalConstants) {
        //If duration is specified the scrolling is animated for n milliseconds
        //if offset is passed it will be subtracted from the elements position which is good if one uses floating menus
        this.scrollToElement = function (elementID, offset, duration) {
            offset = offset || GlobalConstants.DEFAULT_SCROLL_OFFSET;
            duration = duration || GlobalConstants.DEFAULT_SCROLL_DURATION;

            var goToElement = angular.element(document.getElementById(elementID));
            $document.duScrollToElement(goToElement, offset, duration);
        };

        this.scrollToContainer = function (containerID, duration) {
            duration = duration || GlobalConstants.DEFAULT_SCROLL_DURATION;
            var container = angular.element(document.getElementById(containerID));
            container.scrollTop(0, duration);
        };

        //In case of modal, using scrollToElement causes the background to scroll
        this.scrollToElementInModal = function (modalID, elementID, duration) {
            duration = duration || GlobalConstants.DEFAULT_SCROLL_DURATION;
            $(modalID).animate({ scrollTop: $(elementID).offset().top }, duration);
        };

        this.scrollTopToContainer = function (containerID, top, duration) {
            duration = duration || GlobalConstants.DEFAULT_SCROLL_DURATION;
            var container = angular.element(document.getElementById(containerID));
            top = top || 0;
            container.scrollTop(top, duration);
        };
    },
]);

