import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import _ from 'underscore';

class ScheduleRouteChangeAlertModalCtrl {
    constructor(
        data,
        $scope,
        close,
        $element,
        RequestOffer,
        EventEnum,
        $state,
        $timeout,
        NotificationService,
        $stateParams
    ) {
        this._injected = {
            data,
            $scope,
            close,
            $element,
            RequestOffer,
            EventEnum,
            $state,
            $timeout,
            NotificationService,
            $stateParams,
        };
        this.groupedNotification = _.groupBy(data.notifications, 'type');
        this.lockedNotifications = this.groupedNotification.REQUEST_OFFER_BOOKED_LOCKED_BY_CREATOR;
        this.editedNotifications = this.groupedNotification.REQUEST_OFFER_EDITED_BY_CREATOR;
        this.toStateName = data.toStateName;
        this.eventId = $stateParams.eventId;
        this.cancel = data.cancel;
        this.close = this.close.bind(this);
        this._errorHandler = this._errorHandler.bind(this);
        this._successHandler = this._successHandler.bind(this);
        ScopeFunctions.addToScope($scope);
    }

    applyChanges() {
        let { $scope, RequestOffer } = this._injected;
        $scope.startProgress();
        let editedIds = this._extractOfferIds(this.editedNotifications);
        let lockedIds = this._extractOfferIds(this.lockedNotifications);
        RequestOffer.applyAllChangesByCreator(editedIds, lockedIds, this._successHandler, this._errorHandler);
    }

    discardChanges() {
        let { $scope, RequestOffer } = this._injected;
        $scope.startProgress();
        let editedIds = this._extractOfferIds(this.editedNotifications);
        let lockedIds = this._extractOfferIds(this.lockedNotifications);
        RequestOffer.discardAllChangesByCreator(editedIds, lockedIds, this._successHandler, this._errorHandler);
    }

    _extractOfferIds(notifications) {
        if (notifications && notifications.length > 0) {
            return notifications.map((a) => a.offer.id);
        }
    }

    _successHandler() {
        let { $scope, EventEnum, $state, $timeout, NotificationService } = this._injected;
        $scope.endProgress();
        NotificationService.setNotificationData(this.eventId);
        $scope.$emit(EventEnum.EVENT_AND_REQUESTS_RELOAD);
        if (this.ok) {
            this.ok();
        }
        if (this.toStateName) {
            $timeout(() => {
                $state.go(this.toStateName);
            }, 1000);
        }
        this._closeFn();
    }

    _errorHandler(error) {
        let { $scope } = this._injected;
        $scope.endWithErrorOverlay(error);
    }

    close() {
        if (this.cancel) {
            this.cancel();
        }
        this._closeFn();
    }

    _closeFn() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}
ScheduleRouteChangeAlertModalCtrl.$inject = [
    'data',
    '$scope',
    'close',
    '$element',
    'RequestOffer',
    'EventEnum',
    '$state',
    '$timeout',
    'NotificationService',
    '$stateParams',
];
export default ScheduleRouteChangeAlertModalCtrl;
