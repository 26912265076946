/* global angular */
'use strict';

/**
 * This service holds the event details in a page. Should be refreshed wheneever there is change in event details
 */
class EventScheduleDataStore {
    constructor() {
        this.eventSchedule = {};
    }

    setSchedule(data) {
        this.eventSchedule = data;
    }

    getSchedule() {
        return this.eventSchedule;
    }
}

angular.module('core.existingServices').factory('EventScheduleDataStore', EventScheduleDataStore);

