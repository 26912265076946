(function () {
    'use strict';

    angular.module('ogDateTimePickerPair').directive('ogDateTimePickerPair', [
        '$rootScope',
        'TimezoneService',
        '$translate',
        '$timeout',
        function ($rootScope, TimezoneService, $translate, $timeout) {
            var ONE_DAY_MILLISECONDS = 86400000;

            function getFromDate(tabIndex) {
                return jQuery('#dateTimePairFromDate' + tabIndex);
            }

            function getToDate(tabIndex) {
                return jQuery('#dateTimePairToDate' + tabIndex);
            }

            function getFromTime(tabIndex) {
                return jQuery('#dateTimePairFromTime' + tabIndex);
            }

            function getToTime(tabIndex) {
                return jQuery('#dateTimePairToTime' + tabIndex);
            }

            function isSameDay(tabIndex) {
                return getToDate(tabIndex).datepicker('getDate') - getFromDate(tabIndex).datepicker('getDate') === 0;
            }

            function setupFromDate(scope) {
                var baseDate = extractBaseDate(scope);
                var fromDateTime = new Date(TimezoneService.toLocalTime(scope.fromDateTime + baseDate));
                var toDateTime = new Date(TimezoneService.toLocalTime(scope.toDateTime + baseDate));
                if (scope.fromDateTime >= 0 && scope.onchanged && typeof (scope.onchanged === 'function')) {
                    scope.onchanged(scope.modelNameFromDate, scope.fromDateTime);
                }
                $timeout(() => {
                    getFromDate(scope.tabIndex).datepicker('setDate', new Date(fromDateTime));
                    if (!scope.hideTime) {
                        getFromTime(scope.tabIndex).timepicker('setTime', fromDateTime);
                    }
                    if (
                        (scope.syncDates === true &&
                            fromDateTime.getTime() &&
                            toDateTime.getTime() &&
                            fromDateTime.getTime() > toDateTime.getTime()) ||
                        scope.newRow
                    ) {
                        setupToTimeSameAsFromTime(scope, fromDateTime);
                        updateDateTimePair(scope);
                        isToDayAfterFromDay(scope);
                    }
                }, 500);
            }

            function setupToDate(scope) {
                var baseDate = extractBaseDate(scope);
                var toDateTime = new Date(TimezoneService.toLocalTime(scope.toDateTime + baseDate));
                if (scope.toDateTime >= 0 && scope.onchanged && typeof (scope.onchanged === 'function')) {
                    scope.onchanged(scope.modelNameToDate, scope.toDateTime);
                }
                $timeout(function () {
                    getToDate(scope.tabIndex).datepicker('setDate', new Date(toDateTime));
                    if (!scope.hideTime) {
                        getToTime(scope.tabIndex).timepicker('setTime', toDateTime);
                    }
                }, 500);
            }

            function updateDateTimePair(scope) {
                var baseDate = extractBaseDate(scope);
                scope.fromDateTime =
                    TimezoneService.fromLocalTime(getFromDateTime(scope.tabIndex)).getTime() - baseDate;
                scope.toDateTime = TimezoneService.fromLocalTime(getToDateTime(scope.tabIndex)).getTime() - baseDate;
                updateCombinedDateOnChangeFn(scope);
                $rootScope.safeApply(scope);
            }

            function updateFromDateTimePair(scope) {
                var baseDate = extractBaseDate(scope);
                scope.fromDateTime =
                    TimezoneService.fromLocalTime(getFromDateTime(scope.tabIndex)).getTime() - baseDate;
                updateCombinedDateOnChangeFn(scope);
                $rootScope.safeApply(scope);
            }

            function updateToDateTimePair(scope) {
                var baseDate = extractBaseDate(scope);
                scope.toDateTime = TimezoneService.fromLocalTime(getToDateTime(scope.tabIndex)).getTime() - baseDate;
                updateCombinedDateOnChangeFn(scope);
                $rootScope.safeApply(scope);
            }

            function updateCombinedDateOnChangeFn(scope) {
                if (angular.isDefined(scope.combinedDate)) {
                    scope.combinedDate = scope.fromDateTime + '~' + scope.toDateTime;
                }
            }

            function extractBaseDate(object) {
                var baseDate = object.baseDate;
                if (!baseDate) {
                    baseDate = 0;
                }
                return baseDate;
            }

            function getFromDateTime(tabIndex) {
                var fromDateTime = getFromDate(tabIndex).datepicker('getDate');
                if (fromDateTime) {
                    fromDateTime.setHours(0, 0, 0, 0);
                    fromDateTime.setSeconds(getFromTime(tabIndex).timepicker('getSecondsFromMidnight'));
                }
                return fromDateTime;
            }

            function getToDateTime(tabIndex) {
                var toDateTime = getToDate(tabIndex).datepicker('getDate');
                if (toDateTime) {
                    toDateTime.setHours(0, 0, 0, 0);
                    toDateTime.setSeconds(getToTime(tabIndex).timepicker('getSecondsFromMidnight'));
                }
                return toDateTime;
            }

            function validateTimePairIfSameDay(scope) {
                if (isSameDay(scope.tabIndex)) {
                    validateTimePairForSameDay(scope);
                }
            }

            function validateTimePairForSameDay(scope) {
                if (
                    getToTime(scope.tabIndex).timepicker('getSecondsFromMidnight') <
                    getFromTime(scope.tabIndex).timepicker('getSecondsFromMidnight')
                ) {
                    scope.isTimePairValid = false;
                } else {
                    scope.isTimePairValid = true;
                }
                $rootScope.safeApply(scope);
            }

            function isToDayAfterFromDay(scope) {
                scope.isTimePairValid = true;
                return (
                    getToDate(scope.tabIndex).datepicker('getDate') -
                        getFromDate(scope.tabIndex).datepicker('getDate') >=
                    ONE_DAY_MILLISECONDS
                );
            }

            function initTimePickers(tabIndex) {
                var lang = $translate.use();
                $timeout(function () {
                    getFromTime(tabIndex).timepicker({ changeOnKeypress: false, scrollDefaultTime: '08:30' });
                    getToTime(tabIndex).timepicker({ changeOnKeypress: false, scrollDefaultTime: '08:30' });
                    setTimePickerFormat(lang, tabIndex);
                });
            }

            function setFormats(lang, tabIndex) {
                setTimePickerFormat(lang, tabIndex);
                setDatePickerFormat(lang, tabIndex);
            }

            function setTimePickerFormat(lang, tabIndex) {
                if (lang == 'en_US') {
                    getFromTime(tabIndex).timepicker('option', { timeFormat: 'g:ia' });
                    getToTime(tabIndex).timepicker('option', { timeFormat: 'g:ia' });
                } else {
                    getFromTime(tabIndex).timepicker('option', { timeFormat: 'H:i' });
                    getToTime(tabIndex).timepicker('option', { timeFormat: 'H:i' });
                }
            }

            function setDatePickerFormat(lang, tabIndex) {
                $timeout(function () {
                    if (lang == 'en_US') {
                        getFromDate(tabIndex).datepicker('option', 'dateFormat', 'mm/dd/yy');
                        getToDate(tabIndex).datepicker('option', 'dateFormat', 'mm/dd/yy');
                    } else {
                        getFromDate(tabIndex).datepicker('option', 'dateFormat', 'yy-mm-dd');
                        getToDate(tabIndex).datepicker('option', 'dateFormat', 'yy-mm-dd');
                    }
                });
            }

            function setupToTimeSameAsFromTime(scope, fromDateTime) {
                var addHour = scope.addHourToDate ? parseInt(scope.addHourToDate) : 0;
                if (addHour > 0 && fromDateTime) {
                    var addHour = parseInt(scope.addHourToDate);
                    getToTime(scope.tabIndex).timepicker(
                        'setTime',
                        moment(fromDateTime).add(addHour, 'hours').toDate()
                    );
                } else if (!scope.disableTime) {
                    getToTime(scope.tabIndex).timepicker('setTime', fromDateTime);
                }
            }

            return {
                scope: {
                    fromDateTime: '=',
                    toDateTime: '=',
                    baseDate: '=ogBaseDate',
                    isTimePairValid: '=',
                    isDisabled: '=',
                    hideToDateField: '=',
                    newRow: '=',
                    syncDates: '=',
                    addHourToDate: '=', // Add default hours from 'from-date' to 'to-date' in dateTime pair.
                    //  To track onchange event
                    modelNameToDate: '@',
                    modelNameFromDate: '@',
                    combinedDate: '=',
                    hideTime: '=',
                    disableTime: '=',
                    disableDate: '=',
                    onchanged: '=onchanged',
                },
                transclude: true,
                priority: 10,
                templateUrl: './app/components/directives/date_time_picker_pair/og_date_time_picker_pair.view.html',
                link: function (scope) {
                    scope.tabIndex = (Math.random() * 6).toString().replace(/\./g, '_');
                    scope.finalDetailsModule = false;
                    if (
                        (scope.hideToDateField &&
                            scope.modelNameFromDate === 'arrivalFromDateTime' &&
                            scope.modelNameToDate === 'arrivalToDateTime') ||
                        (scope.modelNameFromDate === 'departureFromDateTime' &&
                            scope.modelNameToDate === 'departureToDateTime')
                    ) {
                        scope.finalDetailsModule = true;
                    }
                    jQuery(document).ready(function(){
                        //Initialize the to date pickers
                        getToDate(scope.tabIndex).datepicker({
                            dateFormat: getDateFormat(),
                            showButtonPanel: false,
                            beforeShow: function (input, inst) {
                                let _isModal = jQuery('.modal-backdrop').length;
                                if(_isModal) {
                                    $timeout(()=> {
                                        let _offestTop = $("#" + inst.id).offset().top - $(window).scrollTop() + $("#" + inst.id)[0].getBoundingClientRect().height;
                                        inst.dpDiv.css({
                                            'top': _offestTop
                                        })
                                    })
                                }
                            },
                            onSelect: function (dateText) {
                                validateAndUpdateToDate(dateText);
                                jQuery(this).datepicker('hide');
                            },
                            onClose: function (dateText) {
                                if (dateText) {
                                    validateAndUpdateToDate(dateText);
                                } else {
                                    // Check if date is selected empty, if yes add todate to current fromdate with one hour
                                    var oneHr = parseInt(ONE_DAY_MILLISECONDS / 24);
                                    scope.toDateTime = scope.fromDateTime ? +scope.fromDateTime + oneHr : oneHr;
                                    setupToDate(scope);
                                }
                            },
                        });

                        //Initialize the from date pickers
                        getFromDate(scope.tabIndex).datepicker({
                            dateFormat: getDateFormat(),
                            showButtonPanel: false,
                            beforeShow: function (input, inst) {
                                let _isModal = jQuery('.modal-backdrop').length;
                                if(_isModal) {
                                    $timeout(()=> {
                                        let _offestTop = $("#" + inst.id).offset().top - $(window).scrollTop() + $("#" + inst.id)[0].getBoundingClientRect().height;
                                        inst.dpDiv.css({
                                            'top': _offestTop
                                        })
                                    })
                                }
                            },
                            onSelect: function (dateText) {
                                validateAndUpdateFromDate(dateText);
                                jQuery(this).datepicker('hide');
                            },
                            onClose: function (dateText) {
                                // onClose is called when the datepicker is closed,
                                // used when user inputs date-time manually
                                if (dateText) {
                                    validateAndUpdateFromDate(dateText);
                                } else {
                                    // Check if date is selected empty, if yes add fromDate to 0
                                    scope.fromDateTime = 0;
                                    setupFromDate(scope);
                                }
                            },
                        });


                        //Watcher for the toDateTime
                        scope.$watch('toDateTime', function (val) {
                            if (typeof val !== 'undefined' && !val) {
                                scope.toDateTime = parseInt(ONE_DAY_MILLISECONDS / 24);
                            }
                            setupToDate(scope);
                        });

                        //Watcher for the fromDateTime
                        scope.$watch('fromDateTime', function (val) {
                            if (typeof val !== 'undefined' && !val) {
                                scope.fromDateTime = 43200000;
                            }
                            setupFromDate(scope);
                        });

                        //Watcher for the baseDate
                        scope.$watch('baseDate', function () {
                            setupFromDate(scope);
                            setupToDate(scope);
                        });
                    });

                    function getDateFormat() {
                        return $rootScope.langSelected == 'sv_SV' ? 'yy-mm-dd' : 'dd/mm/yy';
                    }

                    function validateAndUpdateFromDate(dateText) {
                        if (!isToDayAfterFromDay(scope)) {
                            getToDate(scope.tabIndex).datepicker('setDate', dateText);
                            validateTimePairForSameDay(scope);
                        }
                        updateDateTimePair(scope);
                    }

                    function validateAndUpdateToDate(dateText) {
                        if (!isToDayAfterFromDay(scope)) {
                            getFromDate(scope.tabIndex).datepicker('setDate', dateText);
                            validateTimePairForSameDay(scope);
                        }
                        updateDateTimePair(scope);
                    }

                    //Initialize the from and to time pickers
                    initTimePickers(scope.tabIndex);

                    //prevent scrolling for parent element for from time
                    $timeout(function () {
                        getFromTime(scope.tabIndex).on('click', function () {
                            $timeout(function () {
                                $('.ui-timepicker-wrapper:visible').scrollTop(
                                    $('.ui-timepicker-wrapper:visible .ui-timepicker-selected').position().top
                                );
                                $('.ui-timepicker-wrapper').on('DOMMouseScroll mousewheel', function (ev) {
                                    var $this = $(this),
                                        scrollTop = this.scrollTop,
                                        scrollHeight = this.scrollHeight,
                                        height = $this.innerHeight(),
                                        delta = ev.originalEvent.wheelDelta,
                                        up = delta > 0;

                                    var prevent = function () {
                                        ev.stopPropagation();
                                        ev.preventDefault();
                                        ev.returnValue = false;
                                        return false;
                                    };

                                    if (!up && -delta > scrollHeight - height - scrollTop) {
                                        $this.scrollTop(scrollHeight);
                                        return prevent();
                                    } else if (up && delta > scrollTop) {
                                        $this.scrollTop(0);
                                        return prevent();
                                    }
                                });
                            }, 50);
                        });
                    });
                    //prevent scrolling for parent element for to time
                    $timeout(function () {
                        getToTime(scope.tabIndex).on('click', function () {
                            $('.ui-timepicker-wrapper:visible').scrollTop(
                                $('.ui-timepicker-wrapper:visible .ui-timepicker-selected').position().top
                            );
                            $('.ui-timepicker-wrapper').on('DOMMouseScroll mousewheel', function (ev) {
                                var $this = $(this),
                                    scrollTop = this.scrollTop,
                                    scrollHeight = this.scrollHeight,
                                    height = $this.innerHeight(),
                                    delta = ev.originalEvent.wheelDelta,
                                    up = delta > 0;

                                var prevent = function () {
                                    ev.stopPropagation();
                                    ev.preventDefault();
                                    ev.returnValue = false;
                                    return false;
                                };

                                if (!up && -delta > scrollHeight - height - scrollTop) {
                                    $this.scrollTop(scrollHeight);
                                    return prevent();
                                } else if (up && delta > scrollTop) {
                                    $this.scrollTop(0);
                                    return prevent();
                                }
                            });
                        });
                    });
                    //Event handling for the change in the from date and time pickers
                    $timeout(function () {
                        getFromTime(scope.tabIndex).on('change', function () {
                            updateFromDateTimePair(scope);
                            validateTimePairIfSameDay(scope);
                        });
                    });

                    //Event handling for the change in the to date and time pickers
                    $timeout(function () {
                        getToTime(scope.tabIndex).on('change', function () {
                            updateToDateTimePair(scope);
                            validateTimePairIfSameDay(scope);
                        });
                    });

                    //Trigger datetime format change on language change
                    scope.$on('lang:change', function (event, data) {
                        setFormats(data.lang);
                        $timeout(function () {
                            setupFromDate(scope);
                            setupToDate(scope);
                        });
                    });
                },
            };
        },
    ]);
})();
