'use strict';
import {OgModal} from "../../utils/global/ogModal";

angular.module('dialog').directive('ogOfferDetailNotesModal', [
    'RequestOffer',
    function (RequestOffer) {
        return new OgModal({
            name: 'offer:detail:notes',
            type: 'custom',
            controller: function ($scope) {
                $scope.initDialog = function (params) {
                    if (params) {
                        $scope.params = params;
                        $scope.type = params.type;

                            RequestOffer.requestOffersForMessageByEventId(
                                params.eventId,
                                function (response) {
                                    $scope.offers = response;
                                },
                                $scope.errorCallback
                            );
                        }
                    };
                },
            });
        },
    ]);

