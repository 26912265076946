import confirmationModal from '../../../../../modals/modals2.0/conformation-modal';
import amountAndPriceService from '../../../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import customMessageModal from '../../../../../modals/modals2.0/custom-message-modal';
import { ScopeFunctions } from '../../../../../../utils/global/scopeFunctions';
import { UtilFunctions } from '../../../../../../utils/global/utilFunctions';
import _ from 'underscore';

const VAT_DROPDOWN_LIST = [10, 15, 25];

class TicketsCtrl {
    constructor(
        $scope,
        $sce,
        $stateParams,
        EventTicket,
        SellerInfo,
        DateService,
        ModalService,
        BankDetails,
        EventFortNoxSetting,
        UserGroup,
        Event,
        ClientService
    ) {
        this._injected = {
            $scope,
            $sce,
            $stateParams,
            EventTicket,
            SellerInfo,
            DateService,
            ModalService,
            BankDetails,
            EventFortNoxSetting,
            UserGroup,
            Event,
            ClientService,
        };
        ScopeFunctions.addToScope($scope);
        this.viewModal = false;
        this.vatDropDownList = VAT_DROPDOWN_LIST;
        this.showTicketAddSection = false;
        this.eventId = $stateParams.eventId;
        this.sellerInfo = {};
        this.sellerInfoForm = {};
        this.getTickets();
        this.getSellerInfo();
        this.checkIsFortNoxConfigured();
        this.getBlockPaymentOption();
        this.getClientName();
        this.fortNoxSettings = {};
        this.account = {};
        this.costCenter = {};
        this.fortnoxConfigured = false;
        this.enableTermsAndConditionsEdit = false;
    }

    checkIsFortNoxConfigured() {
        const { UserGroup, $scope } = this._injected;
        let endProgress = $scope.endProgress();
        $scope.startProgress();
        UserGroup.isFortNoxConfigured(
            ((response) => {
                this.fortnoxConfigured = true;
                $scope.endProgress();
            }).bind(this),
            (error) => {
                this.fortnoxConfigured = false;
                $scope.endProgress();
            }
        );
    }

    getTickets() {
        const { EventTicket, $scope } = this._injected;
        $scope.startProgress();
        EventTicket.getTickets(
            this.eventId,
            function (response) {
                this.eventTickets = response;
                $scope.endProgress();
            }.bind(this)
        );
    }

    getSellerInfo() {
        const { SellerInfo, $scope } = this._injected;
        $scope.startProgress();
        SellerInfo.get(
            this.eventId,
            function (response) {
                this.setSellerInfo(response);
                $scope.endProgress();
            }.bind(this)
        );
    }

    setSellerInfo(response) {
        this.sellerInfo = response;
        this.originalSellerInfo = angular.copy(response);
    }

    setBankDetails() {
        const { BankDetails, $scope } = this._injected;
        $scope.startProgress();
        BankDetails.create(
            this.eventId,
            this.bankDetails,
            function (response) {
                this.bankDetails = response;
                $scope.endProgress();
            }.bind(this)
        );
    }

    getFortNoxSettings() {
        const { EventFortNoxSetting, $scope } = this._injected;
        $scope.startProgress();
        EventFortNoxSetting.listAccountsAndCostCentersRest(
            this.eventId,
            ((response) => {
                this.accounts = response.accounts;
                this.costCenters = response.costCenters;
                if (response.fortNoxSettings) {
                    this.fortNoxSettings = response.fortNoxSettings;
                    this.account = response.fortNoxSettings.accountNumber.toString();
                    this.costCenter = response.fortNoxSettings.costCenterCode;
                }
                $scope.endProgress();
            }).bind(this),
            $scope.endWithAlert()
        );
    }

    setAccountAndConstCenters() {
        const { EventFortNoxSetting, $scope } = this._injected;
        if (this.account) {
            let _accountNumber = parseInt(this.account);
            let _account = _.find(this.accounts, (a) => {
                return a.Number === _accountNumber;
            });
            this.fortNoxSettings.accountNumber = _account.Number;
            this.fortNoxSettings.accountDescription = _account.Description;
        }
        if (this.costCenter) {
            let _costCenter = _.find(this.costCenters, (c) => {
                return c.Code === this.costCenter;
            });
            this.fortNoxSettings.costCenterCode = _costCenter.Code;
            this.fortNoxSettings.costCenterDescription = _costCenter.Description;
        }
        $scope.startProgress();
        if (this.fortNoxSettings.id) {
            this.fortNoxSettings.eventId = this.fortNoxSettings.event.id;
            EventFortNoxSetting.update(
                this.fortNoxSettings,
                function (response) {
                    if (response.id) {
                        this.fortNoxSettings = response;
                    }
                    $scope.endProgress();
                }.bind(this),
                $scope.endWithAlert()
            );
        } else {
            this.fortNoxSettings.eventId = this.eventId;
            EventFortNoxSetting.save(
                this.fortNoxSettings,
                function (response) {
                    if (response.id) {
                        this.fortNoxSettings = response;
                    }
                    $scope.endProgress();
                }.bind(this),
                $scope.endWithAlert()
            );
        }
    }

    getClientName() {
        const { ClientService } = this._injected;
        this.clientName = ClientService.getClientName();
    }

    trustAsHtml(string) {
        const { $sce, $scope } = this._injected;
        return $sce.trustAsHtml(string);
    }

    deleteAccountAndCostCenters() {
        const { EventFortNoxSetting, $scope } = this._injected;
        if (this.fortNoxSettings.id) {
            $scope.startProgress();
            EventFortNoxSetting['delete'](
                this.fortNoxSettings.id,
                () => {
                    this.fortNoxSettings = {};
                    this.account = null;
                    this.costCenter = null;
                    $scope.endProgress();
                },
                $scope.endWithAlert()
            );
        }
    }

    disableSaveButton() {
        if (this.bankDetails) {
            if (this.bankDetails.bankLocation == 'SWEDEN' || this.bankDetails.bankLocation == 'EUROPE') {
                return false;
            }
        }
        return true;
    }

    getBankDetails() {
        const { BankDetails, $scope } = this._injected;
        $scope.startProgress();
        BankDetails.get(
            this.eventId,
            function (response) {
                this.bankDetails = response;
                console.log('get');
                console.log(this.bankDetails);
                $scope.endProgress();
            }.bind(this)
        );
    }

    getBlockPaymentOption() {
        const { Event, $scope } = this._injected;
        $scope.startProgress();
        Event.getBlockPaymentOption(
            this.eventId,
            function (response) {
                this.blockPaymentOption = response.blockPaymentOption;
                $scope.endProgress();
            }.bind(this)
        );
    }

    createTicketCallback(newTicket) {
        this.eventTickets.push(newTicket);
        this.showTicketAddSection = false;
    }

    isSellerInfoEmpty() {
        return (
            UtilFunctions.isUndefinedOrNull(this.originalSellerInfo.city) &&
            UtilFunctions.isUndefinedOrNull(this.originalSellerInfo.companyName) &&
            UtilFunctions.isUndefinedOrNull(this.originalSellerInfo.postalNumber) &&
            UtilFunctions.isUndefinedOrNull(this.originalSellerInfo.street) &&
            UtilFunctions.isUndefinedOrNull(this.originalSellerInfo.termsAndConditions) &&
            UtilFunctions.isUndefinedOrNull(this.originalSellerInfo.vatNumber)
        );
    }

    confirmUpdateSellerInfoForEventAndGroup() {
        if (!this.sellerInfoForm.$valid) {
            return;
        }
        this.showSellerInfoConfirmationForUserGroupUpdate = this.isSellerInfoEmpty();
        if (!this.showSellerInfoConfirmationForUserGroupUpdate) {
            this.createOrUpdateSellerInfo(false);
        }
    }

    enableTermsAndConditionsEditMode() {
        this.enableTermsAndConditionsEdit = true;
    }

    createOrUpdateSellerInfo(updateForUserGroup) {
        const { SellerInfo, $scope } = this._injected;
        $scope.startProgress();
        this.sellerInfo.updateForUserGroup = updateForUserGroup;
        if (this.sellerInfo.id) {
            SellerInfo.update(
                this.eventId,
                this.sellerInfo.id,
                this.sellerInfo,
                function (response) {
                    this.setSellerInfo(response);
                    this.showSellerInfoConfirmationForUserGroupUpdate = false;
                    this.enableTermsAndConditionsEdit = false;
                    $scope.endProgress();
                }.bind(this),
                $scope.endWithAlert()
            );
        } else {
            SellerInfo.create(
                this.eventId,
                this.sellerInfo,
                function (response) {
                    this.setSellerInfo(response);
                    this.showSellerInfoConfirmationForUserGroupUpdate = false;
                    $scope.endProgress();
                }.bind(this),
                $scope.endWithAlert()
            );
        }
    }

    setTicketToEditMode(eventTicket) {
        this.originalTicket = angular.copy(eventTicket);
        eventTicket.edit = true;
        this.showTicketAddSection = false;
    }

    updateTicketCallback(updatedTicket) {
        let updatedTicketIndex = _.findIndex(this.eventTickets, { id: updatedTicket.id });
        updatedTicket.edit = false;
        this.eventTickets.splice(updatedTicketIndex, 1, updatedTicket);
    }

    confirmPaymentMethod(paymentMethod) {
        this.selectedPaymentMethod = paymentMethod;
        this.sellerInfo.paymentMethod.name = paymentMethod;
        if (this.selectedPaymentMethod === 'CREDIT_CARD') {
            this.showPaymentMethodConfirmationForUserGroupUpdate = false;
        } else {
            this.showPaymentMethodConfirmationForUserGroupUpdate = this.isSellerInfoEmpty();
        }
        if (!this.showPaymentMethodConfirmationForUserGroupUpdate) {
            this.updatePaymentMethod();
        }
    }

    updatePaymentMethod(updateForUserGroup = false) {
        const { SellerInfo, $scope } = this._injected;
        $scope.startProgress();
        let data = { paymentMethod: this.selectedPaymentMethod, updateForUserGroup: updateForUserGroup };
        SellerInfo.updatePaymentMethod(
            this.eventId,
            data,
            function (response) {
                this.setSellerInfo(response);
                this.showPaymentMethodConfirmationForUserGroupUpdate = false;
                $scope.endProgress();
            }.bind(this),
            $scope.endWithAlert()
        );
    }

    cancelCallback(eventTicket) {
        if (eventTicket.id > 0) {
            this.updateTicketCallback(this.originalTicket);
        } else {
            this.showTicketAddSection = false;
        }
    }

    parseDateInLocaleFormat(dateTime) {
        const { DateService } = this._injected;
        return DateService.parseDateInLocaleFormat(dateTime);
    }

    addEditTicket() {
        this.newTicket = {};
        this.showTicketAddSection = true;
    }

    deleteTicket(eventTicket) {
        const { ModalService } = this._injected;
        if(this.viewModal) {
            return;
        }
        this.viewModal = true;
        let confirmationModalReference = confirmationModal;
        let data = {
            message: 'confirm.delete.event.ticket',
            okCallBack: function () {
                this.confirmDeleteTicket(eventTicket);
            }.bind(this),
            okLabel: 'Confirm',
        };
        confirmationModalReference.inputs = { data };
        ModalService.showModal(confirmationModalReference).then((modal) => {
            this.viewModal = false;
            modal.element.modal();
            modal.close.then(() => {
                console.log('modal is closed!');
            });
        });
    }

    deleteBankDetails() {
        const { ModalService } = this._injected;
        if(this.viewModal) {
            return;
        }
        this.viewModal = true;
        let confirmationModalReference = confirmationModal;
        let data = {
            message: 'confirm.delete.bank.details',
            okCallBack: function () {
                this.confirmDeleteBankDetails();
            }.bind(this),
            okLabel: 'Confirm',
        };
        confirmationModalReference.inputs = { data };
        ModalService.showModal(confirmationModalReference).then((modal) => {
            this.viewModal = false;
            modal.element.modal();
            modal.close.then(() => {
                console.log('modal is closed!');
            });
        });
    }

    confirmDeleteBankDetails() {
        const { BankDetails, $scope, ModalService } = this._injected;
        $scope.startProgress();
        BankDetails.delete(
            this.eventId,
            this.bankDetails.id,
            function () {
                this.bankDetails = null;
                $scope.endProgress();
            }.bind(this),
            function (error) {
                $scope.endProgress();
                let data = {
                    message: error.data.localisedMessages[0].messageKey,
                };

                let customMessageModalData = customMessageModal;
                customMessageModal.inputs = { data };

                ModalService.showModal(customMessageModalData).then((modal) => {
                    modal.element.modal();
                    modal.close.then(() => {
                        console.log('modal is closed!');
                    });
                });
            }
        );
    }

    confirmDeleteTicket(ticket) {
        const { EventTicket, $scope, ModalService } = this._injected;
        $scope.startProgress();
        EventTicket.delete(
            this.eventId,
            ticket.id,
            function () {
                let targetTicketIndex = _.findIndex(this.eventTickets, { id: ticket.id });
                if (targetTicketIndex > -1) {
                    this.eventTickets.splice(targetTicketIndex, 1);
                }
                $scope.endProgress();
            }.bind(this),
            function (error) {
                $scope.endProgress();
                let data = {
                    message: error.data.localisedMessages[0].messageKey,
                };

                let customMessageModalData = customMessageModal;
                customMessageModal.inputs = { data };

                ModalService.showModal(customMessageModalData).then((modal) => {
                    modal.element.modal();
                    modal.close.then(() => {
                        console.log('modal is closed!');
                    });
                });
            }
        );
    }

    getPriceLocalisedWithCurrency(price, currency) {
        price = price || 0; //if price is null, set price to 0
        return amountAndPriceService.getAmountLocalisedWithCurrency(price, currency);
    }
}

TicketsCtrl.$inject = [
    '$scope',
    '$sce',
    '$stateParams',
    'EventTicket',
    'SellerInfo',
    'DateService',
    'ModalService',
    'BankDetails',
    'EventFortNoxSetting',
    'UserGroup',
    'Event',
    'ClientService',
];
export default TicketsCtrl;
