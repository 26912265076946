'use strict';

import {ScopeFunctions} from '../../../../../utils/global/scopeFunctions';

// Register `Activities` component, along with its associated controller and template
angular.module('activity').component('activities', {
    templateUrl: './app/components/modules/event/events/activities/activities.html',
    controller: [
        '$scope',
        function ActivityController($scope) {
            var vm = this;
            vm.searchParams = {type: 'creatorAllActivities', numPerPage: 20};
            ScopeFunctions.addToScope($scope);
        }]
});
