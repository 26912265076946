'use strict';

import shareParticipantsModal from '../../../../../modals/modals2.0/share-participants';
import importParticipantsModal from '../../../../../modals/modals2.0/import-participants';
import amountAndPriceService from '../../../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import currencyUtil from '../../../../../../utils/CurrencyUtil';
import { ScopeFunctions } from '../../../../../../utils/global/scopeFunctions';
import { OgModal } from "../../../../../../utils/global/ogModal";
import _ from 'underscore';
import { DownloadUtilFunctions } from '../../../../../../utils/global/DownloadUtilFunction';
import {UtilFunctions} from "../../../../../../utils/global/utilFunctions";

const LIST_OF_SORTING_OPTIONS = ['First Name', 'Last Name', 'Company', 'Email', 'Time of registration'];
const LIST_OF_SORTING_OPTIONS_SN = ['Förnamn', 'Efternamn', 'Företag', 'E-post', 'Tid för registrering'];
const LIST_OF_SORTING_OPTIONS_NB = ['Fornavn', 'Etternavn', 'Selskap', 'E-postadresse', 'Tidspunkt for registrering'];
const LIST_OF_SORTING_OPTIONS_DK = ['Fornavn', 'Efternavn', 'Virksomhed', 'E-mail', 'Tidspunkt for registrering'];
const LIST_OF_SORTING_OPTIONS_FI = ['Etunimi', 'Sukunimi', 'Yritys', 'Sähköposti', 'Rekisteröinnin aika'];
const PARTICIPANT_REGISTRATION_TIME_FIELDS = [
    'Time of registration',
    'Tid för registrering',
    'Tidspunkt for registrering',
    'Rekisteröinnin aika',
];
const PARTICIPANT_EMAILS_BOUNCED_ENUMS = [
    'BOUNCED_EMAIL',
    'BOUNCED_FROM_SUPPRESSION_LIST',
    'EMAIL_UNDELIVERABLE_FROM_API',
];

const BASE_URL = './app/components/modules/event/event_detail/participants/list/design-invitation';

// Register `participants.list` component, along with its associated controller and template
angular.
    module('participants.list').
    component('participantslist', {
        templateUrl: './app/components/modules/event/event_detail/participants/list/list.view.html',
        controllerAs: 'participantListCtrl',
        controller: [
            '$scope',
            '$stateParams',
            '$rootScope',
            'ProcessLog',
            'Participant',
            'Event',
            '$translate',
            'TimezoneService',
            'EventEnum',
            'ModalService',
            'ParticipantService',
            '$timeout',
            '$q',
            'ParticipantUtilService',
            'Page',
            'DateService',
            'UserGroup',
            '$location',
            '$state',
            'EventPresentation',
            'AuthenticationService',
            'ActiveTabService',
            'CommonFunctions',
            function ParticipantListController(
                $scope,
                $stateParams,
                $rootScope,
                ProcessLog,
                Participant,
                Event,
                $translate,
                TimezoneService,
                EventEnum,
                ModalService,
                ParticipantService,
                $timeout,
                $q,
                ParticipantUtilService,
                Page,
                DateService,
                UserGroup,
                $location,
                $state,
                EventPresentation,
                AuthenticationService,
                ActiveTabService,
                CommonFunctions
            ) {
                var vm = this;
                vm.deleteParticipant = deleteParticipantFn;
                vm.deleteSelectedParticipants = deleteSelectedParticipantsFn;
                vm.deleteEventMessage = deleteEventMessageFn;
                // vm.displayFilteredList = displayFilteredListFn;
                vm.deleteProcessLog = deleteProcessLogFn;
                vm.deleteProcessLogOfInvitation = deleteProcessLogOfInvitationFn;
                vm.deleteProcessLogOfParticipantDelete = deleteProcessLogOfParticipantDeleteFn;
                vm.deleteParticipants = deleteParticipantsFn;
                vm.doNotOpenEditDialog = doNotOpenEditDialogFn;
                vm.exportInvoice = exportInvoiceToFortNoxFn;
                vm.isNameFieldExists = isNameFieldExistsFn;
                // vm.getSortingCriteria = getSortingCriteriaFn;
                vm.getParticipantBasicQueries = getParticipantBasicQueriesFn;
                vm.getFieldValue = getFieldValueFn;
                vm.getPaymentType = getPaymentTypeFn;
                vm.formatDateTime = formatDateTimeFn;
                vm.importParticipants = importParticipantsFn;
                vm.init = initFn;
                vm.isStatusActive = isStatusActiveFn;
                vm.isParticipantsImporting = isParticipantsImportingFn;
                vm.isParticipantsDeleteRunning = isParticipantsDeleteRunningFn;
                vm.listParticipants = listParticipantsFn;
                vm.getParticipantsShareDetails = getParticipantsShareDetailsFn;
                vm.openDialogAddParticipant = openDialogAddParticipantFn;
                vm.openDialogEditParticipant = openDialogEditParticipantFn;
                vm.openImportParticipantFromEventDialog = openImportParticipantFromEventDialogFn;
                vm.openParticipantChangeStatusDialog = openParticipantChangeStatusDialogFn;
                vm.openShareParticipantsModal = openShareParticipantsModalFn;
                vm.pageChanged = pageChangedFn;
                vm.resetParticipants = resetParticipantsFn;
                vm.checkParticipantInvitationStatus = checkParticipantInvitationStatusFn;
                vm.checkParticipantDeletingStatus = checkParticipantDeletingStatusFn;
                vm.sendParticipantEmails = sendParticipantEmailsFn;
                vm.toggleParticipantSelection = toggleParticipantSelectionFn;
                vm.toggleParticipantViewByStatus = toggleParticipantViewByStatusFn;
                vm.getHeaderName = getHeaderNameFn;
                vm.attendingCellRender = attendingCellRenderFn;
                vm.isBouncedEmail = isBouncedEmailFn;
                vm.sortOptionChange = sortOptionChangeFn;
                vm.searchOptionChange = searchOptionChangeFn;
                vm.disableSendMessage = disableSendMessageFn;
                vm.getParticipantName = getParticipantNameFn;
                vm.getPriceWithVatLocalisedWithCurrency = getPriceWithVatLocalisedWithCurrencyFn;
                vm.getDateFormatForTicket = getDateFormatForTicketFn;
                vm.openSelectedMessage = openSelectedMessageFn;
                vm.sendGroupMessage = sendGroupMessageFn;
                vm.getEventMessages = getEventMessagesFn;
                vm.clearSearchField = clearSearchFieldFn;
                vm.downloadFile = downloadFileFn;
                vm.startDownload = startDownloadFn;
                vm.openDialogSendToForm = openDialogSendToFormFn;
                vm.deleteParticipantDeleteSuccessMessage = deleteParticipantDeleteSuccessMessageFn;
                vm.viewModal = false;
                vm.isInvitationBlocked = null;
                vm.hasAnyRequestsSentInEvent = null;
                vm.alreadyAnsweredQuestions = null;

                vm.getDateSpan = DateService.getDateSpan;
                vm.eventId = $stateParams.eventId;
                vm.columnDefs = [];
                vm.gridOptions = {};
                vm.PARTICIPANT_FILTER_LIST = ['TOTAL'];
                vm.selectAllParticipant = { checked: false };
                vm.participantUpdating = false;
                vm.sort = sortFn;
                vm.ascendingOrder = false;
                vm.descendingOrder = false;
                vm.participantsDropDownList = [
                    'ALL',
                    'NONE',
                    'NOT_INVITED',
                    'NOT_REPLIED',
                    'ATTENDING',
                    'NOT_ATTENDING',
                    'PENDING',
                    'BOUNCED_EMAIL',
                ];
                vm.pagination = { max: 30, offset: 0 }; //fetch 50 participants on each page
                vm.currentPage = 1;
                vm.sortFields = '';
                vm.sorting = { field: null, direction: 'asc' };
                vm.search = { text: null, status: null };
                vm.checkIsParticipantEmailSending = true; // set to true to indicate that email is sending at the start
                vm.actualParticipantDeletedDate = null;
                vm.isTimeOfRegistration = false;
                vm.baseUrl = BASE_URL;
                vm.setTemplateUrl = setTemplateUrlFn;
                vm.saveWillProcureUserFeedback = saveWillProcureUserFeedbackFn;
                vm.saveNoProcurementUserFeedback = saveNoProcurementUserFeedbackFn;
                vm.setSoftBlockOption = setSoftBlockOptionFn;
                vm.setActive = setActiveFn;
                vm.sendInvitationBlockerEmailForManagement = sendInvitationBlockerEmailForManagementFn;
                vm.openInvitationTab = openInvitationTabFn;
                vm.eventId = $stateParams.eventId;
                ScopeFunctions.addToScope($scope);

                switch ($stateParams.highlightElement) {
                    case 'groupMessageButton':
                        vm.animateGroupMessageButton = true;
                        setTimeout(function () {
                            vm.animateGroupMessageButton = false;
                        }, 6800);
                        break;
                    case 'shareParticipantListButton':
                        vm.animateShareParticipantListButton = true;
                        setTimeout(function () {
                            vm.animateShareParticipantListButton = false;
                        }, 6800);
                        break;
                }
                vm.init();
                vm.listParticipants();

                function isParticipantsImportingFn(eventId) {
                    var deferred = $q.defer();
                    ProcessLog.getImportStatus(
                        { referenceId: eventId },
                        (resp) => {
                            vm.participantUploadProcess = resp;
                            vm.participantUploding = resp.status && resp.status.name === 'RUNNING';
                            if (vm.participantUploding) {
                                vm.currentParticipantImportProgress = !isNaN(resp.errorLog) ? parseInt(resp.errorLog) : null;
                                $timeout(() => {
                                    isParticipantsImportingFn(eventId);
                                }, 2000);
                            }
                            if (resp.status && resp.status.name === 'GOT_ERROR') {
                                let _error = angular.fromJson(resp.errorLog);
                                vm.participantsUploadErrors = CommonFunctions.errorMessages(_error, true);
                            } else if (resp.status && resp.status.name === 'SUCCESS_WITH_MESSAGE') {
                                let _error = angular.fromJson(resp.errorLog);
                                vm.participantsUploadErrors = CommonFunctions.errorMessages(_error, true);
                                $timeout(() => {
                                    loadParticipantsFn();
                                }, 100);
                            }
                            deferred.resolve(resp);
                        },
                        (err) => {
                            console.log(err);
                            deferred.reject('ERROR');
                        }
                    );
                    return deferred.promise;
                }

                function isParticipantsInvitationRunningFn(eventId, checkInvitationRunning = false) {
                    if (!eventId) {
                        return;
                    }
                    var deferred = $q.defer();
                    ProcessLog.get(
                        { referenceId: eventId, name: 'PARTICIPANT_INVITATION_EMAIL' },
                        (resp) => {
                            vm.participantInvitationProcess = resp;
                            vm.participantInvitationRunning = resp.status && resp.status.name === 'RUNNING_WITH_MESSAGE';
                            if (vm.participantInvitationRunning) {
                                vm.currentParticipantInvitationProgress = !isNaN(resp.errorLog)
                                    ? parseInt(resp.errorLog)
                                    : null;
                                $timeout(() => {
                                    isParticipantsInvitationRunningFn(eventId);
                                }, 10000);
                            }
                            //check till 10 seconds only otherwise need to press check status button
                            if (
                                resp.status &&
                                resp.status.name === 'RUNNING_WITH_MESSAGE' &&
                                vm.checkIsParticipantEmailSending &&
                                !checkInvitationRunning
                            ) {
                                let _error = angular.fromJson(resp.errorLog);
                                vm.participantsCount = CommonFunctions.errorMessages(_error, true)[0].params.p0;
                                // vm.timeNeededToComplete = Math.ceil((0.35 / 60) * vm.participantsCount); // To send one email it takes approx 0.35 sec
                                $timeout(() => {
                                    isParticipantsInvitationRunningFn(eventId, true);
                                }, 10000);
                            } else if (resp.status && resp.status.name === 'GOT_ERROR') {
                                vm.checkIsParticipantEmailSending = false;
                                let _error = angular.fromJson(resp.errorLog);
                                vm.participantsInvitationErrors = CommonFunctions.errorMessages(_error, true);
                            } else if (resp.status && resp.status.name === 'SUCCESS_WITH_MESSAGE') {
                                let _error = angular.fromJson(resp.errorLog);
                                vm.checkIsParticipantEmailSending = false;
                                vm.participantsInvitationErrors = CommonFunctions.errorMessages(_error, true);
                                vm.unsubscribedParticipantsCount = vm.participantsInvitationErrors[0].params.p1;
                                vm.invalidParticipantsCount = vm.participantsInvitationErrors[0].params.p2;
                                $timeout(() => {
                                    loadParticipantsFn();
                                }, 100);
                            }
                            deferred.resolve(resp);
                        },
                        (err) => {
                            vm.checkIsParticipantEmailSending = false;
                            console.log('.>>', err);
                            deferred.reject('ERROR');
                        }
                    );
                    return deferred.promise;
                }

                function isParticipantsDeleteRunningFn(eventId) {
                    if (!eventId) {
                        return;
                    }
                    var deferred = $q.defer();
                    ProcessLog.get(
                        { referenceId: eventId, name: 'PARTICIPANT_DELETE' },
                        (resp) => {
                            vm.participantDeleteProcess = resp;
                            vm.participantDeleting = resp.status && resp.status.name === 'RUNNING';
                            if (resp.status && resp.status.name === 'GOT_ERROR') {
                                let _error = angular.fromJson(resp.errorLog);
                                vm.participantsDeleteErrors = CommonFunctions.errorMessages(_error, true);
                            } else if (resp.status && resp.status.name === 'SUCCESS_WITH_MESSAGE') {
                                let _error = angular.fromJson(resp.errorLog);
                                vm.participantsDeleteErrors = CommonFunctions.errorMessages(_error, true);
                                $timeout(() => {
                                    loadParticipantsFn();
                                }, 100);
                            }
                            deferred.resolve(resp);
                        },
                        (err) => {
                            console.log(err);
                            deferred.reject('ERROR');
                        }
                    );
                    return deferred.promise;
                }

                function toggleParticipantViewByStatusFn(status) {
                    vm.PARTICIPANT_FILTER_LIST = [];
                    vm.selectedParticipants = [];
                    if (status === 'TOTAL') {
                        vm.search.status = null;
                    } else {
                        vm.search.status = status;
                        vm.sortFields = status;
                    }
                    vm.PARTICIPANT_FILTER_LIST.push(status);
                    listParticipantsFn();
                }

                function isStatusActiveFn(status) {
                    return vm.PARTICIPANT_FILTER_LIST.indexOf(status) > -1;
                }

                function deleteProcessLogFn() {
                    $scope.startProgress('participant-lists');
                    let processId = vm.participantUploadProcess.id;
                    ProcessLog['deleteImportLogs'](
                        processId,
                        function () {
                            $scope.endProgress('participant-lists');
                            vm.participantUploadProcess = null;
                            vm.participantUploding = null;
                            vm.participantsUploadErrors = null;
                        },
                        $scope.endWithErrorOverlay('participant-lists')
                    );
                }

                function deleteProcessLogOfInvitationFn() {
                    $scope.startProgress('participant-lists');
                    let processId = vm.participantInvitationProcess.id;
                    ProcessLog['delete'](
                        processId,
                        function () {
                            $scope.endProgress('participant-lists');
                            vm.participantInvitationProcess = null;
                            vm.participantInvitationRunning = null;
                            vm.participantsInvitationErrors = null;
                            isParticipantsInvitationRunningFn(vm.eventId);
                        },
                        $scope.endWithErrorOverlay('participant-lists')
                    );
                }

                function deleteProcessLogOfParticipantDeleteFn() {
                    $scope.startProgress('participant-lists');
                    let processId = vm.participantDeleteProcess.id;
                    ProcessLog['delete'](
                        processId,
                        function () {
                            $scope.endProgress('participant-lists');
                            vm.participantDeleteProcess = null;
                            vm.participantDeleting = null;
                            vm.participantsDeleteErrors = null;
                            isParticipantsDeleteRunningFn(vm.eventId);
                        },
                        $scope.endWithErrorOverlay('participant-lists')
                    );
                }

                function downloadFileFn(fileFormat, extension) {
                    let totalCount = vm.pagination.totalCount;
                    if (parseInt(totalCount) < 100) {
                        vm.startDownload(fileFormat, extension);
                    } else {
                        vm.openDialogSendToForm(fileFormat, extension);
                    }
                }

                function startDownloadFn(fileFormat, extension, sendTo) {
                    console.log('$location', $location.host());
                    let totalCount = vm.pagination.totalCount;
                    let _url =
                        'api/v1/participant/event/' +
                        vm.eventId +
                        '/exportParticipants?fileformat=' +
                        fileFormat +
                        '&extension=' +
                        extension +
                        '&filterCriteria=' +
                        vm.PARTICIPANT_FILTER_LIST.join() +
                        '&searchParams=' +
                        (vm.searchText ? vm.searchText : '') +
                        '&max=' +
                        totalCount +
                        '';
                    console.log('url', _url);
                    if (!!sendTo) {
                        _url += '&sendTo=' + sendTo + '';
                    }
                    DownloadUtilFunctions.downloadFile(_url, sendTo);
                }

                function deleteSelectedParticipantsFn() {
                    var params = {
                        eventId: vm.eventId,
                        selectedParticipants: vm.selectedParticipants,
                    };
                    $rootScope.$broadcast('dialog:participants:delete:confirmation:open', {
                        message: 'participant.delete.selected.confirm',
                        data: params,
                        okCallBack: vm.deleteParticipants,
                    });
                }

                function deleteEventMessageFn(message) {
                    var params = {
                        eventId: vm.eventId,
                        eventMessage: message,
                    };
                    $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, {
                        message: 'event.message.delete.confirm',
                        okCallBack: function () {
                            $scope.startProgress();
                            Participant.deleteEventMessage(
                                params,
                                function () {
                                    $scope.endProgress();
                                    vm.getEventMessages();
                                },
                                $scope.endWithAlert()
                            );
                        },
                    });
                }

                function deleteParticipantsFn() {
                    var params = {
                        eventId: vm.eventId,
                        selectedParticipants: vm.selectedParticipants,
                    };
                    if (vm.selectedParticipants.length > 50) {
                        params['deleteOnBatch'] = true;
                    }
                    $scope.startProgress('participant-lists');
                    Participant.deleteSelectedParticipants(
                        params,
                        function () {
                            if (vm.selectedParticipants.length <= 50) {
                                vm.participantDeleteSuccessMessage = $translate.instant('participant.deleted.successfully', {
                                    p0: vm.selectedParticipants.length,
                                });
                            }
                            if (vm.successCallback) {
                                vm.successCallback();
                            }
                            $scope.endProgress('participant-lists');
                            vm.resetParticipants();
                        },
                        $scope.endWithAlert('participant-lists')
                    );
                }

                function doNotOpenEditDialogFn() {
                    CommonFunctions.stopPropagation();
                }

                function exportInvoiceToFortNoxFn() {
                    let invalidSelectedParticipantsForInvoice = vm.selectedParticipants.some(
                        (participant) => !(participant.ticket && participant.ticket.fortNoxDetails)
                    );

                    if (vm.selectedParticipants.length <= 0) {
                        OgModal.open('alert', { message: 'alert.message.for.no.participant.selected' });
                        return;
                    }
                    if (invalidSelectedParticipantsForInvoice) {
                        OgModal.open('alert', { message: 'alert.message.for.exporting.not.frontnox.generated' });
                        return;
                    }
                    $scope.startProgress('participant-lists');
                    Participant.exportParticipantsToFortNox(
                        vm.eventId,
                        { participants: vm.selectedParticipants },
                        (result) => {
                            vm.resetParticipants();
                            //OgModal.open('alert', { message: 'success.message.export.to.frontnox' });
                            $scope.endProgress('participant-lists');
                        },
                        $scope.endWithAlert('participant-lists')
                    );
                }

                function getHeaderNameFn(query) {
                    return ParticipantService.getQuestionLabel(query, vm.languageSelected);
                }

                function formatDateTimeFn(value) {
                    return TimezoneService.formatDate(new Date(parseInt(value)), getDateTimeDisplayFormatFn());
                }

                function getDateDisplayFormatFn() {
                    let lang = $rootScope.langSelected;
                    if (lang === 'sv_SV' || lang === 'nb_NB' || lang === 'da_DK' || lang === 'fi_FI') {
                        return 'YYYY-MM-DD';
                    } else {
                        return 'DD/MM/YYYY';
                    }
                }

                function getDateTimeDisplayFormatFn() {
                    let lang = $rootScope.langSelected;
                    if (lang === 'sv_SV' || lang === 'nb_NB' || lang === 'da_DK' || lang === 'fi_FI') {
                        return 'YYYY-MM-DD HH:mm';
                    } else {
                        return 'DD/MM/YYYY hh:mm a';
                    }
                }

                function getDateFormatForTicketFn() {
                    let lang = $rootScope.langSelected;
                    if (lang === 'sv_SV' || lang === 'nb_NB' || lang === 'da_DK' || lang === 'fi_FI') {
                        return 'yyyy-MM-dd';
                    } else {
                        return 'dd/MM/yyyy';
                    }
                }

                function isBouncedEmailFn(participant) {
                    return PARTICIPANT_EMAILS_BOUNCED_ENUMS.indexOf(participant.attending) !== -1;
                }

                function attendingCellRenderFn(participant) {
                    var defaultText = $translate.instant('participant.' + participant.attending);
                    if (isBouncedEmailFn(participant)) {
                        var invalidEmailText = $translate.instant('email.email.invalid');
                        if (participant.attending === 'EMAIL_UNDELIVERABLE_FROM_API') {
                            var reasonText = $translate.instant('participant.email.status.' + participant.statusReason);
                            return invalidEmailText + ' (' + reasonText + ')';
                        } else {
                            var reasonTxt = $translate.instant('participant.aws.' + participant.attending);
                            return invalidEmailText + ' (' + reasonTxt + ')';
                        }
                    } else {
                        return defaultText;
                    }
                }

                function toggleParticipantSelectionFn(participantId) {
                    var participantIsSelected = false;
                    angular.forEach(vm.selectedParticipants, function (participant, i) {
                        if (participantId === participant.id) {
                            participantIsSelected = true;
                            vm.selectedParticipants.splice(i, 1);
                        }
                    });
                    if (participantIsSelected === false) {
                        vm.selectedParticipants.push(getParticipantById(participantId));
                    }
                }

                function isNameFieldExistsFn() {
                    let fullNameArray = _.find(vm.basicQueryForEvent, (query) => {
                        return CommonFunctions.hasIntersectValue(['First Name'], [query.detailNameEnglish]);
                    });
                    return fullNameArray;
                }

                function addPrioritySortingFn() {
                    if (!vm.sortingOptions) return;
                    let length = vm.sortingOptions.length;
                    if (vm.languageSelected === 'en_US') {
                        if (vm.sortFields === 'PENDING' && vm.sortingOptions[length - 1] !== 'Waitlist') {
                            vm.sortingOptions[length] = 'Waitlist';
                        } else {
                            if (vm.sortingOptions[length - 1] === 'Waitlist' && vm.sortFields !== 'PENDING') {
                                vm.sortingOptions.splice(length - 1, 1);
                            }
                        }
                    } else if (vm.languageSelected === 'sv_SV') {
                        if (vm.sortFields === 'PENDING' && vm.sortingOptions[length - 1] !== 'Väntelista') {
                            vm.sortingOptions[length] = 'Väntelista';
                        } else {
                            if (vm.sortingOptions[length - 1] === 'Väntelista' && vm.sortFields !== 'PENDING') {
                                vm.sortingOptions.splice(length - 1, 1);
                            }
                        }
                    } else if (vm.languageSelected === 'nb_NB') {
                        if (vm.sortFields === 'PENDING' && vm.sortingOptions[length - 1] !== 'Avventer') {
                            vm.sortingOptions[length] = 'Avventer';
                        } else {
                            if (vm.sortingOptions[length - 1] === 'Avventer' && vm.sortFields !== 'PENDING') {
                                vm.sortingOptions.splice(length - 1, 1);
                            }
                        }
                    } else if (vm.languageSelected === 'da_DK') {
                        if (vm.sortFields === 'PENDING' && vm.sortingOptions[length - 1] !== 'Venteliste') {
                            vm.sortingOptions[length] = 'Venteliste';
                        } else {
                            if (vm.sortingOptions[length - 1] === 'Venteliste' && vm.sortFields !== 'PENDING') {
                                vm.sortingOptions.splice(length - 1, 1);
                            }
                        }
                    } else {
                        if (vm.sortFields === 'PENDING' && vm.sortingOptions[length - 1] !== 'Odotuslista') {
                            vm.sortingOptions[length] = 'Odotuslista';
                        } else {
                            if (vm.sortingOptions[length - 1] === 'Odotuslista' && vm.sortFields !== 'PENDING') {
                                vm.sortingOptions.splice(length - 1, 1);
                            }
                        }
                    }
                }

                function searchingOptionFn() {
                    if (vm.languageSelected === 'en_US') {
                        vm.searchingOptions = vm.queries.map(function (fieldValue) {
                            if (fieldValue.detailNameEnglish) {
                                return fieldValue.detailNameEnglish;
                            } else if (fieldValue.detailNameSwedish) {
                                return fieldValue.detailNameSwedish;
                            } else if (fieldValue.detailNameNorwegian) {
                                return fieldValue.detailNameNorwegian;
                            } else if (fieldValue.detailNameDanish) {
                                return fieldValue.detailNameDanish;
                            } else {
                                return fieldValue.detailNameFinnish;
                            }
                        });
                        vm.searchingOptions.push('Time of registration');
                    } else if (vm.languageSelected === 'sv_SV') {
                        vm.searchingOptions = vm.queries.map(function (fieldValue) {
                            if (fieldValue.detailNameSwedish) {
                                return fieldValue.detailNameSwedish;
                            } else if (fieldValue.detailNameEnglish) {
                                return fieldValue.detailNameEnglish;
                            } else if (fieldValue.detailNameNorwegian) {
                                return fieldValue.detailNameNorwegian;
                            } else if (fieldValue.detailNameDanish) {
                                return fieldValue.detailNameDanish;
                            } else {
                                return fieldValue.detailNameFinnish;
                            }
                        });
                        vm.searchingOptions.push('Tid för registrering');
                    } else if (vm.languageSelected === 'nb_NB') {
                        vm.searchingOptions = vm.queries.map(function (fieldValue) {
                            if (fieldValue.detailNameNorwegian) {
                                return fieldValue.detailNameNorwegian;
                            } else if (fieldValue.detailNameEnglish) {
                                return fieldValue.detailNameEnglish;
                            } else if (fieldValue.detailNameSwedish) {
                                return fieldValue.detailNameSwedish;
                            } else if (fieldValue.detailNameDanish) {
                                return fieldValue.detailNameDanish;
                            } else {
                                return fieldValue.detailNameFinnish;
                            }
                        });
                        vm.searchingOptions.push('Tidspunkt for registrering');
                    } else if (vm.languageSelected === 'da_DK') {
                        vm.searchingOptions = vm.queries.map(function (fieldValue) {
                            if (fieldValue.detailNameDanish) {
                                return fieldValue.detailNameDanish;
                            } else if (fieldValue.detailNameEnglish) {
                                return fieldValue.detailNameEnglish;
                            } else if (fieldValue.detailNameSwedish) {
                                return fieldValue.detailNameSwedish;
                            } else if (fieldValue.detailNameNorwegian) {
                                return fieldValue.detailNameNorwegian;
                            } else {
                                return fieldValue.detailNameFinnish;
                            }
                        });
                        vm.searchingOptions.push('Tidspunkt for registrering');
                    } else {
                        vm.searchingOptions = vm.queries.map(function (fieldValue) {
                            if (fieldValue.detailNameFinnish) {
                                return fieldValue.detailNameFinnish;
                            } else if (fieldValue.detailNameEnglish) {
                                return fieldValue.detailNameEnglish;
                            } else if (fieldValue.detailNameSwedish) {
                                return fieldValue.detailNameSwedish;
                            } else if (fieldValue.detailNameNorwegian) {
                                return fieldValue.detailNameNorwegian;
                            } else {
                                return fieldValue.detailNameDanish;
                            }
                        });
                        vm.searchingOptions.push('Rekisteröinnin aika');
                    }
                }

                function sortingOptionsFn() {
                    if (vm.languageSelected === 'en_US') {
                        let basicQueryArray = _.filter(vm.basicQueryForEvent, (queryInfo) => {
                            return CommonFunctions.hasIntersectValue(LIST_OF_SORTING_OPTIONS, [queryInfo.detailNameEnglish]);
                        });
                        let basicQueryFieldValue = basicQueryArray.map((fieldValue) => fieldValue.detailNameEnglish);
                        vm.sortingOptions = basicQueryFieldValue;
                        vm.sortingOptions.push('Time of registration');
                    } else if (vm.languageSelected === 'sv_SV') {
                        let basicQueryArray = _.filter(vm.basicQueryForEvent, (queryInfo) => {
                            return CommonFunctions.hasIntersectValue(LIST_OF_SORTING_OPTIONS_SN, [queryInfo.detailNameSwedish]);
                        });
                        let basicQueryFieldValue = basicQueryArray.map((fieldValue) => fieldValue.detailNameSwedish);
                        vm.sortingOptions = basicQueryFieldValue;
                        vm.sortingOptions.push('Tid för registrering');
                    } else if (vm.languageSelected === 'nb_NB') {
                        let basicQueryArray = _.filter(vm.basicQueryForEvent, (queryInfo) => {
                            return CommonFunctions.hasIntersectValue(LIST_OF_SORTING_OPTIONS_NB, [
                                queryInfo.detailNameNorwegian,
                            ]);
                        });
                        let basicQueryFieldValue = basicQueryArray.map((fieldValue) => fieldValue.detailNameNorwegian);
                        vm.sortingOptions = basicQueryFieldValue;
                        vm.sortingOptions.push('Tidspunkt for registrering');
                    } else if (vm.languageSelected === 'da_DK') {
                        let basicQueryArray = _.filter(vm.basicQueryForEvent, (queryInfo) => {
                            return CommonFunctions.hasIntersectValue(LIST_OF_SORTING_OPTIONS_DK, [queryInfo.detailNameDanish]);
                        });
                        let basicQueryFieldValue = basicQueryArray.map((fieldValue) => fieldValue.detailNameDanish);
                        vm.sortingOptions = basicQueryFieldValue;
                        vm.sortingOptions.push('Tidspunkt for registrering');
                    } else {
                        let basicQueryArray = _.filter(vm.basicQueryForEvent, (queryInfo) => {
                            return CommonFunctions.hasIntersectValue(LIST_OF_SORTING_OPTIONS_FI, [queryInfo.detailNameFinnish]);
                        });
                        let basicQueryFieldValue = basicQueryArray.map((fieldValue) => fieldValue.detailNameFinnish);
                        vm.sortingOptions = basicQueryFieldValue;
                        vm.sortingOptions.push('Rekisteröinnin aika');
                    }
                }

                function sortOptionChangeFn() {
                    if (vm.sortingField === null) {
                        vm.descendingOrder = false;
                        vm.ascendingOrder = false;
                        vm.sorting = {};
                        clearSearchFieldFn();
                        return;
                    }
                    if (vm.sortingField === undefined) {
                        vm.sortingField = vm.sortingOptions[0];
                    }
                    let _basicQuery = _.find(vm.basicQueryForEvent, (basicQuery) => {
                        if (vm.languageSelected === 'en_US') {
                            return basicQuery.detailNameEnglish === vm.sortingField;
                        } else if (vm.languageSelected === 'sv_SV') {
                            return basicQuery.detailNameSwedish === vm.sortingField;
                        } else if (vm.languageSelected === 'nb_NB') {
                            return basicQuery.detailNameNorwegian === vm.sortingField;
                        } else if (vm.languageSelected === 'da_DK') {
                            return basicQuery.detailNameDanish === vm.sortingField;
                        } else {
                            return basicQuery.detailNameFinnish === vm.sortingField;
                        }
                    });
                    vm.isFieldBasic = _basicQuery ? true : false;
                    //todo(SC) handle sort by additional query as well if needed in future
                    if (vm.languageSelected === 'en_US') {
                        if (vm.sortingField !== 'Waitlist' && vm.sortingField !== 'Time of registration') {
                            vm.sortingField = _basicQuery.detailNameEnglish;
                        }
                    } else if (vm.languageSelected === 'sv_SV') {
                        if (vm.sortingField !== 'Väntelista' && vm.sortingField !== 'Tid för registrering') {
                            vm.sortingField = _basicQuery.detailNameSwedish;
                        }
                    } else if (vm.languageSelected === 'nb_NB') {
                        if (vm.sortingField !== 'Väntelista' && vm.sortingField !== 'Tidspunkt for registrering') {
                            vm.sortingField = _basicQuery.detailNameNorwegian;
                        }
                    } else if (vm.languageSelected === 'da_DK') {
                        if (vm.sortingField !== 'Väntelista' && vm.sortingField !== 'Tidspunkt for registrering') {
                            vm.sortingField = _basicQuery.detailNameDanish;
                        }
                    } else {
                        if (vm.sortingField !== 'Väntelista' && vm.sortingField !== 'Rekisteröinnin aika') {
                            vm.sortingField = _basicQuery.detailNameFinnish;
                        }
                    }
                    let _direction = vm.descendingOrder ? 'desc' : 'asc';
                    sortFn(vm.sortingField, _direction);
                }

                function searchOptionChangeFn() {
                    if (PARTICIPANT_REGISTRATION_TIME_FIELDS.indexOf(vm.searchingField) === -1) {
                        vm.isTimeOfRegistration = false;
                    } else {
                        vm.isTimeOfRegistration = true;
                    }
                    if (vm.searchingField === null) {
                        clearSearchFieldFn();
                    }

                    if (vm.searchingField === undefined) {
                        vm.searchingField = vm.searchingOptions[0];
                    }
                }

                function sortFn(field, direction) {
                    vm.sorting = { field: field, direction: direction };
                    if (direction === 'desc') {
                        vm.descendingOrder = true;
                        vm.ascendingOrder = false;
                    } else {
                        vm.descendingOrder = false;
                        vm.ascendingOrder = true;
                    }
                    listParticipantsFn();
                }

                function getParticipantById(id) {
                    var requiredParticipant;
                    angular.forEach(vm.participants, function (participant) {
                        if (participant.id === id) {
                            requiredParticipant = participant;
                        }
                    });
                    return requiredParticipant;
                }

                function getParticipantsShareDetailsFn() {
                    Event.getParticipantsShareDetailsForEvent(vm.eventId, function (data) {
                        vm.shareWithAllSuppliers = data.shareWithAllSuppliers;
                        vm.shareWithSelectedSupplies = data.shareWithSelectedSupplies;
                    });
                }

                function initFn() {
                    vm.selectedParticipants = [];
                    vm.searchingOptions = [];
                    vm.queries = [];

                    $('#participants-table').dataTable({
                        scrollY: 200,
                        scrollX: true,
                        searching: false,
                        paging: false,
                        info: false,
                    });
                    getParticipantQueriesFn();
                    getRegistrationExpiryDateFn();
                    getSmsServiceSettingFn();
                    getEventMessagesFn();
                    getParticipantsShareDetailsFn();
                    getIsInvitationBlocked();

                    vm.data = { email: '' };
                    $scope.startProgress({ showContent: false });
                    vm.templateUrl = vm.baseUrl + '/soft_block_invitation/soft-block-invitation.view.html';
                    setSoftBlockOptionFn('WILL_PROCURE');
                }

                function clearSearchFieldFn() {
                    vm.searchText = null;
                    vm.searchingField = null;
                    listParticipantsFn();
                }

                function getEventMessagesFn() {
                    Participant.getEventMessages(
                        vm.eventId,
                        (data) => {
                            vm.draftMessages = angular.copy(data.draft);
                            vm.scheduleMessages = angular.copy(data.schedule);
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function getRegistrationExpiryDateFn() {
                    Event.getRegistrationExpiryDate(vm.eventId, function (data) {
                        vm.registrationExpiryDate = data.registrationExpiryDate;
                    });
                }

                function getSmsServiceSettingFn() {
                    Event.getEventUsedSMSService(vm.eventId, function (data) {
                        vm.useSMS = data.useSMS;
                    });
                }

                function getFullNameFn(participant) {
                    var fname = '',
                        lname = '',
                        name = '';
                    angular.forEach(participant.participantBasicQueryInfoList, function (basicInfo) {
                        if (basicInfo.detailNameEnglish === 'First Name') {
                            if (basicInfo.fieldValue) {
                                fname = basicInfo.fieldValue;
                            } else {
                                fname = '';
                            }
                        }
                        if (basicInfo.detailNameEnglish === 'Last Name') {
                            if (basicInfo.fieldValue) {
                                lname = basicInfo.fieldValue;
                            } else {
                                lname = '';
                            }
                        }
                    });
                    name = fname;
                    if (name) {
                        name += ' ';
                    }
                    name += lname;
                    return name;
                }

                function getSortByQueryIdFn() {
                    if (vm.languageSelected === 'en_US') {
                        if (vm.sortingField === 'Waitlist' || vm.sortingField === 'Time of registration') {
                            return vm.sortingField;
                        }
                    } else if (vm.languageSelected === 'sv_SV') {
                        if (vm.sortingField === 'Väntelista' || vm.sortingField === 'Tid för registrering') {
                            return vm.sortingField;
                        }
                    } else if (vm.languageSelected === 'nb_NB') {
                        if (vm.sortingField === 'Avventer' || vm.sortingField === 'Tidspunkt for registrering') {
                            return vm.sortingField;
                        }
                    } else if (vm.languageSelected === 'da_DK') {
                        if (vm.sortingField === 'Venteliste' || vm.sortingField === 'Tidspunkt for registrering') {
                            return vm.sortingField;
                        }
                    } else {
                        if (vm.sortingField === 'Odotuslista' || vm.sortingField === 'Rekisteröinnin aika') {
                            return vm.sortingField;
                        }
                    }

                    let _sortQuery = _.find(vm.basicQueryForEvent, (basicQuery) => {
                        if (vm.languageSelected === 'en_US') {
                            return basicQuery.detailNameEnglish === vm.sorting.field;
                        } else if (vm.languageSelected === 'sv_SV') {
                            return basicQuery.detailNameSwedish === vm.sorting.field;
                        } else if (vm.languageSelected === 'nb_NB') {
                            return basicQuery.detailNameNorwegian === vm.sorting.field;
                        } else if (vm.languageSelected === 'da_DK') {
                            return basicQuery.detailNameDanish === vm.sorting.field;
                        } else {
                            return basicQuery.detailNameFinnish === vm.sorting.field;
                        }
                    });
                    return _sortQuery ? _sortQuery.eventParticipantBasicQuery.id : null;
                }

                function listParticipantsFn(existingSelectedParticipantIds) {
                    isParticipantsImportingFn(vm.eventId).then(() => {
                        if (vm.participantUploding) {
                            return;
                        }
                        isParticipantsInvitationRunningFn(vm.eventId).then(() => {
                            if (vm.participantInvitationRunning) {
                                return;
                            }
                            isParticipantsDeleteRunningFn(vm.eventId).then(() => {
                                if (vm.participantDeleting) {
                                    return;
                                }
                                loadParticipantsFn(existingSelectedParticipantIds);
                            });
                        });
                    });
                }

                function loadParticipantsFn(existingSelectedParticipantIds) {
                    vm.checkIsParticipantEmailSending = true;
                    let _sortByFieldId = getSortByQueryIdFn();
                    let participantSearchParams = {
                        eventId: vm.eventId,
                        max: vm.pagination.max,
                        offset: vm.pagination.offset,
                        sortParams: { field: _sortByFieldId, direction: vm.sorting.direction },
                        searchParams: { text: vm.searchText, status: vm.search.status, field: vm.searchingField },
                    };
                    $scope.startProgress('participant-lists');
                    Participant.load(
                        participantSearchParams,
                        (result) => {
                            vm.originalParticipantsList = result.participants;
                            vm.maxParticipantCount = result.maxParticipantCount;
                            vm.participants = angular.copy(vm.originalParticipantsList);
                            vm.participants = processParticipantData(vm.participants, existingSelectedParticipantIds);
                            vm.lastModifiedDate = result.lastModifiedDate;
                            vm.fortNoxConfigured = result.fortNoxConfigured;
                            vm.actualParticipantDeletedDate = result.actualParticipantDeletedDate;
                            if (!!result.fortNoxConfigured) {
                                if (!CommonFunctions.hasIntersectValue(['FORTNOX'], vm.participantsDropDownList)) {
                                    vm.participantsDropDownList.push('FORTNOX');
                                }
                            }
                            vm.countTotal = result.counts['TOTAL'];
                            vm.pagination.totalCount = result.paginationParams.totalCount;
                            vm.count = result.counts;
                            vm.availableSpots = result.counts.MAX_PARTICIPANT - result.counts.ATTENDING;
                            vm.pagination = result.paginationParams;
                            vm.lengthOfPagination = Math.ceil(result.paginationParams.totalCount / result.paginationParams.max);
                            addPrioritySortingFn();
                            $scope.endProgress('participant-lists');
                        },
                        $scope.endWithErrorOverlay('participant-lists')
                    );
                }

                function getParticipantBasicQueriesFn(participant) {
                    let _basicQueries = participant.participantBasicQueryInfoList;
                    let _filteredBasicQueries = _.reject(_basicQueries, function (basicInfo) {
                        return (
                            basicInfo.detailNameEnglish &&
                            (basicInfo.detailNameEnglish.toUpperCase() === 'FIRST NAME' ||
                                basicInfo.detailNameEnglish.toUpperCase() === 'LAST NAME')
                        );
                    });
                    return _filteredBasicQueries;
                }

                function getFieldValueFn(info) {
                    let _value = info.fieldValue;
                    if (_value && 'date' === info.type.name.toLowerCase()) {
                        _value = TimezoneService.formatDate(new Date(parseInt(_value)), getDateDisplayFormatFn());
                    }
                    if (_value && 'daterange' === info.type.name.toLowerCase()) {
                        let _dates = _value.split('~');
                        let _from = TimezoneService.formatDate(
                            TimezoneService.toLocalTime(new Date(parseInt(_dates[0]))),
                            getDateTimeDisplayFormatFn()
                        );
                        let _to = TimezoneService.formatDate(
                            TimezoneService.toLocalTime(new Date(parseInt(_dates[1]))),
                            getDateTimeDisplayFormatFn()
                        );
                        _value = _from + ' - ' + _to;
                    }

                    if (!_value) {
                        return '-';
                    }
                    return _value;
                }

                function getPaymentTypeFn(participant) {
                    if (participant.ticket.paymentMethod.name === 'CREDIT_CARD') {
                        return $translate.instant('ticket.card');
                    } else {
                        return $translate.instant('ticket.invoice');
                    }
                }

                function setFullNameInParticipant(participant) {
                    participant.name = getFullNameFn(participant);
                }

                function processParticipantData(participants, existingSelectedParticipantIds) {
                    angular.forEach(participants, (participant) => {
                        setFullNameInParticipant(participant);
                        if (existingSelectedParticipantIds && existingSelectedParticipantIds.indexOf(participant.id) > -1) {
                            participant.selected = true;
                        }
                        angular.forEach(participant.participantBasicQueryInfoList, (partBasicInfo) => {
                            let _optionValues = partBasicInfo.eventParticipantBasicQuery.optionValues;
                            if (['DROPDOWN'].indexOf(partBasicInfo.type.name) > -1) {
                                let _fieldValue = partBasicInfo.fieldValue;
                                partBasicInfo.fieldValue = ParticipantUtilService.getFieldValue(
                                    _optionValues,
                                    $rootScope.langSelected,
                                    _fieldValue
                                );
                            }
                            if (['MULTISELECT'].indexOf(partBasicInfo.type.name) > -1) {
                                let _fieldValue = partBasicInfo.fieldValue;
                                if (_fieldValue) {
                                    partBasicInfo.fieldValue = _fieldValue.replaceAll('~', ' | ');
                                }
                            }
                        });

                        angular.forEach(participant.participantAdditionalQueryInfoList, (partAddInfo) => {
                            let _optionValues = partAddInfo.optionValues;
                            if (['DROPDOWN'].indexOf(partAddInfo.type.name) > -1) {
                                let _fieldValue = partAddInfo.fieldValue;
                                partAddInfo.fieldValue = ParticipantUtilService.getFieldValue(
                                    _optionValues,
                                    $rootScope.langSelected,
                                    _fieldValue
                                );
                            }
                            if (['MULTISELECT'].indexOf(partAddInfo.type.name) > -1) {
                                let _fieldValue = partAddInfo.fieldValue;
                                if (_fieldValue) {
                                    partAddInfo.fieldValue = _fieldValue.replaceAll('~', ' | ');
                                }
                            }
                        });
                    });
                    return participants;
                }

                function importParticipantsFn() {
                    if(vm.viewModal) {
                        return;
                    }
                    vm.viewModal = true;
                    let data = {
                        eventId: vm.eventId,
                        successCallback: () => {
                            isParticipantsImportingFn(vm.eventId);
                        },
                    };

                    let importParticipantsModalModalData = importParticipantsModal;
                    importParticipantsModalModalData.inputs = { data };
                    ModalService.showModal(importParticipantsModalModalData).then((modal) => {
                        vm.viewModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }

                function openDialogSendToFormFn(fileFormat, extension) {
                    OgModal.open('participant:sendTo', {
                        fileFormat: fileFormat,
                        extension: extension,
                        successCallback: vm.startDownload,
                    });
                }

                function openDialogAddParticipantFn() {
                    OgModal.open('participant', {
                        additionalInfoOptionsForEvent: vm.customQueryForEvent,
                        basicInfoForEvent: vm.basicQueryForEvent,
                        eventId: vm.eventId,
                        useSMS: vm.useSMS,
                        successCallback: vm.resetParticipants,
                    });
                }

                function openDialogEditParticipantFn(participant) {
                    OgModal.open('participant', {
                        participant: participant,
                        additionalInfoOptionsForEvent: vm.customQueryForEvent,
                        basicInfoForEvent: vm.basicQueryForEvent,
                        additionalInfoOptionsList: vm.additionalInfoOptionsList,
                        eventId: vm.eventId,
                        useSMS: vm.useSMS,
                        successCallback: vm.resetParticipants,
                    });
                }

                function openShareParticipantsModalFn() {
                    if(vm.viewModal) {
                        return;
                    }
                    vm.viewModal = true;
                    let data = {
                        eventId: vm.eventId,
                    };

                    let shareParticipantsModalData = shareParticipantsModal;
                    shareParticipantsModalData.inputs = { data };
                    ModalService.showModal(shareParticipantsModalData).then((modal) => {
                        vm.viewModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            getParticipantsShareDetailsFn();
                            console.log('modal is closed!');
                        });
                    });
                }

                function pageChangedFn() {
                    vm.pagination.offset = (vm.currentPage - 1) * vm.pagination.max;
                    listParticipantsFn();
                }

                function resetParticipantsFn() {
                    vm.selectedParticipants = [];
                    isParticipantsImportingFn(vm.eventId).then(() => {
                        if (vm.participantUploding) {
                            return;
                        }
                        isParticipantsInvitationRunningFn(vm.eventId).then(() => {
                            if (vm.participantInvitationRunning) {
                                return;
                            }
                            isParticipantsDeleteRunningFn(vm.eventId).then(() => {
                                if (vm.participantDeleting) {
                                    return;
                                }
                                loadParticipantsFn();
                                vm.getEventMessages();
                            });
                        });
                    });
                }

                function checkParticipantInvitationStatusFn() {
                    vm.participantInvitationRunning = true;
                    vm.checkIsParticipantEmailSending = false;
                    vm.selectedParticipants = [];
                    isParticipantsInvitationRunningFn(vm.eventId).then(() => {
                        if (vm.participantInvitationRunning) {
                            return;
                        }
                        loadParticipantsFn();
                        vm.getEventMessages();
                    });
                }

                function checkParticipantDeletingStatusFn() {
                    vm.participantDeleting = true;
                    vm.selectedParticipants = [];
                    isParticipantsDeleteRunningFn(vm.eventId).then(() => {
                        if (vm.participantDeleting) {
                            return;
                        }
                        loadParticipantsFn();
                        vm.getEventMessages();
                    });
                }

                function deleteParticipantFn(participantId) {
                    CommonFunctions.stopPropagation();
                    let _selectedIds = vm.selectedParticipants.map((item) => {
                        return item.id;
                    });
                    let _selectedParticipantIds = angular.copy(_selectedIds);
                    let _existingParticipantIds = _.without(_selectedParticipantIds, participantId);

                    $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, {
                        message: 'participant.delete.confirm',
                        okCallBack: function () {
                            $scope.startProgress('participant-lists');
                            Participant['delete'](
                                participantId,
                                function () {
                                    $scope.endProgress('participant-lists');
                                    vm.listParticipants(_existingParticipantIds);
                                    if (_selectedParticipantIds.length > 0) {
                                        toggleParticipantSelectionFn(participantId);
                                    }
                                    vm.participantDeleteSuccessMessage = $translate.instant(
                                        'participant.deleted.successfully',
                                        { p0: 1 }
                                    );
                                },
                                $scope.endWithAlert('participant-lists')
                            );
                        },
                    });
                }

                function openImportParticipantFromEventDialogFn() {
                    OgModal.open('participants:import:from:event', {
                        eventId: vm.eventId,
                        successCallback: vm.resetParticipants,
                    });
                }

                function deleteParticipantDeleteSuccessMessageFn() {
                    vm.participantDeleteSuccessMessage = null;
                }

                function openParticipantChangeStatusDialogFn(newStatus) {
                    OgModal.open('participants:change:status', {
                        eventId: vm.eventId,
                        selectedParticipants: vm.selectedParticipants,
                        newStatus: newStatus,
                        successCallback: vm.resetParticipants,
                    });
                }

                function openSelectedMessageFn(message) {
                    let _participantsList = [];
                    let eventMessage = message.messageDTO;
                    if (eventMessage.emailMessage === 'null' || eventMessage.emailMessage == null) {
                        eventMessage.emailMessage = '';
                    }
                    if (eventMessage.emailSubject === 'null' || eventMessage.emailSubject == null) {
                        eventMessage.emailSubject = '';
                    }
                    if (eventMessage.provider.name === 'BY_SMS_ONLY') {
                        eventMessage['smsSenderName'] = eventMessage.smsSenderName
                            ? eventMessage.smsSenderName
                            : eventMessage.senderName;
                    }
                    angular.forEach(eventMessage.toAddress, function (participant) {
                        let participantData = {
                            id: participant.id,
                            name: getFullNameFn(participant),
                            email: participant.email,
                            phone: participant.phone,
                        };
                        if (!isBouncedEmailFn(participant)) {
                            _participantsList.push(participantData);
                        }
                    });
                    OgModal.open('participant:email', {
                        type: eventMessage.type,
                        hash: message.hash,
                        eventId: vm.eventId,
                        eventMessage: eventMessage,
                        participants: vm.participants,
                        useSMS: vm.useSMS,
                        selectedParticipants: _participantsList,
                        participantSettingsCallback: $scope.$parent.participantsCtrl.scrollToParticipantSettings,
                        successCallback: vm.resetParticipants,
                    });
                }

                function sendGroupMessageFn(type) {
                    vm.selectedParticipantsList = [];
                    angular.forEach(vm.selectedParticipants, function (participant) {
                        var participantData = {
                            id: participant.id,
                            name: participant.name,
                            email: participant.email,
                            phone: participant.phone,
                        };
                        if (!isBouncedEmailFn(participant)) {
                            vm.selectedParticipantsList.push(participantData);
                        }
                    });
                    OgModal.open('participant:email', {
                        type: type,
                        eventId: vm.eventId,
                        useSMS: vm.useSMS,
                        selectedParticipants: vm.selectedParticipantsList,
                        sendGroupMessageCondition: true,
                        participantSettingsCallback: $scope.$parent.participantsCtrl.scrollToParticipantSettings,
                        successCallback: function () {
                            $scope.$broadcast(EventEnum.PARTICIPANTS_STATUS_CHANGED, {});
                        },
                    });
                }

                function sendParticipantEmailsFn(type) {
                    vm.selectedParticipantsList = [];
                    angular.forEach(vm.selectedParticipants, function (participant) {
                        var participantData = {
                            id: participant.id,
                            name: participant.name,
                            email: participant.email,
                            phone: participant.phone,
                        };
                        if (!isBouncedEmailFn(participant)) {
                            vm.selectedParticipantsList.push(participantData);
                        }
                    });
                    OgModal.open('participant:email', {
                        type: type,
                        eventId: vm.eventId,
                        useSMS: vm.useSMS,
                        selectedParticipants: vm.selectedParticipantsList,
                        participantSettingsCallback: $scope.$parent.participantsCtrl.scrollToParticipantSettings,
                        successCallback: listParticipantsFn,
                    });
                }

                function getParticipantQueriesFn() {
                    $scope.startProgress('participant-lists');
                    Participant.getSelectedQueriesForEvent(
                        vm.eventId,
                        function (response) {
                            vm.basicQueryForEvent = response.basicQueriesForEvent;
                            vm.customQueryForEvent = response.customQueriesForEvent;
                            if (!!vm.basicQueryForEvent) {
                                sortingOptionsFn();
                            }
                            getSearchableFieldFn();
                            $scope.endProgress('participant-lists');
                        },
                        $scope.endWithErrorOverlay('participant-lists')
                    );
                }

                function getSearchableFieldFn() {
                    $scope.startProgress('participant-lists');
                    Participant.getSearchableField(
                        vm.eventId,
                        function (response) {
                            vm.queries = response;
                            if (!!vm.queries) {
                                searchingOptionFn();
                            }
                            $scope.endProgress('participant-lists');
                        },
                        $scope.endWithErrorOverlay('participant-lists')
                    );
                }

                $scope.$on('participant:details:add:submit', function (evt, event, callback) {
                    event.eventId = vm.eventId;
                    Event.update(event, function () {
                        callback();
                    });
                });

                $scope.$on(EventEnum.PARTICIPANTS_STATUS_CHANGED, function () {
                    vm.resetParticipants();
                });

                vm.languageSelected = $rootScope.langSelected;

                $scope.$on('lang:change', function (event, data) {
                    vm.languageSelected = data.lang;
                    sortingOptionsFn();
                    searchingOptionFn();
                    vm.sortingField = null;
                    vm.searchingField = null;
                    vm.ascendingOrder = false;
                    vm.descendingOrder = false;
                });

                vm.getSelectedParticipants = function (selectedOption) {
                    let participantSearchParams = {
                        eventId: vm.eventId,
                        status: selectedOption,
                    };
                    if (selectedOption === 'NONE') {
                        vm.selectAllParticipant.checked = false;
                        vm.selectedParticipants = [];
                        processSelectedParticipantsFn();
                    } else {
                        Participant.listByStatus(
                            participantSearchParams,
                            function (result) {
                                vm.selectedParticipants = result.list;
                                processSelectedParticipantsFn();
                            },
                            $scope.endWithErrorOverlay
                        );
                    }

                    if (selectedOption === 'ALL') {
                        vm.selectAllParticipant.checked = false;
                    }
                };

                function processSelectedParticipantsFn() {
                    let _selectedParticipantsEmails = _.pluck(vm.selectedParticipants, 'email');
                    _.map(vm.participants, function (participant) {
                        if (CommonFunctions.hasIntersectValue(_selectedParticipantsEmails, [participant.email])) {
                            participant.selected = true;
                        } else {
                            participant.selected = false;
                        }
                    });
                }

                function disableSendMessageFn() {
                    let allEmailsInvalid = false;
                    if (vm.selectedParticipants.length > 0) {
                        allEmailsInvalid = vm.selectedParticipants.every((participant) => isBouncedEmailFn(participant));
                    }
                    return vm.selectedParticipants.length < 1 || allEmailsInvalid;
                }

                function getParticipantNameFn(participant) {
                    let participantName = '-';
                    if (participant.name && participant.name.replace(/\s/g, '').length > 0) {
                        participantName = participant.name;
                    }
                    return participantName;
                }

                vm.toggleSelectAllCheckbox = function (status) {
                    if (status === false) {
                        vm.unSelectAllParticipants();
                    } else {
                        vm.selectAllParticipants();
                    }
                };

                vm.selectAllParticipants = function () {
                    vm.getSelectedParticipants('ALL');
                };

                vm.unSelectAllParticipants = function () {
                    vm.getSelectedParticipants('NONE');
                };

                vm.resetSelectAllParticipants = function () {
                    vm.selectAllParticipant.checked = false;
                    vm.unSelectAllParticipants();
                };

                function updateEventParticipants() {
                    vm.listParticipants();
                }

                function getVatAmount(ticket) {
                    let vatAmount =
                        UtilFunctions.isGreaterOrEqualToZero(ticket.price) && UtilFunctions.isGreaterOrEqualToZero(ticket.vat)
                            ? (ticket.price * ticket.vat) / 100
                            : 0;
                    return amountAndPriceService.round(parseFloat(vatAmount));
                }

                function getPriceWithVatLocalisedWithCurrencyFn(ticket) {
                    let priceWithoutVat = UtilFunctions.isGreaterOrEqualToZero(ticket.price) ? parseFloat(ticket.price) : 0;
                    let priceWithVat = priceWithoutVat + getVatAmount(ticket);
                    let currency = ticket.currency || currencyUtil.getDefaultCurrency();
                    return amountAndPriceService.getAmountLocalisedWithCurrency(priceWithVat, currency, 2);
                }

                function getIsInvitationBlocked() {
                    Event.hasAnyRequestsSentInEventOrAlreadyAnswered(
                        vm.eventId,
                        function (data) {
                            vm.hasAnyRequestsSentInEvent = data.hasAnyRequestsSentInEvent;
                            vm.alreadyAnsweredQuestions = data.alreadyAnsweredQuestions;
                        },
                        $scope.endWithErrorOverlay
                    );

                    UserGroup.isInvitationBlocked(
                        { eventId: vm.eventId },
                        (response) => {
                            vm.isInvitationBlocked = response.isInvitationBlocked;
                        },
                        $scope.endWithErrorOverlay()
                    );
                }

                function setTemplateUrlFn(url) {
                    vm.templateUrl = vm.baseUrl + url;
                }

                function saveWillProcureUserFeedbackFn() {
                    if (!vm.willProcureFeedbackForm.$valid) {
                        CommonFunctions.touchFormFields(vm.willProcureFeedbackForm);
                        return;
                    }
                    var params = {
                        startInvitationFeedback: vm.startInvitationFeedback,
                        plannedProcurementDate: vm.plannedProcurementDate,
                    };
                    Event.saveWillProcureInvitationFeedback(vm.eventId, params, function (response) {
                        vm.alreadyAnsweredQuestions = true;
                    });
                }

                function saveNoProcurementUserFeedbackFn() {
                    if (!vm.noProcurementFeedbackForm.$valid) {
                        CommonFunctions.touchFormFields(vm.noProcurementFeedbackForm);
                        return;
                    }
                    var params = {
                        participantManagementOnlyFeedback: vm.participantManagementOnlyFeedback,
                    };
                    Event.saveNoProcurementFeedback(vm.eventId, params, function () {
                        console.log('updated ...', vm.eventId);
                    });
                }

                function setSoftBlockOptionFn(type) {
                    vm.softBlockType = '';
                    vm.softBlock = {
                        willProcure: false,
                        participantManagementOnly: false,
                        startProcurement: false,
                        sendInvitationFirst: false,
                        goToBillingDetails: false,
                    };
                    vm.softBlockType = type;
                    if (vm.softBlockType == 'WILL_PROCURE') {
                        vm.softBlock.willProcure = true;
                    }
                    if (vm.softBlockType == 'PARTICIPANT_MANAGEMENT_ONLY') {
                        vm.softBlock.participantManagementOnly = true;
                    }
                    if (vm.softBlockType == 'GO_TO_BILLING_DETAILS') {
                        vm.softBlock.goToBillingDetails = true;
                    }
                    if (vm.softBlockType == 'RENDER_SUCCESS_MESSAGE') {
                        vm.softBlock.renderSuccessMessage = true;
                    }
                    if (vm.softBlockType == 'START_PROCUREMENT') {
                        vm.softBlock.startProcurement = true;
                    }
                    if (vm.softBlockType == 'SEND_INVITATION_FIRST') {
                        vm.softBlock.sendInvitationFirst = true;
                    }
                }

                function setActiveFn(tab) {
                    ActiveTabService.setEventDetailTab(tab);
                }

                function sendInvitationBlockerEmailForManagementFn(type) {
                    if (!vm.invitationBlockerForm.$valid) {
                        CommonFunctions.touchFormFields(vm.invitationBlockerForm);
                        return;
                    }
                    const params = {
                        company: vm.company,
                        orgnumber: vm.orgnumber,
                        reference: vm.reference,
                        street: vm.street,
                        zipcode: vm.zipcode,
                        town: vm.town,
                        markingpo: vm.markingpo,
                        invoiceemail: vm.invoiceemail,
                        fullname: vm.fullname,
                        emailaddress: vm.emailaddress,
                        phone: vm.phone,
                        eventId: vm.eventId,
                        lang: $rootScope.langSelected,
                    };
                    Event.sendEmailForInvitationBlockerManagement(
                        { params },
                        function (response) { },
                        function (error) {
                            console.log('Something went wrong while sending email : ', error);
                        }
                    );

                    // just to make sure everything works, previous form is completely removed and value is there.
                    const data = {
                        participantManagementOnlyFeedback: 'Some Value',
                    };
                    Event.saveNoProcurementFeedback(vm.eventId, data, function () { });
                    $rootScope.$broadcast(EventEnum.EVENT_PARTICIPANT_SEND_INVITATION_CHANGED, true);
                    setSoftBlockOptionFn(type);
                }

                function openInvitationTabFn() {
                    vm.init();
                }
            }
        ]
    });
