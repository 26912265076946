'use strict';
angular.module('core.existingServices').factory('ChatMessage', [
    '$resource',
    function ($resource) {
        var chatMessageRest = $resource(
            'api/v1/chatMessage/',
            {},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var unReadMessageCountForCreatorRest = $resource(
            'api/v1/chatMessage/unreadMessageCountForCreator/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var unReadMessageCountForSupplierRest = $resource(
            'api/v1/chatMessage/unReadMessageCountForSupplierRest/:offerId',
            {offerId: '@offerId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            list: function (jsonObject, successCallback, errorCallback) {
                return chatMessageRest.list(jsonObject, successCallback, errorCallback);
            },
            unReadMessageCountForCreatorRest: function (eventId, successCallback, errorCallback) {
                return unReadMessageCountForCreatorRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            unReadMessageCountForSupplierRest: function (offerId, successCallback, errorCallback) {
                return unReadMessageCountForSupplierRest.get({offerId: offerId}, successCallback, errorCallback);
            },
        };
    },
]);

