import EventDetailsData from '../../../../core/existingServices/data_store/EventDetailsData';
import addEditAccommodationModal from '../add-edit-accommodation';
import googleAddressUtil from '../../../../utils/GoogleAddressUtil';
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';
import * as _ from 'underscore';

const BUSS_PART_CODES = [
    'common.translate.offer.part.type.bus.trip',
    'common.translate.offer.part.type.bus.(home.journey)',
    'common.translate.offer.part.type.bus.(outward.journey)',
];

class AddEditOfferPartModalCtrl {
    constructor(
        EventSchedule,
        $rootScope,
        $state,
        $timeout,
        $translate,
        EventEnum,
        FieldChangedSvc,
        data,
        $scope,
        close,
        $element,
        TranslationService,
        FilledRequestOfferPartComment,
        DynamicFormUtilService,
        RequestOffer,
        ModalService,
        CommonFile,
        CommonFunctions
    ) {
        this._injected = {
            EventSchedule,
            $rootScope,
            $state,
            $timeout,
            $translate,
            EventEnum,
            FieldChangedSvc,
            data,
            $scope,
            close,
            $element,
            TranslationService,
            FilledRequestOfferPartComment,
            DynamicFormUtilService,
            RequestOffer,
            ModalService,
            CommonFile,
            CommonFunctions
        };

        this.eventScheduleForm = {};
        ScopeFunctions.addToScope($scope);
        this.eventDetails = {};
        this.usersInEvent = [];
        this.remoteUrl = CommonFile.getBaseUrl() + 'api/v1/offerPartType';

        this.editDenied = false;
        this.showConfirmationMessage = false;
        this.busOfferPart = false;
        this.eventScheduleModel = {
            confirmationRequired: false,
            actionRequired: data.event.actionRequired,
            supplierConfirmationPending: data.event.supplierConfirmationPending,
            editType: data.event.editType,
            isTimePairValid: true,
        };

        this.eventModal = {
            supplyAmountTypes: [
                {type: 'PIECES', name: $translate.instant('amount.type.PIECES')},
                {type: 'PEOPLE', name: $translate.instant('amount.type.PEOPLE')},
                {type: 'SAME_AS_EVENT', name: $translate.instant('amount.type.SAME_AS_EVENT')},
            ],
            offerPartCategoryTypes: [
                {type: 'ACCOMMODATION', name: $translate.instant('part.category.type.ACCOMMODATION')},
                {type: 'OTHERS', name: $translate.instant('part.category.type.OTHERS')},
            ],
            isNew: data.createNew,
        };

        this.getEventDetailPage();
        if (this.eventDetails && this.eventDetails.usersInEvent) {
            this.usersInEvent = this.eventDetails.usersInEvent;
        }

        if (!this.eventModal.isNew) {
            $scope.startProgress();

            //used because response in sidebar is different from calendar
            var offerId = data.event.offerId ? data.event.offerId : data.event.offer.id;

            EventSchedule.getOfferPart(
                data.event.referenceId,
                offerId,
                (response) => {
                    var eventScheduleForPart = response.eventScheduleForPart;
                    this.eventData = angular.extend({}, angular.fromJson(eventScheduleForPart.data));
                    this.referenceId = eventScheduleForPart.referenceId;
                    this.dataLoaded(this.eventData);
                    if (this.eventData.defaultCommentByCreator || !this.eventData.commentByCreator) {
                        this.commentEditMode = true;
                    } else {
                        this.commentEditMode = false;
                    }
                    this.requestName = response.eventScheduleForPart.data.requestName;
                    this.requestNameCode = response.eventScheduleForPart.data.requestNameCode;
                    this.requestIconId = response.eventScheduleForPart.data.requestIconId;
                    this.getRequestFromId(response.eventScheduleForPart.data.requestId);
                    $scope.endProgress();
                },
                $scope.endWithErrorOverlay
            );
        } else if (this.eventModal.isNew) {
            var addedNewRequest = _.where(this.eventDetails.requests, {id: 0}); // jshint ignore:line
            if (!addedNewRequest.length) {
                this.eventDetails.requests.push({
                    id: 0,
                    name: $translate.instant('calendar.modal.create.new.content'),
                });
            }
            var eventFromDate = this.eventFromDate;
            var metaData = {
                dateTimeTo: data.event.end ? new Date(data.event.end).getTime() - eventFromDate : 0,
                dateTimeFrom: data.event.start ? new Date(data.event.start).getTime() - eventFromDate : 0,
                eventFromDate: eventFromDate, //base Date
                requestId: data.event.requestId,
                category: {name: data.event.category}, //Set Accommodation if event create modal pass category
                name: data.event.name,
            };
            this.commentEditMode = true;
            this.eventData = metaData;
            this.eventData.timeless = data.timeless;
            this.eventData.amountType = {name: 'PIECES'}; // set PIECES as default while creating new
            this.dataLoaded(this.eventData);
            this.requestName = data.event.requestName;
            this.requestIconId = data.event.requestIconId;
            this.getRequestFromId(data.event.requestId);
        }

        this.okCallBack = data.okCallBack;
        this.deleteCallBack = data.deleteCallBack;
        this.syncDates = true;
        this.hoursToAdd = 1; // Add default hours from 'from-date' to 'to-date' in dateTime pair.
        this.changedDetails = this.changedDetails.bind(this);
        this.updateSelectedPartName = this.updateSelectedPartName.bind(this);
        this.getAdditionalDetails = this.getAdditionalDetails.bind(this);
        this.openAccommodationCallback = this.openAccommodationCallback.bind(this);
        this.cancelChange = this.cancelChange.bind(this);
    }

    getAdditionalDetails(nameCode, partId) {
        let {FilledRequestOfferPartComment, DynamicFormUtilService, $translate, $rootScope} = this._injected;
        if (!nameCode) {
            if (this.data) {
                this.data.list = null;
            }
        }
        FilledRequestOfferPartComment.list(
            'OFFER_PART_LEVEL',
            nameCode,
            partId,
            (resp) => {
                this.data.list = resp;
                angular.forEach(this.data.list, (list) => {
                    if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                        let _listValuesCode = list.listValueCodes;
                        let _listValues = $translate.instant(_listValuesCode);
                        let _fieldValue;
                        if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                            _fieldValue = false;
                        } else {
                            _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                        }
                        list.fieldValue = DynamicFormUtilService.getFieldValue(
                            _listValues,
                            $rootScope.langSelected,
                            _fieldValue
                        );
                    }
                    if (['ICON_RADIO'].indexOf(list.type.name) > -1) {
                        let _iconList = list.iconList;
                        angular.forEach(_iconList, function (icon) {
                            icon.name = $translate.instant(icon.nameCode);
                        });
                    }
                });
            },
            function (e) {
                console.log('error ', e);
            }
        );
    }

    changedDetails(changed) {
        this.additionalDetailsChanged = changed;
    }

    cancelAccommodationOverlay() {
        this.openAccommodationModal = false;
        this.cancelChange();
    }

    getOfferPartAdditionalQuestionClass(nameCode) {
        let {$scope} = this._injected;
        if (nameCode) {
            switch (nameCode) {
                case 'common.translate.offer.part.type.bus.(outward.journey)':
                case 'common.translate.offer.part.type.bus.(home.journey)':
                case 'common.translate.offer.part.type.bus.trip':
                    this.busOfferPart = true;
                    break;
                default:
                    break;
            }
        }
    }

    getEventDetailPage() {
        let detailPageData = EventDetailsData.getEventDetails();
        angular.copy(detailPageData, this.eventDetails);
        this.eventFromDate = this.eventDetails.fromDate;
    }

    clearDialog() {
        this.okCallBack = null;
        this.cancelCallBack = null;
        this.deleteCallBack = null;
        this.okLabel = null;
        this.cancelLabel = null;
        this.message = null;
        this.messageArgs = null;
    }

    selectRequestToCreateNew() {
        const {CommonFile} = this._injected;
        this.remoteUrl = CommonFile.getBaseUrl() + 'api/v1/offerPartType';
        let requestId = this.data.requestId;
        this.getRequestFromId(requestId);
        this.checkIfEditable();
        this.handleOnchanged('requestId', this.data.requestId);
    }

    getRequestFromId(requestId) {
        this.selectedRequest = _.findWhere(this.eventDetails.requests, {id: requestId});
        if (this.selectedRequest) {
            this.requestName = this.selectedRequest.name;
            this.requestNameCode = this.selectedRequest.nameCode;
            this.requestIconId = this.selectedRequest.iconId;
            if (this.selectedRequest.categories) {
                let categoryIds = [];
                angular.forEach(this.selectedRequest.categories, (category) => {
                    categoryIds.push(category.id);
                });
                this.remoteUrl = this.remoteUrl + '?categoryIds=' + categoryIds;
            }
        }
    }

    checkAndSave(form) {
        if (this.data.list && this.data.list.length > 0 && BUSS_PART_CODES.indexOf(this.data.list[0].code) > -1) {
            let filledCommentList = this.data.list;
            const service = new google.maps.DistanceMatrixService();
            // build request
            const request = googleAddressUtil.prepareDistanceCalRequest(filledCommentList);

            service.getDistanceMatrix(request).then((response) => {
                // put response
                this.data.distance = googleAddressUtil.parseAddressesDistance(response);
                this.ok(form);
            });
        } else {
            this.ok(form);
        }
    }

    ok(form) {
        const {$scope, $rootScope, data, EventSchedule, RequestOffer, CommonFunctions} = this._injected;
        if (!form.$valid) {
            CommonFunctions.touchFormFields(form);
            return;
        }
        var okCallBack = this.okCallBack;
        $scope.startProgress('create-event-modal');
        if (this.eventModal.isNew) {
            if (this.data.requestId) {
                EventSchedule.createOfferPartForExistingRequest(
                    this.data.requestId,
                    this.data,
                    (resp) => {
                        $rootScope.$emit('event:user:action', {action: 'added.request.offer.part'});
                        if (okCallBack) {
                            okCallBack(resp);
                        }
                        $scope.endProgress('create-event-modal');
                        this.close();
                    },
                    $scope.endWithErrorOverlay('create-event-modal')
                );
            } else {
                EventSchedule.createOfferPartForRequest(
                    data.event.id,
                    this.data,
                    (resp) => {
                        $rootScope.$emit('event:user:action', {action: 'added.request.offer.part'});
                        if (okCallBack) {
                            okCallBack(resp);
                        }
                        $scope.endProgress('create-event-modal');
                        this.close();
                    },
                    $scope.endWithErrorOverlay('create-event-modal')
                );
            }
        } else {
            let _offerInBookedLockedBySupplierState = _.find(data.event.suppliers, (supplier) => {
                return supplier.status === 'BOOKED_LOCKED_BY_SUPPLIER';
            });
            if (_offerInBookedLockedBySupplierState) {
                RequestOffer.unlockBookedLockedBySupplier(
                    _offerInBookedLockedBySupplierState.offerId,
                    (resp) => {
                        EventSchedule.updateOfferPart(
                            data.event.referenceId,
                            this.data,
                            (resp) => {
                                $rootScope.$emit('event:user:action', {action: 'added.request.offer.part'});
                                if (okCallBack) {
                                    okCallBack(resp);
                                }
                                $scope.endProgress('create-event-modal');
                                this.close();
                            },
                            $scope.endWithErrorOverlay('create-event-modal')
                        );
                    },
                    $scope.endWithErrorOverlay('create-event-modal')
                );
            } else {
                EventSchedule.updateOfferPart(
                    data.event.referenceId,
                    this.data,
                    (resp) => {
                        $rootScope.$emit('event:user:action', {action: 'added.request.offer.part'});
                        if (okCallBack) {
                            okCallBack(resp);
                        }
                        $scope.endProgress('create-event-modal');
                        this.close();
                    },
                    $scope.endWithErrorOverlay('create-event-modal')
                );
            }
        }
    }

    cancel() {
        var cancelCallBack = this.cancelCallBack;
        this.close();
        if (cancelCallBack) {
            cancelCallBack();
        }
    }

    checkIfEditable() {
        this.eventScheduleModel.confirmationRequired = false;
        if (!this.data) {
            return;
        }
        let editType;
        let editTypeName;
        if (this.data.editType) {
            editType = this.data.editType;
        } else {
            var selectedRequest = _.find(this.eventDetails.requests, {id: this.data.requestId});
            if (selectedRequest) {
                let _finishedWaitingForCreatorRequest = _.find(selectedRequest.requestOffers, (requestOffer) => {
                    return requestOffer.status.name === 'FINISHED_WAITING_FOR_CREATOR';
                });
                if (_finishedWaitingForCreatorRequest) {
                    editType = {name: 'DENIED'};
                } else {
                    editType = selectedRequest.editType;
                }
            }
        }
        if (editType) {
            editTypeName = editType.name;
        }
        this.eventScheduleModel.editType = editType;
        if (this.eventScheduleModel.editType) {
            this.editDenied =
                ['DENIED', 'DENIED_ACTION_REQUIRED', 'DENIED_SUPPLIER_LOCKED'].indexOf(
                    this.eventScheduleModel.editType.name
                ) > -1;
        }
        if (editTypeName === 'NOTIFY_BEFORE_EDIT') {
            this.eventScheduleModel.confirmationRequired = true;
        } else if (editTypeName === 'EDITABLE') {
            return;
        }
    }

    delete() {
        /*var deleteCallBack = this.deleteCallBack;
        OgModal.open('confirmation', {
          message: 'request.delete.part.confirm', okCallBack: () => {
            this.close();
            if (deleteCallBack) {
              deleteCallBack();
            }
          }
        });*/
        this.showConfirmationMessage = true;
        if (this.showConfirmationMessage) {
            this.scrollTo();
        }
    }

    deleteConfirm() {
        const {$scope, $rootScope, data, EventSchedule, RequestOffer} = this._injected;
        var deleteCallBack = this.deleteCallBack;
        $scope.startProgress('create-event-modal');
        let _offerInBookedLockedBySupplierState = _.find(data.event.suppliers, (supplier) => {
            return supplier.status === 'BOOKED_LOCKED_BY_SUPPLIER';
        });
        if (_offerInBookedLockedBySupplierState) {
            RequestOffer.unlockBookedLockedBySupplier(
                _offerInBookedLockedBySupplierState.offerId,
                (resp) => {
                    EventSchedule.deleteOfferPart(
                        data.event.referenceId,
                        (resp) => {
                            $rootScope.$emit('event:user:action', {action: 'deleted.request.offer.part'});
                            if (deleteCallBack) {
                                deleteCallBack(resp);
                            }
                            $scope.endProgress('create-event-modal');
                            this.close();
                        },
                        $scope.endWithErrorOverlay('create-event-modal')
                    );
                },
                $scope.endWithErrorOverlay('create-event-modal')
            );
        } else {
            EventSchedule.deleteOfferPart(
                data.event.referenceId,
                (resp) => {
                    $rootScope.$emit('event:user:action', {action: 'deleted.request.offer.part'});
                    if (deleteCallBack) {
                        deleteCallBack(resp);
                    }
                    $scope.endProgress('create-event-modal');
                    this.close();
                },
                $scope.endWithErrorOverlay('create-event-modal')
            );
        }
    }

    cancelChange() {
        this.dataLoaded(this.originalData);
        this.close();
    }

    toggleCommentEditMode() {
        this.commentEditMode = !this.commentEditMode;
    }

    dataLoaded(data) {
        const {FieldChangedSvc, TranslationService} = this._injected;

        // data.commentByCreator = TranslationService.translate(data.commentByCreator, data.commentByCreatorCode);
        data.name = TranslationService.translate(data.name, data.nameCode);
        this.data = angular.copy(data);
        this.originalData = angular.copy(data);

        FieldChangedSvc.reset();
        FieldChangedSvc.init(angular.copy(data));
        this.viewUpdateButton = false;
        this.isDataLoaded = true;
        this.checkIfEditable();
        this.getOfferPartAdditionalQuestionClass(data.nameCode);
    }

    handleOnchanged(modalName, currentValue) {
        const {FieldChangedSvc} = this._injected;
        if (this.isDataLoaded) {
            if (modalName == 'nameCode') {
                this.updateNameCodeOnNameChange();
                if (this.originalData.nameCode == null && this.data.nameCode == null) {
                    modalName = 'name';
                    currentValue = this.data.name;
                }
            }
            FieldChangedSvc.checkOnChanged(modalName, currentValue);
        }
        this.viewUpdateButton = FieldChangedSvc.isFieldChanged();
    }

    updateSelectedPartName(name, nameCode, offerPartCategory = null, isSuggestionEnabled = false, updateListFlag = 1) {
        let {$scope, $rootScope, EventEnum, $timeout} = this._injected;
        this.data.name = name;
        this.data.translatedName = name;
        this.data.nameCode = nameCode;
        this.data.offerPartCategory = offerPartCategory;
        this.data.isSuggestionEnabled = isSuggestionEnabled;
        this.handleOnchanged('nameCode', this.data.nameCode);
        if (updateListFlag === 1) {
            this.getAdditionalDetails(nameCode, this.data.id);
            this.getOfferPartAdditionalQuestionClass(nameCode);
        }
        if (offerPartCategory === 'ACCOMMODATION') {
            this.openAccommodationModal = true;
        }
    }

    updateNameCodeOnNameChange() {
        const { CommonFunctions } = this._injected;
        if (this.originalData) {
            let offerPartTranslatedName = CommonFunctions.getTranslatedTextFromCode(
                this.originalData.name,
                this.originalData.nameCode
            );
            this.data.nameCode = this.data.name == offerPartTranslatedName ? this.originalData.nameCode : null;
        }
    }

    amountTypeChanged() {
        if (this.data.amountType.name == 'SAME_AS_EVENT') {
            this.data.amount = this.eventDetails.participantAmount;
        }
    }

    viewChanges(requestId, offerId) {
        this.close();
        this.viewRequest(requestId, offerId);
    }

    showCommentNotification() {
        const {FieldChangedSvc} = this._injected;
        return (
            this.data &&
            this.data.defaultCommentByCreator &&
            !FieldChangedSvc.isParticularFieldChanged('commentByCreator')
        );
    }

    saveDisabled() {
        let {DynamicFormUtilService} = this._injected;
        var isTimePairValid = this.eventScheduleModel && this.eventScheduleModel.isTimePairValid,
            isRequiredFieldUnanswered = false;
        if (this.data && this.data.timeless) {
            isTimePairValid = true;
        }
        if (this.additionalDetailsChanged) {
            this.viewUpdateButton = true;
        }
        if (this.data && this.data.list) {
            isRequiredFieldUnanswered = DynamicFormUtilService.isRequiredQuestionUnAnswered(this.data.list);
        }
        return this.editDenied || !this.viewUpdateButton || !isTimePairValid || isRequiredFieldUnanswered;
    }

    viewRequest(requestId, offerId) {
        let {$timeout, $rootScope, EventEnum} = this._injected;
        //added 1 sec to let this modal close and open another modal. This fixes the scrollable issue
        $timeout(() => {
            $rootScope.$broadcast(EventEnum.OPEN_SUPPLIER_PREVIEW_MODAL, {offerId: offerId, requestId: requestId});
        }, 1000);
    }

    translateTextByCode(text, code) {
        const { CommonFunctions } = this._injected;
        return CommonFunctions.getTranslatedTextFromCode(text, code);
    }

    scrollTo() {
        let {$timeout} = this._injected;
        let _elementId = '#successAlertBox';
        $timeout(() => {
            let _topHeight = jQuery(_elementId).position().top;
            jQuery('#og-event-schedule-modal').animate({scrollTop: _topHeight}, 'slow');
        }, 500);
    }

    close() {
        const {close, $element} = this._injected;
        $element.modal('hide');
        close(null, 500);
    }

    openAccommodationCallback() {
        const {$rootScope, ModalService, EventEnum, $timeout} = this._injected;
        this.close();

        let data = {
            eventId: this.eventDetails.id,
            requestId: this.data.requestId,
            successCallBack: function (res) {
                $rootScope.$broadcast(EventEnum.EVENT_AND_REQUESTS_RELOAD);
            },
        };

        let addEditAccommodationModalData = addEditAccommodationModal;
        addEditAccommodationModalData.inputs = {data};

        $timeout(() => {
            ModalService.showModal(addEditAccommodationModalData).then((modal) => {
                modal.element.modal();
                modal.close.then(() => {
                    console.log('modal is closed!');
                });
            });
        }, 900);
    }
}

AddEditOfferPartModalCtrl.$inject = [
    'EventSchedule',
    '$rootScope',
    '$state',
    '$timeout',
    '$translate',
    'EventEnum',
    'FieldChangedSvc',
    'data',
    '$scope',
    'close',
    '$element',
    'TranslationService',
    'FilledRequestOfferPartComment',
    'DynamicFormUtilService',
    'RequestOffer',
    'ModalService',
    'CommonFile',
    'CommonFunctions'
];
export default AddEditOfferPartModalCtrl;
