import controller from './og_supplier_resource_form.controller';

(function () {
    'use strict';
    angular.module('ogSupplierResourceForm').directive('ogSupplierResourceForm', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    supplierId: '=',
                    resourceData: '=',
                    list: '=listValue',
                    module: '@module',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                    isChanged: '&',
                    args: '=',
                    cancelCallback: '&',
                },
                templateUrl:
                    './app/components/directives/supplier_resource_form/og_supplier_resource_form_directive.view.html',
                controller,
                controllerAs: 'supplierResourceFormCtrl',
                bindToController: true,
            };
        },
    ]);
})();
