(function () {
    'use strict';

    angular.module('ogFileSelect').directive('ogFileSelect', [
        function () {
            function getFileSelectElementId(scope) {
                return 'file-upload' + scope.tabIndex;
            }

            return {
                scope: {
                    ogFileSelect: '=',
                    clearAll: '=',
                    existingAttachments: '=?',
                    fileSelectToolTipPlacement: '=',
                    fileSelectCallback: '&',
                    removeFileUploadCallback: '&',
                },
                templateUrl: './app/components/directives/file_select/og_file_select.view.html',
                controller: [
                    '$scope',
                    'RemoteFilepicker',
                    function ($scope, RemoteFilepicker) {
                        $scope.selectDropboxFile = function () {
                            RemoteFilepicker.showDropboxPicker(
                                function (files) {
                                    $scope.ogFileSelect.uploads = null;
                                    $scope.ogFileSelect.links = [];

                                    angular.forEach(files, function (f) {
                                        $scope.ogFileSelect.links.push(f);
                                    });

                                    $scope.fileSelectCallback({ fileSelect: $scope.ogFileSelect });

                                    $scope.$apply();
                                },
                                function () {
                                    console.log('User cancelled');
                                }
                            );
                        };

                        $scope.selectGoogleFile = function () {
                            RemoteFilepicker.showGooglePicker(function (files) {
                                $('#' + getFileSelectElementId($scope))
                                    .parent()
                                    .html(
                                        $('#' + getFileSelectElementId($scope))
                                            .parent()
                                            .html()
                                    );
                                $scope.ogFileSelect.uploads = null;
                                $scope.ogFileSelect.links = [];

                                angular.forEach(files, function (f) {
                                    $scope.ogFileSelect.links.push(f);
                                });
                                $scope.fileSelectCallback({ fileSelect: $scope.ogFileSelect });

                                $scope.$apply();
                            });
                        };

                        $scope.getTabIndex = function () {
                            return;
                        };
                    },
                ],
                link: function (scope, element) {
                    scope.queue = [];
                    if (scope.ogFileSelect) {
                        scope.ogFileSelect.maxSizeExceeded = false;
                    }
                    scope.tabIndex = (Math.random() * 6).toString().replace(/\./g, '_');

                    scope.$watch('clearAll', function (nv) {
                        if (nv === true) {
                            scope.clearFiles();
                        }
                    });

                    function fileSizeInMb(fileSize) {
                        return Math.round((fileSize / (1024 * 1024)) * 10000) / 10000;
                    }

                    element.bind('change', function (event) {
                        scope.errorMessage = '';
                        scope.ogFileSelect.maxSizeExceeded = false;
                        setTimeout(function () {
                            scope.$apply(function () {
                                if (event.target && event.target.files) {
                                    var fileLimit = 1;
                                    for (var file = 0; file < event.target.files.length; file++) {
                                        fileLimit *= fileSizeInMb(event.target.files[file].size) < 10;
                                        if (!fileLimit) {
                                            break;
                                        } else {
                                            let files = scope.queue;
                                            if (files.length > 0) {
                                                var isFileAlreadyAdded = files.filter(
                                                    (data) =>
                                                        data.name === event.target.files[file].name &&
                                                        data.size === event.target.files[file].size
                                                );
                                                if (!isFileAlreadyAdded.length > 0) {
                                                    scope.queue.push(event.target.files[file]);
                                                }
                                            } else {
                                                scope.queue.push(event.target.files[file]);
                                            }
                                        }
                                    }
                                    if (!fileLimit) {
                                        scope.errorMessage = 'file.upload.file.big.message';
                                        scope.ogFileSelect.maxSizeExceeded = true;
                                        return;
                                    } else {
                                        scope.ogFileSelect.maxSizeExceeded = false;
                                        scope.ogFileSelect.uploads = scope.queue;
                                        if (scope.ogFileSelect) {
                                            element.val(scope.ogFileSelect.uploads);
                                            if (
                                                scope.ogFileSelect === null ||
                                                (scope.ogFileSelect.uploads === null &&
                                                    scope.ogFileSelect.links === null)
                                            ) {
                                            } else if (
                                                (scope.ogFileSelect.uploads !== null &&
                                                    scope.ogFileSelect.uploads.length > 0) ||
                                                (scope.ogFileSelect.links !== null &&
                                                    scope.ogFileSelect.links.length > 0)
                                            ) {
                                            }
                                        }
                                    }
                                    scope.fileSelectCallback({ fileSelect: scope.ogFileSelect });
                                }
                            });
                        }, 0);
                    });

                    scope.clearFiles = function () {
                        scope.clearAll = false;
                        scope.queue = [];
                        scope.ogFileSelect = { uploads: null, links: null, maxSizeExceeded: false };
                        scope.existingAttachments = null;
                        scope.fileSelectCallback({ fileSelect: scope.ogFileSelect });
                        $('#' + getFileSelectElementId(scope))
                            .parent()
                            .html(
                                $('#' + getFileSelectElementId(scope))
                                    .parent()
                                    .html()
                            );
                    };

                    scope.showClearButton = function () {
                        let _uploads = scope.ogFileSelect.uploads && scope.ogFileSelect.uploads.length > 0;
                        let _links = scope.ogFileSelect.links && scope.ogFileSelect.links.length > 0;
                        let _existingAttachments = scope.existingAttachments && scope.existingAttachments.length > 0;
                        return _uploads || _links || _existingAttachments;
                    };

                    scope.removeFile = function (name, lastModified) {
                        let files = scope.ogFileSelect.uploads;
                        for (let i = 0; i < files.length; i++) {
                            if (files[i].name === name && files[i].lastModified === lastModified) {
                                files.splice(i, 1);
                            }
                        }
                        scope.removeFileUploadCallback({ files: files });
                    };

                    scope.selectDriverFile = function () {
                        jQuery('#' + getFileSelectElementId(scope)).val('');
                        jQuery('#' + getFileSelectElementId(scope)).trigger('click');
                    };

                    scope.closeFileSelect = function () {
                        scope.ogFileSelect.maxSizeExceeded = false;
                    };
                },
            };
        },
    ]);
})();
