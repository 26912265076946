'use strict';
import {ScopeFunctions} from '../../../../../../utils/global/scopeFunctions';
import {OgModal} from "../../../../../../utils/global/ogModal";

// Register `participants.summary` component, along with its associated controller and template
angular.module('participants.summary').component('participantsummary', {
    templateUrl: './app/components/modules/event/event_detail/participants/summary/summary.view.html',
    controllerAs: 'participantSummaryCtrl',
    controller: [
        '$scope',
        '$rootScope',
        '$stateParams',
        'ParticipantActivity',
        'ParticipantSummary',
        'DateService',
        'TimezoneService',
        'ParticipantService',
        function ParticipantListController(
            $scope,
            $rootScope,
            $stateParams,
            ParticipantActivity,
            ParticipantSummary,
            DateService,
            TimezoneService,
            ParticipantService
        ) {
            var vm = this;

            vm.getDetailChangesValue = getDetailChangesValueFn;
            vm.listActivities = listActivitiesFn;
            vm.openMessageDetails = openMessageDetailsFn;
            vm.showMessageDetails = showMessageDetailsFn;
            vm.hideMessageDetails = hideMessageDetailsFn;
            vm.getTranslateValues = getTranslateValuesFn;
            vm.getQuestionLabel = getQuestionLabelFn;
            vm.listLatestRegisteredParticipants = listLatestRegisteredParticipantsFn;
                vm.filterParticipantActivity = filterParticipantActivityFn;

                vm.eventId = $stateParams.eventId;
                vm.activities = [];
                vm.registeredParticipants = [];
                vm.activitiesLoading = false;
                vm.allParticipantsFetched = false;
                vm.showActivityDetails = false;
                vm.showActivityId = null;

                const INITIAL_OFFSET = 0;
                const MAX_ACTIVITIES_COUNT_PER_FETCH = 5;
                const MAX_PARTICIPANTS_ON_FIRST_FETCH = 5;

                var activitySearchParams = { offset: INITIAL_OFFSET, max: MAX_ACTIVITIES_COUNT_PER_FETCH };
                var registeredParticipantsSearchParams = { offset: INITIAL_OFFSET, max: MAX_PARTICIPANTS_ON_FIRST_FETCH };

                ScopeFunctions.addToScope($scope);

                function init() {
                    $scope.startProgress();
                    ParticipantSummary.getSummary(
                        vm.eventId,
                        function (response) {
                            vm.summary = response;
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );

                    vm.listLatestRegisteredParticipants();
                }

                function showMessageDetailsFn(activity) {
                    vm.showActivityDetails = true;
                    vm.showActivityId = activity.id;
                }

                function hideMessageDetailsFn() {
                    vm.showActivityDetails = false;
                    vm.showActivityId = null;
                }

                function getTranslateValuesFn(message) {
                    let _participant = message.toAddress[0];
                    let name = getFullNameFn(_participant);
                    if (!name) {
                        name = !!_participant.email ? _participant.email : _participant.phone;
                    }
                    return { participant: name };
                }

                function getQuestionLabelFn(item) {
                    return ParticipantService.getQuestionLabel(item, $rootScope.langSelected);
                }

                function openMessageDetailsFn(eventMessage) {
                    let _participantsList = [];
                    if (eventMessage.emailMessage === 'null' || eventMessage.emailMessage === null) {
                        eventMessage.emailMessage = '';
                    }
                    if (eventMessage.emailSubject === 'null' || eventMessage.emailSubject === null) {
                        eventMessage.emailSubject = '';
                    }
                    angular.forEach(eventMessage.toAddress, function (participant) {
                        let participantData = {
                            id: participant.id,
                            name: getFullNameFn(participant),
                            email: participant.email,
                            phone: participant.phone,
                        };
                        if (participant.attending !== 'BOUNCED_EMAIL') {
                            _participantsList.push(participantData);
                        }
                    });
                    OgModal.open('participant:email', {
                        type: eventMessage.type,
                        hash: eventMessage.hash,
                        eventId: vm.eventId,
                        eventMessage: eventMessage,
                        participants: vm.participants,
                        selectedParticipants: _participantsList,
                        readOnly: true,
                    });
                }

                function getFullNameFn(participant) {
                    var fname = '',
                        lname = '',
                        name = '';
                    angular.forEach(participant.participantBasicQueryInfoList, function (basicInfo) {
                        if (basicInfo.detailNameEnglish === 'First Name') {
                            if (basicInfo.fieldValue) {
                                fname = basicInfo.fieldValue;
                            } else {
                                fname = '';
                            }
                        }
                        if (basicInfo.detailNameEnglish === 'Last Name') {
                            if (basicInfo.fieldValue) {
                                lname = basicInfo.fieldValue;
                            } else {
                                lname = '';
                            }
                        }
                    });
                    name = fname;
                    if (name) {
                        name += ' ';
                    }
                    name += lname;
                    return name;
                }

                function listActivitiesFn(freshSearch) {
                    if (vm.activitiesLoading || (activitySearchParams.offset >= vm.activityTotalCount && !freshSearch)) {
                        return;
                    }
                    vm.activitiesLoading = true;

                    ParticipantActivity.listActivities(
                        vm.eventId,
                        activitySearchParams,
                        function (response) {
                            vm.activities = vm.activities.concat(response.list);
                            vm.activityTotalCount = response.totalCount;
                            activitySearchParams.offset = vm.activities.length;
                            vm.activitiesLoading = false;
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function listLatestRegisteredParticipantsFn() {
                    if (registeredParticipantsSearchParams.offset >= vm.registeredParticipantsTotalCount) {
                        return;
                    }
                    ParticipantSummary.listRegisteredParticipants(
                        vm.eventId,
                        registeredParticipantsSearchParams,
                        function (response) {
                            vm.registeredParticipants = vm.registeredParticipants.concat(response.list);
                            vm.registeredParticipantsTotalCount = response.totalCount;
                            registeredParticipantsSearchParams.offset = vm.registeredParticipants.length;
                            registeredParticipantsSearchParams.max =
                                vm.registeredParticipantsTotalCount - vm.registeredParticipants.length;
                            if (registeredParticipantsSearchParams.offset >= vm.registeredParticipantsTotalCount) {
                                vm.allParticipantsFetched = true;
                            }
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function getDateTimeDisplayFormatFn() {
                    return vm.langSelected == 'sv_SV' ? 'YYYY-MM-DD HH:mm' : 'DD/MM/YYYY hh:mm a';
                }

                function getDetailChangesValueFn(value, type) {
                    if (value && type.toLowerCase() == 'date') {
                        let dateFormat = DateService.getDateFormatByLocale();
                        value = TimezoneService.formatDate(new Date(parseInt(value)), dateFormat);
                    }
                    if (value && type.toLowerCase() == 'daterange') {
                        let dates = value.split('~');
                        let from = TimezoneService.formatDate(
                            TimezoneService.toLocalTime(new Date(parseInt(dates[0]))),
                            getDateTimeDisplayFormatFn()
                        );
                        let to = TimezoneService.formatDate(
                            TimezoneService.toLocalTime(new Date(parseInt(dates[1]))),
                            getDateTimeDisplayFormatFn()
                        );
                        value = from + ' - ' + to;
                    }
                    return value;
                }

                function filterParticipantActivityFn() {
                    activitySearchParams.offset = INITIAL_OFFSET;
                    activitySearchParams.participantId = vm.selectedParticipantId;
                    activitySearchParams.activityType = vm.filterActivityType;
                    vm.activities = [];
                    vm.listActivities(true);
                }

                init();
            }
        ]
    });
