import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

(function () {
    'use strict';

    angular.module('ogOfferBox').directive('ogOfferBox', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/offer_box/og_offer_box.view.html',
            replace: true,
            controller: [
                '$scope',
                'DateService',
                function ($scope, DateService) {
                    ScopeFunctions.addToScope($scope);

                    $scope.getDateSpan = DateService.getDateSpan;
                },
            ],
        };
    });
})();
