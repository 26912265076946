class UserGroupData {
    constructor() {
        this.UserGroupJsonData = {};
    }

    setUserGroupData(data) {
        this._userGroupJsonData = data;
    }

    getUserGroupData() {
        return this._userGroupJsonData;
    }

    userGroupHasIncludeVatOnPriceAsTrue(userGroup) {
        if (userGroup.includeVatInPrice === true) {
            return true;
        }
        return false;
    }

    hasIncludeVatOnPriceTrue() {
        let _currentUserGroup = this.getUserGroupData();
        return this.userGroupHasIncludeVatOnPriceAsTrue(_currentUserGroup);
    }
}

export default new UserGroupData();
