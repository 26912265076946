import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

let INITIAL_USER_GROUP_SIZE = 10;
let USER_GROUP_SEARCH_SIZE = 10;

(function () {
    'use strict';

    angular.module('ogUserGroupSelectTreeStructure').directive('ogUserGroupSelectTreeStructure', [
        'AuthenticationService',
        'EventEnum',
        '$translate',
        'eaTreeViewFactory',
        'ModalService',
        'UserGroup',
        function (AuthenticationService, EventEnum, $translate, eaTreeViewFactory, ModalService, UserGroup) {
            return {
                templateUrl:
                    './app/components/directives/user_group_select_tree_structure/user_group_select_tree_structure.directive.view.html',
                restrict: 'EA',
                replace: true,
                scope: {
                    group: '=',
                    groupChangeCallback: '&',
                },
                link: function ($scope) { },
                controller: [
                    '$scope',
                    function ($scope) {
                        var self = this;
                        ScopeFunctions.addToScope($scope);
                        $scope.datasetId = 'my-group';

                        $scope.selectedContextId = null;
                        $scope.showLoadMore = false;
                        $scope.selectedGroupName = angular.copy($scope.group.name);
                        $scope.selectedGroupOwnerName = null;
                        $scope.selectedGroupId = angular.copy($scope.group.id);
                        $scope.searchParam = {
                            max: 10,
                            offset: 0,
                        };

                        $scope.$watch('group', (newVal) => {
                            $scope.selectedGroupName = angular.copy(newVal.name);
                            $scope.selectedGroupOwnerName = null;
                        });

                        $scope.selectedContextChanged = function (item) {
                            let _userGroupId = item.id;
                            $scope.selectedGroupName = item.name;
                            $scope.selectedGroupOwnerName =
                                item.childUserGroupCount === 0 ? (item.groupOwner ? item.groupOwner.name : null) : null;
                            $scope.selectedGroupId = item.id;
                            let isOpened = $('#event-management-usergroup-dropdown').hasClass('open');
                            if (isOpened) {
                                $('#event-management-usergroup-dropdown').removeClass('open');
                            }
                            $scope.groupChangeCallback({ groupId: _userGroupId });
                        };

                        $scope.isUserGroupSelected = function (item) {
                            if (item.id === $scope.selectedGroupId) {
                                return true;
                            } else {
                                return false;
                            }
                        };

                        function initUserGroups(userGroups) {
                            if (userGroups) {
                                let _userGroupsWithoutPrivateGroup = _.filter(userGroups, function (item) {
                                    return item && item.name;
                                });
                                // todo need to optimize: merge sorting criteria
                                _userGroupsWithoutPrivateGroup = _.sortBy(_userGroupsWithoutPrivateGroup, function (o) {
                                    return o.id;
                                });
                                _userGroupsWithoutPrivateGroup = _.sortBy(_userGroupsWithoutPrivateGroup, function (o) {
                                    return o.name;
                                });
                                var parentGroup = {
                                    id: angular.copy($scope.group.id),
                                    name: angular.copy($scope.group.name),
                                };
                                _userGroupsWithoutPrivateGroup.splice(0, 0, parentGroup);
                                let _groups = _userGroupsWithoutPrivateGroup;
                                let _activeUserGroup = _.find(userGroups, function (g) {
                                    return g.expanded;
                                });
                                if (_activeUserGroup) {
                                    _activeUserGroup.expanded = false;
                                }
                                return _groups;
                            }
                            return [];
                        }

                        $scope.toggleUserGroupExpanded = function (item) {
                            item.expanded = !item.expanded;
                            item.showLoadMoreChild = false;
                            item.childrenUserGroups = [];
                            if (item.expanded) {
                                let params = {
                                    groupId: item.id,
                                    offset: 0,
                                    max: USER_GROUP_SEARCH_SIZE,
                                };
                                UserGroup.getChildren(params, (response) => {
                                    item.childrenUserGroups = response.childGroupList;
                                    item.showLoadMoreChild = response.childGroupTotal > item.childrenUserGroups.length;
                                });
                            }
                        };

                        $scope.expandUsergroup = function () {
                            $scope.userGroupSearch = '';
                            var isCollapsed = $('#event-management-usergroup-dropdown').hasClass('open');
                            if (!isCollapsed) {
                                this.searchUserGroups($scope.group);
                            }
                        };

                        $scope.searchUserGroups = (group) => {
                            let params = {
                                groupId: group ? group.id : $scope.group.id,
                                searchText: $scope.userGroupSearch,
                                offset: 0,
                                max: USER_GROUP_SEARCH_SIZE,
                            };
                            $scope.startProgress();
                            // if($scope.userGroupSearch){
                            UserGroup.getChildren(
                                params,
                                (response) => {
                                    $scope.searchParam.max = response.max;
                                    $scope.searchParam.offset = response.offset;
                                    $scope.totalUGResult = response.childGroupTotal;
                                    $scope.userGroups = initUserGroups(response.childGroupList);
                                    $scope.showLoadMore = $scope.totalUGResult > $scope.userGroups.length - 1; // -1 as parent group has been added in the list
                                    $scope.endProgress();
                                },
                                $scope.endWithError
                            );
                            // }
                        };

                        $scope.loadMore = () => {
                            let offset = $scope.searchParam.offset + $scope.searchParam.max;
                            if (offset >= $scope.totalUGResult) {
                                return;
                            }

                            let params = {
                                groupId: $scope.group.id,
                                searchText: $scope.userGroupSearch,
                                offset: offset,
                                max: USER_GROUP_SEARCH_SIZE,
                            };

                            UserGroup.getChildren(params, (response) => {
                                $scope.searchParam.max = response.max;
                                $scope.searchParam.offset = response.offset;
                                $scope.userGroups = [...$scope.userGroups, ...response.childGroupList];
                                $scope.showLoadMore = $scope.totalUGResult > $scope.userGroups.length - 1; // -1 as parent group has been added in the list
                            });
                        };

                        $scope.loadMoreChildren = (group) => {
                            let offset = group.childrenUserGroups.length ? group.childrenUserGroups.length : 0;
                            let params = {
                                groupId: group.id,
                                offset: offset,
                                max: USER_GROUP_SEARCH_SIZE,
                            };
                            UserGroup.getChildren(params, (response) => {
                                angular.forEach(response.childGroupList, (userGroup) => {
                                    group.childrenUserGroups.push(userGroup);
                                });
                                group.showLoadMoreChild = response.childGroupTotal > group.childrenUserGroups.length;
                            });
                        };

                        $(document).on('click', '#event-management-usergroup-dropdown .dropdown-menu', function (e) {
                            e.stopPropagation();
                        });
                    },
                ],
            };
        },
    ]);
})();
