'use strict';
angular.module('core.existingServices').factory('LinkService', [
    'AuthenticationService',
    function (AuthenticationService) {
        var PARAM_TYPE_MAP = {
            CustomerUser: 'user',
            Supplier: 'supplier',
            Event: 'event',
            Request: 'request',
            RequestOffer: 'offer',
            Task: 'task',
            Survey: 'Survey',
            ChatMessage: 'ChatMessage',
        };

        return {
            createHref: function (param) {
                var uiType = PARAM_TYPE_MAP[param.type];
                var text = param.text;
                if (param.asSupplier || AuthenticationService.isLoggedSupplier()) {
                    if (uiType === 'offer') {
                        return '/offer/' + param.id;
                    }
                    if (!param.external && (uiType === 'event' || uiType === 'request')) {
                        return;
                    }
                } else {
                    if (uiType === 'offer') {
                        return (
                            '/event/' +
                            param.additional.eventId +
                            '/overview?requestId=' +
                            param.additional.requestId +
                            '&offerId=' +
                            param.id
                        );
                    } else if (uiType === 'Survey' && text === 'CREATOR_SURVEY_FOR_EVENT') {
                        return '/event/' + param.additional.eventId + '/rating/creator';
                    } else if (uiType === 'ChatMessage') {
                        console.log(
                            '/event/' + param.additional.eventId + '/message?msgId=' + param.id,
                            '>>>>>>>>'
                        );
                        return '/event/' + param.additional.eventId + '/message?msgId=' + param.id;
                    }
                }
                return uiType ? '/' + uiType + '/' + param.id : '';
            },
        };
    },
]);

