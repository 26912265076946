/**
 * Don't put this content inside function()
 */
'use strict';
import CommonFunctions from '../../utils/CommonFunctionUtil'
export const ScopeFunctions = (function () {
    var fieldChanged = function (data, originalData, field) {
        var value = data;
        var originalValue = originalData;
        if (field) {
            if (!originalData || !data) {
                return false;
            }
            value = data[field];
            originalValue = originalData[field];
        }
        if (value !== null && typeof value === 'object') {
            if (value !== null && value != undefined && (originalValue === null || originalValue === undefined)) {
                return true;
            }
            if ($.isArray(value)) {
                if (!$.isArray(originalValue) || value.length != originalValue.length) {
                    return true;
                }
                value = value.slice(0).sort();
                originalValue = originalValue.slice(0).sort();
                for (var i = 0; i < value.length; i++) {
                    if (fieldChanged(value[i], originalValue[i])) {
                        return true;
                    }
                }
            } else {
                for (var subField in value) {
                    if (value.hasOwnProperty(subField) && fieldChanged(value, originalValue, subField)) {
                        return true;
                    }
                }
            }
            return false;
        }
        if (value == '') {
            value = null;
        }
        if (originalValue == '') {
            originalValue = null;
        }
        return value != originalValue;
    };

    //TODO(SC) double check if this is working fine or not to use it need to add as ScopeFunctions.addToRootScope($scope, $rootScope);
    var addToRootScope = function ($scope, $rootScope) {
        $scope.formError = function (errorResponse) {
            $rootScope.$broadcast('validation:form:error:' + $scope.form, errorResponse);
        };

        $scope.formSuccess = function (message) {
            $rootScope.$broadcast('validation:form:success:' + $scope.form, message);
        };

        $scope.formInfo = function (message) {
            $rootScope.$broadcast('validation:form:info:' + $scope.form, message);
        };

        $scope.formClearError = function () {
            $rootScope.$broadcast('validation:form:' + $scope.form + ':clear');
        };
    }

    var addToScope = function ($scope) {
        $scope.form = 'form' + Math.floor(Math.random() * 1000000000);

        $scope._progress = {
            parts: {},
        };

        $scope.startProgress = function (params) {
            if ($scope.inProgress == false || $scope.inProgress == 0) {
                angular.forEach($scope._progress.parts, function (part) {
                    part.inProgress = false;
                });
            }
            if (typeof params === 'string' || typeof params === 'number') {
                params = { name: params };
            }
            if (params == null) {
                params = {};
            }
            if (params.name == null) {
                params.name = '_main';
            }
            if ($scope._progress.parts[params.name] != null) {
                params.inProgress = $scope._progress.parts[params.name].inProgress;
            }
            $scope._progress.parts[params.name] = params;
            $scope._startProgress(params);
            $scope._startProgress($scope);
        };

        $scope._startProgress = function ($scope) {
            if (isNaN($scope.inProgress)) {
                if ($scope.inProgress) {
                    $scope.inProgress = 2;
                } else {
                    $scope.inProgress = 1;
                }
            } else {
                $scope.inProgress++;
            }
        };

        $scope.endProgress = function (name, error) {
            if (name == null) {
                name = '_main';
            }
            var part = $scope._progress.parts[name];
            if (part == null) {
                part = {};
                $scope._progress.parts[name] = part;
            }
            part.error = error;
            $scope._endProgress(part);
            $scope._endProgress($scope);
            if ($scope.inProgress == false || $scope.inProgress == 0) {
                angular.forEach($scope._progress.parts, function (part) {
                    part.inProgress = false;
                });
            }
        };

        $scope.isInProgress = function (name) {
            if (!name) {
                name = '_main';
            }
            var part = $scope._progress.parts[name];
            if (!!part) {
                return part.inProgress === true || part.inProgress > 0;
            }
            return false;
        };

        $scope._endProgress = function (part) {
            if (isNaN(part.inProgress)) {
                part.inProgress = false;
            } else {
                if (part.inProgress > 0) {
                    part.inProgress--;
                }
            }
            if (part.inProgress == false || part.inProgress == 0) {
                if (part.doAfter != null && part.error != null) {
                    part.doAfter();
                    part.doAfter = null;
                }
            }
        };

        $scope.endWithErrorOverlay = function (name, errorDetails) {
            return $scope._endWith($scope.endProgress, name, errorDetails);
        };

        $scope.endWithError = function (name, errorDetails) {
            return $scope._endWith(
                function (name, error) {
                    $scope.endProgress(name);
                    if (name === '_main') {
                        name = $scope.form;
                    }
                    $scope.formError(name, error);
                },
                name,
                errorDetails
            );
        };

        $scope.endWithAlert = function (name, errorDetails) {
            return $scope._endWith(
                function (name, error) {
                    $scope.endProgress(name);
                    CommonFunctions.alert(error);
                },
                name,
                errorDetails
            );
        };

        $scope.endWithAlertAndCallBack = function (name, errorDetails, callBack) {
            return $scope._endWith(
                function (name, error) {
                    $scope.endProgress(name);
                    var settings = { response: error, okCallBack: callBack };
                    CommonFunctions.alertWithCallBack(settings);
                },
                name,
                errorDetails
            );
        };

        $scope._endWith = function (errCallback, name, errorDetails) {
            if (name == null) {
                // site uses $scope.endWith() as a callback
                return function (response) {
                    errCallback('_main', response);
                };
            }
            if (typeof name === 'string' || typeof name === 'number') {
                // site uses $scope.endWith('name') as a callback
                return function (response) {
                    response.errorDetails = errorDetails;
                    errCallback(name, response);
                };
            } else {
                // site uses $scope.endWith as a callback
                return errCallback('_main', name);
            }
        };

        /**
         * params: name, func
         */
        $scope.appendToProgress = function (params) {
            if (params.name == null) {
                params.name = '_main';
            }
            var part = $scope._progress.parts[params.name];
            if (part != null && (part.inProgress || part.inProgress > 0)) {
                part.doAfter = params.func;
            } else {
                params.func();
            }
        };



        $scope.unchanged = function () {
            return !fieldChanged($scope.data, $scope.originalData);
        };

        $scope.fieldChanged = function (field) {
            return fieldChanged($scope.data, $scope.originalData, field);
        };

        $scope.dataLoaded = function (data) {
            $scope.data = angular.copy(data);
            $scope.originalData = angular.copy(data);
            if ($scope.form) {
                $scope.data.form = $scope.form;
                $scope.originalData.form = $scope.form;
            }
            $scope.endProgress();
        };
    };

    return {
        addToScope: addToScope,
        addToRootScope: addToRootScope,
    };
})();
