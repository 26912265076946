import controller from './og_location_dropdown.controller';

(function () {
    'use strict';

    angular.module('ogLocationDropdown').directive('ogLocationDropdown', [
        function () {
            return {
                scope: {
                    placeholder: '@',
                    list: '=',
                    selected: '=',
                    otherSelectedValue: '=',
                    open: '=',
                    inputClass: '@',
                    minLength: '@',
                    disabled: '=',
                    updateSelectedGeoTagIdCallback: '&',
                },
                templateUrl: './app/components/directives/og-location-dropdown/og_location_dropdown.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogLocationDropdownCtrl',
                bindToController: true,
            };
        },
    ]);
})();
