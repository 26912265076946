'use strict';
angular.module('core.userGroup').factory('UserGroup', [
    '$resource',
    function ($resource) {
        var groupRest = $resource(
            'api/v1/userGroup/:groupId',
            {groupId: '@id'},
            {
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: false},
            }
        );

        var groupAddressRest = $resource(
            'api/v1/userGroup/address/:groupId',
            {groupId: '@id'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var listUsersRest = $resource(
            'api/v1/userGroup/:groupId/users',
            {groupId: '@groupId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var addUsersRest = $resource(
            'api/v1/userGroup/:groupId/addUsers',
            {groupId: '@id'},
            {
                list: {method: 'POST', isArray: false},
            }
        );
        var deleteGroupRest = $resource(
            'api/v1/userGroup/:groupId/delete',
            {groupId: '@groupId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var groupFortNoxConfigRest = $resource(
            'api/v1/userGroup/isFortNoxConfigured/:groupId',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var isInvitationBlockedRest = $resource(
            'api/v1/userGroup/isInvitationBlocked/:eventId',
            {eventId: '@eventId'},
            {
                post: {method: 'POST', isArray: false},
            }
        );

        var getShowMarkup = $resource(
            'api/v1/userGroup/getShowMarkup',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var updateGroupOwner = $resource(
            'api/v1/userGroup/:groupId/updateOwner/:userId',
            {groupId: '@groupId', userId: '@userId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var getChildren = $resource(
            'api/v1/userGroup/:groupId/children',
            {groupId: '@groupId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );

        var searchUserGroups = $resource(
            'api/v1/userGroup/searchUserGroups',
            {},
            {
                list: {method: 'GET', isArray: false},
            }
        );

        var getEmailSettings = $resource(
            'api/v1/userGroup/:groupId/getEmailSettings',
            {groupId: '@groupId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        var updateEmailSetting = $resource(
            'api/v1/userGroup/:groupId/updateEmailSetting',
            {groupId: '@groupId'},
            {
                update: {method: 'POST', isArray: true},
            }
        );

        return {
            get: function (id, successCallback, errorCallback) {
                return groupRest.get({groupId: id}, successCallback, errorCallback);
            },
            getAddress: function (id, successCallback, errorCallback) {
                return groupAddressRest.get({groupId: id}, successCallback, errorCallback);
            },
            update: function (params, successCallback, errorCallback) {
                return groupRest.update(params, successCallback, errorCallback);
            },
            listUsers: function (params, successCallback, errorCallback) {
                return listUsersRest.list({groupId: params.id}, successCallback, errorCallback);
            },
            addUsers: function (params, successCallback, errorCallback) {
                return addUsersRest.list(params, successCallback, errorCallback);
            },
            delete: function (groupId, successCallback, errorCallback) {
                return deleteGroupRest.delete({groupId: groupId}, successCallback, errorCallback);
            },
            isFortNoxConfigured: function (successCallback, errorCallback) {
                return groupFortNoxConfigRest.get(successCallback, errorCallback);
            },
            isInvitationBlocked: function (params, successCallback, errorCallback) {
                return isInvitationBlockedRest.post(params, successCallback, errorCallback);
            },
            getShowMarkup: function (params, successCallback, errorCallback) {
                return getShowMarkup.get(params, successCallback, errorCallback);
            },
            // update the group owner @BP
            updateGroupOwner: function (params, successCallback, errorCallback) {
                return updateGroupOwner.update(
                    {groupId: params.groupId, userId: params.userId},
                    successCallback,
                    errorCallback
                );
            },

            getChildren: function (params, successCallback, errorCallback) {
                return getChildren.list(params, successCallback, errorCallback);
            },

            searchUserGroups: function (params, successCallback, errorCallback) {
                return searchUserGroups.list(params, successCallback, errorCallback);
            },

            getEmailSettings: function (params, successCallback, errorCallback) {
                return getEmailSettings.list(params, successCallback, errorCallback);
            },

            updateEmailSetting: function (params, successCallback, errorCallback) {
                return updateEmailSetting.update(
                    {groupId: params.groupId, selectedIds: params.selectedIds},
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);
