/* global angular */
'use strict';
angular.module('core.existingServices').factory('BudgetDataShareService', [
    '$stateParams',
    function ($stateParams) {
        var selectedOfferList = [];
        var selectedOfferListForClientSelectionList = [];
        var totalPrice;
        var totalPriceByCurrency;
        var originalPrice;
        var priceDifferenceByCurrency;
        var showBudgetDiffColumn;
        var eventId;
        var selectedPartIdForMarkup;
        var selectedOfferIdForMarkup;
        let selectedOfferPartOption = [];
        let selectedOfferPartOptionForClientSelectionList = [];

        function initFn() {
            selectedOfferList = [];
            selectedOfferListForClientSelectionList = [];
            eventId = $stateParams.eventId;
        }

        function setSelectedOfferList(requestId, offer) {
            if ($stateParams.eventId && eventId !== $stateParams.eventId) {
                initFn();
            }
            if (requestId && offer) {
                selectedOfferList[requestId] = offer;
            }
        }

        function setSelectedOfferListForClientSelection(requestId, offer) {
            if ($stateParams.eventId && eventId !== $stateParams.eventId) {
                initFn();
            }
            if (requestId && offer) {
                selectedOfferListForClientSelectionList[requestId] = offer;
            }
        }

        function checkAndRemoveOfferFromSelectedOfferList(requestId, offer) {
            if (
                requestId in selectedOfferList &&
                ((!offer.supplier && offer.statusDetailed.name !== 'INTERNAL') ||
                    offer.statusDetailed.name === 'CANCELED')
            ) {
                delete selectedOfferList[requestId];
            }
        }

        function checkAndRemoveOfferFromSelectedOfferListForClientSelection(requestId, offer) {
            if (
                requestId in selectedOfferListForClientSelectionList &&
                ((!offer.supplier && offer.statusDetailed.name !== 'INTERNAL') ||
                    offer.statusDetailed.name === 'CANCELED')
            ) {
                delete selectedOfferListForClientSelectionList[requestId];
            }
        }

        function setTotalPriceForBudget(price) {
            totalPrice = price;
        }

        function setTotalPriceForBudgetByCurrency(priceByCurrency) {
            totalPriceByCurrency = priceByCurrency;
        }

        function setOriginalPrice(price) {
            originalPrice = price;
        }

        function setPriceDifferenceByCurrency(priceByCurrency) {
            priceDifferenceByCurrency = priceByCurrency;
        }

        function setShowDiffColumn(yesNo) {
            if (!showBudgetDiffColumn) {
                // if any one of offer has diff column then we should display diff values
                showBudgetDiffColumn = yesNo;
            }
        }

        function getSelectedOfferList() {
            return selectedOfferList;
        }

        function getSelectedOfferListForClientSelectionList() {
            return selectedOfferListForClientSelectionList;
        }

        function getSelectedOffer(requestId) {
            return selectedOfferList[requestId];
        }

        function getSelectedOfferForClientSelection(requestId) {
            return selectedOfferListForClientSelectionList[requestId];
        }

        function getTotalPriceForBudget() {
            return totalPrice;
        }

        function getTotalPriceForBudgetByCurrency() {
            return totalPriceByCurrency;
        }

        function getOriginalPrice() {
            return originalPrice;
        }

        function getPriceDifferenceByCurrency() {
            return priceDifferenceByCurrency;
        }

        function getShowBudgetDiffColumn() {
            return showBudgetDiffColumn;
        }

        function clearSelectedOfferList() {
            selectedOfferList = [];
        }

        function setSelectedPartIdForMarkup(selectedPartId) {
            selectedPartIdForMarkup = selectedPartId;
        }

        function setSelectedOfferIdForMarkup(selectedOfferId) {
            selectedOfferIdForMarkup = selectedOfferId;
        }

        function setSelectedOfferPartOption(offerPartOption) {
            const i = selectedOfferPartOption.findIndex((option) => option.id === offerPartOption.id);
            if (i > -1) {
                selectedOfferPartOption[i] = offerPartOption;
            } else {
                selectedOfferPartOption.push(offerPartOption);
            }
        }

        function getSelectedOfferPartOption() {
            return selectedOfferPartOption;
        }

        function setSelectedOfferPartOptionForClientSelection(offerPartOption) {
            const i = selectedOfferPartOptionForClientSelectionList.findIndex(
                (option) => option.id === offerPartOption.id
            );
            if (i > -1) {
                selectedOfferPartOptionForClientSelectionList[i] = offerPartOption;
            } else {
                selectedOfferPartOptionForClientSelectionList.push(offerPartOption);
            }
        }

        function getSelectedOfferPartOptionForClientSelection() {
            return selectedOfferPartOptionForClientSelectionList;
        }

        return {
            setSelectedOfferList: function (requestId, offer) {
                return setSelectedOfferList(requestId, offer);
            },
            setSelectedOfferListForClientSelection: function (requestId, offer) {
                return setSelectedOfferListForClientSelection(requestId, offer);
            },
            checkAndRemoveOfferFromSelectedOfferList: function (requestId, offer) {
                return checkAndRemoveOfferFromSelectedOfferList(requestId, offer);
            },
            checkAndRemoveOfferFromSelectedOfferListForClientSelection: function (requestId, offer) {
                return checkAndRemoveOfferFromSelectedOfferListForClientSelection(requestId, offer);
            },
            setTotalPriceForBudget: function (price) {
                return setTotalPriceForBudget(price);
            },
            setTotalPriceForBudgetByCurrency: function (priceByCurrency) {
                return setTotalPriceForBudgetByCurrency(priceByCurrency);
            },
            setOriginalPrice: function (price) {
                return setOriginalPrice(price);
            },
            setPriceDifferenceByCurrency: function (price) {
                return setPriceDifferenceByCurrency(price);
            },
            setShowDiffColumn: function (yesNo) {
                return setShowDiffColumn(yesNo);
            },
            getSelectedOffer: function (requestId) {
                return getSelectedOffer(requestId);
            },
            getSelectedOfferForClientSelection: function (requestId) {
                return getSelectedOfferForClientSelection(requestId);
            },
            getSelectedOfferList: function () {
                return getSelectedOfferList();
            },
            getSelectedOfferListForClientSelection: function () {
                return getSelectedOfferListForClientSelectionList();
            },
            getTotalPriceForBudget: function () {
                return getTotalPriceForBudget();
            },
            getTotalPriceForBudgetByCurrency: function () {
                return getTotalPriceForBudgetByCurrency();
            },
            getOriginalPrice: function () {
                return getOriginalPrice();
            },
            getPriceDifferenceByCurrency: function () {
                return getPriceDifferenceByCurrency();
            },
            getShowBudgetDiffColumn: function () {
                return getShowBudgetDiffColumn();
            },
            clearSelectedOfferList: function () {
                return clearSelectedOfferList();
            },
            getSelectedPartIdForMarkup: function () {
                return selectedPartIdForMarkup;
            },
            setSelectedPartIdForMarkup: function (selectedPartId) {
                return setSelectedPartIdForMarkup(selectedPartId);
            },
            getSelectedOfferIdForMarkup: function () {
                return selectedOfferIdForMarkup;
            },
            setSelectedOfferIdForMarkup: function (selectedPartId) {
                return setSelectedOfferIdForMarkup(selectedPartId);
            },
            setSelectedOfferPartOption: function (offerPartOption) {
                return setSelectedOfferPartOption(offerPartOption);
            },
            getSelectedOfferPartOption: function () {
                return getSelectedOfferPartOption();
            },
            setSelectedOfferPartOptionForClientSelection: function (offerPartOption) {
                return setSelectedOfferPartOptionForClientSelection(offerPartOption);
            },
            getSelectedOfferPartOptionForClientSelection: function () {
                return getSelectedOfferPartOptionForClientSelection();
            },
        };
    },
]);

