/**
 * Created by sampurlachhantyal on 9/1/17.
 */

import {ScopeFunctions} from "../../../../utils/global/scopeFunctions";
class ContactUsModal {
    constructor(close, $element, $sce, data, $rootScope, $timeout, RequestOffer, $scope, ClientService) {
        this._injected = { close, $element, $sce, data, $rootScope, $timeout, RequestOffer, $scope, ClientService };
        this.offerId = data.offerId;
        this.clientName = ClientService.getClientDisplayName();
        ScopeFunctions.addToScope($scope);
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }

    send() {
        const { RequestOffer, $scope } = this._injected;
        let params = { offerId: this.offerId, text: this.text, email: this.email, name: this.name };
        $scope.startProgress('contact-us');
        RequestOffer.supplierContactUs(
            this.offerId,
            params,
            (resp) => {
                $scope.endProgress('contact-us');
                this.close();
            },
            $scope.endWithErrorOverlay('contact-us')
        );
    }
}

ContactUsModal.$inject = [
    'close',
    '$element',
    '$sce',
    'data',
    '$rootScope',
    '$timeout',
    'RequestOffer',
    '$scope',
    'ClientService',
];
export default ContactUsModal;
