'use strict';

import { ScopeFunctions } from '../../../../../../utils/global/scopeFunctions';
import viewMailModal from '../../../../../modals/modals2.0/view-mail-content';

const LIST_OF_SEARCH_SORT_OPTIONS = [
    {
        name: 'firstName',
        name_en: 'First Name',
        name_sv: 'Förnamn',
        name_nb: 'Fornavn',
        name_da: 'Fornavn',
        name_fi: 'Etunimi',
    },
    {
        name: 'lastName',
        name_en: 'Last Name',
        name_sv: 'Efternamn',
        name_nb: 'Efternavn',
        name_da: 'Efternavn',
        name_fi: 'Sukunimi',
    },
    { name: 'subject', name_en: 'Subject', name_sv: 'Ämne', name_nb: 'Emne', name_da: 'Emne', name_fi: 'Aihe' },
    { name: 'email', name_en: 'Email', name_sv: 'E-post', name_nb: 'E-post', name_da: 'E-mail', name_fi: 'Sähköposti' },
];
// Register `participants.email.logs` component, along with its associated controller and template
angular.
    module('participants.email.logs').
    component('participantemaillogs', {
        templateUrl: './app/components/modules/event/event_detail/participants/email_logs/email_logs.view.html',
        controllerAs: 'participantsMailLogsCtrl',
        controller: [
            '$scope',
            '$rootScope',
            '$sce',
            '$stateParams',
            'Participant',
            'ModalService',
            'DateService',
            'TimezoneService',
            'ProcessLog',
            '$timeout',
            'CommonFunctions',
            function ParticipantsMailLogsController(
                $scope,
                $rootScope,
                $sce,
                $stateParams,
                Participant,
                ModalService,
                DateService,
                TimezoneService,
                ProcessLog,
                $timeout,
                CommonFunctions
            ) {
                var vm = this;
                vm.viewModal = false;
                vm.clearSearchField = clearSearchFieldFn;
                vm.listParticipantsMail = listParticipantsMailFn;
                vm.prepareSearchSortOptions = prepareSearchSortOptionsFn;
                vm.isParticipantsInvitationRunning = isParticipantsInvitationRunningFn;
                vm.deleteProcessLogOfInvitation = deleteProcessLogOfInvitationFn;
                vm.pageChanged = pageChangedFn;
                vm.formatDateTime = formatDateTimeFn;
                vm.searchOptionChange = searchOptionChangeFn;
                vm.sortOptionChange = sortOptionChangeFn;
                vm.sort = sortFn;
                vm.viewMail = viewMailFn;
                vm.currentPage = 1;
                vm.eventId = $stateParams.eventId;
                vm.languageSelected = $rootScope.langSelected;
                vm.pagination = {max: 100, offset: 0}; //fetch 100 mails on each page
                vm.sorting = {field: null, direction: 'desc'};
                vm.search = {text: null, status: null};

                ScopeFunctions.addToScope($scope);

                vm.prepareSearchSortOptions();
                vm.listParticipantsMail();

                $scope.$on('lang:change', function (event, data) {
                    vm.languageSelected = data.lang;
                    prepareSearchSortOptionsFn();
                    vm.sortingField = null;
                    vm.searchingField = null;
                    vm.ascendingOrder = false;
                    vm.descendingOrder = false;
                });

                function isParticipantsInvitationRunningFn(checkInvitationRunning = false) {
                    if (!vm.eventId) {
                        return;
                    }
                    ProcessLog.get(
                        { referenceId: vm.eventId, name: 'PARTICIPANT_INVITATION_EMAIL' },
                        (resp) => {
                            vm.participantInvitationProcess = resp;
                            vm.participantInvitationRunning = resp.status && resp.status.name === 'RUNNING_WITH_MESSAGE';

                            if (
                                resp.status &&
                                resp.status.name === 'RUNNING_WITH_MESSAGE' &&
                                vm.checkIsParticipantEmailSending &&
                                !checkInvitationRunning
                            ) {
                                let _error = angular.fromJson(resp.errorLog);
                                vm.participantsCount = CommonFunctions.errorMessages(_error, true)[0].params.p0;
                                vm.timeNeededToComplete = Math.ceil((0.35 / 60) * vm.participantsCount); // To send one email it takes approx 0.35 sec
                                $timeout(() => {
                                    isParticipantsInvitationRunningFn(true);
                                }, 10000);
                            } else if (resp.status && resp.status.name === 'GOT_ERROR') {
                                vm.checkIsParticipantEmailSending = false;
                                let _error = angular.fromJson(resp.errorLog);
                                vm.participantsInvitationErrors = CommonFunctions.errorMessages(_error, true);
                            } else if (resp.status && resp.status.name === 'SUCCESS_WITH_MESSAGE') {
                                let _error = angular.fromJson(resp.errorLog);
                                vm.checkIsParticipantEmailSending = false;
                                vm.participantsInvitationErrors = CommonFunctions.errorMessages(_error, true);
                                vm.unsubscribedParticipantsCount = vm.participantsInvitationErrors[0].params.p1;
                                vm.invalidParticipantsCount = vm.participantsInvitationErrors[0].params.p2;
                            }
                        },
                        (err) => {
                            vm.checkIsParticipantEmailSending = false;
                            console.log(err);
                        }
                    );
                }

                function deleteProcessLogOfInvitationFn() {
                    $scope.startProgress('participant-lists');
                    let processId = vm.participantInvitationProcess.id;
                    ProcessLog['delete'](
                        processId,
                        function () {
                            $scope.endProgress('participant-lists');
                            vm.participantInvitationProcess = null;
                            vm.participantInvitationRunning = null;
                            vm.participantsInvitationErrors = null;
                            isParticipantsInvitationRunningFn();
                        },
                        $scope.endWithErrorOverlay('participant-lists')
                    );
                }

                function listParticipantsMailFn() {
                    $scope.startProgress('participant-mail-lists');
                    vm.checkIsParticipantEmailSending = true;
                    let mailSearchParams = {
                        eventId: vm.eventId,
                        max: vm.pagination.max,
                        offset: vm.pagination.offset,
                        sortParams: { field: vm.sorting.field, direction: vm.sorting.direction },
                        /* searchParams: {
                             firsName: vm.firsName,
                             lastName: vm.firsName,
                             email: vm.email,
                             subject: vm.subject,
                             sentStatus: vm.sentStatus
                         },*/
                        searchParams: { text: vm.searchText, status: vm.search.status, field: vm.searchingField },
                    };
                    isParticipantsInvitationRunningFn();
                    Participant.emailsLoad(
                        mailSearchParams,
                        (res) => {
                            vm.mails = res.mails;
                            vm.counts = res.counts;
                            vm.pagination.totalCount = res.paginationParams.totalCount;
                            vm.pagination = res.paginationParams;
                            vm.lengthOfPagination = Math.ceil(res.paginationParams.totalCount / res.paginationParams.max);
                            $scope.endProgress('participant-mail-lists');
                        },
                        $scope.endWithErrorOverlay('participant-mail-lists')
                    );
                }

                function formatDateTimeFn(value) {
                    return TimezoneService.formatDate(new Date(parseInt(value)), getDateTimeDisplayFormatFn());
                }

                function getDateTimeDisplayFormatFn() {
                    let lang = $rootScope.langSelected;
                    if (lang === 'sv_SV' || lang === 'nb_NB' || lang === 'da_DK' || lang === 'fi_FI') {
                        return 'YYYY-MM-DD HH:mm';
                    } else {
                        return 'DD/MM/YYYY hh:mm a';
                    }
                }

                function clearSearchFieldFn() {
                    vm.searchText = null;
                    vm.searchingField = null;
                    listParticipantsMailFn();
                }

                function prepareSearchSortOptionsFn() {
                    vm.searchSortOptions = [];
                    switch (vm.languageSelected) {
                        case 'en_US':
                            angular.forEach(LIST_OF_SEARCH_SORT_OPTIONS, (option) => {
                                vm.searchSortOptions.push({
                                    name: option.name,
                                    value: option.name_en,
                                });
                            });
                            break;
                        case 'nb_NB':
                            angular.forEach(LIST_OF_SEARCH_SORT_OPTIONS, (option) => {
                                vm.searchSortOptions.push({
                                    name: option.name,
                                    value: option.name_nb,
                                });
                            });
                            break;
                        case 'sv_SV':
                            angular.forEach(LIST_OF_SEARCH_SORT_OPTIONS, (option) => {
                                vm.searchSortOptions.push({
                                    name: option.name,
                                    value: option.name_sv,
                                });
                            });
                            break;

                        case 'da_DK':
                            angular.forEach(LIST_OF_SEARCH_SORT_OPTIONS, (option) => {
                                vm.searchSortOptions.push({
                                    name: option.name,
                                    value: option.name_da,
                                });
                            });
                            break;
                        case 'fi_FI':
                            angular.forEach(LIST_OF_SEARCH_SORT_OPTIONS, (option) => {
                                vm.searchSortOptions.push({
                                    name: option.name,
                                    value: option.name_fi,
                                });
                            });
                            break;
                        default:
                            angular.forEach(LIST_OF_SEARCH_SORT_OPTIONS, (option) => {
                                vm.searchSortOptions.push({
                                    name: option.name,
                                    value: option.name_en,
                                });
                            });
                    }
                }

                function sortFn(field, direction) {
                    vm.sorting = { field: field, direction: direction };
                    if (direction === 'desc') {
                        vm.descendingOrder = true;
                        vm.ascendingOrder = false;
                    } else {
                        vm.descendingOrder = false;
                        vm.ascendingOrder = true;
                    }
                    listParticipantsMailFn();
                }

                function searchOptionChangeFn() {
                    if (vm.searchingField === undefined) {
                        vm.searchingField = vm.searchingOptions[0];
                    }
                }

                function sortOptionChangeFn() {
                    if (vm.sortingField === undefined) {
                        vm.sortingField = vm.sortingField[0];
                    }
                }

                function pageChangedFn() {
                    vm.pagination.offset = (vm.currentPage - 1) * vm.pagination.max;
                    listParticipantsMailFn();
                }

                function viewMailFn(mail) {
                    if(vm.viewModal) {
                        return;
                    }
                    vm.viewModal = true;
                    let data = {
                        mail: mail,
                        eventId: vm.eventId,
                        // successCallback : vm.listParticipantsMail
                    };

                    let viewMailContentModalData = viewMailModal;
                    viewMailContentModalData.inputs = { data };
                    ModalService.showModal(viewMailContentModalData).then((modal) => {
                        vm.viewModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }
            }
        ]
    });
