(function () {
    'use strict';

    angular.module('ogSupplierCheckboxGroup').directive('ogSupplierCheckboxGroup', function () {
        return {
            restrict: 'A',
            scope: {
                supplierId: '=',
                mutualSuppliersArray: '=',
            },
            link: function (scope, elem) {
                if (scope.mutualSuppliersArray.indexOf(scope.supplierId) !== -1) {
                    elem[0].checked = true;
                }

                elem.bind('click', function () {
                    var index = scope.mutualSuppliersArray.indexOf(scope.supplierId);
                    if (elem[0].checked) {
                        if (index === -1) {
                            scope.mutualSuppliersArray.push(scope.supplierId);
                        }
                    } else {
                        if (index !== -1) {
                            scope.mutualSuppliersArray.splice(index, 1);
                        }
                    }
                });
            },
        };
    });
})();
