import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";
(function () {
    'use strict';
    angular.module('ratings').component('ratings', {
        templateUrl: './app/components/modules/event/event_detail/rating/rating.view.html',
        controllerAs: 'ratingCtrl',
        controller: ['$scope', '$rootScope', '$http', '$state', 'Survey', '$stateParams',
            function RatingController($scope, $rootScope, $http, $state, Survey, $stateParams){
                ScopeFunctions.addToScope($scope);
                var vm = this;
                vm.init = initFn;
                vm.setActiveTab = setActiveTabFn;
                vm.activeTab = '';
                vm.eventId = $stateParams.eventId;
                vm.surveyCreated = false;
                vm.init();

                switch ($state.current.name) {
                    case 'event.detail.rating.creator':
                        vm.activeTab = 'creator';
                        break;
                    case 'event.detail.rating.participants':
                        vm.activeTab = 'participants';
                        break;
                }

                function initFn() {
                    $scope.startProgress('surveyList');
                    Survey.hasSurveyBeenCreated(
                        { eventId: vm.eventId },
                        function (response) {
                            vm.surveyCreated = response.surveyCreated;
                            $scope.endProgress('surveyList');
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function setActiveTabFn(tab) {
                    vm.activeTab = tab;
                }
            }]
    });

})();
