import OgPresentationProfileSettingsController from '../presentation_profile_settings/og_presentation_profile_settings.controller';

class OgRequestCategoryIconCtrl {
    constructor(CommonFile) {
        this._injected = { CommonFile };
        this.$onInit = function () {
            this.contentHeight = 56;
            if (this.height) {
                this.contentHeight = this.height;
            }
            this.iconHeight = this.contentHeight / 2;
        }
    }

    getImageUrl(fileId) {
        const { CommonFile } = this._injected;
        return CommonFile.getCommonFile(fileId);
    }
}

OgRequestCategoryIconCtrl.$inject = ['CommonFile'];
export default OgRequestCategoryIconCtrl;
