import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import _ from 'underscore';

class BadgePreview {
    constructor($element, $sce, data, close, $scope, Participant) {
        this._injected = { $element, $sce, data, close, $scope, Participant };
        this.eventId = data.eventId;
        ScopeFunctions.addToScope($scope);
        this.htmlTemplate = data.htmlTemplate;
        this.addedCustomFields = data.addedCustomFields;
        this.addedBasicFields = data.addedBasicFields;
        let queryForFullName = {};
        queryForFullName.eventParticipantBasicQuery = { id: 'name' };
        queryForFullName.id = 'fullName';
        queryForFullName.detailNameEnglish = 'Full Name';
        this.addedBasicFields.push(queryForFullName);
        this.listParticipants();
    }

    listParticipants() {
        const { Participant, $scope } = this._injected;
        $scope.startProgress();
        Participant.listAttending(
            this.eventId,
            (result) => {
                this.originalParticipantsList = result.participants;
                this.participants = angular.copy(this.originalParticipantsList);
                this.lastModifiedDate = result.lastModifiedDate;
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay
        );
    }

    getHtmlTemplate() {
        const { $sce } = this._injected;
        return $sce.trustAsHtml(this.htmlTemplate);
    }

    getHtmlWithValue(participant) {
        const { $sce } = this._injected;
        let $div = $(this.htmlTemplate);

        angular.forEach(this.addedBasicFields, (field) => {
            let _identifier = '.basic_replaceable_item_' + field.eventParticipantBasicQuery.id;
            if (field.id === 'fullName') {
                let _fieldValue = this.getFullName(participant);
                $div.find(_identifier).text(_fieldValue);
            } else {
                let _fieldValue = this.getValueFromInfoList(participant.participantBasicQueryInfoList, field);
                $div.find(_identifier).text(_fieldValue);
            }
        });

        angular.forEach(this.addedCustomFields, (field) => {
            let _identifier = '.custom_replaceable_item_' + field.id;
            let _fieldValue = this.getValueFromCustomInfoList(participant.participantAdditionalQueryInfoList, field);
            $div.find(_identifier).text(_fieldValue);
        });

        return $sce.trustAsHtml($div.prop('outerHTML'));
    }

    getValueFromInfoList(queryInfoList, query) {
        let _queryId = query.eventParticipantBasicQuery.id;
        let info = queryInfoList.filter(function (t) {
            return t.eventParticipantBasicQuery.id === _queryId;
        });
        if (info.length > 0) {
            let _value = info[0].fieldValue;
            return _value ? _value : '-';
        }
        return '-';
    }

    getFullName(participant) {
        var fname = '',
            lname = '',
            name = '';
        angular.forEach(participant.participantBasicQueryInfoList, function (basicInfo) {
            if (basicInfo.detailNameEnglish == 'First Name') {
                if (basicInfo.fieldValue) {
                    fname = basicInfo.fieldValue;
                } else {
                    fname = '';
                }
            }
            if (basicInfo.detailNameEnglish == 'Last Name') {
                if (basicInfo.fieldValue) {
                    lname = basicInfo.fieldValue;
                } else {
                    lname = '';
                }
            }
        });
        name = fname;
        if (name) {
            name += ' ';
        }
        name += lname;
        return name;
    }

    getValueFromCustomInfoList(queryInfoList, query) {
        let _queryId = query.id;
        let info = _.findWhere(queryInfoList, { eventParticipantCustomQueryId: _queryId });
        if (info && info.fieldValue) {
            return info.fieldValue;
        }
        return '-';
    }

    printDiv() {
        jQuery('#printDiv').printThis({
            importCSS: false,
            importStyle: false,
            loadCSS: '/app/print-css/schedule-print.css',
            printDelay: '3000',
        });
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}
BadgePreview.$inject = ['$element', '$sce', 'data', 'close', '$scope', 'Participant'];
export default BadgePreview;
