/**
 * Created by dell on 5/17/2016.
 */
'use strict';
import {OgModal} from "../../utils/global/ogModal";

angular.module('dialog').directive('ogOfferDetailMessageModal', [
    'RequestOffer',
    'Request',
    function (RequestOffer, Request) {
        return new OgModal({
            name: 'offer:detail:message',
            type: 'custom',
            controller: function ($scope) {
                $scope.initDialog = function (params) {
                    if (params) {
                        $scope.params = params;
                            $scope.type = params.type;

                            RequestOffer.requestOffersForMessageByEventId(
                                params.eventId,
                                function (response) {
                                    $scope.offers = response;
                                },
                                $scope.errorCallback
                            );

                            Request.requestsForMessageByEventId(
                                params.eventId,
                                function (response) {
                                    $scope.requests = response;
                                },
                                $scope.errorCallback
                            );
                        }
                    };
                },
            });
        },
    ]);

