import profileSettingsModal from '../../modals/modals2.0/profile-settings-modal';
import { ScopeFunctions } from "../../../utils/global/scopeFunctions";
import _ from 'underscore';

let INITIAL_USER_GROUP_SIZE = 10;
let USER_GROUP_SEARCH_SIZE = 10;

(function () {
    'use strict';

    angular.module('ogUserGroupSelect').directive('ogUserGroupSelect', [
        'AuthenticationService',
        'EventEnum',
        '$translate',
        'eaTreeViewFactory',
        'ModalService',
        'UserGroup',
        function (AuthenticationService, EventEnum, $translate, eaTreeViewFactory, ModalService, UserGroup) {
            return {
                templateUrl: './app/components/directives/user_group_select/user_group_select.directive.view.html',
                restrict: 'EA',
                scope: true,
                controller: [
                    '$scope',
                    function ($scope) {
                        var self = this;
                        $scope.datasetId = 'my-group';

                        $scope.selectedContextId = null;
                        $scope.showLoadMore = false;
                        $scope.searchParam = {
                            max: 10,
                            offset: 0,
                        };
                        $scope.viewprofileSettingsModal = false;
                        ScopeFunctions.addToScope($scope);

                        self.refreshUserData = function () {
                            $scope.userData = AuthenticationService.getUserData();
                            if ($scope.userData.logged) {
                                $scope.userGroups = initUserGroups(AuthenticationService.getUserGroups());
                                $scope.totalUGResult = $scope.userData.totalUserGroupsCount;
                            }
                            if ($scope.userData && $scope.userData.userGroup) {
                                $scope.selectedContextId = $scope.userData.userGroup.id;
                            } else {
                                $scope.selectedContextId = null;
                            }
                            $scope.showLoadMore = $scope.totalUGResult > INITIAL_USER_GROUP_SIZE;
                        };

                        $scope.$on(EventEnum.USER_DATA_CHANGED, self.refreshUserData);
                        $scope.$on(EventEnum.USER_GROUP_CHANGED_SILENT, self.refreshUserData);

                        $scope.selectedContextChanged = function (item) {
                            let _userGroupId = item.id;
                            AuthenticationService.switchUserGroup(_userGroupId);
                        };

                        $scope.isUserGroupSelected = function (item) {
                            if ($scope.userData.userGroup && item.id === $scope.userData.userGroup.id) {
                                return true;
                            } else {
                                return false;
                            }
                        };

                        $scope.openEditProfileModal = function () {
                            jQuery('#user-group-drop-down').removeClass('open');
                            if($scope.viewprofileSettingsModal){
                                return;
                            }
                            $scope.viewprofileSettingsModal = true;
                            let data = { selectedGroupId: $scope.selectedContextId };
                            let profileSettingsModalData = profileSettingsModal;
                            profileSettingsModalData.inputs = { data };
                            ModalService.showModal(profileSettingsModalData).then((modal) => {
                                $scope.viewprofileSettingsModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        };

                        function initUserGroups(userGroups) {
                            if (userGroups) {
                                let _userGroupsWithoutPrivateGroup = _.filter(userGroups, function (item) {
                                    return item && item.name;
                                });
                                // todo need to optimize: merge sorting criteria
                                _userGroupsWithoutPrivateGroup = _.sortBy(_userGroupsWithoutPrivateGroup, function (o) {
                                    return o.id;
                                });
                                _userGroupsWithoutPrivateGroup = _.sortBy(_userGroupsWithoutPrivateGroup, function (o) {
                                    return o.name;
                                });

                                let _privateGroup = _.find(userGroups, function (g) {
                                    return !g.name;
                                });
                                if (_privateGroup) {
                                    _privateGroup.name = $translate.instant('user.group.events.shared.with.me');
                                    _userGroupsWithoutPrivateGroup.splice(0, 0, _privateGroup);
                                }
                                let _groups = _userGroupsWithoutPrivateGroup;
                                let _activeUserGroup = _.find(userGroups, function (g) {
                                    return g.expanded;
                                });
                                if (_activeUserGroup) {
                                    _activeUserGroup.expanded = false;
                                }
                                return _groups;
                            }
                            return [];
                        }

                        $scope.toggleUserGroupExpanded = function (item) {
                            item.expanded = !item.expanded;
                            item.showLoadMoreChild = false;
                            item.childrenUserGroups = [];
                            if (item.expanded) {
                                let params = {
                                    groupId: item.id,
                                    offset: 0,
                                    max: USER_GROUP_SEARCH_SIZE,
                                };
                                UserGroup.getChildren(params, (response) => {
                                    item.childrenUserGroups = response.childGroupList;
                                    item.showLoadMoreChild = response.childGroupTotal > item.childrenUserGroups.length;
                                });
                            }
                        };

                        $scope.expandUsergroup = function () {
                            $scope.userGroupSearch = '';
                            var isCollapsed = $('#user-group-drop-down').hasClass('open');
                            if (!isCollapsed) {
                                this.searchUserGroups();
                            }
                        };

                        $scope.searchUserGroups = () => {
                            let params = {
                                userId: $scope.userData.id,
                                searchText: $scope.userGroupSearch,
                                offset: 0,
                                max: USER_GROUP_SEARCH_SIZE,
                            };
                            $scope.startProgress();
                            // if($scope.userGroupSearch){
                            UserGroup.searchUserGroups(
                                params,
                                (response) => {
                                    $scope.searchParam.max = response.max;
                                    $scope.searchParam.offset = response.offset;
                                    $scope.totalUGResult = response.refUserGroupTotal;
                                    $scope.userGroups = initUserGroups(response.refUserGroupList);
                                    $scope.showLoadMore = $scope.totalUGResult > $scope.userGroups.length;
                                    $scope.endProgress();
                                },
                                $scope.endWithError
                            );
                            // }
                        };

                        $scope.loadMore = () => {
                            let offset = $scope.userGroups.length ? $scope.userGroups.length : 0;
                            if (offset >= $scope.totalUGResult) {
                                return;
                            }

                            let params = {
                                userId: $scope.userData.id,
                                searchText: $scope.userGroupSearch,
                                offset: offset,
                                max: USER_GROUP_SEARCH_SIZE,
                            };

                            UserGroup.searchUserGroups(params, (response) => {
                                $scope.searchParam.max = response.max;
                                $scope.searchParam.offset = response.offset;
                                let _moreGroups = initUserGroups(response.refUserGroupList);
                                $scope.userGroups = [...$scope.userGroups, ..._moreGroups];
                                $scope.showLoadMore = $scope.totalUGResult > $scope.userGroups.length;
                            });
                        };

                        $scope.loadMoreChildren = (group) => {
                            let offset = group.childrenUserGroups.length ? group.childrenUserGroups.length : 0;
                            let params = {
                                groupId: group.id,
                                offset: offset,
                                max: USER_GROUP_SEARCH_SIZE,
                            };
                            UserGroup.getChildren(params, (response) => {
                                angular.forEach(response.childGroupList, (userGroup) => {
                                    group.childrenUserGroups.push(userGroup);
                                });
                                group.showLoadMoreChild = response.childGroupTotal > group.childrenUserGroups.length;
                            });
                        };

                        // $scope.searchUserGroup = function (item) {
                        //     if($scope.userGroupSearch == undefined) {
                        //         return true;
                        //     }
                        //     else {
                        //         if(item.name.toLowerCase().indexOf($scope.userGroupSearch.toLowerCase()) != -1) {
                        //             item.expanded = true;
                        //             return true;
                        //         }
                        //         else {
                        //             item.expanded = false;
                        //             return false;
                        //         }
                        //     }
                        // }

                        $scope.$watch(
                            'myGroups.currentNode',
                            function (newObj, oldObj) {
                                if ($scope.myGroups && angular.isObject($scope.myGroups.currentNode)) {
                                    $scope.selectedContextChanged($scope.myGroups.currentNode.id);
                                }
                            },
                            false
                        );

                        $(document).on('click', '#user-group-drop-down .dropdown-menu', function (e) {
                            e.stopPropagation();
                        });

                        self.refreshUserData();
                    },
                ],
            };
        },
    ]);
})();
