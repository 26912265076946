'use strict';
angular.module('core.existingServices').factory('GlobalOption', [
    '$resource',
    function ($resource) {
        var allOptions = $resource(
            'api/v1/globaloption',
            {},
            {
                list: {method: 'GET', isArray: false},
            }
        );

        var v1SelectedRest = $resource(
            'api/v1/globaloption/get/:type',
            {type: '@type'},
            {
                create: {method: 'POST', isArray: false},
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            allOptions: function (successCallback, errorCallback) {
                return allOptions.get({}, successCallback, errorCallback);
            },
            get: function (key, successCallback, errorCallback) {
                return v1SelectedRest.get({type: key}, successCallback, errorCallback);
            },
        };
    },
]);

