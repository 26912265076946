import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";
(function () {
    'use strict';
    angular.module('event-details').component('eventDetails', {
        templateUrl: './app/components/modules/participant/participant_details/event_details/event_details.view.html',
        bindings: {
            personalizedPageDetails: '<'
        },
        controllerAs: 'eventDetailsCtrl',
        controller: [
            '$scope',
            '$stateParams',
            'EventPresentationRo',
            'ActiveTabService',
            '$state',
            function EventDetailsController(
                $scope,
                $stateParams,
                EventPresentationRo,
                ActiveTabService,
                $state
            ) {
                var vm = this;
                vm.hash = $stateParams.hash;
                vm.uploadedFiles = [];
                vm.data = {};

                ScopeFunctions.addToScope($scope);
                vm.$onInit = () => {
                    initFn();
                }

                function initFn() {
                    $scope.startProgress();
                    EventPresentationRo.get(
                        { hash: $stateParams.hash, requestFrom: 'infoSite' },
                        function (response) {
                            vm.data = response.eventPresentationDetails;
                            vm.data.slide =
                                response.eventPresentationDetails.slide || response.eventPresentationDetails.slides[0];
                            vm.invitationDetails = response.invitationDetails;
                            vm.fromPublicSite = vm.personalizedPageDetails.fromPublicSite;
                            vm.participantStatus = response.participantStatus;
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                vm.goForRegistrationTab = function () {
                    ActiveTabService.setParticipantsTab('registration');
                    $state.go('participant_details.registration');
                };
            }
        ]
    });
})();
