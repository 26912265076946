(function () {
    'use strict';

    angular.module('userGroupTree').directive('userGroupTree', function () {
        return {
            restrict: 'E',
            replace: true,
            scope: {
                collection: '=',
                padding: '=?',
                viewCallBack: '=',
                deleteCallBack: '=',
            },
            template:
                "<ul><user-group-member padding-prefix='paddingPrefix' ng-repeat='member in collection'" +
                " member='member' view-call-back='viewCallBack'" +
                "delete-call-back='deleteCallBack'></user-group-member></ul>",
            controller: [
                '$scope',
                function ($scope) {
                    $scope.padding = $scope.padding ? $scope.padding : 0;
                    $scope.paddingPrefix = $scope.padding + 10;
                },
            ],
        };
    });
})();
