import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('ogSupplierResultMap').directive('ogSupplierResultMap', [
        '$timeout',
        '$compile',
        '$rootScope',
        'Supplier',
        'GlobalConstants',
        '$templateCache',
        'SupplierDetailService',
        'ClientService',
        'CommonFile',
        'CommonFunctions',
        function (
            $timeout,
            $compile,
            $rootScope,
            Supplier,
            GlobalConstants,
            $templateCache,
            SupplierDetailService,
            ClientService,
            CommonFile,
            CommonFunctions
        ) {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    watchFor: '@',
                    doRender: '=ogMapInView',
                    addSupplierCallBack: '&',
                    addedSupplierList: '=',
                    declinedOffers: '=',
                    canceledOffers: '=',
                    showSupplierDetailsCallback: '=',
                    zoom: '@',
                    disableControl: '@',
                },
                link: function (scope, element) {
                    var suppliers = [];

                    scope.$watch('doRender', function (val) {
                        window.googleMapsCallback(function () {
                            if (val) {
                                var canvas = element[0];
                                scope.createMap(canvas);
                                if (suppliers.length > 0) {
                                    drawSuppliers(suppliers);
                                }
                            } else {
                                element.empty();
                                element.removeAttr('style');
                                scope.removeMap();
                            }
                        });
                    });

                    $('#og-select-supplier-modal').on('shown.bs.modal', function () {
                        window.googleMapsCallback(function () {
                            var canvas = element.context;
                            scope.createMap(canvas);
                            if (suppliers.length > 0) {
                                drawSuppliers(suppliers);
                            }
                        });
                    });

                    scope.$on(scope.watchFor, function (event, searchParams, value) {
                        suppliers = value;
                        scope.setSearchParams(searchParams);
                        scope.setCategoryIds(searchParams.categoryIds);
                        scope.setEventId(searchParams.eventId);
                        if (scope.doRender) {
                            window.googleMapsCallback(function () {
                                drawSuppliers(value);
                            });
                        }
                    });

                    function drawSuppliers(suppliers) {
                        if (suppliers) {
                            scope.clearMarkers();
                            scope.renderSuppliers(suppliers);
                        } else {
                            element.empty();
                            element.removeAttr('style');
                            scope.removeMap();
                        }
                    }
                },
                controller: [
                    '$scope',
                    '$rootScope',
                    'CommonFunctions',
                    function ($scope, $rootScope, CommonFunctions) {
                        ScopeFunctions.addToScope($scope);

                        var map, overlappingMarkerSpiderfier, infoWindow, categoriesIds, eventId;
                        var alreadyMarkedSuppliers = [];
                        var markers = [];
                        var alreadyMarkedSuppliersServiceArea = [];
                        var _searchParams = {};

                        $scope.item = null;

                        $scope.removeMap = function () {
                            map = undefined;
                            alreadyMarkedSuppliers = [];
                            alreadyMarkedSuppliersServiceArea = [];
                            $scope.clearMarkers();
                        };

                        $scope.createMap = function (canvas) {
                            var zoom;
                            if ($scope.zoom === null) {
                                zoom = GlobalConstants.DEFAULT_MAP_ZOOM;
                            } else {
                                zoom = parseInt($scope.zoom);
                            }
                            var enableControl = !$scope.disableControl;
                            var mapOptions = {
                                zoom: zoom,
                                disableDefaultUI: enableControl,
                                zoomControl: enableControl,
                                scaleControl: enableControl,
                                streetViewControl: enableControl,
                                mapTypeControl: enableControl,
                                scrollwheel: enableControl,
                                navigationControl: enableControl,
                                draggable: enableControl,
                                disableDoubleClickZoom: !enableControl,
                                mapTypeId: google.maps.MapTypeId.ROADMAP,
                            };
                            if (!map) {
                                map = new google.maps.Map(canvas);
                            }
                            map.setOptions(mapOptions);
                            if (!overlappingMarkerSpiderfier) {
                                overlappingMarkerSpiderfier = new OverlappingMarkerSpiderfier(map, {
                                    markersWontMove: true,
                                    markersWontHide: true,
                                    basicFormatEvents: true,
                                });
                            }

                            onZoomFn(map);
                            onDragFn(map);
                        };

                        if (CommonFunctions.isMapAlreadyLoaded()) {
                            google.maps.event.addDomListener(window, 'resize', function () {
                                if (map) {
                                    var center = map.getCenter();
                                    google.maps.event.trigger(map, 'resize');
                                    map.setCenter(center);
                                }
                            });
                        }

                        function onDragFn(googleMap) {
                            google.maps.event.addListener(googleMap, 'dragend', function (event) {
                                var categoryIds = categoriesIds;
                                var center = googleMap.getCenter();
                                var lat = center.lat();
                                var lng = center.lng();
                                searchSupplier(lat, lng, categoryIds);
                                //if there is infoWindow opening needs to close it to display search buttons
                                closeInfoWindowFn();
                            });
                        }

                        function onZoomFn(googleMap) {
                            google.maps.event.addListenerOnce(googleMap, 'tilesloaded', function () {
                                google.maps.event.addListener(googleMap, 'zoom_changed', function (event) {
                                    var zoomSize = googleMap.getZoom();
                                    var categoryIds = categoriesIds;
                                    var center = googleMap.getCenter();
                                    var lat = center.lat();
                                    var lng = center.lng();
                                    if (!_searchParams.supplierSearchFromMap) {
                                        searchSupplier(lat, lng, categoryIds, zoomSize);
                                        closeInfoWindowFn();
                                    }
                                });
                            });
                        }

                        $scope.calculateDistance = function (lat1, lon1, lat2, lon2) {
                            if (lat1 === lat2 && lon1 === lon2) {
                                return 0;
                            } else {
                                let theta = lon1 - lon2;
                                let dist =
                                    Math.sin((lat1 / 180.0) * 3.14159265358979323846) *
                                    Math.sin((lat2 / 180.0) * 3.14159265358979323846) +
                                    Math.cos((lat1 / 180.0) * 3.14159265358979323846) *
                                    Math.cos((lat2 / 180.0) * 3.14159265358979323846) *
                                    Math.cos((theta / 180.0) * 3.14159265358979323846);
                                dist = Math.acos(dist);
                                dist = (dist * 180.0) / 3.14159265358979323846;
                                dist = dist * 60 * 1.1515;
                                dist = dist * 1609.344; // convert to meters
                                return dist;
                            }
                        };

                        $scope.getZoomForMetersWide = function (distance, latitude) {
                            let latitudinalAdjustment = Math.cos((Math.PI * latitude) / 180);
                            let arg = (40075000 * 1000 * latitudinalAdjustment) / (distance * 256);
                            return parseInt(Math.log(arg) / Math.log(2));
                        };

                        function addMarker(supplier, latLng, serviceAreaAddress = null) {
                            if (!alreadyMarkedSuppliers.some((item) => item.id === supplier.id)) {
                                alreadyMarkedSuppliers.push(supplier);
                                var marker = new google.maps.Marker({ position: latLng, map: map });
                                $rootScope.markers.push(marker);
                                overlappingMarkerSpiderfier.addMarker(marker);

                                var supplierObject = supplier;

                                google.maps.event.addListener(marker, 'spider_click', function () {
                                    $timeout(function () {
                                        $scope.item = supplierObject;
                                        $scope.startProgress();
                                        Supplier.getSupplierFromES(
                                            {
                                                id: supplierObject.id,
                                                eventId: eventId,
                                            },
                                            (supplier) => {
                                                $scope.selectedSupplier = supplier;
                                                $scope.getSlidingImages();
                                                $scope.showDetailInfoWindow(marker);
                                                $scope.endProgress();
                                            },
                                            $scope.endWithErrorOverlay()
                                        );
                                    });
                                });
                                // return marker

                                overlappingMarkerSpiderfier.addListener('spiderfy', function (markers) { });
                                overlappingMarkerSpiderfier.addListener('unspiderfy', function (markers) { });
                            }
                        }

                        $scope.setCategoryIds = function (category) {
                            categoriesIds = category;
                        };

                        $scope.setEventId = function (id) {
                            eventId = id;
                        };

                        $scope.setSearchParams = function (searchParams) {
                            _searchParams = searchParams;
                        };

                        $scope.renderSuppliers = function (suppliers) {
                            var bounds = new google.maps.LatLngBounds();
                            if (suppliers.length > 0) {
                                angular.forEach(suppliers, function (supplier) {
                                    if (supplier) {
                                        var latitude = supplier.latitude;
                                        var longitude = supplier.longitude;
                                        if (latitude && longitude) {
                                            var latLng = new google.maps.LatLng(latitude, longitude);
                                            bounds.extend(latLng);
                                            addMarker(supplier, latLng);
                                        }
                                        //Task Id: https://app.asana.com/0/1201292221257935/1201442446582079
                                        /*    if (supplier.serviceAreaAddresses && supplier.serviceAreaAddresses.length > 0) {
                                            angular.forEach(supplier.serviceAreaAddresses, serviceAreaAddress => {
                                                let _latitude = serviceAreaAddress.latitude;
                                                let _longitude = serviceAreaAddress.longitude;
                                                /!*If service area latlng is near to supplier latlng do not add new marker. Issue occurs with cluster*!/
                                                if (_latitude && _longitude && Math.abs(latitude - _latitude) > 0.1 && Math.abs(longitude - _longitude) > 0.1) {
                                                    var latLng = new google.maps.LatLng(_latitude, _longitude);
                                                    bounds.extend(latLng);
                                                    !_.some(markers, (m)=>m.getPosition().lat() === latLng.lat() && m.getPosition().lng() === latLng.lng()) ?  markers.push(addMarker(supplier, latLng, serviceAreaAddress)) :addMarker(supplier, latLng, serviceAreaAddress);
                                                }
                                            })
                                        }*/
                                    }
                                });
                            } else {
                                var geocoder = new google.maps.Geocoder();
                                var address = '';
                                if (_searchParams.officeAddress.displayAddress) {
                                    address = _searchParams.officeAddress.displayAddress;
                                } else {
                                    address = 'Göteborg';
                                }
                                geocoder.geocode({ address: address }, function (results, status) {
                                    if (status === google.maps.GeocoderStatus.OK) {
                                        var latitudeLongitude = results[0].geometry.location
                                            .toString()
                                            .replace(/[\(\)]/g, '')
                                            .split(',');
                                        var latitude = latitudeLongitude[0];
                                        var longitude = latitudeLongitude[1];
                                        let _bounds = results[0].geometry.bounds;
                                        if (!_bounds) {
                                            _bounds = results[0].geometry.viewport;
                                        }
                                        let _minLat = _bounds.getSouthWest().lat();
                                        let _minLng = _bounds.getSouthWest().lng();
                                        let _maxLat = _bounds.getNorthEast().lat();
                                        let _maxLng = _bounds.getNorthEast().lng();
                                        let _distance = $scope.calculateDistance(_minLat, _minLng, _maxLat, _maxLng);
                                        let _zoom = $scope.getZoomForMetersWide(_distance, _minLat);
                                        map.setZoom(_zoom);
                                        var latLng = new google.maps.LatLng(latitude, longitude);
                                        bounds.extend(latLng);
                                        map.panTo(latLng);
                                    }
                                });
                            }
                            google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
                                if (this.getZoom() > 15) {
                                    this.setZoom(15);
                                }
                            });
                            map.setCenter(bounds.getCenter());
                            map.fitBounds(bounds);
                        };

                        function searchSupplier(lat, lng, categoryIds, zoomSize) {
                            var params = {
                                clientId: ClientService.get().id,
                                previewAll: true,
                                categoryIds: categoryIds,
                                text: _searchParams.text,
                                supplierDetailArray: _searchParams.supplierDetailArray,
                                supplierDetails: _searchParams.supplierDetails,
                                eventId: eventId,
                                isThereAccomAndConf: _searchParams.isThereAccomAndConf,
                                isThereConferenceOnly: _searchParams.isThereConferenceOnly,
                            };
                            if (lat !== 0 || lng !== 180) {
                                params['lat'] = lat;
                                params['lng'] = lng;
                                params['zoomSize'] = zoomSize;
                            }
                            // To optimize supplier search on map only when searching not during mapZoom or mapDrag
                            if (_searchParams.supplierSearchFromMap) {
                                params['officeAddress'] = _searchParams.officeAddress;
                                _searchParams.supplierSearchFromMap = false;
                            }
                            $scope.startProgress();
                            Supplier.listSuppliersLocation(
                                params,
                                (resp) => {
                                    angular.forEach(resp.locations, function (supplier) {
                                        if (supplier) {
                                            var latitude = supplier.latitude;
                                            var longitude = supplier.longitude;
                                            if (longitude && latitude) {
                                                var latLng = new google.maps.LatLng(latitude, longitude);
                                                addMarker(supplier, latLng);
                                            }
                                            /* if (supplier.serviceAreaAddresses && supplier.serviceAreaAddresses.length > 0) {
                                            angular.forEach(supplier.serviceAreaAddresses, serviceAreaAddress => {
                                                let _latitude = serviceAreaAddress.latitude;
                                                let _longitude = serviceAreaAddress.longitude;
                                                /!*If service area latlng is near to supplier latlng do not add new marker. Issue occurs with cluster*!/
                                                if (_latitude && _longitude && Math.abs( latitude - _latitude) > 0.1 && Math.abs( longitude - _longitude) > 0.1) {
                                                    var latLng = new google.maps.LatLng(_latitude, _longitude);
                                                    addMarker(supplier, latLng, serviceAreaAddress);
                                                }
                                            })
                                        }*/
                                        }
                                    });
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay()
                            );
                        }

                        $scope.showInfoWindow = function (marker, supplierObject) {
                            SupplierDetailService.setDisplaySearchFilterInMap(true);
                            // var content = supplierObject.name;
                            // content = '<div class="info-window-content"><strong>' + content + '</strong></div>';

                            if (!infoWindow) {
                                infoWindow = new google.maps.InfoWindow({
                                    // content: content,
                                });

                                google.maps.event.addListener(infoWindow, 'domready', function () {
                                    $timeout(function () {
                                        $compile($('.info-window-content')[0])($scope);
                                    });
                                });
                            } else {
                                infoWindow.close();
                                // infoWindow.setContent(content);
                            }
                            // infoWindow.open(map, marker);
                        };

                        $scope.showDetailInfoWindow = function (marker) {
                            SupplierDetailService.setDisplaySearchFilterInMap(false);
                            var html = $templateCache.get(
                                'supplier_detail_map.html'
                            );
                            var compiled = $compile(html)($scope);
                            if (!infoWindow) {
                                infoWindow = new google.maps.InfoWindow();
                                infoWindow.setContent(compiled[0]);
                            } else {
                                infoWindow.close();
                                infoWindow.setContent(compiled[0]);
                            }

                            google.maps.event.addListener(infoWindow, 'closeclick', function () {
                                $timeout(function () {
                                    SupplierDetailService.setDisplaySearchFilterInMap(true);
                                });
                            });

                            infoWindow.open(map, marker);
                        };

                        $scope.clearMarkers = function () {
                            alreadyMarkedSuppliers = [];
                            alreadyMarkedSuppliersServiceArea = [];
                            if ($rootScope.markers && $rootScope.markers.length > 0) {
                                for (let i = 0; i < $rootScope.markers.length; i++) {
                                    $rootScope.markers[i].setMap(null);
                                }
                            }
                            $rootScope.markers = [];
                        };

                        $scope.isSupplierInDeclineState = function () {
                            if ($scope.declinedOffers) {
                                var obj = _.find($scope.declinedOffers, (offer) => {
                                    if (!!offer.supplier) {
                                        return parseInt(offer.supplier.id) === parseInt($scope.selectedSupplier.id);
                                    }
                                });
                                return !!obj;
                            }
                            return false;
                        };

                        $scope.isSupplierInCancelState = function () {
                            if ($scope.canceledOffers) {
                                var obj = _.find($scope.canceledOffers, (offer) => {
                                    if (!!offer.supplier) {
                                        return parseInt(offer.supplier.id) === parseInt($scope.selectedSupplier.id);
                                    }
                                });
                                return !!obj;
                            }
                            return false;
                        };

                        $scope.viewPresentation = function () {
                            $scope.showSupplierDetailsCallback($scope.selectedSupplier);
                        };

                        $scope.isSupplierAlreadyAdded = function () {
                            if (_.findIndex($scope.addedSupplierList, { id: $scope.selectedSupplier.id }) > -1) {
                                return true;
                            }
                        };

                        function closeInfoWindowFn() {
                            if (!infoWindow) {
                                infoWindow = new google.maps.InfoWindow();
                                google.maps.event.addListener(infoWindow, 'domready', function () {
                                    $timeout(function () {
                                        $compile($('.info-window-content')[0])($scope);
                                    });
                                });
                            }
                            infoWindow.close();
                            SupplierDetailService.setDisplaySearchFilterInMap(true);
                        }

                        $scope.getSlidingImages = function () {
                            $scope.slidingList = [];
                            if ($scope.selectedSupplier && $scope.selectedSupplier.presentation) {
                                let presentationImages = $scope.selectedSupplier.presentation.presentationImages;
                                if (presentationImages.length > 0) {
                                    for (let k in presentationImages) {
                                        if (presentationImages.hasOwnProperty(k)) {
                                            $scope.slidingList.push({ image: presentationImages[k].fileStoreId });
                                        }
                                    }
                                }
                            }
                        };

                        $scope.getImageUrl = function (fileId) {
                            return CommonFile.getCommonFile(fileId);
                        };
                    },
                ],
            };
        },
    ]);
})();
