import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('ogParticipantRegistrationForm').directive('ogParticipantRegistrationForm', [
        function () {
            return {
                scope: {
                    participantData: '=participantData',
                    formPreview: '=formPreview',
                    /**
                     * if disableReadOnly set true,
                     * set the field as readonly (disabled) having isReadOnly true
                     */
                    disableReadOnly: '=disableReadOnly',
                },
                controllerAs: 'registrationFormCtrl',
                bindToController: true,
                templateUrl:
                    './app/components/directives/participant_registration_form/og_participant_registration_form.view.html',
                controller: [
                    '$scope',
                    '$translate',
                    '$rootScope',
                    'ParticipantService',
                    'Event',
                    'ParticipantUtilService',
                    function ($scope, $translate, $rootScope, ParticipantService, Event, ParticipantUtilService) {
                        var vm = this;
                        vm.data = {};
                        vm.form = 'confirmationForm';
                        vm.langSelected = $rootScope.langSelected;

                        vm.show = showQuestionFn;
                        vm.resetRequiredField = resetRequiredFieldFn;
                        vm.formatQueries = formatQueriesFn;
                        vm.getInformation = getInformationFn;
                        vm.getLabel = getLabelFn;
                        vm.getQuestionLabel = getQuestionLabelFn;
                        vm.validatePhoneNumber = validatePhoneNumberFn;
                        ScopeFunctions.addToScope($scope);

                        function initFn() {
                            if (vm.participantData) {
                                setParticipantDataFn(vm.participantData);
                                concatBothTypeOfQueriesFn();
                            }
                        }

                        /*function getOptionValuesFn (options, lang) {
            if (options && options.indexOf("~") >= 0) {
                let _options = options.split("~");
                let _englishOptions = _options ? _options[0] : null;
                let _swedishOptions = _options.length > 1 ? _options[1] : null;
                let _norwegianOptions = _options.length > 2 ? _options[2] : null;
                let _danishOptions = _options.length > 3 ? _options[3] : null;
                let _finnishOptions = _options.length > 4 ? _options[4] : null;
                if(lang === 'en_US'){
                    return _englishOptions ? _englishOptions : _swedishOptions ? _swedishOptions : _norwegianOptions ? _norwegianOptions : _danishOptions;
                }
                if(lang === 'sv_SV'){
                    return _swedishOptions ? _swedishOptions : _englishOptions ? _englishOptions : _norwegianOptions ? _norwegianOptions : _danishOptions;
                }
                if(lang === 'nb_NB'){
                    return _norwegianOptions ? _norwegianOptions : _englishOptions ? _englishOptions : _swedishOptions ? _swedishOptions : _danishOptions;
                }
                if(lang === 'da_DK'){
                    return _danishOptions ? _danishOptions : _englishOptions ? _englishOptions : _swedishOptions ? _swedishOptions : _norwegianOptions;
                }
                if(lang === 'fi_FI'){
                    return _finnishOptions ? _finnishOptions : _englishOptions ? _englishOptions : _swedishOptions ? _swedishOptions : _norwegianOptions;
                }
            } else {
                return options;
                }
          };*/

                        function resetRequiredFieldFn(value, fieldName) {
                            if ((fieldName !== 'Email' && fieldName !== 'Phone') || !vm.useSMS) return;
                            let changeField = 'Email';
                            if (fieldName === 'Email') {
                                changeField = 'Phone';
                            }
                            let field = _.findWhere(vm.participantData.participantBasicQueryInfoList, {
                                detailNameEnglish: changeField,
                            });
                            if (field) {
                                field.isMandatory = !value;
                            }
                        }

                        function getLabelFn(fieldName) {
                            if (fieldName === 'Phone' || fieldName === 'Mobilnummer') {
                                let customLabel = $translate.instant('participant.phone.custom.label.text');
                                return fieldName + ' ' + customLabel;
                            }
                            return fieldName;
                        }

                        function getQuestionLabelFn(item) {
                            return ParticipantService.getQuestionLabel(item, vm.langSelected);
                        }

                        function validatePhoneNumberFn(field, val) {
                            if (field === 'Phone') {
                                return val && val.indexOf('+') === 0;
                            }
                            return true;
                        }

                        function concatBothTypeOfQueriesFn() {
                            let _queryList = vm.participantData.participantBasicQueryInfoList;
                            _queryList = _queryList.concat(vm.participantData.participantAdditionalQueryInfoList);
                            vm.eventQueries = _queryList;
                        }

                        function getInformationFn(fieldName) {
                            if ((fieldName === 'Email' || fieldName === 'Phone') && vm.useSMS) {
                                let subFieldName = 'Email';
                                if (fieldName === 'Email') {
                                    subFieldName = 'Phone';
                                }
                                let subFieldInfo = _.findWhere(vm.participantData.participantBasicQueryInfoList, {
                                    detailNameEnglish: subFieldName,
                                });
                                if (!subFieldInfo) {
                                    return '';
                                }
                                return $translate.instant('default.email.phone.info.text');
                            }
                            return '';
                        }

                        function showQuestionFn(question) {
                            let _queryList = vm.participantData.participantBasicQueryInfoList;
                            _queryList = _queryList.concat(vm.participantData.participantAdditionalQueryInfoList);
                            return ParticipantService.showQuestion(question, _queryList, vm.langSelected);
                        }

                        function setParticipantDataFn(data) {
                            if (data.participantBasicQueryInfoList) {
                                vm.basicInfo = data.participantBasicQueryInfoList;
                                vm.formatQueries(vm.basicInfo);
                                markConditionalRequired();
                                translateBasicValues();
                                vm.useSMS = data.useSMS;
                            }

                            if (data.participantAdditionalQueryInfoList) {
                                vm.additionalInfo = data.participantAdditionalQueryInfoList;
                                translateAdditionalValues();
                            }
                        }

                        function markConditionalRequired() {
                            let phoneIndex = _.findIndex(vm.basicInfo, { detailNameEnglish: 'Phone' });
                            let phone = vm.basicInfo[phoneIndex];
                            let emailIndex = _.findIndex(vm.basicInfo, { detailNameEnglish: 'Email' });
                            let email = vm.basicInfo[emailIndex];
                            if (email && phone) {
                                let _isBothNotMandatory = !phone.isMandatory && !email.isMandatory;
                                if (_isBothNotMandatory) {
                                    vm.basicInfo[emailIndex].isMandatory = true;
                                }
                            }
                        }

                        function formatQueriesFn(bInfo) {
                            angular.forEach(bInfo, function (n) {
                                n.showHideLogic = n.eventParticipantBasicQuery.showHideLogic;
                                n.isMandatory = n.eventParticipantBasicQuery.isMandatory;
                            });
                        }

                        function translateBasicValues() {
                            let _list = angular.copy(vm.basicInfo);
                            let _translated = [];
                            vm.basicInfo = [];
                            for (let i = 0; i < _list.length; i++) {
                                let _question = _list[i];
                                let _optionValues = _question.optionValues;
                                if (['DROPDOWN'].indexOf(_question.type.name) > -1) {
                                    let _fieldValue = _question.fieldValue;
                                    _question.translatedOptions = ParticipantUtilService.getOptionValues(
                                        _optionValues,
                                        vm.langSelected
                                    );
                                    _question.fieldValue = ParticipantUtilService.getFieldValue(
                                        _optionValues,
                                        vm.langSelected,
                                        _fieldValue
                                    );
                                }
                                if (['MULTISELECT'].indexOf(_question.type.name) > -1) {
                                    let _fieldValue = _question.fieldValue;
                                    let _optionValues = _question.optionValues;
                                    _question.translatedOptions = ParticipantUtilService.getOptionValues(
                                        _optionValues,
                                        vm.langSelected
                                    );
                                    if (_fieldValue) {
                                        _question.fieldValue = ParticipantUtilService.getFieldValue(
                                            _optionValues,
                                            vm.langSelected,
                                            _fieldValue
                                        );
                                    }
                                }
                                _translated.push(_question);
                            }
                            vm.basicInfo = _translated;
                            vm.participantData.participantBasicQueryInfoList = _translated;
                        }

                        function translateAdditionalValues() {
                            let _list = angular.copy(vm.additionalInfo);
                            let _translated = [];
                            vm.additionalInfo = [];
                            for (let i = 0; i < _list.length; i++) {
                                let _question = _list[i];
                                let _optionValues = _question.optionValues;
                                if (['DROPDOWN'].indexOf(_question.type.name) > -1) {
                                    let _fieldValue = _question.fieldValue;
                                    _question.translatedOptions = ParticipantUtilService.getOptionValues(
                                        _optionValues,
                                        vm.langSelected
                                    );
                                    _question.fieldValue = ParticipantUtilService.getFieldValue(
                                        _optionValues,
                                        vm.langSelected,
                                        _fieldValue
                                    );
                                }
                                if (['MULTISELECT'].indexOf(_question.type.name) > -1) {
                                    let _fieldValue = _question.fieldValue;
                                    let _optionValues = _question.optionValues;
                                    _question.translatedOptions = ParticipantUtilService.getOptionValues(
                                        _optionValues,
                                        vm.langSelected
                                    );
                                    if (_fieldValue) {
                                        _question.fieldValue = ParticipantUtilService.getFieldValue(
                                            _optionValues,
                                            vm.langSelected,
                                            _fieldValue
                                        );
                                    }
                                }
                                _translated.push(_question);
                            }
                            vm.additionalInfo = _translated;
                            vm.participantData.participantAdditionalQueryInfoList = _translated;
                        }

                        $scope.$on('lang:change', function (event, data) {
                            vm.langSelected = data.lang;
                            translateBasicValues();
                            translateAdditionalValues();
                        });

                        $scope.$watch('registrationFormCtrl.participantData', function () {
                            initFn();
                            /*translateBasicValues();
            translateAdditionalValues();*/
                        });
                    },
                ],
            };
        },
    ]);
})();
