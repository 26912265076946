(function () {
    'use strict';
    angular.module('ogDisabled').directive('ogDisabled', [
        function () {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    scope.$watch(attrs.ogDisabled, function () {
                        var disabled = scope.$eval(attrs.ogDisabled);
                        if (disabled) {
                            element.disabled = 'disabled';
                            element.addClass('disabled');
                        } else {
                            element.removeAttr('disabled');
                            element.removeClass('disabled');
                        }
                    });
                },
            };
        },
    ]);
})();
