'use strict';

angular.module('core.sellerInfo').factory('SellerInfo', [
    '$resource',
    function ($resource) {
        var sellerInfoByEventRest = $resource(
            'api/v1/sellerInfo/event/:eventId',
            {eventId: '@eventId'},
            {
                create: {method: 'POST', isArray: false},
                get: {method: 'GET', isArray: false},
            }
        );

        var sellerInfoRest = $resource(
            'api/v1/sellerInfo/event/:eventId/sellerInfo/:sellerInfoId',
            {eventId: '@eventId', sellerInfoId: '@sellerInfoId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var paymentMethodRest = $resource(
            'api/v1/sellerInfo/event/:eventId/paymentMethod',
            {eventId: '@eventId'},
            {
                update: {method: 'POST', isArray: false},
            }
        );

        return {
            create: function (eventId, jsonObject, successCallback, errorCallback) {
                sellerInfoByEventRest.create({eventId: eventId}, jsonObject, successCallback, errorCallback);
            },
            get: function (eventId, successCallback, errorCallback) {
                sellerInfoByEventRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            update: function (eventId, sellerInfoId, jsonObject, successCallback, errorCallback) {
                sellerInfoRest.update(
                    {eventId: eventId, sellerInfoId: sellerInfoId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            updatePaymentMethod: function (eventId, jsonObject, successCallback, errorCallback) {
                paymentMethodRest.update({eventId: eventId}, jsonObject, successCallback, errorCallback);
            },
        };
    },
]);
