import { Component } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'event-logic-client-angular-v2';
    constructor(private metaService: Meta) {
        this.addTag();
        alert("Here");
    }

    addTag() {
        this.metaService.addTag({ name: 'description', content: 'Article Description' });
        this.metaService.addTag({ name: 'robots', content: 'index,follow' });
        this.metaService.addTag({ property: 'og:title', content: 'Content Title for social media' });
    }
}
