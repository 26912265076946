import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

class AddSupplierCtrl {
    constructor(
        $rootScope,
        $scope,
        $element,
        $location,
        $timeout,
        $translate,
        $sce,
        FieldChangedSvc,
        EventEnum,
        AuthenticationService,
        SupplierDetailInfo,
        Event,
        CustomerUser,
        UserGroup,
        Presentation,
        SupplierDetailService,
        ClientService,
        CommonFunctions
    ) {
        this._injected = {
            $rootScope,
            $scope,
            $element,
            $location,
            $timeout,
            $translate,
            $sce,
            FieldChangedSvc,
            EventEnum,
            AuthenticationService,
            SupplierDetailInfo,
            Event,
            CustomerUser,
            UserGroup,
            Presentation,
            SupplierDetailService,
            ClientService,
        };

        this.vmData = {
            filterBy: '',
            supplierList: [],
            supplierListBusy: false,
            supplierListOffset: 0,
            selectedIndex: -1,
        };
        ScopeFunctions.addToScope($scope);
        this.editDetails = false;
        this.fullDescriptionHeight = 0; //default value
        SupplierDetailService.setSupplierDetailStatus(null);
        SupplierDetailService.setSearchByEmailEnabled(false);
        this.selectedSuppliersForDetails = null;
        this.disableInfiniteScroll = false;
        this.clientId = ClientService.get().id;
        jQuery('#select-supplier-body').scroll(function () {
            var mScroll = jQuery(this).scrollTop();
            if (mScroll > 500) {
                jQuery('.arrow').fadeOut();
            } else {
                jQuery('.arrow').fadeIn();
            }
        });
        $scope.$watch('AddSupplierCtrl.animateThumbnail', (newval) => {
            if (!newval) {
                setTimeout(() => {
                    $('.list-li').removeClass('list-animate');
                }, 0);
            }
            this.animateThumbnail = true;
        });
    }

    showSupplierDetails(supplier, index) {
        const { SupplierDetailService } = this._injected;
        if (this.isSupplierUnPublished(supplier)) {
            return;
        }
        //this.removedSuppliers.id == supplier.id ? null : this.vmData.selectedIndex = $index;
        this.getDefaultSelectedImage(supplier);
        this.disableInfiniteScroll = true;
        //this.getSupplierDetailInfo(supplier);
        SupplierDetailService.setSupplierDetailStatus(supplier.id);
        let _searchByEmailView = this.view === 'SEARCH_BY_EMAIL';
        SupplierDetailService.setSearchByEmailEnabled(_searchByEmailView);
        this.selectedSuppliersForDetails = supplier;
    }

    getPresentationDescription(presentation) {
        const { $rootScope } = this._injected;
        const lang = $rootScope.langSelected === 'sv_SV' ? 'SWEDISH' : 'ENGLISH';
        const localisedDescriptions = presentation.localisedDescriptions;
        if (localisedDescriptions) {
            let _desc = _.find(localisedDescriptions, (o) => {
                return o.language.name === lang;
            });
            if (_desc && _desc.text && _desc.text !== '') {
                return _desc.text;
            } else {
                _desc = _.find(localisedDescriptions, (o) => {
                    return o.language.name !== lang;
                });
                if (_desc && _desc.text && _desc.text !== '') {
                    return _desc.text;
                }
            }
        }
        return presentation.description;
    }

    shouldDisplaySupplierDetails() {
        const { SupplierDetailService } = this._injected;
        return SupplierDetailService.getSupplierDetailStatus();
    }

    getSupplierDetailInfo(supplier) {
        const { SupplierDetailInfo, $scope } = this._injected;
        $scope.startProgress();
        SupplierDetailInfo.get(
            supplier.id,
            (response) => {
                this.supplierDetailGroups = response.supplierDetailGroups;
                this.sortDetailsList();
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    sortDetailsList() {
        angular.forEach(this.supplierDetailGroups, (group) => {
            let _finalList = [];
            let _maxOrder = _.max(group.supplierDetails, _.property('sdsdgDisplayOrder'));
            _maxOrder = _maxOrder ? _maxOrder.sdsdgDisplayOrder : group.supplierDetails.length;
            for (let i = 0; i < _maxOrder; i++) {
                let _obj = _.find(group.supplierDetails, (o) => {
                    return o.sdsdgDisplayOrder === i + 1;
                });
                if (!_obj) {
                    _obj = { sdsdgDisplayOrder: i + 1 };
                    _obj.isEmpty = true;
                }
                _finalList.push(_obj);
            }
            group.supplierDetails = _finalList;
        });
    }

    translateDynamicFields(name, code) {
        const { $translate } = this._injected;
        if (code) {
            let translatedText = $translate.instant(code);
            if (translatedText !== code) {
                return translatedText;
            }
        }
        return name;
    }

    removeEmptyValuesFromDetailsList() {
        let _supplierDetailsData = angular.copy(this.supplierDetailGroups);
        angular.forEach(_supplierDetailsData, (group, $index) => {
            let _finalList = _.without(
                group.supplierDetails,
                _.findWhere(group.supplierDetails, {
                    isEmpty: true,
                })
            );
            group.supplierDetails = _finalList;
        });
        return _supplierDetailsData;
    }

    trustAsHtml(string) {
        const { $sce } = this._injected;
        return $sce.trustAsHtml(string);
    }

    moreSupplierList() {
        this.moreSuppliersCallback();
    }

    isNotEmpty(str) {
        return angular.isDefined(str) && !(str === null || str === 'null') && /\S/.test(str);
    }

    scrollOnClick() {
        const { $timeout } = this._injected;
        let _topHeight = jQuery('#scrollTarget').position().top;
        // jQuery('#select-supplier-body').scrollTop(_topHeight);
        jQuery('#select-supplier-body').animate({ scrollTop: _topHeight }, 'slow');
    }

    redirectToSupplierPublicPage(supplierUrl) {
        const { $window } = this._injected;
        $window.open(CommonFunctions.getPublicURL() + '/leverantor/' + supplierUrl, '_blank');
    }

    toggleFullDescription() {
        this.fullDescription = !this.fullDescription;
    }

    isSupplierDeclined(supplier) {
        if (this.declinedOffers) {
            var obj = _.find(this.declinedOffers, (offer) => {
                if (!!offer.supplier) {
                    return parseInt(offer.supplier.id) === parseInt(supplier.id);
                }
            });
            return !!obj;
        }
        return false;
    }

    isSupplierUnPublished(supplier) {
        return supplier.unPublishSupplier;
    }

    getDefaultSelectedImage(supplier) {
        this.selectedImageUrl = null;
        if (supplier && supplier.presentation) {
            this.selectedImageUrl =
                supplier.presentation.presentationImages.length > 0
                    ? supplier.presentation.presentationImages[0].fileStoreId
                    : null;
            if (!this.selectedImageUrl) {
                if (supplier.presentation.mapUrl) {
                    this.selectedImageUrl = supplier.presentation.mapUrl;
                }
            }
        }
    }

    getPresentationImage(image) {
        let { CommonFile } = this._injected;
        let url = CommonFile.getBaseUrl() + 'api/v1/file/getFile/';
        let imageUrl = '0';
        if (image) {
            imageUrl = image.url;
        }
        return url + imageUrl;
    }

    updateSelectedImage(selectedImageUrl) {
        this.selectedImageUrl = selectedImageUrl;
    }

    calcDescLength(desc) {
        if (typeof desc == 'undefined') {
            return false;
        }
        return desc.length > 1000;
    }
}

AddSupplierCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$element',
    '$location',
    '$timeout',
    '$translate',
    '$sce',
    'FieldChangedSvc',
    'EventEnum',
    'AuthenticationService',
    'SupplierDetailInfo',
    'Event',
    'CustomerUser',
    'UserGroup',
    'Presentation',
    'SupplierDetailService',
    'ClientService',
    'CommonFile',
    'CommonFunctions',
];
export default AddSupplierCtrl;
