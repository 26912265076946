import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class BookedContentConfirmationModalCtrl {
    constructor($scope, $rootScope, $element, data, $translate, EventSummary) {
        this._injected = {
            $scope,
            $rootScope,
            $element,
            data,
            $translate,
            EventSummary,
        };

        this.okCallBack = data.okCallBack;
        this.eventId = data.eventId;
        this.defaultBookedContentMessage = $translate.instant('agent.default.booked.content.message', {
            p0: data.agentName,
            p1: data.eventName,
        });
        this.clearAttachedFiles();
        this.clearAll = false;
        ScopeFunctions.addToScope($scope);
    }

    sendBookedConfirmation() {
        const { EventSummary, $scope } = this._injected;
        $scope.startProgress();
        EventSummary.sendConfirmation(
            {
                eventId: this.eventId,
                sendToMyself: this.sendToMyself,
                message: this.defaultBookedContentMessage,
                attachedFiles: this.attachedFiles.uploads,
            },
            (response) => {
                this.okCallBack(response.confirmationDate);
                this.clearAll = true;
                this.close();
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    clearAttachedFiles() {
        this.attachedFiles = { uploads: null, links: null };
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

BookedContentConfirmationModalCtrl.$inject = ['$scope', '$rootScope', '$element', 'data', '$translate', 'EventSummary'];
export default BookedContentConfirmationModalCtrl;
