'use strict';

angular.module('core.existingServices').factory('AccommodationSchedule', [
    '$resource',
    function ($resource) {
        var accommodationScheduleRest = $resource(
            'api/v1/accommodationSchedule/event/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var updateAccommodationSchedule = $resource(
            'api/v1/accommodationSchedule/request/:requestId',
            {requestId: '@requestId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );
        var updateRoomName = $resource(
            'api/v1/accommodationSchedule/part/:partId',
            {partId: '@partId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );
        var newRequestAndOfferPartRest = $resource(
            'api/v1/accommodationSchedule/event/:eventId',
            {eventId: '@eventId'},
            {
                create: {method: 'POST', isArray: false},
            }
        );

        return {
            getAccommodationSchedule: function (eventId, successCallback, errorCallback) {
                accommodationScheduleRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            updateAccommodation: function (requestId, jsonObject, successCallback, errorCallback) {
                updateAccommodationSchedule.update(
                    {requestId: requestId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            updateOtherRoomTypeName: function (partId, jsonObject, successCallback, errorCallback) {
                updateRoomName.update({partId: partId}, jsonObject, successCallback, errorCallback);
            },
            createNewRequestAndAddOfferPart: function (eventId, jsonObject, successCallback, errorCallback) {
                newRequestAndOfferPartRest.create({eventId: eventId}, jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

