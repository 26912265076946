/**
 * Don't put this content inside function()
 */
'use strict';
import { getLocalToken } from "../index";
import { environment } from "../../../environments/environment";

export const DownloadUtilFunctions = {
    downloadFile: function (downloadUrl, sendTo) {
        var _token = getLocalToken();
        var xhr = new XMLHttpRequest();
        var win;
        const BASE_URL = environment.apiUrl; //todo(SC) make it configurable later
        var _finalUrl = BASE_URL.concat(downloadUrl);
        xhr.open('GET', _finalUrl, true);
        xhr.responseType = 'blob';
        if (!!_token) {
            xhr.setRequestHeader('X-Auth-Token', _token);
        }
        if (!sendTo) {
            win = window.open('', '_blank');
        }
        xhr.onload = function (data) {
            if (!sendTo) {
                win.close();
                var contentDispo = this.getResponseHeader('Content-disposition');
                var fileName = contentDispo.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];
                download(data.target.response, fileName, 'text/plain');
            }
        };
        xhr.send();
    },
};
