import requestOfferPartOptionsUtil from '../../../utils/request_offer_parts/RequestOfferPartOptionsUtil';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('ogRowOfferPartOptionsActions').directive('ogRowOfferPartOptionsActions', [
        '$translate',
        'RequestOfferPartOption',
        'GlobalConstants',
        'EventEnum',
        function ($translate, RequestOfferPartOption, GlobalConstants, EventEnum) {
            return {
                restrict: 'AE',
                replace: true,
                scope: {
                    offer: '=',
                    offerPartOption: '=',
                    acceptOfferPartOption: '=',
                    declineOfferPartOption: '=',
                    flagForAcceptedWithChanges: '=',
                    originalOfferPartOption: '=',
                    viewLayout: '=',
                    placement: '=',
                },
                templateUrl:
                    './app/components/directives/row_offer_part_options_actions/og_row_offer_part_options_actions.directive.view.html',
                controller: [
                    '$scope',
                    '$rootScope',
                    function ($scope, $rootScope) {
                        ScopeFunctions.addToScope($scope);
                        if ($scope.offerPartOption && !$scope.offerPartOption.buttonViewMap) {
                            $scope.offerPartOption.buttonViewMap = {
                                name: 'requestOfferPart.button.action',
                                ngclass: 'spAction',
                            };
                        }
                        // $scope.originalOfferPartOption = angular.copy($scope.originalOfferPartOption);

                        $scope.doOfferPartOptionAccept = function (offerPartOption) {
                            $scope.offerPartOption.buttonViewMap = {
                                name: 'requestOfferPart.button.accepted',
                                ngclass: 'spAccept',
                            };
                            $scope.acceptOfferPartOption(offerPartOption);
                            jQuery('#offerpart-options' + offerPartOption.id).css('visibility', 'hidden'); //needs to be changed
                            jQuery('#offerpart-options' + offerPartOption.id).removeClass('open');
                        };

                        $scope.doOfferPartOptionDecline = function (offerPartOption) {
                            if ($scope.viewLayout === 'supplier') {
                                if (
                                    offerPartOption.status.name === 'DELETED_WAITING_FOR_SUPPLIER' ||
                                    offerPartOption.status.name === 'NOT_DELIVERABLE'
                                ) {
                                    $scope.offerPartOption.buttonViewMap = {
                                        name: 'requestOfferPart.button.undeleted',
                                        ngclass: 'spDecline',
                                    };
                                } else {
                                    $scope.offerPartOption.buttonViewMap = {
                                        name: 'requestOfferPart.button.declined',
                                        ngclass: 'spDecline',
                                    };
                                }
                            } else {
                                if (
                                    offerPartOption.status.name === 'DELETED_WAITING_FOR_CREATOR' ||
                                    offerPartOption.status.name === 'NOT_DELIVERABLE'
                                ) {
                                    $scope.offerPartOption.buttonViewMap = {
                                        name: 'requestOfferPart.button.undeleted',
                                        ngclass: 'spDecline',
                                    };
                                } else {
                                    $scope.offerPartOption.buttonViewMap = {
                                        name: $translate.instant('requestOfferPart.button.declined'),
                                        ngclass: 'spDecline',
                                    };
                                }
                            }
                            $scope.declineOfferPartOption(offerPartOption);
                            jQuery('#offerpart-options' + offerPartOption.id).css('visibility', 'hidden'); //needs to be changed
                            jQuery('#offerpart-options' + offerPartOption.id).removeClass('open');
                        };

                        $scope.openInlineOfferPartOptionEdit = function (offerPartOption) {
                            if ($scope.viewLayout === 'supplier') {
                                $rootScope.ROPOAddEditId = 'a' + offerPartOption.id;
                                $rootScope.$broadcast('open:edit:offer:part:option:supplier', {
                                    offerPartOption: offerPartOption,
                                });
                                if (offerPartOption.status.name === 'ADDED_WAITING_FOR_SUPPLIER_DECLINED') {
                                    offerPartOption.status.name = 'ADDED_WAITING_FOR_SUPPLIER';
                                }
                            } else {
                                $rootScope.offerPartOptionEditId = 'd' + offerPartOption.id;
                                $rootScope.$broadcast('open:edit:offer:part:option:creator', {
                                    offerPartOption: offerPartOption,
                                });
                                if (offerPartOption.status.name === 'ADDED_WAITING_FOR_CREATOR_DECLINED') {
                                    offerPartOption.status.name = 'ADDED_WAITING_FOR_CREATOR';
                                }
                            }
                        };

                        $scope.isOfferPartOptionInSupplierWaitingStatesFn = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER') {
                                return false;
                            }
                            var offerPartIsInSupplierWaitingStates = false;
                            var originalOfferPartOption = $scope.originalOfferPartOption;
                            angular.forEach(GlobalConstants.SUPPLIER_WAITING_STATES, function (state) {
                                if (!offerPartIsInSupplierWaitingStates) {
                                    if (originalOfferPartOption && originalOfferPartOption.status.name === state) {
                                        offerPartIsInSupplierWaitingStates = true;
                                    }
                                }
                            });
                            return offerPartIsInSupplierWaitingStates;
                        };
                        let _acceptAllPartOptions = $rootScope.$on(
                            'update:accept:all:part:options',
                            function (event, data) {
                                let _offerPartOptionWithChangesAccepted = _.find(
                                    data.offerPartOptionsWithActions,
                                    (offerPartOptionWithActions) => {
                                        return (
                                            offerPartOptionWithActions.offerPartOption.id ===
                                            $scope.offerPartOption.id &&
                                            offerPartOptionWithActions.action === 'ACCEPTED_BY_SUPPLIER_WITH_CHANGES'
                                        );
                                    }
                                );
                                if (!_offerPartOptionWithChangesAccepted) {
                                    $scope.offerPartOption.buttonViewMap = {
                                        name: 'requestOfferPart.button.accepted',
                                        ngclass: 'spAccept',
                                    };
                                    $scope.doOfferPartOptionAccept($scope.offerPartOption);
                                } else {
                                    $scope.offerPartOption.buttonViewMap = {
                                        name: 'requestOfferPart.button.accept.with.changes',
                                        ngclass: 'spEdit',
                                    };
                                }
                            }
                        );

                        $rootScope.$on('$destroy', _acceptAllPartOptions);

                        let _declineAllPartOptions = $rootScope.$on('update:decline:all:part:options', function (data) {
                            let _offerPartOptionWithChangesAccepted = _.find(
                                data.offerPartOptionsWithActions,
                                (offerPartOptionWithActions) => {
                                    return (
                                        offerPartOptionWithActions.offerPartOption.id === $scope.offerPartOption.id &&
                                        offerPartOptionWithActions.action === 'ACCEPTED_BY_SUPPLIER_WITH_CHANGES'
                                    );
                                }
                            );
                            if (!_offerPartOptionWithChangesAccepted) {
                                $scope.offerPartOption.buttonViewMap = {
                                    name: 'requestOfferPart.button.declined',
                                    ngclass: 'spDecline',
                                };
                                $scope.doOfferPartOptionDecline($scope.offerPartOption);
                            } else {
                                $scope.offerPartOption.buttonViewMap = {
                                    name: 'requestOfferPart.button.accept.with.changes',
                                    ngclass: 'spEdit',
                                };
                            }
                        });

                        $rootScope.$on('$destroy', _declineAllPartOptions);
                    },
                ],
                link: function ($scope) {
                    $scope.$watch('flagForAcceptedWithChanges', function (value) {
                        if (value === 'edit' + $scope.offerPartOption.id) {
                            $scope.offerPartOption.buttonViewMap = {
                                name: 'requestOfferPart.button.accept.with.changes',
                                ngclass: 'spEdit',
                            };
                        }
                    });
                },
            };
        },
    ]);
})();
