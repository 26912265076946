/* global angular */
'use strict';

angular.module('core.existingServices').factory('AuthRegister', [
    '$resource',
    function ($resource) {
        var registerUser = $resource(
            'api/v1/register/selfForCustomer',
            {},
            {
                do: {method: 'POST', isArray: false},
            }
        );

        return {
            registerUser: function (jsonObject, successCallback, errorCallback) {
                registerUser.do(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

