'use strict';

import './overview/calendar/calendar.module';
import './overview/calendar/calendar.component';

import './message/message.module';
import './message/message.component';

import './task/task.module';
import './task/task.component';

import './notes/notes.module';
import './notes/notes.component';

import './rating/rating.module';
import './rating/rating.component';

import './all_activities/all_activities.module';
import './all_activities/all_activities.component';

// Define the `events` module
angular.module('event-detail', [
    'ngRoute',
    'calendar',
    'message',
    'task',
    'notes',
    'ratings',
    'allActivities'
]);
