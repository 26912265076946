export default function (appModule) {
    appModule.config([
        '$stateProvider',
        '$urlRouterProvider',
        '$injector',
        function ($stateProvider) {
            $stateProvider.state('showPage', {
                url: '/showPage?link',
                template: '<show-page></show-page>'
            });
        },
    ]);
}