'use strict';
angular.module('core.supplierResource').factory('SupplierResources', [
    '$resource',
    '$log',
    '$http',
    function ($resource, $log, $http) {
        var addNewResourceRest = $resource(
            'api/v1/supplierResources/addResource',
            {},
            {
                save: {method: 'POST', isArray: false},
            }
        );

        var createNewResourceRest = $resource(
            'api/v1/supplierResources/createNewResource',
            {},
            {
                save: {method: 'POST', isArray: false},
            }
        );

        var deleteResourceRest = $resource(
            'api/v1/supplierResources/deleteResource',
            {},
            {
                delete: {method: 'POST', isArray: false},
            }
        );

        var getResourcesRest = $resource(
            'api/v1/supplierResources/getResources',
            {},
            {
                get: {method: 'POST', isArray: false},
            }
        );

        var deleteImageRest = $resource(
            'api/v1/supplierResources/deleteImage',
            {},
            {
                delete: {method: 'POST', isArray: false},
            }
        );
        var pinResourceImageRest = $resource(
            'api/v1/supplierResources/pinResourceImage',
            {},
            {
                update: {method: 'POST', isArray: false},
            }
        );

        var updateResourceStatusRest = $resource(
            'api/v1/supplierResources/updateResourceStatus',
            {},
            {
                update: {method: 'POST', isArray: false},
            }
        );

        var deleteAttachmentRest = $resource(
            'api/v1/supplierResources/deleteResourceAttachment',
            {},
            {
                delete: {method: 'POST', isArray: false},
            }
        );

        var updateResourceImage = function (formData, successCallback, errorCallback) {
            $http
                .post('api/v1/supplierResources/updateResourceImage', formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };

        var getResourceRest = $resource(
            'api/v1/supplierResources/getResource/:resourceId',
            {resourceId: '@resourceId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var updateResourceDataRest = $resource(
            'api/v1/supplierResources/updateResourceData',
            {},
            {
                update: {method: 'POST', isArray: false},
            }
        );

        var updateSupplierResource = function (jsonObject, successCallback, errorCallback) {
            var uri = 'api/v1/supplierResources/updateSupplierResource';

            var formData = new FormData();
            if (jsonObject.attachedFiles) {
                for (var i = 1; i <= jsonObject.attachedFiles.length; i++) {
                    formData.append('file' + i, jsonObject.attachedFiles[i - 1]);
                }
            }

            formData.append('resourceId', jsonObject.resourceId);
            formData.append('supplierId', jsonObject.supplierId);
            formData.append('resourceName', jsonObject.resourceName);
            formData.append('offerPartTypeNameCode', jsonObject.offerPartTypeNameCode);
            formData.append('resourceDescription', jsonObject.resourceDescription);
            formData.append('includes', jsonObject.includes);
            formData.append('resourceStatus', jsonObject.resourceStatus);
            formData.append('pricePerPerson', jsonObject.pricePerPerson ? jsonObject.pricePerPerson : '');
            formData.append('currency', jsonObject.currency ? jsonObject.currency : '');
            formData.append('serviceAreas', JSON.stringify(jsonObject.serviceAreas));

            return rest(uri, formData, successCallback, errorCallback);
        };

        var rest = function (restUrl, formData, successCallback, errorCallback) {
            $http
                .post(restUrl, formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };

        return {
            addResource: function (data, successCallback, errorCallback) {
                addNewResourceRest.save(data, successCallback, errorCallback);
            },
            createNewResource: function (data, successCallback, errorCallback) {
                createNewResourceRest.save(data, successCallback, errorCallback);
            },

            deleteResource: function (data, successCallback, errorCallback) {
                deleteResourceRest.delete(data, successCallback, errorCallback);
            },
            getResources: function (params, successCallback, errorCallback) {
                getResourcesRest.get(params, successCallback, errorCallback);
            },
            updateResourceImage: function (formData, successCallback, errorCallback) {
                return updateResourceImage(formData, successCallback, errorCallback);
            },
            updateResourceStatus: function (jsonObject, successCallback, errorCallback) {
                return updateResourceStatusRest.update(jsonObject, successCallback, errorCallback);
            },
            deleteImage: function (jsonObject, successCallback, errorCallback) {
                deleteImageRest.delete(jsonObject, successCallback, errorCallback);
            },
            pinResourceImageRest: function (jsonObject, successCallback, errorCallback) {
                pinResourceImageRest.update(jsonObject, successCallback, errorCallback);
            },
            getResource: function (resourceId, successCallback, errorCallback) {
                getResourceRest.get({resourceId: resourceId}, successCallback, errorCallback);
            },
            updateResourceData: function (jsonObject, successCallback, errorCallback) {
                updateResourceDataRest.update(jsonObject, successCallback, errorCallback);
            },
            updateSupplierResource: function (jsonObject, successCallback, errorCallback) {
                updateSupplierResource(jsonObject, successCallback, errorCallback);
            },
            deleteAttachment: function (jsonObject, successCallback, errorCallback) {
                deleteAttachmentRest.delete(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);
