'use strict';

angular.module('core.existingServices').factory('ParticipantBasicQuery', [
    '$resource',
    function ($resource) {
        var queryV1Rest = $resource(
            'api/v1/ParticipantBasicQuery/event/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: true},
                update: {method: 'PUT', isArray: true},
            }
        );

        var updateInBatchV1Rest = $resource(
            'api/v1/ParticipantBasicQuery/event/:eventId/updateInBatch',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: true},
            }
        );

        var participantSelectedQueryListRest = $resource(
            'api/v1/ParticipantBasicQuery/:eventId/selectedQueries',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: true},
            }
        );

        var showHideLogicRest = $resource(
            'api/v1/ParticipantBasicQuery/eventParticipantBasicQuery/:eventParticipantBasicQueryId/showHideLogic',
            {eventParticipantBasicQueryId: '@eventParticipantBasicQueryId'},
            {
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: false},
            }
        );

        return {
            getForEvent: function (eventId, successCallback, errorCallback) {
                return queryV1Rest.list({eventId: eventId}, successCallback, errorCallback);
            },
            updateInBatch: function (eventId, jsonObject, successCallback, errorCallback) {
                return updateInBatchV1Rest.update({eventId: eventId}, jsonObject, successCallback, errorCallback);
            },
            updateOptionForEvent: function (eventId, jsonObject, successCallback, errorCallback) {
                return queryV1Rest.update({eventId: eventId}, jsonObject, successCallback, errorCallback);
            },
            selectedBasicQueryList: function (eventId, successCallback, errorCallback) {
                participantSelectedQueryListRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            getShowHideLogic: function (eventParticipantBasicQueryId, successCallback, errorCallback) {
                return showHideLogicRest.get(
                    {eventParticipantBasicQueryId: eventParticipantBasicQueryId},
                    successCallback,
                    errorCallback
                );
            },
            updateShowHideLogic: function (
                eventParticipantBasicQueryId,
                jsonObject,
                successCallback,
                errorCallback
            ) {
                return showHideLogicRest.update(
                    {eventParticipantBasicQueryId: eventParticipantBasicQueryId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);

