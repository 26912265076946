/**
 * Created by Amol on 08/29/22.
 */
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    /* global angular */
    'use strict';

    angular.module('ogSupplierResourceModalImage').directive('ogSupplierResourceModalImage', [
        function () {
            return {
                templateUrl:
                    './app/components/directives/supplier-resource-modal-image/og_supplier_resource_modal_image.view.html',
                scope: {
                    supplierId: '=supplierId',
                    resourceData: '=resourceData',
                    isthumbnail: '=isthumbnail',
                    defaultEditable: '=editable',
                    imageCropSection: '=imageAndDescriptionSection',
                    imageCropSectionOpenCallback: '&',
                    parent: '=parent',
                },
                controller: [
                    '$scope',
                    '$rootScope',
                    '$location',
                    '$log',
                    '$anchorScroll',
                    'Presentation',
                    'Supplier',
                    'SupplierResources',
                    '$timeout',
                    'CommonFile',
                    function (
                        $scope,
                        $rootScope,
                        $location,
                        $log,
                        $anchorScroll,
                        Presentation,
                        Supplier,
                        SupplierResources,
                        $timeout,
                        CommonFile
                    ) {
                        ScopeFunctions.addToScope($scope);

                        $scope.removePicture = function (imageId, imageType) {
                            $scope.showRemovePictureConfirmationMessage = true;
                            $scope.removeImageId = imageId;
                            $scope.removeImageType = imageType;
                        };

                        $scope.resetRemovePicture = function () {
                            $scope.showRemovePictureConfirmationMessage = false;
                            $scope.removeImageId = null;
                            $scope.removeImageType = null;
                        };

                        $scope.doRemovePicture = function () {
                            $scope.startProgress();
                            var data = {
                                resourceId: $scope.resource.id,
                                currentImg: $scope.removeImageId,
                                supplierId: $scope.supplierId,
                            };
                            SupplierResources.deleteImage(
                                data,
                                function (response) {
                                    if ($scope.removeImageId === $scope.currentImg) {
                                        $scope.currentImg = null;
                                    }
                                    $scope.resource = response;
                                    $scope.resourceImages = response.resourceImages;
                                    $scope.resourceImage = $scope.resourceImages[Object.keys($scope.resourceImages)[0]];
                                    $scope.endProgress();
                                    $scope.resetRemovePicture();
                                    $scope.currentImage();
                                    // $rootScope.$emit('presentation:image:updated', response);
                                },
                                $scope.endWithErrorOverlay
                            );
                        };

                        $scope.$watch('resourceData', function (data) {
                            if (data) {
                                $scope.resource = data;
                                $scope.resourceImages = data.resourceImages;
                                if ($scope.resource && $scope.resource.pinnedImageId) {
                                    $scope.resourceImage = +$scope.resource.pinnedImageId;
                                } else {
                                    $scope.resourceImage = $scope.resourceImages[Object.keys($scope.resourceImages)[0]];
                                }
                                $scope.resetEditModes($scope.resourceImages);
                                $scope.currentImg = null;
                                $scope.currentImage();
                                // getSupplierData();
                            }
                        });

                        $scope.$watch('defaultEditable', function (data) {
                            $scope.isEditable = data ? $scope.defaultEditable : false;
                        });

                        $scope.$watch('imageCropSection', function (data) {
                            if (!data) {
                                $scope.showImageCropSection = false;
                            }
                        });

                        function getSupplierData() {
                            $scope.startProgress();
                            if ($scope.supplierId !== null) {
                                Supplier.get(
                                    { supplierId: $scope.supplierId },
                                    function (result) {
                                        $scope.supplier = result;
                                    },
                                    $scope.endWithErrorOverlay
                                );
                            }
                            $scope.endProgress();
                        }

                        $scope.resetEditModes = function (images) {
                            $scope.edit = {
                                name: false,
                                logoUrl: false,
                                videoUrl: false,
                                description: false,
                                map: false,
                            };
                            if (images) {
                                angular.forEach(images, function (image, key) {
                                    $scope.edit[key] = false;
                                });
                            }
                        };

                        $scope.setItemType = function (itemType) {
                            $scope.itemType = itemType;
                        };

                        $scope.errorCallback = function (error) {
                            $scope.error = error;
                            $scope.itemType = undefined;
                        };

                        $scope.parseUploadResponse = function (response) {
                            $scope.edit[$scope.itemType] = false;
                            $scope.resource = response;
                            // $rootScope.$emit('update:supplier:presentation', { presentation: $scope.resource });
                            if ($scope.itemType == 'mapURL') {
                                $scope.$broadcast('map/init', response.mapUrl);
                            }
                            $scope.itemType = undefined;
                        };

                        $scope.upload = function (itemType, successfn) {
                            $scope.itemType = itemType;
                            if ($scope.resource[itemType]) {
                                Presentation.uploadItem(
                                    $scope.resource.id,
                                    itemType,
                                    $scope.resource[itemType],
                                    function (resp) {
                                        if (successfn) {
                                            successfn();
                                        }
                                        $scope.parseUploadResponse(resp);
                                    },
                                    $scope.errorCallback
                                );
                            }
                        };

                        $scope.reset = function () {
                            $scope.presentation.useParent = true;
                            $scope.upload('useParent');
                        };

                        $scope.uploadImage = function (content, completed) {
                            if (completed && content) {
                                var fileStore = angular.fromJson(content);
                                $scope.resource[$scope.itemType] = fileStore.id;
                                $scope.upload($scope.itemType);
                            }
                        };

                        $scope.displayHeaderType = 'IMAGE';

                        $scope.currentImage = function (val) {
                            $scope.displayHeaderType = 'IMAGE';
                            $scope.currentImg = val
                                ? val
                                : $scope.currentImg
                                    ? $scope.currentImg
                                    : $scope.resourceImages
                                        ? _.values($scope.resourceImages)[0]
                                        : '0';

                            return $scope.currentImg;
                        };

                        angular.forEach($scope.resourceImages, function (img, key) {
                            $scope.$watch('edit.' + key, function (a) {
                                if (!a) {
                                    return;
                                }
                                trigger(key);
                            });
                        });

                        function trigger(img) {
                            var repeat = 0;

                            function triggerClick(i) {
                                if ($('input[type="file"].' + i).is(':visible')) {
                                    $('input[type="file"].' + i).trigger('click');
                                } else if (repeat < 5) {
                                    repeat++;
                                    $timeout(triggerClick, 100);
                                }
                            }
                            triggerClick(img);
                        }

                        $scope.$watch('edit.logoUrl', function (a) {
                            if (!a) {
                                return;
                            }
                            trigger('logoUrl');
                        });

                        $scope.updateResourceFile = function (formData) {
                            formData.append('supplierId', $scope.supplierId);
                            SupplierResources.updateResourceImage(
                                formData,
                                function (data) {
                                    $scope.showImageCropSection = false;
                                    $scope.resourceData.resourceImages = data.resourceImages;
                                    $scope.resourceImages = data.resourceImages;
                                    $scope.resourceImage = $scope.resourceImages[Object.keys($scope.resourceImages)[0]];
                                    // $scope.$emit('updated.resource.image');
                                    refreshImage();
                                    $scope.imageCropSectionOpenCallback({ isImgAddOpen: false });
                                    // $rootScope.$emit('resource:image:updated', data);
                                },
                                $scope.endWithErrorOverlay
                            );
                        };

                        function refreshImage() {
                            let oldImage = $scope.resourceImages[$scope.targetImage];
                            let newImage = $scope.resourceImages ? _.values($scope.resourceImages)[0] : null;
                            $scope.updateCurrentImg(oldImage, newImage);
                        }

                        $scope.hasMapUrl = function () {
                            let noMapUrl = 'https://maps.google.com/maps?q=null%2C+null%2C+null';
                            return $scope.resource && $scope.resource.mapUrl != noMapUrl;
                        };

                        $scope.updateCurrentImg = function (oldImageId, newImageId) {
                            if ($scope.currentImg === oldImageId || oldImageId === undefined) {
                                $scope.currentImg = newImageId;
                            }
                        };

                        $scope.setCropImageSection = function (image) {
                            if ($scope.isEditable) {
                                $scope.targetImage = image
                                    ? image
                                    : $scope.resourceImages
                                        ? _.keys($scope.resourceImages)[0]
                                        : null;
                                $scope.targetImageUrl = image ? image : 'other';
                                $scope.showImageCropSection = true;
                                $anchorScroll.yOffset = 50;
                                $anchorScroll('scrolltocrop');
                                $scope.imageCropSectionOpenCallback({ isImgAddOpen: true });
                            }
                        };

                        $scope.resetCropImageSection = function () {
                            $scope.targetImage = null;
                            $scope.targetImageUrl = null;
                            $scope.showImageCropSection = false;
                            $scope.imageCropSectionOpenCallback({ isImgAddOpen: false });
                        };

                        $scope.getImageUrl = function (fileId) {
                            return CommonFile.getCommonFile(fileId);
                        };
                    },
                ],
                link: function (scope, element, attrs) {
                    scope.tabIndex = (Math.random() * 6).toString().replace(/\./g, '_');
                    if (attrs.isthumbnail) {
                        element.bind('click', function (e) {
                            e.stopPropagation();
                        });
                    }

                    $(element).on('change', 'input[type="file"]', function () {
                        $(this).closest('form').find('input[upload-submit]').click();
                    });
                },
            };
        },
    ]);
})();
