import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";
(function () {
    'use strict';
    angular
        .module('participant-invitation')
        .component('participantInvitation', {
            templateUrl: './app/components/modules/participant/participant_details/invitation/invitation.view.html',
            bindings: {
                personalizedPageDetails: '<'
            },
            controllerAs: 'participantInvitationCtrl',
            controller: [
                '$scope',
                '$stateParams',
                '$state',
                'Event',
                'EventPresentationRo',
                'Page',
                function ParticipantInvitationController(
                    $scope,
                    $stateParams,
                    $state,
                    Event,
                    EventPresentationRo,
                    Page,
                ) {
                    var vm = this;

                    vm.gotoRegistrationTab = gotoRegistrationTabFn;
                    vm.declineParticipation = declineParticipationFn;
                    vm.showDeclineOption = showDeclineOptionFn;

                    ScopeFunctions.addToScope($scope);

                    vm.$onInit = ()=> {
                        if ($stateParams.participate == 'true') {
                            vm.gotoRegistrationTab();
                        }
                        vm.showParticipateButton = $stateParams.participate != 'false';
                        $scope.startProgress();
                        EventPresentationRo.get(
                            { hash: $stateParams.hash },
                            function (response) {
                                vm.data = response.eventPresentationDetails;
                                vm.data.slide =
                                    response.eventPresentationDetails.slide || response.eventPresentationDetails.slides[0];
                                vm.invitationDetails = response.invitationDetails;
                                vm.fromPublicSite = vm.personalizedPageDetails.fromPublicSite;
                                vm.participantStatus = response.participantStatus;

                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay
                        );
                    }

                    function gotoRegistrationTabFn() {
                        // should redirect to registration tab
                        $scope.participantDetailsCtrl.activeTab = 'registration';
                        return $state.go('participant_details.registration');
                    }

                    function declineParticipationFn() {
                        $scope.startProgress({ showContent: true });
                        EventPresentationRo.declineParticipation(
                            { hash: $stateParams.hash },
                            function () {
                                vm.participationDeclined = true;
                                $scope.endProgress();
                            },
                            $scope.endWithAlert
                        );
                    }

                    function showDeclineOptionFn() {
                        return !vm.fromPublicSite && vm.participantStatus != 'NOT_ATTENDING';
                    }
                }
            ]
        });
})();
