'use strict';
angular.module('core.existingServices').factory('HashLinkHandler', [
    '$resource',
    function ($resource) {
        var redirectLink = $resource(
            'api/v1/gw/:hash',
            {hash: '@hash'},
            {
                list: {method: 'GET', isArray: false},
            }
        );

        return {
            getRedirectLink: function (jsonObject, successCallback, errorCallback) {
                return redirectLink.list(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

