/**
 * Created by sampurlachhantyal on 10/5/17.
 */

'use strict';

angular.module('core.existingServices').factory('BadgeTemplate', [
    '$resource',
    function ($resource) {
        var v1BadgeTemplateRest = $resource(
            'api/v1/event/badgeTemplate/:eventId',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT'},
                get: {method: 'GET', isArray: false},
                delete: {method: 'DELETE', isArray: false},
            }
        );

        return {
            update: function (data, successCallback, errorCallback) {
                return v1BadgeTemplateRest.update(data, successCallback, errorCallback);
            },

            get: function (data, successCallback, errorCallback) {
                return v1BadgeTemplateRest.get({eventId: data.eventId}, successCallback, errorCallback);
            },

            delete: function (eventId, success, error) {
                return v1BadgeTemplateRest['delete']({eventId: eventId}, success, error);
            },
        };
    },
]);

