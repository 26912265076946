/**
 * Created by sampurnachhantyal on 9/7/17.
 */
const BLUE_DARK = '#5283CC';
const GREEN_DARK = '#269E8C';
const PURPLE_DARK = '#6E6ECC';
const VIOLET_DARK = '#C462A5';
const ORCHID_DARK = '#AE6ECF';
const CRIMSON_DARK = '#B8565F';

const COLOR_CODE_MAP = {
    '#5283CC': BLUE_DARK,
    '#269E8C': GREEN_DARK,
    '#6E6ECC': PURPLE_DARK,
    '#C462A5': VIOLET_DARK,
    '#AE6ECF': ORCHID_DARK,
    '#B8565F': CRIMSON_DARK,
};

class AccommodationColorCodeUtil {
    constructor() {
        this.getDarkColor = this.getDarkColor.bind(this);
    }

    getDarkColor(colorCode) {
        return COLOR_CODE_MAP[colorCode];
    }
}

const accommodationColorCode = new AccommodationColorCodeUtil();
export default accommodationColorCode;
