class OgErrorSuccessMessageController {
    constructor($timeout, $scope) {
        this._injected = { $timeout, $scope };
    }

    resetAlertMessage() {
        this.messages = null;
    }
}

OgErrorSuccessMessageController.$inject = ['$timeout', '$scope'];
export default OgErrorSuccessMessageController;
