import controller from './og_add_content_side_bar.controller';

(function () {
    'use strict';

    angular.module('ogAddContentSideBar').directive('ogAddContentSideBar', [
        function () {
            return {
                scope: {
                    event: '=',
                    addCallback: '&',
                    moreSuppliersCallback: '&',
                    displayAddContentSideBar: '=',
                },
                templateUrl: './app/components/directives/add_content_side_bar/og_add_content_side_bar.html',
                restrict: 'AE',
                controller,
                controllerAs: 'AddContentSideBarCtrl',
                bindToController: true,
            };
        },
    ]);
})();
