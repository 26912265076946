import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";
import {OgModal} from "../../../../../utils/global/ogModal";

(function () {
    'use strict';
    angular.module('budget').component('budget', {
        templateUrl: './app/components/modules/event/event_detail/budget/budget.view.html',
        controllerAs: 'budgetCtrl',
        controller: [
            '$scope',
            'EventSchedule',
            '$rootScope',
            '$state',
            '$stateParams',
            '$timeout',
            'EventEnum',
            'Event',
            'UserGroup',
            'ogScrollService',
            'ActiveTabService',
            'AuthenticationService',
            'DateService',
            'TimezoneService',
            function BudgetController(
                $scope,
                EventSchedule,
                $rootScope,
                $state,
                $stateParams,
                $timeout,
                EventEnum,
                Event,
                UserGroup,
                ogScrollService,
                ActiveTabService,
                AuthenticationService,
                DateService,
                TimezoneService
            ) {
                var vm = this;
                ScopeFunctions.addToScope($scope);
                var eventId = $stateParams.eventId;
                vm.enableAgent = AuthenticationService.getUserData().userGroup.showMarkup;
                setBudgetTabActiveFn('budget');
                vm.setBudgetTabActive = setBudgetTabActiveFn;
                vm.getBudgetActiveTab = getBudgetActiveTabFn;
                vm.openSummaryTab = openSummaryTabFn;
                vm.parseDateInLocaleFormat = parseDateInLocaleFormatFn;
                vm.loading = false;
                vm.isSummaryShared = false;
                vm.summarySharedUsers = '';
                vm.eventId = $stateParams.eventId;
                initFn();

                function initFn() {
                    updateTab();
                    $scope.startProgress('budget');
                    /*vm.loading = true;
                    Event.getEventMarkupDetails(
                        eventId,
                        function (response) {
                            vm.loading = false;
                            vm.event = response;
                            vm.requests = vm.event.requests;
                        },
                        $scope.endWithError('budget')
                    );*/

                    Event.getSummarySharedUsersForBudget(
                        { eventId: eventId },
                        function (response) {
                            vm.summarySharedUsersList = response.userList;
                            let _result = '';
                            if (vm.summarySharedUsersList.length > 0) {
                                vm.isSummaryShared = true;
                                for (let i = 0; i < vm.summarySharedUsersList.length; i++) {
                                    _result += vm.summarySharedUsersList[i].email + '\n';
                                }
                                let _decisionMakerUser = vm.summarySharedUsersList.find((x) => x.decisionMaker === true);
                                if (_decisionMakerUser) {
                                    vm.decisionMakerUserEmail = _decisionMakerUser.email;
                                }
                            }
                            let sharedUser = _result.substring(0, _result.length - 1);
                            vm.summarySharedUsers = sharedUser.replace(/\s/g, '<br>');
                            vm.selectionLastUpdated = response.selectionLastUpdated;
                            $scope.endProgress('budget');
                        },
                        $scope.endWithError()
                    );
                }

                function updateTab() {
                    switch ($state.current.name) {
                        case 'event.detail.budget.list':
                            ActiveTabService.setBudgetSubTab('budget');
                            break;
                        case 'event.detail.budget.markup':
                            ActiveTabService.setBudgetSubTab('markup');
                            break;
                        case 'event.detail.budget.client_election':
                            ActiveTabService.setBudgetSubTab('clientSelection');
                            break;
                        default:
                            ActiveTabService.setBudgetSubTab('budget');
                    }
                }

                function openSummaryTabFn() {
                    if (vm.loading) {
                        return;
                    }
                    window.open('/event/' + vm.eventId + '/summary', '_blank');
                    /* if (vm.requests && vm.requests.length > 0) {
                         window.open('/event/' + vm.event.id + '/summary', '_blank');
                     } else {
                         OgModal.open('alert', { message: 'event.presentation.empty' });
                     }*/
                }

                function setBudgetTabActiveFn(tab) {
                    ActiveTabService.setBudgetSubTab(tab);
                }

                function getBudgetActiveTabFn() {
                    return ActiveTabService.getBudgetSubTab();
                }

                function parseDateInLocaleFormatFn(date) {
                    let dateFormat = DateService.getDateFormatByLocale();
                    return TimezoneService.formatDate(new Date(parseInt(date)), dateFormat);
                }
            }
        ]
    });
})();
