'use strict';
import {ScopeFunctions} from "../../../../../../../utils/global/scopeFunctions";
import {OgModal} from "../../../../../../../utils/global/ogModal";

// Register `participants.design.event.infosite` component, along with its associated controller and template

const BASE_URL = './app/components/modules/event/event_detail/participants/invitation/design-invitation';
angular.module('participants.design.event.infosite').component('designeventinfosite', {
    templateUrl: './app/components/modules/event/event_detail/participants/invitation/design-event-infosite/event-infosite.view.html',
    controllerAs: 'EventInfositeCtrl',
    controller: [
            '$scope',
            '$rootScope',
            '$sce',
            'FileService',
            '$stateParams',
            'EventPresentation',
            function EventInfositeController(
                $scope, $rootScope, $sce, FileService, $stateParams, EventPresentation
            ) {
                var vm = this;
                vm.fileUpload = fileUploadFn;
                vm.downloadFile = downloadFileFn;
                vm.trustAsHtml = trustAsHtmlFn;
                vm.uploadedFiles = [];
                vm.eventId = $stateParams.eventId;
                vm.init = initFn;

                ScopeFunctions.addToScope($scope);
                vm.init();

                function initFn() {
                    $scope.startProgress({ showContent: false });
                    EventPresentation.getInvitationPresentationForEvent(
                        { eventId: vm.eventId, requestFrom: 'infoSite' },
                        updatePresentationDetails,
                        $scope.endWithErrorOverlay
                    );
                }

                function updatePresentationDetails(response) {
                    vm.eventPresentation = response.eventPresentationDetails.eventPresentation;
                    vm.presentation = response.eventPresentationDetails.slide;
                    vm.presentationImages = response.eventPresentationDetails.slide.presentationImages;
                    vm.showMapInInvitation = { checked: response.eventPresentationDetails.eventPresentation.showMap };
                    vm.invitationDetails = response.invitationDetails;
                    $scope.endProgress();
                }

                function listAllFiles() {
                    FileService.listAllFiles(
                        { eventId: vm.eventId },
                        (res) => {
                            vm.uploadedFiles = angular.copy(res.data);
                        },
                        $scope.endWithError
                    );
                }

                function fileUploadFn() {
                    OgModal.open('file:upload', {
                        // types: ['pdf'],
                        types: ['*'],
                        eventId: vm.eventId,
                    });
                }

                function downloadFileFn(url, filename) {
                    FileService.downloadFile(url, filename);
                }

                function trustAsHtmlFn(string) {
                    return $sce.trustAsHtml(string);
                }

                $scope.uploadSelectedFiles = function (fileSelect) {
                    $scope.attachedFiles = fileSelect;
                };

                var eventFilesUpdate = $rootScope.$on('event:files:update', function (event, data) {
                    listAllFiles();
                });

                $scope.$on('$destroy', eventFilesUpdate);
            }
        ]
    });
