const FINISHED_OFFER = ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_SUPPLIER'];
const EXCLUDE_PART_ON_PRICE_CALCULATION = ['NOT_DELIVERABLE', 'DELETED_BY_CREATOR', 'DELETED_WAITING_FOR_SUPPLIER'];
import requestOfferPartsUtil from '../../utils/request_offer_parts/RequestOfferPartsUtil';
import {UtilFunctions} from '../global/utilFunctions'
import CommonFunctions from '../../utils/CommonFunctionUtil'

class PriceCalculationUtil {
    constructor() {
        this._calculateTotalPrice = this._calculateTotalPrice.bind(this);
        this._getOriginalTotalPrice = this._getOriginalTotalPrice.bind(this);
        this._getOriginalTotalPriceWithVat = this._getOriginalTotalPriceWithVat.bind(this);
        this.getOfferOriginalPrice = this.getOfferOriginalPrice.bind(this);
        this._getOriginalTotalPriceInclVat = this._getOriginalTotalPriceInclVat.bind(this);
        this._getOriginalRequestOfferPartOptionTotalPriceInclVat =
            this._getOriginalRequestOfferPartOptionTotalPriceInclVat.bind(this);
        this._getDeliverableParts = this._getDeliverableParts.bind(this);
        this._getPriceFromPart = this._getPriceFromPart.bind(this);
        this._getPriceFromPartOption = this._getPriceFromPartOption.bind(this);
        this.getOldPriceValue = this.getOldPriceValue.bind(this);
        this.getOldAmountValue = this.getOldAmountValue.bind(this);
        this.excludeDeletedOfferParts = this.excludeDeletedOfferParts.bind(this);
        this._getSupplierPriceFromPartOption = this._getSupplierPriceFromPartOption.bind(this);
        this._getSupplierPriceFromPartOptionInclVat = this._getSupplierPriceFromPartOptionInclVat.bind(this);
        this.round = this.round.bind(this);
        this.calculatePriceFromPriceAndVat = this.calculatePriceFromPriceAndVat.bind(this);
    }

    _calculateTotalPrice(amount, price) {
        let _total = amount * price;
        if (isNaN(_total)) {
            _total = 0;
        }
        return this.round(_total);
    }

    round(value) {
        let decimals = 2; //only take 2 digit after decimal
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    _getOriginalTotalPrice(part) {
        let _price = this._getPriceFromPart(part);
        let _amount = part.amount;
        let _total = this._calculateTotalPrice(_amount, _price);
        return this.round(_total);
    }

    _getOriginalTotalPriceWithVat(part) {
        let _price = this._getPriceFromPart(part);
        let _vat = 0;
        if (part.vat > 0) {
            _vat = (_price * part.vat) / 100;
        }
        _price = parseFloat(_price) + parseFloat(_vat);
        _price = Number(Math.round(_price + 'e' + 2) + 'e-' + 2);
        let _amount = part.amount;
        let _total = this._calculateTotalPrice(_amount, _price);
        return this.round(_total);
    }

    _getOriginalTotalPriceInclVat(part, offer) {
        let _price = this._getPriceFromPart(part, offer.vatIncludedInPrice);
        _price = parseFloat(_price);
        _price = Number(Math.round(_price + 'e' + 2) + 'e-' + 2);
        let _amount = part.amount;
        let _total = this._calculateTotalPrice(_amount, _price);
        return this.round(_total);
    }

    _getOriginalRequestOfferPartOptionTotalPrice(partOption) {
        let _price = this._getPriceFromPartOption(partOption);
        let _amount = partOption.amount;
        let _total = this._calculateTotalPrice(_amount, _price);
        return this.round(_total);
    }

    _getOriginalRequestOfferPartOptionTotalPriceWithVat(partOption) {
        let _price = this._getPriceFromPartOption(partOption);
        let _vat = 0;
        if (partOption && partOption.vat > 0) {
            _vat = (_price * partOption.vat) / 100;
        }
        _price = parseFloat(_price) + parseFloat(_vat);
        _price = Number(Math.round(_price + 'e' + 2) + 'e-' + 2);
        let _amount = partOption.amount;
        let _total = this._calculateTotalPrice(_amount, _price);
        return this.round(_total);
    }

    _getOriginalRequestOfferPartOptionTotalPriceInclVat(partOption, offer) {
        let _price = this._getPriceFromPartOption(partOption, offer.vatIncludedInPrice);
        _price = parseFloat(_price);
        _price = Number(Math.round(_price + 'e' + 2) + 'e-' + 2);
        let _amount = partOption.amount;
        let _total = this._calculateTotalPrice(_amount, _price);
        return this.round(_total);
    }

    excludeDeletedOfferParts(offerParts) {
        if (offerParts) {
            let _filteredOfferParts = [];
            offerParts.forEach((offerPart) => {
                if (EXCLUDE_PART_ON_PRICE_CALCULATION.indexOf(offerPart.status.name) < 0) {
                    _filteredOfferParts.push(offerPart);
                }
            });
            return _filteredOfferParts;
        } else {
            return offerParts;
        }
    }

    getOfferOriginalPrice(partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        for (let part of _deliverableParts) {
            _totalPrice += this._getOriginalTotalPrice(part);
        }
        return this.round(_totalPrice);
    }

    _getDeliverableParts(parts) {
        var _parts = requestOfferPartsUtil.filterOfferPartsForCreator(null, parts);
        return this.excludeDeletedOfferParts(_parts);
    }

    _getPriceFromPart(part, vatIncludedInPrice = false) {
        if (part) {
            if (vatIncludedInPrice) {
                return UtilFunctions.isGreaterOrEqualToZero(part.newPriceInclVat) ? part.newPriceInclVat : part.price;
            } else {
                if (part.newPriceInclVat && part.vat && part.vat > 0) {
                    let _newPrice = parseFloat(part.newPriceInclVat / (1 + part.vat / 100));
                    part.newPrice = Number(Math.round(_newPrice + 'e' + 2) + 'e-' + 2);
                }
                return UtilFunctions.isGreaterOrEqualToZero(part.newPrice) ? part.newPrice : part.price;
            }
        }
    }

    _getPriceFromPartOption(partOption, vatIncludedInPrice = false) {
        if (vatIncludedInPrice) {
            if (!partOption.priceInclVat) {
                let _newPrice = parseFloat(partOption.price * (1 + partOption.vat / 100));
                partOption.priceInclVat = Number(Math.round(_newPrice + 'e' + 2) + 'e-' + 2);
            }
            return UtilFunctions.isGreaterOrEqualToZero(partOption.priceInclVat)
                ? partOption.priceInclVat
                : partOption.price;
        } else {
            if (partOption.priceInclVat && partOption.vat && partOption.vat > 0) {
                let _newPrice = parseFloat(partOption.priceInclVat / (1 + partOption.vat / 100));
                partOption.newPrice = Number(Math.round(_newPrice + 'e' + 2) + 'e-' + 2);
            }
            return UtilFunctions.isGreaterOrEqualToZero(partOption.newPrice) ? partOption.newPrice : partOption.price;
        }
    }

    _getPriceExclVatFromPartOption(partOption) {
        return UtilFunctions.isGreaterOrEqualToZero(partOption.newPrice) ? partOption.newPrice : partOption.price;
    }

    _isFinishedOffer(offer) {
        CommonFunctions.hasIntersectValue([offer.status.name], FINISHED_OFFER);
    }

    _getSupplierPriceFromPartOption(partOption) {
        return UtilFunctions.isGreaterOrEqualToZero(partOption.supplierPrice)
            ? partOption.supplierPrice
            : partOption.price;
    }

    _getSupplierPriceFromPartOptionInclVat(partOption, vatIncludedInPrice = false) {
        if (vatIncludedInPrice) {
            if (!partOption.priceInclVat) {
                let _newPrice = parseFloat(partOption.supplierPrice * (1 + partOption.vat / 100));
                partOption.priceInclVat = Number(Math.round(_newPrice + 'e' + 2) + 'e-' + 2);
            }
            return UtilFunctions.isGreaterOrEqualToZero(partOption.priceInclVat)
                ? partOption.priceInclVat
                : partOption.supplierPrice;
        } else {
            if (partOption.priceInclVat && partOption.vat && partOption.vat > 0) {
                let _newPrice = parseFloat(partOption.priceInclVat / (1 + partOption.vat / 100));
                partOption.supplierPrice = Number(Math.round(_newPrice + 'e' + 2) + 'e-' + 2);
            }
            return UtilFunctions.isGreaterOrEqualToZero(partOption.supplierPrice)
                ? partOption.supplierPrice
                : partOption.price;
        }
    }

    getOldPriceValue(offer, part) {
        if (offer && this._isFinishedOffer(offer)) {
            return part.price;
        }
        return part.oldPrice;
    }

    getOldAmountValue(offer, part) {
        if (this._isFinishedOffer(offer)) {
            return part.amount;
        }
        return part.oldAmount;
    }

    getOldAmountType(offer, part) {
        if (this._isFinishedOffer(offer)) {
            return part.amountType ? part.amountType.name : null;
        }
        return part.oldAmountType ? part.oldAmountType.name : null;
    }

    getOldAmountTypeValue(offer, part) {
        var type = this.getOldAmountType(offer, part);
        return type ? type : 'PIECES';
    }

    calculatePriceFromPriceAndVat(price, vat) {
        let _vat = 0;
        if (vat > 0) {
            _vat = (price * vat) / 100;
        }
        price = parseFloat(price) + parseFloat(_vat);
        if (isNaN(price)) {
            price = 0;
        }
        return Number(Math.round(price + 'e' + 2) + 'e-' + 2);
    }
}

export default PriceCalculationUtil;
