/**
 * Created by kshitij on 1/19/17.
 */
(function () {
    'use strict';
    angular.module('ogOfferNegotiationAlert').directive('ogOfferNegotiationAlert', [
        function () {
            return {
                restrict: 'EA',
                /* jshint ignore:start */
                templateUrl:
                    './app/components/modals/modals2.0/offer-preview/offer_negotiation_alert/og_offer_negotiation_alert.directive.view.html',
                /* jshint ignore:end */
                replace: true,
                scope: {
                    message: '=',
                    okCallback: '=',
                    cancelCallback: '=',
                    canAddCustomMessage: '=',
                    userMessage: '=',
                },
            };
        },
    ]);
})();
