(function () {
    'use strict';

    angular.module('ogEventBoxTemplate').directive('ogEventBoxTemplate', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/event_box_template/og_event_box_template.view.html',
            replace: true,
        };
    });
})();
