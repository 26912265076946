import amountAndPriceService from '../../../utils/price_calculate/PriceCalculationCreatorUtil';
import CurrencyUtil from '../../../utils/CurrencyUtil';
import tooltipPartStatusUtil from '../../../utils/negotiation_notification/NegotiationTooltipPartStatusUtil';
import {UtilFunctions} from "../../../utils/global/utilFunctions";

(function () {
    'use strict';

    angular.module('ogCreatorOfferPartSummary').directive('ogCreatorOfferPartSummary', [
        '$translate',
        'TranslationService',
        function ($translate, TranslationService) {
            return {
                restrict: 'EA',
                scope: {
                    offerPart: '=',
                    originalOfferPart: '=',
                    offer: '=',
                    event: '=',
                    isOfferPartOption: '=',
                    isCreator: '=',
                },
                templateUrl:
                    './app/components/directives/creator_offer_part_summary/og_creator_offer_part_summary.view.html',
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.updateRowSummary = function () {
                            $scope.changedFields = [];

                            let _originalPrice = amountAndPriceService.getPrice($scope.offer, $scope.originalOfferPart);
                            let _currentPrice = amountAndPriceService.getPrice($scope.offer, $scope.offerPart);
                            let _originalOldPrice = amountAndPriceService.getOldPrice(
                                $scope.offer,
                                $scope.originalOfferPart
                            );
                            let _currentOldPrice = amountAndPriceService.getOldPrice($scope.offer, $scope.offerPart);

                            if (tooltipPartStatusUtil.ABC($scope.offerPart)) {
                                return;
                            }

                            if (
                                tooltipPartStatusUtil.ACCEPTED($scope.originalOfferPart) ||
                                tooltipPartStatusUtil.AWFC($scope.originalOfferPart)
                            ) {
                                if (tooltipPartStatusUtil.EWFS($scope.offerPart)) {
                                    if ($scope.originalOfferPart.hasAdditionalQuestions) {
                                        if ($scope.offerPart.list && $scope.originalOfferPart.list) {
                                            angular.forEach($scope.offerPart.list, (partListValue) => {
                                                angular.forEach($scope.originalOfferPart.list, (originalPartListValue) => {
                                                    if (partListValue.id === originalPartListValue.id && partListValue.fieldValue !== originalPartListValue.fieldValue) {
                                                        $scope.changedFields.push({
                                                            name: $translate.instant(originalPartListValue.labelCode),
                                                            originalValue: $scope.getFieldValue(partListValue),
                                                            creatorValue: $scope.getFieldValue(originalPartListValue),
                                                        });
                                                    }
                                                })
                                            })
                                        }
                                    }
                                    if ($scope.originalOfferPart.name !== $scope.offerPart.name) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.name'),
                                            originalValue: $scope.originalOfferPart.name,
                                            originalCodeValue: $scope.originalOfferPart.nameCode,
                                            creatorValue: $scope.offerPart.name,
                                            creatorCodeValue: $scope.offerPart.nameCode,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.commentByCreator !== $scope.offerPart.commentByCreator
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.description'),
                                            originalValue: TranslationService.translate(
                                                $scope.originalOfferPart.commentByCreator,
                                                $scope.originalOfferPart.commentByCreatorCode
                                            ),
                                            creatorValue: TranslationService.translate(
                                                $scope.offerPart.commentByCreator,
                                                $scope.offerPart.commentByCreatorCode
                                            ),
                                        });
                                    }
                                    if ($scope.originalOfferPart.amount !== $scope.offerPart.amount) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.amount,
                                            creatorValue: $scope.offerPart.amount,
                                        });
                                    }
                                    if ($scope.originalOfferPart.amountType.name !== $scope.offerPart.amountType.name) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue: $scope.getAmountTypeName(
                                                $scope.originalOfferPart.amountType
                                            ),
                                            creatorValue: $scope.getAmountTypeName($scope.offerPart.amountType),
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.dateTimeFrom !== $scope.offerPart.dateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.offerPart.dateTimeTo
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.originalOfferPart.dateTimeFrom,
                                                $scope.originalOfferPart.dateTimeTo,
                                            ],
                                            creatorValue: [$scope.offerPart.dateTimeFrom, $scope.offerPart.dateTimeTo],
                                        });
                                    }
                                    if ($scope.originalOfferPart.finalPaidOnsite) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.paid.onsite'),
                                            originalValue: $translate.instant('common.paid.onsite.false.value'),
                                            supplierValue: $scope.offerPart.finalPaidOnsite
                                                ? $translate.instant('common.paid.onsite.true.value')
                                                : $translate.instant('common.paid.onsite.false.value'),
                                        });
                                    }
                                }
                            }

                            if (tooltipPartStatusUtil.EWFC_EWFSD_AWFSD_DWFSD($scope.originalOfferPart)) {
                                if (tooltipPartStatusUtil.EWFS($scope.offerPart)) {
                                    if (
                                        $scope.originalOfferPart.name !== $scope.offerPart.name ||
                                        $scope.originalOfferPart.name !== $scope.originalOfferPart.oldName
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.name'),
                                            originalValue: $scope.originalOfferPart.oldName,
                                            originalCodeValue: $scope.originalOfferPart.oldNameCode,
                                            supplierValue:
                                                $scope.originalOfferPart.name === $scope.originalOfferPart.oldName
                                                    ? '-'
                                                    : $scope.originalOfferPart.supplierName,
                                            supplierCodeValue:
                                                $scope.originalOfferPart.nameCode !== null &&
                                                $scope.originalOfferPart.nameCode ===
                                                $scope.originalOfferPart.oldNameCode
                                                    ? '-'
                                                    : $scope.originalOfferPart.supplierNameCode,
                                            creatorValue:
                                                $scope.originalOfferPart.name === $scope.offerPart.name
                                                    ? '-'
                                                    : $scope.offerPart.name,
                                            creatorCodeValue:
                                                $scope.originalOfferPart.nameCode !== null &&
                                                $scope.originalOfferPart.nameCode === $scope.offerPart.nameCode
                                                    ? '-'
                                                    : $scope.offerPart.nameCode,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.amount !== $scope.offerPart.amount ||
                                        $scope.originalOfferPart.amount !== $scope.originalOfferPart.oldAmount
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.oldAmount,
                                            supplierValue:
                                                $scope.originalOfferPart.amount === $scope.originalOfferPart.oldAmount
                                                    ? '-'
                                                    : $scope.originalOfferPart.supplierAmount,
                                            creatorValue:
                                                $scope.originalOfferPart.amount === $scope.offerPart.amount
                                                    ? '-'
                                                    : $scope.offerPart.amount,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.amountType.name !== $scope.offerPart.amountType.name ||
                                        ($scope.originalOfferPart.oldAmountType &&
                                            $scope.originalOfferPart.amountType.name !==
                                            $scope.originalOfferPart.oldAmountType.name)
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue: $scope.getAmountTypeName(
                                                $scope.originalOfferPart.oldAmountType
                                            ),
                                            supplierValue:
                                                $scope.originalOfferPart.oldAmountType &&
                                                $scope.originalOfferPart.amountType.name ===
                                                $scope.originalOfferPart.oldAmountType.name
                                                    ? '-'
                                                    : $scope.getAmountTypeName(
                                                        $scope.originalOfferPart.supplierAmountType
                                                    ),
                                            creatorValue:
                                                $scope.originalOfferPart.amountType.name ===
                                                $scope.offerPart.amountType.name
                                                    ? '-'
                                                    : $scope.getAmountTypeName($scope.offerPart.amountType),
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.dateTimeFrom !== $scope.offerPart.dateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.offerPart.dateTimeTo ||
                                        $scope.originalOfferPart.dateTimeFrom !==
                                        $scope.originalOfferPart.oldDateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.originalOfferPart.oldDateTimeTo
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.originalOfferPart.oldDateTimeFrom,
                                                $scope.originalOfferPart.oldDateTimeTo,
                                            ],
                                            supplierValue:
                                                $scope.originalOfferPart.dateTimeFrom ===
                                                $scope.originalOfferPart.oldDateTimeFrom &&
                                                $scope.originalOfferPart.dateTimeTo ===
                                                $scope.originalOfferPart.oldDateTimeTo
                                                    ? '-'
                                                    : [
                                                        $scope.originalOfferPart.creatorDateTimeFrom,
                                                        $scope.originalOfferPart.supplierDateTimeTo,
                                                    ],
                                            creatorValue:
                                                $scope.originalOfferPart.dateTimeFrom ===
                                                $scope.offerPart.dateTimeFrom &&
                                                $scope.originalOfferPart.dateTimeTo === $scope.offerPart.dateTimeTo
                                                    ? '-'
                                                    : [$scope.offerPart.dateTimeFrom, $scope.offerPart.dateTimeTo],
                                        });
                                    }
                                    if ($scope.originalOfferPart.finalPaidOnsite) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.paid.onsite'),
                                            originalValue: $translate.instant('common.paid.onsite.false.value'),
                                            supplierValue: $scope.offerPart.finalPaidOnsite
                                                ? $translate.instant('common.paid.onsite.true.value')
                                                : $translate.instant('common.paid.onsite.false.value'),
                                        });
                                    }
                                    //TODO(SC) Creator never change price I think should be removed
                                    if (_originalPrice !== _currentPrice || _originalPrice !== _originalOldPrice) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: _originalOldPrice,
                                            supplierValue: _originalPrice === _originalOldPrice ? '-' : _originalPrice,
                                            creatorValue: _originalPrice === _currentPrice ? '-' : _currentPrice,
                                        });
                                    }
                                } else if (tooltipPartStatusUtil.EWFC_ACCEPTED_DECLINED($scope.offerPart)) {
                                    if ($scope.originalOfferPart.name !== $scope.originalOfferPart.oldName) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.name'),
                                            originalValue: $scope.originalOfferPart.oldName,
                                            originalCodeValue: $scope.originalOfferPart.oldNameCode,
                                            supplierValue: $scope.originalOfferPart.name,
                                            supplierCodeValue: $scope.originalOfferPart.nameCode,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.commentBySupplier !==
                                        $scope.originalOfferPart.oldCommentBySupplier
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.description'),
                                            originalValue: $scope.originalOfferPart.oldCommentBySupplier,
                                            supplierValue: $scope.originalOfferPart.commentBySupplier,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.amount !== $scope.originalOfferPart.oldAmount &&
                                        $scope.offer.status.name !== 'FINISHED_WAITING_FOR_CREATOR'
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.oldAmount,
                                            supplierValue: $scope.originalOfferPart.amount,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.amount !== $scope.originalOfferPart.finalAmount &&
                                        $scope.offer &&
                                        $scope.offer.status &&
                                        $scope.offer.status.name === 'FINISHED_WAITING_FOR_CREATOR'
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.amount,
                                            supplierValue: $scope.originalOfferPart.finalAmount,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.oldAmountType &&
                                        $scope.originalOfferPart.amountType.name !==
                                        $scope.originalOfferPart.oldAmountType.name
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue: $scope.getAmountTypeName(
                                                $scope.originalOfferPart.oldAmountType
                                            ),
                                            supplierValue: $scope.getAmountTypeName(
                                                $scope.originalOfferPart.amountType
                                            ),
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.dateTimeFrom !==
                                        $scope.originalOfferPart.oldDateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.originalOfferPart.oldDateTimeTo
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.originalOfferPart.oldDateTimeFrom,
                                                $scope.originalOfferPart.oldDateTimeTo,
                                            ],
                                            supplierValue: [
                                                $scope.originalOfferPart.dateTimeFrom,
                                                $scope.originalOfferPart.dateTimeTo,
                                            ],
                                        });
                                    }
                                    if (
                                        _originalPrice !== _originalOldPrice &&
                                        $scope.offer.status.name !== 'FINISHED_WAITING_FOR_CREATOR'
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: _originalOldPrice,
                                            supplierValue: _originalPrice,
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.price !== $scope.originalOfferPart.finalPrice &&
                                        $scope.offer &&
                                        $scope.offer.status &&
                                        $scope.offer.status.name === 'FINISHED_WAITING_FOR_CREATOR'
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.price'),
                                            originalValue: amountAndPriceService.getTooltipPrice(
                                                $scope.offer,
                                                $scope.originalOfferPart,
                                                $scope.originalOfferPart.price
                                            ),
                                            supplierValue: amountAndPriceService.getTooltipPrice(
                                                $scope.offer,
                                                $scope.originalOfferPart,
                                                $scope.originalOfferPart.finalPrice
                                            ),
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.finalPaidOnsite &&
                                        $scope.offer &&
                                        $scope.offer.status &&
                                        $scope.offer.status.name === 'FINISHED_WAITING_FOR_CREATOR'
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.paid.onsite'),
                                            originalValue: $translate.instant('common.paid.onsite.false.value'),
                                            supplierValue: $scope.offerPart.finalPaidOnsite
                                                ? $translate.instant('common.paid.onsite.true.value')
                                                : $translate.instant('common.paid.onsite.false.value'),
                                        });
                                    }
                                }
                            }

                            if (tooltipPartStatusUtil.AWFC($scope.originalOfferPart)) {
                                if (tooltipPartStatusUtil.ACWC($scope.offerPart)) {
                                    if ($scope.originalOfferPart.name !== $scope.offerPart.name) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.name'),
                                            originalValue: $scope.originalOfferPart.name,
                                            originalCodeValue: $scope.originalOfferPart.nameCode,
                                            supplierValue: $scope.originalOfferPart.supplierName,
                                            supplierCodeValue: $scope.originalOfferPart.supplierNameCode,
                                            creatorValue: $scope.offerPart.name,
                                            creatorCodeValue: $scope.offerPart.nameCode,
                                        });
                                    }
                                    if ($scope.originalOfferPart.amount !== $scope.offerPart.amount) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amount'),
                                            originalValue: $scope.originalOfferPart.amount,
                                            supplierValue: $scope.originalOfferPart.supplierAmount,
                                            creatorValue: $scope.offerPart.amount,
                                        });
                                    }
                                    if ($scope.originalOfferPart.amountType.name !== $scope.offerPart.amountType.name) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.amountType'),
                                            originalValue: $scope.getAmountTypeName(
                                                $scope.originalOfferPart.amountType
                                            ),
                                            supplierValue: $scope.getAmountTypeName(
                                                $scope.originalOfferPart.supplierAmountType
                                            ),
                                            creatorValue: $scope.getAmountTypeName($scope.offerPart.amountType),
                                        });
                                    }
                                    if (
                                        $scope.originalOfferPart.dateTimeFrom !== $scope.offerPart.dateTimeFrom ||
                                        $scope.originalOfferPart.dateTimeTo !== $scope.offerPart.dateTimeTo
                                    ) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.date'),
                                            originalValue: [
                                                $scope.originalOfferPart.dateTimeFrom,
                                                $scope.originalOfferPart.dateTimeTo,
                                            ],
                                            supplierValue: [
                                                $scope.originalOfferPart.supplierDateTimeFrom,
                                                $scope.originalOfferPart.supplierDateTimeTo,
                                            ],
                                            creatorValue: [$scope.offerPart.dateTimeFrom, $scope.offerPart.dateTimeTo],
                                        });
                                    }
                                    if ($scope.originalOfferPart.finalPaidOnsite) {
                                        $scope.changedFields.push({
                                            name: $translate.instant('common.paid.onsite'),
                                            originalValue: $translate.instant('common.paid.onsite.false.value'),
                                            supplierValue: $scope.offerPart.finalPaidOnsite
                                                ? $translate.instant('common.paid.onsite.true.value')
                                                : $translate.instant('common.paid.onsite.false.value'),
                                            creatorValue: $translate.instant('common.paid.onsite.false.value'),
                                        });
                                    }
                                }
                            }

                            //TODO conditions matched to (tooltipPartStatusUtil.ACCEPTED($scope.originalOfferPart) || tooltipPartStatusUtil.AWFC($scope.originalOfferPart)) check and remove
                            /*          if (tooltipPartStatusUtil.AWFC($scope.originalOfferPart)) {
            if (tooltipPartStatusUtil.EWFS($scope.offerPart)) {
              if ($scope.originalOfferPart.name !== $scope.offerPart.name) {
                $scope.changedFields.push({
                  name: $translate.instant('common.name'),
                  originalValue: $scope.originalOfferPart.name,
                  originalCodeValue: $scope.originalOfferPart.nameCode,
                  supplierValue: $scope.originalOfferPart.supplierName,
                  supplierCodeValue: $scope.originalOfferPart.supplierNameCode,
                  creatorValue: $scope.offerPart.name,
                  creatorCodeValue: $scope.offerPart.nameCode
                });
              }
              if ($scope.originalOfferPart.amount !== $scope.offerPart.amount) {
                $scope.changedFields.push({
                  name: $translate.instant('common.amount'),
                  originalValue: $scope.originalOfferPart.amount,
                  supplierValue: $scope.originalOfferPart.supplierAmount,
                  creatorValue: $scope.offerPart.amount
                });
              }
              if ($scope.originalOfferPart.amountType.name !== $scope.offerPart.amountType.name) {
                $scope.changedFields.push({
                  name: $translate.instant('common.amountType'),
                  originalValue: $scope.getAmountTypeName($scope.originalOfferPart.amountType),
                  supplierValue: $scope.getAmountTypeName($scope.originalOfferPart.supplierAmountType),
                  creatorValue: $scope.getAmountTypeName($scope.offerPart.amountType)
                });
              }
              if ($scope.originalOfferPart.dateTimeFrom !== $scope.offerPart.dateTimeFrom ||
                  $scope.originalOfferPart.dateTimeTo !== $scope.offerPart.dateTimeTo) {
                $scope.changedFields.push({
                  name: $translate.instant('common.date'),
                  originalValue: [$scope.originalOfferPart.dateTimeFrom, $scope.originalOfferPart.dateTimeTo],
                  supplierValue: [$scope.originalOfferPart.supplierDateTimeFrom,
                    $scope.originalOfferPart.supplierDateTimeTo
                  ],
                  creatorValue: [$scope.offerPart.dateTimeFrom, $scope.offerPart.dateTimeTo]
                });
              }
            }
            //TODO no uses in view, check and remove
            else if (tooltipPartStatusUtil.EWFC_ACCEPTED_DECLINED($scope.offerPart)) {
              if ($scope.originalOfferPart.name !== $scope.originalOfferPart.oldName) {
                $scope.changedFields.push({
                  name: $translate.instant('common.name'),
                  originalValue: $scope.originalOfferPart.oldName,
                  originalCodeValue: $scope.originalOfferPart.oldNameCode,
                  supplierValue: $scope.originalOfferPart.name,
                  supplierCodeValue: $scope.originalOfferPart.nameCode
                });
              }
              if ($scope.originalOfferPart.commentBySupplier !== $scope.originalOfferPart.oldCommentBySupplier) {
                $scope.changedFields.push({
                  name: $translate.instant('common.description'),
                  originalValue: $scope.originalOfferPart.oldCommentBySupplier,
                  supplierValue: $scope.originalOfferPart.commentBySupplier
                });
              }
              if ($scope.originalOfferPart.amount !== $scope.originalOfferPart.oldAmount) {
                $scope.changedFields.push({
                  name: $translate.instant('common.amount'),
                  originalValue: $scope.originalOfferPart.oldAmount,
                  supplierValue: $scope.originalOfferPart.amount
                });
              }
              if ($scope.originalOfferPart.oldAmountType &&
                  $scope.originalOfferPart.amountType.name !== $scope.originalOfferPart.oldAmountType.name) {
                $scope.changedFields.push({
                  name: $translate.instant('common.amountType'),
                  originalValue: $scope.getAmountTypeName($scope.originalOfferPart.oldAmountType),
                  supplierValue: $scope.getAmountTypeName($scope.originalOfferPart.amountType)
                });
              }
              if ($scope.originalOfferPart.dateTimeFrom !== $scope.originalOfferPart.oldDateTimeFrom ||
                  $scope.originalOfferPart.dateTimeTo !== $scope.originalOfferPart.oldDateTimeTo) {
                $scope.changedFields.push({
                  name: $translate.instant('common.date'),
                  originalValue: [$scope.originalOfferPart.oldDateTimeFrom, $scope.originalOfferPart.oldDateTimeTo],
                  supplierValue: [$scope.originalOfferPart.dateTimeFrom, $scope.originalOfferPart.dateTimeTo]
                });
              }
              if (_originalPrice !== _originalOldPrice) {
                $scope.changedFields.push({
                  name: $translate.instant('common.price'),
                  originalValue: _originalOldPrice,
                  supplierValue: _originalPrice
                });
              }
            }
          }*/
                        };

                        $scope.getAmountTypeName = function (amountType) {
                            if (amountType.name === 'SAME_AS_EVENT') {
                                return 'PEOPLE';
                            }
                            return amountType.name;
                        };

                        $scope.isChangedFieldName = function (label) {
                            return label === 'Name' || label === 'Namn';
                        };

                        $scope.isChangedFieldDescription = function (label) {
                            return label === $translate.instant('common.description');
                        };

                        $scope.isChangedFieldDate = function (label) {
                            return label === 'Date' || label === 'Datum';
                        };

                        $scope.isChangedFieldAmountType = function (label) {
                            return label === 'Amount Type' || label === 'Antal Typ';
                        };

                        $scope.isChangedFieldPrice = function (label) {
                            return label === $translate.instant('common.price');
                        };

                        $scope.isChangedFieldPaidOnsite = function (label) {
                            return label === $translate.instant('common.paid.onsite');
                        };

                        $scope.getAmountLocalisedWithCurrency = function (price, currency) {
                            if (!UtilFunctions.isDefinedAndNotNull(price) || price === '-') {
                                return '-';
                            }
                            return CurrencyUtil.viewPrice(price, currency);
                        };

                        $scope.getAmountCurrency = function (offerPart) {
                            if ($scope.isOfferPartOption) {
                                return offerPart.requestOfferPart.offer.currency;
                            } else {
                                return offerPart.offer.currency;
                            }
                        };

                        $scope.getFieldValue = function (list) {
                            if (['MULTICHECKBOX'].indexOf(list.type.name) > -1) {
                                return list.fieldValue.split('~').join(', ');
                            } else if (['TEXT_WITH_AMOUNT_TYPE'].indexOf(list.type.name) !== -1) {
                                return list.fieldValue
                                    .split('~')[0]
                                    .concat(' ')
                                    .concat($translate.instant('common.people'));
                            } else {
                                return list.fieldValue;
                            }
                        };
                    },
                ],
                link: function ($scope) {
                    $scope.$watch('offerPart.name', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.commentByCreator', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.amount', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.amountType.name', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.dateTimeFrom', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.dateTimeTo', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.price', function () {
                        $scope.updateRowSummary();
                    });
                    $scope.$watch('offerPart.finalPaidOniste', function () {
                        $scope.updateRowSummary();
                    });
                },
            };
        },
    ]);
})();
