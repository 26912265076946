/*global angular */
'use strict';

angular.module('core.existingServices').factory('EventFinalDetails', [
    '$resource',
    '$http',
    function ($resource, $http) {
        var v1Uri = 'api/v1/eventFinalDetails';

        var eventFinalDetailsRest = $resource(
            v1Uri + '/getEventFinalDetails',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var rest = function (restUrl, formData, successCallback, errorCallback) {
            $http
                .post(restUrl, formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };

        var saveEventFinalDetailsRest = function (jsonObject, successCallback, errorCallback) {
            var uri = v1Uri + '/createOrUpdateEventFinalDetails';
            var formData = new FormData();

            formData.append('file', jsonObject.attachedFile);
            formData.append('eventId', jsonObject.eventId);
            formData.append('offerId', jsonObject.offerId);
            formData.append('offerParts', JSON.stringify(jsonObject.offerParts));
            formData.append('finalDetailsShared', jsonObject.finalDetailsShared);
            formData.append('eventFinalDetails', JSON.stringify(jsonObject.eventFinalDetails));
            formData.append('categories', JSON.stringify(jsonObject.categories));

            return rest(uri, formData, successCallback, errorCallback);
        };

        var hideUpdatedChangesRest = $resource(
            v1Uri + '/hideUpdatedChanges',
            {},
            {
                put: {method: 'POST', isArray: false},
            }
        );

        return {
            getEventFinalDetails: function (params, successCallback, errorCallback) {
                return eventFinalDetailsRest.get(params, successCallback, errorCallback);
            },
            saveEventFinalDetails: function (jsonObject, successCallback, errorCallback) {
                return saveEventFinalDetailsRest(jsonObject, successCallback, errorCallback);
            },
            hideUpdatedChanges: function (params, successCallback, errorCallback) {
                return hideUpdatedChangesRest.put(params, successCallback, errorCallback);
            },
        };
    },
]);

