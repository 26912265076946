(function () {
    'use strict';

    angular.module('ogPngLogo').directive('ogPngLogo', [
        'AuthenticationService',
        'EventEnum',
        'ClientService',
        'CommonFile',
        function (AuthenticationService, EventEnum, ClientService, CommonFile) {
            return {
                templateUrl: './app/components/directives/png-logo/og.png_logo.directive.view.html',
                restrict: 'EA',
                scope: {
                    className: '@className',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        var vm = this;

                        vm.getClientData = getClienatDataFn;
                        vm.getClientData();

                        function getClienatDataFn() {
                            $scope.client = ClientService.get();
                        }

                        $scope.getImageUrl = function (fileId) {
                            return CommonFile.getCommonFile(fileId);
                        };

                        $scope.$on(EventEnum.USER_DATA_CHANGED, vm.getClientData);
                    },
                ],
            };
        },
    ]);
})();
