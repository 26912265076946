'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogMessageSupplierModal', [
    'RequestOffer',
    '$rootScope',
    '$translate',
    'GlobalOption',
    function (RequestOffer, $rootScope, $translate, GlobalOption) {
        return new OgModal({
            name: 'message:supplier',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                $scope.form = 'updateRequestOfferForm';

                $scope.initDialog = function (data) {
                    var offerIdList = [];
                    $scope.offer = data.offer;
                    $scope.eventId = data.eventId;
                    $scope.responsibleUser = data.responsibleUser;
                    $scope.isFromNotification = data.isFromNotification;

                    $scope.data = {};
                    if ($scope.offer && $scope.offer.supplierMessage) {
                        $scope.data.requestSupplierMessage = $scope.offer.supplierMessage;
                    } else {
                        $scope.startProgress();
                        GlobalOption.get(
                            'DEFAULT_REQUEST_MESSAGE',
                            (resp) => {
                                $scope.data.requestSupplierMessage = resp[$rootScope.langSelected]
                                    ? resp[$rootScope.langSelected]
                                    : $translate.instant('default.message.to.supplier', {
                                        p0: $scope.responsibleUser,
                                    });
                                $scope.endProgress();
                            },
                            $scope.endWithError
                        );
                    }

                    if ($scope.offer) {
                        offerIdList.push($scope.offer.id);
                    } else {
                        angular.forEach(data.offerList, function (offer) {
                            offerIdList.push(offer.id);
                        });
                    }

                    $scope.startProgress();
                    $scope.otherOffers = RequestOffer.getMessageOptions(
                        {eventId: $scope.eventId, offerIdList: offerIdList},
                        $scope.endProgress,
                        $scope.endWithErrorOverlay
                    );
                };

                $scope.$watch('data.selectedMessage', function (value) {
                    if ($scope.data) {
                        if (value) {
                            $scope.data.requestSupplierMessage = value;
                        } else {
                            $scope.startProgress();
                            GlobalOption.get(
                                'DEFAULT_REQUEST_MESSAGE',
                                (resp) => {
                                    $scope.data.requestSupplierMessage = resp[$rootScope.langSelected]
                                        ? resp[$rootScope.langSelected]
                                        : $translate.instant('default.message.to.supplier', {
                                            p0: $scope.responsibleUser,
                                        });
                                    $scope.endProgress();
                                },
                                $scope.endWithError
                            );
                        }
                    }
                });

                $scope.sendMessageToSupplier = function () {
                    if (!$scope.isFromNotification) {
                        $scope.offer.supplierMessage = $scope.data.requestSupplierMessage;
                        $rootScope.$broadcast('offer:message:supplier:set', {offer: $scope.offer});
                    } else {
                        $scope.supplierMessage = $scope.data.requestSupplierMessage;
                        $rootScope.$broadcast('message:all:suppliers', {supplierMessage: $scope.supplierMessage});
                    }
                    $scope.close();
                };
            },
        });
    },
]);

