(function () {
    'use strict';

    angular.module('ogRowActions').directive('ogRowActions', [
        '$translate',
        function ($translate) {
            return {
                restrict: 'AE',
                replace: true,
                scope: {
                    offerPart: '=',
                    acceptOfferPart: '=',
                    declineOfferPart: '=',
                    flagForAcceptedWithChanges: '=',
                    originalOfferPart: '=',
                    viewLayout: '=',
                    placement: '=',
                },
                templateUrl: './app/components/directives/row_actions/og_row_actions.directive.view.html',
                controller: [
                    '$scope',
                    '$rootScope',
                    function ($scope, $rootScope) {
                        $scope.buttonViewMap = {
                            name: 'requestOfferPart.button.action',
                            ngclass: 'spAction',
                        };

                        $scope.doOfferPartAccept = function (offerPart) {
                            $scope.buttonViewMap = {
                                name: 'requestOfferPart.button.accepted',
                                ngclass: 'spAccept',
                            };
                            $scope.acceptOfferPart(offerPart);
                            jQuery('#offerpart-options' + offerPart.id).css('visibility', 'hidden'); //needs to be changed
                            jQuery('#offerpart-options' + offerPart.id).removeClass('open');
                        };

                        $scope.doOfferPartDecline = function (offerPart) {
                            if ($scope.viewLayout === 'supplier') {
                                if (
                                    offerPart.status.name === 'DELETED_WAITING_FOR_SUPPLIER' ||
                                    offerPart.status.name === 'NOT_DELIVERABLE'
                                ) {
                                    $scope.buttonViewMap = {
                                        name: 'requestOfferPart.button.undeleted',
                                        ngclass: 'spDecline',
                                    };
                                } else {
                                    $scope.buttonViewMap = {
                                        name: 'requestOfferPart.button.declined',
                                        ngclass: 'spDecline',
                                    };
                                }
                            } else {
                                if (
                                    offerPart.status.name === 'DELETED_WAITING_FOR_CREATOR' ||
                                    offerPart.status.name === 'NOT_DELIVERABLE'
                                ) {
                                    $scope.buttonViewMap = {
                                        name: 'requestOfferPart.button.undeleted',
                                        ngclass: 'spDecline',
                                    };
                                } else {
                                    $scope.buttonViewMap = {
                                        name: $translate.instant('requestOfferPart.button.declined'),
                                        ngclass: 'spDecline',
                                    };
                                }
                            }
                            $scope.declineOfferPart(offerPart);
                            jQuery('#offerpart-options' + offerPart.id).css('visibility', 'hidden'); //needs to be changed
                            jQuery('#offerpart-options' + offerPart.id).removeClass('open');
                        };

                        $scope.openInlineOfferPartEdit = function (offerPart) {
                            if ($scope.viewLayout === 'supplier') {
                                $rootScope.ROPEditId = 'o' + offerPart.id;
                                if (offerPart.status.name === 'ADDED_WAITING_FOR_SUPPLIER_DECLINED') {
                                    offerPart.status.name = 'ADDED_WAITING_FOR_SUPPLIER';
                                }
                            } else {
                                $rootScope.offerPartEditId = 'd' + offerPart.id;
                                if (offerPart.status.name === 'ADDED_WAITING_FOR_CREATOR_DECLINED') {
                                    offerPart.status.name = 'ADDED_WAITING_FOR_CREATOR';
                                }
                            }
                        };

                        $scope.$on('update:accept:all', function () {
                            $scope.buttonViewMap = {
                                name: 'requestOfferPart.button.accepted',
                                ngclass: 'spAccept',
                            };
                        });

                        $scope.$on('update:accept:all:with:changes', function (event, data) {
                            if (
                                data.offerPartWithChangesAccepted &&
                                data.offerPartWithChangesAccepted.id === $scope.offerPart.id
                            ) {
                                $scope.buttonViewMap = {
                                    name: 'requestOfferPart.button.accept.with.changes',
                                    ngclass: 'spEdit',
                                };
                            }
                        });

                        $scope.$on('update:decline:all', function () {
                            $scope.buttonViewMap = {
                                name: 'requestOfferPart.button.declined',
                                ngclass: 'spDecline',
                            };
                        });
                    },
                ],
                link: function ($scope) {
                    $scope.$watch('flagForAcceptedWithChanges', function (value) {
                        if (value === 'edit' + $scope.offerPart.id) {
                            $scope.buttonViewMap = {
                                name: 'requestOfferPart.button.accept.with.changes',
                                ngclass: 'spEdit',
                            };
                        }
                    });
                },
            };
        },
    ]);
})();
