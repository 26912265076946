(function () {
    'use strict';

    angular.module('ogFileInputBox').directive('ogFileInputBox', [
        function () {
            return {
                scope: {
                    ogFileInputBox: '=',
                },
                link: function (scope, element) {
                    element.bind('change', function (changeEvent) {
                        scope.$apply(function () {
                            scope.ogFileInputBox = changeEvent.target.files[0];
                        });
                    });
                },
            };
        },
    ]);
})();
