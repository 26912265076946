class OgSupplierDropdownController {
    constructor($timeout, $scope) {
        this._injected = { $timeout, $scope };

        if (!this.minLength) {
            this.minLength = 0;
        }

        this.uniqueId = Math.random();

        $scope.$watch('ogSupplierDropdownCtrl.open', (newval) => {
            if (newval) {
                $timeout(() => {
                    let identifier = this.uniqueId + '_value';
                    var searchInput = document.getElementById(identifier);
                    searchInput.focus();
                });
            }
        });

        this.select = this.select.bind(this);
        this.getInitialValue = this.getInitialValue.bind(this);
        this.remoteUrlRequest = this.remoteUrlRequest.bind(this);
        this.remoteUrlResponse = this.remoteUrlResponse.bind(this);
    }

    remoteUrlRequest(str) {
        return { text: str };
    }

    remoteUrlResponse(response) {
        let suppliers = [];

        response.supplierList.forEach((supplier) => {
            if (!supplier.isSuggested) {
                suppliers.push(supplier);
            }
        });
        if (response.isThereSuggestedSuppliers) {
            const headingObject = { name: '', id: '-1', isSuggested: undefined, location: '' };
            suppliers.push(headingObject);
            response.supplierList.forEach((supplier) => {
                if (supplier.isSuggested) {
                    suppliers.push(supplier);
                }
            });
        }
        return { supplierList: suppliers };
    }

    getInitialValue() {
        if (!this.selected) {
            return;
        }
        return this.selected;
    }

    select(selected) {
        if (!selected) {
            return;
        }
        this.selected = selected.originalObject.name;
        this.isSuggestedSupplierSelected = selected.originalObject.isSuggested;
        localStorage.setItem('isSuggestedSupplierSelected', this.isSuggestedSupplierSelected);
        this.selectAndSearch({
            supplierName: this.selected,
            isSuggestedSupplierSelected: JSON.parse(localStorage.getItem('isSuggestedSupplierSelected')),
        });
    }

    getSupplierFromSearchString($item) {
        this.$parent.ogSupplierDropdownCtrl.updateSelectedSupplierNameCallback({ supplierName: $item });
    }
}

OgSupplierDropdownController.$inject = ['$timeout', '$scope'];
export default OgSupplierDropdownController;
