/**
 * Created by threadcodetechnologies on 9/4/18.
 */
'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogParticipantEmailPreviewModal', [
    'Participant',
    '$translate',
    '$timeout',
    function (Participant, $translate, $timeout) {
        return new OgModal({
            name: 'participant:email:preview',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                $scope.initDialog = function (params) {
                    $scope.eventId = params.eventId;
                        let _params = {
                            eventId: $scope.eventId,
                            eventSite: true,
                            showInfoPage: false,
                            includeRegistrationLink: true,
                            invitation: true,
                            selectedParticipants: undefined,
                            messageProvider: 'BY_EMAIL_ONLY',
                            sendTo: 'PREVIEW',
                        };
                        $scope.showPreview(_params);
                    };

                    $scope.showPreview = function (params) {
                        $scope.startProgress();
                        Participant.email(
                            params,
                            function (result) {
                                //data:text/html not worked for IE
                                let _iframe = document.querySelector('#emailPreviewFrame');
                                var iframeDocument = _iframe.contentWindow.document;
                                iframeDocument.open('text/html', 'replace');
                                iframeDocument.write(result.html);
                                iframeDocument.close();
                                $timeout(() => {
                                    _iframe.style.height = iframeDocument.body.scrollHeight + 'px';
                                }, 500);
                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay
                        );
                    };
                },
            });
        },
    ]);

