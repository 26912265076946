class CommonTranslation {
    constructor(CustomTranslationService) {
        this._injected = { CustomTranslationService };
    }

    translate(name, code) {
        const { CustomTranslationService } = this._injected;
        return CustomTranslationService.translate(name, code);
    }
}
CommonTranslation.$inject = ['CustomTranslationService'];
export default CommonTranslation;
