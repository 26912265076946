/* global angular */
'use strict';
import _ from 'underscore';

angular.module('core.existingServices').factory('NotificationService', [
    '$translate',
    'Notification',
    '$stateParams',
    '$rootScope',
    function ($translate, Notification, $stateParams, $rootScope) {
        var notifications = [];
        var grouped = {};
        var editedByCreatorNotificationsGroupByRequest = {};
        var newNotificationsGroupByRequest = {};

        function setNotificationData(eventId) {
            Notification.listUnreadByEvent(
                eventId,
                (data) => {
                    if (data && data.length > 0) {
                        notifications = data;
                        grouped = _.groupBy(data, 'type');
                        editedByCreatorNotificationsGroupByRequest = groupedByRequest(
                            grouped.REQUEST_OFFER_EDITED_BY_CREATOR
                        );
                        newNotificationsGroupByRequest = groupedByRequest(grouped.REQUEST_OFFER_NEW);
                    } else {
                        notifications = [];
                        grouped = {};
                        editedByCreatorNotificationsGroupByRequest = {};
                        newNotificationsGroupByRequest = {};
                    }
                    $rootScope.$broadcast('notification:change');
                },
                function (err) {
                    console.log(err); // TODO handle error!
                }
            );
        }

        function groupedByRequest(notifications) {
            // let _a = _.groupBy(notifications, 'request.id');
            // return _a;
            return _(notifications).groupBy(function (o) {
                return o.request.id;
            });
        }

        return {
            setNotificationData: function (eventId) {
                return setNotificationData(eventId);
            },
            getNotificationLength: function () {
                return notifications.length;
            },
            getLengthWithoutCreatorLocked: function () {
                var temNotifications = _.without(
                    grouped,
                    grouped.REQUEST_OFFER_BOOKED_LOCKED_BY_CREATOR,
                    grouped.REQUEST_OFFER_EDITED_BY_CREATOR
                );
                return temNotifications.length;
            },
            get_REQUEST_OFFER_ANSWERED: function () {
                return grouped.REQUEST_OFFER_ANSWERED;
            },
            get_REQUEST_OFFER_FINISHED_WAITING_FOR_CREATOR: function () {
                return grouped.REQUEST_OFFER_FINISHED_WAITING_FOR_CREATOR;
            },
            get_REQUEST_OFFER_CANCELED_WAITING_FOR_CREATOR: function () {
                return grouped.REQUEST_OFFER_CANCELED_WAITING_FOR_CREATOR;
            },

            get_REQUEST_OFFER_NEW: function () {
                return newNotificationsGroupByRequest;
            },
            get_REQUEST_OFFER_EDITED_BY_CREATOR: function () {
                return editedByCreatorNotificationsGroupByRequest;
            },
            get_REQUEST_OFFER_BOOKED_LOCKED_BY_CREATOR: function () {
                return grouped.REQUEST_OFFER_BOOKED_LOCKED_BY_CREATOR;
            },
            get_REQUEST_OFFER_BOOKED_WAITING_FOR_CREATOR: function () {
                return grouped.REQUEST_OFFER_BOOKED_WAITING_FOR_CREATOR;
            },
        };
    },
]);

