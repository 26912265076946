import googleAddressUtil from '../../../../utils/GoogleAddressUtil';
import GoogleAddressUtil from '../../../../utils/GoogleAddressUtil';
import EventDetailsData from '../../../../core/existingServices/data_store/EventDetailsData';
import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import { UtilFunctions } from "../../../../utils/global/utilFunctions";
import { DownloadUtilFunctions } from '../../../../utils/global/DownloadUtilFunction';

class editEventModalCtrl {
    constructor(
        $scope,
        $rootScope,
        Event,
        AuthenticationService,
        $location,
        $timeout,
        $translate,
        FieldChangedSvc,
        EventEnum,
        data,
        close,
        $element,
        RequestOfferStatusUtils,
        TimezoneService,
        CustomerUser,
        UserGroup,
        ProcessLog
    ) {
        this._injected = {
            $scope,
            $rootScope,
            Event,
            AuthenticationService,
            $location,
            $timeout,
            $translate,
            FieldChangedSvc,
            EventEnum,
            data,
            close,
            $element,
            RequestOfferStatusUtils,
            TimezoneService,
            CustomerUser,
            UserGroup,
            ProcessLog,
        };

        ScopeFunctions.addToScope($scope);
        this.editEventForms = {};
        this.data = {};
        this.statusList = [
            { name: 'ACTIVE', code: 'event.setting.status.active' },
            { name: 'CLOSED', code: 'event.setting.status.closed' },
        ];
        this.eventOfferStatusList = ['PENDING', 'WON', 'LOST'];
        this.confirmationRequired = false;
        $scope.showCropImageSectionForThumbnail = false;
        $scope.showCropImageSectionForBanner = false;
        /*  this.fieldChanged = FieldChangedSvc;*/

        this.userGroupMembersList = [];
        this.requests = data.requests;
        this.getEventDetails(data);
        this.getShowMarkup();
        this.handleOnChanged = this.handleOnChangedFn.bind(this);
        this.checkParticipantsCallback = this.checkParticipantsCallbackFn.bind(this);
        this.closeEmptyMsg = this.closeEmptyMsgFn.bind(this);
        this.checkEventDateCallback = this.checkEventDateCallbackFn.bind(this);
        this.originalData = {};
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.exportParticipants = this.exportParticipants.bind(this);
        this.startDownload = this.startDownload.bind(this);
        this.closeExportOverlay = this.closeExportOverlay.bind(this);
        this.processExport = this.processExport.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.submitEvent = this.submitEvent.bind(this);
        this.disableSaveButton = false;
        this.checkIfEventCloseIsRunningAndDisableModal(data);
        this.syncDates = true;
        this.showAlertOverlay = false;
        this.showIsNotPreventingMessage = false;
        this.showIsPreventingMessage = false;
        this.showBookerOffersInEventMessage = false;
        this.showFinishedOffersInEventMessage = false;
        this.showsentOrAnsweredOffersInEventMessage = false;
        this.sendToSuppliers = false;
        this.showSendButton = false;

        $scope.$watchCollection(
            'editEventModalController.data.tags',
            function (newData) {
                if (newData) {
                    FieldChangedSvc.checkOnChanged('tags', newData);
                    this.viewUpdateButton = FieldChangedSvc.isFieldChanged();
                }
            }.bind(this)
        );

        this._close = this._close.bind(this);
    }

    downloadTemplate() {
        let _downloadUrl = 'api/v1/file/downloadable/Tax_related_template.xlsx';
        DownloadUtilFunctions.downloadFile(_downloadUrl);
    }

    exportParticipants() {
        const { $translate } = this._injected;
        let totalCount = this.data.participantActualNumbers;
        if (totalCount < 1) {
            this.participantExportMsg = $translate.instant('no.participants.for.event');
        } else if (parseInt(totalCount) < 100) {
            this.startDownload('excel', 'xlsx');
        } else {
            this.openDialogSendToForm('excel', 'xlsx');
        }
    }

    startDownload(fileFormat, extension, sendTo) {
        let _url =
            'api/v1/participant/event/' +
            this.data.id +
            '/exportParticipants?fileformat=' +
            fileFormat +
            '&extension=' +
            extension;
        if (!!sendTo) {
            _url += '&sendTo=' + sendTo + '';
        }
        DownloadUtilFunctions.downloadFile(_url, sendTo);
    }

    openDialogSendToForm(fileFormat, extension) {
        this.openDialogExportToForm = true;
        this.sendTo = '';
        this.updateLoggedUserEmail();
        // OgModal.open('participant:sendTo', {
        //     fileFormat: fileFormat,
        //     extension: extension,
        //     successCallback: this.startDownload
        // });
    }

    updateLoggedUserEmail() {
        const { AuthenticationService } = this._injected;
        var userData = AuthenticationService.getUserData();
        if (userData !== null) {
            this.sendTo = userData.email;
        }
    }

    processExport() {
        this.startDownload('excel', 'xlsx', this.sendTo);
        this.closeExportOverlay();
    }

    closeExportOverlay() {
        this.openDialogExportToForm = false;
    }

    uploadTaxRelatedFiles() {
        const { $scope, Event } = this._injected;

        let _params = {
            attachedFile: this.data.file,
            eventId: this.data.id,
        };
        $scope.startProgress();
        Event.uploadTaxRelatedFile(
            _params,
            (resp) => {
                this.data.taxRelatedFiles = resp.files;
                $scope.endProgress();
                this.data.file = null;
                jQuery('#tax-file-url').val('');
            },
            $scope.endWithErrorOverlay()
        );
    }

    clearUploadedFile() {
        this.data.file = null;
        jQuery('#tax-file-url').val('');
    }

    deleteUploadedFile(id) {
        const { $scope, Event } = this._injected;
        $scope.startProgress();
        Event.deleteTaxRelatedFile(
            id,
            (resp) => {
                this.data.taxRelatedFiles = resp.files;
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    uploadEventThumbnailImage() {
        const { $scope } = this._injected;
        this.event = this.data;
        this.itemType = 'logoUrl';
        $scope.showCropImageSectionForThumbnail = true;
        /*let initParams = { event: this.data, itemType: 'logoUrl' };
        OgModal.open('event:image', initParams);*/
    }

    uploadEventBannerImage() {
        const { $scope } = this._injected;
        this.event = this.data;
        this.bannerItemType = 'bigImage';
        $scope.showCropImageSectionForBanner = true;
        /*let initParams = { event: this.data, itemType: 'bigImage' };
        OgModal.open('event:banner:image', initParams);*/
    }

    closeImageCropSection() {
        const { $scope } = this._injected;
        $scope.showCropImageSectionForThumbnail = false;
        $scope.showCropImageSectionForBanner = false;
    }

    updateThumbnailImage(formData) {
        const { $scope, Event, $rootScope } = this._injected;

        Event.updateEventImage(
            formData,
            function (data) {
                let _thumbnailImageData = data;
                $scope.showCropImageSectionForThumbnail = false;
                $scope.eventThumbnailImageId = _thumbnailImageData.imageId;
            },
            $scope.endWithErrorOverlay
        );
    }

    updateBannerImage(formData) {
        const { $scope, Event, $rootScope } = this._injected;
        Event.updateBannerImage(
            formData,
            function (data) {
                let _bannerImageData = data;
                $scope.showCropImageSectionForBanner = false;
                $scope.eventBannerImageId = _bannerImageData.bannerImageId;
                $rootScope.$broadcast('event:banner:image:changed', {bannerImageId: _bannerImageData.bannerImageId});
            },
            $scope.endWithErrorOverlay
        );
    }

    closeEmptyMsgFn() {
        this.participantExportMsg = null;
    }

    checkParticipantsCallbackFn(response) {
        const { $scope, $translate } = this._injected;
        let isAffected = response.affected && response.affected.length > 0;
        let isNotAffected = response.notAffected && response.notAffected.length > 0;
        let isPreventing = response.preventing && response.preventing.length > 0;
        $scope.endProgress();
        if (isPreventing) {
            this.preventingParts = response.preventing;
            this.showAlertOverlay = true;
            this.showIsPreventingMessage = true;
            this.showIsNotPreventingMessage = false;
            // this.isPreventingMessage = message;
        } else if (isAffected || isNotAffected) {
            if (this.showAmountChangedMessage || this.showDateAndAmountChangedMessage) {
                this.affectedParts = response.affected;
                this.notAffectedParts = response.notAffected;
            }
            if (this.showDateChangedMessage) {
                this.dateChangedParts = response.allParts;
            }
            this.showAlertOverlay = true;
            this.showIsNotPreventingMessage = true;
            this.showIsPreventingMessage = false;
            // this.isNotPreventingMessage = message;
        } else {
            this.checkEventStatusUpdate();
        }
    }

    checkEventDateCallbackFn(response) {
        const { $scope, $translate } = this._injected;

        $scope.endProgress();
        let isAffected = response.affectingParts && response.affectingParts.length > 0;
        if (isAffected) {
            // this.showSendButton = true;

            if (isAffected) {
                this.dateChangedParts = response.affectingParts;
                this.showAlertOverlay = true;
                this.showIsNotPreventingMessage = true;
                this.showIsPreventingMessage = false;
            } else {
                this.showAlertOverlay = true;
                this.showIsPreventingMessage = true;
                this.showIsNotPreventingMessage = false;
            }
        } else {
            this.checkEventStatusUpdate();
        }
    }

    translateTextByCode(text, code) {
        return CommonFunctions.getTranslatedTextFromCode(text, code);
    }

    hideAlertOverlay() {
        this.showAlertOverlay = false;
    }

    submitEvent() {
        const { $rootScope, $scope, $location, $timeout, Event, EventEnum, FieldChangedSvc } = this._injected;
        let validForms = 1;
        this.data.sendToSuppliers = this.sendToSuppliers;
        for (let form in this.editEventForms) {
            validForms *= this.editEventForms[form].$valid;
        }
        if (!validForms) {
            for (let form in this.editEventForms) {
                CommonFunctions.touchFormFields(this.editEventForms[form]);
            }
            return;
        } else {
            if (this.data.id !== undefined) {
                $scope.startProgress();
                Event.update(
                    this.data,
                    (data) => {
                        $rootScope.$broadcast('event:update:event', data);
                        // if participant amount has been change. We need to refresh the event details and sidebar as well
                        if (
                            FieldChangedSvc.isParticularFieldChanged('participantAmount') ||
                            FieldChangedSvc.isParticularFieldChanged('status.name')
                        ) {
                            $rootScope.$broadcast(EventEnum.EVENT_DETAIL_PAGE_RELOAD);
                            $rootScope.$broadcast(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                        }
                        $scope.endProgress();
                        $timeout(() => {
                            this._close();
                        }, 200);
                    },
                    $scope.endWithErrorOverlay
                );
            } else {
                Event.save(
                    this.data,
                    (data) => {
                        $rootScope.$emit('event:user:action', {
                            action: 'create.event',
                            data: {
                                'No. participants': data.participantAmount,
                                'Event length': 1 + Math.round((data.toDate - data.fromDate) / (3600 * 24 * 1000)),
                                'Days before start': Math.floor(
                                    (new Date(data.fromDate).getTime() - new Date(data.dateCreated).getTime()) /
                                    (1000 * 60 * 60 * 24)
                                ),
                            },
                        });

                        $scope.endProgress();
                        this._close();
                        $location.url('/event/' + data.id);
                    },
                    this.endWithError
                );
            }
        }
    }

    cancelActiveOffers() {
        const { $scope, $rootScope, Event, EventEnum } = this._injected;
        this.btnDisabled = true;
        this.hideAlertOverlay();
        let params = this.data;
        let eventId = this.data.id;
        Event.cancelActiveOffers(
            eventId,
            params,
            () => {
                // this.submitEvent(); cancels runs in background, call submit method once cancellation is successful.
                this._close();
                $rootScope.$broadcast(EventEnum.REQUEST_OFFERS_CLOSE_PROCESS_STATUS_CHECK);
            },
            $scope.endWithErrorOverlay
        );
    }

    checkEventStatusUpdate(response) {
        const { $scope, Event } = this._injected;
        $scope.startProgress();
        Event.checkEventStatusUpdate(
            { eventId: this.data.id, status: this.data.status.name },
            function (response) {
                if (response.bookedOffersInEvent) {
                    this.showAlertOverlay = true;
                    this.showBookerOffersInEventMessage = true;
                } else if (response.finishedOffersInEvent) {
                    this.showAlertOverlay = true;
                    this.showFinishedOffersInEventMessage = true;
                } else if (response.sentOrAnsweredOffersInEvent) {
                    this.showAlertOverlay = true;
                    this.showsentOrAnsweredOffersInEventMessage = true;
                } else {
                    this.submitEvent();
                }
                $scope.endProgress();
            }.bind(this),
            $scope.endWithErrorOverlay
        );
    }

    saveAndSend() {
        const { $rootScope, $scope, $timeout, Event, EventEnum, FieldChangedSvc } = this._injected;
        $scope.startProgress();
        Event.update(
            this.data,
            (data) => {
                $rootScope.$broadcast('event:update:event', data);
                if (
                    FieldChangedSvc.isParticularFieldChanged('participantAmount') ||
                    FieldChangedSvc.isParticularFieldChanged('fromDate') ||
                    FieldChangedSvc.isParticularFieldChanged('toDate')
                ) {
                    $rootScope.$broadcast(EventEnum.EVENT_DETAIL_PAGE_RELOAD);
                    $rootScope.$broadcast(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                }
                $scope.endProgress();
                $timeout(() => {
                    this._close();
                }, 200);
            },
            $scope.endWithErrorOverlay
        );
    }

    updateAddress() {
        this.handleOnChanged('eventAddress.displayAddress', this.data.eventAddress.displayAddress);
    }

    checkAndSubmitEvent() {
        const { $rootScope, EventEnum, $timeout } = this._injected;
        let _displayAddress = angular.copy(this.data.eventAddress.displayAddress);
        if (_displayAddress) {
            let _correctedDisplayAddress = GoogleAddressUtil.getCorrectedDisplayAddressManually(_displayAddress);
            window.googleMapsCallback(() => {
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: _correctedDisplayAddress }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        var place = results[0];
                        this.data.eventAddress = googleAddressUtil.parseGoogleAddress(place);
                        this.data.eventAddress.displayAddress = _displayAddress;
                        this.data.location = _displayAddress;
                        let _bounds = place.geometry.bounds;
                        if (_bounds) {
                            let _swLat = _bounds.getSouthWest().lat();
                            let _swLng = _bounds.getSouthWest().lng();
                            let _neLat = _bounds.getNorthEast().lat();
                            let _neLng = _bounds.getNorthEast().lng();
                            this.bounds = { minLng: _swLng, maxLng: _neLng, minLat: _swLat, maxLat: _neLat };
                            if (place.geometry.location) {
                                let _lat = place.geometry.location.lat();
                                let _lng = place.geometry.location.lng();
                                this.location = { lat: _lat, lng: _lng };
                            }
                        } else if (place.geometry.location) {
                            let _lat = place.geometry.location.lat();
                            let _lng = place.geometry.location.lng();
                            this.location = { lat: _lat, lng: _lng };
                        }
                        $rootScope.$broadcast(EventEnum.EVENT_ADDRESS_CHANGED, {
                            location: this.location,
                            bounds: this.bounds,
                        });
                    }
                    $timeout(() => {
                        this.checkForAmountAndStatusUpdateAndSubmitEvent();
                    }, 200);
                });
            });
        } else {
            this.resetEventAddress();
            $rootScope.$broadcast(EventEnum.EVENT_ADDRESS_CHANGED, { location: {}, bounds: {} });
            this.checkForAmountAndStatusUpdateAndSubmitEvent();
        }
    }

    resetEventAddress() {
        this.data.eventAddress.street = '';
        this.data.eventAddress.route = '';
        this.data.eventAddress.town = '';
        this.data.eventAddress.state = '';
        this.data.eventAddress.municipality = '';
        this.data.eventAddress.country = '';
        this.data.eventAddress.zipCode = '';
        this.data.eventAddress.geoCoordinates = '';
        this.data.eventAddress.streetAddress = '';
        this.data.location = '';
    }

    checkForAmountAndStatusUpdateAndSubmitEvent() {
        const { $scope, Event, FieldChangedSvc } = this._injected;
        this.showAmountChangedMessage = false;
        this.showDateChangedMessage = false;
        this.showDateAndAmountChangedMessage = false;
        var dataId = this.data.id > 0;
        var participantAmountChanged = FieldChangedSvc.isParticularFieldChanged('participantAmount');
        var statusChanged = FieldChangedSvc.isParticularFieldChanged('status.name');
        let _dateChanged =
            FieldChangedSvc.isParticularFieldChanged('fromDate') || FieldChangedSvc.isParticularFieldChanged('toDate');

        if (dataId && statusChanged) {
            this.checkEventStatusUpdate();
        } else if (dataId && participantAmountChanged && !_dateChanged) {
            this.showAmountChangedMessage = true;
            this.data.changedField = 'AMOUNT';
            $scope.startProgress();
            Event.checkParticipantAmountUpdate(
                { id: this.data.id },
                this.checkParticipantsCallback,
                $scope.endWithError
            );
        } else if (dataId && !participantAmountChanged && _dateChanged) {
            this.data.changedField = 'DATE';
            $scope.startProgress();
            Event.checkEventDateUpdate(
                { id: this.data.id },
                (response) => {
                    $scope.endProgress();
                    if (response.showDateOverlay) {
                        this.showDateChangedMessage = true;
                        this.showAlertOverlay = true;
                        this.showIsNotPreventingMessage = true;
                        this.showIsPreventingMessage = false;
                    } else {
                        this.submitEvent();
                    }
                },
                $scope.endWithError
            );
        } else if (dataId && participantAmountChanged && _dateChanged) {
            this.showDateAndAmountChangedMessage = true;
            this.data.changedField = 'AMOUNT_AND_DATE';
            $scope.startProgress();
            Event.checkParticipantAmountUpdate(
                { id: this.data.id },
                this.checkParticipantsCallback,
                $scope.endWithError
            );
        } else {
            this.submitEvent();
        }
    }

    updateVatSettingForEvent() {
        const { $scope, Event, FieldChangedSvc, $rootScope, EventEnum } = this._injected;
        var data = {
            enableVat: this.data.enableVat,
        };
        $scope.startProgress();
        this.viewUpdateButton = false;
        Event.updateVatSetting(
            this.data.id,
            data,
            (data) => {
                this.originalData.enableVat = data.isVatEnabled;
                FieldChangedSvc.reset();
                FieldChangedSvc.init(this.originalData);
                EventDetailsData.setVat(data.isVatEnabled);
                $rootScope.$broadcast(EventEnum.EVENT_VAT_SETTING_CHANGED, data);
                $scope.endProgress();
            },
            (err) => {
                this.viewUpdateButton = true;
                $scope.endWithError;
            }
        );
    }

    getEventDetails(data) {
        const { $scope, Event, FieldChangedSvc, $translate } = this._injected;
        $scope.startProgress();
        Event.getEventDetails(
            data.eventId,
            function (response) {
                this.data = response.eventDetails;
                this.originalData = angular.copy(response.eventDetails);
                this.timeDiffInMillis = this.data.toDate - this.data.fromDate;
                $scope.eventThumbnailImageId = response.eventDetails.imageId;
                $scope.eventBannerImageId = response.eventDetails.bannerImageId;
                FieldChangedSvc.reset();
                FieldChangedSvc.init(this.originalData);
                this.viewUpdateButton = false;

                this.userGroupMembersList = response.users;
                this.isEventDeletable();
                this.getTags(data);
                if (data.perferTab === 'TAX_RELATED_TAB') {
                    this.activateTab('tax_related');
                }
                $scope.endProgress();
            }.bind(this),
            this.endWithErrorOverlay
        );
    }

    getShowMarkup() {
        const { $scope, UserGroup } = this._injected;
        UserGroup.getShowMarkup(
            ((response) => {
                this.showMarkup = response.showMarkup;
            }).bind(this),
            (error) => {},
            $scope.endWithErrorOverlay()
        );
    }

    checkIfEventCloseIsRunningAndDisableModal(data) {
        const { ProcessLog } = this._injected;
        ProcessLog.get({ referenceId: data.eventId, name: 'EVENT_CLOSE' }, (resp) => {
            if (resp && resp.status && (resp.status.name == 'RUNNING' || resp.status.name == 'RUNNING_WITH_MESSAGE')) {
                this.disableSaveButton = true;
            } else {
                this.disableSaveButton = false;
            }
        });
    }

    activateTab(tab) {
        jQuery('.nav-tabs a[href="#' + tab + '"]').tab('show');
    }

    getGroups() {
        const { $scope, AuthenticationService } = this._injected;
        let groups = AuthenticationService.getUserGroups();
        this.userGroups = [];
        angular.forEach(
            groups,
            function (group) {
                if (!group.supplier || !UtilFunctions.isGreaterOrEqualToZero(group.supplier.id)) {
                    this.userGroups.push(group);
                }
            }.bind(this)
        );
    }

    getTags(data) {
        const { $timeout, AuthenticationService } = this._injected;
        if (data.eventId !== undefined) {
            let tagEventsElm = jQuery('#eventTags');
            tagEventsElm.tagit({
                afterTagAdded: (event, ui) => {
                    $timeout(() => {
                        if (this.data.tags.indexOf(ui.tagLabel) == -1) {
                            this.data.tags.push(ui.tagLabel);
                        }
                    });
                },
                afterTagRemoved: (event, ui) => {
                    $timeout(() => {
                        var tags = [];
                        if (this.data) {
                            this.data.tags.forEach((tag) => {
                                if (ui.tagLabel !== tag) {
                                    tags.push(tag);
                                }
                            });
                        }
                        this.data.tags = tags;
                    });
                },
            });
            tagEventsElm.tagit('removeAll');
            this.data.tags.forEach((tag) => {
                tagEventsElm.tagit('createTag', tag);
            });
            this.eventId = this.data.id;

            if (this.data.ownerGroup) {
                this.data.ownerGroupId = this.data.ownerGroup.id;
            }
            this.getGroups();
            this.showGroupsSelect =
                !(this.data.isTemplate && this.data.isPublic) &&
                (!this.data.ownerGroupId ||
                    AuthenticationService.getUserData().userGroup.id === this.data.ownerGroupId);
        } else {
            this.eventId = null;
            this.clearDialog();
        }
    }

    clearDialog() {
        this.requests = [];
        this.data = {};
        this.data.tags = [];
        this.showGroupsSelect = false;
        this.userGroupMembersList = [];
    }

    handleOnChangedFn(modalName, currentValue) {
        const { FieldChangedSvc } = this._injected;
        FieldChangedSvc.checkOnChanged(modalName, currentValue);
        this.viewUpdateButton = FieldChangedSvc.isFieldChanged();
    }

    disabled(item) {
        const { $rootScope } = this._injected;
        return !this.data || !this.data.userRole || this.data.userRole[item] === $rootScope.rights.NONE;
    }

    deleteEvent() {
        const { $scope, $location, Event, $timeout } = this._injected;
        $scope.startProgress();
        Event['delete'](
            this.data.id,
            () => {
                $scope.endProgress();
                this._close();
                $timeout(() => {
                    $location.url('/events');
                }, 500);
            },
            $scope.endWithErrorOverlay
        );
    }

    closeEvent() {
        const { $scope, Event, $timeout, $rootScope, FieldChangedSvc } = this._injected;
        $scope.startProgress();
        this.data.status['name'] = 'CLOSED';
        Event.update(
            this.data,
            (data) => {
                $rootScope.$broadcast('event:update:event', data);
                // if participant amount has been change. We need to refresh the event details and sidebar as well
                if (
                    FieldChangedSvc.isParticularFieldChanged('participantAmount') ||
                    FieldChangedSvc.isParticularFieldChanged('fromDate') ||
                    FieldChangedSvc.isParticularFieldChanged('status.name')
                ) {
                    $rootScope.$broadcast(EventEnum.EVENT_DETAIL_PAGE_RELOAD);
                    $rootScope.$broadcast(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                }
                $scope.endProgress();
                $timeout(() => {
                    this._close();
                }, 200);
            },
            $scope.endWithErrorOverlay
        );
    }

    isEventDeletable() {
        const { $scope } = this._injected;
        this.isEventEditable =
            !this.isAnyRequestOfferBookedInEvent() && !this.disabled('DELETE') && this.data.status.name !== 'CLOSED';
    }

    isAnyRequestOfferBookedInEvent() {
        const { $scope } = this._injected;
        if (!this.requests) {
            return false;
        }
        let length = this.requests.length;
        for (let i = 0; i < length; i++) {
            let request = this.requests[i];
            if (!request.isInternal && this.isAnyRequestOfferBooked(request.requestOffers)) {
                return true;
            }
        }
        return false;
    }

    dataLoaded(data) {
        const { FieldChangedSvc } = this._injected;

        this.data = angular.copy(data);
        this.originalData = angular.copy(data);

        FieldChangedSvc.reset();
        FieldChangedSvc.init(angular.copy(data));
        this.viewUpdateButton = false;
        /*    this.checkIfEditable();*/
    }

    cancelChange() {
        this.dataLoaded(this.originalData);
        //this._close();
    }

    isAnyRequestOfferBooked(offers) {
        const { RequestOfferStatusUtils } = this._injected;
        if (!offers) {
            return false;
        }

        let length = offers.length;
        for (let i = 0; i < length; i++) {
            let offer = offers[i];
            let status = offer.status;
            let statusDetailed = offer.statusDetailed;
            let statusLogs = offer.statusLogs;
            if (
                status &&
                statusDetailed &&
                statusDetailed.name !== 'INTERNAL' &&
                (RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(status.name) > -1 || status.name === 'FEE_RECEIVED')
            ) {
                return offer;
            }
            if (statusLogs.some((log) => RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(log) !== -1)) {
                return offer;
            }
        }
        return false;
    }

    _close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

editEventModalCtrl.$inject = [
    '$scope',
    '$rootScope',
    'Event',
    'AuthenticationService',
    '$location',
    '$timeout',
    '$translate',
    'FieldChangedSvc',
    'EventEnum',
    'data',
    'close',
    '$element',
    'RequestOfferStatusUtils',
    'TimezoneService',
    'CustomerUser',
    'UserGroup',
    'ProcessLog',
];
export default editEventModalCtrl;
