/* global angular */
'use strict';
/**
 * This service holds the event details in a page. Should be refreshed wheneever there is change in event details
 */
const FILE_BASE_URL = 'api/v1/file';
import {environment} from "../../../environments/environment";

class FileService {
    constructor($resource, $http) {
        this._injected = {$resource, $http};
    }

    uploadFile(file, successCallback, errorCallback) {
        const {$http} = this._injected;
        var formData = new FormData();
        formData.append('file', file);
        $http
            .post(`${FILE_BASE_URL}/upload`, formData, {
                transformRequest: angular.identity,
                headers: {'Content-Type': undefined},
            })
            .then(function (resp) {
                successCallback(resp.data);
            }, function (error) {
                errorCallback(error);
            });
    }

    saveEventFiles(data, successCallback, errorCallback) {
        const {$http} = this._injected;
        $http
            .post(`${FILE_BASE_URL}/eventFiles/store`, data, {
                headers: {'Content-Type': 'application/json'},
            })
            .then(successCallback, errorCallback);
    }

    listAllFiles(query, successCallback, errorCallback) {
        const {$http} = this._injected;
        $http
            .post(`${FILE_BASE_URL}/eventFiles/list`, query, {
                headers: {'Content-Type': 'application/json'},
            })
            .then(successCallback, errorCallback);
    }

    downloadFile(url, filename) {
        var linkElement = document.createElement('a');
        var status = false;
        const BASE_URL = environment.apiUrl;
        var _finalUrl = '';
        if (url.includes(BASE_URL)) {
            _finalUrl = url;
        } else {
            _finalUrl = BASE_URL.concat(url);
        }
        try {
            linkElement.setAttribute('href', _finalUrl);
            linkElement.setAttribute('download', filename);

            var clickEvent = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: false,
            });
            linkElement.dispatchEvent(clickEvent);
            status = true;
        } catch (ex) {
            console.log(ex);
        }
        if (!status) {
            const {$http} = this._injected;
            $http
                .get(_finalUrl, {responseType: 'arraybuffer'})
                .then(function (data, status, headers) {
                    var octetStreamMime = 'application/octet-stream';
                    var success = false;
                    headers = headers();

                    // Determine the content type from the header or default to "application/octet-stream"
                    var contentType = headers['content-type'] || octetStreamMime;
                    var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                    var uri = _finalUrl;
                    if (urlCreator) {
                        try {
                            // Try using msSaveBlob if supported
                            console.log('Trying saveBlob method ...');
                            var blob = new Blob([data], {type: contentType});
                            if (navigator.msSaveBlob) navigator.msSaveBlob(blob, filename);
                            else {
                                // Try using other saveBlob implementations, if available
                                var saveBlob =
                                    navigator.webkitSaveBlob || navigator.mozSaveBlob || navigator.saveBlob;
                                if (saveBlob === undefined) throw 'Not supported';
                                saveBlob(blob, filename);
                            }
                            console.log('saveBlob succeeded');
                            success = true;
                        } catch (ex) {
                            console.log('saveBlob method failed with the following exception:');
                            console.log(ex);
                        }
                        if (!success) {
                            // Fallback to window.location method
                            try {
                                // Prepare a blob URL
                                // Use application/octet-stream when using window.location to force download
                                console.log('Trying download link method with window.location ...');
                                window.location = uri;
                                console.log('Download link method with window.location succeeded');
                                success = true;
                            } catch (ex) {
                                console.log(
                                    'Download link method with window.location failed with the following exception:'
                                );
                                console.log(ex);
                            }
                            if (!success) {
                                // Fallback to window.open method
                                console.log(
                                    'No methods worked for saving the arraybuffer, using last resort window.open'
                                );
                                window.open(uri, '_blank', '');
                            }
                        }
                    }
                }, (data, status) => {
                    console.log('Request failed with status: ' + status);
                });
        }
    }
}

FileService.$inject = ['$resource', '$http'];
angular.module('core.existingServices').factory('FileService', FileService);

