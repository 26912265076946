export default function (appModule) {
    appModule.config([
        '$routeProvider',
        '$locationProvider',
        '$stateProvider',
        function ($routeProvider, $locationProvider, $stateProvider) {
            $stateProvider
                .state('event.detail.rating.creator', {
                    url: '/creator',
                    template: '<creator-rating-detail></creator-rating-detail>'
                })
                .state('event.detail.rating.participants', {
                    url: '/participants',
                    template: '<participants-rating-detail></participants-rating-detail>'
                });
        },
    ])
}
