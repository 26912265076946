import amountAndPriceService from '../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import requestOfferPartsUtil from '../../../../utils/request_offer_parts/RequestOfferPartsUtil';
import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('ogBudgetDetail').directive('ogBudgetDetail', [
        function () {
            return {
                restrict: 'EA',
                scope: {
                    showMarkedPrice: '=',
                    request: '=',
                    event: '=',
                    eventCreator: '=',
                    sharedPublicly: '=',
                    decisionMaker: '=',
                    expandOptions: '=',
                    selectedContentList: '=',
                    showInternalContentComment: '=',
                    isPrintCall: '=',
                },
                templateUrl: './app/components/directives/budget/budget_detail/og.budget.detail.view.html',
                controllerAs: 'ogBudgetDetailCtrl',
                bindToController: true,
                controller: [
                    '$scope',
                    '$log',
                    '$rootScope',
                    'BudgetDataShareService',
                    'ClientSelectedContent',
                    '$translate',
                    'FilledRequestOfferPartComment',
                    'DynamicFormUtilService',
                    'EventEnum',
                    function (
                        $scope,
                        $log,
                        $rootScope,
                        BudgetDataShareService,
                        ClientSelectedContent,
                        $translate,
                        FilledRequestOfferPartComment,
                        DynamicFormUtilService,
                        EventEnum
                    ) {

                        var vm = this;
                        this.$onInit = function () {
                            var vm = this;

                            vm.calculateTotalPrice = calculateTotalPriceFn;
                            vm.filter = filterFn;
                            vm.includeVat = vm.event.enableVat;
                            vm.getAmount = amountAndPriceService.getAmount;
                            vm.getDifference = amountAndPriceService.getDifference;
                            vm.getPrice = amountAndPriceService.getPrice;
                            vm.getAmountLocalisedWithCurrency = amountAndPriceService.getAmountLocalisedWithCurrency;
                            vm.getTotalPrice = amountAndPriceService.getTotalPrice;
                            vm.viewPriceLocalisedWithCurrency = viewPriceLocalisedWithCurrencyFn;
                            vm.selectedOfferListPrice = selectedOfferListPriceFn;
                            vm.setDisplayDiffColmn = setDisplayDiffColmnFn;
                            vm.setSelectedOffersOriginalPrice = setSelectedOffersOriginalPriceFn;
                            vm.setSelectedOffersPriceDifference = setSelectedOffersPriceDifferenceFn;
                            vm.toggleRequestWise = toggleRequestWiseFn;
                            vm.toggleRequestPart = toggleRequestPartFn;
                            vm.recalculateOfferWiseTotal = recalculateOfferWiseTotalFn;
                            vm.recalculateOfferWiseTotalWithDiscount = recalculateOfferWiseTotalWithDiscountFn;
                            vm.getSelectedOfferDiscount = getSelectedOfferDiscountFn;
                            vm.getAdditionalDetails = getAdditionalDetailsFn;
                            vm.getOfferPartAdditionalQuestionClass = getOfferPartAdditionalQuestionClassFn;
                            vm.toggleResourceViewOption = toggleResourceViewOptionFn;
                            vm.getPriceFromPartOption = getPriceFromPartOptionFn;
                            vm.getRequestPartOptionTotalPrice = getRequestPartOptionTotalPriceFn;
                            vm.toggleAdditionalQuestionViewOption = toggleAdditionalQuestionViewOptionFn;
                            vm.round = roundFn;
                            vm.requestWiseToggle = true;
                            vm.supplierDiscountEnabled = vm.event && vm.event.ownerGroup && vm.event.ownerGroup.supplierDiscount.name === 'TRUE';
                            vm.request.view = 'advanced';
                            vm.getAmountType = function (amountType) {
                                if (amountType === 'SAME_AS_EVENT' || amountType === 'PEOPLE') {
                                    return $translate.instant('amount.type.PEOPLE.short');
                                } else if (amountType == 'PIECES') {
                                    return $translate.instant('amount.type.PIECES.short');
                                }
                                return amountType;
                            };
                            // vm.selectedOfferList = {};
                            vm.totalPrice = 0;
                            vm.offers = [];

                            ScopeFunctions.addToScope($scope);

                            initFn();
                        }

                        $rootScope.$on(EventEnum.EVENT_VAT_SETTING_CHANGED, (event, data) => {
                            vm.includeVat = data.isVatEnabled;
                            selectedOfferListPriceFn();
                        });

                        function calculateTotalPriceFn(requestId, offer) {
                            syncSelectedPartsFn();
                            let _offer = _.find(vm.filteredOffers, (o) => {
                                return offer.id === o.id;
                            });
                            vm.request.selectedOfferId = _offer.id;
                            BudgetDataShareService.setSelectedOfferList(requestId, _offer);
                            vm.selectedOfferListPrice();
                        }

                        function filterFn(offer) {
                            var filter = false;
                            /*The offer should either have a supplier and not be in Cancelled State or should have no
                         supplier and Internal.The offer should have offer parts.*/

                            if (
                                offer.offerParts &&
                                ((offer.supplier && offer.statusDetailed.name !== 'CANCELED') ||
                                    offer.statusDetailed.name === 'INTERNAL')
                            ) {
                                angular.forEach(offer.offerParts, function (part) {
                                    if (part.price || part.finalPrice) {
                                        filter = true;
                                    }
                                    if (part.hasRequestOfferPartOptions && !filter) {
                                        angular.forEach(part.offerPartOptions, (offerPartOption) => {
                                            if (offerPartOption.price || offerPartOption.finalPrice) {
                                                filter = true;
                                            }
                                        });
                                    }
                                });
                            }
                            return filter;
                        }

                        function viewPriceLocalisedWithCurrencyFn(price, currency, digitsAfterDecimal = 0) {
                            return amountAndPriceService.viewPrice(price, currency, digitsAfterDecimal);
                        }

                        function filterOffersToDisplayFn() {
                            let _filteredOffers = [];
                            angular.forEach(vm.requestOffers, function (offer) {
                                if (filterFn(offer)) {
                                    _filteredOffers.push(offer);
                                }
                            });
                            vm.filteredOffers = _filteredOffers;
                        }

                        function initFn() {
                            vm.requestId = vm.request.id;
                            vm.requestOffers = angular.copy(vm.request.requestOffers);
                            vm.selectedOfferPartOption = BudgetDataShareService.getSelectedOfferPartOption();
                            vm.selectedOfferPartOptionLength = vm.selectedOfferPartOption.length;
                            filterOffersToDisplayFn();
                            $scope.startProgress();
                            processBudgetDataFn();
                            $scope.endProgress();
                            /*This is for refreshing the selected offer list when an Internal offer has been made External,
                         thereby removing the offer from the selected offer list. For an internal offer, the request will always
                         have only one request offer.*/
                            if (vm.requestOffers.length == 1) {
                                BudgetDataShareService.checkAndRemoveOfferFromSelectedOfferList(
                                    vm.requestId,
                                    vm.requestOffers[0]
                                );
                            }
                        }

                        function getSelectedOfferFn() {
                            if (vm.selectedContentList && vm.selectedContentList.length > 0) {
                                let _selectedOfferIds = vm.selectedContentList.map((content) => {
                                    return content.offer.id;
                                });
                                let _offer = _.find(vm.filteredOffers, (offer) => {
                                    return _selectedOfferIds.indexOf(offer.id) > -1;
                                });
                                if (!_offer) {
                                    _offer = angular.copy(vm.filteredOffers[0]);
                                    angular.forEach(_offer.offerParts, (part) => {
                                        part.selected = false;
                                    });
                                    vm.requestWiseToggle = false;
                                }
                                BudgetDataShareService.setSelectedOfferList(vm.requestId, _offer); // offers
                                vm.selectedOffer = angular.copy(_offer);
                                return _offer;
                            } else if (vm.request.selectedOfferId) {
                                let _offer = _.find(vm.filteredOffers, (offer) => {
                                    return offer.id === vm.request.selectedOfferId;
                                });
                                if (!_offer) {
                                    _offer = angular.copy(vm.filteredOffers[0]);
                                    vm.requestWiseToggle = false;
                                }
                                if (!vm.isPrintCall) {
                                    BudgetDataShareService.setSelectedOfferList(vm.requestId, _offer);
                                } // offers
                                vm.selectedOffer = angular.copy(_offer);
                                return _offer;
                            } else {
                                let _offer = angular.copy(vm.filteredOffers[0]);
                                BudgetDataShareService.setSelectedOfferList(vm.requestId, _offer);
                                vm.selectedOffer = _offer;
                                return _offer;
                            }
                        }

                        function processBudgetDataFn() {
                            vm.offerWiseTotal = [];
                            if (vm.filteredOffers.length <= 0) {
                                return;
                            }
                            let _selectedOffer = getSelectedOfferFn();
                            let originalParts = requestOfferPartsUtil.filterOfferPartsForCreator(
                                _selectedOffer,
                                _selectedOffer.offerParts
                            );
                            var result = [];
                            angular.forEach(originalParts, (part) => {
                                if (part.nameCode !== 'common.translate.offer.part.type.cancellation.fee') {
                                    var myPart = {
                                        id: part.id,
                                        name: part.name,
                                        nameCode: part.nameCode,
                                        status: part.status,
                                        dateTimeFrom: part.dateTimeFrom,
                                        dateCreated: part.dateCreated,
                                        dateTimeTo: part.dateTimeTo,
                                        amountType: part.amountType,
                                        amount: part.amount,
                                        offer: { id: part.offer.id },
                                        referenceId: part.referenceId,
                                        commentByCreator: part.commentByCreator,
                                        commentByCreatorCode: part.commentByCreatorCode,
                                        hasAdditionalQuestions: part.hasAdditionalQuestions,
                                        hasRequestOfferPartOptions: part.isSuggestionEnabled,
                                    };
                                    if (vm.selectedContentList && vm.selectedContentList.length > 0) {
                                        let _index = _.findIndex(vm.selectedContentList, {
                                            referenceId: part.referenceId,
                                        });
                                        myPart.selected = vm.decisionMaker && _index > -1;
                                    } else {
                                        myPart.selected = part.selected;
                                    }

                                    //selected: part.selected
                                    var offerWiseData = [];
                                    var offerWisePriceDiff = [];
                                    angular.forEach(vm.filteredOffers, (offer) => {
                                        var total = 0;
                                        var difference = 0;
                                        var offerData = {
                                            supplier: offer.supplier ? offer.supplier.name : 'INTERNAL',
                                            status: offer.status.name,
                                            offer: {id: offer.id, discount: offer.discount, status: offer.status, currency: offer.currency},
                                        };
                                        let _offerParts = requestOfferPartsUtil.filterOfferPartsForCreator(
                                            offer,
                                            offer.offerParts
                                        );
                                        angular.forEach(_offerParts, (offerPart) => {
                                            if (
                                                offerPart.nameCode !==
                                                'common.translate.offer.part.type.cancellation.fee'
                                            ) {
                                                offerPart.newPrice = offerPart.newPrice
                                                    ? offerPart.newPrice
                                                    : offerPart.price;
                                                if (part.referenceId === offerPart.referenceId) {
                                                    offerData.id = offerPart.id;
                                                    offerData.name = offerPart.name;
                                                    offerData.nameCode = offerPart.nameCode;
                                                    offerData.vat = offerPart.vat;
                                                    offerData.price = offerPart.price;
                                                    offerData.newPrice = offerPart.newPrice;
                                                    offerData.finalPrice = offerPart.finalPrice;
                                                    offerData.amount = offerPart.amount;
                                                    offerData.finalAmount = offerPart.finalAmount;
                                                    offerData.hasRequestOfferPartOptions =
                                                        offerPart.hasRequestOfferPartOptions;
                                                    if (offerPart.markupValue > 0 && vm.showMarkedPrice) {
                                                        offerData.price +=
                                                            (offerData.price * offerPart.markupValue) / 100;
                                                        offerData.newPrice +=
                                                            (offerData.newPrice * offerPart.markupValue) / 100;
                                                        offerData.finalPrice +=
                                                            (offerData.finalPrice * offerPart.markupValue) / 100;
                                                    }
                                                    if (vm.selectedContentList && vm.selectedContentList.length > 0) {
                                                        offerData.offerPartOptions = offerPart.offerPartOptions;
                                                        let _selectedOfferPartOptionsList = [];
                                                        let _selectedContent = _.find(vm.selectedContentList, {
                                                            referenceId: part.referenceId,
                                                        });
                                                        if (_selectedContent && _selectedContent.offerPartOptionsId) {
                                                            let _optionsId =
                                                                _selectedContent.offerPartOptionsId.split('~');
                                                            angular.forEach(_optionsId, (optionId) => {
                                                                let _offerPartOption = _.find(
                                                                    offerData.offerPartOptions,
                                                                    (opo) => {
                                                                        return opo.id === parseInt(optionId);
                                                                    }
                                                                );
                                                                if (_offerPartOption) {
                                                                    _offerPartOption.isSelected = true;
                                                                    _selectedOfferPartOptionsList.push(
                                                                        _offerPartOption
                                                                    );
                                                                }
                                                            });
                                                            let _unSelectedOfferPartOptionsList =
                                                                offerData.offerPartOptions.filter(
                                                                    (x) => !_selectedOfferPartOptionsList.includes(x)
                                                                );
                                                            angular.forEach(
                                                                _unSelectedOfferPartOptionsList,
                                                                (usopo) => {
                                                                    if (usopo) {
                                                                        usopo.isSelected = false;
                                                                    }
                                                                }
                                                            );
                                                        }
                                                    } else {
                                                        offerData.offerPartOptions = offerPart.offerPartOptions;
                                                    }
                                                    let _filteredOfferPartOption =
                                                        requestOfferPartsUtil.filterOfferPartsForCreator(
                                                            offer,
                                                            offerData.offerPartOptions
                                                        );
                                                    offerData.offerPartOptions = _filteredOfferPartOption;
                                                    if (
                                                        offerData.hasRequestOfferPartOptions &&
                                                        vm.selectedOfferPartOptionLength > 0
                                                    ) {
                                                        angular.forEach(offerData.offerPartOptions, (partOption) => {
                                                            angular.forEach(
                                                                vm.selectedOfferPartOption,
                                                                (selectedPartOption) => {
                                                                    if (partOption.id === selectedPartOption.id) {
                                                                        partOption.isSelected =
                                                                            selectedPartOption.isSelected;
                                                                    }
                                                                }
                                                            );
                                                        });
                                                    }
                                                }
                                                difference = difference + vm.getDifference(offer, offerPart);
                                            }
                                        });
                                        offerWiseData.push(offerData);
                                        let _differenceWithDiscount = difference - (difference * offer.discount / 100)
                                        offerWisePriceDiff.push({ difference: difference, differenceWithDiscount: _differenceWithDiscount, currency: offer.currency });
                                    });
                                    myPart.offerWiseData = offerWiseData;
                                    vm.offerWisePriceDiff = offerWisePriceDiff;
                                    result.push(myPart);
                                }
                            });
                            vm.offerWiseDataList = result[0].offerWiseData;
                            vm.finalData = result;
                            vm.request.offerParts = vm.finalData;
                            vm.request.selectedOfferId = _selectedOffer.id;
                            getAdditionalDetailsFn(vm.finalData);
                            selectedOfferListPriceFn();
                            setSelectedOffersOriginalPriceFn();
                            setDisplayDiffColmnFn(_selectedOffer);
                        }

                        function getAdditionalDetailsFn(offerParts) {
                            angular.forEach(offerParts, (part) => {
                                if (part.hasAdditionalQuestions) {
                                    FilledRequestOfferPartComment.list(
                                        'OFFER_PART_LEVEL',
                                        part.nameCode,
                                        part.id,
                                        (resp) => {
                                            vm.finalData.list = resp;
                                            angular.forEach(resp, (list) => {
                                                if (
                                                    ['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(
                                                        list.type.name
                                                    ) > -1
                                                ) {
                                                    let _listValuesCode = list.listValueCodes;
                                                    let _listValues = $translate.instant(_listValuesCode);
                                                    list.fieldValue = DynamicFormUtilService.getFieldValue(
                                                        _listValues,
                                                        $rootScope.langSelected,
                                                        list.fieldValue
                                                    );
                                                    // list.oldFieldValue = DynamicFormUtilService.getFieldValue(_listValues, $rootScope.langSelected, list.oldFieldValue);
                                                }
                                            });
                                        },
                                        function (e) {
                                            console.log('error ', e);
                                        }
                                    );
                                }
                            });
                        }

                        function getOfferPartAdditionalQuestionClassFn(resourceData) {
                            if (resourceData && resourceData.nameCode) {
                                switch (resourceData.nameCode) {
                                    case 'common.translate.offer.part.type.bus.(outward.journey)':
                                    case 'common.translate.offer.part.type.bus.(home.journey)':
                                    case 'common.translate.offer.part.type.bus.trip':
                                        return 'supplier-bus-details';
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }

                        function recalculateOfferWiseTotalFn(offerId) {
                            let _selectedOffer = _.findWhere(vm.requestOffers, { id: offerId });
                            return getSelectedOfferTotalPrice(_selectedOffer);
                        }

                        function recalculateOfferWiseTotalWithDiscountFn(offerId, discount) {
                            let _selectedOffer = _.findWhere(vm.requestOffers, { id: offerId });
                            let _totalPrice = getSelectedOfferTotalPrice(_selectedOffer);
                            let _discountedPrice = _totalPrice * (discount / 100);
                            return _totalPrice - _discountedPrice;
                        }

                        function getSelectedOfferDiscountFn(offerId) {
                            let _selectedOffer = _.findWhere(vm.requestOffers, { id: offerId });
                            return _selectedOffer.discount;
                        }

                        function selectedOfferListPriceFn() {
                            BudgetDataShareService.setTotalPriceForBudgetByCurrency([]);
                            let totalPriceByCurrency = [];
                            let _selectedList = BudgetDataShareService.getSelectedOfferList();
                            angular.forEach(_selectedList, (offer) => {
                                let existingOfferWithInListWithOfferCurrency = _.findWhere(totalPriceByCurrency, {
                                    currencyId: offer.currency.id,
                                });
                                if (!existingOfferWithInListWithOfferCurrency) {
                                    totalPriceByCurrency.push({
                                        currencyId: offer.currency.id,
                                        currency: offer.currency,
                                        total: 0,
                                        totalWithDiscount: 0,
                                        discount: offer.discount,
                                    });
                                    existingOfferWithInListWithOfferCurrency =
                                        totalPriceByCurrency[totalPriceByCurrency.length - 1];
                                }
                                existingOfferWithInListWithOfferCurrency.total += getSelectedOfferTotalPrice(offer);
                                let _totalPrice = getSelectedOfferTotalPrice(offer);
                                let _discountedPrice = _totalPrice * (offer.discount / 100)
                                existingOfferWithInListWithOfferCurrency.totalWithDiscount += (_totalPrice - _discountedPrice);
                            });
                            BudgetDataShareService.setTotalPriceForBudgetByCurrency(totalPriceByCurrency);
                        }

                        function setDisplayDiffColmnFn(offer) {
                            if (offer) {
                                vm.showDiffColumn = amountAndPriceService.hasUpdatedFinalPriceOrAmount(
                                    offer,
                                    offer.offerParts
                                );
                                BudgetDataShareService.setShowDiffColumn(vm.showDiffColumn);
                                vm.setSelectedOffersPriceDifference();
                            }
                        }

                        function setSelectedOffersOriginalPriceFn() {
                            var originalPrice = 0;
                            angular.forEach(BudgetDataShareService.getSelectedOfferList(), function (offer) {
                                originalPrice += getSelectedOfferOriginalPrice(offer);
                            });
                            BudgetDataShareService.setOriginalPrice(originalPrice);
                        }

                        function setSelectedOffersPriceDifferenceFn() {
                            let priceDifferenceByCurrency = [];
                            angular.forEach(BudgetDataShareService.getSelectedOfferList(), function (offer) {
                                let existingOfferWithInListWithOfferCurrency = _.findWhere(priceDifferenceByCurrency, {
                                    currencyId: offer.currency.id,
                                });
                                if (!existingOfferWithInListWithOfferCurrency) {
                                    priceDifferenceByCurrency.push({
                                        currencyId: offer.currency.id,
                                        currency: offer.currency,
                                        total: 0,
                                        totalWithDiscount: 0,
                                        discount: offer.discount,
                                    });
                                    existingOfferWithInListWithOfferCurrency =
                                        priceDifferenceByCurrency[priceDifferenceByCurrency.length - 1];
                                }
                                existingOfferWithInListWithOfferCurrency.total += getPriceDifference(offer);
                                let _totalPrice = getPriceDifference(offer);
                                let _discountedPrice = _totalPrice * (offer.discount / 100)
                                existingOfferWithInListWithOfferCurrency.totalWithDiscount += (_totalPrice - _discountedPrice);
                            });
                            BudgetDataShareService.setPriceDifferenceByCurrency(priceDifferenceByCurrency);
                        }

                        function toggleRequestWiseFn() {
                            vm.requestWiseToggle = !vm.requestWiseToggle;
                            let _selectedOffer = BudgetDataShareService.getSelectedOffer(vm.requestId);
                            angular.forEach(_selectedOffer.offerParts, (selectedPart) => {
                                selectedPart.selected = vm.requestWiseToggle;
                            });
                            angular.forEach(vm.finalData, (part) => {
                                part.selected = vm.requestWiseToggle;
                                markPartSelectionFn(part);
                            });
                            BudgetDataShareService.setSelectedOfferList(vm.requestId, _selectedOffer);
                            selectedOfferListPriceFn();
                        }

                        function toggleRequestPartFn(part) {
                            part.selected = !part.selected;
                            if (part.selected) {
                                vm.requestWiseToggle = true;
                            }
                            let _notSelectedPartsCount = 0;
                            let _selectedOffer = BudgetDataShareService.getSelectedOffer(vm.requestId);
                            angular.forEach(_selectedOffer.offerParts, (selectedPart) => {
                                if (selectedPart.referenceId === part.referenceId) {
                                    selectedPart.selected = part.selected;
                                }
                                if (!selectedPart.selected) {
                                    _notSelectedPartsCount += 1;
                                }
                            });
                            if (_notSelectedPartsCount === _selectedOffer.offerParts.length) {
                                vm.requestWiseToggle = false;
                            }
                            markPartSelectionFn(part);
                            BudgetDataShareService.setSelectedOfferList(vm.requestId, _selectedOffer);
                            selectedOfferListPriceFn();
                        }

                        function toggleResourceViewOptionFn(part, offerWiseData) {
                            part.expanded = false;
                            if (offerWiseData.viewResource && part.viewResource) {
                                part.viewResource = false;
                                offerWiseData.viewResource = false;
                            } else {
                                angular.forEach(part.offerWiseData, (_data) => {
                                    _data.viewResource = false;
                                });
                                part.viewResource = true;
                                offerWiseData.viewResource = true;
                            }
                        }

                        function toggleAdditionalQuestionViewOptionFn(part) {
                            part.viewResource = false;
                            part.expanded = !part.expanded;
                        }

                        function markPartSelectionFn(_part) {
                            angular.forEach(vm.filteredOffers, (_offer) => {
                                angular.forEach(_offer.offerParts, (selectedPart) => {
                                    if (selectedPart.referenceId === _part.referenceId) {
                                        selectedPart.selected = _part.selected;
                                    }
                                });
                            });
                        }

                        function getPriceDifference(offer) {
                            return getSelectedOfferTotalPrice(offer) - getSelectedOfferOriginalPrice(offer);
                        }

                        function getSelectedOfferTotalPrice(offer) {
                            let offerTotal = 0;
                            let _offerParts = requestOfferPartsUtil.filterOfferPartsForCreator(offer, offer.offerParts);
                            syncSelectedPartsFn();
                            angular.forEach(_offerParts, (offerPart) => {
                                if (offerPart.nameCode !== 'common.translate.offer.part.type.cancellation.fee') {
                                    var total = 0;
                                    var totalOptionsPrice = 0;
                                    if (offerPart.hasRequestOfferPartOptions) {
                                        totalOptionsPrice = vm.getRequestPartOptionTotalPrice(offer, offerPart);
                                    } else {
                                        total += vm.getTotalPrice(offer, offerPart);
                                        if (offerPart.markupValue > 0 && vm.showMarkedPrice) {
                                            total += (total * offerPart.markupValue) / 100;
                                        }
                                    }
                                    offerTotal += total + totalOptionsPrice;
                                }
                            });
                            return offerTotal;
                        }

                        function getSelectedOfferOriginalPrice(offer) {
                            let offerTotal = 0;
                            let _offerParts = requestOfferPartsUtil.filterOfferPartsForCreator(offer, offer.offerParts);
                            syncSelectedPartsFn();
                            angular.forEach(_offerParts, function (offerPart) {
                                if (
                                    offerPart.nameCode !== 'common.translate.offer.part.type.cancellation.fee' &&
                                    offerPart.selected
                                ) {
                                    var total = 0;
                                    if (offerPart.hasRequestOfferPartOptions) {
                                        total += vm.getRequestPartOptionTotalPrice(offer, offerPart, true);
                                    } else {
                                        total += vm.getTotalPrice(offer, offerPart, true);
                                        if (offerPart.markupValue > 0 && vm.showMarkedPrice) {
                                            total += (total * offerPart.markupValue) / 100;
                                        }
                                    }
                                    offerTotal += total;
                                }
                            });
                            return offerTotal;
                        }

                        function syncSelectedPartsFn() {
                            angular.forEach(vm.finalData, (part) => {
                                markPartSelectionFn(part);
                            });
                        }

                        function getPriceFromPartOptionFn(offerWiseData) {
                            let _totalPriceWithMarkup = 0;
                            let _selectedOfferPartOptions = _.filter(
                                offerWiseData.offerPartOptions,
                                (offerPartOption) => {
                                    return (
                                        offerPartOption.isSelected === true &&
                                        offerPartOption.status.name !== 'NOT_DELIVERABLE'
                                    );
                                }
                            );
                            angular.forEach(_selectedOfferPartOptions, (_selectedOfferPartOption) => {
                                let _totalPrice = 0;
                                if (vm.includeVat) {
                                    let _vat = 0;
                                    if (_selectedOfferPartOption.vat > 0) {
                                        _vat = (_selectedOfferPartOption.price * _selectedOfferPartOption.vat) / 100;
                                    }
                                    _totalPrice += parseFloat(_selectedOfferPartOption.price) + parseFloat(_vat);
                                } else {
                                    _totalPrice += _selectedOfferPartOption.price;
                                }
                                if (_selectedOfferPartOption.markupValue > 0 && vm.showMarkedPrice) {
                                    _totalPrice += (_totalPrice * _selectedOfferPartOption.markupValue) / 100;
                                }
                                _totalPriceWithMarkup += _totalPrice;
                            });
                            return vm.round(_totalPriceWithMarkup);
                        }

                        function roundFn(value) {
                            let decimals = 2; //only take 2 digit after decimal
                            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
                        }

                        function getRequestPartOptionTotalPriceFn(offer, offerWiseData, originalPrice = false) {
                            if (vm.selectedOfferPartOptionLength > 0) {
                                angular.forEach(offerWiseData.offerPartOptions, (partOption) => {
                                    angular.forEach(vm.selectedOfferPartOption, (selectedPartOption) => {
                                        if (partOption.id === selectedPartOption.id) {
                                            partOption.isSelected = selectedPartOption.isSelected;
                                        }
                                    });
                                });
                            }
                            let _offerPartOptions = _.filter(offerWiseData.offerPartOptions, (offerPartOption) => {
                                return (
                                    offerPartOption.isSelected === true &&
                                    offerPartOption.status.name !== 'NOT_DELIVERABLE'
                                );
                            });
                            let _totalPriceWithMarkup = 0;
                            angular.forEach(_offerPartOptions, (_offerPartOption) => {
                                let _totalPrice = 0;
                                _totalPrice += amountAndPriceService.getRequestPartOptionTotalPrice(
                                    offer,
                                    _offerPartOption,
                                    originalPrice
                                );
                                if (_offerPartOption.markupValue > 0 && vm.showMarkedPrice) {
                                    _totalPrice += (_totalPrice * _offerPartOption.markupValue) / 100;
                                }
                                _totalPriceWithMarkup += _totalPrice;
                            });
                            return vm.round(_totalPriceWithMarkup);
                        }

                        let isSelectedValueChanged = $rootScope.$on(
                            'selected.value.changed.budget.page',
                            (event, data) => {
                                BudgetDataShareService.setSelectedOfferPartOption(data.offerPartOption);
                                let _offerPartOption = data.offerPartOption;
                                let _selectedOffer = BudgetDataShareService.getSelectedOffer(vm.requestId);
                                angular.forEach(_selectedOffer.offerParts, (offerPart) => {
                                    if (offerPart.hasRequestOfferPartOptions) {
                                        let _selectedOfferPartOption = _.find(offerPart.offerPartOptions, (opo) => {
                                            return opo.id === _offerPartOption.id;
                                        });
                                        if (_selectedOfferPartOption) {
                                            _selectedOfferPartOption.isSelected = _offerPartOption.isSelected;
                                        }
                                    }
                                });
                                BudgetDataShareService.setSelectedOfferList(vm.requestId, _selectedOffer);
                                vm.selectedOfferListPrice();
                            }
                        );

                        $scope.$on('$destroy', isSelectedValueChanged);
                    },
                ],
            };
        },
    ]);
})();
