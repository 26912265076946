import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

(function () {
    'use strict';

    angular.module('ogMessageStream').directive('ogMessageStream', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/message_stream/og_message_stream.view.html',
            replace: true,
            scope: {
                messageParams: '=',
                offers: '=',
                requests: '=',
            },
            controllerAs: 'msgStreamCtrl',
            bindToController: true,
            controller: [
                '$scope',
                '$rootScope',
                '$stateParams',
                function ($scope, $rootScope, $stateParams) {
                    var vm = this;
                    vm.errorCallback = errorCallbackFn;
                    vm.filterChatMessages = filterChatMessagesFn;
                    vm.init = initFn;
                    vm.showMessageBySupplier = showMessageBySupplierFn;

                    vm.eventId = $stateParams.eventId;
                    vm.activityFilterArray = [
                        { id: 0, tag: 'all' },
                        { id: 1, tag: 'supplier' },
                        { id: 2, tag: 'task' },
                        { id: 3, tag: 'message' },
                        { id: 4, tag: 'messageWithFiles' },
                    ];
                    vm.showTaskFilterArray = [
                        { id: 0, tag: 'showMineTask' },
                        { id: 1, tag: 'showAllTask' },
                    ];

                    vm.activityFilter = vm.activityFilterArray[0].tag;
                    vm.showTaskFilter = vm.showTaskFilterArray[1].tag;

                    ScopeFunctions.addToScope($scope);

                    vm.init();

                    function errorCallbackFn(error) {
                        vm.error = error;
                        vm.streamSpinner = false;
                        vm.messageSpinner = false;
                    }

                    function filterChatMessagesFn(message) {
                        return vm.activityFilter == 'message' ? vm.getMessageTypeFn(message) == 'CHATMESSAGE' : true;
                    }

                    function initFn() {
                        $scope.startProgress();
                        vm.messageSpinner = false;
                        $rootScope.$on('message:s:offer:id', function (evt, data) {
                            vm.showMessageBySupplier(data.offerId);
                        });
                    }

                    function showMessageBySupplierFn(id) {
                        vm.offerId = id;
                    }

                    $scope.$on('msgStreamCtrl.activityFilter/changed', function (event, value) {
                        $scope.$broadcast('msgStream:activityChanged', value);
                    });

                    $scope.$watch('msgStreamCtrl.messageParams.offerId', function (data) {
                        vm.offerId = data;
                    });

                    $scope.$on('offer:id:changed', function (evt, data) {
                        vm.offerId = data;
                    });
                },
            ],
        };
    });
})();
