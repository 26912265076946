import controller from '../og_dropdown.controller.js';

(function () {
    'use strict';

    angular.module('ogDropdown').directive('ogDropdown', [
        function () {
            return {
                scope: {
                    placeholder: '@',
                    list: '=',
                    selected: '=',
                    property: '@',
                    open: '=',
                    disabled: '=',
                },
                templateUrl: './app/components/directives/dropdown/default/og_dropdown.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogDropdownCtrl',
                bindToController: true,
            };
        },
    ]);
})();
