/**
 * Created by Diwakar on 11/2/2017.
 */

import {ScopeFunctions} from "../../../../utils/global/scopeFunctions";

class ConformationModalCtrl {
    constructor(data, $scope, close, $element) {
        this._injected = {
            data,
            $scope,
            close,
            $element,
        };

        this.okCallBack = data.okCallBack;
        this.cancelCallBack = data.cancelCallBack;
        this.closeCallBack = data.closeCallBack;
        this.message = data.message;
        this.messageArgs = data.messageArgs;
        this.okLabel = data.okLabel;
        this.cancelLabel = data.cancelLabel;
        this.isHtml = !!data.html;
        this.canAddCustomMessage = data.canAddCustomMessage;
        this.isCreatorInterestedInAlternateDates = data.isCreatorInterestedInAlternateDates;
        ScopeFunctions.addToScope($scope);
    }

    ok() {
        let okCallBack = this.okCallBack;
        if (okCallBack) {
            okCallBack(this.userMessage);
        }
        this._close();
    }

    cancel() {
        let cancelCallBack = this.cancelCallBack;
        if (cancelCallBack) {
            cancelCallBack();
        }
        this._close();
    }

    _close() {
        const { close, $element } = this._injected;
        let closeCallBack = this.closeCallBack;
        if (closeCallBack) {
            closeCallBack();
        }
        $element.modal('hide');
        close(null, 500);
    }
}
ConformationModalCtrl.$inject = ['data', '$scope', 'close', '$element'];
export default ConformationModalCtrl;
