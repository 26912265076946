import controller from './og_user_message_image.controller.js';
(function () {
    'use strict';
    angular.module('ogUserMessageImage').directive('ogUserMessageImage', [
        function () {
            return {
                scope: {
                    user: '=',
                    image: '=',
                    height: '=',
                    fontSize: '=',
                    bgColor: '=',
                    position: '=',
                    type: '=',
                },
                templateUrl: './app/components/directives/user_message_image/og_user_message_image.directive.view.html',
                controller,
                controllerAs: 'OgUserMessageImageCtrl',
                bindToController: true,
            };
        },
    ]);
})();
