import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class ShareParticipantsModalCtrl {
    constructor($scope, $rootScope, $element, data, close, Event, Participant, ParticipantService) {
        this._injected = {$scope, $rootScope, $element, data, close, Event, Participant, ParticipantService};

        ScopeFunctions.addToScope($scope);

        this.eventId = data.eventId;
        this.showAlertMessage = false;
        this.participantsSharing = {
            withAllSuppliers: false,
            withSelectedSuppliersOnly: false,
        };
        this.getQuestionLabel = this.getQuestionLabel.bind(this);

        this.getParticipantsShareDetails();
        this.getSelectedQueriesForEvent();

        $scope.$watch(
            'shareParticipantsMoCtrl.participantsSharing.withAllSuppliers',
            function () {
                if (this.participantsSharing) {
                    this.participantsSharingWithAllSuppliers = this.participantsSharing.withAllSuppliers;
                }
            }.bind(this)
        );

        $scope.$watch(
            'shareParticipantsMoCtrl.participantsSharing.withSelectedSuppliersOnly',
            function () {
                if (this.participantsSharing) {
                    this.participantsSharingWithSelectedSuppliersOnly =
                        this.participantsSharing.withSelectedSuppliersOnly;
                }
            }.bind(this)
        );

        this.languageSelected = $rootScope.langSelected;
        $scope.$on(
            'lang:change',
            function (evt, data) {
                this.languageSelected = data.lang;
            }.bind(this)
        );
    }

    getParticipantsShareDetails() {
        const { $scope, Event } = this._injected;
        this.mutualSuppliersArray = [];
        $scope.startProgress('share-participants-modal');
        Event.getParticipantsShareDetailsForEvent(
            this.eventId,
            (response) => {
                this.setParticipantShareDetails(response);
                $scope.endProgress('share-participants-modal');
            },
            $scope.endWithErrorOverlay
        );
    }

    getSelectedQueriesForEvent() {
        const { $scope, Participant } = this._injected;
        $scope.startProgress();
        Participant.getSelectedQueriesForEvent(
            this.eventId,
            (response) => {
                this.basicQueryForEvent = response.basicQueriesForEvent;
                this.customQueryForEvent = response.customQueriesForEvent;
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay
        );
    }

    getQueryListSharedWithSupplier(basicQueryForEvent, customQueryForEvent) {
        let sharedQueryList = [];
        angular.forEach(basicQueryForEvent, function (basicQuery) {
            if (basicQuery.eventParticipantBasicQuery.shareWithSupplier) {
                sharedQueryList.push(basicQuery);
            }
        });

        angular.forEach(customQueryForEvent, function (customQuery) {
            if (customQuery.shareWithSupplier) {
                sharedQueryList.push(customQuery);
            }
        });

        return sharedQueryList;
    }

    isEditable() {
        if (this.participantsSharing.withAllSuppliers || this.participantsSharing.withSelectedSuppliersOnly) {
            return this.getQueryListSharedWithSupplier(this.basicQueryForEvent, this.customQueryForEvent).length > 0;
        }
        return true;
    }

    setParticipantShareDetails(response) {
        this.suppliersForEvent = response.suppliersInEvent;
        this.participantsSharingWithAllSuppliers = response.shareWithAllSuppliers;
        if (this.participantsSharingWithAllSuppliers === true) {
            this.suppliersWithWhomParticipantsAreShared = response.suppliersInEvent;
            this.participantsSharing.withAllSuppliers = true;
            this.participantsSharingWithSelectedSuppliersOnly = false;
        } else {
            this.participantsSharingWithSelectedSuppliersOnly = response.shareWithSelectedSupplies;
            if (this.participantsSharingWithSelectedSuppliersOnly === true) {
                this.suppliersWithWhomParticipantsAreShared = response.selectedSupplierDetails;
                this.participantsSharing.withSelectedSuppliersOnly = true;
            } else {
                this.suppliersWithWhomParticipantsAreShared = [];
            }
        }
        this.suppliersWithWhomParticipantsAreShared.forEach((supplier) => this.mutualSuppliersArray.push(supplier.id));
        this.mutualSuppliersArray_ = [...this.mutualSuppliersArray];
    }

    updateParticipantsShareDetails(optionsForEvent, updatedQueryList) {
        const { $scope, Participant } = this._injected;
        let params = {
            eventId: this.eventId,
            participantsSharingWithAllSuppliers: this.participantsSharingWithAllSuppliers,
            basicQuery: { optionsForEvent: optionsForEvent },
            customQuery: updatedQueryList,
        };
        if (
            this.participantsSharingWithAllSuppliers !== true &&
            this.participantsSharingWithSelectedSuppliersOnly === true
        ) {
            params.mutualSupplierIds = this.mutualSuppliersArray;
        }
        $scope.startProgress('share-participants-modal');
        Participant.updateParticipantsShareDetailsForEvent(
            params,
            (response) => {
                this.setParticipantShareDetails(response);
                $scope.endProgress('share-participants-modal');
                this.close();
            },
            $scope.endWithErrorOverlay
        );
    }

    update() {
        if (this.isEditable()) {
            this.updateParticipantsShareDetails(this.basicQueryForEvent, this.customQueryForEvent);
        } else {
            this.showAlertMessage = true;
            /*OgModal.open('alert', { message: 'share.with.supplier.not.selected' });*/
        }
    }

    getQuestionLabel(item) {
        let {ParticipantService, $rootScope} = this._injected;
        return ParticipantService.getQuestionLabel(item, $rootScope.langSelected);
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}
ShareParticipantsModalCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$element',
    'data',
    'close',
    'Event',
    'Participant',
    'ParticipantService',
];
export default ShareParticipantsModalCtrl;
