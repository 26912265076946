(function () {
    'use strict';
    angular.module('ogShowFocus').directive('ogShowFocus', [
        '$timeout',
        function ($timeout) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    scope.$watch(attrs.ogShowFocus, function (newValue) {
                        $timeout(function () {
                            if (newValue) {
                                element.focus();
                            }
                        }, 500);
                    });
                },
            };
        },
    ]);
})();
