/**
 * Created with IntelliJ IDEA.
 * User: pc
 * Date: 12/9/19
 * Time: 4:10 PM
 * To change this template use File | Settings | File Templates.
 */

import controller from './alert-modal.controller.js';
const controllerAs = 'alertModalController';
const templateUrl = './app/components/modals/modals2.0/alert-modal/alert-modal.html';
export default { templateUrl, controller, controllerAs };
