'use strict';
import { ScopeFunctions } from "../../../../utils/global/scopeFunctions";
angular
    .module('invitation-participant').component('invitationParticipant', {
        templateUrl: './app/components/modules/participant/invitation/invitation.view.html',
        controllerAs: 'invitationParticipantCtrl',
        bindings: {
            personalizedPageDetails: '<'
        },
        controller: [
            '$scope',
            '$stateParams',
            '$state',
            '$location',
            'Event',
            'EventPresentationRo',
            'Page',
            '$rootScope',
            function InvitationParticipantController(
                $scope,
                $stateParams,
                $state,
                $location,
                Event,
                EventPresentationRo,
                Page,
                $rootScope
            ) {
                var vm = this;

                vm.gotoRegistrationTab = gotoRegistrationTabFn;
                vm.declineParticipation = declineParticipationFn;
                vm.showDeclineOption = showDeclineOptionFn;

                ScopeFunctions.addToScope($scope);

                vm.$onInit = function () {
                    (function () {
                        if ($stateParams.participate == 'true') {
                            vm.gotoRegistrationTab();
                        }
                        vm.isRegistration = $location.search().registration;

                        vm.showParticipateButton = $stateParams.participate != 'false';
                        $scope.startProgress();
                        EventPresentationRo.get(
                            { hash: $stateParams.hash },
                            function (response) {
                                $rootScope.switchLang(response.locale, true, $stateParams.hash);
                                vm.data = response.eventPresentationDetails;
                                vm.data.slide =
                                    response.eventPresentationDetails.slide || response.eventPresentationDetails.slides[0];
                                vm.invitationDetails = response.invitationDetails;
                                vm.fromPublicSite = vm.personalizedPageDetails.fromPublicSite;
                                vm.participantStatus = response.participantStatus;

                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay
                        );
                    })();
                }

                function gotoRegistrationTabFn() {
                    if (vm.isRegistration) {
                        return $state.go('participant_details.registration', { hash: $stateParams.hash });
                    }
                    return $state.go('participant_details.event_details', { hash: $stateParams.hash });
                }

                function declineParticipationFn() {
                    $scope.startProgress({ showContent: true });
                    EventPresentationRo.declineParticipation(
                        { hash: $stateParams.hash },
                        function () {
                            vm.participationDeclined = true;
                            $scope.endProgress();
                        },
                        $scope.endWithAlert
                    );
                }

                function showDeclineOptionFn() {
                    return !vm.fromPublicSite && vm.participantStatus != 'NOT_ATTENDING';
                }
            }]
    })
