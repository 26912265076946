'use strict';
import { OgModal } from "../../utils/global/ogModal";
import { ScopeFunctions } from '../../utils/global/scopeFunctions';
import CommonFunctions from '../../utils/CommonFunctionUtil';

angular.module('dialog').directive('ogEditBillingInfoDetailModal', [
    'AuthenticationService',
    'NotificationHandleService',
    function (AuthenticationService, NotificationHandleService) {
        return new OgModal({
            name: 'edit:billing:info:detail',
            type: 'custom',
            controller: function ($scope) {
                //$scope.form = 'BillingAddressForm';
                ScopeFunctions.addToScope($scope);
                $scope.billingAddress = {};

                $scope.initDialog = function (data) {
                    $scope.editBillingAddressForm.$setUntouched();
                    $scope.eventId = data.eventId;
                    $scope.offer = data.offer;
                    $scope.okCallBack = data.okCallBack;
                    $scope.isFromNotification = data.isFromNotification;
                    $scope.currentUserGroup = AuthenticationService.getUserData().userGroup;
                    $scope.defaultCompany = $scope.currentUserGroup.name;

                    if (data.billingAddress) {
                        $scope.billingAddress = data.billingAddress;
                    } else if ($scope.currentUserGroup.userGroupBillingAddress) {
                        $scope.billingAddress = $scope.currentUserGroup.userGroupBillingAddress;
                    }
                    $scope.originalBillingAddress = $scope.currentUserGroup.userGroupBillingAddress;
                };

                $scope.clearDialog = function () {
                    $scope.billingAddress = '';
                    $scope.defaultCompany = '';
                };

                $scope.bookRequestOffer = function () {
                    if (!$scope.editBillingAddressForm.$valid) {
                        CommonFunctions.touchFormFields($scope.editBillingAddressForm);
                        return;
                    }
                    if ($scope.billingAddress) {
                        $scope.billingAddress.company = $scope.defaultCompany;
                    }
                    $scope.startProgress($scope.eventId);
                    let okCallBack = $scope.okCallBack;
                    if (okCallBack) {
                        okCallBack(
                            $scope.offer,
                            'BOOKED_WAITING_FOR_CONFIRMATION',
                            undefined,
                            $scope.billingAddress
                        );
                    } else {
                        NotificationHandleService.changeRequestOfferStatus(
                            $scope.eventId,
                            $scope.offer.request.id,
                            $scope.offer,
                            'BOOKED_WAITING_FOR_CONFIRMATION',
                            $scope,
                            undefined,
                            $scope.billingAddress
                        );
                    }
                    $scope.endProgress($scope.eventId);
                    $scope.clearDialog();
                    $scope.close();
                };
            },
        });
    },
]);

