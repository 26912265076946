(function () {
    /* global angular */
    /*This is used in supplier/offer/Message as well as client main page/Message*/
    'use strict';

    angular.module('activityTimeElapsed').directive('ogActivityTimeElapsed', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/activity_time_elapsed/og.activity.time.elapsed.view.html',
            replace: true,
            scope: {
                timestamp: '=',
            },
            controllerAs: 'activityTimeElapsedCtrl',
            bindToController: true,
            controller: [
                '$scope',
                '$log',
                '$rootScope',
                '$stateParams',
                'DateService',
                function ($scope, $log, $rootScope, $stateParams, DateService) {
                    let vm = this;
                    vm.isTimeElapsedGreaterThanADay = isTimeElapsedGreaterThanADayFn;
                    vm.parseDateTimeInLocaleFormat = parseDateTimeInLocaleFormatFn;

                    function isTimeElapsedGreaterThanADayFn(timestamp) {
                        return DateService.isTimeElapsedGreaterThanADay(timestamp);
                    }

                    function parseDateTimeInLocaleFormatFn(timestamp) {
                        return DateService.parseDateTimeInLocaleFormat(timestamp);
                    }
                },
            ],
        };
    });
})();
