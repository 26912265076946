'use strict';
import { OgModal } from "../../utils/global/ogModal";
import { ScopeFunctions } from '../../utils/global/scopeFunctions';
import CommonFunctions from '../../utils/CommonFunctionUtil';

angular.module('dialog').directive('ogParticipantsChangeStatusModal', [
    '$rootScope',
    '$translate',
    'Event',
    'Participant',
    function ($rootScope, $translate, Event, Participant) {
        return new OgModal({
            name: 'participants:change:status',
            type: 'custom',
            controller: function ($scope) {
                $scope.form = 'ParticipantsChangeStatusForm';
                $scope.changeInvitationForms = {};

                $scope.initDialog = function (data) {
                    ScopeFunctions.addToScope($scope);
                    $scope.statusChangeTo = data.newStatus;
                    $scope.eventId = data.eventId;
                    $scope.selectedParticipants = data.selectedParticipants;
                    $scope.successCallback = data.successCallback;
                    $scope.hasOrganizerDetails = false;
                    $scope.sendMail = true;
                    $scope.showConfirmationBox = false;
                    $scope.getInvitationDetails();
                };

                $scope.clearDialog = function () {
                    $scope.eventId = null;
                    $scope.successCallback = null;
                };

                $scope.setSendMail = function (send) {
                    $scope.sendMail = send;
                };

                $scope.showHideConfirmationBox = function (show) {
                    $scope.showConfirmationBox = show;
                    if ($scope.showConfirmationBox === false) {
                        $scope.changeParticipantsStatus();
                    }
                };

                $scope.changeParticipantsStatus = function () {
                    var params = {
                        eventId: $scope.eventId,
                        selectedParticipants: $scope.selectedParticipants,
                        sendMail: $scope.sendMail,
                        statusChangeTo: $scope.statusChangeTo,
                    };
                    $scope.startProgress();
                    Participant.updateParticipantsStatus(
                        params,
                        function () {
                            if ($scope.successCallback) {
                                $scope.successCallback();
                            }
                            $scope.setSendMail(false);
                            $scope.endProgress();
                            $scope.close();
                        },
                        $scope.endWithErrorOverlay
                    );
                };

                $scope.getInvitationDetails = function () {
                    $scope.startProgress();
                    Event.getEventInvitationDetails(
                        $scope.eventId,
                        function (response) {
                            console.log(response);
                            $scope.invitationDetails = response;
                            if ($scope.invitationDetails.organizer.name != null) {
                                $scope.hasOrganizerDetails = true;
                            }
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                };

                $scope.useOrganizerAsDataController = function () {
                    var _data = {
                        name: $scope.invitationDetails.organizer.company,
                        email: $scope.invitationDetails.organizer.email,
                    };
                    $scope.invitationDetails.dataAccessHandler = angular.copy(_data);
                };

                $scope.updateOrganizerDetails = function () {
                    if (!$scope.changeInvitationForms.formForOrganizer.$valid) {
                        CommonFunctions.touchFormFields($scope.changeInvitationForms.formForOrganizer);
                        return;
                    }
                    var invitationDetails = $scope.invitationDetails;
                    $scope.startProgress();
                    Event.updateEventInvitationDetails(
                        $scope.eventId,
                        invitationDetails,
                        function (data) {
                            $scope.hasOrganizerDetails = true;
                            $rootScope.$broadcast('invitation:details:update', data);
                            $scope.endProgress();
                        },
                        $scope.endWithError
                    );
                };
            },
        });
    },
]);

