import controller from './og_add_supplier_from_map.controller.js';

(function () {
    'use strict';

    angular.module('add.supplier.from.map').directive('ogAddSupplierFromMap', [
        function () {
            return {
                scope: {
                    searchParams: '=',
                    declinedOffers: '=',
                    canceledOffers: '=',
                    addSupplierCallBackFunction: '=',
                    addedSupplierList: '=',
                    closeMapViewCallBackFn: '=',
                    geoTags: '=',
                    supplierName: '=',
                    request: '=',
                },
                templateUrl: './app/components/directives/add_supplier_from_map/og_add_supplier_from_map.html',
                restrict: 'AE',
                controller,
                controllerAs: 'AddSupplierFromMapCtrl',
                bindToController: true,
            };
        },
    ]);
})();
