import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import { OgModal } from "../../../utils/global/ogModal";
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('ogPresentationForSite').directive('ogPresentationForSite', [
        function () {
            return {
                templateUrl: './app/components/directives/presentation_for_site/og_presentation_for_site.view.html',
                scope: {
                    jsondata: '=jsondata',
                    defaultEditable: '=editable',
                    // parent: '=parent',
                    startInEditMode: '=editmode',
                    liveView: '=liveview',
                    event: '=event',
                    invitationDetails: '=invitationDetails',
                },
                controller: [
                    '$scope',
                    '$rootScope',
                    '$location',
                    '$log',
                    'Presentation',
                    '$timeout',
                    'TimezoneService',
                    'EventEnum',
                    'DateService',
                    'CommonFunctions',
                    function (
                        $scope,
                        $rootScope,
                        $location,
                        $log,
                        Presentation,
                        $timeout,
                        TimezoneService,
                        EventEnum,
                        DateService,
                        CommonFunctions
                    ) {
                        ScopeFunctions.addToScope($scope);

                        $scope.$watch('jsondata', function (data) {
                            if (data) {
                                $scope.presentation = data;
                                $scope.presentationImages = data.presentationImages;

                                if (typeof $scope.defaultEditable !== 'undefined') {
                                    $scope.isEditable = $scope.defaultEditable && data.editable;
                                } else {
                                    $scope.isEditable = data.editable;
                                }

                                $scope.globalEditMode = $scope.startInEditMode || $scope.categoryId;
                                $scope.resetEditModes($scope.presentationImages);
                            }
                        });

                        $scope.resetEditModes = function (images) {
                            $scope.edit = {
                                name: false,
                                logoUrl: false,
                                videoUrl: false,
                                description: false,
                                map: false,
                            };
                            if (images) {
                                angular.forEach(images, function (image, key) {
                                    $scope.edit[key] = false;
                                });
                            }
                        };

                        $scope.getFormattedDate = function (dateTime) {
                            return DateService.extractLocalizedDateFromTimeStamp(dateTime);
                        };

                        $scope.getLocalizedDate = function (dateTime) {
                            return TimezoneService.toLocalTime(dateTime);
                        };

                        $scope.getTimeFormat = function () {
                            return $rootScope.langSelected == 'sv_SV' ? 'HH:mm' : 'hh:mm a';
                        };

                        $scope.toggleGlobalEditMode = function () {
                            if ($scope.isEditable) {
                                if ($scope.globalEditMode) {
                                    $scope.resetEditModes($scope.presentationImages);
                                    $scope.uploadDescription();
                                } else if (tinyMCE && $scope.presentation.description !== null) {
                                    tinyMCE.get('description').setContent($scope.presentation.description);
                                }
                                $scope.globalEditMode = !$scope.globalEditMode;
                            }
                        };

                        $scope.setItemType = function (itemType) {
                            $scope.itemType = itemType;
                        };

                        $scope.toggleEditMode = function (item) {
                            if ($scope.globalEditMode) {
                                $scope.edit[item] = !$scope.edit[item];
                            }
                        };

                        $scope.deleteImage = function (imageId, itemType) {
                            var customMessage = 'picture.delete.confirm';
                            if (itemType === 'logoUrl') {
                                customMessage = 'picture.delete.confirm.logoImage';
                            }
                            $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, {
                                message: customMessage,
                                okCallBack: function () {
                                    if (imageId) {
                                        Presentation.deleteImage(
                                            { currentImg: imageId },
                                            function (response) {
                                                $scope.presentation = response;
                                                $scope.presentationImages = response.presentationImages;
                                                $scope.currentImage();
                                            },
                                            function () {
                                                console.log('delete failure');
                                            }
                                        );
                                    }
                                },
                            });
                        };

                        $scope.getPresentationImage = function () {
                            return $scope.presentationImages ? _.values($scope.presentationImages)[0] : null;
                        };

                        $scope.errorCallback = function (error) {
                            $scope.endWithAlert(error);
                            $scope.itemType = undefined;
                        };

                        $scope.parseUploadResponse = function (response) {
                            $scope.edit[$scope.itemType] = false;
                            $scope.presentation = response;
                            $scope.presentationImages = response.presentationImages;
                            if ($scope.itemType == 'mapURL') {
                                $scope.$broadcast('map/init', response.mapUrl);
                            } else {
                                $log.log($scope.itemType);
                            }
                            $scope.itemType = undefined;
                        };

                        $scope.upload = function (itemType) {
                            $scope.itemType = itemType;
                            Presentation.uploadItem(
                                $scope.presentation.id,
                                itemType,
                                $scope.presentation[itemType],
                                $scope.parseUploadResponse,
                                $scope.errorCallback
                            );
                        };

                        $scope.reset = function () {
                            $scope.presentation.useParent = true;
                            $scope.upload('useParent');
                        };

                        $scope.uploadDescription = function () {
                            if (tinyMCE) {
                                $scope.presentation.description = tinyMCE.get('description').getContent();
                            }
                            $scope.upload('description');
                        };

                        $scope.uploadImage = function (content, completed) {
                            if (completed && content) {
                                var fileStore = angular.fromJson(content);
                                if (fileStore.id) {
                                    $scope.presentation[$scope.itemType] = fileStore.id;
                                    $scope.upload($scope.itemType);
                                } else {
                                    CommonFunctions.alert(fileStore);
                                }
                            }
                        };

                        $scope.currentImage = function (val) {
                            $scope.currentImg = val
                                ? val
                                : $scope.currentImg
                                    ? $scope.currentImg
                                    : $scope.presentationImages
                                        ? _.values($scope.presentationImages)[0]
                                        : '0';
                            return $scope.currentImg;
                        };

                        angular.forEach($scope.presentationImages, function (img, key) {
                            $scope.$watch('edit.' + key, function (a) {
                                if (!a) {
                                    return;
                                }
                                trigger(key);
                            });
                        });

                        function trigger(img) {
                            var repeat = 0;

                            function triggerClick(i) {
                                if ($('input[type="file"].' + i).is(':visible')) {
                                    $('input[type="file"].' + i).trigger('click');
                                } else if (repeat < 5) {
                                    repeat++;
                                    $timeout(triggerClick, 100);
                                }
                            }

                            triggerClick(img);
                        }

                        $scope.$watch('edit.logoUrl', function (a) {
                            if (!a) {
                                return;
                            }
                            trigger('logoUrl');
                        });

                        $scope.openCropMeDialog = function (itemType) {
                            $scope.setItemType(!!itemType ? itemType : 'other');
                            var initParams = { presentationId: $scope.presentation.id, itemType: $scope.itemType };
                            if (itemType === 'logoUrl') {
                                OgModal.open('crop:logo:url', initParams);
                            } else {
                                OgModal.open('crop:presentation:image', initParams);
                            }
                        };

                        var presentationImageChangedEvent = $rootScope.$on(
                            'presentation:presentationImage:changed',
                            function (event, data) {
                                $scope.presentationImages = data.presentationImages;
                                $scope.currentImg = data.presentationImages
                                    ? _.values(data.presentationImages)[0]
                                    : null;
                            }
                        );

                        var logoUrlChangeEvent = $rootScope.$on('presentation:logoUrl:changed', function (event, data) {
                            $scope.presentation.logoUrl = data.logoUrl;
                        });

                        $scope.$on('$destroy', function () {
                            logoUrlChangeEvent();
                            presentationImageChangedEvent();
                        });
                    },
                ],
                link: function (scope, element /*, attrs*/) {
                    $(element).on('change', 'input[type="file"]', function () {
                        $(this).closest('form').find('input[upload-submit]').click();
                    });
                },
            };
        },
    ]);
})();
