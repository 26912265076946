/**
 * Created by Amol on 6/19/2019.
 */
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class SupplierPresentationModalCtrl {
    constructor($scope, $rootScope, $element, data, $log, Presentation, close, CommonPresentation, $window, $timeout) {
        this._injected = {
            $scope,
            $rootScope,
            $element,
            data,
            $log,
            Presentation,
            close,
            CommonPresentation,
            $window,
            $timeout,
        };

        ScopeFunctions.addToScope($scope);

        let resizeBodyHeight = function () {
            this.resizeModalBodyHeight();
        }.bind(this);

        var onHeaderLoaded = $scope.$watch('supplierPresentationMoCtrl.headerLoaded()', function (newValue) {
            if (newValue) {
                resizeBodyHeight();
                onHeaderLoaded();
            }
        });

        angular.element($window).bind('resize', resizeBodyHeight);
        //unbind the resize listener
        $scope.$on('$destroy', function () {
            angular.element($window).unbind('resize', resizeBodyHeight);
        });

        $scope.supplier = data.supplier;
        $scope.supplierId = data.supplierId;
        $scope.presentation = data.presentation;
        if ($scope.presentation === null && $scope.supplierId !== null) {
            $scope.startProgress();
            CommonPresentation.get(
                { supplierId: $scope.supplierId },
                function (result) {
                    $scope.presentation = result;
                    $scope.endProgress();
                },
                $scope.endWithErrorOverlay
            );
        }

        this.languageSelected = $rootScope.langSelected;
        $scope.$on(
            'lang:change',
            function (evt, data) {
                this.languageSelected = data.lang;
            }.bind(this)
        );
    }

    headerLoaded() {
        const { $element } = this._injected;
        var headerElement = $element.find('#select-supplier-header');
        return headerElement && true;
    }

    resizeModalBodyHeight(delayTime = 0) {
        const { $window, $element, $timeout } = this._injected;
        $timeout(function () {
            let windowHeight = $window.innerHeight;
            let modalHeader = $element.find('#select-supplier-header');
            // we need to keep margin on top and bottom section, Also see select-supplier-modal.css for top-margin
            //https://app.asana.com/0/14368931590528/292465599612174
            let totalMarginForTopAndBottom = 24;
            let bodyHeight = windowHeight - modalHeader.innerHeight() - totalMarginForTopAndBottom;
            let modalBody = $element.find('#select-supplier-body');
            modalBody.css('height', bodyHeight + 'px');
        }, delayTime);
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}
SupplierPresentationModalCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$element',
    'data',
    '$log',
    'Presentation',
    'close',
    'CommonPresentation',
    '$window',
    '$timeout',
];
export default SupplierPresentationModalCtrl;
