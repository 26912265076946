import controller from './og-error-success-message.controller';

(function () {
    'use strict';
    angular.module('ogErrorSuccessMessage').directive('ogErrorSuccessMessage', [
        function () {
            return {
                scope: {
                    messages: '=',
                    showme: '=',
                },
                templateUrl: './app/components/directives/og-error-success-message/og-error-success-message.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogErrorSuccessMessageCtrl',
                bindToController: true,
            };
        },
    ]);
})();
