import googleAddressUtil from '../../../utils/GoogleAddressUtil';
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

class ogAutocompleteAddressCtrl {
    constructor($scope, $element, $timeout) {
        this._injected = {$scope, $element, $timeout};

        this.handleOnChanged = this.handleOnChanged.bind(this);
        this.setLocationDetails = this.setLocationDetails.bind(this);
        this.initGoogleAutocomplete();
        this.manualEntry = false;
        ScopeFunctions.addToScope($scope);
    }

    initGoogleAutocomplete() {
        window.googleMapsCallback(() => {
            // The use of unique Id for google places option causes error in LIVE
            // Apparently, google API tries to add the "autocomplete" effect before the element gets ready
            var options = {
                strictBounds: true,
                types: ['geocode', 'establishment'],
            };
            var autocomplete = new google.maps.places.Autocomplete($('#google_address_ac')[0], options);
            google.maps.event.addListener(autocomplete, 'place_changed', () => {
                var place = autocomplete.getPlace();
                let _displayAddress = $('#google_address_ac').val();
                this.setLocationDetails(place, _displayAddress);
            });
        });
    }

    handleOnChanged(locationNameChanged) {
        if (this.address) {
            if (this.address.displayAddress && locationNameChanged) {
                var location = this.address.displayAddress;
                window.googleMapsCallback(() => {
                    var geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ address: location }, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK) {
                            var place = results[0];
                            let _displayAddress = $('#google_address_ac').val();
                            this.setLocationDetails(place, _displayAddress);
                        }
                        if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                            this.resetAllAddress();
                        }
                    });
                });
            }
            if (this.address.displayAddress === '') {
                this.resetAllAddress();
            }
        }
    }

    enableManualEntry() {
        this.manualEntry = true;
    }

    resetAllAddress() {
        const { $scope, $timeout } = this._injected;
        this.address.street = '';
        this.address.route = '';
        this.address.town = '';
        this.address.municipality = '';
        this.address.state = '';
        this.address.country = '';
        this.address.zipCode = '';
        this.address.geoCoordinates = '';
        this.address.latitude = '';
        this.address.longitude = '';
        this.address.streetAddress = '';
        if (this.locationChangeCallback) {
            this.locationChangeCallback({ newLocation: this.address });
        }
        $timeout(function () {
            $scope.$apply();
        });
    }

    setLocationDetails(place, _displayAddress) {
        const { $scope } = this._injected;
        this.address = googleAddressUtil.parseGoogleAddress(place);
        this.address.displayAddress = _displayAddress;
        if (this.locationChangeCallback) {
            this.locationChangeCallback({ newLocation: this.address });
        }
        $scope.$apply();
    }
}

ogAutocompleteAddressCtrl.$inject = ['$scope', '$element', '$timeout'];
export default ogAutocompleteAddressCtrl;
