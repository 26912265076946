'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogCropBigImageModal', [
    '$rootScope',
    '$timeout',
    'Presentation',
    function ($rootScope, $timeout, Presentation) {
        return new OgModal({
            name: 'crop:big:image',
            type: 'custom',
            controller: function ($scope) {
                $scope.form = 'bigImageForm';
                ScopeFunctions.addToScope($scope);

                    initCropBigImageConfig();

                    $scope.initDialog = function (data) {
                        $scope.customStyle = {};
                        $scope.logoType = data.itemType;
                        $scope.presentationId = data.presentationId;
                        $scope.type = data.type;
                        $scope.crop.bigImage.image = undefined;
                        if ($scope.type == 'invitation') {
                            $scope.crop.bigImage.cropStyle.aspectRatio = 1200 / 420;
                        }
                        jQuery('#bigImageUrl').val('');
                    };

                    var URL = window.URL || window.webkitURL,
                        blobURL;

                    $scope.$watch('crop.bigImage.image', function (value) {
                        if (URL) {
                            if (value) {
                                if (/^image\/\w+$/.test(value.type)) {
                                    $scope.customStyle = {};
                                    $scope.crop.bigImage.image = value;
                                    blobURL = URL.createObjectURL($scope.crop.bigImage.image);
                                    $scope.crop.bigImage.blobURL = blobURL;
                                } else {
                                    $scope.crop.bigImage.image = null;
                                }
                            }
                        }
                    });

                    $scope.setBigImageUrl = function () {
                        if (!Presentation.checkPictureCropDetails($scope.crop.bigImage)) {
                            $rootScope.$broadcast('dialog:alert:open', { message: 'image.crop.before.uploading' });
                        } else {
                            $scope.startProgress();
                            var formData = new FormData();
                            formData.append('logoType', 'bigImageUrl');
                            formData.append('type', $scope.type);
                            formData.append('presentation', $scope.presentationId);
                            formData.append('logo', $scope.crop.bigImage.image);
                            formData.append('dataX', $scope.crop.bigImage.coordinates[0]);
                            formData.append('dataY', $scope.crop.bigImage.coordinates[1]);
                            formData.append('dataWidth', $scope.crop.bigImage.coordinates[4]);
                            formData.append('dataHeight', $scope.crop.bigImage.coordinates[5]);
                            Presentation.updatePresentationLogo(
                                formData,
                                function (data) {
                                    document.getElementById('bigImageUrl-invitation').value = '';
                                    $scope.endProgress();
                                    $scope.close();
                                    $rootScope.$emit('presentation:bigImage:changed', data);
                                    $rootScope.$emit('presentation:image:updated', data);
                                },
                                $scope.endWithErrorOverlay()
                            );
                        }
                    };

                    function initCropBigImageConfig() {
                        $scope.crop = {};
                        $scope.crop.bigImage = {};
                        $scope.crop.bigImage.coordinates = null;
                        $scope.crop.bigImage.previewStyle = {
                            width: '200px',
                            height: '60px',
                            overflow: 'hidden',
                            'margin-left': '118px',
                            'margin-bottom': '5px',
                        };
                        $scope.crop.bigImage.cropStyle = {
                            boxWidth: '445',
                            boxHeight: '445',
                            aspectRatio: 1200 / 360,
                            bgColor: 'rgb(16, 16, 16)',
                            minSize: [200, 60],
                            setSelect: [0, 0, 200, 60],
                        };
                        $scope.crop.bigImage.thumbnail = true;
                        $scope.crop.bigImage.image = null;
                    }

                    $scope.sizeTypeCheck = () => {
                        var uploadField = document.getElementById('bigImageUrl-invitation');
                        if (uploadField.files[0].size > 3145728 && !/^image\/\w+$/.test(uploadField.files[0].type)) {
                            $scope.customStyle.typeError = { color: 'red' };
                            $scope.customStyle.sizeError = { color: 'red' };
                            uploadField.value = '';
                        } else if (uploadField.files[0].size > 3145728) {
                            $scope.customStyle.sizeError = { color: 'red' };
                            $scope.customStyle.typeError = {};
                            uploadField.value = '';
                        } else if (!/^image\/\w+$/.test(uploadField.files[0].type)) {
                            $scope.customStyle.typeError = { color: 'red' };
                            $scope.customStyle.sizeError = {};
                            uploadField.value = '';
                        }
                    };
                },
            });
        },
    ]);

