/* global angular */
'use strict';

angular.module('core.existingServices').factory('OfferPreview', [
    '$resource',
    function ($resource) {
        var getPreviewRequest = $resource(
            'api/v1/offerPreview/event/:eventId/request/:requestId',
            {eventId: '@eventId', requestId: '@requestId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var getSupplierFromEmail = $resource(
            'api/v1/offerPreview/supplier/findByEmail',
            {},
            {
                getByEmail: {method: 'POST', isArray: true},
            }
        );

        var offerPartRest = $resource(
            'api/v1/offerPreview/offerPart/:partId',
            {partId: '@partId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var addSuppliers = $resource(
            'api/v1/offerPreview/:requestId/addSuppliers',
            {requestId: '@requestId'},
            {
                do: {method: 'POST', isArray: false},
            }
        );

        var addSuppliersAndThenSend = $resource(
            'api/v1/offerPreview/:requestId/addSuppliersAndThenSend',
            {requestId: '@requestId'},
            {
                do: {method: 'POST', isArray: false},
            }
        );

        return {
            getPreviewRequest: function (eventId, requestId, successCallback, errorCallback) {
                return getPreviewRequest.get(
                    {eventId: eventId, requestId: requestId},
                    successCallback,
                    errorCallback
                );
            },
            updateOfferPart: function (partId, jsonObject, successCallback, errorCallback) {
                offerPartRest.update({partId: partId}, jsonObject, successCallback, errorCallback);
            },
            addSuppliers: function (requestId, jsonObject, successCallback, errorCallback) {
                addSuppliers.do({requestId: requestId}, jsonObject, successCallback, errorCallback);
            },
            addSuppliersAndThenSend: function (requestId, jsonObject, successCallback, errorCallback) {
                addSuppliersAndThenSend.do({requestId: requestId}, jsonObject, successCallback, errorCallback);
            },
            checkForAddedEmail: function (jsonObject, successCallback, errorCallback) {
                return getSupplierFromEmail.getByEmail(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

