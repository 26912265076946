(function () {
    'use strict';

    angular.module('overwriteEmailValidation').directive('overwriteEmailValidation', [
        function () {
            var EMAIL_REGEXP =
                /^(?![.\s])+[A-Z0-9-_%#+\x22“”\x27’\\]+(\.[_A-Z0-9-\x27’]+)*@(?:(?![-.\s])((?!.*\.\.)(?!.*(\b(web)\b))[A-Z0-9.+-])+\.[A-Z]{2,15}|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\]|(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))$/i;

            return {
                require: 'ngModel',
                restrict: '',
                priority: 1,
                link: function (scope, elm, attrs, ctrl) {
                    if (ctrl && ctrl.$validators.email) {
                        // this will overwrite the default Angular email validator
                        ctrl.$validators.email = function (modelValue) {
                            return ctrl.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue);
                        };
                    }
                },
            };
        },
    ]);
})();
