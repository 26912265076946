'use strict';

import _ from 'underscore';

angular.
    module('core.clientService').
    factory('ClientService', ['$http', '$q',
        function ($http, $q) {
            var clientData = {};
            var clientName = '';
            var clientSettingsData = {};
            let clientDisplayName = '';

            function setUserData(data) {
                if (angular.isObject(data)) {
                    clientData = data;
                } else {
                    clientData = {};
                }
            }

            function setClientName(name) {
                clientName = name;
            }
            function setClientDisplayName(displayName) {
                clientDisplayName = displayName;
            }

            function setClientSettingsData(data){
                if (angular.isObject(data)) {
                    clientSettingsData = data;
                } else {
                    clientSettingsData = {};
                }
            }

            return {
                load: function () {
                    var deferred = $q.defer();
                    $http({
                        method: 'GET',
                        url: 'api/v1/client/current',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then(function (response) {
                        setUserData(response.data);
                        setClientDisplayName(response.data.displayName);
                    }, function (error) {
                        deferred.promise;
                        //deferred.resolve(error);
                    });
                    return deferred.promise;
                },
                loadClientSettingsData: function () {
                    var deferred = $q.defer();
                     if(!_.isEmpty(clientSettingsData)) {
                         deferred.resolve(clientSettingsData);
                         return deferred.promise;
                     }
                    $http({
                        method: 'GET',
                        url: 'api/v1/client/getClientSettings',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then(function (response) {
                        setClientSettingsData(response.data);
                        deferred.resolve(response.data);
                    }, function (error) {
                        deferred.resolve(error);
                    });
                    return deferred.promise;
                },
                get: function () {
                    return clientData;
                },
                setClientName: function (name) {
                    return setClientName(name);
                },
                setClientDisplayName: function (displayName) {
                    return setClientDisplayName(displayName);
                },
                getClientName: function () {
                    return clientName;
                },
                getClientDisplayName: function () {
                    return clientDisplayName;
                },
                getClientBannerImgId: function () {
                    return clientData.bannerImageId;
                },
                getClientSettingsData: function(){
                    return clientSettingsData;
                },
                setClientSettingsData : function(data){
                    setClientSettingsData(data)
                }
            };
        },
    ]);
