'use strict';

angular.module('core.existingServices').factory('EventSchedule', [
        '$resource',
        function ($resource) {
            var eventScheduleRest = $resource(
                'api/v1/event/schedule/:eventId',
                { eventId: '@eventId' },
                {
                    get: { method: 'GET', isArray: false },
                }
            );
            var offerPartRest = $resource(
                'api/v1/event/schedule/offerPart/:referenceId',
                { referenceId: '@referenceId' },
                {
                    get: { method: 'GET', isArray: false },
                    update: { method: 'PUT', isArray: false },
                    delete: { method: 'DELETE', isArray: false },
                }
            );

            var getOfferPartRest = $resource(
                'api/v1/event/schedule/offerPart/',
                {},
                {
                    get: { method: 'GET', isArray: false },
                }
            );

            var createOfferPartForExistingRequestRest = $resource(
                'api/v1/event/schedule/offerPart/request/:requestId',
                { requestId: '@requestId' },
                {
                    save: { method: 'POST', isArray: false },
                }
            );
            var createOfferPartForRequestRest = $resource(
                'api/v1/event/schedule/offerPart/event/:eventId',
                { eventId: '@eventId' },
                {
                    save: { method: 'POST', isArray: false },
                }
            );
            var dateTimeForOfferPartRest = $resource(
                'api/v1/event/schedule/offerPart/:referenceId/updateDateTime',
                { referenceId: '@referenceId' },
                {
                    update: { method: 'PUT', isArray: false },
                }
            );
            var scheduleForSupplierRest = $resource(
                'api/v1/event/schedule/getScheduleForSupplier/:offerId',
                {},
                {
                    get: { method: 'GET', isArray: false },
                }
            );
            var addDayBeforeEventStartDateRest = $resource(
                'api/v1/event/schedule/:eventId/addDayBeforeEventStartDate',
                { eventId: '@eventId' },
                {
                    update: { method: 'PUT', isArray: false },
                }
            );
            var addDayAfterEventEndDateRest = $resource(
                'api/v1/event/schedule/:eventId/addDayAfterEventEndDate',
                { eventId: '@eventId' },
                {
                    update: { method: 'PUT', isArray: false },
                }
            );
            var deleteDayBeforeEventStartDateRest = $resource(
                'api/v1/event/schedule/:eventId/deleteDayBeforeEventStartDate',
                { eventId: '@eventId' },
                {
                    update: { method: 'PUT', isArray: false },
                }
            );
            var deleteDayAfterEventEndDateRest = $resource(
                'api/v1/event/schedule/:eventId/deleteDayAfterEventEndDate',
                { eventId: '@eventId' },
                {
                    update: { method: 'PUT', isArray: false },
                }
            );

            return {
                getEventSchedule: function (eventId, successCallback, errorCallback) {
                    eventScheduleRest.get({ eventId: eventId }, successCallback, errorCallback);
                },
                getOfferPart: function (referenceId, offerId, successCallback, errorCallback) {
                    getOfferPartRest.get(
                        { referenceId: referenceId, offerId: offerId },
                        successCallback,
                        errorCallback
                    );
                },
                createOfferPartForExistingRequest: function (requestId, jsonObject, successCallback, errorCallback) {
                    createOfferPartForExistingRequestRest.save(
                        { requestId: requestId },
                        jsonObject,
                        successCallback,
                        errorCallback
                    );
                },
                createOfferPartForRequest: function (eventId, jsonObject, successCallback, errorCallback) {
                    createOfferPartForRequestRest.save(
                        { eventId: eventId },
                        jsonObject,
                        successCallback,
                        errorCallback
                    );
                },
                updateOfferPart: function (referenceId, jsonObject, successCallback, errorCallback) {
                    offerPartRest.update({ referenceId: referenceId }, jsonObject, successCallback, errorCallback);
                },
                updateDateTimeForOfferPart: function (referenceId, jsonObject, successCallback, errorCallback) {
                    dateTimeForOfferPartRest.update(
                        { referenceId: referenceId },
                        jsonObject,
                        successCallback,
                        errorCallback
                    );
                },
                deleteOfferPart: function (referenceId, successCallback, errorCallback) {
                    offerPartRest.delete({ referenceId: referenceId }, successCallback, errorCallback);
                },
                getScheduleForSupplier: function (eventId, offerId, successCallback, errorCallback) {
                    scheduleForSupplierRest.get({ eventId: eventId, offerId: offerId }, successCallback, errorCallback);
                },
                addDayBeforeEventStartDate: function (eventId, successCallback, errorCallback) {
                    addDayBeforeEventStartDateRest.update({ eventId: eventId }, successCallback, errorCallback);
                },
                addDayAfterEventEndDate: function (eventId, successCallback, errorCallback) {
                    addDayAfterEventEndDateRest.update({ eventId: eventId }, successCallback, errorCallback);
                },
                deleteDayBeforeEventStartDate: function (eventId, successCallback, errorCallback) {
                    deleteDayBeforeEventStartDateRest.update({ eventId: eventId }, successCallback, errorCallback);
                },
                deleteDayAfterEventEndDate: function (eventId, successCallback, errorCallback) {
                    deleteDayAfterEventEndDateRest.update({ eventId: eventId }, successCallback, errorCallback);
                },
            };
        },
]);

