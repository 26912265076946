/**
 * Created by pc on 3/12/19.
 */
'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogCropPresentationImageModal', [
    '$rootScope',
    '$timeout',
    'Presentation',
    function ($rootScope, $timeout, Presentation) {
        return new OgModal({
            name: 'crop:presentation:image',
            type: 'custom',
            controller: function ($scope) {
                $scope.form = 'presentationImageForm';
                ScopeFunctions.addToScope($scope);

                    initCropPresentationImageConfig();

                    $scope.initDialog = function (data) {
                        $scope.customStyle = {};
                        $scope.logoType = data.itemType;
                        $scope.presentationId = data.presentationId;
                        $scope.type = data.type;
                        $scope.crop.presentationImage.image = undefined;
                        if ($scope.type == 'invitation') {
                            $scope.crop.presentationImage.cropStyle.aspectRatio = 1200 / 420;
                        }
                    };

                    var URL = window.URL || window.webkitURL,
                        blobURL;

                    $scope.$watch('crop.presentationImage.image', function (value) {
                        if (URL) {
                            if (value) {
                                if (/^image\/\w+$/.test(value.type)) {
                                    $scope.customStyle = {};
                                    $scope.crop.presentationImage.image = value;
                                    blobURL = URL.createObjectURL($scope.crop.presentationImage.image);
                                    $scope.crop.presentationImage.blobURL = blobURL;
                                } else {
                                    $scope.crop.presentationImage.image = null;
                                }
                            }
                        }
                    });

                    $scope.setPresentationImageUrl = function () {
                        if (!Presentation.checkPictureCropDetails($scope.crop.presentationImage)) {
                            $rootScope.$broadcast('dialog:alert:open', { message: 'image.crop.before.uploading' });
                        } else {
                            $scope.startProgress();
                            var formData = new FormData();
                            formData.append(
                                'logoType',
                                ['bigImageUrl', 'smallImageUrl', 'thirdImageUrl'].includes($scope.logoType)
                                    ? $scope.logoType
                                    : 'other'
                            );
                            formData.append('logoTypeUrl', $scope.logoType);
                            formData.append('type', $scope.type);
                            formData.append('presentation', $scope.presentationId);
                            formData.append('logo', $scope.crop.presentationImage.image);
                            formData.append('dataX', $scope.crop.presentationImage.coordinates[0]);
                            formData.append('dataY', $scope.crop.presentationImage.coordinates[1]);
                            formData.append('dataWidth', $scope.crop.presentationImage.coordinates[4]);
                            formData.append('dataHeight', $scope.crop.presentationImage.coordinates[5]);
                            Presentation.updatePresentationLogo(
                                formData,
                                function (data) {
                                    $scope.endProgress();
                                    $scope.close();
                                    $rootScope.$emit('presentation:presentationImage:changed', data);
                                    $rootScope.$emit('presentation:image:updated', data);
                                },
                                $scope.endWithErrorOverlay()
                            );
                        }
                    };

                    function initCropPresentationImageConfig() {
                        $scope.crop = {};
                        $scope.crop.presentationImage = {};
                        $scope.crop.presentationImage.coordinates = null;
                        $scope.crop.presentationImage.previewStyle = {
                            width: '200px',
                            height: '60px',
                            overflow: 'hidden',
                            'margin-left': '118px',
                            'margin-bottom': '5px',
                        };
                        $scope.crop.presentationImage.cropStyle = {
                            boxWidth: '445',
                            boxHeight: '445',
                            aspectRatio: 1280 / 720,
                            bgColor: 'rgb(16, 16, 16)',
                            minSize: [160, 90],
                            setSelect: [0, 0, 160, 90],
                        };
                        $scope.crop.presentationImage.thumbnail = true;
                        $scope.crop.presentationImage.image = null;
                    }

                    $scope.sizeTypeCheck = () => {
                        var uploadField = document.getElementById('presentationImageUrl');
                        if (uploadField.files[0].size > 3145728 && !/^image\/\w+$/.test(uploadField.files[0].type)) {
                            $scope.customStyle.typeError = { color: 'red' };
                            $scope.customStyle.sizeError = { color: 'red' };
                            uploadField.value = '';
                        } else if (uploadField.files[0].size > 3145728) {
                            $scope.customStyle.sizeError = { color: 'red' };
                            $scope.customStyle.typeError = {};
                            uploadField.value = '';
                        } else if (!/^image\/\w+$/.test(uploadField.files[0].type)) {
                            $scope.customStyle.typeError = { color: 'red' };
                            $scope.customStyle.sizeError = {};
                            uploadField.value = '';
                        }
                    };
                },
            });
        },
    ]);

