import { UtilFunctions } from "../../../utils/global/utilFunctions";
import { getLocalToken, removeParam, clearLocalToken } from "../../../utils/index";
import tokenExpireModal from "../../../components/modals/modals2.0/token-expire-modal";

export default function (appModule) {
    appModule.factory('AuthInterceptor',
        function ($rootScope, $q, $window, $location, $injector, $timeout) {
            return {
                request: function (config) {
                    let _token = getLocalToken();
                    config.headers = config.headers || {};
                    if (UtilFunctions.isDefinedAndNotNull(_token)) {
                        config.headers['X-Auth-Token'] = _token;
                    }
                    return config;
                },
                responseError: function (response) {
                    let _status = response.status;
                    let _currentLocation = $location.url();
                    let _apiUrl = response.config.url;
                    let _redirectUrl = "";
                    if (_currentLocation === '/') {
                        _redirectUrl = "/"
                    } else if (_currentLocation.includes('gw/') || _currentLocation.includes('shared/') || _currentLocation.includes('link/')) {
                        _redirectUrl = _currentLocation;
                    } else {
                        let url = removeParam('refresh', _currentLocation);
                        _redirectUrl = '/?location=' + encodeURIComponent(url);
                    }
                    let AuthenticationService = $injector.get('AuthenticationService');
                    let ModalService = $injector.get('ModalService');
                    let SessionService = $injector.get('SessionService');
                    let state = $injector.get('$state');
                    if (_status === 401) { // Not logged in case
                        if (response.data && response.data.messageCode === "custom.springSecurity.errors.login.token.session.expired") {
                            if (SessionService.getIsModalOpen()) {
                                return;
                            }
                            SessionService.setIsModalOpen();
                            let data = {
                                apiUrl: _apiUrl,
                                redirectUrl: _redirectUrl,
                            };
                            let tokenExpireModalData = tokenExpireModal;
                            tokenExpireModal.inputs = { data };

                            ModalService.showModal(tokenExpireModalData).then((modal) => {
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        } else {
                            if (!_apiUrl.includes("/api/login")) {
                                window.location.href = _redirectUrl;
                            }
                        }
                    }
                    if (_status === 403) { //Logged in but access denied case
                        $timeout(function () {
                            let _userData = AuthenticationService.getUserData();
                            if (_userData) {
                                if (AuthenticationService.isLoggedSupplier()) {
                                    state.go('offers');
                                } else {
                                    state.go('events');
                                }
                            } else {
                                clearLocalToken();
                                window.location.href = _redirectUrl;
                            }
                        }, 1000);
                    }
                    return $q.reject(response);
                }
            };
        });
}