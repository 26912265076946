import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";
'use strict';
// Register `upcomingOffers` component, along with its associated controller and template
angular.
    module('upcoming.offers').
    component('upcomingOffers', {
        templateUrl: './app/components/modules/event/events/upcomingOffers/upcomingOffers.template.html',
        controllerAs: 'upcomingOffersCtrl',
        require: {
            parentCtrl: '^events'
        },
        controller: [
            '$scope', 'RequestOffer',
            function UpcomingOffersController(
                $scope, RequestOffer
            ) {
                ScopeFunctions.addToScope($scope);
                var vm = this;

                this.$onInit = function () {
                    vm.eventsCtrl = this.parentCtrl;
                };
                function initFn() {
                    // $scope.startProgress('deadlines');
                    RequestOffer.getOffersNearingDeadline(function (response) {
                        vm.upcomingOffers = response;
                        // $scope.endProgress('deadlines');
                    }, $scope.endWithAlert);
                }

                initFn();
            }
        ]
    });
