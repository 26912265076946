'use strict';

angular.module('core.toolTipValue').factory('TooltipValueService', [
    '$translate',
    'TranslationService',
    'TimezoneService',
    function ($translate, TranslationService, TimezoneService) {
        var getTooltipValueName = function (offerPart) {
            let oldValueString = $translate.instant('common.old.value').concat(': ');
            let partNameString = TranslationService.translate(offerPart.oldName, offerPart.oldNameCode);
            return oldValueString.concat(partNameString);
        };

        var getTooltipValueAmount = function (offerPart, oldAmount) {
            let oldValueString = $translate.instant('common.old.value').concat(': ');
            let amountTypeString = '';
            if (offerPart.oldAmountType) {
                if (offerPart.oldAmountType.name === 'PIECES') {
                    amountTypeString = $translate.instant('common.value.pieces');
                } else {
                    amountTypeString = $translate.instant('common.value.people');
                }
            }

            return oldValueString.concat(oldAmount, ' ', amountTypeString);
        };

        var getTooltipValueAfterFinished = function (oldAmount, newAmount, type) {
            let strFrom;
            switch (type) {
                case 'AMOUNT':
                    strFrom = $translate.instant('request.offer.part.update.amount.from');
                    break;
                case 'PRICE':
                    strFrom = $translate.instant('request.offer.part.update.price.from');
                    break;
            }
            let strTo = $translate.instant('common.to.small');
            return strFrom.concat(' ', oldAmount, ' ', strTo, ' ', newAmount);
        };

        var getTooltipValueDate = function (params) {
            let oldValueString = $translate.instant('common.old.value').concat(': ');
            return oldValueString.concat(TimezoneService.getDateInterval(params));
        };

        var getTooltipValuePrice = function (oldPriceString) {
            let oldValueString = $translate.instant('common.old.value').concat(': ');
            return oldValueString.concat(oldPriceString);
        };

        var getInfoMessageByStatus = function (status) {
            let msgString;
            switch (status) {
                case 'EDITED_WAITING_FOR_SUPPLIER':
                    msgString = $translate.instant('request.waiting.for.supplier');
                    break;
                case 'ADDED_WAITING_FOR_SUPPLIER':
                    msgString = $translate.instant('request.creator.has.created.part');
                    break;
                case 'DELETED_WAITING_FOR_SUPPLIER':
                    msgString = $translate.instant('request.deleted.waiting.for.supplier');
                    break;
                case 'EDITED_WAITING_FOR_CREATOR_DECLINED':
                    msgString = $translate.instant('requestOffer.declined.changes.for.supplier');
                    break;
                case 'ADDED_WAITING_FOR_CREATOR_DECLINED':
                    msgString = $translate.instant('requestOffer.declined.of.addition.part.for.supplier');
                    break;
                case 'DELETED_WAITING_FOR_CREATOR_DECLINED':
                    msgString = $translate.instant('requestOffer.declined.of.deletion.part.for.supplier');
                    break;

                case 'ADDED_WAITING_FOR_CREATOR':
                    msgString = $translate.instant('requestOffer.added.waiting.for.creator');
                    break;
                case 'EDITED_WAITING_FOR_CREATOR':
                    msgString = $translate.instant('requestOffer.waiting.for.creator');
                    break;
                case 'DELETED_WAITING_FOR_CREATOR':
                    msgString = $translate.instant('requestOffer.deleted.waiting.for.creator');
                    break;
                case 'EDITED_WAITING_FOR_SUPPLIER_DECLINED':
                    msgString = $translate.instant('requestOffer.declined.changes.for.creator');
                    break;
                case 'ADDED_WAITING_FOR_SUPPLIER_DECLINED':
                    msgString = $translate.instant('requestOffer.declined.of.addition.part.for.creator');
                    break;
                case 'DELETED_WAITING_FOR_SUPPLIER_DECLINED':
                    msgString = $translate.instant('requestOffer.declined.of.deletion.part.for.creator');
                    break;
            }
            return msgString;
        };

        return {
            getTooltipValueName: getTooltipValueName,
            getTooltipValueAmount: getTooltipValueAmount,
            getTooltipValueDate: getTooltipValueDate,
            getTooltipValuePrice: getTooltipValuePrice,
            getInfoMessageByStatus: getInfoMessageByStatus,
            getTooltipValueAfterFinished: getTooltipValueAfterFinished,
        };
    },
]);
