import {clearLocalToken} from "../../app/utils";

export default function (appModule) {
    appModule.factory("CustomTranslationLoaderService", function ($http, $translate, $q) {
        var languages = [];

        var convert = function (messages) {
            var newMessages = {};
            angular.forEach(messages, function (value, key) {
                if (value) {
                    newMessages[key] = value.replace(/\{(\d+)\}/g, '{{p$1}}');
                } else {
                    newMessages[key] = '';
                }
            })
            return newMessages;
        }

        return {
            setLanguage: function (apiBaseUrl, langKey) {
                langKey = langKey ? langKey : localStorage.getItem('language') || 'sv_SV';
                localStorage.setItem('language', langKey);
                var langShort = langKey && langKey.substring(0, langKey.indexOf('_'));
                langShort = (langShort === 'nb') ? 'no' : langShort;
                var deferred = $q.defer();
                $http.get(apiBaseUrl + 'api/v1/commonTranslation/getTranslation?prefLanguage=' + langShort)
                    .then((resp) => {
                        languages = convert(resp.data.languages[langShort]);
                        $translate.use(langKey);
                        deferred.resolve();
                    }, function (message) {
                        let _status = message.status;
                        if (_status === 401) { // Not logged in case
                            clearLocalToken();
                            window.location.href = '/';
                        }
                        deferred.resolve(message);
                    });
                return deferred.promise;
            },

            getLanguages: function () {
                return languages;
            }
        }
    });
}