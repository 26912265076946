(function () {
    'use strict';

    angular.module('ogDateTimePicker').directive('ogDateTimePicker', [
        '$rootScope',
        'TimezoneService',
        '$translate',
        '$timeout',
        function ($rootScope, TimezoneService, $translate, $timeout) {
            function getDate() {
                return jQuery('#dateTimePairDate');
            }

            function getTime() {
                return jQuery('#dateTimePairTime');
            }

            function setupDate(scope) {
                var dateTime = new Date(TimezoneService.toLocalTime(scope.dateTime));
                getDate().datepicker('setDate', new Date(dateTime));
                getTime().timepicker('setTime', dateTime);
            }

            function updateDateTimePair(scope) {
                scope.dateTime = TimezoneService.fromLocalTime(getDateTime()).getTime();
                $rootScope.safeApply(scope);
            }

            function getDateTime() {
                var dateTime = getDate().datepicker('getDate');
                dateTime.setHours(0, 0, 0, 0);
                dateTime.setSeconds(getTime().timepicker('getSecondsFromMidnight'));
                return dateTime;
            }

            function initTimePicker() {
                var lang = $translate.uses();
                getTime().timepicker({ changeOnKeypress: false });
                setTimePickerFormat(lang);
            }

            function setFormats(lang) {
                setTimePickerFormat(lang);
                setDatePickerFormat(lang);
            }

            function setTimePickerFormat(lang) {
                if (lang == 'en_US') {
                    getTime().timepicker('option', { timeFormat: 'g:ia' });
                } else {
                    getTime().timepicker('option', { timeFormat: 'H:i' });
                }
            }

            function setDatePickerFormat(lang) {
                if (lang == 'en_US') {
                    getDate().datepicker('option', 'dateFormat', 'mm/dd/yy');
                } else {
                    getDate().datepicker('option', 'dateFormat', 'yy-mm-dd');
                }
            }

            return {
                scope: {
                    dateTime: '=',
                },
                priority: 10,
                templateUrl: './app/components/directives/date_time_picker/og_date_time_picker.view.html',
                link: function (scope) {
                    //Watcher for the dateTime
                    scope.$watch('dateTime', function () {
                        setupDate(scope);
                    });

                    //Initialize the date picker
                    jQuery('#dateTimePairDate').datetimepicker({
                        dateFormat: getDateFormat(),
                        onSelect: function () {
                            updateDateTimePair(scope);
                        },
                    });

                    function getDateFormat() {
                        return $rootScope.langSelected == 'sv_SV' ? 'yy-mm-dd' : 'dd/mm/yy';
                    }

                    //Initialize the time picker
                    initTimePicker();

                    //Event handling for the change in the date and time pickers
                    jQuery('#dateTimePairTime').on('change', function () {
                        updateDateTimePair(scope);
                    });

                    //Trigger datetime format change on language change
                    scope.$on('lang:change', function (event, data) {
                        setFormats(data.lang);
                        $timeout(function () {
                            setupDate(scope);
                        });
                    });
                },
            };
        },
    ]);
})();
