/**
 * Created by sampurna on 2020-04-22.
 */


angular.module('core.existingServices').factory('ParticipantUtilService', [
    '$q',
    function ($q) {
        function getFieldValuesFn(options, lang, fieldValue) {
            if (options && options.indexOf('~') >= 0 && fieldValue) {
                let _enOptions = options.split('~')[0].split('#');
                let _svOptions = options.split('~')[1].split('#');
                let _nrOptions = !!options.split('~')[2] ? options.split('~')[2].split('#') : '';
                let _daOptions = !!options.split('~')[3] ? options.split('~')[3].split('#') : '';
                let _fiOptions = !!options.split('~')[4] ? options.split('~')[4].split('#') : '';
                let _fieldValues = Array.isArray(fieldValue) ? fieldValue : fieldValue.split('~');
                let _indexes = [];
                for (let _i = 0; _i < _fieldValues.length; _i++) {
                    let _index = _enOptions.indexOf(_fieldValues[_i]);
                    if (_index < 0) {
                        _index = _svOptions.indexOf(_fieldValues[_i]);
                    }
                    if (_index < 0) {
                        _index = _nrOptions.indexOf(_fieldValues[_i]);
                    }
                    if (_index < 0) {
                        _index = _daOptions.indexOf(_fieldValues[_i]);
                    }
                    if (_index < 0) {
                        _index = _fiOptions.indexOf(_fieldValues[_i]);
                    }
                    if (_index > -1) {
                        _indexes.push(_index);
                    }
                }
                if (_indexes.length > 0) {
                    let _result = [];
                    for (let _i = 0; _i < _indexes.length; _i++) {
                        if (lang === 'en_US') {
                            let _enValue;
                            if (_enOptions[_indexes[_i]]) {
                                _enValue = _enOptions[_indexes[_i]];
                            } else if (_svOptions[_indexes[_i]]) {
                                _enValue = _svOptions[_indexes[_i]];
                            } else if (_nrOptions[_indexes[_i]]) {
                                _enValue = _nrOptions[_indexes[_i]];
                            } else if (_daOptions[_indexes[_i]]) {
                                _enValue = _daOptions[_indexes[_i]];
                            } else {
                                _enValue = _fiOptions[_indexes[_i]];
                            }
                            _result.push(_enValue);
                        }
                        if (lang === 'sv_SV') {
                            let _svValue;
                            if (_svOptions[_indexes[_i]]) {
                                _svValue = _svOptions[_indexes[_i]];
                            } else if (_enOptions[_indexes[_i]]) {
                                _svValue = _enOptions[_indexes[_i]];
                            } else if (_nrOptions[_indexes[_i]]) {
                                _svValue = _nrOptions[_indexes[_i]];
                            } else if (_daOptions[_indexes[_i]]) {
                                _svValue = _daOptions[_indexes[_i]];
                            } else {
                                _svValue = _fiOptions[_indexes[_i]];
                            }
                            _result.push(_svValue);
                        }
                        if (lang === 'nb_NB') {
                            let _nrValue;
                            if (_nrOptions[_indexes[_i]]) {
                                _nrValue = _nrOptions[_indexes[_i]];
                            } else if (_enOptions[_indexes[_i]]) {
                                _nrValue = _enOptions[_indexes[_i]];
                            } else if (_svOptions[_indexes[_i]]) {
                                _nrValue = _svOptions[_indexes[_i]];
                            } else if (_daOptions[_indexes[_i]]) {
                                _nrValue = _daOptions[_indexes[_i]];
                            } else {
                                _nrValue = _fiOptions[_indexes[_i]];
                            }
                            _result.push(_nrValue);
                        }
                        if (lang === 'da_DK') {
                            let _daValue;
                            if (_daOptions[_indexes[_i]]) {
                                _daValue = _daOptions[_indexes[_i]];
                            } else if (_svOptions[_indexes[_i]]) {
                                _daValue = _svOptions[_indexes[_i]];
                            } else if (_enOptions[_indexes[_i]]) {
                                _daValue = _enOptions[_indexes[_i]];
                            } else if (_nrOptions[_indexes[_i]]) {
                                _daValue = _nrOptions[_indexes[_i]];
                            } else {
                                _daValue = _fiOptions[_indexes[_i]];
                            }
                            _result.push(_daValue);
                        }
                        if (lang === 'fi_FI') {
                            let _fiValue;
                            if (_fiOptions[_indexes[_i]]) {
                                _fiValue = _fiOptions[_indexes[_i]];
                            } else if (_svOptions[_indexes[_i]]) {
                                _fiValue = _svOptions[_indexes[_i]];
                            } else if (_enOptions[_indexes[_i]]) {
                                _fiValue = _enOptions[_indexes[_i]];
                            } else if (_nrOptions[_indexes[_i]]) {
                                _fiValue = _nrOptions[_indexes[_i]];
                            } else {
                                _fiValue = _daOptions[_indexes[_i]];
                            }
                            _result.push(_fiValue);
                        }
                    }
                    _result = _result.join(',');
                    return _result;
                }
            } else {
                return fieldValue;
            }
        }

        function getOptionValuesFn(options, lang) {
            if (options && options.indexOf('~') >= 0) {
                let _options = options.split('~');
                let _englishOptions = _options ? _options[0] : null;
                let _swedishOptions = _options.length > 1 ? _options[1] : null;
                let _norwegianOptions = _options.length > 2 ? _options[2] : null;
                let _danishOptions = _options.length > 3 ? _options[3] : null;
                let _finnishOptions = _options.length > 4 ? _options[4] : null;
                if (lang === 'en_US') {
                    return _englishOptions
                        ? _englishOptions
                        : _swedishOptions
                            ? _swedishOptions
                            : _norwegianOptions
                                ? _norwegianOptions
                                : _danishOptions;
                }
                if (lang === 'sv_SV') {
                    return _swedishOptions
                        ? _swedishOptions
                        : _englishOptions
                            ? _englishOptions
                            : _norwegianOptions
                                ? _norwegianOptions
                                : _danishOptions;
                }
                if (lang === 'nb_NB') {
                    return _norwegianOptions
                        ? _norwegianOptions
                        : _englishOptions
                            ? _englishOptions
                            : _swedishOptions
                                ? _swedishOptions
                                : _danishOptions;
                }
                if (lang === 'da_DK') {
                    return _danishOptions
                        ? _danishOptions
                        : _swedishOptions
                            ? _swedishOptions
                            : _englishOptions
                                ? _englishOptions
                                : _norwegianOptions;
                }
                if (lang === 'fi_FI') {
                    return _finnishOptions
                        ? _finnishOptions
                        : _danishOptions
                            ? _danishOptions
                            : _swedishOptions
                                ? _swedishOptions
                                : _englishOptions
                                    ? _englishOptions
                                    : _norwegianOptions;
                }
            } else {
                return options;
            }
        }

        return {
            getFieldValue: function (listValues, lang, fieldValue) {
                return getFieldValuesFn(listValues, lang, fieldValue);
            },

            getOptionValues: function (options, lang) {
                return getOptionValuesFn(options, lang);
            },
        };
    },
]);

