/**
 * Created by kshitij on 11/22/17.
 */
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

class OgCropImageCtrl {
    constructor($scope, GlobalConstants) {
        this._injected = {
            $scope,
            GlobalConstants,
        };

        ScopeFunctions.addToScope($scope);
        this.$onInit = function () {
            this.init();
        }

        var URL = window.URL || window.webkitURL,
            blobURL;

        $scope.$watch(
            'OgCropImageCtrl.data.image',
            function (value) {
                if (URL) {
                    if (value) {
                        this.data.image = value;
                        if (/^image\/\w+$/.test(this.data.image.type)) {
                            this.customStyle = {};
                            blobURL = URL.createObjectURL(this.data.image);
                            this.crop.blobURL = blobURL;
                        } else {
                            this.data.image = null;
                        }
                    }
                }
            }.bind(this)
        );

        $scope.sizeTypeCheck = () => {
            var uploadField = document.getElementById('profileImage-setting');
            if (uploadField.files[0].size > 3145728 && !/^image\/\w+$/.test(uploadField.files[0].type)) {
                this.customStyle.typeError = { color: 'red' };
                this.customStyle.sizeError = { color: 'red' };
                uploadField.value = '';
            } else if (uploadField.files[0].size > 3145728) {
                this.customStyle.sizeError = { color: 'red' };
                this.customStyle.typeError = {};
                uploadField.value = '';
            } else if (!/^image\/\w+$/.test(uploadField.files[0].type)) {
                this.customStyle.typeError = { color: 'red' };
                this.customStyle.sizeError = {};
                uploadField.value = '';
            }
        };
    }

    checkCropDetails() {
        if (!this.crop) {
            return false;
        }
        if (!this.crop.coordinates[4] || !this.crop.coordinates[5]) {
            return false;
        }
        return true;
    }

    updateImage() {
        const { $scope } = this._injected;
        if (!this.checkCropDetails()) {
            this.incorrectCropMessage = 'image.crop.before.uploading';
        } else {
            var formData = this.getFormData();
            $scope.startProgress();
            this.updateImageCallback({ formData: formData }, function () {
                $scope.endProgress();
            });
        }
    }

    getFormData() {
        const { GlobalConstants } = this._injected;

        var formData = new FormData();
        formData.append('dataX', this.crop.coordinates[0]);
        formData.append('dataY', this.crop.coordinates[1]);
        formData.append('dataWidth', this.crop.coordinates[4]);
        formData.append('dataHeight', this.crop.coordinates[5]);

        if (this.category === GlobalConstants.PRESENTATION) {
            formData.append(
                'logoType',
                ['bigImageUrl', 'smallImageUrl', 'thirdImageUrl'].includes(this.presentationLogoType)
                    ? this.presentationLogoType
                    : 'other'
            );
            formData.append('logoTypeUrl', this.presentationLogoType);
            formData.append('type', this.presentationType);
            formData.append('presentation', this.presentationId);
            formData.append('logo', this.data.image);
        } else if (this.category === GlobalConstants.RESOURCE) {
            formData.append('logoType', 'other');
            formData.append('logoTypeUrl', this.presentationLogoType);
            formData.append('type', this.presentationType);
            formData.append('resource', this.presentationId);
            formData.append('logo', this.data.image);
        } else if (this.category === GlobalConstants.SUPPLIERPROFILE) {
            formData.append(this.imageTitle, this.data.image);
        } else {
            formData.append(this.imageTitle, this.data.image);
        }

        return formData;
    }

    getDefaultPreviewDetails() {
        let defaultPreviewDetails = {
            PRESENTATION: { width: '200px', height: '60px', margin: '0 auto' },
            RESOURCE: { width: '200px', height: '60px', margin: '0 auto' },
            SUPPLIERPROFILE: { width: '200px', height: '60px', margin: '0 auto' },
            PROFILE: { width: '100px', height: '100px', margin: '0 auto', borderRadius: '50%' },
        };
        return defaultPreviewDetails[this.category];
    }

    getDefaultCropDetails() {
        let presentationAspectRatio = this.presentationType == 'invitation' ? 1200 / 420 : 1280 / 720;
        let defaultCropDetails = {
            PRESENTATION: {
                maxWidth: '445',
                maxHeight: '445',
                aspectRatio: presentationAspectRatio,
                minSize: [160, 90],
                setSelect: [0, 0, 160, 90],
            },
            RESOURCE: {
                maxWidth: '445',
                maxHeight: '445',
                aspectRatio: presentationAspectRatio,
                minSize: [160, 90],
                setSelect: [0, 0, 160, 90],
            },
            PROFILE: {
                maxWidth: '445',
                maxHeight: '445',
                aspectRatio: 1,
                minSize: [100, 100],
                setSelect: [0, 0, 100, 100],
            },
            SUPPLIERPROFILE: {
                maxWidth: '445',
                maxHeight: '445',
                minSize: [100, 100],
                setSelect: [0, 0, 100, 100],
            },
        };
        return defaultCropDetails[this.category];
    }

    getPreviewDetails() {
        let details = this.previewDetails || this.getDefaultPreviewDetails();
        let previewDetails = {
            width: details.width,
            height: details.height,
            overflow: 'hidden',
            margin: details.margin,
            'border-radius': details.borderRadius ? details.borderRadius : '100%',
        };
        if (details.borderRadius) {
            previewDetails['border-radius'] = details.borderRadius;
        }
        return previewDetails;
    }

    getCropDetails() {
        let details = this.cropDetails || this.getDefaultCropDetails();
        return {
            maxWidth: details.maxWidth,
            maxHeight: details.maxHeight,
            aspectRatio: details.aspectRatio,
            bgColor: 'rgb(16, 16, 16)',
            minSize: details.minSize,
            setSelect: details.setSelect,
        };
    }

    initCropImageConfig() {
        this.crop = {};
        this.crop.coordinates = null;

        // this is css style for preview image on cropping i.e inline css of preview div
        this.crop.previewStyle = this.getPreviewDetails();
        this.crop.cropStyle = this.getCropDetails();
        this.crop.thumbnail = true;
    }

    init() {
        this.customStyle = {};
        this.data = {};
        this.data.image = undefined;
        // https://stackoverflow.com/questions/4149276/javascript-camelcase-to-regular-form#answer-4149393
        this.imageType = this.imageTitle
            // insert a space before all caps
            .replace(/([A-Z])/g, ' $1')
            // uppercase the first character
            .replace(/^./, function (str) {
                return str.toUpperCase();
            });
        this.form = this.imageTitle + 'Form';
        jQuery('#image').val('');

        this.initCropImageConfig();
    }
}

OgCropImageCtrl.$inject = ['$scope', 'GlobalConstants'];
export default OgCropImageCtrl;
