import resourcePresentationModal from '../../modals/modals2.0/resource-presentation';
import amountAndPriceService from '../../../utils/price_calculate/PriceCalculationSupplierUtil';
import amountAndPriceCreatorService from '../../../utils/price_calculate/PriceCalculationCreatorUtil';
import {ScopeFunctions} from "../../../utils/global/scopeFunctions";

(function () {
    'use strict';

    angular.module('ogBudgetRequestOfferPartOption').directive('ogBudgetRequestOfferPartOption', [
        '$log',
        '$routeParams',
        '$rootScope',
        '$timeout',
        '$translate',
        'ModalService',
        'RequestOfferPartOption',
        'GlobalConstants',
        'RequestOfferStatusUtils',
        function (
            $log,
            $routeParams,
            $rootScope,
            $timeout,
            $translate,
            ModalService,
            RequestOfferPartOption,
            GlobalConstants,
            RequestOfferStatusUtils
        ) {
            return {
                restrict: 'EA',
                scope: {
                    offerWiseData: '=',
                    offerPart: '=',
                    offerPartOption: '=',
                    event: '=',
                    expandOptions: '=',
                    showMarkedPrice: '=',
                    isModal: '=',
                },
                templateUrl:
                    './app/components/directives/budget-request-offer-part-option/og_budget_request_offer_part_option.view.html',
                controller: [
                    '$scope',
                    function ($scope) {
                        ScopeFunctions.addToScope($scope);
                        $scope.viewResourcePresentationModal = false;
                        $scope.getAmountLocalisedWithCurrency = amountAndPriceService.getAmountLocalisedWithCurrency;
                        $scope.viewPriceLocalisedWithCurrency = amountAndPriceService.viewPriceLocalisedWithCurrency;
                        $scope.includeVat = amountAndPriceCreatorService.shouldIncludeVatOnPrice();

                        $scope.viewAmountLocalisedWithCurrency = function (price, currency, digitsAfterDecimal = 0) {
                            return amountAndPriceService.viewPrice(price, currency, digitsAfterDecimal);
                        };

                        $scope.getCreatorPrice = function (offer, partOption) {
                            let _price = 0;
                            _price += amountAndPriceCreatorService.getRequestOfferPartOptionPrice(offer, partOption);
                            if ($scope.showMarkedPrice) {
                                _price = _price + (_price * partOption.markupValue) / 100;
                            }
                            return _price;
                        };

                        $scope.getTotalPrice = function (offer, partOption, includeVat) {
                            let _totalPrice = 0;
                            _totalPrice += amountAndPriceCreatorService.getRequestPartOptionTotalPrice(
                                offer,
                                partOption
                            );
                            if ($scope.showMarkedPrice) {
                                _totalPrice = _totalPrice + (_totalPrice * partOption.markupValue) / 100;
                            }
                            return _totalPrice;
                        };

                        $scope.openResourcePresentation = function (resourceName, resourceId) {
                            if($scope.viewResourcePresentationModal) {
                                return;
                            }
                            $scope.viewResourcePresentationModal = true;
                            let data = {
                                resourceName: resourceName,
                                offerPartTypeNameCode: $scope.offerPart.nameCode,
                                resourceId: resourceId,
                                supplierId: $scope.supplierId,
                                layout: 'creator',
                            };
                            let resourcePresentationModalData = resourcePresentationModal;
                            resourcePresentationModalData.inputs = { data };
                            ModalService.showModal(resourcePresentationModalData).then((modal) => {
                                $scope.viewResourcePresentationModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        };

                        $scope.openResourcePresentationPopover = function (resourceName, resourceId) {
                            $rootScope.$broadcast('part:option:open:presentation', {
                                resourceName: resourceName,
                                offerPartTypeNameCode: $scope.offerPart.nameCode,
                                resourceId: resourceId,
                            });
                        };

                        $scope.isOfferInFromBookedStateAndROPNotSelected = function (offerPartOption, offerWiseData) {
                            return (
                                !offerPartOption.isSelected &&
                                offerWiseData &&
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(
                                    offerWiseData.offer.status.name
                                ) > -1
                            );
                        };

                        $scope.ifThereIsNoROPOptionNotSelected = function (offerWiseData) {
                            if (
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(
                                    offerWiseData.offer.status.name
                                ) > -1
                            ) {
                                // It's only for after or at BOOKED state.
                                if (!offerWiseData && !offerWiseData.offerPartOptions) {
                                    return;
                                }
                                let isThereAnyOptionSelected = false;
                                for (let offerPartOption of offerWiseData.offerPartOptions) {
                                    if (offerPartOption.isSelected) {
                                        isThereAnyOptionSelected = true;
                                        break;
                                    }
                                }
                                return !isThereAnyOptionSelected;
                            }
                            return false;
                        };

                        $scope.disableCheckbox = function (offerPartOption, offerWiseData) {
                            return (
                                offerWiseData &&
                                offerPartOption.isSelected &&
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(
                                    offerWiseData.offer.status.name
                                ) > -1
                            );
                        };

                        $scope.selectedValueChanged = function (offerPartOption) {
                            $rootScope.$broadcast('selected.value.changed.budget.page', {
                                offerPartOption: offerPartOption,
                            });
                        };
                    },
                ],
            };
        },
    ]);
})();
