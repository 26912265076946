(function () {
    'use strict';

    angular.module('userGroupMember').directive('userGroupMember', [
        '$compile',
        function ($compile) {
            return {
                restrict: 'E',
                replace: true,
                scope: {
                    member: '=',
                    paddingPrefix: '=',
                    viewCallBack: '=',
                    deleteCallBack: '=',
                },
                template:
                    "<li><div class='left'><div class='user-group-title'" +
                    " style='padding-left:{{paddingPrefix}}px;'>" +
                    "{{member.name || ('user.group.default' | translate)}}" +
                    ' </div></div>' +
                    "<div class='left'><span style='cursor: pointer;' og-click='viewCallBack(member)' " +
                    "ng-hide='group.default'>{{'default.button.view.label' | translate}}</div>" +
                    "<div class='left'><span style='cursor: pointer;'" +
                    " og-click='deleteCallBack(member.id)' og-disabled='inProgress || group.default'>" +
                    "<span class='glyphicon glyphicon-trash clickable-link'></span>" +
                    '</div>' +
                    '</li>',
                link: function (scope, element, attrs) {
                    if (angular.isArray(scope.member.childrenUserGroups)) {
                        element.append(
                            "<div class='clear'></div><user-group-tree" +
                                " padding='paddingPrefix' view-call-back='viewCallBack'" +
                                "delete-call-back='deleteCallBack' collection='member.childrenUserGroups'>" +
                                '</user-group-tree>'
                        );
                        $compile(element.contents())(scope);
                    }
                },
            };
        },
    ]);
})();
