import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

angular.module('shared.public.landing').component('sharedPublicLanding', {
    templateUrl: './app/components/modules/shared/public_landing/public_landing.view.html',
    controller: ['$scope', '$stateParams',
        function publicLandingControllerFn($scope, $stateParams) {
            var vm = this;
            vm.init = initFn;
            vm.goToRegistrationPage = goToRegistrationPageFn;
            ScopeFunctions.addToScope($scope);
            vm.init();

            function initFn() {
                vm.eventName = $stateParams.eventName;
                vm.redirectLink = $stateParams.targetUrl.replaceAll(' ', '+');
                vm.redirectLink = vm.redirectLink.replace('/eventsite/', '/eventsitenew/');
                getRegistrationPageText();
            }

            function getRegistrationPageText() {
                switch ($stateParams.lang) {
                    case 'sv_SV':
                        vm.btnText = 'Fortsätt till anmälningssidan';
                        vm.landingDescription =
                            'Detta är en anmälningssida skapad med Event Logic. Klicka på länken nedan för att gå vidare till själva anmälan.';
                        break;
                    case 'nb_Nb':
                        vm.btnText = 'Fortsett til registreringssiden';
                        vm.landingDescription =
                            'Dette er en registreringsside opprettet med Event Logic. Klikk på lenken nedenfor for å gå videre til selve registreringen.';
                        break;
                    case 'da_DK':
                        vm.btnText = 'Fortsæt til registreringssiden';
                        vm.landingDescription =
                            'This is a registration page created with Event Logic. Click on the link below to proceed to the actual registration.';
                        break;
                    case 'fi_FI':
                        vm.btnText = 'Siirry rekisteröintisivulle';
                        vm.landingDescription =
                            'This is a registration page created with Event Logic. Click on the link below to proceed to the actual registration.';
                        break;
                    default:
                        vm.btnText = 'Proceed to registration page';
                        vm.landingDescription =
                            'This is a registration page created with Event Logic. Click on the link below to proceed to the actual registration.';
                        break;
                }
            }

            function goToRegistrationPageFn() {
                window.open(vm.redirectLink, '_self');
            }
        }]
})
