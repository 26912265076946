(function () {
    'use strict';

    angular.module('ogRichEdit').directive('ogRichEdit', [
        function () {
            return {
                restrict: 'EA',
                templateUrl: './app/components/directives/rich_edit/og_rich_edit.directive.view.html',
                scope: {
                    field: '=ogRichEdit',
                    disabled: '=disabled',
                    onchanged: '=onchanged',
                    modelName: '@modelName',
                    noHeight: '=',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.$watch('field', function (newValue) {
                            if (newValue && typeof $scope.onchanged == 'function') {
                                $scope.onchanged($scope.modelName, newValue);
                            }
                        });
                    },
                ],
            };
        },
    ]);
})();
