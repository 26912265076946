'use strict';
angular.module('core.supplierDetailInfo').service('SupplierDetailInfo', [
    '$resource',
    function ($resource) {
        var supplierDetailInfoRest = $resource(
            'api/v1/supplierDetailInfo/supplier/getDetailInfo/:supplierId',
            {supplierId: '@supplierId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var updateSupplierDetailInfoRest = $resource(
            'api/v1/supplierDetailInfo/supplier/:supplierId',
            {supplierId: '@supplierId'},
            {
                update: {method: 'POST', isArray: false},
            }
        );
        var getSupplierDetailsFilterRest = $resource(
            'api/v1/supplierDetailInfo/getSupplierDetailsFilter/:selectedCategoryId',
            {selectedCategoryId: '@selectedCategoryId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            get: function (supplierId, successCallback, errorCallback) {
                return supplierDetailInfoRest.get({supplierId: supplierId}, successCallback, errorCallback);
            },
            update: function (supplierId, jsonObject, successCallback, errorCallback) {
                return updateSupplierDetailInfoRest.update(
                    {supplierId: supplierId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            getSupplierDetailsFilter: function (selectedCategoryId, successCallback, errorCallback) {
                return getSupplierDetailsFilterRest.get(
                    {selectedCategoryId: selectedCategoryId},
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);
