export default function (appModule) {
    appModule.config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider.state('healthcheck', {
                url: '/healthcheck',
                templateUrl: './app/components/modules/healthcheck/healthcheck.view.html',
            });
        }
    ]);
}