class OpenYoutubeModal {
    constructor(close, $element, data, $sce) {
        this._injected = { close, $element, data, $sce };
        console.log(data);
        this.movie = { videoId: data.videoId, title: data.title };
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }

    trustSrc(src) {
        let { $sce } = this._injected;
        return $sce.trustAsResourceUrl(src);
    }
}
OpenYoutubeModal.$inject = ['close', '$element', 'data', '$sce'];
export default OpenYoutubeModal;
