import _ from 'underscore';

class OgOfferpartDropdownController {
    constructor($timeout, $scope, $translate) {
        this._injected = { $timeout, $scope, $translate };

        if (!this.minLength) {
            this.minLength = 0;
        }

        this.uniqueId = Math.random();

        $scope.$watch('ogSupplierDropdownCtrl.open', (newval) => {
            if (newval) {
                $timeout(() => {
                    let identifier = this.uniqueId + '_value';
                    var searchInput = document.getElementById(identifier);
                    searchInput.focus();
                });
            }
        });
        //this.remoteUrl = window.location.protocol +"//"+ window.location.host + "/api/v1/offerPartType";
        this.select = this.select.bind(this);
        this.getInitialValue = this.getInitialValue.bind(this);
        this.remoteUrlRequest = this.remoteUrlRequest.bind(this);
        this.translateParts = this.translateParts.bind(this);
        this.getPartFromSearchString = this.getPartFromSearchString.bind(this);
        this.updateListCount = 0;
    }

    remoteUrlRequest(str) {
        return { name: str };
    }

    getInitialValue() {
        if (!this.selected) {
            return;
        }
        return this.selected.name;
    }

    select(selected) {
        if (!selected) {
            return;
        }
        let _nameCode = selected.originalObject.nameCode;
        let _name = selected.originalObject.name;
        let _offerPartCategory = selected.originalObject.offerPartCategory;
        let _isSuggestionEnabled = selected.originalObject.isSuggestionEnabled;
        this.updateSelectedPartNameCallback({
            name: _name,
            nameCode: _nameCode,
            offerPartCategory: _offerPartCategory,
            isSuggestionEnabled: _isSuggestionEnabled,
        });
        this.updateListCount = 1;
    }

    getPartFromSearchString($item) {
        let _selected = _.find(this.parts, (o) => {
            return o.translatedName.toLowerCase() === $item.toLowerCase();
        });

        let _selectedNameCode = null;
        let _offerPartCategory = null;
        let _isSuggestionEnabled = null;

        if (_selected) {
            _selectedNameCode = _selected.nameCode;
            _offerPartCategory = _selected.offerPartCategory;
            _isSuggestionEnabled = _selected.isSuggestionEnabled;
            this.updateListCount = 1;
        }

        if (this.updateListCount === 1) {
            this.updateListCount = 0;
            if (_selected) {
                this.updateListCount = 1;
            }
        }
        this.updateSelectedPartNameCallback({
            name: $item,
            nameCode: _selectedNameCode,
            offerPartCategory: _offerPartCategory,
            isSuggestionEnabled: _isSuggestionEnabled,
            updateListFlag: this.updateListCount,
        });
    }

    translateParts(resp) {
        const { $translate } = this._injected;
        this.parts = resp;
        angular.forEach(this.parts, (part) => {
            if (part.nameCode) {
                part.translatedName = $translate.instant(part.nameCode);
            } else {
                part.translatedName = part.name;
            }
        });
        return this.parts;
    }
}

OgOfferpartDropdownController.$inject = ['$timeout', '$scope', '$translate'];
export default OgOfferpartDropdownController;
