//This might contain duplicate code from og_content_and_supplier.directive.js. When implementing the new sidebar in
// supplier as well as budget tab, this will replace og_content_and_supplier.directive.js.

import selectSupplierModal from '../../modals/modals2.0/select-supplier';
import offerPreviewModal from '../../modals/modals2.0/offer-preview';
import amountAndPriceService from '../../../utils/price_calculate/PriceCalculationCreatorUtil';
import requestOfferPartsUtil from '../../../utils/request_offer_parts/RequestOfferPartsUtil';
import addTransportModal from '../../modals/modals2.0/add-transport-modal';
import addConferenceActivityModal from '../../modals/modals2.0/add-conference-activity-modal';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import { UtilFunctions } from "../../../utils/global/utilFunctions";
import { offerStatusMessageUtil } from "../../../utils/global/offerStatusMessageUtil";
import { OgModal } from "../../../utils/global/ogModal";
import _ from 'underscore';

(function () {
    /* global angular */
    'use strict';

    angular.module('ogContentAndSuppliersSidebar').directive('ogContentAndSuppliersSidebar', function () {
        return {
            restrict: 'EA',
            /* jshint ignore:start */
            templateUrl:
                './app/components/directives/content_and_suppliers_sidebar/og_content_and_suppliers_sidebar.view.html',
            /* jshint ignore:end */
            replace: true,
            scope: {
                requests: '=requests',
                event: '=event',
                addPartCallback: '&',
                addRequestCallback: '&',
                eventClickCallback: '&',
                hasContractedSupplier: '=hasContractedSupplier',
                showContractedSuppliersOnly: '=showContractedSuppliersOnly',
                isEventDateApproaching: '=',
                extraRequirements: '=extraRequirements',
                initialBounds: '=',
                initialLocation: '=',
            },
            controllerAs: 'ContentAndSuppliersSidebarCtrl',
            bindToController: true,
            controller: [
                '$scope',
                '$stateParams',
                '$rootScope',
                'EventEnum',
                'GlobalConstants',
                'Request',
                '$state',
                'RequestOffer',
                'ModalService',
                'ogScrollService',
                '$translate',
                'TransportService',
                'ActivityService',
                'FilledRequestOfferPartComment',
                'RequestOfferStatusUtils',
                'NotificationService',
                'CommonFunctions',
                function (
                    $scope,
                    $stateParams,
                    $rootScope,
                    EventEnum,
                    GlobalConstants,
                    Request,
                    $state,
                    RequestOffer,
                    ModalService,
                    ogScrollService,
                    $translate,
                    TransportService,
                    ActivityService,
                    FilledRequestOfferPartComment,
                    RequestOfferStatusUtils,
                    NotificationService,
                    CommonFunctions
                ) {
                    var vm = this;
                    vm.eventId = $stateParams.eventId;
                    vm.addManualRequest = addManualRequestFn;
                    vm.addRequest = addRequestFn;
                    vm.canDeleteOffer = canDeleteOfferFn;
                    vm.canRemoveSupplier = canRemoveSupplierFn;
                    vm.confirmDeleteOffer = confirmDeleteOfferFn;
                    vm.confirmRemoveSupplier = confirmRemoveSupplierFn;
                    vm.confirmRequestDelete = confirmRequestDeleteFn;
                    vm.disableAddOfferPart = disableAddOfferPartFn;
                    vm.editRequestName = editRequestNameFn;
                    vm.hasCreatorEligibleOfferParts = hasCreatorEligibleOfferPartsFn;

                    //todo(SC) remove following two lines after checking its usage
                    vm.getAmountLocalisedWithCurrency = amountAndPriceService.getAmountLocalisedWithCurrency;
                    vm.getTotalPrice = amountAndPriceService.getOfferTotalPrice;

                    vm.getOfferTotalPrice = getOfferTotalPriceFn;
                    vm.getOfferStateDescription = getOfferStateDescriptionFn;
                    vm.getOfferStateButtonDescription = getOfferStateButtonDescriptionFn;
                    vm.hasCommentOnPart = hasCommentOnPartFn;
                    vm.isEligibleToMakeInternal = isEligibleToMakeInternalFn;
                    vm.isEligibleToMakeExternal = isEligibleToMakeExternalFn;
                    vm.isOfferInBookedState = isOfferInBookedStateFn;
                    vm.isOfferInBookedOrLockedState = isOfferInBookedOrLockedStateFn;
                    vm.isOfferInCreatorWaitingState = isOfferInCreatorWaitingStateFn;
                    vm.isOfferInEditedByCreatorState = isOfferInEditedByCreatorStateFn;
                    vm.isOfferInFinishedOrApprovedState = isOfferInFinishedOrApprovedStateFn;
                    vm.isOfferInCanceledFinishedOrApprovedState = isOfferInCanceledFinishedOrApprovedStateFn;
                    vm.isOfferInUnsentState = isOfferInUnsentStateFn;
                    vm.isOfferCancelledOrDeclined = isOfferCancelledOrDeclinedFn;
                    vm.isOfferIsInAnsweredState = isOfferIsInAnsweredStateFn;
                    vm.isOfferIsInDeclinedState = isOfferIsInDeclinedStateFn;
                    vm.isOfferInCanceledState = isOfferInCanceledStateFn;
                    vm.isOfferIsInEditedByCreatorAndAlreadyAnswered = isOfferIsInEditedByCreatorAndAlreadyAnsweredFn;
                    vm.isOfferWaitingForCreator = isOfferWaitingForCreatorFn;
                    vm.isFinishedWaitingForCreator = isFinishedWaitingForCreatorFn;
                    vm.makeRequestExternal = makeRequestExternalFn;
                    vm.makeRequestInternal = makeRequestInternalFn;
                    vm.openModalOnEventClick = openModalOnEventClickFn;
                    vm.removeSupplierFromEvent = removeSupplierFromEventFn;
                    vm.respondToCreatorWaitingOffer = respondToCreatorWaitingOfferFn;
                    vm.requestAfterBookedState = requestAfterBookedStateFn;
                    vm.scrollToRequest = scrollToRequestFn;
                    vm.scrollToOffer = scrollToOfferFn;
                    vm.sendOffersExtraStep = sendOffersExtraStepFn;
                    vm.addOfferPart = addOfferPartFn;
                    vm.shouldDisplayOfferInSidebar = shouldDisplayOfferInSidebarFn;
                    vm.showOfferPrice = showOfferPriceFn;
                    vm.getState = getStateFn;
                    vm.openAddSupplierModal = openAddSupplierModalFn;
                    // vm.openAddSupplierModalForDeclined = openAddSupplierModalForDeclinedFn;
                    vm.openOfferPreviewModal = openOfferPreviewModalFn;
                    vm.trimSupplierNameBeautify = trimSupplierNameBeautifyFn;
                    vm.updateRequestName = updateRequestNameFn;
                    vm.toggleShowAll = toggleShowAllFn;
                    vm.updateSelectedOffer = updateSelectedOfferFn;
                    vm.openAddTransportModal = openAddTransportModalFn;
                    vm.openAddConferenceActivityModal = openaddConferenceActivityModalFn;
                    vm.bookDirectly = false;
                    vm.showPopup = false;
                    vm.viewModal = false;

                    switch ($stateParams.highlightElement) {
                        case 'addSupplierButton':
                            vm.animateAddSupplierButton = true;
                            setTimeout(function () {
                                vm.animateAddSupplierButton = false;
                            }, 6800);
                            break;
                        case 'viewOfferButton':
                            vm.animateViewOfferButton = true;
                            setTimeout(function () {
                                vm.animateViewOfferButton = false;
                            }, 6800);
                            break;
                        case 'respondOfferButton':
                            vm.animateRespondOfferButton = true;
                            setTimeout(function () {
                                vm.animateRespondOfferButton = false;
                            }, 6800);
                            break;
                        case 'finalDetailsButton':
                            vm.animatefinalDetailsButton = true;
                            setTimeout(function () {
                                vm.animatefinalDetailsButton = false;
                            }, 6800);
                            break;
                    }

                    let partOptionSelectedValueChanged = $rootScope.$on(
                        'part:option:is:selected:value:changed',
                        function (event, data) {
                            vm.requests.forEach((request) => {
                                request.requestOffers.forEach((requestOffer) => {
                                    if (data.offerId === requestOffer.id) {
                                        requestOffer.offerParts.forEach((offerPart) => {
                                            if (
                                                data.offerPartId === offerPart.id &&
                                                offerPart.hasRequestOfferPartOptions
                                            ) {
                                                offerPart.offerPartOptions.forEach((offerPartOption) => {
                                                    if (data.offerPartOptionId === offerPartOption.id) {
                                                        offerPartOption.isSelected = data.isSelected;
                                                    }
                                                });
                                            }
                                        });
                                    }
                                });
                            });
                            vm.requests.forEach((request) => {
                                request.requestOffers.forEach((requestOffer) => {
                                    if (data.offerId === requestOffer.id) {
                                        // Update suggestions with timed offer parts
                                        requestOffer.offerPartSchedule.timedOfferParts.forEach((timedPart) => {
                                            timedPart.partData.forEach((partData) => {
                                                partData.parts.forEach((part) => {
                                                    if (
                                                        data.offerPartId === part.id &&
                                                        part.hasRequestOfferPartOptions
                                                    ) {
                                                        part.offerPartOptions.forEach((offerPartOption) => {
                                                            if (data.offerPartOptionId === offerPartOption.id) {
                                                                offerPartOption.isSelected = data.isSelected;
                                                            }
                                                        });
                                                    }
                                                });
                                            });
                                        });
                                        // Update suggestions with timeless offer parts
                                        requestOffer.offerPartSchedule.timelessOfferParts.forEach((timelessPart) => {
                                            timelessPart.partData.forEach((partData) => {
                                                partData.parts.forEach((part) => {
                                                    if (
                                                        data.offerPartId === part.id &&
                                                        part.hasRequestOfferPartOptions
                                                    ) {
                                                        part.offerPartOptions.forEach((offerPartOption) => {
                                                            if (data.offerPartOptionId === offerPartOption.id) {
                                                                offerPartOption.isSelected = data.isSelected;
                                                            }
                                                        });
                                                    }
                                                });
                                            });
                                        });
                                    }
                                });
                            });
                        }
                    );

                    $scope.$on('$destroy', partOptionSelectedValueChanged);

                    ScopeFunctions.addToScope($scope);

                    function addManualRequestFn() {
                        OgModal.open('request:add', { allowInternalContent: vm.event.ownerGroup.allowInternalContent });
                        $rootScope.$emit('event:user:action', { action: 'add.empty.module' });
                    }

                    function addRequestFn() {
                        vm.addRequestCallback();
                        ogScrollService.scrollToElement('scroll-on-show-add-content');
                    }

                    function addOfferPartFn(request) {
                        vm.addPartCallback({
                            data: { id: request.id, name: request.name, iconId: request.iconId },
                            timeless: false,
                        });
                    }

                    function canDeleteOfferFn(offer, offerCount) {
                        if (offer.statusDetailed.name !== 'INTERNAL') {
                            let statusLogs = offer.statusLogs;
                            let _offerPreviouslyBooked = statusLogs.some(
                                (log) => RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(log) !== -1
                            );
                            return offer && offer.deletable && offerCount >= 1 && !_offerPreviouslyBooked;
                        }
                    }

                    function canRemoveSupplierFn(offer, offerCount) {
                        return offer && offer.deletable && offerCount == 1 && offer.supplier;
                    }

                    function confirmDeleteOfferFn(offer) {
                        vm.selectedOffer = offer;
                        var settings = {
                            message: 'request.offer.delete.notification',
                            okCallBack: vm.removeSupplierFromEvent,
                        };
                        $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, settings);
                        CommonFunctions.stopPropagation();
                        return false;
                    }

                    function confirmRemoveSupplierFn(offer) {
                        vm.selectedOffer = offer;
                        var settings = {
                            message: 'request.offer.supplier.remove.notification',
                            okCallBack: vm.removeSupplierFromEvent,
                        };
                        $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, settings);
                        CommonFunctions.stopPropagation();
                        return false;
                    }

                    function confirmRequestDeleteFn(request) {
                        vm.request = request;
                        if (vm.request.deletable) {
                            if (isAnyRequestOfferBookedPreviouslyBooked(vm.request.requestOffers)) {
                                OgModal.open('alert', { message: 'request.cannot.delete.previously.booked' });
                            } else {
                                OgModal.open('confirmation', {
                                    message: 'request.delete.confirm',
                                    okCallBack: deleteRequest,
                                });
                            }
                        } else {
                            OgModal.open('alert', { message: 'request.cannot.delete' });
                        }
                    }

                    function isAnyRequestOfferBookedPreviouslyBooked(offers) {
                        if (!offers) {
                            return false;
                        }
                        let length = offers.length;
                        for (let i = 0; i < length; i++) {
                            let offer = offers[i];
                            let statusLogs = offer.statusLogs;
                            if (
                                statusLogs.some((log) => RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(log) !== -1)
                            ) {
                                return offer;
                            }
                        }
                        return false;
                    }

                    function makeRequestInternalFn(request) {
                        let offer = request.requestOffers[0];
                        let params = { requestId: request.id, offerId: offer.id, status: 'INTERNAL' };
                        $scope.startProgress();
                        RequestOffer.update(
                            params,
                            (response) => {
                                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                                    requestId: request.id,
                                    offerId: offer.id,
                                });
                            },
                            $scope.endWithErrorOverlay()
                        );
                    }

                    function makeRequestExternalFn(request) {
                        let offer = request.requestOffers[0];
                        let params = { requestId: request.id, offerId: offer.id, status: 'NEW_NO_SUPPLIER' };
                        $scope.startProgress();
                        RequestOffer.update(
                            params,
                            (response) => {
                                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                                    requestId: request.id,
                                    offerId: offer.id,
                                });
                            },
                            $scope.endWithErrorOverlay()
                        );
                    }

                    function deleteRequest() {
                        $scope.startProgress();
                        Request['delete'](
                            vm.eventId,
                            vm.request.id,
                            {},
                            function () {
                                $rootScope.$broadcast(EventEnum.SUPPLIER_REMOVED_FROM_PANEL);
                                $scope.$emit(EventEnum.EVENT_DETAIL_PAGE_RELOAD);
                                $scope.endProgress();
                            },
                            (err) => {
                                console.log(err);
                                $scope.endWithAlert(err);
                            }
                        );
                    }

                    function disableAddOfferPartFn(request) {
                        return request.requestOffers.some((offer) => vm.isOfferInFinishedOrApprovedState(offer));
                    }

                    function editRequestNameFn(request) {
                        request.requestNameEditMode = true;
                        request.editedRequestName = request.name;
                    }

                    function hasCreatorEligibleOfferPartsFn(request) {
                        let _offer = request.requestOffers[0];
                        let _creatorEligibleOfferParts = getCreatorEligibleOfferPartsInOfferFn(_offer);
                        vm.creatorEligibleOfferPartsInFirstOffer = _creatorEligibleOfferParts;
                        return _creatorEligibleOfferParts.length > 0;
                    }

                    function getCreatorEligibleOfferPartsInOfferFn(offer) {
                        let filterParts = requestOfferPartsUtil.filterOfferPartsForCreator(offer, offer.offerParts);
                        filterParts = _.filter(filterParts, (part) => {
                            return part && part.status.name !== 'CANCELED';
                        });
                        return filterParts;
                    }

                    function getStateFn() {
                        return $state.current.name;
                    }

                    function hasCommentOnPartFn(part) {
                        return UtilFunctions.hasCommentOnPart(part);
                    }

                    function isEligibleToMakeInternalFn(request) {
                        if (vm.event.ownerGroup.allowInternalContent) {
                            return !request.isInternal && !request.isRequestSent;
                        }
                    }

                    function isEligibleToMakeExternalFn(request) {
                        if (vm.event.ownerGroup.allowInternalContent) {
                            return request.isInternal;
                        }
                    }

                    function isOfferInBookedStateFn(offer) {
                        return RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(offer.status.name) > -1;
                    }

                    function isOfferInBookedOrLockedStateFn(offer) {
                        return RequestOfferStatusUtils.BOOKED_OR_LOCKED_STATUS.indexOf(offer.status.name) > -1;
                    }

                    function isOfferInFinishedOrApprovedStateFn(offer) {
                        return RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf(offer.status.name) > -1;
                    }

                    function isOfferInCanceledFinishedOrApprovedStateFn(offer) {
                        return RequestOfferStatusUtils.ALL_CANCELED_FINISHED_STATUS.indexOf(offer.status.name) > -1;
                    }

                    function isOfferInEditedByCreatorStateFn(offer) {
                        return ['EDITED_BY_CREATOR'].indexOf(offer.status.name) > -1;
                    }

                    function isOfferInCreatorWaitingStateFn(offer) {
                        return RequestOfferStatusUtils.ALL_CREATOR_WAITING_STATUS.indexOf(offer.status.name) > -1;
                    }

                    function isOfferIsInAnsweredStateFn(offer) {
                        return 'ANSWERED' === offer.status.name;
                    }

                    function isOfferIsInDeclinedStateFn(offer) {
                        return 'DECLINED' === offer.status.name;
                    }

                    function isOfferInCanceledStateFn(offer) {
                        return 'CANCELED' === offer.status.name;
                    }

                    function isOfferIsInEditedByCreatorAndAlreadyAnsweredFn(offer) {
                        if (isOfferInEditedByCreatorStateFn(offer)) {
                            return CommonFunctions.hasIntersectValue(['ANSWERED'], offer.statusLogs);
                        }
                        return false;
                    }

                    function isOfferInUnsentStateFn(offer) {
                        return RequestOfferStatusUtils.UNSENT_STATUS_LIST.indexOf(offer.status.name) > -1;
                    }

                    function isOfferCancelledOrDeclinedFn(offer) {
                        return RequestOfferStatusUtils.CANCELLED_DECLINED_STATUS.indexOf(offer.status.name) > -1;
                    }

                    function isOfferWaitingForCreatorFn(request) {
                        return request.requestOffers.some(
                            (offer) => offer.status.name === 'BOOKED_WAITING_FOR_CREATOR'
                        );
                    }

                    function isFinishedWaitingForCreatorFn(request) {
                        return request.requestOffers.some(
                            (offer) => offer.status.name === 'FINISHED_WAITING_FOR_CREATOR'
                        );
                    }

                    function removeSupplierFromEventFn() {
                        if (vm.selectedOffer) {
                            RequestOffer['delete'](
                                vm.selectedOffer.id,
                                supplierRemovedBroadCast,
                                $scope.endWithErrorOverlay()
                            );
                        }
                    }

                    function respondToCreatorWaitingOfferFn(request) {
                        let waitingOffer = request.requestOffers.find((offer) => {
                            //can only have at most one at a time
                            return (
                                offer.status.name === 'BOOKED_WAITING_FOR_CREATOR' ||
                                offer.status.name === 'FINISHED_WAITING_FOR_CREATOR' ||
                                offer.status.name === 'CANCELED_WAITING_FOR_CREATOR'
                            );
                        });
                        openOfferPreviewModalFn(waitingOffer, request, vm.event, 'OFFER_CONTENT');
                    }

                    function sendOffersExtraStepFn(event, request) {
                        let requestId = request.id;
                        vm.isRequestSent = request.isRequestSent;
                        let unsentOffers = request.unsentRequestOffers;
                        let supplierCount = request.supplierCount;
                        vm.showPopup = vm.extraRequirements && supplierCount < 3 && !vm.isRequestSent;
                        const SELECT_SUPPLIER_STEP_TWO = vm.showPopup ? 1 : 2;

                        $rootScope.$emit('event:user:action', {
                            action: 'sent.request.to.suppliers.clicked',
                            data: { event: event.id, request: requestId },
                        });
                        if (unsentOffers.some((offer) => offer.offerParts.length > 0)) {
                            vm.openAddSupplierModal(
                                event,
                                requestId,
                                SELECT_SUPPLIER_STEP_TWO,
                                unsentOffers,
                                vm.showPopup
                            );
                        } else {
                            OgModal.open('alert', { message: 'event.detail.sidebar.cannot.send.empty.request' });
                        }
                    }

                    function shouldDisplayOfferInSidebarFn(request, offer) {
                        if (request.hasBookedOffer) {
                            return (
                                RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(offer.status.name) > -1 ||
                                !!request.displayAllSuppliers
                            );
                        } else if (request.hasFinishedOffer) {
                            return (
                                RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf(offer.status.name) > -1 ||
                                !!request.displayAllSuppliers ||
                                RequestOfferStatusUtils.ALL_CANCELED_FINISHED_STATUS.indexOf(offer.status.name) > -1
                            );
                        } else {
                            return true;
                        }
                    }

                    function supplierRemovedBroadCast(response) {
                        $rootScope.$broadcast(EventEnum.SUPPLIER_REMOVED_FROM_PANEL, response);
                        $scope.$emit(EventEnum.EVENT_DETAIL_PAGE_RELOAD);
                    }

                    //TODO(SC) should rename this function now it does not scroll any more
                    function scrollToRequestFn(requestId) {
                        let request = CommonFunctions.findItemInListById(vm.requests, requestId);
                        let offer = request.requestOffers[0];
                        openOfferPreviewModalFn(offer, request, vm.event, 'OFFER_CONTENT');
                    }

                    //TODO(SC) should rename this function now it does not scroll any more
                    function scrollToOfferFn(requestId, offerId) {
                        let request = CommonFunctions.findItemInListById(vm.requests, requestId);
                        let offer = CommonFunctions.findItemInListById(request.requestOffers, offerId);
                        openOfferPreviewModalFn(offer, request, vm.event, 'OFFER_CONTENT');
                    }

                    function openAddSupplierModalFn(event, requestId, step, unsentOffers, showPopup) {
                        if(vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        var requestObj = CommonFunctions.findItemInListById(vm.requests, requestId);
                        var canceledOffers = requestObj.requestOffers.filter(function (obj) {
                            return vm.isOfferInCanceledState(obj);
                        });
                        var declinedOffers = requestObj.requestOffers.filter(function (obj) {
                            return vm.isOfferIsInDeclinedState(obj);
                        });
                        let isThereAccommodation = false,
                            isThereConference = false,
                            isThereAccomAndConf = false,
                            isThereConferenceOnly = false;
                        angular.forEach(requestObj.offerParts, (part) => {
                            if (!isThereAccommodation && part.category && part.category.name === 'ACCOMMODATION') {
                                isThereAccommodation = true;
                            }
                            if (
                                !isThereConference &&
                                part.partType &&
                                part.partType.nameCode === 'common.translate.offer.part.type.conference.room'
                            ) {
                                isThereConference = true;
                            }
                        });
                        if (!isThereAccommodation && !!isThereConference) {
                            isThereConferenceOnly = true;
                        }
                        if (!!isThereAccommodation && !!isThereConference) {
                            isThereAccomAndConf = true;
                        }
                        let _conferenceRoom = _.find(requestObj.offerParts, function (part) {
                            return (
                                part.partType &&
                                part.partType.nameCode === 'common.translate.offer.part.type.conference.room'
                            );
                        });
                        let _accommodations = _.filter(requestObj.offerParts, function (part) {
                            return part.category && part.category.name === 'ACCOMMODATION';
                        });

                        let data = {
                            eventId: event.id,
                            eventAddress: event.eventAddress,
                            bounds: vm.initialBounds,
                            location: vm.initialLocation,
                            requestId: requestId,
                            step: step,
                            unsentOffers: unsentOffers,
                            canceledOffers: canceledOffers,
                            declinedOffers: declinedOffers,
                            hasContractedSupplier: vm.hasContractedSupplier,
                            showContractedSuppliersOnly: vm.showContractedSuppliersOnly,
                            extraRequirements: vm.extraRequirements,
                            showPopup: showPopup,
                            isThereConferenceOnly: isThereConferenceOnly,
                            isThereAccomAndConf: isThereAccomAndConf,
                            conference: _conferenceRoom,
                            startDate: event.fromDate,
                            accommodations: _accommodations,
                        };
                        let selectSupplierModalData = selectSupplierModal;
                        selectSupplierModalData.inputs = { data };

                        ModalService.showModal(selectSupplierModalData).then((modal) => {
                            vm.viewModal = false;
                            $rootScope.$emit('event:user:action', {
                                action: 'add.supplier.button.clicked.in.request',
                                data: { event: event.id, request: requestId },
                            });
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                    }

                    function openOfferPreviewModalFn(offer, request, event, activeTab, bookDirectly) {
                        if (!request.editable || vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        let isThereAccommodation = false,
                            isThereConference = false,
                            isThereConferenceCategory = false,
                            categoryIds = [];
                        angular.forEach(offer.offerParts, (part) => {
                            if (!isThereAccommodation && part.category && part.category.name === 'ACCOMMODATION') {
                                isThereAccommodation = true;
                            }
                        });
                        angular.forEach(request.categories, (category) => {
                            if (
                                !isThereConferenceCategory &&
                                category.nameCode &&
                                category.nameCode === 'common.translate.category.conference.room'
                            ) {
                                isThereConferenceCategory = true;
                            }
                            categoryIds.push(category.id);
                        });
                        if (!isThereConferenceCategory) {
                            angular.forEach(offer.offerParts, (part) => {
                                if (
                                    !isThereConference &&
                                    part.partType &&
                                    part.partType.nameCode === 'common.translate.offer.part.type.conference.room'
                                ) {
                                    isThereConference = true;
                                }
                            });
                        }
                        let data = {
                            offer: offer,
                            request: request,
                            event: event,
                            callback: vm.updateSelectedOffer,
                            bookDirectly: vm.bookDirectly,
                            activeTab: activeTab,
                            isThereAccommodation: isThereAccommodation,
                            isThereConference: isThereConference,
                            isThereConferenceCategory: isThereConferenceCategory,
                            categoryIds: categoryIds,
                        };
                        let offerModalData = offerPreviewModal;
                        offerModalData.inputs = { data };
                        ModalService.showModal(offerModalData).then((modal) => {
                            vm.viewModal = false;
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                    }

                    function updateSelectedOfferFn(offer) {
                        let requestObj = CommonFunctions.findItemInListById(vm.requests, offer.request.id);
                        let offerObj = CommonFunctions.findItemInListById(requestObj.requestOffers, offer.id);
                        if (!offerObj) {
                            return;
                        }
                        offerObj['confirmWithSupplier'] = offer.confirmWithSupplier;
                    }

                    function trimSupplierNameBeautifyFn(supplierName) {
                        return UtilFunctions.trimStringBeautify(supplierName, 25);
                    }

                    function getOfferStateDescriptionFn(requestOffer) {
                        return offerStatusMessageUtil.getStatusMessage(requestOffer);
                    }

                    function getOfferTotalPriceFn(offer) {
                        let _eligParts = getCreatorEligibleOfferPartsInOfferFn(offer);
                        let _total = amountAndPriceService.getOfferTotalPrice(offer, _eligParts);
                        return amountAndPriceService.viewPrice(_total, offer.currency);
                    }

                    function getOfferStateButtonDescriptionFn(requestOffer) {
                        return offerStatusMessageUtil.getStatusButtonMessage(requestOffer);
                    }

                    function openModalOnEventClickFn(requestOfferPart, request) {
                        if (!request.editable) {
                            return;
                        }
                        vm.eventClickCallback({ data: requestOfferPart, timeless: requestOfferPart.timeless });
                    }

                    function showOfferPriceFn(offer) {
                        if (['CANCELED', 'DECLINED'].indexOf(offer.status.name) > -1) {
                            return false;
                        }
                        if (['INTERNAL'].indexOf(offer.status.name) > -1) {
                            return !offer.offerParts.every((part) => UtilFunctions.isUndefinedOrNull(part.price));
                        }
                        if (RequestOfferStatusUtils.ALL_CANCELED_FINISHED_STATUS.indexOf(offer.status.name) > -1) {
                            let cancelFeePart = _.find(offer.offerParts, (part) => {
                                return part.nameCode === 'common.translate.offer.part.type.cancellation.fee';
                            });
                            return !(cancelFeePart && UtilFunctions.isUndefinedOrNull(cancelFeePart.finalPrice));
                        }
                        let priceNotSetForParts = offer.offerParts.every((part) =>
                            UtilFunctions.isUndefinedOrNull(part.price)
                        );
                        let priceNotSetForPartOptions = false;
                        angular.forEach(offer.offerParts, (part) => {
                            if (part.hasRequestOfferPartOptions) {
                                let _partOptionWithUndefinedOrNullPrice = _.find(
                                    part.offerPartOptions,
                                    (offerPartOption) => {
                                        return UtilFunctions.isUndefinedOrNull(offerPartOption.price);
                                    }
                                );
                                if (_partOptionWithUndefinedOrNullPrice) {
                                    priceNotSetForPartOptions = true;
                                }
                            }
                        });

                        let cancellationFeesParts = _.filter(offer.offerParts, (part) => {
                            return part && part.status.name !== 'CANCELED';
                        });
                        let priceNotSetForCancellationParts = !!cancellationFeesParts
                            ? cancellationFeesParts.every((part) => UtilFunctions.isUndefinedOrNull(part.price))
                            : false;
                        let priceNotSetForCancellationPartOptions = false;
                        angular.forEach(cancellationFeesParts, (part) => {
                            if (part.hasRequestOfferPartOptions) {
                                let _partOptionWithUndefinedOrNullPrice = _.find(
                                    part.offerPartOptions,
                                    (offerPartOption) => {
                                        return UtilFunctions.isUndefinedOrNull(offerPartOption.price);
                                    }
                                );
                                if (_partOptionWithUndefinedOrNullPrice) {
                                    priceNotSetForCancellationPartOptions = true;
                                }
                            }
                        });
                        return (
                            (priceNotSetForCancellationParts && priceNotSetForCancellationPartOptions) ||
                            (priceNotSetForParts && priceNotSetForPartOptions) ||
                            !vm.isOfferInCreatorWaitingState(offer)
                        );
                    }

                    function updateRequestNameFn(request) {
                        request.name = request.editedRequestName;
                        $scope.startProgress(request.id);
                        Request.update(
                            request.id,
                            request,
                            function () {
                                request.requestNameEditMode = false;
                                $scope.$emit(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                                $scope.endProgress(request.id);
                            },
                            $scope.endWithErrorOverlay(request.id)
                        );
                    }

                    function requestAfterBookedStateFn(request) {
                        return request.hasBookedOffer || request.hasFinishedOffer;
                    }

                    function toggleShowAllFn(request) {
                        request.displayAllSuppliers = !request.displayAllSuppliers;
                    }

                    function openaddConferenceActivityModalFn() {
                        if(vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        let data = {
                            messageTitle:
                                "{{ 'common.onboarding.button.add	' | translate }} {{ 'common.onboarding.eventContent.conferenceActivity	' | translate }}",
                            eventId: vm.eventId,
                            fromDate: vm.event.fromDate,
                            toDate: vm.event.toDate,
                            participants: vm.event.participantAmount,
                            requestTypeCode: vm.requests[0] ? vm.requests[0].nameCode : null,
                            okCallBack: addActivitySuccess,
                        };

                        let addConferenceActivityModalData = addConferenceActivityModal;
                        addConferenceActivityModalData.inputs = { data };

                        ModalService.showModal(addConferenceActivityModalData).then((modal) => {
                            vm.viewModal = false;
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                    }

                    function addActivitySuccess() {
                        $scope.$emit(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                        NotificationService.setNotificationData(vm.eventId);
                    }

                    const offerCurrencyChanged = $rootScope.$on(
                        EventEnum.OFFER_CURRENCY_CHANGED,
                        function (event, data) {
                            angular.forEach(vm.requests, (request) => {
                                angular.forEach(request.requestOffers, (requestOffer) => {
                                    if (requestOffer.id === data.offerId) {
                                        requestOffer.currency = data.currency;
                                        return;
                                    }
                                });
                            });
                        }
                    );
                    $scope.$on('$destroy', offerCurrencyChanged);

                    function openAddTransportModalFn() {
                        if(vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        let data = {
                            messageTitle:
                                "{{ 'common.onboarding.button.add	' | translate }} {{ 'common.onboarding.eventContent.transportation	' | translate }}",
                            eventId: vm.eventId,
                            requests: vm.requests,
                            fromDate: vm.event.fromDate,
                            toDate: vm.event.toDate,
                            okCallBack: addTransportSuccess,
                        };

                        let addTransportModalData = addTransportModal;
                        addTransportModalData.inputs = { data };

                        ModalService.showModal(addTransportModalData).then((modal) => {
                            vm.viewModal = false;
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                    }

                    function addTransportSuccess() {
                        $scope.$emit(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                        NotificationService.setNotificationData(vm.eventId);
                    }
                },
            ],
        };
    });
})();
