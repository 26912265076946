import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    /* global angular */
    'use strict';
    angular.module('ogResourceCalendar').directive('ogResourceCalendar', [
        '$rootScope',
        '$sce',
        '$timeout',
        '$compile',
        '$translate',
        'uiCalendarConfig',
        'EventEnum',
        'GlobalConstants',
        'BrowserDetectService',
        'SupplierResourceAvailability',
        'DateService',
        'TimezoneService',
        '$templateCache',
        function (
            $rootScope,
            $sce,
            $timeout,
            $compile,
            $translate,
            uiCalendarConfig,
            EventEnum,
            GlobalConstants,
            BrowserDetectService,
            SupplierResourceAvailability,
            DateService,
            TimezoneService,
            $templateCache
        ) {
            var getPopoverTemplate = function () {
                return $templateCache.get('availability_popover.html');
            };
            return {
                restrict: 'EA',
                templateUrl: './app/components/directives/resource_calendar/og_resource_calendar.directive.view.html',
                replace: true,
                scope: {
                    supplierResources: '=',
                    supplierSlots: '=',
                    supplierId: '=',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        ScopeFunctions.addToScope($scope);
                        $scope.supplierSlotsWithData = [];
                        $scope.selectedDateSlotsWithData = [];
                        $scope.supplierSlotsForSingleDayWithPrice = [];
                        $scope.data = [];
                        $scope.selectedDateSlot = [];
                        $scope.showPriceAndDateRangeField = false;
                        $scope.selectedResource = 0;
                        $scope.calendarDate = angular.copy(new Date());
                        $scope.events = [];
                        $scope.eventSources = [$scope.events];

                        initFn();

                        $scope.openPopOver = function (data) {
                            $scope.selectedDateSlotsWithData = [];
                            angular.forEach(
                                $scope.supplierResourceAvailabilityForMonth,
                                (supplierResourceAvailability) => {
                                    if (supplierResourceAvailability.date === data.date) {
                                        $scope.selectedDateSlotsWithData.push(supplierResourceAvailability);
                                    }
                                }
                            );
                        };

                        function initFn() {
                            $scope.data.fromDate = new Date().getTime();
                            $scope.data.toDate = new Date().getTime();
                        }

                        $scope.getSupplierResourceAvailabilityForMonth = function () {
                            let _resourceId = $scope.currentResource.id;
                            let _params = {
                                supplierId: $scope.supplierId,
                                date: $scope.calendarDate.getTime(),
                            };
                            if ($scope.events.length > 0) {
                                $timeout(() => {
                                    $scope.events.splice(0, $scope.events.length);
                                });
                            }
                            $scope.startProgress('resource-calendar');
                            SupplierResourceAvailability.getSupplierResourceAvailabilityForMonth(
                                _resourceId,
                                _params,
                                (response) => {
                                    $scope.supplierResourceAvailabilityForMonth = response;
                                    $scope.endProgress('resource-calendar');
                                    if ($scope.supplierSlots) {
                                        angular.forEach($scope.supplierSlots, (supplierSlot) => {
                                            angular.forEach(
                                                $scope.supplierResourceAvailabilityForMonth,
                                                (supplierResourceAvailability) => {
                                                    if (
                                                        supplierResourceAvailability.slot.id === supplierSlot.id &&
                                                        !$scope.supplierSlotsWithData.find(
                                                            (x) => x.id === supplierSlot.id
                                                        )
                                                    ) {
                                                        supplierSlot['price'] = '';
                                                        supplierSlot['resource'] =
                                                            supplierResourceAvailability.resource;
                                                        supplierSlot['available'] = false;
                                                        $scope.supplierSlotsWithData.push(supplierSlot);
                                                    }
                                                }
                                            );
                                        });
                                    }
                                    if ($scope.supplierResourceAvailabilityForMonth.length > 0) {
                                        angular.forEach(
                                            $scope.supplierResourceAvailabilityForMonth,
                                            (supplierResourceAvailability) => {
                                                let _title = supplierResourceAvailability.slot.name + '<br/>';
                                                if (
                                                    supplierResourceAvailability.price &&
                                                    supplierResourceAvailability.price > 0
                                                ) {
                                                    _title =
                                                        supplierResourceAvailability.slot.name +
                                                        '<br/>' +
                                                        '<strong>' +
                                                        supplierResourceAvailability.price +
                                                        '</strong>';
                                                }

                                                $scope.events.push({
                                                    slotId: supplierResourceAvailability.slot.id,
                                                    displayOrder: supplierResourceAvailability.slot.displayOrder,
                                                    title: _title,
                                                    allDay: true,
                                                    date: supplierResourceAvailability.date,
                                                    start: parseDateInLocaleFormatFn(supplierResourceAvailability.date),
                                                    color: supplierResourceAvailability['available']
                                                        ? '#e8f5ed'
                                                        : '#fcd2d2',
                                                });
                                            }
                                        );
                                    }
                                    $scope.commonToggleSlot = $scope.supplierSlotsWithData[0];
                                    $scope.unCommonToggleSlots = [];
                                    angular.forEach($scope.supplierSlotsWithData, (supplierSlotWithData1) => {
                                        let _count = 0;
                                        angular.forEach($scope.supplierSlotsWithData, (supplierSlotWithData2) => {
                                            if (supplierSlotWithData1.id !== supplierSlotWithData2.id) {
                                                let _firstSlotStartTimeInMin =
                                                    parseInt(supplierSlotWithData1.startTime.split(':')[0]) * 60 +
                                                    parseInt(supplierSlotWithData1.startTime.split(':')[1]);
                                                let _firstSlotEndTimeInMin =
                                                    parseInt(supplierSlotWithData1.endTime.split(':')[0]) * 60 +
                                                    parseInt(supplierSlotWithData1.endTime.split(':')[1]);
                                                let _secondSlotStartTimeInMin =
                                                    parseInt(supplierSlotWithData2.startTime.split(':')[0]) * 60 +
                                                    parseInt(supplierSlotWithData2.startTime.split(':')[1]);
                                                let _secondSlotEndTimeInMin =
                                                    parseInt(supplierSlotWithData2.endTime.split(':')[0]) * 60 +
                                                    parseInt(supplierSlotWithData2.endTime.split(':')[1]);

                                                let _checkTimeOverLap = checkTimeRangeOverlaps(
                                                    _firstSlotStartTimeInMin,
                                                    _firstSlotEndTimeInMin,
                                                    _secondSlotStartTimeInMin,
                                                    _secondSlotEndTimeInMin
                                                );
                                                if (_checkTimeOverLap) {
                                                    _count++;
                                                }
                                            }
                                        });
                                        if (_count > 1) {
                                            $scope.commonToggleSlot = supplierSlotWithData1;
                                        } else {
                                            $scope.unCommonToggleSlots.push(supplierSlotWithData1);
                                        }
                                    });
                                },
                                $scope.endWithErrorOverlay('resource-calendar')
                            );
                        };

                        $scope.saveSupplierResourceAvailabilityBetweenDates = function () {
                            let _resourceId = $scope.currentResource.id;
                            let _params = {
                                supplierId: $scope.supplierId,
                                supplierSlotsWithData: $scope.supplierSlotsWithData,
                                fromDate: $scope.data.fromDate,
                                toDate: $scope.data.toDate,
                            };
                            $scope.startProgress('resource-calendar');
                            SupplierResourceAvailability.saveSupplierResourceAvailabilityBetweenDates(
                                _resourceId,
                                _params,
                                (response) => {
                                    $scope.supplierResourceAvailabilityBetweenDates = response;
                                    $scope.showPriceAndDateRangeField = false;
                                    $scope.data = [];
                                    initFn();
                                    $scope.endProgress('resource-calendar');
                                    if ($scope.supplierResourceAvailabilityBetweenDates.length > 0) {
                                        angular.forEach(
                                            $scope.supplierResourceAvailabilityBetweenDates,
                                            (supplierResourceAvailability) => {
                                                let _arr = $scope.events;
                                                for (var i = _arr.length - 1; i >= 0; i--) {
                                                    if (
                                                        _arr[i].title.split('<br/>')[0] ===
                                                        supplierResourceAvailability.slot.name &&
                                                        _arr[i].start ===
                                                        parseDateInLocaleFormatFn(supplierResourceAvailability.date)
                                                    ) {
                                                        _arr.splice(i, 1);
                                                    }
                                                }
                                                let _title = supplierResourceAvailability.slot.name + '<br/>';
                                                if (
                                                    supplierResourceAvailability.price &&
                                                    supplierResourceAvailability.price > 0
                                                ) {
                                                    _title =
                                                        supplierResourceAvailability.slot.name +
                                                        '<br/>' +
                                                        '<strong>' +
                                                        supplierResourceAvailability.price +
                                                        '</strong>';
                                                }
                                                $scope.events.push({
                                                    title: _title,
                                                    allDay: true,
                                                    date: supplierResourceAvailability.date,
                                                    slotId: supplierResourceAvailability.slot.id,
                                                    displayOrder: supplierResourceAvailability.slot.displayOrder,
                                                    start: parseDateInLocaleFormatFn(supplierResourceAvailability.date),
                                                    color: supplierResourceAvailability['available']
                                                        ? '#e8f5ed'
                                                        : '#fcd2d2',
                                                });
                                            }
                                        );
                                    }
                                    if ($scope.supplierResourceAvailabilityBetweenDates.length > 0) {
                                        angular.forEach(
                                            $scope.supplierResourceAvailabilityBetweenDates,
                                            (supplierResourceAvailability) => {
                                                let _arr = $scope.supplierResourceAvailabilityForMonth;
                                                for (var i = _arr.length - 1; i >= 0; i--) {
                                                    if (
                                                        _arr[i].slot.id === supplierResourceAvailability.slot.id &&
                                                        _arr[i].date === supplierResourceAvailability.date
                                                    ) {
                                                        _arr.splice(i, 1);
                                                    }
                                                }
                                                $scope.supplierResourceAvailabilityForMonth.push(
                                                    supplierResourceAvailability
                                                );
                                            }
                                        );
                                    }
                                    angular.forEach($scope.supplierSlotsWithData, (supplierSlotWithData) => {
                                        supplierSlotWithData.price = '';
                                        supplierSlotWithData.available = false;
                                    });
                                },
                                $scope.endWithErrorOverlay('resource-calendar')
                            );
                        };

                        $scope.saveSupplierResourceAvailability = function () {
                            let _resourceId = $scope.currentResource.id;
                            let _params = {
                                supplierId: $scope.supplierId,
                                selectedDateSlotsWithData: $scope.selectedDateSlotsWithData,
                            };
                            $scope.startProgress('resource-calendar');
                            SupplierResourceAvailability.saveSupplierResourceAvailability(
                                _resourceId,
                                _params,
                                (response) => {
                                    $scope.supplierResourceAvailabilitySingleDay = response;
                                    $scope.endProgress('resource-calendar');
                                    $('.popover').popover('hide');
                                    if ($scope.supplierResourceAvailabilitySingleDay.length > 0) {
                                        angular.forEach(
                                            $scope.supplierResourceAvailabilitySingleDay,
                                            (supplierResourceAvailability) => {
                                                let _arr = $scope.events;
                                                for (var i = _arr.length - 1; i >= 0; i--) {
                                                    if (
                                                        _arr[i].title.split('<br/>')[0] ===
                                                        supplierResourceAvailability.slot.name &&
                                                        _arr[i].start ===
                                                        parseDateInLocaleFormatFn(supplierResourceAvailability.date)
                                                    ) {
                                                        _arr.splice(i, 1);
                                                    }
                                                }
                                                let _title = supplierResourceAvailability.slot.name + '<br/>';
                                                if (
                                                    supplierResourceAvailability.price &&
                                                    supplierResourceAvailability.price > 0
                                                ) {
                                                    _title =
                                                        supplierResourceAvailability.slot.name +
                                                        '<br/>' +
                                                        '<strong>' +
                                                        supplierResourceAvailability.price +
                                                        '</strong>';
                                                }
                                                $scope.events.push({
                                                    title: _title,
                                                    allDay: true,
                                                    date: supplierResourceAvailability.date,
                                                    slotId: supplierResourceAvailability.slot.id,
                                                    displayOrder: supplierResourceAvailability.slot.displayOrder,
                                                    start: parseDateInLocaleFormatFn(supplierResourceAvailability.date),
                                                    color: supplierResourceAvailability['available']
                                                        ? '#e8f5ed'
                                                        : '#fcd2d2',
                                                });
                                            }
                                        );
                                    }
                                    if ($scope.supplierResourceAvailabilitySingleDay.length > 0) {
                                        angular.forEach(
                                            $scope.supplierResourceAvailabilitySingleDay,
                                            (supplierResourceAvailability) => {
                                                let _arr = $scope.supplierResourceAvailabilityForMonth;
                                                for (var i = _arr.length - 1; i >= 0; i--) {
                                                    if (
                                                        _arr[i].slot.id === supplierResourceAvailability.slot.id &&
                                                        _arr[i].date === supplierResourceAvailability.date
                                                    ) {
                                                        _arr.splice(i, 1);
                                                    }
                                                }
                                                $scope.supplierResourceAvailabilityForMonth.push(
                                                    supplierResourceAvailability
                                                );
                                            }
                                        );
                                    }
                                },
                                $scope.endWithErrorOverlay('resource-calendar')
                            );
                        };

                        $scope.changeCurrentResource = function (resource, index) {
                            $scope.selectedResource = index;
                            if ($scope.currentResource.id != resource.id) {
                                $scope.currentResource = resource;
                                $scope.getSupplierResourceAvailabilityForMonth();
                            }
                        };

                        $scope.setPriceAndDateRange = function (val) {
                            $scope.showPriceAndDateRangeField = val;
                        };

                        $scope.toggleAvailableForSlot = function (slotId) {
                            if ($scope.supplierSlotsWithData.length > 0) {
                                let _existingSlot = _.find($scope.supplierSlotsWithData, (p) => {
                                    return p.id === slotId;
                                });
                                if (_existingSlot.id === $scope.commonToggleSlot.id) {
                                    angular.forEach($scope.supplierSlotsWithData, (supplierSlotWithData) => {
                                        if (supplierSlotWithData.id !== $scope.commonToggleSlot.id) {
                                            supplierSlotWithData['available'] = _existingSlot['available'];
                                        }
                                    });
                                } else {
                                    let _existingUnCommonSlot = _.find($scope.unCommonToggleSlots, (p) => {
                                        return p.id === slotId;
                                    });
                                    let _withoutExistingUnCommonSlot = _.without(
                                        $scope.unCommonToggleSlots,
                                        _existingUnCommonSlot
                                    );
                                    let _commonSlot = _.find($scope.supplierSlotsWithData, (p) => {
                                        return p.id === $scope.commonToggleSlot.id;
                                    });
                                    let _unCommonSlot1 = _.find($scope.supplierSlotsWithData, (p) => {
                                        return p.id === _existingUnCommonSlot.id;
                                    });
                                    let _unCommonSlot2 = _.find($scope.supplierSlotsWithData, (p) => {
                                        return p.id === _withoutExistingUnCommonSlot[0].id;
                                    });
                                    _commonSlot['available'] =
                                        _unCommonSlot1['available'] && _unCommonSlot2['available'];
                                }
                            }
                        };

                        $scope.toggleAvailableForSlotPopover = function (slotId) {
                            if ($scope.selectedDateSlotsWithData.length > 0) {
                                let _existingSlot = _.find($scope.selectedDateSlotsWithData, (p) => {
                                    return p.slot.id === slotId;
                                });
                                if (_existingSlot.slot.id === $scope.commonToggleSlot.id) {
                                    angular.forEach($scope.selectedDateSlotsWithData, (supplierSlotWithData) => {
                                        if (supplierSlotWithData.slot.id !== $scope.commonToggleSlot.id) {
                                            supplierSlotWithData['available'] = _existingSlot['available'];
                                        }
                                    });
                                } else {
                                    let _existingUnCommonSlot = _.find($scope.unCommonToggleSlots, (p) => {
                                        return p.id === slotId;
                                    });
                                    let _withoutExistingUnCommonSlot = _.without(
                                        $scope.unCommonToggleSlots,
                                        _existingUnCommonSlot
                                    );
                                    let _commonSlot = _.find($scope.selectedDateSlotsWithData, (p) => {
                                        return p.slot.id === $scope.commonToggleSlot.id;
                                    });
                                    let _unCommonSlot1 = _.find($scope.selectedDateSlotsWithData, (p) => {
                                        return p.slot.id === _existingUnCommonSlot.id;
                                    });
                                    let _unCommonSlot2 = _.find($scope.selectedDateSlotsWithData, (p) => {
                                        return p.slot.id === _withoutExistingUnCommonSlot[0].id;
                                    });
                                    _commonSlot['available'] =
                                        _unCommonSlot1['available'] && _unCommonSlot2['available'];
                                }
                            }
                        };

                        function parseDateInLocaleFormatFn(date) {
                            let dateFormat = DateService.getResourceCalendarDateFormat();
                            return TimezoneService.formatDate(new Date(parseInt(date)), dateFormat);
                        }

                        function checkTimeRangeOverlaps(a_start, a_end, b_start, b_end) {
                            if (a_start < b_start && b_start < a_end) return true; // b starts in a
                            if (a_start < b_end && b_end < a_end) return true; // b ends in a
                            if (b_start < a_start && a_end < b_end) return true; // a in b
                            return false;
                        }

                        $scope.viewRender = function (view) {
                            let _calendarDateYear = $scope.calendarDate.getFullYear();
                            let _calendarDateMonth = $scope.calendarDate.getMonth() + 1;
                            let _resourceDateYear = view.intervalStart.toDate().getFullYear();
                            let _resourceDateMonth = view.intervalStart.toDate().getMonth() + 1;
                            if (_calendarDateYear !== _resourceDateYear || _calendarDateMonth !== _resourceDateMonth) {
                                $scope.calendarDate = view.intervalStart.toDate();
                            }
                        };

                        $scope.$watch('calendarDate', (newVal, oldVal) => {
                            if (oldVal || (!oldVal && oldVal !== newVal)) {
                                $timeout(() => {
                                    uiCalendarConfig.calendars.resourceCalendar.fullCalendar('gotoDate', newVal);
                                    $scope.data.fromDate = $scope.calendarDate.getTime();
                                    $scope.data.toDate = $scope.calendarDate.getTime();
                                    $scope.getSupplierResourceAvailabilityForMonth();
                                });
                            }
                        });

                        $scope.extraEventSignature = function (event) {
                            return [event.color, event.test];
                        };
                    },
                ],

                link: function ($scope, element) {
                    $timeout(function () {
                        if ($scope.supplierResources && $scope.supplierResources.length > 0) {
                            $scope.currentResource = $scope.supplierResources[0];
                        }
                    });

                    let popoverTemplate =
                        '<div class="popover resource-popover" role="tooltip" style="min-width: 350px; max-width: 380px;""><h3 class="popover-title text-right"></h3><div class="popover-content"></div></div>';

                    function popoverContent() {
                        var html = getPopoverTemplate();
                        var popOverContent = $compile(html)($scope);
                        return popOverContent;
                    }

                    function eventRender(event, element) {
                        element.find('span.fc-title').html(event.title);
                        element.popover({
                            title: '<i id="closePopover" class="fa fa-times-circle f-18 pointer" aria-hidden="true"></i>',
                            content: popoverContent,
                            template: popoverTemplate,
                            html: true,
                            trigger: 'click',
                            placement: 'top',
                            animation: true,
                            container: 'body',
                        });
                    }

                    $(document).on('click', '#closePopover', function () {
                        $('.popover').popover('hide');
                    });

                    $scope.uiConfig = {
                        contentHeight: 'auto',
                        handleWindowResize: true,
                        defaultView: 'month',
                        fixedWeekCount: false,
                        themeSystem: 'bootstrap3',
                        eventRender: eventRender,
                        viewRender: $scope.viewRender,
                        eventOrder: 'displayOrder',
                        eventClick: $scope.openPopOver,
                        eventColor: 'transparent',
                        eventBackgroundColor: 'transparent',
                        eventTextColor: 'black',
                        customRender: true,
                        events: [],
                    };
                },
            };
        },
    ]);
})();
