'use strict';
import './header-navigation/og-header.module';
import './header-navigation/og-header.component';

import './user_group_select/user_group_select.module';
import './user_group_select/user_group_select.directive';

import './logo/og.logo.module';
import './logo/og.logo.component';

import './activity_time_elapsed/og.activity.time.elapsed.module'
import './activity_time_elapsed/og.activity.time.elapsed.directive'

import './activity_message/og.activity.message.module'
import './activity_message/og.activity.message.directive'

import './common_translation/og_common_translation.module'
import './common_translation/og_common_translation.directive'

import './progress_overlay/og_progress_overlay.module'
import './progress_overlay/og_progress_overlay.directive'

import './spinner/og_spinner.module'
import './spinner/og_spinner.component'

import './activity/og.activity.stream.module'
import './activity/og.activity.stream.component'

import './tasks_stream/og_task_stream.module'
import './tasks_stream/og_task_stream.directive'

import './task_additional_info/og_task_additional_info.module'
import './task_additional_info/og_task_additional_info.directive'

import './offer_box/og_offer_box.module'
import './offer_box/og_offer_box.directive'

import './og-event-dropdown/og_event_dropdown.module'
import './og-event-dropdown/og_event_dropdown-directive'

import './og_ui_date/og_ui_date.module'
import './og_ui_date/og_ui_date.directive'

import './billing_address/og.billing.address.module'
import './billing_address/og.billing.address.directive'

import './email_settings/og.email.settings.module'
import './email_settings/og.email.settings.component'

import './presentation_profile_settings/og_presentation_profile_settings.module'
import './presentation_profile_settings/og_presentation_profile_settings.component'

import './click/og_click.module'
import './click/og_click.directive'

import './field_input/og_field_input.module'
import './field_input/og_field_input.directive'

import './tooltip/ogTooltip.module';
import './tooltip/ogTooltip.directive';

import './sort_attributes/og_sort_attributes.module';
import './sort_attributes/og_sort_attributes.directive';

import './event_box_template/og_event_box_template.module';
import './event_box_template/og_event_box_template.directive';

import './category_drop_down/og.category.dropdown.module';
import './category_drop_down/og.category.dropdown.directive';

import './location_input/og_location_input.module';
import './location_input/og_location_input.directive';

import './category_typeahead/og_category_typeahead.module';
import './category_typeahead/og_category_typeahead.directive';

import './auto-focus/showFocus.module';
import './auto-focus/showFocus.directive';

import './offer_status/og_offer_status.module';
import './offer_status/og_offer_status.directive';

import './participants_for_supplier/og_participants_for_supplier.module';
import './participants_for_supplier/og_participants_for_supplier.directive';

import './event_summary/og_event_summary.module';
import './event_summary/og_event_summary.directive';

import './customer_user_activity_tracker/og.customer.user.activity.tracker.module';
import './customer_user_activity_tracker/og.customer.user.activity.tracker.component';

import './event_process_sidebar/og_event_process_sidebar.module';
import './event_process_sidebar/og_event_process_sidebar.directive';

import './add_content_side_bar/og_add_content_side_bar.module';
import './add_content_side_bar/og_add_content_side_bar.directive';

import './event_schedule_summary/event_schedule_summary.module';
import './event_schedule_summary/event_schedule_summary.directive';

import './accommodation_calendar/og_accommodation_calendar.module';
import './accommodation_calendar/og_accommodation_calendar.directive';

import './accommodation/og_accommodation.module';
import './accommodation/og_accommodation.directive';

import './date_interval/og_date_interval.module';
import './date_interval/og_date_interval.directive';

import './accommodation_input/og_accommodation_input.module';
import './accommodation_input/og_accommodation_input.directive';

import './accommodation_calendar_box/og_accommodation_calendar_box.module';
import './accommodation_calendar_box/og_accommodation_calendar_box.directive';

import './content_and_suppliers_sidebar/og_content_and_suppliers_sidebar.module';
import './content_and_suppliers_sidebar/og_content_and_suppliers_sidebar.directive';

import './request_category_icon/og_request_category_icon.module';
import './request_category_icon/og_request_category_icon.directive';

import './offer_part_comments/og_offer_part_comment.module';
import './offer_part_comments/og_offer_part_comment.directive';

import './user_group_select_tree_structure/user_group_select_tree_structure.module';
import './user_group_select_tree_structure/user_group_select_tree_structure.directive';

import './user_profile_image/og_user_profile_image.module';
import './user_profile_image/og_user_profile_image.directive';

import './managers_events_calendar/og_managers_event_calendar.module';
import './managers_events_calendar/og_managers_event_calendar.directive';


import './supplier_map_canvas/og_supplier_map_canvas.module';
import './supplier_map_canvas/og_supplier_map_canvas.directive';

import './map-canvas/map_canvas.module';
import './map-canvas/map_canvas.directive';

import './event_summary_print/og_event_summary_print.module';
import './event_summary_print/og_event_summary_print.directive';

import './event_summary_message/og.event.summary.message.module';
import './event_summary_message/og.event.summary.message.directive';

import './budget/og.budget.module';
import './budget/og.budget.directive';

import './budget/budget_detail/og.budget.detail.module';
import './budget/budget_detail/og.budget.detail.directive';

import './budget/budget_markup_detail/og.budget.markup.detail.module';
import './budget/budget_markup_detail/og.budget.markup.detail.directive';

import './budget/budget_client_selection/og.budget.client.selection.module';
import './budget/budget_client_selection/og.budget.client.selection.directive';

import './budget/budget_client_selection_detail/og.budget.client.selection.detail.module';
import './budget/budget_client_selection_detail/og.budget.client.selection.detail.directive';

import './markup-request-offer-part-option/og_markup_request_offerpart_option.module';
import './markup-request-offer-part-option/og_markup_request_offerpart_option.directive';

import './budget-request-offer-part-option/og_budget_request_offer_part_option.module';
import './budget-request-offer-part-option/og_budget_request_offer_part_option.directive';

import './supplier_presentation_images/og_supplier_presentation_images.module';
import './supplier_presentation_images/og_supplier_presentation_images.directive';

import './owl_carousel/og_owl_carousel.module';
import './owl_carousel/og_owl_carousel.directive';

import './add_supplier/og_add_supplier.module';
import './add_supplier/og_add_supplier.directive';

import './resource_calendar/og_resource_calendar.module';
import './resource_calendar/og_resource_calendar.directive';

import './supplier_resource_images/og_supplier_resource_images.module';
import './supplier_resource_images/og_supplier_resource_images.directive';

import './supplier_resource_form/og_supplier_resource_form.module';
import './supplier_resource_form/og_supplier_resource_form.directive';

import './dynamic-form/og.dynamic.form.module';
import './dynamic-form/og.dynamic.form.directive';

import './dynamic_question/og.additional.questions.module';
import './dynamic_question/og.dynamic.fields.directives';
import './dynamic_question/og.dynamic.fields.readonly.directives';

import './rich-text-editor/og_rich_text_editor.module';
import './rich-text-editor/og_rich_text_editor.directive';

import './dropdown/editDropDown/og_input_dropdown.module';
import './dropdown/editDropDown/og_input_dropdown.directive';

import './text-angular-editor/og_text_angular_editor.module';
import './text-angular-editor/og_text_angular_editor.directive';

import './event_supplier_summary/og.event.supplier.summary.module';
import './event_supplier_summary/og.event.supplier.summary.directive';

import './footer/og.footer.module';
import './footer/og.footer.directive';

import './png-logo/og.png_logo.module';
import './png-logo/og.png_logo.directive';

import './change/og_change.module';
import './change/og_change.directive';

import './supplier-checkbox_group/ogSupplierCheckboxGroup.module';
import './supplier-checkbox_group/ogSupplierCheckboxGroup.directive';

import './supplier-resource-modal-image/og_supplier_resource_modal_image.module';
import './supplier-resource-modal-image/og_supplier_resource_modal_image.directive';

import './supplier_default_resource_form/og_supplier_default_resource_form.module';
import './supplier_default_resource_form/og_supplier_default_resource_form.directive';

import './supplier_default_resource_modal_form/og_supplier_default_resource_modal_form.module';
import './supplier_default_resource_modal_form/og_supplier_default_resource_modal_form.directive';

import './og_sec_user_role_granted/og-sec-user-role-granted.module';
import './og_sec_user_role_granted/og-sec-user-role-granted.directive';

import './supplier_presentation/og_supplier_presentation.module';
import './supplier_presentation/og_supplier_presentation.directive';

import './map-canvas/map_canvas.module';
import './map-canvas/map_canvas.directive';

import './auto-complete-address-searchbox/og_auto_complete_address_searchbox.module';
import './auto-complete-address-searchbox/og_auto_complete_address_searchbox.component';

import './secondary_logo/og.secondary.logo.module'
import './secondary_logo/og.secondary.logo.directive'

import './og-supplier-dropdown/og_supplier_dropdown.module';
import './og-supplier-dropdown/og_supplier_dropdown-directive';

import './form/og_form.module';
import './form/og_form.directive';

import './add_supplier_from_map/og_add_supplier_from_map.module';
import './add_supplier_from_map/og_add_supplier_from_map.directive';

import './supplier_thumbnail/og_supplier_thumbnail.module';
import './supplier_thumbnail/og_supplier_thumbnail.directive';

import './supplier_result_map/og_supplier_result_map.module';
import './supplier_result_map/og_supplier_result_map.directive';

import './autocomplete/og_autocomplete.module';
import './autocomplete/og_autocomplete.directive';

import './design_for_invitation/og_design_for_invitation.module';
import './design_for_invitation/og_design_for_invitation.directive';

import './badge_menu_item/og_badge_menu_item.module';
import './badge_menu_item/og_badge_menu_item.component';

import './design_for_event_info_site/og_design_for_event_info_site.module';
import './design_for_event_info_site/og_design_for_event_info_site.directive';

import './design_for_confirmation_email/og_design_for_confirmation_email.module';
import './design_for_confirmation_email/og_design_for_confirmation_email.directive';

import './multi_select/og_multi_select.module';
import './multi_select/og_multi_select.directive';

import './file_select/og_file_select.module';
import './file_select/og_file_select.directive';

import './custom_popover/og_custom_popover.module';
import './custom_popover/og_custom_popover.directive';

import './crop_image/og_crop_image.module';
import './crop_image/og_crop_image.directive';

import './file_input_box/og_file_input_box.module';
import './file_input_box/og_file_input_box.directive';

import './request_offer_part_add_edit_inline/request_offer_part_add_edit_inline.module';
import './request_offer_part_add_edit_inline/request_offer_part_add_edit_inline.directive';

import './og-offerpart-dropdown/og_offerpart_dropdown.module';
import './og-offerpart-dropdown/og_offerpart_dropdown-directive';

import './date_time_picker_pair/og_date_time_picker_pair.module';
import './date_time_picker_pair/og_date_time_picker_pair.directive';

import './add_edit_ticket/og_add_edit_ticket.module';
import './add_edit_ticket/og_add_edit_ticket.component';

import './audit_log/og_audit_log.module';
import './audit_log/og_audit_log.component';

import './autocomplete-address/og_auto_complete_address.module';
import './autocomplete-address/og_auto_complete_address.directive';

import './autocomplete_multi_address/og_auto_complete_multi_address.module';
import './autocomplete_multi_address/og_auto_complete_multi_address.directive';

import './back_button/og_back_button.module';
import './back_button/og_back_button.directive';

import './blur/og_blur.module';
import './blur/og_blur.directive';

import './creator_offer_part_summary/og_creator_offer_part_summary.module';
import './creator_offer_part_summary/og_creator_offer_part_summary.directive';

import './crop_event_banner_image/og_crop_event_banner_image.module';
import './crop_event_banner_image/og_crop_event_banner_image.directive';

import './crop_event_thumbnail_image/og_crop_event_thumbnail_image.module';
import './crop_event_thumbnail_image/og_crop_event_thumbnail_image.directive';

import './customToolTip/ogCustomTooltip.module';
import './customToolTip/ogCustomTooltip.directive';

import './date_picker_pair/og_date_picker_pair.module';
import './date_picker_pair/og_date_picker_pair.directive';

import './date_time_format/og_date_time_format.module';
import './date_time_format/og_date_time_format.directive';

import './date_time_picker/og_date_time_picker.module';
import './date_time_picker/og_date_time_picker.directive';

import './delete_undelete_row/og_delete_undelete_row.module';
import './delete_undelete_row/og_delete_undelete_row.directive';

import './disabled/og_disabled.module';
import './disabled/og_disabled.directive';

import './dynamic-element/og.dynamic.element.module';
import './dynamic-element/og.dynamic.element.directive';

import './entity_rating/og_entity_rating.module';
import './entity_rating/og_entity_rating.directive';

import './error_id/og_error_id.module';
import './error_id/og_error_id.directive';

import './field_status/og_field_status.module';
import './field_status/og_field_status.directive';

import './fixed_notification/og_fixed_notification.module';
import './fixed_notification/og_fixed_notification.directive';

import './form_error/og_form_error.module';
import './form_error/og_form_error.directive';

import './form_field/og_form_field.module';
import './form_field/og_form_field.directive';

import './google_places/og_google_places.module';
import './google_places/og_google_places.directive';

import './message_body/og_message_body.module';
import './message_body/og_message_body.directive';

import './message_stream/og_message_stream.module';
import './message_stream/og_message_stream.directive';

import './my_group_tree/user_group_member.module';
import './my_group_tree/user_group_member.directive';

import './my_group_tree/user_group_tree.module';
import './my_group_tree/user_group_tree.directive';

import './offer_activities/og_offer_activities.module';
import './offer_activities/og_offer_activities.directive';

import './offer_additional_info/og_offer_additional_info.module';
import './offer_additional_info/og_offer_additional_info.directive';

import './offer_preview_message/og.offer.preview.message.module';
import './offer_preview_message/og.offer.preview.message.directive';

import './offer_price_compare/og_offer_price_compare.module';
import './offer_price_compare/og_offer_price_compare.directive';

import './offer_status_button/og_offer_status_button.module';
import './offer_status_button/og_offer_status_button.directive';

import './office_address/og.office.address.module';
import './office_address/og.office.address.directive';

import './og-error-success-message/og-error-success-message.module';
import './og-error-success-message/og-error-success-message.directive';

import './og-location-dropdown/og_location_dropdown.module';
import './og-location-dropdown/og_location_dropdown.directive';


import './og-offer-part-option-dropdown/og_offer_part_option_dropdown.module';
import './og-offer-part-option-dropdown/og_offer_part_option_dropdown-directive';

import './og_intl_tel_input/og_intl_tel_input.module';
import './og_intl_tel_input/og_intl_tel_input.directive';

import './og_select_country_code/ogSelectCountryCode.module';
import './og_select_country_code/ogSelectCountryCode';

import './options_class/og_option_class.module';
import './options_class/og_option_class.directive';

import './overwriteEmailValidation/overwriteEmailValidation.module';
import './overwriteEmailValidation/overwriteEmailValidation.directive';

import './participant_registration_form/og_participant_registration_form.module';
import './participant_registration_form/og_participant_registration_form.directive';

import './participant_tickets/og_participant_tickets.module';
import './participant_tickets/og_participant_tickets.directive';

import './presentation/og_presentation.module';
import './presentation/og_presentation.directive';

import './presentation_for_site/og_presentation_for_site.module';
import './presentation_for_site/og_presentation_for_site.directive';

import './price-field/og_price_field.module';
import './price-field/og_price_field.directive';

import './rate_it/og_rate_it.module';
import './rate_it/og_rate_it.directive';

import './request_offer_part_option_add_edit_creator/request_offer_part_option_add_edit_creator.module';
import './request_offer_part_option_add_edit_creator/request_offer_part_option_add_edit_creator.directive';

import './request_offer_part_option_add_edit_inline/request_offer_part_option_add_edit_inline.module';
import './request_offer_part_option_add_edit_inline/request_offer_part_option_add_edit_inline.directive';

import './request_offer_part_option_add_edit_supplier/request_offer_part_option_add_edit_supplier.module';
import './request_offer_part_option_add_edit_supplier/request_offer_part_option_add_edit_supplier.directive';

import './request_offer_part_options_creator/request_offer_part_options_creator.module';
import './request_offer_part_options_creator/request_offer_part_options_creator.directive';

import './request_offer_part_options_supplier/request_offer_part_options_supplier.module';
import './request_offer_part_options_supplier/request_offer_part_options_supplier.directive';

import './required-explanation/og_required_explanation.module';
import './required-explanation/og_required_explanation.directive';

import './rich_edit/og_rich_edit.module';
import './rich_edit/og_rich_edit.directive';

import './rich_text/og_rich_text.module';
import './rich_text/og_rich_text.directive';

import './row_action_popover/og_row_action_popover.module';
import './row_action_popover/og_row_action_popover.directive';

import './row_actions/og_row_actions.module';
import './row_actions/og_row_actions.directive';

import './row_offer_part_option_action_popover/og_row_offer_part_option_action_popover.module';
import './row_offer_part_option_action_popover/og_row_offer_part_option_action_popover.directive';

import './row_offer_part_options_actions/og_row_offer_part_options_actions.module';
import './row_offer_part_options_actions/og_row_offer_part_options_actions.directive';

import './search/ogSearchResult.module';
import './search/ogSearchResult.directive';

import './search_result_tile/og_search_result_tile.module';
import './search_result_tile/og_search_result_tile.directive';

import './secondary_logo/og.secondary.logo.module';
import './secondary_logo/og.secondary.logo.directive';

import './star_rating/star_rating.module';
import './star_rating/star_rating.directive';

import './supplier_geotag_and_description_presentation/og_supplier_geotag_and_description_presentation.module';
import './supplier_geotag_and_description_presentation/og_supplier_geotag_and_description_presentation.directive';

import './supplier_offer_part_summary/og_supplier_offer_part_summary.module';
import './supplier_offer_part_summary/og_supplier_offer_part_summary.directive';

import './supplier_search_result_tile/og_supplier_search_result_tile.module';
import './supplier_search_result_tile/og_supplier_search_result_tile.directive';

import './survey_details/og_survey_details.module';
import './survey_details/og_survey_details.directive';

import './time_picker/og_time_picker.module';
import './time_picker/og_time_picker.directive';

import './timeless_activity/og_timeless_activity.module';
import './timeless_activity/og_timeless_activity.directive';

import './transclude/og_transclude.module';
import './transclude/og_transclude.directive';

import './user_message_image/og_user_message_image.module';
import './user_message_image/og_user_message_image.directive';

import './vat-field/og_vat_field.module';
import './vat-field/og_vat_field.directive';

import './dropdown/default/og_dropdown.module';
import './dropdown/default/og_dropdown.directive';

import './row_action_popover/og_row_action_popover.module';
import './row_action_popover/og_row_action_popover.directive';

import './client-app-logo/og.client.app.logo.module';
import './client-app-logo/og.client.app.logo.component';

import './client_show_hide_setting/og_client_show_hide_setting.module';
import './client_show_hide_setting/og_client_show_hide_setting.directive';

import './image_upload/og-image-upload-placeholder.module'
import './image_upload/og-image-upload-placeholder.component'

// Define the `core` module
angular.module('directive', [
    'ogHeaderDirective',
    'ogLogo',
    'activityTimeElapsed',
    'activityMessage',
    'ogUserGroupSelect',
    'ogCommonTranslate',
    'ogProgressOverlay',
    'activity.stream',
    'ogOfferBox',
    'ogEventDropdown',
    'ogUiDate',
    'ogSpinner',
    'ogBillingAddress',
    'email.settings',
    'presentation-profile-settings',
    'ogTasksStream',
    'ogTaskAdditionalInfo',
    'ogClick',
    'ogTooltip',
    'ogSortAttributes',
    'ogFieldInput',
    'ogEventBoxTemplate',
    'ogCategoryDropDown',
    'ogLocationInput',
    'ogCategoryTypeahead',
    'ogShowFocus',
    'ogOfferStatus',
    'ogParticipantsForSupplier',
    'ogEventSummary',
    'ogCustomerUserActivityTracker',
    'ogEventProcessSidebar',
    'ogAddContentSideBar',
    'ogEventScheduleSummary',
    'accommodation.calendar',
    'accommodation',
    'ogDateInterval',
    'accommodation.input',
    'accommodation.calendar.box',
    'ogContentAndSuppliersSidebar',
    'ogRequestCategoryIcon',
    'ogOfferPartComment',
    'ogUserGroupSelectTreeStructure',
    'ogUserProfileImage',
    'ogManagersEventCalendar',
    'ogSupplierMapCanvas',
    'ogMapCanvas',
    'ogEventSummaryPrint',
    'ogEventSummaryMessage',
    'ogBudget',
    'ogBudgetDetail',
    'ogBudgetMarkupDetail',
    'ogBudgetClientSelection',
    'ogBudgetClientSelectionDetail',
    'ogMarkupRequestOfferpartOption',
    'ogBudgetRequestOfferPartOption',
    'ogDynamicForm',
    'dnQuestions',
    'ogInputDropdown',
    'ogTextAngularEditor',
    'ogEventSupplierSummary',
    'ogFooter',
    'ogPngLogo',
    'ogChange',
    'ogRichTextEditor',
    'ogSupplierPresentationImages',
    'owlCarousel',
    'add.supplier',
    'ogResourceCalendar',
    'ogSupplierResourceImages',
    'ogSupplierResourceForm',
    'ogDynamicForm',
    'ogSupplierCheckboxGroup',
    'ogSupplierResourceModalImage',
    'ogSupplierDefaultResourceForm',
    'ogSupplierDefaultResourceModalForm',
    'ogSecUserRoleGranted',
    'ogSupplierPresentation',
    'auto.complete.search.box',
    'ogSecondaryLogo',
    'ogSupplierDropdown',
    'ogForm',
    'add.supplier.from.map',
    'ogSupplierThumbnail',
    'ogSupplierResultMap',
    'ogAutocomplete',
    'ogDesignForInvitation',
    'ogBadgeMenuItem',
    'ogDesignForEventInfoSite',
    'ogDesignForConfirmationEmail',
    'ogMultiSelect',
    'ogFileSelect',
    'ogCustomPopover',
    'ogCropImage',
    'ogFileInputBox',
    'ogRequestOfferPartAddEditInline',
    'ogOfferpartDropdown',
    'ogDateTimePickerPair',
    'autoCompleteAddress',
    'autoCompleteMultiAddress',
    'ogBackButton',
    'ogBlur',
    'ogCreatorOfferPartSummary',
    'ogCropEventBannerImage',
    'ogCropEventThumbnailImage',
    'ogCustomTooltip',
    'ogDynamicElement',
    'ogDisabled',
    'ogDeleteUndeleteRow',
    'ogDateTimePicker',
    'ogDateTimeFormat',
    'ogDatePickerPair',
    'add.edit.ticket',
    'audit.log',
    'validVat',
    'ogUserMessageImage',
    'ogTransclude',
    'ogTimelessActivity',
    'ogTimePicker',
    'ogSurveyDetails',
    'ogSupplierSearchResultTile',
    'ogSupplierOfferPartSummary',
    'ogSupplierGeotagAndDescriptionPresentation',
    'starRating',
    'ogSecondaryLogo',
    'ogSearchResultTile',
    'ogSearchResult',
    'ogRowOfferPartOptionsActions',
    'ogRowOfferPartOptionActionPopover',
    'ogRowActions',
    'ogRichText',
    'ogRichEdit',
    'ogRequiredExplanation',
    'ogRequestOfferPartOptionsSupplier',
    'ogRequestOfferPartOptionsCreator',
    'ogRequestOfferPartOptionAddEditSupplier',
    'ogRequestOfferPartOptionAddEditInline',
    'ogRequestOfferPartOptionAddEditCreator',
    'ogRateIt',
    'validPrice',
    'ogPresentationForSite',
    'ogPresentation',
    'ogParticipantTickets',
    'ogParticipantRegistrationForm',
    'overwriteEmailValidation',
    'ogOptionsClass',
    'ogCountryCodeSelect',
    'ogIntlTelInput',
    'ogOfferPartOptionDropdown',
    'ogLocationDropdown',
    'ogErrorSuccessMessage',
    'ogOfficeAddress',
    'ogOfferStatusButton',
    'ogOfferPriceCompare',
    'ogOfferPreviewMessage',
    'ogOfferAdditionalInfo',
    'ogOfferActivities',
    'userGroupMember',
    'ogMessageStream',
    'ogMessageBody',
    'ogGooglePlaces',
    'ogFormField',
    'ogFormError',
    'ogFixedNotification',
    'ogFieldStatus',
    'ogErrorId',
    'ogEntityRating',
    'ogDropdown',
    'ogRowActionPopover',
    'ogClientAppLogo',
    'ogClientShowHideSetting',
    'ogImageUploadPlaceholder'
]);
