/* global angular */
'use strict';

angular.module('core.search').factory('SearchService', function () {
    var searchText;

    function setSearchText(txt) {
        searchText = txt;
    }

    function getSearchText() {
        return searchText;
    }

    return {
        setSearchText: function (txt) {
            return setSearchText(txt);
        },
        getSearchText: function () {
            return getSearchText();
        },
    };
});
