'use strict';
import _ from 'underscore';

angular
    .module('core.customFilter', [])
    .filter('currencySymbol', function () {
        return function (price, symbol) {
            if (!price || price == 'null') {
                price = 0;
            }
            return symbol + price;
        };
    })
    .filter('localCurrency', [
        function () {
            return function (price) {
                if (!price || price == 'null') {
                    price = 0;
                }
                var localPrice = '# kr';
                return localPrice.replace('#', price);
            };
        },
    ])
    .filter('nl2br', function () {
        var regexp = /\n/g;

        return function (input) {
            var str;

            if (input) {
                str = input.replace(regexp, '<br>');
            } else {
                str = input;
            }
            /*
     .replace(linkreg, function(a){
     hits.push(a);
     return '||||' + index++ + '||||';
     });
     angular.forEach(hits, function(b,a) {
     str = str.replace('||||'+a+'||||', '<a href="' + (b.match(httpreg) ? b : 'http://' + b) + '">' + b + '</a>');
     });
     */
            return str;
        };
    })
    .filter('pluralize', [
        function () {
            return function (input, count) {
                input = input.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2": '); // transforms 1:"a" to "1":"a"
                var map = JSON.parse('{' + input + '}');
                var result = map.other;
                angular.forEach(map, function (value, key) {
                    if (count == key) {
                        result = value;
                    }
                });
                return result;
            };
        },
    ])
    .filter('striphtml', [
        function () {
            return function (input) {
                var result = input.replace(/<[^>]*>/gi, '');

                return result;
            };
        },
    ])
    .filter('ellipsis', [
        function () {
            return function (input, count) {
                var words = input.split(' ');

                if (words.length <= count) {
                    return input;
                }

                return words.slice(0, count).join(' ');
            };
        },
    ])
    .filter('truncate', [
        function () {
            return function (text, length, end) {
                if (!text) {
                    return;
                }
                if (isNaN(length)) {
                    length = 10;
                }

                if (end === undefined) {
                    end = '...';
                }

                if (text.length <= length || text.length - end.length <= length) {
                    return text;
                } else {
                    return String(text).substring(0, length - end.length) + end;
                }
            };
        },
    ])
    .filter('replaceCommaWithSpace', [
        function () {
            return function (x) {
                if (x === null || !x) {
                    x = 0;
                }
                return x.toString().replace(/,/g, ' ');
            };
        },
    ])
    .filter('withDate', [
        function () {
            return function (items) {
                var result = {};

                angular.forEach(items, function (item, key) {
                    if (item.day !== 0) {
                        result[key] = item;
                    }
                });

                return result;
            };
        },
    ])
    .filter('withoutDate', [
        function () {
            return function (items) {
                var result = {};

                angular.forEach(items, function (item, key) {
                    if (item.day === 0) {
                        result[key] = item;
                    }
                });

                return result;
            };
        },
    ])
    .filter('capitalizeFirstCharacter', function () {
        return function (input) {
            return !!input ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
        };
    })
    .filter('groupBy', [
        '$parse',
        function ($parse) {
            return _.memoize(function (items, field) {
                var getter = $parse(field);
                return _.groupBy(items, function (item) {
                    return getter(item);
                });
            });
        },
    ])
    .filter('linebreaks', function () {
        return function (text) {
            return text.replace(/\n/g, '<br>');
        };
    });
angular.module('core.customFilter').filter('daysLeft', [
    '$translate',
    function ($translate) {
        return function (input) {
            var ms = moment(input).diff(moment());
            var d = Math.ceil(moment.duration(ms).asDays());

            if (d < -1) {
                return -d + ' ' + $translate.instant('filter.daysleft.days.ago');
            } else if (d < 0) {
                return $translate.instant('filter.daysleft.yesterday');
            } else if (d === 0) {
                return $translate.instant('filter.daysleft.today');
            } else if (d === 1) {
                return $translate.instant('filter.daysleft.tomorrow');
            } else {
                return d + ' ' + $translate.instant('filter.daysleft.days.left');
            }
        };
    },
]);
