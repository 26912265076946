/**
 * Created with IntelliJ IDEA.
 * User: pc
 * Date: 12/9/19
 * Time: 3:51 PM
 * To change this template use File | Settings | File Templates.
 */
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class AlertModalCtrl {
    constructor(data, $scope, close, $element, $rootScope, $translate) {
        this._injected = {
            data,
            $scope,
            close,
            $element,
            $rootScope,
            $translate,
        };

        ScopeFunctions.addToScope($scope);

        this.message = '';
        this.messageTitle = '';
        this.isHtml = false;
        this.data = data;
        this.init(data);
    }

    init(data) {
        const { $translate } = this._injected;
        if (data.message) {
            this.message = $translate.instant(data.message, data.params);
        }
        if (data.messageTitle) {
            this.messageTitle = $translate.instant(data.messageTitle, data.params);
        }
        this.isHtml = data.isHtml;
    }

    ok() {
        if (this.data.okCallBack) {
            this.data.okCallBack();
        }
        this._close();
    }

    _close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

AlertModalCtrl.$inject = ['data', '$scope', 'close', '$element', '$rootScope', '$translate'];
export default AlertModalCtrl;
