'use strict';
import * as _ from 'underscore';
import {OgModal} from "../ogModal";

angular.module('util.commonFunction').factory('CommonFunctions', [
    '$log',
    '$rootScope',
    '$translate',
    '$timeout',
    '$location',
    '$q',
    'GlobalConstants',
    'CommonFile',
    function ($log, $rootScope, $translate, $timeout, $location) {

        return {
            stopPropagation: function () {
                var event = window.event;
                if (event) {
                    if (event.stopPropagation) {
                        event.stopPropagation();
                    } else {
                        event.cancelBubble = true;
                    }
                }
            },

            alert: function (errorResponse) {
                var currentLocation = $location.url();
                if (currentLocation != '/') {
                    OgModal.open('alert', {response: errorResponse});
                }
            },

            alertWithCallBack: function (msgAndCallBack) {
                var currentLocation = $location.url();
                if (currentLocation != '/') {
                    OgModal.open('alert', msgAndCallBack);
                }
            },

            alertInline: function (form) {
                return function (errorResponse) {
                    $rootScope.formError(form, errorResponse);
                };
            },

            formError: function (form, errorResponse) {
                $rootScope.$broadcast('validation:form:error:' + form, errorResponse);
            },

            formSuccess: function (form, message) {
                $rootScope.$broadcast('validation:form:success:' + form, message);
            },

            formInfo: function (form, message) {
                $rootScope.$broadcast('validation:form:info:' + form, message);
            },

            formClearError: function (form) {
                $rootScope.$broadcast('validation:form:' + form + ':clear');
            },

            alertSimple: function (errorResponse) {
                window.alert($translate.instant(this.errorMessage(errorResponse)));
            },

            errorMessage: function (errorResponse) {
                return this.errorMessages(errorResponse)[0].messageKey;
            },

            errorMessages: function (errorResponse, includeFieldErrors) {
                var message = 'operation.failed';
                var status = errorResponse.status;
                if (status == 401 || status == 403) {
                    message = 'http.' + errorResponse.status;
                } else if (status == 0) {
                    message = 'connection.error';
                }
                var data = errorResponse.data;
                if (data == null) {
                    data = errorResponse;
                }
                if (data && data.localisedMessages && data.localisedMessages.length >= 1) {
                    var messages = data.localisedMessages;
                    angular.forEach(messages, function (message) {
                        var params = {};
                        var i = 0;
                        angular.forEach(message.params, function (param) {
                            params['p' + i] = param;
                            i++;
                        });
                        message.type = 'ERROR';
                        message.params = params;
                    });
                    if (includeFieldErrors) {
                        messages = messages.concat(data.fieldErrors);
                    }
                    return messages;
                } else {
                    return [{messageKey: message}];
                }
            },

            isApp: function () {
                var subDomain = window.location.host;
                if (subDomain.indexOf('-') > 0) {
                    subDomain = subDomain.split('-')[0];
                }
                return subDomain.indexOf('app') > -1;
            },
            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            intersect: function (aList, bList) {
                if (aList && bList) {
                    var result = aList.filter(function (obj) {
                        return bList.indexOf(obj) != -1;
                    });
                    return result;
                }
            },
            setSameHeight: function (className) {
                $timeout(function () {
                    jQuery('.' + className).matchHeight();
                }, 2000);
            },
            setSameHeightWithoutTimeOut: function (className) {
                jQuery('.' + className).matchHeight();
            },

            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            hasIntersectValue: function (aList, bList) {
                if (aList && bList) {
                    var commonValue = this.intersect(aList, bList);
                    return commonValue.length > 0;
                }
            },

            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            convertToLowerCase: function (aList) {
                if (aList) {
                    var result = aList.map(function (item) {
                        return angular.lowercase(item);
                    });
                    return result;
                }
            },
            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            doAlertBeforeUpdate: function (offerPart) {
                return (
                    angular.isDefined(offerPart) &&
                    angular.isDefined(offerPart.editable) &&
                    ['NOTIFY_BEFORE_EDIT', 'DRAG_DROP_DENIED'].includes(offerPart.editable.name)
                );
            },

            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            accessDeniedToUpdate: function (offerPart) {
                if (angular.isDefined(offerPart) && offerPart.editable.name === 'DENIED') {
                    return true;
                }
                return false;
            },

            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            isMapAlreadyLoaded: function () {
                if (typeof google == 'undefined') {
                    return false;
                } else {
                    return true;
                }
            },

            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            findItemInListById: function (items, id) {
                var objId = Number(id);
                return _.findWhere(items, {id: objId});
            },

            getPublicURL: function () {
                return $rootScope.publicURL;
            },
            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            isItFinishedOffer: function (offer) {
                return this.hasIntersectValue(
                    [offer.status.name],
                    ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR', 'APPROVED', 'FINISHED_WAITING_FOR_SUPPLIER']
                );
            },
            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            isItCanceledOffer: function (offer) {
                return this.hasIntersectValue(
                    [offer.status.name],
                    [
                        'CANCELED_AND_FINISHED',
                        'CANCELED_WAITING_FOR_CREATOR',
                        'CANCELED_AND_APPROVED',
                        'CANCELED_WAITING_FOR_SUPPLIER',
                    ]
                );
            },


            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            touchFormFields: function (form) {
                angular.forEach(form.$error, function (field) {
                    angular.forEach(field, function (errorField) {
                        errorField.$setTouched();
                    });
                });
            },

            getTranslatedTextFromCode: function (text, code) {
                if (code) {
                    var translatedText = $translate.instant(code);
                    if (translatedText !== code) {
                        return translatedText;
                    }
                }
                return text;
            },

            /**
             * @deprecated Use CommonFunctionUtil instead
             */
            formatClientName: function (text) {
                if (text) {
                    var toLower = text.trim().toLowerCase();
                    text = toLower.replaceAll(' ', '_');
                }
                return text;
            }
        };
    },
]);