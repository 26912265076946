const CHANGED_BY_ME_OFFERS = [
    'EDITED_BY_CREATOR',
    'EDITED_WAITING_FOR_SUPPLIER',
    'BOOKED_LOCKED_BY_CREATOR',
    'NEW',
    'BOOKED_WAITING_FOR_CREATOR',
    'FINISHED_WAITING_FOR_CREATOR',
];
const EDITED_BY_ME_PARTS = [
    'EDITED_BY_CREATOR',
    'EDITED_WAITING_FOR_SUPPLIER',
    'ACCEPTED_BY_CREATOR_WITH_CHANGES',
    'EDITED_WAITING_FOR_CREATOR',
    'EDITED_WAITING_FOR_SUPPLIER_DECLINED',
];
const DELETED_BY_ME_PARTS = [
    'DELETED_BY_CREATOR',
    'DELETED_WAITING_FOR_CREATOR',
    'DELETED_WAITING_FOR_SUPPLIER_DECLINED',
];
const ADDED_BY_ME_PARTS = ['ADDED_BY_CREATOR', 'ADDED_WAITING_FOR_CREATOR', 'ADDED_WAITING_FOR_SUPPLIER_DECLINED'];

const CHANGED_BY_ME_SENT_OFFERS = ['SENT', 'BOOKED_WAITING_FOR_SUPPLIER'];
const EDITED_BY_ME_SENT_PARTS = ['EDITED_WAITING_FOR_SUPPLIER', 'EDITED_WAITING_FOR_CREATOR_DECLINED'];
const ADDED_BY_ME_SENT_PARTS = ['ADDED_WAITING_FOR_SUPPLIER', 'DELETED_WAITING_FOR_CREATOR_DECLINED'];
const DELETED_BY_ME_SENT_PARTS = ['DELETED_WAITING_FOR_SUPPLIER', 'ADDED_WAITING_FOR_CREATOR_DECLINED'];

const CHANGED_BY_THEM_OFFERS = ['BOOKED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_CREATOR'];
const EDITED_BY_THEM_PARTS = ['EDITED_WAITING_FOR_CREATOR', 'EDITED_WAITING_FOR_SUPPLIER_DECLINED'];
const ADDED_BY_THEM_PARTS = ['ADDED_WAITING_FOR_CREATOR', 'ADDED_WAITING_FOR_SUPPLIER_DECLINED'];
const DELETED_BY_THEM_PARTS = ['DELETED_WAITING_FOR_CREATOR', 'DELETED_WAITING_FOR_SUPPLIER_DECLINED'];

const PART_STATE = [
    'EDITED_BY_ME',
    'EDITED_BY_ME_SENT',
    'EDITED_BY_THEM',
    'ADDED_BY_ME',
    'ADDED_BY_ME_SENT',
    'ADDED_BY_THEM',
    'DELETED_BY_ME',
    'DELETED_BY_ME_SENT',
    'DELETED_BY_THEM',
];

class NegotiationTooltipCreatorUtil {
    static editedByMe(offer, part) {
        if (CHANGED_BY_ME_OFFERS.indexOf(offer.status.name) > -1) {
            return EDITED_BY_ME_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static addedByMe(offer, part) {
        if (CHANGED_BY_ME_OFFERS.indexOf(offer.status.name) > -1) {
            return ADDED_BY_ME_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static deletedByMe(offer, part) {
        if (CHANGED_BY_ME_OFFERS.indexOf(offer.status.name) > -1) {
            return DELETED_BY_ME_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static editedByMeAndSent(offer, part) {
        if (CHANGED_BY_ME_SENT_OFFERS.indexOf(offer.status.name) > -1) {
            return EDITED_BY_ME_SENT_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static addedByMeAndSent(offer, part) {
        if (CHANGED_BY_ME_SENT_OFFERS.indexOf(offer.status.name) > -1) {
            return ADDED_BY_ME_SENT_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static deletedByMeAndSent(offer, part) {
        if (CHANGED_BY_ME_SENT_OFFERS.indexOf(offer.status.name) > -1) {
            return DELETED_BY_ME_SENT_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static editedByThem(offer, part) {
        if (CHANGED_BY_THEM_OFFERS.indexOf(offer.status.name) > -1) {
            return EDITED_BY_THEM_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static addedByThem(offer, part) {
        if (CHANGED_BY_THEM_OFFERS.indexOf(offer.status.name) > -1) {
            return ADDED_BY_THEM_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static deletedByThem(offer, part) {
        if (CHANGED_BY_THEM_OFFERS.indexOf(offer.status.name) > -1) {
            return DELETED_BY_THEM_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static getPartNotificationState(offer, part) {
        if (NegotiationTooltipCreatorUtil.editedByMe(offer, part)) {
            return PART_STATE[0];
        }
        if (NegotiationTooltipCreatorUtil.editedByMeAndSent(offer, part)) {
            return PART_STATE[1];
        }
        if (NegotiationTooltipCreatorUtil.editedByThem(offer, part)) {
            return PART_STATE[2];
        }

        if (NegotiationTooltipCreatorUtil.addedByMe(offer, part)) {
            return PART_STATE[3];
        }
        if (NegotiationTooltipCreatorUtil.addedByMeAndSent(offer, part)) {
            return PART_STATE[4];
        }
        if (NegotiationTooltipCreatorUtil.addedByThem(offer, part)) {
            return PART_STATE[5];
        }

        if (NegotiationTooltipCreatorUtil.deletedByMe(offer, part)) {
            return PART_STATE[6];
        }
        if (NegotiationTooltipCreatorUtil.deletedByMeAndSent(offer, part)) {
            return PART_STATE[7];
        }
        if (NegotiationTooltipCreatorUtil.deletedByThem(offer, part)) {
            return PART_STATE[8];
        }
    }
}

const tooltipUtil = NegotiationTooltipCreatorUtil;
export default tooltipUtil;
