import controller from './og_rich_text_editor.controller.js';

(function () {
    'use strict';

    angular.module('ogRichTextEditor').directive('ogRichTextEditor', [
        function () {
            return {
                scope: {
                    tinymceModel: '=',
                    imageUpload: '@imageUpload', //support image upload ('true' or 'false')
                },
                templateUrl: './app/components/directives/rich-text-editor/og_rich_text_editor.view.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogTextEditorCtrl',
                bindToController: true,
            };
        },
    ]);
})();
