(function () {
    'use strict';
    /**
     * Creates a list of attribute that can be used for sorting.
     *
     * This is expected usage:
     *
     * <ul og-sort-attributes="sort">
     *  <li value="1">Featured</li>
     *  <li value="2">Release data</li>
     *  <li value="3">A-Z</li>
     * </ul>
     *
     * 'og-sort-attributes' defines attribute in parent scope that will be updated. Individual <li> tags then
     * specifies the attribute values and labels.
     *
     * TODO : Replace the parent <ul> with <div> or something similar in the directive compilation process
     * using replaceWith() doesn't work here.
     *
     */
    angular.module('ogSortAttributes').directive('ogSortAttributes', [
        '$compile',
        function ($compile) {
            function parseInfo(e) {
                var info = [];
                angular.forEach(e.find('li'), function (_item) {
                    var item = jQuery(_item);
                    info.push({
                        attr: item.attr('value'),
                        id: item.attr('href'),
                        className: item.attr('class'),
                        label: item.html(),
                    });
                });
                return info;
            }

            var buttonTemplate =
                "<li><a class=\"button small\" ng-class=\"@attrName == '@attrValue' && 'selected-item' ||" +
                "'secondary' \" ng-click=\"@attrName = '@attrValue';activityStreamCtrl.backToMessageList()\">@attrLabel</a></li>";
            var inlineTemplate =
                "<li><a class=\"sort-attribute\" ng-class=\"@attrName == '@attrValue' && 'selected-item' || " +
                "'secondary' \" ng-click=\"@attrName = '@attrValue'\">@attrLabel</a></li>";
            var tabPanTemplate =
                "<li role='presentation' class='@className'><a href=\"@attrId\"  " +
                'ng-click="@attrName = \'@attrValue\'" role="tab" data-toggle="tab">@attrLabel</a></li>';

            return {
                restrict: 'AE',
                link: function (scope, element, attrs) {
                    var template;
                    switch (attrs.markupStyle) {
                        case 'inline':
                            template = inlineTemplate;
                            break;
                        case 'tabPan':
                            template = tabPanTemplate;
                            break;
                        default:
                            template = buttonTemplate;
                            break;
                    }

                    var info = parseInfo(element);
                    element.empty().addClass(attrs.markupStyle == 'inline' ? 'filter-select' : 'button-group');
                    angular.forEach(info, function (item) {
                        var attrName = attrs.ogSortAttributes;
                        var updatedTemplate = template
                            .replace(/@attrName/g, attrName)
                            .replace(/@attrValue/g, item.attr)
                            .replace(/@attrLabel/g, item.label)
                            .replace(/@className/g, item.className)
                            .replace(/@attrId/g, item.id);
                        $compile(updatedTemplate)(scope).appendTo(element);
                    });
                    scope.$watch(attrs.ogSortAttributes, function (newValue, oldValue) {
                        if (newValue != oldValue) {
                            scope.$emit(attrs.ogSortAttributes + '/changed', newValue, oldValue);
                        }
                    });
                },
            };
        },
    ]);
})();
