import {ScopeFunctions} from '../../../utils/global/scopeFunctions';
import _ from "underscore";

(function () {
    /* global angular */
    'use strict';
    angular.module('ogEventScheduleSummary').directive('ogEventScheduleSummary', [
        '$rootScope',
        '$sce',
        '$timeout',
        '$compile',
        '$translate',
        'uiCalendarConfig',
        'EventEnum',
        'GlobalConstants',
        'BrowserDetectService',
        'CommonFunctions',
        function (
            $rootScope,
            $sce,
            $timeout,
            $compile,
            $translate,
            uiCalendarConfig,
            EventEnum,
            GlobalConstants,
            BrowserDetectService,
            CommonFunctions
        ) {
            var uiConfig;

            var translationForAmountTypes = [
                { name: 'PIECES', code: 'amount.type.PIECES.short' },
                { name: 'PEOPLE', code: 'amount.type.PEOPLE.short' },
                { name: 'SAME_AS_EVENT', code: 'amount.type.PEOPLE.short' },
            ];

            var translationForRooms = [
                { name: 'enkelrum', code: 'common.translate.offer.part.type.single.room' },
                { name: 'dubbelrum', code: 'common.translate.offer.part.type.double.room' },
            ];

            function renderCalendarWithResponse(scope, $translate, langSelected, done) {
                if (!scope.eventSchedule) {
                    return;
                }
                var timedOfferParts = scope.eventSchedule.timedOfferParts;
                scope.timelessOffers = scope.eventSchedule.timelessOfferParts;
                scope.beforeEventAccommodations = scope.eventSchedule.beforeEventAccommodations;
                scope.afterEventAccommodations = scope.eventSchedule.afterEventAccommodations;
                scope.hasAccommodationBeforeEvent = scope.eventSchedule.hasAccommodationBeforeEvent;
                scope.hasAccommodationAfterEvent = scope.eventSchedule.hasAccommodationAfterEvent;
                scope.hasBufferDaysBeforeEvent = scope.eventSchedule.hasBufferDaysBeforeEvent;
                scope.hasBufferDaysAfterEvent = scope.eventSchedule.hasBufferDaysAfterEvent;
                scope.canDeleteBufferDaysBeforeEvent = scope.eventSchedule.canDeleteBufferDaysBeforeEvent;
                scope.canDeleteBufferDaysAfterEvent = scope.eventSchedule.canDeleteBufferDaysAfterEvent;
                // default config
                scope.calendarList = timedOfferParts.map(function (cal) {
                    cal.config = angular.extend(
                        {},
                        cal.config,
                        angular.extend(
                            {},
                            uiConfig,
                            langSelected === 'en_US'
                                ? {
                                    timeFormat: 'hh:mm a', //set default
                                    slotLabelFormat: 'hh:mm a', //set default
                                }
                                : {}
                        )
                    );
                    cal.eventData[0].events.map(function (event) {
                        if (event.titleCode) {
                            let translatedTitle = $translate.instant(event.titleCode);
                            event.title = translatedTitle != event.titleCode ? translatedTitle : event.title;
                        }
                    });
                    return cal;
                });
                scope.calendarList.forEach(function (day, index) {
                    scope.startProgress(day.name);
                    $timeout(function () {
                        renderCalendar(day.name);
                        scope.endProgress(day.name);
                        if (index + 1 === scope.calendarList.length) {
                            if (typeof done === 'function') {
                                done(true);
                            }
                        }
                    }, 0);
                });

                if (scope.hasBufferDaysBeforeEvent && scope.hasAccommodationBeforeEvent) {
                    scope.calendarList[0].eventData.forEach((data) => {
                        data.doubleRoom = angular.copy(scope.beforeEventAccommodations[0].eventData[0].doubleRoom);
                        data.singleRoom = angular.copy(scope.beforeEventAccommodations[0].eventData[0].singleRoom);
                        data.otherAccommodations = angular.copy(
                            scope.beforeEventAccommodations[0].eventData[0].otherAccommodations
                        );
                    });
                }
                var lastDay = scope.calendarList[scope.calendarList.length - 1];
                var lastDayHasAccommodation =
                    lastDay.eventData[0].singleRoom ||
                    lastDay.eventData[0].doubleRoom ||
                    lastDay.eventData[0].otherAccommodations;
                var daysInEvent = scope.calendarList.length + scope.afterEventAccommodations.length;
                scope.totalNumberOfEventDays = lastDayHasAccommodation ? daysInEvent + 1 : daysInEvent;

                if (scope.hasBufferDaysBeforeEvent) {
                    let firstDay = scope.calendarList[0];
                    scope.fisrtDayHasAccommodation = checkIfDayHasAccommodation(firstDay);
                }
                if (scope.hasBufferDaysAfterEvent) {
                    let dayBeforeDayAfterEvent = scope.calendarList.at(-2);
                    scope.dayBeforeDayAfterEventHasAccommodation = checkIfDayHasAccommodation(dayBeforeDayAfterEvent);
                    $rootScope.$broadcast('buffer.days.after.event.updates', {
                        dayBeforeDayAfterEventHasAccommodation: scope.dayBeforeDayAfterEventHasAccommodation,
                    });
                }
            }

            function checkIfDayHasAccommodation(day) {
                return !!(
                    day.eventData[0].singleRoom ||
                    day.eventData[0].doubleRoom ||
                    day.eventData[0].otherAccommodations
                );
            }

            function renderCalendar(calendar) {
                if (uiCalendarConfig.calendars[calendar]) {
                    $timeout(function () {
                        uiCalendarConfig.calendars[calendar].fullCalendar('render');
                    }, 1);
                }
            }

            function viewRender(view, element) {
                element.find('.fc-day-header').html('');
            }

            return {
                restrict: 'EA',
                templateUrl: './app/components/directives/event_schedule_summary/event_schedule_summary.view.html',
                replace: true,
                scope: {
                    eventSchedule: '=',
                    dateTimeUpdateCallback: '&',
                    createCallback: '&',
                    eventClickCallback: '&',
                    calendarLoadError: '=',
                    isCalendarLoaded: '=',
                    accommodationCallback: '&',
                    addDayBeforeEventCallback: '&',
                    addDayAfterEventCallback: '&',
                    deleteDayBeforeEventCallback: '&',
                    deleteDayAfterEventCallback: '&',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.getCalendarError = '';
                        $scope.extraEventSignature = function (event) {
                            return [event.color, event.test];
                        };

                        $scope.getFormatedDate = function (date) {
                            return moment(date).format('DD MMMM');
                        };

                        $scope.openModalforNewEvent = function (data, timeless) {
                            $scope.createCallback({ data: data, timeless: timeless });
                        };

                        $scope.openModalForNewAccommodationEvent = function (defaultDate, name) {
                            if (name) {
                                let room = _.findWhere(translationForRooms, { name: name });
                                if (room) {
                                    name = CommonFunctions.getTranslatedTextFromCode(room.name, room.code);
                                }
                            }
                            let startDate = defaultDate + GlobalConstants.ACCOMMODATION_DEFAULT_START_TIMESTAMP; //Start Date+5pm
                            let endDate = defaultDate + GlobalConstants.ACCOMMODATION_DEFAULT_END_TIMESTAMP; //Start Date+10am+1 day
                            let data = { category: 'ACCOMMODATION', partName: name, start: startDate, end: endDate };
                            $scope.createCallback({ data: data, timeless: false });
                        };

                        $scope.showAccommodationParts = function (isLastLoop, singleRoom, doubleRoom) {
                            var display = true;
                            if (isLastLoop) {
                                display =
                                    UtilFunctions.isDefinedAndNotNull(singleRoom) ||
                                    UtilFunctions.isDefinedAndNotNull(doubleRoom);
                            }
                            return display;
                        };

                        $scope.openAccommodationModal = function (
                            requestId,
                            accommodationBeforeEvent = false,
                            accommodationAfterEvent = false,
                            accommodationDay,
                            isFirst,
                            _index
                        ) {
                            if (
                                isFirst &&
                                $scope.hasBufferDaysBeforeEvent &&
                                ((!$scope.hasAccommodationBeforeEvent && $scope.canDeleteBufferDaysBeforeEvent) ||
                                    !$scope.fisrtDayHasAccommodation)
                            ) {
                                accommodationBeforeEvent = true;
                            }
                            if (
                                $scope.hasBufferDaysAfterEvent &&
                                _index === $scope.calendarList.indexOf($scope.calendarList.at(-2))
                            ) {
                                if (
                                    (!$scope.hasAccommodationAfterEvent && $scope.canDeleteBufferDaysAfterEvent) ||
                                    !$scope.dayBeforeDayAfterEventHasAccommodation
                                ) {
                                    accommodationAfterEvent = true;
                                }
                            }
                            $scope.accommodationCallback({
                                requestId: requestId,
                                accommodationDay: accommodationDay,
                                accommodationBeforeEvent: accommodationBeforeEvent,
                                accommodationAfterEvent: accommodationAfterEvent,
                                hasBufferDaysAfterEvent: $scope.hasBufferDaysAfterEvent,
                                dayBeforeDayAfterEventHasAccommodation: $scope.dayBeforeDayAfterEventHasAccommodation,
                            });
                        };

                        $scope.addDayBeforeEvent = function () {
                            $scope.addDayBeforeEventCallback();
                        };

                        $scope.addDayAfterEvent = function () {
                            $scope.addDayAfterEventCallback();
                        };

                        $scope.deleteDayBeforeEvent = function () {
                            $scope.deleteDayBeforeEventCallback();
                        };

                        $scope.deleteDayAfterEvent = function () {
                            $scope.deleteDayAfterEventCallback();
                        };

                        $scope.jqueryPrint = function () {
                            let isItIE = BrowserDetectService.isItIE();
                            if (!!isItIE) {
                                jQuery('#calendar').printElement({
                                    leaveOpen: false,
                                    printMode: 'popup',
                                    overrideElementCSS: ['/app/print-css/schedule-print.css'],
                                });
                            } else {
                                jQuery('#calendar').printThis({
                                    importCSS: false,
                                    importStyle: false,
                                    loadCSS: `/app/print-css/schedule-print.css`,
                                    printDelay: '3000',
                                });
                            }
                        };
                    },
                ],

                link: function ($scope, element) {
                    ScopeFunctions.addToScope($scope);
                    var languageSelected = $rootScope.langSelected;

                    function calcHeight() {
                        $scope.calendarHolderHeight = [];
                        $timeout(function () {
                            let timelessOfferPartsLength = element.find('div.timeless-offer-parts').length;
                            //10px for each of the timeless parts and an extra 10px for padding at the bottom
                            if (element[1]) {
                                $scope.calendarOffsetHeight =
                                    element[1].offsetHeight + (timelessOfferPartsLength + 1) * 10;
                            }
                            Array.prototype.slice.call(element.find('div.calendar-holder')).forEach(function (e) {
                                $scope.calendarOffsetHeight += e.offsetHeight;
                                $scope.calendarHolderHeight.push(e.offsetHeight);
                            });
                        }, 100);
                    }

                    $scope.$on('lang:change', function (event, data) {
                        languageSelected = data.lang;
                        $timeout(function () {
                            renderCalendarWithResponse($scope, $translate, languageSelected, calcHeight());
                        });
                    });

                    var tabChangedEventSummary = $rootScope.$on(EventEnum.TAB_CHANGED_IN_EVENT_SUMMARY, function () {
                        $timeout(function () {
                            renderCalendarWithResponse($scope, $translate, languageSelected, calcHeight());
                        });
                    });

                    $scope.$on('$destroy', tabChangedEventSummary);

                    $scope.$watch('eventSchedule', function (newVal, oldVal) {
                        if (newVal || (!newVal && newVal != oldVal)) {
                            $timeout(function () {
                                renderCalendarWithResponse($scope, $translate, languageSelected, calcHeight());
                            });
                        }
                    });

                    $scope.getNotificationHtml = function (defaultCommentByCreator, notSaved) {
                        let notification;
                        if (defaultCommentByCreator || notSaved) {
                            notification = 1;
                            return $sce.trustAsHtml('<span class="notification no-print">' + notification + '</span>');
                        } else {
                            return $sce.trustAsHtml('');
                        }
                    };

                    function eventRender(event, element) {
                        var actionRequired = event && event.actionRequired;
                        var hasComments = event.hasComment;
                        var isPartEditable = event.isPartEditable;
                        var supplierConfirmationPending = event.supplierConfirmationPending;
                        var creatorConfirmationPending = event.creatorConfirmationPending;
                        var actionRequiredHtml = actionRequired
                            ? '<span class="action-required">' + '<span class="fa fa-warning"></span>' + '</span>'
                            : '';
                        if (event.amountType) {
                            let amountType = _.findWhere(translationForAmountTypes, { name: event.amountType });
                            if (amountType) {
                                event.amountType = CommonFunctions.getTranslatedTextFromCode(
                                    amountType.name,
                                    amountType.code
                                );
                            }
                        }
                        var confirmationPendingHtml = '';
                        if (supplierConfirmationPending) {
                            confirmationPendingHtml = '<span class="fa fa-hourglass-start"></span>';
                        } else if (creatorConfirmationPending) {
                            confirmationPendingHtml = '<span class="fa fa-reply"></span>';
                        }
                        // convert to string
                        var hasNotificationHtml =
                            '' +
                            $scope.getNotificationHtml(
                                event.defaultCommentByCreator,
                                !event.hasAnsweredAdditionalQuestion
                            );
                        var hasCommentHtml = hasComments ? `<span class="fa fa-comment"></span>` : '';
                        var htmlString =
                            '<div class="event-render">' +
                            '<div class="event-calendar-icon-group">' +
                            hasCommentHtml +
                            actionRequiredHtml +
                            confirmationPendingHtml +
                            '</div>' +
                            '<strong>' +
                            CommonFunctions.getTranslatedTextFromCode(event.title, event.titleCode) +
                            '</strong> (' +
                            CommonFunctions.getTranslatedTextFromCode(event.requestName, event.requestNameCode) +
                            ') ' +
                            (event.amount ? event.amount : 0) +
                            ' ' +
                            event.amountType +
                            '</div>';

                        let contentLeftPadding = 0;
                        if (actionRequired) {
                            contentLeftPadding = contentLeftPadding + 15;
                        }
                        if (supplierConfirmationPending || creatorConfirmationPending) {
                            contentLeftPadding = contentLeftPadding + 15;
                        }
                        if (hasComments) {
                            contentLeftPadding = contentLeftPadding + 15;
                        }
                        if (!isPartEditable) {
                            element.addClass('cursor-deny');
                        }
                        switch (contentLeftPadding) {
                            case 15:
                                element
                                    .find('div.fc-content')
                                    .removeClass('remove-left-pading')
                                    .addClass('add-left-pading-15');
                                break;
                            case 30:
                                element
                                    .find('div.fc-content')
                                    .removeClass('remove-left-pading')
                                    .addClass('add-left-pading-30');
                                break;
                            case 40:
                                element
                                    .find('div.fc-content')
                                    .removeClass('remove-left-pading')
                                    .addClass('add-left-pading-40');
                                break;
                            case 45:
                                element
                                    .find('div.fc-content')
                                    .removeClass('remove-left-pading')
                                    .addClass('add-left-pading-45');
                                break;
                        }
                        element.find('div.fc-content').css('padding-left', `${contentLeftPadding}px !important`);

                        if (event.isTransparent) {
                            element.addClass('deleted-activity');
                        }
                        if (supplierConfirmationPending || creatorConfirmationPending) {
                            element.addClass('stripe-pattern');
                        }
                        element.find('div.fc-title').html(htmlString);

                        //wrap the fc-content element to hide the overflow
                        element
                            .find('div.fc-content')
                            .wrap(
                                `<div class="fc-content-wrapper" style="overflow: hidden; position:relative; height: 100%"></div>`
                            );
                        /**
                         * Add the notification section out of the fc-content
                         * See related issue https://app.asana.com/0/14368931590528/247826519903348
                         */
                        if (hasNotificationHtml) {
                            element.prepend(hasNotificationHtml);
                        }
                        $compile(element)($scope);
                    }

                    function alertOrUpdate(event, revertFunc) {
                        var editType = event && event.editType && event.editType.name;
                        if (editType === 'NOTIFY_BEFORE_EDIT') {
                            notifyBeforeEdit(event, revertFunc);
                        } else if (editType === 'EDITABLE') {
                            updateDateTime(event);
                        } else if (editType === 'DENIED') {
                            denyUpdate(revertFunc, 'request.offer.part.adding.new.row.alert.denied');
                        } else if (editType === 'DRAG_DROP_DENIED') {
                            denyUpdate(revertFunc, 'request.offer.part.updating.date.and.time.denied');
                        }
                    }

                    /* alert on Drop */
                    function alertOnDrop(event, delta, revertFunc, jsEvent, ui, view) {
                        if (!event.isPartEditable) {
                            let message = $translate.instant('common.text.cannot.drag.or.update');
                            denyUpdate(revertFunc, message);
                            return;
                        }
                        alertOrUpdate(event, revertFunc);
                    }

                    /* jshint unused:vars */

                    /* alert on Resize */
                    function eventResize(event, delta, revertFunc) {
                        if (!event.isPartEditable) {
                            let message = $translate.instant('common.text.cannot.drag.or.update');
                            denyUpdate(revertFunc, message);
                            return;
                        }
                        alertOrUpdate(event, revertFunc);
                    }

                    /* jshint unused:vars */

                    function denyUpdate(revertFunc, message) {
                        $rootScope.$broadcast(EventEnum.OPEN_ALERT_DIALOG, {
                            message: message,
                            okCallBack: revertFunc,
                        });
                    }

                    function notifyBeforeEdit(event, revertFunc) {
                        var settings = {
                            message: 'request.offer.part.change.alert',
                            okCallBack: function () {
                                $scope.dateTimeUpdateCallback({
                                    referenceId: event.referenceId,
                                    start: event.start,
                                    end: event.end,
                                });
                            },
                            cancelCallBack: revertFunc,
                        };
                        $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, settings);
                    }

                    function onSelectTimeFrame(start, end) {
                        $scope.createCallback({ data: { start: start, end: end } });
                    }

                    function updateDateTime(event) {
                        //if end value is not defined, set end same as start
                        var end = event.end;
                        if (event.end === null) {
                            end = event.start;
                        }
                        $scope.dateTimeUpdateCallback({ referenceId: event.referenceId, start: event.start, end: end });
                    }

                    $scope.openModalOnEventClick = function (data, timeless) {
                        if (!data.isRequestEditable) {
                            return;
                        }
                        if (!data.isPartEditable) {
                            let message = $translate.instant('common.text.cannot.drag.or.update');
                            $rootScope.$broadcast(EventEnum.OPEN_ALERT_DIALOG, {
                                message: message,
                            });
                            return;
                        }
                        $scope.eventClickCallback({ data: data, timeless: timeless });
                    };

                    uiConfig = {
                        contentHeight: 'auto',
                        header: false,
                        defaultView: 'agendaDay',
                        allDaySlot: false,
                        slotEventOverlap: false,
                        eventClick: $scope.openModalOnEventClick,
                        eventDrop: alertOnDrop,
                        eventResize: eventResize,
                        eventRender: eventRender,
                        select: onSelectTimeFrame,
                        viewRender: viewRender,
                        timeFormat: 'HH:mm',
                        slotLabelFormat: 'HH:mm',
                        defaultTimedEventDuration: '01:00',
                    };
                },
            };
        },
    ]);
})();
