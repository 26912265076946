class LinkExpiredCtrl {
    constructor($scope, $rootScope, $stateParams, AuthenticationService, $state) {
        this._injected = { $scope, $rootScope, $stateParams, AuthenticationService, $state };
    }


    redirectMe() {
        const { AuthenticationService, $state } = this._injected;
        if (AuthenticationService.isLogged()) {
            console.log("Here");
            if (AuthenticationService.isLoggedSupplier()) {
                $state.go('offers');
            } else {
                $state.go('events');
            }
        } else {
            window.location.href = '/';
        }
    }
}

LinkExpiredCtrl.$inject = ['$scope', '$rootScope', '$stateParams', 'AuthenticationService', '$state'];
export default LinkExpiredCtrl;
