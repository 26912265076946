import controller from './og_text_angular_editor.controller.js';

(function () {
    'use strict';

    angular.module('ogTextAngularEditor').directive('ogTextAngularEditor', [
        function () {
            return {
                scope: {
                    textAngularModel: '=',
                    placeholder: '@placeholder',
                    textOnChangeCallback: '=',
                },
                templateUrl: './app/components/directives/text-angular-editor/og_text_angular_editor.view.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogTextAngularEditorCtrl',
                bindToController: true,
            };
        },
    ]);
})();
