/**
 * Created by kshitij on 7/28/17.
 */

const MILLISECONDS_IN_A_DAY = 86400000;
const TEN_AM_MILLISECONDS = 36000000;
const FIVE_PM_MILLISECONDS = 61200000;

class AccommodationUtil {
    constructor() {
        this.getDateTimeFrom = this.getDateTimeFrom.bind(this);
        this.getDateTimeTo = this.getDateTimeTo.bind(this);
        this.getDateDiff = this.getDateDiff.bind(this);
        this.getDayIndex = this.getDayIndex.bind(this);
    }

    getDateTimeFrom(dayIndex) {
        return parseInt(dayIndex) * MILLISECONDS_IN_A_DAY + FIVE_PM_MILLISECONDS;
    }

    getDateTimeTo(dayIndex) {
        return (parseInt(dayIndex) + 1) * MILLISECONDS_IN_A_DAY + TEN_AM_MILLISECONDS;
    }

    getDateDiff(fromDate, toDate) {
        return Math.round((toDate - fromDate) / (1000 * 60 * 60 * 24));
    }

    getDayIndex(dateTime) {
        let _index = Math.floor(dateTime, MILLISECONDS_IN_A_DAY) / MILLISECONDS_IN_A_DAY;
        return parseInt(_index);
    }
}

const accommodation = new AccommodationUtil();
export default accommodation;
