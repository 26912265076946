(function () {
    'use strict';
    angular.module('ogChange').directive('ogChange', [
        function () {
            return {
                restrict: 'A',
                link: function postLink(scope, element, attrs) {
                    element.bind('change', function () {
                        scope.$apply(attrs.ogChange);
                    });
                },
            };
        },
    ]);
})();
