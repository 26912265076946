(function () {
    'use strict';

    angular.module('ogMessageBody').directive('ogMessageBody', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/message_body/og_message_body.view.html',
            replace: true,
            scope: {
                offers: '=',
                msgOfferId: '=',
            },
            controllerAs: 'msgBodyCtrl',
            bindToController: true,
            controller: [
                '$scope',
                'AuthenticationService',
                'Activity',
                '$rootScope',
                'EventEnum',
                '$stateParams',
                'CommonFunctions',
                function ($scope, AuthenticationService, Activity, $rootScope, EventEnum, $stateParams, CommonFunctions) {
                    var vm = this;
                    vm.createMessage = createMessageFn;
                    vm.clearAttachedFiles = clearAttachedFilesFn;
                    vm.getActivityStream = getActivityStreamFn;
                    vm.getPaginatedActivities = getPaginatedActivitiesFn;
                    vm.init = initFn;
                    vm.loadNextPage = loadNextPageFn;
                    vm.pageChanged = pageChangedFn;
                    vm.paginatedActivityStreamCallback = paginatedActivityStreamCallbackFn;
                    vm.resetActivitiesList = resetActivitiesListFn;
                    vm.sendMessage = sendMessageFn;
                    vm.sendMessageCallback = sendMessageCallbackFn;

                    vm.currentPage = 1;
                    vm.messageForm = {};
                    vm.numPerPage = 6;
                    vm.offerId = vm.msgOfferId;
                    vm.showMyTasks = false;

                    vm.activityFilterArray = [
                        { id: 0, tag: 'all' },
                        { id: 1, tag: 'supplier' },
                        { id: 2, tag: 'task' },
                        { id: 3, tag: 'message' },
                        { id: 4, tag: 'messageWithFiles' },
                    ];
                    vm.showTaskFilterArray = [
                        { id: 0, tag: 'showMineTask' },
                        { id: 1, tag: 'showAllTask' },
                    ];

                    vm.activityFilter = vm.activityFilterArray[0].tag;
                    vm.showTaskFilter = vm.showTaskFilterArray[1].tag;

                    vm.init();

                    function createMessageFn() {
                        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                            return;
                        }
                        if (!vm.messageForm.newMessageForm.$valid) {
                            CommonFunctions.touchFormFields(vm.messageForm.newMessageForm);
                            return;
                        }
                        if (vm.attachedFiles.maxSizeExceeded === true) {
                            return;
                        }
                        var messageBody = vm.messageBody;
                        if (tinyMCE && false) {
                            messageBody = tinyMCE.get('messageBody').getContent();
                        }
                        vm.sendMessage(messageBody);
                    }

                    function clearAttachedFilesFn() {
                        vm.attachedFiles = { uploads: null, links: null };
                    }

                    function getActivityStreamFn() {
                        vm.streamSpinner = true;
                    }

                    function getPaginatedActivitiesFn() {
                        var params = {
                            eventId: vm.eventId,
                            showMyTasks: vm.showMyTasks,
                            page: vm.params.offset,
                            pageSize: vm.numPerPage,
                            type: vm.activityFilter,
                        };
                        if (vm.level != 'EVENT') {
                            params.requestId = vm.requestId;
                            params.offerId = vm.offerId;
                        }
                        Activity.list(params, vm.paginatedActivityStreamCallback, vm.errorCallback);
                    }

                    function initFn() {
                        vm.clearAttachedFiles();
                        vm.messageSpinner = false;
                        vm.clearAll = false; //clear file upload model
                        vm.messageBodyEmpty = false;
                        vm.resetActivitiesList();
                        vm.getPaginatedActivities();

                        updateLoggedUserStatus();
                    }

                    function loadNextPageFn() {
                        if (vm.loading) {
                            return;
                        }
                        if (vm.totalActivitiesCount !== -1) {
                            if (vm.activities.length >= vm.totalActivitiesCount) {
                                return;
                            }
                        }
                        vm.loading = true;
                        vm.getPaginatedActivities();
                    }

                    function paginatedActivityStreamCallbackFn(response) {
                        vm.activities = response.list;
                        vm.streamSpinner = false;
                        vm.messageSpinner = false;
                        vm.loading = false;
                        vm.totalActivitiesCount = response.totalCount;
                        if (vm.activities.length !== 0 && vm.activities.length % 20 === 0) {
                            vm.restrictAutoLoad = true;
                        }
                        vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                    }

                    function pageChangedFn() {
                        setPaginationParams();
                        vm.loadNextPage();
                    }

                    function resetActivitiesListFn() {
                        vm.params = { offset: 0, max: vm.numPerPage };
                        vm.loading = false;
                        vm.totalActivitiesCount = -1; //default value
                        vm.activities = [];
                    }

                    function sendMessageFn(messageBody) {
                        if (messageBody && messageBody.length > 0) {
                            var compiledMessageBody = messageBody;
                            if (vm.attachedFiles.links !== null && vm.attachedFiles.links.length > 0) {
                                compiledMessageBody += '\n\n';
                                angular.forEach(vm.attachedFiles.links, function (link) {
                                    compiledMessageBody +=
                                        '<a href="' +
                                        link.fileUrl +
                                        '" target="_blank"><i class="glyphicon glyphicon-paperclip"></i> ' +
                                        link.name +
                                        '</a>' +
                                        '\n';
                                });
                            }
                            vm.messageSpinner = true;
                            Activity.save(
                                {
                                    eventId: vm.eventId,
                                    requestId: vm.requestId,
                                    offerId: vm.offerId,
                                    messageBody: compiledMessageBody,
                                    privateMessage: vm.privateMessage,
                                    attachedFiles: vm.attachedFiles.uploads,
                                },
                                vm.sendMessageCallback,
                                vm.errorCallback
                            );
                        }
                    }

                    function sendMessageCallbackFn() {
                        vm.messageSpinner = false;
                        vm.messageBody = '';
                        if (tinyMCE && false) {
                            var editor = tinyMCE.get('messageBody');
                            if (editor) {
                                editor.setContent('');
                            }
                        }
                        vm.clearAttachedFiles();
                        vm.resetActivitiesList();
                        vm.getPaginatedActivities();
                        vm.clearAll = true;
                        vm.messageForm.newMessageForm.$setPristine();
                        vm.messageForm.newMessageForm.$setUntouched();
                    }

                    function setPaginationParams() {
                        vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                        vm.params.max = vm.numPerPage;
                    }

                    function updateLoggedUserStatus() {
                        vm.loggedUserStatus = AuthenticationService.getLoggedUserStatus();
                    }

                    var offerId;

                    /*Is there another way to clear form without $broadcast ??*/
                    $scope.$on('offer:detail:message:modal:open', function () {
                        vm.messageForm.newMessageForm.$setPristine();
                        vm.messageForm.newMessageForm.$setUntouched();
                    });

                    $scope.$watch('msgBodyCtrl.offerId', function (a, b) {
                        if (vm.offerId) {
                            offerId = vm.offerId;
                        }
                        if (typeof a == 'undefined' && typeof b == 'undefined') {
                            return;
                        }

                        if (a === '') {
                            vm.level = 'EVENT';
                            vm.privateMessage = true;
                            vm.offer = null;
                        } else {
                            vm.level = 'OFFER';
                            vm.privateMessage = false;

                            angular.forEach(vm.offers, function (offer) {
                                if (offer.id == a) {
                                    vm.offer = offer;
                                }
                            });
                        }
                        vm.resetActivitiesList();
                        vm.getPaginatedActivities();
                        vm.currentPage = 1;

                        $scope.$emit('offer:id:changed', vm.offerId);
                    });

                    $scope.$on(EventEnum.USER_DATA_CHANGED, updateLoggedUserStatus);
                    $scope.$on(EventEnum.USER_GROUP_CHANGED_SILENT, updateLoggedUserStatus);
                    $scope.$on(EventEnum.USER_GROUP_CHANGED, updateLoggedUserStatus);

                    $scope.$watch('msgBodyCtrl.msgOfferId', function () {
                        vm.offerId = vm.msgOfferId;
                        vm.eventId = $stateParams.eventId;
                    });

                    $scope.$on('msgStream:activityChanged', function (event, value) {
                        angular.forEach(vm.activityFilterArray, function (item) {
                            if (item.tag == value) {
                                vm.activityFilter = value;
                                vm.isTask = value == 'task';
                                vm.resetActivitiesList();
                                vm.loadNextPage();
                                vm.currentPage = 1;
                            }
                        });
                    });
                },
            ],
        };
    });
})();
