import amountAndPriceService from '../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class UpdatePartOptionMarkupModalCtrl {
    constructor($scope, RequestOfferPartOption, $element, data, close, $rootScope, EventEnum, BudgetDataShareService) {
        this._injected = {
            $scope,
            RequestOfferPartOption,
            $element,
            data,
            close,
            $rootScope,
            EventEnum,
            BudgetDataShareService,
        };

        ScopeFunctions.addToScope($scope);

        this.offer = data.offer;
        this.partOption = data.partOption;
        this.partOption.markupValue = data.partOption.markupValue;
        this.offerPart = data.offerPart;
        let originalMarkupValue = this.partOption.markupValue;
        this.customerPrice = data.customerPrice;
        this.originalPartMarkupValue = data.partOption.markupValue;
        this.originalCustomerPrice = data.customerPrice;
        this.disableupdatePartOptionMarkupValue = false;

        $scope
            .$watch('updatePartOptionMarkupCtrl.partOption.markupValue', (newValue, oldValue) => {
                if (newValue) {
                    if (newValue === oldValue) {
                        this.partOption.markupValue = this.round(this.partOption.markupValue);
                    } else {
                        this.partOption.markupValue = newValue;
                    }
                }
                if (
                    oldValue === newValue ||
                    this.round(originalMarkupValue) === newValue ||
                    originalMarkupValue === newValue
                ) {
                    this.disableupdatePartOptionMarkupValue = true;
                } else {
                    this.disableupdatePartOptionMarkupValue = false;
                }
            })
            .bind(this);
    }

    round(value) {
        let decimals = 2; //only take 2 digit after decimal
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    calculateMarkupFromCustomerPrice(partOption) {
        this.partOption.markupValue = amountAndPriceService.getOptionMarkupFromCustomerPrice(
            this.offer,
            partOption,
            this.customerPrice
        );
    }

    calculateCustomerPriceFromMarkup(partOption) {
        this.customerPrice = amountAndPriceService.getOptionCustomerPriceFromMarkup(
            this.offer,
            partOption,
            partOption.markupValue
        );
    }

    updatePartOptionMarkupValue() {
        const { $scope, RequestOfferPartOption, $rootScope, EventEnum, BudgetDataShareService } = this._injected;
        let _data = { markupValue: this.partOption.markupValue };
        $scope.startProgress('part-option-markup-value');
        RequestOfferPartOption.updatePartOptionMarkupValue(
            this.partOption.id,
            _data,
            (response) => {
                BudgetDataShareService.setSelectedPartIdForMarkup(this.offerPart.id);
                $rootScope.$broadcast('markup.value.changed', { offerPart: this.offerPart });
                $rootScope.$broadcast(EventEnum.REQUEST_CHANGED_IN_BUDGET, {
                    request: response,
                    offerPart: this.offerPart,
                });
                this.close();
                $scope.endProgress('part-option-markup-value');
            },
            $scope.endWithErrorOverlay('part-option-markup-value')
        );
    }

    cancel() {
        this.partOption.markupValue = this.originalPartMarkupValue;
        this.customerPrice = this.originalCustomerPrice;
        this.close();
    }

    close() {
        const { close, $element } = this._injected;
        this.partOption.markupValue = this.originalPartMarkupValue;
        this.customerPrice = this.originalCustomerPrice;
        $element.modal('hide');
        close(null, 500);
    }
}

UpdatePartOptionMarkupModalCtrl.$inject = [
    '$scope',
    'RequestOfferPartOption',
    '$element',
    'data',
    'close',
    '$rootScope',
    'EventEnum',
    'BudgetDataShareService',
];
export default UpdatePartOptionMarkupModalCtrl;
