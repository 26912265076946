import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

(function () {
    /* global angular */
    'use strict';

    angular.module('ogCropEventThumbnailImage').directive('ogCropEventThumbnailImage', [
        function () {
            return {
                templateUrl: './app/components/directives/crop_event_thumbnail_image/og_crop_event_thumbnail_image.view.html',
                scope: {
                    imageType: '=imageType',
                    eventDetails: '=eventDetails',
                    updateImageCallback: '&updateImageCallback',
                    cancelUpdateImageCallback: '&cancelUpdateImageCallback',
                },
                controller: [
                    '$scope',
                    '$rootScope',
                    '$timeout',
                    'Event',
                    function ($scope, $rootScope, $timeout, Event) {
                        ScopeFunctions.addToScope($scope);
                        $scope.form = 'logoImageForm';
                        $scope.customStyle = {};
                        initCropEventImageConfig();

                        var URL = window.URL || window.webkitURL,
                            blobURL;

                        $scope.$watch('crop.logoUrl.image', function (value) {
                            if (URL) {
                                if (value) {
                                    if (/^image\/\w+$/.test(value.type)) {
                                        $scope.customStyle = {};
                                        $scope.crop.logoUrl.image = value;
                                        blobURL = URL.createObjectURL($scope.crop.logoUrl.image);
                                        $scope.crop.logoUrl.blobURL = blobURL;
                                    } else {
                                        $scope.crop.logoUrl.image = null;
                                    }
                                }
                            }
                        });

                        $scope.$watch('imageType', function (data) {
                            if (data) {
                                $scope.logoType = data;
                            }
                        });

                        $scope.$watch('eventDetails', function (data) {
                            if (data) {
                                $scope.event = data;
                                $scope.eventId = data.id;
                                $scope.crop.logoUrl.image = undefined;
                                jQuery('#logoImageUrl-thumbnail').val('');
                            }
                        });

                        $scope.setLogoUrl = function () {
                            if (!Event.checkPictureCropDetails($scope.crop.logoUrl)) {
                                $rootScope.$broadcast('dialog:alert:open', { message: 'image.crop.before.uploading' });
                            } else {
                                var formData = new FormData();
                                formData.append('event', $scope.eventId);
                                formData.append('logo', $scope.crop.logoUrl.image);
                                formData.append('dataX', $scope.crop.logoUrl.coordinates[0]);
                                formData.append('dataY', $scope.crop.logoUrl.coordinates[1]);
                                formData.append('dataWidth', $scope.crop.logoUrl.coordinates[4]);
                                formData.append('dataHeight', $scope.crop.logoUrl.coordinates[5]);
                                $scope.startProgress();
                                $scope.updateImageCallback({ formData: formData }, function () {
                                    $scope.endProgress();
                                });
                            }
                        };

                        function initCropEventImageConfig() {
                            $scope.crop = {};
                            $scope.crop.logoUrl = {};
                            $scope.crop.logoUrl.coordinates = null;
                            $scope.crop.logoUrl.previewStyle = {
                                width: '200px',
                                height: '125px',
                                overflow: 'hidden',
                                'margin-left': '118px',
                                'margin-bottom': '5px',
                            };
                            $scope.crop.logoUrl.cropStyle = {
                                boxWidth: '445',
                                boxHeight: '445',
                                aspectRatio: 800 / 500,
                                bgColor: 'rgb(16, 16, 16)',
                                minSize: [200, 125],
                                setSelect: [0, 0, 200, 125],
                            };
                            $scope.crop.logoUrl.thumbnail = true;
                            $scope.crop.logoUrl.image = null;
                        }

                        $scope.sizeTypeCheck = () => {
                            var uploadField = document.getElementById('logoImageUrl-thumbnail');
                            if (
                                uploadField.files[0].size > 3145728 &&
                                !/^image\/\w+$/.test(uploadField.files[0].type)
                            ) {
                                $scope.customStyle.typeError = { color: 'red' };
                                $scope.customStyle.sizeError = { color: 'red' };
                                uploadField.value = '';
                            } else if (uploadField.files[0].size > 3145728) {
                                $scope.customStyle.sizeError = { color: 'red' };
                                $scope.customStyle.typeError = {};
                                uploadField.value = '';
                            } else if (!/^image\/\w+$/.test(uploadField.files[0].type)) {
                                $scope.customStyle.typeError = { color: 'red' };
                                $scope.customStyle.sizeError = {};
                                uploadField.value = '';
                            }
                        };
                    },
                ],
            };
        },
    ]);
})();
