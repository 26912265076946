import ParticipantDetailsRoute from './participant_details/route';

export default function (appModule) {
    appModule.config([
        '$routeProvider',
        '$locationProvider',
        '$stateProvider',
        '$urlRouterProvider',
        function ($routeProvider, $locationProvider, $stateProvide, $urlRouterProvider) {
            $urlRouterProvider.when('/participant/confirmation/:hash', '/participant/confirm/:hash');
            $urlRouterProvider.when('/participant/confirm/:hash', [
                '$match',
                '$location',
                function ($match, $location) {
                    var lang = $location.search().lang;
                    var confirm = $location.search().confirm;
                    var registration = $location.search().registration;
                    console.log('registration', registration);
                    var path = '/participant_details/' + $match.hash + '/event-details';
                    if (registration) {
                        path = '/participant_details/' + $match.hash + '/registration';
                    }

                    if (lang) {
                        path += '?lang=' + lang;
                    }
                    if (confirm != undefined) {
                        path += '&confirm=' + confirm;
                    }
                    if (registration != undefined) {
                        path += '&registration=' + registration;
                    }
                    $location.url(path);
                },
            ]);

            $urlRouterProvider.when('/survey/:hash?lang', '/participant_details/:hash/ratings?lang');
            $urlRouterProvider.when(
                '/eventPresentationRo/:hash?lang&registration',
                '/invitation/:hash?lang&registration',
                '/unsubscribe/:hash?lang&unsubscribe'
            );

            $stateProvide
                .state('participant_details', {
                    url: '/participant_details/:hash',
                    abstract: true,
                    template: '<participant-details personalized-page-details="$resolve.personalizedPageDetails"></participant-details>',
                    resolve: {
                        personalizedPageDetails: [
                            '$stateParams',
                            'Participant',
                            '$q',
                            function ($stateParams, Participant, $q) {
                                var deferred = $q.defer();
                                Participant.getParticipantPersonalizePageDetails(
                                    $stateParams.hash,
                                    function (response) {
                                        console.log('response', response);
                                        if (response.valid) {
                                            deferred.resolve(response);
                                        } else {
                                            deferred.reject('PAGE_NOT_FOUND');
                                        }
                                    },
                                    function () {
                                        deferred.reject('PAGE_NOT_FOUND');
                                    }
                                );
                                return deferred.promise;
                            },
                        ],
                    },
                })
                .state('unsubscribe', {
                    url: '/public/unsubscribe/:hash',
                    template: '<unsubscribed-info personalized-page-details="$resolve.personalizedPageDetails"></unsubscribed-info>',
                    resolve: {
                        personalizedPageDetails: [
                            '$stateParams',
                            'Participant',
                            '$q',
                            function ($stateParams, Participant, $q) {
                                var deferred = $q.defer();
                                Participant.getParticipantPersonalizePageDetails(
                                    $stateParams.hash,
                                    function (response) {
                                        if (response.valid) {
                                            deferred.resolve(response);
                                        } else {
                                            deferred.reject('PAGE_NOT_FOUND');
                                        }
                                    },
                                    function () {
                                        deferred.reject('PAGE_NOT_FOUND');
                                    }
                                );
                                return deferred.promise;
                            },
                        ],
                    },
                })
                .state('invitation', {
                    url: '/invitation/:hash',
                    template: '<invitation-participant personalized-page-details="$resolve.personalizedPageDetails"></invitation-participant>',
                    resolve: {
                        personalizedPageDetails: [
                            '$stateParams',
                            'Participant',
                            '$q',
                            function ($stateParams, Participant, $q) {
                                var deferred = $q.defer();
                                Participant.getParticipantPersonalizePageDetails(
                                    $stateParams.hash,
                                    function (response) {
                                        if (response.valid) {
                                            deferred.resolve(response);
                                        } else {
                                            deferred.reject('PAGE_NOT_FOUND');
                                        }
                                    },
                                    function () {
                                        deferred.reject('PAGE_NOT_FOUND');
                                    }
                                );
                                return deferred.promise;
                            },
                        ],
                    },
                });
        },
    ]);
    ParticipantDetailsRoute(appModule);
}
