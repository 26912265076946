'use strict';

angular.module('core.requestOfferPartOption').factory('RequestOfferPartOption', [
    '$resource',
    function ($resource) {
        var v1RequestOfferPartOptionForPartRest = $resource(
            'api/v1/requestOfferPartOption/findAllRequestOfferPartOptionForPart/:requestOfferPartId',
            {requestOfferPartId: '@requestOfferPartId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        var v1RequestOfferPartOptionForOfferRest = $resource(
            'api/v1/requestOfferPartOption/findAllRequestOfferPartOptionForOffer/:requestOfferId',
            {requestOfferId: '@requestOfferId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        var v1RequestOfferPartOptionCreateRest = $resource(
            'api/v1/requestOfferPartOption/create/:supplierId',
            {supplierId: '@supplierId'},
            {
                create: {method: 'POST', isArray: true},
            }
        );

        var v1RequestOfferPartOptionDeleteRest = $resource(
            'api/v1/requestOfferPartOption/:requestOfferPartOptionId',
            {requestOfferPartOptionId: '@requestOfferPartOptionId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var v1RequestOfferPartOptionUpdateRest = $resource(
            'api/v1/requestOfferPartOption/:requestOfferPartOptionId/:supplierId',
            {requestOfferPartOptionId: '@requestOfferPartOptionId', supplierId: '@supplierId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var v1RequestOfferPartOptionSelectionUpdateRest = $resource(
            'api/v1/requestOfferPartOption/updatePartOptionSelection/:requestOfferPartOptionId',
            {requestOfferPartOptionId: '@requestOfferPartOptionId'},
            {
                update: {method: 'POST', isArray: false},
            }
        );

        var v1UpdateIsSelectedForOptionsRest = $resource(
            'api/v1/requestOfferPartOption/updateIsSelectedForOptions/:requestOfferPartOptionId',
            {requestOfferPartOptionId: '@requestOfferPartOptionId'},
            {
                update: {method: 'POST', isArray: false},
            }
        );

        var v1RequestOfferPartOptionListSelectionUpdateRest = $resource(
            'api/v1/requestOfferPartOption/updatePartOptionListSelection',
            {},
            {
                update: {method: 'POST', isArray: false},
            }
        );

        var updatePartOptionMarkupValueRest = $resource(
            'api/v1/requestOfferPartOption/updatePartOptionMarkupValue/:partOptionId',
            {partOptionId: '@partOptionId'},
            {
                put: {method: 'PUT', isArray: false},
            }
        );

        return {
            getAllOptionsForPart: function (requestOfferPartId, successCallback, errorCallback) {
                return v1RequestOfferPartOptionForPartRest.list(
                    {requestOfferPartId: requestOfferPartId},
                    successCallback,
                    errorCallback
                );
            },
            getAllPartOptionsForOffer: function (requestOfferId, successCallback, errorCallback) {
                return v1RequestOfferPartOptionForOfferRest.list(
                    {requestOfferId: requestOfferId},
                    successCallback,
                    errorCallback
                );
            },
            create: function (supplierId, data, successCallback, errorCallback) {
                return v1RequestOfferPartOptionCreateRest.create(
                    {supplierId: supplierId},
                    data,
                    successCallback,
                    errorCallback
                );
            },
            delete: function (requestOfferPartOptionId, successCallback, errorCallback) {
                return v1RequestOfferPartOptionDeleteRest.delete(
                    {requestOfferPartOptionId: requestOfferPartOptionId},
                    successCallback,
                    errorCallback
                );
            },
            update: function (_data, data, successCallback, errorCallback) {
                return v1RequestOfferPartOptionUpdateRest.update(
                    {requestOfferPartOptionId: _data.requestOfferPartOptionId, supplierId: _data.supplierId},
                    data,
                    successCallback,
                    errorCallback
                );
            },
            updatePartOptionSelection: function (requestOfferPartOptionId, data, successCallback, errorCallback) {
                return v1RequestOfferPartOptionSelectionUpdateRest.update(
                    {requestOfferPartOptionId: requestOfferPartOptionId},
                    data,
                    successCallback,
                    errorCallback
                );
            },
            updateIsSelectedForOptions: function (requestOfferPartOptionId, successCallback, errorCallback) {
                return v1UpdateIsSelectedForOptionsRest.update(
                    {requestOfferPartOptionId: requestOfferPartOptionId},
                    successCallback,
                    errorCallback
                );
            },
            updatePartOptionListSelection: function (data, successCallback, errorCallback) {
                return v1RequestOfferPartOptionListSelectionUpdateRest.update(data, successCallback, errorCallback);
            },
            updatePartOptionMarkupValue: function (partOptionId, data, successCallback, errorCallback) {
                return updatePartOptionMarkupValueRest.put(
                    {partOptionId: partOptionId},
                    data,
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);
