'use strict';
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';
import {OgModal} from "../../../../utils/global/ogModal";

// Register `register` component, along with its associated controller and template
angular.module('register').component('register', {
    templateUrl: './app/components/modules/auth/register/register.view.html',
    controller: [
        '$scope',
        '$http',
        '$rootScope',
        '$stateParams',
        '$translate',
        'CustomerUser',
        'CommonFunctions',
        'AuthenticationService',

        function RegistrationController($scope, $http, $rootScope, $stateParams, $translate, CustomerUser, CommonFunctions,AuthenticationService) {
            var vm = this;

            ScopeFunctions.addToScope($scope);
            vm.passwordsNotMatch = passwordsNotMatchFn;
            vm.validatePassword = validatePasswordFn;
            vm.showPasswordStrength = showPasswordStrengthFn;
            vm.register = registerFn;
            vm.showTerms = showTermsFn;
            vm.switchToEn = switchToEnFn;
            vm.switchToSv = switchToSvFn;
            vm.switchToNb = switchToNbFn;
            vm.switchToDa = switchToDaFn;
            vm.switchToFi = switchToFiFn;
            vm.data = {};
            // vm.processed = false;
            vm.updated = '';
            vm.userIsNew = ''; // Not used in view
            vm.message = '';
            vm.messageArgs = '';
            vm.enabled = '';
            vm.langSelected = $rootScope.langSelected;
            vm.fetchCompanyName = fetchCompanyNameFn;
            vm.handlePaste = handlePasteFn;
            vm.handleCut = handleCutFn;

            //fields and functions for suggested password @BP
            vm.generatedPassword = '';
            vm.getGeneratedPassword = getGeneratedPasswordFn;
            vm.fillPasswordFields = fillPasswordFieldsFn;
            vm.parentUserGroups = null;

            if($stateParams.eventId){
                vm.externalEventId = $stateParams.eventId;
                vm.switchToSv();
            }

            if ($stateParams.locale) {
                if ($stateParams.locale === 'en') {
                    vm.switchToEn();
                } else if ($stateParams.locale === 'sv') {
                    vm.switchToSv();
                } else if ($stateParams.locale === 'nb') {
                    vm.switchToNb();
                } else if ($stateParams.locale === 'da') {
                    vm.switchToDa();
                } else {
                    vm.switchToFi();
                }
            } else {
                vm.activeEn = $rootScope.langSelected === 'en_US' ? 'active' : '';
                vm.activeSv = $rootScope.langSelected === 'sv_SV' ? 'active' : '';
                vm.activeNb = $rootScope.langSelected === 'nb_Nb' ? 'active' : '';
                vm.activeDk = $rootScope.langSelected === 'da_DK' ? 'active' : '';
                vm.activeFi = $rootScope.langSelected === 'fi_FI' ? 'active' : '';
            }

            // fetch the suggested password and set to scope
            function getGeneratedPasswordFn() {
                CustomerUser.getPassword(
                    function (result) {
                        vm.generatedPassword = result.generatedPassword;
                    },
                    function (error) {
                        console.log('>>> Error has occurred while getting generated password from API ' + error);
                    }
                );
            }

            /*
             ** Fetch api to get user group info matched with the domain name of the user's email
             * If only one parent user group , directly assign it as parent user group
             * If more than one, give option to choose from
             * If none, never assign any parent user group
             */
            function fetchCompanyNameFn(userEmail) {
                const regExp = /\S+@\S+\.\S+/;
                if (regExp.test(userEmail)) {
                    CustomerUser.fetchCompanyName(
                        {userEmail: userEmail},
                        function (result) {
                            vm.parentUserGroups = result.userGroups;
                            if (result.userGroups.length === 0) {
                                vm.data.parentGroupId = null;
                            }
                            if (result.userGroups.length === 1) {
                                vm.data.parentGroupId = result.userGroups[0].id;
                            }
                        },
                        function (error) {
                            console.log('error while fetching company names with associated domain = ', error);
                        }
                    );
                } else {
                    vm.data.parentGroupId = null;
                    vm.parentUserGroups = null;
                }
            }

            function handlePasteFn() {
                setTimeout(() => {
                    fetchCompanyNameFn(this.data.email);
                }, 200);
            }

            function handleCutFn() {
                vm.data.parentGroupId = null;
                vm.parentUserGroups = null;
            }

            //fills the password with the suggested password
            function fillPasswordFieldsFn() {
                vm.data.password = vm.generatedPassword;
                vm.data.password2 = vm.generatedPassword;
            }

            function passwordsNotMatchFn() {
                return (vm.data.password || vm.data.password2) && vm.data.password != vm.data.password2;
            }

            function validatePasswordFn() {
                if (!vm.data.password) {
                    return true;
                }
                let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)');
                return regex.test(vm.data.password);
            }

            function registerFn() {
                // if (vm.passwordsNotMatch() || !vm.data.email || !vm.data.accountType) { // TODO- check if accountType is needed
                if (!vm.registrationForm.$valid) {
                    CommonFunctions.touchFormFields(vm.registrationForm);
                    $('html, body').animate(
                        {
                            scrollTop: $('.validation-usergroup').offset().top - 300,
                        },
                        100
                    );
                    return;
                }

                if (vm.passwordsNotMatch() || !vm.data.email || !vm.validatePassword()) {
                    return;
                } else {
                    vm.data.username = vm.data.email;
                    //TODO- create factory for this
                    $scope.startProgress();

                    $http({
                        method: 'POST',
                        url: 'api/v1/register/selfForCustomer',
                        data: vm.data,
                    }).then(function (response) {
                        let data = response.data;
                        vm.processed = true;
                        vm.updated = data.updated;
                        vm.userIsNew = data.isnew; // Not used in view
                        vm.message = data.message;
                        vm.messageArgs = data.messageArgs;
                        vm.enabled = data.enabled;
                        $scope.endProgress();
                        $rootScope.$emit('event:user:action', {action: 'user.registered', data: data});
                        if(vm.externalEventId && data && data.user && data.user.email){
                            AuthenticationService.changeOwnerGroup(
                                { onboardingEventHashId: vm.externalEventId, email: data.user.email },
                                (response) => {
                                    if(response){
                                        console.log('Successfully changed ownership of event with eventId '+vm.externalEventId)
                                    }
                                },
                                function (error) {
                                    console.log('Error while changing ownership of event with id ' + vm.externalEventId);
                                }
                            );
                        }
                    }, $scope.endWithAlert);
                }
            }

            function showPasswordStrengthFn(password) {
                if (password) {
                    //Uppercase Words only
                    if (password.match(/[A-Z]/) != null) {
                        jQuery('#uppercase-text .progress-text').addClass('active');
                    } else {
                        jQuery('#uppercase-text .progress-text').removeClass('active');
                    }
                    //Lowercase words only
                    if (password.match(/[a-z]/) != null) {
                        jQuery('#lowercase-text .progress-text').addClass('active');
                    } else {
                        jQuery('#lowercase-text .progress-text').removeClass('active');
                    }
                    //Digits only
                    if (password.match(/[0-9]/) != null) {
                        jQuery('#number-in-text .progress-text').addClass('active');
                    } else {
                        jQuery('#number-in-text .progress-text').removeClass('active');
                    }
                    //Password length
                    if (password.length > 7) {
                        jQuery('#eight-characters-long .progress-text').addClass('active');
                    } else {
                        jQuery('#eight-characters-long .progress-text').removeClass('active');
                    }
                } else {
                    jQuery('.progress-bar-wrap .progress-bar-item .progress-text').each(function () {
                        jQuery(this).removeClass('active');
                    });
                }
            }

            function showTermsFn() {
                var selectedLanguage = $rootScope.langSelected;
                if (!selectedLanguage) {
                    if (vm.langSelected === 'en_US') {
                        selectedLanguage = 'en_US';
                    } else if (vm.langSelected === 'sv_SV') {
                        selectedLanguage = 'sv_SV';
                    } else if (vm.langSelected === 'nb_Nb') {
                        selectedLanguage = 'nb_Nb';
                    } else if (vm.langSelected === 'da_DK') {
                        selectedLanguage = 'da_DK';
                    } else if (vm.langSelected === 'fi_FI') {
                        selectedLanguage = 'fi_FI';
                    }
                }
                OgModal.open('user:terms', {langSelected: selectedLanguage});
            }

            function switchToEnFn() {
                $rootScope.switchLang('en_US');
                vm.activeEn = 'active';
                vm.activeSv = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToSvFn() {
                $rootScope.switchLang('sv_SV');
                vm.activeSv = 'active';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToNbFn() {
                $rootScope.switchLang('nb_NB');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = 'active';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToDaFn() {
                $rootScope.switchLang('da_DK');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = 'active';
                vm.activeFi = '';
            }

            function switchToFiFn() {
                $rootScope.switchLang('fi_FI');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = 'active';
            }
            vm.accountTypeReadOnly = true; // not used
            vm.hideChangedFieldIcon = true; // not used
            vm.form = 'registrationForm'; // not used
            $scope.dataLoaded({
                // Not used
                accountType: 'EVENT_CREATOR',
            });
            //$rootScope.bodyClassForController($scope, 'registerscreen');
        }
    ]
});
