(function () {
    'use strict';
    angular.module('ogDateInterval').directive('ogDateInterval', [
        '$filter',
        '$translate',
        '$rootScope',
        'TimezoneService',
        function ($filter, $translate, $rootScope, TimezoneService) {
            return {
                restrict: 'EA',
                template: '<span ng-bind-html="interval"></span>',
                replace: false,
                scope: {
                    from: '=ogDateIntervalFrom',
                    to: '=ogDateIntervalTo',
                    baseDate: '=ogBaseDate',
                    absolute: '=ogShowAbsoluteDate',
                    showTime: '=ogShowTime',
                    isInvitation: '=ogIsInvitation',
                    hideMonthAndDay: '=ogHideMonthAndDay',
                },
                link: function ($scope) {
                    function updateInterval() {
                        let params = {
                            fromDate: $scope.fromDate,
                            toDate: $scope.toDate,
                            baseDate: $scope.baseDate,
                            absolute: $scope.absolute,
                            showTime: $scope.showTime,
                            isInvitation: $scope.isInvitation,
                            hideMonthAndDay: $scope.hideMonthAndDay,
                        };

                        $scope.interval = TimezoneService.getDateInterval(params);
                    }

                    $scope.$watch('from', function (val) {
                        if (val != null) {
                            $scope.fromDate = new Date(val);
                            updateInterval();
                        }
                    });

                    $scope.$watch('to', function (val) {
                        if (val != null) {
                            $scope.toDate = new Date(val);
                            updateInterval();
                        }
                    });

                    $scope.$watch('absolute', function () {
                        updateInterval();
                    });

                    $scope.$watch('baseDate', function () {
                        updateInterval();
                    });

                    $scope.$watch('showTime', function () {
                        updateInterval();
                    });

                    $scope.languageSelected = $rootScope.langSelected;

                    $scope.$on('lang:change', function (event, data) {
                        $scope.languageSelected = data.lang;
                        updateInterval();
                        $rootScope.safeApply($scope);
                    });
                },
            };
        },
    ]);
})();
