/* global angular */
'use strict';

angular.module('core.existingServices').factory('ActiveTabService', function () {
    var eventDetailTab;
    var eventsTab;
    var participantsTab;
    var offerDetailTab;
    var participantsSubTab;
    var invitationTab;
    var budgetSubTab;

    function setEventDetailTab(tab) {
        eventDetailTab = tab;
    }

    function getEventDetailTab() {
        return eventDetailTab;
    }

    function setEventsTab(tab) {
        eventsTab = tab;
    }

    function getEventsTab() {
        return eventsTab;
    }

    function setParticipantsTab(tab) {
        participantsTab = tab;
    }

    function getParticipantsTab() {
        return participantsTab;
    }

    function setParticipantsSubTab(tab) {
        participantsSubTab = tab;
    }

    function getParticipantsSubTab() {
        return participantsSubTab;
    }

    function setBudgetSubTab(tab) {
        budgetSubTab = tab;
    }

    function getBudgetSubTab() {
        return budgetSubTab;
    }

    function setInvitationTab(tab) {
        invitationTab = tab;
    }

    function getInvitationTab() {
        return invitationTab;
    }

    function setOfferDetailTab(tab) {
        offerDetailTab = tab;
    }

    function getOfferDetailTab() {
        return offerDetailTab;
    }

    return {
        setEventDetailTab: function (tab) {
            return setEventDetailTab(tab);
        },
        getEventDetailTab: function () {
            return getEventDetailTab();
        },
        setEventsTab: function (tab) {
            return setEventsTab(tab);
        },
        getEventsTab: function () {
            return getEventsTab();
        },
        setParticipantsTab: function (tab) {
            return setParticipantsTab(tab);
        },
        getParticipantsTab: function () {
            return getParticipantsTab();
        },
        setParticipantsSubTab: function (tab) {
            return setParticipantsSubTab(tab);
        },
        getParticipantsSubTab: function () {
            return getParticipantsSubTab();
        },
        setBudgetSubTab: function (tab) {
            return setBudgetSubTab(tab);
        },
        getBudgetSubTab: function () {
            return getBudgetSubTab();
        },
        setInvitationTab: function (tab) {
            return setInvitationTab(tab);
        },
        getInvitationTab: function () {
            return getInvitationTab();
        },
        setOfferDetailTab: function (tab) {
            return setOfferDetailTab(tab);
        },
        getOfferDetailTab: function () {
            return getOfferDetailTab();
        },
    };
});

