'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from '../../utils/global/scopeFunctions';

angular.module('dialog').directive('ogCategoryTemplateResultTileModal', [
    'RequestOffer',
    '$rootScope',
    function (RequestOffer, $rootScope) {
        return new OgModal({
            name: 'category:template:result:tile',
            type: 'custom',
            controller: function ($scope) {
                $scope.form = 'CategoryTemplateResultTileForm';
                ScopeFunctions.addToScope($scope);
                $scope.initDialog = function (data) {
                        /*$scope.requestId = data.request.id;
             $scope.item = data.request;
             RequestOffer.requestOfferSchedule({requestId: $scope.requestId}, function (response) {
             if(response){
             $scope.requestSchedule=response;
             }
             $scope.endProgress();
             });*/
                        $scope.item = data.item;
                        $scope.requestSchedule = data.request;
                        //   $scope.clearDialog();
                    };

                    $scope.addContentFromPreview = function () {
                        $rootScope.$broadcast('add:content:from:preview', { item: $scope.item });
                        $scope.clearDialog();
                    };

                    $scope.clearDialog = function () {
                        $scope.requestSchedule = null;
                    };
                },
            });
        },
    ]);
