import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";
(function () {
    'use strict';
    angular.module('allActivities').component('allActivities', {
        templateUrl: './app/components/modules/event/event_detail/all_activities/all_activities.view.html',
        controllerAs: 'activitiesCtrl',
        controller: [
            '$scope',
            '$rootScope',
            '$http',
            '$state',
            'Survey',
            '$stateParams',
            'Activity',
            'Event',
            function ActivitiesController($scope, $rootScope, $http, $state, Survey, $stateParams, Activity, Event) {
                ScopeFunctions.addToScope($scope);
                var vm = this;
                vm.init = initFn;
                vm.pageChanged = pageChangedFn;
                vm.loadNextPage = loadNextPageFn;
                vm.getPaginatedActivities = getPaginatedActivitiesFn;
                vm.paginatedActivityStreamCallback = paginatedActivityStreamCallbackFn;
                vm.resetActivitiesList = resetActivitiesListFn;
                vm.errorCallback = errorCallbackFn;
                vm.hideFilter = hideFilterFn;
                vm.showMessageByUser = showMessageByUserFn;
                vm.eventId = $stateParams.eventId;
                vm.eventOwners = [];
                vm.currentPage = 1;
                vm.numPerPage = 6;
                vm.noFilter = true;
                vm.init();

                function initFn() {
                    vm.resetActivitiesList();
                    vm.getPaginatedActivities();

                    Event.getUsers(
                        { eventId: vm.eventId, excludeSelf: true },
                        (response) => {
                            vm.eventOwners = response;
                        },
                        vm.errorCallback
                    );
                }

                function pageChangedFn() {
                    setPaginationParams();
                    vm.loadNextPage();
                }

                function setPaginationParams() {
                    vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                    vm.params.max = vm.numPerPage;
                }

                function loadNextPageFn(refresh) {
                    if (vm.loading) {
                        return;
                    }
                    if (!refresh && vm.totalActivitiesCount !== -1) {
                        if (vm.activities.length >= vm.totalActivitiesCount) {
                            return;
                        }
                    }
                    vm.loading = true;
                    vm.getPaginatedActivities();
                }

                function getPaginatedActivitiesFn() {
                    var params = {
                        eventId: vm.eventId,
                        page: vm.params.offset,
                        pageSize: vm.numPerPage,
                        type: 'creatorAllActivities',
                    };
                    if (vm.level === 'EVENT_USERS') {
                        params.eventOwnerId = vm.eventOwnerId;
                    } else {
                        params.requestId = vm.requestId;
                        params.offerId = vm.offerId;
                    }
                    $scope.startProgress('activities');
                    vm.loading = true;
                    Activity.getAllActivities(
                        params,
                        vm.paginatedActivityStreamCallback,
                        $scope.endWithErrorOverlay('activities')
                    );
                }

                function paginatedActivityStreamCallbackFn(response) {
                    vm.activities = response.list;
                    vm.streamSpinner = false;
                    vm.messageSpinner = false;
                    vm.loading = false;
                    vm.totalActivitiesCount = response.totalCount;
                    if (vm.activities.length !== 0 && vm.activities.length % 20 === 0) {
                        vm.restrictAutoLoad = true;
                    }
                    vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                    $scope.endProgress('activities');
                }

                function resetActivitiesListFn() {
                    vm.params = { offset: 0, max: vm.numPerPage };
                    vm.loading = false;
                    vm.totalActivitiesCount = -1; //default value
                    vm.activities = [];
                }

                function errorCallbackFn(error) {
                    vm.error = error;
                    vm.streamSpinner = false;
                    vm.messageSpinner = false;
                }

                function showMessageByUserFn(userId) {
                    vm.userId = userId;
                    vm._offerId = 'user_' + userId;
                    vm.offerId = null;
                    vm.level = 'EVENT_USERS';
                    vm.eventOwnerId = userId;
                    vm.offer = null;
                    vm.noFilter = false;
                    vm.resetActivitiesList();
                    vm.getPaginatedActivities();
                    vm.currentPage = 1;
                }

                function hideFilterFn() {
                    vm.noFilter = true;
                    vm.userId = null;
                    vm.eventOwnerId = null;
                    vm.resetActivitiesList();
                    vm.loadNextPage();
                }
            }
        ]
    });
})();
