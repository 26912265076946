import controller from './og_supplier_default_resource_form.controller';

(function () {
    'use strict';
    angular.module('ogSupplierDefaultResourceForm').directive('ogSupplierDefaultResourceForm', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    supplierId: '=',
                    resourceData: '=',
                    showPartName: '=',
                    attachedFiles: '=',
                    cancelCallback: '&',
                },
                templateUrl:
                    './app/components/directives/supplier_default_resource_form/og_supplier_default_resource_form_directive.view.html',
                controller,
                controllerAs: 'supplierDefaultResourceFormCtrl',
                bindToController: true,
            };
        },
    ]);
})();
