'use strict';

angular.module('core.existingServices').factory('Notification', [
    '$resource',
    function ($resource) {
        var listUnreadAllByEvent = $resource(
            'api/v1/notification/event/:eventId/listOfferNotificationForCreator',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: true},
            }
        );

        var listUnSentChangesNotificationsByEvent = $resource(
            'api/v1/notification/event/:eventId/unSentChangesNotifications',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: true},
            }
        );

        var listUnreadAllByOffer = $resource(
            'api/v1/notification/offer/:offerId/listOfferNotificationForSupplier',
            {offerId: '@offerId'},
            {
                get: {method: 'GET', isArray: true},
            }
        );

        var setAsRead = $resource(
            'api/v1/notification/markAsRead',
            {notifications: '@notifications'},
            {
                put: {method: 'PUT', isArray: true},
            }
        );

        return {
            listUnreadByEvent: function (eventId, success, error) {
                return listUnreadAllByEvent.get({eventId: eventId}, success, error);
            },
            listUnsentNotifications: function (eventId, success, error) {
                return listUnSentChangesNotificationsByEvent.get({eventId: eventId}, success, error);
            },
            listUnreadAllByOffer: function (offerId, success, error) {
                return listUnreadAllByOffer.get({offerId: offerId}, success, error);
            },
            setAsRead: function (data, success, error) {
                return setAsRead.put({notifications: data}, success, error);
            },
        };
    },
]);

