class OgOfferPartOptionDropdownController {
    constructor($timeout, $scope, $translate) {
        this._injected = { $timeout, $scope, $translate };

        if (!this.minLength) {
            this.minLength = 0;
        }

        this.select = this.select.bind(this);
        this.getInitialValue = this.getInitialValue.bind(this);
        this.remoteUrlRequest = this.remoteUrlRequest.bind(this);
        this.handleUrlResponse = this.handleUrlResponse.bind(this);
        this.getPartFromSearchString = this.getPartFromSearchString.bind(this);
    }

    remoteUrlRequest(str) {
        return { name: str };
    }

    getInitialValue() {
        if (!this.selected) {
            return;
        } else if (this.selected.resourceName) {
            return this.selected.resourceName;
        }
        return this.selected.name;
    }

    select(selected) {
        if (!selected) {
            return;
        }
        let _resourceId = selected.originalObject.id;
        let _name = selected.originalObject.name;
        let _isResourceUpdated = selected.originalObject.isResourceUpdated;
        let _isResourcePublic = selected.originalObject.isResourcePublic;
        this.updateSelectedPartNameCallback({
            name: _name,
            resourceId: _resourceId,
            isResourceUpdated: _isResourceUpdated,
            isResourcePublic: _isResourcePublic,
        });
    }

    getPartFromSearchString($item) {
        this.updateSelectedPartNameCallback({
            name: $item,
            resourceId: '',
            isResourceUpdated: false,
            isResourcePublic: false,
        });
    }

    handleUrlResponse(resp) {
        this.resources = resp;
        angular.forEach(this.resources, (resource) => {
            resource.translatedName = resource.name;
        });
        return this.resources;
    }
}

OgOfferPartOptionDropdownController.$inject = ['$timeout', '$scope', '$translate'];
export default OgOfferPartOptionDropdownController;
