import {UtilFunctions} from "../../../utils/global/utilFunctions";

(function () {
    'use strict';

    angular.module('ogFieldInput').directive('ogFieldInput', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                templateUrl: './app/components/directives/field_input/og_field_input.view.html',
                transclude: true,
                require: '^form',
                scope: {
                    id: '@id',
                    field: '@ogFieldInput',
                    model: '=model',
                    modelCode: '=modelCode',
                    label: '@label',
                    type: '@type',
                    required: '=required',
                    isDisabled: '=isDisabled', // this is to fix the issue for cannot using disabled in IE
                    optionValues: '=',
                    tooltipText: '=',
                    className: '@className',
                    onchanged: '=onchanged',
                    modelName: '@modelName',
                    fieldPlaceholder: '@fieldPlaceholder',
                    translatedLabel: '@translatedLabel',
                    showIcon: '=?', //for calender Icon,
                    maxLength: '=?',
                    hideLabel: '=',
                    focusTrigger: '=?',
                    showYearMonthOption: '=showYearMonthOption',
                    blur: '=?',
                    invalidValue: '=',
                    invalidValueMessage: '@',
                },
                controller: [
                    '$scope',
                    '$rootScope',
                    '$timeout',
                    'CommonFunctions',
                    function ($scope, $rootScope, $timeout, CommonFunctions) {
                        /*$scope.countries = ['se','dk','de','no','np','gb','us'];*/
                        $scope.preferredCountries = ['se', 'us'];
                        $scope.showIcon = true;
                        if ($scope.type) {
                            if ($scope.type === 'CHECKBOX') {
                                $scope.model = UtilFunctions.isBooleanTrue($scope.model);
                            } else if ($scope.type === 'DROPDOWN') {
                                setOptionValuesForDropDown();
                            } else if ($scope.type === 'MULTISELECT') {
                                if ($scope.model) {
                                    if (!Array.isArray($scope.model)) {
                                        var list = [];
                                        var _values = $scope.model.split(',');
                                        angular.forEach(_values, function (value) {
                                            list.push(value + '');
                                        });
                                        $scope.model = list;
                                    }
                                }
                            }
                        }
                        if ($scope.type === 'NUMBER') {
                            $scope.model = parseFloat($scope.model);
                        }
                        if ($scope.type === 'DATERANGE') {
                            if ($scope.model) {
                                let _dates = $scope.model.split('~');
                                $scope.fromDate = parseInt(_dates[0]);
                                $scope.toDate = parseInt(_dates[1]);
                            }
                        }
                        $scope.fieldChanged = $scope.$parent.fieldChanged;
                        $scope.hideChangedFieldIcon = $scope.$parent.hideChangedFieldIcon;
                        $scope.toggleCheckbox = function () {
                            if ($scope.isDisabled) {
                                return;
                            }
                            $scope.model = !$scope.model;
                            if (angular.isDefined($scope.onchanged)) {
                                $scope.onchanged();
                            }
                        };
                        $scope.getForm = function () {
                            if ($scope.form) {
                                return $scope.form;
                            }
                            return $scope.$parent.form;
                        };

                        $scope.getDateFormat = function getDateFormatFn() {
                            return $rootScope.langSelected === 'sv_SV' ? 'yy-mm-dd' : 'dd/mm/yy';
                        };

                        $scope.togglePasswordVisibility = function (eyeId) {
                            let _togglePassword = document.getElementById('tooglePasswordType_' + eyeId);
                            let _password = document.getElementById(eyeId);
                            // toggle the type attribute
                            const type = _password.getAttribute('type') === 'password' ? 'text' : 'password';
                            _password.setAttribute('type', type);
                            // toggle the eye / eye slash icon
                            _togglePassword.classList.toggle('active');
                        };

                        $scope.$watch('model', function (newVal, oldVal) {
                            if (newVal !== oldVal && $scope.modelCode && !$scope.modelLoaded) {
                                $scope.model = CommonFunctions.getTranslatedTextFromCode(
                                    $scope.model,
                                    $scope.modelCode
                                );
                                $scope.modelLoaded = true;
                            }
                        });

                        $scope.$watch('type', function () {
                            if ($scope.type === 'DROPDOWN') {
                                setOptionValuesForDropDown();
                            }
                            if ($scope.type === 'MULTISELECT') {
                                setOptionValuesForMultiSelect();
                                $timeout(function () {
                                    jQuery(document).ready(function () {
                                        jQuery('.multi-select-dropdown').select2({
                                            closeOnSelect: false,
                                            allowHtml: true,
                                            tags: true,
                                        });
                                    });
                                }, 500);
                            }
                        });

                        function setOptionValuesForDropDown() {
                            $scope.$watch(
                                'optionValues',
                                function (newVal) {
                                    if (newVal && newVal.length) {
                                        if (Array.isArray(newVal)) {
                                            $scope.selectMenuOptions = newVal;
                                        } else {
                                            $scope.selectMenuOptions = newVal.split('#');
                                        }
                                    } else {
                                        $scope.selectMenuOptions = '';
                                    }
                                },
                                true
                            );
                        }

                        function setOptionValuesForMultiSelect() {
                            $scope.$watch(
                                'optionValues',
                                function (newVal) {
                                    if (newVal && newVal.length) {
                                        if (Array.isArray(newVal)) {
                                            $scope.selectMenuOptions = newVal;
                                        } else {
                                            // jQuery('.multi-select-dropdown').empty();
                                            $scope.selectMenuOptions = newVal.split('#');
                                        }
                                    } else {
                                        $scope.selectMenuOptions = '';
                                    }
                                },
                                true
                            );
                        }
                    },
                ],
                link: function (scope, elem, attrs, form) {
                    scope.form = form;
                    var transElem = $(elem).find('.ng-scope');
                    scope.id = transElem.attr('id');
                    if (scope.id == null) {
                        scope.id = 'field_' + Math.floor(Math.random() * 1000000 + 1);
                        transElem.attr('id', scope.id);
                    }
                    if (scope.type && 'date' === scope.type.toLowerCase()) {
                        if (scope.model) {
                            scope.model = parseInt(scope.model);
                        }
                    }
                    setTimeout(function () {
                        jQuery('.icon-calendar').on('click', function () {
                            jQuery(this).siblings('input').datepicker('show');
                        });
                    }, 0);
                },
            };
        },
    ]);

    // angular.module('stringToNumber').directive('stringToNumber', function () {
    //     return {
    //         require: 'ngModel',
    //         link: function (scope, element, attrs, ngModel) {
    //             ngModel.$parsers.push(function (value) {
    //                 return '' + value;
    //             });
    //             ngModel.$formatters.push(function (value) {
    //                 return parseFloat(value);
    //             });
    //         },
    //     };
    // });
})();
