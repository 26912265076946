import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('ogSupplierThumbnail').directive('ogSupplierThumbnail', [
        function () {
            return {
                templateUrl: './app/components/directives/supplier_thumbnail/og_supplier_thumbnail.html',
                restrict: 'AE',
                scope: {
                    supplier: '<',
                    addSupplierCallback: '&',
                    readonly: '=',
                },
                controller: [
                    '$scope',
                    'CommonFile',
                    function ($scope, CommonFile) {
                        ScopeFunctions.addToScope($scope);

                        $scope.getPresentationImage = function () {
                            if ($scope.supplier && $scope.supplier.presentation) {
                                let presentationImages = $scope.supplier.presentation.presentationImages;
                                if (presentationImages.length > 0) {
                                    let imgFeatured = _.find(presentationImages, (img) => {
                                        return img.featured === true;
                                    });
                                    if (imgFeatured) {
                                        return imgFeatured.fileStoreId;
                                    }
                                    return presentationImages[0].fileStoreId;
                                }
                            }
                            return null;
                        };

                        $scope.getImageUrl = function (fileId) {
                            return CommonFile.getCommonFile(fileId);
                        };
                    },
                ],
                link: function (scope, $elm) {
                    $elm.on('click', function () {
                        setTimeout(function () {
                            $('#select-supplier-body').animate({ scrollTop: $elm[0].offsetTop + 0 }, 'slow');
                        }, 10);
                    });
                },
            };
        },
    ]);
})();
