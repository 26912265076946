(function () {
    'use strict';
    angular.module('budget-table').component('budgetTable', {
        templateUrl: './app/components/modules/event/event_detail/budget/budget_table/budget_table.view.html',
        controllerAs: 'budgetListCtrl',
        require: {
            parentCtrl: '^eventDetail'
        },
        controller: [
            'BrowserDetectService',
            function BudgetListController(BrowserDetectService) {
                let vm = this;
                vm.$onInit = function () {
                    vm.eventDetailCtrl = vm.parentCtrl;
                }
                vm.startPrint = BrowserDetectService.getPrintSummary;
            }
        ]
    });
})();
