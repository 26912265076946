'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from '../../utils/global/scopeFunctions';
import commonFunction from "../../utils/CommonFunctionUtil";

angular.module('dialog').directive('ogShareEventModal', [
    '$timeout',
    '$rootScope',
    'Event',
    'ValidationService',
    '$location',
    'AuthenticationService',
    '$translate',
    'GeoTag',
    '$stateParams',
    function (
            $timeout,
            $rootScope,
            Event,
            ValidationService,
            $location,
            AuthenticationService,
            $translate,
            GeoTag,
            $stateParams
        ) {
            return new OgModal({
                name: 'share:event',
                type: 'custom',
                controller: function ($scope) {
                    ScopeFunctions.addToScope($scope);
                    $scope.shareEventForm = {};

                    $scope.initDialog = function (data) {
                        if ($scope.shareEventForm) {
                            $scope.shareEventForm.shareEvent.$setUntouched();
                        }

                        $scope.data = {
                            eventTemplateName: data.event.name,
                            isPublic: false,
                            description: '',
                        };
                    };

                    $scope.saveEventTemplate = function () {
                        if (!$scope.shareEventForm.shareEvent.$valid) {
                            commonFunction.touchFormFields($scope.shareEventForm.shareEvent);
                            return;
                        }
                        $scope.startProgress('share-event');
                        Event.saveTemplate(
                            {
                                eventId: $stateParams.eventId,
                                isPublic: $scope.data.isPublic,
                                name: $scope.data.eventTemplateName,
                                description: $scope.data.description,
                                addPresentation: $scope.data.addPresentation,
                            },
                            function () {
                                $scope.endProgress('share-event');
                                $scope.close();
                            },
                            $scope.endWithErrorOverlay('share-event')
                        );
                    };
                },
            });
        },
    ]);

