'use strict';
angular.module('core.existingServices').factory('Client', [
    '$resource',
    function ($resource) {
        var v1ClientRest = $resource(
            'api/v1/client/clientName',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            getName: function ({}, successCallback, errorCallback) {
                return v1ClientRest.get({}, successCallback, errorCallback);
            },
        };
    },
]);

