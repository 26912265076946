import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

(function () {
    'use strict';

    angular.module('ogSearchResult').directive('ogSearchResult', function () {
        return {
            restrict: 'EA',
            transclude: true,
            templateUrl: './app/components/directives/search/search.result.view.html',
            controllerAs: 'searchResultCtrl',
            scope: {
                type: '@',
                params: '=',
                nonSearchParams: '=',
                loadOnInit: '@',
                searchWatch: '@',
                columns: '@',
                page: '@',
            },
            controller: [
                '$scope',
                '$rootScope',
                'Supplier',
                'Event',
                'Request',
                'LandingPage',
                function ($scope, $rootScope, Supplier, Event, Request, LandingPage) {
                    var vm = this;

                    vm.init = initFn;
                    vm.loadData = loadDataFn;
                    vm.newSearch = newSearchFn;
                    vm.nextPageAvailable = nextPageAvailableFn;
                    vm.paginationWatch = paginationWatchFn;
                    vm.searched = searchedFn;
                    vm.searchPreview = searchPreviewFn;
                    vm.setSorting = setSortingFn;
                    vm.showMore = showMoreFn;
                    vm.showNextColumns = showNextColumnsFn;
                    vm.showPreviousColumns = showPreviousColumnsFn;

                    vm.list = [];
                    vm.showMoreSpinner = false;
                    var searchCriteriaChanged = true;
                    $scope.animationClassList = [];
                    vm.type = $scope.type;
                    vm.params = $scope.params;
                    vm.nonSearchParams = $scope.nonSearchParams;
                    vm.loadOnInit = $scope.loadOnInit;
                    vm.searchWatch = $scope.searchWatch;
                    vm.columns = $scope.columns;
                    vm.page = $scope.page;

                    ScopeFunctions.addToScope($scope);

                    function generateDynamicClassForAnimation(totalCount) {
                        var classNameList = [];
                        for (var i = 0; i < totalCount; i++) {
                            classNameList.push('animate' + i);
                        }
                        return classNameList;
                    }

                    function getDivIdByOffset(offset) {
                        var divisionId = 'animate' + (offset - 1);
                        return divisionId;
                    }

                    function initFn() {
                        if (vm.search != null) {
                            return;
                        }
                        switch (vm.type) {
                            case 'SUPPLIER':
                                vm.search = Supplier.search;
                                vm.sortOptions = [
                                    { field: 'featured', direction: 'desc' },
                                    { field: 'score', direction: 'desc' },
                                    { field: 'name', direction: 'asc' },
                                    { field: 'name', direction: 'desc' },
                                ];
                                break;
                            case 'EVENT_TEMPLATE':
                                vm.search = Event.searchTemplates;
                                vm.sortOptions = [
                                    { field: 'priority', direction: 'asc' },
                                    { field: 'name', direction: 'asc' },
                                    { field: 'name', direction: 'desc' },
                                ];
                                break;
                            case 'REQUEST_TEMPLATE':
                                vm.search = Request.listTemplates;
                                vm.sortOptions = [
                                    { field: 'name', direction: 'asc' },
                                    { field: 'name', direction: 'desc' },
                                ];
                                break;
                            case 'GEO_TAG':
                                vm.search = LandingPage.listLandingGeoTags;
                                vm.sortOptions = [
                                    { field: 'priority', direction: 'asc' },
                                    { field: 'name', direction: 'desc' },
                                ];
                                break;
                            case 'ACTIVITY':
                                vm.search = LandingPage.listLandingActivities;
                                vm.sortOptions = [
                                    { field: 'name', direction: 'asc' },
                                    { field: 'name', direction: 'desc' },
                                ];
                        }
                        vm.params.sorting = vm.sortOptions[0];
                    }

                    function loadDataFn() {
                        vm.init();
                        $scope.startProgress();
                        vm.params.previewAll = searchCriteriaChanged;
                        vm.search(
                            vm.params,
                            function (result) {
                                vm.list = vm.list.concat(result.list);
                                vm.totalCount = result.totalCount;
                                $rootScope.$broadcast('searchTotalCount', [vm.type, vm.totalCount]);
                                if (result.list) {
                                    $scope.animationClassList = generateDynamicClassForAnimation(result.list.length, 4);
                                    vm.params.offset = result.paginationParams.offset;
                                }
                                if (searchCriteriaChanged) {
                                    if (vm.params.previewAll) {
                                        vm.searchPreviewAll = result.list;
                                        $rootScope.$broadcast(vm.searchWatch + '-previewAll', result.params);
                                    }
                                    searchCriteriaChanged = false;
                                }
                                if (vm.nonSearchParams && vm.nonSearchParams.callback) {
                                    vm.nonSearchParams.callback();
                                }
                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay
                        );
                    }

                    function newSearchFn() {
                        vm.list = [];
                        vm.showMoreSpinner = false;
                        vm.loadData();
                    }

                    function nextPageAvailableFn() {
                        if (!vm.params) {
                            return false;
                        }
                        var offset = vm.params.offset;
                        if (!offset) {
                            offset = 0;
                        }
                        return vm.params.max && offset + vm.params.max < vm.totalCount;
                    }

                    function paginationWatchFn(newValue, oldValue) {
                        if (newValue != oldValue && (oldValue != null || newValue != 0)) {
                            vm.loadData();
                        }
                    }

                    function searchedFn() {
                        return vm.list != null;
                    }

                    function searchPreviewFn(item) {
                        var preview = null;
                        angular.forEach(vm.searchPreviewAll, function (s) {
                            if (s.id === item.id) {
                                preview = s;
                            }
                        });
                        return preview;
                    }

                    function setSortingFn(sorting) {
                        if (vm.nonSearchParams) {
                            vm.nonSearchParams.scrollToId = 'supplierSection';
                        }
                        vm.params.sorting = sorting;
                        newSearchFn();
                    }

                    function showMoreFn(showMore) {
                        vm.params.offset += vm.params.max;
                        if (vm.nonSearchParams) {
                            vm.nonSearchParams.scrollToId = getDivIdByOffset(vm.params.offset);
                        }
                        vm.params.max = showMore;
                        vm.showMoreSpinner = true;
                        vm.loadData();
                    }

                    function showNextColumnsFn(col) {
                        vm.params.max = col;
                        if (vm.params.offset < vm.totalCount - col) {
                            vm.params.offset += col;
                        }
                    }

                    function showPreviousColumnsFn(col) {
                        vm.params.max = col;
                        if (vm.params.offset != 0) {
                            vm.params.offset -= col;
                        }
                    }

                    var watchString = 'search/new';

                    if (vm.searchWatch) {
                        watchString = vm.searchWatch;
                    }

                    $scope.$on(watchString, function () {
                        searchCriteriaChanged = true;
                        vm.params.offset = 0; //always set offset 0 while new search start
                        if ('supplier-search' == watchString) {
                            if (vm.nonSearchParams) {
                                vm.nonSearchParams.scrollToId = 'supplierSection';
                            }
                        }
                        newSearchFn();
                    });

                    if (vm.loadOnInit) {
                        vm.list = [];
                        vm.showMoreSpinner = false;
                        vm.loadData();
                    }
                },
            ],
        };
    });
})();
