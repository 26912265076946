import amountAndPriceCreatorService from '../../../utils/price_calculate/PriceCalculationCreatorUtil';
import tooltipUtilCreator from '../../../utils/negotiation_notification/NegotiationTooltipCreatorUtil';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';
import {UtilFunctions} from "../../../utils/global/utilFunctions";
import {OfferPartUtilFunctions} from "../../../utils/global/OfferPartUtilFunctions";

const DRAW_STRIKE_THROUGH_LINE = [
    'NOT_DELIVERABLE',
    'ADDED_WAITING_FOR_CREATOR_DECLINED',
    'ADDED_WAITING_FOR_SUPPLIER_DECLINED',
    'DELETED_WAITING_FOR_SUPPLIER',
    'DELETED_BY_CREATOR',
    'DELETED_BY_SUPPLIER',
    'DELETED_WAITING_FOR_CREATOR',
];
const DISPLAY_TOOLTIP_STATUS = [
    'ANSWERED',
    'SENT',
    'BOOKED_LOCKED_BY_SUPPLIER',
    'BOOKED_WAITING_FOR_SUPPLIER',
    'BOOKED_WAITING_FOR_CREATOR',
    'DELETED_BY_SUPPLIER',
    'DELETED_WAITING_FOR_CREATOR',
    'ADDED_WAITING_FOR_CREATOR',
    'FINISHED_WAITING_FOR_SUPPLIER',
    'FINISHED_WAITING_FOR_CREATOR',
];

(function () {
    'use strict';
    const HIDE_CHANGE_TOOLTIP_PART_STATUS = ['EDITED_WAITING_FOR_CREATOR', 'EDITED_WAITING_FOR_SUPPLIER'];

    angular.module('ogRequestOfferPartOptionAddEditCreator').directive('ogRequestOfferPartOptionAddEditCreator', [
        '$log',
        '$routeParams',
        '$rootScope',
        '$timeout',
        '$translate',
        'ModalService',
        'RequestOfferPartOption',
        'GlobalConstants',
        'RequestOfferStatusUtils',
        'TooltipValueService',
        'CommonFile',
        'CommonFunctions',
        function (
            $log,
            $routeParams,
            $rootScope,
            $timeout,
            $translate,
            ModalService,
            RequestOfferPartOption,
            GlobalConstants,
            RequestOfferStatusUtils,
            TooltipValueService,
            CommonFile,
            CommonFunctions
        ) {
            return {
                restrict: 'EA',
                scope: {
                    event: '=',
                    supplierId: '=',
                    layout: '=',
                    saveCallback: '=',
                    deleteCallback: '=',
                    offerPart: '=',
                    includeVat: '=',
                    selectedCurrency: '=',
                    offerPartOption: '=',
                    offer: '=',
                    offerPartOptionsWithActions: '=',
                    offerPartOptionsWithActionsCallback: '=',
                    flagForAcceptedWithChanges: '=',
                    showDiffCol: '=',
                    finalPriceAndAmountEdit: '=',
                    showPaidOnSiteCol: '=',
                },
                templateUrl:
                    './app/components/directives/request_offer_part_option_add_edit_creator/request_offer_part_option_add_edit_creator.directive.view.html',
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.hoursToAdd = 1; // Add default hours from 'from-date' to 'to-date' in dateTime pair.
                        ScopeFunctions.addToScope($scope);
                        $scope.uniqueId = Math.random();
                        $scope.VAT_DROPDOWN_LIST = GlobalConstants.VAT_DROPDOWN_LIST;
                        $scope.remoteUrl =
                            CommonFile.getBaseUrl() +
                            'api/v1/requestOfferPartOption?supplierId=' +
                            $scope.supplierId +
                            '&partTypeNameCode=' +
                            $scope.offerPart.nameCode;
                        $scope.getCreatorTotalPrice = amountAndPriceCreatorService.getRequestPartOptionTotalPrice;
                        $scope.getCreatorAmount = amountAndPriceCreatorService.getRequestOfferPartOptionAmount;
                        $scope.getCreatorPrice = amountAndPriceCreatorService.getRequestOfferPartOptionPrice;
                        $scope.getCreatorDifference = amountAndPriceCreatorService.getRequestPartOptionDifference;
                        $scope.showDiffColumnCreator =
                            $scope.offer.statusDetailed.name === 'FINISHED_WAITING_FOR_CREATOR';
                        $scope.partOptionId = null;
                        $rootScope.offerPartOptionEditId = 0;

                        $scope.amountTypes = [
                            { type: 'PIECES', name: $translate.instant('amount.type.PIECES') },
                            { type: 'PEOPLE', name: $translate.instant('amount.type.PEOPLE') },
                            { type: 'SAME_AS_EVENT', name: $translate.instant('amount.type.SAME_AS_EVENT') },
                        ];

                        $scope.init = function () {
                            $scope.isNewRow = false;
                            $scope.syncDates = true;
                            $scope.originalOfferPartsList = angular.copy($scope.offer.offerParts);

                            if ($scope.offerPartOption == null) {
                                $scope.data = {
                                    requestOfferPartId: $scope.offerPart.id,
                                    amount: $scope.offerPart.amount,
                                    amountType: $scope.offerPart.amountType,
                                    dateTimeFrom: $scope.offerPart.dateTimeFrom,
                                    dateTimeTo: $scope.offerPart.dateTimeTo,
                                    vat: $scope.offerPart.vat,
                                    status: $scope.offerPart.status,
                                };
                                if (!$scope.offerPart.hasRequestOfferPartOptions) {
                                    $scope.data.isSelected = true;
                                }
                                $scope.isNewRow = true;
                                $scope.originalOfferPartOption = angular.copy($scope.data);
                            } else {
                                $scope.originalOfferPartOption = angular.copy($scope.offerPartOption);
                                $scope.data = angular.copy($scope.offerPartOption);
                                $scope.data.price = UtilFunctions.isGreaterOrEqualToZero($scope.data.newPrice)
                                    ? $scope.data.newPrice
                                    : $scope.data.price;
                                $scope.data.requestOfferPartId = $scope.offerPart.id;
                            }
                            $scope.data.canEditOptionByCreator = false;
                            $scope.data.canEditOptionBySupplier = false;
                            $scope.data.loading = false;
                            $scope.isTimePairValid = true;
                            $scope.showPaidOnsiteCol = $scope.isOfferInFromFinishedState() && $scope.showPaidOnSiteCol;
                        };

                        $scope.checkIfOfferPartOptionPaidOnSite = function () {
                            return _.some($scope.offerPart.offerPartOption, (partOption) => {
                                return partOption.finalPaidOnsite;
                            });
                        };

                        $scope.toggleOptionSelected = function () {
                            let _params = { isSelected: $scope.data.isSelected };
                            $scope.startProgress();
                            RequestOfferPartOption.updatePartOptionSelection(
                                $scope.originalOfferPartOption.id,
                                _params,
                                (resp) => {
                                    $rootScope.$broadcast('part:option:is:selected:value:changed', {
                                        offerId: $scope.offer.id,
                                        offerPartId: $scope.offerPart.id,
                                        offerPartOptionId: $scope.originalOfferPartOption.id,
                                        isSelected: resp['isSelected'],
                                    });
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay()
                            );
                        };

                        $scope.save = function () {
                            $scope.data.sectionUpdated = 'ALL';
                            $scope.data.newPrice = $scope.data.price;
                            let data = angular.copy($scope.data);
                            $scope.saveCallback(data);
                        };

                        $scope.saveInlineForm = function (data) {
                            $scope.saveCallback(data);
                        };

                        $scope.viewAmountLocalisedWithCurrency = function (price, currency, digitsAfterDecimal = 0) {
                            return amountAndPriceCreatorService.viewPrice(price, currency, digitsAfterDecimal);
                        };

                        $scope.confirmDeleteToggle = function (requestOfferPartOptionId) {
                            if ($scope.data.id === requestOfferPartOptionId) {
                                $scope.data.showDeleteConfirmation = !$scope.data.showDeleteConfirmation;
                            }
                            $rootScope.$broadcast('confirm:delete:toggle:part:option', {
                                partId: requestOfferPartOptionId,
                            });
                            // $rootScope.$broadcast('confirm:delete:toggle:offer:part', {});
                        };

                        $scope.delete = function (requestOfferPartOption) {
                            $scope.confirmDeleteToggle(requestOfferPartOption.id);
                            $scope.deleteCallback(requestOfferPartOption);
                        };

                        $scope.openResourcePresentationPopover = function (resourceName, resourceId) {
                            $rootScope.$broadcast('part:option:open:presentation', {
                                resourceName: resourceName,
                                offerPartTypeNameCode: $scope.offerPart.nameCode,
                                resourceId: resourceId,
                            });
                        };

                        $scope.isOfferInCanceledState = function (offer) {
                            return CommonFunctions.isItCanceledOffer(offer);
                        };

                        $scope.isOfferInFromBookedState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(
                                    $scope.offer.status.name
                                ) > -1
                            );
                        };

                        /*Need to check*/
                        $scope.isOfferInSupplierEditDisabledState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.OFFER_IN_SUPPLIER_EDIT_DISABLED_STATE.indexOf(
                                    $scope.offer.status.name
                                ) > -1
                            );
                        };

                        $scope.showInfoTooltip = function (offerPartOption) {
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
                                return (
                                    $scope.isOfferPartOptionInSupplierWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartOptionInCreatorDeclinedStates(offerPartOption)
                                );
                            }
                            return false;
                        };

                        $scope.getTooltipValueInfoMessageByStatus = function (status) {
                            return TooltipValueService.getInfoMessageByStatus(status);
                        };

                        $scope.applyRowBgColor = function (offer, part) {
                            if ($scope.isOfferInCanceledState(offer)) {
                                return part.status.name;
                            }
                            if (
                                offer.status.name === 'BOOKED_WAITING_FOR_SUPPLIER' &&
                                part.status.name === 'EDITED_BY_CREATOR'
                            ) {
                                return 'EDITED_BY_ME_SENT';
                            }
                            return tooltipUtilCreator.getPartNotificationState(offer, part);
                        };

                        $scope.hideDeleteRowBtnCreator = function (offerPartOption) {
                            return (
                                $scope.isOfferInFromFinishedState() ||
                                $scope.isOfferInCanceledState($scope.offer) ||
                                $scope.offer.statusDetailed.name === 'CANCELED' ||
                                $scope.showCreatorOptions(offerPartOption) ||
                                ['DELETED_BY_CREATOR', 'DELETED_WAITING_FOR_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(
                                    offerPartOption.status.name
                                ) > -1
                            );
                        };

                        $scope.isOfferInFromFinishedState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf($scope.offer.status.name) > -1
                            );
                        };

                        $scope.actionOnRowEdit = function (offerPartOption) {
                            $scope.disableOfferPartOptionInfo(offerPartOption);
                            $scope.partOptionId = 'd' + offerPartOption.id;
                            $scope.changeRowId();
                        };

                        $scope.changeRowId = function () {
                            $rootScope.offerPartOptionEditId = $scope.partOptionId;
                        };

                        $scope.doStrikeThrough = function (offerPartOption) {
                            if (
                                CommonFunctions.hasIntersectValue(DRAW_STRIKE_THROUGH_LINE, [
                                    offerPartOption.status.name,
                                ])
                            ) {
                                return true;
                            }
                            if (
                                offerPartOption.status.name === 'CANCELED' &&
                                $scope.isOfferInCanceledState($scope.offer)
                            ) {
                                return true;
                            }
                            if (offerPartOption.status.name === 'DELETED_WAITING_FOR_CREATOR') {
                                return $scope.offer.statusDetailed.name !== 'BOOKED_LOCKED_BY_SUPPLIER';
                            }
                        };

                        $scope.fieldValueHasBeenChanged = function (offerPartOption, oldValue, newValue) {
                            let partOptionStatus = offerPartOption.status.name;
                            if (partOptionStatus !== 'ACCEPTED') {
                                return $scope.hasFieldValueChanged(oldValue, newValue);
                            }
                            return false;
                        };
                        $scope.fieldAmountValueHasBeenChanged = function (offerPartOption, oldValue, newValue) {
                            // let partOptionStatus = offerPartOption.status.name;
                            // if (partOptionStatus !== 'ACCEPTED') {
                            //     return $scope.hasAmountFieldValueChanged(oldValue, newValue);
                            // }
                            // return false;
                            if (
                                $scope.offer.statusDetailed.name === 'SENT' &&
                                offerPartOption.status.name === 'ACCEPTED'
                            ) {
                                return false;
                            } else {
                                return $scope.hasAmountFieldValueChanged(oldValue, newValue);
                            }
                        };

                        $scope.hasFieldValueChanged = function (oldValue, newValue) {
                            if ($scope.offer.statusDetailed.name === 'SENT' && $scope.data.status.name === 'ACCEPTED') {
                                return false;
                            }

                            if ($scope.offer.alreadyAnswered) {
                                return OfferPartUtilFunctions.checkOfferPartHasOldValueAndChanged(oldValue, newValue);
                            }
                            return false;
                        };
                        $scope.hasAmountFieldValueChanged = function (oldValue, newValue) {
                            if ($scope.offer.alreadyAnswered) {
                                return OfferPartUtilFunctions.checkOfferPartAmountHasOldValueAndChanged(
                                    oldValue,
                                    newValue
                                );
                            }
                            return false;
                        };

                        $scope.hasValChanged = function (offerPartOption, type) {
                            if (offerPartOption.status.name === 'ADDED_BY_CREATOR') {
                                return false;
                            }
                            if (
                                $scope.isOfferPartInCreatorWaitingStates(offerPartOption) ||
                                $scope.isOfferPartInSupplierDeclinedStates(offerPartOption)
                            ) {
                                var originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                                switch (type) {
                                    case 'NAME':
                                        return offerPartOption.resourceName != originalOfferPart.resourceName;
                                    case 'AMOUNT':
                                        return offerPartOption.amount != originalOfferPart.amount;
                                    case 'AMOUNT_TYPE':
                                        return offerPartOption.amountType.name != originalOfferPart.amountType.name;
                                    case 'DATE_TIME':
                                        return (
                                            offerPartOption.dateTimeFrom != originalOfferPart.dateTimeFrom ||
                                            offerPartOption.dateTimeTo != originalOfferPart.dateTimeTo
                                        );
                                    case 'PRICE':
                                        return offerPartOption.newPrice != originalOfferPart.price;
                                    default:
                                        return false;
                                }
                            }
                        };

                        $scope.isOfferPartInCreatorWaitingStates = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_CREATOR') {
                                return false;
                            }
                            let offerPartIsInCreatorWaitingStates = false;
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            GlobalConstants.CREATOR_WAITING_STATES.forEach((state) => {
                                if (!offerPartIsInCreatorWaitingStates) {
                                    if (originalOfferPart && originalOfferPart.status.name === state) {
                                        offerPartIsInCreatorWaitingStates = true;
                                    }
                                }
                            });
                            return offerPartIsInCreatorWaitingStates;
                        };

                        $scope.isOfferPartInSupplierDeclinedStates = function (offerPartOption) {
                            let offerPartIsInSupplierDeclinedStates = false;
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            GlobalConstants.SUPPLIER_DECLINED_STATES.forEach((state) => {
                                if (!offerPartIsInSupplierDeclinedStates) {
                                    if (originalOfferPart && originalOfferPart.status.name === state) {
                                        offerPartIsInSupplierDeclinedStates = true;
                                    }
                                }
                            });
                            return offerPartIsInSupplierDeclinedStates;
                        };

                        $scope.getOriginalOfferPartOption = function (offerPartOption) {
                            let originalOfferPartOption;
                            if (!offerPartOption || !offerPartOption.id) {
                                return undefined;
                            }
                            if (offerPartOption.id === $scope.originalOfferPartOption.id) {
                                originalOfferPartOption = $scope.originalOfferPartOption;
                            }
                            return originalOfferPartOption;
                        };

                        $scope.fieldChangedForPrice = function (offerPartOption) {
                            if (
                                $scope.offer.statusDetailed.name === 'SENT' &&
                                offerPartOption.status.name === 'ACCEPTED'
                            ) {
                                return false;
                            }
                            if (offerPartOption.status.name === 'ADDED_BY_CREATOR') {
                                return false;
                            }
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            if (originalOfferPart.status.name === 'ADDED_WAITING_FOR_CREATOR') {
                                return false;
                            }
                            let _price = amountAndPriceCreatorService.getRequestOfferPartOptionPrice(
                                $scope.offer,
                                offerPartOption
                            );
                            let _oldPrice = amountAndPriceCreatorService.getOldPrice($scope.offer, offerPartOption);
                            if ($scope.isOfferPartInCreatorWaitingStates(offerPartOption)) {
                                return _price !== _oldPrice;
                            } else {
                                let _oldPrice = offerPartOption.oldPrice;
                                let _isOldPriceExists = UtilFunctions.isDefinedAndNotNull(_oldPrice);
                                let _newPrice = offerPartOption.price || 0;
                                return _isOldPriceExists && _newPrice !== _oldPrice;
                            }
                        };

                        $scope.fieldChangedForDate = function (offerPartOption) {
                            // console.log('offerPartOption', offerPartOption);
                            if (
                                $scope.offer.statusDetailed.name === 'SENT' &&
                                offerPartOption.status.name === 'ACCEPTED'
                            ) {
                                return false;
                            }
                            let hasFromDateChanged;
                            let hasToDateChanged;
                            if (offerPartOption && offerPartOption.status.name === 'EDITED_BY_CREATOR') {
                                hasFromDateChanged = OfferPartUtilFunctions.checkOfferPartHasOldValueAndChanged(
                                    offerPartOption.dateTimeFrom,
                                    offerPartOption.creatorDateTimeFrom
                                );
                                hasToDateChanged = OfferPartUtilFunctions.checkOfferPartHasOldValueAndChanged(
                                    offerPartOption.dateTimeTo,
                                    offerPartOption.creatorDateTimeTo
                                );
                            } else {
                                hasFromDateChanged = OfferPartUtilFunctions.checkOfferPartHasOldValueAndChanged(
                                    offerPartOption.oldDateTimeFrom,
                                    offerPartOption.dateTimeFrom
                                );
                                hasToDateChanged = OfferPartUtilFunctions.checkOfferPartHasOldValueAndChanged(
                                    offerPartOption.oldDateTimeTo,
                                    offerPartOption.dateTimeTo
                                );
                            }
                            return (
                                offerPartOption.status.name !== 'ADDED_WAITING_FOR_SUPPLIER' &&
                                (hasFromDateChanged || hasToDateChanged)
                            );
                        };

                        $scope.enableOfferPartOptionInfo = function (offerPartOption) {
                            if (
                                ['BOOKED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_CREATOR'].indexOf(
                                    $scope.offer.statusDetailed.name
                                ) > -1
                            ) {
                                if (offerPartOption.status.name === 'ADDED_BY_CREATOR') {
                                    $scope.ROPInfo = 'info' + offerPartOption.name;
                                    return;
                                }
                                if (
                                    $scope.isOfferPartInCreatorWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartOptionInSupplierWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartInSupplierDeclinedStates(offerPartOption)
                                ) {
                                    $scope.ROPInfo = 'info' + offerPartOption.id;
                                }
                            }
                        };

                        $scope.disableOfferPartOptionInfo = function (offerPartOption) {
                            if (
                                ['BOOKED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_CREATOR'].indexOf(
                                    $scope.offer.statusDetailed.name
                                ) > -1
                            ) {
                                if (offerPartOption.status.name === 'ADDED_BY_CREATOR') {
                                    $scope.ROPInfo = undefined;
                                    return;
                                }
                                if (
                                    $scope.isOfferPartInCreatorWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartOptionInSupplierWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartInSupplierDeclinedStates(offerPartOption)
                                ) {
                                    $scope.ROPInfo = undefined;
                                }
                            }
                        };

                        $scope.showPriceChangeToolTipForOfferByStatus = function (offerPartOption, statusList) {
                            if ($scope.hideToolTipForGivenPartStatuses(offerPartOption.status)) {
                                return false;
                            }
                            let _isOfferStatusMatched = CommonFunctions.hasIntersectValue(
                                [$scope.offer.status.name],
                                statusList
                            );
                            let _oldPrice = offerPartOption.oldPrice || 0;
                            let _newPrice =
                                offerPartOption.status.name === 'EDITED_BY_SUPPLIER'
                                    ? offerPartOption.newPrice
                                    : offerPartOption.price || 0;
                            let _valueChanged = _oldPrice !== _newPrice;
                            return (
                                _isOfferStatusMatched &&
                                UtilFunctions.isGreaterOrEqualToZero(offerPartOption.oldPrice) &&
                                _valueChanged
                            );
                        };

                        $scope.hideToolTipForGivenPartStatuses = function (status) {
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
                                if (_.contains(HIDE_CHANGE_TOOLTIP_PART_STATUS, status.name.trim())) {
                                    return true;
                                }
                            }
                            return false;
                        };

                        $scope.isOfferPartOptionInSupplierWaitingStates = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER') {
                                return false;
                            }
                            let offerPartIsInSupplierWaitingStates = false;
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            angular.forEach(GlobalConstants.SUPPLIER_WAITING_STATES, function (state) {
                                if (!offerPartIsInSupplierWaitingStates) {
                                    if (
                                        $scope.layout === 'supplier' &&
                                        originalOfferPart &&
                                        originalOfferPart.status.name === state
                                    ) {
                                        offerPartIsInSupplierWaitingStates = true;
                                    }
                                    if ($scope.layout === 'creator' && offerPartOption.status.name === state) {
                                        offerPartIsInSupplierWaitingStates = true;
                                    }
                                }
                            });
                            return offerPartIsInSupplierWaitingStates;
                        };

                        $scope.isOfferPartOptionInCreatorDeclinedStates = function (offerPartOption) {
                            let offerPartIsInCreatorDeclinedStates = false;
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            angular.forEach(GlobalConstants.CREATOR_DECLINED_STATES, function (state) {
                                if (!offerPartIsInCreatorDeclinedStates) {
                                    if (originalOfferPart && originalOfferPart.status.name === state) {
                                        offerPartIsInCreatorDeclinedStates = true;
                                    }
                                }
                            });
                            return offerPartIsInCreatorDeclinedStates;
                        };

                        $scope.displayOfferPartChangeToolTip = function (offerPartOption, oldValue, newValue) {
                            if (
                                $scope.hasFieldValueChanged(oldValue, newValue) &&
                                !$scope.displayOfferPartChangeSummary(offerPartOption)
                            ) {
                                return !$scope.hideToolTipForGivenPartStatuses(offerPartOption.status);
                            }
                            return false;
                        };

                        $scope.getOldValueForChangedTitle = function (offerPartOption, type) {
                            let value = 0;
                            switch (type) {
                                case 'AMOUNT':
                                    value = amountAndPriceCreatorService.getOldAmountValue(
                                        $scope.offer,
                                        offerPartOption
                                    );
                                    break;
                                case 'PRICE':
                                    value = amountAndPriceCreatorService.getOldPriceValue(
                                        $scope.offer,
                                        offerPartOption
                                    );
                                    if (amountAndPriceCreatorService.shouldIncludeVatOnPrice()) {
                                        value = amountAndPriceCreatorService.calculatePriceFromPriceAndVat(
                                            value,
                                            offerPartOption.vat
                                        );
                                    }
                                    break;
                                case 'AMOUNT_TYPE':
                                    value = amountAndPriceCreatorService.getOldAmountTypeValue(
                                        $scope.offer,
                                        offerPartOption
                                    );
                                    break;
                            }
                            return UtilFunctions.isUndefinedOrNull(value) ? 0 : value;
                        };

                        $scope.displayFieldChangedForDate = function (offerPartOption) {
                            if (
                                $scope.fieldChangedForDate(offerPartOption) &&
                                !$scope.displayOfferPartChangeSummary(offerPartOption)
                            ) {
                                return !$scope.hideToolTipForGivenPartStatuses(offerPartOption.status);
                            }
                            return false;
                        };

                        $scope.showPriceChangeToolTip = function (offerPartOption) {
                            if ($scope.hideToolTipForGivenPartStatuses(offerPartOption.status)) {
                                return false;
                            }

                            let _showPriceForFinished = $scope.showPriceChangeToolTipForFinishedOffer(offerPartOption);
                            let _oldPrice = offerPartOption.oldPrice;
                            let _newPrice = offerPartOption.price || 0;
                            let _isOldPriceExists = UtilFunctions.isDefinedAndNotNull(_oldPrice);
                            let _changed = _oldPrice !== _newPrice;
                            let _isOfferStatusMatched = CommonFunctions.hasIntersectValue(
                                [$scope.offer.status.name],
                                ['ANSWERED']
                            );
                            let _showPriceForAnswered = _isOldPriceExists && _changed && _isOfferStatusMatched;

                            return _showPriceForFinished || _showPriceForAnswered;
                        };

                        $scope.getTooltipValueAfterFinished = function (offerPartOption, type) {
                            let oldString;
                            let newString;
                            switch (type) {
                                case 'AMOUNT':
                                    oldString = $scope.getOldValueForChangedTitle(offerPartOption, type);
                                    newString = amountAndPriceCreatorService.getAmount(this.offer, offerPartOption);
                                    break;
                                case 'PRICE':
                                    oldString = $scope.viewAmountLocalisedWithCurrency(
                                        $scope.getOldValueForChangedTitle(offerPartOption, type),
                                        $scope.offer.currency
                                    );
                                    newString = $scope.viewAmountLocalisedWithCurrency(
                                        amountAndPriceCreatorService.getPrice(this.offer, offerPartOption),
                                        $scope.offer.currency
                                    );
                                    break;
                            }
                            return TooltipValueService.getTooltipValueAfterFinished(oldString, newString, type);
                        };

                        $scope.showPriceChangeToolTipForFinishedOffer = function (offerPartOption) {
                            var correctOfferStatus = CommonFunctions.hasIntersectValue(
                                [$scope.offer.status.name],
                                ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR']
                            );
                            var correctFinishedCondition =
                                offerPartOption.finalPrice !== offerPartOption.price &&
                                offerPartOption.status.name !== 'EDITED_WAITING_FOR_CREATOR';
                            return correctOfferStatus && correctFinishedCondition && !$scope.finalPriceAndAmountEdit;
                        };

                        $scope.displayOfferPartChangeSummary = function (offerPartOption) {
                            return (
                                $scope.ROPInfo === 'info' + offerPartOption.id ||
                                $scope.ROPInfo === 'info' + offerPartOption.resourceName
                            );
                        };

                        $scope.getTooltipValuePrice = function (offerPartOption) {
                            let oldPriceString = $scope.viewAmountLocalisedWithCurrency(
                                $scope.getOldValueForChangedTitle(offerPartOption, 'PRICE'),
                                $scope.offer.currency
                            );
                            return TooltipValueService.getTooltipValuePrice(oldPriceString);
                        };

                        $scope.getTooltipValueAmount = function (offerPartOption) {
                            if (offerPartOption && offerPartOption.status.name === 'EDITED_BY_CREATOR') {
                                let oldValueString = $translate.instant('common.old.value').concat(': ');
                                let amountTypeString = '';
                                if (offerPartOption.amountType) {
                                    if (offerPartOption.amountType.name === 'PIECES') {
                                        amountTypeString = $translate.instant('common.value.pieces');
                                    } else {
                                        amountTypeString = $translate.instant('common.value.people');
                                    }
                                }
                                return oldValueString.concat(offerPartOption.amount, ' ', amountTypeString);
                            } else {
                                let oldAmount = $scope.getOldValueForChangedTitle(offerPartOption, 'AMOUNT');
                                return TooltipValueService.getTooltipValueAmount(offerPartOption, oldAmount);
                            }
                        };

                        $scope.showAmountChangeToolTipForFinishedOffer = function (offerPartOption) {
                            var correctOfferStatus = CommonFunctions.hasIntersectValue(
                                [$scope.offer.status.name],
                                ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR']
                            );
                            var correctFinishedCondition =
                                offerPartOption.finalAmount !== offerPartOption.amount &&
                                offerPartOption.status.name !== 'EDITED_WAITING_FOR_CREATOR';
                            return correctOfferStatus && correctFinishedCondition && !$scope.finalPriceAndAmountEdit;
                        };

                        $scope.getTooltipValueDate = function (offerPartOption) {
                            let params = {};
                            if (offerPartOption && offerPartOption.status.name === 'EDITED_BY_CREATOR') {
                                params = {
                                    fromDate: offerPartOption.dateTimeFrom,
                                    toDate: offerPartOption.dateTimeTo,
                                    baseDate: this.event.fromDate,
                                    absolute: true,
                                    showTime: true,
                                    isInvitation: false,
                                };
                            } else {
                                params = {
                                    fromDate: offerPartOption.oldDateTimeFrom,
                                    toDate: offerPartOption.oldDateTimeTo,
                                    baseDate: this.event.fromDate,
                                    absolute: true,
                                    showTime: true,
                                    isInvitation: false,
                                };
                            }
                            return TooltipValueService.getTooltipValueDate(params);
                        };

                        $scope.showCreatorOptions = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_CREATOR' || $scope.layout !== 'creator') {
                                return false;
                            }
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
                                return (
                                    $scope.isOfferPartInCreatorWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartInSupplierDeclinedStates(offerPartOption)
                                );
                            }
                        };

                        $scope.acceptOfferPartOptionCreator = function (offerPartOption) {
                            let offerPartOptionMap = {
                                offerPartOption: offerPartOption,
                                action: 'ACCEPTED_BY_CREATOR',
                            };
                            $scope.removeOfferPartOptionFromMap(offerPartOption);

                            var originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            //OfferPreviewModalCtrl.reassignOriginalValuesToOfferPart(offerPart, originalOfferPart);
                            if (originalOfferPart.status.name === 'EDITED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'ACCEPTED';
                            }
                            if (originalOfferPart.status.name === 'ADDED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'ACCEPTED';
                            }
                            if (originalOfferPart.status.name === 'DELETED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'NOT_DELIVERABLE';
                            }
                            $scope.flagForAcceptedWithChanges = undefined;
                            $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);

                            //$scope.checkIfActionHasBeenTakenForAllSupplierChanges();
                        };

                        $scope.removeOfferPartOptionFromMap = function (offerPartOption) {
                            angular.forEach($scope.offerPartOptionsWithActions, function (rowAndAction, i) {
                                if (rowAndAction.offerPartOption.id === offerPartOption.id) {
                                    $scope.offerPartOptionsWithActions.splice(i, 1);
                                }
                            });
                        };

                        $scope.declineOfferPartOptionCreator = function (offerPartOption) {
                            var offerPartOptionMap = {
                                offerPartOption: offerPartOption,
                                action: 'DECLINED_BY_CREATOR',
                            };
                            $scope.removeOfferPartOptionFromMap(offerPartOption);

                            var originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            //OfferPreviewModalCtrl.reassignOriginalValuesToOfferPart(offerPartOption, originalOfferPart);
                            if (originalOfferPart.status.name === 'EDITED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'DECLINED';
                            }
                            if (originalOfferPart.status.name === 'ADDED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'ADDED_WAITING_FOR_CREATOR_DECLINED';
                            }
                            if (originalOfferPart.status.name === 'DELETED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'DELETED_WAITING_FOR_CREATOR_DECLINED';
                            }
                            $scope.flagForAcceptedWithChanges = undefined;
                            $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);

                            //$scope..checkIfActionHasBeenTakenForAllSupplierChanges();
                        };

                        $scope.$on('request:offer:part:option:updated:client:side', (event, data) => {
                            if ($scope.data.id === data.offerPartOption.id) {
                                $scope.data = angular.copy(data.offerPartOption);
                            }
                        });

                        let partOptionSelectedValueChanged = $rootScope.$on(
                            'part:option:is:selected:value:changed',
                            function (event, data) {
                                if ($scope.data.id === data.offerPartOptionId) {
                                    $scope.data.isSelected = data.isSelected;
                                }
                            }
                        );

                        $scope.$on('$destroy', partOptionSelectedValueChanged);

                        $rootScope.$on('part:option:list:is:selected:value:changed', function (event, data) {
                            angular.forEach(data.offerPartOptionsChangeList, (o) => {
                                if (o.id === $scope.data.id) {
                                    $scope.data.isSelected = o.isSelected;
                                }
                            });
                        });

                        $rootScope.$on('open:edit:offer:part:option:creator', function (event, data) {
                            if ($scope.data.id === data.offerPartOption.id) {
                                $scope.data.canEditOptionByCreator = true;
                            }
                        });

                        $scope.$on('update:offer:in:offer:part:option', (event, data) => {
                            angular.forEach(data.requestOffers, (offer) => {
                                if ($scope.offer.id === offer.id) {
                                    $scope.offer = angular.copy(offer);
                                }
                            });
                        });

                        $scope.$on('revert:offer:part:option:changes', (event, data) => {
                            angular.forEach(data.requestOfferParts, (offerPart) => {
                                if ($scope.offerPart.id === offerPart.id) {
                                    angular.forEach(offerPart.offerPartOptions, (offerPartOption) => {
                                        if ($scope.offerPartOption.id === offerPartOption.id) {
                                            $scope.offerPartOption = angular.copy(offerPartOption);
                                            $scope.init();
                                        }
                                    });
                                }
                            });
                        });

                        let confirmDeleteToggle = $rootScope.$on(
                            'confirm:delete:toggle:part:option',
                            function (event, data) {
                                if ($scope.data.id !== data.partId) {
                                    $scope.data.showDeleteConfirmation = false;
                                }
                            }
                        );

                        $scope.$on('$destroy', function () {
                            confirmDeleteToggle();
                        });

                        let requestOfferPartOptionDeleted = $rootScope.$on(
                            'request:offer:part:option:deleted',
                            (event, data) => {
                                angular.forEach(data.offerPartOptions, function (partOption) {
                                    if ($scope.layout === 'creator' && $scope.data.id === partOption.id) {
                                        $scope.data.status.name = partOption.status.name;
                                    }
                                });
                            }
                        );

                        $scope.$on('$destroy', requestOfferPartOptionDeleted);

                        let requestOfferPartOptionDeletedClient = $rootScope.$on(
                            'request:offer:part:option:deleted:client:side:only:creator',
                            (event, data) => {
                                if ($scope.layout === 'creator' && $scope.data.id === data.offerPartOption.id) {
                                    $scope.data.status.name = data.offerPartOption.status.name;
                                }
                            }
                        );

                        $scope.$on('$destroy', requestOfferPartOptionDeletedClient);

                        let offerPartOptionUpdateClient = $rootScope.$on(
                            'request:offer:part:option:updated',
                            (event, data) => {
                                if ($scope.data.id === data.offerPartOption.id) {
                                    $scope.data = angular.copy(data.offerPartOption);
                                }
                            }
                        );

                        $scope.$on('$destroy', offerPartOptionUpdateClient);

                        $scope.init();
                    },
                ],
                link: function () { },
            };
        },
    ]);
})();
