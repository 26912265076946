(function () {
    'use strict';

    angular.module('ogSurveyDetails').directive('ogSurveyDetails', [
        function () {
            return {
                restrict: 'EA',
                templateUrl: './app/components/directives/survey_details/og_survey_details.directive.view.html',
                scope: {
                    surveys: '=',
                    type: '=',
                    show: '=',
                    close: '=',
                    save: '=',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.showCommentBox = false;
                        if ($scope.surveys && !$scope.surveys.rated) {
                            $scope.showCommentBox = true;
                        }
                        $scope.toggleCommentBox = function () {
                            $scope.showCommentBox = !$scope.showCommentBox;
                        };
                    },
                ],
            };
        },
    ]);
})();
