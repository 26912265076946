'use strict';
import {
    setLocalToken,
    getHttpConfig,
    getLocalToken,
    clearLocalToken,
    setSessionToken,
    getLocalToken_temp
} from "../../utils/index";

angular.
    module('core.authenticationService').
    factory('AuthenticationService', [
        '$http',
        '$q',
        '$rootScope',
        '$resource',
        '$location',
        '$window',
        'EventEnum',
        '$timeout',
        '$state',
        'xdLocalStorage',
        'ClientService',
        function (
            $http,
            $q,
            $rootScope,
            $resource,
            $location,
            $window,
            EventEnum,
            $timeout,
            $state,
            xdLocalStorage,
            ClientService) {
            var userData = {};
            var userGroups = [];
            var userRoles = [];

            function setUserData(data) {
                if (angular.isObject(data)) {
                    userData = data;
                    userGroups = data.userGroups;
                    //  var token = userData.token;
                    var lang = userData.prefLang;
                    var prefLang = lang ? lang.split('_')[0] : 'sv';
                    //  document.cookie = "token="+ token;
                    document.cookie = 'prefLang=' + prefLang;
                    $rootScope.$broadcast(EventEnum.USER_DATA_CHANGED, userData);
                } else {
                    userData = {};
                    document.cookie = 'token=' + null;
                    document.cookie = 'prefLang=sv';
                }
                if (testRole()) {
                    $rootScope.showPage = true;
                }
            }

            function setUserRoles(roles) {
                userRoles = roles;
            }

            function getParameterByName(name) {
                name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                var results = regex.exec(location.search);
                return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
            }

            var userResource = $resource(
                'api/v1/user/:action',
                {},
                {
                    listGroups: {
                        method: 'GET',
                        isArray: true,
                        params: { action: 'groups' },
                    },
                    switchGroup: {
                        method: 'GET',
                        params: { action: 'switchGroup' },
                    },
                }
            );

            var userEventResource = $resource(
                'api/v1/user/:action/:eventId',
                {eventId: '@eventId'},{
                    changeOwnerGroup: {
                        method: 'PUT',
                        isArray:false,
                        params: {action: 'changeOwnerGroup'}
                    }
                }
            )

            var passwordResource = $resource(
                'api/v1/password/:action',
                {},
                {
                    requestNewPassword: {
                        method: 'POST',
                        params: { action: 'request' },
                    },
                    changePassword: {
                        method: 'POST',
                        params: { action: 'change' },
                    },
                    changeFirstTimePassword: {
                        method: 'POST',
                        params: { action: 'changeFirstTimePassword' },
                    },
                }
            );

            var mfaResource = $resource(
                'api/v1/mfa/:action',
                {},
                {
                    verifyMfaCode: {
                        method: 'POST',
                        params: { action: 'verify' },
                    },
                    enableMfaAndSendCode: {
                        method: 'POST',
                        params: { action: 'enableMfaAndSendCode' },
                    },
                    resendMfaCode: {
                        method: 'POST',
                        params: { action: 'resendMfaCode' },
                    }
                }
            );

            var testRole = function (nextUrl) {
                var path = $location.path();
                if (nextUrl) {
                    if (nextUrl.indexOf('#') > 0) {
                        path = nextUrl.substr(nextUrl.indexOf('#') + 1);
                    } else {
                        var parser = document.createElement('a');
                        parser.href = nextUrl;
                        path = parser.pathname;
                        if (path.length > 0 && path[0] != '/') {
                            path = '/' + path;
                        }
                    }
                }
                if (path.indexOf('/events') >= 0) {
                    if (userData && userData.userGroup) {
                        if (isLoggedSupplier()) {
                            $location.url('/offers');
                            return false;
                        }
                        $rootScope.showPage = true;
                    }
                } else if (path.indexOf('/offers') >= 0) {
                    if (userData && userData.userGroup) {
                        if (!isLoggedSupplier()) {
                            $location.url('/events');
                            return false;
                        }
                        $rootScope.showPage = true;
                    }
                } else {
                    $rootScope.showPage = true;
                }
                return true;
            };

            var isLoggedSupplier = function () {
                if (userData && userData.userGroup) {
                    if (userData.userGroup.supplier) {
                        return userData.userGroup.supplier.id && userData.userGroup.supplier.id > 0;
                    }
                }
                return false;
            };

            var switchUserGroup = function (userGroupId, silent) {
                userResource.switchGroup({ userGroupId: userGroupId }, function (data) {
                    userData.userGroup = data;

                    var isSupplier = typeof userData.userGroup.supplier !== 'undefined';

                    $rootScope.$emit('event:user:action', {
                        action: 'user.switched.group',
                        data: {
                            Supplier: isSupplier,
                            'Group id': userData.userGroup.id,
                            'Group name': userData.userGroup.name,
                        },
                    });
                    if (silent) {
                        $rootScope.$broadcast(EventEnum.USER_GROUP_CHANGED_SILENT, data);
                    } else {
                        $rootScope.$broadcast(EventEnum.USER_GROUP_CHANGED, data);
                    }
                });
            };

            var setRefreshToken = function (token) {
                let _originalToken = atob(token); //convert base64 to normal string
                setSessionToken(_originalToken);
                $rootScope.setToken(_originalToken);
                $location.search('refresh', null);
            };

            return {
                login: function (username, password) {
                    var deferred = $q.defer();
                    var data = { username: username.toLowerCase(), password: password };
                    var post = $http.post('api/login', data, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    post.then(function (response) {
                        let data = response.data;
                        // setLocalToken(data.access_token);
                        $http
                            .get('login/getPostLoginData',{
                                headers: {
                                    'X-Auth-Token': data.access_token
                                }
                            })
                            .then((response) => {
                                let resp = response.data;
                                ClientService.setClientName(resp.clientName);
                                resp.access_token = data.access_token
                                deferred.resolve(resp);
                            }, (err) => {
                                console.log('getLoggedUser error: ' + err);
                            });
                    }, function (data) {
                        deferred.resolve(data);
                    });

                    return deferred.promise;
                },

                logout: function () {
                    let _authToken = getLocalToken() ? getLocalToken() :getLocalToken_temp();
                    // Check if user is already logged out
                    if (!_authToken) {
                        window.location.href = '/';
                        return;
                    }
                    var deferred = $q.defer();
                    var httpConfig = {
                        headers: {
                            'X-Auth-Token': _authToken,
                        },
                    };
                    $http.post('api/logout', {}, httpConfig).then(function () {

                        clearLocalToken();
                        $rootScope.$emit('event:user:action', {
                            action: 'user.logged.out',
                        });
                        setUserData(null);
                        deferred.resolve();
                    }, function (error) {
                        let _status = error.status;
                        if (_status === 401 || _status === 404) { // Not logged in case or already session expired case
                            clearLocalToken();
                            window.location.href = '/';
                        }
                    });
                    return deferred.promise;
                },

                isLogged: function () {
                    return userData && userData.userGroup && userData.userGroup.id > 0;
                },

                isLoggedSupplier: isLoggedSupplier,

                getUserData: function () {
                    return userData;
                },

                getUserRoles: function () {
                    return userRoles;
                },

                setRefreshToken: function (token) {
                    return setRefreshToken(token);
                },

                getLoggedUserStatus: function () {
                    var status = userData.status;
                    if (status != null) {
                        return status.name;
                    }
                },

                getClientBannerImageId: function () {
                    return userData.bannerImageId;
                },

                checkIfUserVerifiedAndAlert: function () {
                    const userIsVerified = this.isUserVerified();
                    if (!userIsVerified) {
                        OgModal.open('alert', {message: 'dialog.user.not.activated'});
                    }
                    return userIsVerified;
                },

                isUserVerified: function () {
                    return this.getLoggedUserStatus() === 'VERIFIED';
                },

                loadUserData: function () {
                    let headerConfig = getHttpConfig()
                    var deferred = $q.defer();
                    $http({
                        method: 'post',
                        url: 'login/getLoggedUser', headerConfig,
                    }).then(function (response) {
                        setUserData(response.data);
                        ClientService.setClientName(response.data.clientName);
                        deferred.resolve(response.data);
                    }, function (error) {
                        deferred.resolve(error);
                    });
                    return deferred.promise;
                },

                getUserGroups: function () {
                    return userGroups;
                },

                switchUserGroup: switchUserGroup,

                switchUserGroupPromise: function (userGroupId) {
                    var deferred = $q.defer();
                    userResource.switchGroup({ userGroupId: userGroupId }, (data) => {
                        userData.userGroup = data;
                        var isSupplier = typeof userData.userGroup.supplier !== 'undefined';
                        $rootScope.$emit('event:user:action', {
                            action: 'user.switched.group',
                            data: {
                                Supplier: isSupplier,
                                'Group id': userData.userGroup.id,
                                'Group name': userData.userGroup.name,
                            },
                        });
                        $rootScope.$broadcast(EventEnum.USER_GROUP_CHANGED_SILENT, data);
                        deferred.resolve(data);
                    });
                    return deferred.promise;
                },

                requestNewPassword: function (data, success, error) {
                    passwordResource.requestNewPassword({}, data, success, error);
                },

                changePassword: function (data, success, error) {
                    passwordResource.changePassword({}, data, success, error);
                },
                changeFirstTimePassword: function (data, success, error) {
                    passwordResource.changeFirstTimePassword({}, data, success, error);
                },
                verifyMfaCode: function (data, success, error) {
                    mfaResource.verifyMfaCode({}, data, success, error);
                },
                enableMfaAndSendCode: function (data, success, error) {
                    mfaResource.enableMfaAndSendCode({}, data, success, error);
                },
                resendMfaCode: function (data, success, error) {
                    mfaResource.resendMfaCode({}, data, success, error);
                },

                changeOwnerGroup: function (jsonObject, successCallback, errorCallback) {
                    return userEventResource.changeOwnerGroup(
                        {eventId: jsonObject.onboardingEventHashId},
                        jsonObject,
                        successCallback,
                        errorCallback
                    );
                },

                testRole: function (nextUrl) {
                    return testRole(nextUrl);
                },

                checkCreatorGroup: function (groupId) {
                    var selectedGroup;
                    var found = false;
                    angular.forEach(userGroups, function (group) {
                        if (!found) {
                            if (group.default) {
                                selectedGroup = group;
                            }
                            if (group.id == groupId) {
                                selectedGroup = group;
                                found = true;
                            }
                        }
                    });
                    if (selectedGroup && selectedGroup.id != userData.userGroup.id) {
                        switchUserGroup(selectedGroup.id, true);
                    }
                },

                checkSupplierGroup: function (supplierId) {
                    var selectedGroup;
                    angular.forEach(userGroups, function (group) {
                        if (group.supplier && group.supplier.id == supplierId) {
                            selectedGroup = group;
                        }
                    });
                    if (selectedGroup) {
                        if (selectedGroup.id != userData.userGroup.id) {
                            switchUserGroup(selectedGroup.id, true);
                        }
                    } else {
                        $location.url('/events');
                    }
                },

                home: function () {
                    if (isLoggedSupplier()) {
                        $location.url('/offers');
                    }
                    $location.url('/events');
                },

                updateUserData: function (data) {
                    setUserData(data);
                },
                updatePrefLang: function (prefLang) {
                    userData.prefLang = prefLang;
                },
                validate: function (isLoginPage) {
                    var deferred = $q.defer();
                    $http
                        .get('api/validate', getHttpConfig())
                        .then((response) => {
                            let resp = response.data;
                            var savedLocation = getParameterByName('location');
                            if (resp && resp.access_token) {
                                xdLocalStorage.setItem('accessToken', resp.access_token, (data) => {
                                });
                                setLocalToken(resp.access_token);
                                if (isLoginPage && savedLocation) {
                                    $location.url(savedLocation);
                                }
                            }
                            if (resp && resp.roles) {
                                setUserRoles(resp.roles);
                            }
                            if (this.isLogged()) {
                                deferred.resolve();
                            } else {
                                $http
                                    .get('login/getLoggedUser', getHttpConfig())
                                    .then((resp) => {
                                        setUserData(resp.data);
                                        ClientService.setClientName(resp.data.clientName);
                                        deferred.resolve(resp);
                                    }, (err) => {
                                        console.log('getLoggedUser error: ' + err);
                                    });
                            }
                        }, (err) => {
                            deferred.promise;
                            console.log('validate error: ' + err);
                        });
                    return deferred.promise;
                },

                checkIfUserIsLoggedInAndRedirectToEventsDashBoard: function () {
                    window.location.href = '/events/list';
                },

                checkIfUserIsLoggedInAndRedirectToOverviewDashBoard: function () {
                    window.location.href = '/events/overview';
                },

                checkIfUserIsLoggedInAndRedirectToOffersDashboard: function () {
                    window.location.href = '/offers';
                },
            };
        }
    ]);
