(function () {
    'use strict';

    angular.module('ogDatePickerPair').directive('ogDatePickerPair', [
        '$rootScope',
        'TimezoneService',
        '$translate',
        '$timeout',
        function ($rootScope, TimezoneService, $translate, $timeout) {
            var ONE_DAY_MILLISECONDS = 86400000;

            function getFromDate(tabIndex) {
                return jQuery('#fromDate' + tabIndex);
            }

            function getToDate(tabIndex) {
                return jQuery('#toDate' + tabIndex);
            }

            function setupFromDate(scope) {
                var fromDate = new Date(TimezoneService.toLocalTime(scope.fromDate));
                var toDate = new Date(TimezoneService.toLocalTime(scope.toDate));
                if (scope.fromDate >= 0 && scope.onchanged && typeof (scope.onchanged === 'function')) {
                    scope.onchanged(scope.modelNameFromDate, scope.fromDate);
                }
                $timeout(function () {
                    getFromDate(scope.tabIndex).datepicker('setDate', new Date(fromDate));
                });
            }

            function setupToDate(scope) {
                var toDate = new Date(TimezoneService.toLocalTime(scope.toDate));
                if (scope.toDate >= 0 && scope.onchanged && typeof (scope.onchanged === 'function')) {
                    scope.onchanged(scope.modelNameToDate, scope.toDate);
                }
                $timeout(function () {
                    getToDate(scope.tabIndex).datepicker('setDate', new Date(toDate));
                });
            }

            function isToDayAfterFromDay(scope) {
                return (
                    getToDate(scope.tabIndex).datepicker('getDate') -
                        getFromDate(scope.tabIndex).datepicker('getDate') >=
                    ONE_DAY_MILLISECONDS
                );
            }

            function updateDatePair(scope, timeDiffInMillis = 0, toDateChange = true) {
                scope.fromDate = TimezoneService.fromLocalTime(getFromDateTime(scope.tabIndex)).getTime();
                scope.toDate = TimezoneService.fromLocalTime(getToDateTime(scope.tabIndex)).getTime();

                if (!toDateChange) {
                    if (timeDiffInMillis > 0) {
                        scope.toDate =
                            TimezoneService.fromLocalTime(getFromDateTime(scope.tabIndex)).getTime() + timeDiffInMillis;
                    } else {
                        scope.toDate = TimezoneService.fromLocalTime(getFromDateTime(scope.tabIndex)).getTime();
                    }
                }
                $rootScope.safeApply(scope);
            }

            function getFromDateTime(tabIndex) {
                var fromDate = getFromDate(tabIndex).datepicker('getDate');
                return fromDate;
            }

            function getToDateTime(tabIndex) {
                var toDate = getToDate(tabIndex).datepicker('getDate');
                return toDate;
            }

            return {
                scope: {
                    fromDate: '=',
                    toDate: '=',
                    fromLabel: '=',
                    toLabel: '=',
                    showIcon: '=',
                    disableField: '=',
                    onchanged: '=onchanged',
                    syncDates: '=',
                    modelNameToDate: '@',
                    modelNameFromDate: '@',
                    timeDiffInMillis: '=',
                    showYearMonthOption: '=showYearMonthOption',
                },
                transclude: true,
                priority: 10,
                templateUrl: './app/components/directives/date_picker_pair/og_date_picker_pair.view.html',
                link: function (scope) {
                    scope.tabIndex = (Math.random() * 6).toString().replace(/\./g, '_');

                    //Watcher for the fromDate
                    scope.$watch('fromDate', function (val) {
                        if (typeof val !== 'undefined' && !val) {
                            scope.fromDate = 0;
                        }
                        setupFromDate(scope);
                    });

                    //Watcher for the toDate
                    scope.$watch('toDate', function (val) {
                        if (typeof val !== 'undefined' && !val) {
                            scope.toDate = parseInt(ONE_DAY_MILLISECONDS / 24);
                        }
                        setupToDate(scope);
                    });

                    //Initialize the to date pickers
                    $timeout(function () {
                        jQuery('#toDate' + scope.tabIndex).datetimepicker({
                            dateFormat: getDateFormat(),
                            showButtonPanel: false,
                            beforeShow: function (input, inst) {
                                let _isModal = jQuery('.modal-backdrop').length;
                                if(_isModal) {
                                    $timeout(()=> {
                                        let _offestTop = $("#" + inst.id).offset().top - $(window).scrollTop() + $("#" + inst.id)[0].getBoundingClientRect().height;
                                        inst.dpDiv.css({
                                            'top': _offestTop
                                        })
                                    })
                                }
                            },
                            onSelect: function (dateText) {
                                validateAndUpdateToDate(dateText);
                                jQuery(this).datepicker('hide');
                            },
                            onClose: function (dateText) {
                                if (dateText) {
                                    validateAndUpdateToDate(dateText);
                                } else {
                                    // Check if date is selected empty, if yes add todate to current fromdate with one hour
                                    var oneHr = parseInt(ONE_DAY_MILLISECONDS / 24);
                                    scope.toDate = scope.fromDate ? +scope.fromDate + oneHr : oneHr;
                                    setupToDate(scope);
                                }
                            },
                        });
                    });

                    //Initialize the from date pickers
                    $timeout(function () {
                        jQuery('#fromDate' + scope.tabIndex).datetimepicker({
                            dateFormat: getDateFormat(),
                            showButtonPanel: false,
                            beforeShow: function (input, inst) {
                                let _isModal = jQuery('.modal-backdrop').length;
                                if(_isModal) {
                                    $timeout(()=> {
                                        let _offestTop = $("#" + inst.id).offset().top - $(window).scrollTop() + $("#" + inst.id)[0].getBoundingClientRect().height;
                                        inst.dpDiv.css({
                                            'top': _offestTop
                                        })
                                    })
                                }
                            },
                            onSelect: function (dateText) {
                                validateAndUpdateFromDate(dateText);
                                jQuery(this).datepicker('hide');
                            },
                            onClose: function (dateText) {
                                // onClose is called when the datepicker is closed,
                                // used when user inputs date-time manually
                                if (dateText) {
                                    validateAndUpdateFromDate(dateText);
                                } else {
                                    // Check if date is selected empty, if yes add fromDate to 0
                                    scope.fromDate = 0;
                                    setupFromDate(scope);
                                }
                            },
                        });
                    });

                    function validateAndUpdateFromDate(dateText) {
                        if (!isToDayAfterFromDay(scope)) {
                            getToDate(scope.tabIndex).datepicker('setDate', dateText);
                            // validateTimePairForSameDay(scope);
                        }
                        updateDatePair(scope, scope.timeDiffInMillis, false);
                    }

                    function validateAndUpdateToDate(dateText) {
                        if (!isToDayAfterFromDay(scope)) {
                            getFromDate(scope.tabIndex).datepicker('setDate', dateText);
                        }
                        updateDatePair(scope);
                    }

                    function getDateFormat() {
                        return $rootScope.langSelected == 'sv_SV' ? 'yy-mm-dd' : 'dd/mm/yy';
                    }

                    function setFormats(lang, tabIndex) {
                        setDatePickerFormat(lang, tabIndex);
                    }

                    function setDatePickerFormat(lang, tabIndex) {
                        $timeout(function () {
                            if (lang == 'en_US') {
                                getFromDate(tabIndex).datepicker('option', 'dateFormat', 'mm/dd/yy');
                                getToDate(tabIndex).datepicker('option', 'dateFormat', 'mm/dd/yy');
                            } else {
                                getFromDate(tabIndex).datepicker('option', 'dateFormat', 'yy-mm-dd');
                                getToDate(tabIndex).datepicker('option', 'dateFormat', 'yy-mm-dd');
                            }
                        });
                    }

                    //Trigger datetime format change on language change
                    scope.$on('lang:change', function (event, data) {
                        setFormats(data.lang);
                        $timeout(function () {
                            setupFromDate(scope);
                            setupToDate(scope);
                            // setupDate(scope);
                        });
                    });

                    jQuery('.icon-calendar').on('click', function () {
                        jQuery(this).siblings('input').datepicker('show');
                    });
                },
            };
        },
    ]);
})();
