export default function (appModule) {
    appModule.config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('offers', {
                    url: '/offers?showPresentation',
                    template: '<offers></offers>',
                    params: {showPresentation: null},
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('offer', {
                    url: '/offer',
                    abstract: true, // has children view
                    template: '<og-header-directive></og-header-directive><ui-view><ui-view>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('offer.detail', {
                    url: '/:offerId',
                    template: '<offer-detail></offer-detail>'
                })
                .state('offer.detail.message', {
                    url: '/message?msgId&&openComposeMessageBox',
                    template: '<supplier-messages></supplier-messages>',
                    resolve: {
                        currentTab: [
                            '$stateParams',
                            '$state',
                            'ActiveTabService',
                            function ($stateParams, $state, ActiveTabService) {
                                ActiveTabService.setOfferDetailTab('messages');
                            },
                        ],
                    },
                })
                .state('offer.detail.final', {
                    url: '/finaldetails',
                    template: '<final-details></final-details>',
                    resolve: {
                        currentTab: [
                            'ActiveTabService',
                            function (ActiveTabService) {
                                ActiveTabService.setOfferDetailTab('final_details');
                            },
                        ],
                    },
                })
                .state('offer.thanks', {
                    url: '/:offerId/thanks',
                    template: '<thank-you-for-confirmation></thank-you-for-confirmation>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('offer.confirmation', {
                    url: '/:offerId/confirm',
                    template: '<offer-booking-confirm> </offer-booking-confirm>',
                    params: {eventId: null, requestId: null, offer: null, billingAddress: null},
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                });
        },
    ]);
}
