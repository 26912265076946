import _ from 'underscore';

const EXCLUDE_PARTS_BY_OFFER_AND_PARTS_STATUS = {
    ANSWERED: ['DELETED_BY_CREATOR'],
};
const EXCLUDE_PARTS_BY_PART_STATUS = ['NOT_DELIVERABLE'];

class RequestOfferPartsUtil {
    constructor() {
    }

    filterOfferPartsForCreator(offer, offerParts) {
        let _offerStatus;
        if (offer) {
            _offerStatus = offer.status.name;
        }
        let _filteredOfferParts = [];
        if (offerParts && offerParts.length > 0) {
            offerParts.forEach((offerPart) => {
                if (this._isEligibleForCreator(_offerStatus, offerPart.status.name)) {
                    _filteredOfferParts.push(offerPart);
                }
            });
        }
        return _filteredOfferParts;
    }

    _isEligibleForCreator(offerStatus, partStatus) {
        if (EXCLUDE_PARTS_BY_PART_STATUS.indexOf(partStatus) > -1) {
            return false;
        }
        let _PART_STATUS_TO_EXCLUDE_FOR_THIS_OFFER = EXCLUDE_PARTS_BY_OFFER_AND_PARTS_STATUS[offerStatus];
        if (_PART_STATUS_TO_EXCLUDE_FOR_THIS_OFFER) {
            return _PART_STATUS_TO_EXCLUDE_FOR_THIS_OFFER.indexOf(partStatus) < 0;
        }
        return true;
    }

    filterOfferPartsForDecisionMaker(selectedDecisionMakerOfferParts, offerParts) {
        let _filteredOfferParts = [];
        offerParts.forEach((offerPart) => {
            let _selectedOfferPart = _.find(selectedDecisionMakerOfferParts, (selectedDecisionMakerOfferPart) => {
                return selectedDecisionMakerOfferPart.requestOfferPartId === offerPart.id;
            });
            if (_selectedOfferPart) {
                _filteredOfferParts.push(offerPart);
            }
        });
        return _filteredOfferParts;
    }
}

const requestOfferPartsUtil = new RequestOfferPartsUtil();
export default requestOfferPartsUtil;
