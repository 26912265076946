import controller from './og_request_category_icon.controller.js';
(function () {
    'use strict';
    angular.module('ogRequestCategoryIcon').directive('ogRequestCategoryIcon', [
        function () {
            return {
                scope: {
                    request: '=',
                    height: '=',
                },
                templateUrl: './app/components/directives/request_category_icon/og_request_category_icon.html',
                controller,
                controllerAs: 'OgRequestCategoryIconCtrl',
                bindToController: true,
            };
        },
    ]);
})();
