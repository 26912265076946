import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

(function () {
    /* global angular */
    'use strict';
    angular.module('ogManagersEventCalendar').directive('ogManagersEventCalendar', [
        '$rootScope',
        '$sce',
        '$timeout',
        '$compile',
        '$translate',
        'uiCalendarConfig',
        'EventEnum',
        'GlobalConstants',
        'BrowserDetectService',
        'SupplierResourceAvailability',
        'DateService',
        'TimezoneService',
        '$templateCache',
        'Event',
        function (
            $rootScope,
            $sce,
            $timeout,
            $compile,
            $translate,
            uiCalendarConfig,
            EventEnum,
            GlobalConstants,
            BrowserDetectService,
            SupplierResourceAvailability,
            DateService,
            TimezoneService,
            $templateCache,
            Event
        ) {
            var getPopoverTemplate = function () {
                return $templateCache.get(
                    'event_details_popover.html'
                );
            };
            return {
                restrict: 'EA',
                templateUrl:
                    './app/components/directives/managers_events_calendar/og_managers_event_calendar.view.html',
                replace: true,
                scope: {
                    groupId: '=',
                    selectedStatus: '=',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.data = [];
                        ScopeFunctions.addToScope($scope);
                        $scope.calendarDate = angular.copy(new Date());
                        $scope.events = [];
                        $scope.eventSources = [$scope.events];

                        initFn();

                        $scope.openPopOver = function (data) {
                            let _hasPopover = jQuery('.popover');
                            if (_hasPopover.length) {
                                jQuery('.popover').popover('hide');
                            }
                            $timeout(() => {
                                $scope.selectedEventDetails = {};
                                angular.forEach($scope.groupManagersEventsForMonth, (event) => {
                                    if (event.id === data.eventId) {
                                        $scope.selectedEventDetails = event;
                                    }
                                });
                            }, 200);
                        };

                        $scope.getEventJourneyStatus = function (statusName) {
                            switch (statusName) {
                                case 'CREATED':
                                    return $translate.instant('event.journey.status.CREATED');
                                    break;
                                case 'SENT':
                                    return $translate.instant('event.journey.status.SENT');
                                    break;
                                case 'FIRST_OFFER_RECEIVED':
                                    return $translate.instant('event.journey.status.FIRST_OFFER_RECEIVED');
                                    break;
                                case 'FIFTY_PERCENT_OFFER_RECEIVED':
                                    return $translate.instant('event.journey.status.FIFTY_PERCENT_OFFER_RECEIVED');
                                    break;
                                case 'FIRST_OFFER_BOOKED':
                                    return $translate.instant('event.journey.status.FIRST_OFFER_BOOKED');
                                    break;
                                case 'ALL_BOOKED':
                                    return $translate.instant('event.journey.status.ALL_BOOKED');
                                    break;
                                case 'ALL_CANCELLED':
                                    return $translate.instant('event.journey.status.ALL_CANCELLED');
                                    break;
                                case 'ALL_OFFER_RECEIVED':
                                    return $translate.instant('event.journey.status.ALL_OFFER_RECEIVED');
                                    break;
                                case 'FINISHED':
                                    return $translate.instant('event.journey.status.FINISHED');
                                    break;
                                case 'COMPLETED':
                                    return $translate.instant('event.journey.status.COMPLETED');
                                    break;
                                default:
                                    return statusName;
                                    break;
                            }
                        };

                        function initFn() {
                            $scope.data.fromDate = new Date().getTime();
                            $scope.data.toDate = new Date().getTime();
                        }

                        $scope.getGroupManagersEventsForMonth = function () {
                            let _params = {
                                status: $scope.selectedStatus,
                                managersView: true,
                                groupId: $scope.groupId,
                                calendarDate: $scope.calendarDate.getTime(),
                            };
                            if ($scope.events.length > 0) {
                                $timeout(() => {
                                    $scope.events.splice(0, $scope.events.length);
                                });
                            }
                            $scope.startProgress('resource-calendar');
                            Event.findAllEventsForManager(
                                _params,
                                (response) => {
                                    $scope.groupManagersEventsForMonth = response.list;
                                    $scope.endProgress('resource-calendar');

                                    if ($scope.groupManagersEventsForMonth.length > 0) {
                                        angular.forEach($scope.groupManagersEventsForMonth, (event) => {
                                            $scope.events.push({
                                                eventId: event.id,
                                                title: event.name,
                                                isMultipleDay: true,
                                                start: parseDateInLocaleFormatFn(event.fromDate),
                                                end: parseDateInLocaleFormatFn(event.toDate),
                                            });
                                        });
                                    }
                                },
                                $scope.endWithErrorOverlay('resource-calendar')
                            );
                        };

                        $scope.changeCurrentResource = function (resource, index) {
                            $scope.selectedResource = index;
                            if ($scope.currentResource.id != resource.id) {
                                $scope.currentResource = resource;
                                $scope.getSupplierResourceAvailabilityForMonth();
                            }
                        };

                        $scope.setPriceAndDateRange = function (val) {
                            $scope.showPriceAndDateRangeField = val;
                        };

                        function parseDateInLocaleFormatFn(date) {
                            return TimezoneService.formatDate(new Date(parseInt(date)), 'YYYY-MM-DD HH');
                        }

                        function checkTimeRangeOverlaps(a_start, a_end, b_start, b_end) {
                            if (a_start < b_start && b_start < a_end) return true; // b starts in a
                            if (a_start < b_end && b_end < a_end) return true; // b ends in a
                            if (b_start < a_start && a_end < b_end) return true; // a in b
                            return false;
                        }

                        $scope.viewRender = function (view) {
                            let _calendarDateYear = $scope.calendarDate.getFullYear();
                            let _calendarDateMonth = $scope.calendarDate.getMonth() + 1;
                            let _resourceDateYear = view.intervalStart.toDate().getFullYear();
                            let _resourceDateMonth = view.intervalStart.toDate().getMonth() + 1;
                            if (_calendarDateYear !== _resourceDateYear || _calendarDateMonth !== _resourceDateMonth) {
                                $scope.calendarDate = view.intervalStart.toDate();
                            }
                        };

                        $scope.$watch('calendarDate', (newVal, oldVal) => {
                            if (oldVal || (!oldVal && oldVal !== newVal)) {
                                $timeout(() => {
                                    uiCalendarConfig.calendars.resourceCalendar.fullCalendar('gotoDate', newVal);
                                    $scope.data.fromDate = $scope.calendarDate.getTime();
                                    $scope.data.toDate = $scope.calendarDate.getTime();
                                    $scope.getGroupManagersEventsForMonth();
                                });
                            }
                        });
                        $scope.$watch('selectedStatus', function (event, value) {
                            if (event !== value) {
                                $scope.getGroupManagersEventsForMonth();
                            }
                        });

                        $scope.$watch('groupId', function (event, value) {
                            if (event !== value) {
                                $scope.getGroupManagersEventsForMonth();
                            }
                        });

                        $scope.extraEventSignature = function (event) {
                            return [event.color, event.test];
                        };
                    },
                ],

                link: function ($scope, element) {
                    ScopeFunctions.addToScope($scope);

                    let popoverTemplate =
                        '<div class="popover event-summary-popover" role="tooltip" style="min-width: 280px; max-width: 380px;""><h3 class="popover-title text-right"></h3><div class="popover-content"></div></div>';

                    function popoverContent() {
                        var html = getPopoverTemplate();
                        var popOverContent = $compile(html)($scope);
                        return popOverContent;
                    }

                    function eventRender(event, element) {
                        element.find('div.fc-content').html(event.title);
                        element.popover({
                            title: '<i id="closePopover" class="fa fa-times-circle f-14 pointer" aria-hidden="true"></i>',
                            content: popoverContent,
                            template: popoverTemplate,
                            delay: { show: 200, hide: 0 },
                            html: true,
                            trigger: 'click',
                            placement: 'top',
                            animation: true,
                            container: 'body',
                            fallbackPlacement: ['left', 'top'],
                        });
                    }

                    $(document).on('click', '#closePopover', function () {
                        $('.popover').popover('hide');
                    });

                    $(document).on('click', '.event-content-link', function () {
                        $('.popover').popover('destroy');
                    });

                    $scope.uiConfig = {
                        // contentHeight: 'auto',
                        handleWindowResize: true,
                        defaultView: 'month',
                        fixedWeekCount: false,
                        themeSystem: 'bootstrap3',
                        eventRender: eventRender,
                        viewRender: $scope.viewRender,
                        eventOrder: 'displayOrder',
                        eventClick: $scope.openPopOver,
                        eventColor: 'transparent',
                        eventBackgroundColor: '#fff',
                        eventBorderColor: '#e0e0e0',
                        eventTextColor: 'black',
                        customRender: true,
                        nextDayThreshold: '00:00:00',
                        displayEventTime: false,
                        eventLimit: true,
                        firstDay: '1',
                        events: [],
                    };
                },
            };
        },
    ]);
})();
