'use strict';
import {ScopeFunctions} from '../../../../../utils/global/scopeFunctions';

angular.module('supplier.offer.confirm').component('offerBookingConfirm', {
    templateUrl: './app/components/modules/supplier/offer/confirm/offer.booking.confirm.view.html',
    controller: [
        '$scope', 'Page', 'NotificationHandleService', '$stateParams', '$state', 'ClientService', 'CommonFunctions',

        function OfferBookingConfirm($scope, Page, NotificationHandleService, $stateParams, $state, ClientService, CommonFunctions) {
            var vm = this;
            vm.offerId = $stateParams.offerId;

            ScopeFunctions.addToScope($scope);

            vm.confirmBookedOffer = confirmBookedOfferFn;
            vm.changeRequestOfferStatus = changeRequestOfferStatusFn;
            vm.init = initFn;

            function confirmBookedOfferFn() {
                if (!vm.confirmedBookedOffer.$valid) {
                    CommonFunctions.touchFormFields(vm.confirmedBookedOffer);
                    return;
                }

                if (hasBillingAddressChanged()) {
                    vm.showShouldUpdateAddressForSupplier = true;
                } else {
                    vm.changeRequestOfferStatus(false);
                }
            }

            var redirect2Thanks = function redirect2ThankYouPage() {
                $state.go('offer.thanks', {offerId: vm.offer.id});
            };

            var redirect2OfferDetails = function redirect2OfferDetails() {
                $state.go('offer.detail', {offerId: vm.offerId});
            };

            function initFn() {
                $scope.startProgress();
                Page.confirmationPage(
                    {offerId: vm.offerId},
                    function (res) {
                        vm.requestId = res.requestId;
                        vm.eventId = res.eventId;
                        vm.supplierName = res.supplierName;
                        vm.supplierBillingAddress = res.billingInfo;
                        vm.originalSupplierBillingAddress = angular.copy(vm.supplierBillingAddress);
                        vm.offer = res.offer;
                        $scope.endProgress();
                    },
                    function (name, errorDetails) {
                        $scope.endWithAlertAndCallBack(name, errorDetails, redirect2OfferDetails);
                    }
                );
                vm.clientName = ClientService.getClientDisplayName();
            }

            function hasBillingAddressChanged() {
                if (!vm.originalSupplierBillingAddress) {
                    return true;
                }
                return (
                    vm.supplierBillingAddress.email != vm.originalSupplierBillingAddress.email ||
                    vm.supplierBillingAddress.zipCode != vm.originalSupplierBillingAddress.zipCode ||
                    vm.supplierBillingAddress.street != vm.originalSupplierBillingAddress.street ||
                    vm.supplierBillingAddress.company != vm.originalSupplierBillingAddress.company ||
                    vm.supplierBillingAddress.number != vm.originalSupplierBillingAddress.number ||
                    vm.supplierBillingAddress.town != vm.originalSupplierBillingAddress.town ||
                    vm.supplierBillingAddress.marking != vm.originalSupplierBillingAddress.marking ||
                    vm.supplierBillingAddress.reference != vm.originalSupplierBillingAddress.reference
                );
            }

            function changeRequestOfferStatusFn(shouldUpdateSupplierAddress) {
                NotificationHandleService.changeRequestOfferStatus(
                    vm.eventId,
                    vm.requestId,
                    vm.offer,
                    'BOOKED',
                    $scope,
                    undefined,
                    undefined,
                    redirect2Thanks,
                    vm.supplierBillingAddress,
                    shouldUpdateSupplierAddress
                );
            }

            vm.init();
        }],
    controllerAs: 'bookingConfirmCtrl'
})
