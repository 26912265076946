'use strict';
angular.module('core.survey').service('Survey', [
    '$resource',
    function ($resource) {
        var v1SurveyRest = $resource(
            'api/v1/survey/:identifier',
            {surveyId: '@identifier'},
            {
                update: {method: 'PUT'},
            }
        );
        var v1SurveyParticipantRest = $resource(
            'api/v1/survey/participant/:hash',
            {hash: '@hash'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1SurveyCreatorRest = $resource(
            'api/v1/survey/creator/event/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1SurveySupplierRest = $resource(
            'api/v1/survey/supplier/:id',
            {id: '@id'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1SurveyOfferRest = $resource(
            'api/v1/survey/offer/:id',
            {id: '@id'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1SurveyEventCreatedRest = $resource(
            'api/v1/survey/hasSurveyBeenCreated/:eventId',
            {eventId: '@eventId'},
            {
                check: {method: 'GET', isArray: false},
            }
        );
        var v1SurveyConfirmRest = $resource(
            'api/v1/survey/confirm',
            {},
            {
                confirm: {method: 'POST', isArray: true},
            }
        );
        var v1SurveyEventSummaryRest = $resource(
            'api/v1/survey/event/summary/:id',
            {id: '@id'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1SurveysForParticipantsRest = $resource(
            'api/v1/survey/participants/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var v1SurveysForCreatorsRest = $resource(
            'api/v1/survey/creators/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var v1SurveysForAUserRest = $resource(
            'api/v1/survey/allSurveysForAUser',
            {},
            {
                update: {method: 'PUT', isArray: false},
            }
        );
        var getEventDetails = $resource(
            'api/v1/survey/getEventDetails/:hash',
            {hash: '@hash'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            forParticipant: function (jsonObject, successCallback, errorCallback) {
                return v1SurveyParticipantRest.list(jsonObject, successCallback, errorCallback);
            },
            forCreator: function (jsonObject, successCallback, errorCallback) {
                return v1SurveyCreatorRest.list(jsonObject, successCallback, errorCallback);
            },
            forSupplier: function (jsonObject, successCallback, errorCallback) {
                return v1SurveySupplierRest.list(jsonObject, successCallback, errorCallback);
            },
            forOffer: function (jsonObject, successCallback, errorCallback) {
                return v1SurveyOfferRest.list(jsonObject, successCallback, errorCallback);
            },
            hasSurveyBeenCreated: function (jsonObject, successCallback, errorCallback) {
                return v1SurveyEventCreatedRest.check(jsonObject, successCallback, errorCallback);
            },
            update: function (jsonObject, successCallback, errorCallback) {
                return v1SurveyRest.update(
                    {identifier: jsonObject.identifier},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            confirmSurvey: function (jsonObject, successCallback, errorCallback) {
                return v1SurveyConfirmRest.confirm({}, jsonObject, successCallback, errorCallback);
            },
            eventSummary: function (jsonObject, successCallback, errorCallback) {
                return v1SurveyEventSummaryRest.list(jsonObject, successCallback, errorCallback);
            },
            surveysForParticipants: function (jsonObject, successCallback, errorCallback) {
                return v1SurveysForParticipantsRest.get(jsonObject, successCallback, errorCallback);
            },
            surveysForCreators: function (jsonObject, successCallback, errorCallback) {
                return v1SurveysForCreatorsRest.get(jsonObject, successCallback, errorCallback);
            },
            updateAllSurveysForAUser: function (jsonObject, successCallback, errorCallback) {
                return v1SurveysForAUserRest.update(jsonObject, successCallback, errorCallback);
            },
            getEventDetails: function (hash, successCallback, errorCallback) {
                return getEventDetails.get({hash: hash}, successCallback, errorCallback);
            },
        };
    },
]);
