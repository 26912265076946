(function () {
    'use strict';

    angular.module('ogOfferStatus').directive('ogOfferStatus', [
        '$compile',
        function ($compile) {
            return {
                restrict: 'AE',
                replace: true,
                scope: {
                    userRole: '=ogUserRole',
                    status: '=ogOfferStatus',
                    prefix: '=?ogOfferStatusPrefix',
                },
                link: function (scope, element) {
                    scope.$watch('status', function (status) {
                        var prefix = scope.prefix || '';
                        if (status) {
                            var statusText = status;
                            if (scope.userRole == 'supplier') {
                                if (status == 'SENT') {
                                    statusText = 'REQUEST';
                                } else if (status == 'MANUAL') {
                                    statusText = 'MANUAL_SUPPLIER';
                                } else if (status == 'MANUALLY_BOOKED') {
                                    statusText = 'MANUALLY_BOOKED_SUPPLIER';
                                }
                            }
                            jQuery(element).html(
                                $compile(
                                    "<span class='btn offerstatus " +
                                        status +
                                        "'>" +
                                        prefix +
                                        "{{ '" +
                                        statusText +
                                        "' | translate }}</span>"
                                )(scope)
                            );
                        }
                    });
                },
            };
        },
    ]);
})();
