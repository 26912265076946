import amountAndPriceService from '../../../utils/price_calculate/PriceCalculationSupplierUtil';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

const EDITED_BY_SUPPLIER_PART_OPTION_STATE = ['EDITED_BY_SUPPLIER', 'ADDED_BY_SUPPLIER'];
const SHOW_SUPPLIER_PRICE_STATE = ['SENT', 'EDITED_BY_CREATOR'];

(function () {
    'use strict';

    angular.module('ogRequestOfferPartOptionsSupplier').directive('ogRequestOfferPartOptionsSupplier', [
        '$log',
        '$routeParams',
        '$rootScope',
        '$timeout',
        'ModalService',
        'RequestOfferPartOption',
        'GlobalConstants',
        'RequestOfferStatusUtils',
        'CommonFunctions',
        function (
            $log,
            $routeParams,
            $rootScope,
            $timeout,
            ModalService,
            RequestOfferPartOption,
            GlobalConstants,
            RequestOfferStatusUtils,
            CommonFunctions
        ) {
            return {
                restrict: 'EA',
                scope: {
                    event: '=',
                    supplierId: '=',
                    offer: '=',
                    layout: '=',
                    offerPart: '=',
                    includeVat: '=',
                    selectedCurrency: '=',
                    offerPartOptionsWithActions: '=',
                    offerPartOptionsWithActionsCallback: '=',
                    showDiffCol: '=',
                    isOfferInFinishedToApprovedStates: '=',
                    disablePaidOnsiteEdit: '=',
                },
                templateUrl:
                    './app/components/directives/request_offer_part_options_supplier/request_offer_part_options_supplier.directive.view.html',
                controller: [
                    '$scope',
                    function ($scope) {
                        ScopeFunctions.addToScope($scope);

                        $scope.init = function () {
                            if ($scope.offerPart && $scope.offerPart.id) {
                                $scope.startProgress();
                                $scope.finalPriceAndAmountEdit = CommonFunctions.hasIntersectValue(
                                    [$scope.offer.status.name],
                                    ['FINISHED', 'FINISHED_WAITING_FOR_SUPPLIER', 'CANCELED_AND_FINISHED']
                                );
                                if (
                                    $scope.offer.status.name === 'BOOKED_WAITING_FOR_SUPPLIER' &&
                                    $scope.offerPartOptionsWithActions &&
                                    $scope.offerPartOptionsWithActions.length > 0
                                ) {
                                    $scope.allOptionsForPart = [];
                                    let _addedOfferPartOptions = _.filter(
                                        $scope.offerPartOptionsWithActions,
                                        (opowa) => {
                                            return (
                                                opowa.offerPartOption.requestOfferPartId === $scope.offerPart.id &&
                                                opowa.action === 'ADDED_BY_SUPPLIER'
                                            );
                                        }
                                    );
                                    if (_addedOfferPartOptions && _addedOfferPartOptions.length > 0) {
                                        angular.forEach(_addedOfferPartOptions, (_aopo) => {
                                            $scope.allOptionsForPart.push(_aopo.offerPartOption);
                                        });
                                        $scope.originalOfferPartOptionsList = angular.copy($scope.allOptionsForPart);
                                    }
                                    $scope.endProgress();
                                } else {
                                    RequestOfferPartOption.getAllOptionsForPart(
                                        $scope.offerPart.id,
                                        (resp) => {
                                            $scope.allOptionsForPart = resp;
                                            $scope.originalOfferPartOptionsList = angular.copy(resp);
                                            $scope.setPartOptionNewPrice();
                                            $scope.setFinalPriceAndAmount();
                                            if (
                                                $scope.allOptionsForPart.length < 1 &&
                                                RequestOfferStatusUtils.OFFER_IN_SUPPLIER_EDIT_DISABLED_STATE.indexOf(
                                                    $scope.offer.status.name
                                                ) === -1
                                            ) {
                                                if (
                                                    RequestOfferStatusUtils.ALL_CANCELED_FINISHED_STATUS.indexOf(
                                                        $scope.offer.status.name
                                                    ) > -1 ||
                                                    $scope.offer.status.name === 'DECLINED'
                                                ) {
                                                    $scope.ROPOAddEditId = 0;
                                                } else {
                                                    $scope.ROPOAddEditId = 'a' + $scope.offerPart.id;
                                                }
                                            } else {
                                                $scope.ROPOAddEditId = 0;
                                            }
                                            $scope.endProgress();
                                        },
                                        $scope.endWithErrorOverlay()
                                    );
                                }
                            } else {
                                $scope.offerPart.id = Math.random();
                                $scope.ROPOAddEditId = 'a' + $scope.offerPart.id;
                                $scope.allOptionsForPart = [];
                            }
                        };

                        $scope.openInlineRowOptionAddition = function (offerPartId) {
                            // $rootScope.ROPOAddEditId = 0;
                            $timeout(function () {
                                $scope.ROPOAddEditId = 'a' + offerPartId;
                            }, 100);
                        };

                        $scope.isAddAnotherOptionDisabled = function (offerPartId) {
                            return (
                                $scope.ROPOAddEditId === 'a' + offerPartId || $scope.offer.status.name === 'DECLINED'
                            );
                        };

                        $scope.addANewOfferPartOption = function (requestOfferPartOption) {
                            $scope.startProgress();
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
                                $scope.ROPOAddEditId = 0;
                                requestOfferPartOption.status = { name: 'ADDED_BY_SUPPLIER' };
                                requestOfferPartOption.newPrice = requestOfferPartOption.price;
                                if (
                                    $scope.includeVat &&
                                    $scope.offer.vatIncludedInPrice &&
                                    requestOfferPartOption.price
                                ) {
                                    requestOfferPartOption.priceInclVat =
                                        amountAndPriceService.calculatePriceFromPriceAndVat(
                                            requestOfferPartOption.price,
                                            requestOfferPartOption.vat
                                        );
                                    requestOfferPartOption.newPriceInclVat =
                                        amountAndPriceService.calculatePriceFromPriceAndVat(
                                            requestOfferPartOption.price,
                                            requestOfferPartOption.vat
                                        );
                                }
                                requestOfferPartOption.deletable = true;
                                requestOfferPartOption.isSelected = true;
                                requestOfferPartOption.referenceId = Math.random();
                                let offerPartOptionMap = {
                                    offerPartOption: requestOfferPartOption,
                                    action: 'ADDED_BY_SUPPLIER',
                                };
                                $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);
                                $scope.allOptionsForPart.push(requestOfferPartOption);
                                $scope.originalOfferPartOptionsList = angular.copy($scope.allOptionsForPart);
                                $rootScope.$broadcast('part:option:added:updated:in:client:side:only', {
                                    requestOfferPart: $scope.offerPart,
                                    allOptionsForPart: $scope.allOptionsForPart,
                                });
                                if ($scope.offerPart.status.name === 'ADDED_WAITING_FOR_SUPPLIER') {
                                    $rootScope.$broadcast('part:option:added:in:client:side:only', {
                                        requestOfferPart: $scope.offerPart,
                                    });
                                }
                                $scope.endProgress();
                            } else {
                                RequestOfferPartOption.create(
                                    $scope.supplierId,
                                    requestOfferPartOption,
                                    (resp) => {
                                        $scope.ROPOAddEditId = 0;
                                        $scope.allOptionsForPart = resp;
                                        $scope.setPartOptionNewPrice();
                                        $scope.setFinalPriceAndAmount();
                                        $scope.offerPart = resp[0].requestOfferPart;
                                        $scope.originalOfferPartOptionsList = angular.copy(resp);
                                        $rootScope.$broadcast('part:option:added:with:is:selected:radio:value', {
                                            requestOfferPartId: $scope.offerPart.id,
                                            offerPartOptions: $scope.allOptionsForPart,
                                        });
                                        $scope.endProgress();
                                    },
                                    $scope.endWithErrorOverlay()
                                );
                            }
                        };

                        $scope.updateANewOfferPartOption = function (requestOfferPartOption) {
                            $scope.startProgress();
                            let _data = {
                                requestOfferPartOptionId: requestOfferPartOption.id,
                                supplierId: $scope.supplierId,
                            };
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
                                $scope.updateOfferPartOptionOnClientSideOnly(requestOfferPartOption);
                                $rootScope.$broadcast('part:option:added:updated:in:client:side:only', {
                                    requestOfferPart: $scope.offerPart,
                                    allOptionsForPart: $scope.allOptionsForPart,
                                });
                                $scope.endProgress();
                            } else {
                                if (requestOfferPartOption && requestOfferPartOption.newPrice) {
                                    requestOfferPartOption['price'] = requestOfferPartOption.newPrice;
                                }
                                RequestOfferPartOption.update(
                                    _data,
                                    requestOfferPartOption,
                                    (resp) => {
                                        if (resp.requestOfferPartOption.isSelected) {
                                            $rootScope.$broadcast('part:option:updated:with:is:selected:radio:value', {
                                                requestOfferPartId: $scope.offerPart.id,
                                                requestOfferPartOption: resp.requestOfferPartOption,
                                            });
                                        }
                                        if (resp) {
                                            $scope.offer.status.name = resp.requestOffer.status.name;
                                            $scope.init();
                                            $rootScope.$broadcast('request:offer:part:option:updated:by:supplier', {
                                                requestOfferPartOption: resp.requestOfferPartOption,
                                                requestOffer: resp.requestOffer,
                                                requestOfferPartId: $scope.offerPart.id,
                                            });
                                        }
                                        $scope.endProgress();
                                    },
                                    $scope.endWithErrorOverlay()
                                );
                            }
                        };

                        $scope.updateOfferPartOptionOnClientSideOnly = function (offerPartOption) {
                            let originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                            if (!originalOfferPartOption) {
                                $scope.originalOfferPartOptionsList.forEach((n) => {
                                    if (offerPartOption.referenceId === n.referenceId) {
                                        originalOfferPartOption = n;
                                    }
                                });
                                if (originalOfferPartOption) {
                                    $scope.removeOfferPartOptionFromMap(originalOfferPartOption);
                                    angular.forEach($scope.allOptionsForPart, function (partOption, i) {
                                        if (partOption.referenceId === originalOfferPartOption.referenceId) {
                                            $scope.allOptionsForPart.splice(i, 1);
                                        }
                                    });
                                    $scope.ROPOAddEditId = 0;
                                    offerPartOption.status = { name: 'ADDED_BY_SUPPLIER' };
                                    offerPartOption.newPrice = offerPartOption.price;
                                    if ($scope.includeVat && $scope.offer.vatIncludedInPrice && offerPartOption.price) {
                                        originalOfferPartOption.priceInclVat =
                                            amountAndPriceService.calculatePriceFromPriceAndVat(
                                                offerPartOption.price,
                                                offerPartOption.vat
                                            );
                                        originalOfferPartOption.newPriceInclVat =
                                            amountAndPriceService.calculatePriceFromPriceAndVat(
                                                offerPartOption.price,
                                                offerPartOption.vat
                                            );
                                    }
                                    offerPartOption.deletable = true;
                                    offerPartOption.referenceId = Math.random();
                                    let offerPartOptionMap = {
                                        offerPartOption: offerPartOption,
                                        action: 'ADDED_BY_SUPPLIER',
                                    };
                                    $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);
                                    $scope.allOptionsForPart.push(offerPartOption);
                                    $scope.originalOfferPartOptionsList = angular.copy($scope.allOptionsForPart);
                                }
                            } else {
                                if ($scope.isOfferPartOptionInSupplierWaitingStates(originalOfferPartOption)) {
                                    angular.forEach($scope.allOptionsForPart, function (partOption) {
                                        if (partOption.id === offerPartOption.id) {
                                            partOption = $scope.storeOfferPartOptionValues(
                                                originalOfferPartOption,
                                                offerPartOption,
                                                partOption
                                            );
                                            partOption.status.name = 'EDITED_BY_SUPPLIER';
                                            $scope.$broadcast(
                                                'request:offer:part:option:updated:client:side:supplier',
                                                { offerPartOption: partOption }
                                            );
                                        }
                                    });
                                    if ($scope.checkIfFieldValueChanged(offerPartOption, originalOfferPartOption)) {
                                        let offerPartOptionMap = {
                                            offerPartOption: offerPartOption,
                                            action: 'ACCEPTED_BY_SUPPLIER_WITH_CHANGES',
                                        };
                                        $scope.removeOfferPartOptionFromMap(offerPartOption);
                                        $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);
                                        $scope.flagForAcceptedWithChanges = 'edit' + offerPartOption.id;
                                    } else {
                                        $scope.removeOfferPartOptionFromMap(offerPartOption);
                                        $scope.flagForAcceptedWithChanges = undefined;
                                    }
                                } else {
                                    angular.forEach($scope.allOptionsForPart, function (partOption) {
                                        if (partOption.id === offerPartOption.id) {
                                            partOption = $scope.storeOfferPartOptionValues(
                                                originalOfferPartOption,
                                                offerPartOption,
                                                partOption
                                            );
                                            if (partOption.status.name === 'ADDED_WAITING_FOR_CREATOR_DECLINED') {
                                                partOption.status.name = 'ADDED_WAITING_FOR_CREATOR';
                                            } else {
                                                partOption.status.name = 'EDITED_BY_SUPPLIER';
                                            }
                                            $scope.$broadcast(
                                                'request:offer:part:option:updated:client:side:supplier',
                                                { offerPartOption: partOption }
                                            );
                                        }
                                    });
                                    if ($scope.checkIfFieldValueChanged(offerPartOption, originalOfferPartOption)) {
                                        var offerPartOptionMap = {
                                            offerPartOption: offerPartOption,
                                            action: 'EDITED_BY_SUPPLIER',
                                        };
                                        $scope.removeOfferPartOptionFromMap(offerPartOption);
                                        $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);
                                        $scope.flagForAcceptedWithChanges = 'edit' + offerPartOption.id;
                                    } else {
                                        $scope.removeOfferPartOptionFromMap(offerPartOption);
                                        $scope.flagForAcceptedWithChanges = undefined;
                                    }
                                }
                            }
                            //$rootScope.ROPEditId = 0;
                        };

                        $scope.checkIfFieldValueChanged = function (offerPartOption, originalOfferPartOption) {
                            if (!offerPartOption.id) {
                                return true;
                            }
                            if (
                                offerPartOption.amount !== originalOfferPartOption.amount ||
                                offerPartOption.amountType !== originalOfferPartOption.amountType ||
                                offerPartOption.amountType !== originalOfferPartOption.amountType.name ||
                                offerPartOption.dateTimeFrom !== originalOfferPartOption.dateTimeFrom ||
                                offerPartOption.dateTimeTo !== originalOfferPartOption.dateTimeTo ||
                                (offerPartOption.price &&
                                    offerPartOption.price !== 0 &&
                                    offerPartOption.price !== originalOfferPartOption.price)
                            ) {
                                return true;
                            } else {
                                return false;
                            }
                        };

                        $scope.storeOfferPartOptionValues = function (
                            originalOfferPartOption,
                            offerPartOption,
                            partOption
                        ) {
                            if (
                                originalOfferPartOption.amount !== offerPartOption.amount ||
                                partOption.amount !== offerPartOption.amount
                            ) {
                                partOption.oldAmount = originalOfferPartOption.amount;
                                partOption.amount = offerPartOption.amount;
                            }
                            if (
                                originalOfferPartOption.amountType.name !== offerPartOption.amountType.name ||
                                partOption.amountType.name !== offerPartOption.amountType.name
                            ) {
                                partOption.oldAmountType = originalOfferPartOption.amountType;
                                partOption.amountType.name = offerPartOption.amountType.name;
                            }
                            if (originalOfferPartOption.dateTimeFrom !== offerPartOption.dateTimeFrom) {
                                partOption.oldDateTimeFrom = originalOfferPartOption.dateTimeFrom;
                                partOption.dateTimeFrom = offerPartOption.dateTimeFrom;
                            }
                            if (originalOfferPartOption.dateTimeTo !== offerPartOption.dateTimeTo) {
                                partOption.oldDateTimeTo = originalOfferPartOption.dateTimeTo;
                                partOption.dateTimeTo = offerPartOption.dateTimeTo;
                            }
                            if (
                                originalOfferPartOption.price !== offerPartOption.price ||
                                originalOfferPartOption.newPriceInclVat !== offerPartOption.newPriceInclVat
                            ) {
                                partOption.oldPrice = originalOfferPartOption.newPrice
                                    ? originalOfferPartOption.newPrice
                                    : 0;
                                partOption.newPrice = offerPartOption.price;
                                partOption.price = offerPartOption.price;
                                if ($scope.includeVat && $scope.offer.vatIncludedInPrice) {
                                    partOption.priceInclVat = offerPartOption.priceInclVat;
                                    partOption.newPriceInclVat = offerPartOption.priceInclVat;
                                }
                            }
                            partOption.vat = offerPartOption.vat;
                            return partOption;
                        };

                        $scope.isOfferPartOptionInSupplierWaitingStates = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER') {
                                return false;
                            }
                            var offerPartOptionIsInSupplierWaitingStates = false;
                            var originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                            angular.forEach(GlobalConstants.SUPPLIER_WAITING_STATES, function (state) {
                                if (!offerPartOptionIsInSupplierWaitingStates) {
                                    if (originalOfferPartOption && originalOfferPartOption.status.name === state) {
                                        offerPartOptionIsInSupplierWaitingStates = true;
                                    }
                                }
                            });
                            return offerPartOptionIsInSupplierWaitingStates;
                        };

                        $scope.deleteOfferPartOption = function (requestOfferPartOption) {
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
                                if (
                                    requestOfferPartOption &&
                                    (requestOfferPartOption.id || requestOfferPartOption.referenceId)
                                ) {
                                    $scope.deleteOfferPartOptionOnClientSideOnly(requestOfferPartOption);
                                    $rootScope.$broadcast('part:option:added:updated:in:client:side:only', {
                                        requestOfferPart: $scope.offerPart,
                                        allOptionsForPart: $scope.allOptionsForPart,
                                    });
                                } else {
                                    $scope.ROPOAddEditId = 0;
                                    $rootScope.ROPOEditId = 0;
                                }
                            } else {
                                if (requestOfferPartOption && requestOfferPartOption.id) {
                                    $scope.startProgress();
                                    RequestOfferPartOption.delete(
                                        requestOfferPartOption.id,
                                        (resp) => {
                                            $scope.allOptionsForPart = resp.allOptionsForPart;
                                            $scope.setPartOptionNewPrice();
                                            $scope.setFinalPriceAndAmount();
                                            $scope.originalOfferPartOptionsList = angular.copy(resp);
                                            $rootScope.$broadcast('part:option:deleted:with:is:selected:radio:value', {
                                                requestOfferPartId: $scope.offerPart.id,
                                                offerPartOptions: $scope.allOptionsForPart,
                                            });
                                            $scope.endProgress();
                                        },
                                        $scope.endWithErrorOverlay()
                                    );
                                } else {
                                    $scope.ROPOAddEditId = 0;
                                    $rootScope.ROPOEditId = 0;
                                }
                            }
                        };

                        $scope.deleteOfferPartOptionOnClientSideOnly = function (offerPartOption) {
                            if (offerPartOption.id) {
                                offerPartOption.status = { name: 'DELETED_BY_SUPPLIER' };
                                var offerPartOptionMap = {
                                    offerPartOption: offerPartOption,
                                    action: 'DELETED_BY_SUPPLIER',
                                };
                                $scope.removeOfferPartOptionFromMap(offerPartOption);
                                $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);

                                var originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                                $scope.reassignOriginalValuesToOfferPartOption(
                                    offerPartOption,
                                    originalOfferPartOption
                                );

                                angular.forEach($scope.allOptionsForPart, function (partOption) {
                                    if (partOption.id === offerPartOption.id) {
                                        partOption.oldAmount = originalOfferPartOption.oldAmount;
                                        partOption.oldAmountType = originalOfferPartOption.oldAmountType;
                                        partOption.oldDateTimeFrom = originalOfferPartOption.oldDateTimeFrom;
                                        partOption.oldDateTimeTo = originalOfferPartOption.oldDateTimeTo;
                                        partOption.oldPrice = originalOfferPartOption.oldPrice;
                                        partOption.status.name = 'DELETED_WAITING_FOR_CREATOR';
                                        partOption.isSelected = false;
                                    }
                                });
                                $rootScope.$broadcast('request:offer:part:option:deleted:client:side:only', {
                                    offerPartOption: offerPartOption,
                                });
                            } else {
                                $scope.removeOfferPartOptionFromMap(offerPartOption);
                                angular.forEach($scope.allOptionsForPart, function (partOption, i) {
                                    if (partOption.referenceId === offerPartOption.referenceId) {
                                        $scope.allOptionsForPart.splice(i, 1);
                                        offerPartOption.isSelected = false;
                                    }
                                });
                            }
                        };

                        $scope.setPartOptionNewPrice = function () {
                            angular.forEach($scope.allOptionsForPart, (item) => {
                                item.newPrice = item.price;
                                if (SHOW_SUPPLIER_PRICE_STATE.indexOf($scope.offer.status.name) > -1) {
                                    item.newPrice = item.supplierPrice;
                                    if (['EDITED_WAITING_FOR_SUPPLIER'].indexOf($scope.offer.status.name) > -1) {
                                        item.amount = item.supplierAmount;
                                        item.amountType = item.supplierAmountType;
                                        item.dateTimeFrom = item.supplierDateTimeFrom;
                                        item.dateTimeTo = item.supplierDateTimeTo;
                                    }
                                }
                                if (
                                    EDITED_BY_SUPPLIER_PART_OPTION_STATE.indexOf(item.status.name) > -1 &&
                                    !$scope.isOfferInFromFinishedState()
                                ) {
                                    item.newPrice = item.supplierPrice;
                                    item.dateTimeFrom = item.supplierDateTimeFrom;
                                    item.dateTimeTo = item.supplierDateTimeTo;
                                    item.amount = item.supplierAmount;
                                    item.amountType = item.supplierAmountType;
                                }
                            });
                        };

                        $scope.setFinalPriceAndAmount = function () {
                            angular.forEach($scope.allOptionsForPart, (item) => {
                                //initially final price will not be set, so price is set to final price
                                item.finalPrice = item.finalPrice != null ? item.finalPrice : item.price;
                                item.finalAmount = item.finalAmount != null ? item.finalAmount : item.amount;
                                if (
                                    RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf($scope.offer.status.name) >
                                    -1 &&
                                    $scope.includeVat &&
                                    $scope.offer.vatIncludedInPrice
                                ) {
                                    item.finalPriceInclVat = amountAndPriceService.calculatePriceFromPriceAndVat(
                                        item.finalPrice,
                                        item.vat
                                    );
                                }
                            });
                        };

                        $scope.isOfferInFromBookedState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(
                                    $scope.offer.status.name
                                ) > -1
                            );
                        };

                        $scope.isOfferInFromFinishedState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf($scope.offer.status.name) > -1
                            );
                        };
                        $scope.isOfferInFromCanceledFinishedState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.ALL_CANCELED_FINISHED_STATUS.indexOf($scope.offer.status.name) >
                                -1
                            );
                        };
                        $scope.isOfferInAnsweredState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                ['ANSWERED'].indexOf($scope.offer.status.name) > -1
                            );
                        };

                        $scope.isOfferInSupplierEditDisabledState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.OFFER_IN_SUPPLIER_EDIT_DISABLED_STATE.indexOf(
                                    $scope.offer.status.name
                                ) > -1
                            );
                        };

                        $scope.getOriginalOfferPartOption = function (offerPartOption) {
                            let originalOfferPartOption;
                            if (!offerPartOption) {
                                return undefined;
                            }
                            $scope.originalOfferPartOptionsList.forEach((n) => {
                                if (offerPartOption && offerPartOption.id && n && n.id && offerPartOption.id === n.id) {
                                    originalOfferPartOption = n;
                                }
                            });
                            if (
                                $scope.includeVat &&
                                $scope.offer.vatIncludedInPrice &&
                                originalOfferPartOption &&
                                originalOfferPartOption.price
                            ) {
                                originalOfferPartOption.priceInclVat =
                                    amountAndPriceService.calculatePriceFromPriceAndVat(
                                        originalOfferPartOption.price,
                                        originalOfferPartOption.vat
                                    );
                                originalOfferPartOption.newPriceInclVat =
                                    amountAndPriceService.calculatePriceFromPriceAndVat(
                                        originalOfferPartOption.price,
                                        originalOfferPartOption.vat
                                    );
                            }
                            return originalOfferPartOption;
                        };

                        $scope.showSupplierOptions = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER' || $scope.layout !== 'supplier') {
                                return false;
                            }
                            var suppOptionFlag;
                            suppOptionFlag =
                                $scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER' &&
                                ($scope.isOfferPartOptionInSupplierWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartOptionInCreatorDeclinedStates(offerPartOption));
                            if (suppOptionFlag && $scope.colspan === 2) {
                                $scope.colspan = 1;
                            }
                            $scope.blankTableHeader = !!suppOptionFlag;
                            return suppOptionFlag;
                        };

                        $scope.isOfferPartOptionInSupplierWaitingStates = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER') {
                                return false;
                            }
                            var offerPartIsInSupplierWaitingStates = false;
                            var originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                            angular.forEach(GlobalConstants.SUPPLIER_WAITING_STATES, function (state) {
                                if (!offerPartIsInSupplierWaitingStates) {
                                    if (originalOfferPartOption && originalOfferPartOption.status.name === state) {
                                        offerPartIsInSupplierWaitingStates = true;
                                    }
                                }
                            });
                            return offerPartIsInSupplierWaitingStates;
                        };

                        $scope.isOfferPartOptionInCreatorDeclinedStates = function (offerPartOption) {
                            var offerPartIsInCreatorDeclinedStates = false;
                            var originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                            angular.forEach(GlobalConstants.CREATOR_DECLINED_STATES, function (state) {
                                if (!offerPartIsInCreatorDeclinedStates) {
                                    if (originalOfferPartOption && originalOfferPartOption.status.name === state) {
                                        offerPartIsInCreatorDeclinedStates = true;
                                    }
                                }
                            });
                            return offerPartIsInCreatorDeclinedStates;
                        };

                        $scope.acceptOfferPartOption = function (offerPartOption) {
                            var offerPartOptionMap = {
                                offerPartOption: offerPartOption,
                                action: 'ACCEPTED_BY_SUPPLIER',
                            };
                            $scope.removeOfferPartOptionFromMap(offerPartOption);
                            //$scope.offerPartOptionsWithActions.push(offerPartOptionMap);

                            var originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                            $scope.reassignOriginalValuesToOfferPartOption(offerPartOption, originalOfferPartOption);
                            if (originalOfferPartOption.status.name === 'EDITED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'ACCEPTED';
                            }
                            if (originalOfferPartOption.status.name === 'ADDED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'ACCEPTED';
                            }
                            if (originalOfferPartOption.status.name === 'DELETED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'NOT_DELIVERABLE';
                            }
                            $scope.flagForAcceptedWithChanges = undefined;
                            $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);

                            if ($scope.supplierActionOnCreatorChanges === 'DECLINE_ALL') {
                                $scope.btnMap = { name: 'requestOfferPart.button.action.all', ngclass: 'spAction' };
                            }
                        };

                        $scope.declineOfferPartOption = function (offerPartOption) {
                            var offerPartOptionMap = {
                                offerPartOption: offerPartOption,
                                action: 'DECLINED_BY_SUPPLIER',
                            };
                            $scope.removeOfferPartOptionFromMap(offerPartOption);

                            var originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                            $scope.reassignOriginalValuesToOfferPartOption(offerPartOption, originalOfferPartOption);
                            if (originalOfferPartOption.status.name === 'EDITED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'DECLINED';
                            }
                            if (originalOfferPartOption.status.name === 'ADDED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'ADDED_WAITING_FOR_SUPPLIER_DECLINED';
                            }
                            if (originalOfferPartOption.status.name === 'DELETED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'DELETED_WAITING_FOR_SUPPLIER_DECLINED';
                            }
                            $scope.flagForAcceptedWithChanges = undefined;
                            $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);

                            if ($scope.supplierActionOnCreatorChanges === 'ACCEPT_ALL') {
                                $scope.btnMap = { name: 'requestOfferPart.button.action.all', ngclass: 'spAction' };
                            }
                        };

                        $scope.removeOfferPartOptionFromMap = function (offerPartOption) {
                            angular.forEach($scope.offerPartOptionsWithActions, function (rowAndAction, i) {
                                if (offerPartOption.id) {
                                    if (rowAndAction.offerPartOption.id === offerPartOption.id) {
                                        $scope.offerPartOptionsWithActions.splice(i, 1);
                                    }
                                } else if (offerPartOption.referenceId) {
                                    if (rowAndAction.offerPartOption.referenceId === offerPartOption.referenceId) {
                                        $scope.offerPartOptionsWithActions.splice(i, 1);
                                    }
                                }
                            });
                        };

                        $scope.reassignOriginalValuesToOfferPartOption = function (
                            offerPartOption,
                            originalOfferPartOption
                        ) {
                            offerPartOption.amount = originalOfferPartOption.amount;
                            offerPartOption.amountType.name = originalOfferPartOption.amountType.name;
                            offerPartOption.dateTimeFrom = originalOfferPartOption.dateTimeFrom;
                            offerPartOption.dateTimeTo = originalOfferPartOption.dateTimeTo;
                            offerPartOption.newPrice = originalOfferPartOption.price;
                            offerPartOption.price = originalOfferPartOption.price;
                        };

                        $scope.close = function () {
                            $scope.ROPOAddEditId = 0;
                            $rootScope.ROPOEditId = 0;
                            $rootScope.offerPartEditId = 0;
                        };

                        $scope.$on('update.offer.part.option', (event, data) => {
                            $scope.ROPOAddEditId = 0;
                            if (data.status) {
                                $scope.offer.status.name = data.status;
                            }
                            $scope.init();
                        });

                        $scope.$on('revert.final.prices.by.supplier', () => {
                            $scope.init();
                        });

                        $scope.$on('decline.offer.part.with.option.added', (event, data) => {
                            if (data && data.offerPartId === $scope.offerPart.id) {
                                $scope.ROPOAddEditId = 0;
                                $scope.allOptionsForPart = [];
                            }
                        });

                        $scope.$on('enable.offer.part.option.edit', (event, data) => {
                            $scope.finalPriceAndAmountEdit = true;
                        });

                        $scope.$on('disable.offer.part.option.edit', (event, data) => {
                            $scope.init();
                            $scope.finalPriceAndAmountEdit = false;
                        });

                        let applySupplierActionOnCreatorChanges = $scope.$on(
                            'apply.supplier.action.on.creator.changes',
                            (event, data) => {
                                $scope.ROPOAddEditId = 0;
                                $rootScope.ROPOEditId = 0;
                            }
                        );

                        $scope.$on('$destroy', applySupplierActionOnCreatorChanges);

                        $scope.init();
                    },
                ],
                link: function () { },
            };
        },
    ]);
})();
