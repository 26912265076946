import invitationRoute from './invitation/route';
export default function (appModule) {
    appModule.config([
        '$routeProvider',
        '$locationProvider',
        '$stateProvider',
        function ($routeProvider, $locationProvider, $stateProvider) {
            $stateProvider
                .state('event.detail.participants.list', {
                    url: '/list',
                    template: '<participantslist></participantslist>',
                    params: { highlightElement: null },
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('event.detail.participants.summary', {
                    url: '/summary',
                    template: '<participantsummary></participantsummary>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                let _isLoggedIn = AuthenticationService.validate();
                                return _isLoggedIn;
                            },
                        ],
                    },
                })
                .state('event.detail.participants.design_invitation', {
                    url: '/design_invitation',
                    template: '<participantinvitation></participantinvitation>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('event.detail.participants.design-badge', {
                    url: '/design-badge',
                    template: '<participantinvitationdesignbadge></participantinvitationdesignbadge>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('event.detail.participants.tickets', {
                    url: '/tickets',
                    template: '<participanttickets></participanttickets>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('event.detail.participants.settings', {
                    url: '/settings',
                    template: '<participantsettings></participantsettings>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('event.detail.participants.mails', {
                    url: '/mails',
                    template: '<participantemaillogs></participantemaillogs>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
        },
    ]);
    invitationRoute(appModule);
}