'use strict';
import { OgModal } from "../../../../utils/global/ogModal";
import { setLocalToken, setLocalToken_temp } from "../../../../utils/index";
// Register `login` component, along with its associated controller and template
angular.
    module('login').
    component('login', {
        templateUrl: './app/components/modules/auth/login/login.template.html',
        controller: [
            '$scope',
            '$location',
            '$rootScope',
            '$log',
            '$translate',
            'AuthenticationService',
            '$anchorScroll',
            'Config',
            '$timeout',
            'EventEnum',
            '$stateParams',
            'ClientService',
            'CustomerUser',
            'GlobalOption',
            'Phone',
            '$state',
            'Event',

            function LoginController(
                $scope,
                $location,
                $rootScope,
                $log,
                $translate,
                AuthenticationService,
                $anchorScroll,
                Config,
                $timeout,
                EventEnum,
                $stateParams,
                ClientService,
                CustomerUser,
                GlobalOption,
                Phone,
                $state,
                Event,
            ) {
                var vm = this;

                vm.changePasswordDialog = changePasswordDialogFn;
                vm.scrollToTop = scrollToTopFn;
                vm.errorCode = '';
                vm.invalidLogin = false;
                vm.login = loginFn;
                vm.loginUsingOAuth = loginUsingOAuthFn;
                vm.switchToEn = switchToEnFn;
                vm.switchToSv = switchToSvFn;
                vm.switchToNb = switchToNbFn;
                vm.switchToDa = switchToDaFn;
                vm.switchToFi = switchToFiFn;
                vm.password = '';
                vm.username = '';
                initFn();

                vm.client = ClientService.get();

                if ($stateParams.locale) {
                    if ($stateParams.locale === 'en') {
                        vm.switchToEn();
                    } else if ($stateParams.locale === 'sv') {
                        vm.switchToSv();
                    } else if ($stateParams.locale === 'nb') {
                        vm.switchToNb();
                    } else if ($stateParams.locale === 'da') {
                        vm.switchToDa();
                    } else {
                        vm.switchToFi();
                    }
                } else {
                    vm.activeEn = $rootScope.langSelected === 'en_US' ? 'active' : '';
                    vm.activeSv = $rootScope.langSelected === 'sv_SV' ? 'active' : '';
                    vm.activeNb = $rootScope.langSelected === 'nb_Nb' ? 'active' : '';
                    vm.activeDk = $rootScope.langSelected === 'da_DK' ? 'active' : '';
                    vm.activeFi = $rootScope.langSelected === 'fi_FI' ? 'active' : '';
                }

                function changePasswordDialogFn() {
                    OgModal.open('change:password');
                }

                function initFn() {
                    vm.externalEventId = $stateParams.eventId
                    if (vm.externalEventId) {
                        vm.switchToSv();
                    }
                    GlobalOption.get(
                        'MAX_FAILED_LOGIN_ATTEMPT',
                        (resp) => {
                            vm.maxLoginAttempts = resp[$rootScope.langSelected] ? resp[$rootScope.langSelected] : resp['sv_SV'];
                        },
                        $scope.endWithError
                    );
                }

                function loginFn() {
                    vm.invalidLogin = false;
                    if (vm.username && vm.password) {
                        vm.loading = true;
                        loginUser(vm.username, vm.password);
                    }
                }

                function scrollToTopFn() {
                    $timeout(function () {
                        if (vm.externalEventId) {
                            $location.search('eventId', vm.externalEventId);
                        }
                        // $location.hash('header_logout');
                        $anchorScroll();
                    }, 500);
                }

                function loginUsingOAuthFn(oauthProvider) {
                    $location.url('oauth/' + oauthProvider + '/authenticate');
                    $timeout(function () {
                        window.location.reload();
                    }, 200);
                }

                var redirectToHomePage = function (data) {
                    vm.loading = false;
                    $rootScope.$broadcast(EventEnum.USER_GROUP_CHANGED, data.userGroup);

                };

                var redirectToMFAPage = function (res) {
                    vm.loading = false;
                    // window.location.href = '/auth/mfaVerify';
                    let user = res.user
                    if (user.isMFAEnabled) {
                        $state.go('auth.mfaVerify', { resp: res });
                    } else {
                        $state.go('auth.mfaEnable', { resp: res });
                    }
                };

                var loginUser = function (username, password) {
                    AuthenticationService.login(username, password).then(handleLoginResponse);
                };

                function getParameterByName(name) {
                    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
                    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
                    var results = regex.exec(location.search);
                    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
                }

                var handleLoginResponse = function (data) {
                    //Change ownership of events created from onboarding
                    if (vm.externalEventId && data && data.user && data.user.email) {
                        AuthenticationService.changeOwnerGroup(
                            { onboardingEventHashId: vm.externalEventId, email: data.user.email },
                            (response) => {
                                if (response) {
                                    console.log('Successfully changed ownership of event with eventId ' + vm.externalEventId)
                                }
                            },
                            function (error) {
                                console.log('Error while changing ownership of event with id ' + vm.externalEventId);
                            }
                        );
                    }

                    if (data && data.user && data.user.checkForFirstTimeLogin) {
                        vm.loading = false;
                        setLocalToken_temp(data.access_token);
                        $state.go('auth.firstTimeLogin', { resp: data });
                    } else if (data && data.userGroup && data.userGroup.forceMfaEnum.name === 'TRUE') {
                        setLocalToken_temp(data.access_token);
                        redirectToMFAPage(data);
                    } else if (data && data.user) {
                        if (data.user.isMFAEnabled) {
                            setLocalToken_temp(data.access_token);
                            $state.go('auth.mfaVerify', { resp: data });
                        } else {
                            setLocalToken(data.access_token);
                            $rootScope.$emit('event:user:action', { action: 'user.loggedin', data: data.user });
                            var savedLocation = getParameterByName('location');
                            if (savedLocation) {
                                vm.loading = false;
                                $location.url(savedLocation);
                            } else if (Config && Config.savedLocation) {
                                vm.loading = false;
                                $location.url(Config.savedLocation);
                                Config.savedLocation = '';
                            } else {
                                console.log(vm.externalEventId, "here");
                                if (vm.externalEventId) {
                                    Event.findEventByEventHashId(
                                        vm.externalEventId,
                                        function (response) {
                                            console.log(response, "response");
                                            if (response.eventId) {
                                                $location.url('/event/' + response.eventId + '/overview');
                                            }
                                        },
                                        function () {
                                            redirectToHomePage(data);
                                        }
                                    );
                                } else {
                                    redirectToHomePage(data);
                                }

                            }
                        }

                    } else if (data && data.data && data.messageCode === 'springSecurity.errors.login.locked') {
                        vm.loading = false;
                        vm.invalidLogin = true;
                        vm.errorCode = 'springSecurity.errors.login.locked';
                    } else if (data && data.data && data.data.messageCode === "springSecurity.errors.login.disabled") {
                        vm.loading = false;
                        vm.invalidLogin = true;
                        vm.errorCode = 'springSecurity.errors.login.disabled';
                    } else {
                        CustomerUser.getFailedLoginAttemptCount({ username: vm.username }, (response) => {
                            vm.loading = false;
                            vm.invalidLogin = true;
                            if (!response.username) {
                                vm.errorCode = 'login.invalid.credentials'; //todo(SC) use code from response to handle error properly
                                return;
                            }
                            if (response.accountLocked) {
                                vm.errorCode = 'custom.springSecurity.errors.login.locked';
                            } else {
                                vm.remainingLoginAttempt = vm.maxLoginAttempts - response.failedAttempt;
                                vm.errorCode =
                                    $translate.instant('custom.springSecurity.errors.account.locked') +
                                    ' ' +
                                    vm.remainingLoginAttempt;
                            }
                        });
                    }
                };

                function switchToEnFn() {
                    $rootScope.switchLang('en_US');
                    vm.activeEn = 'active';
                    vm.activeSv = '';
                    vm.activeNb = '';
                    vm.activeDk = '';
                    vm.activeFi = '';
                }

                function switchToSvFn() {
                    $rootScope.switchLang('sv_SV');
                    vm.activeSv = 'active';
                    vm.activeEn = '';
                    vm.activeNb = '';
                    vm.activeDk = '';
                    vm.activeFi = '';
                }

                function switchToNbFn() {
                    $rootScope.switchLang('nb_NB');
                    vm.activeSv = '';
                    vm.activeEn = '';
                    vm.activeNb = 'active';
                    vm.activeDk = '';
                    vm.activeFi = '';
                }

                function switchToDaFn() {
                    $rootScope.switchLang('da_DK');
                    vm.activeSv = '';
                    vm.activeEn = '';
                    vm.activeNb = '';
                    vm.activeDk = 'active';
                    vm.activeFi = '';
                }

                function switchToFiFn() {
                    $rootScope.switchLang('fi_FI');
                    vm.activeSv = '';
                    vm.activeEn = '';
                    vm.activeNb = '';
                    vm.activeDk = '';
                    vm.activeFi = 'active';
                }

                /*CommonFunctions.setSameHeight('login-col-height');*/

                $scope.$on('dialog:password:change:success', function () {
                    $scope.$broadcast('notification:show', { message: 'login.user.password.requested', timeout: 10000 });
                });

                $rootScope.$emit('event:user:action', { action: 'visit.login' });
            }
        ]
    });
