'use strict';
import { OgModal } from "../../utils/global/ogModal";
import { ScopeFunctions } from "../../utils/global/scopeFunctions";
import CommonFunctions from '../../utils/CommonFunctionUtil';

angular.module('dialog').directive('ogParticipantsImportFromEventModal', [
    '$rootScope',
    '$translate',
    'Event',
    'Participant',
    'ProcessLog',
    '$timeout',
    function ($rootScope, $translate, Event, Participant, ProcessLog, $timeout) {
        return new OgModal({
            name: 'participants:import:from:event',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                $scope.initDialog = function (data) {
                    $scope.eventId = data.eventId;
                    $scope.successCallback = data.successCallback;
                    $scope.pullTillGetStatus = false;
                    $scope.isHideImportContent = false;
                    $scope.importSubTab = 'BASIC_IMPORT_TAB';
                    $scope.errorMessages = null;
                    $scope.startProgress();
                    Event.getAllEvents(
                        $scope.eventId,
                        function (response) {
                            $scope.otherEvents = response;
                            jQuery('#selectEventNameBasic').select2({
                                placeholder: $translate.instant('event.select.list.placeholder'),
                                allowClear: true,
                            });
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                };

                $scope.goBackToPrevious = function () {
                    $scope.isHideImportContent = false;
                };

                $scope.getSelectedEventId = function () {
                    if ($scope.importSubTab === 'BASIC_IMPORT_TAB') {
                        let selectedEvent = jQuery('#selectEventNameBasic').select2('data');
                        return selectedEvent ? selectedEvent[0].id : null;
                    } else {
                        let selectedEvent = jQuery('#selectEventNameAdvanced').select2('data');
                        return selectedEvent ? selectedEvent[0].id : null;
                    }
                };

                $scope.importParticipantFromEvent = function (importType) {
                    var params = {
                        currentEventId: $scope.eventId,
                        selectedEventId: $scope.getSelectedEventId(),
                        importType: importType,
                    };
                    $scope.uploadInProgress = true;
                    $scope.pullTillGetStatus = true;
                    Participant.importFromEvent(
                        params,
                        function (response) {
                            if ($scope.successCallback) {
                                $scope.successCallback();
                            }
                            $scope.participant = {};
                            $scope.isHideImportContent = true;
                            $scope.uploadInProgress = false;
                            $scope.pullTillGetStatus = false;
                        },
                        (err) => {
                            this.pullTillGetStatus = false;
                            this.errorMessages = CommonFunctions.errorMessages(err, true);
                            this.uploadInProgress = false;
                        }
                    );
                };

                $scope.setImportTabActive = function (tabName) {
                    $scope.importSubTab = tabName;
                    if (tabName === 'ADVANCED_IMPORT_TAB') {
                        jQuery('#selectEventNameAdvanced').select2({
                            placeholder: $translate.instant('event.select.list.placeholder'),
                            allowClear: true,
                        });
                    } else {
                        jQuery('#selectEventNameBasic').select2({
                            placeholder: $translate.instant('event.select.list.placeholder'),
                            allowClear: true,
                        });
                    }
                };

                $scope.getCurrentProcessStatus = function () {
                    if (!$scope.eventId) {
                        return;
                    }
                    ProcessLog.getImportStatus(
                        { referenceId: $scope.eventId },
                        (resp) => {
                            if (resp.status && resp.status.name === 'RUNNING') {
                                $scope.closeModal();
                            } else if (resp.status && resp.status.name === 'SUCCESS_WITH_MESSAGE') {
                                let _error = angular.fromJson(resp.errorLog);
                                $scope.errorMessages = CommonFunctions.errorMessages(_error, true);
                            } else if ($scope.pullTillGetStatus) {
                                $timeout(() => {
                                    $scope.getCurrentProcessStatus($scope.eventId);
                                }, 500);
                            }
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                };

                $scope.closeModal = () => {
                    $scope.close();
                    var successCallback = $scope.successCallback;
                    $scope.close();
                    if (successCallback) {
                        successCallback();
                    }
                };
            },
        });
    },
]);

