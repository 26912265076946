import amountAndPriceService from '../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import requestOfferPartsUtil from '../../../../utils/request_offer_parts/RequestOfferPartsUtil';
import updatePartMarkupModal from '../../../../components/modals/modals2.0/update_part_markup_value';
import updateRequestMarkupModal from '../../../../components/modals/modals2.0/update_request_markup_value';
import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('ogBudgetMarkupDetail').directive('ogBudgetMarkupDetail', [
        function () {
            return {
                restrict: 'EA',
                scope: {
                    request: '=',
                    event: '=',
                },
                templateUrl: './app/components/directives/budget/budget_markup_detail/og.budget.markup.detail.view.html',
                controllerAs: 'ogBudgetMarkupDetailCtrl',
                bindToController: true,
                controller: [
                    '$scope',
                    '$log',
                    '$rootScope',
                    'BudgetDataShareService',
                    'Request',
                    'ModalService',
                    'EventEnum',
                    '$timeout',
                    function (
                        $scope,
                        $log,
                        $rootScope,
                        BudgetDataShareService,
                        Request,
                        ModalService,
                        EventEnum,
                        $timeout
                    ) {
                        var vm = this;

                        this.$onInit = function () {
                            vm = this;
                            vm.viewModal = false;
                            vm.calculateTotalPrice = calculateTotalPriceFn;
                            vm.chooseSelectedOffer = chooseSelectedOfferFn;
                            vm.filter = filterFn;
                            vm.filterOffersToDisplay = filterOffersToDisplayFn;
                            vm.getAmount = amountAndPriceService.getAmount;
                            vm.getDifference = amountAndPriceService.getDifference;
                            vm.getPrice = amountAndPriceService.getPrice;
                            vm.getAmountLocalisedWithCurrency = amountAndPriceService.getAmountLocalisedWithCurrency;
                            vm.getTotalPrice = amountAndPriceService.getTotalPrice;
                            vm.getRequestPartOptionTotalPrice = getRequestPartOptionTotalPriceFn;
                            vm.getRequestPartOptionTotalPriceWithMarkup = getRequestPartOptionTotalPriceWithMarkupFn;
                            vm.getCustomerPrice = amountAndPriceService.getCustomerPrice;
                            vm.init = initFn;
                            vm.processBudgetData = processBudgetDataFn;
                            vm.selectedOfferListPrice = selectedOfferListPriceFn;
                            vm.setDisplayDiffColmn = setDisplayDiffColmnFn;
                            vm.setSelectedOffersOriginalPrice = setSelectedOffersOriginalPriceFn;
                            vm.setSelectedOffersPriceDifference = setSelectedOffersPriceDifferenceFn;
                            vm.editRequestOfferPart = editRequestOfferPartFn;
                            vm.editRequestMarkupValue = editRequestMarkupValueFn;
                            vm.getFixedMarkupValue = getFixedMarkupValueFn;
                            vm.toggleResourceViewOption = toggleResourceViewOptionFn;
                            vm.getMarkupFromTotalPriceAndCustomerPrice =
                                amountAndPriceService.getMarkupFromPriceAndCustomerPrice;
                            vm.getMarkupFromPriceAndCustomerPriceWithCurrency =
                                amountAndPriceService.getMarkupFromPriceAndCustomerPriceWithCurrency;

                            // vm.selectedOfferList = {};
                            vm.totalPrice = 0;
                            vm.offers = [];

                            ScopeFunctions.addToScope($scope);

                            vm.init();
                        }


                        function calculateTotalPriceFn(requestId, offer) {
                            if (!offer.offerParts) {
                                angular.forEach(vm.requestOffers, function (offer1) {
                                    if (offer.id === offer1.id) {
                                        offer.offerParts = offer1.offerParts;
                                    }
                                });
                            }
                            BudgetDataShareService.setSelectedOfferList(requestId, offer);
                            vm.selectedOfferListPrice();
                        }

                        function setSelectedPartToEditFn(part, requestId) {
                            let _selectedOffers = BudgetDataShareService.getSelectedOfferList();
                            let _selectedOffer = _selectedOffers[requestId];
                            let _selectedPartIds = _.chain(part.offerWiseData).pluck('id').value();
                            if (_selectedOffer.offerParts) {
                                vm.selectedPart = _.find(_selectedOffer.offerParts, (p) => {
                                    return _selectedPartIds.indexOf(p.id) > -1;
                                });
                            } else {
                                vm.selectedPart = null;
                            }
                        }

                        function chooseSelectedOfferFn(offers) {
                            angular.forEach(offers, function (offer) {
                                if (offer.totalPrice > 0 && vm.filter(offer)) {
                                    vm.selectedOffer = offer;
                                }
                            });
                        }

                        function filterFn(offer) {
                            var filter = false;
                            /*The offer should either have a supplier and not be in Cancelled State or should have no
             supplier and Internal.The offer should have offer parts.*/

                            if (
                                offer.offerParts &&
                                ((offer.supplier && offer.statusDetailed.name != 'CANCELED') ||
                                    offer.statusDetailed.name == 'INTERNAL')
                            ) {
                                angular.forEach(offer.offerParts, function (part) {
                                    if (part.price || part.finalPrice) {
                                        filter = true;
                                    }
                                    if (part.hasRequestOfferPartOptions && !filter) {
                                        angular.forEach(part.offerPartOptions, (offerPartOption) => {
                                            if (offerPartOption.price || offerPartOption.finalPrice) {
                                                filter = true;
                                            }
                                        });
                                    }
                                });
                            }
                            return filter;
                        }

                        function filterOffersToDisplayFn(requestOffers) {
                            var filteredOffers = [];
                            angular.forEach(requestOffers, function (offer) {
                                if (vm.filter(offer)) {
                                    filteredOffers.push(offer);
                                }
                            });
                            return filteredOffers;
                        }

                        function initFn() {
                            $scope.startProgress();
                            vm.requestId = vm.request.id;
                            vm.chooseSelectedOffer(vm.request.requestOffers);
                            vm.requestOffers = vm.request.requestOffers;
                            vm.selectedCurrencySymbol = vm.request.requestOffers[0].currency.symbol;
                            vm.processBudgetData(vm.filterOffersToDisplay(vm.request.requestOffers));
                            BudgetDataShareService.setSelectedOfferList(vm.requestId, vm.selectedOffer); // offers

                            /*This is for refreshing the selected offer list when an Internal offer has been made External,
             thereby removing the offer from the selected offer list. For an internal offer, the request will always
             have only one request offer.*/
                            if (vm.requestOffers.length == 1) {
                                BudgetDataShareService.checkAndRemoveOfferFromSelectedOfferList(
                                    vm.requestId,
                                    vm.requestOffers[0]
                                );
                            }

                            vm.selectedOfferListPrice();
                            vm.setSelectedOffersOriginalPrice();
                            vm.setDisplayDiffColmn(vm.selectedOffer);
                            $scope.endProgress();
                        }

                        function processBudgetDataFn(filteredOffers) {
                            vm.offerWiseTotal = [];
                            if (filteredOffers.length <= 0) {
                                return;
                            }
                            let _offer = filteredOffers[0];
                            var originalParts = requestOfferPartsUtil.filterOfferPartsForCreator(
                                _offer,
                                _offer.offerParts
                            );
                            var result = [];
                            angular.forEach(originalParts, function (part) {
                                if (part.nameCode !== 'common.translate.offer.part.type.cancellation.fee') {
                                    var myPart = {
                                        id: part.id,
                                        name: part.name,
                                        nameCode: part.nameCode,
                                        status: part.status,
                                        dateTimeFrom: part.dateTimeFrom,
                                        dateCreated: part.dateCreated,
                                        amountType: part.amountType,
                                        amount: part.amount,
                                        hasRequestOfferPartOptions: part.isSuggestionEnabled,
                                    };
                                    let _selectedPartId = BudgetDataShareService.getSelectedPartIdForMarkup();
                                    if (_selectedPartId && _selectedPartId === part.id) {
                                        myPart.viewResource = true;
                                    }
                                    var offerWiseData = [];
                                    var offerWiseTotal = [];
                                    var offerWisePriceDiff = [];
                                    angular.forEach(filteredOffers, function (offer) {
                                        var total = 0;
                                        var totalWithMarkup = 0;
                                        var difference = 0;
                                        var offerData = {
                                            supplier: (offer.supplier && offer.supplier.name) ? offer.supplier.name : 'INTERNAL',
                                            status: offer.status.name,
                                            offer: {id: offer.id, status: offer.status, currency: offer.currency},
                                        };
                                        let _selectedOfferId = BudgetDataShareService.getSelectedOfferIdForMarkup();
                                        if (_selectedOfferId && _selectedOfferId === offerData.offer.id) {
                                            offerData.viewResource = true;
                                        }
                                        let _offerParts = requestOfferPartsUtil.filterOfferPartsForCreator(
                                            offer,
                                            offer.offerParts
                                        );
                                        angular.forEach(_offerParts, function (offerPart) {
                                            if (
                                                offerPart.nameCode !==
                                                'common.translate.offer.part.type.cancellation.fee'
                                            ) {
                                                var totalPrice = 0;
                                                var totalOptionsPriceWithMarkup = 0;
                                                offerPart.newPrice = offerPart.newPrice
                                                    ? offerPart.newPrice
                                                    : offerPart.price;
                                                if (part.referenceId == offerPart.referenceId) {
                                                    offerData.id = offerPart.id;
                                                    offerData.name = offerPart.name;
                                                    offerData.nameCode = offerPart.nameCode;
                                                    offerData.vat = offerPart.vat;
                                                    offerData.price = offerPart.price;
                                                    offerData.newPrice = offerPart.newPrice;
                                                    offerData.finalPrice = offerPart.finalPrice;
                                                    offerData.amount = offerPart.amount;
                                                    offerData.finalAmount = offerPart.finalAmount;
                                                    offerData.markupValue = offerPart.markupValue;
                                                    offerData.offerPartOptions = offerPart.offerPartOptions;
                                                    offerData.hasRequestOfferPartOptions =
                                                        offerPart.hasRequestOfferPartOptions;
                                                }
                                                difference = difference + vm.getDifference(offer, offerPart);
                                                if (offerPart.hasRequestOfferPartOptions) {
                                                    total += vm.getRequestPartOptionTotalPrice(offer, offerPart);
                                                    totalOptionsPriceWithMarkup =
                                                        vm.getRequestPartOptionTotalPriceWithMarkup(offer, offerPart);
                                                } else {
                                                    totalPrice = vm.getTotalPrice(offer, offerPart);
                                                }
                                                var adjustedTotal = totalPrice;
                                                total += adjustedTotal;
                                                if (offerPart.markupValue > 0) {
                                                    adjustedTotal =
                                                        totalPrice + (totalPrice * offerPart.markupValue) / 100;
                                                }
                                                adjustedTotal += totalOptionsPriceWithMarkup;
                                                totalWithMarkup += adjustedTotal;
                                            }
                                        });
                                        offerWiseTotal.push({
                                            total: total,
                                            currency: offer.currency,
                                            totalWithMarkup: totalWithMarkup,
                                        });
                                        offerWiseData.push(offerData);
                                        offerWisePriceDiff.push({ difference: difference, currency: offer.currency });
                                    });
                                    myPart.offerWiseData = offerWiseData;
                                    vm.offerWiseTotal = offerWiseTotal;
                                    vm.offerWisePriceDiff = offerWisePriceDiff;
                                    result.push(myPart);
                                }
                            });
                            vm.offerWiseDataList = result[0].offerWiseData;
                            vm.finalData = result;
                        }

                        function getRequestPartOptionTotalPriceFn(offer, offerWiseData, originalPrice = false) {
                            let _offerPartOptions = _.filter(offerWiseData.offerPartOptions, (offerPartOption) => {
                                return (
                                    offerPartOption.isSelected === true &&
                                    offerPartOption.status.name !== 'NOT_DELIVERABLE'
                                );
                            });
                            let _totalPrice = 0;
                            angular.forEach(_offerPartOptions, (_offerPartOption) => {
                                _totalPrice += amountAndPriceService.getRequestPartOptionTotalPrice(
                                    offer,
                                    _offerPartOption,
                                    originalPrice
                                );
                            });
                            return _totalPrice;
                        }

                        function getRequestPartOptionTotalPriceWithMarkupFn(
                            offer,
                            offerWiseData,
                            originalPrice = false
                        ) {
                            let _offerPartOptions = _.filter(offerWiseData.offerPartOptions, (offerPartOption) => {
                                return (
                                    offerPartOption.isSelected === true &&
                                    offerPartOption.status.name !== 'NOT_DELIVERABLE'
                                );
                            });
                            let _totalPrice = 0;
                            angular.forEach(_offerPartOptions, (_offerPartOption) => {
                                let _price = amountAndPriceService.getRequestOfferPartOptionPrice(
                                    offer,
                                    _offerPartOption
                                );
                                if (_offerPartOption.markupValue > 0) {
                                    _price += (_price * _offerPartOption.markupValue) / 100;
                                }
                                let _amount = amountAndPriceService.getRequestOfferPartOptionAmount(
                                    offer,
                                    _offerPartOption
                                );
                                _totalPrice = _totalPrice + _price * _amount;
                            });
                            return _totalPrice;
                        }

                        function selectedOfferListPriceFn() {
                            let totalPriceByCurrency = [];
                            angular.forEach(BudgetDataShareService.getSelectedOfferList(), function (offer) {
                                let existingOfferWithInListWithOfferCurrency = _.findWhere(totalPriceByCurrency, {
                                    currencyId: offer.currency.id,
                                });
                                if (!existingOfferWithInListWithOfferCurrency) {
                                    totalPriceByCurrency.push({
                                        currencyId: offer.currency.id,
                                        currency: offer.currency,
                                        total: 0,
                                        totalWithMarkup: 0,
                                    });
                                    existingOfferWithInListWithOfferCurrency =
                                        totalPriceByCurrency[totalPriceByCurrency.length - 1];
                                }
                                existingOfferWithInListWithOfferCurrency.total += getSelectedOfferTotalPrice(offer);
                                existingOfferWithInListWithOfferCurrency.totalWithMarkup +=
                                    getSelectedOfferTotalPriceWithMarkup(offer);
                            });
                            BudgetDataShareService.setTotalPriceForBudgetByCurrency(totalPriceByCurrency);
                        }

                        function setDisplayDiffColmnFn(offer) {
                            if (offer) {
                                vm.showDiffColumn = amountAndPriceService.hasUpdatedFinalPriceOrAmount(
                                    offer,
                                    offer.offerParts
                                );
                                BudgetDataShareService.setShowDiffColumn(vm.showDiffColumn);
                                vm.setSelectedOffersPriceDifference();
                            }
                        }

                        function setSelectedOffersOriginalPriceFn() {
                            var originalPrice = 0;
                            angular.forEach(BudgetDataShareService.getSelectedOfferList(), function (offer) {
                                originalPrice += getSelectedOfferOriginalPrice(offer);
                            });
                            BudgetDataShareService.setOriginalPrice(originalPrice);
                        }

                        function setSelectedOffersPriceDifferenceFn() {
                            let priceDifferenceByCurrency = [];
                            angular.forEach(BudgetDataShareService.getSelectedOfferList(), function (offer) {
                                let existingOfferWithInListWithOfferCurrency = _.findWhere(priceDifferenceByCurrency, {
                                    currencyId: offer.currency.id,
                                });
                                if (!existingOfferWithInListWithOfferCurrency) {
                                    priceDifferenceByCurrency.push({
                                        currencyId: offer.currency.id,
                                        currency: offer.currency,
                                        total: 0,
                                    });
                                    existingOfferWithInListWithOfferCurrency =
                                        priceDifferenceByCurrency[priceDifferenceByCurrency.length - 1];
                                }
                                existingOfferWithInListWithOfferCurrency.total += getPriceDifference(offer);
                            });
                            BudgetDataShareService.setPriceDifferenceByCurrency(priceDifferenceByCurrency);
                        }

                        function getPriceDifference(offer) {
                            return getSelectedOfferTotalPrice(offer) - getSelectedOfferOriginalPrice(offer);
                        }

                        function getSelectedOfferTotalPrice(offer) {
                            let offerTotal = 0;
                            let _offerParts = requestOfferPartsUtil.filterOfferPartsForCreator(offer, offer.offerParts);
                            angular.forEach(_offerParts, function (offerPart) {
                                if (offerPart.nameCode !== 'common.translate.offer.part.type.cancellation.fee') {
                                    var total = 0;
                                    if (offerPart.hasRequestOfferPartOptions) {
                                        total += vm.getRequestPartOptionTotalPrice(offer, offerPart);
                                    } else {
                                        total += vm.getTotalPrice(offer, offerPart);
                                    }
                                    /*if (offerPart.markupValue > 0) {
                  total += total * offerPart.markupValue / 100;
                }*/
                                    offerTotal += total;
                                }
                            });
                            return offerTotal;
                        }

                        function getSelectedOfferTotalPriceWithMarkup(offer) {
                            let offerTotalWithMarkup = 0;
                            let _offerParts = requestOfferPartsUtil.filterOfferPartsForCreator(offer, offer.offerParts);
                            angular.forEach(_offerParts, function (offerPart) {
                                if (offerPart.nameCode !== 'common.translate.offer.part.type.cancellation.fee') {
                                    var total = 0;
                                    if (offerPart.hasRequestOfferPartOptions) {
                                        total += vm.getRequestPartOptionTotalPriceWithMarkup(offer, offerPart);
                                    } else {
                                        total += vm.getTotalPrice(offer, offerPart);
                                        if (offerPart.markupValue > 0) {
                                            total += (total * offerPart.markupValue) / 100;
                                        }
                                    }
                                    offerTotalWithMarkup += total;
                                }
                            });
                            return offerTotalWithMarkup;
                        }

                        function getSelectedOfferOriginalPrice(offer) {
                            let offerTotal = 0;
                            let _offerParts = requestOfferPartsUtil.filterOfferPartsForCreator(offer, offer.offerParts);
                            angular.forEach(_offerParts, function (offerPart) {
                                if (offerPart.nameCode !== 'common.translate.offer.part.type.cancellation.fee') {
                                    var total = vm.getTotalPrice(offer, offerPart, true);
                                    if (offerPart.markupValue > 0) {
                                        total += (total * offerPart.markupValue) / 100;
                                    }
                                    offerTotal += total;
                                }
                            });
                            return offerTotal;
                        }

                        function getFixedMarkupValueFn(markupValue, digitsAfterDecimal = 0) {
                            if (!markupValue) {
                                return 0;
                            }
                            return amountAndPriceService.round(markupValue).toFixed(digitsAfterDecimal);
                        }

                        function editRequestOfferPartFn(selected) {
                            if(vm.viewModal) {
                                return;
                            }
                            vm.viewModal = true;
                            let updatePartMarkupModalData = updatePartMarkupModal;
                            setSelectedPartToEditFn(selected, vm.requestId);
                            let _customerPrice = vm.getCustomerPrice(vm.selectedPart.offer, vm.selectedPart);
                            let _data = { part: vm.selectedPart, customerPrice: _customerPrice };
                            updatePartMarkupModalData.inputs = { data: _data };
                            ModalService.showModal(updatePartMarkupModalData).then((modal) => {
                                vm.viewModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        }

                        function editRequestMarkupValueFn() {
                            if(vm.viewModal) {
                                return;
                            }
                            vm.viewModal = true;
                            let updateRequestMarkupModalData = updateRequestMarkupModal;
                            let _data = {
                                requestId: vm.request.id,
                                markupValue: vm.request.markupValue,
                            };
                            updateRequestMarkupModalData.inputs = { data: _data };
                            ModalService.showModal(updateRequestMarkupModalData).then((modal) => {
                                vm.viewModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        }

                        function toggleResourceViewOptionFn(part, offerWiseData) {
                            if (offerWiseData.viewResource && part.viewResource) {
                                part.viewResource = false;
                                offerWiseData.viewResource = false;
                            } else {
                                angular.forEach(part.offerWiseData, (_data) => {
                                    _data.viewResource = false;
                                });
                                part.viewResource = true;
                                offerWiseData.viewResource = true;
                            }
                        }

                        let isSelectedValueChanged = $rootScope.$on(
                            'selected.value.changed.markup.page',
                            (event, data) => {
                                let _offerPart = data.offerPart;
                                vm.processBudgetData(vm.filterOffersToDisplay(vm.request.requestOffers));
                                vm.selectedOfferListPrice();
                                let _selectedPart = _.find(vm.finalData, (part) => {
                                    return part.id === _offerPart.id;
                                });
                                if (_selectedPart) {
                                    _selectedPart.viewResource = true;
                                    let _selectedOfferWiseDataIndex = _.findIndex(_offerPart.offerWiseData, (_data) => {
                                        return _data.viewResource;
                                    });
                                    _selectedPart.offerWiseData[_selectedOfferWiseDataIndex].viewResource = true;
                                }
                            }
                        );

                        let isMarkupValueChanged = $rootScope.$on('markup.value.changed', (event, data) => {
                            let _offerPart = data.offerPart;
                            vm.processBudgetData(vm.filterOffersToDisplay(vm.request.requestOffers));
                            let _selectedPart = _.find(vm.finalData, (part) => {
                                return part.id === _offerPart.id;
                            });
                            if (_selectedPart) {
                                _selectedPart.viewResource = true;
                                let _selectedOfferWiseDataIndex = _.findIndex(_offerPart.offerWiseData, (_data) => {
                                    return _data.viewResource;
                                });
                                _selectedPart.offerWiseData[_selectedOfferWiseDataIndex].viewResource = true;
                                BudgetDataShareService.setSelectedOfferIdForMarkup(
                                    _selectedPart.offerWiseData[_selectedOfferWiseDataIndex].offer.id
                                );
                            }
                        });

                        $scope.$on('$destroy', isSelectedValueChanged);
                        $scope.$on('$destroy', isMarkupValueChanged);
                    },
                ],
            };
        },
    ]);
})();
