'use strict';
angular.module('core.existingServices').factory('GeoTag', [
    '$resource',
    function ($resource) {
        var v1GeoTagRest = $resource(
            'api/v1/geotag/:tagId',
            {tagId: '@tagId'},
            {
                getMap: {method: 'GET', isArray: true},
            }
        );

        var v1SelectedGeoTagRest = $resource(
            'api/v1/geotag/selectedGeoTag/:id',
            {id: '@id'},
            {
                get: {method: 'GET', isArray: true},
            }
        );

        var v1GeoTagList = $resource(
            'api/v1/geotag/geotags',
            {},
            {
                list: {method: 'POST', isArray: true},
            }
        );

        return {
            getMap: function (jsonObject, successCallback, errorCallback) {
                return v1GeoTagRest.getMap(jsonObject, successCallback, errorCallback);
            },
            selectedGeoTag: function (jsonObject, successCallback, errorCallback) {
                return v1SelectedGeoTagRest.get(jsonObject, successCallback, errorCallback);
            },
            getList: function (jsonObject, successCallback, errorCallback) {
                return v1GeoTagList.list(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

