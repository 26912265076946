import * as _ from "underscore";
import {OgModal} from "./global/ogModal";


class CommonFunctions {

    constructor() {
        this.$rootScope = null;
    }

    setRootScope($rootScope) {
        this.$rootScope = $rootScope;
    }

    hasIntersectValue(aList, bList) {
        if (aList && bList) {
            var commonValue = this.intersect(aList, bList);
            return commonValue.length > 0;
        }
    }

    intersect(aList, bList) {
        if (aList && bList) {
            var result = aList.filter(function (obj) {
                return bList.indexOf(obj) != -1;
            });
            return result;
        }
    }

    formatClientName(text) {
        if (text) {
            var toLower = text.trim().toLowerCase();
            text = toLower.replaceAll(' ', '_');
        }
        return text;
    }

    isItFinishedOffer(offer) {
        return this.hasIntersectValue(
            [offer.status.name],
            ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR', 'APPROVED', 'FINISHED_WAITING_FOR_SUPPLIER']
        );
    }

    isItCanceledOffer(offer) {
        return this.hasIntersectValue(
            [offer.status.name],
            [
                'CANCELED_AND_FINISHED',
                'CANCELED_WAITING_FOR_CREATOR',
                'CANCELED_AND_APPROVED',
                'CANCELED_WAITING_FOR_SUPPLIER',
            ]
        );
    }

    setSameHeight(className) {
        setTimeout(function () {
            jQuery('.' + className).matchHeight();
        }, 2000);
    };

    touchFormFields(form) {
        angular.forEach(form.$error, function (field) {
            angular.forEach(field, function (errorField) {
                errorField.$setTouched();
            });
        });
    }

    convertToLowerCase(aList) {
        if (aList) {
            var result = aList.map(function (item) {
                return angular.lowercase(item);
            });
            return result;
        }
    }

    doAlertBeforeUpdate(offerPart) {
        return (
            angular.isDefined(offerPart) &&
            angular.isDefined(offerPart.editable) &&
            ['NOTIFY_BEFORE_EDIT', 'DRAG_DROP_DENIED'].includes(offerPart.editable.name)
        );
    }

    accessDeniedToUpdate(offerPart) {
        if (angular.isDefined(offerPart) && offerPart.editable.name === 'DENIED') {
            return true;
        }
        return false;
    }

    isMapAlreadyLoaded() {
        if (typeof google == 'undefined') {
            return false;
        } else {
            return true;
        }
    }

    findItemInListById(items, id) {
        var objId = Number(id);
        return _.findWhere(items, {id: objId});
    }

    alert(errorResponse) {
        var currentLocation = window.location;
        if (currentLocation != '/') {
            OgModal.open('alert', {response: errorResponse});
        }
    }

    errorMessages(errorResponse, includeFieldErrors) {
        var message = 'operation.failed';
        var status = errorResponse.status;
        if (status == 401 || status == 403) {
            message = 'http.' + errorResponse.status;
        } else if (status == 0) {
            message = 'connection.error';
        }
        var data = errorResponse.data;
        if (data == null) {
            data = errorResponse;
        }
        if (data && data.localisedMessages && data.localisedMessages.length >= 1) {
            var messages = data.localisedMessages;
            angular.forEach(messages, function (message) {
                var params = {};
                var i = 0;
                angular.forEach(message.params, function (param) {
                    params['p' + i] = param;
                    i++;
                });
                message.type = 'ERROR';
                message.params = params;
            });
            if (includeFieldErrors) {
                messages = messages.concat(data.fieldErrors);
            }
            return messages;
        } else {
            return [{messageKey: message}];
        }
    }

    alertWithCallBack(msgAndCallBack) {
        var currentLocation = window.location;
        if (currentLocation != '/') {
            OgModal.open('alert', msgAndCallBack);
        }
    }
}

const commonFunction = new CommonFunctions();
export default commonFunction;
