import controller from './og_offer_activities.controller.js';
(function () {
    'use strict';
    angular.module('ogOfferActivities').directive('ogOfferActivities', [
        function () {
            return {
                scope: {
                    activityType: '=', //message, NOTE, all
                    offer: '=',
                    event: '=',
                    newActivity: '=',
                    closeMessages: '=',
                },
                templateUrl: './app/components/directives/offer_activities/og_offer_activities.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogOfferActivitiesController',
                bindToController: true,
            };
        },
    ]);
})();
