'use strict';
angular.module('core.existingServices').factory('FileShare', [
    '$resource',
    function ($resource) {
        var v1FileShareEventRest = $resource(
            'api/v1/fileshare/event/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: true},
                save: {method: 'POST', isArray: false},
            }
        );

        var v1FileShareDeleteRest = $resource(
            'api/v1/fileshare/:fileShareId',
            {fileShareId: '@fileShareId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );

        return {
            list: function (eventId, successCallback, errorCallback) {
                return v1FileShareEventRest.list({eventId: eventId}, successCallback, errorCallback);
            },
            save: function (eventId, fileShareDto, successCallback, errorCallback) {
                return v1FileShareEventRest.save(
                    {eventId: eventId},
                    fileShareDto,
                    successCallback,
                    errorCallback
                );
            },
            delete: function (fileShareId, successCallback, errorCallback) {
                return v1FileShareDeleteRest['delete'](
                    {fileShareId: fileShareId},
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);

