import controller from './og_common_translation.controller';

(function () {
    'use strict';

    angular.module('ogCommonTranslate').directive('ogCommonTranslate', [
        function () {
            return {
                scope: {
                    name: '=',
                    code: '=',
                },
                templateUrl: './app/components/directives/common_translation/common_translation.view.html',
                restrict: 'AE',
                controller,
                controllerAs: 'commonTranslationCtrl',
                bindToController: true,
            };
        },
    ]);
})();
