import amountAndPriceService from '../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';
import {OgModal} from "../../../../utils/global/ogModal";

(function () {
    'use strict';

    angular.module('ogBudgetClientSelection').directive('ogBudgetClientSelection', [
        function () {
            return {
                restrict: 'EA',
                scope: {
                    showMarkedPrice: '=',
                    requests: '=',
                    event: '=',
                    eventCreator: '=',
                    sharedPublicly: '=',
                    decisionMaker: '=',
                    decisionLastUpdated: '=',
                    showInternalContentComment: '=',
                    isPrintCall: '=',
                },
                templateUrl:
                    './app/components/directives/budget/budget_client_selection/og.budget.client.selection.view.html',
                controllerAs: 'ogBudgetClientSelectionCtrl',
                bindToController: true,
                controller: [
                    '$scope',
                    '$stateParams',
                    'BudgetDataShareService',
                    'BrowserDetectService',
                    'Event',
                    'ClientSelectedContent',
                    '$rootScope',
                    'DateService',
                    'TimezoneService',
                    'EventScheduleDataStore',
                    'EventSummary',
                    'EventEnum',
                    '$timeout',
                    function (
                        $scope,
                        $stateParams,
                        BudgetDataShareService,
                        BrowserDetectService,
                        Event,
                        ClientSelectedContent,
                        $rootScope,
                        DateService,
                        TimezoneService,
                        EventScheduleDataStore,
                        EventSummary,
                        EventEnum,
                        $timeout
                    ) {
                        var vm = this;
                        var eventId = $stateParams.eventId;

                        this.$onInit = function () {
                            vm = this;
                            eventId = $stateParams.eventId;

                            vm.getOriginalPrice = getOriginalPriceFn;
                            vm.getShowDiffColumn = getShowDiffColumnFn;
                            vm.getTotalPriceForBudget = getTotalPriceForBudgetFn;
                            vm.getTotalPriceForBudgetByCurrency = getTotalPriceForBudgetByCurrencyFn;
                            vm.getPriceDifferenceByCurrency = getPriceDifferenceByCurrencyFn;
                            vm.getAmountLocalisedWithCurrency = amountAndPriceService.getAmountLocalisedWithCurrency;
                            vm.includeVat = vm.event.enableVat;
                            vm.openSummaryTab = openSummaryTabFn;
                            vm.exportEventSummaryToPDF = exportEventSummaryToPDFFn;
                            vm.editBookingTerms = editBookingTermsFn;
                            vm.seeBookingTerms = seeBookingTermsFn;
                            vm.getBookingTerms = getBookingTermsFn;
                            vm.updateBookingTerms = updateBookingTermsFn;
                            vm.proceedBookingTermUpdate = proceedBookingTermUpdateFn;
                            vm.shareSelectionToCreator = shareSelectionToCreatorFn;
                            vm.parseDateInLocaleFormat = parseDateInLocaleFormatFn;
                            vm.cancelBooking = cancelBookingFn;
                            vm.handleOnchanged = handleOnchangedFn;
                            vm.toggleBudgetView = toggleBudgetViewFn;
                            vm.getDiscountForBudget = getDiscountForBudgetFn;
                            vm.editBookingTerm = false;
                            vm.proceedBookingTerm = false;
                            vm.enableAgent = vm.event && vm.event.ownerGroup && vm.event.ownerGroup.showMarkup;
                            vm.supplierDiscountEnabled = vm.event && vm.event.ownerGroup && vm.event.ownerGroup.supplierDiscount.name === 'TRUE';
                            vm.event.budgetView = 'advanced';

                            ScopeFunctions.addToScope($scope);
                            initFn();
                            vm.getBookingTerms();
                        }

                        $rootScope.$on(EventEnum.EVENT_VAT_SETTING_CHANGED, (event, data) => {
                            vm.includeVat = data.isVatEnabled;
                        });

                        if ($stateParams.highlightElement === 'eventSummaryButton') {
                            vm.animateEventSummaryButton = true;
                            setTimeout(function () {
                                vm.animateEventSummaryButton = false;
                            }, 6800);
                        }

                        function initFn() {
                            BudgetDataShareService.clearSelectedOfferList();
                            BudgetDataShareService.setTotalPriceForBudgetByCurrency([]);
                        }

                        function getOriginalPriceFn() {
                            return BudgetDataShareService.getOriginalPrice();
                        }

                        function getShowDiffColumnFn() {
                            return BudgetDataShareService.getShowBudgetDiffColumn();
                        }

                        function getTotalPriceForBudgetFn() {
                            return BudgetDataShareService.getTotalPriceForBudget();
                        }

                        function getTotalPriceForBudgetByCurrencyFn() {
                            return BudgetDataShareService.getTotalPriceForBudgetByCurrency();
                        }

                        function getDiscountForBudgetFn() {
                            let _totalPriceForBudget = BudgetDataShareService.getTotalPriceForBudgetByCurrency()[0];
                            let _discount = 0;
                            if (_totalPriceForBudget) {
                                _discount = _totalPriceForBudget.discount;
                            }
                            return _discount;
                        }

                        function getBookingTermsFn() {
                            $scope.startProgress();
                            Event.getBookingTerms(
                                vm.event.id,
                                (resp) => {
                                    vm.bookingTerms = resp.bookingTerms;
                                    $scope.endProgress();
                                },
                                $scope.endWithError
                            );
                        }

                        function updateBookingTermsFn(proceedForFuture) {
                            vm.editBookingTerm = false;
                            vm.proceedBookingTerm = false;
                            $scope.startProgress();
                            Event.updateBookingTerms(
                                {
                                    eventId: vm.event.id,
                                    bookingTerms: vm.bookingTerms,
                                    proceedForFuture: proceedForFuture,
                                },
                                (response) => {
                                    vm.bookingTerms = response.bookingTerms;
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay()
                            );
                        }

                        function shareSelectionToCreatorFn() {
                            $scope.startProgress('budget-table');
                            ClientSelectedContent.save(
                                vm.requests,
                                (response) => {
                                    vm.selectedContentList = response;
                                    $rootScope.$broadcast('dialog:alert:open', {
                                        message: 'event.summary.successfully.booked.by.client',
                                    });
                                    $scope.endProgress('budget-table');
                                },
                                $scope.endWithErrorOverlay()
                            );
                        }

                        function proceedBookingTermUpdateFn() {
                            vm.editBookingTerm = false;
                            vm.proceedBookingTerm = true;
                        }

                        function getPriceDifferenceByCurrencyFn() {
                            return BudgetDataShareService.getPriceDifferenceByCurrency();
                        }

                        function cancelBookingFn() {
                            vm.editBookingTerm = false;
                            vm.proceedBookingTerm = false;
                        }

                        function openSummaryTabFn() {
                            if (vm.requests && vm.requests.length > 0) {
                                window.open('/event/' + vm.event.id + '/summary', '_blank');
                            } else {
                                OgModal.open('alert', { message: 'event.presentation.empty' });
                            }
                        }

                        function editBookingTermsFn() {
                            vm.editBookingTerm = true;
                        }

                        function seeBookingTermsFn() {
                            $rootScope.$broadcast('dialog:alert:open', {
                                message: vm.bookingTerms,
                            });
                        }

                        function handleOnchangedFn(modalName, currentValue) {
                            fieldChanged.checkOnChanged(modalName, currentValue);
                            $scope.viewUpdateButton = fieldChanged.isFieldChanged();
                        }

                        function parseDateInLocaleFormatFn(date) {
                            let dateFormat = DateService.getDateFormatByLocale();
                            return TimezoneService.formatDate(new Date(parseInt(date)), dateFormat);
                        }

                        function exportEventSummaryToPDFFn() {
                            $scope.startProgress('budget-table');
                            BrowserDetectService.setPrintSummary(false);
                            EventSummary.schedule(
                                { eventId: vm.event.id },
                                (response) => {
                                    BrowserDetectService.setPrintSummary(true);
                                    EventScheduleDataStore.setSchedule(response.eventSchedule);

                                    $timeout(() => {
                                        $scope.endProgress('budget-table');
                                        print();
                                    }, 3500);
                                },
                                $scope.endWithErrorOverlay
                            );
                        }

                        function print() {
                            let isItIE = BrowserDetectService.isItIE();
                            if (!!isItIE) {
                                jQuery('#event-summary-print-section').printElement({
                                    leaveOpen: false,
                                    printMode: 'popup',
                                    overrideElementCSS: ['/app/print-css/schedule-print.css'],
                                });
                            } else {
                                jQuery('#event-summary-print-section').printThis({
                                    importCSS: false,
                                    importStyle: false,
                                    loadCSS: '/app/print-css/schedule-print.css',
                                    printDelay: '3000',
                                });
                            }
                        }

                        function toggleBudgetViewFn(view) {
                            vm.event.budgetView = view;
                        }
                    },
                ],
            };
        },
    ]);
})();
