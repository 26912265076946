class OgDropdownController {
    constructor($scope, $document, $element, CommonFunctions) {
        this._injected = {
            $scope,
            $document,
            $element,
            CommonFunctions,
        };
        this.listVisible = false;
        this.isPlaceholder = true;

        $scope.$watch('ogDropdownCtrl.selected', (newval) => {
            if (newval) {
                this.updateSelectedText();
            } else {
                this.isPlaceholder = true;
            }
        });

        $scope.$on(
            'lang:change',
            function (event, data) {
                //$timeout(function () {
                this.updateSelectedText();
                //}, 1000);
            }.bind(this)
        );

        let documentClick = function (event) {
            if ($element.find(event.target).length > 0) {
                return;
            }
            this.listVisible = false;
        }.bind(this);

        $document.on('click', documentClick);

        //unbind the click listener
        $scope.$on('$destroy', function () {
            $document.off('click', documentClick);
        });
    }

    select(item) {
        this.isPlaceholder = false;
        this.selected = item;
        this.listVisible = false;
        if (angular.isDefined(this.onChange)) {
            this.onChange(this.selected);
        }
    }

    toggle() {
        this.listVisible = !this.listVisible;
    }

    getTextToDisplay(item) {
        const {CommonFunctions} = this._injected;
        if (item.nameCode) {
            return CommonFunctions.getTranslatedTextFromCode(item.name, item.nameCode);
        }
        return this.property !== undefined ? item[this.property] : item;
    }

    updateSelectedText() {
        const {CommonFunctions} = this._injected;
        this.isPlaceholder = this.selected && this.selected[this.property] === undefined;
        if (this.selected && this.selected.name && this.selected.nameCode) {
            this.display = CommonFunctions.getTranslatedTextFromCode(this.selected.name, this.selected.nameCode);
        } else {
            this.display = this.selected ? this.selected[this.property] : '';
        }
    }
}

OgDropdownController.$inject = ['$scope', '$document', '$element', 'CommonFunctions'];
export default OgDropdownController;
