angular.module('change.password').component('changePassword', {
    templateUrl: './app/components/modules/auth/change_password/change_password.view.html',
    controller: ['$stateParams', '$rootScope', 'ExternalAccessService', 'AuthenticationService', '$translate', 'CustomerUser', 'CommonFunctions',
        function ForgottenPasswordController(
            $stateParams,
            $rootScope,
            ExternalAccessService,
            AuthenticationService,
            $translate,
            CustomerUser,
            CommonFunctions
        ) {
            var vm = this;

            vm.changePassword = changePasswordFn;
            vm.validatePassword = validatePasswordFn;
            vm.showPasswordStrength = showPasswordStrengthFn;
            vm.password1 = '';
            vm.password2 = '';
            vm.passwordChanged = false;
            vm.passwordHash = $stateParams.hash;
            vm.passwordsNotMatch = passwordsNotMatchFn;
            vm.switchToEn = switchToEnFn;
            vm.switchToSv = switchToSvFn;
            vm.switchToNb = switchToNbFn;
            vm.switchToDa = switchToDaFn;
            vm.switchToFi = switchToFiFn;
            vm.generatedPassword = '';
            vm.getGeneratedPassword = getGeneratedPasswordFn;
            vm.fillPasswordFields = fillPasswordFieldsFn;

            if ($stateParams.locale) {
                if ($stateParams.locale === 'en') {
                    vm.switchToEn();
                } else if ($stateParams.locale === 'sv') {
                    vm.switchToSv();
                } else if ($stateParams.locale === 'nb') {
                    vm.switchToNb();
                } else if ($stateParams.locale === 'da') {
                    vm.switchToDa();
                } else {
                    vm.switchToFi();
                }
            } else {
                vm.activeEn = $rootScope.langSelected === 'en_US' ? 'active' : '';
                vm.activeSv = $rootScope.langSelected === 'sv_SV' ? 'active' : '';
                vm.activeNb = $rootScope.langSelected === 'nb_Nb' ? 'active' : '';
                vm.activeDk = $rootScope.langSelected === 'da_DK' ? 'active' : '';
                vm.activeFi = $rootScope.langSelected === 'fi_FI' ? 'active' : '';
            }

            function changePasswordFn() {
                if (!vm.passwordForm.$valid) {
                    CommonFunctions.touchFormFields(vm.passwordForm);
                    return;
                }
                if (vm.passwordsNotMatch() || !vm.validatePassword()) {
                    return;
                }
                var data = {
                    password: vm.password1,
                    form: 'passwordForm',
                    hash: vm.passwordHash,
                };
                AuthenticationService.changePassword(data, function (data) {
                    if (data.success) {
                        vm.passwordChanged = true;
                    }
                });
            }

            function validatePasswordFn() {
                if (!vm.password1) {
                    return true;
                }
                let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)');
                return regex.test(vm.password1);
            }

            function passwordsNotMatchFn() {
                return (vm.password1 || vm.password2) && vm.password1 != vm.password2;
            }

            function showPasswordStrengthFn(password) {
                if (password) {
                    //Uppercase Words only
                    if (password.match(/[A-Z]/) != null) {
                        jQuery('#uppercase-text .progress-text').addClass('active');
                    } else {
                        jQuery('#uppercase-text .progress-text').removeClass('active');
                    }
                    //Lowercase words only
                    if (password.match(/[a-z]/) != null) {
                        jQuery('#lowercase-text .progress-text').addClass('active');
                    } else {
                        jQuery('#lowercase-text .progress-text').removeClass('active');
                    }
                    //Digits only
                    if (password.match(/[0-9]/) != null) {
                        jQuery('#number-in-text .progress-text').addClass('active');
                    } else {
                        jQuery('#number-in-text .progress-text').removeClass('active');
                    }
                    //Password length
                    if (password.length > 7) {
                        jQuery('#eight-characters-long .progress-text').addClass('active');
                    } else {
                        jQuery('#eight-characters-long .progress-text').removeClass('active');
                    }
                } else {
                    jQuery('.progress-bar-wrap .progress-bar-item .progress-text').each(function () {
                        jQuery(this).removeClass('active');
                    });
                }
            }

            // fetch the suggested password and set to scope
            function getGeneratedPasswordFn() {
                CustomerUser.getPassword(
                    function (result) {
                        vm.generatedPassword = result.generatedPassword;
                    },
                    function (error) {
                        console.log('>>> Error has occurred while getting generated password from API ' + error);
                    }
                );
            }

            //fills the password with the suggested password
            function fillPasswordFieldsFn() {
                vm.password1 = vm.generatedPassword;
                vm.password2 = vm.generatedPassword;
            }

            function switchToEnFn() {
                $rootScope.switchLang('en_US');
                vm.activeEn = 'active';
                vm.activeSv = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToSvFn() {
                $rootScope.switchLang('sv_SV');
                vm.activeSv = 'active';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToNbFn() {
                $rootScope.switchLang('nb_NB');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = 'active';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToDaFn() {
                $rootScope.switchLang('da_DK');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = 'active';
                vm.activeFi = '';
            }

            function switchToFiFn() {
                $rootScope.switchLang('fi_FI');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = 'active';
            }

            ExternalAccessService.verify(vm.passwordHash, vm);
        }]
});