import controller from './og_add_supplier.controller.js';

(function () {
    'use strict';

    angular.module('add.supplier').directive('ogAddSupplier', [
        function () {
            return {
                scope: {
                    suppliers: '=',
                    view: '@',
                    declinedOffers: '=',
                    disableInfiniteScroll: '=',
                    addCallback: '&',
                    moreSuppliersCallback: '&',
                    loadingSupplier: '<',
                    isSuggestedSupplier: '=',
                    animateThumbnail: '=',
                },
                templateUrl: './app/components/directives/add_supplier/og_add_supplier.html',
                restrict: 'AE',
                controller,
                controllerAs: 'AddSupplierCtrl',
                bindToController: true,
            };
        },
    ]);
})();
