import controller from './og.additional.questions.controller';

(function () {
    'use strict';
    angular.module('dnQuestions').directive('dnTextRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    dnModel: '=',
                    className: '=',
                    disabled: '=',
                    required: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.text.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnTextCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnRadioRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    disabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.radio.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnRadioCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnTextAreaRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    disabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.textarea.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnTextAreaCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnDateRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    disabled: '=',
                    label: '=',
                    required: '=',
                    dnModel: '=',
                    className: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.date.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnDateCtrl',
                bindToController: true,
            };
        },
    ]);
    angular.module('dnQuestions').directive('dnEmailRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    disabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.email.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnEmailCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnCheckBoxRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    disabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.checkbox.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnCheckBoxCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnDropDownRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    disabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.dropdown.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnDropDownCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnNumberRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    dnModel: '=',
                    className: '=',
                    disabled: '=',
                    required: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.number.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnNumberCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnLabelRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.label.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnLabelCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnButtonRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    disabled: '=',
                    dnModel: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.button.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnButtonCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnIconRadioRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    disabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.icon.radio.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnIconRadioCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnIconMultiRadioRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    disabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.icon.multi.radio.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnIconMultiRadioCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnIconMultiCheckBoxRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    value: '=',
                    disabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.icon.multi.checkbox.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnIconMultiCheckBoxCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnMultiCheckBoxRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    disabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    onClick: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.multi.checkbox.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnMultiCheckBoxCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnTextWithAmountTypeRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    disabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    onClick: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                    participantAmount: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.text.with.amount.type.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnTextWithAmountTypeCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnTextWithGoogleAutosuggestRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    onClick: '=',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                },
                templateUrl:
                    './app/components/directives/dynamic_question/partials/readonly/dn.text.with.google.autosuggest.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnTextWithGoogleAutosuggestCtrl',
                bindToController: true,
            };
        },
    ]);

    angular.module('dnQuestions').directive('dnPriceRead', [
        function () {
            return {
                scope: {
                    question: '=',
                    dnDisabled: '=',
                    required: '=',
                    dnModel: '=',
                    errorMessage: '=',
                    onChange: '=',
                    onClick: '=',
                    isCreatorLabel: '=',
                    participantAmount: '=',
                },
                templateUrl: './app/components/directives/dynamic_question/partials/readonly/dn.price.html',
                restrict: 'AE',
                controller,
                controllerAs: 'dnPriceCtrl',
                bindToController: true,
            };
        },
    ]);
})();
