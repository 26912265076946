import AccommodationUtil from '../../../../utils/accommodation/AccommodationUtil';
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';
import * as _ from 'underscore';

class AddEditAccommodationModalCtrl {
    constructor(
        data,
        $scope,
        close,
        $element,
        $translate,
        AccommodationSchedule,
        EventSchedule,
        GlobalConstants,
        EventEnum,
        $rootScope,
        $timeout
    ) {
        this._injected = {
            data,
            $scope,
            close,
            $element,
            $translate,
            AccommodationSchedule,
            EventSchedule,
            GlobalConstants,
            EventEnum,
            $rootScope,
            $timeout,
        };

        this.eventId = data.eventId;
        this.selectedTab = data.requestId;
        this.beforeEventAccommodation = data.accommodationBeforeEvent;
        this.afterEventAccommodation = data.accommodationAfterEvent;
        this.accommodationDay = data.accommodationDay;
        this.hasBufferDaysAfterEvent = data.hasBufferDaysAfterEvent;
        this.dayBeforeDayAfterEventHasAccommodation = data.dayBeforeDayAfterEventHasAccommodation;
        this.successCallBack = data.successCallBack;
        this.accommodationScheduleForm = {};
        this.addOtherAccommodationId = -1;
        this.changeForAll = -1;
        this.changeForAllText = true;
        this.returnDayByDayEvent = false;
        this.parts = [];
        this.viewUpdateButton = false;
        this.duplicateOtherParts = false;
        this.duplicateOtherPartsList = [];

        ScopeFunctions.addToScope($scope);

        this.isAmountChanged = this.isAmountChanged.bind(this);
        this.getAccommodationSchedule();
        this.setPartsData = this.setPartsData.bind(this);

        let dayAfterEventAccommodationChanged = $rootScope.$on(
            'buffer.days.after.event.updates',
            function (event, data) {
                if (data) {
                    this.dayBeforeDayAfterEventHasAccommodation = data.dayBeforeDayAfterEventHasAccommodation;
                }
            }.bind(this)
        );
        $scope.$on('$destroy', dayAfterEventAccommodationChanged);
    }

    setPartsData() {
        this.selectedRequest = _.findWhere(this.schedule, { requestId: this.selectedTab });
        this.parts = this.selectedRequest.scheduleForRequest.timed;
        this.copyOriginalParts();
        if (this.beforeEventAccommodation) {
            this.showAddAccommodationBeforeEvent(this.selectedRequest);
        }
        if (this.afterEventAccommodation) {
            this.showAddAccommodationAfterEvent(this.selectedRequest);
        }
        let singleRooms = this.getAllSingleRoomsFromParts();
        let doubleRooms = this.getAllDoubleRoomsFromParts();
        let suites = this.getAllSuitesFromParts();
        let tripleRooms = this.getAllTripleRoomsFromParts();
        let quadRooms = this.getAllQuadRoomsFromParts();
        //let otherRooms = this.getAllOtherRoomsFromParts();
        let singleRoomActionRequired = this.hasActionRequiredPartInRooms(singleRooms);
        let doubleRoomActionRequired = this.hasActionRequiredPartInRooms(doubleRooms);
        let suiteActionRequired = this.hasActionRequiredPartInRooms(suites);
        let tripleRoomActionRequired = this.hasActionRequiredPartInRooms(tripleRooms);
        let quadRoomActionRequired = this.hasActionRequiredPartInRooms(quadRooms);
        let otherRoomActionRequired = false;
        /*angular.forEach(otherRooms, (otherRoom) => {
      if (!otherRoomActionRequired) {
        otherRoomActionRequired = this.hasActionRequiredPartInRooms(otherRoom);
      }
    })*/
        this.hasActionRequiredPart =
            singleRoomActionRequired ||
            doubleRoomActionRequired ||
            suiteActionRequired ||
            tripleRoomActionRequired ||
            quadRoomActionRequired ||
            otherRoomActionRequired;
    }

    isAmountChanged() {
        this.viewUpdateButton = false;
        if (this.beforeEventAccommodation) {
            this.isChangedBeforeAfterEvent(this.beforeEventAccommodation);
        }
        if (this.afterEventAccommodation) {
            this.isChangedBeforeAfterEvent(this.afterEventAccommodation);
        }
        this.parts.forEach((_currentPart, index) => {
            let _originalPart = this.originalParts[index];
            let _isValid = true;
            if (_isValid) {
                _isValid = this.isChanged(_originalPart, _currentPart);
            }
            return;
        });
    }

    isChangedBeforeAfterEvent(_currentPart) {
        let _otherRooms = _currentPart.otherRooms;

        let _otherWithoutName = _.filter(_otherRooms, (_other) => {
            return _.isEmpty(_other.name);
        });
        if (_otherWithoutName && _otherWithoutName.length > 0) {
            this.viewUpdateButton = false;
            return;
        }

        if (_currentPart.doubleRoom.amount > 0 || !_.isEmpty(_currentPart.doubleRoom.commentByCreator)) {
            this.viewUpdateButton = true;
            return;
        }

        if (_currentPart.singleRoom.amount > 0 || !_.isEmpty(_currentPart.singleRoom.commentByCreator)) {
            this.viewUpdateButton = true;
            return;
        }

        if (_currentPart.suite.amount > 0 || !_.isEmpty(_currentPart.suite.commentByCreator)) {
            this.viewUpdateButton = true;
            return;
        }

        if (_currentPart.tripleRoom.amount > 0 || !_.isEmpty(_currentPart.tripleRoom.commentByCreator)) {
            this.viewUpdateButton = true;
            return;
        }

        if (_currentPart.quadRoom.amount > 0 || !_.isEmpty(_currentPart.quadRoom.commentByCreator)) {
            this.viewUpdateButton = true;
            return;
        }

        if (_currentPart.defaultOtherRoom.amount > 0 || !_.isEmpty(_currentPart.defaultOtherRoom.commentByCreator)) {
            this.viewUpdateButton = true;
            return;
        }

        angular.forEach(_otherRooms, (_current) => {
            if (!this.viewUpdateButton && !_.isEmpty(_current.name) && _current.amount > 0) {
                this.viewUpdateButton = true;
                return;
            }
        });
    }

    isChanged(_originalPart, _currentPart) {
        if (!_originalPart && _currentPart) {
            this.viewUpdateButton = true;
            return;
        }
        let _otherRooms = _currentPart.otherRooms;
        let _originalOtherRooms = _originalPart.otherRooms;

        let _otherWithoutName = _.filter(_otherRooms, (_other) => {
            return _.isEmpty(_other.name);
        });
        if (_otherWithoutName && _otherWithoutName.length > 0) {
            this.viewUpdateButton = false;
            return false;
        }

        if (
            _originalPart &&
            (_currentPart.doubleRoom.amount !== _originalPart.doubleRoom.amount ||
                _currentPart.doubleRoom.commentByCreator !== _originalPart.doubleRoom.commentByCreator)
        ) {
            this.viewUpdateButton = true;
            return;
        }

        if (
            _originalPart &&
            (_currentPart.singleRoom.amount !== _originalPart.singleRoom.amount ||
                _currentPart.singleRoom.commentByCreator !== _originalPart.singleRoom.commentByCreator)
        ) {
            this.viewUpdateButton = true;
            return;
        }

        if (
            _originalPart &&
            (_currentPart.suite.amount !== _originalPart.suite.amount ||
                _currentPart.suite.commentByCreator !== _originalPart.suite.commentByCreator)
        ) {
            this.viewUpdateButton = true;
            return;
        }

        if (
            _originalPart &&
            (_currentPart.tripleRoom.amount !== _originalPart.tripleRoom.amount ||
                _currentPart.tripleRoom.commentByCreator !== _originalPart.tripleRoom.commentByCreator)
        ) {
            this.viewUpdateButton = true;
            return;
        }

        if (
            _originalPart &&
            (_currentPart.quadRoom.amount !== _originalPart.quadRoom.amount ||
                _currentPart.quadRoom.commentByCreator !== _originalPart.quadRoom.commentByCreator)
        ) {
            this.viewUpdateButton = true;
            return;
        }

        if (
            _originalPart &&
            (_currentPart.defaultOtherRoom.amount !== _originalPart.defaultOtherRoom.amount ||
                _currentPart.defaultOtherRoom.commentByCreator !== _originalPart.defaultOtherRoom.commentByCreator)
        ) {
            this.viewUpdateButton = true;
            return;
        }

        if (_originalPart && _otherRooms.length !== _originalOtherRooms.length) {
            this.viewUpdateButton = true;
            return;
        }

        if (_originalPart) {
            angular.forEach(_otherRooms, (_current) => {
                let _existing = _.find(_originalOtherRooms, (ori) => {
                    return ori.id === _current.id;
                });
                if (
                    !this.viewUpdateButton &&
                    _existing &&
                    (_existing.amount !== _current.amount || _existing.commentByCreator !== _current.commentByCreator)
                ) {
                    this.viewUpdateButton = true;
                    return;
                }
            });
        }
    }

    hasActionRequiredPartInRooms(rooms) {
        if (rooms) {
            return rooms.some((room) => this.isActionRequiredForRoom(room));
        }
        return false;
    }

    isActionRequiredForRoom(room) {
        return room.editType && room.editType.name === 'DENIED_ACTION_REQUIRED';
    }

    loadOnSuccess() {
        const { $scope, EventSchedule } = this._injected;
        if (this.successCallBack) {
            EventSchedule.getEventSchedule(
                this.eventId,
                (res) => {
                    this.successCallBack(res);
                },
                $scope.endWithErrorOverlay
            );
        }
        this.getAccommodationSchedule();
    }

    afterEventAccommodationCall() {
        this.afterEventAccommodationId = -1;
        this.afterEventAccommodation = null;
    }

    beforeEventAccommodationCall() {
        this.beforeEventAccommodationId = -1;
        this.beforeEventAccommodation = null;
    }

    changeForAllDaysAtOnce(item) {
        this.copyOriginalParts();

        let _referenceData = this.parts && this.parts.length > 0 ? angular.copy(this.parts[0]) : null;
        if (!_referenceData) {
            _referenceData = this.beforeEventAccommodationId === item.requestId ? this.beforeEventAccommodation : null;
        }
        if (!_referenceData) {
            _referenceData = this.afterEventAccommodationId === item.requestId ? this.afterEventAccommodation : null;
        }
        if (!_referenceData) {
            return;
        }
        this.firstDayParts = angular.copy(_referenceData);
        if (this.firstDayParts) {
            this.syncAllDayRoomsWithRefData(this.firstDayParts);
        }
        this.changeForAll = item.requestId;
        this.returnDayByDayEvent = true;
        this.changeForAllText = false;
    }

    syncAllDayRoomsWithRefData(refPart) {
        angular.forEach(this.parts, (_part) => {
            if (refPart.singleRoom.amount > 0) {
                _part.singleRoom.amount = refPart.singleRoom.amount;
            }
            if (refPart.doubleRoom.amount > 0) {
                _part.doubleRoom.amount = refPart.doubleRoom.amount;
            }
            if (refPart.suite.amount > 0) {
                _part.suite.amount = refPart.suite.amount;
            }
            if (refPart.tripleRoom.amount > 0) {
                _part.tripleRoom.amount = refPart.tripleRoom.amount;
            }
            if (refPart.quadRoom.amount > 0) {
                _part.quadRoom.amount = refPart.quadRoom.amount;
            }
        });
        this.synchronizeOtherRooms(refPart.otherRooms);
    }

    closeChangeForAllDaysAtOnce() {
        this.changeForAll = -1;
        this.revertToOriginalParts();
        this.returnDayByDayEvent = false;
        this.changeForAllText = true;
    }

    copyOriginalParts() {
        this.originalParts = angular.copy(this.parts);
    }

    getAccommodationSchedule() {
        const { $scope, AccommodationSchedule } = this._injected;
        $scope.startProgress('add-edit-accommodation');
        AccommodationSchedule.getAccommodationSchedule(
            this.eventId,
            (response) => {
                this.eventName = response.eventName;
                this.schedule = response.schedule;
                this.selectedTab = this.selectedTab ? this.selectedTab : response.schedule[0].requestId;
                this.setPartsData();
                $scope.endProgress('add-edit-accommodation');
            },
            $scope.endWithErrorOverlay('add-edit-accommodation')
        );
    }

    getAllSingleRoomsFromParts() {
        let singleRooms = this.parts.map(function (a) {
            return a.singleRoom;
        });
        return singleRooms;
    }

    getAllDoubleRoomsFromParts() {
        let doubleRooms = this.parts.map((a) => {
            return a.doubleRoom;
        });
        return doubleRooms;
    }

    getAllSuitesFromParts() {
        let suites = this.parts.map((a) => {
            return a.suite;
        });
        return suites;
    }

    getAllTripleRoomsFromParts() {
        let tripleRooms = this.parts.map((a) => {
            return a.tripleRoom;
        });
        return tripleRooms;
    }

    getAllQuadRoomsFromParts() {
        let quadRooms = this.parts.map((a) => {
            return a.quadRoom;
        });
        return quadRooms;
    }

    getAllOtherRoomsFromParts() {
        let _result = [];
        this.parts.forEach((current, index) => {
            let _others = current.otherRooms;
            if (_others && _others.length > 0) {
                _result = [..._result, ..._others];
            } else {
                let _defaultOtherRoom = current.defaultOtherRoom;
                _result.push(_defaultOtherRoom);
            }
        });
        return _result;
    }

    revertToOriginalParts() {
        this.parts = angular.copy(this.originalParts);
    }

    showAddAccommodationAfterEvent(item) {
        //this.revertToOriginalParts();
        this.afterEventAccommodation = {};
        this.afterEventAccommodationId = item.requestId;
        let dateTimeFrom = AccommodationUtil.getDateTimeFrom(item.totalDaysOfEvent);
        let dateTimeTo = AccommodationUtil.getDateTimeTo(item.totalDaysOfEvent);
        let hasContent = item.scheduleForRequest.timed.length > 0;
        this.afterEventAccommodation.singleRoom = {
            name: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].singleRoom.name
                : null,
            nameCode: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].singleRoom.nameCode
                : null,
            amount: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].singleRoom.amount
                : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };
        this.afterEventAccommodation.doubleRoom = {
            name: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].doubleRoom.name
                : null,
            nameCode: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].doubleRoom.nameCode
                : null,
            amount: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].doubleRoom.amount
                : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };
        this.afterEventAccommodation.suite = {
            name: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].suite.name
                : null,
            nameCode: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].suite.nameCode
                : null,
            amount: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].suite.amount
                : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };

        this.afterEventAccommodation.tripleRoom = {
            name: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].tripleRoom.name
                : null,
            nameCode: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].tripleRoom.nameCode
                : null,
            amount: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].tripleRoom.amount
                : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };
        this.afterEventAccommodation.quadRoom = {
            name: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].quadRoom.name
                : null,
            nameCode: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].quadRoom.nameCode
                : null,
            amount: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].quadRoom.amount
                : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };
        this.afterEventAccommodation.defaultOtherRoom = {
            name: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].defaultOtherRoom.name
                : null,
            nameCode: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].defaultOtherRoom.nameCode
                : null,
            amount: hasContent
                ? item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].defaultOtherRoom.amount
                : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };

        let _otherRooms = [];
        if (hasContent) {
            let _others = angular.copy(
                item.scheduleForRequest.timed[item.scheduleForRequest.timed.length - 1].otherRooms
            );
            _otherRooms = this._parseOtherAccommodation(_others, hasContent, dateTimeFrom, dateTimeTo);
        }
        _otherRooms = _.uniq(_otherRooms, (room) => room.name);
        this.afterEventAccommodation.otherRooms = _otherRooms;

        this.changeForAll = -1;
        this.returnDayByDayEvent = false;
        this.changeForAllText = true;
        this.isAmountChanged();
    }

    _parseOtherAccommodation(otherRooms, hasContent, dateTimeFrom, dateTimeTo) {
        let _result = [];
        for (var i = 0; i < otherRooms.length; i++) {
            let _current = otherRooms[i];
            if (_current && _current.amount > 0) {
                let _jso = {
                    name: hasContent ? _current.name : null,
                    nameCode: hasContent ? _current.nameCode : null,
                    amount: hasContent ? _current.amount : 0,
                    dateTimeFrom: dateTimeFrom,
                    dateTimeTo: dateTimeTo,
                };
                _result.push(_jso);
            }
        }
        return _result;
    }

    showAddAccommodationBeforeEvent(item) {
        //this.revertToOriginalParts();
        this.beforeEventAccommodation = {};
        this.beforeEventAccommodationId = item.requestId;
        let dateTimeFrom = AccommodationUtil.getDateTimeFrom(-1);
        let dateTimeTo = AccommodationUtil.getDateTimeTo(-1);
        let hasContent = item.scheduleForRequest.timed.length > 0;
        this.beforeEventAccommodation.singleRoom = {
            name: hasContent ? item.scheduleForRequest.timed[0].singleRoom.name : null,
            nameCode: hasContent ? item.scheduleForRequest.timed[0].singleRoom.nameCode : null,
            amount: hasContent ? item.scheduleForRequest.timed[0].singleRoom.amount : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };
        this.beforeEventAccommodation.doubleRoom = {
            name: hasContent ? item.scheduleForRequest.timed[0].doubleRoom.name : null,
            nameCode: hasContent ? item.scheduleForRequest.timed[0].doubleRoom.nameCode : null,
            amount: hasContent ? item.scheduleForRequest.timed[0].doubleRoom.amount : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };
        this.beforeEventAccommodation.suite = {
            name: hasContent ? item.scheduleForRequest.timed[0].suite.name : null,
            nameCode: hasContent ? item.scheduleForRequest.timed[0].suite.nameCode : null,
            amount: hasContent ? item.scheduleForRequest.timed[0].suite.amount : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };
        this.beforeEventAccommodation.tripleRoom = {
            name: hasContent ? item.scheduleForRequest.timed[0].tripleRoom.name : null,
            nameCode: hasContent ? item.scheduleForRequest.timed[0].tripleRoom.nameCode : null,
            amount: hasContent ? item.scheduleForRequest.timed[0].tripleRoom.amount : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };
        this.beforeEventAccommodation.quadRoom = {
            name: hasContent ? item.scheduleForRequest.timed[0].quadRoom.name : null,
            nameCode: hasContent ? item.scheduleForRequest.timed[0].quadRoom.nameCode : null,
            amount: hasContent ? item.scheduleForRequest.timed[0].quadRoom.amount : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };

        let _otherRooms = [];
        if (hasContent) {
            let _others = angular.copy(item.scheduleForRequest.timed[0].otherRooms);
            _otherRooms = this._parseOtherAccommodation(_others, hasContent, dateTimeFrom, dateTimeTo);
        }
        _otherRooms = _.uniq(_otherRooms, (room) => room.name);
        this.beforeEventAccommodation.otherRooms = _otherRooms;

        this.beforeEventAccommodation.defaultOtherRoom = {
            name: hasContent ? item.scheduleForRequest.timed[0].defaultOtherRoom.name : null,
            nameCode: hasContent ? item.scheduleForRequest.timed[0].defaultOtherRoom.nameCode : null,
            amount: hasContent ? item.scheduleForRequest.timed[0].defaultOtherRoom.amount : 0,
            dateTimeFrom: dateTimeFrom,
            dateTimeTo: dateTimeTo,
        };
        this.changeForAll = -1;
        this.returnDayByDayEvent = false;
        this.changeForAllText = true;
        this.isAmountChanged();
    }

    synchronizeDoubleRooms(firstDayAmount) {
        let doubleRooms = this.getAllDoubleRoomsFromParts();
        for (let i = 0; i < doubleRooms.length; i++) {
            doubleRooms[i].amount = firstDayAmount;
        }
        if (this.afterEventAccommodation) {
            this.afterEventAccommodation.doubleRoom.amount = firstDayAmount;
        }
        if (this.beforeEventAccommodation) {
            this.beforeEventAccommodation.doubleRoom.amount = firstDayAmount;
        }
        if (this.afterEventAccommodation) {
            this.afterEventAccommodation.doubleRoom.amount = firstDayAmount;
        }
        if (this.beforeEventAccommodation) {
            this.beforeEventAccommodation.doubleRoom.amount = firstDayAmount;
        }
    }

    synchronizeSingleRooms(firstDayAmount) {
        let singleRooms = this.getAllSingleRoomsFromParts();
        for (let i = 0; i < singleRooms.length; i++) {
            singleRooms[i].amount = firstDayAmount;
        }
        if (this.afterEventAccommodation) {
            this.afterEventAccommodation.singleRoom.amount = firstDayAmount;
        }
        if (this.beforeEventAccommodation) {
            this.beforeEventAccommodation.singleRoom.amount = firstDayAmount;
        }
        if (this.afterEventAccommodation) {
            this.afterEventAccommodation.singleRoom.amount = firstDayAmount;
        }
        if (this.beforeEventAccommodation) {
            this.beforeEventAccommodation.singleRoom.amount = firstDayAmount;
        }
    }

    synchronizeSuites(firstDayAmount) {
        let suites = this.getAllSuitesFromParts();
        for (let i = 0; i < suites.length; i++) {
            suites[i].amount = firstDayAmount;
        }
        if (this.afterEventAccommodation) {
            this.afterEventAccommodation.suite.amount = firstDayAmount;
        }
        if (this.beforeEventAccommodation) {
            this.beforeEventAccommodation.suite.amount = firstDayAmount;
        }
    }

    synchronizeTripleRooms(firstDayAmount) {
        let tripleRooms = this.getAllTripleRoomsFromParts();
        for (let i = 0; i < tripleRooms.length; i++) {
            tripleRooms[i].amount = firstDayAmount;
        }
        if (this.afterEventAccommodation) {
            this.afterEventAccommodation.tripleRoom.amount = firstDayAmount;
        }
        if (this.beforeEventAccommodation) {
            this.beforeEventAccommodation.tripleRoom.amount = firstDayAmount;
        }
        if (this.afterEventAccommodation) {
            this.afterEventAccommodation.tripleRoom.amount = firstDayAmount;
        }
        if (this.beforeEventAccommodation) {
            this.beforeEventAccommodation.tripleRoom.amount = firstDayAmount;
        }
    }

    synchronizeQuadRooms(firstDayAmount) {
        let quadRooms = this.getAllQuadRoomsFromParts();
        for (let i = 0; i < quadRooms.length; i++) {
            quadRooms[i].amount = firstDayAmount;
        }
        if (this.afterEventAccommodation) {
            this.afterEventAccommodation.quadRoom.amount = firstDayAmount;
        }
        if (this.beforeEventAccommodation) {
            this.beforeEventAccommodation.quadRoom.amount = firstDayAmount;
        }
        if (this.afterEventAccommodation) {
            this.afterEventAccommodation.quadRoom.amount = firstDayAmount;
        }
        if (this.beforeEventAccommodation) {
            this.beforeEventAccommodation.quadRoom.amount = firstDayAmount;
        }
    }

    synchronizeOtherRooms(firstDayOtherRooms) {
        let _firstDayOthers = angular.copy(firstDayOtherRooms);
        angular.forEach(this.parts, (_part) => {
            var _freshOtherRoomList = [];
            if (_part.otherRooms.length > 0 && _.isEmpty(_firstDayOthers)) {
                _firstDayOthers = angular.copy(_part.otherRooms);
            } else if (_part.otherRooms.length > 0 && !_.isEmpty(_firstDayOthers)) {
                function arrayUnion(arr1, arr2, equalityFunc) {
                    var union = arr1.concat(arr2);
                    for (var i = 0; i < union.length; i++) {
                        for (var j = i + 1; j < union.length; j++) {
                            if (equalityFunc(union[i], union[j])) {
                                union.splice(j, 1);
                                j--;
                            }
                        }
                    }
                    return union;
                }

                function areRoomsSame(r1, r2) {
                    return r1._tempId && r2._tempId ? r1._tempId === r2._tempId : r1.name === r2.name;
                }
                _firstDayOthers = arrayUnion(_firstDayOthers, _part.otherRooms, areRoomsSame);
            }

            angular.forEach(_firstDayOthers, (_first) => {
                let _tempPart = angular.copy(_first);
                _tempPart.dateTimeFrom = _part.singleRoom.dateTimeFrom;
                _tempPart.dateTimeTo = _part.singleRoom.dateTimeTo;
                if (_tempPart.id) {
                    let _existing = _.find(_part.otherRooms, (_other) => {
                        return _other.name === _tempPart.name && _other.dateTimeFrom === _tempPart.dateTimeFrom;
                    });
                    if (_existing) {
                        _existing.amount = _tempPart.amount;
                        _freshOtherRoomList.push(_existing);
                    } else {
                        _freshOtherRoomList.push(_tempPart);
                    }
                } else {
                    let _roomExist = _.find(_part.otherRooms, (_other) => {
                        return _other._tempId === _tempPart._tempId;
                    });
                    if (!_roomExist) {
                        // _part.otherRooms.push(_tempPart);
                        _freshOtherRoomList.push(_tempPart);
                    } else {
                        _roomExist.amount = _tempPart.amount;
                        _roomExist.name = _tempPart.name;
                        _freshOtherRoomList.push(_roomExist);
                    }
                }
            });
            _part.otherRooms = _freshOtherRoomList;
        });

        if (this.afterEventAccommodation) {
            var _freshAfterEventOtherRoomList = [];
            angular.forEach(_firstDayOthers, (_first) => {
                let _tempPart = angular.copy(_first);

                _tempPart.dateTimeFrom = this.afterEventAccommodation.singleRoom.dateTimeFrom;
                _tempPart.dateTimeTo = this.afterEventAccommodation.singleRoom.dateTimeTo;
                if (_tempPart.id) {
                    let _existing = _.find(this.afterEventAccommodation.otherRooms, (_other) => {
                        return _other.name === _tempPart.name && _other.dateTimeFrom === _tempPart.dateTimeFrom;
                    });
                    if (_existing) {
                        _existing.amount = _tempPart.amount;
                        _freshAfterEventOtherRoomList.push(_existing);
                    } else {
                        _freshAfterEventOtherRoomList.push(_tempPart);
                    }
                } else {
                    let _roomExist = _.find(this.afterEventAccommodation.otherRooms, (_other) => {
                        return _other._tempId === _tempPart._tempId;
                    });
                    if (!_roomExist) {
                        // _part.otherRooms.push(_tempPart);
                        _freshAfterEventOtherRoomList.push(_tempPart);
                    } else {
                        _roomExist.amount = _tempPart.amount;
                        _roomExist.name = _tempPart.name;
                        _freshAfterEventOtherRoomList.push(_roomExist);
                    }
                }
            });

            this.afterEventAccommodation.otherRooms = _freshAfterEventOtherRoomList;
        }

        if (this.beforeEventAccommodation) {
            var _freshBeforeEventOtherRoomList = [];
            angular.forEach(_firstDayOthers, (_first) => {
                let _tempPart = angular.copy(_first);
                _tempPart.dateTimeFrom = this.beforeEventAccommodation.singleRoom.dateTimeFrom;
                _tempPart.dateTimeTo = this.beforeEventAccommodation.singleRoom.dateTimeTo;
                if (_tempPart.id) {
                    let _existing = _.find(this.beforeEventAccommodation.otherRooms, (_other) => {
                        return _other.name === _tempPart.name && _other.dateTimeFrom === _tempPart.dateTimeFrom;
                    });
                    if (_existing) {
                        _existing.amount = _tempPart.amount;
                        _freshBeforeEventOtherRoomList.push(_existing);
                    } else {
                        _freshBeforeEventOtherRoomList.push(_tempPart);
                    }
                } else {
                    let _roomExist = _.find(this.beforeEventAccommodation.otherRooms, (_other) => {
                        return _other._tempId === _tempPart._tempId;
                    });
                    if (!_roomExist) {
                        // _part.otherRooms.push(_tempPart);
                        _freshBeforeEventOtherRoomList.push(_tempPart);
                    } else {
                        _roomExist.amount = _tempPart.amount;
                        _roomExist.name = _tempPart.name;
                        _freshBeforeEventOtherRoomList.push(_roomExist);
                    }
                }
            });
            this.beforeEventAccommodation.otherRooms = _freshBeforeEventOtherRoomList;
        }
    }

    tabSwitch(selectedRequestId) {
        this.selectedTab = selectedRequestId;
        this.setPartsData();
    }

    saveDisabled(item) {
        if (item.editType) {
            return item.editType.name === 'DENIED';
        }
        return false;
    }

    updateAccommodation(item, close = true) {
        const { $scope, AccommodationSchedule, EventSchedule } = this._injected;
        if (this.beforeEventAccommodation) {
            this.parts.splice(0, 0, this.beforeEventAccommodation);
            this.beforeEventAccommodation = null;
            this.beforeEventAccommodationId = -1;
        }
        if (this.afterEventAccommodation) {
            this.parts.push(this.afterEventAccommodation);
            this.afterEventAccommodation = null;
            this.afterEventAccommodationId = -1;
        }

        let singleRooms = this.getAllSingleRoomsFromParts();
        let doubleRooms = this.getAllDoubleRoomsFromParts();
        let suites = this.getAllSuitesFromParts();
        let tripleRooms = this.getAllTripleRoomsFromParts();
        let quadRooms = this.getAllQuadRoomsFromParts();
        let _otherRooms = this.getAllOtherRoomsFromParts();
        let _otherRoomGroup = _.groupBy(_otherRooms, function (o) {
            if (o.name) {
                return o.name.toLowerCase();
            }
        });
        let _othersGroupByDate = _.groupBy(_otherRooms, 'dateTimeFrom');
        _.map(_othersGroupByDate, (_parts, key) => {
            const _duplicate = this._findDuplicateOtherPart(_parts);
            if (_duplicate.length > 0) {
                this.duplicateOtherParts = true;
                this.duplicateOtherPartsList = _duplicate;
                return;
            }
        });

        let singleRoomWithId = singleRooms.filter(function (room) {
            return room && room.id > 0;
        });
        let doubleRoomWithId = doubleRooms.filter(function (room) {
            return room && room.id > 0;
        });
        let suiteWithId = suites.filter(function (room) {
            return room && room.id > 0;
        });
        let tripleRoomWithId = tripleRooms.filter(function (room) {
            return room && room.id > 0;
        });
        let quadRoomWithId = quadRooms.filter(function (room) {
            return room && room.id > 0;
        });

        if (
            _.isEmpty(singleRooms) &&
            _.isEmpty(doubleRooms) &&
            _.isEmpty(suites) &&
            _.isEmpty(tripleRooms) &&
            _.isEmpty(quadRooms) &&
            _.isEmpty(_otherRooms)
        ) {
            this._close();
            return;
        }
        let _singleRoom = {
            id: singleRoomWithId ? singleRoomWithId.id : null,
            name: singleRooms[0].name ? singleRooms[0].name : item.singleRoomDefaultName,
            nameCode: singleRooms[0].nameCode ? singleRooms[0].nameCode : item.singleRoomDefaultNameCode,
            schedule: singleRooms.map((room) => {
                return {
                    amount: room.amount,
                    dateTimeFrom: room.dateTimeFrom,
                    dateTimeTo: room.dateTimeTo,
                    commentByCreator: room.commentByCreator,
                };
            }),
        };

        let _doubleRoom = {
            id: doubleRoomWithId ? doubleRoomWithId.id : null,
            name: doubleRooms[0].name ? doubleRooms[0].name : item.doubleRoomDefaultName,
            nameCode: doubleRooms[0].nameCode ? doubleRooms[0].nameCode : item.doubleRoomDefaultNameCode,
            schedule: doubleRooms.map((room) => {
                return {
                    amount: room.amount,
                    dateTimeFrom: room.dateTimeFrom,
                    dateTimeTo: room.dateTimeTo,
                    commentByCreator: room.commentByCreator,
                };
            }),
        };

        let _suite = {
            id: suiteWithId ? suiteWithId.id : null,
            name: suites[0].name ? suites[0].name : item.suiteDefaultName,
            nameCode: suites[0].nameCode ? suites[0].nameCode : item.suiteDefaultNameCode,
            schedule: suites.map((room) => {
                return {
                    amount: room.amount,
                    dateTimeFrom: room.dateTimeFrom,
                    dateTimeTo: room.dateTimeTo,
                    commentByCreator: room.commentByCreator,
                };
            }),
        };
        let _tripleRoom = {
            id: tripleRoomWithId ? tripleRoomWithId.id : null,
            name: tripleRooms[0].name ? tripleRooms[0].name : item.tripleRoomDefaultName,
            nameCode: tripleRooms[0].nameCode ? tripleRooms[0].nameCode : item.tripleRoomDefaultNameCode,
            schedule: tripleRooms.map((room) => {
                return {
                    amount: room.amount,
                    dateTimeFrom: room.dateTimeFrom,
                    dateTimeTo: room.dateTimeTo,
                    commentByCreator: room.commentByCreator,
                };
            }),
        };

        let _quadRoom = {
            id: quadRoomWithId ? quadRoomWithId.id : null,
            name: quadRooms[0].name ? quadRooms[0].name : item.quadRoomDefaultName,
            nameCode: quadRooms[0].nameCode ? quadRooms[0].nameCode : item.quadRoomDefaultNameCode,
            schedule: quadRooms.map((room) => {
                return {
                    amount: room.amount,
                    dateTimeFrom: room.dateTimeFrom,
                    dateTimeTo: room.dateTimeTo,
                    commentByCreator: room.commentByCreator,
                };
            }),
        };

        let _ott = [];
        _.map(_otherRoomGroup, (value, key) => {
            if (key !== null || key.trim() !== '' || !_.isEmpty(key)) {
                let _others = value;
                let _otherRoomWithId = _others.filter(function (room) {
                    return room && room.id > 0;
                });
                let _schedule = [];
                for (let _i = 0; _i < singleRooms.length; _i++) {
                    let _singleRoom = singleRooms[_i];
                    let _otherRoom = _.find(_others, (room) => {
                        return _singleRoom.dateTimeFrom === room.dateTimeFrom;
                    });
                    let _amount = _otherRoom ? _otherRoom.amount : 0;
                    let _commentByCreator = _otherRoom ? _otherRoom.commentByCreator : null;
                    let _json = {
                        amount: _amount,
                        dateTimeFrom: _singleRoom.dateTimeFrom,
                        dateTimeTo: _singleRoom.dateTimeTo,
                        commentByCreator: _commentByCreator,
                    };
                    _schedule.push(_json);
                }
                let _otherRoom = {
                    id: _otherRoomWithId ? _otherRoomWithId.id : null,
                    name: _others[0].name,
                    nameCode: _others[0].nameCode,
                    schedule: _schedule,
                };
                _ott.push(_otherRoom);
            }
        });

        if (!this.duplicateOtherParts) {
            let _data = {
                singleRoom: _singleRoom,
                doubleRoom: _doubleRoom,
                suite: _suite,
                tripleRoom: _tripleRoom,
                quadRoom: _quadRoom,
                otherRooms: _ott,
            };
            $scope.startProgress('add-edit-accommodation');
            if (item.requestId) {
                AccommodationSchedule.updateAccommodation(
                    item.requestId,
                    _data,
                    (response) => {
                        this.schedule = response.schedule;
                        if (this.successCallBack) {
                            EventSchedule.getEventSchedule(
                                this.eventId,
                                (res) => {
                                    this.successCallBack(res);
                                },
                                $scope.endWithErrorOverlay
                            );
                        }
                        $scope.endProgress('add-edit-accommodation');
                        if (close) {
                            this._close();
                        } else {
                            this.getAccommodationSchedule();
                        }
                    },
                    $scope.endWithErrorOverlay('add-edit-accommodation')
                );
            } else {
                _data.requestName = item.requestName;
                AccommodationSchedule.createNewRequestAndAddOfferPart(
                    this.eventId,
                    _data,
                    (response) => {
                        if (this.successCallBack) {
                            EventSchedule.getEventSchedule(
                                this.eventId,
                                (res) => {
                                    this.successCallBack(res);
                                },
                                $scope.endWithErrorOverlay
                            );
                        }
                        $scope.endProgress('add-edit-accommodation');
                        if (close) {
                            this._close();
                        } else {
                            this.getAccommodationSchedule();
                        }
                    },
                    $scope.endWithErrorOverlay('add-edit-accommodation')
                );
            }
        }

        if (this.duplicateOtherParts) {
            this._scrollTo();
        }
    }

    deleteRoom(room, item) {
        room.amount = 0;
        this.updateAccommodation(item, false);
    }

    updateRoomName(room, otherRooms) {
        const { $scope, AccommodationSchedule, EventEnum, $rootScope } = this._injected;
        const _duplicate = this._findDuplicateOtherPart(otherRooms);
        if (_duplicate.length > 0) {
            this.duplicateOtherParts = true;
            if (this.duplicateOtherParts) {
                this._scrollTo();
            }
            this.duplicateOtherPartsList = _duplicate;
        } else {
            $scope.endProgress('add-edit-accommodation');
            AccommodationSchedule.updateOtherRoomTypeName(
                room.id,
                room,
                (response) => {
                    $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                        requestId: this.selectedTab,
                        offerId: response.offerId,
                    });
                    room.showNameEditTooltip = false;
                    $scope.endProgress('add-edit-accommodation');
                },
                $scope.endWithErrorOverlay('add-edit-accommodation')
            );
        }
    }

    _findDuplicateOtherPart(parts) {
        let _duplicate = [];
        if (parts && parts.length > 0) {
            let _groupedByCount = _.countBy(parts, (item) => {
                return item.name && item.name.toLowerCase();
            });
            for (var name in _groupedByCount) {
                if (_groupedByCount[name] > 1) {
                    _duplicate.push(name);
                }
            }
        }
        return _duplicate;
    }

    _scrollTo() {
        const { $timeout } = this._injected;
        let _elementId = '#successAlertBox';
        $timeout(() => {
            let _topHeight = jQuery(_elementId).position().top;
            jQuery('#add-edit-accommodation').animate({ scrollTop: _topHeight }, 'slow');
        }, 500);
    }

    _close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}
AddEditAccommodationModalCtrl.$inject = [
    'data',
    '$scope',
    'close',
    '$element',
    '$translate',
    'AccommodationSchedule',
    'EventSchedule',
    'GlobalConstants',
    'EventEnum',
    '$rootScope',
    '$timeout',
];
export default AddEditAccommodationModalCtrl;
