/* global angular */
'use strict';

angular.module('core.existingServices').factory('NotificationHandleService', [
    'EventEnum',
    '$rootScope',
    'AuthenticationService',
    'NotificationService',
    'RequestOffer',
    function (EventEnum, $rootScope, AuthenticationService, NotificationService, RequestOffer) {
        var offers = [];

        function changeRequestOfferStatus(
            eventId,
            requestId,
            offer,
            status,
            scope,
            supplierMessage,
            billingAddress,
            callBack,
            supplierBillingAddress,
            shouldUpdateSupplierAddress
        ) {
            var params = {eventId: eventId, requestId: requestId, offerId: offer.id, status: status};
            if (supplierMessage !== null) {
                params.supplierMessage = supplierMessage;
            }
            if (billingAddress !== null) {
                params.billingAddress = billingAddress;
            }
            if (supplierBillingAddress !== null) {
                params.supplierBillingAddress = supplierBillingAddress;
            }
            params.shouldUpdateSupplierAddress = shouldUpdateSupplierAddress;
            scope.startProgress();
            RequestOffer.update(
                params,
                function () {
                    NotificationService.setNotificationData(eventId);
                    if (callBack) {
                        callBack();
                    }
                    scope.endProgress();
                    $rootScope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                        requestId: requestId,
                        offerId: offer.id,
                    });
                },
                scope.endWithAlert()
            );

            $rootScope.$emit('event:user:action', {
                action: 'booked.offer',
                data: {
                    'Event id': eventId,
                    'Module name': requestId,
                    'Module id': requestId,
                    'Supplier id': offer.supplier.id,
                    'Offer id': offer.id,
                },
            });
        }

        return {
            changeRequestOfferStatus: function (
                eventId,
                requestId,
                offer,
                status,
                scope,
                supplierMessage,
                billingAddress,
                callBack,
                supplierBillingAddress,
                shouldUpdateSupplierAddress
            ) {
                return changeRequestOfferStatus(
                    eventId,
                    requestId,
                    offer,
                    status,
                    scope,
                    supplierMessage,
                    billingAddress,
                    callBack,
                    supplierBillingAddress,
                    shouldUpdateSupplierAddress
                );
            },
        };
    },
]);

