/**
 * Created by sampurna on 2020-05-12.
 */
'use strict';
import {ScopeFunctions} from '../../../../../../utils/global/scopeFunctions';

angular.module('supplier.offer.detail.final.details').component('finalDetails', {
    templateUrl: './app/components/modules/supplier/offer/offer_detail/final-details/final-details.html',
    require: {
        parentCtrl: '^offerDetail'
    },
    controller: [
        '$scope',
        '$stateParams',
        'ActiveTabService',
        'TimezoneService',
        'ClientService',
        'CommonFile',
        function FinalDetailsController(
            $scope,
            $stateParams,
            ActiveTabService,
            TimezoneService,
            ClientService,
            CommonFile
        ) {
            var vm = this;
            vm.init = initFn;
            vm.getActiveTab = getActiveTabFn;
            vm.parseDateInScheduleFormat = parseDateInScheduleFormatFn;
            vm.getImageUrl = getImageUrlFn;
            vm.getFileUrl = getFileUrlFn;
            vm.offerId = $stateParams.offerId;

            ScopeFunctions.addToScope($scope);

            vm.init();

            function initFn() {
                $scope.clientName = ClientService.getClientName();
            }

            function getActiveTabFn() {
                return ActiveTabService.getOfferDetailTab();
            }

            function parseDateInScheduleFormatFn(date) {
                let dateFormat = 'HH:mm';
                var localeDate = new Date(TimezoneService.toLocalTime(date));
                return TimezoneService.formatDate(localeDate, dateFormat);
            }

            function getImageUrlFn(fileId) {
                return CommonFile.getCommonFile(fileId);
            }

            function getFileUrlFn(fileId) {
                return CommonFile.getFile(fileId);
            }

            this.$onInit = function () {
                vm.offerDetailCtrl = this.parentCtrl;
            };
        }]
})
