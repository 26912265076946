/**
 * Don't put this content inside function()
 */
'use strict';
import _ from 'underscore';

export const UtilFunctions = {
    /**
     * checks if the number (integer/string) is greater than or equal to 0 in value
     * @param num
     * @returns {boolean}
     */
    isGreaterOrEqualToZero: function (num) {
        return typeof num !== 'object' && +num >= 0;
    },

    isUndefinedOrNull: function (val) {
        return angular.isUndefined(val) || val === null;
    },

    isDefinedAndNotNull: function (val) {
        var definedNotnull = angular.isDefined(val) && !(val === null || val === 'null');

        if (_.isString(val)) {
            return definedNotnull && val.toLowerCase() !== 'undefined';
        }
        return definedNotnull;
    },

    hasCommentOnPart: function (part) {
        if (part.commentByCreator) {
            return true;
        }
        return part.comments && part.comments[0].hasSupplierComment;
    },

    isBooleanTrue: function (input) {
        return input === 'true' || input === true;
    },

    trimStringBeautify: function (inputString, length) {
        if (!inputString) {
            return '';
        } else if (inputString.length <= length) {
            return inputString;
        } else {
            return inputString.substring(0, length - 3).concat('...');
        }
    },

    limitText: function (text, limit) {
        var changedString = String(text).replace(/<[^>]+>/gm, '');
        var length = changedString.length;
        var suffix = ' ...';

        return length > limit ? changedString.substr(0, limit - 1) + suffix : changedString;
    },

    /* Returns an array with numbers from start to n; pads 0s to format number as per number of digits*/
    getRange: function (n, start, noOfDigits) {
        start = start || 1;
        noOfDigits = noOfDigits || 0;
        if (noOfDigits > 0) {
            return _.range(start, n + 1).map(function (num) {
                return ('0' + num).slice(-noOfDigits);
            });
        }
        return _.range(start, n + 1);
    },

    normalizeToUpperCase: function (input) {
        var _value = new String(input).valueOf();
        if (!_value || _value === null || _value === 'null' || _value.toLowerCase() === 'undefined') {
            return null;
        }
        return _value.replace(/\s/g, '').toUpperCase();
    },

    getListItemIdFromSearchText: function (list, searchText) {
        var item = null;
        if (searchText) {
            item = _.find(list, function (o) {
                if (o) {
                    return o.name.toLowerCase() === searchText.toLowerCase();
                }
            });
        }
        if (item) {
            return item.id;
        }
        return item;
    },

    hasText: function (text, checkText) {
        text = text.toLowerCase();

        if (_.isString(checkText)) {
            checkText = checkText.toLowerCase();
            return text.indexOf(checkText) !== -1;
        }

        if (_.isArray(checkText)) {
            var result = [];
            angular.forEach(checkText, function (txt) {
                txt = txt.toLowerCase();
                result.push(text.indexOf(txt) !== -1);
            });
            return _.contains(result, true);
        }
    },

    containsAny: function (str, substrings) {
        for (var i = 0; i !== substrings.length; i++) {
            var substring = substrings[i];
            if (substring.indexOf(str) !== -1) {
                return substring;
            }
        }
        return null;
    },

    allClientBillingAddresses: function () {
        return [
            'CLIENT_BILLING_ADDRESS_SV',
            'CLIENT_BILLING_ADDRESS_DK',
            'CLIENT_BILLING_ADDRESS_NO',
            'CLIENT_BILLING_ADDRESS_FI',
        ];
    },

    isClientBillingAddress: function (billingAddress) {
        return this.allClientBillingAddresses().includes(billingAddress);
    },
};
