import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

class OfferPartComment {
    constructor(
        $scope,
        $rootScope,
        $translate,
        FieldChangedSvc,
        FilledRequestOfferPartComment,
        DynamicFormUtilService
    ) {
        this._injected = {
            $scope,
            $rootScope,
            $translate,
            FieldChangedSvc,
            FilledRequestOfferPartComment,
            DynamicFormUtilService,
        };
        this.list = [];
        this.originalList = [];
        this.requestNameCode = '';
        this.partListValue = this.selectedContentList;
        //this.getList();
        ScopeFunctions.addToScope($scope);
        this.checkHasChanged = this.checkHasChanged.bind(this);

        $scope.$watch('offerPartCommentCtrl.offerPart', (newValue, oldValue) => {
            if (newValue && newValue.hasAdditionalQuestions) {
                this.getList(newValue);
                this.requestNameCode = newValue.requestNameCode;
            }
        });
        $scope.$watch('offerPartCommentCtrl.langChanged', (newValue, oldValue) => {
            if (newValue) {
                this.getList(this.offerPart);
            }
        });
    }

    getList(part) {
        const { FilledRequestOfferPartComment, $translate, $rootScope, DynamicFormUtilService } = this._injected;
        if (part && part.hasAdditionalQuestions) {
            if (this.partListValue) {
                this.loadingInProgress = true;
                this.list = this.partListValue;
                angular.copy(this.partListValue, this.originalList);
                if (part.status && ['EDITED_BY_CREATOR'].indexOf(part.status.name) > -1 && !!this.isCreatorLabel) {
                    angular.forEach(this.list, (list) => {
                        if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                            let _listValuesCode = list.listValueCodes;
                            let _listValues = $translate.instant(_listValuesCode);
                            let _fieldValue;
                            if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                                _fieldValue = false;
                            } else {
                                _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                            }
                            list.fieldValue = DynamicFormUtilService.getFieldValue(
                                _listValues,
                                $rootScope.langSelected,
                                _fieldValue
                            );
                            list.creatorFieldValue = DynamicFormUtilService.getFieldValue(
                                _listValues,
                                $rootScope.langSelected,
                                list.creatorFieldValue
                            );
                            list.oldFieldValue = DynamicFormUtilService.getFieldValue(
                                _listValues,
                                $rootScope.langSelected,
                                list.oldFieldValue
                            );
                        }
                        list.oldFieldValue = list.fieldValue;
                        list.fieldValue = list.creatorFieldValue ? list.creatorFieldValue : list.fieldValue;
                        if (['ICON_RADIO'].indexOf(list.type.name) > -1) {
                            let _iconList = list.iconList;
                            angular.forEach(_iconList, function (icon) {
                                icon.name = $translate.instant(icon.nameCode);
                            });
                        }
                    });
                } else {
                    angular.forEach(this.list, (list) => {
                        if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                            let _listValuesCode = list.listValueCodes;
                            let _listValues = $translate.instant(_listValuesCode);
                            let _fieldValue;
                            if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                                _fieldValue = false;
                            } else {
                                _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                            }
                            list.fieldValue = DynamicFormUtilService.getFieldValue(
                                _listValues,
                                $rootScope.langSelected,
                                _fieldValue
                            );
                        }
                        if (['ICON_RADIO'].indexOf(list.type.name) > -1) {
                            let _iconList = list.iconList;
                            angular.forEach(_iconList, function (icon) {
                                icon.name = $translate.instant(icon.nameCode);
                            });
                        }
                    });
                }
                this.loadingInProgress = false;
            } else {
                this.loadingInProgress = true;
                FilledRequestOfferPartComment.list(
                    this.module,
                    part.nameCode,
                    part.id,
                    (resp) => {
                        if (resp && resp.length > 0) {
                            this.list = resp;
                            angular.copy(resp, this.originalList);
                            if (
                                part.status &&
                                ['EDITED_BY_CREATOR'].indexOf(part.status.name) > -1 &&
                                !!this.isCreatorLabel
                            ) {
                                angular.forEach(this.list, (list) => {
                                    if (
                                        ['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) >
                                        -1
                                    ) {
                                        let _listValuesCode = list.listValueCodes;
                                        let _listValues = $translate.instant(_listValuesCode);
                                        let _fieldValue;
                                        if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                                            _fieldValue = false;
                                        } else {
                                            _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                                        }
                                        list.fieldValue = DynamicFormUtilService.getFieldValue(
                                            _listValues,
                                            $rootScope.langSelected,
                                            _fieldValue
                                        );
                                        list.creatorFieldValue = DynamicFormUtilService.getFieldValue(
                                            _listValues,
                                            $rootScope.langSelected,
                                            list.creatorFieldValue
                                        );
                                        list.oldFieldValue = DynamicFormUtilService.getFieldValue(
                                            _listValues,
                                            $rootScope.langSelected,
                                            list.oldFieldValue
                                        );
                                    }
                                    list.oldFieldValue = list.fieldValue;
                                    list.fieldValue = list.creatorFieldValue ? list.creatorFieldValue : list.fieldValue;
                                    if (['ICON_RADIO'].indexOf(list.type.name) > -1) {
                                        let _iconList = list.iconList;
                                        angular.forEach(_iconList, function (icon) {
                                            icon.name = $translate.instant(icon.nameCode);
                                        });
                                    }
                                });
                            } else {
                                angular.forEach(this.list, (list) => {
                                    if (
                                        ['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) >
                                        -1
                                    ) {
                                        let _listValuesCode = list.listValueCodes;
                                        let _listValues = $translate.instant(_listValuesCode);
                                        let _fieldValue;
                                        if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                                            _fieldValue = false;
                                        } else {
                                            _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                                        }
                                        list.fieldValue = DynamicFormUtilService.getFieldValue(
                                            _listValues,
                                            $rootScope.langSelected,
                                            _fieldValue
                                        );
                                    }
                                    if (['ICON_RADIO'].indexOf(list.type.name) > -1) {
                                        let _iconList = list.iconList;
                                        angular.forEach(_iconList, function (icon) {
                                            icon.name = $translate.instant(icon.nameCode);
                                        });
                                    }
                                });
                            }
                            this.loadingInProgress = false;
                        }
                    },
                    function (e) {
                        console.log('error ', e);
                    }
                );
            }
        }
    }

    save() {
        const { FilledRequestOfferPartComment } = this._injected;
        FilledRequestOfferPartComment.save(
            this.offerPart.id,
            this.list,
            this.offerPart.commentByCreator,
            (resp) => {
                this.list = resp;
            },
            function (e) {
                console.log('error ', e);
            }
        );
    }

    checkHasChanged(question, fieldValue) {
        let orig = _.find(this.originalList, (orgObj) => {
            return orgObj.id === question.id;
        });
        let _changed = orig && orig.fieldValue !== fieldValue;
        this.isChanged({ changed: _changed });
    }
}
OfferPartComment.$inject = [
    '$scope',
    '$rootScope',
    '$translate',
    'FieldChangedSvc',
    'FilledRequestOfferPartComment',
    'DynamicFormUtilService',
];
export default OfferPartComment;
