/**
 * Created by amol on 10/17/2019.
 */
import editEventModal from '../../../components/modals/modals2.0/edit-event';
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';
import * as _ from 'underscore';

class EventProcessSidebarCtrl {
    constructor(
        $element,
        $rootScope,
        $scope,
        DateService,
        $stateParams,
        EventProgress,
        $sce,
        AuthenticationService,
        Event,
        $state,
        ActiveTabService,
        EventGuideDataStore,
        EventEnum,
        ModalService
    ) {
        this._injected = {
            $element,
            $rootScope,
            $scope,
            $stateParams,
            EventProgress,
            $sce,
            AuthenticationService,
            Event,
            $state,
            ActiveTabService,
            EventGuideDataStore,
            EventEnum,
            ModalService,
        };
        this.eventId = $stateParams.eventId;
        ScopeFunctions.addToScope($scope);
        this.getEventProgressDateSpan = DateService.getEventProgressDateSpan;
        this.closePopUp = this.closePopUp.bind(this);
        this.stopVideo = this.stopVideo.bind(this);
        this.init();
        this.languageSelected = $rootScope.langSelected;
        this.viewEditEventModal = false;
        $scope.$on(
            'lang:change',
            function (evt, data) {
                this.languageSelected = data.lang;
            }.bind(this)
        );
        this.videoIframeId = null;
    }

    init() {
        const { $scope, EventProgress } = this._injected;
        $scope.startProgress('event-progress');
        EventProgress.getEventProgress(
            { eventId: this.eventId },
            (response) => {
                this.data = response;
                if (this.newEventShowGuideCallback) {
                    this.openFirstStep(this.data);
                }
                $scope.endProgress('event-progress');
            },
            $scope.endWithAlert
        );
    }

    openFirstStep(data) {
        let firstStep = _.findWhere(data, { name: 'event.progress.get.started' });
        this.showEventProcessSidebarStep(firstStep.id);
    }

    markUnmarkStepComplete(stepId, state) {
        const { $scope, EventProgress } = this._injected;
        $scope.startProgress('event-progress');
        EventProgress.markUnmarkStepAsComplete(
            { stepId: stepId, eventId: this.eventId, state: state },
            (response) => {
                this.updateCurrentProgress(response, stepId);
                $scope.endProgress('event-progress');
            },
            $scope.endWithErrorOverlay
        );
    }

    updateCurrentProgress(response, stepId) {
        let _newSubStem = null;
        angular.forEach(response, (step) => {
            let _subSteps = step.subSteps;
            _newSubStem = _.findWhere(_subSteps, { id: stepId });
            if (_newSubStem) {
                let _oldStep = _.findWhere(this.data, { id: step.id });
                _oldStep.colorCode = step.colorCode;

                let _oldSubSteps = _oldStep.subSteps;
                let _oldSubStep = _.findWhere(_oldSubSteps, { id: stepId });
                if (_oldSubStep) {
                    _oldSubStep.isCompleted = _newSubStem.isCompleted;
                    _oldSubStep.colorCode = _newSubStem.colorCode;
                    _oldSubStep.additionalInfo = _newSubStem.additionalInfo;
                }
            }
        });
    }

    updateAllProgress(response) {
        angular.forEach(response, (step) => {
            let _subSteps = step.subSteps;
            let _oldStep = _.findWhere(this.data, { id: step.id });
            if (_oldStep) {
                _oldStep.isCompleted = step.isCompleted;
                _oldStep.colorCode = step.colorCode;
                _oldStep.canAddSupplier = step.canAddSupplier;
                _oldStep.requestCount = step.requestCount;
            }

            angular.forEach(_subSteps, (subStep) => {
                let _oldSubSteps = _oldStep.subSteps;
                let _oldSubStep = _.findWhere(_oldSubSteps, { id: subStep.id });
                if (_oldSubStep) {
                    _oldSubStep.isCompleted = subStep.isCompleted;
                    _oldSubStep.colorCode = subStep.colorCode;
                    _oldSubStep.additionalInfo = subStep.additionalInfo;
                }
            });
        });
    }

    closePopUp() {
        this.stopVideo({ id: this.showToolTip });
        this.showToolTip = null;
    }

    closePopUpAndStopVideo(subStep) {
        this.closePopUp();
        this.stopVideo(subStep);
    }

    stopVideo(subStep) {
        var $frame = $('iframe#vimeo-frame-' + subStep.id);
        var src = $frame.attr('src');
        if (src) {
            $frame.attr('src', '');
            $frame.attr('src', src);
        }
    }

    showPopUp(subStep) {
        this.stopVideo({ id: this.showToolTip });
        this.showToolTip = subStep.id;
        this.activeSubStep = subStep.name;
    }

    openToolTip(subStep) {
        if (subStep.name === 'event.progress.steps.create.event') {
            return false;
        }
        return this.showToolTip === subStep.id;
    }

    openEventSettingModal() {
        if(this.viewEditEventModal) {
            return
        }
        this.viewEditEventModal = true;
        let { ModalService } = this._injected;
        let data = {
            eventId: this.eventId,
            perferTab: 'TAX_RELATED_TAB',
        };

        let _editEventModal = editEventModal;
        _editEventModal.inputs = { data };
        ModalService.showModal(_editEventModal).then((modal) => {
            this.viewEditEventModal = false;
            modal.element.modal();
            modal.close.then(() => {
                console.log('modal is closed!');
            });
        });
    }

    toggleMainSteps(id) {
        this.stopVideo({ id: this.showToolTip });
        setTimeout(function () {
            var steps = jQuery('#mainStep' + id);
            var checkElement = jQuery('#getSubStep' + id);

            jQuery('#accordian li').removeClass('active');
            steps.closest('li').addClass('active');
            jQuery('#accordian ul li a').attr('aria-expanded', 'false').addClass('collapsed');

            if (checkElement.is(':visible')) {
                steps.closest('li').removeClass('active');
                checkElement.slideUp('normal');
            }
            if (!checkElement.is(':visible')) {
                jQuery('#accordian ul ul:visible').slideUp('normal');
                steps.attr('aria-expanded', 'true').removeClass('collapsed');
                checkElement.slideDown('normal');
            }
        }, 1);
    }

    _getTemplateUrl(mainStepIndex, subStepIndex) {
        let _baseUrl = 'components/directives/event_process_sidebar/partials/';
        _baseUrl = _baseUrl + (mainStepIndex + 1) + '/';
        let _url = _baseUrl + '_' + (subStepIndex + 1) + '.html';
        this.templateUrl = _url;
        return _url;
    }

    getVideoUrl(url) {
        const { $sce } = this._injected;
        let _videoUrl = $sce.trustAsResourceUrl(url);
        return ' ';
    }

    closeSidebar() {
        this.showSideBar = false;
        this.showEventProcessSidebarCallback({ show: this.showSideBar });
    }

    showEventProcessSidebar() {
        const { $scope, EventProgress } = this._injected;

        this.showSideBar = !this.showSideBar;
        this.closePopUp();
        if (this.showSideBar) {
            $scope.startProgress('event-progress');
            EventProgress.getEventProgress(
                { eventId: this.eventId },
                (response) => {
                    // this.data = response;
                    this.updateAllProgress(response);
                    $scope.endProgress('event-progress');
                },
                $scope.endWithAlert
            );
        }
        this.showEventProcessSidebarCallback({ show: this.showSideBar });
    }

    showEventProcessSidebarStep(elementId) {
        const { $scope, EventProgress } = this._injected;
        this.showSideBar = true;
        var step = jQuery('#mainStep' + elementId);
        this.closePopUp();
        $scope.startProgress('event-progress');
        EventProgress.getEventProgress(
            { eventId: this.eventId },
            (response) => {
                this.updateAllProgress(response);
                $scope.endProgress('event-progress');
            },
            $scope.endWithAlert
        );
        if (!step.closest('li').hasClass('active')) {
            this.toggleMainSteps(elementId);
        }
        this.showEventProcessSidebarCallback({ show: this.showSideBar });
    }

    highlightAddCollaboratorButton() {
        this.animateInviteUser({ animate: true });
        this.showToolTip = null;
    }

    viewOfferFinalDetails() {
        // (close, go to content and open offerPreviewModal)
        const { $state, ActiveTabService } = this._injected;
        this.showSideBar = false;
        this.closePopUp();
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.overview') {
            $state.go('event.detail.overview', {
                eventId: this.eventId,
            });
            ActiveTabService.setEventDetailTab('overview');
        }
        this.openOfferPreviewModalCallback();
        this.showEventProcessSidebarCallback({ show: this.showSideBar });
    }

    addSupplier() {
        const { $state, ActiveTabService } = this._injected;
        this.closePopUp();
        // this.showSideBar = false;
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.overview') {
            $state.go('event.detail.overview', { eventId: this.eventId, highlightElement: 'addSupplierButton' });
            ActiveTabService.setEventDetailTab('overview');
        }
    }

    goEventSummary() {
        // (close and go to budget and highlight event summary)
        const { $state, ActiveTabService } = this._injected;
        // this.showSideBar = true;
        this.closePopUp();
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.budget') {
            $state.go('event.detail.budget', { highlightElement: 'eventSummaryButton' });
            ActiveTabService.setEventDetailTab('budget');
        }
        // this.showEventProcessSidebarCallback({show: this.showSideBar});
    }

    goViewOffer(toHighlight) {
        // (close, go to content and highlight view offer/s)
        const { $state, ActiveTabService } = this._injected;
        // this.showSideBar = false;
        this.closePopUp();
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.overview') {
            $state.go('event.detail.overview', {
                eventId: this.eventId,
                requestId: this.requestId,
                offerId: this.offerId,
                highlightElement: toHighlight,
            });
            ActiveTabService.setEventDetailTab('overview');
        }
    }

    goInvitationPage() {
        const { $state, ActiveTabService } = this._injected;
        // this.showSideBar = false;
        this.closePopUp();
        // (close and go to Invitation > Invitation participants/design_invitation/form
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.participants.design_invitation.design-form') {
            $state.go('event.detail.participants.design_invitation.design-form', { eventId: this.eventId });
            ActiveTabService.setEventDetailTab('participants');
            ActiveTabService.setParticipantsSubTab('design_invitation');
            ActiveTabService.setInvitationTab('form');
        }
    }

    goRegistrationForm() {
        const { $state, ActiveTabService } = this._injected;
        // this.showSideBar = false;
        this.closePopUp();
        //participants/design_invitation/registration_form
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.participants.design_invitation.design-registration_form') {
            $state.go('event.detail.participants.design_invitation.design-registration_form', {
                eventId: this.eventId,
            });
            ActiveTabService.setEventDetailTab('participants');
            ActiveTabService.setParticipantsSubTab('design_invitation');
            ActiveTabService.setInvitationTab('registration_form');
        }
    }

    goParticipants() {
        const { $state, ActiveTabService } = this._injected;
        // this.showSideBar = false;
        this.closePopUp();
        //participants/list
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.participants.list') {
            $state.go('event.detail.participants.list', { eventId: this.eventId });
            ActiveTabService.setEventDetailTab('participants');
            ActiveTabService.setParticipantsSubTab('list');
        }
    }

    goUpdateBooking() {
        const { $state, ActiveTabService } = this._injected;
        this.closePopUp();
        // this.showSideBar = false;
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.overview') {
            $state.go('event.detail.overview', { eventId: this.eventId });
            ActiveTabService.setEventDetailTab('overview');
        }
    }

    shareParticipants() {
        const { $state, ActiveTabService } = this._injected;
        // this.showSideBar = false;
        this.closePopUp();
        //(close and go to participants and highlight button share participants)
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.participants.list') {
            $state.go('event.detail.participants.list', {
                eventId: this.eventId,
                highlightElement: 'shareParticipantListButton',
            });
            ActiveTabService.setEventDetailTab('participants');
            ActiveTabService.setParticipantsSubTab('list');
        }
    }

    informParticipants() {
        const { $state, ActiveTabService } = this._injected;
        // this.showSideBar = false;
        this.closePopUp();
        //(close and go to participant and highlight ”Send group message”)
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.participants.list') {
            $state.go('event.detail.participants.list', {
                eventId: this.eventId,
                highlightElement: 'groupMessageButton',
            });
            ActiveTabService.setEventDetailTab('participants');
        }
    }

    startScheduleGuide(mainStep) {
        const { $rootScope, $state, EventGuideDataStore, EventEnum, ActiveTabService } = this._injected;
        let _currentState = $state.current;
        if (_currentState !== 'event.detail.overview') {
            $state.go('event.detail.overview', { eventId: this.eventId });
            ActiveTabService.setEventDetailTab('overview');
        }
        EventGuideDataStore.setShowOnBoardingGuide(true);
        $rootScope.$broadcast(EventEnum.EVENT_GUIDE_INTRO_START, {
            skipNo4: !mainStep.canAddSupplier,
            hasRequest: mainStep.requestCount > 0,
        });
        this.showToolTip = null;
    }

    displayEventGuideTooltip() {
        const { EventGuideDataStore } = this._injected;
        return EventGuideDataStore.showEventProgressGuideTooltip;
    }

    closeEventGuideTooltip() {
        const { EventGuideDataStore } = this._injected;
        EventGuideDataStore.setShowEventProgressGuideTooltip(false);
    }
}

EventProcessSidebarCtrl.$inject = [
    '$element',
    '$rootScope',
    '$scope',
    'DateService',
    '$stateParams',
    'EventProgress',
    '$sce',
    'AuthenticationService',
    'Event',
    '$state',
    'ActiveTabService',
    'EventGuideDataStore',
    'EventEnum',
    'ModalService',
];
export default EventProcessSidebarCtrl;
