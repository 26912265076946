import amountAndPriceService from '../../../utils/price_calculate/PriceCalculationCreatorUtil';
import requestOfferPartUtil from '../../../utils/request_offer_parts/RequestOfferPartsUtil';
import currencyUtil from '../../../utils/CurrencyUtil';
import requestOfferPartsUtil from '../../../utils/request_offer_parts/RequestOfferPartsUtil';
import {UtilFunctions} from "../../../utils/global/utilFunctions";
import _ from 'underscore';

class OfferPriceCompareController {
    constructor($scope, $translate, $filter, $timeout) {
        this._injected = {
            $scope,
            $translate,
            $filter,
            $timeout,
        };
        let selectedOfferChanged = function () {
            this.setupData();
        }.bind(this);
        $scope.$watch(() => this.selectedOffer, selectedOfferChanged);
        this.includeVat = amountAndPriceService.shouldIncludeVatOnPrice();
        this.getPrice = amountAndPriceService.getPrice;
        this.getAmountLocalisedWithCurrency = amountAndPriceService.getAmountLocalisedWithCurrency;
        this.getAmountType = function (amountType) {
            if (amountType === 'SAME_AS_EVENT' || amountType === 'PEOPLE') {
                return $translate.instant('person.lowercase');
            } else if (amountType == 'PIECES') {
                return $translate.instant('piece.lowercase');
            }
            return amountType;
        };
        this.getAmount = function (offerPart) {
            return !UtilFunctions.isUndefinedOrNull(offerPart.finalAmount) && offerPart.finalAmount >= 0
                ? offerPart.finalAmount
                : offerPart.amount;
        };
    }

    setupData() {
        var self = this;
        let filterOffer = function (requestOffer) {
            return (
                requestOffer.offerParts &&
                ((requestOffer.supplier && requestOffer.statusDetailed.name != 'CANCELED') ||
                    requestOffer.statusDetailed.name == 'INTERNAL')
            );
        };
        const filteredOffers = this.requestOffers.filter((requestOffer) => filterOffer(requestOffer));
        const filteredOfferParts = requestOfferPartUtil.filterOfferPartsForCreator(filteredOffers[0], this.offerParts);

        // for IE supportive
        if (typeof Object.assign !== 'function') {
            Object.assign = function (target) {
                'use strict';
                if (target === null) {
                    throw new TypeError('Cannot convert undefined or null to object');
                }

                target = Object(target);
                for (var index = 1; index < arguments.length; index++) {
                    var source = arguments[index];
                    if (source !== null) {
                        for (var key in source) {
                            if (Object.prototype.hasOwnProperty.call(source, key)) {
                                target[key] = source[key];
                            }
                        }
                    }
                }
                return target;
            };
        }

        // Calculate the part wise offer data
        this.offerPartWiseDataList = filteredOfferParts.map(function (part) {
            let offerPartCompareData = {
                id: part.id,
                name: part.name,
                nameCode: part.nameCode,
                status: part.status,
                dateTimeFrom: part.dateTimeFrom,
                dateCreated: part.dateCreated,
                amount: part.amount,
                finalAmount: part.finalAmount,
                amountType: part.amountType,
                hasRequestOfferPartOptions: part.hasRequestOfferPartOptions,
            };
            let offerWiseData = [];
            filteredOffers.forEach((offer) => {
                let _offerParts = requestOfferPartUtil.filterOfferPartsForCreator(offer, offer.offerParts);
                const matchedOfferPart = _offerParts.find((offerPart) => part.referenceId == offerPart.referenceId);
                if (matchedOfferPart) {
                    let offerData = {
                        supplier: offer.supplier.name ? offer.supplier.name : 'INTERNAL',
                        status: offer.status.name,
                        offer: { id: offer.id, status: offer.status, currency: offer.currency },
                    };
                    offerData.name = matchedOfferPart.name;
                    offerData.nameCode = matchedOfferPart.nameCode;
                    offerData.vat = matchedOfferPart.vat;
                    offerData.amountType = matchedOfferPart.amountType;
                    offerData.price = matchedOfferPart.price;
                    offerData.newPrice = matchedOfferPart.price;
                    offerData.finalPrice = matchedOfferPart.finalPrice;
                    offerData.amount = matchedOfferPart.amount;
                    offerData.finalAmount = matchedOfferPart.finalAmount;
                    offerData.offerPartOptions = matchedOfferPart.offerPartOptions;
                    offerData.hasCommentOnPart = UtilFunctions.hasCommentOnPart(matchedOfferPart);
                    offerData.isSupplierSelected = offer.id == self.selectedOffer.id;
                    let totalPrice = amountAndPriceService.getTotalPrice(
                        offer,
                        Object.assign({}, matchedOfferPart, {
                            newPrice: matchedOfferPart.newPrice ? matchedOfferPart.newPrice : matchedOfferPart.price,
                        })
                    );
                    offerData.totalPrice = amountAndPriceService.getAmountLocalisedWithCurrency(
                        totalPrice,
                        offer.currency,
                        2
                    );
                    offerWiseData.push(offerData);
                }
            });
            offerPartCompareData.offerWiseData = offerWiseData;
            return offerPartCompareData;
        });
        // calculate the total price of offer
        // this.offerTotalPriceWiseList = filteredOffers.map(function(offer) {
        //   const offerParts = requestOfferPartUtil.filterOfferPartsForCreator(offer , offer.offerParts);
        //   const offerPriceNotSet = offerParts.every(part=> self.isUndefinedOrNull(part.newPrice) &&
        //   self.isUndefinedOrNull(part.price));
        //   let totalOfferPrice = 0;
        //   if (!offerPriceNotSet) {
        //     totalOfferPrice = offerParts.map(part => amountAndPriceService.getTotalPrice(offer, Object.assign({}, part,
        //       { newPrice: part.newPrice ? part.newPrice : part.price }))).reduce((a, b) => a + b);
        //     totalOfferPrice = currencyUtil.getAmountLocalisedWithCurrency(totalOfferPrice, offer.currency, 2);
        //   }
        //   let isSupplierSelected = offer.id == self.selectedOffer.id;
        //   let status = offer.status;
        //   return { price: totalOfferPrice, isSupplierSelected: isSupplierSelected, offerPriceNotSet: offerPriceNotSet, status: status };
        // });
    }

    recalculateOfferWiseTotal(offerId) {
        let _selectedOffer = _.findWhere(this.requestOffers, { id: offerId });
        return this.getSelectedOfferTotalPrice(_selectedOffer);
    }

    getSelectedOfferTotalPrice(offer) {
        let offerTotal = 0;
        const _offerParts = requestOfferPartsUtil.filterOfferPartsForCreator(offer, offer.offerParts);
        angular.forEach(_offerParts, (offerPart) => {
            if (offerPart.nameCode !== 'common.translate.offer.part.type.cancellation.fee' && offerPart.selected) {
                var total = 0;
                if (offerPart.hasRequestOfferPartOptions) {
                    total += this.getRequestPartOptionTotalPrice(offer, offerPart);
                } else {
                    total += amountAndPriceService.getTotalPrice(offer, offerPart);
                }
                if (offerPart.markupValue > 0 && this.showMarkedPrice) {
                    total += (total * offerPart.markupValue) / 100;
                }
                offerTotal += total;
            }
        });
        return offerTotal;
    }

    toggleResourceViewOption(part, offerWiseData) {
        if (offerWiseData.viewResource && part.viewResource) {
            part.viewResource = false;
            offerWiseData.viewResource = false;
        } else {
            angular.forEach(part.offerWiseData, (_data) => {
                _data.viewResource = false;
            });
            part.viewResource = true;
            offerWiseData.viewResource = true;
        }
    }

    getPriceFromPartOption(offerWiseData) {
        let _totalPrice = 0;
        let _selectedOfferPartOptions = _.filter(offerWiseData.offerPartOptions, (offerPartOption) => {
            return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
        });
        angular.forEach(_selectedOfferPartOptions, (_selectedOfferPartOption) => {
            let _offerPartOptionPrice = _selectedOfferPartOption.finalPrice
                ? _selectedOfferPartOption.finalPrice
                : _selectedOfferPartOption.price;
            if (this.includeVat) {
                let _vat = 0;
                if (_selectedOfferPartOption.vat > 0) {
                    _vat = (_offerPartOptionPrice * _selectedOfferPartOption.vat) / 100;
                }
                _totalPrice += parseFloat(_offerPartOptionPrice) + parseFloat(_vat);
            } else {
                _totalPrice += _offerPartOptionPrice;
            }
        });
        return this.round(_totalPrice);
    }

    round(value) {
        let decimals = 2; //only take 2 digit after decimal
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    getRequestPartOptionTotalPrice(offer, offerWiseData) {
        let _offerPartOptions = _.filter(offerWiseData.offerPartOptions, (offerPartOption) => {
            return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
        });
        let _totalPrice = 0;
        angular.forEach(_offerPartOptions, (_offerPartOption) => {
            _totalPrice += amountAndPriceService.getRequestPartOptionTotalPrice(offer, _offerPartOption);
        });
        return this.round(_totalPrice);
    }

    viewPriceLocalisedWithCurrency(price, currency, digitsAfterDecimal = 0) {
        return amountAndPriceService.viewPrice(price, currency, digitsAfterDecimal);
    }

    isUndefinedOrNull(val) {
        return UtilFunctions.isUndefinedOrNull(val);
    }

    isPriceUndefinedOrNull(val) {
        return UtilFunctions.isUndefinedOrNull(val.price) && UtilFunctions.isUndefinedOrNull(val.finalPrice);
    }

    filterAnsweredSupplier(offer) {
        return (
            offer.status !== 'SENT' &&
            offer.status !== 'NEW' &&
            offer.status !== 'CANCELED' &&
            offer.status !== 'DECLINED'
        );
    }
}

OfferPriceCompareController.$inject = ['$scope', '$translate', '$filter', '$timeout'];
export default OfferPriceCompareController;
