class EventDetailsData {
    constructor() {
        // see se.eventlogic.centevent.dtos.EventJsonDTO
        this.eventDetails = {};
    }

    setEventDetails(data) {
        this.eventDetails = data;
    }

    setRequests(requests) {
        this.eventDetails.requests = requests;
    }

    getEventDetails() {
        return this.eventDetails;
    }

    /**
     * checks if the event has at least a request
     * @returns {*|boolean}
     */
    hasRequest() {
        if (this.eventDetails.requests && this.eventDetails.requests.length > 0) {
            return true;
        }
        return false;
    }

    setVat(vat) {
        this.eventDetails.enableVat = vat;
    }

    hasVatEnabled() {
        return this.eventDetails.enableVat;
    }
}

export default new EventDetailsData();
