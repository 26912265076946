/**
 * Created by sampurlachhantyal on 9/1/17.
 */
class ViewMailContentModal {
    constructor(close, $element, $sce, data, $rootScope, $timeout, Participant, $scope) {
        this._injected = { close, $element, $sce, data, $rootScope, $timeout, Participant, $scope };
        this.eventId = data.eventId;
        this.mail = data.mail;
        this.successCallback = data.successCallback;
        this.trustAsHtml = this.trustAsHtml.bind(this);
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }

    trustAsHtml(string) {
        const { $sce } = this._injected;
        return $sce.trustAsHtml(string);
    }
}

ViewMailContentModal.$inject = ['close', '$element', '$sce', 'data', '$rootScope', '$timeout', 'Participant', '$scope'];
export default ViewMailContentModal;
