'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogOfferConfirmedModal', [
    '$rootScope',
    '$http',
    '$stateParams',
    'CustomerUser',
    'AuthenticationService',
    function ($rootScope, $http, $stateParams, CustomerUser, AuthenticationService) {
        return new OgModal({
            name: 'offer:confirmed',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                    $scope.form = 'OfferConfirmedForm';
                    $scope.hideChangedFieldIcon = true;
                    $scope.hasAcceptedTerms = false;
                    $scope.dataToSave = undefined;

                    $scope.initDialog = function (params) {
                        $scope.type = params.type;
                        $scope.dataToSave = params.data;
                        $scope.dataLoaded({
                            email: AuthenticationService.getUserData().username,
                        });
                        $scope.userRegistered = false;

                        $scope.showTerms = false;
                        let _baseUrl = 'components/modals/modals2.0/custom-message-modal/partials/';
                        $scope.templateUrl = _baseUrl + '_' + $rootScope.langSelected + '_terms_and_conditions.html';
                    };

                    $scope.validatePassword = function () {
                        if (!($scope.data && $scope.data.password)) {
                            return true;
                        }
                        let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)');
                        return regex.test($scope.data.password);
                    };

                    $scope.passwordsNotMatch = function () {
                        return !(
                            $scope.data &&
                            $scope.data.password &&
                            $scope.data.password2 &&
                            $scope.data.password === $scope.data.password2
                        );
                    };

                    $scope.showPasswordStrength = function (password) {
                        if (password) {
                            //Uppercase Words only
                            if (password.match(/[A-Z]/) != null) {
                                jQuery('.uppercase-text .progress-text').addClass('active');
                            } else {
                                jQuery('.uppercase-text .progress-text').removeClass('active');
                            }
                            //Lowercase words only
                            if (password.match(/[a-z]/) != null) {
                                jQuery('.lowercase-text .progress-text').addClass('active');
                            } else {
                                jQuery('.lowercase-text .progress-text').removeClass('active');
                            }
                            //Digits only
                            if (password.match(/[0-9]/) != null) {
                                jQuery('.number-in-text .progress-text').addClass('active');
                            } else {
                                jQuery('.number-in-text .progress-text').removeClass('active');
                            }
                            //Password length
                            if (password.length > 7) {
                                jQuery('.eight-characters-long .progress-text').addClass('active');
                            } else {
                                jQuery('.eight-characters-long .progress-text').removeClass('active');
                            }
                        } else {
                            jQuery('.offer-progress-bar-wrap .progress-bar-item .progress-text').each(function () {
                                jQuery(this).removeClass('active');
                            });
                        }
                    };

                    // fetch the suggested password and set to scope
                    $scope.getGeneratedPassword = function () {
                        CustomerUser.getPassword(
                            function (result) {
                                $scope.generatedPassword = result.generatedPassword;
                            },
                            function (error) {
                                console.log(
                                    '>>> Error has occurred while getting generated password from API ' + error
                                );
                            }
                        );
                    };

                    //fills the password with the suggested password
                    $scope.fillPasswordFields = function () {
                        $scope.data.password = $scope.generatedPassword;
                        $scope.data.password2 = $scope.generatedPassword;
                    };

                    $scope.register = function () {
                        $scope.startProgress();
                        //TODO(SC) in this case we need to change both info and credentials
                        $scope.data.generalInfoOnly = true;
                        $scope.data.credentialsOnly = true;
                        CustomerUser.updateUserProfile(
                            $scope.data,
                            function (data) {
                                $scope.dataLoaded(data);
                                $scope.userRegistered = true;
                            },
                            $scope.endWithError
                        );
                    };

                    $scope.showTermsAndConditions = function () {
                        $scope.showTerms = true;
                    };

                    $scope.hideTermsAndConditions = function () {
                        $scope.showTerms = false;
                    };
                },
            });
        },
    ]);

