'use strict';
angular.module('core.existingServices').factory('CustomerUser', [
    '$resource',
    '$http',
    function ($resource, $http) {
        var customerUserRest = $resource(
            'api/v1/user/:userId',
            {userId: '@userId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var customerUserGroupsRest = $resource(
            'api/v1/user/groups',
            {},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var createGroupRest = $resource(
            'api/v1/user/createGroup',
            {},
            {
                do: {method: 'POST', isArray: false},
            }
        );
        var removeUserFromGroupRest = $resource(
            'api/v1/user/removeUserFromGroup',
            {},
            {
                do: {method: 'POST', isArray: true},
            }
        );
        var customerUserEmailRest = $resource(
            'api/v1/user/email',
            {},
            {
                getByEmail: {method: 'POST', isArray: false},
            }
        );

        var customerUserSettingsRest = $resource(
            'api/v1/user/settings',
            {},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var currentCustomerRest = $resource(
            'api/v1/user/currentUser',
            {},
            {
                getCurrentCustomer: {method: 'GET', isArray: false},
            }
        );

        var updateUserProfileRest = $resource(
            'api/v1/user/updateUserProfile',
            {},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var getFailedLoginAttemptCountRest = $resource(
            'api/v1/user/getFailedLoginAttemptCount',
            {},
            {
                update: {method: 'POST', isArray: false},
            }
        );

        var getUserEmailRest = $resource(
            'api/v1/user/allUsersEmail',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        //password generator
        var getGeneratedPassword = $resource(
            'api/v1/user/getPassword',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        //fetch company names
        const fetchCompanyName = $resource(
            'api/v1/user/getCompanyNames',
            {},
            {
                get: {method: 'POST', isArray: false},
            }
        );
        var createCustomerUserActivityTracker = $resource(
            'api/v1/user/createCustomerUserActivityTracker',
            {},
            {
                update: {method: 'POST', isArray: false},
            }
        );

        var updateUserProfilePictureRest = function (formData, successCallback, errorCallback) {
            $http
                .post('api/v1/user/updateUserProfilePicture', formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };

        return {
            getCustomerUserById: function (userId, successCallback, errorCallback) {
                return customerUserRest.get({userId: userId}, successCallback, errorCallback);
            },
            getUserGroups: function (successCallback, errorCallback) {
                return customerUserGroupsRest.list({}, successCallback, errorCallback);
            },
            removeUserFromGroup: function (params, successCallback, errorCallback) {
                return removeUserFromGroupRest.do(params, successCallback, errorCallback);
            },
            createUserGroup: function (params, successCallback, errorCallback) {
                return createGroupRest.do(params, successCallback, errorCallback);
            },
            getByEmail: function (jsonObject, successCallback, errorCallback) {
                return customerUserEmailRest.getByEmail({}, jsonObject, successCallback, errorCallback);
            },
            updateSettings: function (jsonObject, successCallback, errorCallback) {
                return customerUserSettingsRest.update({}, jsonObject, successCallback, errorCallback);
            },
            getCurrentCustomerProfile: function (successCallback, errorCallback) {
                return currentCustomerRest.getCurrentCustomer({}, successCallback, errorCallback);
            },
            updateUserProfile: function (customerUser, successCallback, errorCallback) {
                return updateUserProfileRest.update(customerUser, successCallback, errorCallback);
            },
            getAllUserEmail: function (successCallback, errorCallback) {
                return getUserEmailRest.get({}, successCallback, errorCallback);
            },
            updateUserProfilePicture: function (formData, successCallback, errorCallback) {
                return updateUserProfilePictureRest(formData, successCallback, errorCallback);
            },
            getFailedLoginAttemptCount: function (params, successCallback, errorCallback) {
                return getFailedLoginAttemptCountRest.update(params, successCallback, errorCallback);
            },

            //get generated password from the api
            getPassword: function (successCallback, errorCallback) {
                return getGeneratedPassword.get({}, successCallback, errorCallback);
            },
            createCustomerUserActivityTracker: function (jsonObject, successCallback, errorCallback) {
                return createCustomerUserActivityTracker.update(jsonObject, successCallback, errorCallback);
            },
            //get company names if domain name matches with the customer user's
            fetchCompanyName: function (jsonObject, successCallback, errorCallback) {
                return fetchCompanyName.get(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

