'use strict';
angular.module('core.existingServices').factory('Note', [
    '$resource',
    function ($resource) {
        var noteRest = $resource(
            'api/v1/note',
            {},
            {
                list: {method: 'GET', isArray: false},
                save: {method: 'POST', isArray: false},
                update: {method: 'PUT', isArray: false},
            }
        );

        return {
            list: function (jsonObject, successCallback, errorCallback) {
                return noteRest.list(jsonObject, successCallback, errorCallback);
            },
            save: function (jsonObject, successCallback, errorCallback) {
                return noteRest.save(jsonObject, successCallback, errorCallback);
            },
            update: function (jsonObject, successCallback, errorCallback) {
                return noteRest.update(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

