import mailUnsubscriptionModal from '../../../../modals/modals2.0/mail-unsubscription-modal';
import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";

(function () {
    'use strict';
    angular.module('unsubscribed-info').component('unsubscribedInfo', {
        templateUrl: './app/components/modules/participant/participant_details/unsubscribed/unsubscribed_info.view.html',
        controllerAs: 'unsubscribedInfoCtrl',
        bindings: {
            personalizedPageDetails: '<'
        },
        controller: [
            '$scope',
            '$rootScope',
            '$state',
            '$location',
            '$stateParams',
            'Participant',
            'ModalService',
            function UnsubscribedInfoController(
                $scope,
                $rootScope,
                $state,
                $location,
                $stateParams,
                Participant,
                ModalService
            ) {
                var vm = this;
                var fromPublicSite = true;
                var unsubscribe = $location.search().unsubscribe;
                vm.viewMailUnsubscriptionModal = false;
                vm.reSubscribe = reSubscribeFn;
                ScopeFunctions.addToScope($scope);
                vm.init = initFn;
                vm.$onInit = function () {
                    vm.personalizedPageDetailsData = vm.personalizedPageDetails
                    vm.init();
                }

                function initFn() {
                    vm.event = vm.personalizedPageDetailsData.event;
                    vm.isUnsubscribed = vm.personalizedPageDetailsData.mailUnsubscribed;
                    fromPublicSite = vm.personalizedPageDetailsData.fromPublicSite;
                    if (vm.personalizedPageDetailsData && vm.personalizedPageDetailsData.participantLocale) {
                        this.selectedLang = vm.personalizedPageDetailsData.participantLocale;
                        $rootScope.switchLang(vm.personalizedPageDetailsData.participantLocale, true);
                    }
                    if (unsubscribe === 'true') {
                        if (!vm.isUnsubscribed) {
                            let data = {
                                message: 'mail.unsubscribe.confirm',
                                okCallBack: function () {
                                    $scope.startProgress();
                                    Participant.updateParticipantMailSubscribeStatus(
                                        {
                                            hash: $stateParams.hash,
                                            status: unsubscribe,
                                        },
                                        updateViewDetails,
                                        $scope.endWithErrorOverlay
                                    );
                                },
                                cancelCallBack: function () {
                                    vm.isUnsubscribed = false;
                                },
                                closeCallBack: function () {
                                    vm.isUnsubscribed = false;
                                },
                            };
                            if(vm.viewMailUnsubscriptionModal) {
                                return;
                            }
                            vm.viewMailUnsubscriptionModal = true;
                            let mailUnsubscriptionModalData = mailUnsubscriptionModal;
                            mailUnsubscriptionModal.inputs = { data };

                            ModalService.showModal(mailUnsubscriptionModalData).then((modal) => {
                                vm.viewMailUnsubscriptionModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        } else {
                            vm.isUnsubscribed = true;
                        }
                    }
                }

                function updateViewDetails(response) {
                    vm.isUnsubscribed = response.mailUnsubscribed;
                    $scope.endProgress();
                }

                function reSubscribeFn() {
                    $scope.startProgress();
                    Participant.updateParticipantMailSubscribeStatus(
                        {
                            hash: $stateParams.hash,
                            status: false,
                        },
                        function (response) {
                            // here we get the type of the access, we can use that for different use
                            vm.isUnsubscribed = response.mailUnsubscribed;
                            $location.search('unsubscribe', false);
                        }
                    );
                    $scope.endProgress();
                }
            }
        ]
    });
})();
