'use strict';

angular.
    module('core.customTranslationService').
    factory('CustomTranslationService', [
        'AuthenticationService',
        '$translate',
        '$sce',
        function (AuthenticationService, $translate, $sce) {
            function translate(name, code) {
                if (code) {
                    let _userData = AuthenticationService.getUserData();
                    let groupId = _userData.userGroup ? _userData.userGroup.id : null;
                    if (groupId) {
                        let groupWiseCode = code + '.' + groupId;
                        let _groupWiseTranslation = _translate(groupWiseCode);
                        if (_groupWiseTranslation !== groupWiseCode) {
                            return _groupWiseTranslation;
                        } else {
                            let parentGroup = _userData.userGroup.parentUserGroups
                                ? _userData.userGroup.parentUserGroups
                                : null;
                            if (parentGroup) {
                                let _parentWiseTranslation = parentWiseTranslation(code, parentGroup);
                                if (_parentWiseTranslation) {
                                    return _parentWiseTranslation;
                                }
                            }
                        }
                    }
                    let _translation = _translate(code);
                    if (_translation !== code) {
                        return _translation;
                    }
                }
                return name;
            }

            function parentWiseTranslation(code, parentGroup) {
                let parentWiseCode = code + '.' + parentGroup.id;
                let _parentWiseTranslation = _translate(parentWiseCode);
                if (_parentWiseTranslation !== parentWiseCode) {
                    return _parentWiseTranslation;
                } else {
                    let g_parentGroup = parentGroup.parentGroup ? parentGroup.parentGroup : null;
                    if (g_parentGroup) {
                        return parentWiseTranslation(code, g_parentGroup);
                    }
                }
            }

            function _translate(code) {
                let _trans = $translate.instant(code);
                if (_trans !== code) {
                    return $sce.trustAsHtml(_trans);
                }
                return _trans;
            }
            return {
                translate: function (name, code) {
                    return translate(name, code);
                },
            };
        },
    ]);
