const CONFERENCE_CATEGORIES = [
    'common.translate.category.hotel.&.conference',
    'common.translate.category.conference.room',
];
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

class SupplierPresentationController {
    constructor(
        $rootScope,
        $scope,
        $element,
        $location,
        $timeout,
        $translate,
        $sce,
        FieldChangedSvc,
        EventEnum,
        AuthenticationService,
        SupplierDetailInfo,
        Event,
        CustomerUser,
        UserGroup,
        Presentation,
        SupplierDetailService,
        SupplierResources,
        FilledSupplierResourceField,
        DynamicFormUtilService,
        Translation,
        CommonFile
    ) {
        this._injected = {
            $rootScope,
            $scope,
            $element,
            $location,
            $timeout,
            $translate,
            $sce,
            FieldChangedSvc,
            EventEnum,
            AuthenticationService,
            SupplierDetailInfo,
            Event,
            CustomerUser,
            UserGroup,
            Presentation,
            SupplierDetailService,
            SupplierResources,
            FilledSupplierResourceField,
            DynamicFormUtilService,
            Translation,
            CommonFile,
        };

        ScopeFunctions.addToScope($scope);
        let _index = SupplierDetailService.getSupplierDetailIndex();
        this.setSelectedLang($rootScope.langSelected);
        this.transaltionLoading = false;
        this.descriptionTranslated = false;
        //for default presentation descriptions
        this.translatedPresentationDescription = '';
        this.isThereConference = false;
        this.isThereConferenceActivity = false;
        this.$onInit = function () {
            this.showSupplierDetails(this.supplier, _index);
            if (this.displaySupplierDetailsTab) {
                this.displaySupplierDetails = this.displaySupplierDetailsTab;
            } else {
                this.displaySupplierDetails = 'Hotel_details';
            }
        }
    }

    setSelectedLang(lang) {
        switch (lang) {
            case 'sv_SV':
                this.langSelected = 'SWEDISH';
                break;
            case 'nb_NB':
                this.langSelected = 'NORWEGIAN';
                break;
            case 'da_DK':
                this.langSelected = 'DANISH';
                break;
            case 'fi_FI':
                this.langSelected = 'FINNISH';
                break;
            default:
                this.langSelected = 'ENGLISH';
        }
    }

    setCarouselFullSize(index) {
        this.showOverlayedCarousel = index;
    }

    resetCarouselFullSize(user) {
        this.showOverlayedCarousel = null;
    }

    showSupplierDetails(supplier, index) {
        const { SupplierDetailService } = this._injected;
        //this.removedSuppliers.id == supplier.id ? null : this.vmData.selectedIndex = $index;
        this.getDefaultSelectedImage(supplier);
        this.disableInfiniteScroll = true;
        this.getSupplierDetailInfo(supplier);
        this.getSupplierResources(supplier);
        SupplierDetailService.setSupplierDetailStatus(index);
        this.selectedSuppliersForDetails = supplier;
        this.setPresentationDescription(this.selectedSuppliersForDetails.presentation);
    }

    setPresentationDescription(presentation) {
        const { Translation, $scope } = this._injected;
        const lang = this.langSelected;
        this.showTranslateButton = false;
        this.sourceLanguage = '';
        this.presentationDescription = '';
        if (presentation && presentation.localisedDescriptions) {
            var _localDesc, _engDesc, _swedDesc, _norDesc, _dkDesc;
            const localisedDescriptions = presentation.localisedDescriptions;
            _localDesc = _.find(localisedDescriptions, (desc) => {
                return desc.language === lang;
            });
            if (!_localDesc) {
                _engDesc = _.find(localisedDescriptions, (desc) => {
                    return desc.language === 'ENGLISH';
                });
            }
            if (!_engDesc) {
                _swedDesc = _.find(localisedDescriptions, (desc) => {
                    return desc.language === 'SWEDISH';
                });
            }

            if (!_swedDesc) {
                _norDesc = _.find(localisedDescriptions, (desc) => {
                    return desc.language === 'NORWEGIAN';
                });
            }

            if (!_norDesc) {
                _dkDesc = _.find(localisedDescriptions, (desc) => {
                    return desc.language === 'DANISH';
                });
            }

            if (_localDesc) {
                this.presentationDescription = _localDesc.text;
            } else if (_engDesc) {
                this.presentationDescription = _engDesc.text;
                this.sourceLanguage = _engDesc.language;
                this.showTranslateButton = true;
            } else if (_swedDesc) {
                this.presentationDescription = _swedDesc.text;
                this.sourceLanguage = _swedDesc.language;
                this.showTranslateButton = true;
            } else if (_norDesc) {
                this.presentationDescription = _norDesc.text;
                this.sourceLanguage = _norDesc.language;
                this.showTranslateButton = true;
            } else if (_dkDesc) {
                this.presentationDescription = _dkDesc.text;
                this.sourceLanguage = _dkDesc.language;
                this.showTranslateButton = true;
            }
        }
        this.originalPresentationDescription = angular.copy(this.presentationDescription);
    }

    showOriginalDescription() {
        this.presentationDescription = this.originalPresentationDescription;
        this.descriptionTranslated = false;
    }

    translateDescription(lang) {
        const { Translation, $scope } = this._injected;
        var txtToTranslate = this.presentationDescription;
        var sourceLang = this.sourceLanguage;

        this.transaltionLoading = true;
        let params = {
            text: txtToTranslate,
            target: lang,
            source: sourceLang,
        };
        if (this.translatedPresentationDescription) {
            this.presentationDescription = this.translatedPresentationDescription;
            this.descriptionTranslated = true;
            this.transaltionLoading = false;
        } else {
            $scope.startProgress();
            Translation.getTranslatedText(
                params,
                (response) => {
                    this.presentationDescription = response.text;
                    this.sourceLanguage = response.source;
                    // this.translatedLang = response.target;
                    this.transaltionLoading = false;
                    this.descriptionTranslated = true;
                    $scope.endProgress();
                },
                $scope.endWithErrorOverlay()
            );
        }
    }

    /*isSupplierRemoved() {
      if(this.removedSuppliers && this.supplier) {
        return parseInt(this.removedSuppliers.id) === parseInt(this.supplier.id);
      }
      return false;
    }*/

    shouldDisplaySupplierDetails() {
        const { SupplierDetailService } = this._injected;
        return SupplierDetailService.getSupplierDetailStatus();
    }

    getSupplierDetailInfo(supplier) {
        const { SupplierDetailInfo, $scope } = this._injected;
        $scope.startProgress();
        SupplierDetailInfo.get(
            supplier.id,
            (response) => {
                this.supplierDetailGroups = response.supplierDetailGroups;
                this.selectedCategory = response.selectedCategory;
                if (this.selectedCategory) {
                    this.isThereConference = CONFERENCE_CATEGORIES.indexOf(this.selectedCategory.nameCode) > -1;
                }
                this.isThereConferenceActivity = response.canSupplierAddActivityResource;
                this.supportedTabs = response.supportedTabs;
                this.sortDetailsList();
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    getSupplierResources(supplier) {
        const { SupplierResources, $scope } = this._injected;
        $scope.startProgress();
        SupplierResources.getResources(
            { supplierId: supplier.id },
            (res) => {
                this.allResources = res;
                this.getFilledSupplierResourceField(
                    this.getAllMatchingResources('common.translate.offer.part.type.conference.room')
                );
                // Convert {key1 : [values], [key2: [values ] } to array
                const objectValues = Object.values(this.allResources);
                this.getFilledSupplierActivityResourceField([].concat.apply([], objectValues));
                $scope.endProgress();
            },
            $scope.endWithError
        );
    }

    getFilledSupplierResourceField(resources) {
        const { FilledSupplierResourceField, $scope, DynamicFormUtilService, $rootScope, $translate } = this._injected;
        this.filledSupplierResourceFieldList = [];
        angular.forEach(resources, (resource) => {
            FilledSupplierResourceField.list(
                'ALL',
                resource.offerPartTypeNameCode,
                resource.id,
                this.supplier.id,
                (resp) => {
                    this.list = resp;
                    angular.forEach(this.list, (list) => {
                        if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                            let _listValuesCode = list.listValueCodes;
                            let _listValues = $translate.instant(_listValuesCode);
                            let _fieldValue;
                            if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                                _fieldValue = false;
                            } else {
                                _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                            }
                            list.fieldValue = DynamicFormUtilService.getFieldValue(
                                _listValues,
                                $rootScope.langSelected,
                                _fieldValue
                            );
                        }
                        if (['ICON_RADIO', 'ICON_MULTI_CHECKBOX'].indexOf(list.type.name) > -1) {
                            let _iconList = list.iconList;
                            angular.forEach(_iconList, function (icon) {
                                icon.name = $translate.instant(icon.nameCode);
                            });
                        }
                    });
                    let _resourceArray = { resource: resource, list: this.list };
                    this.filledSupplierResourceFieldList.push(_resourceArray);
                    $scope.endProgress();
                },
                function (e) {
                    console.log('error ', e);
                    $scope.endWithError();
                }
            );
        });
    }

    getFilledSupplierActivityResourceField(resources) {
        const { FilledSupplierResourceField, $scope, DynamicFormUtilService, $rootScope, $translate } = this._injected;
        this.filledSupplierActivityResourceFieldList = [];
        angular.forEach(resources, (resource) => {
            if (
                resource.isResourcePublic &&
                resource.offerPartTypeNameCode !== 'common.translate.offer.part.type.conference.room'
            ) {
                FilledSupplierResourceField.list(
                    'ALL',
                    resource.offerPartTypeNameCode,
                    resource.id,
                    this.supplier.id,
                    (resp) => {
                        this.list = resp;
                        angular.forEach(this.list, (list) => {
                            if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                                let _listValuesCode = list.listValueCodes;
                                let _listValues = $translate.instant(_listValuesCode);
                                let _fieldValue;
                                if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                                    _fieldValue = false;
                                } else {
                                    _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                                }
                                list.fieldValue = DynamicFormUtilService.getFieldValue(
                                    _listValues,
                                    $rootScope.langSelected,
                                    _fieldValue
                                );
                            }
                            if (['ICON_RADIO', 'ICON_MULTI_CHECKBOX'].indexOf(list.type.name) > -1) {
                                let _iconList = list.iconList;
                                angular.forEach(_iconList, function (icon) {
                                    icon.name = $translate.instant(icon.nameCode);
                                });
                            }
                        });
                        let _resourceArray = { resource: resource, list: this.list };
                        this.filledSupplierActivityResourceFieldList.push(_resourceArray);
                        $scope.endProgress();
                    },
                    function (e) {
                        console.log('error ', e);
                        $scope.endWithError();
                    }
                );
            }
        });
    }

    enableShowMoreBtn(str, index, code) {
        if (str) {
            let elemID = '';
            switch (code) {
                case 'resource.description':
                    elemID = 'supplier-activity-description-';
                    break;
                case 'resource.includes':
                    elemID = 'supplier-activity-includes-';
                    break;
            }
            let text = document.getElementById(elemID + index);
            let textOffsetHeight = text.clientHeight;
            let textScrollHeight = text.scrollHeight;
            if (textOffsetHeight < textScrollHeight || textScrollHeight > 98) {
                return true;
            } else {
                return false;
            }
        }
    }

    expandShowMoreBtn($event) {
        this.toggleText($event);
    }

    toggleText(e) {
        const expandableElement = e.target.closest('[data-expandable]');
        expandableElement.classList.toggle('expanded');
        this.setExpandButtonText(e.target);
    }

    setExpandButtonText(e) {
        const { $translate } = this._injected;
        const expandableElement = e.closest('[data-expandable]');
        const expanded = expandableElement.classList.contains('expanded');
        e.innerText = expanded ? $translate.instant('common.read.less') : $translate.instant('common.read.more');
    }

    expandActivityDescBtn(index) {
        const { $translate } = this._injected;
        let text = jQuery('#supplier-activity-description-' + index);
        let textHeight = text[0].scrollHeight;
        let btnText = document.getElementById('readActivityBtn_' + index);
        let newHeight = 0;
        if (text.hasClass('active')) {
            newHeight = '100px';
            btnText.innerHTML = $translate.instant('common.read.more');
            text.removeClass('active');
        } else {
            newHeight = textHeight;
            btnText.innerHTML = $translate.instant('common.read.less');
            text.addClass('active');
        }
        text.animate(
            {
                'max-height': newHeight,
            },
            500
        );
    }

    sortDetailsList() {
        angular.forEach(this.supplierDetailGroups, (group) => {
            let _finalList = [];
            let _maxOrder = _.max(group.supplierDetails, _.property('sdsdgDisplayOrder'));
            _maxOrder = _maxOrder ? _maxOrder.sdsdgDisplayOrder : group.supplierDetails.length;
            for (let i = 0; i < _maxOrder; i++) {
                let _obj = _.find(group.supplierDetails, (o) => {
                    return o.sdsdgDisplayOrder === i + 1;
                });
                if (!_obj) {
                    _obj = { sdsdgDisplayOrder: i + 1 };
                    _obj.isEmpty = true;
                }
                _finalList.push(_obj);
            }
            group.supplierDetails = _finalList;
        });
    }

    translateDynamicFields(name, code) {
        const { $translate } = this._injected;
        if (code) {
            let translatedText = $translate.instant(code);
            if (translatedText !== code) {
                return translatedText;
            }
        }
        return name;
    }

    trustAsHtml(string) {
        const { $sce } = this._injected;
        return $sce.trustAsHtml(string);
    }

    add(supplier) {
        this.addSupplierFn({ addedSupplier: supplier });
    }

    isAlreadyAdded() {
        if (this.selectedSuppliersForDetails.added) {
            return true;
        } else if (_.findIndex(this.addedSupplierList, { id: this.selectedSuppliersForDetails.id }) > -1) {
            return true;
        }
        return false;
    }

    isSupplierInCancelState() {
        if (this.canceledOffers) {
            var obj = _.find(this.canceledOffers, (offer) => {
                if (!!offer.supplier) {
                    return parseInt(offer.supplier.id) === parseInt(this.supplier.id);
                }
            });
            return !!obj;
        }
        return false;
    }

    isSupplierInDeclineState() {
        if (this.declinedOffers) {
            var obj = _.find(this.declinedOffers, (offer) => {
                if (!!offer.supplier) {
                    return parseInt(offer.supplier.id) === parseInt(this.supplier.id);
                }
            });
            return !!obj;
        }
        return false;
    }

    isNotEmpty(str) {
        return angular.isDefined(str) && !(str === null || str === 'null') && /\S/.test(str);
    }

    setPresenationTabActive(tabName) {
        this.displaySupplierDetails = tabName;
    }

    scrollToHotelDetails() {
        let _topHeight = jQuery('#hotel-details').position().top;
        // jQuery('#select-supplier-body').scrollTop(_topHeight);
        if (this.modalName == 'offer-preview') {
            jQuery('#offer-preview-modal-body, .supplier-map-presentation-body').animate(
                { scrollTop: _topHeight },
                'slow'
            );
        } else {
            jQuery('#select-supplier-body, .supplier-map-presentation-body').animate({ scrollTop: _topHeight }, 'slow');
        }
    }

    scrollToConferenceRooms() {
        let _topHeight = jQuery('#conference-room').position().top;
        // jQuery('#select-supplier-body').scrollTop(_topHeight);
        if (this.modalName == 'offer-preview') {
            jQuery('#offer-preview-modal-body, .supplier-map-presentation-body').animate(
                { scrollTop: _topHeight },
                'slow'
            );
        } else {
            jQuery('#select-supplier-body, .supplier-map-presentation-body').animate({ scrollTop: _topHeight }, 'slow');
        }
    }

    toggleFullDescription() {
        this.fullDescription = !this.fullDescription;
    }

    getDefaultSelectedImage(supplier) {
        this.selectedImageUrl = null;
        if (supplier && supplier.presentation) {
            let featuredImg = _.find(supplier.presentation.presentationImages, (img) => {
                return img.featured === true;
            });
            if (featuredImg) {
                this.selectedImageUrl = featuredImg.fileStoreId;
            } else if (supplier.presentation.presentationImages.length > 0) {
                this.selectedImageUrl = supplier.presentation.presentationImages[0].fileStoreId;
            }

            if (!this.selectedImageUrl) {
                if (supplier.presentation.mapUrl) {
                    this.selectedImageUrl = supplier.presentation.mapUrl;
                }
            }
        }
    }

    getPresentationImage(image) {
        // let url = 'api/v1/file/getCommonFile/';
        let imageUrl = '0';
        if (image) {
            imageUrl = image.fileStoreId;
        }
        return this.getImageUrl(imageUrl);
    }

    checkSupplierResourceImage(resource) {
        if (resource && resource.resourceImages) {
            return Object.keys(resource.resourceImages).length > 0;
        }
        return;
    }

    getSupplierResourceImage(image) {
        // let url = 'api/v1/file/getCommonFile/';
        let imageUrl = '0';
        if (image) {
            imageUrl = image;
        }
        return this.getImageUrl(imageUrl);
    }

    updateSelectedImage(selectedImageUrl) {
        this.selectedImageUrl = selectedImageUrl;
    }

    calcDescLength(desc) {
        if (typeof desc == 'undefined') {
            return false;
        }
        return desc.length > 1000;
    }

    getAllMatchingResources(nameCode) {
        return this.allResources[nameCode];
    }

    getImageUrl(fileId) {
        const { CommonFile } = this._injected;
        return CommonFile.getCommonFile(fileId);
    }

    /**
     * It sorts the array keeping pinned Image at first place and others afterward and returns the sorted array.
     */
    getSortedResourceImages(resource) {
        let pinnedImageId = resource.pinnedImageId;
        let resourceImages = resource.resourceImages;
        let matchingImages = [];
        let remainingImages = [];
        Object.keys(resourceImages).forEach(function (imageId) {
            let image = resourceImages[imageId];
            if (image == pinnedImageId) {
                matchingImages.push(image);
            } else {
                remainingImages.push(image);
            }
        });
        return matchingImages.concat(remainingImages);
    }
}

SupplierPresentationController.$inject = [
    '$rootScope',
    '$scope',
    '$element',
    '$location',
    '$timeout',
    '$translate',
    '$sce',
    'FieldChangedSvc',
    'EventEnum',
    'AuthenticationService',
    'SupplierDetailInfo',
    'Event',
    'CustomerUser',
    'UserGroup',
    'Presentation',
    'SupplierDetailService',
    'SupplierResources',
    'FilledSupplierResourceField',
    'DynamicFormUtilService',
    'Translation',
    'CommonFile',
];
export default SupplierPresentationController;
