import addEditOfferPartModal from '../../../../../modals/modals2.0/add-edit-offer-part';
import addEditAccommodationModal from '../../../../../modals/modals2.0/add-edit-accommodation';
import scheduleChangeAlertModal from '../../../../../modals/modals2.0/schedule-route-change-alert';
import EventDetailsData from '../../../../../../core/existingServices/data_store/EventDetailsData';
import {ScopeFunctions} from "../../../../../../utils/global/scopeFunctions";
import {OgModal} from "../../../../../../utils/global/ogModal";

(function () {
    'use strict';
    angular.module('calendar').component('calendar', {
        templateUrl: './app/components/modules/event/event_detail/overview/calendar/calendar.view.html',
        controllerAs: 'calendarCtrl',
        require: {
            parentCtrl: '^eventDetail'
        },
        controller: [
            '$rootScope',
            '$scope',
            '$stateParams',
            'ModalService',
            'EventSchedule',
            'EventEnum',
            '$timeout',
            'ModalName',
            'ModalStatusStore',
            '$state',
            'Notification',
            'AuthenticationService',
            '$translate',
            function CalenderController(
                $rootScope,
                $scope,
                $stateParams,
                ModalService,
                EventSchedule,
                EventEnum,
                $timeout,
                ModalName,
                ModalStatusStore,
                $state,
                Notification,
                AuthenticationService,
                $translate
            ) {
                var vm = this;
                vm.viewModal = false;
                this.$onInit = function () {
                    vm.eventDetailCtrl = this.parentCtrl;
                };

                vm.calendarDataLoaded = false;
                vm.calendarLoadError = '';
                vm.openModalOnEventClick = openModalOnEventClickFn;
                vm.openModalforNewEvent = openModalforNewEventFn;
                vm.removeOfferPart = removeOfferPartFn;
                vm.updateDateTimeForRequestOfferPart = updateDateTimeForRequestOfferPartFn;
                vm.addContentSidePanelActivated = false;
                vm.isOfferPreviewModalOpen = isOfferPreviewModalOpenFn;
                vm.updateAccommodation = updateAccommodationFn;
                vm.addDayBeforeEvent = addDayBeforeEventFn;
                vm.addDayAfterEvent = addDayAfterEventFn;
                vm.deleteDayBeforeEvent = deleteDayBeforeEventFn;
                vm.deleteDayAfterEvent = deleteDayAfterEventFn;
                //TODO(SC) could not find it's usage
                //vm.viewRequest = viewRequestFn;

                vm.collapseLimit = 2;
                vm.eventId = $stateParams.eventId;

                ScopeFunctions.addToScope($scope);

                function handleError(error) {
                    vm.calendarDataLoaded = false;
                    vm.calendarLoadError = error;
                    $scope.endWithAlert(error);
                }

                //Stops the quick display of side panel while loading
                //https://app.asana.com/0/14368931590528/256899026370930
                $timeout(function () {
                    vm.addContentSidePanelActivated = true;
                });

                function init() {
                    $scope.startProgress('calendar');
                    EventSchedule.getEventSchedule(vm.eventId, renderCalendarDontUpdateEventDetails, handleError);
                }

                function openEventClickModalFn(params, timeless) {
                    if(vm.viewModal) {
                        return;
                    }
                    vm.viewModal = true;
                    let data = {
                        event: params,
                        createNew: false,
                        timeless: timeless && typeof timeless === 'boolean',
                        okCallBack: function (resp) {
                            $scope.startProgress();
                            renderCalendarAndUpdateEventDetails(resp);
                        },
                        deleteCallBack: function (resp) {
                            $scope.startProgress();
                            renderCalendarAndUpdateEventDetails(resp);
                        },
                    };

                    let addEditOfferPartModalData = addEditOfferPartModal;
                    addEditOfferPartModalData.inputs = { data };

                    ModalService.showModal(addEditOfferPartModalData).then((modal) => {
                        vm.viewModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }

                function openModalforNewEventFn(params, timeless) {
                    if(vm.viewModal) {
                        return;
                    }
                    vm.viewModal = true;
                    let data = {
                        event: {
                            id: vm.eventId,
                            start: params.start,
                            end: params.end,
                            //while adding part from side bar need this fields
                            requestId: params.id,
                            //While opening from add accommodations
                            category: params.category,
                            requestName: params.name,
                            requestIconId: params.iconId,
                            name: params.partName,
                            editType: { name: 'NOTIFY_BEFORE_EDIT' },
                        },
                        timeless: timeless,
                        createNew: true,
                        okCallBack: function (resp) {
                            $scope.startProgress();
                            renderCalendarAndUpdateEventDetails(resp);
                        },
                    };

                    let addEditOfferPartModalData = addEditOfferPartModal;
                    addEditOfferPartModalData.inputs = { data };

                    ModalService.showModal(addEditOfferPartModalData).then((modal) => {
                        vm.viewModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }

                function openModalOnEventClickFn(data, timeless) {
                    openEventClickModalFn(data, timeless);
                }

                function renderCalendarAndUpdateEventDetails(response) {
                    vm.calendarDataLoaded = true;
                    vm.eventSchedule = response;
                    vm.calendarLoadError = '';
                    updateEventDetails();
                    $scope.endProgress();
                }

                function renderCalendarDontUpdateEventDetails(response) {
                    vm.calendarDataLoaded = true;
                    vm.eventSchedule = response;
                    vm.calendarLoadError = '';
                    $scope.endProgress('calendar');
                }

                function removeOfferPartFn(referenceId) {
                    OgModal.open('confirmation', {
                        message: 'request.delete.part.confirm',
                        okCallBack: function () {
                            $scope.startProgress();
                            EventSchedule.deleteOfferPart(referenceId, renderCalendarAndUpdateEventDetails, handleError);
                        },
                    });
                }

                function updateDateTimeForRequestOfferPartFn(referenceId, start, end) {
                    let eventFromDate = EventDetailsData.getEventDetails().fromDate;
                    var metaData = {
                        dateTimeTo: new Date(end).getTime() - eventFromDate,
                        dateTimeFrom: new Date(start).getTime() - eventFromDate,
                        eventFromDate: eventFromDate, //base Date
                    };
                    EventSchedule.updateDateTimeForOfferPart(
                        referenceId,
                        metaData,
                        (resp) => {
                            $rootScope.$emit('event:user:action', { action: 'updated.request.offer.part' });
                            renderCalendarAndUpdateEventDetails(resp);
                        },
                        handleError
                    );
                }

                function isOfferPreviewModalOpenFn() {
                    return ModalStatusStore.isModalOpen(ModalName.OFFER_PREVIEW_MODAL);
                }

                /* Change View */

                function updateEventDetails() {
                    $scope.$emit(EventEnum.EVENT_DETAIL_PAGE_RELOAD);
                }

                function updateAccommodationFn(
                    requestId,
                    accommodationBeforeEvent = false,
                    accommodationAfterEvent = false,
                    accommodationDay,
                    hasBufferDaysAfterEvent = false,
                    dayBeforeDayAfterEventHasAccommodation = false
                ) {
                    if(vm.viewModal) {
                        return;
                    }
                    vm.viewModal = true;
                    let data = {
                        eventId: vm.eventId,
                        requestId: requestId,
                        accommodationDay: accommodationDay,
                        accommodationBeforeEvent: accommodationBeforeEvent,
                        accommodationAfterEvent: accommodationAfterEvent,
                        hasBufferDaysAfterEvent: hasBufferDaysAfterEvent,
                        dayBeforeDayAfterEventHasAccommodation: dayBeforeDayAfterEventHasAccommodation,

                        successCallBack: function (res) {
                            init();
                            renderCalendarAndUpdateEventDetails(res);
                        },
                    };

                    let addEditAccommodationModalData = addEditAccommodationModal;
                    addEditAccommodationModalData.inputs = { data };

                    ModalService.showModal(addEditAccommodationModalData).then((modal) => {
                        vm.viewModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }

                function addDayBeforeEventFn() {
                    $scope.startProgress('calendar');
                    EventSchedule.addDayBeforeEventStartDate(vm.eventId, renderCalendarAndUpdateEventDetails, handleError);
                }

                function addDayAfterEventFn() {
                    $scope.startProgress('calendar');
                    EventSchedule.addDayAfterEventEndDate(vm.eventId, renderCalendarAndUpdateEventDetails, handleError);
                }

                function deleteDayBeforeEventFn() {
                    $scope.startProgress('calendar');
                    EventSchedule.deleteDayBeforeEventStartDate(vm.eventId, renderCalendarAndUpdateEventDetails, handleError);
                }

                function deleteDayAfterEventFn() {
                    $scope.startProgress('calendar');
                    EventSchedule.deleteDayAfterEventEndDate(vm.eventId, renderCalendarAndUpdateEventDetails, handleError);
                }

                $scope.$on('event:update:event', function () {
                    init();
                });

                let eventRequestReload = $rootScope.$on(EventEnum.EVENT_AND_REQUESTS_RELOAD, function () {
                    init();
                });

                let requestOfferStatusChangedRefresh = $rootScope.$on(
                    EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS,
                    function () {
                        init();
                    }
                );

                let supplierRemovedFromPanelRefresh = $rootScope.$on(EventEnum.SUPPLIER_REMOVED_FROM_PANEL, function () {
                    init();
                });

                init();

                let $locationChangeStartUnbind = $scope.$on(
                    '$stateChangeStart',
                    function (event, toState, toParams, fromState, fromParams) {
                        let _isUserLoggedIn = AuthenticationService.isLogged();
                        if (toState !== fromState && !vm.confirmLeave && _isUserLoggedIn) {
                            let _toState = toState.name;
                            event.preventDefault();
                            $rootScope.$broadcast('cancelStateChange');
                            Notification.listUnsentNotifications(
                                vm.eventId,
                                function (data) {
                                    let _notifications = data;
                                    if (_notifications.length > 0) {
                                        let data = {
                                            notifications: _notifications,
                                            toStateName: toState.name,
                                            ok: function () {
                                                vm.confirmLeave = true;
                                            },
                                            cancel: function () {
                                                $rootScope.$broadcast('keepScheduleFormActive');
                                            },
                                        };
                                        let scheduleChangeAlertModalData = scheduleChangeAlertModal;
                                        scheduleChangeAlertModalData.inputs = { data };
                                        ModalService.showModal(scheduleChangeAlertModalData).then((modal) => {
                                            modal.element.modal();
                                            modal.close.then(() => {
                                                console.log('modal is closed!');
                                            });
                                        });
                                    } else {
                                        vm.confirmLeave = true;
                                        $state.go(_toState, {
                                            highlightElement: toParams.highlightElement,
                                            eventId: toParams.eventId,
                                        });
                                    }
                                },
                                function (err) {
                                    console.log(err); // TODO handle error!
                                    //403
                                    vm.confirmLeave = true;
                                    $state.go(_toState);
                                }
                            );
                        }
                    }
                );

                $scope.$on('$destroy', requestOfferStatusChangedRefresh);
                $scope.$on('$destroy', supplierRemovedFromPanelRefresh);
                $scope.$on('$destroy', $locationChangeStartUnbind);
                $scope.$on('$destroy', eventRequestReload);
            }
        ]
    });
})();
