import {OgModal} from "./ogModal";
import {ScopeFunctions} from './scopeFunctions';

export default function (appModule) {
    appModule.run([
        '$log',
        '$rootScope',
        '$translate',
        '$timeout',
        '$location',
        'ValidationService',
        function ($log, $rootScope, $translate, $timeout, $location, ValidationService) {
            OgModal.prototype.commonController = function ($scope) {
                var self = this;

                $scope.initDialog = function (data) {
                };

                $scope.refreshDialog = function () {
                };

                $scope.dialogOpened = function () {
                };

                $scope._clearDialog = function () {
                    if ($scope.data) {
                        $scope.data = null;
                    }
                    if ($scope.originalData) {
                        $scope.originalData = null;
                    }
                    if ($scope.form) {
                        ValidationService.clear($scope.form);
                    }
                    $scope.inProgress = false;
                    $scope.clearDialog();
                };

                $scope.clearDialog = function () {
                };

                $rootScope.$on('$routeChangeStart', function (event, next, current) {
                    if ($scope._isOpen) {
                        var openDialog = OgModal.stack[OgModal.stack.length - 1];
                        OgModal.stack = [openDialog];
                        if (self.dialogName == openDialog) {
                            $scope.close();
                        } else {
                            $scope._clearDialog();
                        }
                    }
                });

                $scope.close = function () {
                    if (self.dialogType == 'custom') {
                        self._closeDialog();
                        self.automaticClosing = false;
                        $scope._closed();
                        jQuery('body').removeClass('modal-open');
                        jQuery('.modal-backdrop').remove();
                    } else {
                        jQuery(self.dialogSelector).modal('hide');
                    }
                };

                $scope._closed = function () {
                    if (!self.automaticClosing) {
                        OgModal.closed(self.dialogName);
                        $scope._clearDialog();
                    }
                    $scope._isOpen = false;
                };

                jQuery(self.dialogSelector).bind('hide.bs.modal', $scope._closed);

                self._openDialog = function (data) {
                    self.automaticClosing = false;
                    if (data) {
                        $scope.initDialog(data);
                    } else {
                        $scope.refreshDialog();
                    }
                    if (self.dialogType == 'custom') {
                        jQuery(self.dialogSelector).addClass('opened');
                        jQuery(self.dialogSelector).modal('show');
                    } else {
                        jQuery(self.dialogSelector).modal('show');
                    }
                    OgModal.opened(self.dialogName);
                    $scope.dialogOpened();
                    $scope._isOpen = true;
                };

                self._closeDialog = function () {
                    self.automaticClosing = true;
                    if (self.dialogType == 'custom') {
                        jQuery(self.topSelector).removeClass('opened');
                        jQuery(self.dialogSelector).modal('hide');
                    } else {
                        jQuery(self.dialogSelector).modal('hide');
                    }
                };

                OgModal.register(this);

                $scope.$on('dialog:' + self.dialogName + ':open', function (guiEvent, data) {
                    OgModal.open(self.dialogName, data);
                });
                ScopeFunctions.addToScope($scope);
            };

            OgModal.stack = [];
            OgModal.open = $rootScope.dialog = function (name, params) {
                if (OgModal.stack.length > 0) {
                    var openDialog = OgModal.stack[OgModal.stack.length - 1];
                    dialogs[openDialog]._closeDialog();
                }
                $timeout(function () {
                    var dialog = dialogs[name];
                    if (dialog) {
                        if (params == null) {
                            params = {};
                        }
                        dialog._openDialog(params);
                    } else {
                        //TODO(JK) delete fallback for old dialogs
                        $rootScope.$broadcast('dialog:' + name + ':open', params);
                        //$log.error('Unknown dialog ' + name);
                    }
                }, 200);
            };

            OgModal.opened = function (name) {
                if (OgModal.stack.length === 0 || OgModal.stack[OgModal.stack.length - 1] !== name) {
                    OgModal.stack.push(name);
                }
            };

            OgModal.closed = function (name) {
                if (OgModal.stack.length > 0) {
                    var openDialog = OgModal.stack[OgModal.stack.length - 1];
                    if (openDialog === name) {
                        OgModal.stack.pop();
                    }
                    if (OgModal.stack.length > 0) {
                        $timeout(function () {
                            var previous = OgModal.stack[OgModal.stack.length - 1];
                            dialogs[previous]._openDialog();
                        }, 200);
                    }
                }
            };

            var dialogs = {};

            OgModal.register = function (dialog) {
                dialogs[dialog.dialogName] = dialog;
            };
        }
    ])
}