import commonFunction from "../../../utils/CommonFunctionUtil";
(function () {
    'use strict';

    angular.module('ogFormError').directive('ogFormError', [
        function () {
            return {
                restrict: 'EA',
                template: `<div class="row po-r" ng-show="show">
                  <div class="col-md-12">
                    <div style="position: absolute; left: -10px; margin-left: 100%; top: 100%;
                      transform: translate(-100%, -120%); color: rgba(256,256,256,.7); z-index:9"
                      og-error-id="error">
                    </div>
                    <div ng-class="labelClass" class="alert-dismissible">
                      <a ng-click="show = false;" class="close-alert fa fa-times f-16" 
                         data-dismiss="alert" aria-label="close"></a>
                      <div ng-repeat="message in messages" translate="{{message.messageKey}}"
                        values="{{message.params}}">
                      </div>
                    </div>
                  </div>
                 </div>`,
                scope: true,
                replace: true,
                link: function ($scope, element, attrs) {
                    var formName = attrs.ogFormError;

                    $scope.$on('validation:form:' + formName + ':clear', function () {
                        $scope.show = false;
                        // element.hide();// in case of dialog
                        $scope.message = null;
                    });

                    var showMessage = function (err) {
                        $scope.error = err;
                        if (err.messageKey) {
                            $scope.messages = [err];
                        } else {
                            $scope.messages = commonFunction.errorMessages(err);
                        }
                        $scope.show = true;
                    };

                    $scope.$on('validation:form:error:' + formName, function (event, err) {
                        $scope.labelClass = 'alert alert-danger';
                        showMessage(err);
                    });

                    $scope.$on('validation:form:success:' + formName, function (event, statusMessage) {
                        $scope.labelClass = 'alert alert-success';
                        showMessage({ messageKey: statusMessage });
                    });

                    $scope.$on('validation:form:info:' + formName, function (event, statusMessage) {
                        $scope.labelClass = 'alert alert-info';
                        showMessage({ messageKey: statusMessage });
                    });
                },
            };
        },
    ]);
})();
