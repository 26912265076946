import eventsRoute from './events/route';
import eventDetailsRoute from './event_detail/route'

import '../../../utils/global/common_function/common.function.module'
import '../../../utils/global/common_function/common.function.service'

export default function (appModule) {
    appModule.config([
        '$routeProvider',
        '$locationProvider',
        '$stateProvider',
        '$urlRouterProvider',
        function ($routeProvider, $locationProvider, $stateProvider, $urlRouterProvider) {
            $urlRouterProvider.when('/event/:eventId/request/:requestId/offer/:offerId', [
                '$match',
                '$location',
                function ($match, $location) {
                    var path =
                        '/event/' +
                        $match.eventId +
                        '/overview?requestId=' +
                        $match.requestId +
                        '&offerId=' +
                        $match.offerId;
                    $location.url(path);
                },
            ]);

            $urlRouterProvider.when('/event/:eventId/guide/request/:requestId/:originalAdd', [
                '$match',
                '$location',
                function ($match, $location) {
                    var path =
                        '/event/' +
                        $match.eventId +
                        '/guide?requestId=' +
                        $match.requestId +
                        '&originalAdd=' +
                        $match.originalAdd;
                    $location.url(path);
                },
            ]);

            $urlRouterProvider.when('/event/:eventId/guide/request/:requestId?:showAddSupplier', [
                '$match',
                '$location',
                'EventGuideDataStore',
                function ($match, $location, EventGuideDataStore) {
                    EventGuideDataStore.setShowAddSupplierModal($match.showAddSupplier);
                    var path = '/event/' + $match.eventId + '/overview?requestId=' + $match.requestId;
                    $location.url(path);
                },
            ]);

            $urlRouterProvider.when('/events/overview', [
                '$match',
                '$state',
                '$location',
                'AuthenticationService',
                function ($match, $state, $location, AuthenticationService) {
                    AuthenticationService.validate().then(() => {
                        let userGroup = AuthenticationService.getUserData().userGroup;
                        if (userGroup && userGroup.childUserGroupCount > 0) {
                            $state.go('overview');
                        } else {
                            $location.url('/events/list');
                        }
                    });
                },
            ]);

            $urlRouterProvider.when('/events', [
                '$match',
                '$location',
                'AuthenticationService',
                function ($match, $location, AuthenticationService) {
                    AuthenticationService.validate().then(() => {
                        let userGroup = AuthenticationService.getUserData().userGroup;
                        var path;
                        if (userGroup && userGroup.childUserGroupCount > 0) {
                            path = '/events/overview';
                        } else {
                            path = '/events/list';
                        }
                        $location.url(path);
                    });
                },
            ]);

            $stateProvider
                .state('events', {
                    url: '/events',
                    template: '<Events></Events>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('overview', {
                    url: '/events/overview',
                    template: '<eventsManagersView></eventsManagersView>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('event', {
                    url: '/event',
                    abstract: true, // has children view
                    template: '<ui-view>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('event.guide', {
                    url: '/:eventId/guide?requestId&originalAdd',
                    template: 'app/event/event_guide/event_guide.view.html',
                    controller: 'AddContentGuideController',
                    controllerAs: 'addContentGuideCtrl',
                    reloadOnSearch: false,
                })
                .state('event.detail', {
                    url: '/:eventId?requestId&offerId&showAddSupplier',
                    template: '<event-detail resolved-event-details="$resolve.resolvedEventDetails"></event-detail>',
                    abstract: true, // has children view
                    reloadOnSearch: false,
                    resolve: {
                        resolvedEventDetails: [
                            '$stateParams',
                            '$state',
                            'Page',
                            '$q',
                            'CommonFunctions',
                            function ($stateParams, $state, Page, $q, CommonFunctions) {
                                var eventId = $stateParams.eventId;
                                var requestId = $stateParams.requestId;
                                var deferred = $q.defer();
                                Page.eventDetailPage(
                                    {eventId: eventId, requestId: requestId},
                                    function (response) {
                                        if (response) {
                                            deferred.resolve(response);
                                        }
                                    },
                                    function (err) {
                                        if (err.status === 401) {
                                            deferred.reject('NOT_LOGGED_IN');
                                        } else if (err.status === 403) {
                                            deferred.reject('ACCESS_DENIED');
                                        } else {
                                            let callBack = function () {
                                                $state.go('events');
                                            };
                                            let settings = { response: err, okCallBack: callBack };
                                            CommonFunctions.alertWithCallBack(settings);
                                        }
                                    }
                                );
                                return deferred.promise;
                            },
                        ],
                    },
                })
                .state('event.summary', {
                    url: '/:eventId/summary',
                    template: '<eventSummary></eventSummary>'
                })
                .state('createEvent', {
                    url: '/createEvent',
                    template: '<eventcreate></eventcreate>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                });
        },
    ]);
    eventsRoute(appModule);
    eventDetailsRoute(appModule);
}
