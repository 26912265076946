import UserGroupData from '../../../../core/existingServices/data_store/UserGroupData';
import EventDetailsData from '../../../../core/existingServices/data_store/EventDetailsData';
import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';

angular.
    module('event.summary').
    component('eventsummary', {
        templateUrl: './app/components/modules/event/event_summary/eventSummary.template.html',
        controllerAs: 'eventSummaryCtrl',
        controller: [
            '$scope', '$stateParams', 'EventSummary',
            function EventSummaryController(
                $scope, $stateParams, EventSummary
            ) {

                var vm = this;
                vm.init = initFn;

                var eventId = $stateParams.eventId;

                ScopeFunctions.addToScope($scope);

                vm.init();

                function initFn() {
                    $scope.startProgress({ showContent: false });
                    EventSummary.eventSummaryForCreatorRest(
                        { eventId: eventId },
                        function (response) {
                            vm.event = response.event;
                            UserGroupData.setUserGroupData(vm.event.ownerGroup);
                            EventDetailsData.setEventDetails(vm.event);
                            vm.eventSchedule = response.eventSchedule;
                            vm.supplierLocations = response.supplierLocations;
                            vm.sharedLists = response.sharedLists;
                            vm.sharedPublicly = response.sharedPublicly;
                            vm.currentDecisionMaker = response.currentDecisionMaker;
                            $scope.endProgress();
                        },
                        $scope.endWithAlert
                    );
                }
            }
        ]
    });
