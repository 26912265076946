import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    /* global angular */
    /*This is used in creator's offer preview message section*/
    'use strict';

    angular.module('ogEventSummaryMessage').directive('ogEventSummaryMessage', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/event_summary_message/og.event.summary.message.view.html',
            replace: true,
            scope: {
                isEditable: '=ogEditable',
                event: '=',
                eventCreator: '=',
                // tell the position for the file attachment.
                fileSelectToolTipPlacement: '=',
                showMessage: '=',
            },
            controllerAs: 'eventSummaryMessageCtrl',
            bindToController: true,
            controller: [
                '$scope',
                '$log',
                '$rootScope',
                '$stateParams',
                '$state',
                '$location',
                'Activity',
                'Task',
                'AuthenticationService',
                '$timeout',
                'EventEnum',
                'Request',
                'Event',
                'RequestOffer',
                'ChatMessage',
                'ChatMessageCountStore',
                'ogScrollService',
                'ClientService',
                'CommonFunctions',
                function (
                    $scope,
                    $log,
                    $rootScope,
                    $stateParams,
                    $state,
                    $location,
                    Activity,
                    Task,
                    AuthenticationService,
                    $timeout,
                    EventEnum,
                    Request,
                    Event,
                    RequestOffer,
                    ChatMessage,
                    ChatMessageCountStore,
                    ogScrollService,
                    ClientService,
                    CommonFunctions
                ) {

                    var vm = this;

                    this.$onInit = function () {
                        vm = this;
                        vm.backToMessageList = backToMessageListFn;
                        vm.clearAttachedFiles = clearAttachedFilesFn;
                        vm.createMessage = createMessageFn;
                        vm.errorCallback = errorCallbackFn;
                        vm.filterChatMessages = filterChatMessagesFn;
                        vm.getActivityStream = getActivityStreamFn;
                        vm.getPaginatedActivities = getPaginatedActivitiesFn;
                        vm.hideComposeMessage = hideComposeMessageFn;
                        vm.hideDefaultMessage = hideDefaultMessageFn;
                        vm.hideMessageDetails = hideMessageDetailsFn;
                        vm.init = initFn;
                        vm.paginatedActivityStreamCallback = paginatedActivityStreamCallbackFn;
                        vm.resetActivitiesList = resetActivitiesListFn;
                        vm.resetDefaultMessage = resetDefaultMessageFn;
                        vm.resetReplySuccessMessage = resetReplySuccessMessageFn;
                        vm.resetSuccessMessage = resetSuccessMessageFn;
                        vm.sendMessageCallback = sendMessageCallbackFn;
                        vm.showAllMessage = showAllMessageFn;
                        vm.showComposeMessage = showComposeMessageFn;
                        vm.showDefaultMessage = showDefaultMessageFn;
                        vm.showLessMessage = showLessMessageFn;
                        vm.showMessageDetails = showMessageDetailsFn;
                        vm.stripHtmlTags = stripHtmlTagsFn;
                        vm.translateTextByCode = translateTextByCodeFn;
                        vm.isOfferInCanceledOrDeclinedState = isOfferInCanceledOrDeclinedStateFn;
                        vm.cancelMessage = cancelMessageFn;
                        vm.cancelReplyMessage = cancelReplyMessageFn;
                        vm.showComposeMessageSection = false;
                        vm.showActivityMessageDetails = false;
                        vm.displayReplySection = false;
                        vm.noFilter = true;
                        vm.currentPage = 1;
                        vm.eventId = vm.event.id;
                        vm.messageForm = {};
                        vm.numPerPage = 2;
                        vm.showMyTasks = false;
                        vm.eventOwners = [];
                        vm.defaultMessage = false;
                        vm.isReplyMessage = false;
                        vm.privateMessage = false;
                        vm.replyToOfferId = null;
                        vm.maxMsgLength = 4000;
                        vm.showMsgLimitError = false;

                        ScopeFunctions.addToScope($scope);

                        vm.activityFilterArray = [
                            { id: 0, tag: 'all' },
                            { id: 1, tag: 'supplier' },
                            { id: 2, tag: 'task' },
                            { id: 3, tag: 'message' },
                            { id: 4, tag: 'messageWithFiles' },
                        ];

                        vm.showTaskFilterArray = [
                            { id: 0, tag: 'showMineTask' },
                            { id: 1, tag: 'showAllTask' },
                        ];

                        vm.activityFilter = vm.activityFilterArray[3].tag;
                        vm.activeFilter = vm.activityFilterArray[3].tag;
                        vm.showTaskFilter = vm.showTaskFilterArray[1].tag;

                        vm.init();
                    }

                    function showComposeMessageFn() {
                        vm.showComposeMessageSection = true;
                    }

                    function showAllMessageFn() {
                        if (vm.loading) {
                            return;
                        }
                        vm.numPerPage = vm.totalActivitiesCount;
                        vm.params.offset = 0;
                        getPaginatedActivitiesFn();
                    }

                    function showLessMessageFn() {
                        if (vm.loading) {
                            return;
                        }
                        vm.numPerPage = 2;
                        vm.params.offset = 0;
                        getPaginatedActivitiesFn();
                    }

                    function hideComposeMessageFn() {
                        vm.showComposeMessageSection = false;
                        resetSuccessMessageFn();
                        resetReplySuccessMessageFn();
                    }

                    function backToMessageListFn() {
                        hideMessageDetailsFn();
                        vm.getPaginatedActivities();
                    }

                    function createMessageFn(msg, messageId) {
                        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                            return;
                        }
                        if (!msg && !vm.isReplyMessage && !vm.messageForm.newMessageForm.$valid) {
                            CommonFunctions.touchFormFields(vm.messageForm.newMessageForm);
                            return;
                        }
                        vm.level = 'EVENT_USERS';
                        vm.privateMessage = false;
                        vm.eventOwnerId = null;

                        var messageBody = vm.messageBody;
                        if (msg && msg.isReplyEnabled) {
                            if (vm.isReplyMessage && !vm.messageForm.replyMessageForm.$valid) {
                                CommonFunctions.touchFormFields(vm.messageForm.replyMessageForm);
                                return;
                            }
                            messageBody = vm.messageReplyBody;
                            vm.parentMessageId = messageId;
                            vm.privateMessage = msg.privateMessage; //if parent is private child also should be private
                            vm.replyToUserId = msg.author.id;
                            vm.isReplyMessage = true;
                        }
                        if (messageBody.length > vm.maxMsgLength) {
                            vm.showMsgLimitError = true;
                            return;
                        }
                        vm.showMsgLimitError = false;
                        if (tinyMCE && false) {
                            messageBody = tinyMCE.get('messageBody').getContent();
                        }
                        let plainMessage = htmlToPlaintext(messageBody);
                        if (plainMessage && plainMessage.length > 0 && plainMessage.charCodeAt(0) !== 8203) {
                            sendMessageFn(messageBody);
                        }
                        // vm.clearAttachedFiles();
                    }

                    function htmlToPlaintext(text) {
                        let plainText = text ? String(text).replace(/<[^>]+>/gm, '') : '';
                        return plainText.trim();
                    }

                    function errorCallbackFn(error) {
                        vm.error = error;
                        vm.streamSpinner = false;
                        vm.messageSpinner = false;
                    }

                    function filterChatMessagesFn(message) {
                        return vm.activityFilter == 'message'
                            ? vm.getMessageTypeFn(message) == 'CHATMESSAGE'
                                ? true
                                : false
                            : true;
                    }

                    function getActivityStreamFn() {
                        vm.streamSpinner = true;
                    }

                    function isOfferInCanceledOrDeclinedStateFn() {
                        /*if(vm.offer) {
                            return vm.offer.status.name === 'DECLINED' || vm.offer.status.name === 'CANCELED';
                        }
                        return false;*/
                    }

                    function getPaginatedActivitiesFn() {
                        var params = {
                            eventId: vm.eventId,
                            page: vm.params.offset,
                            pageSize: vm.numPerPage,
                            eventCreator: vm.eventCreator,
                        };
                        $scope.startProgress();
                        vm.loading = true;
                        Activity.listEventSummaryMessage(params, vm.paginatedActivityStreamCallback, vm.errorCallback);
                    }

                    function translateTextByCodeFn(text, code) {
                        return CommonFunctions.getTranslatedTextFromCode(text, code);
                    }

                    function initFn() {
                        vm.clearAttachedFiles();
                        vm.messageSpinner = false;
                        vm.messageBodyEmpty = false;
                        vm.resetActivitiesList();
                        vm.getPaginatedActivities();
                        vm.clearAll = false; //clear file upload model
                        vm.clientName = ClientService.getClientName();
                        updateLoggedUserStatus();
                    }

                    function paginatedActivityStreamCallbackFn(response) {
                        $scope.endProgress();
                        vm.activities = response.list;
                        vm.streamSpinner = false;
                        vm.messageSpinner = false;
                        vm.loading = false;
                        vm.totalActivitiesCount = response.totalCount;
                        if (vm.showActivityMessageId) {
                            setCreatorChatMessages(vm.showActivityMessageId);
                        }
                    }

                    function resetActivitiesListFn() {
                        vm.params = { offset: 0, max: 2 };
                        vm.loading = false;
                        vm.activities = [];
                    }

                    function sendMessageFn(messageBody) {
                        if (messageBody && messageBody.length > 0) {
                            var compiledMessageBody = messageBody;
                            if (vm.attachedFiles.maxSizeExceeded === true) {
                                return;
                            }
                            if (vm.attachedFiles.links !== null && vm.attachedFiles.links.length > 0) {
                                compiledMessageBody += '\n\n';
                                angular.forEach(vm.attachedFiles.links, function (link) {
                                    compiledMessageBody +=
                                        '<a href="' +
                                        link.fileUrl +
                                        '" target="_blank"><i class="glyphicon glyphicon-paperclip"></i> ' +
                                        link.name +
                                        '</a>' +
                                        '\n';
                                });
                            }
                            vm.messageSpinner = true;

                            Activity.saveEventSummaryMessage(
                                {
                                    eventId: vm.eventId,
                                    messageBody: compiledMessageBody,
                                    isReplyMessage: vm.isReplyMessage,
                                    parentMessageId: vm.parentMessageId,
                                    eventOwnerId: !!vm.replyToUserId ? vm.replyToUserId : vm.eventOwnerId,
                                    eventCreator: vm.eventCreator,
                                    attachedFiles: vm.attachedFiles.uploads,
                                },
                                vm.sendMessageCallback,
                                vm.errorCallback
                            );
                        }
                    }

                    function sendMessageCallbackFn() {
                        vm.messageSpinner = false;
                        if (vm.isReplyMessage) {
                            $scope.replySuccessMessage = true;
                            $scope.successMessage = false;
                            vm.displayReplySection = false;
                        } else {
                            $scope.replySuccessMessage = false;
                            $scope.successMessage = true;
                            vm.showComposeMessageSection = false;
                        }
                        vm.isReplyMessage = false;
                        vm.parentMessageId = null;
                        vm.privateMessage = false;
                        resetDefaultMessageFn();
                        vm.messageBody = '';
                        vm.messageReplyBody = '';
                        if (tinyMCE && false) {
                            var editor = tinyMCE.get('messageBody');
                            if (editor) {
                                editor.setContent('');
                            }
                        }
                        vm.clearAttachedFiles();
                        vm.resetActivitiesList();

                        vm.getPaginatedActivities();
                        vm.clearAll = true;
                        if (vm.messageForm.newMessageForm) {
                            vm.messageForm.newMessageForm.$setPristine();
                            vm.messageForm.newMessageForm.$setUntouched();
                        } else if (vm.messageForm.replyMessageForm) {
                            vm.messageForm.replyMessageForm.$setPristine();
                            vm.messageForm.replyMessageForm.$setUntouched();
                        }
                    }

                    function showDefaultMessageFn() {
                        Event.showDefaultMessage(
                            vm.eventId,
                            function (data) {
                                vm.defaultMessage = data.showDefaultMessage;
                            },
                            errorCallbackFn()
                        );
                    }

                    function hideDefaultMessageFn() {
                        if (vm.defaultMessage) {
                            Event.hideDefaultMessage(
                                vm.eventId,
                                function (data) {
                                    vm.defaultMessage = data.showDefaultMessage;
                                },
                                errorCallbackFn()
                            );
                        }
                    }

                    function showMessageDetailsFn(activityMessage) {
                        setCreatorChatMessages(activityMessage.id);
                        vm.showActivityMessageDetails = true;
                        vm.showActivityMessageId = activityMessage.id;
                        vm.isReplyMessage = true;
                        $scope.successMessage = false;
                        if (activityMessage.offer) {
                            vm.replyToOfferId = activityMessage.offer.id;
                        } else {
                            vm.replyToOfferId = null;
                        }
                        updateMessageSeenStatusFn(activityMessage);
                        /*                        if (vm.showActivityMessageDetails && vm.showActivityMessageId == activityMessage.id) {
                                                    if (vm.creatorChatMessageDetails.childMessages.length > 0) {
                                                        $timeout(function () {
                                                            ogScrollService.scrollToElement('scroll-here');
                                                        }, 500);
                                                    } else {
                                                        $timeout(function () {
                                                            ogScrollService.scrollToElement('reply-section-header');
                                                        }, 500);
                                                    }
                                                }*/
                    }

                    function setCreatorChatMessages(messageId) {
                        vm.creatorChatMessageDetails = _.find(vm.activities, (o) => {
                            return o.id === messageId;
                        });
                        $rootScope.$broadcast(EventEnum.MESSAGE_THREAD_CHANGED, vm.creatorChatMessageDetails.author);
                    }

                    function hideMessageDetailsFn() {
                        vm.showActivityMessageDetails = false;
                        vm.showActivityMessageId = null;
                        vm.replyToUserId = null;
                        vm.isReplyMessage = false;
                        vm.replyToOfferId = null;
                        vm.displayReplySection = false;
                    }

                    function updateMessageSeenStatusFn(activityMessage) {
                        if (activityMessage.isThreadHasNewMessage) {
                            $scope.startProgress();
                            Activity.markAsRead(
                                activityMessage,
                                (resp) => {
                                    ChatMessage.unReadMessageCountForCreatorRest(
                                        vm.eventId,
                                        function (resp) {
                                            ChatMessageCountStore.setCreatorUnreadMessageCount(resp.count);
                                            ChatMessageCountStore.setCreatorHomePageUnreadMessageCount(
                                                resp.homePageCount
                                            );
                                        },
                                        $scope.endWithAlert()
                                    );
                                    $scope.endProgress();
                                },
                                $scope.endWithAlert()
                            );
                        }
                    }

                    function resetDefaultMessageFn() {
                        vm.defaultMessage = false;
                    }

                    function resetSuccessMessageFn() {
                        if ($scope.successMessage) $scope.successMessage = false;

                        vm.showMsgLimitError = false;
                    }

                    function resetReplySuccessMessageFn() {
                        console.log('dis', vm.displayReplySection);
                        if ($scope.replySuccessMessage) $scope.replySuccessMessage = false;

                        vm.showMsgLimitError = false;
                    }

                    function updateLoggedUserStatus() {
                        vm.loggedUserStatus = AuthenticationService.getLoggedUserStatus();
                    }

                    function cancelMessageFn() {
                        vm.showComposeMessageSection = false;
                        vm.showMessage = false;
                        vm.messageBody = '';
                        clearAttachedFilesFn();
                    }

                    function cancelReplyMessageFn() {
                        vm.displayReplySection = false;
                        vm.messageReplyBody = '';
                        clearAttachedFilesFn();
                    }

                    function clearAttachedFilesFn() {
                        vm.attachedFiles = { uploads: null, links: null };
                    }

                    function stripHtmlTagsFn(str) {
                        if (str === null || str === '') {
                            return false;
                        } else {
                            str = str.toString();
                            var stripedhtml = str.replace(/(<([^>]+)>)/gi, '');
                            return htmlDecode(stripedhtml);
                        }
                    }

                    function htmlDecode(input) {
                        var e = document.createElement('div');
                        e.innerHTML = input;
                        return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
                    }

                    $scope.$on(EventEnum.USER_DATA_CHANGED, updateLoggedUserStatus);
                    $scope.$on(EventEnum.USER_GROUP_CHANGED_SILENT, updateLoggedUserStatus);
                    $scope.$on(EventEnum.USER_GROUP_CHANGED, updateLoggedUserStatus);
                },
            ],
        };
    });
})();
