/**
 * Created by sampurna on 7/7/19.
 */

/* global angular */
'use strict';

/**
 * This service holds the event details in a page. Should be refreshed wheneever there is change in event details
 */
class ChatMessageCountStore {
    constructor() {
        this.creatorUnreadMessageCount = 0;
        this.creatorHomePageUnreadMessageCount = 0;
        this.supplierUnreadMessageCount = 0;
        this.creatorHomePageTaskCount = 0;
    }

    setCreatorUnreadMessageCount(data) {
        this.creatorUnreadMessageCount = data;
    }

    getCreatorUnreadMessageCount() {
        return this.creatorUnreadMessageCount;
    }

    setSupplierUnreadMessageCount(data) {
        this.supplierUnreadMessageCount = data;
    }

    getSupplierUnreadMessageCount() {
        return this.supplierUnreadMessageCount;
    }

    setCreatorHomePageUnreadMessageCount(data) {
        this.creatorHomePageUnreadMessageCount = data;
    }

    getCreatorHomePageUnreadMessageCount() {
        return this.creatorHomePageUnreadMessageCount;
    }

    setCreatorHomePageTaskCount(data) {
        this.creatorHomePageTaskCount = data;
    }

    getCreatorHomePageTaskCount() {
        return this.creatorHomePageTaskCount;
    }
}

angular.module('core.existingServices').factory('ChatMessageCountStore', ChatMessageCountStore);

