import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import profileSettingsModal from "../../../modals/modals2.0/profile-settings-modal";

// Register `offers` component, along with its associated controller and template
angular.module('supplier.offers').component('offers', {
    templateUrl: './app/components/modules/supplier/offers/offers.view.html',
    controller: [
        '$scope',
        '$rootScope',
        '$http',
        'AuthenticationService',
        'Page',
        'Event',
        'RequestOffer',
        'EventEnum',
        '$stateParams',
        'ModalService',
        'ActiveTabService',
        '$timeout',
        '$translate',
        'UserGroup',
        'CommonFile',
        function SupplierHomeController(
            $scope,
            $rootScope,
            $http,
            AuthenticationService,
            Page,
            Event,
            RequestOffer,
            EventEnum,
            $stateParams,
            ModalService,
            ActiveTabService,
            $timeout,
            $translate,
            UserGroup,
            CommonFile
        ) {

            var vm = this;

            vm.countActiveRequestOffers = countActiveRequestOffersFn;
            vm.displayActivityStream = displayActivityStreamFn;
            vm.displayAllRequestOffers = displayAllRequestOffersFn;
            vm.init = initFn;
            vm.mainStatusChanged = mainStatusChangedFn;
            vm.pageChanged = pageChangedFn;
            vm.resendActivationEmail = resendActivationEmailFn;
            vm.toggleSortDirection = toggleSortDirectionFn;
            vm.openPresentationModal = openPresentationModalFn;
            vm.setActive = setActiveFn;
            vm.eventsOrderByAsc = eventsOrderByAscFn;
            vm.eventsOrderByDesc = eventsOrderByDescFn;
            vm.eventSearch = eventSearchFn;
            vm.setEventNameAndSearch = setEventNameAndSearchFn;
            vm.updateSelectedEventName = updateSelectedEventNameFn;
            vm.stripHtmlTags = stripHtmlTagsFn;
            vm.userGroupChanged = userGroupChangedFn;
            vm.openPresentation = $stateParams.showPresentation;
            ScopeFunctions.addToScope($scope);
            vm.params = { offset: 0, max: 6 };
            vm.numPerPage = 6;
            vm.currentPage = 1;
            vm.direction = 'asc';
            vm.sorting = { field: 'fromDate', direction: 'asc' };
            vm.mainStatusFilterArray = ['ACTIVE', 'FINISHED'];
            vm.searchResult = false;
            vm.viewProfileSettingsModal = false;
            vm.searchParams = {
                name: '',
                fromDate: '',
                toDate: '',
            };

            var activeSubStatusFilterArray = [
                { id: 1, title: 'UNANSWERED' },
                { id: 2, title: 'ANSWERED' },
                { id: 3, title: 'ACTION_REQUIRED' },
                { id: 4, title: 'BOOKED' },
                { id: 8, title: 'APPROVED' },
            ];

            var finishedSubStatusFilterArray = [
                { id: 5, title: 'DECLINED' },
                { id: 6, title: 'CANCELLED' },
            ];
            vm.remoteUrl = new URL(CommonFile.getBaseUrl() + 'api/v1/event/getSupplierEventDropdownList');

            vm.init();

            /**
             * Badri Paudel 9/17/2021
             * Listens for route change in this controller
             * check if user is already logged in and is trying to access login page again, if yes, redirect to dashboard
             */
            $scope.$on('$locationChangeSuccess', function ($event, next, current) {
                if (window.location.pathname === '/' && localStorage.getItem('authToken')) {
                    AuthenticationService.checkIfUserIsLoggedInAndRedirectToOffersDashboard();
                }
            });

            function countActiveRequestOffersFn(requestOffers) {
                return jQuery.grep(requestOffers, function filter(it) {
                    return it.status.name != 'DECLINED';
                }).length;
            }

            function setActiveFn(tab) {
                ActiveTabService.setOfferDetailTab(tab);
            }

            function displayActivityStreamFn() {
                vm.showActivityStream = true;
                vm.showAllRequestOffers = false;
            }

            function displayAllRequestOffersFn() {
                vm.showActivityStream = false;
                vm.showAllRequestOffers = true;
            }

            function initFn() {
                let _userData = AuthenticationService.getUserData();
                if (_userData && _userData.userGroup) {
                    _initUserGroups(_userData.userGroup);
                }
                vm.params.status = $rootScope.selectedStatus;
                vm.params.userGroupId = vm.currentUserGroupId;
                vm.currentUserGroupName = vm.currentUserGroupId;
                vm.showActivityStream = false;
                vm.showAllRequestOffers = true;
                vm.requestOfferListViewMode = 1; // 1 = card, 2 = list
                vm.sortColumn = '- request.event.fromDate';
                vm.reverse = false;
                vm.sortArrow = 'asc';
                vm.mainStatus = vm.mainStatusFilterArray[0];
                vm.statusFilterArray = activeSubStatusFilterArray.concat(finishedSubStatusFilterArray);
                $rootScope.selectedStatus = vm.statusFilterArray[0].title;
                $scope.startProgress({ showContent: false });
                Page.supplierHomePage(
                    vm.params,
                    function (response) {
                        vm.setActive(undefined); // reset on supplier's home page
                        if (response.userGroup && response.enableCentralBooking) {
                            let _userData = AuthenticationService.getUserData();
                            _userData.userGroup = response.userGroup;
                            AuthenticationService.updateUserData(_userData);
                            _initUserGroups(response.userGroup);
                            $rootScope.$broadcast('user:group:changed:central:booking', {});
                        }
                        vm.supplier = response.supplier;
                        vm.offers = response.offers;
                        vm.enableCentralBooking = response.enableCentralBooking;
                        vm.lastActivities = response.lastActivities;
                        vm.params = response.paginationParams;
                        vm.totalOffersCount = response.totalCount;
                        vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                        if (vm.openPresentation) {
                            vm.openPresentationModal();
                        }
                        $scope.endProgress();
                    },
                    $scope.endWithErrorOverlay
                );

                $timeout(function () {
                    jQuery(document).ready(function () {
                        jQuery('#central-booking-search').select2({
                            closeOnSelect: true,
                            placeholder: $translate.instant('common.search'),
                            ajax: {
                                url: CommonFile.getBaseUrl() + 'api/v1/userGroup/' + vm.currentUserGroupId + '/children',
                                dataType: 'json',
                                data: function (params) {
                                    return {
                                        searchText: params.term,
                                        page: params.page,
                                    };
                                },
                                processResults: function (data, params) {
                                    params.page = params.page || 1;
                                    var results = [];
                                    if (params.page < 2) {
                                        results.push({
                                            id: vm.currentUserGroup.id,
                                            text: vm.currentUserGroup.name,
                                        });
                                    }
                                    $.each(data.childGroupList, function (index, item) {
                                        results.push({
                                            id: item.id,
                                            text: item.name,
                                        });
                                    });

                                    return {
                                        results: results,
                                        pagination: {
                                            more: params.page * 10 < data.childGroupTotal,
                                        },
                                    };
                                },
                                formatResult: function (item) {
                                    return item.name;
                                },
                                formatSelection: function (item) {
                                    return item.name;
                                },
                            },
                        });
                        jQuery('.select2-selection__rendered').hover(function () {
                            $(this).removeAttr('title');
                        });
                    });
                }, 2000);

                $rootScope.$emit('event:user:action', {
                    action: 'visit.supplier.homepage',
                });
                updateLoggedUserStatus();
                getRemoteUrl();
            }

            function _initUserGroups(userGroup) {
                vm.userGroups = [];
                vm.currentUserGroup = userGroup;
                vm.currentUserGroupId = userGroup.id;
                vm.currentSelectedGroupId = userGroup.id;
                vm.currentUserGroupName = userGroup.id;
                // vm.userGroups.push({id: vm.currentUserGroup.id, name: vm.currentUserGroup.name});
                // UserGroup.getChildren({groupId: vm.currentUserGroupId, max: 10, offset:0}, (response) => {
                //     vm.totalChildren = response.childGroupTotal
                //     if (response.childGroupList && response.childGroupList.length > 0) {
                //         angular.forEach(response.childGroupList, (childUserGroup) => {
                //             vm.userGroups.push({id: childUserGroup.id, name: childUserGroup.name});
                //         })
                //     }
                // })
            }

            function userGroupChangedFn() {
                jQuery('#central-booking-search').on('select2:select', function(e) {
                    let selectedUsergroup = e.params.data;
                    $timeout(()=> {
                        vm.currentUserGroupId = selectedUsergroup.id;
                        vm.params.status = $rootScope.selectedStatus;
                        vm.params.userGroupId = selectedUsergroup.id;
                        vm.currentSelectedGroupId = selectedUsergroup.id;
                        selectedStatusChanged(vm.params.status);
                    }, 200)
                });
            }

            function updateSelectedEventNameFn(eventName) {
                vm.searchParams.name = eventName;
                if (!vm.searchParams.name) {
                    resetParams();
                    getPaginatedEvents();
                }
            }

            function setEventNameAndSearchFn(eventName) {
                vm.searchParams.name = eventName;
                getPaginatedEvents();
            }

            function getRemoteUrl() {
                if ($rootScope.selectedStatus) {
                    vm.remoteUrl.searchParams.append('status', $rootScope.selectedStatus);
                }
                if (vm.searchParams.fromDate) {
                    vm.remoteUrl.searchParams.append('fromDate', vm.searchParams.fromDate);
                }
                if (vm.searchParams.toDate) {
                    vm.remoteUrl.searchParams.append('toDate', vm.searchParams.toDate);
                }
            }

            function eventSearchFn() {
                getPaginatedEvents();
                vm.searchResult = true;
            }

            function getPaginatedEvents() {
                setParams();
                var params = angular.merge(
                    {
                        fromDateStart: vm.searchParams.fromDate,
                        fromDateEnd: vm.searchParams.toDate,
                        searchText: vm.searchParams.name,
                        sorting: vm.sorting,
                    },
                    vm.params
                );
                vm.searchPattern = params.searchText;

                $scope.startProgress(params.status);
                Event.findAllEventsRest(
                    params,
                    function (response) {
                        vm.offers = response.list;
                        vm.totalOffersCount = response.totalCount;
                        // vm.totalLength = response.totalCount;
                        vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                        $scope.endProgress(vm.params.status);
                    },
                    $scope.endWithAlert
                );
            }

            function resendActivationEmailFn() {
                vm.data = {
                    email: vm.loggedUserEmail,
                    username: vm.loggedUserUsername,
                };
                $http
                    .post('api/v1/register/resendActivationEmail', vm.data)
                    .then(function () {
                        $rootScope.dialog('alert', {message: 'dialog.activation.message.sent'})
                    }, $scope.endWithAlert);
            }

            function toggleSortDirectionFn() {
                vm.reverse = !vm.reverse;
                vm.sortArrow = vm.reverse ? 'desc' : 'asc';
            }

            function mainStatusChangedFn(mainStatus) {
                if (vm.mainStatus === mainStatus) {
                    return;
                }
                vm.mainStatus = mainStatus;
                return vm.mainStatus === vm.mainStatusFilterArray[0]
                    ? selectedStatusChanged(activeSubStatusFilterArray[0].title)
                    : selectedStatusChanged(finishedSubStatusFilterArray[0].title);
            }

            function updateLoggedUserStatus() {
                var userData = AuthenticationService.getUserData();
                if (userData !== null) {
                    vm.loggedUserEmail = userData.email;
                    vm.loggedUserUsername = userData.username;
                }
            }

            function selectedStatusChanged(value) {
                angular.forEach(vm.statusFilterArray, function (item) {
                    if (item.title === value) {
                        $rootScope.selectedStatus = value;
                        statusChangedFn();
                    }
                });
            }

            function pageChangedFn() {
                if (vm.searchResult) {
                    getPaginatedEvents();
                } else {
                    setParams();
                    getOffers();
                }
            }

            function statusChangedFn() {
                resetParams();
                getOffers();
            }

            function setParams() {
                vm.params.status = $rootScope.selectedStatus;
                vm.params.userGroupId = vm.currentUserGroupId;
                vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                vm.params.max = vm.numPerPage;
            }

            function resetParams() {
                vm.params.status = $rootScope.selectedStatus;
                vm.params.userGroupId = vm.currentUserGroupId;
                vm.params.offset = 0;
                vm.params.max = vm.numPerPage;
                vm.currentPage = 1;
                vm.searchPattern = undefined;
                vm.field = 'fromDate';
                vm.direction = 'asc';
                vm.totalLength = 0;
                vm.searchParams = {
                    name: '',
                    fromDate: '',
                    toDate: '',
                };
                vm.remoteUrl.searchParams.set('status', vm.params.status);
                $scope.$broadcast('angucomplete-alt:clearInput');
            }

            function getOffers() {
                $scope.startProgress(vm.params.status);
                RequestOffer.list(
                    vm.params,
                    function (response) {
                        vm.offers = response.list;
                        vm.totalOffersCount = response.totalCount;
                        vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                        $scope.endProgress(vm.params.status);
                    },
                    $scope.endWithAlert
                );
            }

            function eventsOrderByDescFn() {
                vm.field = 'fromDate';
                vm.direction = 'desc';
                vm.sorting = { field: vm.field, direction: vm.direction };
                getPaginatedEvents();
            }

            function eventsOrderByAscFn() {
                vm.field = 'fromDate';
                vm.direction = 'asc';
                vm.sorting = { field: vm.field, direction: vm.direction };
                getPaginatedEvents();
            }

            $scope.$emit('event:home:page');

            $scope.$on('selectedStatus/changed', function (event, value) {
                selectedStatusChanged(value);
            });

            $rootScope.bodyClassForController($scope, 'supplierscreen');

            var profilePictureChangedEvent = $rootScope.$on(EventEnum.USER_PROFILE_PICTURE_CHANGED, function (event, data) {
                angular.forEach(vm.usersInGroup, function (user, index) {
                    if (user.id == data.id) {
                        vm.usersInGroup.splice(index, 1, data);
                    }
                });
            });

            function openPresentationModalFn() {
                if(vm.viewProfileSettingsModal) {
                    return;
                }
                vm.viewProfileSettingsModal = true;
                let data = { openPresentationByDefault: 'PRESENTATION' };
                let profileSettingsModalData = profileSettingsModal;
                profileSettingsModalData.inputs = { data };

                ModalService.showModal(profileSettingsModalData).then((modal) => {
                    vm.viewProfileSettingsModal = false;
                    modal.element.modal();
                    modal.close.then(() => {
                        console.log('modal is closed!');
                    });
                });
            }

            function stripHtmlTagsFn(str) {
                if (str === null || str === '') {
                    return false;
                } else {
                    str = str.toString();
                    var stripedhtml = str.replace(/(<([^>]+)>)/gi, '');
                    return htmlDecode(stripedhtml);
                }
            }

            function htmlDecode(input) {
                var e = document.createElement('div');
                e.innerHTML = input;
                return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
            }

            $scope.$on(EventEnum.USER_DATA_CHANGED, updateLoggedUserStatus);
            $scope.$on(EventEnum.USER_GROUP_CHANGED_SILENT, updateLoggedUserStatus);
            $scope.$on(EventEnum.USER_GROUP_CHANGED, updateLoggedUserStatus);

            $scope.$on('$destroy', profilePictureChangedEvent);
        }
    ]
})