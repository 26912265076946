import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

class CommonTranslation {
    constructor($translate, $scope, RequestOffer, DateService) {
        this._injected = {$translate, $scope, RequestOffer, DateService};
        ScopeFunctions.addToScope($scope);
        this.getLogs = this.getLogs.bind(this);
        this.parseDateTimeInLocaleFormat = this.parseDateTimeInLocaleFormat.bind(this);
        this.currentPage = 1;
        this.numberPerPage = 6;
        this.resetActivitiesList();
        this.$onInit = () => {
            this.getLogs();
        }
    }

    getLogs() {
        const { RequestOffer } = this._injected;
        var params = {
            offerId: this.offerId,
            max: this.params.max,
            offset: this.params.offset,
        };
        this.loading = true;
        if (this.offerId) {
            RequestOffer.offerLog(
                params,
                (resp) => {
                    this.logs = resp.list;
                    this.logs.forEach((activity) => {
                        this.activities.push(activity);
                    });
                    this.totalActivitiesCount = resp.totalCount;
                    this.loading = false;
                    this.totalCount = Math.ceil(resp.totalCount / this.numberPerPage);
                },
                () => {
                    this.loading = false;
                }
            );
        }
    }

    resetActivitiesList() {
        this.params = { offset: 0, max: this.numberPerPage };
        this.loading = false;
        this.totalActivitiesCount = -1;
        this.activities = [];
    }

    getDomainClassName(str) {
        let m = /[^.]*$/.exec(str)[0];
        return m;
    }

    getChangedPropertyName(log) {
        if (log.oldValue) {
            if (['name', 'creatorName', 'supplierName'].indexOf(log.propertyName) > -1) {
                return 'Name';
            }
            if (['amount', 'creatorAmount', 'supplierAmount'].indexOf(log.propertyName) > -1) {
                return 'Amount';
            }
            if (['price', 'creatorPrice', 'supplierPrice'].indexOf(log.propertyName) > -1) {
                return 'Price';
            }
            return log.propertyName;
        }
        return null;
    }

    parseDateTimeInLocaleFormat(timestamp) {
        const { DateService } = this._injected;
        return DateService.parseDateTimeInLocaleFormat(timestamp);
    }

    getEventName(log) {
        const { $translate } = this._injected;
        if (log.eventName === 'UPDATE') {
            return $translate.instant('final.details.updated');
        }
        if (log.eventName === 'INSERT') {
            return $translate.instant('add.content.guide.added');
        }
        return null;
    }

    setPaginationParams() {
        this.params.offset = (this.currentPage - 1) * this.numberPerPage;
        console.log('this.params.offset', this.params.offset);
        this.params.max = this.numberPerPage;
    }

    pageChanged() {
        this.setPaginationParams();
        this.loadNextPage();
    }

    loadNextPage() {
        if (this.loading) {
            return;
        }
        this.getLogs();
    }
}

CommonTranslation.$inject = ['$translate', '$scope', 'RequestOffer', 'DateService'];
export default CommonTranslation;
