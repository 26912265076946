/**
 * Created by kshitij on 1/23/17.
 */

const OFFER_UNSENT_STATUS = ['NEW', 'EDITED_BY_CREATOR'];
const OFFER_ALL_BEFORE_BOOKED_STATUS = [
    'NEW',
    'NEW_NO_SUPPLIER',
    'EDITED_BY_CREATOR',
    'SENT',
    'ANSWERED',
    'CANCELED',
    'DECLINED',
];
const OFFER_ALL_BOOKED_STATUS = [
    'BOOKED',
    'BOOKED_WAITING_FOR_CONFIRMATION',
    'BOOKED_LOCKED_BY_CREATOR',
    'BOOKED_WAITING_FOR_CREATOR',
    'BOOKED_LOCKED_BY_SUPPLIER',
    'BOOKED_WAITING_FOR_SUPPLIER',
];
const OFFER_AFTER_ALL_BOOKED_STATUS = [
    'BOOKED',
    'BOOKED_LOCKED_BY_CREATOR',
    'BOOKED_WAITING_FOR_CREATOR',
    'BOOKED_LOCKED_BY_SUPPLIER',
    'BOOKED_WAITING_FOR_SUPPLIER',
];
const OFFER_BOOKED_OR_LOCKED_STATUS = [
    'BOOKED',
    'BOOKED_WAITING_FOR_CONFIRMATION',
    'BOOKED_LOCKED_BY_CREATOR',
    'BOOKED_LOCKED_BY_SUPPLIER',
];
const BOOKED_NON_LOCKED_STATUS = [
    'BOOKED',
    'BOOKED_WAITING_FOR_CONFIRMATION',
    'BOOKED_WAITING_FOR_CREATOR',
    'BOOKED_WAITING_FOR_SUPPLIER',
];
const OFFER_ALL_FINISHED_STATUS = [
    'FINISHED',
    'FINISHED_WAITING_FOR_CREATOR',
    'FINISHED_WAITING_FOR_SUPPLIER',
    'APPROVED',
];
const OFFER_ALL_CANCELED_FINISHED_STATUS = [
    'CANCELED_AND_FINISHED',
    'CANCELED_WAITING_FOR_CREATOR',
    'CANCELED_WAITING_FOR_SUPPLIER',
    'CANCELED_AND_APPROVED',
];
const OFFER_FROM_BOOKED_TO_APPROVED = [...OFFER_ALL_BOOKED_STATUS, ...OFFER_ALL_FINISHED_STATUS];
const OFFER_AFTER_BOOKED_TO_APPROVED = [...OFFER_AFTER_ALL_BOOKED_STATUS, ...OFFER_ALL_FINISHED_STATUS];
const SHOW_BILLING_INFO = [
    ...OFFER_ALL_BOOKED_STATUS,
    ...OFFER_ALL_FINISHED_STATUS,
    ...OFFER_ALL_CANCELED_FINISHED_STATUS,
];
const OFFER_ALL_AFTER_ANSWERED_STATUS = ['ANSWERED', ...OFFER_ALL_BOOKED_STATUS, ...OFFER_ALL_FINISHED_STATUS];

export default function (appModule) {
    //used for the constants in a global scope
    appModule.constant('RequestOfferStatusUtils', {
        /**
         * NEW
         */
        UNSENT_STATUS_LIST: OFFER_UNSENT_STATUS,

        /**
         * ANSWERED
         */
        ALL_AFTER_ANSWERED_STATUS: OFFER_ALL_AFTER_ANSWERED_STATUS,

        ANSWERED_STATUS_LIST: ['ANSWERED', 'BOOKED'],

        /**
         * BOOKED
         */
        ALL_BEFORE_BOOKED_STATUS: OFFER_ALL_BEFORE_BOOKED_STATUS,

        ALL_BOOKED_STATUS: OFFER_ALL_BOOKED_STATUS,

        BOOKED_OR_LOCKED_STATUS: OFFER_BOOKED_OR_LOCKED_STATUS,

        BOOKED_NON_LOCKED_STATUS: BOOKED_NON_LOCKED_STATUS,

        /**
         * FINISHED
         */
        ALL_FINISHED_STATUS: OFFER_ALL_FINISHED_STATUS,
        ALL_CANCELED_FINISHED_STATUS: OFFER_ALL_CANCELED_FINISHED_STATUS,

        /**
         * INTERNAL
         */
        ALL_INTERNAL_STATUS: ['INTERNAL', 'MANUAL', 'MANUALLY_BOOKED'],

        /**
         * MISCELLANEOUS
         */
        ALL_CREATOR_WAITING_STATUS: [
            'NEW',
            'SENT',
            'EDITED_BY_CREATOR',
            'BOOKED_WAITING_FOR_SUPPLIER',
            'FINISHED_WAITING_FOR_SUPPLIER',
        ],

        OFFER_IN_CREATOR_EDIT_DISABLED_STATE: [
            'BOOKED_WAITING_FOR_CREATOR',
            'BOOKED_WAITING_FOR_SUPPLIER',
            'FINISHED_WAITING_FOR_SUPPLIER',
        ],

        OFFER_IN_SUPPLIER_EDIT_DISABLED_STATE: [
            'ANSWERED',
            'EDITED_BY_CREATOR',
            'BOOKED_WAITING_FOR_CONFIRMATION',
            'BOOKED_LOCKED_BY_CREATOR',
            'BOOKED_WAITING_FOR_CREATOR',
            'FINISHED_WAITING_FOR_CREATOR',
            'APPROVED',
        ],

        CANNOT_CHAT_WITH_SUPPLIER_STATUS: ['NEW', 'CANCELED', 'APPROVED'],

        NOT_TO_CREATE_ACTIVITY_LIST: ['CANCELED', 'FINISHED_WAITING_FOR_SUPPLIER', 'FINISHED'],

        OFFER_PARTS_STATUS_EDITABLE_BUT_NOTIFY: ['ANSWERED'], // to show alert while trying to edit offer part

        CANCELLED_DECLINED_STATUS: ['CANCELED', 'DECLINED'],

        OFFER_FROM_BOOKED_TO_APPROVED: OFFER_FROM_BOOKED_TO_APPROVED,

        OFFER_AFTER_BOOKED_TO_APPROVED: OFFER_AFTER_BOOKED_TO_APPROVED,

        SHOW_BILLING_INFO: SHOW_BILLING_INFO,
    });
}