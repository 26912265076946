'use strict';
import { ScopeFunctions } from "../../../../../../../utils/global/scopeFunctions";

// Register `participants.confirm.email.design` component, along with its associated controller and template

const BASE_URL = 'app/components/modules/event/event_detail/participants/invitation/design-confirmation-email';
angular.
    module('participants.confirm.email.design').
    component('confirmemaildesign', {
        templateUrl: './app/components/modules/event/event_detail/participants/invitation/design-confirmation-email/confirmation.email.design.view.html',
        controllerAs: 'confirmationEmailDesignCtrl',
        controller: [
            '$location',
            '$scope',
            '$rootScope',
            '$state',
            'EventPresentation',
            'AuthenticationService',
            '$stateParams',
            'Event',
            'UserGroup',
            'ActiveTabService',
            function ConfirmationEmailDesignController(
                $location,
                $scope,
                $rootScope,
                $state,
                EventPresentation,
                AuthenticationService,
                $stateParams,
                Event,
                UserGroup,
                ActiveTabService
            ) {
                var vm = this;
                vm.baseUrl = BASE_URL;
                vm.alreadyAnsweredQuestions = false;
                vm.init = initFn;
                vm.showHideMapInInvitation = showHideMapInInvitationFn;
                vm.setActive = setActiveFn;
                vm.openInvitationTab = openInvitationTabFn;

                vm.eventId = $stateParams.eventId;

                ScopeFunctions.addToScope($scope);
                vm.init();

                function initFn() {
                    $scope.startProgress({ showContent: false });
                    EventPresentation.getInvitationPresentationForEvent(
                        { eventId: vm.eventId, requestFrom: 'confirmationEmail' },
                        updatePresentationDetails,
                        $scope.endWithErrorOverlay
                    );
                }

                function setActiveFn(tab) {
                    ActiveTabService.setEventDetailTab(tab);
                }

                function showHideMapInInvitationFn(value) {
                    vm.eventPresentation.showMap = value;
                    $scope.startProgress();
                    EventPresentation.update(vm.eventPresentation, updatePresentationDetails);
                }

                function updatePresentationDetails(response) {
                    vm.eventPresentation = response.eventPresentationDetails.eventPresentation;
                    vm.selectedPresentation = response.eventPresentationDetails.slide;
                    vm.showMapInInvitation = { checked: response.eventPresentationDetails.eventPresentation.showMap };
                    vm.invitationDetails = response.invitationDetails;
                    $scope.endProgress();
                }

                function openInvitationTabFn() {
                    $state.go('event.detail.participants.invitation');
                }

                var invitationUpdateEvent = $rootScope.$on('invitation:details:update', function (event, data) {
                    vm.invitationDetails = data;
                    vm.showMapInInvitation = { checked: data.showMap };
                });

                $scope.$on('$destroy', invitationUpdateEvent);
            }
        ]
    });
