'use strict';
import { OgModal } from "../../utils/global/ogModal";
import CommonFunctions from '../../utils/CommonFunctionUtil';

angular.module('dialog').directive('ogAddRequestModal', [
    '$rootScope',
    'EventEnum',
    function ($rootScope, EventEnum) {
        return new OgModal({
            name: 'request:add',
            type: 'custom',
            controller: function ($scope) {
                $scope.newRequest = { form: 'addRequestForm' };

                $scope.initDialog = function (data) {
                    $scope.newRequest.allowInternalContent = data.allowInternalContent;
                };

                $scope.submit = function () {
                    $scope.addRequestForm.$setUntouched();
                    $scope.addRequestForm.$setPristine();
                    if (!$scope.addRequestForm.$valid) {
                        CommonFunctions.touchFormFields($scope.addRequestForm);
                        return;
                    }
                    $rootScope.$broadcast(EventEnum.ADD_REQUEST_TO_EVENT, $scope.newRequest, function () {
                        $scope.show = false;
                        $rootScope.$emit('event:user:action', {
                            action: 'content.added.in.event',
                            data: $scope.newRequest,
                        });
                        $scope.newRequest.name = '';
                        $scope.newRequest.isInternal = false;
                    });
                    $scope.close();
                };
                $scope.clearValue = function () {
                    $scope.newRequest.name = '';
                    $scope.addRequestForm.$setUntouched();
                    $scope.addRequestForm.$setPristine();
                };
            },
        });
    },
]);
