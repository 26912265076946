/**
 * Created by sampura on 2020-02-27.
 */


/* global angular */
'use strict';

angular.module('core.existingServices').factory('BrowserDetectService', [
    '$window',
    function ($window) {
        let _printSummary = false;

        function isItIE() {
            let userAgent = $window.navigator.userAgent;
            var isIE = /MSIE|Trident/.test(userAgent);
            return isIE;
        }

        function setPrintSummary(yes) {
            _printSummary = yes;
        }

        function getPrintSummary() {
            return _printSummary;
        }

        return {
            detect: function () {
                return detectBrowser();
            },
            isItIE: function () {
                return isItIE();
            },
            getPrintSummary: function () {
                return getPrintSummary();
            },
            setPrintSummary: function (value) {
                return setPrintSummary(value);
            },
        };
    },
]);

