(function () {
    'use strict';

    angular.module('ogDeleteUndeleteRow').directive('ogDeleteUndeleteRow', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    offerPart: '=',
                    requestOffer: '=',
                    message: '=',
                    deleteRow: '=',
                    undoDeleteRow: '=',
                    flagForDeleteUndelete: '=',
                    confirmDelete: '=',
                    showDeleteIcon: '=',
                    popoverTriggerType: '@ogPopoverTriggerType',
                },
                templateUrl: './app/components/directives/delete_undelete_row/og_delete_undelete_row.view.html',
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.showUndeleteBtn = false;
                    },
                ],
                link: function ($scope) {
                    $scope.$watch('flagForDeleteUndelete', function (value) {
                        if (value == 'undelete' + $scope.offerPart.id) {
                            $scope.showUndeleteBtn = true;
                        } else if (value == 'delete' + $scope.offerPart.id) {
                            $scope.showUndeleteBtn = false;
                        }
                    });
                },
            };
        },
    ]);
})();
