const DEFAULT_ACTIVITY_FETCH_SIZE = 6;
class OfferMessagesController {
    constructor($scope, Activity, Note) {
        this._injected = { $scope, Activity, Note };
        this.currentPage = 1;
        this.numPerPage = 6;
        this.resetSearchParams();
        this.activityFetchBusy = true;
        this.showActivityMessageDetails = false;
        let newActivityAdded = function () {
            if (this.newActivity) {
                this.addNewActivity();
            }
        }.bind(this);
        this.$onInit = () => {
            this.getActivities();
        }
        $scope.$watch(() => this.newActivity, newActivityAdded);
    }

    resetSearchParams() {
        this.params = { offset: 0, max: this.numPerPage };
        this.loading = false;
        this.totalActivitiesCount = -1; //default value
        this.activities = [];
    }

    getActivities() {
        const { Activity, Note } = this._injected;
        this.loading = true;
        this.searchParams = {
            offerId: this.offer.id,
            eventId: this.event.id,
            page: this.params.offset,
            pageSize: this.numPerPage,
            type: this.activityType,
        };
        let activityCb = function (response) {
            this.activityLists = response.list;
            this.activityLists.forEach((activity) => {
                this.activities.push(activity);
            });
            this.totalActivitiesCount = response.totalCount;
            this.totalCount = Math.ceil(response.totalCount / this.numPerPage);
            this.activityFetchBusy = false;
            this.loading = false;
        }.bind(this);
        if (this.searchParams.type === 'NOTE') {
            Note.list(this.searchParams, activityCb);
        } else {
            Activity.list(this.searchParams, activityCb);
        }
    }

    addNewActivity() {
        if (this.newActivity) {
            this.activities.splice(0, 0, this.newActivity);
            this.totalActivitiesCount++;
            this.newActivity = null; // to avoid the recursive loop in watch
        }
    }

    loadMoreActivities() {
        if (this.activityFetchBusy) {
            return;
        }
        this.searchParams.page = this.activities.length;
        this.getActivities();
    }

    showMessageDetails(activityMessage) {
        this.showActivityMessageDetails = true;
        /*    setCreatorChatMessages(activityMessage.id);
        vm.showActivityMessageDetails = true;
        vm.showActivityMessageId = activityMessage.id;
        vm.isReplyMessage = true;
        if (activityMessage.offer){
          vm.replyToOfferId = activityMessage.offer.id;
        }else {
          vm.replyToOfferId = null;
        }
        updateMessageSeenStatusFn(activityMessage);
        if(vm.showActivityMessageDetails && vm.showActivityMessageId == activityMessage.id) {
          if(vm.creatorChatMessageDetails.childMessages.length > 0) {
            $timeout(function() {
              ogScrollService.scrollToElement('scroll-here');
            }, 500);
          }
          else {
            $timeout(function() {
              ogScrollService.scrollToElement('reply-section-header');
            }, 500);
          }
        }
      }*/
    }

    pageChanged() {
        this.setPaginationParams();
        this.loadNextPage();
    }

    setPaginationParams() {
        this.params.offset = (this.currentPage - 1) * this.numPerPage;
        this.params.max = this.numPerPage;
    }

    loadNextPage() {
        if (this.loading) {
            return;
        }
        this.getActivities();
    }
}
OfferMessagesController.$inject = ['$scope', 'Activity', 'Note'];
export default OfferMessagesController;
