import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class DeclineInvitationModalCtrl {
    constructor(_data, $scope, close, $element) {
        this._injected = {
            _data,
            $scope,
            close,
            $element,
        };

        this.okCallBack = _data.okCallBack;
        this.cancelCallBack = _data.cancelCallBack;
        this.closeCallBack = _data.closeCallBack;
        this.message = _data.message;
        this.messageArgs = _data.messageArgs;
        this.okLabel = _data.okLabel;
        this.cancelLabel = _data.cancelLabel;
        this.isHtml = !!_data.html;
        this.canAddCustomMessage = _data.canAddCustomMessage;

        ScopeFunctions.addToScope($scope);
    }

    ok() {
        let okCallBack = this.okCallBack;
        if (okCallBack) {
            okCallBack(this.userMessage);
        }
        this._close();
    }

    cancel() {
        let cancelCallBack = this.cancelCallBack;
        if (cancelCallBack) {
            cancelCallBack();
        }
        this._close();
    }

    _close() {
        const { close, $element } = this._injected;
        let closeCallBack = this.closeCallBack;
        if (closeCallBack) {
            closeCallBack();
        }
        $element.modal('hide');
        close(null, 500);
    }
}
DeclineInvitationModalCtrl.$inject = ['_data', '$scope', 'close', '$element'];
export default DeclineInvitationModalCtrl;
