angular.module('core.configuration', []).provider('Config', function () {
    var config = {
        savedLocation: '',
        registerEmail: '',
        registerAccountType: '',
    };

    this.saveLocation = function (location) {
        config.savedLocation = location;
    };

    this.$get = function () {
        return config;
    };
});
