import budgetRoute from './budget/route';
import participantRoute from './participants/route';
import ratingRoute from './rating/route';

export default function (appModule) {
    appModule.config([
        '$routeProvider',
        '$locationProvider',
        '$stateProvider',
        '$urlRouterProvider',
        function ($routeProvider, $locationProvider, $stateProvider, $urlRouterProvider) {
            $urlRouterProvider.when('/event/:eventId', '/event/:eventId/overview');
            //To support existing url to supplier/calendar and budget tab to calendar tab
            $urlRouterProvider.when('/event/:eventId/overview/calendar?showIntro', [
                '$match',
                '$location',
                'EventGuideDataStore',
                function ($match, $location, EventGuideDataStore) {
                    if ($match.showIntro) {
                        EventGuideDataStore.setShowOnBoardingGuide(false); //show onboarding guided
                        EventGuideDataStore.setEventGuideNotification(false); //show event guide notification
                        EventGuideDataStore.setShowEventProgressGuide(true); //show event progress guide
                    }
                    var path = '/event/' + $match.eventId + '/overview';
                    $location.url(path);
                },
            ]);
            $urlRouterProvider.when('/event/:eventId/overview/supplier', '/event/:eventId/overview');
            $urlRouterProvider.when('/event/:eventId/overview/budget', '/event/:eventId/overview');

            $urlRouterProvider.when('/event/:eventId/participants', '/event/:eventId/participants/list');
            $urlRouterProvider.when('/event/:eventId/survey', '/event/:eventId/survey/rating');
            $urlRouterProvider.when('/event/:eventId/rating', '/event/:eventId/rating/creator');
            $stateProvider
                .state('event.detail.overview', {
                    url: '/overview',
                    template: '<calendar></calendar>',
                    params: { requestId: null, offerId: null, highlightElement: null },
                })
                .state('event.detail.budget', {
                    url: '/budget',
                    template: '<budget></budget>',
                    params: { highlightElement: null },
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('event.detail.message', {
                    url: '/message?msgId',
                    template: '<message></message>',
                })
                .state('event.detail.participants', {
                    url: '/participants',
                    template: '<participants></participants>',
                    abstract: true,
                })
                .state('event.detail.task', {
                    url: '/task',
                    template: '<task></task>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('event.detail.activities', {
                    url: '/activities',
                    template: '<all-activities></all-activities>'
                })
                .state('event.detail.notes', {
                    url: '/notes',
                    template: '<notes></notes>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate();
                            },
                        ],
                    },
                })
                .state('event.detail.rating', {
                    url: '/rating',
                    template: '<ratings></ratings>',
                    abstract: true,
                });
        },
    ]);
    budgetRoute(appModule);
    participantRoute(appModule);
    ratingRoute(appModule);
};
