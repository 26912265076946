'use strict';
angular.module('core.translation').service('Translation', [
    '$resource',
    function ($resource) {
        var translateRest = $resource(
            'api/v1/translate/getTranslatedText',
            {},
            {
                getText: {method: 'POST', isArray: false},
            }
        );

        return {
            getTranslatedText: function (jsonObject, success, error) {
                return translateRest.getText(jsonObject, success, error);
            },
        };
    },
]);
