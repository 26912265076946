import {ScopeFunctions} from '../../../utils/global/scopeFunctions';
import {UtilFunctions} from "../../../utils/global/utilFunctions";

(function () {
    'use strict';

    angular.module('ogRequestOfferPartOptionAddEditInline').directive('ogRequestOfferPartOptionAddEditInline', [
        '$log',
        '$routeParams',
        '$rootScope',
        '$translate',
        'GlobalConstants',
        'RequestOfferStatusUtils',
        'CommonFile',
        'CommonFunctions',
        function ($log, $routeParams, $rootScope, $translate, GlobalConstants, RequestOfferStatusUtils, CommonFile, CommonFunctions) {
            return {
                restrict: 'EA',
                scope: {
                    partOption: '=',
                    offerPart: '=',
                    offer: '=',
                    event: '=',
                    includeVat: '=',
                    layout: '=',
                    partOptionSave: '=',
                },
                /* jshint ignore:start */
                templateUrl:
                    './app/components/directives/request_offer_part_option_add_edit_inline/request_offer_part_option_add_edit_inline.directive.view.html',
                /* jshint ignore:end */
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.hoursToAdd = 1; // Add default hours from 'from-date' to 'to-date' in dateTime pair.
                        ScopeFunctions.addToScope($scope);
                        $scope.VAT_DROPDOWN_LIST = GlobalConstants.VAT_DROPDOWN_LIST;
                        $scope.DEFAULT_VAT = GlobalConstants.DEFAULT_VAT;
                        $scope.remoteUrl = CommonFile.getBaseUrl() + 'api/v1/offerPartType';

                        $scope.amountTypes = [
                            { type: 'PIECES', name: $translate.instant('amount.type.PIECES') },
                            { type: 'PEOPLE', name: $translate.instant('amount.type.PEOPLE') },
                            { type: 'SAME_AS_EVENT', name: $translate.instant('amount.type.SAME_AS_EVENT') },
                        ];

                        $scope.init = function () {
                            $scope.syncDates = true;
                            $scope.isNewRow = false;
                            if ($scope.partOption == null) {
                                $scope.data = {
                                    dateTimeFrom: 0,
                                    dateTimeTo: 0,
                                };
                                $scope.isNewRow = true;
                                $scope.data.amountType = { name: 'PIECES' }; // set PIECES as default while creating new
                                $scope.data.timeless = false;
                            } else {
                                $scope.originalData = angular.copy($scope.partOption);
                                $scope.data = angular.copy($scope.partOption);
                                $scope.data.price = UtilFunctions.isGreaterOrEqualToZero($scope.data.newPrice)
                                    ? $scope.data.newPrice
                                    : $scope.data.price;
                            }
                            if ($scope.data && $scope.data.name) {
                                $scope.data.name = CommonFunctions.getTranslatedTextFromCode(
                                    $scope.data.name,
                                    $scope.data.nameCode
                                );
                            }
                            $scope.isTimePairValid = true;
                        };

                        $scope.canceled = function () {
                            $rootScope.offerPartOptionEditId = 0;
                            $scope.partOption = $scope.originalData;
                        };

                        $scope.checkIfAmountTypeChanged = function () {
                            if ($scope.data.amountType.name == 'SAME_AS_EVENT') {
                                $scope.data.amount = $scope.event.participantAmount;
                            }
                        };

                        $scope.save = function () {
                            $scope.data.newPrice = $scope.data.price;
                            if ($scope.data.status.name === 'EDITED_BY_CREATOR') {
                                $scope.data.amount = $scope.data.creatorAmount;
                                $scope.data.amountType = $scope.data.creatorAmountType;
                                $scope.data.dateTimeFrom = $scope.data.creatorDateTimeFrom;
                                $scope.data.dateTimeTo = $scope.data.creatorDateTimeTo;
                            }
                            $rootScope.offerPartOptionEditId = 0;
                            var data = angular.copy($scope.data);
                            $scope.partOptionSave(data);
                        };

                        $scope.isOfferInCreatorEditDisabledState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.OFFER_IN_CREATOR_EDIT_DISABLED_STATE.indexOf(
                                    $scope.offer.status.name
                                ) > -1
                            );
                        };

                        $scope.init();

                        $scope.isOfferInCanceledState = function (offer) {
                            return CommonFunctions.isItCanceledOffer(offer);
                        };

                        $scope.isAmountInvalid = function () {
                            if ($scope.data && !$scope.data.amount) {
                                return true;
                            }
                        };

                        $scope.saveDisabled = function () {
                            if (!$scope.isTimePairValid && !$scope.data.timeless) {
                                return true;
                            }
                            if (
                                $scope.layout === 'creator' &&
                                $scope.offerPart &&
                                $scope.offerPart.editable &&
                                $scope.offerPart.editable.name === 'DENIED'
                            ) {
                                return true;
                            }
                            if ($scope.data && $scope.data.id) {
                                var nameCodeChanged =
                                    $scope.originalData.nameCode != null &&
                                    $scope.data.nameCode != $scope.originalData.nameCode;
                                var nameChanged =
                                    $scope.originalData.nameCode == null &&
                                    $scope.data.resourceName != $scope.originalData.resourceName;
                                let creatorDataChanged = false;
                                if ($scope.data.status.name === 'EDITED_BY_CREATOR') {
                                    creatorDataChanged =
                                        $scope.data.creatorAmount != $scope.originalData.creatorAmount ||
                                        $scope.data.creatorAmountType.name !=
                                        $scope.originalData.creatorAmountType.name ||
                                        $scope.data.creatorDateTimeFrom != $scope.originalData.creatorDateTimeFrom ||
                                        $scope.data.creatorDateTimeTo != $scope.originalData.creatorDateTimeTo;
                                }
                                let dataHasChanged =
                                    nameCodeChanged ||
                                    nameChanged ||
                                    creatorDataChanged ||
                                    $scope.data.amount != $scope.originalData.amount ||
                                    $scope.data.amountType.name != $scope.originalData.amountType.name ||
                                    $scope.data.dateTimeFrom != $scope.originalData.dateTimeFrom ||
                                    $scope.data.dateTimeTo != $scope.originalData.dateTimeTo;
                                return !dataHasChanged;
                            } else {
                                return !(
                                    $scope.data &&
                                    $scope.data.resourceName &&
                                    ($scope.event.participantAmount &&
                                    $scope.data.amountType &&
                                    $scope.data.amountType.name != 'SAME_AS_EVENT'
                                        ? $scope.data.amount
                                        : true)
                                );
                            }
                        };
                    },
                ],
            };
        },
    ]);
})();
