'use strict';
import {OgModal} from "../../utils/global/ogModal";

angular.module('dialog').directive('ogShowBillingInfoModal', [
    function () {
        return new OgModal({
            name: 'show:billing:info',
            type: 'custom',
            controller: function ($scope) {
                $scope.initDialog = function (data) {
                    if (data) {
                        $scope.billingAddress = data.billingInfo;
                        $scope.closeCallBack = data.closeCallBack;
                    }
                    };

                    $scope.closeModal = function () {
                        var closeCallBack = $scope.closeCallBack;
                        if (closeCallBack) {
                            closeCallBack();
                        }
                    };
                },
            });
        },
    ]);

