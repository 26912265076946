'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";
import CommonFunctions from '../../utils/CommonFunctionUtil';
import {DiacriticsUtil} from '../../utils/global/DiacriticsUtil';

angular.module('dialog').directive('ogEventSiteShareModal', [
    '$rootScope',
    '$translate',
    '$sce',
    'AuthenticationService',
    'FieldChangedSvc',
    'Participant',
    'ParticipantActivity',
    'Event',
    'EventSite',
    '$timeout',
    function (
        $rootScope,
        $translate,
        $sce,
        AuthenticationService,
        FieldChangedSvc,
        Participant,
        ParticipantActivity,
        Event,
        EventSite,
        $timeout
    ) {
        return new OgModal({
            name: 'event:site:share',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                $scope.eventSiteShare = {};
                var initData = {};
                $scope.changeInvitationForms = {};

                function setScopeData(data) {
                    $scope.data = {
                        name: data.name,
                        sharePublicly: data.sharePublicly,
                        url: data.url,
                        invalidCharacter: null,
                    };
                }

                $scope.initDialog = function (data) {
                    initData = {
                        eventId: data.eventId,
                        type: data.type,
                    };
                    $scope.eventId = data.eventId;
                    $scope.orgizerDetailsNeededMessage = false;
                    $scope.openOrganizerDetails = false;
                    $scope.setEventSiteLink = false;
                    $scope.startProgress();
                    Event.getEventInvitationDetails(
                        $scope.eventId,
                        function (response) {
                            $scope.invitationDetails = response;
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );

                    EventSite.getEventSiteDetails(
                        initData,
                        function (response) {
                            setScopeData(response);
                        },
                        function (error) {
                            console.log(error);
                        }
                    );
                    $scope.eventSiteShare.eventSiteShareForm.$setUntouched();
                };

                $scope.set = function () {
                    if (!$scope.eventSiteShare.eventSiteShareForm.$valid) {
                        CommonFunctions.touchFormFields($scope.eventSiteShare.eventSiteShareForm);
                        return;
                    }
                    getOrganizerDetailsFn();
                    $timeout(function () {
                        if (!$scope.setEventSiteLink) {
                            return;
                        } else {
                            $scope.startProgress();
                            var reqData = angular.extend({}, initData, {
                                name: $scope.data.name,
                                sharePublicly: $scope.data.sharePublicly,
                            });
                            EventSite.updatePublicUrl(
                                reqData,
                                (response) => {
                                    setScopeData(response);
                                    $scope.endProgress();
                                },
                                $scope.endWithError
                            );
                        }
                    }, 1000);
                };

                function getOrganizerDetailsFn() {
                    $scope.startProgress();
                    Participant.organizerDetails(
                        $scope.eventId,
                        function (response) {
                            if (
                                $scope.invitationDetails.dataAccessHandler.name &&
                                $scope.invitationDetails.dataAccessHandler.email
                            ) {
                                $scope.setEventSiteLink = true;
                            } else {
                                $scope.orgizerDetailsNeededMessage = true;
                            }
                            /*if($scope.email.provider !== 'BY_EMAIL_ONLY'){
                            shouldWeActivateSMSFn();
                        }*/
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                $scope.openOrganizerDetailsSection = function () {
                    $scope.openOrganizerDetails = true;
                    $scope.orgizerDetailsNeededMessage = false;
                };

                $scope.closeOrganizerDetailsSection = function () {
                    $scope.openOrganizerDetails = false;
                    $scope.orgizerDetailsNeededMessage = true;
                };

                $scope.updateOrganizerDetails = function () {
                    $scope.openOrganizerDetails = false;
                    if (!$scope.changeInvitationForms.formForOrganizer.$valid) {
                        CommonFunctions.touchFormFields($scope.changeInvitationForms.formForOrganizer);
                        return;
                    }
                    var invitationDetails = $scope.invitationDetails;
                    $scope.startProgress();
                    Event.updateEventInvitationDetails(
                        $scope.eventId,
                        invitationDetails,
                        function (data) {
                            $scope.set();
                            $rootScope.$broadcast('invitation:details:update', data);
                            $scope.endProgress();
                        },
                        $scope.endWithError
                    );
                };

                $scope.useOrganizerAsDataController = function () {
                    var _data = {
                        name: $scope.invitationDetails.organizer.company,
                        email: $scope.invitationDetails.organizer.email,
                    };
                    $scope.invitationDetails.dataAccessHandler = angular.copy(_data);
                };

                $scope.isNameValid = function () {
                    if (!$scope.data || !$scope.data.name) {
                        $scope.nameIsValid = true;
                        return;
                    }
                    var specialCharactersRegEx = new RegExp('[^a-zA-Z0-9\\s\\-_.*]');
                    var normalizedName = DiacriticsUtil.normalizeText($scope.data.name);
                    $scope.nameIsValid = !specialCharactersRegEx.test(normalizedName);
                    $scope.data.invalidCharacter = $scope.nameIsValid
                        ? null
                        : specialCharactersRegEx.exec(normalizedName)[0];
                };
            },
        });
    },
]);

