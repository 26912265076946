import controller from './og_offer_part_comment.controller';

(function () {
    'use strict';
    angular.module('ogOfferPartComment').directive('ogOfferPartComment', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    offer: '=',
                    offerPart: '=',
                    list: '=listValue',
                    module: '@module',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                    isChanged: '&',
                    args: '=',
                    participantAmount: '=',
                    langChanged: '=',
                    selectedContentList: '=',
                },
                templateUrl: './app/components/directives/offer_part_comments/og_offer_part_comment.view.html',
                controller,
                controllerAs: 'offerPartCommentCtrl',
                bindToController: true,
            };
        },
    ]);
})();
