/**
 * Created with IntelliJ IDEA.
 * User: pc
 * Date: 12/9/19
 * Time: 3:51 PM
 * To change this template use File | Settings | File Templates.
 */
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class AddTransportModalCtrl {
    constructor(data, $scope, close, $element, $rootScope, $translate, EventEnum, TransportService, TimezoneService) {
        this._injected = {
            data,
            $scope,
            close,
            $element,
            $rootScope,
            $translate,
            EventEnum,
            TransportService,
            TimezoneService,
        };

        ScopeFunctions.addToScope($scope);

        this.transportation = {
            name: '',
            nameCode: '',
            type: {
                name: '',
                status: '',
            },
            timeFrom: 61200000,
            timeTo: 64800000,
            eventFromDate: 1664409600000,
            dayOfEvent: 0,
        };
        this.transportDetails = {
            busRequestId: null,
            boatRequestId: null,
            trainRequestId: null,
            planeRequestId: null,
            heliRequestId: null,
            taxiRequestId: null,
        };
        this.hideAddButton = true;
        this.hideNextButton = true;
        this.message = '';
        this.messageTitle = '';
        this.step = 1;
        this.isHtml = false;
        this.data = data;
        this.days = [];
        this.hoursToAdd = 1;
        this.fromDate = '';
        this.toDate = '';
        this.init(data);
        this.fillDays();
    }

    init(data) {
        const { $translate } = this._injected;
        if (data.message) {
            this.message = $translate.instant(data.message, data.params);
        }
        if (data.messageTitle) {
            this.messageTitle = $translate.instant(data.messageTitle, data.params);
        }

        this.isHtml = data.isHtml;

        data.requests && this.setRequestId(data.requests);
    }

    ok() {
        this.addTransport(this.transportation);
        this.init(this.transportation);
    }

    addTransport(transport) {
        let { $scope, TransportService, EventEnum } = this._injected;

        $scope.startProgress();
        let otherTransportObject = {
            timeFrom: null,
            timeTo: null,
            dayOfEvent: null,
        };
        if (transport.type.status === 'Other') {
            otherTransportObject.timeFrom = this.transportation.timeFrom;
            otherTransportObject.timeTo = this.transportation.timeTo;
            otherTransportObject.dayOfEvent = parseInt(this.transportation.dayOfEvent);
        }
        TransportService.addTransport(
            { eventId: this.data.eventId },
            {
                type: transport.name,
                oneWayTowardsEvent: transport.type.status === 'To Event',
                oneWayOutwardsEvent: transport.type.status === 'From Event',
                requestId: this.getRequestId(transport.name),
                transportOtherOption: transport.type.status === 'Other' ? otherTransportObject : null,
            },
            (response) => {
                this.data.okCallBack();
                this._close();
            },
            $scope.endWithErrorOverlay()
        );
    }
    handleOnchanged(modalName, currentValue) {
        // if (modalName === "timeFrom") { console.log(modalName, currentValue, 'conference activity controller') }
    }

    handleClick(name) {
        switch (name) {
            case 'BUS':
                this.transportation.name = 'BUS';
                this.transportation.nameCode = 'common.translate.request.bus.transport';
                this.next();
                break;
            case 'BOAT':
                this.transportation.name = 'BOAT';
                this.transportation.nameCode = 'common.translate.request.boat.transport';
                this.next();
                break;
            case 'TRAIN':
                this.transportation.name = 'TRAIN';
                this.transportation.nameCode = 'common.translate.request.train.transport';
                this.next();
                break;
            case 'TAXI':
                this.transportation.name = 'TAXI';
                this.transportation.nameCode = 'common.translate.request.taxi';
                this.next();
                break;
            case 'PLANE':
                this.transportation.name = 'PLANE';
                this.transportation.nameCode = 'common.translate.request.flight.transport';
                this.next();
                break;
            case 'HELICOPTER':
                this.transportation.name = 'HELICOPTER';
                this.transportation.nameCode = 'common.translate.request.helicopter';
                this.next();
                break;
            case 'One Way':
                this.hideAddButton = true;
                this.hideNextButton = false;
                this.transportation.type.name = 'One Way';
                this.next();
                break;
            case 'Round Trip':
                this.hideAddButton = false;
                this.hideNextButton = true;
                this.transportation.type.name = 'Round Trip';
                this.transportation.type.status = '';
                break;
            case 'From Event':
                this.transportation.type.status = 'From Event';
                break;
            case 'To Event':
                this.transportation.type.status = 'To Event';
                break;
            case 'Other':
                this.transportation.type.status = 'Other';
                this.hideAddButton = true;
                this.hideNextButton = false;
                this.next();
                break;

            default:
                break;
        }
    }

    next() {
        this.step = this.step + 1;
    }

    prev() {
        this.step = this.step - 1;
    }

    setRequestId(requests) {
        requests.length > 0 &&
            requests.forEach((request) => {
                switch (request.nameCode) {
                    case 'common.translate.request.bus.transport':
                        this.transportDetails.busRequestId = request.id;
                        break;
                    case 'common.translate.request.boat.transport':
                        this.transportDetails.boatRequestId = request.id;
                        break;
                    case 'common.translate.request.train.transport':
                        this.transportDetails.trainRequestId = request.id;
                        break;
                    case 'common.translate.request.taxi':
                        this.transportDetails.taxiRequestId = request.id;
                        break;
                    case 'common.translate.request.flight.transport':
                        this.transportDetails.planeRequestId = request.id;
                        break;
                    case 'common.translate.request.helicopter':
                        this.transportDetails.heliRequestId = request.id;
                        break;

                    default:
                        break;
                }
            });
    }

    getRequestId(name) {
        switch (name) {
            case 'BUS':
                return this.transportDetails.busRequestId;
                break;
            case 'BOAT':
                return this.transportDetails.boatRequestId;
                break;
            case 'TRAIN':
                return this.transportDetails.trainRequestId;
                break;
            case 'TAXI':
                return this.transportDetails.taxiRequestId;
                break;
            case 'PLANE':
                return this.transportDetails.planeRequestId;
                break;
            case 'HELICOPTER':
                return this.transportDetails.heliRequestId;
                break;

            default:
                break;
        }
    }

    fillDays() {
        const { TimezoneService } = this._injected;
        let noOfDays = 0;
        this.fromDate = new Date(TimezoneService.toLocalTime(this.data.fromDate));
        this.toDate = new Date(TimezoneService.toLocalTime(this.data.toDate));

        let differenceInTime = this.toDate.getTime() - this.fromDate.getTime();
        noOfDays = differenceInTime / (1000 * 3600 * 24) + 1;
        for (let i = 1; i <= noOfDays; i++) {
            this.days = [
                ...this.days,
                {
                    displayDate: this.formatDate(
                        new Date(TimezoneService.toLocalTime(this.data.fromDate)).setDate(this.fromDate.getDate() + i)
                    ),
                    id: i,
                },
            ];
        }
        if (noOfDays === 1) {
            this.transportation.dayOfEvent = 1;
        }
    }

    formatDate(date) {
        const { TimezoneService } = this._injected;
        let newDate = new Date(TimezoneService.toLocalTime(date));
        const month = newDate.toLocaleString('default', { month: 'short' });
        newDate = newDate.getDate() + ' ' + month;
        return newDate;
    }

    _close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

AddTransportModalCtrl.$inject = [
    'data',
    '$scope',
    'close',
    '$element',
    '$rootScope',
    '$translate',
    'EventEnum',
    'TransportService',
    'TimezoneService',
];
export default AddTransportModalCtrl;
