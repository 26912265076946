'use strict';

angular.
    module('core.customerUser').
    factory('CustomerUser', ['$resource',
        function ($resource) {
            return $resource('assets/phones/:phoneId.json', {}, {
                query: {
                    method: 'GET',
                    params: { phoneId: 'phones' },
                    isArray: true
                }
            });
        }
    ]);
