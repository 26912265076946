/**
 * Created by kshitij on 12/1/17.
 */
import amountAndPriceService from '../../../utils/price_calculate/PriceCalculationCreatorUtil';
import currencyUtil from '../../../utils/CurrencyUtil';
import customMessageModal from '../../../components/modals/modals2.0/custom-message-modal';
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';
import {UtilFunctions} from "../../../utils/global/utilFunctions";

const VAT_DROPDOWN_LIST = [0, 6, 12, 25]; //only valid vat for fortNox are 0, 6, 12 and 25
const A_DAY_IN_MILLISECONDS = 86400000;

class AddEditTicketCtrl {
    constructor($scope, EventTicket, ModalService, TimezoneService) {
        this._injected = {
            $scope,
            EventTicket,
            ModalService,
            TimezoneService,
        };

        this.vatDropDownList = VAT_DROPDOWN_LIST;
        this.addTicketForm = {};
        this.syncDates = true;
        let dateToday = new Date().setHours(0, 0, 0, 0);
        let startDate = dateToday - A_DAY_IN_MILLISECONDS;
        let endDate = dateToday + A_DAY_IN_MILLISECONDS;
        this.$onInit = () => {
            this.eventId = this.eventId;
            this.ticket.startDate = this.ticket.startDate || TimezoneService.fromLocalTime(startDate).getTime();
            this.ticket.endDate = this.ticket.endDate || TimezoneService.fromLocalTime(endDate).getTime();
        }

        ScopeFunctions.addToScope($scope);
    }

    createOrUpdate() {
        if (!this.addTicketForm.$valid) {
            return;
        }
        const { EventTicket, $scope, ModalService } = this._injected;
        $scope.startProgress();
        if (this.ticket.id) {
            EventTicket.update(
                this.eventId,
                this.ticket.id,
                this.ticket,
                function (ticket) {
                    this.updateCallback({ updatedTicket: ticket });
                    $scope.endProgress();
                }.bind(this),
                $scope.endWithAlert()
            );
        } else {
            EventTicket.create(
                this.eventId,
                this.ticket,
                function (ticket) {
                    this.updateCallback({ newTicket: ticket });
                    $scope.endProgress();
                }.bind(this),
                (error) => {
                    $scope.endProgress();
                    let data = {
                        message: error.data.localisedMessages[0].messageKey,
                    };

                    let customMessageModalData = customMessageModal;
                    customMessageModal.inputs = { data };

                    ModalService.showModal(customMessageModalData).then((modal) => {
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }
            );
        }
    }

    cancel() {
        this.cancelCallback({ ticket: this.ticket });
    }

    getVatAmount() {
        let vatAmount =
            UtilFunctions.isGreaterOrEqualToZero(this.ticket.price) &&
            UtilFunctions.isGreaterOrEqualToZero(this.ticket.vat)
                ? (this.ticket.price * this.ticket.vat) / 100
                : 0;
        return amountAndPriceService.round(parseFloat(vatAmount));
    }

    getVatAmountLocalisedWithCurrency() {
        let vatAmount = this.getVatAmount();
        let currency = this.ticket.currency || currencyUtil.getDefaultCurrency();
        return amountAndPriceService.getAmountLocalisedWithCurrency(vatAmount, currency, 2);
    }

    getPriceWithVatLocalisedWithCurrency() {
        let priceWithoutVat = UtilFunctions.isGreaterOrEqualToZero(this.ticket.price)
            ? parseFloat(this.ticket.price)
            : 0;
        let priceWithVat = priceWithoutVat + this.getVatAmount();
        let currency = this.ticket.currency || currencyUtil.getDefaultCurrency();
        return amountAndPriceService.getAmountLocalisedWithCurrency(priceWithVat, currency, 2);
    }
}
AddEditTicketCtrl.$inject = ['$scope', 'EventTicket', 'ModalService', 'TimezoneService'];
export default AddEditTicketCtrl;
