import _ from 'underscore';

class OgAutocompleteController {
    constructor($timeout, $scope) {
        this._injected = { $timeout, $scope };

        if (!this.minLength) {
            this.minLength = 0;
        }
        this.uniqueId = Math.random();

        $scope.$watch('ogAutocompleteCtrl.open', (newval) => {
            if (newval) {
                $timeout(() => {
                    let identifier = this.uniqueId + '_value';
                    var searchInput = document.getElementById(identifier);
                    searchInput.focus();
                });
            }
        });

        this.select = this.select.bind(this);
        this.getInitialValue = this.getInitialValue.bind(this);
    }

    getInitialValue() {
        if (!this.selected) {
            return;
        }

        return _.findWhere(this.list, { id: this.selected });
    }

    select(selected) {
        if (selected) {
            this.selected = selected.originalObject.id;
        } else {
            this.selected = null;
        }
    }
}
OgAutocompleteController.$inject = ['$timeout', '$scope'];
export default OgAutocompleteController;
