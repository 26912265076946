import profileSettingsModal from '../../modals/modals2.0/profile-settings-modal';
import EventDetailsData from 'src/app/core/existingServices/data_store/EventDetailsData';
import { ScopeFunctions } from 'src/app/utils/global/scopeFunctions'

(function () {
    'use strict';
    const HELP_DROPDOWN_MAP = { GUIDE: false, NOTIFICATION: false, PDF: true };
    angular.module('ogHeaderDirective').component('ogHeaderDirective', {
        templateUrl: './app/components/directives/header-navigation/og-header.view.html',
        bindings: {
            withBackGroundImg: '@withBackGroundImg',
            eventImgId: '<',
        },
        transclude: true,
        controller: [
            '$scope',
            'AuthenticationService',
            'EventImageService',
            '$http',
            '$location',
            'ModalService',
            '$resource',
            'EventGuideDataStore',
            '$translate',
            '$rootScope',
            'CustomerUser',
            '$timeout',
            '$state',
            'EventEnum',
            'ActiveTabService',
            'ClientService',
            'CommonFile',
            function ($scope,
                AuthenticationService,
                EventImageService,
                $http,
                $location,
                ModalService,
                $resource,
                EventGuideDataStore,
                $translate,
                $rootScope,
                CustomerUser,
                $timeout,
                $state,
                EventEnum,
                ActiveTabService,
                ClientService,
                CommonFile) {
                ScopeFunctions.addToScope($scope);
                var self = this;
                $scope.selectedContextId = null;
                $scope.viewprofileSettingsModal = false;

                this.$onInit = function () {
                    $scope.eventImgId = this.eventImgId;
                    $scope.withBackGroundImg = this.withBackGroundImg;
                    self.refreshUserData();
                }


                self.refreshUserData = function () {
                    $scope.startProgress({showContent: false});
                    $scope.userData = AuthenticationService.getUserData();
                    $scope.loggedUserStatus = AuthenticationService.getLoggedUserStatus();
                    $scope.updateProfilePictureSource();
                    $scope.clientName = ClientService.getClientName();
                    $scope.client = ClientService.get();
                    if ($scope.userData.logged) {
                        $scope.userGroups = AuthenticationService.getUserGroups();
                    }
                    if ($scope.userData && $scope.userData.userGroup) {
                        $scope.selectedContextId = $scope.userData.userGroup.id;
                    } else {
                        $scope.selectedContextId = null;
                    }

                    if ($scope.userData.prefLang && $scope.userData.prefLang !== $rootScope.langSelected) {
                        $rootScope.switchLang($scope.userData.prefLang, true);
                    } else {
                        var lang = $location.search().lang;
                        if (lang === null) {
                            $scope.switchToSv(true);
                        }
                    }
                    if ($scope.userData !== null) {
                        $scope.loggedUserEmail = $scope.userData.email;
                        $scope.loggedUserUsername = $scope.userData.username;
                    }
                    let _imageId = $scope.userData.userGroup ? $scope.userData.userGroup.imageId : null;
                    let _bannerImgId = AuthenticationService.getClientBannerImageId();
                    $scope.headerImgId = EventImageService.getEventImageId(
                        $scope.eventImgId,
                        _imageId,
                        _bannerImgId
                    );
                    if ($scope.eventImgId) {
                        $scope.url = 'api/v1/file/getFile/' + $scope.headerImgId;
                    } else if (_imageId) {
                        $scope.url = 'api/v1/file/getFile/' + $scope.headerImgId;
                    } else if (_bannerImgId) {
                        $scope.url = 'api/v1/file/getCommonFile/' + $scope.headerImgId;
                    } else {
                        $scope.url = 'assets/app/common/header-new.jpg';
                    }
                    $scope.url = CommonFile.getBaseUrl() + $scope.url;
                    $scope.endProgress();
                };

                $scope.$on(EventEnum.USER_GROUP_CHANGED_SILENT, self.refreshUserData);
                var profilePictureChangedEvent = $rootScope.$on(
                    EventEnum.USER_PROFILE_PICTURE_CHANGED,
                    function () {
                        $scope.updateProfilePictureSource();
                    }
                );

                var userGroupChangedCentralBooking = $rootScope.$on(
                    'user:group:changed:central:booking',
                    function () {
                        self.refreshUserData();
                    }
                );

                $scope.$on('event:banner:image:changed', function (event, data) {
                    $scope.headerImgId = data.bannerImageId;
                    $scope.url = CommonFile.getBaseUrl() + 'api/v1/file/getFile/' + $scope.headerImgId;
                });

                $scope.updateProfilePictureSource = function () {
                    $scope.profileImgSrc =
                        CommonFile.getBaseUrl() + 'api/v1/file/getProfilePicture/' + new Date().getTime();
                };

                function redirectToHome() {
                    $state.go('auth.login');
                }

                $scope.logout = function () {
                    AuthenticationService.logout().then(redirectToHome);
                };

                $scope.resendActivationEmail = function () {
                    $scope.data = {
                        email: $scope.loggedUserEmail,
                        username: $scope.loggedUserUsername,
                    };
                    $http
                        .post('api/v1/register/resendActivationEmail', $scope.data)
                        .then(function () {
                            $rootScope.dialog('alert', {message: 'dialog.activation.message.sent'})
                        }, $scope.endWithAlert);
                };

                $scope.switchToEn = function (guiOnly) {
                    $rootScope.switchLang('en_US', guiOnly);
                    $location.search('lang', null);
                    $scope.activeEn = 'active';
                    $scope.activeSv = '';
                    $scope.activeNb = '';
                    $scope.activeDk = '';
                    $scope.activeFi = '';
                };

                $scope.getCurrentLang = function () {
                    return $rootScope.langSelected;
                };

                $scope.switchToSv = function (guiOnly) {
                    $rootScope.switchLang('sv_SV', guiOnly);
                    $location.search('lang', null);
                    $scope.activeSv = 'active';
                    $scope.activeEn = '';
                    $scope.activeNb = '';
                    $scope.activeDk = '';
                    $scope.activeFi = '';
                };

                $scope.switchToNb = function (guiOnly) {
                    $rootScope.switchLang('nb_NB', guiOnly);
                    $location.search('lang', null);
                    $scope.activeSv = '';
                    $scope.activeEn = '';
                    $scope.activeNb = 'active';
                    $scope.activeDk = '';
                    $scope.activeFi = '';
                };

                $scope.switchToDa = function (guiOnly) {
                    $rootScope.switchLang('da_DK', guiOnly);
                    $location.search('lang', null);
                    $scope.activeSv = '';
                    $scope.activeEn = '';
                    $scope.activeNb = '';
                    $scope.activeDk = 'active';
                    $scope.activeFi = '';
                };

                $scope.switchToFi = function (guiOnly) {
                    $rootScope.switchLang('fi_FI', guiOnly);
                    $location.search('lang', null);
                    $scope.activeSv = '';
                    $scope.activeEn = '';
                    $scope.activeNb = '';
                    $scope.activeDk = 'active';
                };

                $scope.startGuide = function () {
                    EventGuideDataStore.setShowOnBoardingGuide(true);
                    $rootScope.$broadcast(EventEnum.EVENT_GUIDE_INTRO_START, {
                        skipNo4: false,
                        hasRequest: true,
                    });
                };

                $scope.getStartedNotification = function () {
                    EventGuideDataStore.setEventGuideNotification(true);
                    $rootScope.$broadcast(EventEnum.EVENT_GUIDE_GET_STARTED_NOTIFICATION_OPEN);
                };

                $scope.setActiveTabs = function (tab) {
                    ActiveTabService.setEventsTab(tab);
                };

                self.setHelpDropDownShowHide = function () {
                    let currentState = $state.current.name;
                    if (currentState === 'events') {
                        HELP_DROPDOWN_MAP.PDF = true;
                        HELP_DROPDOWN_MAP.GUIDE = false;
                        HELP_DROPDOWN_MAP.NOTIFICATION = false;
                    }
                    if (currentState == 'event.detail.overview') {
                        HELP_DROPDOWN_MAP.GUIDE = canStartGuide();
                        HELP_DROPDOWN_MAP.NOTIFICATION = true;
                    }
                };

                function canStartGuide() {
                    if (EventDetailsData.hasRequest()) {
                        let eventDetailPage = EventDetailsData.getEventDetails();
                        return eventDetailPage.requests[0].canAddNewSupplier;
                    }
                    return false;
                }

                $scope.getShowDropDown = function (type) {
                    if (HELP_DROPDOWN_MAP[type]) {
                        return true;
                    }
                    return false;
                };

                $scope.openEditProfileModal = function () {
                    if($scope.viewprofileSettingsModal) {
                        return;
                    }
                    $scope.viewprofileSettingsModal = true;
                    let data = {};

                    let profileSettingsModalData = profileSettingsModal;
                    profileSettingsModalData.inputs = { data };

                    ModalService.showModal(profileSettingsModalData).then((modal) => {
                        $scope.viewprofileSettingsModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                };

                $scope.openPresentationModal = function () {
                    let data = { openPresentationByDefault: 'PRESENTATION' };
                    let profileSettingsModalData = profileSettingsModal;
                    profileSettingsModalData.inputs = { data };

                    ModalService.showModal(profileSettingsModalData).then((modal) => {
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                };

                $scope.downloadFile = function (file) {
                    let link = document.createElement('a');
                    link.setAttribute('type', 'hidden');
                    link.href = 'assets/downloadable/' + file;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                };

                self.setHelpDropDownShowHide();

                $scope.$on('$destroy', profilePictureChangedEvent);
                $scope.$on('$destroy', userGroupChangedCentralBooking);
            },
        ],
    });
})();
