'use strict';
angular.module('core.existingServices').factory('Presentation', [
    '$resource',
    '$log',
    '$http',
    function ($resource, $log, $http) {
        var slideUpdateItemRest = $resource(
            'api/v1/presentation/:slideId/updateProperty',
            {slideId: '@slideId'},
            {
                updateProperty: {method: 'POST', isArray: false},
            }
        );

        var siteInfoItemRest = $resource(
            'api/v1/presentation/siteInfo/:slideId/updateInfo',
            {slideId: '@slideId'},
            {
                updateInfo: {method: 'POST', isArray: false},
            }
        );

        var confirmEmailInfoItemRest = $resource(
            'api/v1/presentation/confirmEmailInfo/:slideId/updateInfo',
            {slideId: '@slideId'},
            {
                updateInfo: {method: 'POST', isArray: false},
            }
        );

        var presentationRest = $resource(
            'api/v1/presentation/:presentationId',
            {presentationId: '@presentationId'},
            {
                list: {method: 'GET', isArray: true},
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: false},
                save: {method: 'POST', isArray: false},
            }
        );
        var presentationEventRest = $resource(
            'api/v1/presentation/event/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var presentationEventPresentationRest = $resource(
            'api/v1/event/presentation/:eventPresentationId/slide',
            {eventPresentationId: '@eventPresentationId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var presentationRequestRest = $resource(
            'api/v1/presentation/request/:requestId',
            {requestId: '@requestId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var presentationSupplierRest = $resource(
            'api/v1/presentation/supplier/:supplierId',
            {supplierId: '@supplierId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var presentationByParamsRest = $resource(
            'api/v1/presentation/byParams',
            {},
            {
                get: {method: 'POST', isArray: false},
            }
        );
        var presentationGeotagCategoryRest = $resource(
            'api/v1/presentation/supplier/category/geotag',
            {},
            {
                get: {method: 'POST', isArray: true},
            }
        );
        var offerChoosePresentationRest = $resource(
            'api/v1/presentation/addOrReplaceRequestOfferPresentation',
            {},
            {
                save: {method: 'POST', isArray: false},
            }
        );
        var deleteImageRest = $resource(
            'api/v1/presentation/deleteImage',
            {},
            {
                delete: {method: 'POST', isArray: false},
            }
        );

        var updatePresentationLogo = function (formData, successCallback, errorCallback) {
            $http
                .post('api/v1/presentation/updatePresentationLogo', formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };

        return {
            list: function (jsonObject, successCallback, errorCallback) {
                var resource = presentationRest;
                if (jsonObject.eventId) {
                    resource = presentationEventRest;
                }
                if (jsonObject.eventPresentationId) {
                    resource = presentationEventPresentationRest;
                }
                if (jsonObject.requestId) {
                    resource = presentationRequestRest;
                }
                resource.list(jsonObject, successCallback, errorCallback);
            },
            get: function (params, successCallback, errorCallback) {
                presentationByParamsRest.get({}, params, successCallback, errorCallback);
            }, //todo (SC) Instead uploadItem use updateItem
            uploadItem: function (slideId, itemType, value, successCallback, errorCallback) {
                slideUpdateItemRest.updateProperty(
                    {slideId: slideId},
                    {itemType: itemType, value: value},
                    successCallback,
                    errorCallback
                );
            },
            updateItem: function (slideId, jsonObject, successCallback, errorCallback) {
                slideUpdateItemRest.updateProperty(
                    {slideId: slideId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            uploadSiteInfoItem: function (slideId, itemType, value, successCallback, errorCallback) {
                siteInfoItemRest.updateInfo(
                    {slideId: slideId},
                    {itemType: itemType, value: value},
                    successCallback,
                    errorCallback
                );
            },

            uploadConfirmEmailInfoItem: function (slideId, itemType, value, successCallback, errorCallback) {
                confirmEmailInfoItemRest.updateInfo(
                    {slideId: slideId},
                    {itemType: itemType, value: value},
                    successCallback,
                    errorCallback
                );
            },

            getByGeotagAndCategory: function (jsonObject, successCallback, errorCallback) {
                presentationGeotagCategoryRest.get(jsonObject, successCallback, errorCallback);
            },
            getBySupplier: function (supplierId, successCallback, errorCallback) {
                presentationSupplierRest.list({supplierId: supplierId}, successCallback, errorCallback);
            },
            offerChoosePresentation: function (jsonObject, successCallback, errorCallback) {
                offerChoosePresentationRest.save(jsonObject, successCallback, errorCallback);
            },
            deleteImage: function (jsonObject, successCallback, errorCallback) {
                deleteImageRest.delete(jsonObject, successCallback, errorCallback);
            },
            updatePresentationLogo: function (formData, successCallback, errorCallback) {
                return updatePresentationLogo(formData, successCallback, errorCallback);
            },
            checkPictureCropDetails: function (imageurl) {
                if (!imageurl) {
                    return false;
                }
                if (!imageurl.coordinates[4] || !imageurl.coordinates[5]) {
                    return false;
                }
                return true;
            },
        };
    },
]);

