'use strict';
angular.module('core.existingServices').factory('EventPresentation', [
    '$http',
    '$resource',
    function ($http, $resource) {
        var eventPresentationRest = $resource(
            'api/v1/event/presentation/:id',
            {id: '@id'},
            {
                update: {method: 'PUT', isArray: false},
                save: {method: 'POST', isArray: false},
            }
        );
        var eventPresentationForInvitationRest = $resource(
            'api/v1/event/presentation/getInvitationPresentationForEvent/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'POST', isArray: false},
            }
        );
        var getSelectedQueriesAndInvitationsForEventRest = $resource(
            'api/v1/event/presentation/getSelectedQueriesAndInvitationsForEvent/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var eventPresentationEventRest = $resource(
            'api/v1/event/presentation/event/:eventId',
            {eventId: '@eventId'},
            {
                save: {method: 'POST', isArray: false},
            }
        );
        var eventPresentationBySlug = $resource(
            'api/v1/event/presentation/public/:urlSlug',
            {urlSlug: '@urlSlug'},
            {
                getData: {method: 'POST', isArray: false},
            }
        );
        var externalAccessBySlug = $resource(
            'api/v1/event/presentation/public/eaDetails/:urlSlug',
            {urlSlug: '@urlSlug'},
            {
                getDetails: {method: 'GET', isArray: false},
            }
        );
        var rest = function (restUrl, successCallback, errorCallback) {
            $http
                .get(restUrl, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };

        return {
            update: function (jsonObject, successCallback, errorCallback) {
                return eventPresentationRest.update(jsonObject, successCallback, errorCallback);
            },
            save: function (jsonObject, successCallback, errorCallback) {
                return eventPresentationEventRest.save(
                    {eventId: jsonObject.eventId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            getInvitationPresentationForEvent: function (params, successCallback, errorCallback) {
                return eventPresentationForInvitationRest.get(params, successCallback, errorCallback);
            },
            getSelectedQueriesAndInvitationsForEvent: function (params, successCallback, errorCallback) {
                return getSelectedQueriesAndInvitationsForEventRest.get(params, successCallback, errorCallback);
            },
            getPresentationPageByUrlSlug: function (jsonObject, successCallBack, errorCallBack) {
                return eventPresentationBySlug.getData(jsonObject, successCallBack, errorCallBack);
            },
            externalAccessBySlug: function (jsonObject, successCallBack, errorCallBack) {
                return externalAccessBySlug.getDetails(jsonObject, successCallBack, errorCallBack);
            },
            goForRegistrationFromPublicSite: function (urlSlug, successCallBack, errorCallBack) {
                //$http used here instead of $resource to get plain text String in response
                var uri = 'api/v1/event/presentation/public/goForRegistration/' + urlSlug;
                return rest(uri, successCallBack, errorCallBack);
            },
        };
    },
]);

