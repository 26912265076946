'use strict';

import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

// Register `link.expired` component, along with its associated controller and template
angular.module('account.confirm').component('accountConfirm', {
    templateUrl: './app/components/modules/auth/account_confirm/account_confirm.template.html',
    controller: ['$scope', '$http', '$rootScope', '$stateParams',
        function AccountConfirmController($scope, $http, $rootScope, $stateParams) {
            var vm = this;

            ScopeFunctions.addToScope($scope);
            vm.switchToEn = switchToEnFn;
            vm.switchToSv = switchToSvFn;
            vm.switchToNb = switchToNbFn;
            vm.switchToDa = switchToDaFn;
            vm.switchToFi = switchToFiFn;

            if ($stateParams.locale) {
                if ($stateParams.locale === 'en') {
                    vm.switchToEn();
                } else if ($stateParams.locale === 'sv') {
                    vm.switchToSv();
                } else if ($stateParams.locale === 'nb') {
                    vm.switchToNb();
                } else if ($stateParams.locale === 'da') {
                    vm.switchToDa();
                } else {
                    vm.switchToFi();
                }
            } else {
                vm.activeEn = $rootScope.langSelected === 'en_US' ? 'active' : '';
                vm.activeSv = $rootScope.langSelected === 'sv_SV' ? 'active' : '';
                vm.activeNb = $rootScope.langSelected === 'nb_Nb' ? 'active' : '';
                vm.activeDk = $rootScope.langSelected === 'da_DK' ? 'active' : '';
                vm.activeFi = $rootScope.langSelected === 'fi_FI' ? 'active' : '';
            }

            function switchToEnFn() {
                $rootScope.switchLang('en_US');
                vm.activeEn = 'active';
                vm.activeSv = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToSvFn() {
                $rootScope.switchLang('sv_SV');
                vm.activeSv = 'active';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToNbFn() {
                $rootScope.switchLang('nb_NB');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = 'active';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToDaFn() {
                $rootScope.switchLang('da_DK');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = 'active';
                vm.activeFi = '';
            }

            function switchToFiFn() {
                $rootScope.switchLang('fi_FI');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = 'active';
            }
        }
    ]
});