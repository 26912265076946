'use strict';
angular.module('core.existingServices').factory('Part', [
    '$resource',
    function ($resource) {
        var v1RequestPartRest = $resource(
            'api/v1/part/:partId/request/:requestId',
            {partId: '@partId', requestId: '@requestId'},
            {
                save: {method: 'POST', isArray: false},
                list: {method: 'GET', isArray: true},
                update: {method: 'PUT'},
                delete: {method: 'DELETE'},
            }
        );

        return {
            save: function (eventId, requestId, newPart, successCallback, errorCallback) {
                return v1RequestPartRest.save({requestId: requestId}, newPart, successCallback, errorCallback);
            },
            list: function (jsonObject, successCallback, errorCallback) {
                return v1RequestPartRest.list(jsonObject, successCallback, errorCallback);
            },
            get: function (jsonObject, successCallback, errorCallback) {
                return v1RequestPartRest.get(jsonObject, successCallback, errorCallback);
            },
            update: function (eventId, requestId, partId, jsonObject, successCallback, errorCallback) {
                return v1RequestPartRest.update(
                    {requestId: requestId, partId: partId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            delete: function (jsonObject, successCallback, errorCallback) {
                return v1RequestPartRest['delete'](jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

