(function () {
    'use strict';

    angular.module('ogTaskAdditionalInfo').directive('ogTaskAdditionalInfo', [
        'LinkService',
        '$routeParams',
        function (LinkService, $routeParams) {
            return {
                restrict: 'EA',
                replace: true,
                templateUrl: './app/components/directives/task_additional_info/og_task_additional_info.directive.view.html',
                scope: {
                    taskMessage: '=ogTaskAdditionalInfo',
                },
                link: function ($scope) {
                    $scope.$watch('taskMessage', function (taskMessage) {
                        if (taskMessage.links) {
                            if (taskMessage.links.Event) {
                                $scope.eventHref = LinkService.createHref(taskMessage.links.Event[0]);
                            }
                            if (taskMessage.links.RequestOffer) {
                                $scope.requestHref = LinkService.createHref(taskMessage.links.RequestOffer[0]);
                            }
                        }
                    });
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        if (!$routeParams.eventId) {
                            $scope.displayEventLink = true;
                        } else {
                            $scope.displayEventLink = false;
                        }
                    },
                ],
            };
        },
    ]);
})();
