(function () {
    'use strict';

    angular.module('ogFieldStatus').directive('ogFieldStatus', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                template: '<i ng-show="changed()" class="glyphicon glyphicon-pencil field-changed-icon"></i>',
                scope: {
                    field: '@ogFieldStatus',
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.changed = function () {
                            if (!$scope.$parent.hideChangedFieldIcon && $scope.$parent.fieldChanged) {
                                return $scope.$parent.fieldChanged($scope.field);
                            }
                            return false;
                        };
                    },
                ],
            };
        },
    ]);
})();
