'use strict';
import { OgModal } from "../../utils/global/ogModal";
import { ScopeFunctions } from "../../utils/global/scopeFunctions";
import CommonFunctions from '../../utils/CommonFunctionUtil';


angular.module('dialog').directive('ogAddCollaboratorsModal', [
    '$timeout',
    '$log',
    '$routeParams',
    '$rootScope',
    'CustomerUser',
    'Event',
    'ValidationService',
    'AuthenticationService',
    'UserGroup',
    function (
        $timeout,
        $log,
        $routeParams,
        $rootScope,
        CustomerUser,
        Event,
        ValidationService,
        AuthenticationService,
        UserGroup
    ) {
        return new OgModal({
            name: 'add:collaborators',
            type: 'custom',
            controller: function ($scope) {
                $scope.addUserByEmailForm = {};
                ScopeFunctions.addToScope($scope);
                $scope.initDialog = function (data) {
                    $scope.eventId = data.event.id;
                    $scope.event = data.event;
                    $scope.userAlreadyPending = false;
                    if ($scope.event.ownerGroup && $scope.event.ownerGroup.id) {
                        $scope.eventUserGroup = UserGroup.get($scope.event.ownerGroup.id);
                    }
                    $scope.searchInput = '';
                    $scope.selectedUserMap = {};
                    $scope.userEmail = null;
                    $scope.selectedUser = null;
                    $scope.refreshDialog();
                    $scope.addUserByEmailForm.addByEmailForm.$setUntouched();
                    $scope.showRemoveUserConfirmation = false;
                    $scope.isSharedUser = false;
                };

                $scope.refreshDialog = function () {
                    getSharedUsersList();
                    getPendingUsersList();
                    updateFreshAddedUser();
                };

                var updateFreshAddedUser = function () {
                    $scope.hasFreshAddedUser = !jQuery.isEmptyObject($scope.selectedUserMap);
                };

                $scope.clearDialog = function () {
                    ValidationService.clear('addByEmailForm');
                };

                function getSharedUsersList() {
                    Event.getUsers({ eventId: $scope.eventId, sharedOnly: true }, function (response) {
                        $scope.sharedUsers = response;
                    });
                }

                function getPendingUsersList() {
                    Event.getUsers({ eventId: $scope.eventId, pendingOnly: true }, function (response) {
                        $scope.pendingUsers = response;
                    });
                }

                $scope.confirmRemoveSharedUser = function (user) {
                    $scope.showRemoveUserConfirmation = true;
                    $scope.selectedUser = user;
                    $scope.isSharedUser = true;
                };

                $scope.confirmRemovePendingUser = function (user) {
                    $scope.showRemoveUserConfirmation = true;
                    $scope.selectedPendingUser = user;
                };

                $scope.removeSharedUser = function () {
                    if ($scope.selectedUser) {
                        $scope.startProgress();
                        Event.removeSharedUser(
                            { eventId: $scope.eventId, userId: $scope.selectedUser.id },
                            function () {
                                getSharedUsersList();
                                $scope.showRemoveUserConfirmation = false;
                                $scope.isSharedUser = false;
                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay()
                        );
                    }
                };
                $scope.removePendingUser = function () {
                    $scope.startProgress();
                    Event.removePendingUser(
                        { email: $scope.selectedPendingUser.email },
                        function () {
                            getPendingUsersList();
                            $scope.showRemoveUserConfirmation = false;
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay()
                    );
                };

                $scope.closeRemoveSharedUserDialog = function () {
                    $scope.selectedUser = undefined;
                    $scope.selectedPendingUser = undefined;
                    $scope.showRemoveUserConfirmation = false;
                    $scope.isSharedUser = false;
                };
                /*
      $scope.addUserByEmail = function() {
        $scope.userAlreadyPending= false;
        if (!$scope.addUserByEmailForm.addByEmailForm.$valid) {
          CommonFunctions.touchFormFields($scope.addUserByEmailForm.addByEmailForm);
          return;
        }
        angular.forEach($scope.pendingUsers, function(val){
          if($scope.userEmail == val.email){
            $scope.userAlreadyPending = true;
          }
        });


        CustomerUser.getByEmail({ email: $scope.userEmail, form: 'addByEmailForm' }, function(response) {
          if (response.id) {
            if (!$scope.selectedUserMap[response.id]) {
              $scope.selectedUserMap[response.id] = response;
            }
          } else {
            if (!$scope.selectedUserMap[$scope.userEmail]) {
              $scope.selectedUserMap[$scope.userEmail] =
              { name: $scope.userEmail, email: $scope.userEmail, id: null };
            }
          }
          $scope.userEmail = '';
          $scope.addUserByEmailForm.addByEmailForm.$setPristine();
          $scope.addUserByEmailForm.addByEmailForm.$setUntouched();
          updateFreshAddedUser();

        });

      };
*/
                $scope.$watch('userEmail', function () {
                    ValidationService.clear('addByEmailForm');
                });

                $scope.addUserToList = function (user) {
                    $scope.selectedUserMap[user.id] = user;
                };

                // $scope.removeUserFromList = function(user) {
                //   if (user.id) {
                //     delete $scope.selectedUserMap[user.id];
                //   } else {
                //     delete $scope.selectedUserMap[user.name];
                //   }
                //   updateFreshAddedUser();
                // };

                $scope.isAddUserButtonVisible = function () {
                    for (var prop in $scope.selectedUserMap) {
                        if ($scope.selectedUserMap.hasOwnProperty(prop)) {
                            return true;
                        }
                    }

                    return false;
                };
                /*
      $scope.addAllUsers = function() {
        var emails = [];
        angular.forEach($scope.selectedUserMap, function(value) {
          emails.push(value.email);
        });
        $scope.close();
        Event.share($scope.eventId, { emails: emails }, function() {
        }, $rootScope.alert);
      };
*/

                $scope.addUser = function (email) {
                    email = email.toLowerCase();
                    $scope.userAlreadyPending = false;
                    if (!$scope.addUserByEmailForm.addByEmailForm.$valid) {
                        CommonFunctions.touchFormFields($scope.addUserByEmailForm.addByEmailForm);
                        return;
                    }
                    angular.forEach($scope.pendingUsers, function (val) {
                        if (email === val.email) {
                            $scope.userAlreadyPending = true;
                        }
                    });
                    $scope.startProgress();
                    Event.share(
                        $scope.eventId,
                        { emails: [email] },
                        function () {
                            $scope.refreshDialog();
                            $scope.userEmail = null;
                            $scope.addUserByEmailForm.addByEmailForm.$setPristine();
                            $scope.addUserByEmailForm.addByEmailForm.$setUntouched();
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay()
                    );
                };
            },
        });
    },
]);

