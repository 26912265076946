'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogBookingTermsModal', [
    'GlobalOption',
    '$rootScope',
    function (GlobalOption, $rootScope) {
        return new OgModal({
            name: 'booking:terms',
            type: 'custom',
            controller: function ($scope) {
                $scope.initDialog = function (data) {
                    ScopeFunctions.addToScope($scope);
                    $scope.offer = data.offer;
                    $scope.clientName = data.clientName;
                        $scope.isUsingClientBillingAddressAndIsEL = data.isUsingClientBillingAddressAndIsEL;

                        if (
                            !$scope.bookingTermLanguage ||
                            ($scope.bookingTermLanguage && $scope.bookingTermLanguage === $rootScope.langSelected)
                        ) {
                            if (!$scope.offer.bookingTerms) {
                                loadBookingTerms();
                            }
                        }
                        if ($scope.bookingTermLanguage && $scope.bookingTermLanguage !== $rootScope.langSelected) {
                            loadBookingTerms();
                        }
                    };

                    function loadBookingTerms() {
                        $scope.startProgress();
                        GlobalOption.get(
                            'BOOKING_TERMS',
                            (resp) => {
                                $scope.bookingTermLanguage = $rootScope.langSelected;
                                $scope.offer.bookingTerms = resp[$rootScope.langSelected];
                                $scope.endProgress();
                            },
                            $scope.endWithError
                        );
                    }
                },
            });
        },
    ]);