/**
 * Created by kshitij on 11/15/17.
 */


'use strict';
angular.module('core.existingServices').factory('FortNoxConfig', [
    '$resource',
    function ($resource) {
        var fortNoxRest = $resource(
            'api/v1/fortNoxConfig/group/:groupId',
            {groupId: '@id'},
            {
                get: {method: 'GET', isArray: false},
                update: {method: 'POST', isArray: false},
            }
        );

        return {
            get: function (id, successCallback, errorCallback) {
                return fortNoxRest.get({groupId: id}, successCallback, errorCallback);
            },
            update: function (jsonObject, successCallback, errorCallback) {
                return fortNoxRest.update(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

