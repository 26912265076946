import controller from './og_autocomplete.controller';

(function () {
    'use strict';

    angular.module('ogAutocomplete').directive('ogAutocomplete', [
        function () {
            return {
                scope: {
                    placeholder: '@',
                    list: '=',
                    selected: '=',
                    open: '=',
                    inputClass: '@',
                    minLength: '@',
                    disabled: '=',
                    searchFor: '=',
                },
                templateUrl: './app/components/directives/autocomplete/og_autocomplete.directive.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogAutocompleteCtrl',
                bindToController: true,
            };
        },
    ]);
})();
