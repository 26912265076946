(function () {
    'use strict';
    angular.module('core.supplier').service('Supplier', [
        '$resource',
        '$http',
        function ($resource, $http) {
            var v1SearchRest = $resource(
                'api/v1/supplier/search',
                {},
                {
                    list: {method: 'POST', isArray: false},
                }
            );
            var listSupplierMapData = $resource(
                'api/v1/supplier/listSupplierMapData',
                {},
                {
                    list: {method: 'POST', isArray: false},
                }
            );
            var listSuppliersLocation = $resource(
                'api/v1/supplier/listSuppliersLocation',
                {},
                {
                    list: {method: 'POST', isArray: false},
                }
            );
            var getSupplierFromES = $resource(
                'api/v1/supplier/getSupplierFromES',
                {},
                {
                    get: {method: 'POST', isArray: false},
                }
            );
            var v1SupplierRest = $resource(
                'api/v1/supplier/:supplierId',
                {supplierId: '@supplierId'},
                {
                    save: {method: 'POST', isArray: false},
                    get: {method: 'GET', isArray: false},
                    update: {method: 'PUT'},
                }
            );
            var v1LoggedSupplierRest = $resource(
                'api/v1/supplier/logged',
                {},
                {
                    get: {method: 'GET', isArray: false},
                }
            );
            var v1SupplierJoinAddresses = $resource(
                'api/v1/supplier/:id/joinAddresses',
                {id: '@id'},
                {
                    do: {method: 'POST', isArray: false},
                }
            );
            var v1SupplierCreateBillingAddress = $resource(
                'api/v1/supplier/:id/createBillingAddress',
                {id: '@id'},
                {
                    do: {method: 'POST', isArray: false},
                }
            );
            var v1AddBillingAddress = $resource(
                'api/v1/supplier/:id/addBillingAddress',
                {id: '@id'},
                {
                    do: {method: 'POST', isArray: false},
                }
            );
            var v1AddOfficeAddress = $resource(
                'api/v1/supplier/:id/addOfficeAddress',
                {id: '@id'},
                {
                    do: {method: 'POST', isArray: false},
                }
            );
            var emailSupplierRest = $resource(
                'api/v1/supplier/findbyemail/',
                {},
                {
                    getByEmail: {method: 'POST', isArray: false},
                }
            );
            var supplierCapacityRest = $resource(
                'api/v1/supplier/capacityDetails/group/:groupId',
                {groupId: '@groupId'},
                {
                    update: {method: 'POST', isArray: false},
                }
            );
            var getSupplierCapacityDetails = $resource(
                'api/v1/supplier/getCapacityDetails/group/:groupId',
                {groupId: '@groupId'},
                {
                    get: {method: 'GET', isArray: false},
                }
            );
            var v1GetSupplierList = $resource(
                'api/v1/supplier/getSupplierDropdownList',
                {},
                {
                    get: {method: 'GET', isArray: false},
                }
            );
            var v1GetSupplierListForMap = $resource(
                'api/v1/supplier/getSupplierListForMap',
                {},
                {
                    get: {method: 'POST', isArray: false},
                }
            );
            var v1UpdateSupplierLogo = function (formData, successCallback, errorCallback) {
                $http
                    .post('api/v1/supplier/updateSupplierLogo', formData, {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': undefined},
                    })
                    .then(function (resp) {
                        successCallback(resp.data);
                    }, function (error) {
                        errorCallback(error);
                    });
            };

            return {
                save: function (eventId, requestId, newRequest, successCallback, errorCallback) {
                    return v1SupplierRest.save(
                        {eventId: eventId, requestId: requestId, offerId: 0},
                        newRequest,
                        successCallback,
                        errorCallback
                    );
                },
                search: function (jsonObject, successCallback, errorCallback) {
                    return v1SearchRest.list(jsonObject, successCallback, errorCallback);
                },
                listSupplierMapData: function (jsonObject, successCallback, errorCallback) {
                    return listSupplierMapData.list(jsonObject, successCallback, errorCallback);
                },
                listSuppliersLocation: function (jsonObject, successCallback, errorCallback) {
                    return listSuppliersLocation.list(jsonObject, successCallback, errorCallback);
                },
                getSupplierFromES: function (jsonObject, successCallback, errorCallback) {
                    return getSupplierFromES.get(jsonObject, successCallback, errorCallback);
                },
                get: function (jsonObject, successCallback, errorCallback) {
                    return v1SupplierRest.get(jsonObject, successCallback, errorCallback);
                },
                update: function (jsonObject, successCallback, errorCallback) {
                    return v1SupplierRest.update(
                        {supplierId: jsonObject.id},
                        jsonObject,
                        successCallback,
                        errorCallback
                    );
                },
                getByEmail: function (jsonObject, successCallback, errorCallback) {
                    return emailSupplierRest.getByEmail({}, jsonObject, successCallback, errorCallback);
                },
                getLoggedSupplier: function (successCallback, errorCallback) {
                    return v1LoggedSupplierRest.get({}, successCallback, errorCallback);
                },
                createBillingAddress: function (jsonObject, successCallback, errorCallback) {
                    return v1SupplierCreateBillingAddress.do(jsonObject, successCallback, errorCallback);
                },
                joinAddresses: function (jsonObject, successCallback, errorCallback) {
                    return v1SupplierJoinAddresses.do(jsonObject, successCallback, errorCallback);
                },
                addBillingAddress: function (id, jsonObject, successCallback, errorCallback) {
                    return v1AddBillingAddress.do({id: id}, jsonObject, successCallback, errorCallback);
                },
                addOfficeAddress: function (id, jsonObject, successCallback, errorCallback) {
                    return v1AddOfficeAddress.do({id: id}, jsonObject, successCallback, errorCallback);
                },
                updateCapacityDetails: function (groupId, jsonObject, successCallback, errorCallback) {
                    return supplierCapacityRest.update(
                        {groupId: groupId},
                        jsonObject,
                        successCallback,
                        errorCallback
                    );
                },
                getSupplierCapacityDetails: function (jsonObject, successCallback, errorCallback) {
                    return getSupplierCapacityDetails.get(jsonObject, successCallback, errorCallback);
                },
                getSupplierList: function (jsonObject, successCallback, errorCallback) {
                    return v1GetSupplierList.get(jsonObject, successCallback, errorCallback);
                },
                getSupplierListForMap: function (jsonObject, successCallback, errorCallback) {
                    return v1GetSupplierListForMap.get(jsonObject, successCallback, errorCallback);
                },
                updateSupplierLogo: function (formData, successCallback, errorCallback) {
                    return v1UpdateSupplierLogo(formData, successCallback, errorCallback);
                },
            };
        },
    ]);
})();
