'use strict';

class ogMatchHeightService {
    constructor() {
    }

    //takes all elements with a class, calculates the maximum height and applies it to all elements
    matchHeightByClass(className) {
        var maxHeight = Math.max.apply(
            null,
            $(className)
                .map(function () {
                    return $(this).height();
                })
                .get()
        );
        jQuery(className).css('min-height', maxHeight + 'px');
    }

    matchContentHeightWithSidebar() {
        this.resetContentHeight();
        this.matchHeightByClass('.content-match-height');
    }

    resetContentHeight() {
        jQuery('#main-content').css('min-height', jQuery('#main-content .container').height() + 'px');
        jQuery('#sidebar-content').css(
            'min-height',
            jQuery('#sidebar-content #content-sidebar-container').height() + 'px'
        );
    }
}

angular.module('core.existingServices').service('ogMatchHeightService', ogMatchHeightService);
