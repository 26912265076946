'use strict';
angular.module('core.existingServices').factory('OfferPartType', [
    '$resource',
    function ($resource) {
        var v1OfferPartTypeRest = $resource(
            'api/v1/offerPartType',
            {},
            {
                get: {method: 'GET', isArray: true},
            }
        );

        return {
            list: function (jsonObject, successCallback, errorCallback) {
                return v1OfferPartTypeRest.get(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

