(function () {
    'use strict';

    angular.module('ogDateTimeFormat').directive('ogDateTimeFormat', [
        '$filter',
        '$translate',
        '$timeout',
        '$rootScope',
        'TimezoneService',
        function ($filter, $translate, $timeout, $rootScope, TimezoneService) {
            function toAbsoluteDayTime(baseDate, relativeDateTime) {
                return new Date(baseDate + relativeDateTime.getTime());
            }

            return {
                restrict: 'EA',
                template: '<span>{{result}}</span>',
                replace: false,
                scope: {
                    dateTime: '=ogDateTimeFormat',
                    baseDate: '=ogBaseDate',
                    absolute: '=ogShowAbsoluteDate',
                    showTime: '=ogShowTime',
                    showDate: '=ogShowDate',
                },
                link: function ($scope) {
                    function update() {
                        var dateTime = $scope.dateTime;
                        if (dateTime === null) {
                            return;
                        }
                        dateTime = new Date(dateTime);

                        $scope.result = '';
                        // format date
                        if ($scope.absolute) {
                            if ($scope.baseDate) {
                                dateTime = toAbsoluteDayTime($scope.baseDate, dateTime);
                            }
                            dateTime = TimezoneService.toLocalTime(dateTime);
                            if ($scope.showDate) {
                                $scope.result = TimezoneService.formatDate(
                                    dateTime,
                                    $scope.languageSelected == 'en_US' ? 'MM/dd/yy' : 'yy-MM-dd'
                                );
                            }
                        } else {
                            if ($scope.showDate) {
                                $scope.result = TimezoneService.formatRelativeDay(dateTime);
                            }
                        }
                        // format time
                        if ($scope.showTime && TimezoneService.isTimeSet(dateTime, !$scope.absolute)) {
                            if ($scope.showDate) {
                                $scope.result += ' (';
                            }
                            $scope.result += TimezoneService.formatTime(dateTime, !$scope.absolute);
                            if ($scope.showDate) {
                                $scope.result += ')';
                            }
                        }
                        // format empty result
                        if ($scope.result === '') {
                            $scope.result = '- - : - -';
                        }
                    }

                    $scope.$watch('dateTime', function (val) {
                        if (val !== null) {
                            update();
                        }
                    });

                    $scope.$watch('absolute', function () {
                        update();
                    });

                    $scope.$watch('baseDate', function () {
                        update();
                    });

                    $scope.$watch('showTime', function () {
                        update();
                    });

                    $scope.$watch('showDate', function () {
                        update();
                    });

                    $scope.languageSelected = $rootScope.langSelected;

                    $scope.$on('lang:change', function (event, data) {
                        $scope.languageSelected = data.lang;
                        $timeout(function () {
                            update();
                        });
                    });
                },
            };
        },
    ]);
})();
