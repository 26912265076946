import { environment } from "../../../environments/environment"

'use strict';
angular.module('core.existingServices').factory('CommonFile', [
    '$resource',
    'ClientService',
    function ($resource, ClientService) {
        const BASE_URL = environment.apiUrl;
        let client = ClientService.get();
        let hostName = client.hostName;
        // console.log(BASE_URL, 'URL');
        return {
            getCommonFile: function (fileId) {
                if (fileId) {
                    return BASE_URL + 'api/v1/file/getCommonFile/' + fileId;
                }
            },
            getFile: function (fileId) {
                if (fileId) {
                    return BASE_URL + 'api/v1/file/getFile/' + fileId;
                }
            },
            getBaseUrl: function () {
                return BASE_URL;
            },
        };
    },
]);

