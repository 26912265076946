/**
 * Created by kshitij on 8/7/17.
 */
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';
import {UtilFunctions} from "../../../utils/global/utilFunctions";

class AccommodationCalendarCtrl {
    constructor($scope, $rootScope, EventEnum) {
        this._injected = {
            $scope,
            $rootScope,
            EventEnum,
        };

        ScopeFunctions.addToScope($scope);
    }

    openAccommodationModal(requestId, accommodationDay) {
        this.openAccommodationModalCallback({ requestId: requestId, accommodationDay: accommodationDay });
    }

    showAccommodationParts() {
        let display = true;
        if (this.isLast) {
            display =
                UtilFunctions.isDefinedAndNotNull(this.item.eventData[0].doubleRoom) ||
                UtilFunctions.isDefinedAndNotNull(this.item.eventData[0].singleRoom) ||
                UtilFunctions.isDefinedAndNotNull(this.item.eventData[0].otherAccommodations);
        }
        return display;
    }
}

AccommodationCalendarCtrl.$inject = ['$scope', '$rootScope', 'EventEnum'];
export default AccommodationCalendarCtrl;
