'use strict';
angular.module('core.supplierSlot').factory('SupplierSlot', [
    '$resource',
    function ($resource) {
        var v1listSlotsRest = $resource(
            'api/v1/supplierSlot/list',
            {supplierId: '@supplierId', partTypeCode: '@partTypeCode'},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        return {
            listSlots: function (supplierId, partTypeCode, successCallback, errorCallback) {
                return v1listSlotsRest.list(
                    {supplierId: supplierId, partTypeCode: partTypeCode},
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);
