import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

class SupplierResourceForm {
    constructor(
        $scope,
        $rootScope,
        $translate,
        FieldChangedSvc,
        FilledSupplierResourceField,
        DynamicFormUtilService,
        SupplierResources
    ) {
        this._injected = {
            $scope,
            $rootScope,
            $translate,
            FieldChangedSvc,
            FilledSupplierResourceField,
            DynamicFormUtilService,
            SupplierResources,
        };
        this.list = [];
        this.originalList = [];
        this.editResource = true;
        this.setImageAndDescription = false;
        this.setImageSectionOpen = false;
        //this.getList();
        ScopeFunctions.addToScope($scope);
        this.checkHasChanged = this.checkHasChanged.bind(this);

        $scope.$watch('supplierResourceFormCtrl.resourceData', (newValue, oldValue) => {
            if (newValue) {
                this.resource = newValue;
                this.getList(newValue.offerPartTypeNameCode);
            }
        });
    }

    getList(offerPartTypeNameCode) {
        const {
            FilledSupplierResourceField,
            $translate,
            $rootScope,
            $scope,
            DynamicFormUtilService,
            SupplierResources,
        } = this._injected;
        $scope.startProgress();
        SupplierResources.getResource(
            this.resourceData.id,
            (data) => {
                this.resourceName = data.name;
                this.resourceDescription = data.description;
            },
            $scope.endWithError()
        );
        if (offerPartTypeNameCode) {
            FilledSupplierResourceField.list(
                this.module,
                offerPartTypeNameCode,
                this.resource.id,
                this.supplierId,
                (resp) => {
                    this.list = resp;
                    angular.copy(resp, this.originalList);
                    angular.forEach(this.list, (list) => {
                        if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                            let _listValuesCode = list.listValueCodes;
                            let _listValues = $translate.instant(_listValuesCode);
                            let _fieldValue;
                            if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                                _fieldValue = false;
                            } else {
                                _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                            }
                            list.fieldValue = DynamicFormUtilService.getFieldValue(
                                _listValues,
                                $rootScope.langSelected,
                                _fieldValue
                            );
                        }
                        if (['ICON_RADIO', 'ICON_MULTI_CHECKBOX'].indexOf(list.type.name) > -1) {
                            let _iconList = list.iconList;
                            angular.forEach(_iconList, function (icon) {
                                icon.name = $translate.instant(icon.nameCode);
                            });
                        }
                    });
                    $scope.endProgress();
                },
                function (e) {
                    console.log('error ', e);
                    $scope.endWithError();
                }
            );
        }
    }

    save() {
        const { FilledSupplierResourceField, $scope, $translate, SupplierResources } = this._injected;
        $scope.startProgress();
        let _params = {
            resourceId: this.resourceData.id,
            supplierId: this.supplierId,
            resourceName: this.resourceName,
            resourceStatus: this.resourceData.resourceStatus.name,
            resourceDescription: this.resourceDescription,
            offerPartTypeNameCode: this.resourceData.offerPartTypeNameCode,
        };
        SupplierResources.updateResourceData(
            _params,
            (data) => {
                this.resourceName = data.name;
                this.resourceDescription = data.description;
                FilledSupplierResourceField.save(
                    this.resourceData.id,
                    this.supplierId,
                    this.list,
                    this.resourceData.commentByCreator,
                    (resp) => {
                        this.list = resp;
                        angular.forEach(this.list, (list) => {
                            if (['ICON_MULTI_CHECKBOX'].indexOf(list.type.name) > -1) {
                                let _iconList = list.iconList;
                                angular.forEach(_iconList, function (icon) {
                                    icon.name = $translate.instant(icon.nameCode);
                                });
                            }
                        });
                        this.goBack();
                        $scope.endProgress();
                    },
                    function (e) {
                        console.log('error ', e);
                        $scope.endWithErrorOverlay();
                    }
                );
            },
            $scope.endWithErrorOverlay()
        );
    }

    goBack() {
        this.cancelCallback();
    }

    imageSectionOpen(res) {
        this.setImageSectionOpen = res;
    }

    checkHasChanged(question, fieldValue) {
        let orig = _.find(this.originalList, (orgObj) => {
            return orgObj.id === question.id;
        });
        let _changed = orig && orig.fieldValue !== fieldValue;
        this.isChanged({ changed: _changed });
    }
}
SupplierResourceForm.$inject = [
    '$scope',
    '$rootScope',
    '$translate',
    'FieldChangedSvc',
    'FilledSupplierResourceField',
    'DynamicFormUtilService',
    'SupplierResources',
];
export default SupplierResourceForm;
