/**
 * Created by kshitij on 11/17/17.
 */
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    /* global angular */
    'use strict';

    angular.module('ogSupplierGeotagAndDescriptionPresentation').directive('ogSupplierGeotagAndDescriptionPresentation', [
        function () {
            return {
                templateUrl:
                    './app/components/directives/supplier_geotag_and_description_presentation/og_supplier_geotag_and_description_presentation.view.html',
                scope: {
                    supplierId: '=supplierId',
                    jsondata: '=jsondata',
                    defaultEditable: '=editable',
                    setDescriptionSection: '=imageAndDescriptionSection',
                    parent: '=parent',
                },
                controller: [
                    '$scope',
                    '$rootScope',
                    '$location',
                    '$sce',
                    '$log',
                    'Presentation',
                    'Supplier',
                    '$timeout',
                    'EventEnum',
                    'CommonPresentation',
                    'Translation',
                    function (
                        $scope,
                        $rootScope,
                        $location,
                        $sce,
                        $log,
                        Presentation,
                        Supplier,
                        $timeout,
                        EventEnum,
                        CommonPresentation,
                        Translation
                    ) {
                        ScopeFunctions.addToScope($scope);
                        $scope.enableDescriptionEdit = false;
                        $scope.descriptionLanguage = setLanguage($rootScope.langSelected);

                        $scope.$watch('jsondata', function (data) {
                            if (data) {
                                $scope.presentation = data;
                                $scope.presentation.description = getPresentationDescription($scope.presentation);
                                $scope.resetEditModes();
                                getSupplierData();
                            }
                        });

                        function setLanguage(rootLang) {
                            let lang;
                            switch (rootLang) {
                                case 'sv_SV':
                                    lang = 'SWEDISH';
                                    break;
                                case 'nb_NB':
                                    lang = 'NORWEGIAN';
                                    break;
                                case 'da_DK':
                                    lang = 'DANISH';
                                    break;
                                case 'fi_FI':
                                    lang = 'FINNISH';
                                    break;
                                default:
                                    lang = 'ENGLISH';
                            }
                            return lang;
                        }

                        function setDescription() {
                            let _localisedDescs = $scope.presentation.localisedDescriptions;
                            let _localDesc = _.find(_localisedDescs, (desc) => {
                                return desc.language === $scope.descriptionLanguage;
                            });

                            if (_localDesc) {
                                $scope.presentation.description = _localDesc.text;
                            } else {
                                $scope.presentation.description = '';
                            }
                        }

                        function getPresentationDescription(presentation) {
                            const lang = setLanguage($rootScope.langSelected);
                            const localisedDescriptions = presentation.localisedDescriptions;

                            let _localDesc = _.find(localisedDescriptions, (desc) => {
                                return desc.language === lang;
                            });

                            if (_localDesc) {
                                presentation.description = _localDesc.text;
                            }
                            return presentation.description;
                        }

                        $scope.$watch('defaultEditable', function (data) {
                            $scope.isEditable = data ? $scope.defaultEditable : false;
                        });

                        $scope.$watch('setDescriptionSection', function (data) {
                            if (!data) {
                                $scope.enableDescriptionEdit = false;
                            }
                        });

                        function getSupplierData() {
                            $scope.startProgress();
                            if ($scope.supplierId !== null) {
                                Supplier.get(
                                    { supplierId: $scope.supplierId },
                                    function (result) {
                                        $scope.supplier = result;
                                    },
                                    $scope.endWithErrorOverlay
                                );
                            }
                            $scope.endProgress();
                        }

                        $scope.resetEditModes = function () {
                            $scope.edit = {
                                description: false,
                                map: false,
                            };
                        };

                        $scope.toggleGlobalEditMode = function () {
                            if ($scope.isEditable) {
                                if ($scope.globalEditMode) {
                                    $scope.resetEditModes();
                                    $scope.uploadDescription();
                                }
                                $scope.globalEditMode = !$scope.globalEditMode;
                            }
                        };

                        $scope.setItemType = function (itemType) {
                            $scope.itemType = itemType;
                        };

                        $scope.enableDescriptionEditMode = function () {
                            $scope.enableDescriptionEdit = true;
                            setDescription();
                        };

                        $scope.errorCallback = function (error) {
                            $scope.error = error;
                            $scope.itemType = undefined;
                        };

                        $scope.parseUploadResponse = function (response) {
                            $scope.resetEditModes();
                            $scope.presentation = response;
                            $rootScope.$emit('update:supplier:presentation', { presentation: $scope.presentation });
                            if ($scope.itemType === 'mapURL') {
                                $scope.$broadcast('map/init', response.mapUrl);
                            }
                            $scope.itemType = undefined;
                        };

                        $scope.reset = function () {
                            $scope.presentation.useParent = true;
                            $scope.upload('useParent');
                        };

                        $scope.uploadDescription = function () {
                            $scope.startProgress();
                            setTimeout(() => {
                                $scope.upload('description', function () {
                                    $scope.enableDescriptionEdit = false;
                                    $scope.presentation.description = getPresentationDescription($scope.presentation);
                                    $scope.endProgress();
                                });
                            }, 500);
                        };

                        $scope.selectLanguage = function (lang) {
                            $scope.descriptionLanguage = lang;
                            setDescription();
                        };

                        $scope.trustAsHtml = function (string) {
                            return $sce.trustAsHtml(string);
                        };

                        $scope.uploadMap = function () {
                            $scope.startProgress('mapurl');
                            $scope.upload(
                                'mapUrl',
                                function () {
                                    $scope.enableDescriptionEdit = false;
                                    $scope.endProgress('mapurl');
                                },
                                $scope.endWithErrorOverlay
                            );
                        };

                        $scope.upload = function (itemType, successfn) {
                            $scope.itemType = itemType;
                            let _params = {
                                itemType: itemType,
                                value: $scope.presentation[itemType],
                                language: $scope.descriptionLanguage,
                            };
                            if ($scope.presentation[itemType] != null && $scope.presentation[itemType] != undefined) {
                                CommonPresentation.updateItem(
                                    $scope.presentation.id,
                                    _params,
                                    (resp) => {
                                        $scope.parseUploadResponse(resp);
                                        if (successfn) {
                                            successfn();
                                        }
                                    },
                                    $scope.endWithErrorOverlay
                                );
                            } else {
                                if (successfn) {
                                    successfn();
                                }
                            }
                        };

                        $scope.hasMapUrl = function () {
                            let noMapUrl = 'https://maps.google.com/maps?q=null%2C+null%2C+null';
                            return $scope.presentation && $scope.presentation.mapUrl != noMapUrl;
                        };
                    },
                ],
            };
        },
    ]);
})();
