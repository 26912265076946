'use strict';

angular.module('core.existingServices').factory('EventProgress', [
    '$resource',
    function ($resource) {
        var eventProgressRest = $resource(
            'api/v1/event/eventProgress/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: true},
                update: {method: 'PUT', isArray: true},
            }
        );

        return {
            getEventProgress: function (jsonObject, successCallback, errorCallback) {
                return eventProgressRest.get(jsonObject, successCallback, errorCallback);
            },
            markUnmarkStepAsComplete: function (jsonObject, successCallback, errorCallback) {
                return eventProgressRest.update(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

