'use strict';

import './budget_table/budget_table.module';
import './budget_table/budget_table.component';

import './client_selection/client_selection.module';
import './client_selection/client_selection.component';

import './markup/markup.module';
import './markup/markup.component';

// Define the `events` module
angular.module('budget', [
    'ngRoute',
    'markup',
    'client-selection',
    'budget-table'
]);