import resourcePresentationModal from '../../modals/modals2.0/resource-presentation';
import amountAndPriceCreatorService from '../../../utils/price_calculate/PriceCalculationCreatorUtil';
import amountAndPriceService from '../../../utils/price_calculate/PriceCalculationCreatorUtil';
import updatePartOptionMarkupModal from '../../modals/modals2.0/update_part_option_markup_value';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('ogMarkupRequestOfferpartOption').directive('ogMarkupRequestOfferpartOption', [
        '$log',
        '$routeParams',
        '$rootScope',
        '$timeout',
        '$translate',
        'ModalService',
        'RequestOfferPartOption',
        'GlobalConstants',
        'RequestOfferStatusUtils',
        'BudgetDataShareService',
        function (
            $log,
            $routeParams,
            $rootScope,
            $timeout,
            $translate,
            ModalService,
            RequestOfferPartOption,
            GlobalConstants,
            RequestOfferStatusUtils,
            BudgetDataShareService
        ) {
            return {
                restrict: 'EA',
                scope: {
                    offerWiseData: '=',
                    offerPart: '=',
                    offerPartOption: '=',
                    event: '=',
                    isModal: '=',
                    selectedCurrency: '=',
                },
                templateUrl:
                    './app/components/directives/markup-request-offer-part-option/og_markup_request_offerpart_option.view.html',
                controller: [
                    '$scope',
                    function ($scope) {
                        ScopeFunctions.addToScope($scope);
                        $scope.viewModal = false;
                        // $scope.selectedCurrency = $scope.offer.currency;
                        $scope.getPriceWithVat = amountAndPriceService.getPriceWithVat;
                        $scope.getTotalPrice = amountAndPriceService.getRequestPartOptionTotalPrice;
                        $scope.getAmountLocalisedWithCurrency = amountAndPriceService.getAmountLocalisedWithCurrency;
                        $scope.viewPriceLocalisedWithCurrency = amountAndPriceService.viewPriceLocalisedWithCurrency;
                        $scope.includeVat = amountAndPriceCreatorService.shouldIncludeVatOnPrice();
                        $scope.getCreatorPrice = amountAndPriceCreatorService.getRequestOfferPartOptionPrice;
                        $scope.getCustomerPrice = amountAndPriceService.getOptionCustomerPrice;
                        $scope.getMarkupFromPriceAndCustomerPriceWithCurrency =
                            amountAndPriceService.getMarkupFromPriceAndCustomerPriceWithCurrency;

                        $scope.viewAmountLocalisedWithCurrency = function (price, currency, digitsAfterDecimal = 0) {
                            return amountAndPriceService.viewPrice(price, currency, digitsAfterDecimal);
                        };

                        $scope.openResourcePresentation = function (resourceName, resourceId) {
                            if($scope.viewModal) {
                                return;
                            }
                            $scope.viewModal = true;
                            let data = {
                                resourceName: resourceName,
                                offerPartTypeNameCode: $scope.offerPart.nameCode,
                                resourceId: resourceId,
                                supplierId: $scope.supplierId,
                                layout: 'creator',
                            };
                            let resourcePresentationModalData = resourcePresentationModal;
                            resourcePresentationModalData.inputs = { data };
                            ModalService.showModal(resourcePresentationModalData).then((modal) => {
                                $scope.viewModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        };

                        $scope.openResourcePresentationPopover = function (resourceName, resourceId) {
                            $rootScope.$broadcast('part:option:open:presentation', {
                                resourceName: resourceName,
                                offerPartTypeNameCode: $scope.offerPart.nameCode,
                                resourceId: resourceId,
                            });
                        };

                        $scope.getFixedMarkupValue = function (markupValue, digitsAfterDecimal = 0) {
                            if (!markupValue) {
                                return 0;
                            }
                            return amountAndPriceService.round(markupValue).toFixed(digitsAfterDecimal);
                        };

                        $scope.isOfferInFromBookedStateAndROPNotSelected = function (offerPartOption, offerWiseData) {
                            return (
                                !offerPartOption.isSelected &&
                                offerWiseData &&
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(
                                    offerWiseData.offer.status.name
                                ) > -1
                            );
                        };

                        $scope.disableCheckbox = function (offerPartOption, offerWiseData) {
                            return (
                                offerWiseData &&
                                offerPartOption.isSelected &&
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(
                                    offerWiseData.offer.status.name
                                ) > -1
                            );
                        };

                        $scope.selectedValueChanged = function () {
                            $rootScope.$broadcast('selected.value.changed.markup.page', {
                                offerPart: $scope.offerPart,
                            });
                        };

                        $scope.setSelectedPartToEdit = function (part, requestId) {
                            let _selectedOffers = BudgetDataShareService.getSelectedOfferList();
                            let _selectedOffer = _selectedOffers[requestId];
                            let _selectedPartIds = _.chain(part.offerWiseData).pluck('id').value();
                            if (_selectedOffer.offerParts) {
                                vm.selectedPart = _.find(_selectedOffer.offerParts, (p) => {
                                    return _selectedPartIds.indexOf(p.id) > -1;
                                });
                            } else {
                                vm.selectedPart = null;
                            }
                        };

                        $scope.editRequestOfferPartOption = function (selectedPartOption) {
                            if($scope.viewModal) {
                                return;
                            }
                            $scope.viewModal = true;
                            let updatePartOptionMarkupModalData = updatePartOptionMarkupModal;
                            let _customerPrice = $scope.getCustomerPrice(
                                $scope.offerWiseData.offer,
                                selectedPartOption
                            );
                            let _data = {
                                partOption: selectedPartOption,
                                customerPrice: _customerPrice,
                                offer: $scope.offerWiseData.offer,
                                offerPart: $scope.offerPart,
                            };
                            updatePartOptionMarkupModalData.inputs = { data: _data };
                            ModalService.showModal(updatePartOptionMarkupModalData).then((modal) => {
                                $scope.viewModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        };

                        $scope.ifThereIsNoROPOptionNotSelected = function (offerWiseData) {
                            if (
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(
                                    offerWiseData.offer.status.name
                                ) > -1
                            ) {
                                // It's only for after or at BOOKED state.
                                if (!offerWiseData && !offerWiseData.offerPartOptions) {
                                    return;
                                }
                                let isThereAnyOptionSelected = false;
                                for (let offerPartOption of offerWiseData.offerPartOptions) {
                                    if (offerPartOption.isSelected) {
                                        isThereAnyOptionSelected = true;
                                        break;
                                    }
                                }
                                return !isThereAnyOptionSelected;
                            }
                            return false;
                        };

                        $scope.disableCheckbox = function (offerPartOption, offerWiseData) {
                            return (
                                offerWiseData &&
                                offerPartOption.isSelected &&
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(
                                    offerWiseData.offer.status.name
                                ) > -1
                            );
                        };
                    },
                ],
            };
        },
    ]);
})();
