'use strict';

angular.module('core.requestOfferPart').service('RequestOfferPart', [
    '$resource',
    function ($resource) {
        var v1RequestOfferPartOfferRest = $resource(
            'api/v1/requestOfferPart/requestOffer/:requestId',
            {requestId: '@requestId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        var v1RequestOfferPartRest = $resource(
            'api/v1/requestOfferPart/:requestOfferPartId',
            {requestOfferPartId: '@requestOfferPartId'},
            {
                update: {method: 'PUT', isArray: false},
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var v1RequestOfferPartAccept = $resource(
            'api/v1/requestOfferPart/accept/:requestOfferPartId',
            {requestOfferPartId: '@requestOfferPartId'},
            {
                accept: {method: 'POST', isArray: false},
            }
        );

        var v1RequestOfferPartDecline = $resource(
            'api/v1/requestOfferPart/decline/:requestOfferPartId',
            {requestOfferPartId: '@requestOfferPartId'},
            {
                decline: {method: 'POST', isArray: false},
            }
        );

        var v1RequestOfferPartTypes = $resource(
            'api/v1/requestOfferPart/type',
            {},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        var v1ApplySupplierActionsOnCreatorChangesRest = $resource(
            'api/v1/requestOfferPart/requestOffer/:requestOfferId/applySupplierActionsOnCreatorChanges',
            {requestOfferId: '@requestOfferId'},
            {
                update: {method: 'POST', isArray: true},
            }
        );

        var v1ApplyCreatorActionsOnSupplierChangesRest = $resource(
            'api/v1/requestOfferPart/requestOffer/:requestOfferId/applyCreatorActionsOnSupplierChanges',
            {requestOfferId: '@requestOfferId'},
            {
                update: {method: 'POST', isArray: true},
            }
        );

        var v1CreateByRequestOfferIdRest = $resource(
            'api/v1/requestOfferPart/create/requestOffer/:requestOfferId',
            {requestOfferId: '@requestOfferId'},
            {
                save: {method: 'POST', isArray: false},
            }
        );

        var v1UndoDeleteRequestOfferPart = $resource(
            'api/v1/requestOfferPart/undoDeleteRequestOfferPart/:requestOfferPartId',
            {requestOfferPartId: '@requestOfferPartId'},
            {
                undoDelete: {method: 'PUT', isArray: false},
            }
        );

        var v1AdjustAccommodationAmount = $resource(
            'api/v1/requestOfferPart/adjustAccommodationAmount/:eventId',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: true},
            }
        );
        var updatePartMarkupValueRest = $resource(
            'api/v1/requestOfferPart/updatePartMarkupValue/:partId',
            {partId: '@partId'},
            {
                put: {method: 'PUT', isArray: false},
            }
        );

        return {
            list: function (json, successCallback, errorCallback) {
                return v1RequestOfferPartOfferRest.list(json, successCallback, errorCallback);
            },
            delete: function (requestOfferPartId, successCallback, errorCallback) {
                return v1RequestOfferPartRest['delete'](
                    {requestOfferPartId: requestOfferPartId},
                    successCallback,
                    errorCallback
                );
            },
            update: function (requestOfferPartId, updateRequestOfferPart, successCallback, errorCallback) {
                return v1RequestOfferPartRest.update(
                    {requestOfferPartId: requestOfferPartId},
                    updateRequestOfferPart,
                    successCallback,
                    errorCallback
                );
            },
            accept: function (requestOfferPartId, data, successCallback, errorCallback) {
                return v1RequestOfferPartAccept.accept(
                    {requestOfferPartId: requestOfferPartId},
                    data,
                    successCallback,
                    errorCallback
                );
            },
            decline: function (requestOfferPartId, data, successCallback, errorCallback) {
                return v1RequestOfferPartDecline.decline(
                    {requestOfferPartId: requestOfferPartId},
                    data,
                    successCallback,
                    errorCallback
                );
            },
            types: function (successCallback, errorCallback) {
                return v1RequestOfferPartTypes.list({}, successCallback, errorCallback);
            },
            applySupplierActionsOnCreatorChanges: function (
                requestOfferId,
                offerPartsWithActions,
                successCallback,
                errorCallback
            ) {
                return v1ApplySupplierActionsOnCreatorChangesRest.update(
                    {requestOfferId: requestOfferId},
                    offerPartsWithActions,
                    successCallback,
                    errorCallback
                );
            },
            applyCreatorActionsOnSupplierChanges: function (
                requestOfferId,
                offerPartsWithActions,
                successCallback,
                errorCallback
            ) {
                return v1ApplyCreatorActionsOnSupplierChangesRest.update(
                    {requestOfferId: requestOfferId},
                    offerPartsWithActions,
                    successCallback,
                    errorCallback
                );
            },
            createByRequestOfferId: function (requestOfferId, data, successCallback, errorCallback) {
                return v1CreateByRequestOfferIdRest.save(
                    {requestOfferId: requestOfferId},
                    data,
                    successCallback,
                    errorCallback
                );
            },
            undoDelete: function (requestOfferPartId, successCallback, errorCallback) {
                return v1UndoDeleteRequestOfferPart.undoDelete(
                    {requestOfferPartId: requestOfferPartId},
                    successCallback,
                    errorCallback
                );
            },
            adjustAccommodationAmount: function (eventId, jsonObj, successCallback, errorCallback) {
                return v1AdjustAccommodationAmount.update(
                    {eventId: eventId},
                    jsonObj,
                    successCallback,
                    errorCallback
                );
            },
            updatePartMarkupValue: function (partId, data, successCallback, errorCallback) {
                return updatePartMarkupValueRest.put({partId: partId}, data, successCallback, errorCallback);
            },
            // for Karma tests
            getPartUri: v1RequestOfferPartOfferRest,
        };
    },
]);

