'use strict';
angular.module('core.supplierResourceAvailability').service('SupplierResourceAvailability', [
    '$resource',
    function ($resource) {
        var v1GetSupplierResourceAvailabilityForMonth = $resource(
            'api/v1/supplierResourceAvailability/:resourceId/getSupplierResourceAvailabilityForMonth',
            {resourceId: '@resourceId'},
            {
                list: {method: 'POST', isArray: true},
            }
        );

        var v1SaveSupplierResourceAvailability = $resource(
            'api/v1/supplierResourceAvailability/:resourceId/saveSupplierResourceAvailability',
            {resourceId: '@resourceId'},
            {
                put: {method: 'POST', isArray: true},
            }
        );

        var v1SaveSupplierResourceAvailabilityBetweenDates = $resource(
            'api/v1/supplierResourceAvailability/:resourceId/saveSupplierResourceAvailabilityBetweenDates',
            {resourceId: '@resourceId'},
            {
                put: {method: 'POST', isArray: true},
            }
        );

        return {
            getSupplierResourceAvailabilityForMonth: function (
                resourceId,
                jsonObject,
                successCallback,
                errorCallback
            ) {
                return v1GetSupplierResourceAvailabilityForMonth.list(
                    {resourceId: resourceId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            saveSupplierResourceAvailability: function (resourceId, jsonObject, successCallback, errorCallback) {
                return v1SaveSupplierResourceAvailability.put(
                    {resourceId: resourceId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            saveSupplierResourceAvailabilityBetweenDates: function (
                resourceId,
                jsonObject,
                successCallback,
                errorCallback
            ) {
                return v1SaveSupplierResourceAvailabilityBetweenDates.put(
                    {resourceId: resourceId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);
