import authRouteConfig from "./components/modules/auth/route";
import eventsConfig from "./components/modules/event/route";
import linkRouteConfig from "./components/modules/link/route";
import supplierRouteConfig from "./components/modules/supplier/route";
import eventSiteRouteConfig from "./components/modules/event_site/route";
import sharedRouteConfig from "./components/modules/shared/route";
import healthCheckRouteConfig from "./components/modules/healthcheck/route";
import showPageRouteConfig from "./components/modules/show_page/route";
import participantRouteConfig from "./components/modules/participant/route";

export default function (appModule) {
    const liveDomain = ["app.eventlogic.se"];
    const stripePubKey = "pk_live_w1z8EtwZrtUn8D8qPRRE0RJe";
    const stripePubKeyStaging = "pk_test_dX1K399N0oTYeGuj1iXyADt4";

    // Config
    appModule.config(function (
        $locationProvider,
        $httpProvider,
        StripeElementsProvider,
        $translateProvider,
        $sceDelegateProvider
    ) {
        if (liveDomain.indexOf(document.location.hostname) !== -1) {
            StripeElementsProvider.setAPIKey(stripePubKey);
        } else {
            StripeElementsProvider.setAPIKey(stripePubKeyStaging);
        }
        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        });
        $translateProvider.useLoader('CustomTranslationLoader');
        //$translateProvider.useSanitizeValueStrategy("sanitize");
        $httpProvider.interceptors.push("AuthInterceptor");
        $httpProvider.interceptors.push("ApiInterceptor");
        $sceDelegateProvider.resourceUrlWhitelist([
            // Allow same origin resource loads.
            'self',
            // Allow loading from our assets domain. **.
            'https://admin-qat.eventlogic.se/**',
            'https://admin-qat-bigtravel.eventlogic.se/**',
            'https://admin-qat-skanskamoten.eventlogic.se/**',
            'https://admin-uat.eventlogic.se/**',
            'https://admin-uat-skanskamoten.eventlogic.se/**',
            'https://admin-uat-bigtravel.eventlogic.se/**',
            'https://admin.eventlogic.se/**',
            'https://admin.bigtravel.se/**',
            'https://admin.skanskamoten.se/**',
            'http://app.localhost:8080/**',
        ]);
        angular.lowercase = angular.$$lowercase;
    });
    authRouteConfig(appModule);
    eventsConfig(appModule);
    linkRouteConfig(appModule);
    supplierRouteConfig(appModule);
    eventSiteRouteConfig(appModule);
    sharedRouteConfig(appModule);
    healthCheckRouteConfig(appModule);
    showPageRouteConfig(appModule);
    participantRouteConfig(appModule);
}