'use strict';

class EventGuideDataStore {
    constructor() {
        this.showOnBoardingGuide = false;
        this.showEventGuideNotification = false;
        this.showEventProgressGuide = false;
        this.showEventProgressGuideTooltip = false;
        /**
         * This is used when we want to open add supplier modal directly for a request
         * @type {boolean}
         */
        this.showAddSupplierModal = false;
    }

    setShowOnBoardingGuide(show) {
        this.showOnBoardingGuide = show;
    }

    setEventGuideNotification(show) {
        this.showEventGuideNotification = show;
    }

    setShowEventProgressGuide(show) {
        this.showEventProgressGuide = show;
    }

    setShowEventProgressGuideTooltip(show) {
        this.showEventProgressGuideTooltip = show;
    }

    setShowAddSupplierModal(show) {
        this.showAddSupplierModal = show;
    }
}

angular.module('core.existingServices').factory('EventGuideDataStore', EventGuideDataStore);

