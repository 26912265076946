import './login/login.module';
import './login/login.component';

import './register/register.module';
import './register/register.component';

import './account_confirm/account_confirm.module';
import './account_confirm/account_confirm.component';

import './change_password/change_password.module';
import './change_password/change_password.component';

import './first_time_login/first_time_login.module';
import './first_time_login/first_time_login.component';

import './register_invitation/register_invitation.module';
import './register_invitation/register_invitation.component';

export default function (appModule) {
    appModule.config(
        function ($stateProvider, $urlRouterProvider) {
            $urlRouterProvider.when('/auth/participant/confirm/:hash', '/participant/confirm/:hash');
            $stateProvider
                .state('auth', {
                    url: '',
                    abstract: true,
                    template: '<ui-view>',
                })
                .state('auth.login', {
                    url: '/?locale?eventId',
                    template: '<login></login>',
                    resolve: {
                        isLoggedIn: [
                            'AuthenticationService',
                            function (AuthenticationService) {
                                return AuthenticationService.validate(true); //set true to define, the check is from login view
                            },
                        ],
                    },
                })
                .state('auth.accountConfirm', {
                    url: '/auth/account_confirm?locale',
                    template: '<account-confirm></account-confirm>'
                })
                .state('auth.changePassword', {
                    url: '/auth/change_password/:hash',
                    template: '<change-password></change-password>'
                })
                .state('auth.oAuthRegister', { //Not in used atm
                    url: '/auth/oauth_register?state',
                    template: 'app/auth/oauth_register/oauth_register.view.html',
                    controller: 'OauthRegistrationController',
                    controllerAs: 'oAuthRegisterCtrl',
                })
                .state('auth.register', {
                    url: '/auth/register?locale?eventId',
                    template: '<register></register>'
                })
                .state('auth.invitationRegister', {
                    url: '/auth/register/:invitationId?showPresentation',
                    template: '<register-invitation></register-invitation>',
                })
                .state('auth.firstTimeLogin', {
                    url: '/auth/firstTimeLogin',
                    template: '<first-login></first-login>'
                })
                .state('auth.mfaVerify', {
                    url: '/auth/mfaVerify',
                    template: '<mfa-verify></mfa-verify>',
                })
                .state('auth.mfaEnable', {
                    url: '/auth/mfaEnable',
                    template: '<mfa-enable></mfa-enable>',
                });
        },
    )
}
