export default function (appModule) {
    appModule.run([
        'AuthenticationService',
        '$location',
        '$rootScope',
        '$state',
        'ClientService',
        function (AuthenticationService, $location, $rootScope, $state, ClientService) {
            $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
                if (error === 'NOT_LOGGED_IN') {
                    event.preventDefault(); // stop current execution
                    clearLocalToken();
                    var currentLocation = $location.url();
                    if (currentLocation != '/') {
                        let url = removeParam('refresh', currentLocation);
                        window.location.href = '/?location=' + encodeURIComponent(url);
                    }
                } else if (error === 'ACCESS_DENIED') {
                    console.log('ACCESS DENIED TO THIS PAGE ');
                } else if (error === 'PAGE_NOT_FOUND') {
                    $state.go('link.expired');
                }
            });

            $rootScope.$on('$stateChangeStart', function () {
                $rootScope.stateLoading = true;
                let token = $location.search().refresh;
                if (token) {
                    AuthenticationService.setRefreshToken(token);
                }
                ClientService.load();
                if (!AuthenticationService.isLogged()) {
                    AuthenticationService.loadUserData().then(() => {
                        redirectMe();
                    })
                } else {
                  //  redirectMe();
                }
            });

            function redirectMe() {
                if (AuthenticationService.isLogged() && $location.url() === '/') {
                    let checkForFirstTimeLogin = AuthenticationService.getUserData().checkForFirstTimeLogin;
                    if (checkForFirstTimeLogin) {
                        $state.go('auth.firstTimeLogin');
                    } else {
                        if (AuthenticationService.isLoggedSupplier()) {
                            $state.go('offers');
                        } else {
                            let userGroup = AuthenticationService.getUserData().userGroup;
                            if (userGroup && userGroup.childUserGroupCount > 0) {
                                $state.go('overview');
                            } else {
                                $state.go('events');
                            }
                        }
                    }
                }
            }

            $rootScope.$on('$stateChangeSuccess', function () {
                $rootScope.stateLoading = false;
                // analytics.page({src: 'app'}); //On Every Page change need to track the activity 307718212401858/715484362814741
            });

            $rootScope.$on('cancelStateChange', function () {
                $rootScope.stateLoading = false;
            });
        },
    ]);
}
