'use strict';

class EventImageService {
    constructor() {
    }

    getEventImageId(eventImageId, userGroupImageId, bannerImageId) {
        var imageId;
        if (eventImageId) {
            imageId = eventImageId;
        } else if (userGroupImageId) {
            imageId = userGroupImageId;
        } else if (bannerImageId) {
            imageId = bannerImageId;
        }
        return imageId;
    }
}

angular.module('core.existingServices').factory('EventImageService', EventImageService);

