import controller from './og.category.dropdown.controller';

(function () {
    'use strict';

    angular.module('ogCategoryDropDown').directive('ogCategoryDropDown', [
        function () {
            return {
                scope: {
                    placeholder: '@',
                    list: '=',
                    selected: '=',
                    open: '=',
                    isSubcategory: '=',
                    showRemoveTemplateConfirmation: '=',
                    categroy: '=',
                },
                templateUrl: './app/components/directives/category_drop_down/og.category.dropdown.view.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogCategoryDropDownCtrl',
                bindToController: true,
            };
        },
    ]);
})();
