(function () {
    'use strict';

    angular.module('ogTooltip').directive('ogTooltip', [
        '$compile',
        '$timeout',
        function ($compile, $timeout) {
            return {
                restrict: 'EA',
                transclude: true,
                scope: {
                    text: '=',
                    ogTooltipClass: '@',
                    hideDelay: '=',
                },
                replace: true,
                templateUrl: './app/components/directives/tooltip/tooltip.directive.view.html',

                link: function (scope, element, attrs) {
                    var delayTimeInMillis = scope.hideDelay ? scope.hideDelay : 350;
                    var tooltip = element.find('.tooltipster-base');
                    var trigger = element.find('.tooltipster-trigger');
                    if (Object.keys(tooltip).length && Object.keys(trigger).length) {
                        tooltip.hide();
                        trigger.addClass(attrs.ogTriggerType || 'info');
                        tooltip.width(attrs.ogTooltipWidth || 200);
                    }
                    if (attrs.ogTooltipMaxWidthClass) {
                        tooltip.addClass(attrs.ogTooltipMaxWidthClass);
                    }
                    scope.triggerType = attrs.ogTriggerType;
                    scope.position = attrs.ogPosition ? attrs.ogPosition : 'top'; //default setting set to top
                    scope.linkText = attrs.ogLinkText || '';
                    $('body').append(tooltip);
                    var hideTimer;
                    var triggerFn = function () {
                        var offset = trigger.offset();
                        switch (scope.position) {
                            case 'bottom':
                                offset.top += trigger.outerHeight() + 15;
                                offset.left -= Math.floor(tooltip.outerWidth() / 2 - trigger.outerWidth() / 2);
                                break;
                            case 'left':
                                offset.top -= Math.floor(tooltip.outerHeight() / 2 - trigger.outerHeight() / 2);
                                offset.left -= Math.floor(tooltip.outerWidth() + trigger.outerHeight() / 2);
                                break;
                            case 'right':
                                offset.top -= Math.floor(tooltip.outerHeight() / 2 - trigger.outerHeight() / 2);
                                offset.left += Math.floor(trigger.outerWidth() + trigger.outerHeight() / 2);
                                break;
                            case 'top':
                                offset.top -= tooltip.outerHeight() + 15;
                                offset.left -= Math.floor(tooltip.outerWidth() / 2 - trigger.outerWidth() / 2);
                                break;
                            case 'left-bottom':
                                offset.top += trigger.outerHeight() - 50;
                                offset.left -= Math.floor(tooltip.outerWidth() + trigger.outerHeight() / 2) - 10;
                                break;
                        }

                        tooltip.css(offset).show();
                        if (hideTimer) {
                            $timeout.cancel(hideTimer);
                        }
                    };

                    var hideFn = function () {
                        hideTimer = $timeout(function () {
                            tooltip.hide();
                        }, delayTimeInMillis);
                    };

                    scope.$watch('text', function () {
                        scope.linkText = scope.text ? scope.text : attrs.ogLinkText || '';
                    });

                    trigger.on('mouseenter', triggerFn).on('mouseleave', hideFn);

                    var eventStr = 'mouseleave click';

                    if (attrs.ogTooltipPreventHideOnClick) {
                        eventStr = 'mouseleave';
                    }

                    tooltip
                        .on(eventStr, function () {
                            tooltip.hide();
                        })
                        .on('mouseenter', function () {
                            if (hideTimer) {
                                $timeout.cancel(hideTimer);
                            }
                        });

                    element.on('$destroy', function () {
                        tooltip.remove();
                        trigger.off('click', triggerFn);
                    });
                },
            };
        },
    ]);
})();

