import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";
import {OgModal} from "../../../../../utils/global/ogModal";

(function () {
    'use strict';

    angular.module('task').component('task', {
        templateUrl: './app/components/modules/event/event_detail/task/task.view.html',
        controllerAs: 'taskCtrl',
        controller: [
            '$scope',
            '$rootScope',
            'AuthenticationService',
            'Activity',
            'Event',
            '$translate',
            '$stateParams',
            'CommonFunctions',
            function TasksController(
                $scope,
                $rootScope,
                AuthenticationService,
                Activity,
                Event,
                $translate,
                $stateParams,
                CommonFunctions
            ) {
                var vm = this;

                vm.clearAttachedFiles = clearAttachedFilesFn;
                vm.createNewTask = createNewTaskFn;
                vm.errorCallback = errorCallbackFn;
                vm.getFileLink = getFileLinkFn;
                vm.getSelectedTaskUsers = getSelectedTaskUsersFn;
                vm.getTasksPaginated = getTasksPaginatedFn;
                vm.init = initFn;
                vm.initSelectedTaskUsers = initSelectedTaskUsersFn;
                // vm.isChat = isChatFn;
                vm.isTaskComplete = isTaskCompleteFn;
                vm.loadNextPage = loadNextPageFn;
                vm.pageChanged = pageChangedFn;
                vm.paginatedActivityStreamCallback = paginatedActivityStreamCallbackFn;
                vm.processTask = processTaskFn;
                vm.processTaskMessage = processTaskMessageFn;
                vm.resetActivitiesList = resetActivitiesListFn;
                vm.taskTypeChanged = taskTypeChangedFn;
                vm.updateMessage = updateMessagefn;
                vm.visibleToSupplier = visibleToSupplierFn;
                vm.showByResponsibleUser = showByResponsibleUserFn;
                vm.taskForm = {};

                vm.showTaskFilterArray = [
                    { id: 0, tag: 'showMineTask' },
                    { id: 1, tag: 'showAllTask' },
                ];

                vm.showTaskTypeFilterArray = [
                    { id: 0, name: 'ALL', value: 'common.all' },
                    { id: 1, name: 'PENDING', value: 'common.pending' },
                    { id: 2, name: 'COMPLETED', value: 'common.complete' },
                ];

                vm.taskType = vm.showTaskTypeFilterArray[0];
                vm.showTaskFilter = vm.showTaskFilterArray[1].tag;
                vm.showMyTasks = false;
                /*    vm.taskType = 'ALL';*/
                vm.sharedEventUsers = [];
                vm.dateTimeControl = {};

                vm.numPerPage = 6;
                vm.currentPage = 1;
                ScopeFunctions.addToScope($scope);
                resetPaginationParams();
                vm.resetActivitiesList();
                vm.init();

                function clearAttachedFilesFn() {
                    vm.attachedFiles = { uploads: null, links: null };
                }

                function createNewTaskFn() {
                    if (vm.loggedUserStatus != 'VERIFIED') {
                        OgModal.open('alert', { message: 'dialog.user.not.activated' });
                        return;
                    }
                    if (!vm.taskForm.newTaskForm.$valid) {
                        CommonFunctions.touchFormFields(vm.taskForm.newTaskForm);
                        return;
                    }
                    if (vm.attachedFiles.maxSizeExceeded === true) {
                        return;
                    }
                    var messageBody = vm.messageBody;
                    if (tinyMCE && false) {
                        messageBody = tinyMCE.get('messageBody').getContent();
                    }
                    messageBody = cleanHTML(messageBody);
                    if (messageBody && messageBody.length > 0) {
                        var message = {
                            text: messageBody,
                            messageBody: messageBody,
                            privateMessage: vm.privateMessage,
                            isTask: true,
                            event: {
                                id: vm.eventId,
                            },
                            request: {
                                id: vm.requestId,
                            },
                            offer: {
                                id: vm.offerId,
                            },
                            attachedFiles: vm.attachedFiles.uploads,
                        };
                        message.form = 'TaskForm';
                        message.dueDate = vm.taskDueDate ? vm.taskDueDate : new Date().getTime();
                        message.responsibles = vm.getSelectedTaskUsers();
                        $scope.startProgress('tasks');
                        Activity.save(message, vm.processTask, $scope.endWithAlert());
                    }
                    // vm.clearAttachedFiles();
                }

                function cleanHTML(str) {
                    return str
                        .replace(/&/g, '&amp;')
                        .replace(/</g, '&lt;')
                        .replace(/>/g, '&gt;')
                        .replace(/"/g, '&quot;')
                        .replace(/'/g, '&#39;')
                        .replace(/\//g, '&#x2F;');
                }

                function errorCallbackFn(error) {
                    vm.error = error;
                    vm.streamSpinner = false;
                    vm.messageSpinner = false;
                }

                function getFileLinkFn(id) {
                    return 'api/v1/' + id;
                }

                function getSelectedTaskUsersFn() {
                    var selectedUsers = jQuery('#taskUserTagsPanel').select2('data');
                    var result = [];
                    angular.forEach(selectedUsers, function (item) {
                        result.push(vm.mapping[item.text]);
                    });
                    return result;
                }

                function getTasksPaginatedFn(responsibleId) {
                    var params = {
                        eventId: vm.eventId,
                        showMyTasks: vm.showMyTasks,
                        taskType: vm.taskType.name,
                        page: vm.params.offset,
                        pageSize: vm.numPerPage,
                        type: 'task',
                    };
                    if (vm.level != 'EVENT') {
                        params.requestId = vm.requestId;
                        params.offerId = vm.offerId;
                    }
                    if (!!responsibleId) {
                        params.responsibleId = responsibleId;
                    }
                    Activity.list(params, vm.paginatedActivityStreamCallback, vm.errorCallback);
                }

                function initFn() {
                    vm.clearAttachedFiles();
                    vm.messageSpinner = false;
                    vm.eventId = vm.eventId || $stateParams.eventId;
                    if (vm.eventId) {
                        getUserList();
                    }
                    updateLoggedUserStatus();
                    vm.getTasksPaginated();
                    vm.clearAll = false;
                }

                function isTaskCompleteFn(message) {
                    if (message.task.done) {
                        return true;
                    }
                    return false;
                }

                function loadNextPageFn() {
                    vm.getTasksPaginated();
                }

                function pageChangedFn() {
                    setPaginationParams();
                    vm.loadNextPage();
                }

                function paginatedActivityStreamCallbackFn(response) {
                    vm.activities = response.list;
                    vm.streamSpinner = false;
                    vm.messageSpinner = false;
                    vm.totalActivitiesCount = response.totalCount;
                    vm.page = vm.page + 1;
                    if (vm.activities.length !== 0 && vm.activities.length % 20 === 0) {
                        vm.restrictAutoLoad = true;
                    }
                    vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                }

                function processTaskFn() {
                    vm.messageBody = '';
                    vm.initSelectedTaskUsers();
                    vm.getTasksPaginated();
                    vm.clearAll = true;
                    vm.taskDueDate = new Date().getTime();
                    vm.taskForm.newTaskForm.$setPristine();
                    vm.taskForm.newTaskForm.$setUntouched();
                    $scope.endProgress('tasks');
                }

                function processTaskMessageFn(message) {
                    message.form = 'TaskForm';
                    message.done = false;
                    message.dueDate = vm.taskDueDate ? vm.taskDueDate : new Date().getTime();
                    message.responsibles = vm.getSelectedTaskUsers();
                    $scope.startProgress('tasks');
                    Activity.save(message, vm.processTask, $scope.endWithAlert());
                }

                function resetActivitiesListFn() {
                    resetPaginationParams();
                    vm.page = 0;
                    vm.restrictAutoLoad = false;
                    vm.totalActivitiesCount = -1; //default value
                    vm.activities = [];
                }

                function taskTypeChangedFn() {
                    vm.resetActivitiesList();
                    vm.loadNextPage();
                }

                function updateMessagefn(message) {
                    OgModal.open('task:edit', {
                        message: message,
                        changeCallback: vm.getTasksPaginated,
                    });
                }

                function resetPaginationParams() {
                    vm.params = { offset: 0, max: vm.numPerPage };
                }

                function setPaginationParams() {
                    vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                    vm.params.max = vm.numPerPage;
                }

                function updateLoggedUserStatus() {
                    vm.loggedUserStatus = AuthenticationService.getLoggedUserStatus();
                }

                $scope.$on('taskCtrl.showTaskFilter/changed', function (event, value) {
                    angular.forEach(vm.showTaskFilterArray, function (item) {
                        if (item.tag == value) {
                            vm.showMyTasks = value == 'showMineTask';
                            vm.resetActivitiesList();
                            vm.loadNextPage();
                        }
                    });
                });

                function showByResponsibleUserFn(id) {
                    vm.showMyTasks = true;
                    vm.showTaskFilter = '';
                    vm.resetActivitiesList();
                    vm.getTasksPaginated(id);
                }

                $scope.$on('activityStream/changed', function (/*event, value*/) {
                    vm.resetActivitiesList();
                    vm.loadNextPage();
                });

                $scope.$on('task/update', function (event, value) {
                    vm.updateTask(value);
                });

                function visibleToSupplierFn() {
                    return vm.isSupplier || (vm.offer && vm.offer.supplier);
                }

                function getUserList() {
                    vm.sharedEventUsers = $rootScope.sharedUsers;
                    $scope.startProgress('tasks');
                    Event.getUsers({ eventId: vm.eventId }, function (response) {
                        vm.sharedEventUsers = response;
                        vm.initSelectedTaskUsers();
                        $scope.endProgress('tasks');
                    });
                }

                function initSelectedTaskUsersFn() {
                    var tags = [];
                    vm.mapping = {};

                    angular.forEach(vm.sharedEventUsers, function (item) {
                        tags.push({ id: item.name, text: item.name });
                        vm.mapping[item.name] = item;
                    });
                    jQuery('#taskUserTagsPanel').select2({
                        data: tags,
                        allowClear: true,
                        multiple: true,
                        placeholder: $translate.instant('task.set.responsible'),
                    });
                    jQuery('#taskUserTagsPanel').val('').trigger('change');
                }

                $scope.$on('attachedFiles:clear', function (/*event, data*/) {
                    vm.clearAttachedFiles();
                });
            }
        ]
    });
})();
