class SupplierAgreementReferenceInfoModalCtrl {
    constructor($element, close, $scope, data, $rootScope) {
        this._injected = {
            $element,
            close,
            $scope,
            data,
            $rootScope,
        };
        this.$scope = $scope;
        this.contractInfo = data.contractInfo;
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

SupplierAgreementReferenceInfoModalCtrl.$inject = ['$element', 'close', '$scope', 'data', '$rootScope'];
export default SupplierAgreementReferenceInfoModalCtrl;
