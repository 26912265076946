import {ScopeFunctions} from "../../../utils/global/scopeFunctions";
import CommonFunctions from "../../../utils/CommonFunctionUtil";
(function () {
    /* global angular */
    /*This is used in editSupplier tab of edit profile view*/
    'use strict';
    angular.module('ogBillingAddress').directive('ogBillingAddress', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/billing_address/og.billing.address.view.html',
            replace: true,
            controllerAs: 'billingAddressCtrl',
            scope: {
                addressId: '=',
                addressType: '=addressType',
                refresh: '=?',
                userGroupId: '=',
                createCallback: '&',
            },
            bindToController: true,
            controller: [
                '$log',
                '$scope',
                '$rootScope',
                'UserGroup',
                'Address',
                'FieldChangedSvc',
                'CommonAddress',
                'UserGroupSettings',
                '$translate',
                'Client',
                function (
                    $log,
                    $scope,
                    $rootScope,
                    UserGroup,
                    Address,
                    FieldChangedSvc,
                    CommonAddress,
                    UserGroupSettings,
                    $translate,
                    Client
                ) {
                    var vm = this;
                    this.$onInit = function () {
                        vm = this;
                        vm.handleOnchanged = handleOnchangedFn;
                        vm.update = updateFn;
                        ScopeFunctions.addToScope($scope);
                        vm.form = 'billingAddressForm';
                        vm.showErrorSuccessMessages = false;
                        initFn();
                    }
                    var fieldChanged = FieldChangedSvc;

                    function handleOnchangedFn(modalName, currentValue) {
                        fieldChanged.checkOnChanged(modalName, currentValue);
                        vm.viewUpdateButton = fieldChanged.isFieldChanged();
                    }

                    $scope.$watch('billingAddressCtrl.refresh', (newval) => {
                        if (!!newval) {
                            initFn();
                        }
                    });

                    function getClientNameFn() {
                        Client.getName(
                            {},
                            (resp) => {
                                vm.clientName = resp.clientName.trim().replaceAll(' ', '').toLowerCase();
                                getBillingAddressPath(vm.clientName);
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                    }

                    function getBillingAddressPath(clientName) {
                        if(clientName) {
                            vm.billingAddressPath = './app/components/directives/billing_address/partials/' + clientName + '-billing-address-form.html';
                        }
                    }

                    function initFn() {
                        $scope.startProgress('billingAddress');
                        vm.roleGranted = false;
                        vm.markingLabel = $translate.instant('common.billing.address.marking');
                        getClientNameFn();
                        if (vm.userGroupId) {
                            UserGroupSettings.get(
                                { type: 'MARKING_LABEL', userGroupId: vm.userGroupId },
                                (resp) => {
                                    vm.markingLabel = resp[$rootScope.langSelected];
                                    if (!vm.markingLabel) {
                                        vm.markingLabel = $translate.instant('common.billing.address.marking');
                                    }
                                },
                                $scope.endWithErrorOverlay('billingAddress')
                            );
                            UserGroup.getAddress(
                                vm.userGroupId,
                                function (data) {
                                    vm.billingAddress = data.billingAddress;
                                    vm.useEventLogicBillingAddress = data.useEventLogicBillingAddress;
                                    vm.viewUpdateButton = false;
                                    fieldChanged.init(angular.copy(data));
                                    $scope.endProgress('billingAddress');
                                },
                                $scope.endWithErrorOverlay('billingAddress')
                            );
                        } else if (vm.addressId) {
                            CommonAddress.get(
                                { id: vm.addressId },
                                function (data) {
                                    vm.billingAddress = data;
                                    vm.viewUpdateButton = false;
                                    fieldChanged.init(angular.copy(data));
                                    $scope.endProgress('billingAddress');
                                },
                                $scope.endWithErrorOverlay('billingAddress')
                            );
                        }
                    }

                    function updateFn() {
                        if (!vm.billingAddressForm.$valid) {
                            CommonFunctions.touchFormFields(vm.billingAddressForm);
                            return;
                        }
                        vm.errorSuccessMessages = [];
                        $scope.startProgress();
                        if (vm.billingAddress.id) {
                            if (vm.addressId) {
                                CommonAddress.update(
                                    vm.billingAddress,
                                    function (address) {
                                        let _successMsg = {};
                                        _successMsg.messageKey = 'event.presentation.password.updated';
                                        _successMsg.type = 'SUCCESS';
                                        vm.errorSuccessMessages.push(_successMsg);
                                        vm.showErrorSuccessMessages = true;
                                        vm.billingAddress = address;
                                        vm.viewUpdateButton = false;
                                        fieldChanged.reset();
                                        fieldChanged.init(angular.copy(address));
                                        $rootScope.formSuccess(vm.form, 'dialog.address.update.success');
                                        $scope.endProgress();
                                    },
                                    $scope.endWithErrorOverlay('billingAddress')
                                );
                            } else if (vm.userGroupId) {
                                Address.update(
                                    vm.billingAddress,
                                    function (address) {
                                        let _successMsg = {};
                                        _successMsg.messageKey = 'event.presentation.password.updated';
                                        _successMsg.type = 'SUCCESS';
                                        vm.errorSuccessMessages.push(_successMsg);
                                        vm.showErrorSuccessMessages = true;
                                        vm.billingAddress = address;
                                        vm.viewUpdateButton = false;
                                        fieldChanged.reset();
                                        fieldChanged.init(angular.copy(address));
                                        $rootScope.formSuccess(vm.form, 'dialog.address.update.success');
                                        $scope.endProgress();
                                    },
                                    $scope.endWithErrorOverlay('billingAddress')
                                );
                            }
                        } else {
                            vm.createCallback({ form: vm.form, address: vm.billingAddress });
                            vm.viewUpdateButton = false;
                            $rootScope.formSuccess(vm.form, 'dialog.address.update.success');
                            $scope.endProgress();
                        }
                    }
                },
            ],
        };
    });
})();
