import controller from './og_auto_complete_multi_address.controller';

(function () {
    'use strict';

    angular.module('autoCompleteMultiAddress').directive('autoCompleteMultiAddress', [
        function () {
            return {
                scope: {
                    addresses: '=',
                    isDisabled: '=',
                },
                templateUrl:
                    './app/components/directives/autocomplete_multi_address/og_auto_complete_multi_address.view.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogAutocompleteMultiAddressCtrl',
                bindToController: true,
            };
        },
    ]);
})();
