import createEventPublicModalCtrl from '../../../../components/modals/modals2.0/create-event-public';
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';


'use strict';

angular.module('shared.create.event.express,booking').component('sharedCreateEventExpressBooking', {
    templateUrl: './app/components/modules/shared/create_event_expressBooking/create_event_expressBooking.view.html',
    controller: ['$scope', '$rootScope', '$stateParams', 'ModalService', '$timeout',

        function createEventExpressBookingControllerFn($scope, $rootScope, $stateParams, ModalService, $timeout) {
            var vm = this;
            vm.openCreateEventModal = openCreateEventModalFn;

            /*angular.element('body').addClass('fullscreen');*/
            ScopeFunctions.addToScope($scope);

            if ($stateParams.locale === 'en') {
                $rootScope.switchLang('en_US');
            } else if ($stateParams.locale === 'nb') {
                $rootScope.switchLang('nb_NB');
            } else {
                $rootScope.switchLang('sv_SV');
            }
            $timeout(() => {
                vm.openCreateEventModal();
            });

            function openCreateEventModalFn() {
                let createEventPublicModalCtrlData = createEventPublicModalCtrl;

                ModalService.showModal(createEventPublicModalCtrlData).then((modal) => {
                    modal.element.modal();
                    modal.close.then(() => {
                        console.log('modal is closed!');
                    });
                });
            }
        }]
})
