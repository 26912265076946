class OgEventDropdownController {
    constructor($timeout, $scope) {
        this._injected = { $timeout, $scope };

        if (!this.minLength) {
            this.minLength = 0;
        }

        this.uniqueId = Math.random();

        $scope.$watch('ogEventDropdownCtrl.open', (newval) => {
            if (newval) {
                $timeout(() => {
                    let identifier = this.uniqueId + '_value';
                    var searchInput = document.getElementById(identifier);
                    searchInput.focus();
                });
            }
        });

        this.select = this.select.bind(this);
        this.getInitialValue = this.getInitialValue.bind(this);
        this.remoteUrlRequest = this.remoteUrlRequest.bind(this);
    }

    remoteUrlRequest(str) {
        return { searchText: str };
    }

    getInitialValue() {
        if (!this.selected) {
            return;
        }
        return this.selected;
    }

    select(selected) {
        if (!selected) {
            return;
        }
        this.selected = selected.originalObject.name;
        this.selectAndSearch({ eventName: this.selected });
    }

    getEventFromSearchString($item) {
        this.$parent.ogEventDropdownCtrl.updateSelectedEventNameCallback({ eventName: $item });
    }
}

OgEventDropdownController.$inject = ['$timeout', '$scope'];
export default OgEventDropdownController;
