'use strict';
angular.module('core.task').service('Task', [
    '$resource',
    function ($resource) {
        var taskFromMsgRest = $resource(
            'api/v1/task/message/:chatMessageId',
            {chatMessageId: '@chatMessageId'},
            {
                create: {method: 'POST', isArray: false},
            }
        );
        var taskUpdateRest = $resource(
            'api/v1/task/message/:chatMessageId',
            {chatMessageId: '@chatMessageId'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );
        var taskListRest = $resource(
            'api/v1/task/event/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var taskGetRest = $resource(
            'api/v1/task/:taskId',
            {taskId: '@taskId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var pendingTaskRest = $resource(
            'api/v1/task/pendingTaskCount',
            {},
            {get: {method: 'GET', isArray: false}}
        );

        var update = function (chatMessageId, data, success, error) {
            return taskUpdateRest.update({chatMessageId: chatMessageId}, data, success, error);
        };

        return {
            save: function (chatMessageId, data, success, error) {
                if (data.id) {
                    return update(chatMessageId, data, success, error);
                }
                return taskFromMsgRest.create({chatMessageId: chatMessageId}, data, success, error);
            },
            update: update,
            list: function (eventId, success, error) {
                return taskListRest.list({eventId: eventId}, success, error);
            },
            get: function (taskId, success, error) {
                return taskGetRest.get({taskId: taskId}, success, error);
            },
            pendingTaskCount: function (success, error) {
                return pendingTaskRest.get({}, success, error);
            },
        };
    },
]);
