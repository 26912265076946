import controller from './og.email.setting.controller';

(function () {
    'use strict';
    angular.module('email.settings').component('ogEmailSettings', {
        bindings: {
            group: '<',
        },
        templateUrl: './app/components/directives/email_settings/og.email.settings.view.html',
        controller,
        controllerAs: 'emailSettingsCtrl',
    });
})();
