(function () {
    'use strict';

    angular.module('ogSupplierSearchResultTile').directive('ogSupplierSearchResultTile', [
        '$rootScope',
        '$compile',
        '$timeout',
        function ($rootScope, $compile, $timeout) {
            return {
                restrict: 'EA',
                transclude: true,
                /*jshint ignore:start */
                templateUrl:
                    './app/components/directives/supplier_search_result_tile/og_supplier_search_result_tile.directive.view.html',
                /*jshint ignore:end */
                controller: [
                    '$scope',
                    function ($scope) {
                        function init() {
                            initEllipsis();
                        }

                        function initEllipsis() {
                            $timeout(function () {
                                jQuery('.ellipsis').ellipsis();
                            }, 1);
                        }

                        $scope.$on('init:ellipsis', function () {
                            initEllipsis();
                        });

                        init();
                    },
                ],
            };
        },
    ]);
})();
