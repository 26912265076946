
import "./invitation/invitation.module";
import "./invitation/invitation.component";

import "./list/list.module";
import "./list/list.component";

import "./summary/summary.module";
import "./summary/summary.component";

import "./email_logs/emailLogs.module";
import "./email_logs/emailLogs.component";

import "./invitation/design-invitation/invitationDesign.module";
import "./invitation/design-invitation/invitationDesign.component";

import "./invitation/design-badge/dragDrop.module";
import "./invitation/design-badge/dragDrop.component";

import "./invitation/design-event-infosite/eventInfosite.module";
import "./invitation/design-event-infosite/eventInfosite.component";

import "./invitation/design-confirmation-email/confirmationEmailDesign.module";
import "./invitation/design-confirmation-email/confirmationEmailDesign.component";

import "./registration_form/registrationForm.module";
import "./registration_form/registrationForm.component";

import "./tickets/tickets.module";
import "./tickets/tickets.component";

import "./settings/settings.module";
import "./settings/settings.component";

// Define the `participants` module
angular.module('participants', [
    'participants.list',
    'participants.summary',
    'participants.email.logs',
    'participants.invitation',
    'participants.invitation.design',
    'participants.design.badge',
    'participants.design.event.infosite',
    'participants.confirm.email.design',
    'participants.registration.form',
    'participants.tickets',
    'participants.settings'
]);
