'use strict';
import './serverUrl/serverUrl.module';
import './serverUrl/serverUrl.service';

import './participant/participant.module';
import './participant/participant.service';

import './userInfoStatus/userInfoStatus.module';
import './userInfoStatus/userInfoStatus.service';

import './userGroupSetting/userGroupSetting.module';
import './userGroupSetting/userGroupSetting.service';


import './userGroup/userGroup.module';
import './userGroup/userGroup.service';

import './transport/transport.module';
import './transport/transport.service';

import './translation/translation.module';
import './translation/translation.service';
import './translation/translation.util.service';

import './validation/validation.module';
import './validation/validation.service';

import './toolTipValue/toolTipValue.module';
import './toolTipValue/toolTipValue.service';

import './timeZone/timeZone.module';
import './timeZone/timezone.service';

import './task/task.module';
import './task/task.service';

import './survey/survey.module';
import './survey/survey.service';

import './supplierSlot/supplierSlot.module';
import './supplierSlot/supplierSlot.service';

import './supplierResource/supplierResource.module';
import './supplierResource/supplierResource.service';

import './supplierResourceAvailability/supplierResourceAvailability.module';
import './supplierResourceAvailability/supplierResourceAvailability.service';

import './supplierDetail/supplierDetail.module';
import './supplierDetail/supplierDetail.service';

import './session/session.module';
import './session/session.service';

import './supplierDetailInfo/supplierDetailInfo.module';
import './supplierDetailInfo/supplierDetailInfo.service';

import './supplierCategory/supplierCategory.module';
import './supplierCategory/supplierCategory.service';

import './supplierAvailabilityCheck/supplierAvailabilityCheck.module';
import './supplierAvailabilityCheck/supplierAvailabilityCheck.service';

import './supplier/supplier.module';
import './supplier/supplier.service';

import './sellerInfo/sellerInfo.module';
import './sellerInfo/sellerInfo.service';


import './search/search.module';
import './search/search.service';

import './requestOffer/requestOffer.module';
import './requestOffer/requestOffer.service';
import './requestOffer/requestOfferUtil.service';

import './requestOfferPartOption/requestOfferPartOption.module';
import './requestOfferPartOption/requestOfferPartOption.service';

import './requestOfferPart/requestOfferPart.module';
import './requestOfferPart/requestOfferPart.service';

import './customFilter/customFilter.module';
import './customFilter/customFilter';

import './existingServices/existingServices.module';
import './existingServices/AccommodationSchedule'
import './existingServices/ActiveTabService'
import './existingServices/Activity'
import './existingServices/ActivityService'
import './existingServices/AdditionalInfoOptions'
import './existingServices/Address'
import './existingServices/AuthRegister'
import './existingServices/BadgeTemplate'
import './existingServices/BankDetails'
import './existingServices/BrowserDetectService'
import './existingServices/BudgetDataShareService'
import './existingServices/Category'
import './existingServices/ChatMessage'
import './existingServices/Client'
import './existingServices/ClientSelectedContent'
import './existingServices/CommonAddress'
import './existingServices/CommonPresentation'
import './existingServices/CustomerUser'
import './existingServices/CustomTranslationService'
import './existingServices/DateService'
import './existingServices/DynamicFormUtilService'
import './existingServices/Event'
import './existingServices/EventFinalDetails'
import './existingServices/EventFortNoxSetting'
import './existingServices/EventImageService'
import './existingServices/EventParticipantCustomQuery'
import './existingServices/EventPresentation'
import './existingServices/EventPresentationRo'
import './existingServices/EventProgress'
import './existingServices/EventSchedule'
import './existingServices/EventSite'
import './existingServices/EventSummary'
import './existingServices/EventTicket'
import './existingServices/existingServices.module'
import './existingServices/ExternalAccessService'
import './existingServices/FileService'
import './existingServices/FileShare'
import './existingServices/FilledRequestOfferAdditionalInfo'
import './existingServices/FilledRequestOfferPartComment'
import './existingServices/FilledSupplierResourceField'
import './existingServices/FortNoxConfig'
import './existingServices/GeoTag'
import './existingServices/GetCommonFile'
import './existingServices/GlobalOption'
import './existingServices/HashLinkHandler'
import './existingServices/HintService'
import './existingServices/LandingPage'
import './existingServices/LinkService'
import './existingServices/LocationService'
import './existingServices/Note'
import './existingServices/Notification'
import './existingServices/NotificationHandleService'
import './existingServices/NotificationService'
import './existingServices/OfferInfoStatus'
import './existingServices/OfferPartType'
import './existingServices/OfferPreview'
import './existingServices/ogMatchHeightService'
import './existingServices/ogScrollService'
import './existingServices/pagingService'
import './existingServices/Part'
import './existingServices/ParticipantActivity'
import './existingServices/ParticipantBasicQuery'
import './existingServices/ParticipantService'
import './existingServices/ParticipantSummary'
import './existingServices/ParticipantUtilService'
import './existingServices/Presentation'
import './existingServices/ProcessLog'
import './existingServices/RemoteFilepicker'
import './existingServices/Request'
import './existingServices/data_store/EventDetailsData'
import './existingServices/data_store/EventGuideDataStore'
import './existingServices/data_store/EventScheduleDataStore'
import './existingServices/data_store/ModalStatusStore'
import './existingServices/data_store/UserGroupData'
import './existingServices/data_store/ChatMessageCountStore'
import './existingServices/field_change/FieldChanged'


// New Added Modules Start

import './auth/authenticationService.module';
import './auth/authenticationService.service';

import './clientService/clientService.module';
import './clientService/clientService.service';

import './customerUser/customerUser.module';
import './customerUser/customerUser.service';

import './globalOption/globalOption.module';
import './globalOption/globalOption.service';

import './customTranslationService/customTranslationService.module';
import './customTranslationService/customTranslationService.service';

import './customTranslationLoaderService/customTranslationLoaderService.module';
import './customTranslationLoaderService/customTranslationLoaderService.service';

import './customTranslationLoaderService/customTranslationLoaderService.module';
import './customTranslationLoaderService/customTranslationLoaderService.service';

import './page/page.module';
import './page/page.service';

import './serverUrlService/serverUrlService.module';
import './serverUrlService/serverUrlService.service';

import './participant/participant.module';
import './participant/participant.service';

import './providers/configurationProvider.module';
import './providers/configurationProvider.service';
// New Added Modules End


import './phone/phone.module';
import './phone/phone.service';



// Define the `core` module
angular.module('core', [
    'core.phone',
    'core.authenticationService',
    'core.clientService',
    'core.customerUser',
    'core.globalOption',
    'core.customTranslationService',
    'core.customTranslationLoaderService',
    'core.page',
    'core.serverUrl',
    'core.participant',
    'core.userInfoStatus',
    'core.userGroupSetting',
    'core.userGroup',
    'core.transport',
    'core.translation',
    'core.validation',
    'core.toolTipValue',
    'core.timeZone',
    'core.task',
    'core.survey',
    'core.supplierSlot',
    'core.supplierResource',
    'core.supplierResourceAvailability',
    'core.supplierDetail',
    'core.session',
    'core.supplierDetailInfo',
    'core.supplierCategory',
    'core.supplier',
    'core.supplierAvailabilityCheck',
    'core.sellerInfo',
    'core.search',
    'core.requestOffer',
    'core.requestOfferPartOption',
    'core.requestOfferPart',
    'core.existingServices',
    'core.customFilter',
    'core.serverUrlService',
    'core.configuration'
]);
