'use strict';
import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";
import {OgModal} from "../../../../../utils/global/ogModal";
// Register `events` component, along with its associated controller and template
angular.module('participants').component('participants', {
    templateUrl: './app/components/modules/event/event_detail/participants/participants.view.html',
    controllerAs: 'participantsCtrl',
    controller: [
        '$scope',
        '$rootScope',
        '$state',
            '$stateParams',
            '$timeout',
            'EventEnum',
            'Event',
            'UserGroup',
            'ogScrollService',
            'ActiveTabService',
            'ClientService',
            function ParticipantsController(
                $scope,
                $rootScope,
                $state,
                $stateParams,
                $timeout,
                EventEnum,
                Event,
                UserGroup,
                ogScrollService,
                ActiveTabService,
                ClientService
            ) {
                var vm = this;
                vm.sendParticipantEmails = sendParticipantEmailsFn;
                vm.setParticipantActiveTab = setParticipantActiveTabFn;
                vm.getParticipantActiveTab = getParticipantActiveTabFn;
                vm.scrollToParticipantSettings = scrollToParticipantSettingsFn;
                vm.downloadFile = downloadFileFn;
                vm.init = initFn;

                vm.eventId = $stateParams.eventId;
                vm.isInvitationBlocked = null;
                vm.hasAnyRequestsSentInEvent = null;
                vm.alreadyAnsweredQuestions = null;

                ScopeFunctions.addToScope($scope);

                vm.init();

                function updateTab() {
                    switch ($state.current.name) {
                        case 'event.detail.participants.list':
                            ActiveTabService.setParticipantsSubTab('list');
                            break;
                        case 'event.detail.participants.summary':
                            ActiveTabService.setParticipantsSubTab('summary');
                            break;
                        case 'event.detail.participants.mails':
                            ActiveTabService.setParticipantsSubTab('mail_logs');
                            break;
                        case 'event.detail.participants.design_invitation':
                        case 'event.detail.participants.design_invitation.design-form':
                        case 'event.detail.participants.design_invitation.design-event-infosite':
                        case 'event.detail.participants.design_invitation.design-registration_form':
                            ActiveTabService.setParticipantsSubTab('design_invitation');
                            break;
                        case 'event.detail.participants.settings':
                            ActiveTabService.setParticipantsSubTab('settings');
                            break;
                        case 'event.detail.participants.tickets':
                            ActiveTabService.setParticipantsSubTab('tickets');
                            break;
                        case 'event.detail.participants.design-badge':
                            ActiveTabService.setParticipantsSubTab('design-badge');
                            break;
                    }
                }

                $rootScope.$on('keepSettingsFormActive', function (event, data) {
                    ActiveTabService.setParticipantsSubTab('settings');
                });

                function sendParticipantEmailsFn(type) {
                    OgModal.open('participant:email', {
                        type: type,
                        eventId: vm.eventId,
                        useSMS: vm.useSMS,
                        participantSettingsCallback: vm.scrollToParticipantSettings,
                        successCallback: function () {
                            $scope.$broadcast(EventEnum.PARTICIPANTS_STATUS_CHANGED, {});
                        },
                    });
                }

                function initFn() {
                    updateTab();
                    Event.getEventUsedSMSService(vm.eventId, function (data) {
                        vm.useSMS = data.useSMS;
                    });
                    vm.clientName = ClientService.getClientName();
                    getIsInvitationBlocked();
                }

                function setParticipantActiveTabFn(tab) {
                    ActiveTabService.setParticipantsSubTab(tab);
                }

                function getParticipantActiveTabFn() {
                    return ActiveTabService.getParticipantsSubTab();
                }

                function scrollToParticipantSettingsFn() {
                    vm.setParticipantActiveTab('settings');
                    $state.go('event.detail.participants.settings');
                    $timeout(function () {
                        ogScrollService.scrollToElement('notification_settings');
                    }, 0);
                }

                function downloadFileFn(file) {
                    let link = document.createElement('a');
                    link.setAttribute('type', 'hidden');
                    link.href = 'assets/downloadable/' + file;
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }

                $rootScope.$on('keepBadgeDesignActive', function (event) {
                    ActiveTabService.setParticipantsSubTab('design_invitation');
                });

                function getIsInvitationBlocked() {
                    Event.hasAnyRequestsSentInEventOrAlreadyAnswered(
                        vm.eventId,
                        function (data) {
                            vm.hasAnyRequestsSentInEvent = data.hasAnyRequestsSentInEvent;
                            vm.alreadyAnsweredQuestions = data.alreadyAnsweredQuestions;
                        },
                        $scope.endWithErrorOverlay
                    );

                    UserGroup.isInvitationBlocked(
                        { eventId: vm.eventId },
                        (response) => {
                            vm.isInvitationBlocked = response.isInvitationBlocked;
                        },
                        $scope.endWithErrorOverlay()
                    );
                }

                let participantInvitationScope = $rootScope.$on(
                    EventEnum.EVENT_PARTICIPANT_SEND_INVITATION_CHANGED,
                    function (event, data) {
                        vm.alreadyAnsweredQuestions = data;
                    }
                );
                let participantSoftBlockScope = $rootScope.$on(
                    EventEnum.EVENT_PARTICIPANT_SOFT_BLOCK_CHANGED,
                    function (event, data) {
                        vm.isInvitationBlocked = data;
                    }
                );
                $rootScope.$on('$destroy', participantInvitationScope);
                $rootScope.$on('$destroy', participantSoftBlockScope);
            }
        ]
    });
