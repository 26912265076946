/* global angular */
'use strict';

/**
 * Holds if any modal is open
 */
class ModalStatusStore {
    constructor() {
        this.modalOpenArray = [];
    }

    setModalOpen(modalName) {
        if (!this.isModalOpen(modalName)) {
            this.modalOpenArray.push(modalName);
        }
    }

    setModalClosed(modalName) {
        let index = this.modalOpenArray.indexOf(modalName);
        if (index > -1) {
            this.modalOpenArray.splice(index, 1);
        }
    }

    isModalOpen(modalName) {
        return this.modalOpenArray.indexOf(modalName) > -1;
    }
}

angular.module('core.existingServices').factory('ModalStatusStore', ModalStatusStore);

