/**
 * Created by sampurlachhantyal on 10/10/17.
 */

'use strict';

angular.module('core.existingServices').factory('FilledRequestOfferPartComment', [
    '$resource',
    function ($resource) {
        var dynamicQuestionResourceV1Rest = $resource(
            'api/v1/filledRequestOfferPartComment/list',
            {code: '@code', module: '@module', partId: '@partId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        var partResourceRest = $resource(
            'api/v1/filledRequestOfferPartComment/:partId',
            {partId: '@partId'},
            {
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: true},
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var requestWiseResourceRest = $resource(
            'api/v1/filledRequestOfferPartComment/listByRequest/:requestId',
            {requestId: '@requestId'},
            {
                list: {method: 'GET', isArray: false},
            }
        );

        return {
            list: function (module, code, partId, successCallback, errorCallback) {
                return dynamicQuestionResourceV1Rest.list(
                    {
                        code: code,
                        partId: partId,
                        module: module,
                    },
                    successCallback,
                    errorCallback
                );
            },
            listByRequest: function (requestId, successCallback, errorCallback) {
                return requestWiseResourceRest.list(
                    {
                        requestId: requestId,
                    },
                    successCallback,
                    errorCallback
                );
            },
            save: function (partId, data, commentByCreator, distanceVal, successCallback, errorCallback) {
                return partResourceRest.update(
                    {partId: partId},
                    {list: data, commentByCreator: commentByCreator, distance: distanceVal},
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);

