'use strict';
angular.module('core.timeZone').factory('TimezoneService', [
    '$rootScope',
    '$filter',
    '$translate',
    function ($rootScope, $filter, $translate) {
        ///////// Time zone handling methods ///////////

        var serverOffset = 0; // all times are stored as GMT
        var isEnabled = true;

        var offset = function (date) {
            if (!isEnabled) {
                return 0;
            }
            var localOffset = date.getTimezoneOffset();
            return serverOffset - localOffset;
        };

        var toLocalDate = function (date) {
            var m = moment(date);
            m.subtract('minutes', offset(date));
            return m.toDate();
        };

        var fromLocalDate = function (date) {
            var m = moment(date);
            m.add('minutes', offset(date));
            return m.toDate();
        };

        ///////// Date format methods ///////////

        var formatDate = function (date, format) {
            return moment(date).format(format);
        };

        //dont display 12 am if date has 0 hours set
        var customDateFormat = function (date) {
            var hour = date ? date.getHours() : 0;
            var minute = date ? date.getMinutes() : 0;
            var format,
                lang = $rootScope.langSelected;
            if (hour > 0 || minute > 5) {
                if (lang === 'sv_SV' || lang === 'nb_NB' || lang === 'da_DK' || lang === 'fi_FI') {
                    format = 'YYYY-MM-DD HH:mm';
                } else {
                    format = 'DD/MM/YYYY hh:mm a';
                }
            } else {
                if (lang === 'sv_SV' || lang === 'nb_NB' || lang === 'da_DK' || lang === 'fi_FI') {
                    format = 'YYYY-MM-DD';
                } else {
                    format = 'DD/MM/YYYY';
                }
            }
            return moment(date).format(format);
        };

        var formatRelativeDay = function (date) {
            var day;
            if (date >= 0) {
                day = moment(date).diff(0, 'days') + 1;
            } else {
                day = moment(date).diff(-1, 'days') - 1;
            }
            return $translate.instant('date.day') + ' ' + day;
        };

        var formatTime = function (date, resetZone) {
            var m = moment(date);
            if (resetZone) {
                m.zone(0);
            }
            return m.format('HH.mm');
        };

        var isTimeSet = function (date, resetZone) {
            var m = moment(date);
            if (resetZone) {
                m.zone(0);
            }
            return m.hours() !== 0 || m.minutes() !== 0;
        };

        var getDateInterval = function (params) {
            let from = new Date(params.fromDate);
            let to = new Date(params.toDate);

            if (from == null || to == null) {
                return;
            }
            if (params.absolute) {
                if (params.baseDate) {
                    from = toAbsoluteDayTime(params.baseDate, from);
                    to = toAbsoluteDayTime(params.baseDate, to);
                }
                from = this.toLocalTime(from);
                to = this.toLocalTime(to);
                if (from.getFullYear() === to.getFullYear() && from.getMonth() === to.getMonth()) {
                    if (from.getDate() === to.getDate()) {
                        if (params.isInvitation) {
                            return formatDayIntervalInvitation(params.showTime, from, to);
                        } else {
                            return formatDayInterval(params.showTime, from, to, params.hideMonthAndDay);
                        }
                    } else {
                        if (params.isInvitation) {
                            return formatMonthIntervalInvitation(params.showTime, from, to);
                        } else {
                            return formatMonthInterval(params.showTime, from, to);
                        }
                    }
                } else {
                    if (params.isInvitation) {
                        return formatFullIntervalInvitation(params.showTime, from, to);
                    } else {
                        return formatFullInterval(params.showTime, from, to);
                    }
                }
            } else {
                return formatRelativeInterval(from, to, params.showTime);
            }
        };

        var toAbsoluteDayTime = function (baseDate, relativeDateTime) {
            return new Date(baseDate + relativeDateTime.getTime());
        };

        var formatDayIntervalInvitation = function (displayTime, from, to) {
            return (
                formatDate(from, $rootScope.langSelected === 'en_US' ? 'MMM D' : 'D MMM') +
                formatTimeWithinADayInvitation(displayTime, from, to)
            );
        };

        var formatDayInterval = function (displayTime, from, to, hideMonthAndDay = false) {
            if (hideMonthAndDay) {
                return formatTimeWithinADay(displayTime, from, to).replace(', ', '');
            }
            return (
                formatDate(from, $rootScope.langSelected === 'en_US' ? 'MMM D' : 'D MMM') +
                formatTimeWithinADay(displayTime, from, to)
            );
        };

        var formatMonthIntervalInvitation = function (displayTime, from, to) {
            if ($rootScope.langSelected === 'en_US') {
                return (
                    formatDate(from, 'MMM D') +
                    formatSingleTime(displayTime, from) +
                    ' - <br/> ' +
                    formatDate(to, 'MMM D') +
                    formatSingleTime(displayTime, to)
                );
            } else {
                return (
                    formatDate(from, 'D MMM') +
                    formatSingleTime(displayTime, from) +
                    ' - <br/> ' +
                    formatDate(to, 'D MMM') +
                    formatSingleTime(displayTime, to)
                );
            }
        };

        var formatMonthInterval = function (displayTime, from, to) {
            if ($rootScope.langSelected === 'en_US') {
                return (
                    formatDate(from, 'MMM D') +
                    formatSingleTime(displayTime, from) +
                    ' - ' +
                    formatDate(to, 'MMM D') +
                    formatSingleTime(displayTime, to)
                );
            } else {
                return (
                    formatDate(from, 'D MMM') +
                    formatSingleTime(displayTime, from) +
                    ' - ' +
                    formatDate(to, 'D MMM') +
                    formatSingleTime(displayTime, to)
                );
            }
        };

        var formatFullIntervalInvitation = function (displayTime, from, to) {
            return (
                formatDate(from, $rootScope.langSelected === 'en_US' ? 'MMM D' : 'D MMM') +
                formatSingleTime(displayTime, from) +
                ' - <br/>' +
                formatDate(to, $rootScope.langSelected === 'en_US' ? 'MMM D' : 'D MMM') +
                formatSingleTime(displayTime, to)
            );
        };

        var formatFullInterval = function (displayTime, from, to) {
            return (
                formatDate(from, $rootScope.langSelected === 'en_US' ? 'MMM D' : 'D MMM') +
                formatSingleTime(displayTime, from) +
                ' - ' +
                formatDate(to, $rootScope.langSelected === 'en_US' ? 'MMM D' : 'D MMM') +
                formatSingleTime(displayTime, to)
            );
        };

        var formatRelativeInterval = function (from, to, displayTime) {
            var dayFrom = formatRelativeDay(from);
            var dayTo = formatRelativeDay(to);
            var result = dayFrom;
            if (dayFrom !== dayTo) {
                if (displayTime && isTimeSet(from, true)) {
                    result += ' ' + formatTime(from, true);
                }
                result += ' - ' + dayTo;
                if (displayTime && isTimeSet(to, true)) {
                    result += ' ' + formatTime(to, true);
                }
            } else {
                result += formatTimeWithinADay(displayTime, from, to, true);
            }
            return result;
        };

        var formatSingleTime = function (displayTime, time) {
            if (displayTime && isTimeSet(time)) {
                return ', ' + formatTime(time);
            }
            return '';
        };

        var formatTimeWithinADayInvitation = function (displayTime, from, to, resetZone) {
            var result = '';
            if (displayTime) {
                if (isTimeSet(from, resetZone) && isTimeSet(to, resetZone)) {
                    result += ', <br/>' + formatTime(from, resetZone);
                    if (from.getTime() !== to.getTime()) {
                        result += ' - ' + formatTime(to, resetZone);
                    }
                } else if (isTimeSet(from, resetZone)) {
                    result += ' ' + $translate.instant('date.time.from') + ' ' + formatTime(from, resetZone);
                } else if (isTimeSet(to, resetZone)) {
                    result += ' ' + $translate.instant('date.time.to') + ' ' + formatTime(to, resetZone);
                }
            }
            return result;
        };

        var formatTimeWithinADay = function (displayTime, from, to, resetZone) {
            var result = '';
            if (displayTime) {
                if (isTimeSet(from, resetZone) && isTimeSet(to, resetZone)) {
                    result += ', ' + formatTime(from, resetZone);
                    if (from.getTime() !== to.getTime()) {
                        result += ' - ' + formatTime(to, resetZone);
                    }
                } else if (isTimeSet(from, resetZone)) {
                    result += ' ' + $translate.instant('date.time.from') + ' ' + formatTime(from, resetZone);
                } else if (isTimeSet(to, resetZone)) {
                    result += ' ' + $translate.instant('date.time.to') + ' ' + formatTime(to, resetZone);
                }
            }
            return result;
        };

        return {
            toLocalTime: function (time) {
                if (time instanceof Date) {
                    return toLocalDate(time);
                } else {
                    return toLocalDate(new Date(time));
                }
            },

            fromLocalTime: function (time) {
                if (time instanceof Date) {
                    return fromLocalDate(time);
                } else {
                    return fromLocalDate(new Date(time));
                }
            },

            enable: function (enabled) {
                isEnabled = enabled;
            },

            formatDate: formatDate,
            customDateFormat: customDateFormat,
            formatRelativeDay: formatRelativeDay,
            formatTime: formatTime,
            isTimeSet: isTimeSet,
            getDateInterval: getDateInterval,
        };
    },
]);
