/**
 * Created by sampurlachhantyal on 10/10/17.
 */

'use strict';

angular.module('core.existingServices').factory('FilledSupplierResourceField', [
    '$resource',
    function ($resource) {
        var dynamicQuestionResourceV1Rest = $resource(
            'api/v1/filledSupplierResourceField/list',
            {code: '@code', module: '@module', resourceId: '@resourceId', supplierId: '@supplierId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        var partResourceRest = $resource(
            'api/v1/filledSupplierResourceField/:resourceId',
            {resourceId: '@resourceId'},
            {
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: true},
                delete: {method: 'DELETE', isArray: false},
            }
        );

        return {
            list: function (module, code, resourceId, supplierId, successCallback, errorCallback) {
                return dynamicQuestionResourceV1Rest.list(
                    {
                        code: code,
                        resourceId: resourceId,
                        module: module,
                        supplierId: supplierId,
                    },
                    successCallback,
                    errorCallback
                );
            },
            save: function (resourceId, supplierId, data, commentByCreator, successCallback, errorCallback) {
                return partResourceRest.update(
                    {resourceId: resourceId},
                    {list: data, commentByCreator: commentByCreator, supplierId: supplierId},
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);

