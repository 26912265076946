import controller from './og_offer_additional_info.controller';

(function () {
    'use strict';
    angular.module('ogOfferAdditionalInfo').directive('ogOfferAdditionalInfo', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    offer: '=',
                    category: '=',
                    list: '=listValue',
                    module: '@module',
                    isCreatorLabel: '=',
                    partCommentDataList: '=',
                    showUpdatedChanges: '=',
                    isThereConference: '=',
                    isChanged: '&',
                },
                templateUrl: './app/components/directives/offer_additional_info/og_offer_additional_info.view.html',
                controller,
                controllerAs: 'offerAdditionalInfoCtrl',
                bindToController: true,
            };
        },
    ]);
})();
