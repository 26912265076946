import controller from '../auto-complete-address-searchbox/og_auto_complete_address_searchbox.controller';

(function () {
    'use strict';
    //todo(SC) need to add extra params eg place holder, title etc but for this time we are just using it for supplier search
    angular.module('auto.complete.search.box').component('autoCompleteSearchBox', {
        bindings: {
            address: '=',
            addressChangeCallback: '&',
        },
        templateUrl:
            './app/components/directives/auto-complete-address-searchbox/og_auto_complete_address_searchbox.directive.view.html',
        controller,
        controllerAs: 'ogAutocompleteAddressSearchBoxCtrl',
    });
})();
