'use strict';
import TicketsCtrl from './tickets.controller';

// Register `participants.tickets` component, along with its associated controller and template
angular.
    module('participants.tickets').
    component('participanttickets', {
        templateUrl: './app/components/modules/event/event_detail/participants/tickets/tickets.view.html',
        controllerAs: 'ticketsCtrl',
        controller: TicketsCtrl
    });
