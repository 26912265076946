import controller from './og_offer_status_button.controller';

(function () {
    'use strict';

    angular.module('ogOfferStatusButton').directive('ogOfferStatusButton', [
        '$compile',
        function ($compile) {
            return {
                scope: {
                    offer: '=ogOfferStatusButton',
                    changeOfferStatusCallback: '=',
                    disabled: '=',
                    request: '=',
                },
                restrict: 'AE',
                replace: true,
                controller,
                controllerAs: 'OfferStatusButtonCtrl',
                bindToController: true,
                link: function (scope, element) {
                    scope.$watch('OfferStatusButtonCtrl.offer.statusDetailed.name', function (status) {
                        if (status) {
                            var statusMap = [];

                            if (status === 'NEW') {
                                statusMap = [
                                    { label: 'requestOffer.button.send', status: 'SENT' },
                                    /*    { label: 'requestOffer.button.manual', status: 'MANUAL' }*/
                                ];
                            } else if (status === 'NEW_NO_SUPPLIER') {
                                statusMap = [{ label: 'requestOffer.button.internal', status: 'INTERNAL' }];
                            } else if (status === 'INTERNAL') {
                                statusMap = [{ label: 'requestOffer.button.external', status: 'NEW_NO_SUPPLIER' }];
                                /* } else if (status == 'MANUAL') {
              statusMap = [
                { label: 'requestOffer.button.book.manually', status: 'MANUALLY_BOOKED' },
                { label: 'requestOffer.button.send', status: 'SENT' },
                { label: 'requestOffer.button.cancel', status: 'CANCELED' }
              ];*/
                            } else if (status === 'SENT') {
                                statusMap = [
                                    { label: 'requestOffer.button.cancel', status: 'CANCELED' },
                                    /*{ label: 'requestOffer.button.manual', status: 'MANUAL' }*/
                                ];
                            } else if (status === 'EDITED_BY_CREATOR') {
                                statusMap = [
                                    { label: 'requestOffer.button.send', status: 'SENT' },
                                    { label: 'requestOffer.button.cancel', status: 'CANCELED' },
                                ];
                            } else if (status === 'ANSWERED') {
                                statusMap = [
                                    { label: 'requestOffer.button.book', status: 'BOOKED_WAITING_FOR_CONFIRMATION' },
                                    //{ label: 'requestOffer.button.send', status: 'SENT' },
                                    { label: 'requestOffer.button.cancel', status: 'CANCELED' },
                                    { label: 'common.re.open.offer', status: 'RE_OPEN_OFFER' },
                                ];
                            } else if (status === 'BOOKED') {
                                statusMap = [{ label: 'requestOffer.button.cancel', status: 'CANCELED' }];
                            } else if (status === 'BOOKED_LOCKED_BY_CREATOR') {
                                statusMap = [
                                    {
                                        label: 'requestOffer.button.send.changes',
                                        status: 'BOOKED_WAITING_FOR_SUPPLIER',
                                    },
                                    { label: 'requestOffer.button.revert', status: 'BOOKED' },
                                    { label: 'requestOffer.button.cancel', status: 'CANCELED' },
                                ];
                            } else if (status === 'BOOKED_LOCKED_BY_SUPPLIER') {
                                statusMap = [{ label: 'requestOffer.button.cancel', status: 'CANCELED' }];
                            } else if (status === 'BOOKED_WAITING_FOR_CREATOR') {
                                statusMap = [
                                    { label: 'requestOffer.button.send.answer', status: 'BOOKED' },
                                    { label: 'requestOffer.button.cancel.booking', status: 'CANCELED' },
                                ];
                            } else if (status === 'BOOKED_WAITING_FOR_SUPPLIER') {
                                statusMap = [
                                    //     {label: 'requestOffer.button.reedit', status: 'BOOKED_LOCKED_BY_CREATOR'},
                                    { label: 'requestOffer.button.cancel', status: 'CANCELED' },
                                ];
                            } /*else if (status == 'CANCELED_AND_FINISHED') {
                statusMap = [
                    { label: 'requestOffer.button.reopen', status: 'NEW' }
                  /!*{ label: 'requestOffer.button.manual', status: 'MANUAL' }*!/
                ];
            }*/ else if (status === 'CANCELED') {
                                statusMap = [
                                    { label: 'requestOffer.button.reopen', status: 'NEW' },
                                    /*{ label: 'requestOffer.button.manual', status: 'MANUAL' }*/
                                ];
                            } else if (status === 'DECLINED') {
                                statusMap = [
                                    { label: 'requestOffer.button.reopen', status: 'NEW' },
                                    /* { label: 'requestOffer.button.manual', status: 'MANUAL' }*/
                                ];
                            }

                            var formattedBody = '';
                            if (statusMap.length > 1) {
                                scope.status = statusMap[0].status;
                                let isCancelledButton1 = scope.status === 'CANCELED';
                                let isAnsweredButton = scope.status === 'BOOKED_WAITING_FOR_CONFIRMATION';
                                formattedBody = `<span class="dropdown">
                <button ng-disabled="OfferStatusButtonCtrl.disabledButton()" id="split-button-1" 
                  type="button" class="btn"
                  ng-class="{'btn-secondary' : ${isCancelledButton1}, 'btn-success' : !${isCancelledButton1}, 'btn-success btn-success-lg' : ${isAnsweredButton} }"
                  style="color: white"  ng-click="OfferStatusButtonCtrl.changeOfferStatus($event,
                  OfferStatusButtonCtrl.offer, '${statusMap[0].status}')">{{ '${statusMap[0].label}' | translate}}
                `;
                                if (scope.OfferStatusButtonCtrl.displayReasonOnDisabledButton()) {
                                    formattedBody += `<span class="no-bg" og-custom-tooltip 
                                    base-element-html='<i class="fa fa-exclamation-triangle"></i>'>
                                    {{ 'supplier.view.warning.for.edited.by.creator.button' | translate}}
                                  </span>
                          </button>`;
                                } else {
                                    formattedBody += `</button>`;
                                }
                                // add the dropdown arrow button (also fixes the gap between the buttons)
                                formattedBody += `<button id="split-button-2" type="button" class="btn btn-success"
               ng-class="{'btn-secondary' : ${isCancelledButton1}, 'btn-success' : !${isCancelledButton1}, 'btn-success btn-success-lg-dropdown' : ${isAnsweredButton} }"
                data-toggle="dropdown" role="button">
                <i class="glyphicon glyphicon-triangle-bottom"></i></button>
                <ul id="drop-{{$parent.$index}}" class="dropdown-menu dropdown-menu-right" role="menu"
                 style="top:18px; left: 0px;">`;

                                for (var i = 1; i < statusMap.length; i++) {
                                    if (
                                        scope.offer &&
                                        scope.offer.offerParts &&
                                        scope.offer.offerParts.length <= 0 &&
                                        statusMap[i].status === 'SENT'
                                    ) {
                                        continue;
                                    }
                                    formattedBody += `<li><a ng-click="OfferStatusButtonCtrl.changeOfferStatus($event, OfferStatusButtonCtrl.offer,
                    '${statusMap[i].status}')">{{ '${statusMap[i].label}' | translate}}</a></li>`;
                                }
                                formattedBody += '</ul></span>';
                            } else if (statusMap.length === 1) {
                                if (status === 'NEW' || status === 'CANCELED' || status === 'DECLINED') {
                                    formattedBody = `<button class="btn btn-success" ng-click="OfferStatusButtonCtrl.changeOfferStatus($event,
                  OfferStatusButtonCtrl.offer, '${statusMap[0].status}')">{{ '${statusMap[0].label}' | translate}}
                 </button>`;
                                } else {
                                    formattedBody = `<button class="btn btn-secondary" ng-click="OfferStatusButtonCtrl.changeOfferStatus($event,
                  OfferStatusButtonCtrl.offer, '${statusMap[0].status}')">{{ '${statusMap[0].label}' | translate}}
                 </button>`;
                                }
                            }

                            jQuery(element).html($compile(formattedBody)(scope));
                        }
                    });
                },
            };
        },
    ]);
})();
