'use strict';
angular.module('core.existingServices').factory('FieldChangedSvc', [
    function () {
        class FieldChanged {
            constructor() {
                // does not work for array type
                this.tempArray = [];
                this.originalData = {};
            }

            /**
             Public Method
             Initialize the Object
             @param originalValue - Original dataset before field has changed
             @return null
             **/
            init(originalValue) {
                this.originalData = originalValue;
            }

            /**
             Public Method
             Process the tracking to check if field changed
             @param modelName {String} - Name of the model, which lies within original object
             @param currentValue {String} - Current value for that field
             @return null
             **/
            checkOnChanged(modelName, currentValue) {
                var _data = modelName.split('.');
                var s = {};
                _data.forEach(
                    function (d) {
                        if (s && Object.keys(s) && Object.keys(s).length) {
                            s = s[d];
                        } else {
                            s = this.originalData[d];
                        }
                    }.bind(this)
                );

                if (!Array.isArray(s) && s != currentValue) {
                    if (!s && !currentValue) {
                        this.removeFromArray(modelName);
                    } else {
                        this.addToArray(modelName);
                    }
                } else if (Array.isArray(s) && Array.isArray(currentValue)) {
                    var isNotSame = currentValue.some(function (item) {
                        return s.indexOf(item) == -1;
                    });
                    if (s.length == currentValue.length && !isNotSame) {
                        this.removeFromArray(modelName);
                    } else {
                        this.addToArray(modelName);
                    }
                } else {
                    this.removeFromArray(modelName);
                }
            }

            /**
             Private Method
             Add field for tracking if field changes
             @param modelName {String} - Name of the model, which lies within original object
             @return null
             **/
            addToArray(modelName) {
                if (this.tempArray.indexOf(modelName) == -1) {
                    this.tempArray.push(modelName);
                }
            }

            /**
             Private Method
             Remove field from tracking if field does not changes
             @param modelName {String} - Name of the model, which lies within original object
             @return null
             **/
            removeFromArray(modelName) {
                var index = this.tempArray.indexOf(modelName);
                if (index > -1) {
                    this.tempArray.splice(index, 1);
                }
            }

            /**
             Public Method
             Check if any field has changed in current dataset
             @return {Boolean}
             **/
            isFieldChanged() {
                if (this.tempArray.length !== 0) {
                    return true;
                }
                return false;
            }

            /**
             Public Method
             Reset Current Tracker
             @return null
             **/ reset() {
                this.tempArray = [];
            }

            /**
             * checks is a particular model has been changed
             * @param modelName
             * @returns {Array|number|boolean}
             */ isParticularFieldChanged(modelName) {
                return this.tempArray && this.tempArray.length && this.tempArray.indexOf(modelName) > -1;
            }
        }

        /**
         Field change Class
         @return Object instance
         **/
        return new FieldChanged();
    },
]);

