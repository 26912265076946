'use strict';
angular.module('core.existingServices').factory('Category', [
    '$resource',
    function ($resource) {
        var v1CategoryRest = $resource(
            'api/v1/category/:type',
            {type: '@type'},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        var v1CategoryRestTree = $resource(
            'api/v1/category/:type',
            {type: '@type'},
            {
                list: {method: 'GET', isArray: false},
            }
        );
        var v1PaginatedCategoryRestTree = $resource(
            'api/v1/category/listCategory',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );

        var v1ListActivities = $resource(
            'api/v1/category/listActivities',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );

        var v1ListEventTypeList = $resource(
            'api/v1/category/eventTypeList',
            {},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        var v1ListEventSubTypeList = $resource(
            'api/v1/category/eventSubTypeList/:categoryId',
            {categoryId: '@categoryId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );

        var results = [],
            tree = {};

        return {
            list: function (jsonObject, successCallback, errorCallback) {
                return v1CategoryRest.list(jsonObject, successCallback, errorCallback);
            },

            listAsTree: function (type, successCallback, errorCallback) {
                return v1CategoryRestTree.list({type: type}, successCallback, errorCallback);
            },
            paginatedListAsTree: function (jsonObject, successCallback, errorCallback) {
                return v1PaginatedCategoryRestTree.list(jsonObject, successCallback, errorCallback);
            },

            listActivities: function (jsonObject, successCallback, errorCallback) {
                return v1ListActivities.list(jsonObject, successCallback, errorCallback);
            },

            listByType: function (jsonObject, successCallback, errorCallback) {
                jsonObject = angular.extend(
                    {
                        categoryType: 'SUPPLIER',
                    },
                    jsonObject
                );

                var _results = v1CategoryRest.list(
                    jsonObject,
                    function () {
                        results.length = 0;

                        angular.forEach(_results, function (value) {
                            if (value.categoryGroup.name == jsonObject.categoryType) {
                                results.push(value);
                            }
                        });

                        if (angular.isFunction(successCallback)) {
                            successCallback.apply(this, [results]);
                        }
                    },
                    errorCallback
                );

                return results;
            },

            objectByType: function (jsonObject, successCallback, errorCallback) {
                jsonObject = angular.extend(
                    {
                        categoryType: 'SUPPLIER',
                    },
                    jsonObject
                );

                var _results = v1CategoryRest.list(
                    jsonObject,
                    function () {
                        angular.forEach(_results, function (value) {
                            if (value.categoryGroup.name == jsonObject.categoryType) {
                                tree[value.id] = value;
                            }
                        });

                        if (angular.isFunction(successCallback)) {
                            successCallback.apply(this, [tree]);
                        }
                    },
                    errorCallback
                );

                return tree;
            },
            eventTypeList: function (jsonObject, successCallback, errorCallback) {
                return v1ListEventTypeList.list(jsonObject, successCallback, errorCallback);
            },
            eventSubTypeList: function (categoryId, successCallback, errorCallback) {
                return v1ListEventSubTypeList.list({categoryId: categoryId}, successCallback, errorCallback);
            },
        };
    },
]);

