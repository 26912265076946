'use strict';
import createEventModalCtrl from '../../../modals/modals2.0/create-event';
import googleAddressUtil from '../../../../utils/GoogleAddressUtil';
import amountAndPriceService from '../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import { OgModal } from "../../../../utils/global/ogModal";
import _ from 'underscore';

// Register `events.managers.view` component, along with its associated controller and template
angular.module('events.managers.view').component('eventsmanagersview', {
    templateUrl: './app/components/modules/event/managers_overview/events.managers.view.html',
    controllerAs: 'eventsManagersCtrl',
    controller: [
        '$scope',
        '$rootScope',
        '$location',
        '$timeout',
        '$translate',
        '$http',
        'Page',
        'Event',
        'RequestOffer',
        'CustomerUser',
        'AuthenticationService',
        'EventEnum',
        'ogScrollService',
        '$state',
        'ModalService',
        'DateService',
        'ActiveTabService',
        'ChatMessageCountStore',
        'CommonFile',
        'CommonFunctions',
        function EventsManagersHomeController(
            $scope,
            $rootScope,
            $location,
            $timeout,
            $translate,
            $http,
            Page,
            Event,
            RequestOffer,
            CustomerUser,
            AuthenticationService,
            EventEnum,
            ogScrollService,
            $state,
            ModalService,
            DateService,
            ActiveTabService,
            ChatMessageCountStore,
            CommonFile,
            CommonFunctions
        ) {

            var vm = this;
            this.$onInit = function () {
                vm = this;
                vm.displayAllEvents = displayAllEventsFn;
                vm.eventSearch = eventSearchFn;
                vm.pageChanged = pageChangedFn;
                vm.resendActivationEmail = resendActivationEmailFn;
                vm.searchTextChanged = searchTextChangedFn;
                vm.showAddUsersDialog = showAddUsersDialogFn;
                vm.showCreateEventUsingTemplateOrGuide = showCreateEventUsingTemplateOrGuideFn;
                vm.getAmountLocalisedWithCurrency = amountAndPriceService.getAmountLocalisedWithCurrency;
                vm.openCreateEventModal = openCreateEventModalFn;
                vm.getDateFormat = getDateFormatFn;
                vm.viewOffer = viewOfferFn;
                vm.displayListView = displayListViewFn;
                vm.displayGridView = displayGridViewFn;
                vm.getFormattedDate = getFormattedDateFn;
                vm.getActiveTab = getActiveTabFn;
                vm.setActiveTab = setActiveTabFn;
                vm.eventsOrderByAsc = eventsOrderByAscFn;
                vm.eventsOrderByDesc = eventsOrderByDescFn;
                vm.getUnreadMessageCount = getUnreadMessageCountFn;
                vm.getTaskCount = getTaskCountFn;
                vm.stripHtmlTags = stripHtmlTagsFn;
                vm.scrollToLastActivities = scrollToLastActivitiesFn;
                vm.setEventNameAndSearch = setEventNameAndSearchFn;
                vm.updateSelectedEventName = updateSelectedEventNameFn;
                vm.userGroupChanged = userGroupChangedFn;
                vm.changeView = changeViewFn;
                vm.sortBy = sortByFn;
                vm.sortClass = sortClassFn;
                vm.getEventJourneyStatus = getEventJourneyStatusFn;
                vm.filteredEvents = [];
                vm.currentPage = 1;
                vm.page = 0;
                vm.searchResult = false;
                vm.showClear = false;
                vm.totalCount = 0;
                vm.params = {};
                vm.eventLocation = {};
                vm.listView = true;
                vm.numPerPage = 30;
                vm.calendarView = false;
                vm.direction = 'asc';
                vm.sorting = { field: 'fromDate', direction: 'asc' };
                vm.remoteUrl = new URL(CommonFile.getBaseUrl() + 'api/v1/event/getCreatorEventDropdownList');
                vm.disableEventCreation = false;
                vm.viewCreateEventModal = false;
                vm.eventJourneyStatusList = [
                    { name: 'ALL', code: 'common.all' },
                    { name: 'CREATED', code: 'event.journey.status.CREATED' },
                    { name: 'SENT', code: 'event.journey.status.SENT' },
                    { name: 'FIRST_OFFER_RECEIVED', code: 'event.journey.status.FIRST_OFFER_RECEIVED' },
                    { name: 'FIFTY_PERCENT_OFFER_RECEIVED', code: 'event.journey.status.FIFTY_PERCENT_OFFER_RECEIVED' },
                    { name: 'ALL_OFFER_RECEIVED', code: 'event.journey.status.ALL_OFFER_RECEIVED' },
                    { name: 'FIRST_OFFER_BOOKED', code: 'event.journey.status.FIRST_OFFER_BOOKED' },
                    { name: 'ALL_CANCELLED', code: 'event.journey.status.ALL_CANCELLED' },
                    { name: 'FINISHED', code: 'event.journey.status.FINISHED' },
                    { name: 'ALL_BOOKED', code: 'event.journey.status.ALL_BOOKED' },
                    { name: 'COMPLETED', code: 'event.journey.status.COMPLETED' },
                ];

                ScopeFunctions.addToScope($scope);
                // updateTab();

                initFn();
            }

            function initFn() {
                vm.statusFilterArray = [
                    { id: 1, title: 'UPCOMING' },
                    { id: 2, title: 'CLOSED' },
                    { id: 3, title: 'PENDING' },
                    { id: 4, title: 'COMPLETED' },
                    { id: 5, title: 'ACTIVE' },
                ];
                vm.selectedStatus = vm.statusFilterArray[0].title;
                vm.eventJourneyStatus = vm.eventJourneyStatusList[0].name;
                vm.userGroup = AuthenticationService.getUserData().userGroup;
                vm.selectedUserGroup = angular.copy(vm.userGroup);
                vm.selectedUserGroupId = vm.selectedUserGroup.id;
                // vm.column = 'participantAmount';
                // vm.reverse = false;

                // initSelect2Dropdown();
                $scope.startProgress();
                getOverviewSummary();
                getRemoteUrl();
                vm.currentPage = 1;
            }

            /**
             * Badri Paudel 9/17/2021
             * Listens for route change in this controller
             * check if user is already logged in and is trying to access login page again, if yes, redirect to dashboard
             */
            $scope.$on('$locationChangeSuccess', function ($event, next, current) {
                if (window.location.pathname === '/' && localStorage.getItem('authToken')) {
                    AuthenticationService.checkIfUserIsLoggedInAndRedirectToOverviewDashBoard();
                }
            });

            function userGroupChangedFn(selectedGroupId) {
                vm.selectedUserGroupId = selectedGroupId;
                getOverviewSummary();
                resetPaginationParams();
                getPaginatedEvents();
            }

            function changeViewFn(view) {
                if (view === 'list') {
                    vm.listView = true;
                    vm.calendarView = false;
                    jQuery('.popover').popover('destroy');
                    getPaginatedEvents();
                } else {
                    vm.calendarView = true;
                    vm.listView = false;
                }
            }

            function getDateFormatFn(lang) {
                if (lang == 'sv_SV') {
                    return 'yyyy-MM-dd';
                }
                return 'dd/MM/yyyy';
            }

            function getUnreadMessageCountFn() {
                return ChatMessageCountStore.getCreatorHomePageUnreadMessageCount();
            }

            function getTaskCountFn() {
                return ChatMessageCountStore.getCreatorHomePageTaskCount();
            }

            function checkAndOpenCreateEventModal() {
                if (!vm.totalEventCount && vm.userGroupName && !vm.disableEventCreation) {
                    vm.openCreateEventModal();
                }
            }

            function initSelect2Dropdown() {
                $timeout(function () {
                    jQuery(document).ready(function () {
                        jQuery('.user-groups-search').select2({
                            closeOnSelect: true,
                            placeholder: $translate.instant('common.search'),
                            ajax: {
                                url: CommonFile.getBaseUrl() + 'api/v1/userGroup/' + vm.selectedUserGroupId + '/children',
                                dataType: 'json',
                                data: function (params) {
                                    return {
                                        searchText: params.term,
                                        page: params.page,
                                    };
                                },
                                processResults: function (data, params) {
                                    params.page = params.page || 1;
                                    var results = [];
                                    if (params.page < 2) {
                                        results.push({
                                            id: vm.selectedUserGroup.id,
                                            text: vm.selectedUserGroup.name,
                                        });
                                    }
                                    $.each(data.childGroupList, function (index, item) {
                                        results.push({
                                            id: item.id,
                                            text: item.name,
                                        });
                                    });

                                    return {
                                        results: results,
                                        pagination: {
                                            more: params.page * 10 < data.childGroupTotal,
                                        },
                                    };
                                },
                                formatResult: function (item) {
                                    return item.name;
                                },
                                formatSelection: function (item) {
                                    return item.name;
                                },
                            },
                        });
                    });
                }, 2000);
            }

            function updateTab() {
                switch ($state.current.name) {
                    case 'events.list':
                        ActiveTabService.setEventsTab('EVENTS');
                        break;
                    case 'events.messages':
                        ActiveTabService.setEventsTab('MESSAGES');
                        break;
                    case 'events.tasks':
                        ActiveTabService.setEventsTab('TASKS');
                        break;
                    case 'events.upComingOffer':
                        ActiveTabService.setEventsTab('OFFERS_UPCOMING');
                        break;
                    default:
                        ActiveTabService.setEventsTab('EVENTS');
                        break;
                }
            }

            function displayAllEventsFn() {
                vm.viewMode = 'EVENTS';
                vm.showAllEvents = true;
                if (!vm.totalEventCount) {
                    $timeout(function () {
                        ogScrollService.scrollToElement('event-container');
                    }, 0);
                }
            }

            function displayListViewFn() {
                vm.listView = true;
                vm.numPerPage = 15;
                vm.gridView = false;
                getPaginatedEvents();
            }

            function displayGridViewFn() {
                vm.gridView = true;
                vm.numPerPage = 6;
                vm.listView = false;
                getPaginatedEvents();
            }

            function eventsOrderByDescFn() {
                vm.field = 'fromDate';
                vm.direction = 'desc';
                vm.sorting = { field: vm.field, direction: vm.direction };
                getPaginatedEvents();
            }

            function eventsOrderByAscFn() {
                vm.field = 'fromDate';
                vm.direction = 'asc';
                vm.sorting = { field: vm.field, direction: vm.direction };
                getPaginatedEvents();
            }

            function getFormattedDateFn(dateTime) {
                return DateService.extractLocalizedDateFromTimeStamp(dateTime);
            }

            function getActiveTabFn() {
                return ActiveTabService.getEventsTab();
            }

            function setActiveTabFn(tab) {
                return ActiveTabService.setEventsTab(tab);
            }

            function viewOfferFn(offerId, requestId, eventId) {
                $state.go('event.detail.overview', { eventId: eventId, requestId: requestId, offerId: offerId });
            }

            function eventSearchFn() {
                resetPaginationParams();
                vm.searchPattern = vm.searchText;
                vm.currentPage = 1;
                vm.searchResult = true;
                if (vm.searchText || vm.toDate || vm.fromDate) {
                    vm.showClear = true;
                } else {
                    vm.showClear = false;
                }
                let _displayAddress = angular.copy(vm.eventLocation.displayAddress);
                if (_displayAddress) {
                    window.googleMapsCallback(() => {
                        var geocoder = new google.maps.Geocoder();
                        geocoder.geocode({ address: _displayAddress }, (results, status) => {
                            if (status === google.maps.GeocoderStatus.OK) {
                                var place = results[0];
                                vm.eventLocation = googleAddressUtil.parseGoogleAddress(place);
                                vm.eventLocation.displayAddress = _displayAddress;
                            }
                            getPaginatedEvents();
                        });
                    });
                } else {
                    getPaginatedEvents();
                }
            }

            function openCreateEventModalFn() {
                if (vm.viewCreateEventModal) {
                    return
                }
                vm.viewCreateEventModal = true;
                let createEventModalCtrlData = createEventModalCtrl;

                ModalService.showModal(createEventModalCtrlData).then((modal) => {
                    vm.viewCreateEventModal = false;
                    modal.element.modal();
                    modal.close.then(() => {
                        console.log('modal is closed!');
                    });
                });
            }

            function pageChangedFn() {
                setPaginationParams();
                getPaginatedEvents();
            }

            function resendActivationEmailFn() {
                vm.data = {
                    email: vm.loggedUserEmail,
                    username: vm.loggedUserUsername,
                };
                $http
                    .post('api/v1/register/resendActivationEmail', vm.data)
                    .then(function () {
                        $rootScope.dialog('alert', { message: 'dialog.activation.message.sent' })
                    }, $scope.endWithAlert);
            }

            function searchTextChangedFn() {
                vm.showClear = false;
            }

            function showAddUsersDialogFn() {
                refreshUserData();
                OgModal.open("add:user:to:user:group", { group: vm.userGroup });
            }

            function showCreateEventUsingTemplateOrGuideFn() {
                OgModal.open('create:event:using:template:or:guide', { publicUrl: CommonFunctions.getPublicURL() });
            }

            function resetPaginationParams() {
                vm.params = { offset: 0, max: vm.numPerPage };
                vm.totalLength = 0;
            }

            function stripHtmlTagsFn(str) {
                if (str === null || str === '') {
                    return false;
                } else {
                    str = str.toString();
                    var stripedhtml = str.replace(/(<([^>]+)>)/gi, '');
                    return htmlDecode(stripedhtml);
                }
            }

            function htmlDecode(input) {
                var e = document.createElement('div');
                e.innerHTML = input;
                return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
            }

            function setPaginationParams() {
                vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                vm.params.max = vm.numPerPage;
            }

            function refreshUserData() {
                var userData = AuthenticationService.getUserData();
                if (userData !== null && vm.userGroup.id !== userData.userGroup.id) {
                    // vm.userGroup = userData.userGroup;
                    vm.loggedUserEmail = userData.email;
                    vm.loggedUserUsername = userData.username;
                    vm.loggedUserName = userData.name;
                    $timeout(function () {
                        initFn();
                    }, 10);
                }
            }

            function scrollToLastActivitiesFn() {
                ogScrollService.scrollToElement('event-container');
            }

            function getOverviewSummary() {
                var params = {
                    groupId: vm.selectedUserGroupId,
                };
                Page.managersHomePage(
                    params,
                    function (response) {
                        vm.activeUsers = response.totalUsers;
                        vm.bookingsCount = response.totalBookingsCount;
                        vm.bookingsValue = response.totalBookingsValue;
                        vm.bookingsValueLength = Object.keys(vm.bookingsValue).length;
                        vm.activeCount = response.activeCount;
                        vm.usersInGroup = response.usersInGroup;
                        vm.userGroupName = response.userGroupName;
                        // this is the count of all event created so far.. including ACTIVE/CLOSED etc
                        vm.totalEventCount = response.totalEventCount;
                        resetPaginationParams();
                        setPaginationParams();
                        getPaginatedEvents();
                        $scope.endProgress();
                    },
                    $scope.endWithErrorOverlay
                );
            }

            function getPaginatedEvents() {
                $scope.startProgress('event-management');
                var params = {
                    offset: vm.params.offset,
                    max: vm.numPerPage,
                    status: vm.selectedStatus,
                    eventJourneyStatus: vm.eventJourneyStatus === 'ALL' ? null : vm.eventJourneyStatus,
                    eventLocation: vm.eventLocation,
                    pagination: true,
                    managersView: true,
                    fromDateStart: vm.fromDate,
                    fromDateEnd: vm.toDate,
                    sorting: vm.sorting,
                    groupId: vm.selectedUserGroupId,
                };
                if (vm.searchText !== null) {
                    params.searchText = vm.searchText;
                }
                Event.findAllEventsForManager(
                    params,
                    function (response) {
                        vm.filteredEvents = response.list;
                        vm.summaryBookedValue = response.totalBookedValue;
                        vm.totalCount = response.totalCount;
                        // vm.totalLength = Math.ceil(response.totalCount / vm.numPerPage);
                        $scope.endProgress('event-management');
                    },
                    $scope.endWithErrorOverlay
                );
            }

            function sortByFn(col) {
                vm.column = col;
                if (vm.reverse) {
                    vm.reverse = false;
                    vm.reverseclass = 'arrow-up';
                } else {
                    vm.reverse = true;
                    vm.reverseclass = 'arrow-down';
                }
                var direction = vm.reverse ? 'desc' : 'asc';
                vm.sorting = { field: vm.column, direction: direction };
                getPaginatedEvents();
            }

            function sortClassFn(col) {
                if (vm.column == col) {
                    if (vm.reverse) {
                        return 'arrow-down';
                    } else {
                        return 'arrow-up';
                    }
                } else {
                    return '';
                }
            }

            function updateSelectedEventNameFn(eventName) {
                vm.searchText = eventName;
                if (_.isNull(vm.searchText)) {
                    resetPaginationParams();
                    vm.searchPattern = '';
                    getPaginatedEvents();
                }
            }

            function setEventNameAndSearchFn(eventName) {
                vm.searchText = eventName;
                getPaginatedEvents();
            }

            function getRemoteUrl() {
                if (vm.selectedStatus) {
                    vm.remoteUrl.searchParams.append('status', vm.selectedStatus);
                }
                if (vm.fromDate) {
                    vm.remoteUrl.searchParams.append('fromDate', vm.fromDate);
                }
                if (vm.toDate) {
                    vm.remoteUrl.searchParams.append('toDate', vm.toDate);
                }
                if (vm.eventJourneyStatus) {
                    vm.remoteUrl.searchParams.append('eventJourneyStatus', vm.eventJourneyStatus);
                }
                if (vm.eventLocation) {
                    vm.remoteUrl.searchParams.append('eventLocation', vm.eventLocation);
                }
            }

            function getEventJourneyStatusFn(statusName) {
                switch (statusName) {
                    case 'CREATED':
                        return $translate.instant('event.journey.status.CREATED');
                        break;
                    case 'SENT':
                        return $translate.instant('event.journey.status.SENT');
                        break;
                    case 'FIRST_OFFER_RECEIVED':
                        return $translate.instant('event.journey.status.FIRST_OFFER_RECEIVED');
                        break;
                    case 'FIFTY_PERCENT_OFFER_RECEIVED':
                        return $translate.instant('event.journey.status.FIFTY_PERCENT_OFFER_RECEIVED');
                        break;
                    case 'FIRST_OFFER_BOOKED':
                        return $translate.instant('event.journey.status.FIRST_OFFER_BOOKED');
                        break;
                    case 'ALL_BOOKED':
                        return $translate.instant('event.journey.status.ALL_BOOKED');
                        break;
                    case 'ALL_CANCELLED':
                        return $translate.instant('event.journey.status.ALL_CANCELLED');
                        break;
                    case 'ALL_OFFER_RECEIVED':
                        return $translate.instant('event.journey.status.ALL_OFFER_RECEIVED');
                        break;
                    case 'FINISHED':
                        return $translate.instant('event.journey.status.FINISHED');
                        break;
                    case 'COMPLETED':
                        return $translate.instant('event.journey.status.COMPLETED');
                        break;
                    default:
                        return statusName;
                        break;
                }
            }

            $scope.$on('event:template:create', function (event, value) {
                Event.save(value, function (response) {
                    $location.url('/event/' + response.id);
                });
            });

            $scope.$on('eventsManagersCtrl.selectedStatus/changed', function (event, value) {
                angular.forEach(vm.statusFilterArray, function (item) {
                    if (item.title === value && vm.listView) {
                        $rootScope.selectedStatus = value;
                        resetPaginationParams();
                        vm.searchText = '';
                        vm.fromDate = '';
                        vm.toDate = '';
                        vm.eventJourneyStatus = '';
                        vm.eventLocation = {};
                        vm.searchPattern = '';
                        vm.showClear = false;
                        getPaginatedEvents();
                        vm.remoteUrl.searchParams.set('status', vm.selectedStatus);
                        vm.currentPage = 1;
                        $scope.$broadcast('angucomplete-alt:clearInput');
                    }
                });
            });

            $scope.$on(EventEnum.USER_DATA_CHANGED, refreshUserData);
            $scope.$on(EventEnum.USER_GROUP_CHANGED_SILENT, refreshUserData);
            $scope.$on(EventEnum.USER_GROUP_CHANGED, refreshUserData);
            $scope.$on(EventEnum.USER_IN_GROUP_DATA_CHANGED, refreshUserData);

            var profilePictureChangedEvent = $rootScope.$on(EventEnum.USER_PROFILE_PICTURE_CHANGED, function (event, data) {
                angular.forEach(vm.usersInGroup, function (user, index) {
                    if (user.id == data.id) {
                        vm.usersInGroup.splice(index, 1, data);
                    }
                });
            });
            $scope.$on('$destroy', profilePictureChangedEvent);
        }
    ]
});
