import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class BookingTermsEditModalCtrl {
    constructor(
        $element,
        $log,
        $rootScope,
        $timeout,
        ValidationService,
        RequestOffer,
        GlobalOption,
        Supplier,
        $scope,
        close,
        data
    ) {
        this._injected = {
            $element,
            $log,
            $rootScope,
            $timeout,
            ValidationService,
            RequestOffer,
            GlobalOption,
            Supplier,
            $scope,
            close,
            data,
        };

        ScopeFunctions.addToScope($scope);
        this.successCallBack = data.successCallBack;

        this.offer = data.offer;
        this.clientName = data.clientName;
        this.isUsingClientBillingAddressAndIsEL = data.isUsingClientBillingAddressAndIsEL;
        this.disableEditMode();
        this.loadBookingTerms();
        this.loadCancelPeriod();
    }

    getBookingTerms() {
        const { $scope, Supplier } = this._injected;
        $scope.startProgress();
        Supplier.get(
            { supplierId: this.offer.supplier.id },
            (response) => {
                this.supplier = response;
                this.initialSetAsDefault =
                    this.supplier.bookingTerms &&
                    this.offer.bookingTerms &&
                    this.supplier.bookingTerms == this.offer.bookingTerms
                        ? 'forAll'
                        : 'forThis';
                let _supplierBookingTerms = response.bookingTerms;
                let _bookingTerms = response.useCustomBookingTerms ? _supplierBookingTerms : this.standardBookingTerms;
                this.defaultBookingTerms =
                    this.offer && this.offer.bookingTerms ? this.offer.bookingTerms : _bookingTerms;
                this.data = {
                    bookingTerms: this.defaultBookingTerms,
                    cancelPeriod:
                        this.offer && (this.offer.cancelPeriod || this.offer.cancelPeriod === 0)
                            ? this.offer.cancelPeriod
                            : this.cancelPeriod,
                    setAsDefault: angular.copy(this.initialSetAsDefault),
                };
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay
        );
    }

    loadBookingTerms() {
        const { $scope, $rootScope, GlobalOption } = this._injected;
        $scope.startProgress();
        GlobalOption.get(
            'BOOKING_TERMS',
            (resp) => {
                this.standardBookingTerms = resp[$rootScope.langSelected];
                this.getBookingTerms();
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay
        );
    }

    loadCancelPeriod() {
        const { $scope, $rootScope, GlobalOption } = this._injected;
        $scope.startProgress();
        GlobalOption.get(
            'CANCEL_PERIOD',
            (resp) => {
                this.cancelPeriod = resp[$rootScope.langSelected] ? resp[$rootScope.langSelected] : resp['sv_SV'];
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay
        );
    }

    disableEditMode() {
        this.editMode = false;
    }

    enableEditMode() {
        this.editMode = true;
    }

    update() {
        let { $scope, RequestOffer } = this._injected;

        $scope.startProgress({ showContent: true });
        RequestOffer.update(
            {
                offerId: this.offer.id,
                bookingTerms: this.data.bookingTerms,
                setAsDefault: this.data.setAsDefault == 'forAll',
            },
            () => {
                this.offer.bookingTerms = this.data.bookingTerms;
                /* this.offer.cancelPeriod = this.data.cancelPeriod;*/
                this.offer.standardTerms = this.standardTerms();
                this.show = false;
                if (this.successCallBack) {
                    this.successCallBack();
                }
                this.disableEditMode();
                $scope.endProgress();
                this.close();
            },
            $scope.endWithError
        );
    }

    standardTerms() {
        return (
            !!this.standardBookingTerms &&
            !!this.cancelPeriod &&
            this.data &&
            this.data.bookingTerms === this.standardBookingTerms &&
            this.data.cancelPeriod === this.cancelPeriod
        );
    }

    supplierTerms() {
        if (!this.supplier) {
            return false;
        }
        if (this.standardTerms()) {
            return this.supplier.bookingTerms === null && this.supplier.bookingCancelDays === null;
        }
        return (
            this.data &&
            this.data.bookingTerms == this.supplier.bookingTerms &&
            this.data.cancelPeriod == this.supplier.bookingCancelDays
        );
    }

    modified() {
        return (
            this.offer &&
            this.data &&
            (this.data.bookingTerms != this.defaultBookingTerms || this.data.setAsDefault != this.initialSetAsDefault)
        );
    }

    getCountdownDateTime(lastDateChange) {
        var now = new Date();
        var nowTime = now.getTime();
        var diffTime = lastDateChange - nowTime;
        return moment.duration(diffTime).humanize(true);
    }

    useStandard() {
        this.data.bookingTerms = this.standardBookingTerms;
        this.data.cancelPeriod = this.cancelPeriod;
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

BookingTermsEditModalCtrl.$inject = [
    '$element',
    '$log',
    '$rootScope',
    '$timeout',
    'ValidationService',
    'RequestOffer',
    'GlobalOption',
    'Supplier',
    '$scope',
    'close',
    'data',
];
export default BookingTermsEditModalCtrl;
