/**
 * Created by kshitij on 8/7/17.
 */

import controller from './og_accommodation_calendar.controller';

(function () {
    'use strict';
    angular.module('accommodation.calendar').directive('ogAccommodationCalendar', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    item: '=',
                    readOnly: '=',
                    isLast: '=',
                    isAfterEvent: '=',
                    openAccommodationModalCallback: '&',
                },
                templateUrl:
                    './app/components/directives/accommodation_calendar/og_accommodation_calendar.view.html',
                controller,
                controllerAs: 'AccommodationCalendarCtrl',
                bindToController: true,
            };
        },
    ]);
})();
