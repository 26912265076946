import {ScopeFunctions} from "../../../../utils/global/scopeFunctions";
(function () {
    'use strict';
    angular.module('participant-details').component('participantDetails', {
        templateUrl: './app/components/modules/participant/participant_details/participant_details.view.html',
        controllerAs: 'participantDetailsCtrl',
        bindings: {
            personalizedPageDetails: '<'
        },
        controller: [
            '$scope',
            '$rootScope',
            '$state',
            '$stateParams',
            'ActiveTabService',
            'Survey',
            'Participant',
            '$location',
            'EventPresentation',
            function ParticipantDetailsController(
                $scope,
                $rootScope,
                $state,
                $stateParams,
                ActiveTabService,
                Survey,
                Participant,
                $location,
                EventPresentation
            ) {
                var vm = this;
                vm.setActiveTab = setActiveTabFn;
                vm.activeTab = '';
                var fromPublicSite = true;
                vm.surveyRating = false;
                vm.english = false;
                vm.swedish = false;
                vm.norwegian = false;
                vm.danish = false;
                vm.finnish = false;
                ScopeFunctions.addToScope($scope);
                vm.init = initFn;

                vm.$onInit = function () {
                    vm.personalizedPageDetailsData = vm.personalizedPageDetails;
                    vm.init();
                }

                vm.getActiveTab = function () {
                    return ActiveTabService.getParticipantsTab();
                };

                vm.switchLanguage = function (lang) {
                    this.selectedLang = lang;
                    $rootScope.switchLang(lang, true, $stateParams.hash, fromPublicSite);
                    $location.search('lang', null);
                };

                function initFn() {
                    vm.event = vm.personalizedPageDetailsData.event;
                    fromPublicSite = vm.personalizedPageDetailsData.fromPublicSite;
                    if (vm.personalizedPageDetailsData && vm.personalizedPageDetailsData.participantLocale) {
                        this.selectedLang = vm.personalizedPageDetailsData.participantLocale;
                        $rootScope.switchLang(vm.personalizedPageDetailsData.participantLocale, true);
                    }
                    $scope.startProgress();
                    EventPresentation.getInvitationPresentationForEvent(
                        { eventId: vm.event.id },
                        updatePresentationDetails,
                        $scope.endWithErrorOverlay
                    );
                    isSurveyRated();

                    //TODO(BD) Check if this is needed. The response seems to be empty
                    // Survey.getEventDetails($stateParams.hash, function(response) {
                    //   vm.event = response;
                    //   vm.message =
                    //     $translate.instant('participant.details.header',
                    //       { ownerGroupName: vm.event.ownerGroup.name, eventName: vm.event.name });
                    // });

                    // Participant.getParticipantExternalAccessDetails($stateParams.hash, function(response) {
                    //   // here we get the type of the access, we can use that for different use
                    // });

                    switch ($state.current.name) {
                        case 'participant_details.registration':
                            ActiveTabService.setParticipantsTab('registration');
                            break;
                        case 'participant_details.event_details':
                            ActiveTabService.setParticipantsTab('event_details');
                            break;
                        case 'participant_details.ratings':
                            ActiveTabService.setParticipantsTab('ratings');
                            break;
                        case 'participant_details.invitation':
                            ActiveTabService.setParticipantsTab('invitation');
                            break;
                    }
                }

                function isSurveyRated() {
                    Survey.forParticipant(
                        { hash: $stateParams.hash },
                        (response) => {
                            if (response.survey) {
                                vm.surveyRating = true;
                            } else {
                                vm.surveyRating = false;
                            }
                        },
                        $scope.endWithAlert
                    );
                }

                function updatePresentationDetails(response) {
                    vm.eventPresentation = response.eventPresentationDetails.eventPresentation;
                    vm.presentation = response.eventPresentationDetails.slide;
                    vm.presentationImages = response.eventPresentationDetails.slide.presentationImages;
                    vm.showMapInInvitation = { checked: response.eventPresentationDetails.eventPresentation.showMap };
                    vm.invitationDetails = response.invitationDetails;
                    vm.participantDefaultLocale = response.locale;
                    vm.participantSupportLocale = response.participantSupportLocale.split('~');
                    if (vm.participantDefaultLocale === 'en_US' || vm.participantSupportLocale[0] === 'en_US') {
                        vm.english = true;
                    }
                    if (vm.participantDefaultLocale === 'sv_SV' || vm.participantSupportLocale[1] === 'sv_SV') {
                        vm.swedish = true;
                    }
                    if (vm.participantDefaultLocale === 'nb_NB' || vm.participantSupportLocale[2] === 'nb_NB') {
                        vm.norwegian = true;
                    }
                    if (vm.participantDefaultLocale === 'da_DK' || vm.participantSupportLocale[3] === 'da_DK') {
                        vm.danish = true;
                    }
                    if (vm.participantDefaultLocale === 'fi_FI' || vm.participantSupportLocale[4] === 'fi_FI') {
                        vm.finnish = true;
                    }
                    $scope.endProgress();
                }

                function setActiveTabFn(tab) {
                    ActiveTabService.setParticipantsTab(tab);
                    switch (tab) {
                        case 'register':
                            $state.go('participant_details.register');
                            break;
                        case 'registration':
                            $state.go('participant_details.registration');
                            break;
                        case 'event_details':
                            $state.go('participant_details.event_details');
                            break;
                        case 'ratings':
                            $state.go('participant_details.ratings');
                            break;
                        case 'invitation':
                            $state.go('participant_details.invitation');
                            break;
                        default:
                            $state.go('participant_details.registration');
                    }
                }
            }
        ]
    });
})();
