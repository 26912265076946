'use strict';
import {ScopeFunctions} from '../../../../../utils/global/scopeFunctions';
import {UtilFunctions} from '../../../../../utils/global/utilFunctions'

angular.module('supplier.offer.thank.you.confirmation').component('thankYouForConfirmation', {
    templateUrl: './app/components/modules/supplier/offer/thank_you/thank_you_for_confirmation.html',
    controller: [
        '$rootScope',
        '$scope',
        '$state',
        '$stateParams',
        'Page',
        'RequestOffer',
        'ClientService',
        function ThankYouForConfirmation($rootScope, $scope, $state, $stateParams, Page, RequestOffer, ClientService) {
            var vm = this;
            vm.offerId = $stateParams.offerId;

            vm.redirectToHome = redirectToHomeFn;

            ScopeFunctions.addToScope($scope);
            vm.init = initFn;
            vm.responsibleForBookingChanged = responsibleForBookingChangedFn;
            vm.updateIncludeResponsibleBookingSupplierUser = updateIncludeResponsibleBookingSupplierUserFn;
            vm.responsibleForManagingChanged = responsibleForManagingChangedFn;
            vm.changeHandOverDate = changeHandOverDateFn;
            vm.handleOnChanged = handleOnChangedFn;
            vm.addResponsibleUser = addResponsibleUserFn;
            vm.checkUserForBookingAndManaging = checkUserForBookingAndManagingFn;
            vm.closeMsg = closeMsgFn;
            vm.redirectToOfferDetails = redirectToOfferDetailsFn;
            vm.showBillingInfo = showBillingInfoFn;
            vm.hasHandOverDateChanged = false;
            vm.showSuccessMessage = false;

            function redirectToHomeFn() {
                $state.go('offers');
            }

            function redirectToOfferDetailsFn() {
                $state.go('offer.detail', {offerId: vm.offerId});
                $('html, body').animate({scrollTop: 0}, 'slow');
            }

            function initFn() {
                $scope.startProgress();
                Page.thankYouConfirmationPage(
                    {offerId: vm.offerId},
                    function (res) {
                        vm.offer = res.offer;
                        vm.userGroupMembers = res.usersInGroup;
                        vm.responsibleBookingSupplierUser = res.responsibleBookingSupplierUser;
                        vm.includeResponsibleBookingSupplierUser = res.includeResponsibleBookingSupplierUser;
                        vm.responsibleManagingSupplierUser = res.responsibleManagingSupplierUser;
                        vm.handOverDate = res.handOverDate;
                        vm.originalHandOverDate = res.handOverDate;
                        vm.supplierName = res.supplierName;
                        $scope.endProgress();
                    },
                    function (name, errorDetails) {
                        $scope.endWithAlertAndCallBack(name, errorDetails, redirectToHomeFn);
                    }
                );
                vm.clientName = ClientService.getClientDisplayName();
            }

            function responsibleForBookingChangedFn() {
                $scope.startProgress();
                RequestOffer.updateBookingSupplierResponsibleUser(
                    {
                        offerId: vm.offerId,
                        responsibleBookingSupplierUserId: vm.responsibleBookingSupplierUser.id,
                    },
                    (res) => {
                        vm.responsibleBookingSupplierUser = res.responsibleBookingSupplierUser;
                        $scope.endProgress();
                    },
                    $scope.endWithAlert
                );
            }

            function updateIncludeResponsibleBookingSupplierUserFn() {
                $scope.startProgress();
                RequestOffer.updateIncludeResponsibleBookingSupplierUser(
                    {
                        offerId: vm.offerId,
                        includeResponsibleBookingSupplierUser: vm.includeResponsibleBookingSupplierUser,
                    },
                    (res) => {
                        vm.includeResponsibleBookingSupplierUser = res.includeResponsibleBookingSupplierUser;
                        $scope.endProgress();
                    },
                    $scope.endWithAlert
                );
            }

            function responsibleForManagingChangedFn() {
                $scope.startProgress();
                RequestOffer.updateManagingSupplierResponsibleUser(
                    {
                        offerId: vm.offerId,
                        responsibleManagingSupplierUserId: vm.responsibleManagingSupplierUser.id,
                    },
                    (res) => {
                        vm.responsibleManagingSupplierUser = res.responsibleManagingSupplierUser;
                        $scope.endProgress();
                    },
                    $scope.endWithAlert
                );
            }

            function changeHandOverDateFn() {
                $scope.startProgress();
                RequestOffer.updateHandOverDateAndSendEmail(
                    {
                        offerId: vm.offerId,
                        handOverDate: vm.handOverDate,
                    },
                    (res) => {
                        vm.handOverDate = res.handOverDate;
                        vm.originalHandOverDate = res.handOverDate;
                        vm.hasHandOverDateChanged = false;
                        $scope.endProgress();
                    },
                    $scope.endWithAlert
                );
            }

            function addResponsibleUserFn() {
                $scope.startProgress();
                RequestOffer.addNewResponsibleUser(
                    {
                        offerId: vm.offerId,
                        responsibleUserEmail: vm.responsibleUserEmail,
                    },
                    (res) => {
                        vm.userGroupMembers = res.usersInGroup;
                        vm.responsibleManagingSupplierUser = res.responsibleManagingSupplierUser;
                        vm.showSuccessMessage = true;
                        vm.responsibleUserEmail = null;
                        $scope.endProgress();
                    },
                    $scope.endWithAlert
                );
            }

            function handleOnChangedFn(currentValue) {
                if (UtilFunctions.isDefinedAndNotNull(currentValue)) {
                    vm.hasHandOverDateChanged = vm.originalHandOverDate !== currentValue;
                }
            }

            function checkUserForBookingAndManagingFn() {
                if (!!vm.responsibleBookingSupplierUser && !!vm.responsibleManagingSupplierUser) {
                    return vm.responsibleBookingSupplierUser.id === vm.responsibleManagingSupplierUser.id;
                }
                return false;
            }

            function showBillingInfoFn() {
                vm.disableBillingAddressBtn = true;
                RequestOffer.getBillingAddress(
                    vm.offerId,
                    (res) => {
                        if (res) {
                            $rootScope.dialog('show:billing:info', {
                                billingInfo: res.billingAddress,
                                closeCallBack: function () {
                                    vm.disableBillingAddressBtn = false;
                                },
                            });
                        }
                    },
                    (e) => {
                        console.log('Error', e);
                    }
                );
            }

            function closeMsgFn() {
                vm.showSuccessMessage = false;
            }

            vm.init();
        }
    ]
})