/**
 * Created by Diwakar on 9/15/2017.
 */
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class DesignBadgeLogoModal {
    constructor($element, $rootScope, $scope, data, close, $timeout, $translate, $window, Presentation) {
        this._injected = {
            $element,
            $rootScope,
            $scope,
            data,
            close,
            $timeout,
            $translate,
            $window,
            Presentation,
        };
        ScopeFunctions.addToScope($scope);

        this.customStyle = {};
        this.crop = {};
        this.crop.badgeUrl = {};
        this.crop.badgeUrl.coordinates = null;
        this.crop.badgeUrl.cropStyle = {
            boxWidth: '445',
            boxHeight: '445',
            bgColor: 'rgb(16, 16, 16)',
            minSize: [100, 100],
            setSelect: [0, 0, 100, 100],
        };
        this.crop.badgeUrl.thumbnail = true;
        this.crop.badgeUrl.image = null;

        this.logoType = data.itemType;
        this.successCallBack = data.successCallBack;
        this.presentationId = data.presentationId;
        this.crop.badgeUrl.image = undefined;
        jQuery('#logoImageUrl-badge').val('');

        let URL = window.URL || window.webkitURL,
            blobURL;

        $scope.$watch('designBadgeLogoModal.crop.badgeUrl.image', (value) => {
            if (URL) {
                if (value) {
                    if (/^image\/\w+$/.test(value.type)) {
                        this.customStyle = {};
                        this.crop.badgeUrl.image = value;
                        blobURL = URL.createObjectURL(this.crop.badgeUrl.image);
                        this.crop.badgeUrl.blobURL = blobURL;
                    } else {
                        this.crop.badgeUrl.image = null;
                    }
                }
            }
        });

        $scope.sizeTypeCheck = () => {
            var uploadField = document.getElementById('logoImageUrl-badge');
            if (uploadField.files[0].size > 3145728 && !/^image\/\w+$/.test(uploadField.files[0].type)) {
                this.customStyle.typeError = { color: 'red' };
                this.customStyle.sizeError = { color: 'red' };
                uploadField.value = '';
            } else if (uploadField.files[0].size > 3145728) {
                this.customStyle.sizeError = { color: 'red' };
                this.customStyle.typeError = {};
                uploadField.value = '';
            } else if (!/^image\/\w+$/.test(uploadField.files[0].type)) {
                this.customStyle.typeError = { color: 'red' };
                this.customStyle.sizeError = {};
                uploadField.value = '';
            }
        };
    }

    setBadgeUrl() {
        const { $rootScope, $scope, Presentation } = this._injected;
        if (!Presentation.checkPictureCropDetails(this.crop.badgeUrl)) {
            $rootScope.$broadcast('dialog:alert:open', { message: 'image.crop.before.uploading' });
        } else {
            $scope.startProgress();
            let formData = new FormData();
            formData.append('logoType', 'badgeUrl');
            formData.append('presentation', this.presentationId);
            formData.append('logo', this.crop.badgeUrl.image);
            formData.append('dataX', this.crop.badgeUrl.coordinates[0]);
            formData.append('dataY', this.crop.badgeUrl.coordinates[1]);
            formData.append('dataWidth', this.crop.badgeUrl.coordinates[4]);
            formData.append('dataHeight', this.crop.badgeUrl.coordinates[5]);
            Presentation.updatePresentationLogo(
                formData,
                (data) => {
                    $scope.endProgress();
                    if (this.successCallBack) {
                        this.successCallBack();
                    }
                    this._close();
                    $rootScope.$emit('presentation:badgeUrl:changed', data);
                    $rootScope.$emit('presentation:image:updated', data);
                },
                $scope.endWithError
            );
        }
    }

    _close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

DesignBadgeLogoModal.$inject = [
    '$element',
    '$rootScope',
    '$scope',
    'data',
    'close',
    '$timeout',
    '$translate',
    '$window',
    'Presentation',
];
export default DesignBadgeLogoModal;
