'use strict';
angular.module('core.existingServices').factory('Address', [
    '$resource',
    function ($resource) {
        var v1AddressRest = $resource(
            'api/v1/address/:id',
            {id: '@id'},
            {
                save: {method: 'POST', isArray: false},
                list: {method: 'GET', isArray: true},
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: false},
            }
        );

        return {
            get: function (data, successCallback, errorCallback) {
                return v1AddressRest.get({id: data.id}, successCallback, errorCallback);
            },
            update: function (data, successCallback, errorCallback) {
                return v1AddressRest.update({id: data.id}, data, successCallback, errorCallback);
            },

            create: function (data, successCallback, errorCallback) {
                return v1AddressRest.save(data, successCallback, errorCallback);
            },
        };
    },
]);

