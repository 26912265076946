'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogFileUploadModal', [
    '$rootScope',
    '$timeout',
    'FileService',
    function ($rootScope, $timeout, FileService) {
        return new OgModal({
            name: 'file:upload',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                $scope.form = 'fileUploadForm';
                $scope.fileInput = {};
                    $scope.fileInput.file = null;

                    $scope.initDialog = function (data) {
                        $scope.customStyle = {};
                        $scope.types = data.types;
                        $scope.eventId = data.eventId;
                        $scope.fileInput.file = undefined;
                        jQuery('#fileUrl').val('');
                        $scope.okCallBack = data.okCallBack;
                    };

                    $scope.$watch('fileInput.file', function (value) {
                        if (value) {
                            var validFormats = $scope.types;
                            var ext = value.name.substr(value.name.lastIndexOf('.') + 1);
                            if (validFormats && (validFormats.indexOf(ext) > -1 || validFormats.indexOf('*') > -1)) {
                                $scope.customStyle = {};
                                $scope.fileInput.file = value;
                            } else {
                                $scope.fileInput.file = null;
                            }
                        }
                    });

                    $scope.uploadFile = function () {
                        $scope.startProgress();
                        FileService.uploadFile(
                            $scope.fileInput.file,
                            (res) => {
                                FileService.saveEventFiles({fileStoreId: res.id, eventId: $scope.eventId}, (res) => {
                                    $scope.sizeError = false;
                                    var okCallBack = $scope.okCallBack;
                                    if (okCallBack) {
                                        okCallBack();
                                    }
                                    $scope.endProgress();
                                });
                                $scope.close();
                            },
                            $scope.endWithError
                        );
                    };

                    $scope.sizeTypeCheck = () => {
                        var uploadField = document.getElementById('fileUrl');
                        if (uploadField.files[0].size > 10485760) {
                            $scope.customStyle.sizeError = { color: 'red' };
                            uploadField.value = '';
                        }
                    };
                },
            });
        },
    ]);

