'use strict';
import { ScopeFunctions } from '../../../../../../utils/global/scopeFunctions';
import eventSitePreviewModal from '../../../../../modals/modals2.0/event-site-preview';
import participantConfirEmailPreviewModal from '../../../../../modals/modals2.0/participant-confirmation-email-preview';
import { OgModal } from '../../../../../../utils/global/ogModal';

// Register `participants.invitation` component, along with its associated controller and template
angular.
    module('participants.invitation').
    component('participantinvitation', {
        templateUrl: './app/components/modules/event/event_detail/participants/invitation/invitation.view.html',
        controllerAs: 'invitationCtrl',
        controller: [
            '$state',
            '$rootScope',
            '$stateParams',
            '$scope',
            'Participant',
            'Event',
            'UserGroup',
            'AuthenticationService',
            'EventPresentation',
            'ModalService',
            'ActiveTabService',
            function InvitationController(
                $state,
                $rootScope,
                $stateParams,
                $scope,
                Participant,
                Event,
                UserGroup,
                AuthenticationService,
                EventPresentation,
                ModalService,
                ActiveTabService
            ) {
                var vm = this;
                vm.init = initFn;
                // vm.invitationTab = 'form';
                vm.setInvitationTab = setInvitationTabFn;
                vm.getInvitationTab = getInvitationTabFn;
                vm.isSoftBlockEnabled = isSoftBlockEnabledFn;
                vm.previewInvitation = previewInvitationFn;
                vm.registrationFormPreview = registrationFormPreviewFn;
                vm.previewConfirmationEmail = previewConfirmationEmailFn;
                vm.sharePublicly = sharePubliclyFn;
                vm.previewEventSite = previewEventSiteFn;
                vm.eventId = $stateParams.eventId;
                ScopeFunctions.addToScope($scope);

                vm.init();

                function initFn() {
                    Event.hasAnyRequestsSentInEventOrAlreadyAnswered(
                        vm.eventId,
                        function (data) {
                            vm.hasAnyRequestsSentInEvent = data.hasAnyRequestsSentInEvent;
                            vm.alreadyAnsweredQuestions = data.alreadyAnsweredQuestions;
                        },
                        $scope.endWithErrorOverlay
                    );
                    UserGroup.isInvitationBlocked(
                        { eventId: vm.eventId },
                        (response) => {
                            vm.isInvitationBlocked = response.isInvitationBlocked;
                        },
                        (error) => {
                            console.log('error:', error);
                        },
                        $scope.endWithErrorOverlay()
                    );
                }

                switch ($state.current.name) {
                    case 'event.detail.participants.invitation':
                        ActiveTabService.setInvitationTab('form');
                        $state.go('event.detail.participants.design_invitation.design-form');
                        break;
                    case 'event.detail.participants.design_invitation.design-form':
                        ActiveTabService.setInvitationTab('form');
                        break;
                    case 'event.detail.participants.design_invitation.design-registration_form':
                        ActiveTabService.setInvitationTab('registration_form');
                        break;
                    case 'event.detail.participants.design_invitation.design-event-infosite':
                        ActiveTabService.setInvitationTab('event_infosite');
                        break;
                    case 'event.detail.participants.design_invitation.design-confirm-email':
                        ActiveTabService.setInvitationTab('confirm_email');
                        break;

                    default:
                        ActiveTabService.setInvitationTab('form');
                    /*case 'event.detail.participants.design_invitation.design-badge':
                        vm.invitationTab = 'badge';
                        break;*/
                }

                function setInvitationTabFn(tab) {
                    ActiveTabService.setInvitationTab(tab);
                }

                function getInvitationTabFn() {
                    return ActiveTabService.getInvitationTab();
                }

                function previewInvitationFn() {
                    OgModal.open('participant:email:preview', {
                        eventId: vm.eventId,
                    });
                }

                function previewConfirmationEmailFn() {
                    $scope.startProgress();
                    EventPresentation.getInvitationPresentationForEvent(
                        {
                            eventId: this.eventId,
                            requestFrom: 'confirmationEmail',
                        },
                        (response) => {
                            $scope.endProgress();
                            let data = { info: response };
                            let participantConfirmEmailModalData = participantConfirEmailPreviewModal;
                            participantConfirmEmailModalData.inputs = { data };
                            ModalService.showModal(participantConfirmEmailModalData).then((modal) => {
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function registrationFormPreviewFn() {
                    Participant.getSelectedQueriesForEvent(
                        vm.eventId,
                        function (response) {
                            vm.selectedBasicQueryList = response.basicQueriesForEvent;
                            vm.customSelectedQueryList = response.customQueriesForEvent;
                            vm.useSMS = response.useSMS;
                            $scope.endProgress('participant-lists');
                            OgModal.open('participant:registration:form:preview', {
                                participantBasicQueryInfoList: vm.selectedBasicQueryList,
                                participantAdditionalQueryInfoList: vm.customSelectedQueryList,
                                preview: true,
                                useSMS: vm.useSMS,
                            });
                        },
                        $scope.endWithErrorOverlay('participant-lists')
                    );
                }

                function sharePubliclyFn() {
                    if (!AuthenticationService.checkIfUserVerifiedAndAlert() || isSoftBlockEnabledFn()) {
                        return;
                    }
                    OgModal.open('event:site:share', { eventId: vm.eventId, type: 'EVENT_PRESENTATION' });
                }

                function isSoftBlockEnabledFn() {
                    return vm.isInvitationBlocked && !vm.hasAnyRequestsSentInEvent && !vm.alreadyAnsweredQuestions;
                }

                function previewEventSiteFn() {
                    $scope.startProgress();
                    EventPresentation.getInvitationPresentationForEvent(
                        {
                            eventId: this.eventId,
                            requestFrom: 'infoSite',
                        },
                        (response) => {
                            $scope.endProgress();
                            let data = { info: response };
                            let eventSitePreviewModalData = eventSitePreviewModal;
                            eventSitePreviewModalData.inputs = { data };
                            ModalService.showModal(eventSitePreviewModalData).then((modal) => {
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                $rootScope.$on('keepBadgeDesignActive', function (event) {
                    ActiveTabService.setInvitationTab('badge');
                });

                angular.element(document).ready(function () {
                    console.log('page loading completed');
                    $rootScope.$broadcast('notification:change');
                });
            }
        ]
    });
