/**
 * Created by kshitij on 12/19/17.
 */
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class customMessageModalCtrl {
    constructor(data, $scope, close, $element, $rootScope, $sce) {
        this._injected = {
            data,
            $scope,
            close,
            $element,
            $rootScope,
            $sce,
        };

        this.title = data.title || 'dialog.notification.title';
        this.message = $sce.trustAsHtml(data.message);
        let _baseUrl = './app/components/modals/modals2.0/custom-message-modal/partials/';
        this.templateUrl = _baseUrl + '_' + $rootScope.langSelected + '_terms_and_conditions.html';

        ScopeFunctions.addToScope($scope);
    }

    _close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}
customMessageModalCtrl.$inject = ['data', '$scope', 'close', '$element', '$rootScope', '$sce'];
export default customMessageModalCtrl;
