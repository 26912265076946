'use strict';
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

angular.module('show.page').component('showPage', {
    controller: ['$scope', '$location', 'LinkService', '$stateParams',
        function ShowPageControllerFn($scope, $location, LinkService, $stateParams) {
            ScopeFunctions.addToScope($scope);
            var link = JSON.parse($stateParams.link);
            link.external = true;
            var go = function () {
                var url = LinkService.createHref(link);
                $location.url(url);
            };
            go();
        }]
});
