'use strict';
import { OgModal } from "../../utils/global/ogModal";
import { ScopeFunctions } from "../../utils/global/scopeFunctions";
import _ from 'underscore';

angular.module('dialog').directive('ogTaskEditModal', [
    '$rootScope',
    'CustomerUser',
    'ValidationService',
    'Event',
    'Task',
    'RequestOffer',
    'Activity',
    '$timeout',
    'FieldChangedSvc',
    'ChatMessageCountStore',
    function (
        $rootScope,
        CustomerUser,
        ValidationService,
        Event,
        Task,
        RequestOffer,
        Activity,
        $timeout,
        FieldChangedSvc,
        ChatMessageCountStore
    ) {
        return new OgModal({
            name: 'task:edit',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                var modes = {
                    TASK: 'task',
                    MESSAGE: 'message',
                    CREATE_MESSAGE: 'create_message',
                };
                var fieldChanged = FieldChangedSvc;
                $scope.showTaskDialog = false;
                $scope.initDialog = function (data) {
                    $scope.showTaskDialog = true;
                    $scope.createTask = data.createTask;
                    $scope.changeCallback = data.changeCallback;
                    $scope.message = data.message;
                    $scope.eventId = $scope.message.links.Event[0].id;
                    $scope.visibleToSupplier = false;
                    if ($scope.message.offer && $scope.message.offer.id) {
                        RequestOffer.get({ offerId: $scope.message.offer.id }, function (offer) {
                            $scope.visibleToSupplier = offer.supplier;
                        });
                    }
                    $scope.task = $scope.message.task;
                    if ($scope.task) {
                        $scope.mode = modes.TASK;
                        Event.getUsers(
                            { eventId: $scope.eventId },
                            function (response) {
                                $scope.allowedUsers = response;
                            },
                            $rootScope.alert
                        );
                        $scope.startProgress();
                        Task.get(
                            $scope.task.id,
                            function (result) {
                                $scope.task = result;
                                $scope.defaultUsers = $scope.task.responsibles;
                                $scope.task.dueDate = new Date($scope.task.dueDate);
                                $scope.showTaskDialog = true;
                                $scope.loadMultipleSelect();
                                $scope.endProgress();
                            },
                            $rootScope.alert
                        );
                    } else {
                        if ($scope.message.id) {
                            $scope.mode = modes.MESSAGE;
                        } else {
                            $scope.mode = modes.CREATE_MESSAGE;
                        }
                        $scope.task = {
                            dueDate: new Date().getTime(),
                        };
                        $scope.showTaskDialog = true;
                        $scope.loadMultipleSelect();
                    }
                };

                function getResponsibleIdList(users) {
                    let userList = [];
                    angular.forEach(users, function (user) {
                        userList.push(user.id + '');
                    });
                    return userList;
                }

                $scope.getUserNameById = function (id) {
                    let user = getUserById(id);
                    console.log(user, 'User >>>>');
                    if (user) {
                        return user.name;
                    }
                };

                function getUserById(id) {
                    return _.find($scope.allowedUsers, (u) => {
                        return u.id + '' === id + '';
                    });
                }

                $scope.handleOnchanged = function (modalName, currentValue) {
                    fieldChanged.checkOnChanged(modalName, currentValue);
                    let _responsibleUsers = [];
                    angular.forEach(currentValue, function (id) {
                        let user = getUserById(id);
                        if (user) {
                            _responsibleUsers.push(user);
                        }
                    });
                    $scope.task.responsibles = _responsibleUsers;
                };

                $scope.closeTaskDialog = function () {
                    $scope.showTaskDialog = false;
                };

                $scope.updateFinished = function () {
                    $scope.updatePendingTaskCount();
                    $scope.clearAttachedFiles();
                    $scope.closeTaskDialog();
                    $scope.changeCallback();
                    $scope.endProgress();
                };

                $scope.updatePendingTaskCount = function () {
                    Task.pendingTaskCount((res) => {
                        ChatMessageCountStore.setCreatorHomePageTaskCount(res.taskCount);
                    });
                };

                $scope.processMessage = function () {
                    if (tinyMCE) {
                        var editor = tinyMCE.get('message');
                        if (editor) {
                            $scope.message.messageBody = editor.getContent();
                        }
                    }
                    $scope.message.form = 'TaskForm';
                    Activity.save($scope.message, $scope.processTask);
                };

                $scope.processTask = function (message) {
                    $scope.startProgress();
                    var taskToSend = {
                        id: $scope.task.id,
                        done: $scope.task.done,
                        dueDate: new Date($scope.task.dueDate).getTime(),
                        responsibles: $scope.getSelectedUsers(),
                        form: 'TaskForm',
                    };
                    if ($scope.mode == modes.MESSAGE && !$scope.createTask) {
                        $scope.updateFinished();
                    } else {
                        Task.save(message.id, taskToSend, $scope.updateFinished);
                    }
                };

                $scope.$on('dialog:task:close', function () {
                    $scope.closeTaskDialog();
                });

                $scope.clearAttachedFiles = function () {
                    $rootScope.$broadcast('attachedFiles:clear', {});
                };
                $scope.getSelectedUsers = function () {
                    return $scope.task.responsibles;
                };
                $scope.$watch('showTaskDialog', function (value) {
                    if (value === true) {
                        jQuery('#og-task-edit-modal').modal('show');
                    } else if (value === false) {
                        jQuery('#og-task-edit-modal').modal('hide');
                    }
                });

                $scope.$watch('allowedUsers', function (value) {
                    if (value) {
                        $scope.responsibleIdList = getResponsibleIdList(value);
                    }
                });

                $scope.loadMultipleSelect = function () {
                    $().ready(function () {
                        $('#taskUserTagsId').select2();
                    });
                };

                $scope.$watch('defaultUsers', function (value) {
                    if (value) {
                        $scope.task.responsibleIds = getResponsibleIdList(value);
                    }
                });

                jQuery('#og-task-edit-modal').bind('closed', function () {
                    ValidationService.clear('TaskForm');
                });
            },
        });
    },
]);

