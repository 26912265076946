import { enableProdMode } from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as angular from 'angular';
import {setAngularJSGlobal} from '@angular/upgrade/static';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import ajsApp from './app/app.module.ajs';
import states from './app/app.config.ajs';
import interceptor from './app/core/interceptors/index.ajs';
import customTranslationLoader from './vendor/custom-translation/CustomTranslationLoader.js';
import customTranslationLoaderService from './vendor/custom-translation/CustomTranslationLoaderService.js';
import rootController from './app/components/root/RootControl.ajs';
import eventEnumConstants from './app/utils/EventEnums.js';
import globalConstants from './app/utils/GlobalConstants.js';
import ModalName from './app/utils/ModalName.js';
import requestOfferStatusUtilConst from './app/utils/request_offer_status/RequestOfferStatusUtils';
import PopoverTypeConst from './app/utils/PopoverTypeEnum';
import ogModalModule from './app/utils/global/ogModal.module';
import routeChangeHandler from './app/components/root/RouteChangeHandler.ajs';

interceptor(ajsApp);
customTranslationLoaderService(ajsApp);
customTranslationLoader(ajsApp);
rootController(ajsApp);
eventEnumConstants(ajsApp);
ogModalModule(ajsApp);
globalConstants(ajsApp);
ModalName(ajsApp);
requestOfferStatusUtilConst(ajsApp);
PopoverTypeConst(ajsApp);
states(ajsApp);
routeChangeHandler(ajsApp);

setAngularJSGlobal(angular);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
