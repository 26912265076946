import googleAddressUtil from '../../../utils/GoogleAddressUtil';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

class ogAutocompleteMultiAddressCtrl {
    constructor($scope, $element, $timeout) {
        this._injected = { $scope, $element, $timeout };

        this.addNewServiceAreaAddress = this.addNewServiceAreaAddress.bind(this);
        this.setLocationDetails = this.setLocationDetails.bind(this);
        this.initServiceAreaAddresses();
        ScopeFunctions.addToScope($scope);
    }

    addNewServiceAreaAddress(displayAddress) {
        if (displayAddress) {
            window.googleMapsCallback(() => {
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: displayAddress }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        var place = results[0];
                        this.setLocationDetails(place, displayAddress);
                    }
                    if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                        this.address = {};
                        this.resetAllAddress();
                        let tagEventsElm = jQuery('#google_address_sa');
                        tagEventsElm.tagit('removeTagByLabel', displayAddress);
                    }
                });
            });
        }
        if (displayAddress === '') {
            this.resetAllAddress();
        }
    }

    resetAllAddress() {
        const { $scope, $timeout } = this._injected;
        this.address.street = '';
        this.address.route = '';
        this.address.town = '';
        this.address.municipality = '';
        this.address.state = '';
        this.address.country = '';
        this.address.zipCode = '';
        this.address.geoCoordinates = '';
        this.address.streetAddress = '';
        $timeout(function () {
            $scope.$apply();
        });
    }

    setLocationDetails(place, _displayAddress) {
        const { $scope } = this._injected;
        this.address = googleAddressUtil.parseGoogleAddress(place);
        this.address.displayAddress = _displayAddress;
        this.addresses.push(this.address);
        $scope.$apply();
    }

    initServiceAreaAddresses() {
        const { $timeout, $scope } = this._injected;
        let tagEventsElm = jQuery('#google_address_sa');
        tagEventsElm.tagit({
            allowDuplicates: false,
            allowSpaces: true,
            singleFieldDelimiter: '~',
            autocomplete: {
                delay: 0,
                minLength: 2,
                position: { my: 'left bottom-25', at: 'left bottom', collision: 'flip flip' },
                source: function (request, response) {
                    let callback = function (predictions, status) {
                        // console.log("pre", predictions);
                        if (status !== google.maps.places.PlacesServiceStatus.OK) {
                            return;
                        }
                        let data = $.map(predictions, function (item) {
                            return item.description;
                        });
                        response(data);
                    };
                    let service = new google.maps.places.AutocompleteService();
                    service.getPlacePredictions(
                        { input: request.term, strictBounds: true, types: ['(regions)'] },
                        callback
                    );
                },
            },
            afterTagAdded: (event, ui) => {
                $timeout(() => {
                    let _indexOfServiceAreaAddressToAdd = _.findIndex(this.addresses, { displayAddress: ui.tagLabel });
                    if (_indexOfServiceAreaAddressToAdd === -1) {
                        this.addNewServiceAreaAddress(ui.tagLabel);
                    }
                });
            },
            afterTagRemoved: (event, ui) => {
                $timeout(() => {
                    let _indexOfServiceAreaAddressToRemove = _.findIndex(this.addresses, {
                        displayAddress: ui.tagLabel,
                    });
                    if (_indexOfServiceAreaAddressToRemove > -1) {
                        this.addresses.splice(_indexOfServiceAreaAddressToRemove, 1);
                        $scope.$apply();
                    }
                });
            },
        });
        $timeout(() => {
            tagEventsElm.tagit('removeAll');
            if (this.addresses) {
                this.addresses.forEach((tag) => {
                    tagEventsElm.tagit('createTag', tag.displayAddress);
                });
            }
        }, 100);
    }
}

ogAutocompleteMultiAddressCtrl.$inject = ['$scope', '$element', '$timeout'];
export default ogAutocompleteMultiAddressCtrl;
