import controller from './og_offerpart_dropdown.controller';

(function () {
    'use strict';

    angular.module('ogOfferpartDropdown').directive('ogOfferpartDropdown', [
        function () {
            return {
                scope: {
                    placeholder: '@',
                    list: '=',
                    selected: '=',
                    isDisabled: '=',
                    required: '=',
                    remoteUrl: '=',
                    inputClass: '@',
                    minLength: '@',
                    updateSelectedPartNameCallback: '&',
                },
                templateUrl: './app/components/directives/og-offerpart-dropdown/og_offerpart_dropdown.html',
                restrict: 'AE',
                controller,
                controllerAs: 'OgOfferpartDropdownCtrl',
                bindToController: true,
            };
        },
    ]);
})();
