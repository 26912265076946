'use strict';
angular.module('core.existingServices').factory('CommonPresentation', [
    '$resource',
    '$log',
    '$http',
    function ($resource, $log, $http) {
        var presentationUpdateItemRest = $resource(
            'api/v1/commonPresentation/:presentationId/updateProperty',
            {presentationId: '@presentationId'},
            {
                updateProperty: {method: 'POST', isArray: false},
            }
        );

        var presentationByParamsRest = $resource(
            'api/v1/commonPresentation/byParams',
            {},
            {
                get: {method: 'POST', isArray: false},
            }
        );

        var pinImageRest = $resource(
            'api/v1/commonPresentation/pinImage',
            {},
            {
                save: {method: 'POST', isArray: false},
            }
        );

        var deleteImageRest = $resource(
            'api/v1/commonPresentation/deleteImage',
            {},
            {
                delete: {method: 'POST', isArray: false},
            }
        );

        var updatePresentationLogo = function (formData, successCallback, errorCallback) {
            $http
                .post('api/v1/commonPresentation/updatePresentationLogo', formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };

        return {
            get: function (params, successCallback, errorCallback) {
                presentationByParamsRest.get({}, params, successCallback, errorCallback);
            },
            updatePresentationLogo: function (formData, successCallback, errorCallback) {
                return updatePresentationLogo(formData, successCallback, errorCallback);
            },
            updateItem: function (presentationId, jsonObject, successCallback, errorCallback) {
                presentationUpdateItemRest.updateProperty(
                    {presentationId: presentationId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            pinImage: function (jsonObject, successCallback, errorCallback) {
                pinImageRest.save(jsonObject, successCallback, errorCallback);
            },
            deleteImage: function (jsonObject, successCallback, errorCallback) {
                deleteImageRest.delete(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

