import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';
/**
 * Created by Diwakar on 5/8/2017.
 */
class ParticipantDetailViewModalCtrl {
    constructor($scope, $rootScope, data, Participant, ParticipantService, ParticipantUtilService) {
        this._injected = {
            $scope,
            $rootScope,
            data,
            Participant,
            ParticipantService,
            ParticipantUtilService,
        };

        ScopeFunctions.addToScope($scope);
        this.createParticipant = false;
        this.eventId = data.eventId;
        this.successCallback = data.successCallback;
        this.basicInfoForEvent = angular.copy(data.basicInfoForEvent);
        this.additionalInfoOptionsForEvent = angular.copy(data.additionalInfoOptionsForEvent);
        let participant = angular.copy(data.participant);
        if (!participant) {
            this.title = 'dialog.participant.title';
            this.buttonText = 'common.add';
            this.createParticipant = true;
            participant = { attending: 'NOT_INVITED' };
            let participantAdditionalInfoList = [];
            let participantBasicInfoList = [];

            angular.forEach(this.basicInfoForEvent, function (n) {
                let map = {
                    detailNameEnglish: n.detailNameEnglish,
                    detailNameSwedish: n.detailNameSwedish,
                    type: n.type,
                    optionValues: n.optionValues,
                    fieldValue: '',
                    eventParticipantBasicQuery: {
                        id: n.eventParticipantBasicQuery.id,
                        isMandatory: n.eventParticipantBasicQuery.isMandatory,
                        shareWithSupplier: n.eventParticipantBasicQuery.shareWithSupplier,
                    },
                    isMandatory: n.eventParticipantBasicQuery.isMandatory,
                };
                participantBasicInfoList.push(map);
            });
            participant.participantBasicQueryInfoList = participantBasicInfoList;

            angular.forEach(this.additionalInfoOptionsForEvent, function (n) {
                if (n.isChecked) {
                    let map = {
                        detailNameEnglish: n.detailNameEnglish,
                        detailNameSwedish: n.detailNameSwedish,
                        type: n.type,
                        optionValues: n.optionValues,
                        fieldValue: '',
                        eventParticipantCustomQuery: {
                            id: n.id,
                        },
                        isMandatory: n.isMandatory,
                    };
                    participantAdditionalInfoList.push(map);
                }
            });
            participant.participantAdditionalQueryInfoList = participantAdditionalInfoList;
        } else {
            this.createParticipant = false;
            this.originalAttending = participant.attending;
            this.title = 'dialog.participant.edit.title';
            this.buttonText = 'common.save';

            angular.forEach(participant.participantBasicQueryInfoList, (partBasicInfo) => {
                let _optionValues = partBasicInfo.eventParticipantBasicQuery.optionValues;
                if (['DROPDOWN'].indexOf(partBasicInfo.type.name) > -1) {
                    let _fieldValue = partBasicInfo.fieldValue;
                    partBasicInfo.translatedOptions = ParticipantUtilService.getOptionValues(
                        _optionValues,
                        $rootScope.langSelected
                    );
                    partBasicInfo.fieldValue = ParticipantUtilService.getFieldValue(
                        _optionValues,
                        $rootScope.langSelected,
                        _fieldValue
                    );
                }
                if (['MULTISELECT'].indexOf(partBasicInfo.type.name) > -1) {
                    let _fieldValue = partBasicInfo.fieldValue;
                    partBasicInfo.translatedOptions = ParticipantUtilService.getOptionValues(
                        _optionValues,
                        $rootScope.langSelected
                    );
                    if (_fieldValue) {
                        _fieldValue = _fieldValue.split(' | ');
                    }
                    partBasicInfo.fieldValue = _fieldValue;
                }
            });

            angular.forEach(participant.participantAdditionalQueryInfoList, (partAddInfo) => {
                let _optionValues = partAddInfo.optionValues;
                if (['DROPDOWN'].indexOf(partAddInfo.type.name) > -1) {
                    let _fieldValue = partAddInfo.fieldValue;
                    partAddInfo.translatedOptions = ParticipantUtilService.getOptionValues(
                        _optionValues,
                        $rootScope.langSelected
                    );
                    partAddInfo.fieldValue = ParticipantUtilService.getFieldValue(
                        _optionValues,
                        $rootScope.langSelected,
                        _fieldValue
                    );
                }
                if (['MULTISELECT'].indexOf(partAddInfo.type.name) > -1) {
                    let _fieldValue = partAddInfo.fieldValue;
                    partAddInfo.translatedOptions = ParticipantUtilService.getOptionValues(
                        _optionValues,
                        $rootScope.langSelected
                    );
                    if (_fieldValue) {
                        partAddInfo.fieldValue = _fieldValue.split(' | ');
                    }
                }
            });
        }
        $scope.dataLoaded(participant);

        $scope.languageSelected = $rootScope.langSelected;

        $scope.$on('lang:change', function (event, data) {
            $scope.languageSelected = data.lang;
        });
    }

    /*getOptionValuesFn(options, lang) {
    if (options && options.indexOf("~") >= 0) {
        let _options = options.split("~");
        let _englishOptions = _options ? _options[0] : null;
        let _swedishOptions = _options.length > 1 ? _options[1] : null;
        let _norwegianOptions = _options.length > 2 ? _options[2] : null;
        let _danishOptions = _options.length > 3 ? _options[3] : null;
        let _finnishOptions = _options.length > 4 ? _options[4] : null;
        if(lang === 'en_US'){
            return _englishOptions? _englishOptions: _swedishOptions?_swedishOptions: _norwegianOptions ? _norwegianOptions :_danishOptions;
        }
        if(lang === 'sv_SV'){
            return _swedishOptions? _swedishOptions: _englishOptions?_englishOptions: _norwegianOptions ? _norwegianOptions : _danishOptions;
        }
        if(lang === 'nb_NB'){
            return _norwegianOptions? _norwegianOptions: _englishOptions?_englishOptions: _swedishOptions ? _swedishOptions : _danishOptions;
        }
        if(lang === 'da_DK'){
            return _danishOptions? _danishOptions: _swedishOptions?_swedishOptions: _englishOptions ? _englishOptions : _norwegianOptions;
        }
        if(lang === 'fi_FI'){
          return _finnishOptions?_finnishOptions: _danishOptions? _danishOptions: _swedishOptions?_swedishOptions: _englishOptions ? _englishOptions : _norwegianOptions;
        }
    } else {
        return options;
    }
  };*/

    getQuestionLabel(question) {
        const { ParticipantService, $scope } = this._injected;
        return ParticipantService.getQuestionLabel(question, $scope.languageSelected);
    }

    clearFeeDialog() {
        const { $scope } = this._injected;
        $scope.eventId = null;
        $scope.successCallback = null;
    }

    checkIfFieldMandatory(fieldMandatory, type, participantQueryType) {
        const { $scope } = this._injected;
        if (fieldMandatory && type == 'EMAIL' && participantQueryType == 'isBasic') {
            return true;
        } else {
            return fieldMandatory && ('ATTENDING' == $scope.data.attending || 'NOT_REPLIED' == $scope.data.attending);
        }
    }
}
ParticipantDetailViewModalCtrl.$inject = [
    '$scope',
    '$rootScope',
    'data',
    'Participant',
    'ParticipantService',
    'ParticipantUtilService',
];
export default ParticipantDetailViewModalCtrl;
