import _ from 'underscore';

class ogAdditionalQuestionsController {
    constructor($scope, $document, $parse, $rootScope, $translate, $timeout, $location, CommonFile) {
        this._injected = { $scope, $document, $parse, $rootScope, $translate, $timeout, $location, CommonFile };

        this.options = [];
        this.iconOptions = [];
        this.tabIndex = (Math.random() * 6).toString().replace(/\./g, '_');
        this.amountTypes = [
            { type: 'PEOPLE', name: $translate.instant('amount.type.PEOPLE') },
            { type: 'SAME_AS_EVENT', name: $translate.instant('amount.type.SAME_AS_EVENT') },
        ];
        this.currencyList = [
            {
                decimalSeparator: '.',
                id: 2373009,
                iso: 'SEK',
                name: 'Swedish krona',
                prefixed: false,
                symbol: 'kr',
                thousandsSeparator: ' ',
            },
            {
                decimalSeparator: '.',
                id: 2373010,
                iso: 'EUR',
                name: 'Euro',
                prefixed: false,
                symbol: '€',
                thousandsSeparator: '.',
            },
            {
                decimalSeparator: '.',
                id: 2373011,
                iso: 'GBP',
                name: 'British Pound',
                prefixed: true,
                symbol: '£',
                thousandsSeparator: ',',
            },
            {
                decimalSeparator: ',',
                id: 2373012,
                iso: 'DKK',
                name: 'Danish krone',
                prefixed: true,
                symbol: 'Dkk',
                thousandsSeparator: '.',
            },
            {
                decimalSeparator: ',',
                id: 2373013,
                iso: 'NOK',
                name: 'Norwegian Krone',
                prefixed: false,
                symbol: 'NOK',
                thousandsSeparator: ' ',
            },
        ];
        this.$onInit = function () {
            this.labelCode = $translate.instant(this.question.labelCode);
            this.supplierLabelCode = $translate.instant(this.question.supplierLabelCode);
        }
        let optionValues = [];
        let iconOptionValues = [];

        $scope.$watch(
            'dnRadioCtrl.question.listValueCodes',
            (newVal) => {
                this.translateOptionList(newVal);
            },
            true
        );

        $scope.$watch(
            'dnMultiCheckBoxCtrl.question.listValueCodes',
            (newVal) => {
                this.translateOptionList(newVal);
                let copyOptions = angular.copy(this.options);
                angular.forEach(copyOptions, function (option) {
                    let obj = {};
                    obj.name = option;
                    obj.value = false;
                    optionValues.push(obj);
                });
                this.options = optionValues;
            },
            true
        );

        $scope.$watch(
            'dnMultiCheckBoxCtrl.question.fieldValue',
            (newVal) => {
                if (newVal) {
                    let values = newVal.split('~');
                    angular.forEach(values, function (value) {
                        angular.forEach(optionValues, function (option) {
                            if (option.name === value) {
                                option.value = true;
                            }
                        });
                    });
                }
            },
            true
        );

        $scope.$watch(
            'dnIconMultiCheckBoxCtrl.question.iconList',
            (newVal) => {
                if (newVal) {
                    let copyIconList = angular.copy(newVal);
                    angular.forEach(copyIconList, (data) => {
                        let obj = {};
                        obj.name = data.name;
                        obj.nameCode = data.nameCode;
                        obj.iconId = data.icon.id;
                        obj.value = false;
                        iconOptionValues.push(obj);
                    });
                    this.iconOptions = iconOptionValues;
                }
            },
            true
        );

        $scope.$watch(
            'dnIconMultiCheckBoxCtrl.question.fieldValue',
            (newVal) => {
                if (newVal) {
                    let values = newVal.split('~');
                    angular.forEach(values, function (value) {
                        let sittingType = value.split('#');
                        let nameCode = sittingType[0];
                        let maxCapacity = sittingType[1];
                        angular.forEach(iconOptionValues, function (option) {
                            if (option.nameCode === nameCode) {
                                option.value = true;
                                option.maxCapacity = parseInt(maxCapacity);
                            }
                        });
                    });
                }
            },
            true
        );

        $scope.$watch(
            'dnDropDownCtrl.question.listValueCodes',
            (newVal) => {
                this.translateOptionList(newVal);
            },
            true
        );

        // $scope.$watch(
        //     'dnTextWithGoogleAutosuggestCtrl.question.fieldValue',
        //     (newVal) => {
        //         if (newVal) {
        //             this.initGoogleAddressField($scope);
        //         }
        //     },
        //     true
        // );

        $scope.$watch(
            'dnTextWithAmountTypeCtrl.question.fieldValue',
            (newVal) => {
                if (newVal) {
                    let values = newVal.split('~');
                    this.amountType = values[1];
                    if (this.amountType === 'SAME_AS_EVENT') {
                        this.amount = this.question.participantAmount
                            ? this.question.participantAmount
                            : this.participantAmount;
                    } else {
                        this.amount = values[0];
                    }
                } else {
                    this.amountType = 'SAME_AS_EVENT';
                    this.amount = this.question.participantAmount
                        ? this.question.participantAmount
                        : this.participantAmount;
                    if (this.question.type.name === 'TEXT_WITH_AMOUNT_TYPE') {
                        this.question.fieldValue = this.amount + '~' + this.amountType;
                    }
                }
            },
            true
        );

        this.parseBoolean = this.parseBoolean.bind(this);
        this.translateOptionList = this.translateOptionList.bind(this);
        this.hasCheckedValues = this.hasCheckedValues.bind(this);
        this.hasPartCommentDataChanged = this.hasPartCommentDataChanged.bind(this);
        this.getFileUrl = this.getFileUrl.bind(this);
    }

    getFileUrl(option) {
        let { CommonFile } = this._injected;
        let fileUrl = CommonFile.getBaseUrl() + 'api/v1/file/getFile/' + option.icon.id;
        return fileUrl;
    }

    checkMaxCapacity() {
        return _.some(this.iconOptions, function (num) {
            return num.maxCapacity;
        });
    }

    parseBoolean(bool) {
        return String(bool) === 'true';
    }

    amountChange() {
        this.question.fieldValue = this.amount + '~' + this.amountType;
        this.onChange(this.question, this.question.fieldValue);
    }

    checkAndSetIconMultiCheckBoxVal() {
        let copyIconOptions = angular.copy(this.iconOptions);
        let newFieldValues = [];
        angular.forEach(copyIconOptions, (opt) => {
            if (opt.value && opt.maxCapacity) {
                let fieldValue = opt.nameCode + '#' + opt.maxCapacity;
                newFieldValues.push(fieldValue);
            }
        });
        this.question.fieldValue = newFieldValues.join('~');
    }

    checkIfAmountTypeChanged() {
        if (this.amountType === 'SAME_AS_EVENT') {
            this.amount = this.question.participantAmount;
        }
        this.amountChange();
    }

    getQuestionId() {
        this.questionId = this.question.id ? this.question.id : this.question.questionId;
        return this.questionId;
    }

    translateOptionList(newVal) {
        let { $translate, $rootScope } = this._injected;
        if (newVal && newVal.length) {
            if (Array.isArray(newVal)) {
                this.options = $translate.instant(newVal);
            } else {
                let optionValues = $translate.instant(newVal);
                if ($rootScope.langSelected === 'en_US') {
                    this.options = optionValues.split('~')[0].split(',');
                } else if ($rootScope.langSelected === 'sv_SV') {
                    this.options = optionValues.split('~')[1].split(',');
                } else if ($rootScope.langSelected === 'nb_NB') {
                    this.options = optionValues.split('~')[2].split(',');
                } else if ($rootScope.langSelected === 'da_DK') {
                    this.options = optionValues.split('~')[3].split(',');
                } else if ($rootScope.langSelected === 'fi_FI') {
                    this.options = optionValues.split('~')[4].split(',');
                }
            }
        }
    }

    hasCheckedValues(options) {
        let checkedValue = _.findWhere(this.options, { value: true });
        return checkedValue;
    }

    hasPartCommentDataChanged(id) {
        if (this.partCommentDataList && this.showUpdatedChanges) {
            return this.partCommentDataList.indexOf(id.toString()) !== -1;
        }
        return false;
    }

    initGoogleAddressField(questionId) {
        let { $timeout, $scope } = this._injected;
        $timeout(function () {
            let input = document.querySelector('#google_place_' + questionId);
            var autocomplete = new google.maps.places.Autocomplete(input, {});
            google.maps.event.addListener(autocomplete, 'place_changed', () => {
                $scope.dnTextWithGoogleAutosuggestCtrl.question.fieldValue = input.value;
                $scope.dnTextWithGoogleAutosuggestCtrl.onChange(
                    $scope.dnTextWithGoogleAutosuggestCtrl.question,
                    $scope.dnTextWithGoogleAutosuggestCtrl.question.fieldValue
                );
            });
        }, 100);
    }
}

ogAdditionalQuestionsController.$inject = [
    '$scope',
    '$document',
    '$parse',
    '$rootScope',
    '$translate',
    '$timeout',
    '$location',
    'CommonFile',
];
export default ogAdditionalQuestionsController;
