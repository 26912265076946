const ACCEPTED = ['ACCEPTED'];
const DECLINED = ['DECLINED'];
const ABC = ['ADDED_BY_CREATOR'];
const ABS = ['ADDED_BY_SUPPLIER'];
const DBS = ['DELETED_BY_SUPPLIER'];
const DWFC = ['DELETED_WAITING_FOR_CREATOR'];
const EWFS = ['EDITED_WAITING_FOR_SUPPLIER'];
const EBS = ['EDITED_BY_SUPPLIER'];
const AWFC = ['ADDED_WAITING_FOR_CREATOR'];
const AWFS = ['ADDED_WAITING_FOR_SUPPLIER'];
const ACWC = ['ACCEPTED_BY_CREATOR_WITH_CHANGES'];
const EWFC_EWFSD_AWFSD_DWFSD = [
    'EDITED_WAITING_FOR_CREATOR',
    'EDITED_WAITING_FOR_SUPPLIER_DECLINED',
    'ADDED_WAITING_FOR_SUPPLIER_DECLINED',
    'DELETED_WAITING_FOR_SUPPLIER_DECLINED',
];
const EWFC_ACCEPTED_DECLINED = ['EDITED_WAITING_FOR_CREATOR', ...ACCEPTED, ...DECLINED];
const EWFS_ACCEPTED_DECLINED = ['EDITED_WAITING_FOR_SUPPLIER', ...ACCEPTED, ...DECLINED];

const EWFS_EWFCD_AWFCD_DWFCD = [
    'EDITED_WAITING_FOR_SUPPLIER',
    'EDITED_WAITING_FOR_CREATOR_DECLINED',
    'ADDED_WAITING_FOR_CREATOR_DECLINED',
    'DELETED_WAITING_FOR_CREATOR_DECLINED',
];

class NegotiationTooltipPartStatusUtil {
    static ABC(part) {
        return ABC.indexOf(part.status.name) > -1;
    }

    static ABS(part) {
        return ABS.indexOf(part.status.name) > -1;
    }

    static DBS(part) {
        return DBS.indexOf(part.status.name) > -1;
    }

    static DWFC(part) {
        return DWFC.indexOf(part.status.name) > -1;
    }

    static EBS(part) {
        return EBS.indexOf(part.status.name) > -1;
    }

    static ACCEPTED(part) {
        return ACCEPTED.indexOf(part.status.name) > -1;
    }

    static EWFS(part) {
        return EWFS.indexOf(part.status.name) > -1;
    }

    static EWFC_EWFSD_AWFSD_DWFSD(part) {
        return EWFC_EWFSD_AWFSD_DWFSD.indexOf(part.status.name) > -1;
    }

    static EWFS_EWFCD_AWFCD_DWFCD(part) {
        return EWFS_EWFCD_AWFCD_DWFCD.indexOf(part.status.name) > -1;
    }

    static EWFC_ACCEPTED_DECLINED(part) {
        return EWFC_ACCEPTED_DECLINED.indexOf(part.status.name) > -1;
    }

    static EWFS_ACCEPTED_DECLINED(part) {
        return EWFS_ACCEPTED_DECLINED.indexOf(part.status.name) > -1;
    }

    static AWFC(part) {
        return AWFC.indexOf(part.status.name) > -1;
    }

    static AWFS(part) {
        return AWFS.indexOf(part.status.name) > -1;
    }

    static ACWC(part) {
        return ACWC.indexOf(part.status.name) > -1;
    }
}

const tooltipPartStatusUtil = NegotiationTooltipPartStatusUtil;
export default tooltipPartStatusUtil;
