'use strict';

import './../../directives/directive.module'

import './profile-settings-modal/my_groups/og_my_groups.module'
import './profile-settings-modal/my_groups/og_my_groups.directive'

import './offer-preview/offer_negotiation_alert/og_offer_negotiation_alert.module'
import './offer-preview/offer_negotiation_alert/og_offer_negotiation_alert.directive'

import '../dialog.module';
import '../ogAddRequestModal';
import '../ogAlertModal';
import '../ogBookingTermsModal';
import '../ogCategoryTemplateResultTileModal';
import '../ogChangeInvitationDetailsModal';
import '../ogChangePasswordModal';
import '../ogConfirmationModal';
import '../ogCreateEventUsingTemplateOrGuideModal';
import '../ogCropBigImageModal';
import '../ogCropLogoUrlModal';
import '../ogCropPresentationImageModal';
import '../ogCropSmallImageModal';
import '../ogCropThirdImageModal';
import '../ogEditBillingInfoDetailModal';
import '../ogEventDeleteConfirmationModal';
import '../ogEventScheduleModal';
import '../ogEventSiteShareModal';
import '../ogFileUploadModal';
import '../ogImageCropModal';
import '../ogMapModal';
import '../ogMessageSupplierModal';
import '../ogNewRequestSwitchModal';
import '../ogOfferConfirmedModal';
import '../ogOfferDetailMessageModal';
import '../ogOfferDetailNotesModal';
import '../ogParticipantEmailModal';
import '../ogParticipantEmailPreviewModal';
import '../ogParticipantImportModal';
import '../ogParticipantModal';
import '../ogParticipantRegistrationFormPreviewModal';
import '../ogParticipantsChangeStatusModal';
import '../ogParticipantsDeleteConfirmationModal';
import '../ogParticipantSendToModal';
import '../ogParticipantsImportFromEventModal';
import '../ogPresentationModal';
import '../ogRequestOfferPresentationModal';
import '../ogSettingsConfirmationModal';
import '../ogShareEventModal';
import '../ogShowBillingInfoModal';
import '../ogSupplierPresentationModal';
import '../ogSuppliersMapModal';
import '../ogTaskEditModal';
import '../ogUserTermsModal';
import "../ogAddUserToUserGroupModal";
import "../ogAddCollaboratorsModal";

// Define the `core` module
angular.module('modals', [
    'my.groups',
    'directive',
    'ogOfferNegotiationAlert',
    'dialog'
]);
