/**
 * Created by kshitij on 7/28/17.
 */
import accommodationColorCodeUtil from '../../../utils/accommodation/AccommodationColorCodeUtil';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

class AccommodationCtrl {
    constructor($scope, FieldChangedSvc, $timeout, EventEnum) {
        this._injected = {
            $scope,
            FieldChangedSvc,
            $timeout,
            EventEnum,
        };

        this.viewUpdateButton = false;
        this.addExtraAccommodation = false;

        ScopeFunctions.addToScope($scope);
        this.handleOnChanged = this.handleOnChanged.bind(this);
        this.init = this.init.bind(this);
        this.init();
    }

    addExtraTypeAccommodation() {
        this.addExtraAccommodation = true;
    }

    removeExtraTypeAccommodation() {
        this.addExtraAccommodation = false;
    }

    addSuitTypeAccommodation() {
        this.addSuitAccommodation = true;
    }

    addTripleTypeAccommodation() {
        this.addTripleAccommodation = true;
    }

    addQuadTypeAccommodation() {
        this.addQuadAccommodation = true;
    }

    addOtherTypeAccommodation() {
        let _defaultRoom = angular.copy(this.accommodation.defaultOtherRoom);
        _defaultRoom.allowAddRoomName = true;
        _defaultRoom._tempId = this.accommodation.otherRooms.length + 1;
        this.accommodation.otherRooms.push(_defaultRoom);
        this.addOtherAccommodation = true;
    }

    decrementAmount(part) {
        if (!part.actionRequired) {
            part.amount = parseInt(part.amount) - 1;
        }
    }

    handleOnChanged(modalName, value) {
        const { FieldChangedSvc } = this._injected;
        FieldChangedSvc.checkOnChanged(modalName, value);
        this.viewUpdateButton = FieldChangedSvc.isFieldChanged();
    }

    incrementAmount(part) {
        if (!part.actionRequired) {
            part.amount = parseInt(part.amount) > 0 ? parseInt(part.amount) + 1 : 0 + 1;
        }
    }

    cancelChangeForAllDays() {
        this.cancelChangeForAllDaysCallback();
    }

    beforeAfterEventAccommodation() {
        this.beforeAfterEventAccommodationCallback();
    }

    isDisabled() {
        let _editType = this.item.editType ? this.item.editType.name : null;
        let _isDenied = 'DENIED' === _editType;
        let _changeForAll = this.changeForAll === this.item.requestId && this.type !== 'ALL';
        let _actionRequiredPart = this.hasActionRequiredPart;
        let _isDisabled = _isDenied || _changeForAll || _actionRequiredPart;
        return _isDisabled;
    }

    synchronizeSingleRooms(amount) {
        this.synchronizeSingleRoomsCallback({ amount: amount });
    }

    synchronizeDoubleRooms(amount) {
        this.synchronizeDoubleRoomsCallback({ amount: amount });
    }

    synchronizeSuiteRooms(amount) {
        this.synchronizeSuiteRoomsCallback({ amount: amount });
    }

    synchronizeTripleRooms(amount) {
        this.synchronizeTripleRoomsCallback({ amount: amount });
    }

    synchronizeQuadRooms(amount) {
        this.synchronizeQuadRoomsCallback({ amount: amount });
    }

    synchronizeOtherRooms() {
        this.synchronizeOtherRoomsCallback({ otherRooms: this.accommodation.otherRooms });
    }

    showSuitAccommodation() {
        return this.addSuitAccommodation || this.hasSuite();
    }

    showTripleAccommodation() {
        return this.addTripleAccommodation || this.hasTriple();
    }

    showQuadAccommodation() {
        return this.addQuadAccommodation || this.hasQuad();
    }

    showOtherAccommodation() {
        return this.addOtherAccommodation || this.hasOther();
    }

    hasSuite() {
        return this.accommodation && this.accommodation.suite && this.accommodation.suite.amount > 0;
    }

    hasTriple() {
        return this.accommodation && this.accommodation.tripleRoom && this.accommodation.tripleRoom.amount > 0;
    }

    hasQuad() {
        return this.accommodation && this.accommodation.quadRoom && this.accommodation.quadRoom.amount > 0;
    }

    hasOther() {
        return this.accommodation && this.accommodation.otherRooms && !_.isEmpty(this.accommodation.otherRooms);
    }

    cancelRoom() {
        if (this.hasSuite) {
            this.accommodation.suite.amount = 0;
        }
        this.addExtraAccommodation = false;
    }

    cancelSuitAccommodation() {
        this.accommodation.suite.amount = 0;
        this.addSuitAccommodation = false;
    }

    cancelTripleAccommodation() {
        this.accommodation.tripleRoom.amount = 0;
        this.addTripleAccommodation = false;
    }

    cancelQuadAccommodation() {
        this.accommodation.quadRoom.amount = 0;
        this.addQuadAccommodation = false;
    }

    cancelOtherAccommodation(room) {
        this.addOtherAccommodation = false;
        let _currentRoomIndex = _.findIndex(this.accommodation.otherRooms, (_currentRoom) => {
            return _currentRoom.name === room.name && _currentRoom._tempId === room._tempId;
        });
        this.accommodation.otherRooms.splice(_currentRoomIndex, 1);

        angular.forEach(this.item.scheduleForRequest.timed, (dayRoom) => {
            let _currentRoomIndex = _.findIndex(dayRoom.otherRooms, (_currentRoom) => {
                return _currentRoom.name === room.name && _currentRoom._tempId === room._tempId;
            });
            dayRoom.otherRooms.splice(_currentRoomIndex, 1);
        });
    }

    deleteRoom(room) {
        const { $timeout } = this._injected;
        this.addExtraAccommodation = false;
        this.deleteCallback({ room: room, itemDetails: this.item });

        $timeout(() => {
            this.init();
        }, 500);
    }

    init() {
        this.addSuitAccommodation = false;
        this.addTripleAccommodation = false;
        this.addQuadAccommodation = false;
        this.addOtherAccommodation = false;
        if (this.hasSuite()) {
            this.addSuitAccommodation = true;
        }
        if (this.hasTriple()) {
            this.addTripleAccommodation = true;
        }
        if (this.hasQuad()) {
            this.addQuadAccommodation = true;
        }
        if (this.hasOther()) {
            this.addOtherAccommodation = true;
        }

        if (this.accommodationDay) {
            if (this.accommodationDay !== -1) {
                this.accommodationDay -= 1;
            }
            this.addExtraAccommodation = this.accommodationDay === this.accommodation.dayIndex;
        }
    }

    updateRoomName(room) {
        this.updateRoomNameCallback({ room: room, otherRooms: this.accommodation.otherRooms });
    }

    getColorCode() {
        let _isBeforeEvent = this.type === 'BEFORE_EVENT' || (this.accommodation && this.accommodation.isBeforeEvent);
        let _isAfterEvent = this.type === 'AFTER_EVENT' || (this.accommodation && this.accommodation.isAfterEvent);
        if (_isBeforeEvent || _isAfterEvent) {
            return accommodationColorCodeUtil.getDarkColor(this.item.colorCode);
        }
        return this.item.colorCode;
    }
}
AccommodationCtrl.$inject = ['$scope', 'FieldChangedSvc', '$timeout', 'EventEnum'];
export default AccommodationCtrl;
