(function () {
    'use strict';

    angular.module('ogProgressOverlay').directive('ogProgressOverlay', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                transclude: true,
                scope: {
                    name: '@',
                    showContent: '=',
                    hideOverlay: '=',
                },
                templateUrl: './app/components/directives/progress_overlay/og_progress_overlay.directive.view.html',
                controller: ['$scope', 'CommonFunctions', function ($scope, CommonFunctions) {
                    $scope.getPart = function () {
                        var name = $scope.name;
                        if (name == null) {
                            name = '_main';
                        }
                        if ($scope.$parent._progress) {
                            return $scope.$parent._progress.parts[name];
                        }
                        return null;
                    };

                        $scope.showPartContent = function () {
                            var part = $scope.getPart();
                            return (
                                part == null ||
                                (!(part.inProgress === true || part.inProgress > 0) && part.error == null) ||
                                showContent(part)
                            );
                        };

                        $scope.showSpinner = function () {
                            if ($scope.hideOverlay) {
                                return false;
                            }
                            if ($scope.$parent.inProgress === false || $scope.$parent.inProgress === 0) {
                                return false;
                            }
                            var part = $scope.getPart();
                            return part != null && !part.silent && (part.inProgress === true || part.inProgress > 0);
                        };

                        $scope.showError = function () {
                            var part = $scope.getPart();
                            return part != null && part.error != null;
                        };

                        $scope.errorMessages = function () {
                            var part = $scope.getPart();
                            if (!part) {
                                return [];
                            }
                            var error = part.error;
                            if (!error) {
                                return [];
                            }
                            return CommonFunctions.errorMessages(error, true);
                        };

                        $scope.showCloseButton = function () {
                            var part = $scope.getPart();
                            return part == null || showContent(part);
                        };

                        $scope.closeError = function () {
                            var part = $scope.getPart();
                            if (part != null) {
                                part.error = null;
                            }
                        };

                        var showContent = function (part) {
                            if (part.showContent == null) {
                                return $scope.showContent;
                            }
                            return part.showContent;
                        };
                    },
                ],
            };
        },
    ]);
})();
