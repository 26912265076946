/*global angular */
'use strict';

angular.module('core.existingServices').factory('Request', [
    '$resource',
    function ($resource) {
        var requestRest = $resource(
            'api/v1/request/:requestId',
            {requestId: '@requestId'},
            {
                save: {method: 'POST', isArray: false},
                list: {method: 'GET', isArray: true, params: {eventId: '@eventId'}},
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT'},
                delete: {method: 'DELETE', isArray: false},
            }
        );
        var requestStructureRest = $resource(
            'api/v1/request/:requestId/structure',
            {requestId: '@requestId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var requestStructureForDetailRest = $resource(
            'api/v1/request/:requestId/structureForDetail',
            {requestId: '@requestId', offerId: '@offerId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );
        var requestTemplateRest = $resource(
            'api/v1/request/template',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );
        var requestTemplateListRest = $resource(
            'api/v1/request/templates',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );
        var requestFromTemplateRest = $resource('api/v1/request/fromTemplate', {
            save: {method: 'POST', isArray: false},
        });
        var requestUsersRest = $resource(
            'api/v1/request/users/:requestId',
            {requestId: '@requestId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var requestDeleteUserRest = $resource(
            'api/v1/request/:requestId/user/:userId',
            {requestId: '@requestId', userId: '@userId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );
        var requestShareRest = $resource(
            'api/v1/request/:requestId/share',
            {requestId: '@requestId'},
            {
                save: {method: 'POST', isArray: false},
            }
        );
        var requestOrderRest = $resource(
            'api/v1/request/updateRequestOrders',
            {},
            {
                save: {method: 'POST', isArray: true},
            }
        );
        var requestsForMessageByEventIdRest = $resource(
            'api/v1/request/getRequestsForMessageByEventId/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: true},
            }
        );
        var requestSentStatus = $resource(
            'api/v1/request/:requestId/requestSentStatus',
            {requestId: '@requestId'},
            {
                get: {method: 'GET', isArray: false},
                put: {method: 'PUT', isArray: false},
            }
        );
        var updateRequestMarkupValueRest = $resource(
            'api/v1/request/updateRequestMarkupValue/:requestId',
            {requestId: '@requestId'},
            {
                put: {method: 'PUT', isArray: false},
            }
        );
        var v1GetRequestCategoryListForMap = $resource(
            'api/v1/request/getRequestCategoryListForMap',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            save: function (newRequest, successCallback, errorCallback) {
                var resource = requestRest;
                if (newRequest.templateId) {
                    resource = requestFromTemplateRest;
                }
                return resource.save({}, newRequest, successCallback, errorCallback);
            },
            saveTemplate: function (data, success) {
                return requestTemplateRest.save({}, data, success);
            },
            list: function (jsonObject, successCallback, errorCallback) {
                return requestRest.list(jsonObject, successCallback, errorCallback);
            },
            listTemplates: function (jsonObject, successCallback, errorCallback) {
                return requestTemplateListRest.list(jsonObject, successCallback, errorCallback);
            },
            get: function (jsonObject, successCallback, errorCallback) {
                return requestRest.get(jsonObject, successCallback, errorCallback);
            },
            getStructure: function (requestId, successCallback, errorCallback) {
                return requestStructureRest.get({requestId: requestId}, successCallback, errorCallback);
            },
            getStructureForDetail: function (params, successCallback, errorCallback) {
                return requestStructureForDetailRest.get(params, params, successCallback, errorCallback);
            },
            updateOrder: function (eventId, requestIdOrder, successCallback, errorCallback) {
                return requestOrderRest.save(
                    {eventId: eventId, requestIdOrder: requestIdOrder},
                    successCallback,
                    errorCallback
                );
            },
            update: function (requestId, jsonObject, successCallback, errorCallback) {
                return requestRest.update({requestId: requestId}, jsonObject, successCallback, errorCallback);
            },
            delete: function (eventId, requestId, jsonObject, successCallback, errorCallback) {
                return requestRest['delete'](
                    {eventId: eventId, requestId: requestId},
                    successCallback,
                    errorCallback
                );
            },
            getUsers: function (jsonObject, success, error) {
                return requestUsersRest.list(jsonObject, success, error);
            },
            removeSharedUser: function (jsonObject, successCallback, errorCallback) {
                return requestDeleteUserRest['delete'](jsonObject, successCallback, errorCallback);
            },
            share: function (requestId, jsonObject, successCallback, errorCallback) {
                return requestShareRest.save({requestId: requestId}, jsonObject, successCallback, errorCallback);
            },
            requestsForMessageByEventId: function (eventId, successCallback, errorCallback) {
                return requestsForMessageByEventIdRest.list({eventId: eventId}, successCallback, errorCallback);
            },
            setRequestSentStatus: function (requestId, successCallback, errorCallback) {
                return requestSentStatus.put({requestId: requestId}, successCallback, errorCallback);
            },
            getRequestSentStatus: function (requestId, successCallback, errorCallback) {
                return requestSentStatus.get({requestId: requestId}, successCallback, errorCallback);
            },
            v1GetRequestCategoryListForMap: function (jsonObject, successCallback, errorCallback) {
                return v1GetRequestCategoryListForMap.get(jsonObject, successCallback, errorCallback);
            },
            updateRequestMarkupValue: function (requestId, data, successCallback, errorCallback) {
                return updateRequestMarkupValueRest.put(
                    {requestId: requestId},
                    data,
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);

