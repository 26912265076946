import googleAddressUtil from '../../../../../utils/GoogleAddressUtil';
import { ScopeFunctions } from "../../../../../utils/global/scopeFunctions";
import _ from 'underscore';

const SUPPLIER_CATEGORIES = ['HOTEL', 'BUS'];
const CATEGORY_HOTEL_AND_CONFERENCE = 'Hotell & konferens';

(function () {
    'use strict';
    angular.module('my.groups').directive('ogMyGroups', [
        function () {
            return {
                restrict: 'EA',
                templateUrl:
                    './app/components/modals/modals2.0/profile-settings-modal/my_groups/og_my_groups.directive.view.html',
                replace: true,
                scope: {
                    group: '=',
                    userEmail: '=',
                    createNewGroup: '=',
                    createGroupCallback: '&',
                    updateGroupCallback: '&',
                    openDefaultTab: '=',
                    focusOnEmailField: '=',
                    viewPresentationCallback: '&',
                },
                link: function ($scope) { },
                controller: [
                    '$rootScope',
                    '$sce',
                    '$scope',
                    '$timeout',
                    'CustomerUser',
                    'UserGroup',
                    'FortNoxConfig',
                    'GeoTag',
                    'Address',
                    'Presentation',
                    'Supplier',
                    'AuthenticationService',
                    'FieldChangedSvc',
                    'GlobalOption',
                    'SupplierDetailInfo',
                    '$translate',
                    'ClientService',
                    'CommonFile',
                    function (
                        $rootScope,
                        $sce,
                        $scope,
                        $timeout,
                        CustomerUser,
                        UserGroup,
                        FortNoxConfig,
                        GeoTag,
                        Address,
                        Presentation,
                        Supplier,
                        AuthenticationService,
                        FieldChangedSvc,
                        GlobalOption,
                        SupplierDetailInfo,
                        $translate,
                        ClientService,
                        CommonFile
                    ) {
                        ScopeFunctions.addToScope($scope);
                        /*$scope.enableTermsAndConditionsEdit = false;*/
                        $scope.usersLoaded = false;
                        $scope.editDetails = false;
                        $scope.enableManualAddressEntry = false;
                        $scope.enableTermsAndConditionsEdit = false;
                        $scope.dayDropDownList = [5, 10, 15, 20, 30];
                        $scope.supplierCapacities = { category: null };
                        $scope.alertMessage = { type: null, key: null };
                        $scope.roleGranted = false;
                        $scope.fillAddress = {};

                        $scope.$watch('group', (newVal) => {
                            if (newVal) {
                                $scope.alertMessage = { type: null, key: null };
                                getMembersInUserGroup(newVal);
                            }
                        });
                        $scope.$watch('openDefaultTab', (newVal) => {
                            $scope.openTab(newVal);
                        });
                        $scope.$watch('createNewGroup', (newVal) => {
                            if (newVal) {
                                $scope.currentTab = 'GROUP';
                                fetchCompanyNameFn($scope.userEmail);
                            } else {
                                $scope.parentUserGroups = null;
                                if ($scope.selectedUserGroup) {
                                    $scope.selectedUserGroup.parentGroupId = null;
                                }
                            }
                        });
                        initFn();

                        var geoCoder;
                        var autocomplete;
                        window.googleMapsCallback(function () {
                            geoCoder = new google.maps.Geocoder();
                        });

                        function initGoogleAutoComplete() {
                            window.googleMapsCallback(function () {
                                // The use of unique Id for google places option causes error in LIVE
                                // Apparently, google API tries to add the "autocomplete" effect before the element gets ready
                                var options = {
                                    strictBounds: false,
                                    types: ['establishment'],
                                };
                                autocomplete = new google.maps.places.Autocomplete(
                                    $('#google_places_for_company')[0],
                                    options
                                );
                                google.maps.event.addListener(autocomplete, 'place_changed', function () {
                                    var place = autocomplete.getPlace();
                                    $('#google_places_for_company').val(place.name);
                                    $scope.selectedUserGroup.supplier.name = $('#google_places_for_company').val();
                                    $scope.selectedUserGroup.supplier.officeAddress =
                                        googleAddressUtil.parseGoogleAddress(place);
                                    $scope.$apply();
                                });
                            });
                        }

                        var fieldChanged = FieldChangedSvc;

                        $scope.setShowDeleteUserConfirmation = function (user) {
                            user.showDeleteUserConfirmation = true;
                        };

                        $scope.resetShowDeleteUserConfirmation = function (user) {
                            user.showDeleteUserConfirmation = false;
                        };

                        $scope.removeUserFromGroup = function (user, group) {
                            $scope.startProgress();
                            CustomerUser.removeUserFromGroup(
                                { groupId: group.id, userId: user.id },
                                (response) => {
                                    group.usersInGroup = response;
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay()
                            );
                        };

                        $scope.editSupplierDetails = function () {
                            $scope.editDetails = true;
                            $scope.enableManualAddressEntry = false;
                        };

                        $scope.resetAlertMessage = function () {
                            $scope.alertMessage = { type: null, key: null };
                        };

                        $scope.groupNameChanged = function () {
                            if (!$scope.selectedUserGroup) {
                                return false;
                            }
                            return (
                                $scope.selectedUserGroup.name &&
                                $scope.selectedUserGroupOriginal.name &&
                                $scope.selectedUserGroup.name != $scope.selectedUserGroupOriginal.name
                            );
                        };

                        $scope.shouldDisable = function () {
                            if (!$scope.selectedUserGroup.name) {
                                return true;
                            }
                            return $scope.parentUserGroups.length !== 0 && !$scope.selectedUserGroup.parentGroupId;
                        };

                        function hasDataChanged(originalData, data) {
                            return (originalData && data && originalData != data) || (!originalData && data);
                        }

                        $scope.integrationChanged = function () {
                            if (!$scope.selectedUserGroup) {
                                return false;
                            }
                            let integrationData = $scope.integrationData;
                            let originalIntegrationData = $scope.originalIntergationData;
                            if (integrationData && originalIntegrationData) {
                                let hasClientSecretChanged = hasDataChanged(
                                    originalIntegrationData.clientSecret,
                                    integrationData.clientSecret
                                );
                                let hasClientIdChanged = hasDataChanged(
                                    originalIntegrationData.clientId,
                                    integrationData.clientId
                                );
                                let hasAuthorizationCodeChanged = hasDataChanged(
                                    originalIntegrationData.authorizationCode,
                                    integrationData.authorizationCode
                                );
                                return hasClientSecretChanged || hasClientIdChanged || hasAuthorizationCodeChanged;
                            }
                            return false;
                        };

                        function loadUserGroupData(data) {
                            fieldChanged.reset();
                            fieldChanged.init(angular.copy(data));
                            $scope.selectedUserGroup = angular.copy(data);
                            $scope.selectedUserGroupOriginal = angular.copy(data);
                            $scope.getFortNoxConfig($scope.selectedUserGroup);
                            if ($scope.selectedUserGroup.supplier) {
                                //$scope.serviceAreaAddresses = $scope.selectedUserGroup.supplier.serviceAreaAddresses;
                                $scope.useCustomTerms = $scope.selectedUserGroup.supplier.bookingTerms != null;
                            }
                        }

                        function getMembersInUserGroup(group) {
                            group.usersInGroup = [];
                            group.newUsers = [];
                            loadUserGroupData(group);
                            if (group.id) {
                                UserGroup.listUsers({ id: group.id }, function (response) {
                                    group.usersInGroup = response;
                                });
                            }
                        }

                        $scope.updateUserGroup = function () {
                            $scope.startProgress();
                            UserGroup.update(
                                $scope.selectedUserGroup,
                                function (group) {
                                    loadUserGroupData(group);
                                    $scope.alertMessage.key = 'dialog.user.group.update.success';
                                    $scope.alertMessage.type = 'SUCCESS';
                                    $scope.endProgress();
                                    $rootScope.$emit('user.group.create.update.success', group);
                                    // $scope.updateGroupCallback({userGroup: group});
                                    getMembersInUserGroup(group);
                                },
                                $scope.endWithError
                            );
                        };

                        $scope.updateGroupOwner = function (group, member) {
                            UserGroup.updateGroupOwner(
                                { groupId: group.id, userId: member.id },
                                function (response) {
                                    if (response.ownerEmail != null) {
                                        getMembersInUserGroup(group); //refresh
                                    } else {
                                        //make UI changes if needed
                                        console.log("couldn't update the owner");
                                    }
                                },
                                function (error) {
                                    // make UI changes if needed
                                    console.log('Something went wrong while updating group owner : ' + error);
                                }
                            );
                            $scope.resetUserGroupOwnerConfirmation(member); // close the dialog box
                        };

                        function loadFortNoxConfig(data) {
                            $scope.integrationData = angular.copy(data);
                            $scope.originalIntergationData = angular.copy(data);
                        }

                        $scope.getFortNoxConfig = function () {
                            if ($scope.selectedUserGroup.id) {
                                $scope.startProgress();
                                FortNoxConfig.get(
                                    $scope.selectedUserGroup.id,
                                    function (data) {
                                        loadFortNoxConfig(data);
                                        $scope.endProgress();
                                    },
                                    $scope.endWithErrorOverlay()
                                );
                            }
                        };

                        $scope.updateFortNoxConfig = function () {
                            $scope.startProgress();
                            let params = {
                                id: $scope.selectedUserGroup.id,
                                clientId: $scope.integrationData.clientId,
                                clientSecret: $scope.integrationData.clientSecret,
                                authorizationCode: $scope.integrationData.authorizationCode,
                            };
                            FortNoxConfig.update(
                                params,
                                function (data) {
                                    loadFortNoxConfig(data);
                                    $scope.formSuccess('dialog.fortnox.update.success');
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay()
                            );
                        };

                        $scope.addUser = function (group, newUserEmail) {
                            if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                                return;
                            }
                            var isUserAlreadyInTheGroup = false;
                            angular.forEach(group.usersInGroup, function (value) {
                                if (newUserEmail == value.email) {
                                    isUserAlreadyInTheGroup = true;
                                }
                            });
                            angular.forEach(group.newUsers, function (value) {
                                if (newUserEmail == value.email) {
                                    isUserAlreadyInTheGroup = true;
                                }
                            });
                            if (!isUserAlreadyInTheGroup) {
                                CustomerUser.getByEmail({ email: newUserEmail }, function (response) {
                                    if (response.id) {
                                        if (!group.newUsers[response.id]) {
                                            group.newUsers[response.id] = response;
                                        }
                                    } else {
                                        let newUserEmailIsAlreadyInNewUsers =
                                            _.findIndex(group.newUsers, { email: newUserEmail }) > 0;

                                        if (!newUserEmailIsAlreadyInNewUsers) {
                                            group.newUsers.push({ name: newUserEmail, email: newUserEmail, id: null });
                                        }
                                    }
                                    // console.log("what's in group  new users : " , group.newUsers)
                                    addAllUsers(group);
                                });
                            }
                            //commented and moved addAllUsers(group); above @BP
                            // $timeout(function () {
                            //     addAllUsers(group);
                            // }, 1000);
                        };

                        function addAllUsers(group) {
                            var newUserEmails = [];
                            angular.forEach(group.newUsers, function (newUser) {
                                newUserEmails.push(newUser.email);
                            });
                            $scope.startProgress();
                            UserGroup.addUsers(
                                { id: group.id, emails: newUserEmails },
                                function (result) {
                                    $scope.selectedUserGroup.newUserEmail = null;
                                    group.newUsers = {};
                                    $scope.alertMessage.type = 'SUCCESS';
                                    if (result.added > 0 && result.invited > 0) {
                                        $scope.alertMessage.key = 'dialog.user.group.invited.added';
                                    } else if (result.added > 0) {
                                        $scope.alertMessage.key = 'dialog.user.group.added';
                                    } else if (result.invited > 0) {
                                        $scope.alertMessage.key = 'dialog.user.group.invited';
                                    }
                                    getMembersInUserGroup(group);
                                    $scope.endProgress();
                                },
                                $scope.endWithError
                            );
                        }

                        $scope.createGroup = function (selectedUserGroup) {
                            $scope.startProgress();
                            CustomerUser.createUserGroup(
                                selectedUserGroup,
                                function (newGroup) {
                                    $scope.dataLoaded(newGroup);
                                    $scope.isNew = false;
                                    $scope.alertMessage.key = 'dialog.user.group.create.success';
                                    $scope.alertMessage.type = 'SUCCESS';
                                    $scope.data = newGroup;
                                    $scope.endProgress();
                                    $rootScope.$emit('user.group.create.update.success');
                                    $scope.refreshDialog();
                                    $scope.createGroupCallback({ userGroup: newGroup });
                                },
                                $scope.endWithError
                            );
                            $scope.createGroupTab();
                        };

                        $scope.createGroupTab = function () {
                            $scope.createNewGroup = true;
                        };

                        $scope.refreshDialog = function () {
                            $scope.displayUserNotAddedMessage = false;
                            if ($scope.data) {
                                UserGroup.get($scope.data.id, $scope.dataLoaded, $scope.endWithError);
                                $scope.users = UserGroup.listUsers(
                                    $scope.data,
                                    $scope.endProgress,
                                    $scope.endWithError
                                );
                            }
                        };

                        $scope.openImageCropDialog = function () {
                            OgModal.open('image:crop', {});
                        };

                        $scope.openCompanyInfoTab = function () {
                            this.getGeoTags();
                            getSupplierDetailsFn();
                        };

                        $scope.openTab = function (a) {
                            $scope.currentTab = a;
                            $scope.openDefaultTab = a;
                            $scope.alertMessage = { type: null, key: null };
                        };

                        $scope.getGeoTags = function () {
                            $scope.alertMessage = { type: null, key: null };
                            $scope.startProgress();
                            $scope.allGeoTags = GeoTag.getMap(
                                {},
                                function () {
                                    parseGeoTagsFn();
                                    $scope.endProgress();
                                },
                                $scope.endWithError
                            );
                        };

                        $scope.coordinatesFromAddress = function () {
                            if ($scope.selectedUserGroup.supplier && $scope.selectedUserGroup.supplier.officeAddress) {
                                $scope.startProgress();
                                Address.get(
                                    { id: $scope.selectedUserGroup.supplier.officeAddress.id },
                                    function (address) {
                                        $scope.endProgress();
                                        var addressInline = address.street + ' ' + address.zipCode + ' ' + address.town;
                                        geoCoder.geocode({ address: addressInline }, function (results, status) {
                                            $timeout(function () {
                                                if (status == google.maps.GeocoderStatus.OK) {
                                                    $scope.selectedUserGroup.supplier.geoCoordinates =
                                                        results[0].geometry.location.toString().replace(/[\(\)]/g, '');
                                                    //onchange was not working so it has been called manually
                                                    $scope.handleOnchanged(
                                                        'geoCoordinates',
                                                        $scope.selectedUserGroup.supplier.geoCoordinates
                                                    );
                                                    $scope.formSuccess(
                                                        'dialog.supplier.detail.coordinates.from.address.success'
                                                    );
                                                } else {
                                                    $scope.formError({
                                                        messageKey:
                                                            'dialog.supplier.detail.coordinates.from.address.failed',
                                                    });
                                                }
                                            });
                                        });
                                    },
                                    $scope.endWithError
                                );
                            }
                        };

                        $scope.updateSupplierInfo = () => {
                            let _data = angular.copy($scope.selectedUserGroup.supplier);
                            _data.supplierCategories = null;
                            //_data.supplierServiceAreaAddresses =  $scope.serviceAreaAddresses;
                            $scope.startProgress();
                            Supplier.update(
                                _data,
                                (supplier) => {
                                    $scope.alertMessage.key = 'event.presentation.password.updated';
                                    $scope.alertMessage.type = 'SUCCESS';
                                    $scope.enableTermsAndConditionsEdit = false;
                                    $scope.editDetails = false;
                                    $scope.editLogo = false;
                                    $scope.enableManualAddressEntry = false;
                                    $scope.editCustomTerm = false;
                                    $rootScope.$emit('supplier:presentation:address:changed', supplier);
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay
                            );
                        };

                        $scope.useStandardTerms = function () {
                            $scope.editCustomTerm = false;
                            //if ($scope.editDetails) {
                            $scope.useCustomTerms = false;
                            /*$scope.selectedUserGroup.supplier.bookingTerms = null;
                            $scope.selectedUserGroup.supplier.bookingCancelDays = null;*/
                            // }
                        };

                        $scope.useOwnTerms = function () {
                            //if ($scope.editDetails) {
                            $scope.editCustomTerm = false;
                            $scope.useCustomTerms = true;
                            $scope.selectedUserGroup.supplier.bookingTerms = $scope.selectedUserGroup.supplier
                                .bookingTerms
                                ? $scope.selectedUserGroup.supplier.bookingTerms
                                : $scope.bookingTerms;
                            $scope.selectedUserGroup.supplier.bookingCancelDays =
                                $scope.selectedUserGroup.supplier.bookingCancelDays > 0
                                    ? $scope.selectedUserGroup.supplier.bookingCancelDays
                                    : $scope.cancelPeriod;
                            // }
                        };

                        $scope.editCustomTerms = function () {
                            $scope.editCustomTerm = true;
                        };

                        $scope.saveCustomTerms = function () {
                            console.log('Saving custom terms ');

                            $scope.editCustomTerm = false;
                        };

                        $scope.trustAsHtml = function (string) {
                            return $sce.trustAsHtml(string);
                        };

                        $scope.handleOnchanged = function (modalName, currentValue) {
                            fieldChanged.checkOnChanged(modalName, currentValue);
                            $scope.viewUpdateButton = fieldChanged.isFieldChanged();
                        };

                        function parseGeoTagsFn() {
                            if ($scope.selectedUserGroup && $scope.selectedUserGroup.supplier) {
                                if ($scope.selectedUserGroup.supplier.geoTags) {
                                    let _geoTags = [];
                                    angular.forEach($scope.selectedUserGroup.supplier.geoTags, function (tag) {
                                        if (tag.id) {
                                            _geoTags.push('' + tag.id);
                                        } else {
                                            _geoTags.push('' + tag);
                                        }
                                    });
                                    $scope.selectedUserGroup.supplier.geoTags = _geoTags;
                                    // $timeout(function () {
                                    //     jQuery(document).ready(function () {
                                    //         jQuery('#supplEditGeoTags').select2();
                                    //     });
                                    // }, 500);
                                }
                            }
                        }

                        function getSupplierDetailsFn() {
                            $scope.startProgress();
                            Supplier.get(
                                { supplierId: $scope.selectedUserGroup.supplier.id },
                                (response) => {
                                    $scope.selectedUserGroup.supplier = response;
                                    $scope.selectedUserGroup.supplier.officeAddress.geoCoordinates =
                                        $scope.selectedUserGroup.supplier.geoCoordinates;
                                    $scope.fillAddress = angular.copy($scope.selectedUserGroup.supplier.officeAddress);
                                    initGoogleAutoComplete();
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay
                            );
                        }

                        $scope.useOfficeAddressAsBillingAddress = function () {
                            if (!$scope.selectedUserGroup.useOfficeAddress && $scope.selectedUserGroup.supplier) {
                                joinAddressFn();
                            }
                        };

                        $scope.isBooleanValueSelected = function (value) {
                            return value && value.toLowerCase() == 'true';
                        };

                        $scope.toggleEditLogo = function () {
                            if ($scope.editDetails) {
                                $scope.editLogo = !$scope.editLogo;
                                /*if ($scope.editLogo) {
                                $timeout(function () {
                                    var repeat = 0;
                                    var triggerClick = function () {
                                        var e = $('input[type="file"].logo');
                                        if (e.is(":visible")) {
                                            e.trigger("click");
                                            e.change(function () {
                                                $(this).closest("form").find('input[upload-submit]').click();
                                                $timeout(function () {
                                                    $scope.editLogo = false;
                                                });
                                            });
                                        } else if (repeat < 5) {
                                            repeat++;
                                            $timeout(triggerClick, 100);
                                        }
                                    };
                                    triggerClick();
                                });
                            }*/
                            }
                        };

                        $scope.uploadImage = function (content, completed) {
                            if (completed && content) {
                                var fileStore = angular.fromJson(content);
                                if (!$scope.selectedUserGroup.supplier.logoImage) {
                                    $scope.selectedUserGroup.supplier.logoImage = {};
                                }
                                $scope.selectedUserGroup.supplier.logoImage = fileStore;
                                $scope.handleOnchanged('selectedUserGroup.supplier.logoImage.id', fileStore.id);
                                document.getElementById('company-logo').value = '';
                            }
                        };

                        $scope.updateSupplierLogo = function (formData) {
                            formData.append('supplierId', $scope.selectedUserGroup.supplier.id);
                            Supplier.updateSupplierLogo(
                                formData,
                                function (data) {
                                    $scope.editLogo = false;
                                    $scope.editDetails = false;
                                    $scope.selectedUserGroup.supplier.logoImage = data.logoImage;
                                    $scope.handleOnchanged(
                                        'selectedUserGroup.supplier.logoImage.id',
                                        data.logoImage.id
                                    );
                                },
                                $scope.endWithErrorOverlay()
                            );
                        };

                        $scope.createBillingAddressForSupplier = function (form, address) {
                            $scope.startProgress();
                            Supplier.addBillingAddress(
                                $scope.selectedUserGroup.supplier.id,
                                address,
                                (resp) => {
                                    $scope.selectedUserGroup.supplier = resp;
                                    $rootScope.formSuccess(form, 'dialog.address.update.success');
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay()
                            );
                        };

                        $scope.createOfficeAddressForSupplier = function (form, address) {
                            $scope.startProgress();
                            Supplier.addOfficeAddress(
                                $scope.selectedUserGroup.supplier.id,
                                address,
                                (resp) => {
                                    $scope.selectedUserGroup.supplier = resp;
                                    $rootScope.formSuccess(form, 'dialog.address.update.success');
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay()
                            );
                        };

                        $scope.handleViewPresentationView = function (displaySupplierDetailsTab) {
                            $scope.viewPresentationCallback({ displaySupplierDetailsTab: displaySupplierDetailsTab });
                        };

                        $scope.createBillingAddressForCreator = function (form, address) {
                            $scope.selectedUserGroup.userGroupBillingAddress = address;
                            fieldChanged.reset();
                            fieldChanged.init(angular.copy(address));
                            $scope.updateUserGroup();
                        };

                        $scope.translate = function (name, code) {
                            if (code) {
                                let translatedText = $translate.instant(code);
                                if (translatedText !== code) {
                                    return translatedText;
                                }
                            }
                            return name;
                        };

                        function joinAddressFn() {
                            $scope.startProgress();
                            $scope.refreshBillingAddress = false;
                            Supplier.joinAddresses(
                                { id: $scope.selectedUserGroup.supplier.id },
                                function (data) {
                                    $scope.endProgress();
                                    $scope.selectedUserGroup.supplier = data;
                                    $scope.refreshBillingAddress = true;
                                },
                                $scope.endWithError
                            );
                        }

                        function loadBookingTerms() {
                            $scope.startProgress();
                            GlobalOption.get(
                                'BOOKING_TERMS',
                                (resp) => {
                                    $scope.bookingTerms = resp[$rootScope.langSelected];
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay
                            );
                        }

                        function loadCancelPeriod() {
                            GlobalOption.get(
                                'CANCEL_PERIOD',
                                (resp) => {
                                    let _cancelPeriod = resp[$rootScope.langSelected]
                                        ? resp[$rootScope.langSelected]
                                        : resp['sv_SV'];
                                    $scope.cancelPeriod = parseInt(_cancelPeriod);

                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay
                            );
                        }

                        function fetchCompanyNameFn(userEmail) {
                            const regExp = /\S+@\S+\.\S+/;
                            if (regExp.test(userEmail)) {
                                CustomerUser.fetchCompanyName(
                                    { userEmail: userEmail },
                                    function (result) {
                                        $scope.parentUserGroups = result.userGroups;
                                        if (result.userGroups.length === 0) {
                                            $scope.selectedUserGroup.parentGroupId = null;
                                        }
                                        if (result.userGroups.length === 1) {
                                            $scope.selectedUserGroup.parentGroupId = result.userGroups[0].id;
                                        }
                                    },
                                    function (error) {
                                        console.log(
                                            'error while fetching company names with associated domain = ',
                                            error
                                        );
                                    }
                                );
                            }
                        }

                        function initFn() {
                            $scope.showTerms = false;
                            $scope.currentTab = 'GROUP';
                            if (!$scope.openDefaultTab) {
                                $scope.openDefaultTab = 'GROUP';
                            }
                            $scope.refreshBillingAddress = false;
                            $scope.startProgress();
                            loadCancelPeriod();
                            loadBookingTerms();
                            $scope.clientName = ClientService.getClientDisplayName();
                            $scope.defaultBookingTermsTab = 'standard.terms';
                        }

                        $scope.refreshEmail = function (groupMemberEmail) {
                            if (groupMemberEmail === '') {
                                $scope.groupOwnerSelected = false;
                            }
                        };

                        $scope.setGroupOwner = function (groupMemberEmail) {
                            if (groupMemberEmail) {
                                $scope.groupOwnerSelected = !$scope.groupOwnerSelected;
                            }
                        };

                        $scope.clearEmailField = function () {
                            $scope.selectedUserGroup.newUserEmail = '';
                        };

                        $scope.setUserGroupOwnerConfirmation = function (user) {
                            if ($scope.currentUserGroupOwner && user.email === $scope.currentUserGroupOwner.email) {
                                return;
                            }
                            user.showUserGroupOwnerConfirmation = true;
                            $scope.userGroupOwnerConfirmationText = $translate.instant(
                                'common.group.owner.change.confirm',
                                { p0: user.email }
                            );
                        };

                        $scope.resetUserGroupOwnerConfirmation = function (user) {
                            user.showUserGroupOwnerConfirmation = false;
                        };

                        $scope.saveUserGroupOwner = function (group, user, email) {
                            if ($scope.currentUserGroupOwner && email === $scope.currentUserGroupOwner.email) {
                                return;
                            }
                            let previousDecisionMaker = angular.copy($scope.currentUserGroupOwner);
                            $scope.newGroupOwner = false;
                            user.showUserGroupOwnerConfirmation = false;
                            $scope.updateGroupOwner(group, user);
                        };

                        $scope.getImageUrl = function (fileId) {
                            return CommonFile.getCommonFile(fileId);
                        };

                        $scope.setBookingTermsTabActive = function (bookingTermsCode) {
                            $scope.defaultBookingTermsTab = bookingTermsCode;
                            if (bookingTermsCode === 'custom.terms') {
                                $scope.editCustomTerm = false;
                                $scope.useOwnTerms();
                            } else {
                                $scope.useStandardTerms();
                            }
                        };
                    },
                ],
            };
        },
    ]);
})();
