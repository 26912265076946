import controller from './og_supplier_presentation.controller.js';

(function () {
    'use strict';

    angular.module('ogSupplierPresentation').directive('ogSupplierPresentation', [
        function () {
            return {
                scope: {
                    supplier: '=',
                    declinedOffers: '=',
                    canceledOffers: '=',
                    addSupplierFn: '&',
                    addedSupplierList: '=',
                    notEditable: '=',
                    modalName: '=',
                    displaySupplierDetailsTab: '=',
                },
                templateUrl: './app/components/directives/supplier_presentation/og_supplier_presentation.html',
                restrict: 'AE',
                controller,
                controllerAs: 'SupplierPresentationCtrl',
                bindToController: true,
            };
        },
    ]);
})();
