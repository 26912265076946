import { DownloadUtilFunctions } from '../../../../utils/global/DownloadUtilFunction';
/**
 * Created by sampurlachhantyal on 9/1/17.
 */
class ImportParticipantsFromFileModal {
    constructor(close, $element, data, $rootScope, Participant, ProcessLog, $timeout, CommonFunctions) {
        this._injected = {close, $element, data, $rootScope, Participant, ProcessLog, $timeout, CommonFunctions};
        this.eventId = data.eventId;
        this.getCurrentProcessStatus(data.eventId);
        this.successCallback = data.successCallback;
        this.pullTillGetStatus = false;
        this.getCurrentProcessStatus = this.getCurrentProcessStatus.bind(this);
        this.addCountryCode = true;
        this.countryCode = '+46';
        this.importSubTab = 'BASIC';
    }

    getCurrentProcessStatus(eventId) {
        const {ProcessLog, $timeout, CommonFunctions} = this._injected;
        if (!eventId) {
            return;
        }
        ProcessLog.getImportStatus(
            { referenceId: eventId },
            (resp) => {
                this.participantUploadProcess = resp;
                if (resp.status && resp.status.name === 'RUNNING') {
                    this.close();
                } else if (resp.status && resp.status.name === 'GOT_ERROR') {
                    let _error = angular.fromJson(resp.errorLog);
                    if (this.importType === 'BASIC') {
                        this.errorMessagesBasic = CommonFunctions.errorMessages(_error, true);
                    } else if (this.importType === 'ADVANCED') {
                        this.errorMessagesAdvanced = CommonFunctions.errorMessages(_error, true);
                    }
                } else if (resp.status && resp.status.name === 'SUCCESS_WITH_MESSAGE') {
                    let _error = angular.fromJson(resp.errorLog);
                    if (this.importType === 'BASIC') {
                        this.sucessMessagesBasic = CommonFunctions.errorMessages(_error, true);
                    } else if (this.importType === 'ADVANCED') {
                        this.successMessagesAdvanced = CommonFunctions.errorMessages(_error, true);
                    }
                } else if (this.pullTillGetStatus) {
                    $timeout(() => {
                        this.getCurrentProcessStatus(eventId);
                    }, 500);
                }
            },
            (err) => {
                console.log('.>>', err);
            }
        );
    }

    importFromFile(type) {
        const {Participant, CommonFunctions} = this._injected;
        this.uploadInProgress = true;
        this.errorMessagesBasic = null;
        this.sucessMessagesBasic = null;
        this.errorMessagesAdvanced = null;
        this.successMessagesAdvanced = null;
        this.importType = type;
        let formData = new FormData();
        if (!this.addCountryCode) {
            this.countryCode = null;
        }
        formData.append('file', this.data.file);
        formData.append('countryCode', this.countryCode);
        formData.append('importType', type);
        this.pullTillGetStatus = true;
        this.getCurrentProcessStatus(this.eventId);
        Participant.importFromFile(
            this.eventId,
            formData,
            () => {
                if (this.successCallback) {
                    this.successCallback();
                }
                this.pullTillGetStatus = false;
                this._closeThis();
            },
            (err) => {
                this.pullTillGetStatus = false;
                this.uploadInProgress = false;
                this.participantUploadProcess = null;
                if (err) {
                    if (this.importType === 'BASIC') {
                        this.errorMessagesBasic = CommonFunctions.errorMessages(err, true);
                    } else if (this.importType === 'ADVANCED') {
                        this.errorMessagesAdvanced = CommonFunctions.errorMessages(err, true);
                    }
                }
            }
        );
        this.data.file = null;
        jQuery('#file').val('');
    }

    close() {
        //If uploading process is in-progress state need to refresh participant list
        if (this.successCallback) {
            this.successCallback();
        }
        this._closeThis();
    }

    _closeThis() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }

    numberOnly(event) {
        const pattern = /[0-9\+\-\ ]/;

        let inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode !== 8 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    importParticipants(eventId) {
        let _downloadUrl = 'api/v1/event/' + eventId + '/generateExcelFileForParticipantsImport';
        DownloadUtilFunctions.downloadFile(_downloadUrl);
    }

    importParticipantsBasic(eventId) {
        let _downloadUrl = 'api/v1/event/' + eventId + '/generateExcelFileForParticipantsBasicImport';
        DownloadUtilFunctions.downloadFile(_downloadUrl);
    }

    setImportTabActive(tabName) {
        this.importSubTab = tabName;
    }
}

ImportParticipantsFromFileModal.$inject = [
    'close',
    '$element',
    'data',
    '$rootScope',
    'Participant',
    'ProcessLog',
    '$timeout',
    'CommonFunctions'
];
export default ImportParticipantsFromFileModal;
