
import UserGroupData from '../../../../core/existingServices/data_store/UserGroupData';
// import './list/list.module';
// import './list/list.component';

import './messages/messages.module';
import './messages/messages.component';

export default function (appModule) {
    appModule.config([
        '$routeProvider',
        '$locationProvider',
        '$stateProvider',
        '$urlRouterProvider',
        function ($routeProvider, $locationProvider, $stateProvider, $urlRouterProvider) {

            $urlRouterProvider.when('/events/list', [
                '$match',
                '$state',
                '$location',
                'AuthenticationService',
                function ($match, $state, $location, AuthenticationService) {
                    let token = $location.search().refresh;
                    if (token) {
                        AuthenticationService.setRefreshToken(token);
                    }
                    AuthenticationService.validate().then(() => {
                        let userGroup = AuthenticationService.getUserData().userGroup;
                        if (userGroup && userGroup.childUserGroupCount > 0) {
                            $location.url('/events/overview');
                        } else {
                            $state.go('events.list');
                        }
                    });
                },
            ]);

            $urlRouterProvider.when('/events/tasks', [
                '$match',
                '$state',
                '$location',
                'AuthenticationService',
                function ($match, $state, $location, AuthenticationService) {
                    AuthenticationService.validate().then(() => {
                        let userGroup = AuthenticationService.getUserData().userGroup;
                        if (userGroup && userGroup.childUserGroupCount > 0) {
                            $location.url('/events/overview');
                        } else {
                            $state.go('events.tasks');
                        }
                    });
                },
            ]);

            $urlRouterProvider.when('/events/messages', [
                '$match',
                '$state',
                '$location',
                'AuthenticationService',
                function ($match, $state, $location, AuthenticationService) {
                    AuthenticationService.validate().then(() => {
                        let userGroup = AuthenticationService.getUserData().userGroup;
                        if (userGroup && userGroup.childUserGroupCount > 0) {
                            $location.url('/events/overview');
                        } else {
                            $state.go('events.messages');
                        }
                    });
                },
            ]);
            $urlRouterProvider.when('/events/upcoming-offers', [
                '$match',
                '$state',
                '$location',
                'AuthenticationService',
                function ($match, $state, $location, AuthenticationService) {
                    AuthenticationService.validate().then(() => {
                        let userGroup = AuthenticationService.getUserData().userGroup;
                        if (userGroup && userGroup.childUserGroupCount > 0) {
                            $location.url('/events/overview');
                        } else {
                            $state.go('events.upComingOffer');
                        }
                    });
                },
            ]);
            $urlRouterProvider.when('/events/activities', [
                '$match',
                '$state',
                '$location',
                'AuthenticationService',
                function ($match, $state, $location, AuthenticationService) {
                    AuthenticationService.validate().then(() => {
                        let userGroup = AuthenticationService.getUserData().userGroup;
                        if (userGroup && userGroup.childUserGroupCount > 0) {
                            $location.url('/events/overview');
                        } else {
                            $state.go('events.activities');
                        }
                    });
                },
            ]);

            $stateProvider
                .state('events.list', {
                    url: '/list',
                    template: '<list></list>'
                })
                .state('events.tasks', {
                    url: '/tasks',
                    template: '<tasks></tasks>'
                })
                .state('events.messages', {
                    url: '/messages',
                    template: '<Messages></Messages>'
                })
                .state('events.activities', {
                    url: '/activities',
                    template: '<activities></activities>'
                })
                .state('events.upComingOffer', {
                    url: '/upcoming-offers',
                    template: '<upcoming-offers></upcoming-offers>'
                });
        },
    ]);
};
