import resourcePresentationModal from '../../modals/modals2.0/resource-presentation';
import amountAndPriceSupplierService from '../../../utils/price_calculate/PriceCalculationSupplierUtil';
import amountAndPriceService from '../../../utils/price_calculate/PriceCalculationCreatorUtil';
import tooltipUtilSupplier from '../../../utils/negotiation_notification/NegotiationTooltipSupplierUtil';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';
import {OfferPartUtilFunctions} from '../../../utils/global/OfferPartUtilFunctions';
import {UtilFunctions} from "../../../utils/global/utilFunctions";

const DRAW_STRIKE_THROUGH_LINE = [
    'NOT_DELIVERABLE',
    'ADDED_WAITING_FOR_CREATOR_DECLINED',
    'ADDED_WAITING_FOR_SUPPLIER_DECLINED',
    'DELETED_WAITING_FOR_SUPPLIER',
    'DELETED_BY_SUPPLIER',
    'DELETED_WAITING_FOR_CREATOR',
];
const DISPLAY_TOOLTIP_STATUS = [
    'ANSWERED',
    'SENT',
    'BOOKED_LOCKED_BY_SUPPLIER',
    'BOOKED_WAITING_FOR_SUPPLIER',
    'BOOKED_WAITING_FOR_CREATOR',
    'DELETED_BY_SUPPLIER',
    'DELETED_WAITING_FOR_CREATOR',
    'ADDED_WAITING_FOR_CREATOR',
    'FINISHED_WAITING_FOR_SUPPLIER',
    'FINISHED_WAITING_FOR_CREATOR',
];

const SHOW_SUPPLIER_VALUES_STATE = ['SENT', 'EDITED_BY_CREATOR'];

(function () {
    'use strict';
    const HIDE_CHANGE_TOOLTIP_PART_STATUS = ['EDITED_WAITING_FOR_CREATOR', 'EDITED_WAITING_FOR_SUPPLIER'];

    angular.module('ogRequestOfferPartOptionAddEditSupplier').directive('ogRequestOfferPartOptionAddEditSupplier', [
        '$log',
        '$routeParams',
        '$rootScope',
        '$timeout',
        '$translate',
        'ModalService',
        'RequestOfferPartOption',
        'GlobalConstants',
        'RequestOfferStatusUtils',
        'TooltipValueService',
        'CommonFile',
        'CommonFunctions',
        function (
            $log,
            $routeParams,
            $rootScope,
            $timeout,
            $translate,
            ModalService,
            RequestOfferPartOption,
            GlobalConstants,
            RequestOfferStatusUtils,
            TooltipValueService,
            CommonFile,
            CommonFunctions
        ) {
            return {
                restrict: 'EA',
                scope: {
                    event: '=',
                    supplierId: '=',
                    layout: '=',
                    saveCallback: '=',
                    deleteCallback: '=',
                    offerPart: '=',
                    includeVat: '=',
                    selectedCurrency: '=',
                    offerPartOption: '=',
                    offer: '=',
                    offerPartOptionsWithActions: '=',
                    offerPartOptionsWithActionsCallback: '=',
                    flagForAcceptedWithChanges: '=',
                    showDiffCol: '=',
                    finalPriceAndAmountEdit: '=',
                    isOfferInFinishedToApprovedStates: '=',
                    disablePaidOnsiteEdit: '=',
                },
                templateUrl:
                    './app/components/directives/request_offer_part_option_add_edit_supplier/request_offer_part_option_add_edit_supplier.directive.view.html',
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.viewModal = false;
                        $scope.hoursToAdd = 1; // Add default hours from 'from-date' to 'to-date' in dateTime pair.
                        ScopeFunctions.addToScope($scope);
                        $scope.uniqueId = Math.random();
                        $scope.VAT_DROPDOWN_LIST = GlobalConstants.VAT_DROPDOWN_LIST;
                        $scope.remoteUrl =
                            CommonFile.getBaseUrl() +
                            'api/v1/requestOfferPartOption?supplierId=' +
                            $scope.supplierId +
                            '&partTypeNameCode=' +
                            $scope.offerPart.nameCode;
                        $scope.getPriceWithVat = amountAndPriceSupplierService.getRequestOfferPartOptionPriceWithVat;
                        $scope.getPriceInclVat = amountAndPriceSupplierService.getRequestOfferPartOptionPriceInclVat;
                        $scope.getSupplierTotalPrice = amountAndPriceSupplierService.getRequestPartOptionTotalPrice;
                        $scope.getSupplierAmount = amountAndPriceSupplierService.getRequestOfferPartOptionAmount;
                        $scope.getSupplierPrice = amountAndPriceSupplierService.getRequestOfferPartOptionPriceExclVat;
                        $scope.partOptionId = null;
                        $rootScope.offerPartOptionEditId = 0;

                        $scope.amountTypes = [
                            { type: 'PIECES', name: $translate.instant('amount.type.PIECES') },
                            { type: 'PEOPLE', name: $translate.instant('amount.type.PEOPLE') },
                            { type: 'SAME_AS_EVENT', name: $translate.instant('amount.type.SAME_AS_EVENT') },
                        ];

                        $scope.init = function () {
                            $scope.isNewRow = false;
                            $scope.syncDates = true;
                            $scope.originalOfferPartsList = angular.copy($scope.offer.offerParts);

                            if ($scope.offerPartOption == null) {
                                $scope.data = {
                                    requestOfferPartId: $scope.offerPart.id,
                                    amount: angular.copy($scope.offerPart.amount),
                                    amountType: angular.copy($scope.offerPart.amountType),
                                    dateTimeFrom: $scope.offerPart.dateTimeFrom,
                                    dateTimeTo: $scope.offerPart.dateTimeTo,
                                    supplierDateTimeFrom: $scope.offerPart.dateTimeFrom,
                                    supplierDateTimeTo: $scope.offerPart.dateTimeTo,
                                    vat: $scope.offerPart.vat ? $scope.offerPart.vat : GlobalConstants.DEFAULT_VAT,
                                    status: $scope.offerPart.status,
                                    dummyId: Math.random(),
                                };
                                if (
                                    !$scope.offerPart.hasRequestOfferPartOptions &&
                                    $scope.offerPart.offerPartOptions.length < 1
                                ) {
                                    $scope.data.isSelected = true;
                                }
                                $scope.isNewRow = true;
                                $scope.originalOfferPartOption = angular.copy($scope.data);
                            } else {
                                $scope.originalOfferPartOption = angular.copy($scope.offerPartOption);
                                $scope.data = angular.copy($scope.offerPartOption);
                                $scope.data.price = UtilFunctions.isGreaterOrEqualToZero($scope.data.newPrice)
                                    ? $scope.data.newPrice
                                    : $scope.data.price;
                                if ($scope.includeVat && $scope.offer.vatIncludedInPrice) {
                                    $scope.data.priceInclVat =
                                        amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                            $scope.data.price,
                                            $scope.data.vat
                                        );
                                    $scope.data.newPriceInclVat =
                                        amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                            $scope.data.price,
                                            $scope.data.vat
                                        );
                                }
                                $scope.data.requestOfferPartId = $scope.offerPart.id;
                            }
                            if (RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf($scope.offer.status.name) > -1) {
                                $scope.getSupplierDifference =
                                    amountAndPriceSupplierService.getRequestPartOptionDifference(
                                        $scope.offer,
                                        $scope.data,
                                        $scope.includeVat
                                    );
                                $scope.showDiffColumnSupplier =
                                    !!amountAndPriceSupplierService.getRequestPartOptionDifference(
                                        $scope.offer,
                                        $scope.data,
                                        $scope.includeVat
                                    );
                            }
                            $scope.data.canEditOptionByCreator = false;
                            $scope.data.canEditOptionBySupplier = false;
                            $scope.data.loading = false;
                            $scope.isTimePairValid = true;
                        };

                        $scope.updateIsSelectedForOptions = function (requestOfferPartOption) {
                            let _params = { isSelected: requestOfferPartOption.isSelected };
                            if ($scope.originalOfferPartOption.id) {
                                $scope.startProgress();
                                RequestOfferPartOption.updatePartOptionSelection(
                                    $scope.originalOfferPartOption.id,
                                    _params,
                                    (resp) => {
                                        $rootScope.$broadcast('part:option:is:selected:radio:value:updated', {
                                            requestOfferPartId: $scope.offerPart.id,
                                            requestOfferPartOptionId: $scope.originalOfferPartOption.id,
                                            price: $scope.data.price,
                                            vat: $scope.data.vat,
                                            isSelected: resp['isSelected'],
                                        });
                                        $scope.endProgress();
                                    },
                                    $scope.endWithErrorOverlay()
                                );
                            }
                        };

                        $scope.save = function () {
                            $scope.data.newPrice = $scope.data.price;
                            let data = angular.copy($scope.data);
                            $scope.saveCallback(data);
                        };

                        $scope.cancelDateChange = function (resource) {
                            if (
                                $scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER' &&
                                resource.status.name === 'EDITED_BY_SUPPLIER'
                            ) {
                                $scope.data.dateTimeFrom = $scope.updatedClientSideOfferPartOption.dateTimeFrom;
                                $scope.data.dateTimeTo = $scope.updatedClientSideOfferPartOption.dateTimeTo;
                            } else {
                                $scope.data.dateTimeFrom = $scope.originalOfferPartOption.dateTimeFrom;
                                $scope.data.dateTimeTo = $scope.originalOfferPartOption.dateTimeTo;
                            }
                            $scope.changeDateTooltip(resource);
                        };

                        $scope.cancelAmountChange = function (resource) {
                            if (
                                $scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER' &&
                                resource.status.name === 'EDITED_BY_SUPPLIER'
                            ) {
                                $scope.data.amount = $scope.updatedClientSideOfferPartOption.amount;
                                $scope.data.amountType.name = $scope.updatedClientSideOfferPartOption.amountType.name;
                            } else {
                                $scope.data.amount = $scope.originalOfferPartOption.amount;
                                $scope.data.amountType.name = $scope.originalOfferPartOption.amountType.name;
                            }
                            $scope.changeAmountTooltip(resource);
                        };

                        $scope.saveDateDisabled = function () {
                            return (
                                $scope.data.dateTimeFrom === $scope.originalOfferPartOption.dateTimeFrom &&
                                $scope.data.dateTimeTo === $scope.originalOfferPartOption.dateTimeTo
                            );
                        };

                        $scope.saveAmountDisabled = function () {
                            return (
                                $scope.data.amount === $scope.originalOfferPartOption.amount &&
                                $scope.data.amountType.name === $scope.originalOfferPartOption.amountType.name
                            );
                        };

                        $scope.checkForVatValueLimit = (data) => {
                            if (data.vat && data.vat.length >= 3 && !data.vat.includes('.')) {
                                $scope.data.vat = Math.floor(data.vat / 10);
                            }
                            if (data.vat == '' || data.vat == null) {
                                $scope.data.vat = 0;
                            }
                        };

                        $scope.saveInlineForm = function (data) {
                            $scope.saveCallback(data);
                        };

                        $scope.readonlySupplierPrice = function (offerPart, offer) {
                            return (
                                offer.statusDetailed.name !== 'SENT' &&
                                (!$scope.finalPriceAndAmountEdit || offerPart.status.name === 'CANCELED') &&
                                !(
                                    offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER' &&
                                    offerPart.status.name === 'ADDED_WAITING_FOR_SUPPLIER'
                                )
                            );
                        };

                        $scope.updateROPOPrice = function () {
                            let price;
                            if (
                                $scope.offer.status.name === 'CANCELED_AND_FINISHED' ||
                                $scope.offer.status.name === 'CANCELED_WAITING_FOR_SUPPLIER'
                            ) {
                                price = amountAndPriceSupplierService.round(parseFloat($scope.data.finalPrice));
                                if (isNaN(price)) {
                                    $scope.data.finalPrice = 0;
                                }
                            } else {
                                price = amountAndPriceSupplierService.round(parseFloat($scope.data.newPrice));
                            }
                            if (isNaN(price)) {
                                price = 0;
                            }
                            if ($scope.includeVat && $scope.offer.vatIncludedInPrice) {
                                $scope.data.newPriceInclVat =
                                    amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                        $scope.data.price,
                                        $scope.data.vat
                                    );
                                $scope.data.priceInclVat = amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                    $scope.data.price,
                                    $scope.data.vat
                                );
                            }
                            $scope.data.price = price;
                            $scope.data.supplierPrice = price;
                            $scope.data.sectionUpdated = 'PRICE';
                            $scope.save();
                        };

                        $scope.showSupplierValuesState = function () {
                            return SHOW_SUPPLIER_VALUES_STATE.indexOf($scope.offer.status.name) > -1;
                        };

                        $scope.showSupplierValueDate = function (offerPartOption) {
                            return (
                                $scope.showSupplierValuesState() &&
                                offerPartOption.status.name !== 'EDITED_WAITING_FOR_SUPPLIER'
                            );
                        };

                        $scope.updateROPOPriceInclVat = function () {
                            let price;
                            let _priceInclVat = $scope.data.priceInclVat;
                            let _finalPriceInclVat = $scope.data.finalPriceInclVat;
                            if (
                                $scope.offer.status.name === 'CANCELED_AND_FINISHED' ||
                                $scope.offer.status.name === 'CANCELED_WAITING_FOR_SUPPLIER'
                            ) {
                                price = amountAndPriceService.getPriceWithOutVat(_finalPriceInclVat, $scope.data.vat);
                                if (isNaN(price)) {
                                    $scope.data.finalPrice = 0;
                                }
                            } else {
                                price = amountAndPriceSupplierService.getPriceWithOutVat(
                                    _priceInclVat,
                                    $scope.data.vat
                                );
                                if (
                                    $scope.offer.vatIncludedInPrice &&
                                    $scope.includeVat &&
                                    RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf($scope.offer.status.name) >
                                    -1 &&
                                    $scope.data.vat > 0
                                ) {
                                    let _newPrice = parseFloat(_finalPriceInclVat / (1 + $scope.data.vat / 100));
                                    $scope.data.finalPrice = Number(Math.round(_newPrice + 'e' + 2) + 'e-' + 2);
                                }
                            }
                            if (isNaN(price)) {
                                price = 0;
                            }
                            $scope.data.oldPrice = $scope.data.price;
                            $scope.data.price = price;
                            $scope.data.supplierPrice = price;
                            $scope.data.sectionUpdated = 'PRICE';
                            $scope.save();
                        };

                        $scope.updateROPOFinalAmount = function () {
                            let amount;
                            amount = amountAndPriceSupplierService.round(parseFloat($scope.data.finalAmount));
                            if (isNaN(amount)) {
                                $scope.data.finalAmount = 0;
                            }
                            $scope.data.oldAmount = $scope.data.amount;
                            $scope.data.amount = amount;
                            $scope.data.sectionUpdated = 'AMOUNT';
                            $scope.save();
                        };

                        $scope.updateROPOAmount = function (resource) {
                            $scope.data.sectionUpdated = 'AMOUNT';
                            $scope.changeAmountTooltip(resource);
                            $scope.save();
                        };

                        $scope.updateROPOPaidOnsite = function () {
                            $scope.data.finalPaidOnsite = !$scope.data.finalPaidOnsite;
                            $scope.data.sectionUpdated = 'PAID ONSITE';
                            $scope.save();
                        };

                        $scope.updateROPOVat = function () {
                            var vat = parseFloat($scope.data.vat);
                            if (isNaN(vat)) {
                                vat = 0;
                            }
                            $scope.data.vat = vat;
                            if (!$scope.offer.vatIncludedInPrice) {
                                $scope.data.priceInclVat = null;
                                $scope.data.newPriceInclVat = null;
                                $scope.data.finalPriceInclVat = null;
                            } else {
                                let _newPrice = parseFloat($scope.data.priceInclVat / (1 + $scope.data.vat / 100));
                                $scope.data.price = Number(Math.round(_newPrice + 'e' + 2) + 'e-' + 2);
                                let _finalPrice = parseFloat(
                                    $scope.data.finalPriceInclVat / (1 + $scope.data.vat / 100)
                                );
                                $scope.data.finalPrice = Number(Math.round(_finalPrice + 'e' + 2) + 'e-' + 2);
                            }
                            $scope.data.sectionUpdated = 'VAT';
                            $scope.save();
                        };

                        $scope.updateROPOResource = function () {
                            if (!$scope.isNewRow) {
                                $scope.data.loading = true;
                                setTimeout(() => {
                                    $scope.data.sectionUpdated = 'RESOURCE_NAME';
                                    $scope.save();
                                }, 200);
                            }
                        };

                        $scope.updateROPODate = function (resource) {
                            $scope.data.sectionUpdated = 'DATE';
                            $scope.save();
                            $scope.changeDateTooltip(resource);
                        };

                        $scope.viewAmountLocalisedWithCurrency = function (price, currency, digitsAfterDecimal = 0) {
                            return amountAndPriceSupplierService.viewPrice(price, currency, digitsAfterDecimal);
                        };

                        $scope.confirmDeleteToggle = function (requestOfferPartOptionId) {
                            if ($scope.data.id === requestOfferPartOptionId) {
                                $scope.data.showDeleteConfirmation = !$scope.data.showDeleteConfirmation;
                            }
                            $rootScope.$broadcast('confirm:delete:toggle:part:option', {
                                requestOfferPartOptionId: requestOfferPartOptionId,
                            });
                        };

                        $scope.delete = function (requestOfferPartOption) {
                            $scope.confirmDeleteToggle(requestOfferPartOption.id);
                            $scope.deleteCallback(requestOfferPartOption);
                        };

                        $scope.updateSelectedPartName = function (
                            name,
                            resourceId,
                            isResourceUpdated,
                            isResourcePublic
                        ) {
                            if (UtilFunctions.isUndefinedOrNull(name)) {
                                $scope.$broadcast('angucomplete-alt:clearInput', $scope.uniqueId.toString());
                            }
                            $scope.data.resourceName = name;
                            $scope.data.isResourceUpdated = isResourceUpdated;
                            $scope.data.isResourcePublic = isResourcePublic;
                            if (!$scope.data.resourceId || $scope.data.resourceId !== resourceId) {
                                $scope.data.resourceId = resourceId;
                            }
                            if (resourceId && !$scope.data.loading) {
                                $scope.updateROPOResource();
                            }
                        };

                        $scope.checkIfAmountTypeChanged = function () {
                            if ($scope.data.amountType.name === 'SAME_AS_EVENT') {
                                $scope.data.amount = $scope.event.participantAmount;
                            }
                        };

                        $scope.openResourcePresentation = function (resourceName, resourceId) {
                            if($scope.viewModal) {
                                return;
                            }
                            $scope.viewModal = true;
                            let data = {
                                resourceName: resourceName,
                                offerPartTypeNameCode: $scope.offerPart.nameCode,
                                resourceId: resourceId,
                                supplierId: $scope.supplierId,
                                requestOfferPartOptionId: $scope.data.id,
                                requestOfferPartOptionDummyId: $scope.data.dummyId,
                                layout: $scope.layout,
                            };
                            let resourcePresentationModalData = resourcePresentationModal;
                            resourcePresentationModalData.inputs = { data };
                            ModalService.showModal(resourcePresentationModalData).then((modal) => {
                                $scope.viewModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        };

                        $scope.changeDateTooltip = function (resource) {
                            resource.displayDateChangeTooltip = !resource.displayDateChangeTooltip;
                        };

                        $scope.changeAmountTooltip = function (resource) {
                            console.log('resource', resource);
                            resource.displayAmountChangeTooltip = !resource.displayAmountChangeTooltip;
                        };
                        //Used
                        $scope.isOfferInCanceledState = function (offer) {
                            return CommonFunctions.isItCanceledOffer(offer);
                        };

                        $scope.isOfferInFromBookedState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(
                                    $scope.offer.status.name
                                ) > -1
                            );
                        };
                        //Used
                        $scope.isOfferInFromFinishedState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf($scope.offer.status.name) > -1
                            );
                        };
                        //Used
                        $scope.isOfferInSupplierEditDisabledState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.OFFER_IN_SUPPLIER_EDIT_DISABLED_STATE.indexOf(
                                    $scope.offer.status.name
                                ) > -1
                            );
                        };

                        $scope.showInfoTooltip = function (offerPartOption) {
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
                                return (
                                    $scope.isOfferPartInCreatorWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartInSupplierDeclinedStates(offerPartOption)
                                );
                            }
                            return false;
                        };

                        $scope.getTooltipValueInfoMessageByStatus = function (status) {
                            return TooltipValueService.getInfoMessageByStatus(status);
                        };

                        $scope.getUnit = function (unit) {
                            if (unit === 'PEOPLE') {
                                return 'common.people';
                            } else {
                                return 'common.pieces';
                            }
                        };

                        $scope.applyRowBgColor = function (offer, part) {
                            if ($scope.isOfferInCanceledState(offer)) {
                                return part.status.name;
                            }
                            return tooltipUtilSupplier.getPartNotificationState(offer, part);
                        };

                        $scope.hideDeleteRowBtnSupplier = function (offerPartOption) {
                            return (
                                $scope.isOfferInSupplierEditDisabledState() ||
                                ($scope.isOfferInFromFinishedState() &&
                                    offerPartOption.status.name !== 'ADDED_BY_SUPPLIER') ||
                                $scope.isOfferInCanceledState($scope.offer) ||
                                $scope.offer.statusDetailed.name === 'CANCELED' ||
                                $scope.showSupplierOptions(offerPartOption) ||
                                ['DELETED_BY_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(offerPartOption.status.name) > -1
                            );
                        };

                        $scope.actionOnRowEdit = function (offerPartOption) {
                            $scope.disableOfferPartOptionInfo(offerPartOption);
                            $scope.partOptionId = 'd' + offerPartOption.id;
                            $scope.changeRowId();
                        };

                        $scope.changeRowId = function () {
                            $rootScope.offerPartOptionEditId = $scope.partOptionId;
                        };

                        $scope.doStrikeThrough = function (offerPartOption) {
                            if (
                                CommonFunctions.hasIntersectValue(DRAW_STRIKE_THROUGH_LINE, [
                                    offerPartOption.status.name,
                                ])
                            ) {
                                return true;
                            }
                            if (
                                offerPartOption.status.name === 'CANCELED' &&
                                $scope.isOfferInCanceledState($scope.offer)
                            ) {
                                return true;
                            }
                            if (
                                $scope.layout === 'supplier' &&
                                offerPartOption.status.name === 'DELETED_WAITING_FOR_SUPPLIER'
                            ) {
                                return $scope.offer.statusDetailed.name !== 'BOOKED_LOCKED_BY_CREATOR';
                            }
                        };

                        $scope.hasFieldValueChanged = function (oldValue, newValue) {
                            if ($scope.offer.alreadyAnswered) {
                                return OfferPartUtilFunctions.checkOfferPartHasOldValueAndChanged(oldValue, newValue);
                            }
                            return false;
                        };

                        $scope.hasAmountFieldValueChanged = function (oldValue, newValue) {
                            if (
                                $scope.offer.alreadyAnswered &&
                                $scope.offer.statusDetailed.name === 'SENT' &&
                                ($scope.data.status.name === 'EDITED_BY_SUPPLIER' ||
                                    $scope.data.status.name === 'ACCEPTED')
                            ) {
                                return false;
                            }
                            return OfferPartUtilFunctions.checkOfferPartAmountHasOldValueAndChanged(oldValue, newValue);
                        };

                        $scope.hasValChangedSupplier = function (offerPartOption, type) {
                            if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER') {
                                return false;
                            }
                            if (
                                $scope.isOfferPartOptionInSupplierWaitingStates(offerPartOption) ||
                                $scope.isOfferPartOptionInCreatorDeclinedStates(offerPartOption)
                            ) {
                                let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                                switch (type) {
                                    case 'NAME':
                                        return offerPartOption.name !== originalOfferPart.name;
                                    case 'DESCRIPTION':
                                        return offerPartOption.description !== originalOfferPart.description;
                                    case 'AMOUNT':
                                        return offerPartOption.amount !== originalOfferPart.amount;
                                    case 'AMOUNT_TYPE':
                                        return offerPartOption.amountType.name !== originalOfferPart.amountType.name;
                                    case 'DATE_TIME':
                                        return (
                                            offerPartOption.dateTimeFrom !== originalOfferPart.dateTimeFrom ||
                                            offerPartOption.dateTimeTo !== originalOfferPart.dateTimeTo
                                        );
                                    case 'PRICE':
                                        return offerPartOption.newPrice !== originalOfferPart.price;
                                    case 'PRICE_INCL_VAT':
                                        return offerPartOption.newPriceInclVat !== originalOfferPart.priceInclVat;
                                    case 'PAID ONSITE':
                                        return offerPartOption.finalPaidOnsite !== false;
                                    default:
                                        return false;
                                }
                            }
                        };

                        //Used
                        $scope.isOfferPartInCreatorWaitingStates = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_CREATOR') {
                                return false;
                            }
                            let offerPartIsInCreatorWaitingStates = false;
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            GlobalConstants.CREATOR_WAITING_STATES.forEach((state) => {
                                if (!offerPartIsInCreatorWaitingStates) {
                                    if (originalOfferPart && originalOfferPart.status.name === state) {
                                        offerPartIsInCreatorWaitingStates = true;
                                    }
                                }
                            });
                            return offerPartIsInCreatorWaitingStates;
                        };
                        //Used
                        $scope.isOfferPartInSupplierDeclinedStates = function (offerPartOption) {
                            let offerPartIsInSupplierDeclinedStates = false;
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            GlobalConstants.SUPPLIER_DECLINED_STATES.forEach((state) => {
                                if (!offerPartIsInSupplierDeclinedStates) {
                                    if (originalOfferPart && originalOfferPart.status.name === state) {
                                        offerPartIsInSupplierDeclinedStates = true;
                                    }
                                }
                            });
                            return offerPartIsInSupplierDeclinedStates;
                        };

                        $scope.getOriginalOfferPartOption = function (offerPartOption) {
                            let originalOfferPartOption;
                            if (!offerPartOption || (!offerPartOption.id && !offerPartOption.referenceId)) {
                                return undefined;
                            }
                            if (
                                offerPartOption.id &&
                                $scope.originalOfferPartOption.id &&
                                offerPartOption.id === $scope.originalOfferPartOption.id
                            ) {
                                originalOfferPartOption = $scope.originalOfferPartOption;
                            } else if (
                                offerPartOption.referenceId &&
                                $scope.originalOfferPartOption.referenceId &&
                                offerPartOption.referenceId === $scope.originalOfferPartOption.referenceId
                            ) {
                                originalOfferPartOption = $scope.originalOfferPartOption;
                            }
                            if (
                                $scope.includeVat &&
                                $scope.offer.vatIncludedInPrice &&
                                originalOfferPartOption &&
                                originalOfferPartOption.price
                            ) {
                                originalOfferPartOption.priceInclVat =
                                    amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                        originalOfferPartOption.price,
                                        originalOfferPartOption.vat
                                    );
                                originalOfferPartOption.newPriceInclVat =
                                    amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                        originalOfferPartOption.price,
                                        originalOfferPartOption.vat
                                    );
                            }
                            return originalOfferPartOption;
                        };

                        $scope.fieldChangedForPriceSupplier = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER') {
                                return false;
                            }
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            if (
                                originalOfferPart &&
                                originalOfferPart.status.name === 'EDITED_WAITING_FOR_CREATOR_DECLINED' &&
                                offerPartOption.status.name === 'EDITED_WAITING_FOR_CREATOR'
                            ) {
                                return offerPartOption.price !== originalOfferPart.price;
                            } else if (
                                originalOfferPart &&
                                originalOfferPart.status.name === 'ACCEPTED' &&
                                offerPartOption.status.name === 'EDITED_WAITING_FOR_CREATOR'
                            ) {
                                return (
                                    offerPartOption.oldPrice > 0 && offerPartOption.price !== offerPartOption.oldPrice
                                );
                            } else if ($scope.isOfferPartInCreatorWaitingStates(offerPartOption)) {
                                if (offerPartOption.status.name !== 'ADDED_WAITING_FOR_CREATOR') {
                                    return offerPartOption.price !== offerPartOption.oldPrice;
                                }
                            } else {
                                return (
                                    offerPartOption.oldPrice > 0 && offerPartOption.price !== offerPartOption.oldPrice
                                );
                            }
                        };

                        $scope.fieldChangedForPriceInclVatSupplier = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER') {
                                return false;
                            }
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            let _oldPriceInclVat = amountAndPriceSupplierService.getOldPriceInclVat(
                                $scope.offer,
                                offerPartOption
                            );
                            if (
                                originalOfferPart &&
                                originalOfferPart.status.name === 'EDITED_WAITING_FOR_CREATOR_DECLINED' &&
                                offerPartOption.status.name === 'EDITED_WAITING_FOR_CREATOR'
                            ) {
                                return offerPartOption.priceInclVat !== originalOfferPart.priceInclVat;
                            } else if (
                                originalOfferPart &&
                                originalOfferPart.status.name === 'ACCEPTED' &&
                                offerPartOption.status.name === 'EDITED_WAITING_FOR_CREATOR'
                            ) {
                                return _oldPriceInclVat > 0 && offerPartOption.priceInclVat !== _oldPriceInclVat;
                            } else if ($scope.isOfferPartInCreatorWaitingStates(offerPartOption)) {
                                if (offerPartOption.status.name !== 'ADDED_WAITING_FOR_CREATOR') {
                                    return offerPartOption.priceInclVat !== _oldPriceInclVat;
                                }
                            } else {
                                return _oldPriceInclVat > 0 && offerPartOption.priceInclVat !== _oldPriceInclVat;
                            }
                        };

                        $scope.getSupplierAmountType = function (offerPartOption) {
                            return offerPartOption.amountType.name === 'PIECES'
                                ? $translate.instant('amount.type.PIECES')
                                : $translate.instant('common.people');
                        };

                        $scope.fieldChangedForDateSupplier = function (offerPartOption) {
                            // if ($scope.offer.status.name === 'ANSWERED' && offerPartOption.status.name === 'ACCEPTED') {
                            //     return false;
                            // }
                            if (
                                $scope.offer.statusDetailed.name === 'SENT' &&
                                (offerPartOption.status.name === 'EDITED_BY_SUPPLIER' ||
                                    offerPartOption.status.name === 'ACCEPTED')
                            ) {
                                return false;
                            } else {
                                return (
                                    offerPartOption.status.name !== 'ADDED_WAITING_FOR_CREATOR' &&
                                    ($scope.hasFieldValueChanged(
                                        offerPartOption.oldDateTimeFrom,
                                        offerPartOption.dateTimeFrom
                                    ) ||
                                        $scope.hasFieldValueChanged(
                                            offerPartOption.oldDateTimeTo,
                                            offerPartOption.dateTimeTo
                                        ))
                                );
                            }
                        };

                        $scope.enableOfferPartInfo = function (offerPartOption) {
                            if (
                                CommonFunctions.hasIntersectValue(
                                    [$scope.offer.statusDetailed.name],
                                    DISPLAY_TOOLTIP_STATUS
                                )
                            ) {
                                if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER' && !offerPartOption.id) {
                                    //newly added offerpart has no id, so as to make hover result particular to that row only
                                    $scope.ROPInfo = 'info' + offerPartOption.resourceName;
                                    return;
                                }
                                if (
                                    $scope.isOfferPartOptionInSupplierWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartInCreatorWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartOptionInCreatorDeclinedStates(offerPartOption) ||
                                    $scope.showPriceChangeToolTipForOfferByStatus(offerPartOption, [
                                        'BOOKED_LOCKED_BY_SUPPLIER',
                                        'BOOKED_WAITING_FOR_CREATOR',
                                        'BOOKED_WAITING_FOR_SUPPLIER',
                                    ]) ||
                                    $scope.displayOfferPartChangeToolTip(
                                        offerPartOption,
                                        $scope.getOldValueForChangedTitle(offerPartOption, 'AMOUNT'),
                                        offerPartOption.amount
                                    ) ||
                                    $scope.displayOfferPartChangeToolTipForAmountType(offerPartOption) ||
                                    $scope.fieldChangedForDateSupplier(offerPartOption) ||
                                    $scope.showPriceChangeToolTip(offerPartOption) ||
                                    ['ADDED_WAITING_FOR_SUPPLIER_DECLINED', 'DELETED_BY_SUPPLIER'].includes(
                                        offerPartOption.status.name
                                    )
                                ) {
                                    $scope.ROPInfo = 'info' + offerPartOption.id;
                                }
                            }
                        };

                        $scope.disableOfferPartInfo = function (offerPartOption) {
                            if (
                                CommonFunctions.hasIntersectValue(
                                    [$scope.offer.statusDetailed.name],
                                    DISPLAY_TOOLTIP_STATUS
                                )
                            ) {
                                if (offerPartOption.status.name == 'ADDED_BY_SUPPLIER') {
                                    $scope.ROPInfo = undefined;
                                    return;
                                }
                                if (
                                    $scope.isOfferPartOptionInSupplierWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartInCreatorWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartOptionInCreatorDeclinedStates(offerPartOption) ||
                                    $scope.showPriceChangeToolTipForOfferByStatus(offerPartOption, [
                                        'BOOKED_LOCKED_BY_SUPPLIER',
                                        'BOOKED_WAITING_FOR_CREATOR',
                                        'BOOKED_WAITING_FOR_SUPPLIER',
                                    ]) ||
                                    !$scope.displayOfferPartChangeToolTip(
                                        offerPartOption,
                                        $scope.getOldValueForChangedTitle(offerPartOption, 'AMOUNT'),
                                        offerPartOption.amount
                                    ) ||
                                    !$scope.displayOfferPartChangeToolTipForAmountType(offerPartOption) ||
                                    $scope.fieldChangedForDateSupplier(offerPartOption) ||
                                    $scope.showPriceChangeToolTip(offerPartOption) ||
                                    ['ADDED_WAITING_FOR_SUPPLIER_DECLINED', 'DELETED_BY_SUPPLIER'].includes(
                                        offerPartOption.status.name
                                    )
                                ) {
                                    $scope.ROPInfo = undefined;
                                }
                            }
                        };

                        $scope.showPriceChangeToolTipForOfferByStatus = function (offerPartOption, statusList) {
                            if ($scope.hideToolTipForGivenPartStatuses(offerPartOption.status)) {
                                return false;
                            }
                            let _isOfferStatusMatched = CommonFunctions.hasIntersectValue(
                                [$scope.offer.status.name],
                                statusList
                            );
                            let _oldPrice = offerPartOption.oldPrice || 0;
                            let _newPrice =
                                offerPartOption.status.name === 'EDITED_BY_SUPPLIER'
                                    ? offerPartOption.newPrice
                                    : offerPartOption.price || 0;
                            let _valueChanged = _oldPrice !== _newPrice;
                            return (
                                _isOfferStatusMatched &&
                                UtilFunctions.isGreaterOrEqualToZero(_oldPrice) &&
                                _valueChanged
                            );
                        };

                        $scope.hideToolTipForGivenPartStatuses = function (status) {
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
                                if (_.contains(HIDE_CHANGE_TOOLTIP_PART_STATUS, status.name.trim())) {
                                    return true;
                                }
                            }
                            return false;
                        };

                        $scope.isOfferPartOptionInSupplierWaitingStates = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER') {
                                return false;
                            }
                            let offerPartIsInSupplierWaitingStates = false;
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            angular.forEach(GlobalConstants.SUPPLIER_WAITING_STATES, function (state) {
                                if (!offerPartIsInSupplierWaitingStates) {
                                    if (
                                        $scope.layout === 'supplier' &&
                                        originalOfferPart &&
                                        originalOfferPart.status.name === state
                                    ) {
                                        offerPartIsInSupplierWaitingStates = true;
                                    }
                                    if ($scope.layout === 'creator' && offerPartOption.status.name === state) {
                                        offerPartIsInSupplierWaitingStates = true;
                                    }
                                }
                            });
                            return offerPartIsInSupplierWaitingStates;
                        };

                        $scope.isOfferPartOptionInCreatorDeclinedStates = function (offerPartOption) {
                            let offerPartIsInCreatorDeclinedStates = false;
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            angular.forEach(GlobalConstants.CREATOR_DECLINED_STATES, function (state) {
                                if (!offerPartIsInCreatorDeclinedStates) {
                                    if (originalOfferPart && originalOfferPart.status.name === state) {
                                        offerPartIsInCreatorDeclinedStates = true;
                                    }
                                }
                            });
                            return offerPartIsInCreatorDeclinedStates;
                        };

                        $scope.displayOfferPartChangeToolTip = function (offerPartOption, oldValue, newValue) {
                            if (
                                $scope.hasFieldValueChanged(oldValue, newValue) &&
                                !$scope.displayOfferPartChangeSummary(offerPartOption)
                            ) {
                                return (
                                    !$scope.hideToolTipForGivenPartStatuses(offerPartOption.status) &&
                                    $scope.offer.statusDetailed.name !== 'SENT'
                                );
                            }
                            return false;
                        };

                        $scope.getOldValueForChangedTitle = function (offerPartOption, type) {
                            let value = 0;
                            switch (type) {
                                case 'AMOUNT':
                                    value = amountAndPriceService.getOldAmountValue($scope.offer, offerPartOption);
                                    break;
                                case 'PRICE':
                                    value = amountAndPriceService.getOldPriceValue($scope.offer, offerPartOption);
                                    break;
                                case 'AMOUNT_TYPE':
                                    value = amountAndPriceService.getOldAmountTypeValue($scope.offer, offerPartOption);
                                    break;
                            }
                            return UtilFunctions.isUndefinedOrNull(value) ? 0 : value;
                        };

                        $scope.displayOfferPartChangeToolTipForAmountType = function (offerPartOption) {
                            if ($scope.checkIfAmountTypeHasChanged(offerPartOption)) {
                                return !$scope.hideToolTipForGivenPartStatuses(offerPartOption.status);
                            }
                            return false;
                        };

                        $scope.checkIfAmountTypeHasChanged = function (offerPartOption) {
                            let oldType = amountAndPriceService.getOldAmountType($scope.offer, offerPartOption);
                            let newType = offerPartOption.amountType.name;
                            if (!oldType) {
                                return false;
                            }
                            if (oldType === newType) {
                                return false;
                            }
                            if (oldType === 'SAME_AS_EVENT' && newType === 'PEOPLE') {
                                return false;
                            }

                            if (newType === 'SAME_AS_EVENT' && oldType === 'PEOPLE') {
                                return false;
                            }
                            return (
                                $scope.hasFieldValueChanged(oldType, offerPartOption.amountType.name) &&
                                $scope.offer.statusDetailed.name !== 'SENT' &&
                                offerPartOption.status.name !== 'EDITED_BY_SUPPLIER'
                            );
                        };

                        $scope.showPriceChangeToolTip = function (offerPartOption) {
                            if ($scope.hideToolTipForGivenPartStatuses(offerPartOption.status)) {
                                return false;
                            }

                            let _showPriceForFinished = $scope.showPriceChangeToolTipForFinishedOffer(offerPartOption);
                            let _oldPrice = offerPartOption.oldPrice;
                            let _newPrice = offerPartOption.price || 0;
                            let _isOldPriceExists = UtilFunctions.isDefinedAndNotNull(_oldPrice);
                            let _changed = _oldPrice !== _newPrice;
                            let _isOfferStatusMatched = CommonFunctions.hasIntersectValue(
                                [$scope.offer.status.name],
                                ['ANSWERED']
                            );
                            let _showPriceForAnswered = _isOldPriceExists && _changed && _isOfferStatusMatched;

                            return _showPriceForFinished || _showPriceForAnswered;
                        };

                        $scope.getTooltipValueAfterFinished = function (offerPartOption, type) {
                            let oldString;
                            let newString;
                            switch (type) {
                                case 'AMOUNT':
                                    oldString = $scope.getOldValueForChangedTitle(offerPartOption, type);
                                    newString = amountAndPriceService.getAmount(this.offer, offerPartOption);
                                    break;
                                case 'PRICE':
                                    oldString = $scope.viewAmountLocalisedWithCurrency(
                                        $scope.getOldValueForChangedTitle(offerPartOption, type),
                                        $scope.offer.currency
                                    );
                                    newString = $scope.viewAmountLocalisedWithCurrency(
                                        amountAndPriceService.getPrice(this.offer, offerPartOption),
                                        $scope.offer.currency
                                    );
                                    break;
                            }
                            return TooltipValueService.getTooltipValueAfterFinished(oldString, newString, type);
                        };

                        $scope.showPriceChangeToolTipForFinishedOffer = function (offerPartOption) {
                            var correctOfferStatus = CommonFunctions.hasIntersectValue(
                                [$scope.offer.status.name],
                                ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR']
                            );
                            var correctFinishedCondition =
                                offerPartOption.finalPrice !== offerPartOption.price &&
                                offerPartOption.status.name !== 'EDITED_WAITING_FOR_CREATOR';
                            return correctOfferStatus && correctFinishedCondition && !$scope.finalPriceAndAmountEdit;
                        };

                        $scope.showAmountChangeToolTipForFinishedOffer = function (offerPartOption) {
                            var correctOfferStatus = CommonFunctions.hasIntersectValue(
                                [$scope.offer.status.name],
                                ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR']
                            );
                            var correctFinishedCondition =
                                offerPartOption.finalAmount !== offerPartOption.amount &&
                                offerPartOption.status.name !== 'EDITED_WAITING_FOR_CREATOR';
                            return correctOfferStatus && correctFinishedCondition && !$scope.finalPriceAndAmountEdit;
                        };

                        $scope.displayOfferPartChangeSummary = function (offerPartOption) {
                            return (
                                $scope.ROPInfo === 'info' + offerPartOption.id ||
                                $scope.ROPInfo === 'info' + offerPartOption.resourceName
                            );
                        };

                        //Used
                        $scope.showSupplierOptions = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_SUPPLIER' || $scope.layout !== 'supplier') {
                                return false;
                            }
                            let suppOptionFlag;
                            suppOptionFlag =
                                $scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER' &&
                                ($scope.isOfferPartOptionInSupplierWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartOptionInCreatorDeclinedStates(offerPartOption));
                            if (suppOptionFlag && $scope.colspan === 2) {
                                $scope.colspan = 1;
                            }
                            $scope.blankTableHeader = !!suppOptionFlag;
                            return suppOptionFlag;
                        };

                        $scope.acceptOfferPartOptionSupplier = function (offerPartOption) {
                            var offerPartOptionMap = {
                                offerPartOption: offerPartOption,
                                action: 'ACCEPTED_BY_SUPPLIER',
                            };
                            $scope.removeOfferPartOptionFromMap(offerPartOption);
                            //$scope.offerPartOptionsWithActions.push(offerPartOptionMap);

                            var originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                            $scope.reassignOriginalValuesToOfferPartOption(offerPartOption, originalOfferPartOption);
                            if (originalOfferPartOption.status.name === 'EDITED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'ACCEPTED';
                            }
                            if (originalOfferPartOption.status.name === 'ADDED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'ACCEPTED';
                            }
                            if (originalOfferPartOption.status.name === 'DELETED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'NOT_DELIVERABLE';
                            }
                            $scope.flagForAcceptedWithChanges = undefined;
                            $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);

                            if ($scope.supplierActionOnCreatorChanges === 'DECLINE_ALL') {
                                $scope.btnMap = { name: 'requestOfferPart.button.action.all', ngclass: 'spAction' };
                            }
                        };

                        $scope.removeOfferPartOptionFromMap = function (offerPartOption) {
                            angular.forEach($scope.offerPartOptionsWithActions, function (rowAndAction, i) {
                                if (rowAndAction.offerPartOption.id === offerPartOption.id) {
                                    $scope.offerPartOptionsWithActions.splice(i, 1);
                                }
                            });
                        };

                        $scope.reassignOriginalValuesToOfferPartOption = function (
                            offerPartOption,
                            originalOfferPartOption
                        ) {
                            offerPartOption.amount = originalOfferPartOption.amount;
                            offerPartOption.amountType.name = originalOfferPartOption.amountType.name;
                            offerPartOption.dateTimeFrom = originalOfferPartOption.dateTimeFrom;
                            offerPartOption.dateTimeTo = originalOfferPartOption.dateTimeTo;
                            offerPartOption.newPrice = originalOfferPartOption.price;
                            offerPartOption.price = originalOfferPartOption.price;
                            offerPartOption.finalPaidOnsite = originalOfferPartOption.finalPaidOnsite;
                        };

                        $scope.declineOfferPartOptionSupplier = function (offerPartOption) {
                            var offerPartOptionMap = {
                                offerPartOption: offerPartOption,
                                action: 'DECLINED_BY_SUPPLIER',
                            };
                            $scope.removeOfferPartOptionFromMap(offerPartOption);

                            var originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                            $scope.reassignOriginalValuesToOfferPartOption(offerPartOption, originalOfferPartOption);
                            if (originalOfferPartOption.status.name === 'EDITED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'DECLINED';
                            }
                            if (originalOfferPartOption.status.name === 'ADDED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'ADDED_WAITING_FOR_SUPPLIER_DECLINED';
                            }
                            if (originalOfferPartOption.status.name === 'DELETED_WAITING_FOR_SUPPLIER') {
                                offerPartOption.status.name = 'DELETED_WAITING_FOR_SUPPLIER_DECLINED';
                            }
                            $scope.flagForAcceptedWithChanges = undefined;
                            $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);

                            if ($scope.supplierActionOnCreatorChanges === 'ACCEPT_ALL') {
                                $scope.btnMap = { name: 'requestOfferPart.button.action.all', ngclass: 'spAction' };
                            }
                        };

                        $scope.displayOfferPartOptionNameEditField = function (offerPartOption) {
                            return (
                                (!$scope.isOfferInSupplierEditDisabledState() &&
                                    !$scope.isOfferInCanceledState($scope.offer) &&
                                    !$scope.isOfferInFromFinishedState() &&
                                    ['DELETED_BY_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(offerPartOption.status.name) ===
                                    -1) ||
                                ($scope.isOfferInFromFinishedState() && !offerPartOption.resourceId)
                            );
                        };

                        $scope.displayOfferPartOptionNameDisabledField = function (offerPartOption) {
                            return (
                                ($scope.isOfferInFromFinishedState() ||
                                    $scope.isOfferInSupplierEditDisabledState() ||
                                    $scope.isOfferInCanceledState($scope.offer) ||
                                    ['DELETED_BY_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(offerPartOption.status.name) >
                                    -1) &&
                                ($scope.data.resourceId || $scope.data.referenceId) &&
                                $scope.data.resourceName
                            );
                        };

                        $scope.displayOfferPartOptionPriceEditField = function (offerPartOption) {
                            return (
                                !$scope.isOfferInFromFinishedState() &&
                                !$scope.isOfferInSupplierEditDisabledState() &&
                                !$scope.isOfferInCanceledState($scope.offer) &&
                                ['DELETED_BY_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(offerPartOption.status.name) === -1
                            );
                        };

                        $scope.displayOfferPartOptionFinalPriceEditField = function (offerPartOption) {
                            return (
                                $scope.isOfferInFromFinishedState() &&
                                $scope.finalPriceAndAmountEdit &&
                                !$scope.isOfferInCanceledState($scope.offer) &&
                                ['DELETED_BY_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(offerPartOption.status.name) === -1
                            );
                        };

                        $scope.displayOfferPartOptionPriceDisabledField = function (offerPartOption) {
                            return (
                                ($scope.isOfferInSupplierEditDisabledState() &&
                                    !offerPartOption.canEditOptionBySupplier &&
                                    !$scope.finalPriceAndAmountEdit) ||
                                $scope.isOfferInCanceledState($scope.offer) ||
                                ['DELETED_BY_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(offerPartOption.status.name) > -1 ||
                                $scope.offer.vatIncludedInPrice
                            );
                        };

                        $scope.displayOfferPartOptionVatEditField = function (offerPartOption) {
                            return (
                                (!$scope.isOfferInSupplierEditDisabledState() || $scope.finalPriceAndAmountEdit) &&
                                !$scope.isOfferInCanceledState($scope.offer) &&
                                ['DELETED_BY_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(offerPartOption.status.name) === -1
                            );
                        };

                        $scope.displayOfferPartOptionVatDisabledField = function (offerPartOption) {
                            return (
                                ($scope.isOfferInSupplierEditDisabledState() &&
                                    !offerPartOption.canEditOptionBySupplier &&
                                    !$scope.finalPriceAndAmountEdit) ||
                                $scope.isOfferInCanceledState($scope.offer) ||
                                ['DELETED_BY_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(offerPartOption.status.name) > -1
                            );
                        };

                        $scope.hideDateEditTooltip = function (offerPartOption) {
                            return (
                                ($scope.isOfferInSupplierEditDisabledState() &&
                                    !offerPartOption.canEditOptionBySupplier) ||
                                $scope.isOfferInCanceledState($scope.offer) ||
                                $scope.doStrikeThrough(offerPartOption) ||
                                ['DELETED_BY_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(offerPartOption.status.name) > -1
                            );
                        };

                        $scope.hideAmountEditTooltip = function (offerPartOption) {
                            return (
                                ($scope.isOfferInSupplierEditDisabledState() &&
                                    !offerPartOption.canEditOptionBySupplier) ||
                                $scope.isOfferInCanceledState($scope.offer) ||
                                $scope.doStrikeThrough(offerPartOption) ||
                                ['DELETED_BY_SUPPLIER', 'NOT_DELIVERABLE'].indexOf(offerPartOption.status.name) > -1
                            );
                        };

                        $scope.setOfferPartOptionColWidth = function (col) {
                            switch (col) {
                                case 'name':
                                    if ($scope.isOfferInFinishedToApprovedStates($scope.offer) && $scope.includeVat) {
                                        return '23%';
                                    } else if (
                                        $scope.includeVat &&
                                        !$scope.isOfferInFinishedToApprovedStates($scope.offer)
                                    ) {
                                        return '24%';
                                    } else if (
                                        $scope.includeVat &&
                                        ($scope.showDiffCol || $scope.showDiffColumnSupplier)
                                    ) {
                                        return '21%';
                                    } else if (
                                        !$scope.includeVat &&
                                        $scope.isOfferInFinishedToApprovedStates($scope.offer)
                                    ) {
                                        return '25%';
                                    } else {
                                        return '30%';
                                    }
                                    break;
                                case 'amount':
                                    if ($scope.includeVat && ($scope.showDiffCol || $scope.showDiffColumnSupplier)) {
                                        return '8%';
                                    } else if (
                                        $scope.includeVat &&
                                        !($scope.showDiffCol || $scope.showDiffColumnSupplier)
                                    ) {
                                        return '10%';
                                    } else if (
                                        !$scope.includeVat &&
                                        ($scope.showDiffCol || $scope.showDiffColumnSupplier)
                                    ) {
                                        return '10%';
                                    } else {
                                        return '14%';
                                    }
                                    break;
                                case 'date':
                                    if ($scope.isOfferInFinishedToApprovedStates($scope.offer) && $scope.includeVat) {
                                        return '17%';
                                    } else {
                                        return '18%';
                                    }
                                    break;
                                case 'excl.vat':
                                    if ($scope.includeVat) {
                                        return '10%';
                                    } else if (
                                        $scope.isOfferInFinishedToApprovedStates($scope.offer) &&
                                        !$scope.includeVat
                                    ) {
                                        return '14%';
                                    } else if (
                                        $scope.isOfferInFinishedToApprovedStates($scope.offer) &&
                                        !$scope.includeVat &&
                                        ($scope.showDiffCol || $scope.showDiffColumnSupplier)
                                    ) {
                                        return '12%';
                                    } else {
                                        return '16%';
                                    }
                                    break;
                                case 'incl.vat':
                                    if (!$scope.isOfferInFinishedToApprovedStates($scope.offer)) {
                                        return '13%';
                                    } else {
                                        return '10%';
                                    }
                                    break;
                                case 'total.price':
                                    if ($scope.isOfferInFinishedToApprovedStates($scope.offer) && $scope.includeVat) {
                                        return '14%';
                                    } else if (
                                        $scope.isOfferInFinishedToApprovedStates($scope.offer) &&
                                        $scope.includeVat &&
                                        ($scope.showDiffCol || $scope.showDiffColumnSupplier)
                                    ) {
                                        return '13%';
                                    } else if (
                                        !$scope.isOfferInFinishedToApprovedStates($scope.offer) &&
                                        $scope.includeVat
                                    ) {
                                        return '18%';
                                    } else if (
                                        $scope.isOfferInFinishedToApprovedStates($scope.offer) &&
                                        !$scope.includeVat
                                    ) {
                                        return '17%';
                                    } else if (
                                        $scope.isOfferInFinishedToApprovedStates($scope.offer) &&
                                        !$scope.includeVat &&
                                        ($scope.showDiffCol || $scope.showDiffColumnSupplier)
                                    ) {
                                        return '15%';
                                    } else {
                                        return '22%';
                                    }
                                    break;
                                case 'onsite':
                                    if ($scope.includeVat && ($scope.showDiffCol || $scope.showDiffColumnSupplier)) {
                                        return '8%';
                                    } else if (!$scope.includeVat) {
                                        return '12%';
                                    } else if (
                                        !$scope.includeVat &&
                                        ($scope.showDiffCol || $scope.showDiffColumnSupplier)
                                    ) {
                                        return '11%';
                                    } else {
                                        return '11%';
                                    }
                                    break;
                            }
                        };

                        $scope.$on('request:offer:part:option:updated:client:side:supplier', (event, data) => {
                            if ($scope.data.id === data.offerPartOption.id) {
                                $scope.data = angular.copy(data.offerPartOption);
                                $scope.updatedClientSideOfferPartOption = angular.copy(data.offerPartOption);
                            }
                        });

                        $rootScope.$on('supplier:resource:updated', function (event, data) {
                            if ($scope.data && (data.requestOfferPartOptionId && data.requestOfferPartOptionId === $scope.data.id) ||
                                (data.requestOfferPartOptionDummyId && data.requestOfferPartOptionDummyId === $scope.data.dummyId)) {
                                if (!$scope.data.resourceId || $scope.data.resourceId !== data.resourceId) {
                                    $scope.data.resourceId = data.resourceId;
                                }
                                $scope.data.resourceName = data.resourceName;
                                $scope.data.isResourceUpdated = data.isResourceUpdated;
                                $scope.data.isResourcePublic = data.isResourcePublic;
                                $scope.$broadcast(
                                    'angucomplete-alt:changeInput',
                                    $scope.uniqueId.toString(),
                                    data.resourceName
                                );
                            }
                        });

                        $rootScope.$on('open:edit:offer:part:option:supplier', function (event, data) {
                            if ($scope.data.id === data.offerPartOption.id) {
                                $scope.data.canEditOptionBySupplier = true;
                            }
                        });

                        $rootScope.$on('request:offer:part:option:updated:by:supplier', (event, data) => {
                            if ($scope.data.id === data.requestOfferPartOption.id) {
                                $scope.data.resourceId = data.requestOfferPartOption.resourceId;
                                $scope.data.status.name = data.requestOfferPartOption.status.name;
                                $scope.data.status = data.requestOfferPartOption.status;
                                $scope.data.price = UtilFunctions.isGreaterOrEqualToZero($scope.data.newPrice)
                                    ? $scope.data.newPrice
                                    : data.requestOfferPartOption.price;
                                if ($scope.includeVat && $scope.offer.vatIncludedInPrice) {
                                    $scope.data.priceInclVat =
                                        amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                            $scope.data.price,
                                            $scope.data.vat
                                        );
                                    $scope.data.newPriceInclVat =
                                        amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                            $scope.data.price,
                                            $scope.data.vat
                                        );
                                }
                                $scope.data.oldPrice = data.requestOfferPartOption.oldPrice;
                                $scope.data.finalPrice = data.requestOfferPartOption.finalPrice;
                                $scope.data.amount = data.requestOfferPartOption.amount;
                                $scope.data.oldAmount = data.requestOfferPartOption.oldAmount;
                                $scope.data.finalAmount = data.requestOfferPartOption.finalAmount;
                                $scope.data.finalPaidOnsite = data.requestOfferPartOption.finalPaidOnsite;
                                $scope.data.loading = false;
                                if (
                                    RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf($scope.offer.status.name) > -1
                                ) {
                                    $scope.getSupplierDifference =
                                        amountAndPriceSupplierService.getRequestPartOptionDifference(
                                            $scope.offer,
                                            data.requestOfferPartOption,
                                            $scope.includeVat
                                        );
                                    $scope.showDiffColumnSupplier =
                                        !!amountAndPriceSupplierService.getRequestPartOptionDifference(
                                            $scope.offer,
                                            data.requestOfferPartOption,
                                            $scope.includeVat
                                        );
                                }
                            }
                        });

                        let offerPartOptionUpdateClient = $rootScope.$on(
                            'request:offer:part:option:updated',
                            (event, data) => {
                                if ($scope.data.id === data.offerPartOption.id) {
                                    $scope.data = angular.copy(data.offerPartOption);
                                }
                            }
                        );

                        let confirmDeleteToggle = $rootScope.$on(
                            'confirm:delete:toggle:part:option',
                            function (event, data) {
                                if ($scope.data.id !== data.requestOfferPartOptionId) {
                                    $scope.data.showDeleteConfirmation = false;
                                }
                            }
                        );

                        $scope.$on('$destroy', function () {
                            offerPartOptionUpdateClient();
                            confirmDeleteToggle();
                        });

                        $rootScope.$on('part:option:deleted:with:is:selected:radio:value', (event, data) => {
                            if ($scope.offerPart.id === data.requestOfferPartId) {
                                if (data.offerPartOptions.length < 1) {
                                    $scope.offerPart.hasRequestOfferPartOptions = false;
                                }
                            }
                        });

                        let applySupplierActionOnCreatorChanges = $scope.$on(
                            'apply.supplier.action.on.creator.changes',
                            (event, data) => {
                                let _offerPartOptionsWithActions = data.offerPartOptionsWithActions;
                                $scope.offer.status.name = 'BOOKED_WAITING_FOR_CREATOR';
                                $scope.offer.statusDetailed.name = 'BOOKED_WAITING_FOR_CREATOR';
                                if (_offerPartOptionsWithActions.length > 0) {
                                    angular.forEach(_offerPartOptionsWithActions, (_offerPartOptionsWithAction) => {
                                        if ($scope.data.id) {
                                            if (_offerPartOptionsWithAction.offerPartOption.id === $scope.data.id) {
                                                if (_offerPartOptionsWithAction.action === 'ADDED_BY_SUPPLIER') {
                                                    $scope.data.status.name = 'ADDED_WAITING_FOR_CREATOR';
                                                } else if (
                                                    _offerPartOptionsWithAction.action === 'DELETED_BY_SUPPLIER'
                                                ) {
                                                    $scope.data.status.name = 'DELETED_WAITING_FOR_CREATOR';
                                                }
                                            }
                                            if (
                                                _offerPartOptionsWithAction.offerPartOption.id ===
                                                $scope.originalOfferPartOption.id
                                            ) {
                                                if (_offerPartOptionsWithAction.action === 'ADDED_BY_SUPPLIER') {
                                                    $scope.originalOfferPartOption.status.name =
                                                        'ADDED_WAITING_FOR_CREATOR';
                                                } else if (
                                                    _offerPartOptionsWithAction.action === 'DELETED_BY_SUPPLIER'
                                                ) {
                                                    $scope.originalOfferPartOption.status.name =
                                                        'DELETED_WAITING_FOR_CREATOR';
                                                }
                                            }
                                        } else if ($scope.data.referenceId) {
                                            if (
                                                _offerPartOptionsWithAction.offerPartOption.referenceId ===
                                                $scope.data.referenceId
                                            ) {
                                                if (_offerPartOptionsWithAction.action === 'ADDED_BY_SUPPLIER') {
                                                    $scope.data.status.name = 'ADDED_WAITING_FOR_CREATOR';
                                                } else if (
                                                    _offerPartOptionsWithAction.action === 'DELETED_BY_SUPPLIER'
                                                ) {
                                                    $scope.data.status.name = 'DELETED_WAITING_FOR_CREATOR';
                                                }
                                            }
                                            if (
                                                _offerPartOptionsWithAction.offerPartOption.referenceId ===
                                                $scope.originalOfferPartOption.referenceId
                                            ) {
                                                if (_offerPartOptionsWithAction.action === 'ADDED_BY_SUPPLIER') {
                                                    $scope.originalOfferPartOption.status.name =
                                                        'ADDED_WAITING_FOR_CREATOR';
                                                } else if (
                                                    _offerPartOptionsWithAction.action === 'DELETED_BY_SUPPLIER'
                                                ) {
                                                    $scope.originalOfferPartOption.status.name =
                                                        'DELETED_WAITING_FOR_CREATOR';
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        );

                        $scope.$on('$destroy', applySupplierActionOnCreatorChanges);

                        let requestOfferPartOptionDeletedClientSideOnly = $rootScope.$on(
                            'request:offer:part:option:deleted:client:side:only',
                            (event, data) => {
                                if ($scope.originalOfferPartOption.id === data.offerPartOption.id) {
                                    $scope.originalOfferPartOption.status = data.offerPartOption.status;
                                }
                            }
                        );

                        $scope.$on('$destroy', requestOfferPartOptionDeletedClientSideOnly);

                        $scope.init();

                        $scope.$on('revert.final.prices.by.supplier', () => {
                            $scope.showDiffColumnSupplier = false;
                            $scope.showDiffCol = false;
                        });

                        $scope.$on('enable.vat.included.in.price', () => {
                            if ($scope.includeVat && $scope.offer.vatIncludedInPrice) {
                                $scope.data.priceInclVat = amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                    $scope.data.price,
                                    $scope.data.vat
                                );
                                $scope.data.newPriceInclVat =
                                    amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                        $scope.data.price,
                                        $scope.data.vat
                                    );
                            }
                        });

                        $scope.$on('disable.vat.included.in.price', () => {
                            if (!$scope.offer.vatIncludedInPrice) {
                                $scope.data.priceInclVat = null;
                                $scope.data.newPriceInclVat = null;
                            }
                        });
                    },
                ],
                link: function () { },
            };
        },
    ]);
})();
