'use strict';

angular.module('core.existingServices').factory('OfferInfoStatus', [
    '$resource',
    function ($resource) {
        var exitOfferGuide = $resource(
            'api/v1/offerInfoStatus/offer/:offerId',
            {offerId: '@offerId'},
            {
                get: {method: 'GET', isArray: false},
                update: {method: 'POST', isArray: false},
            }
        );

        return {
            getOfferInfoStatus: function (offerId, successCallback, errorCallback) {
                return exitOfferGuide.get({offerId: offerId}, successCallback, errorCallback);
            },
            updateOfferInfoStatus: function (offerId, jsonObject, successCallback, errorCallback) {
                return exitOfferGuide.update({offerId: offerId}, jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

