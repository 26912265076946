const CHANGED_BY_ME_OFFERS = [
    'EDITED_BY_SUPPLIER',
    'BOOKED_LOCKED_BY_SUPPLIER',
    'BOOKED_WAITING_FOR_SUPPLIER',
    'FINISHED_WAITING_FOR_SUPPLIER',
];
const EDITED_BY_ME_PARTS = ['EDITED_BY_SUPPLIER', 'EDITED_WAITING_FOR_SUPPLIER', 'EDITED_WAITING_FOR_CREATOR_DECLINED'];
const DELETED_BY_ME_PARTS = [
    'DELETED_BY_SUPPLIER',
    'DELETED_WAITING_FOR_SUPPLIER',
    'DELETED_WAITING_FOR_CREATOR_DECLINED',
];
const ADDED_BY_ME_PARTS = ['ADDED_BY_SUPPLIER', 'ADDED_WAITING_FOR_SUPPLIER', 'ADDED_WAITING_FOR_CREATOR_DECLINED'];

const CHANGED_BY_ME_SENT_OFFERS = ['FINISHED_WAITING_FOR_CREATOR', 'BOOKED_WAITING_FOR_CREATOR'];
const EDITED_BY_ME_SENT_PARTS = ['EDITED_WAITING_FOR_CREATOR', 'EDITED_WAITING_FOR_SUPPLIER_DECLINED'];
const ADDED_BY_ME_SENT_PARTS = ['ADDED_WAITING_FOR_CREATOR', 'DELETED_WAITING_FOR_SUPPLIER_DECLINED'];
const DELETED_BY_ME_SENT_PARTS = ['DELETED_WAITING_FOR_CREATOR', 'ADDED_WAITING_FOR_SUPPLIER_DECLINED'];

const CHANGED_BY_THEM_OFFERS = ['SENT', 'BOOKED_WAITING_FOR_SUPPLIER', 'FINISHED_WAITING_FOR_SUPPLIER'];
const EDITED_BY_THEM_PARTS = ['EDITED_WAITING_FOR_SUPPLIER', 'EDITED_WAITING_FOR_CREATOR_DECLINED'];
const ADDED_BY_THEM_PARTS = ['ADDED_WAITING_FOR_SUPPLIER', 'ADDED_WAITING_FOR_CREATOR_DECLINED'];
const DELETED_BY_THEM_PARTS = ['DELETED_WAITING_FOR_SUPPLIER', 'DELETED_WAITING_FOR_CREATOR_DECLINED'];

const PART_STATE = [
    'EDITED_BY_ME',
    'EDITED_BY_ME_SENT',
    'EDITED_BY_THEM',
    'ADDED_BY_ME',
    'ADDED_BY_ME_SENT',
    'ADDED_BY_THEM',
    'DELETED_BY_ME',
    'DELETED_BY_ME_SENT',
    'DELETED_BY_THEM',
];

class NegotiationTooltipSupplierUtil {
    static editedByMe(offer, part) {
        if (CHANGED_BY_ME_OFFERS.indexOf(offer.status.name) > -1) {
            return EDITED_BY_ME_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static editedByMeInFinishedOffer(offer, part) {
        if (CHANGED_BY_ME_OFFERS.indexOf(offer.status.name) > -1) {
            return (
                EDITED_BY_ME_PARTS.indexOf(part.status.name) > -1 ||
                EDITED_BY_ME_SENT_PARTS.indexOf(part.status.name) > -1
            );
        }
    }

    static addedByMe(offer, part) {
        if (CHANGED_BY_ME_OFFERS.indexOf(offer.status.name) > -1) {
            return ADDED_BY_ME_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static addedByMeInFinishedOffer(offer, part) {
        if (CHANGED_BY_ME_OFFERS.indexOf(offer.status.name) > -1) {
            return (
                ADDED_BY_ME_PARTS.indexOf(part.status.name) > -1 ||
                ADDED_BY_ME_SENT_PARTS.indexOf(part.status.name) > -1
            );
        }
    }

    static deletedByMe(offer, part) {
        if (CHANGED_BY_ME_OFFERS.indexOf(offer.status.name) > -1) {
            return DELETED_BY_ME_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static editedByMeAndSent(offer, part) {
        if (CHANGED_BY_ME_SENT_OFFERS.indexOf(offer.status.name) > -1) {
            return EDITED_BY_ME_SENT_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static addedByMeAndSent(offer, part) {
        if (CHANGED_BY_ME_SENT_OFFERS.indexOf(offer.status.name) > -1) {
            return ADDED_BY_ME_SENT_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static deletedByMeAndSent(offer, part) {
        if (CHANGED_BY_ME_SENT_OFFERS.indexOf(offer.status.name) > -1) {
            return DELETED_BY_ME_SENT_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static editedByThem(offer, part) {
        if (CHANGED_BY_THEM_OFFERS.indexOf(offer.status.name) > -1) {
            return EDITED_BY_THEM_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static addedByThem(offer, part) {
        if (CHANGED_BY_THEM_OFFERS.indexOf(offer.status.name) > -1) {
            return ADDED_BY_THEM_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static deletedByThem(offer, part) {
        if (CHANGED_BY_THEM_OFFERS.indexOf(offer.status.name) > -1) {
            return DELETED_BY_THEM_PARTS.indexOf(part.status.name) > -1;
        }
    }

    static getPartNotificationState(offer, part) {
        if (NegotiationTooltipSupplierUtil.editedByMe(offer, part)) {
            return PART_STATE[0];
        }
        if (NegotiationTooltipSupplierUtil.editedByMeAndSent(offer, part)) {
            return PART_STATE[1];
        }
        if (NegotiationTooltipSupplierUtil.editedByThem(offer, part)) {
            return PART_STATE[2];
        }

        if (NegotiationTooltipSupplierUtil.addedByMe(offer, part)) {
            return PART_STATE[3];
        }
        if (NegotiationTooltipSupplierUtil.addedByMeAndSent(offer, part)) {
            return PART_STATE[4];
        }
        if (NegotiationTooltipSupplierUtil.addedByThem(offer, part)) {
            return PART_STATE[5];
        }

        if (NegotiationTooltipSupplierUtil.deletedByMe(offer, part)) {
            return PART_STATE[6];
        }
        if (NegotiationTooltipSupplierUtil.deletedByMeAndSent(offer, part)) {
            return PART_STATE[7];
        }
        if (NegotiationTooltipSupplierUtil.deletedByThem(offer, part)) {
            return PART_STATE[8];
        }
        if (offer.status.name === 'FINISHED_WAITING_FOR_SUPPLIER') {
            if (NegotiationTooltipSupplierUtil.editedByMeInFinishedOffer(offer, part)) {
                return PART_STATE[0];
            }

            if (NegotiationTooltipSupplierUtil.addedByMeInFinishedOffer(offer, part)) {
                return PART_STATE[3];
            }
        }
    }
}

const tooltipUtil = NegotiationTooltipSupplierUtil;
export default tooltipUtil;
