import amountAndPriceService from '../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class UpdatePartMarkupModalCtrl {
    constructor($scope, RequestOfferPart, $element, data, close, $rootScope, EventEnum) {
        this._injected = {$scope, RequestOfferPart, $element, data, close, $rootScope, EventEnum};

        ScopeFunctions.addToScope($scope);

        this.part = data.part;
        this.part.markupValue = data.part.markupValue;
        let originalMarkupValue = this.part.markupValue;
        this.customerPrice = data.customerPrice;
        this.originalPartMarkupValue = data.part.markupValue;
        this.originalCustomerPrice = data.customerPrice;
        this.disableupdatePartMarkupValue = false;

        $scope
            .$watch('updatePartMarkupCtrl.part.markupValue', (newValue, oldValue) => {
                if (newValue) {
                    if (newValue === oldValue) {
                        this.part.markupValue = this.round(this.part.markupValue);
                    } else {
                        this.part.markupValue = newValue;
                    }
                }
                if (
                    oldValue === newValue ||
                    this.round(originalMarkupValue) === newValue ||
                    originalMarkupValue === newValue
                ) {
                    this.disableupdatePartMarkupValue = true;
                } else {
                    this.disableupdatePartMarkupValue = false;
                }
            })
            .bind(this);
    }

    round(value) {
        let decimals = 2; //only take 2 digit after decimal
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    }

    calculateMarkupFromCustomerPrice(part) {
        this.part.markupValue = amountAndPriceService.getMarkupFromCustomerPrice(part, this.customerPrice);
    }

    calculateCustomerPriceFromMarkup(part) {
        this.customerPrice = amountAndPriceService.getCustomerPriceFromMarkup(part, part.markupValue);
    }

    updatePartMarkupValue() {
        const { $scope, RequestOfferPart, $rootScope, EventEnum } = this._injected;
        $scope.startProgress('part-markup-value');
        RequestOfferPart.updatePartMarkupValue(
            this.part.id,
            this.part,
            (response) => {
                $rootScope.$broadcast(EventEnum.REQUEST_CHANGED_IN_BUDGET, { request: response });
                this.close();
                $scope.endProgress('part-markup-value');
            },
            $scope.endWithErrorOverlay('part-markup-value')
        );
    }

    cancel() {
        this.part.markupValue = this.originalPartMarkupValue;
        this.customerPrice = this.originalCustomerPrice;
        this.close();
    }

    close() {
        const { close, $element } = this._injected;
        this.part.markupValue = this.originalPartMarkupValue;
        this.customerPrice = this.originalCustomerPrice;
        $element.modal('hide');
        close(null, 500);
    }
}

UpdatePartMarkupModalCtrl.$inject = [
    '$scope',
    'RequestOfferPart',
    '$element',
    'data',
    'close',
    '$rootScope',
    'EventEnum',
];
export default UpdatePartMarkupModalCtrl;
