'use strict';

angular.module('core.existingServices').factory('AdditionalInfoOptions', [
    '$resource',
    function ($resource) {
        var additionalInfoOptionsV1Rest = $resource(
            'api/v1/additionalInfoOptions/event/:eventId',
            {eventId: '@eventId'},
            {
                list: {method: 'GET', isArray: true},
                update: {method: 'PUT', isArray: true},
            }
        );

        var participantSelectedQueryListRest = $resource(
            'api/v1/additionalInfoOptions/:eventId/additionalInfoSelectedQueries',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: true},
            }
        );

        return {
            getAdditionalInfoOptionsForEvent: function (eventId, successCallback, errorCallback) {
                return additionalInfoOptionsV1Rest.list({eventId: eventId}, successCallback, errorCallback);
            },
            updateAdditionalInfoOptionsForEvent: function (eventId, jsonObject, successCallback, errorCallback) {
                return additionalInfoOptionsV1Rest.update(
                    {eventId: eventId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            additionalInfoSelectedQueries: function (eventId, successCallback, errorCallback) {
                participantSelectedQueryListRest.get({eventId: eventId}, successCallback, errorCallback);
            },
        };
    },
]);

