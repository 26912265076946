import { OgModal } from "../../utils/global/ogModal";
import CommonFunctions from "../../utils/CommonFunctionUtil";
import { ScopeFunctions } from "../../utils/global/scopeFunctions";

("use strict");

angular.module("dialog").directive("ogAddUserToUserGroupModal", [
  "$timeout",
  "$log",
  "$routeParams",
  "$rootScope",
  "CustomerUser",
  "Event",
  "ValidationService",
  "AuthenticationService",
  "UserGroup",
  "EventEnum",
  function (
    $timeout,
    $log,
    $routeParams,
    $rootScope,
    CustomerUser,
    Event,
    ValidationService,
    AuthenticationService,
    UserGroup,
    EventEnum
  ) {
    return new OgModal({
      name: "add:user:to:user:group",
      type: "custom",
      controller: function ($scope) {
        ScopeFunctions.addToScope($scope);
        ScopeFunctions.addToRootScope($scope, $rootScope);
        $scope.userGroupDialogForm = {};
        $scope.form = "addUserGroupDialogForm";
        $scope.initDialog = function (data) {
          $scope.data = data.group;
          $scope.isNew = data.isNew;
          $scope.newUsers = {};
          $scope.userGroupDialogForm.addByEmailForm.$setUntouched();
          refreshDialog();

          function refreshDialog() {
            $scope.displayUserNotAddedMessage = false;
            if ($scope.data) {
              $scope.startProgress();
              UserGroup.get(
                $scope.data.id,
                $scope.dataLoaded,
                $scope.endWithError
              );
              $scope.users = UserGroup.listUsers(
                $scope.data,
                $scope.endProgress,
                $scope.endWithError
              );
            }
          }

          $scope.clearDialog = function () {
            $scope.inProgress = false;
            $scope.userGroupDialogForm.email = null;
            $scope.newUsers = null;
          };

          $scope.removeUser = function (user) {
            OgModal.open("confirmation", {
              message: "dialog.user.group.remove.user.confirm",
              okCallBack: function () {
                doRemoveUser(user);
              },
            });
          };

          function doRemoveUser(user) {
            $scope.inProgress = true;
            CustomerUser.removeUserFromGroup(
              { groupId: $scope.data.id, userId: user.id, form: $scope.form },
              refreshDialog,
              $scope.endWithError
            );
            dataChanged();
          }

          function dataChanged() {
            $rootScope.$broadcast(EventEnum.USER_IN_GROUP_DATA_CHANGED);
          }

          $scope.addAllUser = function () {
            $scope.inProgress = true;
            var emails = [];
            angular.forEach($scope.newUsers, function (value) {
              emails.push(value.email);
            });
            UserGroup.addUsers(
              {
                id: $scope.data.id,
                emails: emails,
                form: $scope.userGroupDialogForm.addByEmailForm.$name,
              },
              function (result) {
                $scope.userGroupDialogForm.email = null;
                $scope.newUsers = {};
                refreshDialog();
                if (result.added > 0 && result.invited > 0) {
                  $scope.formSuccess("dialog.user.group.invited.added");
                } else if (result.added > 0) {
                  $scope.formSuccess("dialog.user.group.added");
                } else if (result.invited > 0) {
                  $scope.formSuccess("dialog.user.group.invited");
                }
              },
              $scope.formError
            );
            $scope.clearDialog();
            dataChanged();
          };

          $scope.addUser = function () {
            if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
              return;
            }
            if (!$scope.userGroupDialogForm.addByEmailForm.$valid) {
              CommonFunctions.touchFormFields(
                $scope.userGroupDialogForm.addByEmailForm
              );
              return;
            }
            var isUserAlreadyInTheGroup = false;
            $scope.displayUserNotAddedMessage = false;
            angular.forEach($scope.users, function (value) {
              if (
                $scope.userGroupDialogForm.email.toLowerCase() === value.email
              ) {
                isUserAlreadyInTheGroup = true;
                $scope.displayUserNotAddedMessage = true;
              }
            });
            angular.forEach($scope.newUsers, function (value) {
              if (
                $scope.userGroupDialogForm.email.toLowerCase() === value.email
              ) {
                isUserAlreadyInTheGroup = true;
                $scope.displayUserNotAddedMessage = true;
              }
            });
            if (!isUserAlreadyInTheGroup) {
              $scope.startProgress();
              CustomerUser.getByEmail(
                {
                  email: $scope.userGroupDialogForm.email,
                  form: $scope.userGroupDialogForm.addByEmailForm.$name,
                },
                function (response) {
                  $scope.endProgress();
                  if (response.id) {
                    if (!$scope.newUsers[response.id]) {
                      $scope.newUsers[response.id] = response;
                    }
                  } else {
                    if (!$scope.newUsers[$scope.userGroupDialogForm.email]) {
                      $scope.newUsers[$scope.userGroupDialogForm.email] = {
                        name: $scope.userGroupDialogForm.email,
                        email: $scope.userGroupDialogForm.email,
                        id: null,
                      };
                    }
                  }
                  $scope.userGroupDialogForm.email = "";
                  $scope.userGroupDialogForm.addByEmailForm.$setUntouched();
                  $scope.userGroupDialogForm.addByEmailForm.$setPristine();
                  $scope.addAllUser();
                  dataChanged();
                },
                $scope.endWithErrorOverlay
              );
            }
          };

          $scope.removeNewUser = function (key) {
            delete $scope.newUsers[key];
          };

          $scope.newUsersAdded = function () {
            for (var prop in $scope.newUsers) {
              if ($scope.newUsers.hasOwnProperty(prop)) {
                return true;
              }
            }
            return false;
          };

          $scope.$watch(
            "userGroupDialogForm.email",
            function (newVal, oldValue) {
              if (newVal != oldValue) {
                ValidationService.clear($scope.form);
              }
            }
          );
        };

        $scope.clearFeeDialog = function () {
          $scope.group = null;
        };
      },
    });
  },
]);
