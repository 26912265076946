(function () {
    'use strict';

    angular.module('ogMapCanvas').directive('ogMapCanvas', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    url: '=ogMapCanvas',
                    show: '=ogShow',
                    // This should be either array of lat and longitude value or a string separated by ',' only
                    ogCoords: '=',
                    disableControl: '@',
                },
                // MapController copied
                controller: [
                    '$scope',
                    function ($scope) {
                        var map;
                        var self = this;

                        function inRange(min, number, max) {
                            return !isNaN(number) && number >= min && number <= max;
                        }

                        $scope.validateLatitudeLongitude = function (lat, lang) {
                            if (inRange(-90, lat, 90) && inRange(-180, lang, 180)) {
                                return true;
                            } else {
                                console.log(lat, lang, 'Error on lat and lang');
                                return false;
                            }
                        };

                        $scope.parseGoogleMapUrl = function (mapUrl, mapParams) {
                            var latitude,
                                longitude,
                                zoom = 14,
                                query;
                            if (mapUrl) {
                                var urlArray = mapUrl.split('?');
                                if (urlArray && urlArray.length > 1) {
                                    var ll;
                                    angular.forEach(urlArray[1].split('&'), function (item) {
                                        var array = item.split('=');
                                        if (array && array.length > 1) {
                                            var count = array.length;
                                            if (array[0] == 'll') {
                                                ll = decodeURIComponent(array[1]);
                                            }
                                            if (array[0] == 'q' || (count > 2 && array[count - 2].indexOf('!q') > 0)) {
                                                query = decodeURIComponent(array[count - 1]);
                                            }
                                            if (array[0] == 'z') {
                                                zoom = parseInt(array[1]);
                                            }
                                        }
                                    });
                                    var geoArray = ll ? ll.split(',') : undefined;
                                    if (geoArray && geoArray.length > 1) {
                                        latitude = geoArray[0];
                                        longitude = geoArray[1];
                                    }
                                }
                                // Could be new Google Maps url
                                if (!longitude || !latitude) {
                                    var result = mapUrl.match(/\@[0-9\.\,]+/gi);

                                    if (result && result.length) {
                                        var coords = result[0].replace('@', '').split(',');
                                        latitude = coords[0];
                                        longitude = coords[1];
                                        zoom = parseInt(coords[2]);
                                    }
                                }
                            } else if (mapParams) {
                                latitude = mapParams[0];
                                longitude = mapParams[1];
                                zoom = mapParams.length > 2 ? mapParams[3] : 14;
                            }

                            return {
                                latitude: latitude,
                                longitude: longitude,
                                zoom: zoom,
                                query: query,
                            };
                        };

                        $scope.removeMap = function () {
                            if (map) {
                                map = undefined;
                            }
                        };

                        $scope.createMap = function (canvas, latLng, zoom) {
                            var enableControl = !$scope.disableControl;
                            if (latLng && zoom) {
                                var mapOptions = {
                                    center: latLng,
                                    zoom: zoom,
                                    disableDefaultUI: !enableControl,
                                    zoomControl: enableControl,
                                    scaleControl: enableControl,
                                    streetViewControl: enableControl,
                                    mapTypeControl: enableControl,
                                    scrollwheel: enableControl,
                                    draggable: enableControl,
                                    disableDoubleClickZoom: !enableControl,
                                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                                };
                                if (!map) {
                                    map = new google.maps.Map(canvas);
                                }
                                map.setOptions(mapOptions);
                                new google.maps.Marker({
                                    position: latLng,
                                    map: map,
                                    animation: google.maps.Animation.DROP,
                                });
                                $('.map-modal').on('shown.bs.modal', function () {
                                    google.maps.event.trigger(map, 'resize');
                                    map.setCenter(mapOptions.center);
                                });
                                /**
                         * Below is the fix for map not being display till we resize the page in tabbed view.
                         * Just add the 'refresh-map="true"' attribute in the tab element
                         * <a href="#design_invitation" aria-controls="design_invitation "
                         role="tab" data-toggle="tab" refresh-map="true">
                         */
                                $('a[refresh-map="true"]').on('shown.bs.tab', function () {
                                    google.maps.event.trigger(map, 'resize');
                                    map.setCenter(mapOptions.center);
                                });
                            }
                        };

                        self.parseGoogleMapUrl = function (mapUrl) {
                            return $scope.parseGoogleMapUrl(mapUrl);
                        };
                    },
                ],
                link: function (scope, element) {
                    var watch = function () {
                        if (scope.show) {
                            // to skip operation on original
                            var coordinates = scope.ogCoords;
                            if (scope.url || coordinates) {
                                setTimeout(function () {
                                    // to fix the issue of map only loading after window resize.
                                    window.googleMapsCallback(function () {
                                        var canvas = element[0];
                                        // handle case if coordinates is a plain string e.g. '27.6949964,85.31491419999998'
                                        // extract the long and lat from there
                                        if (
                                            coordinates &&
                                            (coordinates instanceof String || typeof coordinates === 'string')
                                        ) {
                                            var coordsValue = coordinates.split(',');
                                            coordinates = [coordsValue[0], coordsValue[1]];
                                        }
                                        var mapParams = scope.parseGoogleMapUrl(scope.url, coordinates);
                                        var latitude = mapParams.latitude,
                                            longitude = mapParams.longitude,
                                            zoom = mapParams.zoom,
                                            query = mapParams.query;
                                        if (
                                            latitude &&
                                            longitude &&
                                            scope.validateLatitudeLongitude(latitude, longitude)
                                        ) {
                                            scope.removeMap();
                                            scope.createMap(canvas, new google.maps.LatLng(latitude, longitude), zoom);
                                        } else {
                                            new google.maps.Geocoder().geocode(
                                                { address: query },
                                                function (results, status) {
                                                    if (status === google.maps.GeocoderStatus.OK) {
                                                        scope.removeMap();
                                                        scope.createMap(canvas, results[0].geometry.location, zoom);
                                                    }
                                                }
                                            );
                                        }
                                    });
                                }, 10);
                            } else {
                                element.empty();
                                element.removeAttr('style');
                                scope.removeMap();
                            }
                        }
                    };

                    scope.$watch('show', watch);
                    scope.$watch('url', watch);
                    scope.$watch('ogCoords', watch);
                },
            };
        },
    ]);
})();
