'use strict';

angular.module('core.existingServices').factory('ClientSelectedContent', [
    '$resource',
    function ($resource) {
        var partResourceRest = $resource(
            'api/v1/clientSelectedContent',
            {},
            {
                save: {method: 'POST', isArray: true},
            }
        );

        var getAllSelectedContentRest = $resource(
            'api/v1/clientSelectedContent/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: true},
            }
        );

        var getAllSelectedContentForEventRest = $resource(
            'api/v1/clientSelectedContent/getAllSelectedContentForEvent/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var checkEventHasSelectedContentRest = $resource(
            'api/v1/clientSelectedContent/checkEventHasSelectedContent/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var getDecisionMakerHistoryRest = $resource(
            'api/v1/clientSelectedContent/decisionMakerHistoryIndex/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: true},
            }
        );

        return {
            save: function (data, successCallback, errorCallback) {
                return partResourceRest.save(data, successCallback, errorCallback);
            },
            getAllSelectedContent: function (eventId, successCallback, errorCallback) {
                return getAllSelectedContentRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            getAllSelectedContentForEvent: function (eventId, successCallback, errorCallback) {
                return getAllSelectedContentForEventRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            checkEventHasSelectedContent: function (eventId, successCallback, errorCallback) {
                return checkEventHasSelectedContentRest.get({eventId: eventId}, successCallback, errorCallback);
            },
            getDecisionMakerHistory: function (eventId, successCallback, errorCallback) {
                return getDecisionMakerHistoryRest.get({eventId: eventId}, successCallback, errorCallback);
            },
        };
    },
]);

