import controller from './og_audit_log.controller';

(function () {
    'use strict';

    angular.module('audit.log').component('ogAuditLog', {
        bindings: {
            offerId: '=',
        },
        templateUrl: './app/components/directives/audit_log/og_audit_log.view.html',
        controller,
        controllerAs: 'auditLogCtrl',
    });
})();
