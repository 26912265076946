'use strict';

angular.module('core.existingServices').factory('ProcessLog', [
    '$resource',
    function ($resource) {
        var v1Rest = $resource(
            'api/v1/processLog/:referenceId/currentStatusLog',
            {referenceId: '@referenceId'},
            {
                get: {method: 'GET', isArray: false},
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var v1RestImportStatus = $resource(
            'api/v1/processLog/:referenceId/currentStatusLogForParticipantImport',
            {referenceId: '@referenceId'},
            {
                get: {method: 'GET', isArray: false},
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var v1DeleteImportProcessLogs = $resource(
            'api/v1/processLog/:processId/deleteAllParticipantImportProcess',
            {processId: '@processId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var v1Delete = $resource(
            'api/v1/processLog/:processId',
            {processId: '@processId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var v1markProcessAsFinishedRest = $resource(
            'api/v1/processLog/:referenceId/markProcessAsFinished',
            {referenceId: '@referenceId'},
            {
                put: {method: 'POST', isArray: false},
            }
        );
        return {
            get: function (jsonObject, successCallback, errorCallback) {
                return v1Rest.get(jsonObject, successCallback, errorCallback);
            },
            getImportStatus: function (jsonObject, successCallback, errorCallback) {
                return v1RestImportStatus.get(jsonObject, successCallback, errorCallback);
            },

            deleteImportLogs: function (processId, success, error) {
                console.log(processId, processId);
                return v1DeleteImportProcessLogs['delete']({processId: processId}, success, error);
            },

            delete: function (processId, success, error) {
                console.log(processId, processId);
                return v1Delete['delete']({processId: processId}, success, error);
            },
            markProcessAsFinished: function (jsonObject, successCallback, errorCallback) {
                return v1markProcessAsFinishedRest.put(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);

