import currencyUtil from '../../utils/CurrencyUtil';
import amountAndPriceService from '../../utils/price_calculate/PriceCalculationCreatorUtil';
import { ScopeFunctions } from '../../utils/global/scopeFunctions';
import CommonFunctions from "../../utils/CommonFunctionUtil";
import _ from 'underscore';

'use strict';
import { OgModal } from "../../utils/global/ogModal";
import {UtilFunctions} from "../../utils/global/utilFunctions";

angular.module('dialog').directive('ogParticipantModal', [
    '$rootScope',
    '$translate',
    'Participant',
    'ParticipantService',
    'TimezoneService',
    'ParticipantUtilService',
    function ($rootScope, $translate, Participant, ParticipantService, TimezoneService, ParticipantUtilService) {
        return new OgModal({
            name: 'participant',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);

                $scope.participantForm = {};
                $scope.initDialog = function (data) {
                    $scope.participantForm.participant.$setUntouched();
                    $scope.createParticipant = false;
                    $scope.fortnoxDetailsEditMode = false;
                    $scope.eventId = data.eventId;
                    $scope.useSMS = data.useSMS;
                    $scope.successCallback = data.successCallback;
                    if (data.participant) {
                        $scope.originalEmail = data.participant.email;
                    }
                    $scope.basicInfoForEvent = angular.copy(data.basicInfoForEvent);
                    markConditionalRequired();
                    $scope.additionalInfoOptionsForEvent = angular.copy(data.additionalInfoOptionsForEvent);
                    $scope.invalidEmailMessage = null;
                    $scope.sendMail = true;
                    $scope.showConfirmationBox = false;
                    var participant = angular.copy(data.participant);
                    if (!participant) {
                        $scope.title = 'dialog.participant.title';
                        $scope.buttonText = 'common.add';
                        $scope.createParticipant = true;
                        participant = { attending: 'NOT_INVITED' };
                        var participantAdditionalInfoList = [];
                        var participantBasicInfoList = [];

                        angular.forEach($scope.basicInfoForEvent, function (n) {
                            var map = {
                                detailNameEnglish: n.detailNameEnglish,
                                detailNameSwedish: n.detailNameSwedish,
                                detailNameNorwegian: n.detailNameNorwegian,
                                detailNameDanish: n.detailNameDanish,
                                detailNameFinnish: n.detailNameFinnish,
                                type: n.type,
                                optionValues: n.optionValues,
                                fieldValue: '',
                                eventParticipantBasicQuery: {
                                    id: n.eventParticipantBasicQuery.id,
                                    isMandatory: n.eventParticipantBasicQuery.isMandatory,
                                    shareWithSupplier: n.eventParticipantBasicQuery.shareWithSupplier,
                                },
                                isMandatory: n.eventParticipantBasicQuery.isMandatory,
                                showHideLogic: n.eventParticipantBasicQuery.showHideLogic,
                            };
                            participantBasicInfoList.push(map);
                        });
                        participant.participantBasicQueryInfoList = participantBasicInfoList;

                        angular.forEach($scope.additionalInfoOptionsForEvent, function (n) {
                            if (n.isChecked) {
                                var map = {
                                    detailNameEnglish: n.detailNameEnglish,
                                    detailNameSwedish: n.detailNameSwedish,
                                    detailNameNorwegian: n.detailNameNorwegian,
                                    detailNameDanish: n.detailNameDanish,
                                    detailNameFinnish: n.detailNameFinnish,
                                    type: n.type,
                                    optionValues: n.optionValues,
                                    fieldValue: '',
                                    eventParticipantCustomQuery: {
                                        id: n.id,
                                    },
                                    isMandatory: n.isMandatory,
                                    showHideLogic: n.showHideLogic,
                                };
                                participantAdditionalInfoList.push(map);
                            }
                        });
                        participant.participantAdditionalQueryInfoList = participantAdditionalInfoList;
                        participant.allQueryInfoList = participant.participantBasicQueryInfoList.concat(
                            participant.participantAdditionalQueryInfoList
                        );
                    } else {
                        $scope.createParticipant = false;
                        $scope.originalAttending = participant.attending;
                        $scope.title = 'dialog.participant.edit.title';
                        $scope.buttonText = 'common.save';
                        participant.allQueryInfoList = participant.participantBasicQueryInfoList.concat(
                            participant.participantAdditionalQueryInfoList
                        );

                        angular.forEach(participant.participantBasicQueryInfoList, (partBasicInfo) => {
                            let _optionValues = partBasicInfo.eventParticipantBasicQuery.optionValues;
                            if (['DROPDOWN'].indexOf(partBasicInfo.type.name) > -1) {
                                let _fieldValue = partBasicInfo.fieldValue;
                                partBasicInfo.translatedOptions = $scope.getOptionValuesFn(
                                    _optionValues,
                                    $rootScope.langSelected
                                );
                                partBasicInfo.fieldValue = ParticipantUtilService.getFieldValue(
                                    _optionValues,
                                    $rootScope.langSelected,
                                    _fieldValue
                                );
                            }
                            if (['MULTISELECT'].indexOf(partBasicInfo.type.name) > -1) {
                                let _fieldValue = partBasicInfo.fieldValue;
                                // partBasicInfo.translatedOptions = $scope.getOptionValuesFn(_optionValues, $rootScope.langSelected);
                                if (_fieldValue) {
                                    _fieldValue = _fieldValue.split(' | ');
                                }
                                partBasicInfo.fieldValue = _fieldValue;
                            }
                        });

                        angular.forEach(participant.participantAdditionalQueryInfoList, (partAddInfo) => {
                            let _optionValues = partAddInfo.optionValues;
                            if (['DROPDOWN'].indexOf(partAddInfo.type.name) > -1) {
                                let _fieldValue = partAddInfo.fieldValue;
                                partAddInfo.translatedOptions = $scope.getOptionValuesFn(
                                    _optionValues,
                                    $rootScope.langSelected
                                );
                                partAddInfo.fieldValue = ParticipantUtilService.getFieldValue(
                                    _optionValues,
                                    $rootScope.langSelected,
                                    _fieldValue
                                );
                            }
                            if (['MULTISELECT'].indexOf(partAddInfo.type.name) > -1) {
                                let _fieldValue = partAddInfo.fieldValue;
                                // partAddInfo.translatedOptions = $scope.getOptionValuesFn(_optionValues, $rootScope.langSelected);
                                if (_fieldValue) {
                                    partAddInfo.fieldValue = _fieldValue.split(' | ');
                                }
                            }
                        });
                    }
                    var validAttendingEnumList = ['NOT_INVITED', 'NOT_REPLIED', 'ATTENDING', 'NOT_ATTENDING'];
                    let _isNotAttending = validAttendingEnumList.indexOf(participant.attending) < 0;
                    participant.tempAttending = _isNotAttending ? 'NOT_INVITED' : participant.attending;
                    $scope.dataLoaded(participant);
                    $scope.setRequiredFieldForQuery($scope.data.attending);
                };

                $scope.clearFeeDialog = function () {
                    $scope.eventId = null;
                    $scope.successCallback = null;
                };

                $scope.getQuestionLabel = function (question) {
                    return ParticipantService.getQuestionLabel(question, $scope.languageSelected);
                };

                $scope.setSendMail = function (send) {
                    $scope.sendMail = send;
                };

                $scope.showHideConfirmationBox = function (show) {
                    $scope.showConfirmationBox = show;
                    if ($scope.showConfirmationBox === false) {
                        $scope.processParticipant();
                    }
                };

                $scope.setRequiredFieldForQuery = function (status) {
                    $scope.setSendMail(true); // reset mail setting on status changed.
                    angular.forEach($scope.data.participantBasicQueryInfoList, function (basicQuery) {
                        if (
                            basicQuery.isMandatory &&
                            (basicQuery.detailNameEnglish === 'Email' || basicQuery.detailNameEnglish === 'Phone')
                        ) {
                            basicQuery.required = true;
                        } else {
                            basicQuery.required = false;
                        }
                    });
                };

                function markConditionalRequired() {
                    let phoneIndex = _.findIndex($scope.basicInfoForEvent, { detailNameEnglish: 'Phone' });
                    let phone = $scope.basicInfoForEvent[phoneIndex];
                    let emailIndex = _.findIndex($scope.basicInfoForEvent, { detailNameEnglish: 'Email' });
                    let email = $scope.basicInfoForEvent[emailIndex];
                    if (email && phone) {
                        let _isBothNotMandatory =
                            !phone.eventParticipantBasicQuery.isMandatory &&
                            !email.eventParticipantBasicQuery.isMandatory;
                        if (_isBothNotMandatory) {
                            $scope.basicInfoForEvent[emailIndex].eventParticipantBasicQuery.isMandatory = true;
                        }
                    }
                }

                $scope.resetRequiredField = function (value, fieldName) {
                    if ((fieldName === 'Email' || fieldName === 'Phone') && value) {
                        let nonRequiredFieldName = 'Email';
                        if (fieldName === 'Email') {
                            nonRequiredFieldName = 'Phone';
                        }
                        let nonRequiredField = _.findWhere($scope.data.participantBasicQueryInfoList, {
                            detailNameEnglish: nonRequiredFieldName,
                        });
                        if (nonRequiredField) {
                            nonRequiredField.isMandatory = !value;
                            nonRequiredField.required = false;
                        }
                        let requiredField = _.findWhere($scope.data.participantBasicQueryInfoList, {
                            detailNameEnglish: fieldName,
                        });
                        if (requiredField) {
                            requiredField.isMandatory = true;
                            requiredField.required = true;
                        }
                    }
                };

                $scope.getInformation = function (fieldName) {
                    if (fieldName === 'Email' || fieldName === 'Phone') {
                        let subFieldName = 'Email';
                        if (fieldName === 'Email') {
                            subFieldName = 'Phone';
                        }
                        let subFieldInfo = _.findWhere($scope.data.participantBasicQueryInfoList, {
                            detailNameEnglish: subFieldName,
                        });
                        if (!subFieldInfo) {
                            return '';
                        }
                        return $translate.instant('default.email.phone.info.text');
                    }
                    return '';
                };

                //TODO(BT) to replace with ParticipantUtilService.getOptionValues
                $scope.getOptionValuesFn = function (options, lang) {
                    if (options && options.indexOf('~') >= 0) {
                        let _options = options.split('~');
                        let _englishOptions = _options ? _options[0] : null;
                        let _swedishOptions = _options.length > 1 ? _options[1] : null;
                        let _norwegianOptions = _options.length > 2 ? _options[2] : null;
                        let _danishOptions = _options.length > 3 ? _options[3] : null;
                        let _fiOptions = _options.length > 4 ? _options[4] : null;
                        if (lang === 'en_US') {
                            return _englishOptions
                                ? _englishOptions
                                : _swedishOptions
                                    ? _swedishOptions
                                    : _norwegianOptions
                                        ? _norwegianOptions
                                        : _danishOptions;
                        }
                        if (lang === 'sv_SV') {
                            return _swedishOptions
                                ? _swedishOptions
                                : _englishOptions
                                    ? _englishOptions
                                    : _norwegianOptions
                                        ? _norwegianOptions
                                        : _danishOptions;
                        }
                        if (lang === 'nb_NB') {
                            return _norwegianOptions
                                ? _norwegianOptions
                                : _englishOptions
                                    ? _englishOptions
                                    : _swedishOptions
                                        ? _swedishOptions
                                        : _danishOptions;
                        }
                        if (lang === 'da_DK') {
                            return _danishOptions
                                ? _danishOptions
                                : _swedishOptions
                                    ? _swedishOptions
                                    : _englishOptions
                                        ? _englishOptions
                                        : _norwegianOptions;
                        }
                        if (lang === 'fi_FI') {
                            return _fiOptions
                                ? _fiOptions
                                : _danishOptions
                                    ? _danishOptions
                                    : _swedishOptions
                                        ? _swedishOptions
                                        : _englishOptions
                                            ? _englishOptions
                                            : _norwegianOptions;
                        }
                    } else {
                        return options;
                    }
                };

                $scope.getLabel = function (fieldName) {
                    if (fieldName === 'Phone' || fieldName === 'Mobilnummer') {
                        let customLabel = $translate.instant('participant.phone.custom.label.text');
                        return fieldName + ' ' + customLabel;
                    }
                    return fieldName;
                };

                $scope.validatePhoneNumber = function (field, val) {
                    if (field === 'Phone') {
                        return val && val.indexOf('+') === 0;
                    }
                    return true;
                };

                $scope.checkIfFieldMandatory = function (fieldMandatory, type, participantQueryType) {
                    if (fieldMandatory && type == 'EMAIL' && participantQueryType == 'isBasic') {
                        return true;
                    } else {
                        return (
                            fieldMandatory &&
                            ('ATTENDING' == $scope.data.attending || 'NOT_REPLIED' == $scope.data.attending)
                        );
                    }
                };

                $scope.processParticipant = function () {
                    $scope.invalidEmailMessage = null;
                    if (!$scope.participantForm.participant.$valid) {
                        CommonFunctions.touchFormFields($scope.participantForm.participant);
                        return;
                    }
                    $scope.startProgress();
                    $scope.data.fromCreator = true;
                    $scope.data.sendMail = $scope.sendMail;
                    if ($scope.createParticipant) {
                        $scope.data.attending = angular.copy($scope.data.tempAttending);
                        Participant.addParticipant(
                            $scope.eventId,
                            $scope.data,
                            function () {
                                $scope.close();
                                $scope.successCallback();
                                $scope.setSendMail(false);
                                $scope.endProgress();
                            },
                            $scope.endWithErrorOverlay
                        );
                    } else {
                        if ($scope.data.attending != 'BOUNCED_EMAIL') {
                            $scope.data.attending = angular.copy($scope.data.tempAttending);
                            saveParticipant();
                            return;
                        }
                        $scope.data.attending = angular.copy($scope.data.tempAttending);
                        let updatedEmail = _.findWhere($scope.data.participantBasicQueryInfoList, {
                            detailNameEnglish: 'Email',
                        }).fieldValue;
                        if ($scope.originalEmail != updatedEmail) {
                            saveParticipant();
                        } else {
                            $scope.endProgress();
                            $scope.invalidEmailMessage = 'participant.incorrect.email.alert';
                            $scope.data.attending = 'BOUNCED_EMAIL';
                        }
                    }

                    $rootScope.$emit('event:user:action', {
                        action: 'add.participant',
                        data: {
                            'Event id': $scope.eventId,
                        },
                    });
                };

                function saveParticipant() {
                    Participant.save(
                        $scope.eventId,
                        $scope.data,
                        function () {
                            $scope.close();
                            $scope.successCallback();
                            $scope.setSendMail(false);
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                $scope.showQuestion = function (question, listOfQuestions) {
                    return ParticipantService.showQuestion(question, listOfQuestions, $rootScope.langSelected);
                };

                function getVatAmount(ticket) {
                    let vatAmount =
                        UtilFunctions.isGreaterOrEqualToZero(ticket.price) &&
                            UtilFunctions.isGreaterOrEqualToZero(ticket.vat)
                            ? (ticket.price * ticket.vat) / 100
                            : 0;
                    return amountAndPriceService.round(parseFloat(vatAmount));
                }

                $scope.getPriceWithVatLocalisedWithCurrency = function (ticket) {
                    let priceWithoutVat = UtilFunctions.isGreaterOrEqualToZero(ticket.price)
                        ? parseFloat(ticket.price)
                        : 0;
                    let priceWithVat = priceWithoutVat + getVatAmount(ticket);
                    let currency = ticket.currency || currencyUtil.getDefaultCurrency();
                    return amountAndPriceService.getAmountLocalisedWithCurrency(priceWithVat, currency, 2);
                };

                $scope.getPaymentType = function (ticket) {
                    if (ticket.paymentMethod.name === 'CREDIT_CARD') {
                        return $translate.instant('ticket.card');
                    } else {
                        return $translate.instant('ticket.invoice');
                    }
                };

                $scope.editFortnoxDetails = function () {
                    $scope.originalFortnoxDetails = angular.copy($scope.data.ticket.fortNoxDetails);
                    $scope.fortnoxDetailsEditMode = true;
                };

                $scope.updateFortnoxDetails = function () {
                    $scope.startProgress();
                    Participant.updateFortNoxDetails(
                        $scope.data.ticket.id,
                        $scope.data.ticket.fortNoxDetails,
                        function () {
                            $scope.fortnoxDetailsEditMode = false;
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                };

                $scope.cancelFortnoxDetailsEditMode = function () {
                    $scope.startProgress();
                    $scope.fortnoxDetailsEditMode = false;
                    $scope.endProgress();
                };

                $scope.getDateFormatForTicket = function () {
                    return $rootScope.langSelected === 'sv_SV' ? 'yyyy-MM-dd' : 'dd/MM/yyyy';
                };

                function getDateTimeDisplayFormat() {
                    return $rootScope.langSelected === 'sv_SV' ? 'YYYY-MM-DD HH:mm' : 'DD/MM/YYYY hh:mm a';
                }

                $scope.formatDateTime = function (value) {
                    return TimezoneService.formatDate(new Date(parseInt(value)), getDateTimeDisplayFormat());
                };

                $scope.languageSelected = $rootScope.langSelected;

                $scope.$on('lang:change', function (event, data) {
                    $scope.languageSelected = data.lang;
                });
            },
        });
    },
]);

