export default function (appModule) {
    appModule.config([
        '$stateProvider',
        '$urlRouterProvider',
        '$injector',
        function ($stateProvider, $urlRouterProvider, $injector) {
            $urlRouterProvider.when('/linkExpired', '/link/expired');

            $stateProvider
                .state('link', {
                    url: '/link',
                    abstract: true, // has children view
                    template: '<og-header-directive></og-header-directive><ui-view>',
                })
                .state('gw', {
                    url: '/gw/:hash?showPage',
                    template: '<og-progress-overlay show-content="false"></og-progress-overlay>',
                    controller: [
                        'HashLinkHandler',
                        '$rootScope',
                        '$stateParams',
                        function (HashLinkHandler, $rootScope, $stateParams) {
                            let params = {
                                hash: $stateParams.hash,
                                showPage: $stateParams.showPage
                            };
                            $rootScope.stateLoading = true;
                            HashLinkHandler.getRedirectLink(
                                params,
                                (resp) => {
                                    window.location.href = resp.redirecUrl;
                                    $rootScope.stateLoading = false;
                                },
                                function (e) {
                                    $rootScope.stateLoading = false;
                                    console.log('error ', e);
                                }
                            );
                        },
                    ],
                })
                .state('link.expired', {
                    url: '/expired',
                    template: '<link-expired></link-expired>'
                });
            // show a not found error if route is unkown, but keep the original url
             $urlRouterProvider.otherwise(function ($injector, $location) {
                 var state = $injector.get('$state');
                 state.go('link.expired');
             });
        },
    ]);
}