import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import { OgModal } from "../../../utils/global/ogModal";
import _ from 'underscore';

(function () {
    /* global angular */
    'use strict';

    angular.module('ogPresentation').directive('ogPresentation', [
        function () {
            return {
                templateUrl: './app/components/directives/presentation/og_presentation.directive.view.html',
                scope: {
                    supplierId: '=supplierId',
                    jsondata: '=jsondata',
                    isthumbnail: '=isthumbnail',
                    defaultEditable: '=editable',
                    parent: '=parent',
                },
                controller: [
                    '$scope',
                    '$rootScope',
                    '$location',
                    '$log',
                    'Presentation',
                    'Supplier',
                    '$timeout',
                    'Translation',
                    'CommonFile',
                    'CommonFunctions',
                    function (
                        $scope,
                        $rootScope,
                        $location,
                        $log,
                        Presentation,
                        Supplier,
                        $timeout,
                        Translation,
                        CommonFile,
                        CommonFunctions
                    ) {
                        ScopeFunctions.addToScope($scope);

                        $scope.removePicture = function (imageId, imageType) {
                            $scope.showRemovePictureConfirmationMessage = true;
                            $scope.removeImageId = imageId;
                            $scope.removeImageType = imageType;
                        };

                        $scope.resetRemovePicture = function () {
                            $scope.showRemovePictureConfirmationMessage = false;
                            $scope.removeImageId = null;
                            $scope.removeImageType = null;
                        };

                        $scope.doRemovePicture = function () {
                            $scope.startProgress();
                            Presentation.deleteImage(
                                { currentImg: $scope.removeImageId },
                                function (response) {
                                    $scope.endProgress();
                                    if ($scope.removeImageId === $scope.currentImg) {
                                        $scope.currentImg = null;
                                    }
                                    $scope.presentation = response;
                                    $scope.presentationImages = response.presentationImages;
                                    $scope.currentImage();
                                    $scope.resetRemovePicture();
                                    $rootScope.$emit('presentation:image:updated', response);
                                },
                                $scope.endWithErrorOverlay
                            );
                        };

                        $scope.$watch('jsondata', function (data) {
                            if (data) {
                                $scope.presentation = data;
                                $scope.presentationImages = data.presentationImages;
                                if ($scope.defaultEditable) {
                                    $scope.isEditable = $scope.defaultEditable && data.editable;
                                } else {
                                    $scope.isEditable = data.editable;
                                }
                                $scope.globalEditMode = $scope.categoryId;
                                $scope.resetEditModes($scope.presentationImages);
                                $scope.currentImg = null;
                                $scope.currentImage();
                                getSupplierData();
                                setSelectedLang($rootScope.langSelected);
                                setDescription();
                                /*getSupplierDetailInfo();*/
                            }
                        });

                        //TODO to deprecate this function
                        function getPresentationDescription(presentation) {
                            const localisedDescriptions = presentation.localisedDescriptions;
                            const lang = $scope.descriptionLanguage;
                            var keys = Object.keys(localisedDescriptions);

                            keys.forEach(function (key) {
                                if (key === lang) {
                                    presentation.description = localisedDescriptions[key];
                                }
                            });
                            $scope.descriptionLanguage = lang;
                            return presentation.description;
                        }

                        function setSelectedLang(lang) {
                            switch (lang) {
                                case 'sv_SV':
                                    $scope.descriptionLanguage = 'SWEDISH';
                                    break;
                                case 'nb_NB':
                                    $scope.descriptionLanguage = 'NORWEGIAN';
                                    break;
                                default:
                                    $scope.descriptionLanguage = 'ENGLISH';
                            }
                        }

                        function getSupplierData() {
                            $scope.startProgress();
                            if ($scope.supplierId !== null) {
                                Supplier.get(
                                    { supplierId: $scope.supplierId },
                                    function (result) {
                                        $scope.supplier = result;
                                        CommonFunctions.setSameHeightWithoutTimeOut('presentation-flexbox-height');
                                    },
                                    $scope.endWithErrorOverlay
                                );
                            }
                            $scope.endProgress();
                        }

                        $scope.resetEditModes = function (images) {
                            $scope.edit = {
                                name: false,
                                logoUrl: false,
                                videoUrl: false,
                                description: false,
                                map: false,
                            };
                            if (images) {
                                angular.forEach(images, function (image, key) {
                                    $scope.edit[key] = false;
                                });
                            }
                        };

                        $scope.toggleGlobalEditMode = function () {
                            if ($scope.isEditable) {
                                if ($scope.globalEditMode) {
                                    $scope.resetEditModes($scope.presentationImages);
                                    $scope.showImageCropSection = false;
                                } else {
                                    $scope.presentation.description = getPresentationDescription($scope.presentation);
                                }
                                $scope.globalEditMode = !$scope.globalEditMode;
                                $scope.currentImage();
                            }
                            CommonFunctions.setSameHeight('presentation-flexbox-height');
                        };

                        $scope.selectLanguage = function (lang) {
                            $scope.descriptionLanguage = lang;
                            setDescription();
                        };

                        function setDescription() {
                            let params = {
                                text: $scope.presentation.description,
                                target: $scope.descriptionLanguage,
                                source: $scope.presentation.description.descriptionLanguage,
                            };

                            Translation.getTranslatedText(
                                params,
                                (response) => {
                                    $scope.presentation.description = response.text;
                                },
                                $scope.endWithErrorOverlay()
                            );
                        }

                        $scope.setItemType = function (itemType) {
                            $scope.itemType = itemType;
                        };

                        $scope.toggleEditMode = function (item) {
                            if ($scope.globalEditMode) {
                                $scope.edit[item] = !$scope.edit[item];
                            }
                        };

                        $scope.errorCallback = function (error) {
                            $scope.error = error;
                            $scope.itemType = undefined;
                        };

                        $scope.parseUploadResponse = function (response) {
                            $scope.edit[$scope.itemType] = false;
                            $scope.presentation = response;
                            $scope.presentationImages = response.presentationImages;
                            $rootScope.$emit('update:supplier:presentation', { presentation: $scope.presentation });
                            if ($scope.itemType == 'mapURL') {
                                $scope.$broadcast('map/init', response.mapUrl);
                            }
                            $scope.itemType = undefined;
                        };

                        $scope.upload = function (itemType, successfn) {
                            $scope.itemType = itemType;
                            let _params = {
                                itemType: itemType,
                                value: $scope.presentation[itemType],
                                language: $scope.descriptionLanguage,
                            };
                            if ($scope.presentation[itemType] != null && $scope.presentation[itemType] != undefined) {
                                Presentation.updateItem(
                                    $scope.presentation.id,
                                    _params,
                                    (resp) => {
                                        $scope.parseUploadResponse(resp);
                                        if (successfn) {
                                            successfn();
                                        }
                                    },
                                    $scope.errorCallback
                                );
                            } else {
                                if (successfn) {
                                    successfn();
                                }
                            }
                        };

                        $scope.reset = function () {
                            $scope.presentation.useParent = true;
                            $scope.upload('useParent');
                        };

                        $scope.uploadDescription = function () {
                            $scope.startProgress();
                            $scope.upload('description', function () {
                                // getPresentationDescription($scope.presentation);
                                $scope.endProgress();
                            });
                        };

                        $scope.uploadImage = function (content, completed) {
                            if (completed && content) {
                                var fileStore = angular.fromJson(content);
                                $scope.presentation[$scope.itemType] = fileStore.id;
                                $scope.upload($scope.itemType);
                            }
                        };

                        $scope.displayHeaderType = 'IMAGE';

                        $scope.currentImage = function (val) {
                            if (val == 'map') {
                                $scope.displayHeaderType = 'MAP';
                            } else if (val == 'video') {
                                $scope.displayHeaderType = 'VIDEO';
                            } else {
                                $scope.displayHeaderType = 'IMAGE';
                                $scope.currentImg = val
                                    ? val
                                    : $scope.currentImg
                                        ? $scope.currentImg
                                        : $scope.presentationImages
                                            ? _.values($scope.presentationImages)[0]
                                            : '0';
                            }

                            if ($scope.currentImg === '0') {
                                if ($scope.presentation.mapUrl) {
                                    $scope.displayHeaderType = 'MAP';
                                } else if ($scope.presentation.videoUrl) {
                                    $scope.displayHeaderType = 'VIDEO';
                                }
                            }

                            return $scope.currentImg;
                        };

                        angular.forEach($scope.presentationImages, function (img, key) {
                            $scope.$watch('edit.' + key, function (a) {
                                if (!a) {
                                    return;
                                }
                                trigger(key);
                            });
                        });

                        function trigger(img) {
                            var repeat = 0;

                            function triggerClick(i) {
                                if ($('input[type="file"].' + i).is(':visible')) {
                                    $('input[type="file"].' + i).trigger('click');
                                } else if (repeat < 5) {
                                    repeat++;
                                    $timeout(triggerClick, 100);
                                }
                            }

                            triggerClick(img);
                        }

                        $scope.$watch('edit.logoUrl', function (a) {
                            if (!a) {
                                return;
                            }
                            trigger('logoUrl');
                        });

                        $scope.setCropImageSection = function (image) {
                            if ($scope.globalEditMode) {
                                $scope.targetImage = image
                                    ? image
                                    : $scope.presentationImages
                                        ? _.keys($scope.presentationImages)[0]
                                        : null;
                                $scope.targetImageUrl = image ? image : 'other';
                                $scope.showImageCropSection = true;
                            }
                        };

                        $scope.resetCropImageSection = function () {
                            $scope.targetImage = null;
                            $scope.targetImageUrl = null;
                            $scope.showImageCropSection = false;
                        };

                        $scope.updatePresentationImage = function (formData) {
                            Presentation.updatePresentationLogo(
                                formData,
                                function (data) {
                                    $scope.showImageCropSection = false;
                                    $scope.presentationImages = data.presentationImages;
                                    refreshImage();
                                    $rootScope.$emit('presentation:image:updated', data);
                                },
                                $scope.endWithErrorOverlay
                            );
                        };

                        function refreshImage() {
                            let oldImage = $scope.presentationImages[$scope.targetImage];
                            let newImage = $scope.presentationImages ? _.values($scope.presentationImages)[0] : null;
                            $scope.updateCurrentImg(oldImage, newImage);
                        }

                        $scope.updateCurrentImg = function (oldImageId, newImageId) {
                            if ($scope.currentImg === oldImageId || oldImageId === undefined) {
                                $scope.currentImg = newImageId;
                            }
                        };

                        $scope.openCropMeDialog = function (itemType) {
                            $scope.setItemType(!!itemType ? itemType : 'other');
                            var initParams = { presentationId: $scope.presentation.id, itemType: $scope.itemType };
                            if (itemType === 'logoUrl') {
                                OgModal.open('crop:logo:url', initParams);
                            } else {
                                OgModal.open('crop:presentation:image', initParams);
                            }
                        };

                        var logoUrlChangeEvent = $rootScope.$on('presentation:logoUrl:changed', function (event, data) {
                            if ($scope.presentation) {
                                $scope.presentation.logoUrl = data.logoUrl;
                            }
                        });

                        var presentationImageChangedEvent = $rootScope.$on(
                            'presentation:presentationImage:changed',
                            function (event, data) {
                                if ($scope.presentationImages) {
                                    var oldBigImageUrl = angular.copy($scope.presentationImages[$scope.itemType]);
                                    var newBigImageUrl = data.presentationImages
                                        ? _.values(data.presentationImages)[0]
                                        : null;
                                    $scope.updateCurrentImg(oldBigImageUrl, newBigImageUrl);
                                    $scope.presentationImages = data.presentationImages;
                                }
                            }
                        );

                        $scope.updateCurrentImg = function (oldImageId, newImageId) {
                            if ($scope.currentImg === oldImageId) {
                                $scope.currentImg = newImageId;
                            }
                        };

                        $scope.$on('$destroy', function () {
                            logoUrlChangeEvent();
                            presentationImageChangedEvent();
                        });

                        $scope.getImageUrl = function (fileId) {
                            return CommonFile.getCommonFile(fileId);
                        };
                    },
                ],
                link: function (scope, element, attrs) {
                    scope.tabIndex = (Math.random() * 6).toString().replace(/\./g, '_');
                    if (attrs.isthumbnail) {
                        element.bind('click', function (e) {
                            e.stopPropagation();
                        });
                    }

                    $(element).on('change', 'input[type="file"]', function () {
                        $(this).closest('form').find('input[upload-submit]').click();
                    });
                },
            };
        },
    ]);
})();
