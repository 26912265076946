'use strict';

import './creator/creator.module';
import './creator/creator.component';

import './participant/participant.module';
import './participant/participant.component';

angular.module('ratings', [
    'creatorRatingDetail',
    'participantsRatingDetail'
]);