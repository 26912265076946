import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

class OfferAdditionalInfo {
    constructor(
        $scope,
        $rootScope,
        $translate,
        FieldChangedSvc,
        FilledRequestOfferAdditionalInfo,
        DynamicFormUtilService
    ) {
        this._injected = {
            $scope,
            $rootScope,
            $translate,
            FieldChangedSvc,
            FilledRequestOfferAdditionalInfo,
            DynamicFormUtilService,
        };
        this.list = [];
        //this.getList();
        ScopeFunctions.addToScope($scope);

        $scope.$watch('offerAdditionalInfoCtrl.category', (newValue, oldValue) => {
            if (newValue) {
                this.getList(newValue, this.offer);
            }
        });
    }

    checkHasChanged(question, fieldValue) {
        let orig = _.find(this.originalList, (orgObj) => {
            return orgObj.id === question.id;
        });
        let _changed = orig.fieldValue !== fieldValue;
        this.isChanged({ changed: _changed });
    }

    getList(category, offer) {
        const { FilledRequestOfferAdditionalInfo, $translate, $rootScope, DynamicFormUtilService } = this._injected;
        if (category) {
            if (this.isThereConference) {
                category.nameCode = 'common.translate.category.conference.room';
            }
            FilledRequestOfferAdditionalInfo.list(
                this.module,
                category.nameCode,
                offer.id,
                (resp) => {
                    this.list = resp;
                    this.originalList = angular.copy(resp);
                    angular.forEach(this.list, (list) => {
                        if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX'].indexOf(list.type.name) > -1) {
                            let _listValuesCode = list.listValueCodes;
                            let _listValues = $translate.instant(_listValuesCode);
                            let _fieldValue;
                            if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                                _fieldValue = false;
                            } else {
                                _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                            }
                            list.fieldValue = DynamicFormUtilService.getFieldValue(
                                _listValues,
                                $rootScope.langSelected,
                                _fieldValue
                            );
                        }
                    });
                },
                function (e) {
                    console.log('error ', e);
                }
            );
        }
    }
}
OfferAdditionalInfo.$inject = [
    '$scope',
    '$rootScope',
    '$translate',
    'FieldChangedSvc',
    'FilledRequestOfferAdditionalInfo',
    'DynamicFormUtilService',
];
export default OfferAdditionalInfo;
