(function () {
    'use strict';

    angular.module('ogForm').directive('ogForm', [
        function () {
            function parseErrorMap(errors) {
                var errorMap = {};

                angular.forEach(errors, function (error) {
                    var fieldErrors = errorMap[error.fieldName];
                    if (fieldErrors === null) {
                        fieldErrors = [];
                        errorMap[error.fieldName] = fieldErrors;
                    }

                    fieldErrors.push(error.message);
                });

                return errorMap;
            }

            return {
                restrict: 'EA',
                replace: false,
                link: function ($scope, element, attrs) {
                    var formName = attrs.ogForm;

                    $scope.$on('validation:form:error:' + formName, function (event, statusMessage) {
                        if (statusMessage) {
                            var data = statusMessage.data;
                            if (data === null) {
                                data = statusMessage;
                            }
                            if (data.fieldErrors) {
                                var errorMap = parseErrorMap(data.fieldErrors);
                                angular.forEach(errorMap, function (errors, fieldName) {
                                    $scope.$broadcast(
                                        'validation:form:error:' + formName + ':field:' + fieldName,
                                        errors
                                    );
                                });
                            }
                        }
                    });
                },
            };
        },
    ]);
})();
