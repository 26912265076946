/**
 * Created by sampurnachhantyal on 9/17/18.
 */
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class EventSitePreviewModalController {
    constructor($element, close, $scope, EventPresentation, data) {
        this._injected = {$element, close, $scope, EventPresentation, data};
        ScopeFunctions.addToScope($scope);
        this.data = data.info;
        this.data.presentation = this.data.eventPresentationDetails.slide;
        this.data.presentationImages = this.data.eventPresentationDetails.slide.presentationImages;
    }

    close(startGuide = false) {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(startGuide, 500);
    }
}
EventSitePreviewModalController.$inject = ['$element', 'close', '$scope', 'EventPresentation', 'data'];
export default EventSitePreviewModalController;
