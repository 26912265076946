/**
 * Created by kshitij on 12/1/17.
 */

import controller from './og_add_edit_ticket.controller';

(function () {
    'use strict';
    angular.module('add.edit.ticket').component('ogAddEditTicket', {
        bindings: {
            eventId: '=',
            ticket: '=',
            updateCallback: '&',
            cancelCallback: '&',
        },
        templateUrl: './app/components/directives/add_edit_ticket/og_add_edit_ticket.view.html',
        controller,
        controllerAs: 'AddEditTicketCtrl',
    });
})();
