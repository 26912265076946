import { ScopeFunctions } from "../../../../../utils/global/scopeFunctions";
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('message').component('message', {
        templateUrl: './app/components/modules/event/event_detail/message/message.view.html',
        controllerAs: 'messageCtrl',
        controller: [
            '$scope',
            '$rootScope',
            '$timeout',
            '$stateParams',
            'Activity',
            'AuthenticationService',
            'Request',
            'EventEnum',
            'Event',
            'RequestOffer',
            'ChatMessage',
            'ChatMessageCountStore',
            'ogScrollService',
            'ClientService',
            'CommonFunctions',
            function (
                $scope,
                $rootScope,
                $timeout,
                $stateParams,
                Activity,
                AuthenticationService,
                Request,
                EventEnum,
                Event,
                RequestOffer,
                ChatMessage,
                ChatMessageCountStore,
                ogScrollService,
                ClientService,
                CommonFunctions
            ) {
                var vm = this;
                vm.backToMessageList = backToMessageListFn;
                vm.createMessage = createMessageFn;
                vm.clearAttachedFiles = clearAttachedFilesFn;
                vm.errorCallback = errorCallbackFn;
                vm.filterChatMessages = filterChatMessagesFn;
                vm.getActivityStream = getActivityStreamFn;
                vm.getPaginatedActivities = getPaginatedActivitiesFn;
                vm.translateTextByCode = translateTextByCodeFn;
                vm.init = initFn;
                vm.loadNextPage = loadNextPageFn;
                vm.pageChanged = pageChangedFn;
                vm.paginatedActivityStreamCallback = paginatedActivityStreamCallbackFn;
                vm.resetActivitiesList = resetActivitiesListFn;
                vm.sendMessageCallback = sendMessageCallbackFn;
                vm.showMessageBySupplier = showMessageBySupplierFn;
                vm.showMessageByEventSummaryShared = showMessageByEventSummarySharedFn;
                vm.showMessageByUser = showMessageByUserFn;
                vm.showDefaultMessage = showDefaultMessageFn;
                vm.messageReceiverChanged = messageReceiverChangedFn;
                vm.hideDefaultMessage = hideDefaultMessageFn;
                vm.resetDefaultMessage = resetDefaultMessageFn;
                vm.showMessageDetails = showMessageDetailsFn;
                vm.hideMessageDetails = hideMessageDetailsFn;
                vm.showComposeMessage = showComposeMessageFn;
                vm.hideComposeMessage = hideComposeMessageFn;
                vm.showMessage = showMessageFn;
                vm.hideFilter = hideFilterFn;
                vm.resetSuccessMessage = resetSuccessMessageFn;
                vm.resetReplySuccessMessage = resetReplySuccessMessageFn;
                vm.stripHtmlTags = stripHtmlTagsFn;
                vm.parseInt = parseInt;
                vm.hasDeclinedOffers = hasDeclinedOffersFn;
                vm.hasCanceledOffers = hasCanceledOffersFn;
                vm.isSupplierInCancelOrDeclineState = isSupplierInCancelOrDeclineStateFn;
                vm.cancelMessage = cancelMessageFn;
                vm.cancelReplyMessage = cancelReplyMessageFn;
                vm.showComposeMessageSection = false;
                vm.displayReplySection = false;
                vm.noFilter = true;
                vm.currentPage = 1;
                vm.eventId = $stateParams.eventId;
                vm.stateMessageId = $stateParams.msgId;
                vm.messageForm = {};
                vm.numPerPage = 6;
                vm.showMyTasks = false;
                vm.eventOwners = [];
                vm.defaultMessage = false;
                vm.isReplyMessage = false;
                vm.privateMessage = false;
                vm.replyToOfferId = null;
                vm._offerId = '';
                vm.maxMsgLength = 4000;
                vm.showMsgLimitError = false;
                vm.isSharedEventSummary = false;

                ScopeFunctions.addToScope($scope);

                vm.activityFilterArray = [
                    { id: 0, tag: 'all' },
                    { id: 1, tag: 'supplier' },
                    { id: 2, tag: 'task' },
                    { id: 3, tag: 'message' },
                    { id: 4, tag: 'messageWithFiles' },
                ];

                vm.showTaskFilterArray = [
                    { id: 0, tag: 'showMineTask' },
                    { id: 1, tag: 'showAllTask' },
                ];

                vm.activityFilter = vm.activityFilterArray[3].tag;
                vm.activeFilter = vm.activityFilterArray[3].tag;
                vm.showTaskFilter = vm.showTaskFilterArray[1].tag;

                vm.init();

                function showComposeMessageFn() {
                    vm.showComposeMessageSection = true;
                }

                function hideComposeMessageFn() {
                    vm.showComposeMessageSection = false;
                    resetSuccessMessageFn();
                    resetReplySuccessMessageFn();
                }

                function backToMessageListFn() {
                    hideMessageDetailsFn();
                    loadNextPageFn(true);
                }

                function hideFilterFn() {
                    vm.noFilter = true;
                    resetDefaultMessageFn();
                    vm.userId = null;
                    vm.eventOwnerId = null;
                }

                function createMessageFn(msg, messageId) {
                    if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                        return;
                    }
                    if (!msg && !vm.isReplyMessage && !vm.messageForm.newMessageForm.$valid) {
                        CommonFunctions.touchFormFields(vm.messageForm.newMessageForm);
                        return;
                    }
                    if (vm._offerId) {
                        if (!angular.isNumber(vm._offerId) && vm._offerId.indexOf('user_') === 0) {
                            vm.level = 'EVENT_USERS';
                            vm.eventOwnerId = vm._offerId.split('_')[1];
                            vm.privateMessage = true;
                            vm.offer = null;
                        } else {
                            vm.level = 'OFFER';
                            vm.privateMessage = false;
                            vm.eventOwnerId = null;
                        }
                    }
                    var messageBody = vm.messageBody;
                    if (msg && msg.isReplyEnabled) {
                        if (vm.isReplyMessage && !vm.messageForm.replyMessageForm.$valid) {
                            CommonFunctions.touchFormFields(vm.messageForm.replyMessageForm);
                            return;
                        }
                        messageBody = vm.messageReplyBody;
                        vm.parentMessageId = messageId;
                        vm.privateMessage = msg.privateMessage; //if parent is private child also should be private
                        vm.replyToUserId = msg.author.id;
                        vm.isReplyMessage = true;
                        if (msg.type.name === 'CHAT_MESSAGE_EVENT_SUMMARY') {
                            vm.isSharedEventSummary = true;
                        }
                    }
                    if (messageBody.length > vm.maxMsgLength) {
                        vm.showMsgLimitError = true;
                        return;
                    }
                    vm.showMsgLimitError = false;
                    if (tinyMCE && false) {
                        messageBody = tinyMCE.get('messageBody').getContent();
                    }
                    let plainMessage = htmlToPlaintext(messageBody);
                    if (plainMessage && plainMessage.length > 0 && plainMessage.charCodeAt(0) !== 8203) {
                        sendMessageFn(messageBody);
                    }
                    // vm.clearAttachedFiles();
                }

                function htmlToPlaintext(text) {
                    let plainText = text ? String(text).replace(/<[^>]+>/gm, '') : '';
                    return plainText.trim();
                }

                function errorCallbackFn(error) {
                    vm.error = error;
                    vm.streamSpinner = false;
                    vm.messageSpinner = false;
                }

                function filterChatMessagesFn(message) {
                    return vm.activityFilter == 'message'
                        ? vm.getMessageTypeFn(message) == 'CHATMESSAGE'
                            ? true
                            : false
                        : true;
                }

                function getActivityStreamFn() {
                    vm.streamSpinner = true;
                }

                function getPaginatedActivitiesFn() {
                    var params = {
                        eventId: vm.eventId,
                        showMyTasks: vm.showMyTasks,
                        page: vm.params.offset,
                        pageSize: vm.numPerPage,
                        privateMessage: vm.privateMessage,
                        type: vm.activityFilter,
                    };
                    if (vm.level === 'EVENT_USERS') {
                        params.eventOwnerId = vm.eventOwnerId;
                    } else {
                        params.requestId = vm.requestId;
                        params.offerId = vm.offerId;
                    }
                    $scope.startProgress();
                    vm.loading = true;
                    Activity.list(params, vm.paginatedActivityStreamCallback, vm.errorCallback);
                }

                function translateTextByCodeFn(text, code) {
                    return CommonFunctions.getTranslatedTextFromCode(text, code);
                }

                function initFn() {
                    $scope.startProgress('message-container');
                    vm.clearAttachedFiles();
                    vm.messageSpinner = false;
                    vm.messageBodyEmpty = false;
                    vm.resetActivitiesList();
                    vm.getPaginatedActivities();
                    vm.clearAll = false; //clear file upload model
                    vm.clientName = ClientService.getClientName();

                    RequestOffer.requestOffersForMessageByEventId(
                        vm.eventId,
                        function (response) {
                            $scope.endProgress('message-container');
                            vm.offers = response;
                        },
                        vm.errorCallback
                    );

                    Request.requestsForMessageByEventId(
                        vm.eventId,
                        function (response) {
                            vm.requests = response;
                        },
                        vm.errorCallback
                    );

                    Event.getUsers(
                        { eventId: vm.eventId, excludeSelf: true },
                        (response) => {
                            vm.eventOwners = response;
                        },
                        vm.errorCallback
                    );

                    Event.getUsers(
                        { eventId: vm.eventId, eventSummarySharedOnly: true },
                        (response) => {
                            vm.eventSummarySharedUsers = response;
                        },
                        vm.errorCallback
                    );

                    updateLoggedUserStatus();
                }

                function loadNextPageFn(refresh) {
                    if (vm.loading) {
                        return;
                    }
                    if (!refresh && vm.totalActivitiesCount !== -1) {
                        if (vm.activities.length >= vm.totalActivitiesCount) {
                            return;
                        }
                    }
                    vm.loading = true;
                    vm.getPaginatedActivities();
                }

                function paginatedActivityStreamCallbackFn(response) {
                    $scope.endProgress();
                    vm.activities = response.list;
                    vm.streamSpinner = false;
                    vm.messageSpinner = false;
                    vm.loading = false;
                    vm.totalActivitiesCount = response.totalCount;
                    if (vm.activities.length !== 0 && vm.activities.length % 20 === 0) {
                        vm.restrictAutoLoad = true;
                    }
                    vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                    if (vm.showActivityMessageId) {
                        setCreatorChatMessages(vm.showActivityMessageId);
                    } else {
                        if (vm.stateMessageId) {
                            var selectedMessage = _.find(vm.activities, (act) => {
                                let _find = act.id + '' === vm.stateMessageId + '';
                                if (_find) {
                                    return _find;
                                } else {
                                    var childMessage = _.find(act.childMessages, (ch) => {
                                        return ch.id + '' === vm.stateMessageId + '';
                                    });
                                    return !!childMessage;
                                }
                            });
                            if (selectedMessage) {
                                vm.showMessageDetails(selectedMessage);
                            }
                        }
                    }
                }

                function resetActivitiesListFn() {
                    vm.params = { offset: 0, max: vm.numPerPage };
                    vm.loading = false;
                    vm.totalActivitiesCount = -1; //default value
                    vm.activities = [];
                }

                function sendMessageFn(messageBody) {
                    if (messageBody && messageBody.length > 0) {
                        var compiledMessageBody = messageBody;
                        if (vm.attachedFiles.maxSizeExceeded === true) {
                            return;
                        }
                        if (vm.attachedFiles.links !== null && vm.attachedFiles.links.length > 0) {
                            compiledMessageBody += '\n\n';
                            angular.forEach(vm.attachedFiles.links, function (link) {
                                compiledMessageBody +=
                                    '<a href="' +
                                    link.fileUrl +
                                    '" target="_blank"><i class="glyphicon glyphicon-paperclip"></i> ' +
                                    link.name +
                                    '</a>' +
                                    '\n';
                            });
                        }
                        vm.messageSpinner = true;

                        Activity.save(
                            {
                                eventId: vm.eventId,
                                requestId: vm.requestId,
                                offerId: vm.replyToOfferId ? vm.replyToOfferId : vm._offerId,
                                messageBody: compiledMessageBody,
                                privateMessage: vm.privateMessage,
                                eventOwnerId: !!vm.replyToUserId ? vm.replyToUserId : vm.eventOwnerId,
                                isReplyMessage: vm.isReplyMessage,
                                parentMessageId: vm.parentMessageId,
                                isSharedEventSummary: vm.isSharedEventSummary,
                                attachedFiles: vm.attachedFiles.uploads,
                            },
                            vm.sendMessageCallback,
                            vm.errorCallback
                        );
                    }
                }

                function sendMessageCallbackFn() {
                    vm.messageSpinner = false;
                    if (vm.isReplyMessage) {
                        $scope.replySuccessMessage = true;
                        $scope.successMessage = false;
                        vm.displayReplySection = false;
                    } else {
                        $scope.replySuccessMessage = false;
                        $scope.successMessage = true;
                        vm.showComposeMessageSection = false;
                    }
                    vm.parentMessageId = null;
                    vm.privateMessage = false;
                    vm.offerId = undefined;
                    vm._offerId = '';
                    resetDefaultMessageFn();
                    vm.messageBody = '';
                    vm.messageReplyBody = '';
                    if (tinyMCE && false) {
                        var editor = tinyMCE.get('messageBody');
                        if (editor) {
                            editor.setContent('');
                        }
                    }
                    vm.clearAttachedFiles();
                    vm.resetActivitiesList();

                    vm.getPaginatedActivities();
                    vm.clearAll = true;
                    vm.messageForm.newMessageForm.$setPristine();
                    vm.messageForm.newMessageForm.$setUntouched();
                }

                function showDefaultMessageFn() {
                    Event.showDefaultMessage(
                        vm.eventId,
                        function (data) {
                            vm.defaultMessage = data.showDefaultMessage;
                        },
                        errorCallbackFn()
                    );
                }

                function messageReceiverChangedFn() {
                    resetSuccessMessageFn();
                    resetReplySuccessMessageFn();
                    if (vm._offerId === 'event_summary_shared') {
                        showMessageByEventSummarySharedFn();
                    } else if (vm._offerId && vm._offerId.indexOf('user_')) {
                        showMessageBySupplierFn(vm._offerId);
                    } else if (vm._offerId && !vm._offerId.indexOf('user_')) {
                        showMessageByUserFn(vm._offerId.split('_')[1]);
                    } else {
                        showMessageBySupplierFn();
                    }
                }

                function hideDefaultMessageFn() {
                    if (vm.defaultMessage) {
                        Event.hideDefaultMessage(
                            vm.eventId,
                            function (data) {
                                vm.defaultMessage = data.showDefaultMessage;
                            },
                            errorCallbackFn()
                        );
                    }
                }

                function fetchMessageDetails() {
                    let _desc = _.find(data.localisedDescriptions, (o) => {
                        return o.language.name === 'SWEDISH';
                    });
                }

                function showMessageDetailsFn(activityMessage) {
                    setCreatorChatMessages(activityMessage.id);
                    vm.showActivityMessageDetails = true;
                    vm.showActivityMessageId = activityMessage.id;
                    vm.isReplyMessage = true;
                    $scope.successMessage = false;
                    if (activityMessage.offer) {
                        vm.replyToOfferId = activityMessage.offer.id;
                    } else {
                        vm.replyToOfferId = null;
                    }
                    updateMessageSeenStatusFn(activityMessage);
                    if (vm.showActivityMessageDetails && vm.showActivityMessageId == activityMessage.id) {
                        if (vm.creatorChatMessageDetails.childMessages.length > 0) {
                            $timeout(function () {
                                ogScrollService.scrollToElement('scroll-here');
                            }, 500);
                        } else {
                            $timeout(function () {
                                ogScrollService.scrollToElement('reply-section-header');
                            }, 500);
                        }
                    }
                }

                function setCreatorChatMessages(messageId) {
                    vm.creatorChatMessageDetails = _.find(vm.activities, (o) => {
                        return o.id === messageId;
                    });
                    $rootScope.$broadcast(EventEnum.MESSAGE_THREAD_CHANGED, vm.creatorChatMessageDetails.author);
                }

                function hideMessageDetailsFn() {
                    vm.showActivityMessageDetails = false;
                    vm.showActivityMessageId = null;
                    vm.replyToUserId = null;
                    vm.isReplyMessage = false;
                    vm.replyToOfferId = null;
                    vm.stateMessageId = null;
                    vm.displayReplySection = false;
                }

                function updateMessageSeenStatusFn(activityMessage) {
                    if (activityMessage.isThreadHasNewMessage) {
                        $scope.startProgress();
                        Activity.markAsRead(
                            activityMessage,
                            (resp) => {
                                ChatMessage.unReadMessageCountForCreatorRest(
                                    vm.eventId,
                                    function (resp) {
                                        ChatMessageCountStore.setCreatorUnreadMessageCount(resp.count);
                                        ChatMessageCountStore.setCreatorHomePageUnreadMessageCount(resp.homePageCount);
                                    },
                                    $scope.endWithAlert()
                                );
                                $scope.endProgress();
                            },
                            $scope.endWithAlert()
                        );
                    }
                }

                function resetDefaultMessageFn() {
                    vm.defaultMessage = false;
                }

                function resetSuccessMessageFn() {
                    if ($scope.successMessage) $scope.successMessage = false;

                    vm.showMsgLimitError = false;
                }

                function resetReplySuccessMessageFn() {
                    if ($scope.replySuccessMessage) $scope.replySuccessMessage = false;

                    vm.showMsgLimitError = false;
                }

                function showMessageBySupplierFn(id) {
                    if (!id) {
                        vm.noFilter = true;
                        resetDefaultMessageFn();
                    } else {
                        vm.noFilter = false;
                        angular.forEach(vm.offers, function (offer) {
                            if (parseInt(offer.id) === parseInt(id)) {
                                vm.offer = offer;
                            }
                        });
                        if (vm.isSupplierInCancelOrDeclineState(vm.offer)) {
                            resetDefaultMessageFn();
                        } else {
                            showDefaultMessageFn();
                        }
                        resetSuccessMessageFn();
                        vm._offerId = id.toString();
                    }
                    vm.offerId = id;
                    vm.userId = null;
                    vm.hideMessageDetails();
                    vm.level = 'OFFER';
                    vm.privateMessage = false;
                    vm.eventOwnerId = null;
                    vm.isSharedEventSummary = false;
                    vm.resetActivitiesList();
                    vm.getPaginatedActivities();
                    vm.currentPage = 1;
                }

                function showMessageByEventSummarySharedFn() {
                    vm.isSharedEventSummary = true;
                    vm.userId = 'event_summary_shared';
                    vm._offerId = 'event_summary_shared';
                    var params = {
                        eventId: vm.eventId,
                        page: vm.params.offset,
                        pageSize: vm.numPerPage,
                        eventCreator: true,
                    };
                    $scope.startProgress();
                    vm.loading = true;
                    Activity.listEventSummaryMessage(params, vm.paginatedActivityStreamCallback, vm.errorCallback);
                }

                function showMessageByUserFn(userId) {
                    vm.userId = userId;
                    vm._offerId = 'user_' + userId;
                    vm.offerId = null;
                    //  vm.activityFilter = null;
                    vm.activeFilter = null;
                    vm.hideMessageDetails();
                    resetDefaultMessageFn();
                    vm.level = 'EVENT_USERS';
                    vm.eventOwnerId = userId;
                    vm.privateMessage = true;
                    vm.offer = null;
                    vm.noFilter = false;
                    vm.isSharedEventSummary = false;
                    resetSuccessMessageFn();
                    vm.resetActivitiesList();
                    vm.getPaginatedActivities();
                    vm.currentPage = 1;
                }

                function pageChangedFn() {
                    setPaginationParams();
                    vm.loadNextPage();
                }

                function setPaginationParams() {
                    vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                    vm.params.max = vm.numPerPage;
                }

                function updateLoggedUserStatus() {
                    vm.loggedUserStatus = AuthenticationService.getLoggedUserStatus();
                }

                function hasDeclinedOffersFn() {
                    if (vm.requests) {
                        var obj = vm.requests.filter(function (_request) {
                            return _request.declinedOffers.length > 0;
                        });
                        return obj && obj.length > 0;
                    }
                    return false;
                }

                function hasCanceledOffersFn() {
                    if (vm.requests) {
                        var obj = vm.requests.filter(function (_request) {
                            return _request.canceledOffers.length > 0;
                        });
                        return obj && obj.length > 0;
                    }
                    return false;
                }

                function isSupplierInCancelOrDeclineStateFn(selectedOffer) {
                    if (selectedOffer) {
                        return selectedOffer.status.name === 'DECLINED' || selectedOffer.status.name === 'CANCELED';
                    }
                    return false;
                }

                function cancelMessageFn() {
                    vm.showComposeMessageSection = false;
                    vm.messageBody = '';
                    clearAttachedFilesFn();
                }

                function cancelReplyMessageFn() {
                    vm.displayReplySection = false;
                    vm.messageReplyBody = '';
                    clearAttachedFilesFn();
                }

                function showMessageFn(type) {
                    vm.hideMessageDetails();
                    vm.resetActivitiesList();
                    vm.hideComposeMessage();
                    vm.eventOwnerId = null;
                    vm.offerId = null;
                    vm._offerId = null;
                    if (type) {
                        vm.activityFilter = type;
                        vm.activeFilter = type;
                        vm.loadNextPage();
                    }
                }

                function clearAttachedFilesFn() {
                    vm.attachedFiles = { uploads: null, links: null };
                }

                function stripHtmlTagsFn(str) {
                    if (str === null || str === '') {
                        return false;
                    } else {
                        str = str.toString();
                        var stripedhtml = str.replace(/(<([^>]+)>)/gi, '');
                        return htmlDecode(stripedhtml);
                    }
                }

                function htmlDecode(input) {
                    var e = document.createElement('div');
                    e.innerHTML = input;
                    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
                }

                $scope.$on(EventEnum.USER_DATA_CHANGED, updateLoggedUserStatus);
                $scope.$on(EventEnum.USER_GROUP_CHANGED_SILENT, updateLoggedUserStatus);
                $scope.$on(EventEnum.USER_GROUP_CHANGED, updateLoggedUserStatus);
            }
        ]
    });
})();
