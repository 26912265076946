/* global angular */
'use strict';

angular.module('core.requestOffer').factory('RequestOfferService', function () {
    function isAlreadyFinished(offer) {
        return CommonFunctions.hasIntersectValue(
            [offer.status.name],
            ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_SUPPLIER']
        );
    }

    function getOriginalPrice(part) {
        return part.newPrice * part.amount;
    }

    function getTotalPrice(offer, part) {
        return getAmount(offer, part) * getPrice(offer, part);
    }

    function getAmount(offer, part) {
        if (isAlreadyFinished(offer)) {
            return part.finalAmount >= 0 ? part.finalAmount : part.amount;
        } else {
            return part.amount;
        }
    }

    function getPrice(offer, part) {
        if (isAlreadyFinished(offer)) {
            return part.finalPrice >= 0 ? part.finalPrice : part.newPrice;
        } else {
            return part.newPrice;
        }
    }

    function getDifference(offer, part) {
        var difference = getTotalPrice(offer, part) - getOriginalPrice(part);
        if (isNaN(difference)) {
            difference = 0;
        }
        return difference;
    }

    function displayDiffCol(offer, parts) {
        var showDiffColumn = false;
        if (offer) {
            if (isAlreadyFinished(offer)) {
                angular.forEach(parts, function (part) {
                    if (part.offerPart) {
                        //for supplier sides json structure
                        if (part.offerPart.finalPrice || part.offerPart.finalAmount) {
                            showDiffColumn = true;
                        }
                    } else {
                        if (part.finalPrice || part.finalAmount) {
                            showDiffColumn = true;
                        }
                    }
                });
            }
        } else {
            showDiffColumn = true;
        }

        return showDiffColumn;
    }

    function isAlreadyFinishedForCreator(offer) {
        return CommonFunctions.hasIntersectValue([offer.status.name], ['FINISHED_WAITING_FOR_CREATOR']);
    }

    function getOriginalPriceForCreator(part) {
        return part.newPrice * part.amount;
    }

    function getTotalPriceForCreator(offer, part) {
        return getAmountForCreator(offer, part) * getPriceForCreator(offer, part);
    }

    function getAmountForCreator(offer, part) {
        if (isAlreadyFinishedForCreator(offer)) {
            return part.finalAmount >= 0 ? part.finalAmount : part.amount;
        } else {
            return part.amount;
        }
    }

    function getPriceForCreator(offer, part) {
        var partPrice = UtilFunctions.isGreaterOrEqualToZero(part.newPrice) ? part.newPrice : part.price;
        if (isAlreadyFinishedForCreator(offer)) {
            return part.finalPrice >= 0 ? part.finalPrice : partPrice;
        } else {
            return partPrice;
        }
    }

    function getDifferenceForCreator(offer, part) {
        var difference = getTotalPriceForCreator(offer, part) - getOriginalPriceForCreator(part);
        if (isNaN(difference)) {
            difference = 0;
        }
        return difference;
    }

    function displayDiffColForCreator(offer, parts) {
        var showDiffColumn = false;
        if (offer) {
            if (isAlreadyFinishedForCreator(offer)) {
                angular.forEach(parts, function (part) {
                    if (part.offerPart) {
                        //for supplier sides json structure
                        if (part.offerPart.finalPrice || part.offerPart.finalAmount) {
                            showDiffColumn = true;
                        }
                    } else {
                        if (part.finalPrice || part.finalAmount) {
                            showDiffColumn = true;
                        }
                    }
                });
            }
        } else {
            showDiffColumn = true;
        }

        return showDiffColumn;
    }

    return {
        isAlreadyFinished: function (offer) {
            return isAlreadyFinished(offer);
        },
        getOriginalPrice: function (part) {
            return getOriginalPrice(part);
        },
        getTotalPrice: function (offer, part) {
            return getTotalPrice(offer, part);
        },
        getAmount: function (offer, part) {
            return getAmount(offer, part);
        },
        getPrice: function (offer, part) {
            return getPrice(offer, part);
        },
        getTotalDifference: function (offer, part) {
            return getDifference(offer, part);
        },
        displayDiffColumn: function (offer, parts) {
            return displayDiffCol(offer, parts);
        },
        isAlreadyFinishedForCreator: function (offer) {
            return isAlreadyFinishedForCreator(offer);
        },
        getOriginalPriceForCreator: function (part) {
            return getOriginalPriceForCreator(part);
        },
        getTotalPriceForCreator: function (offer, part) {
            return getTotalPriceForCreator(offer, part);
        },
        getAmountForCreator: function (offer, part) {
            return getAmountForCreator(offer, part);
        },
        getPriceForCreator: function (offer, part) {
            return getPriceForCreator(offer, part);
        },
        getTotalDifferenceForCreator: function (offer, part) {
            return getDifferenceForCreator(offer, part);
        },
        displayDiffColumnForCreator: function (offer, parts) {
            return displayDiffColForCreator(offer, parts);
        },
    };
});
