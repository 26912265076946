import GoogleAddressUtil from '../../../../utils/GoogleAddressUtil';
import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import _ from 'underscore';

const INITIAL_SUPPLIER_SEARCH_SIZE = 24;
const DEFAULT_SUPPLIER_SEARCH_SIZE = 24;
const CATEGORY_NAMECODE_LIST = ['common.translate.category.hotel.&.conference', 'common.translate.category.venues'];
const SELECT_SUPPLIER_STEP = {
    SEARCH_SUPPLIER_STEP: 1,
    SEND_OFFER: 2,
};
const BUSS_PART_CODES = [
    'common.translate.offer.part.type.bus.trip',
    'common.translate.offer.part.type.bus.(home.journey)',
    'common.translate.offer.part.type.bus.(outward.journey)',
];

import googleAddressUtil from '../../../../utils/GoogleAddressUtil';

class SelectSupplierModalCtrl {
    constructor(
        $scope,
        $element,
        data,
        AuthenticationService,
        Event,
        Supplier,
        Request,
        close,
        OfferPreview,
        RequestOffer,
        EventEnum,
        $window,
        $timeout,
        $translate,
        DateService,
        $q,
        $rootScope,
        SupplierDetailService,
        ogScrollService,
        FilledRequestOfferPartComment,
        DynamicFormUtilService,
        ProcessLog,
        SupplierAvailabilityCheck,
        $location,
        GlobalOption,
        ClientService,
        SupplierDetailInfo,
        LocationService,
        CommonFile,
        Category
    ) {
        this._injected = {
            $scope,
            $element,
            data,
            AuthenticationService,
            Event,
            Supplier,
            Request,
            close,
            OfferPreview,
            RequestOffer,
            EventEnum,
            $window,
            $timeout,
            $translate,
            DateService,
            $q,
            $rootScope,
            SupplierDetailService,
            ogScrollService,
            FilledRequestOfferPartComment,
            DynamicFormUtilService,
            ProcessLog,
            SupplierAvailabilityCheck,
            $location,
            GlobalOption,
            ClientService,
            SupplierDetailInfo,
            LocationService,
            CommonFile,
            Category,
        };
        this.clientId = ClientService.get().id;
        this.categoryToggleSelected = false;
        let officeAddress = angular.copy(data.eventAddress);
        if (!officeAddress.displayAddress) {
            officeAddress = {};
        }
        this.searchParams = {
            categoryIds: [],
            max: INITIAL_SUPPLIER_SEARCH_SIZE,
            offset: 0,
            previewAll: true,
            sorting: { direction: 'desc', field: 'featured' }, //default search
            withChildLocation: true,
            eventId: data.eventId,
            supplierDetails: {},
            officeAddress,
            bounds: data.bounds,
            location: data.location,
            clientId: this.clientId,
            filterCategoryWiseInSupplierModal: false,
        };
        // use these two params to search even the text/geoId is change but search button not pressed.
        ScopeFunctions.addToScope($scope);
        this.eventId = data.eventId;
        this.requestId = data.requestId;
        this.isThereAccomAndConf = data.isThereAccomAndConf;
        this.isThereConferenceOnly = data.isThereConferenceOnly;
        this.accommodations = data.accommodations;
        this.startDate = data.startDate;
        this.conference = data.conference;
        this.hasContractedSupplier = data.hasContractedSupplier;
        this.showContractedSuppliersOnly = data.showContractedSuppliersOnly;
        this.extraRequirements = data.extraRequirements;
        this.selectedSuppliers = [];
        this.suppliersToSend = [];
        this.newlyAddedSuppliers = [];
        this.partsToUpdate = [];
        this.displayGuidInfo = true;
        this.enableThumbnailAnimation = true;
        this.declinedOffers = data.declinedOffers;
        this.canceledOffers = data.canceledOffers;
        this.unsentOffers = data.unsentOffers;
        this.showListOfConnectedSuppliers = false;
        this.connectedSuppliers = [];
        this.isThereUnpublishedSuppliers = false;
        if (data.step > 0) {
            this.currentSelectSupplierStep = data.step;
        } else {
            this.currentSelectSupplierStep = SELECT_SUPPLIER_STEP.SEARCH_SUPPLIER_STEP;
        }

        if (this.currentSelectSupplierStep === SELECT_SUPPLIER_STEP.SEARCH_SUPPLIER_STEP) {
            this.supplierListBusy = true;
        }
        this.vmData = {
            filterBy: 'featured',
        };

        this.showPopup = data.showPopup;
        this.directiveShowHide = {};
        this.directiveShowHide.addFromMap = false;
        this.getDateSpan = DateService.getDateSpan;
        this.getSearchByEmailEnabled = SupplierDetailService.getSearchByEmailEnabled;
        this.loadData(data);
        this.addSupplier = this.addSupplier.bind(this);
        this.processOfficeAddressParameter = this.processOfficeAddressParameter.bind(this);
        this.returnToSupplierView = this.returnToSupplierView.bind(this);
        this.updateOfferPart = this.updateOfferPart.bind(this);
        this.checkAndUpdateOfferPart = this.checkAndUpdateOfferPart.bind(this);
        this.handleOnLocationChange = this.handleOnLocationChange.bind(this);
        this.updateSelectedSupplierName = this.updateSelectedSupplierName.bind(this);
        this.setSupplierNameAndSearch = this.setSupplierNameAndSearch.bind(this);
        this.expandAdditionalQuestionsPart = this.expandAdditionalQuestionsPart.bind(this);
        this.checkAndSaveParts = this.checkAndSaveParts.bind(this);
        this.checkAndSendToSupplier = this.checkAndSendToSupplier.bind(this);
        this._checkAndSend = this._checkAndSend.bind(this);
        this._getPreviewRequest = this._getPreviewRequest.bind(this);
        this.addSuppliersAndThenSend = this.addSuppliersAndThenSend.bind(this);
        this.requiredUnanswered = this.requiredUnanswered.bind(this);
        this.addRequiredLabel = this.addRequiredLabel.bind(this);
        this.poll = this.poll.bind(this);
        this.isAvailableCheck = this.isAvailableCheck.bind(this);
        this.processCheckResult = this.processCheckResult.bind(this);
        this.getOfferPartAdditionalQuestionClass = this.getOfferPartAdditionalQuestionClass.bind(this);
        this.getRequestCategoryList = this.getRequestCategoryList.bind(this);
        this.isConferenceRequest = this.isConferenceRequest.bind(this);
        this.closeAgreementModal = this.closeAgreementModalFn.bind(this);
        this.advancedFilter = false;
        this.showNoSearchResults = false;
        this.disableCategoryOptions = true;
        this.categoryIds = [];
        this.reInitCarousel = true;
        this.showTopBar = false;
        this.showAgreementModal = false;

        this.sliderGuestAmount = {
            value: 0,
            options: {
                floor: 0,
                ceil: 1000,
                step: 1,
            },
        };

        this.sliderSleepingGuestAmount = {
            value: 0,
            options: {
                floor: 0,
                ceil: 1000,
                step: 1,
            },
        };

        this.sliderBusDistance = {
            value: 0,
            options: {
                floor: 0,
                ceil: 50,
                step: 1,
                translate: (value) => {
                    return value + 'km';
                },
            },
        };

        this.sliderAirportDistance = {
            value: 0,
            options: {
                floor: 0,
                ceil: 50,
                step: 1,
                translate: (value) => {
                    return value + 'km';
                },
            },
        };

        this.sliderTrainDistance = {
            value: 0,
            options: {
                floor: 0,
                ceil: 50,
                step: 1,
                translate: (value) => {
                    return value + 'km';
                },
            },
        };

        let resizeBodyHeight = function () {
            this.resizeModalBodyHeight();
        }.bind(this);

        var onFooterLoaded = $scope.$watch('selectSupplierMoCtrl.footerLoaded()', function (newVlaue) {
            if (newVlaue) {
                resizeBodyHeight();
                onFooterLoaded();
            }
        });

        angular.element($window).bind('resize', resizeBodyHeight);
        //unbind the resize listener
        $scope.$on('$destroy', function () {
            angular.element($window).unbind('resize', resizeBodyHeight);
        });

        $scope.$watch(
            'selectSupplierMoCtrl.selectedMessage',
            function (value) {
                if (value) {
                    this.requestOffer.supplierMessage = value;
                } else if (this.customerInfo) {
                    $scope.startProgress();
                    GlobalOption.get(
                        'DEFAULT_REQUEST_MESSAGE',
                        (resp) => {
                            this.requestOffer.supplierMessage = resp[$rootScope.langSelected]
                                ? resp[$rootScope.langSelected]
                                : $translate.instant('default.message.to.supplier', {
                                    p0: this.customerInfo.firstName + ' ' + this.customerInfo.lastName,
                                });
                            $scope.endProgress();
                        },
                        $scope.endWithError
                    );
                }
            }.bind(this)
        );

        this.baseRemoteUrl = CommonFile.getBaseUrl() + 'api/v1/supplier/getSupplierDropdownList';

        this.activities = [];
    }

    getLocationJsonFromIp() {
        const { $scope, LocationService } = this._injected;
        let _this = this;
        if (this.searchParams && Object.keys(this.searchParams.location).length === 0) {
            $scope.startProgress();
            LocationService.getLocationJsonFromIpApi(
                {},
                (response) => {
                    let __location = {};
                    let __bounds = {};
                    let __officeAddress = {};
                    // Google maps api
                    var geocoder = new google.maps.Geocoder();
                    var address = `${response.city}, ${response.country}`;
                    geocoder.geocode({ address: address }, function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            var latitudeLongitude = results[0].geometry.location
                                .toString()
                                .replace(/[\(\)]/g, '')
                                .split(',');
                            var latitude = latitudeLongitude[0];
                            var longitude = latitudeLongitude[1];
                            let _bounds = results[0].geometry.bounds;
                            if (!_bounds) {
                                _bounds = results[0].geometry.viewport;
                            }
                            let _minLat = _bounds.getSouthWest().lat();
                            let _minLng = _bounds.getSouthWest().lng();
                            let _maxLat = _bounds.getNorthEast().lat();
                            let _maxLng = _bounds.getNorthEast().lng();
                            __bounds = {
                                minLat: _minLat,
                                minLng: _minLng,
                                maxLat: _maxLat,
                                maxLng: _maxLng,
                            };
                            __location = { lat: latitude, lng: longitude };
                        }
                        __officeAddress = {
                            displayAddress: address,
                            municipality: response.city,
                            state: response.region,
                            town: response.city,
                            country: response.country,
                        };
                        _this.searchParams.location = __location;
                        _this.searchParams.officeAddress = __officeAddress;
                        _this.searchParams.bounds = __bounds;
                        _this.processOfficeAddressParameter();
                    });
                    $scope.endProgress();
                },
                $scope.endWithErrorOverlay()
            );
        }
    }

    showAdvancedFilterSection() {
        const { $scope, SupplierDetailInfo } = this._injected;
        if (this.request && !this.request.supplierDetails) {
            $scope.startProgress('add-supplier-modal');
            SupplierDetailInfo.getSupplierDetailsFilter(
                this.request.selectedCategoryId,
                (response) => {
                    this.request.supplierDetails = response;
                    $scope.endProgress('add-supplier-modal');
                },
                $scope.endWithErrorOverlay('add-supplier-modal')
            );
        }
        this.advancedFilter = true;
        this.setActive = true;
        this.resizeAdvanceFilterBodyHeight();
    }

    hideAdvancedFilterSection() {
        this.advancedFilter = false;
        this.setActive = false;
    }

    footerLoaded() {
        const { $element } = this._injected;
        var supplierFooter = $element.find('#select-supplier-footer');
        return supplierFooter && supplierFooter.innerHeight() > 0 && true;
    }

    /**
     * call this whenever the body of modal changes. This will autofit the size of supplier
     * @param delayTime
     */
    resizeModalBodyHeight(delayTime = 0) {
        const { $window, $element, $timeout } = this._injected;
        $timeout(function () {
            let windowHeight = $window.innerHeight;
            let modalHeader = $element.find('#select-supplier-header');
            var footer = $element.find('#select-supplier-footer');
            // we need to keep margin on top and bottom section, Also see select-supplier-modal.css for top-margin
            //https://app.asana.com/0/14368931590528/292465599612174
            let modalHeaderInnerHeight = modalHeader.innerHeight() || 0;
            let totalMarginForTopAndBottom = 24;
            let bodyHeight =
                windowHeight - footer.innerHeight() - modalHeaderInnerHeight - totalMarginForTopAndBottom;
            let modalBody = $element.find('#select-supplier-body');
            modalBody.css('height', bodyHeight + 'px');
        }, delayTime);
    }

    resizeAdvanceFilterBodyHeight(delayTime = 0) {
        const { $element, $timeout } = this._injected;
        $timeout(function () {
            let totalMarginForTopAndBottom = 95;
            let modalBody = $element.find('#select-supplier-body');
            let advanceFilterBodyHeight = modalBody.innerHeight() - totalMarginForTopAndBottom;
            let advanceFilterBody = $element.find('.advance-filter-body');
            advanceFilterBody.css('max-height', advanceFilterBodyHeight + 'px');
        }, delayTime);
    }

    getRequestSuggestionList(requestId, eventId) {
        const { $scope, Request } = this._injected;
        $scope.startProgress();
        Request.v1GetRequestCategoryListForMap(
            { requestId: requestId, eventId: eventId },
            (response) => {
                this.suggestionList = response.list.sort((a, b) => a.id - b.id);
                if (this.suggestionList.length > 0) {
                    this.searchParams.filterCategoryWiseInSupplierModal = true;
                } else {
                    this.searchParams.filterCategoryWiseInSupplierModal = false;
                }
                $scope.endProgress();
            },
            $scope.endWithErrorOverlay()
        );
    }

    getRequestCategoryList(categoryList) {
        if (this.suggestionList.length < 1) {
            this.activities = [];
            return;
        }
        if (categoryList.length < 1) {
            this.activities = [];
            return;
        }
        let _mainActivity = _.find(categoryList, (activity) => {
            return activity.nameCode === 'common.translate.category.activities';
        });
        let _isMainActivityExists = _.contains(this.suggestionList, _mainActivity.id);
        if (_isMainActivityExists) {
            let _currentActivities = _.filter(categoryList, (category) => {
                return category.parent && category.parent.nameCode === 'common.translate.category.activities';
            });
            _mainActivity.nameCode = 'common.translate.category.all.activities';
            if (this.searchParams.categoryIds) {
                let selectedCategory = _.find(categoryList, (activity) => {
                    return activity.id === this.searchParams.categoryIds[0];
                });
                if (selectedCategory) {
                    selectedCategory.selected = true;
                }
            } else {
                _mainActivity.selected = true;
            }
            _currentActivities.push(_mainActivity);
            this.activities = _currentActivities.sort((a, b) => a.id - b.id);
            this.disableCategoryOptions = false;
        } else {
            let _currentActivities = _.filter(categoryList, (category) => {
                return _.contains(this.suggestionList, category.id);
            });
            this.activities = _currentActivities.sort((a, b) => a.id - b.id);
            this.disableCategoryOptions = true;
        }
        if (this.activities) {
            this.searchParams.filterCategoryWiseInSupplierModal = true;
        }
    }

    loadData(data) {
        const { $scope, Event, $translate, ClientService } = this._injected;
        this.getRequestSuggestionList(this.requestId, this.eventId);
        this.clientName = ClientService.getClientName();
        $scope.startProgress('add-supplier-modal');
        Event.getContent(
            this.eventId,
            this.requestId,
            (response) => {
                this.request = response;
                this.isRequestSent = this.request.isRequestSent;
                let _requestOffers = response.requestOffers;
                //use the response data
                //if there is requestId, there will always be a requestOffer but it may not have a supplier
                if (_requestOffers.length === 1 && _requestOffers[0].supplier == null) {
                    this.selectedSuppliers = [];
                } else {
                    if (this.declinedOffers) {
                        angular.forEach(this.declinedOffers, function (_offer) {
                            let _declinedOfferId = parseInt(_offer.id);
                            let _declinedOffer = _.find(_requestOffers, (obj) => {
                                return parseInt(obj.id) === _declinedOfferId;
                            });
                            _requestOffers = _.without(_requestOffers, _declinedOffer);
                        });
                    }
                    if (this.canceledOffers) {
                        angular.forEach(this.canceledOffers, function (_offer) {
                            let _coId = parseInt(_offer.id);
                            let _co = _.find(_requestOffers, (obj) => {
                                return parseInt(obj.id) === _coId;
                            });
                            _requestOffers = _.without(_requestOffers, _co);
                        });
                    }
                    this.selectedSuppliers = _.map(_requestOffers, 'supplier');
                    angular.forEach(this.selectedSuppliers, (supplier) => {
                        supplier.oldAgreementNumber = supplier.agreementNumber;
                    });
                    this.originalSelectedSuppliers = angular.copy(this.selectedSuppliers);
                }
                this.searchParams.isThereAccomAndConf = this.isThereAccomAndConf;
                this.searchParams.isThereConferenceOnly = this.isThereConferenceOnly;
                this.searchParams.conference = this.conference;
                this.searchParams.accommodations = this.accommodations;
                this.searchParams.startDate = this.startDate;
                this.participantAmount = response.event.participantAmount;
                this.searchParams.participantAmount = this.participantAmount;
                this.searchParams.categoryIds = response.categoryIds;
                this.categoryIds = response.categoryIds;
                if (this.showPopup) {
                    this.suppliersToSend = _.map(data.unsentOffers, 'supplier');
                }
                if (response.categoryIds) {
                    this.remoteUrl = new URL(this.baseRemoteUrl);

                    this.remoteUrl.searchParams.append('categoryIds', this.searchParams.categoryIds);

                    if (this.isThereAccomAndConf) {
                        this.remoteUrl.searchParams.append('isThereAccomAndConf', this.isThereAccomAndConf);
                    }
                    if (this.isThereConferenceOnly) {
                        this.remoteUrl.searchParams.append('isThereConferenceOnly', this.isThereConferenceOnly);
                    }
                    if (this.searchParams.withChildLocation) {
                        this.remoteUrl.searchParams.append('withChildLocation', this.searchParams.withChildLocation);
                    }
                    if (!this.searchParams.officeAddress || !this.searchParams.officeAddress.country) {
                        this.getLocationJsonFromIp();
                    } else {
                        this.processOfficeAddressParameter();
                    }
                }
                if (this.currentSelectSupplierStep === SELECT_SUPPLIER_STEP.SEND_OFFER) {
                    this.suppliersToSend = _.map(data.unsentOffers, 'supplier');
                    this.goToSecondStep(data.unsentOffers);
                } else {
                    this.defaultSearchSupplier();
                }
                $scope.endProgress('add-supplier-modal');
            },
            $scope.endWithErrorOverlay('add-supplier-modal')
        );
    }

    processOfficeAddressParameter() {
        if (this.searchParams.officeAddress && this.searchParams.officeAddress.municipality) {
            this.remoteUrl.searchParams.append('municipality', this.searchParams.officeAddress.municipality);
        }
        if (this.searchParams.officeAddress && this.searchParams.officeAddress.town) {
            this.remoteUrl.searchParams.append('town', this.searchParams.officeAddress.town);
        }
        if (this.searchParams.officeAddress && this.searchParams.officeAddress.state) {
            this.remoteUrl.searchParams.append('state', this.searchParams.officeAddress.state);
        }
        if (this.searchParams.officeAddress && this.searchParams.officeAddress.country) {
            this.remoteUrl.searchParams.append('country', this.searchParams.officeAddress.country);
        }
        if (this.searchParams.bounds && this.searchParams.bounds.minLat) {
            this.remoteUrl.searchParams.append('minLat', this.searchParams.bounds.minLat);
        }
        if (this.searchParams.bounds && this.searchParams.bounds.maxLat) {
            this.remoteUrl.searchParams.append('maxLat', this.searchParams.bounds.maxLat);
        }
        if (this.searchParams.bounds && this.searchParams.bounds.minLng) {
            this.remoteUrl.searchParams.append('minLng', this.searchParams.bounds.minLng);
        }
        if (this.searchParams.bounds && this.searchParams.bounds.maxLng) {
            this.remoteUrl.searchParams.append('maxLng', this.searchParams.bounds.maxLng);
        }
        if (this.searchParams.location && this.searchParams.location.lat) {
            this.remoteUrl.searchParams.append('lat', this.searchParams.location.lat);
        }
        if (this.searchParams.location && this.searchParams.location.lng) {
            this.remoteUrl.searchParams.append('lng', this.searchParams.location.lng);
        }
    }

    //checks if supplier is in suppliers, returns the index if present
    supplierIndexInList(suppliers, supplier) {
        let index = _.findIndex(suppliers, { id: supplier.id });
        //for supplier added by email, no id is present
        if (index <= -1 && !supplier.id) {
            index = _.findIndex(suppliers, { email: supplier.email });
        }
        return index;
    }

    /*    doDefaultSupplierSearch(){
           let _displayAddress = angular.copy(this.searchParams.officeAddress.displayAddress);
           if(_displayAddress){
               window.googleMapsCallback( () => {
                   var geocoder = new google.maps.Geocoder();
                   let deAccentText = googleAddressUtil.deAccentSearchText(_displayAddress);
                   geocoder.geocode({'address':deAccentText},  (results, status) => {
                       if (status === google.maps.GeocoderStatus.OK) {
                           var place = results[0];
                           this.searchParams.officeAddress = googleAddressUtil.parseGoogleAddress(place);
                           this.remoteUrl.searchParams.append("neLat",  this.searchParams.officeAddress.geoBounds.northEast.lat);
                           this.remoteUrl.searchParams.append("neLng",  this.searchParams.officeAddress.geoBounds.northEast.lng);
                           this.remoteUrl.searchParams.append("swLat",  this.searchParams.officeAddress.geoBounds.southWest.lat);
                           this.remoteUrl.searchParams.append("swLng",  this.searchParams.officeAddress.geoBounds.southWest.lng);
                           this.searchParams.officeAddress.displayAddress = _displayAddress;
                       }
                       this.defaultSearchSupplier();
                   });
               });
           }else{
               this.searchParams.officeAddress = {};
               this.defaultSearchSupplier();
           }
       }*/

    /**
     * Use this to do a fresh search for supplier. i.e. on text change, geo changed etc
     */
    doFreshSupplierSearch() {
        const { $scope } = this._injected;

        $scope.startProgress('add-supplier-modal');
        setTimeout(() => {
            if (this.supplierListBusy) {
                return;
            }
            this.refreshActivitiList = true;
            this.supplierListBusy = true;
            this.searchParams.filterCategoryWiseInSupplierModal = false;
            this.searchParams.offset = 0;
            this.searchParams.max = INITIAL_SUPPLIER_SEARCH_SIZE;
            let _displayAddress = angular.copy(this.searchParams.officeAddress.displayAddress);
            this.searchParams.isThereAccomAndConf = this.isThereAccomAndConf;
            this.searchParams.accommodations = this.accommodations;
            this.searchParams.startDate = this.startDate;
            this.searchParams.conference = this.conference;
            this.searchParams.participantAmount = this.participantAmount;
            this.searchParams.isSuggestedSupplierSelected = JSON.parse(
                localStorage.getItem('isSuggestedSupplierSelected')
            );
            this.advancedFilter = false;
            this.searchParams.categoryIds = angular.copy(this.categoryIds);
            if (_displayAddress) {
                let _correctedDisplayAddress = GoogleAddressUtil.getCorrectedDisplayAddressManually(_displayAddress);
                window.googleMapsCallback(() => {
                    var geocoder = new google.maps.Geocoder();
                    geocoder.geocode({ address: _correctedDisplayAddress }, (results, status) => {
                        if (status === google.maps.GeocoderStatus.OK) {
                            var place = GoogleAddressUtil.findPlaceFromResults(results);
                            this.searchParams.officeAddress = googleAddressUtil.parseGoogleAddress(place);
                            this.searchParams.officeAddress.displayAddress = _displayAddress;
                            let _bounds = place.geometry.bounds;
                            if (_bounds) {
                                let _swLat = _bounds.getSouthWest().lat();
                                let _swLng = _bounds.getSouthWest().lng();
                                let _neLat = _bounds.getNorthEast().lat();
                                let _neLng = _bounds.getNorthEast().lng();
                                this.searchParams.bounds = {
                                    minLng: _swLng,
                                    maxLng: _neLng,
                                    minLat: _swLat,
                                    maxLat: _neLat,
                                };
                                if (place.geometry.location) {
                                    this.searchParams.location = {
                                        lat: place.geometry.location.lat(),
                                        lng: place.geometry.location.lng(),
                                    };
                                }
                            } else if (place.geometry.location) {
                                this.searchParams.location = {
                                    lat: place.geometry.location.lat(),
                                    lng: place.geometry.location.lng(),
                                };
                                this.searchParams.bounds = {};
                            }
                        }
                        this.searchSupplier();
                    });
                });
            } else {
                this.searchParams.officeAddress = {};
                this.searchParams.bounds = {};
                this.searchParams.location = {};
                this.searchSupplier();
            }
            $scope.endProgress('add-supplier-modal');
        }, 700);
    }

    clearAdvanceSearchFilters() {
        this.setActive = false;
        this.advancedFilter = false;
        //this.request.supplierDetails;
        if (this.request.supplierDetails) {
            angular.forEach(this.request.supplierDetails.supplierDetailGroups, (group) => {
                angular.forEach(group.supplierDetailFilterGroups, (supplierDetailFilterGroup) => {
                    angular.forEach(supplierDetailFilterGroup.supplierDetails, (supplierDetail) => {
                        supplierDetail.value = null;
                    });
                });
            });
        }
        this.doFreshSupplierSearch();
    }

    clearParticularAdvanceSearchFilter(supplierDetailToBeRemoved, index) {
        supplierDetailToBeRemoved.value = null;
        this.supplierDetailArray.splice(index, 1);
        this.doFreshSupplierSearch();
    }

    clearSupplierSearch() {
        const { $scope } = this._injected;
        this.showNoSearchResults = false;
        this.searchParams.officeAddress = {};
        this.supplierName = null;
        $scope.$broadcast('angucomplete-alt:clearInput');
        this.clearAdvanceSearchFilters();
    }

    defaultSearchSupplier() {
        const { Supplier, $scope, $timeout } = this._injected;
        $scope.startProgress('add-supplier-modal');
        const timeout = this.searchParams && Object.keys(this.searchParams.location).length === 0 ? 4000 : 0;
        $timeout(() => {
            Supplier.search(
                this.searchParams,
                (successResponse) => {
                    this.suppliers = successResponse.list;
                    this.getRequestCategoryList(successResponse.categories);
                    this.totalCount = successResponse.totalCount;
                    if (this.totalCount < 1) {
                        this.showNoSearchResults = true;
                    }
                    this.updateSelectedSupplierInSearchResult();
                    this.supplierListBusy = false;
                    if (this.isThereAccomAndConf) {
                        this.apiAvailabililityloding = true;
                        this.poll();
                    }
                    $scope.endProgress('add-supplier-modal');
                    this.showTopBar = true;
                },
                $scope.endWithErrorOverlay('add-supplier-modal')
            );
        }, timeout);
    }

    searchSupplier(offset = 0, max = INITIAL_SUPPLIER_SEARCH_SIZE) {
        const { Supplier, $scope } = this._injected;
        this.supplierDetailArray = [];
        this.searchParams.offset = offset;
        this.searchParams.max = max;
        this.searchParams.text = this.supplierName;
        this.searchParams.searchContractedSupplier = this.searchContractedSupplier;
        this.searchParams.eventId = this.eventId;
        this.searchParams.isThereAccomAndConf = this.isThereAccomAndConf;
        this.searchParams.isThereConferenceOnly = this.isThereConferenceOnly;
        this.searchParams.conference = this.conference;
        this.searchParams.accommodations = this.accommodations;
        this.searchParams.startDate = this.startDate;
        this.searchParams.participantAmount = this.participantAmount;
        this.searchParams.supplierDetails = this.request.supplierDetails;
        if (this.request.supplierDetails) {
            angular.forEach(this.request.supplierDetails.supplierDetailGroups, (group) => {
                angular.forEach(group.supplierDetailFilterGroups, (supplierDetailFilterGroup) => {
                    angular.forEach(supplierDetailFilterGroup.supplierDetails, (supplierDetail) => {
                        if (supplierDetail.value && Number(supplierDetail.value) !== 0) {
                            this.supplierDetailArray.push(supplierDetail);
                        }
                    });
                });
            });
        }
        this.supplierListBusy = true;

        $scope.startProgress('add-supplier-modal');
        Supplier.search(
            this.searchParams,
            (successResponse) => {
                if (successResponse && successResponse.list) {
                    if (offset === 0 && this.refreshActivitiList) {
                        this.getRequestCategoryList(successResponse.categories);
                    }
                    this.isSuggestedSupplier = successResponse.isSuggestedSupplier;
                    this.showNoSearchResults = false;
                    localStorage.setItem('isSuggestedSupplierSelected', 'false');
                    if (this.searchParams.offset === 0) {
                        this.suppliers = successResponse.list;
                    } else {
                        successResponse.list.forEach((supp) => {
                            this.suppliers.push(supp);
                        });
                    }
                }
                if (!successResponse.list.length) {
                    this.showNoSearchResults = true;
                }
                if (this.isThereAccomAndConf) {
                    this.apiAvailabililityloding = true;
                    this.poll();
                }
                this.totalCount = successResponse.totalCount;
                this.updateSelectedSupplierInSearchResult();
                this.supplierListBusy = false;

                this.reInitCarousel ? this.reInitCategoryTagCarousel() : null;

                this.reInitCarousel = true;

                $scope.endProgress('add-supplier-modal');
            },
            $scope.endWithErrorOverlay('add-supplier-modal')
        );
    }

    addSupplierFromMap() {
        this.searchParams.text = this.supplierName;
        this.searchParams.supplierDetailArray = this.supplierDetailArray;
        this.directiveShowHide.addFromMap = true;
        this.resizeModalBodyHeight();
    }

    returnToSupplierView(text, officeAddress, bounds, location) {
        //needed to reset when returned from "send offer view"
        this.currentSelectSupplierStep = SELECT_SUPPLIER_STEP.SEARCH_SUPPLIER_STEP;
        this.supplierName = text;
        this.searchParams.officeAddress = officeAddress;
        this.searchParams.bounds = bounds;
        this.searchParams.location = location;
        if (this.activities) {
            this.refreshActivitiList = true;
        }
        if (this.suggestionList.length > 0) {
            this.searchParams.filterCategoryWiseInSupplierModal = true;
        } else {
            this.searchParams.filterCategoryWiseInSupplierModal = false;
        }
        this.searchSupplier();
        this.directiveShowHide.addFromMap = false;
        this.resizeModalBodyHeight();
    }

    changeFilter(filterBy, direction = 'desc') {
        if (this.suggestionList.length > 0) {
            this.searchParams.filterCategoryWiseInSupplierModal = true;
        } else {
            this.searchParams.filterCategoryWiseInSupplierModal = false;
        }
        this.categoryToggleSelected = true;
        this.reInitCarousel = false;
        if ('contracted' === filterBy) {
            this.searchContractedSupplier = true;
        } else {
            this.searchContractedSupplier = false;
            this.searchParams.sorting = { direction, field: filterBy };
        }
        this.refreshActivitiList = false;
        this.searchSupplier();
    }

    filterByName(filterBy) {
        this.changeFilter('name', filterBy);
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }

    hideSupplierDetails() {
        const { SupplierDetailService } = this._injected;
        let _previewIndex = SupplierDetailService.getSupplierDetailIndex();
        SupplierDetailService.setSupplierDetailStatus(null);
        this.enableThumbnailAnimation = false;
        this.scrollTo(_previewIndex);
    }

    scrollTo(index) {
        const { $timeout } = this._injected;
        if(!index) {
            return;
        }
        let _elementId = '#supplier-thumbnail-' + index;
        $timeout(() => {
            let _topHeight = jQuery(_elementId).position().top;
            // jQuery('#select-supplier-body').scrollTop(_topHeight);
            jQuery('#select-supplier-body').animate({ scrollTop: _topHeight }, 'slow');
            this.disabledInfiniteScroll = false;
            this.enableThumbnailAnimation = true;
        }, 700);
    }

    shouldDisplaySupplierDetails() {
        const { SupplierDetailService } = this._injected;
        return SupplierDetailService.getSupplierDetailStatus();
    }

    _addSupplier(supplier) {
        if (this.selectedSuppliers.length >= 4 || this.supplierIndexInList(this.selectedSuppliers, supplier) > -1) {
            return;
        }
        if (this.isSupplierInCancelState(supplier) || this.isSupplierInDeclineState(supplier)) {
            return;
        }
        supplier.deletable = true; //currently added supplier is deletable from preview
        supplier.editable = true; //currently added supplier is editable from preview
        supplier.selectToSend = true; //By default it is clicked and ready to send to supplier
        this.selectedSuppliers.push(supplier);
        this.newlyAddedSuppliers.push(supplier);
        this.suppliersToSend.push(supplier);
        this.updateSupplierAddedStatus(supplier);
    }

    isSupplierInCancelState(supplier) {
        if (this.canceledOffers) {
            var obj = _.find(this.canceledOffers, (offer) => {
                if (!!offer.supplier) {
                    return parseInt(offer.supplier.id) === parseInt(supplier.id);
                }
            });
            return !!obj;
        }
        return false;
    }

    isSupplierInDeclineState(supplier) {
        if (this.declinedOffers) {
            var obj = _.find(this.declinedOffers, (offer) => {
                if (!!offer.supplier) {
                    return parseInt(offer.supplier.id) === parseInt(supplier.id);
                }
            });
            return !!obj;
        }
        return false;
    }

    isSupplierUnPublished(supplier) {
        return supplier.unPublishSupplier;
    }

    _animate(supplier) {
        let { $q } = this._injected;
        var deferred = $q.defer();

        if (this.selectedSuppliers.length >= 4 || this.supplierIndexInList(this.selectedSuppliers, supplier) > -1) {
            deferred.resolve('error');
            return deferred.promise;
        }
        if (this.isSupplierInCancelState(supplier) || this.isSupplierInDeclineState(supplier)) {
            deferred.resolve('error');
            return deferred.promise;
        }
        var cart = jQuery('.animate-to-supplier-add');
        var imageId = '#supplier-image-' + supplier.id;

        var imgtodrag;
        if (this.directiveShowHide.addFromMap) {
            imgtodrag = jQuery('.map-container .map-information .carousel-inner .item:first');
            if (imgtodrag.length == 0) {
                imgtodrag = jQuery('.map-container .map-information .slider .no-image');
            }
        } else {
            imgtodrag = jQuery(imageId);
        }

        if (imgtodrag) {
            var imgclone = imgtodrag
                .clone()
                .find('div:not(.no-image)')
                .remove()
                .end()
                .offset({
                    top: imgtodrag.offset().top,
                    left: imgtodrag.offset().left,
                })
                .css({
                    position: 'absolute',
                    height: imgtodrag.height(),
                    width: imgtodrag.width(),
                    'min-height': 'initial',
                    'z-index': '10550',
                    'background-size': 'cover',
                })
                .appendTo(jQuery('body'))
                .animate(
                    {
                        top: cart.offset().top,
                        left: cart.offset().left,
                        width: 130,
                        height: 30,
                    },
                    1000,
                    'easeOutExpo',
                    function () {
                        deferred.resolve('success');
                        imgclone.animate(
                            {
                                opacity: 0,
                                // 'width': 0,
                                // 'height': 0
                            },
                            300,
                            function () {
                                jQuery(this).detach();
                            }
                        );
                    }
                );
        }
        return deferred.promise;
    }

    addSupplier(supplier) {
        this._animate(supplier).then(() => {
            this._addSupplier(supplier);
        });
    }

    toggleSupplierEmailSection(show = !this.showSupplierEmailSection) {
        this.showSupplierEmailSection = show;
        if (!show) {
            this.supplierEmail = '';
            this.showListOfConnectedSuppliers = false;
            this.connectedSuppliers = [];
        }
    }

    addSupplierByEmail() {
        const { OfferPreview, $scope } = this._injected;
        if (this.supplierEmail) {
            //TODO check if supplier exists with this email upto UserGroup level
            $scope.startProgress('add-supplier-modal');
            OfferPreview.checkForAddedEmail({ email: this.supplierEmail }, (response) => {
                let supplier = {};
                if (response.length === 1) {
                    supplier = {
                        id: response[0].id,
                        email: response[0].email,
                        name: response[0].name,
                        clients: response[0].clients,
                        unPublishSupplier: response[0].unPublishSupplier,
                    };
                    if (this.isSupplierUnPublished(supplier)) {
                        this.isThereUnpublishedSuppliers = true;
                        this.showListOfConnectedSuppliers = true;
                        this.connectedSuppliers = response;
                    } else {
                        this._addSupplier(supplier);
                        this.toggleSupplierEmailSection();
                    }
                } else if (response.length > 1) {
                    this.showListOfConnectedSuppliers = true;
                    this.connectedSuppliers = response;
                    angular.forEach(this.connectedSuppliers, (supplier) => {
                        if (!this.isThereUnpublishedSuppliers && this.isSupplierUnPublished(supplier)) {
                            this.isThereUnpublishedSuppliers = true;
                        }
                    });
                } else {
                    supplier = { name: this.supplierEmail, email: this.supplierEmail };
                    this._addSupplier(supplier);
                    this.toggleSupplierEmailSection();
                }
                angular.forEach(this.connectedSuppliers, (supplier) => {
                    angular.forEach(this.selectedSuppliers, (selectedSupplier) => {
                        if (selectedSupplier.id === supplier.id) {
                            supplier.added = true;
                        }
                    });
                });
                $scope.endProgress('add-supplier-modal');
            });
        }
    }

    //added=true when supplier is added to list and false when removed from the list
    updateSupplierAddedStatus(supplier, added = true) {
        let indexMainList = this.supplierIndexInList(this.suppliers, supplier);
        let indexSuggestionList = this.supplierIndexInList(this.connectedSuppliers, supplier);
        if (indexMainList > -1) {
            this.suppliers[indexMainList].added = added;
        }

        if (indexSuggestionList > -1) {
            this.connectedSuppliers[indexSuggestionList].added = added;
        }
    }

    removeSelectedSupplier(supplier) {
        this.removeSupplierFromList(this.selectedSuppliers, supplier);
        this.removeSupplierFromList(this.suppliersToSend, supplier);
        this.removeSupplierFromList(this.newlyAddedSuppliers, supplier);
        this.updateSupplierAddedStatus(supplier, false);
    }

    removeSupplierFromList(suppliers, supplier) {
        let index = this.supplierIndexInList(suppliers, supplier);
        if (index > -1) {
            suppliers.splice(index, 1);
        }
    }

    updateSelectedSupplierInSearchResult() {
        if (this.selectedSuppliers) {
            this.selectedSuppliers.forEach((supplier) => this.updateSupplierAddedStatus(supplier));
        }
        if (this.canceledOffers) {
            angular.forEach(this.canceledOffers, (offer) => {
                if (!!offer.supplier) {
                    this.updateSupplierAddedStatus(offer.supplier);
                }
            });
        }
        if (this.declinedOffers) {
            angular.forEach(this.declinedOffers, (offer) => {
                if (!!offer.supplier) {
                    this.updateSupplierAddedStatus(offer.supplier);
                }
            });
        }
    }

    updateRemoteUrlOnAddressChange() {
        let searchAddress;
        let _displayAddress = angular.copy(this.searchParams.officeAddress.displayAddress);
        if (_displayAddress) {
            window.googleMapsCallback(() => {
                var geocoder = new google.maps.Geocoder();
                let _correctedDisplayAddress = GoogleAddressUtil.getCorrectedDisplayAddressManually(_displayAddress);
                geocoder.geocode({address: _correctedDisplayAddress}, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        var place = GoogleAddressUtil.findPlaceFromResults(results);
                        searchAddress = googleAddressUtil.parseGoogleAddress(place);
                        this.remoteUrl.searchParams.set('municipality', searchAddress.municipality);
                        this.remoteUrl.searchParams.set('town', searchAddress.town);
                        this.remoteUrl.searchParams.set('state', searchAddress.state);
                        this.remoteUrl.searchParams.set('country', searchAddress.country);
                        this.searchParams.officeAddress.municipality = searchAddress.municipality;
                        this.searchParams.officeAddress.town = searchAddress.town;
                        this.searchParams.officeAddress.state = searchAddress.state;
                        this.searchParams.officeAddress.country = searchAddress.country;
                        this.searchParams.officeAddress.searchAddress =
                            googleAddressUtil.prepareGeocodeParameters(searchAddress);
                        let _bounds = place.geometry.bounds;
                        if (_bounds) {
                            let _swLat = _bounds.getSouthWest().lat();
                            let _swLng = _bounds.getSouthWest().lng();
                            let _neLat = _bounds.getNorthEast().lat();
                            let _neLng = _bounds.getNorthEast().lng();
                            this.remoteUrl.searchParams.set('minLng', _swLng);
                            this.remoteUrl.searchParams.set('maxLng', _neLng);
                            this.remoteUrl.searchParams.set('minLat', _swLat);
                            this.remoteUrl.searchParams.set('maxLat', _neLat);
                            this.remoteUrl.searchParams.set('lat', '');
                            this.remoteUrl.searchParams.set('lng', '');
                            this.searchParams.bounds = {
                                minLng: _swLng,
                                maxLng: _neLng,
                                minLat: _swLat,
                                maxLat: _neLat,
                            };
                            if (place.geometry.location) {
                                this.remoteUrl.searchParams.set('lat', place.geometry.location.lat());
                                this.remoteUrl.searchParams.set('lng', place.geometry.location.lng());
                                this.searchParams.location = {
                                    lat: place.geometry.location.lat(),
                                    lng: place.geometry.location.lng(),
                                };
                            }
                        } else if (place.geometry.location) {
                            this.remoteUrl.searchParams.set('minLng', '');
                            this.remoteUrl.searchParams.set('maxLng', '');
                            this.remoteUrl.searchParams.set('minLat', '');
                            this.remoteUrl.searchParams.set('maxLat', '');
                            this.remoteUrl.searchParams.set('lat', place.geometry.location.lat());
                            this.remoteUrl.searchParams.set('lng', place.geometry.location.lng());
                            this.searchParams.location = {
                                lat: place.geometry.location.lat(),
                                lng: place.geometry.location.lng(),
                            };
                            this.searchParams.bounds = {};
                        }
                    }
                });
            });
        } else {
            this.remoteUrl.searchParams.set('municipality', '');
            this.remoteUrl.searchParams.set('town', '');
            this.remoteUrl.searchParams.set('state', '');
            this.remoteUrl.searchParams.set('country', '');
            this.remoteUrl.searchParams.set('minLat', '');
            this.remoteUrl.searchParams.set('maxLat', '');
            this.remoteUrl.searchParams.set('minLng', '');
            this.remoteUrl.searchParams.set('maxLng', '');
            this.remoteUrl.searchParams.set('lat', '');
            this.remoteUrl.searchParams.set('lng', '');
            this.searchParams.officeAddress = {};
            this.searchParams.officeAddress.bounds = {};
            this.searchParams.officeAddress.location = {};
        }
    }

    handleOnLocationChange(newLocation) {
        // console.log("select-supplier:",newLocation)
    }

    updateSelectedSupplierName(supplierName) {
        this.supplierName = supplierName;
    }

    setSupplierNameAndSearch(supplierName, isSuggestedSupplierSelected = false) {
        this.supplierName = supplierName;
        this.isSuggestedSupplierSelected = isSuggestedSupplierSelected;
        this.refreshActivitiList = true;
        this.doFreshSupplierSearch();
    }

    getMoreSuppliers() {
        if (this.supplierListBusy) {
            return;
        }
        let offset = this.suppliers.length ? this.suppliers.length : 0;
        if (offset >= this.totalCount) {
            return;
        }
        this.categoryToggleSelected = true;
        this.reInitCarousel = false;
        this.refreshActivitiList = false;
        if (this.suggestionList.length > 0) {
            this.searchParams.filterCategoryWiseInSupplierModal = true;
        } else {
            this.searchParams.filterCategoryWiseInSupplierModal = false;
        }
        this.searchSupplier(offset, DEFAULT_SUPPLIER_SEARCH_SIZE);
    }

    goToSecondStepAnyWay(unsentOffers) {
        this.hideSupplierDetails();
        this.showPopup = false;
        this.currentSelectSupplierStep = SELECT_SUPPLIER_STEP.SEND_OFFER;
        const { OfferPreview, RequestOffer, $scope, $translate, $rootScope, GlobalOption } = this._injected;
        $scope.startProgress('add-supplier-modal');
        OfferPreview.getPreviewRequest(
            this.eventId,
            this.requestId,
            (response) => {
                this.event = response.event;
                this.customerInfo = response.customerInfo;
                this.request = response.request;
                this.requestOffer = response.requestOffer;
                if (this.request.supplierMessage) {
                    this.requestOffer.supplierMessage = this.request.supplierMessage;
                    this.displayRevertMessageSection = true;
                } else {
                    if (response.requestOffer.supplierMessage) {
                        this.requestOffer.supplierMessage = response.requestOffer.supplierMessage;
                    } else {
                        $scope.startProgress('add-supplier-modal');
                        GlobalOption.get(
                            'DEFAULT_REQUEST_MESSAGE',
                            (resp) => {
                                let regardsFrom =
                                    response.customerInfo.firstName && response.customerInfo.lastName
                                        ? response.customerInfo.firstName + ' ' + response.customerInfo.lastName
                                        : response.customerInfo.email;
                                this.requestOffer.supplierMessage = resp[$rootScope.langSelected]
                                    ? resp[$rootScope.langSelected]
                                    : $translate.instant('default.message.to.supplier', { p0: regardsFrom });
                                $scope.endProgress('add-supplier-modal');
                            },
                            $scope.endWithError
                        );
                    }
                }
                $scope.endProgress('add-supplier-modal');
                this.resizeModalBodyHeight();
            },
            $scope.endWithErrorOverlay('add-supplier-modal')
        );

        let offerIdList = _.map(unsentOffers, 'id');
        $scope.startProgress();
        RequestOffer.getMessageOptions(
            { eventId: this.eventId, offerIdList: offerIdList },
            function (response) {
                this.otherOffers = response.otherOffers;
                $scope.endProgress();
            }.bind(this),
            $scope.endWithErrorOverlay
        );
        this.directiveShowHide.addFromMap = false;
    }

    goToSecondStep(unsentOffers) {
        if (this.extraRequirements && this.selectedSuppliers.length < 3 && !this.isRequestSent) {
            this.showPopup = true;
        } else {
            this.goToSecondStepAnyWay(unsentOffers);
        }
    }

    addMoreSuppliers() {
        this.showPopup = false;
    }

    switchToDefaultMessage() {
        const { $translate, $scope, $rootScope, GlobalOption } = this._injected;
        $scope.startProgress('add-supplier-modal');
        GlobalOption.get(
            'DEFAULT_REQUEST_MESSAGE',
            (resp) => {
                this.requestOffer.supplierMessage = resp[$rootScope.langSelected]
                    ? resp[$rootScope.langSelected]
                    : $translate.instant('default.message.to.supplier', {
                        p0: this.customerInfo.firstName + ' ' + this.customerInfo.lastName,
                    });
                $scope.endProgress('add-supplier-modal');
            },
            $scope.endWithError
        );
        this.displayRevertMessageSection = false;
    }

    checkAndAddSupplierOnRequest() {
        if (this.additionalInfoUpdated) {
            this.checkAndSaveParts(this.partsToUpdate).then(() => {
                this.addSupplierOnRequest();
            });
        } else {
            this.addSupplierOnRequest();
        }
    }

    addSupplierOnRequest() {
        let removedSuppliers = this.getRemovedSupplierList();
        this.supplierListBusy = true;
        let params = {
            suppliers: this.newlyAddedSuppliers,
            removeSuppliers: removedSuppliers,
            supplierMessage:
                this.requestOffer && this.requestOffer.supplierMessage ? this.requestOffer.supplierMessage : '',
        };
        const { OfferPreview, $scope, EventEnum, $rootScope } = this._injected;
        $scope.startProgress('add-supplier-modal');
        var vm = this;
        // vm.removeSupplierFromEventFn().then(function(res) {
        OfferPreview.addSuppliers(
            vm.requestId,
            params,
            (response) => {
                vm.event = response.event;
                vm.customerInfo = response.customerInfo;
                vm.request = response.request;
                vm.requestOffer = response.requestOffer;
                vm.supplierListBusy = false;
                $scope.endProgress('add-supplier-modal');
                let _newSupplierIds = vm.newlyAddedSuppliers ? vm.newlyAddedSuppliers.map((a) => a.id) : [];
                $rootScope.$emit('event:user:action', {
                    action: 'save.and.send.request.to.supplier.later',
                    data: { event: vm.event.id, request: vm.requestId, suppliers: _newSupplierIds },
                });
                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                    requestId: vm.request.id,
                    offerId: vm.requestOffer.id,
                });

                vm.close(); //todo close for now , have to display success message
            },
            $scope.endWithErrorOverlay('add-supplier-modal')
        );
        // });
    }

    checkIfAdditionalQuestionsChanges(changed, offerPart) {
        this.additionalInfoUpdated = changed;

        if (!_.contains(this.partsToUpdate, offerPart)) {
            this.partsToUpdate.push(offerPart);
        }
        angular.forEach(this.unsentOffers, (offers) => {
            var partToUpdate = _.find(offers.offerParts, function (part) {
                return part.referenceId === offerPart.referenceId && part.id !== offerPart.id;
            });
            if (partToUpdate && !_.contains(this.partsToUpdate, partToUpdate)) {
                partToUpdate.list = offerPart.list;
                this.partsToUpdate.push(partToUpdate);
            }
        });
    }

    checkAndSendToSupplier() {
        let { $scope } = this._injected;
        // this.skipScroll = false;
        let isRequiredFieldUnanswered = false;
        angular.forEach(this.requestOffer.offerParts, (parentList) => {
            if (parentList.hasAdditionalQuestions && parentList.hasAdditionalQuestionsUnanswered) {
                angular.forEach(parentList.list, (list) => {
                    if (list.type.name !== 'TEXT_WITH_AMOUNT_TYPE') {
                        let _strLogicToShow = list.showHide;
                        if (!_strLogicToShow) {
                            if (list.required && !list.fieldValue) {
                                isRequiredFieldUnanswered = true;
                            }
                        } else {
                            let stringArray = _strLogicToShow.split('~');
                            let shouldShow = stringArray[0] === 'SHOWON';
                            let shouldHide = stringArray[0] === 'HIDEON';
                            let _reference = stringArray[1];
                            if (shouldShow) {
                                let _question = _.find(parentList.list, (item) => {
                                    return item.field === _reference;
                                });
                                if (_question.fieldValue) {
                                    if (list.required && !list.fieldValue) {
                                        isRequiredFieldUnanswered = true;
                                    }
                                }
                            }
                            if (shouldHide) {
                                let _question = _.find(parentList.list, (item) => {
                                    return item.field === _reference;
                                });
                                if (!_question.fieldValue) {
                                    if (list.required && !list.fieldValue) {
                                        isRequiredFieldUnanswered = true;
                                    }
                                }
                            }
                        }
                    }
                });
            }
        });
        if (this.additionalInfoUpdated && !isRequiredFieldUnanswered) {
            $scope.startProgress('add-supplier-modal');
            this.checkAndSaveParts(this.partsToUpdate).then(() => {
                this._getPreviewRequest(isRequiredFieldUnanswered);
            });
        } else {
            this._checkAndSend(isRequiredFieldUnanswered);
        }
    }

    _checkAndSend(isRequiredFieldUnanswered) {
        let { DynamicFormUtilService } = this._injected;
        let _parts = this.requestOffer.offerParts;
        DynamicFormUtilService.checkRequired(
            _parts,
            this.expandAdditionalQuestionsPart,
            isRequiredFieldUnanswered
        ).then((result) => {
            if (result) {
                this.showAgreementModal = this.showOfferAgreementModal();
                if (!this.showAgreementModal) {
                    this.addSuppliersAndThenSend();
                }
            }
        });
    }

    checkAndSaveParts(partsList) {
        let { $q } = this._injected;
        let mainDeferred = $q.defer();
        var loopPromises = [];
        angular.forEach(partsList, (part) => {
            var deferred = $q.defer();
            loopPromises.push(deferred.promise);
            if (part.list && BUSS_PART_CODES.indexOf(part.list[0].code) > -1) {
                let filledCommentList = part.list;
                const service = new google.maps.DistanceMatrixService();
                // build request
                const request = googleAddressUtil.prepareDistanceCalRequest(filledCommentList);
                service.getDistanceMatrix(request).then((response) => {
                    // put response
                    part.distance = googleAddressUtil.parseAddressesDistance(response);
                    this.saveFilledPartComments(part, deferred);
                });
            } else {
                this.saveFilledPartComments(part, deferred);
            }
        });
        $q.all(loopPromises).then(() => {
            mainDeferred.resolve();
        });
        return mainDeferred.promise;
    }

    saveFilledPartComments(part, deferred) {
        let { FilledRequestOfferPartComment } = this._injected;
        FilledRequestOfferPartComment.save(
            part.id,
            part.list,
            part.commentByCreator,
            part.distance,
            (resp) => {
                deferred.resolve();
            },
            function (e) {
                deferred.resolve(false);
            }
        );
    }

    _getPreviewRequest(isRequiredFieldUnanswered) {
        let { $scope, OfferPreview } = this._injected;
        let supplierMessage = this.requestOffer.supplierMessage;
        $scope.startProgress('add-supplier-modal');
        OfferPreview.getPreviewRequest(
            this.eventId,
            this.requestId,
            (response) => {
                this.event = response.event;
                this.customerInfo = response.customerInfo;
                this.request = response.request;
                this.requestOffer = response.requestOffer;
                if (supplierMessage) {
                    this.requestOffer.supplierMessage = supplierMessage;
                }
                $scope.endProgress('add-supplier-modal');
                // this.skipScroll = true;
                this.additionalInfoUpdated = false;
                this.partsToUpdate = [];
                this._checkAndSend(isRequiredFieldUnanswered);
                $scope.endProgress('add-supplier-modal');
                this.resizeModalBodyHeight();
            },
            $scope.endWithErrorOverlay('add-supplier-modal')
        );
    }

    requiredUnanswered() {
        let { DynamicFormUtilService } = this._injected;
        if (this.requestOffer) {
            let _parts = this.requestOffer.offerParts;
            return DynamicFormUtilService.requiredUnanswered(_parts);
        }
        return false;
    }

    addSuppliersAndThenSend() {
        const { OfferPreview, $scope, AuthenticationService, EventEnum, $rootScope, Request } = this._injected;
        if (!AuthenticationService.isUserVerified()) {
            this.showAccountVerificationMessage = true;
            return;
        }
        this.supplierListBusy = true;
        let removedSuppliers = this.getRemovedSupplierList();
        let params = {
            suppliers: this.selectedSuppliers,
            supplierMessage:
                this.requestOffer && this.requestOffer.supplierMessage ? this.requestOffer.supplierMessage : '',
            removeSuppliers: removedSuppliers,
        };

        let _newSupplierIds = this.selectedSuppliers ? this.selectedSuppliers.map((a) => a.id) : [];
        $scope.startProgress('add-supplier-modal');
        var vm = this;
        // this.removeSupplierFromEventFn().then(function() {
        OfferPreview.addSuppliersAndThenSend(
            vm.requestId,
            params,
            (response) => {
                $rootScope.$emit('event:user:action', {
                    action: 'sent.request.to.supplier',
                    data: { event: vm.event, request: vm.requestId, suppliers: _newSupplierIds },
                });
                vm.event = response.event;
                vm.customerInfo = response.customerInfo;
                vm.request = response.request;
                vm.requestOffer = response.requestOffer;
                vm.supplierListBusy = false;
                $scope.endProgress('add-supplier-modal');
                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                    requestId: vm.request.id,
                    offerId: vm.requestOffer.id,
                });

                vm.close(); //todo close for now , have to display success message
            },
            $scope.endWithErrorOverlay('add-supplier-modal')
        );
        // });
    }

    closeInfo() {
        this.displayGuidInfo = false;
    }

    selectToSendOffer(selectedSupplier) {
        selectedSupplier.selectToSend = !selectedSupplier.selectToSend;
        if (selectedSupplier.selectToSend) {
            this.suppliersToSend.push(selectedSupplier);
        } else {
            _.map(this.selectedSuppliers, function (supplier) {
                if (
                    supplier.id == selectedSupplier.id ||
                    //for suppliers added by email, there is no id, so comparison must be done by email
                    (UtilFunctions.isUndefinedOrNull(supplier.id) && supplier.email == selectedSupplier.email)
                ) {
                    supplier.selectToSend = false;
                }
                return supplier;
            });

            this.suppliersToSend = _.without(
                this.suppliersToSend,
                _.findWhere(this.suppliersToSend, {
                    id: selectedSupplier.id,
                })
            );

            this.suppliersToSend = _.without(
                this.suppliersToSend,
                _.findWhere(this.suppliersToSend, {
                    email: selectedSupplier.email,
                })
            );
        }
    }

    checkAndUpdateOfferPart(offerPart) {
        if (offerPart.list.length > 0 && BUSS_PART_CODES.indexOf(offerPart.list[0].code) > -1) {
            let filledCommentList = offerPart.list;
            const service = new google.maps.DistanceMatrixService();
            // build request
            const request = googleAddressUtil.prepareDistanceCalRequest(filledCommentList);

            service.getDistanceMatrix(request).then((response) => {
                // put response
                offerPart.distance = googleAddressUtil.parseAddressesDistance(response);
                this.updateOfferPart(offerPart);
            });
        } else {
            this.updateOfferPart(offerPart);
        }
    }

    updateOfferPart(offerPart) {
        //On update comments supplier messages should be stored
        let supplierMessage = this.requestOffer.supplierMessage;
        const { OfferPreview, $scope, EventEnum } = this._injected;
        $scope.startProgress('add-supplier-modal');
        OfferPreview.updateOfferPart(
            offerPart.id,
            offerPart,
            (response) => {
                this.event = response.event;
                this.customerInfo = response.customerInfo;
                this.request = response.request;
                this.requestOffer = response.requestOffer;
                if (supplierMessage) {
                    this.requestOffer.supplierMessage = supplierMessage;
                }
                $scope.endProgress('add-supplier-modal');
                $scope.$emit(EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS, {
                    requestId: this.request.id,
                    offerId: this.requestOffer.id,
                });
                this.collapseAdditionalQuestionsPart(offerPart.id);
                this._scrollToAdditionalQuestionsPart(this.requestOffer.offerParts);
            },
            $scope.endWithErrorOverlay('add-supplier-modal')
        );
    }

    addRequiredLabel(offerPart) {
        let listValues = offerPart.list;
        //Doesnot have additional questions
        if (listValues && listValues.length < 1) {
            return true;
        }
        angular.forEach(listValues, (question) => {
            if (question.required && !question.fieldValue) {
                question.requiredLabel = 'Required field';
            }
        });
    }

    collapseAdditionalQuestionsPart(index) {
        var parent = jQuery('#accordion' + index);
        var child = jQuery('#collapseOne' + index);

        if (child.is(':visible')) {
            // child.slideUp("normal");
            parent.attr('aria-expanded', 'false').addClass('collapsed');
            child.attr('aria-expanded', 'false').removeClass('in');
        }
    }

    toggleAdditionalQuestionsPart(id) {
        var parent = jQuery('#accordion' + id);
        var child = jQuery('#collapseOne' + id);

        if (child.is(':visible')) {
            parent.attr('aria-expanded', 'false').addClass('collapsed');
            child.attr('aria-expanded', 'false').removeClass('in');
        } else {
            parent.attr('aria-expanded', 'true').removeClass('collapsed');
            child.attr('aria-expanded', 'true').addClass('in');
        }
    }

    expandAdditionalQuestionsPart(partsToExpand) {
        angular.forEach(partsToExpand, (part) => {
            var parent = jQuery('#accordion' + part.id);
            var child = jQuery('#collapseOne' + part.id);

            if (!child.is(':visible')) {
                parent.attr('aria-expanded', 'true').removeClass('collapsed');
                child.attr('aria-expanded', 'true').addClass('in');
            }
            this.addRequiredLabel(part);
        });
        this._scrollToAdditionalQuestionsPart(partsToExpand);
    }

    _scrollToAdditionalQuestionsPart(_parts) {
        const { $timeout } = this._injected;
        let previewBody = jQuery('#select-supplier-body');
        previewBody.scrollTop(0);
        var _unansweredPart = _.find(_parts, (part) => {
            return part.hasAdditionalQuestionsUnanswered;
        });
        if (_unansweredPart) {
            $timeout(() => {
                let _elementId = 'accordion' + _unansweredPart.id;
                let _topHeight = document.getElementById(_elementId).getBoundingClientRect().top - 85;
                previewBody.animate({ scrollTop: _topHeight }, 'slow');
            }, 200);
        }
    }

    hasCommentOnPart(part) {
        let hasCommentOnOfferPart = false;
        if (!UtilFunctions.isUndefinedOrNull(part.commentByCreator)) {
            hasCommentOnOfferPart = true;
        }
        if (part.comments && part.comments[0].hasSupplierComment) {
            hasCommentOnOfferPart = true;
        }
        return hasCommentOnOfferPart;
    }

    getDynamicCommentIconClass(part) {
        if (part.defaultCommentByCreator) {
            return 'fa fa-commenting danger';
        }
        if (this.hasCommentOnPart(part)) {
            return 'fa fa-commenting commented';
        } else {
            return 'fa fa-comment-o';
        }
    }

    getRemovedSupplierList() {
        return _.filter(this.originalSelectedSuppliers, (item) => {
            let index = this.supplierIndexInList(this.selectedSuppliers, item);
            return index < 0;
        });
    }

    translate(name, code) {
        const { $translate } = this._injected;
        if (code) {
            let translatedText = $translate.instant(code);
            if (translatedText !== code) {
                return translatedText;
            }
        }
        return name;
    }

    getSupplierDetailOptions(supplierDetail) {
        supplierDetail.value = (supplierDetail.value || 0).toString();
        supplierDetail.options = {
            floor: 0,
            ceil: supplierDetail.maxValue,
            step: supplierDetail.step,
            // translate: (value) => {
            //     if (supplierDetail.unitDetails) {
            //         return supplierDetail.unitDetails.prefixed
            //             ? supplierDetail.unitDetails.unit + value
            //             : value + supplierDetail.unitDetails.unit;
            //     }
            //     return value;
            // },
        };
        return supplierDetail.options;
    }

    isAvailableCheck(eventId) {
        const { ProcessLog, SupplierAvailabilityCheck } = this._injected;
        ProcessLog.get(
            { referenceId: eventId, name: 'SUPPLIER_AVAILABILITY_CHECK' },
            (resp) => {
                this.apiAvailablilityCheckProcess = resp;
                this.apiAvailabililityloding = resp.status && resp.status.name === 'RUNNING';
                if (resp.status && resp.status.name === 'GOT_ERROR') {
                    let _error = angular.fromJson(resp.errorLog);
                    this.apiAvailablilityErrors = CommonFunctions.errorMessages(_error, true);
                }

                if (resp.status && resp.status.name === 'UP_TO_DATE') {
                    SupplierAvailabilityCheck.get(
                        { referenceId: eventId },
                        (resp) => {
                            this.processCheckResult(resp);
                        },
                        (err) => {
                            console.log(err);
                        }
                    );
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    processCheckResult(resp) {
        angular.forEach(resp, (a) => {
            let _isAvailable = a.isAvailable;
            if (UtilFunctions.isBooleanTrue(_isAvailable)) {
                let availableSupplier = a.supplierJson;
                let _availableSupplier = _.findWhere(this.suppliers, { id: availableSupplier.id });
                if (this.vmData.filterBy === 'featured') {
                    this.suppliers = _.without(this.suppliers, _availableSupplier);
                    availableSupplier.isAccommodationAvailable = true;
                    availableSupplier.singleRoomsCount = a.resultsJson.singleRoomsCount;
                    availableSupplier.doubleRoomsCount = a.resultsJson.doubleRoomsCount;
                    this.suppliers.unshift(availableSupplier);
                } else {
                    if (angular.isDefined(_availableSupplier)) {
                        _availableSupplier.isAccommodationAvailable = true;
                        _availableSupplier.singleRoomsCount = a.resultsJson.singleRoomsCount;
                        _availableSupplier.doubleRoomsCount = a.resultsJson.doubleRoomsCount;
                    }
                }
            }
        });
    }

    deleteProcessLog() {
        const { ProcessLog, $scope } = this._injected;
        $scope.startProgress('participant-lists');
        let processId = this.apiAvailablilityCheckProcess.id;
        ProcessLog['delete'](
            processId,
            function () {
                $scope.endProgress('participant-lists');
                this.apiAvailablilityCheckProcess = null;
                this.apiAvailabililityloding = null;
                this.apiAvailablilityErrors = null;
                this.isAvailableCheck(this.eventId);
            },
            $scope.endWithErrorOverlay('participant-lists')
        );
    }

    //todo(SC) need to check performance
    poll() {
        const { $timeout } = this._injected;
        if (this.apiAvailabililityloding) {
            this.isAvailableCheck(this.eventId);
        }
        $timeout(() => {
            this.poll();
        }, 1000);
    }

    getOfferPartAdditionalQuestionClass(resourceData) {
        if (resourceData && resourceData.nameCode) {
            switch (resourceData.nameCode) {
                case 'common.translate.offer.part.type.bus.(outward.journey)':
                case 'common.translate.offer.part.type.bus.(home.journey)':
                case 'common.translate.offer.part.type.bus.trip':
                    return 'bus-transport-form';
                    break;
                default:
                    break;
            }
        }
    }

    handleCheckboxChange(activity) {
        this.categoryToggleSelected = true;
        this.reInitCarousel = false;
        var categoryIds = [];
        let all_activity;
        this.refreshActivitiList = false;

        //select one category option at a time
        let selected = activity.selected;
        angular.forEach(this.activities, function (activity) {
            activity.selected = false;
        });
        activity.selected = selected;

        if (!(activity.nameCode !== 'common.translate.category.all.activities' || activity.selected)) {
            all_activity = _.findWhere(this.activities, { nameCode: 'common.translate.category.all.activities' });
            all_activity.selected = false;
        }

        //disable 'All Activities' upon selecting other options
        if (activity.nameCode !== 'common.translate.category.all.activities' && activity.selected) {
            all_activity = _.findWhere(this.activities, { nameCode: 'common.translate.category.all.activities' });
            all_activity.selected = false;
        }

        //disable Other options if 'All Activities' is selected
        if (activity.nameCode === 'common.translate.category.all.activities' && activity.selected) {
            angular.forEach(this.activities, function (activity) {
                activity.selected = activity.nameCode === 'common.translate.category.all.activities';
            });
        }

        //keep All activities button enabled if none of the other option is selected
        // if(activity.nameCode === "common.translate.category.activities" && !activity.selected ){
        //    let otherCategorySelected = false
        //    angular.forEach(this.activities, function (category) {
        //       if(!(category.nameCode === "common.translate.category.activities" || otherCategorySelected)){
        //          otherCategorySelected = !(category.selected === true);
        //       }
        //    });
        //    activity.selected =  otherCategorySelected
        //    disableSearch = otherCategorySelected;
        // }
        // if(!disableSearch){
        //    categoryIds = categoryIds.length > 0 ? categoryIds : this.categoryIds
        //    this.searchParams.filterCategoryWiseInSupplierModal = true
        //    this.searchParams.categoryIds = categoryIds
        //    this.searchSupplier()
        // }

        angular.forEach(this.activities, function (activity) {
            if (activity.selected) {
                categoryIds.push(activity.id);
            }
        });

        /* let _existingCategoryIds = angular.copy(this.categoryIds);
       let _categoryIdsWithoutActivity = _.filter(_existingCategoryIds, (categoryId) => { return !(_.findLastIndex(this.activities, { id: categoryId}) > -1)});
       let _categoryIdList = categoryIds.length > 0 ? _categoryIdsWithoutActivity.concat(categoryIds) : this.categoryIds
       this.searchParams.filterCategoryWiseInSupplierModal = true
       this.searchParams.categoryIds = _categoryIdList
       this.searchSupplier()*/

        categoryIds = categoryIds.length > 0 ? categoryIds : this.categoryIds;
        this.searchParams.filterCategoryWiseInSupplierModal = true;
        this.searchParams.categoryIds = categoryIds;
        this.reInitCarousel = false;
        this.searchSupplier();
    }

    isConferenceRequest() {
        let CONFERENCE_REQUESTS = [
            'common.translate.request.conference.room',
            'common.translate.request.conference.hotel',
        ];
        return !!(this.request && CONFERENCE_REQUESTS.indexOf(this.request.nameCode) > -1);
    }

    showOfferAgreementModal() {
        let userData = {};
        const { AuthenticationService } = this._injected;
        userData = AuthenticationService.getUserData();
        let userGroupHasNonCommissionAgreement = userData.userGroup.isUserGroupNonCommissionAgreementPricesEnabled || false;
        if (!userGroupHasNonCommissionAgreement) {
            return false;
        }

        return this.selectedSuppliers.some((supplier) => {
            return supplier.hasHotellOrLokalerCategory && !supplier.alreadySent;
        });
    }

    closeAgreementModalFn() {
        this.showAgreementModal = false;
    }

    reInitCategoryTagCarousel() {
        // owl carousel
        var owl = $('#category-filter');
        owl.trigger('destroy.owl.carousel');
        owl.removeClass('owl-loaded owl-drag');
        owl.on('initialize.owl.carousel', function () { });
    }
}

SelectSupplierModalCtrl.$inject = [
    '$scope',
    '$element',
    'data',
    'AuthenticationService',
    'Event',
    'Supplier',
    'Request',
    'close',
    'OfferPreview',
    'RequestOffer',
    'EventEnum',
    '$window',
    '$timeout',
    '$translate',
    'DateService',
    '$q',
    '$rootScope',
    'SupplierDetailService',
    'ogScrollService',
    'FilledRequestOfferPartComment',
    'DynamicFormUtilService',
    'ProcessLog',
    'SupplierAvailabilityCheck',
    '$location',
    'GlobalOption',
    'ClientService',
    'SupplierDetailInfo',
    'LocationService',
    'CommonFile',
    'Category',
];
export default SelectSupplierModalCtrl;
