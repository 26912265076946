const ALREADY_FINISHED_STATUS_FOR_CREATOR = ['FINISHED_WAITING_FOR_CREATOR'];
const CANCELED_OFFER_STATUSES = [
    'CANCELED_AND_FINISHED',
    'CANCELED_WAITING_FOR_CREATOR',
    'CANCELED_WAITING_FOR_SUPPLIER',
];
import PriceCalculationUtil from '../../utils/price_calculate/PriceCalculationUtil';
import CurrencyUtil from '../CurrencyUtil';
import CommonFunctions from '../../utils/CommonFunctionUtil'
import EventDetailsData from '../../core/existingServices/data_store/EventDetailsData';
import {UtilFunctions} from "../global/utilFunctions";
import _ from 'underscore';

class PriceCalculationCreatorUtil extends PriceCalculationUtil {
    constructor() {
        super();
        this.getAmount = this.getAmount.bind(this);
        this.getDifference = this.getDifference.bind(this);
        this.getPrice = this.getPrice.bind(this);
        this.getTooltipPrice = this.getTooltipPrice.bind(this);
        this.getTotalPrice = this.getTotalPrice.bind(this);
        this.getRequestOfferPartOptionAmount = this.getRequestOfferPartOptionAmount.bind(this);
        this.getRequestOfferPartOptionPrice = this.getRequestOfferPartOptionPrice.bind(this);
        this._getRequestPartOptionTotalPriceWithoutVat = this._getRequestPartOptionTotalPriceWithoutVat.bind(this);
        this._getRequestPartOptionTotalPriceWithVat = this._getRequestPartOptionTotalPriceWithVat.bind(this);
        this._getRequestOfferPartOptionPriceWithVat = this._getRequestOfferPartOptionPriceWithVat.bind(this);
        this.getRequestPartOptionTotalPrice = this.getRequestPartOptionTotalPrice.bind(this);
        this.getOfferTotalPrice = this.getOfferTotalPrice.bind(this);
        this.offerAlreadyFinished = this.offerAlreadyFinished.bind(this);
        this.getCustomerPrice = this.getCustomerPrice.bind(this);
        this.getRequestPartOptionDifference = this.getRequestPartOptionDifference.bind(this);
        this.getMarkupFromPriceAndCustomerPrice = this.getMarkupFromPriceAndCustomerPrice.bind(this);
        this.getMarkupFromPriceAndCustomerPriceWithCurrency =
            this.getMarkupFromPriceAndCustomerPriceWithCurrency.bind(this);
        this.getCustomerPriceFromMarkup = this.getCustomerPriceFromMarkup.bind(this);
        this.getMarkupFromCustomerPrice = this.getMarkupFromCustomerPrice.bind(this);
        this.getOptionCustomerPrice = this.getOptionCustomerPrice.bind(this);
        this.getOptionMarkupFromCustomerPrice = this.getOptionMarkupFromCustomerPrice.bind(this);
        this.getOptionCustomerPriceFromMarkup = this.getOptionCustomerPriceFromMarkup.bind(this);
        this.getPaidOnsitePrice = this.getPaidOnsitePrice.bind(this);
        this.getTotalPaidOnsitePriceWithoutVAT = this.getTotalPaidOnsitePriceWithoutVAT.bind(this);
        this.getTotalPaidOnsitePriceWithVAT = this.getTotalPaidOnsitePriceWithVAT.bind(this);
        this.getOutstandingAmount = this.getOutstandingAmount.bind(this);
        this._getOfferTotalPriceWithVAT = this._getOfferTotalPriceWithVAT.bind(this);
        this._getOfferTotalPriceWithoutVAT = this._getOfferTotalPriceWithoutVAT.bind(this);
        this._getRequestPartOptionTotalPriceWithVat = this._getRequestPartOptionTotalPriceWithVat.bind(this);
        this._getRequestPartOptionTotalPriceWithoutVat = this._getRequestPartOptionTotalPriceWithoutVat.bind(this);
    }

    shouldIncludeVatOnPrice() {
        return EventDetailsData.hasVatEnabled();
    }

    offerAlreadyFinished(offer) {
        if (!offer) {
            return false;
        }
        if (!offer.status) {
            return false;
        }
        return (
            CommonFunctions.hasIntersectValue([offer.status.name], ALREADY_FINISHED_STATUS_FOR_CREATOR) ||
            CommonFunctions.hasIntersectValue([offer.status.name], CANCELED_OFFER_STATUSES)
        );
    }

    getAmount(offer, part, originalAmount = false) {
        if (this.offerAlreadyFinished(offer) && !originalAmount) {
            return !UtilFunctions.isUndefinedOrNull(part.finalAmount) && part.finalAmount >= 0
                ? part.finalAmount
                : part.amount;
        } else {
            return part.amount;
        }
    }

    _getDifferenceWithoutVat(offer, part) {
        let _totalPriceWithoutVat = this._getTotalPriceWithoutVat(offer, part);
        let _originalPriceWithoutVat = this._getOriginalTotalPrice(part);
        var _difference = _totalPriceWithoutVat - _originalPriceWithoutVat;
        if (isNaN(_difference)) {
            _difference = 0;
        }
        return this.round(_difference);
    }

    _getRequestPartOptionDifferenceWithoutVat(offer, partOption) {
        let _totalPriceWithoutVat = this._getRequestPartOptionTotalPriceWithoutVat(offer, partOption);
        let _originalPriceWithoutVat = this._getOriginalRequestOfferPartOptionTotalPrice(partOption);
        var _difference = _totalPriceWithoutVat - _originalPriceWithoutVat;
        if (isNaN(_difference)) {
            _difference = 0;
        }
        return this.round(_difference);
    }

    _getDifferenceWithVat(offer, part) {
        let _totalPriceWithVat = this._getTotalPriceWithVAT(offer, part);
        let _originalPriceWithVat = this._getOriginalTotalPriceWithVat(part);
        var _difference = _totalPriceWithVat - _originalPriceWithVat;
        if (isNaN(_difference)) {
            _difference = 0;
        }
        return this.round(_difference);
    }

    _getRequestPartOptionDifferenceWithVat(offer, partOption) {
        let _totalPriceWithVat = this._getRequestPartOptionTotalPriceWithVat(offer, partOption);
        let _originalPriceWithVat = this._getOriginalRequestOfferPartOptionTotalPriceWithVat(partOption);
        var _difference = _totalPriceWithVat - _originalPriceWithVat;
        if (isNaN(_difference)) {
            _difference = 0;
        }
        return this.round(_difference);
    }

    getDifference(offer, part) {
        if (this.shouldIncludeVatOnPrice()) {
            return this._getDifferenceWithVat(offer, part);
        } else {
            return this._getDifferenceWithoutVat(offer, part);
        }
    }

    getRequestPartOptionDifference(offer, partOption) {
        if (this.shouldIncludeVatOnPrice()) {
            return this._getRequestPartOptionDifferenceWithVat(offer, partOption);
        } else {
            return this._getRequestPartOptionDifferenceWithoutVat(offer, partOption);
        }
    }

    _getPriceWithoutVat(offer, part, originalPrice = false) {
        if (this.offerAlreadyFinished(offer) && !originalPrice) {
            return !UtilFunctions.isUndefinedOrNull(part.finalPrice) && part.finalPrice >= 0
                ? part.finalPrice
                : this._getPriceFromPart(part);
        } else {
            return this._getPriceFromPart(part);
        }
    }

    _getPriceWithVat(offer, part, originalPrice = false) {
        let _price = this._getPriceWithoutVat(offer, part, originalPrice);
        let _vat = 0;
        if (part.vat > 0) {
            _vat = (_price * part.vat) / 100;
        }
        _price = parseFloat(_price) + parseFloat(_vat);
        if (isNaN(_price)) {
            _price = 0;
        }
        return this.round(_price);
    }

    getPrice(offer, part) {
        if (this.shouldIncludeVatOnPrice()) {
            return this._getPriceWithVat(offer, part);
        } else {
            return this._getPriceWithoutVat(offer, part);
        }
    }

    getTooltipPrice(offer, part, price) {
        if (this.shouldIncludeVatOnPrice()) {
            let _vat = 0;
            if (part.vat > 0) {
                _vat = (price * part.vat) / 100;
            }
            price = parseFloat(price) + parseFloat(_vat);
            if (isNaN(price)) {
                price = 0;
            }
            return this.round(price);
        } else {
            if (isNaN(price)) {
                price = 0;
            }
            return price;
        }
    }

    getRequestOfferPartOptionPrice(offer, partOption) {
        if (this.shouldIncludeVatOnPrice()) {
            return this._getRequestOfferPartOptionPriceWithVat(offer, partOption);
        } else {
            return this._getRequestOfferPartOptionPriceWithoutVat(offer, partOption);
        }
    }

    getAmountLocalisedWithCurrency(price, currency, digitsAfterDecimal = 0) {
        return CurrencyUtil.getAmountLocalisedWithCurrency(price, currency, digitsAfterDecimal);
    }

    viewPrice(price, currency, digitsAfterDecimal = 0) {
        return CurrencyUtil.viewPrice(price, currency, digitsAfterDecimal);
    }

    getOldPrice(offer, part) {
        let _withoutVat = this.getOldPriceValue(offer, part);
        let _oldPrice = _withoutVat;
        if (this.shouldIncludeVatOnPrice()) {
            let _vat = 0;
            let _oldVatPercent = part.vat;
            if (_oldVatPercent > 0) {
                _vat = (_withoutVat * _oldVatPercent) / 100;
            }
            _oldPrice = _oldPrice + _vat;
        }
        return this.round(_oldPrice);
    }

    _getTotalPriceWithoutVat(offer, part, originalPrice = false) {
        let _amount = this.getAmount(offer, part, originalPrice);
        let _price = this._getPriceWithoutVat(offer, part);
        return this._calculateTotalPrice(_amount, _price);
    }

    _getTotalPriceWithVAT(offer, part, originalPrice = false) {
        let _amount = this.getAmount(offer, part, originalPrice);
        let _price = this._getPriceWithVat(offer, part, originalPrice);
        return this._calculateTotalPrice(_amount, _price);
    }

    getTotalPrice(offer, part, originalPrice = false) {
        if (this.shouldIncludeVatOnPrice()) {
            return this._getTotalPriceWithVAT(offer, part, originalPrice);
        } else {
            return this._getTotalPriceWithoutVat(offer, part, originalPrice);
        }
    }

    getRequestPartOptionTotalPrice(offer, partOption, originalPrice = false) {
        if (this.shouldIncludeVatOnPrice()) {
            return this._getRequestPartOptionTotalPriceWithVat(offer, partOption, originalPrice);
        } else {
            return this._getRequestPartOptionTotalPriceWithoutVat(offer, partOption, originalPrice);
        }
    }

    _getRequestPartOptionTotalPriceWithVat(offer, partOption, originalPrice = false) {
        let _amount = this.getRequestOfferPartOptionAmount(offer, partOption, originalPrice);
        let _totalPrice = this._getRequestOfferPartOptionPriceWithVat(offer, partOption, originalPrice) * _amount;
        if (isNaN(_totalPrice)) {
            _totalPrice = 0;
        }
        return this.round(_totalPrice);
    }

    _getRequestPartOptionTotalPriceWithoutVat(offer, partOption, originalPrice = false) {
        let _amount = this.getRequestOfferPartOptionAmount(offer, partOption, originalPrice);
        let _price = this._getRequestOfferPartOptionPriceWithoutVat(offer, partOption);
        return this._calculateTotalPrice(_amount, _price);
    }

    getRequestOfferPartOptionAmount(offer, partOption, originalAmount = false) {
        if (this.offerAlreadyFinished(offer) && !originalAmount) {
            return !UtilFunctions.isUndefinedOrNull(partOption.finalAmount) && partOption.finalAmount >= 0
                ? partOption.finalAmount
                : partOption.amount;
        } else {
            return partOption.amount;
        }
    }

    _getRequestOfferPartOptionPriceWithVat(offer, partOption, originalPrice = false) {
        let _price = this._getRequestOfferPartOptionPriceWithoutVat(offer, partOption, originalPrice);
        let _vat = 0;
        if (partOption && partOption.vat > 0) {
            _vat = (_price * partOption.vat) / 100;
        }
        _price = parseFloat(_price) + parseFloat(_vat);
        return this.round(_price);
    }

    _getRequestOfferPartOptionPriceWithoutVat(offer, partOption, originalPrice = false) {
        if (this.offerAlreadyFinished(offer) && !originalPrice) {
            return !UtilFunctions.isUndefinedOrNull(partOption.finalPrice) && partOption.finalPrice >= 0
                ? partOption.finalPrice
                : this._getPriceFromPartOption(partOption);
        } else {
            return this._getPriceFromPartOption(partOption);
        }
    }

    getCustomerPrice(offer, part, originalPrice = false) {
        if (this.shouldIncludeVatOnPrice()) {
            let _priceWithVat = this._getTotalPriceWithVAT(offer, part, originalPrice);
            let markupValue = part.markupValue ? part.markupValue : part.markupValue;
            let _customerPrice = _priceWithVat + (_priceWithVat * markupValue) / 100;
            return this.round(_customerPrice);
        } else {
            let _priceWithoutVat = this._getTotalPriceWithoutVat(offer, part, originalPrice);
            let markupValue = part.markupValue ? part.markupValue : part.markupValue;
            let _customerPrice = _priceWithoutVat + (_priceWithoutVat * markupValue) / 100;
            return this.round(_customerPrice);
        }
    }

    getOptionCustomerPrice(offer, partOption, originalPrice = false) {
        if (this.shouldIncludeVatOnPrice()) {
            let _priceWithVat = this._getRequestPartOptionTotalPriceWithVat(offer, partOption, originalPrice);
            let markupValue = partOption.markupValue ? partOption.markupValue : partOption.markupValue;
            let _customerPrice = _priceWithVat + (_priceWithVat * markupValue) / 100;
            return this.round(_customerPrice);
        } else {
            let _priceWithoutVat = this._getRequestPartOptionTotalPriceWithoutVat(offer, partOption, originalPrice);
            let markupValue = partOption.markupValue ? partOption.markupValue : partOption.markupValue;
            let _customerPrice = _priceWithoutVat + (_priceWithoutVat * markupValue) / 100;
            return this.round(_customerPrice);
        }
    }

    getMarkupFromPriceAndCustomerPrice(price, customerPrice) {
        let markup = ((customerPrice - price) * 100) / price;
        markup = this.round(markup).toFixed(0);
        return markup;
    }

    getMarkupFromPriceAndCustomerPriceWithCurrency(price, customerPrice, currency, digitsAfterDecimal = 0) {
        let _markupInPrice = customerPrice - price;
        _markupInPrice = CurrencyUtil.getAmountLocalisedWithCurrency(_markupInPrice, currency, digitsAfterDecimal);
        return _markupInPrice;
    }

    getMarkupFromCustomerPrice(part, customerPrice) {
        let _markup;
        let _offer = part.offer;
        let _amount = this.getAmount(_offer, part, false);
        if (this.shouldIncludeVatOnPrice()) {
            let _price = this._getPriceWithVat(_offer, part, false);
            _markup = ((customerPrice / _amount - _price) * 100) / _price;
        } else {
            let _price = this._getPriceWithoutVat(_offer, part, false);
            _markup = ((customerPrice / _amount - _price) * 100) / _price;
        }
        _markup = parseFloat(_markup);
        return _markup;
    }

    getOptionMarkupFromCustomerPrice(offer, partOption, customerPrice) {
        let _markup;
        let _amount = this.getRequestOfferPartOptionAmount(offer, partOption, false);
        if (this.shouldIncludeVatOnPrice()) {
            let _price = this._getRequestOfferPartOptionPriceWithVat(offer, partOption, false);
            _markup = ((customerPrice / _amount - _price) * 100) / _price;
        } else {
            let _price = this._getRequestOfferPartOptionPriceWithoutVat(offer, partOption, false);
            _markup = ((customerPrice / _amount - _price) * 100) / _price;
        }
        _markup = parseFloat(_markup);
        return _markup;
    }

    getCustomerPriceFromMarkup(part, markupValue) {
        let _totalCustomerPrice, _customerPrice;
        let _offer = part.offer;
        let _amount = this.getAmount(_offer, part, false);
        if (this.shouldIncludeVatOnPrice()) {
            let _price = this._getPriceWithVat(_offer, part, false);
            _customerPrice = _price + (_price * markupValue) / 100;
            _totalCustomerPrice = _amount * _customerPrice;
            if (isNaN(_totalCustomerPrice)) {
                _totalCustomerPrice = 0;
            }
        } else {
            let _price = this._getPriceWithoutVat(_offer, part, false);
            _customerPrice = _price + (_price * markupValue) / 100;
            _totalCustomerPrice = _amount * _customerPrice;
            if (isNaN(_totalCustomerPrice)) {
                _totalCustomerPrice = 0;
            }
        }
        return _totalCustomerPrice;
    }

    getOptionCustomerPriceFromMarkup(offer, partOption, markupValue) {
        let _totalCustomerPrice, _customerPrice;
        let _amount = this.getRequestOfferPartOptionAmount(offer, partOption, false);
        if (this.shouldIncludeVatOnPrice()) {
            let _price = this._getRequestOfferPartOptionPriceWithVat(offer, partOption, false);
            _customerPrice = _price + (_price * markupValue) / 100;
            _totalCustomerPrice = _amount * _customerPrice;
            if (isNaN(_totalCustomerPrice)) {
                _totalCustomerPrice = 0;
            }
        } else {
            let _price = this._getRequestOfferPartOptionPriceWithoutVat(offer, partOption, false);
            _customerPrice = _price + (_price * markupValue) / 100;
            _totalCustomerPrice = _amount * _customerPrice;
            if (isNaN(_totalCustomerPrice)) {
                _totalCustomerPrice = 0;
            }
        }
        return _totalCustomerPrice;
    }

    _getOfferTotalPriceWithoutVat(offer, partsInOffer) {
        var totalPrice = 0;
        let deliverableParts = this._getDeliverableParts(partsInOffer);
        deliverableParts = _.filter(deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        for (let part of deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    totalPrice += parseFloat(
                        this._getRequestPartOptionTotalPriceWithoutVat(offer, _selectedRequestOfferPartOption)
                    );
                });
            } else {
                totalPrice += parseFloat(this._getTotalPriceWithoutVat(offer, part));
            }
        }
        return this.round(totalPrice);
    }

    _getCanceledAmountWithoutVat(offer, partsInOffer) {
        var totalPrice = 0;
        let deliverableParts = this._getDeliverableParts(partsInOffer);
        deliverableParts = _.filter(deliverableParts, function (part) {
            return part && part.status.name === 'CANCELED';
        });
        for (let part of deliverableParts) {
            totalPrice += parseFloat(this._getTotalPriceWithoutVat(offer, part));
        }
        return this.round(totalPrice);
    }

    _getOfferTotalPriceWithVAT(offer, partsInOffer) {
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        let _totalPrice = 0;
        for (let part of _deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    _totalPrice += parseFloat(
                        this._getRequestPartOptionTotalPriceWithVat(offer, _selectedRequestOfferPartOption)
                    );
                });
            } else {
                _totalPrice += parseFloat(this._getTotalPriceWithVAT(offer, part));
            }
        }
        return this.round(_totalPrice);
    }

    _getOfferTotalPriceWithoutVAT(offer, partsInOffer) {
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        let _totalPrice = 0;
        for (let part of _deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    _totalPrice += parseFloat(
                        this._getRequestPartOptionTotalPriceWithVat(offer, _selectedRequestOfferPartOption)
                    );
                });
            } else {
                _totalPrice += parseFloat(this._getTotalPriceWithoutVat(offer, part));
            }
        }
        return this.round(_totalPrice);
    }

    _getCanceledAmountWithVAT(offer, partsInOffer) {
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name === 'CANCELED';
        });
        let _totalPrice = 0;
        for (let part of _deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    _totalPrice += parseFloat(
                        this._getRequestPartOptionTotalPriceWithVat(offer, _selectedRequestOfferPartOption)
                    );
                });
            } else {
                _totalPrice += parseFloat(this._getTotalPriceWithVAT(offer, part));
            }
        }
        return this.round(_totalPrice);
    }

    getOfferTotalPrice(offer, parts) {
        if (this.shouldIncludeVatOnPrice()) {
            return this._getOfferTotalPriceWithVAT(offer, parts);
        } else {
            return this._getOfferTotalPriceWithoutVat(offer, parts);
        }
    }

    getOutstandingAmount(offerTotalPrice, totalPaidOnsitePrice) {
        let outstandingAmount = 0;
        outstandingAmount = offerTotalPrice - totalPaidOnsitePrice;
        return this.round(outstandingAmount);
    }

    getOfferCanceledAmount(offer, parts, includeVat) {
        if (includeVat) {
            return this._getCanceledAmountWithVAT(offer, parts);
        } else {
            return this._getCanceledAmountWithoutVat(offer, parts);
        }
    }

    getOfferOriginalPrice(parts) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(parts);
        for (let part of _deliverableParts) {
            let _withoutVat = 0, _withoutVatOfferPartOption = 0, _vat = 0;
            if (!part.hasRequestOfferPartOptions) {
                _withoutVat = this._getOriginalTotalPrice(part);
                if (this.shouldIncludeVatOnPrice()) {
                    if (part.vat > 0) {
                        _vat = (_withoutVat * part.vat) / 100;
                    }
                }
            } else {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    let _price = this._getOriginalRequestOfferPartOptionTotalPrice(_selectedRequestOfferPartOption);
                    let _vat = 0;
                    if (this.shouldIncludeVatOnPrice()) {
                        if (_selectedRequestOfferPartOption.vat > 0) {
                            _vat = (_price * _selectedRequestOfferPartOption.vat) / 100;
                        }
                        _withoutVatOfferPartOption += _price + _vat;
                    } else {
                        _withoutVatOfferPartOption += _price + _vat;
                    }
                });
            }
            _totalPrice += _withoutVat + _vat + _withoutVatOfferPartOption;
        }
        return this.round(_totalPrice);
    }

    hasUpdatedFinalPriceOrAmount(offer, parts) {
        if (!offer) {
            return false;
        }

        if (!this.offerAlreadyFinished(offer)) {
            return false;
        }

        for (let part of parts) {
            if (this.getDifference(offer, part)) {
                return true;
            }
        }

        return false;
    }

    getPaidOnsitePrice(offer, offerParts) {
        if (this.shouldIncludeVatOnPrice()) {
            return this.getTotalPaidOnsitePriceWithVAT(offer, offerParts);
        } else {
            return this.getTotalPaidOnsitePriceWithoutVAT(offer, offerParts);
        }
    }

    getTotalPaidOnsitePriceWithVAT(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.finalPaidOnsite) {
                _totalPrice += parseFloat(this._getTotalPriceWithVAT(offer, part));
            }
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    if (_selectedRequestOfferPartOption.finalPaidOnsite) {
                        _totalPrice += parseFloat(
                            this._getRequestPartOptionTotalPriceWithVat(offer, _selectedRequestOfferPartOption)
                        );
                    }
                });
            }
        }
        return this.round(_totalPrice);
    }

    getTotalPaidOnsitePriceWithoutVAT(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.finalPaidOnsite) {
                _totalPrice += parseFloat(this._getTotalPriceWithoutVat(offer, part));
            }
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    if (_selectedRequestOfferPartOption.finalPaidOnsite) {
                        _totalPrice += parseFloat(
                            this._getRequestPartOptionTotalPriceWithoutVat(offer, _selectedRequestOfferPartOption)
                        );
                    }
                });
            }
        }
        return this.round(_totalPrice);
    }
}

const calculator = new PriceCalculationCreatorUtil();
export default calculator;
