import { ScopeFunctions } from 'src/app/utils/global/scopeFunctions'
import { OgModal } from "../../../utils/global/ogModal";
import _ from 'underscore';

(function () {
    'use strict';

    angular.module('activity.stream').component('ogActivityStream', {
        templateUrl: './app/components/directives/activity/og.activity.stream.view.html',
        bindings: {
            isEditable: '=ogEditable',
            isSupplier: '=ogForSupplier',
            offerId: '=',
            searchParams: '=params',
            labelCode: '@',
            // tell the position for the file attachment.
            fileSelectToolTipPlacement: '=',
        },
        controller: [
            '$scope',
            '$log',
            '$rootScope',
            '$stateParams',
            '$state',
            '$location',
            'Activity',
            'Task',
            'AuthenticationService',
            '$timeout',
            'EventEnum',
            'RequestOffer',
            'ChatMessage',
            'ChatMessageCountStore',
            'ogScrollService',
            'CommonFunctions',
            function ($scope,
                $log,
                $rootScope,
                $stateParams,
                $state,
                $location,
                Activity,
                Task,
                AuthenticationService,
                $timeout,
                EventEnum,
                RequestOffer,
                ChatMessage,
                ChatMessageCountStore,
                ogScrollService,
                CommonFunctions
            ) {
                console.log('ASDFASDFASDF', this.timestamp);
                var vm = this;

                vm.backToMessageList = backToMessageListFn;
                vm.clearAttachedFiles = clearAttachedFilesFn;
                vm.createMessage = createMessageFn;
                vm.errorCallback = errorCallbackFn;
                vm.loadNextPage = loadNextPageFn;
                vm.pageChanged = pageChangedFn;
                vm.paginatedActivityStreamCallback = paginatedActivityStreamCallbackFn;
                vm.setPaginationParams = setPaginationParamsFn;
                vm.sendMessageCallback = sendMessageCallbackFn;
                vm.sendMessage = sendMessageFn;
                vm.updateLoggedUserStatus = updateLoggedUserStatusFn;
                vm.visibleToSupplier = visibleToSupplierFn;
                vm.showMessageDetails = showMessageDetailsFn;
                vm.hideMessageDetails = hideMessageDetailsFn;
                vm.showComposeMessage = showComposeMessageFn;
                vm.hideComposeMessage = hideComposeMessageFn;
                vm.resetSuccessMessage = resetSuccessMessageFn;
                vm.resetReplySuccessMessage = resetReplySuccessMessageFn;
                vm.cancelMessage = cancelMessageFn;
                vm.cancelReplyMessage = cancelReplyMessageFn;
                vm.stripHtmlTags = stripHtmlTagsFn;
                vm.isOfferInCanceledOrDeclinedState = isOfferInCanceledOrDeclinedStateFn;

                ScopeFunctions.addToScope($scope);
                vm.activityFilterArray = [
                    { id: 0, tag: 'all' },
                    { id: 1, tag: 'message' },
                    { id: 2, tag: 'messageWithFiles' },
                ];

                vm.activityFilter = vm.activityFilterArray[1].tag;
                vm.streamLoaded = false;
                vm.showMyTasks = false;
                vm.displayReplySection = false;
                vm.showComposeMessageSection = false;
                vm.supplierMessageForm = {};
                vm.numPerPage = 6;
                vm.currentPage = 1;
                vm.eventOwners = [];
                vm.eventOwnerId = 'ALL_CREATORS';
                vm.eventOwner = 'ALL_CREATORS';
                vm.toAllEventCreators = true;
                vm.isReplyMessage = false;
                vm.privateMessage = false;
                vm.stateMessageId = $stateParams.msgId;
                vm.maxMsgLength = 4000;
                vm.showMsgLimitError = false;
                ScopeFunctions.addToScope($scope);

                resetActivitiesListFn();
                initFn();

                function showComposeMessageFn() {
                    vm.showComposeMessageSection = true;
                }

                function hideComposeMessageFn() {
                    vm.showComposeMessageSection = false;
                    resetSuccessMessageFn();
                    resetReplySuccessMessageFn();
                }

                function backToMessageListFn() {
                    vm.showComposeMessageSection = false;
                    hideMessageDetailsFn();
                    getPaginatedActivitiesFn(true);
                }

                function cancelMessageFn() {
                    vm.showComposeMessageSection = false;
                    vm.messageBody = '';
                    clearAttachedFilesFn();
                }

                function cancelReplyMessageFn() {
                    vm.displayReplySection = false;
                    vm.messageReplyBody = '';
                    clearAttachedFilesFn();
                }

                function clearAttachedFilesFn() {
                    vm.attachedFiles = { uploads: null, links: null };
                }

                function resetSuccessMessageFn() {
                    if ($scope.successMessage) $scope.successMessage = false;

                    vm.showMsgLimitError = false;
                }

                function resetReplySuccessMessageFn() {
                    if ($scope.replySuccessMessage) $scope.replySuccessMessage = false;

                    vm.showMsgLimitError = false;
                }

                function createMessageFn(message, messageId) {
                    if (vm.loggedUserStatus != 'VERIFIED') {
                        OgModal.open('alert', { message: 'dialog.user.not.activated' });
                        return;
                    }

                    var messageBody = vm.messageBody;
                    if (message && message.isReplyEnabled) {
                        messageBody = vm.messageReplyBody;
                        vm.parentMessageId = messageId;
                        vm.privateMessage = message.privateMessage; //if parent is private child also should be private
                        vm.replyToUserId = message.author.id;
                        vm.isReplyMessage = true;
                    }
                    if (messageBody.length > vm.maxMsgLength) {
                        vm.showMsgLimitError = true;
                        return;
                    }
                    vm.showMsgLimitError = false;
                    if (tinyMCE && false) {
                        messageBody = tinyMCE.get('messageBody').getContent();
                    }
                    if (vm.isTask === true) {
                        vm.showTaskDialogCreate(messageBody);
                    } else {
                        let plainMessage = htmlToPlaintext(messageBody);
                        if (plainMessage && plainMessage.length > 0 && plainMessage.charCodeAt(0) !== 8203) {
                            sendMessageFn(messageBody);
                        }
                    }
                }

                function htmlToPlaintext(text) {
                    let plainText = text ? String(text).replace(/<[^>]+>/gm, '') : '';
                    return plainText.trim();
                }

                function errorCallbackFn(error) {
                    vm.error = error;
                    vm.streamSpinner = false;
                    vm.messageSpinner = false;
                }

                function isOfferInCanceledOrDeclinedStateFn() {
                    if (vm.offer) {
                        return vm.offer.status.name === 'DECLINED' || vm.offer.status.name === 'CANCELED';
                    }
                    return false;
                }

                function initFn() {
                    vm.clearAttachedFiles();
                    vm.messageSpinner = false;
                    vm.messageBodyEmpty = false;
                    vm.clearAll = false; //clear file upload model

                    vm.requestId = vm.requestId || $stateParams.requestId;
                    vm.offerId = vm.offerId || $stateParams.offerId;
                    vm.eventId = vm.eventId || $stateParams.eventId;
                    vm.offers = vm.offers || [];
                    if (!vm.eventId && vm.event && (vm.requestId || vm.offerId)) {
                        vm.eventId = vm.event.id;
                    }

                    eventId = vm.eventId;
                    requestId = vm.requestId;
                    offerId = vm.offerId;

                    if (!$stateParams.eventId) {
                        vm.showEventLink = true;
                    }

                    if (offerId) {
                        RequestOffer.getUsers(
                            { offerId: offerId },
                            (response) => {
                                vm.eventOwners = response;
                            },
                            vm.errorCallback
                        );
                        RequestOffer.get(
                            { offerId: offerId },
                            (response) => {
                                vm.offer = response;
                            },
                            vm.errorCallback
                        );
                    }
                    updateLoggedUserStatusFn();
                    vm.openComposeMessageBox = $stateParams.openComposeMessageBox === 'true';
                    if (vm.openComposeMessageBox) {
                        showComposeMessageFn();
                    }
                }

                function getPaginatedActivitiesFn() {
                    vm.activityFilter =
                        vm.searchParams && vm.searchParams.type ? vm.searchParams.type : vm.activityFilter;
                    vm.numPerPage = vm.searchParams && vm.searchParams.numPerPage ? vm.searchParams.numPerPage : 6;
                    var params = {
                        eventId: vm.eventId,
                        showMyTasks: vm.showMyTasks,
                        page: vm.params.offset,
                        pageSize: vm.numPerPage,
                        privateMessage: vm.privateMessage,
                        type: vm.activityFilter,
                    };
                    if (vm.level === 'EVENT_USERS') {
                        params.eventOwnerId = vm.eventOwnerId;
                        params.toAllEventCreators = vm.toAllEventCreators;
                    }
                    params.requestId = vm.requestId;
                    params.offerId = offerId;
                    $scope.startProgress();
                    vm.loading = true;
                    Activity.list(params, vm.paginatedActivityStreamCallback, vm.errorCallback);
                }

                function showMessageDetailsFn(activityMessage) {
                    setChatMessages(activityMessage.id);
                    if (vm.chatMessageDetails) {
                        vm.showActivityMessageDetails = true;
                        vm.showActivityMessageId = activityMessage.id;
                        vm.eventOwnerId = activityMessage.author.id;
                        vm.isReplyMessage = activityMessage.isReplyEnabled;
                        $scope.successMessage = false;
                        vm.toAllEventCreators = false;
                        if (vm.showActivityMessageDetails && vm.showActivityMessageId == activityMessage.id) {
                            if (vm.chatMessageDetails.childMessages.length > 0) {
                                $timeout(function () {
                                    ogScrollService.scrollToElement('scroll-here');
                                }, 500);
                            } else {
                                $timeout(function () {
                                    ogScrollService.scrollToElement('supplier-reply-section-header');
                                }, 500);
                            }
                        }

                        updateMessageSeenStatusFn(activityMessage);
                    }
                }

                function hideMessageDetailsFn() {
                    vm.showActivityMessageDetails = false;
                    vm.showActivityMessageId = null;
                    vm.eventOwnerId = 'ALL_CREATORS';
                    vm.isReplyMessage = false;
                    vm.toAllEventCreators = true;
                    vm.stateMessageId = null;
                    vm.displayReplySection = false;
                    //getPaginatedActivitiesFn(true);
                }

                function setChatMessages(messageId) {
                    vm.chatMessageDetails = _.find(vm.activities, (o) => {
                        return o.id === messageId;
                    });
                    $rootScope.$broadcast(EventEnum.MESSAGE_THREAD_CHANGED, vm.chatMessageDetails.author);
                }

                function updateMessageSeenStatusFn(activityMessage) {
                    if (activityMessage.isThreadHasNewMessage) {
                        $scope.startProgress();
                        Activity.markAsRead(
                            activityMessage,
                            (resp) => {
                                if (vm.isSupplier) {
                                    ChatMessage.unReadMessageCountForSupplierRest(
                                        vm.offerId,
                                        function (resp) {
                                            ChatMessageCountStore.setSupplierUnreadMessageCount(resp.count);
                                        },
                                        $scope.endWithAlert()
                                    );
                                } else {
                                    ChatMessage.unReadMessageCountForCreatorRest(
                                        activityMessage.event.id,
                                        function (resp) {
                                            ChatMessageCountStore.setCreatorUnreadMessageCount(resp.count);
                                            ChatMessageCountStore.setCreatorHomePageUnreadMessageCount(
                                                resp.homePageCount
                                            );
                                        },
                                        $scope.endWithAlert()
                                    );
                                }
                                activityMessage.messageSeen = true;
                                $scope.endProgress();
                            },
                            $scope.endWithAlert()
                        );
                    }
                }

                function loadNextPageFn() {
                    if (vm.loading) {
                        return;
                    }
                    if (vm.totalActivitiesCount !== -1) {
                        if (vm.activities.length >= vm.totalActivitiesCount) {
                            return;
                        }
                    }
                    vm.loading = true;
                    getPaginatedActivitiesFn();
                }

                function pageChangedFn() {
                    vm.setPaginationParams();
                    vm.loadNextPage();
                }

                function paginatedActivityStreamCallbackFn(response) {
                    vm.activities = response.list;
                    vm.streamSpinner = false;
                    vm.messageSpinner = false;
                    vm.streamLoaded = true;
                    vm.loading = false;
                    if (vm.activities) {
                        $scope.endProgress();
                    }
                    vm.totalActivitiesCount = response.totalCount;
                    vm.page = vm.page + 1;
                    vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                    if (vm.showActivityMessageId) {
                        setChatMessages(vm.showActivityMessageId);
                    } else {
                        if (vm.stateMessageId) {
                            var selectedMessage = _.find(vm.activities, (act) => {
                                let _find = act.id + '' === vm.stateMessageId + '';
                                if (_find) {
                                    return _find;
                                } else {
                                    var childMessage = _.find(act.childMessages, (ch) => {
                                        return ch.id + '' === vm.stateMessageId + '';
                                    });
                                    return !!childMessage;
                                }
                            });
                            if (selectedMessage) {
                                vm.showMessageDetails(selectedMessage);
                            }
                        }
                    }
                }

                function setPaginationParamsFn() {
                    vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                    vm.params.max = vm.numPerPage;
                }

                function resetActivitiesListFn() {
                    resetPaginationParamsFn();
                    vm.loading = false;
                    vm.page = 0;
                    vm.totalActivitiesCount = -1; //default value
                    vm.activities = [];
                }

                function resetPaginationParamsFn() {
                    vm.params = { offset: 0, max: vm.numPerPage };
                }

                function sendMessageFn(messageBody) {
                    if (!vm.isReplyMessage && !vm.supplierMessageForm.newMessageForm.$valid) {
                        CommonFunctions.touchFormFields(vm.supplierMessageForm.newMessageForm);
                        return;
                    }
                    if (vm.attachedFiles.maxSizeExceeded === true) {
                        return;
                    }
                    if (messageBody && messageBody.length > 0) {
                        var compiledMessageBody = messageBody;

                        if (vm.attachedFiles.links !== null && vm.attachedFiles.links.length > 0) {
                            compiledMessageBody += '\n\n';
                            angular.forEach(vm.attachedFiles.links, function (link) {
                                compiledMessageBody +=
                                    '<a href="' +
                                    link.fileUrl +
                                    '" target="_blank"><i class="glyphicon glyphicon-paperclip"></i> ' +
                                    link.name +
                                    '</a>' +
                                    '\n';
                            });
                        }

                        vm.messageSpinner = true;

                        Activity.save(
                            {
                                eventId: eventId,
                                requestId: requestId,
                                offerId: offerId,
                                messageBody: compiledMessageBody,
                                eventOwnerId: vm.eventOwnerId,
                                toAllEventCreators: vm.toAllEventCreators,
                                privateMessage: vm.privateMessage,
                                isReplyMessage: vm.isReplyMessage,
                                parentMessageId: vm.parentMessageId,
                                attachedFiles: vm.attachedFiles.uploads,
                            },
                            vm.sendMessageCallback,
                            vm.errorCallback
                        );
                    }
                }

                function sendMessageCallbackFn() {
                    vm.messageSpinner = false;
                    if (vm.isReplyMessage) {
                        $scope.replySuccessMessage = true;
                        $scope.successMessage = false;
                        vm.displayReplySection = false;
                    } else {
                        $scope.replySuccessMessage = false;
                        $scope.successMessage = true;
                        vm.showComposeMessageSection = false;
                    }
                    vm.parentMessageId = null;
                    vm.eventOwner = 'ALL_CREATORS';
                    vm.messageBody = '';
                    vm.messageReplyBody = '';
                    if (tinyMCE && false) {
                        var editor = tinyMCE.get('messageBody');
                        if (editor) {
                            editor.setContent('');
                        }
                    }
                    vm.clearAttachedFiles();
                    resetActivitiesListFn();
                    getPaginatedActivitiesFn();
                    // vm.hideMessageDetails();
                    vm.clearAll = true;
                    vm.supplierMessageForm.newMessageForm.$setPristine();
                    vm.supplierMessageForm.newMessageForm.$setUntouched();
                }

                var eventId;
                var requestId;
                var offerId;

                function updateLoggedUserStatusFn() {
                    vm.loggedUserStatus = AuthenticationService.getLoggedUserStatus();
                }

                function visibleToSupplierFn() {
                    return vm.isSupplier || (vm.offer && vm.offer.supplier);
                }

                function stripHtmlTagsFn(str) {
                    if (str === null || str === '') {
                        return false;
                    } else {
                        str = str.toString();
                        var stripedhtml = str.replace(/(<([^>]+)>)/gi, '');
                        return htmlDecode(stripedhtml);
                    }
                }

                function htmlDecode(input) {
                    var e = document.createElement('div');
                    e.innerHTML = input;
                    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
                }

                $scope.$watch('offerId', function (a, b) {
                    if (vm.offerId) {
                        offerId = vm.offerId;
                    }
                    if (typeof a == 'undefined' && typeof b == 'undefined') {
                        return;
                    }

                    if (a === '') {
                        vm.level = 'EVENT';
                        vm.privateMessage = true;
                        vm.offer = null;
                    } else {
                        vm.level = 'OFFER';
                        vm.privateMessage = false;

                        angular.forEach(vm.offers, function (offer) {
                            if (offer.id == a) {
                                vm.offer = offer;
                            }
                        });
                    }
                    resetActivitiesListFn();
                    vm.loadNextPage();
                });

                $scope.$watch('activityStreamCtrl.eventOwner', function (a, b) {
                    if (typeof a == 'undefined' && typeof b == 'undefined') {
                        vm.level = undefined;
                        vm.eventOwnerId = undefined;
                        resetActivitiesListFn();
                        getPaginatedActivitiesFn();
                        return;
                    }

                    if (typeof a == 'undefined') {
                        vm.level = undefined;
                        vm.eventOwnerId = undefined;
                        resetActivitiesListFn();
                        getPaginatedActivitiesFn();
                        return;
                    }

                    if (a === '0') {
                        return;
                    }

                    if (!angular.isNumber(a) && a.indexOf('user_') === 0) {
                        vm.level = 'EVENT_USERS';
                        vm.eventOwnerId = a.split('_')[1];
                        vm.toAllEventCreators = false;
                        $scope.successMessage = false;
                    } else {
                        vm.level = 'OFFER';
                        vm.eventOwnerId = 'ALL_CREATORS';
                        vm.toAllEventCreators = true;
                    }
                    resetActivitiesListFn();
                    getPaginatedActivitiesFn();
                    vm.currentPage = 1;
                });

                $scope.$on('activityStreamCtrl.activityFilter/changed', function (event, value) {
                    angular.forEach(vm.activityFilterArray, function (item) {
                        if (item.tag == value) {
                            vm.activityFilter = value;
                            vm.isTask = value == 'task';
                            vm.hideMessageDetails();
                            resetActivitiesListFn();
                            vm.hideComposeMessage();
                            vm.loadNextPage();
                        }
                    });
                });

                $scope.$on('activityStreamCtrl.activityStream/changed', function () {
                    vm.hideMessageDetails();
                    resetActivitiesListFn();
                    vm.loadNextPage();
                });

                $scope.gotoMessage = function (eventId, messageId) {
                    if (!vm.isSupplier) {
                        $state.go('event.detail.message', { eventId: eventId, msgId: messageId });
                    }
                };

                $scope.$on(EventEnum.USER_DATA_CHANGED, vm.updateLoggedUserStatus);
                $scope.$on(EventEnum.USER_GROUP_CHANGED_SILENT, vm.updateLoggedUserStatus);
                $scope.$on(EventEnum.USER_GROUP_CHANGED, vm.updateLoggedUserStatus);
            },
        ],
    });
})();
