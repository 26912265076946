/**
 * Created by bijayTimalsina on 27/07/22.
 */
import alertModal from '../../modals/modals2.0/alert-modal';
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

class DesignForConfirmationEmailCtrl {
    constructor(
        $scope,
        FieldChangedSvc,
        $rootScope,
        DateService,
        TimezoneService,
        $sce,
        $stateParams,
        Event,
        FileService,
        $location,
        Presentation,
        EventSite,
        ModalService,
        $translate,
        GlobalOption,
        ClientService
    ) {
        this._injected = {
            $scope,
            FieldChangedSvc,
            $rootScope,
            DateService,
            TimezoneService,
            $sce,
            $stateParams,
            Event,
            FileService,
            $location,
            Presentation,
            EventSite,
            ModalService,
            $translate,
            GlobalOption,
            ClientService,
        };

        this.hash = $stateParams.hash;
        $scope.$watch('DesignForConfirmationEmailCtrl.invitationDetails', (data) => {
            if (data) {
                this.eventId = data.eventId;
                // this.getRegistrationExpiryDate();
            }
        });
        this.itemType = undefined;
        ScopeFunctions.addToScope($scope);
        this.resetEditModes();
        this.clientName = ClientService.getClientName();
        this.lang = $rootScope.langSelected;
        this.parseUploadResponse = this.parseUploadResponse.bind(this);
    }

    resetEditModes() {
        this.edit = {
            description: false,
        };
    }

    toggleEditMode(key) {
        this.edit[key] = !this.edit[key];
    }

    // getRegistrationExpiryDate() {
    // const {DateService, Event} = this._injected;
    // Event.getRegistrationExpiryDate(this.eventId, (data) => {
    //     if (data.registrationExpiryDate !== null) {
    //         return this.registrationExpiryDate = DateService.parseDateInLocaleFormat(data.registrationExpiryDate);
    //     } else {
    //         return null;
    //     }
    // });
    // }

    uploadDescription() {
        const { Presentation, $scope } = this._injected;
        this.itemType = 'description';
        Presentation.uploadConfirmEmailInfoItem(
            this.jsondata.id,
            this.itemType,
            this.jsondata[this.itemType],
            this.parseUploadResponse,
            $scope.endWithError
        );
    }

    parseUploadResponse(response) {
        this.toggleEditMode(this.itemType);
        this.jsondata = response;
        this.itemType = undefined;
    }

    getFormattedDate(dateTime) {
        const { DateService } = this._injected;
        return DateService.extractLocalizedDateFromTimeStamp(dateTime);
    }

    getLocalizedDate(dateTime) {
        const { TimezoneService } = this._injected;
        return TimezoneService.toLocalTime(dateTime);
    }

    trustAsHtml(string) {
        const { $sce } = this._injected;
        return $sce.trustAsHtml(string);
    }

    getAlertMessage() {
        const { $translate } = this._injected;
        let alertMessage = $translate.instant('invitation.info.changed.alert.message1');
        return this.trustAsHtml(alertMessage);
    }

    getTimeFormat() {
        const { $rootScope } = this._injected;
        return $rootScope.langSelected === 'sv_SV' ? 'HH:mm' : 'hh:mm a';
    }
}
DesignForConfirmationEmailCtrl.$inject = [
    '$scope',
    'FieldChangedSvc',
    '$rootScope',
    'DateService',
    'TimezoneService',
    '$sce',
    '$stateParams',
    'Event',
    'FileService',
    '$location',
    'Presentation',
    'EventSite',
    'ModalService',
    '$translate',
    'GlobalOption',
    'ClientService',
];
export default DesignForConfirmationEmailCtrl;
