/**
 * Created by kshitij on 12/8/17.
 */

import controller from './og_participant_tickets.controller';

(function () {
    'use strict';
    angular.module('ogParticipantTickets').directive('ogParticipantTickets', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    eventId: '=',
                    tickets: '=',
                    paymentMethod: '=',
                    card: '=',
                    cardValid: '=',
                    stripeElement: '=',
                    fortNoxDetails: '=',
                    termsAndConditions: '=',
                    termsAccepted: '=',
                    participantAttendingUnconfirmed: '=',
                    updateSelectedTicketCallback: '&',
                    freeTicket: '&',
                },
                templateUrl: './app/components/directives/participant_tickets/og_participant_tickets.directive.view.html',
                controller,
                controllerAs: 'ParticipantTicketsCtrl',
                bindToController: true,
            };
        },
    ]);
})();
