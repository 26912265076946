import {getHttpConfig, getLocalToken, getLocalToken_temp, setLocalToken} from "../../../../utils";

angular.module('mfa.enable').component('mfaEnable', {
    templateUrl: './app/components/modules/auth/mfa_enable/mfa_enable.template.html',
    controller: ['$http','$stateParams', '$rootScope', '$state', 'AuthenticationService', 'EventEnum', '$translate', 'CustomerUser', 'CommonFunctions','$timeout',
        function MfaVerifyController(
            $http,
            $stateParams,
            $rootScope,
            $state,
            AuthenticationService
        ) {
            var vm = this;

            vm.saveAndSendCode = saveAndSendCodeFn;
            vm.disableSaveAndSend = disableSaveAndSendFn;
            vm.loginData = angular.copy($stateParams.resp);
            vm.switchToEn = switchToEnFn;
            vm.switchToSv = switchToSvFn;
            vm.switchToNb = switchToNbFn;
            vm.switchToDa = switchToDaFn;
            vm.switchToFi = switchToFiFn;
            vm.redirectMe = redirectMeFn;
            vm.mafEnabled = true;
            initFn();

            if ($stateParams.locale) {
                if ($stateParams.locale === 'en') {
                    vm.switchToEn();
                } else if ($stateParams.locale === 'sv') {
                    vm.switchToSv();
                } else if ($stateParams.locale === 'nb') {
                    vm.switchToNb();
                } else if ($stateParams.locale === 'da') {
                    vm.switchToDa();
                } else {
                    vm.switchToFi();
                }
            } else {
                vm.activeEn = $rootScope.langSelected === 'en_US' ? 'active' : '';
                vm.activeSv = $rootScope.langSelected === 'sv_SV' ? 'active' : '';
                vm.activeNb = $rootScope.langSelected === 'nb_Nb' ? 'active' : '';
                vm.activeDk = $rootScope.langSelected === 'da_DK' ? 'active' : '';
                vm.activeFi = $rootScope.langSelected === 'fi_FI' ? 'active' : '';
            }

            function initFn() {
                if(!vm.loginData){
                    let _authToken = getLocalToken_temp();
                    $http
                        .get('login/getPostLoginData',{
                            headers: {
                                'X-Auth-Token': _authToken
                            }
                        }).then(function (response) {
                        vm.loginData = angular.copy(response.data);
                        vm.phone = vm.loginData.user.phone
                        /* mafEnabled has been enabled true at top */
                        // vm.mafEnabled = vm.loginData.data.user.isMFAEnabled
                    }, function (error) {
                    });
                }else{
                    vm.phone = vm.loginData.user.phone
                    /* mafEnabled has been enabled true at top */
                    // vm.mafEnabled = true;
                }

            }

            function saveAndSendCodeFn() {
                let data = vm.loginData
                var params = {
                    enableMfa: vm.mafEnabled,
                    phone: vm.phone,
                    username: data.user.email,
                };
                AuthenticationService.enableMfaAndSendCode(params, function (res) {
                    if (res.success) {
                        data.user.phone = vm.phone
                        data.user.isMFAEnabled = vm.mafEnabled
                        $state.go('auth.mfaVerify', { resp: vm.loginData})
                    }
                });

            }

            function disableSaveAndSendFn(){
                let validPhoneNumber =  false
                if(!vm.mafEnabled || !vm.phone){
                    return true
                }
                var phoneNo = /^[+]*[0-9]+$/;
                validPhoneNumber = vm.phone.match(phoneNo)
                return !validPhoneNumber
            }

            function switchToEnFn() {
                $rootScope.switchLang('en_US');
                vm.activeEn = 'active';
                vm.activeSv = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToSvFn() {
                $rootScope.switchLang('sv_SV');
                vm.activeSv = 'active';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToNbFn() {
                $rootScope.switchLang('nb_NB');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = 'active';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToDaFn() {
                $rootScope.switchLang('nb_NB');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = 'active';
                vm.activeFi = '';
            }

            function switchToFiFn() {
                $rootScope.switchLang('fi_FI');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = 'active';
            }

            function redirectMeFn() {
                AuthenticationService.logout().then(redirectToHome);
            }

            function redirectToHome() {
                $state.go('auth.login');
            }
        }]
});

