import participantDetailViewModal from '../../modals/modals2.0/participant-detail-view';
const LIST_OF_SORTING_OPTIONS = ['First Name', 'Last Name', 'Company', 'Email', 'Phone'];
const LIST_OF_SORTING_OPTIONS_SN = ['Förnamn', 'Efternamn', 'Företag', 'E-post', 'Mobilnummer'];
const LIST_OF_SORTING_OPTIONS_NB = ['Fornavn', 'Etternavn', 'Selskap', 'E-postadresse', 'Telefon'];
const LIST_OF_SORTING_OPTIONS_DK = ['Fornavn', 'Efternavn', 'Virksomhed', 'E-mail', 'Telefon'];
const LIST_OF_SORTING_OPTIONS_FI = ['Etunimi', 'Sukunimi', 'Yritys', 'Sähköposti', 'Puhelin'];
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';
import { DownloadUtilFunctions } from '../../../utils/global/DownloadUtilFunction';

(function () {
    'use strict';

    angular.module('ogParticipantsForSupplier').directive('ogParticipantsForSupplier', [
        function () {
            return {
                scope: {
                    eventId: '=eventId',
                    afterLoadCallback: '&',
                },
                controllerAs: 'supplierParticipantCtrl',
                templateUrl:
                    './app/components/directives/participants_for_supplier/og_participants_for_supplier.directive.view.html',
                controller: [
                    '$scope',
                    '$rootScope',
                    '$translate',
                    'Participant',
                    'TimezoneService',
                    '$timeout',
                    'ModalService',
                    'ParticipantService',
                    'ParticipantUtilService',
                    'CommonFunctions',
                    function (
                        $scope,
                        $rootScope,
                        $translate,
                        Participant,
                        TimezoneService,
                        $timeout,
                        ModalService,
                        ParticipantService,
                        ParticipantUtilService,
                        CommonFunctions
                    ) {
                        let vm = this;
                        vm.listParticipantsForSupplier = listParticipantsForSupplierFn;
                        vm.getParticipantBasicQueries = getParticipantBasicQueriesFn;
                        vm.getHeaderName = getHeaderNameFn;
                        vm.getFieldValue = getFieldValueFn;
                        vm.isNameFieldExists = isNameFieldExistsFn;
                        vm.pageChanged = pageChangedFn;
                        vm.sortOptionChange = sortOptionChangeFn;
                        vm.sort = sortFn;
                        vm.exportParticipantsForSupplier = exportParticipantsForSupplierFn;
                        vm.ascendingOrder = false;
                        vm.descendingOrder = false;
                        vm.openViewSupplierModal = openViewSupplierModalFn;
                        vm.getParticipantName = getParticipantNameFn;
                        vm.PARTICIPANT_FILTER_LIST = ['TOTAL'];
                        vm.pagination = { max: 50, offset: 0 }; //fetch 50 participants on each page
                        vm.currentPage = 1;
                        vm.sorting = { field: 'Email', direction: 'asc' };
                        vm.search = { text: null, status: null };
                        vm.viewParticipantDetailViewModal = false;

                        vm.languageSelected = $rootScope.langSelected;
                        ScopeFunctions.addToScope($scope);

                        listParticipantsForSupplierFn();

                        function sortingOptionsFn() {
                            if (vm.languageSelected === 'en_US') {
                                let _basicQueryArray = _.filter(vm.basicQueryForEvent, (queryInfo) => {
                                    return CommonFunctions.hasIntersectValue(LIST_OF_SORTING_OPTIONS, [
                                        queryInfo.detailNameEnglish,
                                    ]);
                                });
                                let _basicQueryFieldValue = _basicQueryArray.map(
                                    (fieldValue) => fieldValue.detailNameEnglish
                                );
                                vm.sortingOptions = _basicQueryFieldValue;
                            } else if (vm.languageSelected === 'sv_SV') {
                                let _basicQueryArray = _.filter(vm.basicQueryForEvent, (queryInfo) => {
                                    return CommonFunctions.hasIntersectValue(LIST_OF_SORTING_OPTIONS_SN, [
                                        queryInfo.detailNameSwedish,
                                    ]);
                                });
                                let _basicQueryFieldValue = _basicQueryArray.map(
                                    (fieldValue) => fieldValue.detailNameSwedish
                                );
                                vm.sortingOptions = _basicQueryFieldValue;
                            } else if (vm.languageSelected === 'nb_NB') {
                                let basicQueryArray = _.filter(vm.basicQueryForEvent, (queryInfo) => {
                                    return CommonFunctions.hasIntersectValue(LIST_OF_SORTING_OPTIONS_NB, [
                                        queryInfo.detailNameNorwegian,
                                    ]);
                                });
                                let basicQueryFieldValue = basicQueryArray.map(
                                    (fieldValue) => fieldValue.detailNameNorwegian
                                );
                                vm.sortingOptions = basicQueryFieldValue;
                            } else if (vm.languageSelected === 'da_DK') {
                                let basicQueryArray = _.filter(vm.basicQueryForEvent, (queryInfo) => {
                                    return CommonFunctions.hasIntersectValue(LIST_OF_SORTING_OPTIONS_DK, [
                                        queryInfo.detailNameDanish,
                                    ]);
                                });
                                let basicQueryFieldValue = basicQueryArray.map(
                                    (fieldValue) => fieldValue.detailNameDanish
                                );
                                vm.sortingOptions = basicQueryFieldValue;
                            } else {
                                let basicQueryArray = _.filter(vm.basicQueryForEvent, (queryInfo) => {
                                    return CommonFunctions.hasIntersectValue(LIST_OF_SORTING_OPTIONS_FI, [
                                        queryInfo.detailNameFinnish,
                                    ]);
                                });
                                let basicQueryFieldValue = basicQueryArray.map(
                                    (fieldValue) => fieldValue.detailNameFinnish
                                );
                                vm.sortingOptions = basicQueryFieldValue;
                            }
                        }

                        function sortOptionChangeFn() {
                            let _basicQuery = _.find(vm.basicQueryForEvent, (basicQuery) => {
                                if (vm.languageSelected === 'en_US') {
                                    return basicQuery.detailNameEnglish === vm.sortingField;
                                } else if (vm.languageSelected === 'sv_SV') {
                                    return basicQuery.detailNameSwedish === vm.sortingField;
                                } else if (vm.languageSelected === 'nb_NB') {
                                    return basicQuery.detailNameNorwegian === vm.sortingField;
                                } else if (vm.languageSelected === 'da_DK') {
                                    return basicQuery.detailNameDanish === vm.sortingField;
                                } else {
                                    return basicQuery.detailNameFinnish === vm.sortingField;
                                }
                            });
                            vm.sortingField = _basicQuery.detailNameEnglish;
                            let _direction = vm.descendingOrder ? 'desc' : 'asc';
                            sortFn(vm.sortingField, _direction);
                        }

                        function sortFn(field, direction) {
                            vm.sorting = { field: field, direction: direction };
                            if (direction === 'desc') {
                                vm.descendingOrder = true;
                                vm.ascendingOrder = false;
                            } else {
                                vm.descendingOrder = false;
                                vm.ascendingOrder = true;
                            }
                            listParticipantsForSupplierFn();
                        }

                        function getSortByQueryIdFn() {
                            let _sortQuery = _.find(vm.basicQueryForEvent, (basicQuery) => {
                                if (vm.languageSelected === 'en_US') {
                                    return basicQuery.detailNameEnglish === vm.sorting.field;
                                } else if (vm.languageSelected === 'sv_SV') {
                                    return basicQuery.detailNameSwedish === vm.sorting.field;
                                } else if (vm.languageSelected === 'nb_NB') {
                                    return basicQuery.detailNameNorwegian === vm.sorting.field;
                                } else if (vm.languageSelected === 'da_DK') {
                                    return basicQuery.detailNameDanish === vm.sorting.field;
                                } else {
                                    return basicQuery.detailNameFinnish === vm.sorting.field;
                                }
                            });
                            return _sortQuery ? _sortQuery.eventParticipantBasicQuery.id : null;
                        }

                        function listParticipantsForSupplierFn() {
                            if ($scope.eventId) {
                                let _basicQueryHeading = [];
                                let _customQueryHeading = [];
                                let _sortByFieldId = getSortByQueryIdFn();
                                let participantSearchParams = {
                                    eventId: $scope.eventId,
                                    max: vm.pagination.max,
                                    offset: vm.pagination.offset,
                                    sortParams: { field: _sortByFieldId, direction: vm.sorting.direction },
                                    searchParams: { text: vm.searchText, status: vm.search.status },
                                };
                                $scope.startProgress('list-participants');
                                Participant.loadParticipantsForSupplier(
                                    participantSearchParams,
                                    (result) => {
                                        vm.originalParticipantsList = result.list;
                                        vm.participants = angular.copy(vm.originalParticipantsList);
                                        processParticipantData();
                                        vm.pagination.totalCount = result.paginationParams.totalCount;
                                        vm.lengthOfPagination = Math.ceil(
                                            result.paginationParams.totalCount / result.paginationParams.max
                                        );
                                        vm.totalAttendingCount = result.counts['ATTENDING'];
                                        if (vm.originalParticipantsList.length > 0) {
                                            _basicQueryHeading = vm.participants[0].participantBasicQueryInfoList;
                                            _customQueryHeading = vm.participants[0].participantAdditionalQueryInfoList;
                                            vm.basicQueryForEvent = _basicQueryHeading;
                                            vm.customQueryForEvent = _customQueryHeading;
                                            sortingOptionsFn();
                                        }
                                        if ($scope.afterLoadCallback) {
                                            $scope.afterLoadCallback()
                                        }
                                        $scope.endProgress('list-participants');
                                    },
                                    $scope.endWithAlert('list-participants')
                                );
                            }
                        }

                        function getParticipantBasicQueriesFn(participant) {
                            let _basicQueries = participant.participantBasicQueryInfoList;
                            let _filteredBasicQueries = _.reject(_basicQueries, function (basicInfo) {
                                return (
                                    basicInfo.detailNameEnglish &&
                                    (basicInfo.detailNameEnglish.toUpperCase() == 'FIRST NAME' ||
                                        basicInfo.detailNameEnglish.toUpperCase() == 'LAST NAME')
                                );
                            });
                            return _filteredBasicQueries;
                        }

                        function getHeaderNameFn(query) {
                            return ParticipantService.getQuestionLabel(query, vm.languageSelected);
                        }

                        function getFieldValueFn(info) {
                            let _value = info.fieldValue;
                            if (_value && 'date' === info.type.name.toLowerCase()) {
                                _value = TimezoneService.formatDate(
                                    new Date(parseInt(_value)),
                                    getDateTimeDisplayFormatFn()
                                );
                            }
                            if (_value && 'daterange' === info.type.name.toLowerCase()) {
                                let _dates = _value.split('~');
                                let _from = TimezoneService.formatDate(
                                    TimezoneService.toLocalTime(new Date(parseInt(_dates[0]))),
                                    getDateTimeDisplayFormatFn()
                                );
                                let _to = TimezoneService.formatDate(
                                    TimezoneService.toLocalTime(new Date(parseInt(_dates[1]))),
                                    getDateTimeDisplayFormatFn()
                                );
                                _value = _from + ' - ' + _to;
                            }

                            if (!_value) {
                                return '-';
                            }
                            return _value;
                        }

                        function isNameFieldExistsFn() {
                            let fullNameArray = _.find(vm.basicQueryForEvent, (query) => {
                                return (
                                    CommonFunctions.hasIntersectValue(['First Name'], [query.detailNameEnglish]) ||
                                    CommonFunctions.hasIntersectValue(['Last Name'], [query.detailNameEnglish])
                                );
                            });
                            return fullNameArray;
                        }

                        function pageChangedFn() {
                            vm.pagination.offset = (vm.currentPage - 1) * vm.pagination.max;
                            listParticipantsForSupplierFn();
                        }

                        function getParticipantNameFn(participant) {
                            let participantName = '-';
                            if (participant.name && participant.name.replace(/\s/g, '').length > 0) {
                                participantName = participant.name;
                            }
                            return participantName;
                        }

                        function getFullNameFn(participant) {
                            var fname = '',
                                lname = '',
                                name = '';
                            angular.forEach(participant.participantBasicQueryInfoList, function (basicInfo) {
                                if (basicInfo.detailNameEnglish == 'First Name') {
                                    if (basicInfo.fieldValue) {
                                        fname = basicInfo.fieldValue;
                                    } else {
                                        fname = '';
                                    }
                                }
                                if (basicInfo.detailNameEnglish == 'Last Name') {
                                    if (basicInfo.fieldValue) {
                                        lname = basicInfo.fieldValue;
                                    } else {
                                        lname = '';
                                    }
                                }
                            });
                            name = fname;
                            if (name) {
                                name += ' ';
                            }
                            name += lname;
                            return name;
                        }

                        function setFullNameInParticipant(participant) {
                            participant.name = getFullNameFn(participant);
                        }

                        function processParticipantData() {
                            angular.forEach(vm.participants, function (participant) {
                                setFullNameInParticipant(participant);
                                angular.forEach(participant.participantBasicQueryInfoList, (partBasicInfo) => {
                                    let _optionValues = partBasicInfo.eventParticipantBasicQuery.optionValues;
                                    if (['DROPDOWN'].indexOf(partBasicInfo.type.name) > -1) {
                                        let _fieldValue = partBasicInfo.fieldValue;
                                        partBasicInfo.fieldValue = ParticipantUtilService.getFieldValue(
                                            _optionValues,
                                            $rootScope.langSelected,
                                            _fieldValue
                                        );
                                    }
                                    if (['MULTISELECT'].indexOf(partBasicInfo.type.name) > -1) {
                                        let _fieldValue = partBasicInfo.fieldValue;
                                        if (_fieldValue) {
                                            partBasicInfo.fieldValue = _fieldValue.replaceAll('~', ' | ');
                                        }
                                    }
                                });

                                angular.forEach(participant.participantAdditionalQueryInfoList, (partAddInfo) => {
                                    let _optionValues = partAddInfo.optionValues;
                                    if (['DROPDOWN'].indexOf(partAddInfo.type.name) > -1) {
                                        let _fieldValue = partAddInfo.fieldValue;
                                        partAddInfo.fieldValue = ParticipantUtilService.getFieldValue(
                                            _optionValues,
                                            $rootScope.langSelected,
                                            _fieldValue
                                        );
                                    }
                                    if (['MULTISELECT'].indexOf(partAddInfo.type.name) > -1) {
                                        let _fieldValue = partAddInfo.fieldValue;
                                        if (_fieldValue) {
                                            partAddInfo.fieldValue = _fieldValue.replaceAll('~', ' | ');
                                        }
                                    }
                                });
                            });
                            return vm.participants;
                        }

                        function exportParticipantsForSupplierFn(eventId, fileFormat, extension) {
                            let _downloadUrl =
                                'api/v1/participants/event/' +
                                eventId +
                                '/exportParticipantsForSupplier/?fileformat=' +
                                fileFormat +
                                '&extension=' +
                                extension +
                                '';
                            DownloadUtilFunctions.downloadFile(_downloadUrl);
                        }

                        function getDateTimeDisplayFormatFn() {
                            let lang = $rootScope.langSelected;
                            if (lang === 'sv_SV' || lang === 'nb_NB' || lang === 'da_DK' || lang === 'fi_FI') {
                                return 'YYYY-MM-DD HH:mm';
                            } else {
                                return 'DD/MM/YYYY hh:mm a';
                            }
                        }

                        $scope.$on('lang:change', function (event, data) {
                            vm.languageSelected = data.lang;
                            sortingOptionsFn();
                            vm.sortingField = null;
                            vm.ascendingOrder = false;
                            vm.descendingOrder = false;
                        });

                        function openViewSupplierModalFn(participant) {
                            if(vm.viewParticipantDetailViewModal) {
                                return;
                            }
                            vm.viewParticipantDetailViewModal = true;
                            let data = {
                                participant: participant,
                                additionalInfoOptionsForEvent: vm.customQueryForEvent,
                                basicInfoForEvent: vm.basicQueryForEvent,
                                additionalInfoOptionsList: vm.additionalInfoOptionsList,
                                eventId: vm.eventId,
                            };
                            let participantViewModal = participantDetailViewModal;
                            participantViewModal.inputs = { data };

                            ModalService.showModal(participantViewModal).then((modal) => {
                                vm.viewParticipantDetailViewModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        }
                    },
                ],
            };
        },
    ]);
})();
