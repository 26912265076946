export default function (appModule) {
    appModule.config([
        '$routeProvider',
        '$locationProvider',
        '$stateProvider',
        '$urlRouterProvider',
        function ($routeProvider, $locationProvider, $stateProvide, $urlRouterProvider) {
            $urlRouterProvider.when(
                '/participant_details/:hash/invitation',
                '/participant_details/:hash/event-details'
            );
            $stateProvide
                .state('participant_details.registration', {
                    url: '/registration',
                    template: '<participant-registration personalized-page-details="$resolve.personalizedPageDetails"></participant-registration>',
                    resolve: {
                        personalizedPageDetails: [
                            '$stateParams',
                            'Participant',
                            '$q',
                            function ($stateParams, Participant, $q) {
                                var deferred = $q.defer();
                                Participant.getParticipantPersonalizePageDetails(
                                    $stateParams.hash,
                                    function (response) {
                                        if (response.valid) {
                                            deferred.resolve(response);
                                        } else {
                                            deferred.reject('PAGE_NOT_FOUND');
                                        }
                                    },
                                    function () {
                                        deferred.reject('PAGE_NOT_FOUND');
                                    }
                                );
                                return deferred.promise;
                            },
                        ],
                    },
                })
                .state('participant_details.event_details', {
                    url: '/event-details',
                    template: '<event-details personalized-page-details="$resolve.personalizedPageDetails"></event-details>',
                    resolve: {
                        personalizedPageDetails: [
                            '$stateParams',
                            'Participant',
                            '$q',
                            function ($stateParams, Participant, $q) {
                                var deferred = $q.defer();
                                Participant.getParticipantPersonalizePageDetails(
                                    $stateParams.hash,
                                    function (response) {
                                        if (response.valid) {
                                            deferred.resolve(response);
                                        } else {
                                            deferred.reject('PAGE_NOT_FOUND');
                                        }
                                    },
                                    function () {
                                        deferred.reject('PAGE_NOT_FOUND');
                                    }
                                );
                                return deferred.promise;
                            },
                        ],
                    },
                })
                .state('participant_details.ratings', {
                    url: '/ratings',
                    template: '<participant-ratings></participant-ratings>',
                })
                .state('participant_details.invitation', {
                    url: '/invitation', //todo(SC) remove this invitation tab/It has been replaced by info site
                    template: '<participant-invitation personalized-page-details="$resolve.personalizedPageDetails"></participant-invitation>',
                    resolve: {
                        personalizedPageDetails: [
                            '$stateParams',
                            'Participant',
                            '$q',
                            function ($stateParams, Participant, $q) {
                                var deferred = $q.defer();
                                Participant.getParticipantPersonalizePageDetails(
                                    $stateParams.hash,
                                    function (response) {
                                        if (response.valid) {
                                            deferred.resolve(response);
                                        } else {
                                            deferred.reject('PAGE_NOT_FOUND');
                                        }
                                    },
                                    function () {
                                        deferred.reject('PAGE_NOT_FOUND');
                                    }
                                );
                                return deferred.promise;
                            },
                        ],
                    },
                });
        },
    ]);
}
