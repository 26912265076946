
'use strict';
angular.module('core.participant').service('Participant', [
    '$resource',
    '$http',
    function ($resource, $http) {
        var v1ParticipantEventRest = $resource(
            'api/v1/participant/event/:eventId',
            { eventId: '@eventId' },
            {
                list: { method: 'GET', isArray: false },
                save: { method: 'POST', isArray: false },
                create: { method: 'POST', isArray: false },
            }
        );
        var v1ParticipantListRest = $resource(
            'api/v1/participant/event/list/:eventId',
            { eventId: '@eventId' },
            {
                list: { method: 'POST', isArray: false },
            }
        );
        var v1ParticipantMailListRest = $resource(
            'api/v1/participant/event/getParticipantMails/:eventId',
            { eventId: '@eventId' },
            {
                list: { method: 'POST', isArray: false },
            }
        );
        var v1ParticipantMailResendRest = $resource(
            'api/v1/participant/event/resendParticipantMail',
            {},
            {
                save: { method: 'POST', isArray: false },
            }
        );
        var v1ParticipantListByStatusRest = $resource(
            'api/v1/participant/event/listByStatus/:eventId',
            { eventId: '@eventId' },
            {
                list: { method: 'POST', isArray: false },
            }
        );
        var v1ParticipantCreateRest = $resource(
            'api/v1/participant/event/createParticipant/:eventId',
            { eventId: '@eventId' },
            {
                create: { method: 'POST', isArray: false },
            }
        );

        var v1ParticipantRest = $resource(
            'api/v1/participant/:id',
            { id: '@id' },
            {
                delete: { method: 'DELETE', isArray: false },
            }
        );

        var invite = function (jsonObject, successCallback, errorCallback) {
            var formData = new FormData();

            if (jsonObject.message) {
                formData.append('message', jsonObject.message);
            }
            formData.append('eventId', jsonObject.eventId);
            formData.append('sendSMS', jsonObject.sendSMS);
            formData.append('type', jsonObject.type);
            formData.append('messageProvider', jsonObject.messageProvider);
            formData.append(
                'sendToByStatus',
                jsonObject.sendToByStatus !== undefined ? jsonObject.sendToByStatus : null
            );
            formData.append('hash', jsonObject.hash);
            formData.append('sendGroupMessage', jsonObject.sendGroupMessage);
            formData.append('messageBySMS', jsonObject.messageBySMS);
            formData.append('messageByEmail', jsonObject.messageByEmail);
            formData.append('eventSite', jsonObject.eventSite);
            formData.append('hideRegistrationButtons', jsonObject.hideRegistrationButtons);
            formData.append('showInfoPage', jsonObject.showInfoPage);
            formData.append('includeRegistrationLink', jsonObject.includeRegistrationLink);
            formData.append('isInvitationSentBySms', jsonObject.isInvitationSentBySms);
            formData.append('invitation', jsonObject.invitation);
            formData.append('selectedParticipants', jsonObject.selectedParticipants);
            formData.append('smsMessage', jsonObject.smsMessage);
            formData.append('addLinkToSms', jsonObject.addLinkToSms);
            formData.append('sendToMePhone', jsonObject.sendToMePhone);
            formData.append('sendToMeEmail', jsonObject.sendToMeEmail);

            formData.append('sendTo', jsonObject.sendTo);
            if (jsonObject.emailSenderName) {
                formData.append('emailSenderName', jsonObject.emailSenderName);
            }
            if (jsonObject.smsSenderName) {
                formData.append('smsSenderName', jsonObject.smsSenderName);
            }
            if (jsonObject.replyToEmail) {
                formData.append('replyToEmail', jsonObject.replyToEmail);
            }
            if (jsonObject.subject) {
                formData.append('subject', jsonObject.subject);
            }

            if (jsonObject.attachedFiles) {
                for (var i = 1; i <= jsonObject.attachedFiles.length; i++) {
                    formData.append('file' + i, jsonObject.attachedFiles[i - 1]);
                }
            }
            if (jsonObject.attachments) {
                let _attachments = jsonObject.attachments.map((el) => {
                    return el.id;
                });
                formData.append('fileattachments', _attachments);
            }

            return rest('api/v1/participant/email', formData, successCallback, errorCallback);
        };

        var saveDraft = function (jsonObject, successCallback, errorCallback) {
            var formData = new FormData();

            if (jsonObject.message) {
                formData.append('message', jsonObject.message);
            }
            formData.append('eventId', jsonObject.eventId);
            formData.append('sendSMS', jsonObject.sendSMS);
            formData.append('type', jsonObject.type);
            formData.append('messageProvider', jsonObject.messageProvider);
            formData.append(
                'sendToByStatus',
                jsonObject.sendToByStatus !== undefined ? jsonObject.sendToByStatus : null
            );
            if (jsonObject.hash) {
                formData.append('hash', jsonObject.hash);
            }
            formData.append('sendGroupMessage', jsonObject.sendGroupMessage);
            formData.append('messageBySMS', jsonObject.messageBySMS);
            formData.append('messageByEmail', jsonObject.messageByEmail);
            formData.append('eventSite', jsonObject.eventSite);
            formData.append('hideRegistrationButtons', jsonObject.hideRegistrationButtons);
            formData.append('showInfoPage', jsonObject.showInfoPage);
            formData.append('includeRegistrationLink', jsonObject.includeRegistrationLink);
            formData.append('invitation', jsonObject.invitation);
            formData.append('selectedParticipants', jsonObject.selectedParticipants);
            formData.append('smsMessage', jsonObject.smsMessage);
            formData.append('addLinkToSms', jsonObject.addLinkToSms);
            formData.append('source', jsonObject.source);

            formData.append('scheduleDate', jsonObject.scheduleDate);
            formData.append('scheduleGmtDate', jsonObject.scheduleGmtDate);
            formData.append('scheduleTime', jsonObject.scheduleTime);

            formData.append('sendTo', jsonObject.sendTo);
            if (jsonObject.senderName) {
                formData.append('senderName', jsonObject.senderName);
            }
            if (jsonObject.smsSenderName) {
                formData.append('smsSenderName', jsonObject.smsSenderName);
            }
            if (jsonObject.replyToEmail) {
                formData.append('replyToEmail', jsonObject.replyToEmail);
            }
            if (jsonObject.subject) {
                formData.append('subject', jsonObject.subject);
            }

            if (jsonObject.attachedFiles) {
                for (var i = 1; i <= jsonObject.attachedFiles.length; i++) {
                    formData.append('file' + i, jsonObject.attachedFiles[i - 1]);
                }
            }
            if (jsonObject.attachments) {
                let _attachments = jsonObject.attachments.map((el) => {
                    return el.id;
                });
                formData.append('fileattachments', _attachments);
            }

            return rest('api/v1/participant/saveEventMessage', formData, successCallback, errorCallback);
        };

        var rest = function (restUrl, formData, successCallback, errorCallback) {
            $http
                .post(restUrl, formData, {
                    transformRequest: angular.identity,
                    headers: {'Content-Type': undefined},
                })
                .then(function (resp) {
                    successCallback(resp.data);
                }, function (error) {
                    errorCallback(error);
                });
        };

        var v1EventMessage = $resource(
            'api/v1/participant/getEventMessage/:eventId',
            { eventId: '@eventId' },
            {
                list: { method: 'GET', isArray: false },
            }
        );

        var v1ImportFromEvent = $resource(
            'api/v1/participant/importParticipantsFromEvent',
            {},
            {
                update: { method: 'POST', isArray: false },
            }
        );

        var v1ParticipantsShareDetailsForEventRest = $resource(
            'api/v1/participant/updateParticipantsShareDetailsForEvent/:eventId',
            { eventId: '@eventId' },
            {
                update: { method: 'POST', isArray: false },
            }
        );

        var v1ParticipantsForSupplierRest = $resource(
            'api/v1/participant/listParticipantsForSupplier/:eventId',
            { eventId: '@eventId' },
            {
                list: { method: 'GET', isArray: true },
            }
        );

        var v1ParticipantsForSupplierPostRest = $resource(
            'api/v1/participant/loadParticipantsForSupplier/:eventId',
            { eventId: '@eventId' },
            {
                list: { method: 'POST', isArray: false },
            }
        );

        var v1InvitationAdditionalConfigForEvent = $resource(
            'api/v1/participant/invitationAdditionalConfig/:eventId',
            { eventId: '@eventId' },
            {
                get: { method: 'GET', isArray: false },
                update: { method: 'POST', isArray: false },
            }
        );

        var v1ParticipantConfirmationRest = $resource(
            'api/v1/participant/confirmation/:hash',
            { hash: '@hash' },
            {
                get: { method: 'GET', isArray: false },
                confirm: { method: 'POST', isArray: false },
            }
        );
        var v1GetPublicParticipantDetailsRest = $resource(
            'api/v1/participant/getPublicParticipantDetails/:urlSlug',
            { urlSlug: '@urlSlug' },
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var v1ParticipantStatusUpdateRest = $resource(
            'api/v1/participant/updateParticipantStatus',
            {},
            {
                update: {method: 'POST', isArray: false},
            }
        );
        var v1ParticipantLanguageUpdateRest = $resource(
            'api/v1/event/:eventId/participantDefaultLocale',
            { eventId: '@eventId' },
            {
                update: { method: 'PUT', isArray: false },
            }
        );
        var v1deleteSelectedParticipantsRest = $resource(
            'api/v1/participant/deleteParticipants',
            {},
            {
                update: { method: 'POST', isArray: true },
            }
        );
        var v1deleteEventMessageRest = $resource(
            'api/v1/participant/deleteEventMessage',
            {},
            {
                update: { method: 'POST', isArray: true },
            }
        );
        var v1GetSelectedQueriesForEventRest = $resource(
            'api/v1/participant/selectedQueriesForEvent/:eventId',
            { eventId: '@eventId' },
            {
                get: { method: 'GET', isArray: false },
            }
        );
        var v1GetSearchableFieldForEventRest = $resource(
            'api/v1/participant/searchableFieldForEvent/:eventId',
            { eventId: '@eventId' },
            {
                get: { method: 'GET', isArray: true },
            }
        );
        var v1GetParticipantPersonalizePageDetailsRest = $resource(
            'api/v1/participant/getParticipantPersonalizePageDetails/:hash',
            { eventId: '@hash' },
            {
                get: { method: 'GET', isArray: false },
            }
        );

        var v1UpdateParticipantMailSubscribeStatus = $resource(
            'api/v1/participant/updateParticipantMailSubscribeStatus/:hash',
            { hash: '@hash' },
            {
                update: { method: 'POST', isArray: false },
            }
        );

        var v1getParticipantLanguage = $resource(
            ' api/v1/event/:eventId/participantDefaultLocale',
            { eventId: '@eventId' },
            {
                get: { method: 'GET', isArray: false },
            }
        );

        var v1updateCurrentParticipantLang = $resource(
            'api/v1/participant/updateParticipantLocale/:hash',
            { hash: '@hash' },
            {
                update: { method: 'PUT', isArray: false },
            }
        );

        var attendingParticipantsRest = $resource(
            'api/v1/participant/listAttendingParticipants/:eventId',
            { eventId: '@eventId' },
            {
                get: { method: 'GET', isArray: false },
            }
        );

        var getOrganizerDetails = $resource(
            'api/v1/participant/getParticipantOrganizerDetails/:eventId',
            { eventId: '@eventId' },
            {
                get: { method: 'GET', isArray: false },
            }
        );

        var checkIsParticipantNeedSMSActivationV1 = $resource(
            'api/v1/participant/checkIsParticipantNeedSMSActivation/:eventId',
            { eventId: '@eventId' },
            {
                get: { method: 'GET', isArray: false },
            }
        );

        var v1ExportInvoiceToFortNox = $resource(
            'api/v1/participant/:eventId/exportInvoiceToFortNox',
            { eventId: '@eventId' },
            {
                update: { method: 'POST', isArray: false },
            }
        );

        var updateFortNoxDetails = $resource(
            'api/v1/participant/:participantTicketId/updateFortNoxDetails',
            { participantTicketId: '@participantTicketId' },
            {
                update: { method: 'POST', isArray: false },
            }
        );

        return {
            list: function (jsonObj, successCallback, errorCallback) {
                return v1ParticipantEventRest.list(jsonObj, successCallback, errorCallback);
            },
            load: function (jsonObj, successCallback, errorCallback) {
                return v1ParticipantListRest.list(jsonObj, successCallback, errorCallback);
            },
            emailsLoad: function (jsonObj, successCallback, errorCallback) {
                return v1ParticipantMailListRest.list(jsonObj, successCallback, errorCallback);
            },
            resendMail: function (jsonObj, successCallback, errorCallback) {
                return v1ParticipantMailResendRest.save(jsonObj, successCallback, errorCallback);
            },
            listByStatus: function (jsonObj, successCallback, errorCallback) {
                return v1ParticipantListByStatusRest.list(jsonObj, successCallback, errorCallback);
            },
            save: function (eventId, participantDto, successCallback, errorCallback) {
                return v1ParticipantEventRest.save(
                    { eventId: eventId },
                    participantDto,
                    successCallback,
                    errorCallback
                );
            },
            addParticipant: function (eventId, participantDto, successCallback, errorCallback) {
                return v1ParticipantCreateRest.create(
                    { eventId: eventId },
                    participantDto,
                    successCallback,
                    errorCallback
                );
            },
            delete: function (id, successCallback, errorCallback) {
                return v1ParticipantRest['delete']({ id: id }, successCallback, errorCallback);
            },
            email: function (params, successCallback, errorCallback) {
                return invite(params, successCallback, errorCallback);
            },
            saveEventMessage: function (params, successCallback, errorCallback) {
                return saveDraft(params, successCallback, errorCallback);
            },
            deleteEventMessage: function (params, successCallback, errorCallback) {
                return v1deleteEventMessageRest.update(params, successCallback, errorCallback);
            },
            getEventMessages: function (eventId, successCallback, errorCallback) {
                return v1EventMessage.list({ eventId: eventId }, successCallback, errorCallback);
            },
            importFromFile: function (eventId, formData, successCallback, errorCallback) {
                $http
                    .post('api/v1/participant/event/' + eventId + '/importFromFile', formData, {
                        transformRequest: angular.identity,
                        headers: {'Content-Type': undefined},
                    })
                    .then(successCallback, errorCallback);
            },
            importFromEvent: function (params, successCallback, errorCallback) {
                return v1ImportFromEvent.update(params, successCallback, errorCallback);
            },
            updateParticipantsShareDetailsForEvent: function (jsonObject, successCallback, errorCallback) {
                return v1ParticipantsShareDetailsForEventRest.update(
                    { eventId: jsonObject.eventId },
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            listParticipantsForSupplier: function (eventId, successCallback, errorCallback) {
                return v1ParticipantsForSupplierRest.list({ eventId: eventId }, successCallback, errorCallback);
            },
            loadParticipantsForSupplier: function (jsonObj, successCallback, errorCallback) {
                return v1ParticipantsForSupplierPostRest.list(jsonObj, successCallback, errorCallback);
            },
            getInvitationAdditionalConfigForEvent: function (eventId, successCallback, errorCallback) {
                return v1InvitationAdditionalConfigForEvent.get(
                    { eventId: eventId },
                    successCallback,
                    errorCallback
                );
            },
            getParticipantLanguage: function (eventId, successCallback, errorCallback) {
                return v1getParticipantLanguage.get({ eventId: eventId }, successCallback, errorCallback);
            },
            updateInvitationAdditionalConfigForEvent: function (
                eventId,
                jsonObject,
                successCallback,
                errorCallback
            ) {
                return v1InvitationAdditionalConfigForEvent.update(
                    { eventId: eventId },
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            getByHash: function (params, successCallback, errorCallback) {
                return v1ParticipantConfirmationRest.get(params, successCallback, errorCallback);
            },
            getPublicParticipantDetails: function (params, successCallback, errorCallback) {
                return v1GetPublicParticipantDetailsRest.get(params, successCallback, errorCallback);
            },
            confirm: function (params, successCallback, errorCallback) {
                return v1ParticipantConfirmationRest.confirm(params, successCallback, errorCallback);
            },
            updateParticipantsStatus: function (params, successCallback, errorCallback) {
                return v1ParticipantStatusUpdateRest.update(params, successCallback, errorCallback);
            },
            updateParticipantsLanguage: function (params, successCallback, errorCallback) {
                return v1ParticipantLanguageUpdateRest.update(params, successCallback, errorCallback);
            },
            deleteSelectedParticipants: function (params, successCallback, errorCallback) {
                return v1deleteSelectedParticipantsRest.update(params, successCallback, errorCallback);
            },
            getSelectedQueriesForEvent: function (eventId, successCallback, errorCallback) {
                return v1GetSelectedQueriesForEventRest.get({ eventId: eventId }, successCallback, errorCallback);
            },
            getSearchableField: function (eventId, successCallback, errorCallback) {
                return v1GetSearchableFieldForEventRest.get({ eventId: eventId }, successCallback, errorCallback);
            },
            getParticipantPersonalizePageDetails: function (hash, successCallback, errorCallback) {
                return v1GetParticipantPersonalizePageDetailsRest.get(
                    { hash: hash },
                    successCallback,
                    errorCallback
                );
            },
            updateParticipantMailSubscribeStatus: function (params, successCallback, errorCallback) {
                return v1UpdateParticipantMailSubscribeStatus.update(params, successCallback, errorCallback);
            },
            updateCurrentParticipantLang: function (params, successCallback, errorCallback) {
                return v1updateCurrentParticipantLang.update(params, successCallback, errorCallback);
            },
            listAttending: function (eventId, successCallback, errorCallback) {
                return attendingParticipantsRest.get({ eventId: eventId }, successCallback, errorCallback);
            },
            organizerDetails: function (eventId, successCallback, errorCallback) {
                return getOrganizerDetails.get({ eventId: eventId }, successCallback, errorCallback);
            },
            exportParticipantsToFortNox: function (eventId, params, successCallback, errorCallback) {
                return v1ExportInvoiceToFortNox.update(
                    { eventId: eventId },
                    params,
                    successCallback,
                    errorCallback
                );
            },
            updateFortNoxDetails: function (participantTicketId, jsonObject, successCallback, errorCallback) {
                return updateFortNoxDetails.update(
                    { participantTicketId: participantTicketId },
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            checkIsParticipantNeedSMSActivationV1: function (eventId, successCallback, errorCallback) {
                return checkIsParticipantNeedSMSActivationV1.get(
                    { eventId: eventId },
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);
