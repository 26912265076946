'use strict';

angular.module('core.existingServices').factory('EventParticipantCustomQuery', [
    '$resource',
    '$http',
    function ($resource) {
        var v1EventParticipantCustomQueryRest = $resource(
            'api/v1/EventParticipantCustomQuery/:eventId',
            {id: '@id'},
            {
                save: {method: 'POST', isArray: false},
                list: {method: 'GET', isArray: true},
                get: {method: 'GET', isArray: true},
            }
        );

        var v1EventParticipantCustomQueryUpdate = $resource(
            'api/v1/EventParticipantCustomQuery/:queryId',
            {id: '@id'},
            {
                update: {method: 'PUT', isArray: false},
            }
        );

        var customSelectedQueries = $resource(
            'api/v1/EventParticipantCustomQuery/:eventId/customSelectedQueries',
            {id: '@id'},
            {
                get: {method: 'GET', isArray: true},
            }
        );

        var updateInBatch = $resource(
            'api/v1/EventParticipantCustomQuery/updateInBatch/:eventId',
            {eventId: '@eventId'},
            {
                update: {method: 'PUT', isArray: true},
            }
        );

        var showHideLogicRest = $resource(
            'api/v1/EventParticipantCustomQuery/:eventParticipantCustomQueryId/showHideLogic',
            {eventParticipantCustomQueryId: '@eventParticipantCustomQueryId'},
            {
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: false},
            }
        );

        return {
            list: function (jsonObject, successCallback, errorCallback) {
                return v1EventParticipantCustomQueryRest.get(jsonObject, successCallback, errorCallback);
            },
            save: function (eventId, jsonObject, successCallBack, errorCallBack) {
                return v1EventParticipantCustomQueryRest.save(eventId, jsonObject, successCallBack, errorCallBack);
            },
            update: function (queryId, jsonObject, successCallback, errorCallback) {
                return v1EventParticipantCustomQueryUpdate.update(
                    queryId,
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
            customSelectedQueries: function (jsonObject, successCallback, errorCallback) {
                return customSelectedQueries.get(jsonObject, successCallback, errorCallback);
            },
            updateInBatch: function (eventId, jsonObject, successCallback, errorCallback) {
                return updateInBatch.update({eventId: eventId}, jsonObject, successCallback, errorCallback);
            },
            getShowHideLogic: function (eventParticipantCustomQueryId, successCallback, errorCallback) {
                return showHideLogicRest.get(
                    {eventParticipantCustomQueryId: eventParticipantCustomQueryId},
                    successCallback,
                    errorCallback
                );
            },
            updateShowHideLogic: function (
                eventParticipantCustomQueryId,
                jsonObject,
                successCallback,
                errorCallback
            ) {
                return showHideLogicRest.update(
                    {eventParticipantCustomQueryId: eventParticipantCustomQueryId},
                    jsonObject,
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);

