'use strict';
import {OgModal} from "../../utils/global/ogModal";

angular.module('dialog').directive('ogMapModal', [
    function () {
        return new OgModal({
            name: 'map',
            type: 'custom',
            content: 'map',
            controller: function ($scope) {
                $scope.initDialog = function (params) {
                    if (params.latLng) {
                        $scope.latLng = params.latLng;
                    }
                        if (params.mapCanvas) {
                            $scope.mapCanvas = params.mapCanvas;
                        }
                        if (params.mapUrl) {
                            $scope.mapUrl = params.mapUrl;
                        }
                    };
                },
            });
        },
    ]);

