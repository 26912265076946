'use strict';
angular.module('core.validation').factory('ValidationService', [
    '$rootScope',
    function ($rootScope) {
        return {
            clear: function (formName) {
                $rootScope.$broadcast('validation:form:' + formName + ':clear');
            },
        };
    },
]);
