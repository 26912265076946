import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import { UtilFunctions } from '../../../utils/global/utilFunctions';
import _ from 'underscore';

class DynamicFormController {
    constructor($scope, $document, $parse, $rootScope, DynamicFormUtilService, $translate) {
        this._injected = { $scope, $document, $parse, $rootScope, DynamicFormUtilService, $translate };
        this.variables = {};

        $scope.$watch(
            'ogDynamicFormCtrl.list',
            (newVal) => {
                if (newVal && newVal.length > 0) {
                    this.initializeFields(newVal);
                }
            },
            true
        );

        ScopeFunctions.addToScope($scope);
        this.isCreatorLabel = UtilFunctions.isBooleanTrue(this.isCreatorLabel);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.setEventName = this.setEventName.bind(this);
        this.setUserGroupName = this.setUserGroupName.bind(this);
    }

    initializeFields(questions) {
        const { $rootScope, DynamicFormUtilService, $translate } = this._injected;
        angular.forEach(questions, (question) => {
            if (question.field) {
                question.defaultValue = this.setEventName(question.defaultValue);
                question.defaultValue = this.setUserGroupName(question.defaultValue);
                this.variables[question.field] = question.defaultValue;
                let _listValuesCode = question.listValueCodes;
                let _listValues = $translate.instant(_listValuesCode);
                let _fieldValue;
                if (question.type.name === 'MULTICHECKBOX' && question.fieldValue === false) {
                    _fieldValue = question.fieldValue;
                } else if (question.type.name === 'NUMBER') {
                    _fieldValue = question.fieldValue
                        ? Number(Math.round(question.fieldValue + 'e' + 2) + 'e-' + 2)
                        : question.defaultValue;
                } else {
                    _fieldValue = question.fieldValue ? question.fieldValue : question.defaultValue;
                }
                if (question.type.name !== 'ICON_MULTI_CHECKBOX') {
                    question.fieldValue = DynamicFormUtilService.getFieldValue(
                        _listValues,
                        $rootScope.langSelected,
                        _fieldValue
                    );
                }
                if (question.type.name === 'CHECKBOX') {
                    question.fieldValue = UtilFunctions.isBooleanTrue(question.fieldValue);
                }
            }
        });
    }

    setEventName(defaultValue) {
        if (defaultValue && this.args && this.args.event) {
            defaultValue = defaultValue.split('[[EVENT]]').join(this.args.event);
        }
        return defaultValue;
    }

    setUserGroupName(defaultValue) {
        if (defaultValue && this.args && this.args.userGroup) {
            defaultValue = defaultValue.split('[[USER_GROUP]]').join(this.args.userGroup);
        }
        return defaultValue;
    }

    onChange(question, fieldValue) {
        this.checkChanges(question, fieldValue);
        if (this.onChangeCallback) {
            this.onChangeCallback(question);
        }
    }

    checkChanges(question, fieldValue) {
        this.isChanged({ question: question, fieldValue: fieldValue });
    }

    onClick(question, option) {
        let checkedValues = [];
        let fieldValues = question.fieldValue ? question.fieldValue.split('~') : null;
        angular.forEach(fieldValues, function (fieldValue) {
            checkedValues.push(fieldValue);
        });
        if (checkedValues.indexOf(option) === -1) {
            checkedValues.push(option);
        } else {
            let index = checkedValues.indexOf(option);
            checkedValues.splice(index, 1);
        }
        question.fieldValue = checkedValues.join('~');
        if (question.type.name === 'MULTICHECKBOX' && checkedValues.length === 0) {
            question.fieldValue = false;
        }
        this.checkChanges(question, question.fieldValue);
    }

    createRoundTrip() {
        console.log('Create round trip >>>> ');
    }

    show(question) {
        const { $rootScope, $translate } = this._injected;
        let _strLogicToShow = question.showHide;
        if (!_strLogicToShow) {
            return true;
        }
        let stringArray = _strLogicToShow.split('~');
        let shouldShow = stringArray[0] === 'SHOWON';
        let shouldHide = stringArray[0] === 'HIDEON';
        let _reference = stringArray[1];
        let _valueShouldBe = '';
        if ($rootScope.langSelected === 'en_US') {
            _valueShouldBe = stringArray[2].split('#')[0];
        } else if ($rootScope.langSelected === 'sv_SV') {
            _valueShouldBe = stringArray[2].split('#')[1];
        } else if ($rootScope.langSelected === 'nb_NB') {
            _valueShouldBe = stringArray[2].split('#')[2];
        } else if ($rootScope.langSelected === 'da_DK') {
            _valueShouldBe = stringArray[2].split('#')[3];
        } else if ($rootScope.langSelected === 'fi_FI') {
            _valueShouldBe = stringArray[2].split('#')[4];
        }
        if (shouldShow) {
            let _question = _.find(this.list, (item) => {
                return item.field === _reference;
            });
            if (_question.type.name === 'MULTICHECKBOX') {
                if (!_question.fieldValue) {
                    return false;
                }
                let _values = _question.fieldValue.split('~');
                let _valuesUpperCase = _values.map((value) => value.toUpperCase());
                return _valuesUpperCase.indexOf(_valueShouldBe) !== -1;
            } else if (_question.type.name === 'ICON_MULTI_CHECKBOX') {
                let _translatedValuesArray = [];
                if (!_question.fieldValue) {
                    return false;
                }
                let _values = _question.fieldValue.split('~');
                if (_values.length) {
                    for (let i = 0; i < _values.length; i++) {
                        let _listValueCode = _values[i].split('#');
                        let _translatedValues = $translate.instant(_listValueCode[0]);
                        _translatedValuesArray.push(_translatedValues);
                    }
                }
                let _valuesUpperCase = _translatedValuesArray.map((value) => value.toUpperCase());
                return _valuesUpperCase.indexOf(_valueShouldBe) !== -1;
            } else {
                let _value = _question.fieldValue;
                _value = _value ? _value.replace(/\s/g, '').toUpperCase() : null;
                return _value === _valueShouldBe;
            }
        }

        if (shouldHide) {
            let _question = _.find(this.list, (item) => {
                return item.field === _reference;
            });
            if (_question.type.name === 'MULTICHECKBOX') {
                if (_question.fieldValue) {
                    let _values = _question.fieldValue.split('~');
                    let _valuesUpperCase = _values.map((value) => value.toUpperCase());
                    return _valuesUpperCase.indexOf(_valueShouldBe) == -1;
                }
            } else if (_question.type.name === 'ICON_MULTI_CHECKBOX') {
                let _translatedValuesArray = [];
                let _values = _question.fieldValue.split('~');
                if (_values.length) {
                    for (let i = 0; i < _values.length; i++) {
                        let _listValueCode = _values[i].split('#');
                        let _translatedValues = $translate.instant(_listValueCode[0]);
                        _translatedValuesArray.push(_translatedValues);
                    }
                }
                let _valuesUpperCase = _translatedValuesArray.map((value) => value.toUpperCase());
                return _valuesUpperCase.indexOf(_valueShouldBe) === -1;
            } else {
                let _value = _question.fieldValue;
                _value = _value ? _value.replace(/\s/g, '').toUpperCase() : null;
                return _value !== _valueShouldBe;
            }
        }

        return true;
    }

    getWidht(question) {
        return (
            question.code === 'final_details.common.translate.offer.part.type.conference.room' ||
            (question.code === 'common.translate.offer.part.type.conference.room' && question.field === 'CONFERENCE_02')
        );
    }
}
DynamicFormController.$inject = ['$scope', '$document', '$parse', '$rootScope', 'DynamicFormUtilService', '$translate'];
export default DynamicFormController;
