'use strict';

angular.module('core.existingServices').factory('ParticipantActivity', ParticipantActivityFn);
ParticipantActivityFn.$inject = ['$resource'];

function ParticipantActivityFn($resource) {
    var activities = $resource(
        'api/v1/participantActivity/:eventId',
        {eventId: '@eventId'},
        {
            list: {method: 'POST', isArray: false},
        }
    );

    var activitiesForSupplier = $resource(
        'api/v1/participantActivity/:eventId/offer/:offerId',
        {eventId: '@eventId', offerId: '@offerId'},
        {
            list: {method: 'POST', isArray: false},
        }
    );

    return {
        listActivities: function (eventId, jsonObject, successCallback, errorCallback) {
            return activities.list({eventId: eventId}, jsonObject, successCallback, errorCallback);
        },
        listActivitiesForSupplier: function (eventId, offerId, jsonObject, successCallback, errorCallback) {
            return activitiesForSupplier.list(
                {eventId: eventId, offerId: offerId},
                jsonObject,
                successCallback,
                errorCallback
            );
        },
    };
}

