/**
 * Created by Diwakar on 2/13/2018.
 */

'use strict';

angular.module('core.existingServices').factory('BankDetails', [
    '$resource',
    '$http',
    function ($resource, $http) {
        var bankDetailsByEventRest = $resource(
            'api/v1/bankDetails/event/:eventId',
            {eventId: '@eventId'},
            {
                create: {method: 'POST', isArray: false},
                get: {method: 'GET', isArray: false},
            }
        );

        var bankDetailsDelete = $resource(
            'api/v1/bankDetails/event/:eventId/bankDetails/:bankDetailsId',
            {eventId: '@eventId', bankDetailsId: '@bankDetailsId'},
            {
                delete: {method: 'DELETE', isArray: false},
            }
        );

        return {
            create: function (eventId, jsonObject, successCallback, errorCallback) {
                bankDetailsByEventRest.create({eventId: eventId}, jsonObject, successCallback, errorCallback);
            },
            get: function (eventId, successCallback, errorCallback) {
                bankDetailsByEventRest.get({eventId: eventId}, successCallback, errorCallback);
            },

            delete: function (eventId, bankDetailsId, successCallback, errorCallback) {
                bankDetailsDelete.delete(
                    {eventId: eventId, bankDetailsId: bankDetailsId},
                    successCallback,
                    errorCallback
                );
            },
        };
    },
]);

