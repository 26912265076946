'use strict';
angular.module('core.existingServices').factory('LocationService', [
    '$resource',
    function ($resource) {
        var getLocationFromIp = $resource(
            'https://ipwho.is/',
            {},
            {
                get: {
                    method: 'GET',
                    isArray: false,
                },
            }
        );

        return {
            getLocationJsonFromIpApi: function (jsonObject, success, error) {
                return getLocationFromIp.get(jsonObject, success, error);
            },
        };
    },
]);

