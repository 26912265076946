(function () {
    'use strict';
    angular.module('ogClick').directive('ogClick', [
        function () {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    element.bind('click', function () {
                        if (!attrs.disabled && !element.hasClass('disabled')) {
                            scope.$apply(function () {
                                scope.$eval(attrs.ogClick);
                            });
                        }
                    });
                },
            };
        },
    ]);
})();
