/**
 * Created by kshitij on 11/17/17.
 */
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    /* global angular */
    'use strict';

    angular.module('ogSupplierPresentationImages').directive('ogSupplierPresentationImages', [
        function () {
            return {
                templateUrl:
                    './app/components/directives/supplier_presentation_images/og_supplier_presentation_images.directive.view.html',
                scope: {
                    supplierId: '=supplierId',
                    jsondata: '=jsondata',
                    isthumbnail: '=isthumbnail',
                    defaultEditable: '=editable',
                    imageCropSection: '=imageAndDescriptionSection',
                    parent: '=parent',
                },
                controller: [
                    '$scope',
                    '$rootScope',
                    '$location',
                    '$log',
                    '$anchorScroll',
                    'Presentation',
                    'Supplier',
                    'CommonPresentation',
                    '$timeout',
                    'CommonFile',
                    function (
                        $scope,
                        $rootScope,
                        $location,
                        $log,
                        $anchorScroll,
                        Presentation,
                        Supplier,
                        CommonPresentation,
                        $timeout,
                        CommonFile
                    ) {
                        ScopeFunctions.addToScope($scope);

                        $scope.removePicture = function (imageId, imageType) {
                            $scope.showRemovePictureConfirmationMessage = true;
                            $scope.removeImageId = imageId;
                            $scope.removeImageType = imageType;
                        };

                        $scope.resetRemovePicture = function () {
                            $scope.showRemovePictureConfirmationMessage = false;
                            $scope.removeImageId = null;
                            $scope.removeImageType = null;
                        };

                        $scope.doRemovePicture = function () {
                            $scope.startProgress();
                            CommonPresentation.deleteImage(
                                { currentImg: $scope.removeImageId },
                                function (response) {
                                    if ($scope.removeImageId === $scope.currentImg) {
                                        $scope.currentImg = null;
                                    }
                                    $scope.presentation = response;
                                    $scope.presentationImages = response.presentationImages;
                                    $scope.endProgress();
                                    $scope.resetRemovePicture();
                                    $scope.currentImage();
                                    $rootScope.$emit('presentation:image:updated', response);
                                },
                                $scope.endWithErrorOverlay
                            );
                        };

                        $scope.pinPicture = function (imageId) {
                            $scope.startProgress();
                            CommonPresentation.pinImage(
                                { imageId: imageId },
                                function (response) {
                                    changeActivePinCss(response.presentationImages);
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay()
                            );
                        };

                        function changeActivePinCss(images) {
                            angular.forEach(images, (img) => {
                                if (img.featured) {
                                    $('#pin_img_' + img.fileStoreId).addClass('active');
                                } else {
                                    $('#pin_img_' + img.fileStoreId).removeClass('active');
                                }
                            });
                        }

                        $scope.setPinActive = function (imgId) {
                            let imgFeatured = _.find($scope.presentationImages, (img) => {
                                return img.featured === true;
                            });
                            if (imgFeatured) {
                                return imgFeatured.fileStoreId === imgId;
                            }
                        };

                        $scope.$watch('jsondata', function (data) {
                            if (data) {
                                $scope.presentation = data;
                                $scope.presentationImages = data.presentationImages;
                                $scope.originalPresentationImages = data.presentationImages;
                                $scope.resetEditModes($scope.presentationImages);
                                $scope.currentImg = null;
                                $scope.currentImage();
                                getSupplierData();
                            }
                        });

                        $scope.$watch('defaultEditable', function (data) {
                            $scope.isEditable = data ? $scope.defaultEditable : false;
                        });

                        $scope.$watch('imageCropSection', function (data) {
                            if (!data) {
                                $scope.showImageCropSection = false;
                            }
                        });

                        function getSupplierData() {
                            $scope.startProgress();
                            if ($scope.supplierId !== null) {
                                Supplier.get(
                                    { supplierId: $scope.supplierId },
                                    function (result) {
                                        $scope.supplier = result;
                                        changeActivePinCss($scope.presentationImages);
                                    },
                                    $scope.endWithErrorOverlay
                                );
                            }
                            $scope.endProgress();
                        }

                        $scope.resetEditModes = function (images) {
                            $scope.edit = {
                                name: false,
                                logoUrl: false,
                                videoUrl: false,
                                description: false,
                                map: false,
                            };
                            if (images) {
                                angular.forEach(images, function (image, key) {
                                    $scope.edit[key] = false;
                                });
                            }
                        };

                        $scope.setItemType = function (itemType) {
                            $scope.itemType = itemType;
                        };

                        $scope.errorCallback = function (error) {
                            $scope.error = error;
                            $scope.itemType = undefined;
                        };

                        $scope.parseUploadResponse = function (response) {
                            $scope.edit[$scope.itemType] = false;
                            $scope.presentation = response;
                            $rootScope.$emit('update:supplier:presentation', { presentation: $scope.presentation });
                            if ($scope.itemType == 'mapURL') {
                                $scope.$broadcast('map/init', response.mapUrl);
                            }
                            $scope.itemType = undefined;
                        };

                        $scope.upload = function (itemType, successfn) {
                            $scope.itemType = itemType;
                            if ($scope.presentation[itemType]) {
                                Presentation.uploadItem(
                                    $scope.presentation.id,
                                    itemType,
                                    $scope.presentation[itemType],
                                    function (resp) {
                                        if (successfn) {
                                            successfn();
                                        }
                                        $scope.parseUploadResponse(resp);
                                    },
                                    $scope.errorCallback
                                );
                            }
                        };

                        $scope.reset = function () {
                            $scope.presentation.useParent = true;
                            $scope.upload('useParent');
                        };

                        $scope.uploadImage = function (content, completed) {
                            if (completed && content) {
                                var fileStore = angular.fromJson(content);
                                $scope.presentation[$scope.itemType] = fileStore.id;
                                $scope.upload($scope.itemType);
                            }
                        };

                        $scope.displayHeaderType = 'IMAGE';

                        $scope.currentImage = function (val) {
                            let featuredImg = _.find($scope.presentationImages, (img) => {
                                return img.featured === true;
                            });
                            if (val === 'map') {
                                $scope.displayHeaderType = 'MAP';
                            } else if (val === 'video') {
                                $scope.displayHeaderType = 'VIDEO';
                            } else {
                                $scope.displayHeaderType = 'IMAGE';
                                $scope.currentImg = val
                                    ? val
                                    : $scope.currentImg
                                        ? $scope.currentImg
                                        : featuredImg
                                            ? featuredImg.fileStoreId
                                            : $scope.presentationImages
                                                ? $scope.presentationImages[0].fileStoreId
                                                : '0';
                            }

                            if ($scope.currentImg === '0') {
                                if ($scope.presentation.mapUrl) {
                                    $scope.displayHeaderType = 'MAP';
                                } else if ($scope.presentation.videoUrl) {
                                    $scope.displayHeaderType = 'VIDEO';
                                }
                            }
                            return $scope.currentImg;
                        };

                        angular.forEach($scope.presentationImages, function (img, key) {
                            $scope.$watch('edit.' + key, function (a) {
                                if (!a) {
                                    return;
                                }
                                trigger(key);
                            });
                        });

                        function trigger(img) {
                            var repeat = 0;

                            function triggerClick(i) {
                                if ($('input[type="file"].' + i).is(':visible')) {
                                    $('input[type="file"].' + i).trigger('click');
                                } else if (repeat < 5) {
                                    repeat++;
                                    $timeout(triggerClick, 100);
                                }
                            }
                            triggerClick(img);
                        }

                        $scope.$watch('edit.logoUrl', function (a) {
                            if (!a) {
                                return;
                            }
                            trigger('logoUrl');
                        });

                        $scope.updatePresentationImage = function (formData) {
                            CommonPresentation.updatePresentationLogo(
                                formData,
                                function (data) {
                                    $scope.showImageCropSection = false;
                                    $scope.presentationImages = data.presentationImages;
                                    refreshImage();
                                    $rootScope.$emit('presentation:image:updated', data);
                                },
                                $scope.endWithErrorOverlay
                            );
                        };

                        function refreshImage() {
                            let oldImage = $scope.presentationImages[$scope.targetImage];
                            let newImage = $scope.presentationImages
                                ? _.max($scope.presentationImages, function (img) {
                                    return img.dateCreated;
                                })
                                : null;
                            $scope.updateCurrentImg(oldImage, newImage.fileStoreId);
                        }

                        $scope.hasMapUrl = function () {
                            let noMapUrl = 'https://maps.google.com/maps?q=null%2C+null%2C+null';
                            return $scope.presentation && $scope.presentation.mapUrl != noMapUrl;
                        };

                        $scope.updateCurrentImg = function (oldImageId, newImageId) {
                            if ($scope.currentImg === oldImageId || oldImageId === undefined) {
                                $scope.currentImg = newImageId;
                            }
                        };

                        $scope.setCropImageSection = function (image) {
                            if ($scope.isEditable) {
                                $scope.targetImage = image
                                    ? image
                                    : $scope.presentationImages.length
                                        ? $scope.presentationImages[0].name
                                        : null;
                                $scope.targetImageUrl = image ? image : 'other';
                                $scope.showImageCropSection = true;
                                $anchorScroll.yOffset = 50;
                                $anchorScroll('scrolltocrop');
                            }
                        };

                        $scope.resetCropImageSection = function () {
                            $scope.targetImage = null;
                            $scope.targetImageUrl = null;
                            $scope.showImageCropSection = false;
                        };

                        $scope.getImageUrl = function (fileId) {
                            return CommonFile.getCommonFile(fileId);
                        };
                    },
                ],
                link: function (scope, element, attrs) {
                    scope.tabIndex = (Math.random() * 6).toString().replace(/\./g, '_');
                    if (attrs.isthumbnail) {
                        element.bind('click', function (e) {
                            e.stopPropagation();
                        });
                    }

                    $(element).on('change', 'input[type="file"]', function () {
                        $(this).closest('form').find('input[upload-submit]').click();
                    });
                },
            };
        },
    ]);
})();
