import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

(function () {
    /* global angular */
    'use strict';
    angular.module('ogFixedNotification').directive('ogFixedNotification', [
        function () {
            return {
                restrict: 'EA',
                templateUrl: './app/components/directives/fixed_notification/og_fixed_notification.view.html',
                replace: true,
                scope: {
                    callBackOnChange: '=',
                    callBackOnDiscard: '=',
                },
                controllerAs: 'notificationCtrl',
                bindToController: true,
                controller: [
                    '$scope',
                    '$rootScope',
                    'NotificationService',
                    'Notification',
                    'RequestOffer',
                    'EventEnum',
                    '$stateParams',
                    function (
                        $scope,
                        $rootScope,
                        NotificationService,
                        Notification,
                        RequestOffer,
                        EventEnum,
                        $stateParams
                    ) {
                        var vm = this;
                        vm.lockedNotifications = null;
                        vm.editedNotifications = null;
                        vm.checkIfNotification = checkIfNotificationFn;
                        vm.discardBookedLockedByCreatorChanges = discardBookedLockedByCreatorChangesFn;
                        vm.applyCreatorChangesOnBookedLockedByCreator = applyCreatorChangesOnBookedLockedByCreatorFn;
                        vm.bookedLockedByCreator = NotificationService.get_REQUEST_OFFER_BOOKED_LOCKED_BY_CREATOR;
                        vm.editedByCreator = NotificationService.get_REQUEST_OFFER_EDITED_BY_CREATOR;
                        vm.eventId = $stateParams.eventId;
                        vm.discardEditedByCreatorChanges = discardEditedByCreatorChangesFn;
                        vm.applyEditedByCreatorChanges = applyEditedByCreatorChangesFn;

                        ScopeFunctions.addToScope($scope);

                        function discardBookedLockedByCreatorChangesFn() {
                            $scope.startProgress();
                            var offerIdList = extractBookedLockedByCreatorOfferId();
                            var eventId = extractEventIdFromLockedNotification();
                            RequestOffer.bookOfferListInBulk(eventId, offerIdList, successHandler, errorHandler);
                        }

                        function applyCreatorChangesOnBookedLockedByCreatorFn() {
                            $scope.startProgress();
                            var offerIdList = extractBookedLockedByCreatorOfferId();
                            var eventId = extractEventIdFromLockedNotification();
                            RequestOffer.applyCreatorChangesInBulk(eventId, offerIdList, successHandler, errorHandler);
                        }

                        function discardEditedByCreatorChangesFn(requestId) {
                            $scope.startProgress();
                            var offerIdList = extractEditedByCreatorOfferId(requestId);
                            var eventId = extractEventIdFromEditedNotification();
                            RequestOffer.discardEditedByCreatorChanges(
                                eventId,
                                offerIdList,
                                successHandler,
                                errorHandler
                            );
                        }

                        function applyEditedByCreatorChangesFn(requestId) {
                            $scope.startProgress();
                            var offerIdList = extractEditedByCreatorOfferId(requestId);
                            var eventId = extractEventIdFromEditedNotification();
                            RequestOffer.applyEditedOwnChangesByCreator(
                                eventId,
                                offerIdList,
                                successHandler,
                                errorHandler
                            );
                        }

                        function checkIfNotificationFn() {
                            vm.lockedNotifications = vm.bookedLockedByCreator();
                            vm.editedNotifications = vm.editedByCreator();
                            var editedKeys = Object.keys(vm.editedNotifications);
                            return vm.lockedNotifications || editedKeys.length > 0;
                        }

                        function extractBookedLockedByCreatorOfferId() {
                            return _.chain(vm.lockedNotifications).map('offer').map('id').value();
                        }

                        function extractEventIdFromEditedNotification() {
                            var notifications = $.map(vm.editedNotifications, function (v) {
                                return v;
                            });
                            return notifications[0].event.id;
                        }

                        function extractEventIdFromLockedNotification() {
                            return vm.lockedNotifications[0].event.id;
                        }

                        function extractEditedByCreatorOfferId(requestId) {
                            var notifications = $.map(vm.editedNotifications, function (v) {
                                return v;
                            });
                            var offersData = [];
                            angular.forEach(notifications, function (notification) {
                                if (requestId == notification.request.id) {
                                    offersData.push(notification.offer.id);
                                }
                            });
                            return offersData;
                        }

                        function errorHandler(error) {
                            $scope.endWithAlert(error);
                        }

                        function successHandler() {
                            $scope.endProgress();
                            NotificationService.setNotificationData(vm.eventId);
                            $scope.$emit(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                            $scope.$emit(EventEnum.EVENT_DETAIL_PAGE_RELOAD);
                        }
                    },
                ],
            };
        },
    ]);
})();
