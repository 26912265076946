class OgUserMessageImageCtrl {
    constructor($rootScope, AuthenticationService, EventEnum, $scope) {
        this._injected = { $rootScope, AuthenticationService, EventEnum, $scope };

        this.setDefaultValues();
        this.getUserData();
        this.setUserValues();

        //TODO(SC) DESTROY THIS EVENT ON THIS CLASS DESTROY
        $rootScope.$on(
            EventEnum.USER_PROFILE_PICTURE_CHANGED,
            function (event, data) {
                if (!this.user || (this.user && this.user.id == data.id)) {
                    AuthenticationService.updateUserData(data);
                    this.selectedUser = AuthenticationService.getUserData();
                    this.setProfilePictureValues();
                }
            }.bind(this)
        );

        var messageThreadChanged = $rootScope.$on(
            EventEnum.MESSAGE_THREAD_CHANGED,
            function (event, data) {
                if (data) {
                    this.selectedUser = data;
                    this.setProfilePictureValues();
                }
            }.bind(this)
        );

        $scope.$on('$destroy', messageThreadChanged);
    }

    getUserData() {
        const { AuthenticationService } = this._injected;
        this.selectedUser = this.user;
        if (!this.selectedUser) {
            this.selectedUser = AuthenticationService.getUserData();
        }
    }

    getUserInitials() {
        let usernameList = this.selectedUser.name.split(/[ ,]+/);
        let userInitials;
        if (usernameList.length > 1) {
            userInitials = usernameList[0].charAt(0).toUpperCase() + usernameList[1].charAt(0).toUpperCase();
        } else {
            userInitials = usernameList[0].charAt(0).toUpperCase();
        }
        return userInitials;
    }

    setDefaultValues() {
        this.contentHeight = 33;
        this.contentFontSize = 13;
        this.contentBgColor = '#a53aaf';
        this.contentPosition = 'relative';
        this.contentType = 'small';
        this.userImageId = null;
    }

    setProfilePictureValues() {
        if (this.contentType == 'small' && this.selectedUser.smallProfilePicture) {
            this.userImageId = this.selectedUser.smallProfilePicture.id;
        } else if (this.selectedUser.normalProfilePicture) {
            this.userImageId = this.selectedUser.normalProfilePicture.id;
        } else {
            this.userImageId = null;
        }
    }

    setUserValues() {
        if (this.height) {
            this.contentHeight = this.height;
        }
        if (this.fontSize) {
            this.contentFontSize = this.fontSize;
        }
        if (this.bgColor) {
            this.contentBgColor = this.bgColor;
        }
        if (this.position) {
            this.contentPosition = this.position;
        }
        if (this.type) {
            this.contentType = this.type;
        }

        this.setProfilePictureValues();
    }
}
OgUserMessageImageCtrl.$inject = ['$rootScope', 'AuthenticationService', 'EventEnum', '$scope'];
export default OgUserMessageImageCtrl;
