import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

class TimelessActivity {
    constructor($scope, $sce) {
        this._injected = {$scope, $sce};
        this.openModalOnEventClick = this.openModalOnEventClick.bind(this);
        ScopeFunctions.addToScope($scope);
    }

    openModalOnEventClick() {
        this.callBack();
    }

    getNotificationHtml(notification) {
        const { $sce } = this._injected;
        if (notification) {
            notification = 1;
            return $sce.trustAsHtml('<span class="notification no-print">' + notification + '</span>');
        } else {
            return $sce.trustAsHtml('');
        }
    }
}
TimelessActivity.$inject = ['$scope', '$sce'];
export default TimelessActivity;
