'use strict';

import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import createEventModalCtrl from "../../../modals/modals2.0/create-event";
import { OgModal } from "../../../../utils/global/ogModal";
import _ from 'underscore';

// Register `events` component, along with its associated controller and template
angular.
    module('events').
    component('events', {
        templateUrl: './app/components/modules/event/events/events.template.html',
        controllerAs: 'eventsCtrl',
        controller: [
            '$scope',
            '$rootScope',
            '$location',
            '$timeout',
            '$http',
            'Page',
            'Event',
            'RequestOffer',
            'CustomerUser',
            'AuthenticationService',
            'EventEnum',
            'ogScrollService',
            '$state',
            'ModalService',
            'DateService',
            'ActiveTabService',
            'ChatMessageCountStore',
            'CommonFile',
            'CommonFunctions',
            function EventsController(
                $scope,
                $rootScope,
                $location,
                $timeout,
                $http,
                Page,
                Event,
                RequestOffer,
                CustomerUser,
                AuthenticationService,
                EventEnum,
                ogScrollService,
                $state,
                ModalService,
                DateService,
                ActiveTabService,
                ChatMessageCountStore,
                CommonFile,
                CommonFunctions
            ) {
                var vm = this;
                vm.displayAllEvents = displayAllEventsFn;
                vm.eventSearch = eventSearchFn;
                vm.pageChanged = pageChangedFn;
                vm.resendActivationEmail = resendActivationEmailFn;
                vm.searchTextChanged = searchTextChangedFn;
                vm.showAddUsersDialog = showAddUsersDialogFn;
                vm.showCreateEventUsingTemplateOrGuide = showCreateEventUsingTemplateOrGuideFn;
                vm.openCreateEventModal = openCreateEventModalFn;
                vm.getDateFormat = getDateFormatFn;
                vm.viewOffer = viewOfferFn;
                vm.displayListView = displayListViewFn;
                vm.displayGridView = displayGridViewFn;
                vm.getFormattedDate = getFormattedDateFn;
                vm.getActiveTab = getActiveTabFn;
                vm.setActiveTab = setActiveTabFn;
                vm.eventsOrderByAsc = eventsOrderByAscFn;
                vm.eventsOrderByDesc = eventsOrderByDescFn;
                vm.getUnreadMessageCount = getUnreadMessageCountFn;
                vm.getTaskCount = getTaskCountFn;
                vm.stripHtmlTags = stripHtmlTagsFn;
                vm.scrollToLastActivities = scrollToLastActivitiesFn;
                vm.setEventNameAndSearch = setEventNameAndSearchFn;
                vm.updateSelectedEventName = updateSelectedEventNameFn;

                vm.filteredEvents = [];
                vm.currentPage = 1;
                vm.page = 0;
                vm.searchResult = false;
                vm.showClear = false;
                vm.totalCount = 0;
                vm.params = {};
                vm.gridView = true;
                vm.numPerPage = 6;
                vm.listView = false;
                vm.direction = 'asc';
                vm.sorting = { field: 'fromDate', direction: 'asc' };
                vm.remoteUrl = new URL(CommonFile.getBaseUrl() + 'api/v1/event/getCreatorEventDropdownList');
                vm.disableEventCreation = false;
                vm.viewCreateEventModal = false;

                ScopeFunctions.addToScope($scope);
                updateTab();

                initFn();
                function initFn() {
                    $scope.startProgress();
                    vm.statusFilterArray = [
                        { id: 1, title: 'UPCOMING' },
                        { id: 2, title: 'CLOSED' },
                        { id: 3, title: 'PENDING' },
                        { id: 4, title: 'COMPLETED' },
                        { id: 5, title: 'ACTIVE' },
                    ];
                    vm.selectedStatus = vm.statusFilterArray[0].title;
                    Page.creatorHomePage(function (response) {
                        ChatMessageCountStore.setCreatorHomePageUnreadMessageCount(response.messageCount);
                        ChatMessageCountStore.setCreatorHomePageTaskCount(response.taskCount);
                        vm.messageCount = response.messageCount;
                        vm.lastActivities = response.lastActivities;
                        vm.daysToNextDeadline = response.daysToNextDeadline;
                        vm.activeCount = response.activeCount;
                        vm.usersInGroup = response.usersInGroup;
                        vm.userGroupName = response.userGroupName;
                        // this is the count of all event created so far.. including ACTIVE/CLOSED etc
                        vm.totalEventCount = response.totalEventCount;
                        vm.disableEventCreation = response.disableEventCreation;
                        $rootScope.$emit('event:user:action', { action: 'visit.homepage', data: CustomerUser });
                        checkAndOpenCreateEventModal();
                        $scope.endProgress();
                    }, $scope.endWithErrorOverlay);
                    resetPaginationParams();
                    setPaginationParams();
                    getPaginatedEvents();
                    getRemoteUrl();
                    vm.currentPage = 1;
                }

                /**
                 * Badri Paudel 9/17/2021
                 * Listens for route change in this controller
                 * check if user is already logged in and is trying to access login page again, if yes, redirect to dashboard
                 */
                $scope.$on('$locationChangeSuccess', function ($event, next, current) {
                    if (window.location.pathname === '/' && localStorage.getItem('authToken')) {
                        AuthenticationService.checkIfUserIsLoggedInAndRedirectToEventsDashBoard();
                    }
                });

                function getDateFormatFn(lang) {
                    if (lang == 'sv_SV') {
                        return 'yyyy-MM-dd';
                    }
                    return 'dd/MM/yyyy';
                }

                function getUnreadMessageCountFn() {
                    return ChatMessageCountStore.getCreatorHomePageUnreadMessageCount();
                }

                function getTaskCountFn() {
                    return ChatMessageCountStore.getCreatorHomePageTaskCount();
                }

                function checkAndOpenCreateEventModal() {
                    if (!vm.totalEventCount && vm.userGroupName && !vm.disableEventCreation) {
                        $timeout(() => {
                            vm.openCreateEventModal();
                        }, 200);
                    }
                }

                function updateTab() {
                    switch ($state.current.name) {
                        case 'events.list':
                            ActiveTabService.setEventsTab('EVENTS');
                            break;
                        case 'events.messages':
                            ActiveTabService.setEventsTab('MESSAGES');
                            break;
                        case 'events.tasks':
                            ActiveTabService.setEventsTab('TASKS');
                            break;
                        case 'events.upComingOffer':
                            ActiveTabService.setEventsTab('OFFERS_UPCOMING');
                            break;
                        default:
                            ActiveTabService.setEventsTab('EVENTS');
                            break;
                    }
                }

                function displayAllEventsFn() {
                    vm.viewMode = 'EVENTS';
                    vm.showAllEvents = true;
                    if (!vm.totalEventCount) {
                        $timeout(function () {
                            ogScrollService.scrollToElement('event-container');
                        }, 0);
                    }
                }

                function displayListViewFn() {
                    vm.listView = true;
                    vm.numPerPage = 15;
                    vm.gridView = false;
                    getPaginatedEvents();
                }

                function displayGridViewFn() {
                    vm.gridView = true;
                    vm.numPerPage = 6;
                    vm.listView = false;
                    getPaginatedEvents();
                }

                function eventsOrderByDescFn() {
                    vm.field = 'fromDate';
                    vm.direction = 'desc';
                    vm.sorting = { field: vm.field, direction: vm.direction };
                    getPaginatedEvents();
                }

                function eventsOrderByAscFn() {
                    vm.field = 'fromDate';
                    vm.direction = 'asc';
                    vm.sorting = { field: vm.field, direction: vm.direction };
                    getPaginatedEvents();
                }

                function getFormattedDateFn(dateTime) {
                    return DateService.extractLocalizedDateFromTimeStamp(dateTime);
                }

                function getActiveTabFn() {
                    return ActiveTabService.getEventsTab();
                }

                function setActiveTabFn(tab) {
                    return ActiveTabService.setEventsTab(tab);
                }

                function viewOfferFn(offerId, requestId, eventId) {
                    $state.go('event.detail.overview', { eventId: eventId, requestId: requestId, offerId: offerId });
                }

                function eventSearchFn() {
                    resetPaginationParams();
                    vm.searchPattern = vm.searchText;
                    getPaginatedEvents();
                    vm.currentPage = 1;
                    vm.searchResult = true;
                    if (vm.searchText || vm.toDate || vm.fromDate) {
                        vm.showClear = true;
                    } else {
                        vm.showClear = false;
                    }
                }

                function openCreateEventModalFn() {
                    if(vm.viewCreateEventModal) {
                        return;
                    }
                    vm.viewCreateEventModal = true;
                    let createEventModalCtrlData = createEventModalCtrl;

                    ModalService.showModal(createEventModalCtrlData).then((modal) => {
                        vm.viewCreateEventModal = false;
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                    });
                }

                function pageChangedFn() {
                    setPaginationParams();
                    getPaginatedEvents();
                }

                function resendActivationEmailFn() {
                    vm.data = {
                        email: vm.loggedUserEmail,
                        username: vm.loggedUserUsername,
                    };
                    $http
                        .post('api/v1/register/resendActivationEmail', vm.data)
                        .then(function () {
                            $rootScope.dialog('alert', { message: 'dialog.activation.message.sent' })
                        }, $scope.endWithAlert);
                }

                function searchTextChangedFn() {
                    vm.showClear = false;
                }

                // function showAddUsersDialogFn() {
                //     refreshUserData();
                //     let data = {
                //         group: vm.userGroup
                //     };
                //     let addUserToUserGroupModalData = addUserToUserGroupModal;
                //     addUserToUserGroupModalData.inputs = { data };
                //
                //     ModalService.showModal(addUserToUserGroupModalData).then((modal) => {
                //         modal.element.modal();
                //         modal.close.then(() => {
                //             console.log('modal is closed!');
                //         });
                //     });
                // }

                function showAddUsersDialogFn() {
                    refreshUserData();
                    OgModal.open("add:user:to:user:group", { group: vm.userGroup });
                }

                function showCreateEventUsingTemplateOrGuideFn() {
                    OgModal.open('create:event:using:template:or:guide', { publicUrl: CommonFunctions.getPublicURL() });
                }

                function resetPaginationParams() {
                    vm.params = { offset: 0, max: vm.numPerPage };
                    vm.totalLength = 0;
                }

                function stripHtmlTagsFn(str) {
                    if (str === null || str === '') {
                        return false;
                    } else {
                        str = str.toString();
                        var stripedhtml = str.replace(/(<([^>]+)>)/gi, '');
                        return htmlDecode(stripedhtml);
                    }
                }

                function htmlDecode(input) {
                    var e = document.createElement('div');
                    e.innerHTML = input;
                    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
                }

                function setPaginationParams() {
                    vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                    vm.params.max = vm.numPerPage;
                }

                function refreshUserData() {
                    var userData = AuthenticationService.getUserData();
                    if (userData !== null) {
                        vm.userGroup = userData.userGroup;
                        vm.loggedUserEmail = userData.email;
                        vm.loggedUserUsername = userData.username;
                        vm.loggedUserName = userData.name;
                    }
                }

                function scrollToLastActivitiesFn() {
                    ogScrollService.scrollToElement('event-container');
                }

                function getPaginatedEvents() {
                    $scope.startProgress();
                    var params = {
                        offset: vm.params.offset,
                        max: vm.numPerPage,
                        status: vm.selectedStatus,
                        pagination: true,
                        fromDateStart: vm.fromDate,
                        fromDateEnd: vm.toDate,
                        sorting: vm.sorting,
                    };
                    if (vm.searchText !== null) {
                        params.searchText = vm.searchText;
                    }
                    Event.findAllEvents(
                        params,
                        function (response) {
                            vm.filteredEvents = response.list;
                            vm.totalCount = response.totalCount;
                            // vm.totalLength = Math.ceil(response.totalCount / vm.numPerPage);
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function updateSelectedEventNameFn(eventName) {
                    vm.searchText = eventName;
                    if (_.isNull(vm.searchText)) {
                        resetPaginationParams();
                        vm.searchPattern = '';
                        getPaginatedEvents();
                    }
                }

                function setEventNameAndSearchFn(eventName) {
                    vm.searchText = eventName;
                    getPaginatedEvents();
                }

                function getRemoteUrl() {
                    if (vm.selectedStatus) {
                        vm.remoteUrl.searchParams.append('status', vm.selectedStatus);
                    }
                    if (vm.fromDate) {
                        vm.remoteUrl.searchParams.append('fromDate', vm.fromDate);
                    }
                    if (vm.toDate) {
                        vm.remoteUrl.searchParams.append('toDate', vm.toDate);
                    }
                }

                $scope.$on('event:template:create', function (event, value) {
                    Event.save(value, function (response) {
                        $location.url('/event/' + response.id);
                    });
                });

                $scope.$on('eventListCtrl.eventsCtrl.selectedStatus/changed', function (event, value) {
                    angular.forEach(vm.statusFilterArray, function (item) {
                        if (item.title === value) {
                            $rootScope.selectedStatus = value;
                            resetPaginationParams();
                            // setPaginationParams();
                            $scope.startProgress();
                            vm.searchText = '';
                            vm.fromDate = '';
                            vm.toDate = '';
                            vm.searchPattern = '';
                            vm.showClear = false;
                            getPaginatedEvents();
                            vm.remoteUrl.searchParams.set('status', vm.selectedStatus);
                            vm.currentPage = 1;
                            $scope.$broadcast('angucomplete-alt:clearInput');
                            $scope.endProgress();
                        }
                    });
                });

                $scope.$on(EventEnum.USER_DATA_CHANGED, refreshUserData);
                $scope.$on(EventEnum.USER_GROUP_CHANGED_SILENT, refreshUserData);
                $scope.$on(EventEnum.USER_GROUP_CHANGED, refreshUserData);
                $scope.$on(EventEnum.USER_IN_GROUP_DATA_CHANGED, refreshUserData);

                var profilePictureChangedEvent = $rootScope.$on(EventEnum.USER_PROFILE_PICTURE_CHANGED, function (event, data) {
                    angular.forEach(vm.usersInGroup, function (user, index) {
                        if (user.id == data.id) {
                            vm.usersInGroup.splice(index, 1, data);
                        }
                    });
                });
                $scope.$on('$destroy', profilePictureChangedEvent);
            }
        ]
    });
