import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

class SupplierDefaultResourceForm {
    constructor(
        $scope,
        $translate,
        $rootScope,
        SupplierResources,
        FilledSupplierResourceField,
        DynamicFormUtilService,
        CommonFile
    ) {
        this._injected = {
            $scope,
            $translate,
            $rootScope,
            SupplierResources,
            FilledSupplierResourceField,
            DynamicFormUtilService,
            CommonFile,
        };
        this.list = [];
        this.originalList = [];
        this.editResource = true;
        this.setImageAndDescription = false;
        this.setImageSectionOpen = false;
        this.offerPartType = { name: '', nameCode: '' };
        ScopeFunctions.addToScope($scope);
        this.currencyList = [
            {
                decimalSeparator: '.',
                iso: 'SEK',
                name: 'Swedish krona',
                prefixed: false,
                symbol: 'kr',
                thousandsSeparator: ' ',
            },
            {
                decimalSeparator: '.',
                iso: 'EUR',
                name: 'Euro',
                prefixed: false,
                symbol: '€',
                thousandsSeparator: '.',
            },
            {
                decimalSeparator: '.',
                iso: 'GBP',
                name: 'British Pound',
                prefixed: true,
                symbol: '£',
                thousandsSeparator: ',',
            },
            {
                decimalSeparator: ',',
                iso: 'DKK',
                name: 'Danish krone',
                prefixed: true,
                symbol: 'Dkk',
                thousandsSeparator: '.',
            },
            {
                decimalSeparator: ',',
                iso: 'NOK',
                name: 'Norwegian Krone',
                prefixed: false,
                symbol: 'NOK',
                thousandsSeparator: ' ',
            },
        ];

        this.remoteUrl = CommonFile.getBaseUrl() + 'api/v1/offerPartType';

        $scope.$watch('supplierDefaultResourceFormCtrl.resourceData', (newValue, oldValue) => {
            if (newValue) {
                this.resource = newValue;
                this.resourceName = this.resource.name;
                this.resourceDescription = this.resource.description;
                this.includes = this.resource.includes;
                this.pricePerPerson = this.resource.pricePerPerson;
                this.selectedCurrencyIso = this.resource.currency;
                this.serviceAreas = this.resource.serviceAreas;
                this.getList(this.resource.offerPartTypeNameCode);
                this.offerPartType['nameCode'] = this.resource.offerPartTypeNameCode;
                if (this.offerPartType['nameCode']) {
                    this.offerPartType['name'] = $translate.instant(this.offerPartType['nameCode']);
                }
            }
        });
    }

    getList(offerPartTypeNameCode) {
        const { FilledSupplierResourceField, $translate, $rootScope, $scope, DynamicFormUtilService } = this._injected;
        if (offerPartTypeNameCode) {
            $scope.startProgress();
            FilledSupplierResourceField.list(
                'ALL',
                offerPartTypeNameCode,
                this.resource.id,
                this.supplierId,
                (resp) => {
                    if (resp) {
                        this.list = resp;
                        angular.copy(resp, this.originalList);
                        angular.forEach(this.list, (list) => {
                            if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                                let _listValuesCode = list.listValueCodes;
                                let _listValues = $translate.instant(_listValuesCode);
                                let _fieldValue;
                                if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                                    _fieldValue = false;
                                } else {
                                    _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                                }
                                list.fieldValue = DynamicFormUtilService.getFieldValue(
                                    _listValues,
                                    $rootScope.langSelected,
                                    _fieldValue
                                );
                            }
                            if (['ICON_RADIO', 'ICON_MULTI_CHECKBOX'].indexOf(list.type.name) > -1) {
                                let _iconList = list.iconList;
                                angular.forEach(_iconList, function (icon) {
                                    icon.name = $translate.instant(icon.nameCode);
                                });
                            }
                        });
                        $scope.endProgress();
                    }
                },
                function (e) {
                    console.log('error ', e);
                    $scope.endWithError();
                }
            );
        }
    }

    save() {
        const { $scope, SupplierResources, FilledSupplierResourceField, $translate } = this._injected;
        this.offerPartType['nameCode'] = this.resourceData.offerPartTypeNameCode;
        $scope.startProgress();
        let _params = {
            resourceId: this.resourceData.id,
            supplierId: this.supplierId,
            resourceName: this.resourceName,
            includes: this.includes,
            pricePerPerson: this.pricePerPerson,
            currency: this.selectedCurrencyIso,
            resourceStatus: this.resourceData.resourceStatus.name,
            offerPartTypeNameCode: this.offerPartType['nameCode'],
            resourceDescription: this.resourceDescription,
            serviceAreas: this.serviceAreas,
        };
        SupplierResources.updateSupplierResource(
            _params,
            (data) => {
                this.resourceName = data.name;
                this.resourceDescription = data.description;
                this.selectedCurrencyIso = data.currency;
                this.offerPartType['nameCode'] = data.offerPartTypeNameCode;
                if (this.offerPartType['nameCode']) {
                    this.offerPartType['name'] = $translate.instant(this.offerPartType['nameCode']);
                }
                FilledSupplierResourceField.save(
                    this.resourceData.id,
                    this.supplierId,
                    this.list,
                    this.resourceData.commentByCreator,
                    (resp) => {
                        this.list = resp;
                        angular.forEach(this.list, (list) => {
                            if (['ICON_MULTI_CHECKBOX'].indexOf(list.type.name) > -1) {
                                let _iconList = list.iconList;
                                angular.forEach(_iconList, function (icon) {
                                    icon.name = $translate.instant(icon.nameCode);
                                });
                            }
                        });
                        this.goBack();
                        $scope.endProgress();
                    },
                    function (e) {
                        console.log('error ', e);
                        $scope.endWithError();
                    }
                );
            },
            $scope.endWithErrorOverlay()
        );
    }

    deleteAttachment(currentAttachmentId) {
        var data = {
            resourceId: this.resourceData.id,
            currentAttachmentId: currentAttachmentId,
            supplierId: this.supplierId,
        };
        SupplierResources.deleteAttachment(data, function (response) {
            this.resource = response;
            this.attachedFiles = response.resourceAttachments;
        });
    }

    updateSelectedPartName(name, nameCode) {
        this.offerPartType['name'] = name;
        this.offerPartType['nameCode'] = nameCode;
    }

    goBack() {
        this.cancelCallback();
    }

    imageSectionOpen(res) {
        this.setImageSectionOpen = res;
    }
}
SupplierDefaultResourceForm.$inject = [
    '$scope',
    '$translate',
    '$rootScope',
    'SupplierResources',
    'FilledSupplierResourceField',
    'DynamicFormUtilService',
    'CommonFile',
];
export default SupplierDefaultResourceForm;
