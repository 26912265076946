export default function (appModule) {
    appModule.config([
        '$routeProvider',
        '$locationProvider',
        '$stateProvider',
        '$urlRouterProvider',
        function ($routeProvider, $locationProvider, $stateProvider, $urlRouterProvider) {
            $urlRouterProvider.when(
                '/event/:eventId/participants/design_invitation',
                '/event/:eventId/participants/design_invitation/form'
            );

            $stateProvider
                .state('event.detail.participants.invitation', {
                    url: '/form',
                    template: '<participantinvitation></participantinvitation>',
                })
                .state('event.detail.participants.design_invitation.design-form', {
                    url: '/form',
                    template: '<participantinvitationdesign></participantinvitationdesign>'
                })
                .state('event.detail.participants.badgePrinting', {
                    url: '/printing',
                    template: '<participantinvitationdesignbadge></participantinvitationdesignbadge>'
                })
                .state('event.detail.participants.design_invitation.design-registration_form', {
                    url: '/registration_form',
                    template: '<participantsregistrationform></participantsregistrationform>'
                })
                .state('event.detail.participants.design_invitation.design-event-infosite', {
                    url: '/event_infosite',
                    template: '<designeventinfosite></designeventinfosite>'
                })
                .state('event.detail.participants.design_invitation.design-confirm-email', {
                    url: '/confirmation_email',
                    template: '<confirmemaildesign></confirmemaildesign>'
                });
            /*          .state('event.detail.participants.design_invitation.design-badge', {
                            url: '/badge',
                            templateUrl: 'app/event/event_detail/participants/invitation/design-badge/drag_drop.view.html',
                            controller: 'DragDropController',
                            controllerAs: 'dragDropCtrl'
                          });*/
        },
    ]);
}