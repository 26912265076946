const EXCLUDE_PARTS_BY_OFFER_AND_PARTS_STATUS = {
    ANSWERED: ['DELETED_BY_CREATOR'],
};
const EXCLUDE_PARTS_BY_PART_STATUS = ['NOT_DELIVERABLE'];

class RequestOfferPartOptionsUtil {
    constructor() {
    }

    filterOfferPartOptions(offer, offerPartOptions) {
        let _offerStatus;
        if (offer) {
            _offerStatus = offer.status.name;
        }
        let _filteredOfferPartOptions = [];
        offerPartOptions.forEach((offerPartOption) => {
            if (this._isEligible(_offerStatus, offerPartOption.status.name)) {
                _filteredOfferPartOptions.push(offerPartOption);
            }
        });
        return _filteredOfferPartOptions;
    }

    _isEligible(offerStatus, partOptionStatus) {
        if (EXCLUDE_PARTS_BY_PART_STATUS.indexOf(partOptionStatus) > -1) {
            return false;
        }
        return true;
    }
}

const requestOfferPartOptionsUtil = new RequestOfferPartOptionsUtil();
export default requestOfferPartOptionsUtil;
