import bookingTermsEditModal from '../../../../modals/modals2.0/booking-terms-edit';
import fullBookingTermsEditModal from '../../../../modals/modals2.0/full-booking-terms';
import conformationModal from '../../../../modals/modals2.0/conformation-modal';
import contactUsModal from '../../../../modals/modals2.0/contact-us-modal';
import amountAndPriceService from '../../../../../utils/price_calculate/PriceCalculationSupplierUtil';
import UserGroupData from '../../../../../core/existingServices/data_store/UserGroupData';
import AccommodationUtil from '../../../../../utils/accommodation/AccommodationUtil';
import { OfferPartUtilFunctions } from '../../../../../utils/global/OfferPartUtilFunctions';
import { DownloadUtilFunctions } from '../../../../../utils/global/DownloadUtilFunction';
import * as _ from 'underscore';
import tooltipUtil from '../../../../../utils/negotiation_notification/NegotiationTooltipSupplierUtil';
import profileSettingsModal from '../../../../modals/modals2.0/profile-settings-modal';
import { ScopeFunctions } from '../../../../../utils/global/scopeFunctions';
import { UtilFunctions } from '../../../../../utils/global/utilFunctions';
import supplierAgreementReferenceInfoModal from '../../../../modals/modals2.0/supplier-agreement-reference-info-modal';
import supplierDiscountInfoModal from '../../../../modals/modals2.0/supplier-discount-info-modal';


'use strict';
const EDITED_BY_SUPPLIER_PART_STATE = ['EDITED_BY_SUPPLIER'];
const SHOW_SUPPLIER_PRICE_STATE = ['SENT', 'EDITED_BY_CREATOR', 'ADDED_WAITING_FOR_SUPPLIER'];
const CONFERENCE_OFFERPART_NAMECODE = ['common.translate.offer.part.type.conference.activities'];
const DAY_DROPDOWN_LIST = [5, 10, 15, 20, 30, 40, 50, 60, 70, 80];
const HIDE_CHANGE_TOOLTIP_PART_STATUS = ['EDITED_WAITING_FOR_CREATOR', 'EDITED_WAITING_FOR_SUPPLIER'];
const DISABLED_VAT_EDIT_OFFER_STATUS = [
    'NEW',
    'CANCELED',
    'ANSWERED',
    'BOOKED_WAITING_FOR_CONFIRMATION',
    'BOOKED',
    'APPROVED',
    'BOOKED_LOCKED_BY_CREATOR',
    'BOOKED_LOCKED_BY_SUPPLIER',
    'BOOKED_WAITING_FOR_CREATOR',
    'EDITED_BY_CREATOR',
];
const SHOW_BG_COLOR_FOR_ROW_OFFERS_STATUS = [
    'BOOKED_LOCKED_BY_SUPPLIER',
    'SENT',
    'BOOKED_WAITING_FOR_SUPPLIER',
    'BOOKED_WAITING_FOR_CREATOR',
    'FINISHED',
    'FINISHED_WAITING_FOR_CREATOR',
    'FINISHED_WAITING_FOR_SUPPLIER',
];
const DRAW_STRIKE_THROUGH_LINE = [
    'NOT_DELIVERABLE',
    'ADDED_WAITING_FOR_SUPPLIER_DECLINED',
    'ADDED_WAITING_FOR_CREATOR_DECLINED',
    'DELETED_WAITING_FOR_CREATOR',
];
const DISPLAY_TOOLTIP_STATUS = [
    'ANSWERED',
    'SENT',
    'BOOKED_LOCKED_BY_SUPPLIER',
    'BOOKED_WAITING_FOR_SUPPLIER',
    'BOOKED_WAITING_FOR_CREATOR',
    'FINISHED_WAITING_FOR_SUPPLIER',
    'FINISHED_WAITING_FOR_CREATOR',
];

angular.module('supplier.offer.detail').component('offerDetail', {
    templateUrl: './app/components/modules/supplier/offer/offer_detail/offer_detail.view.html?v=nov2023',
    controllerAs: 'offerDetailCtrl',
    controller: [
        '$scope',
        '$rootScope',
        '$stateParams',
        '$location',
        '$timeout',
        'Page',
        'RequestOffer',
        'Presentation',
        'RequestOfferPart',
        'AuthenticationService',
        '$translate',
        'GlobalConstants',
        'ogScrollService',
        'Activity',
        'ChatMessageCountStore',
        '$filter',
        'ModalService',
        'EventEnum',
        '$state',
        'Notification',
        'DateService',
        'FilledRequestOfferPartComment',
        'ParticipantActivity',
        'ParticipantSummary',
        'EventFinalDetails',
        'EventSchedule',
        'TimezoneService',
        'OfferInfoStatus',
        'DynamicFormUtilService',
        'RequestOfferStatusUtils',
        'ChatMessage',
        'ActiveTabService',
        'BrowserDetectService',
        'ParticipantService',
        'TooltipValueService',
        'FieldChangedSvc',
        'GlobalOption',
        'ClientService',
        'PopoverTypeEnum',
        '$sce',
        'CommonFunctions',
        function SupplierOfferControllerFn(
            $scope,
            $rootScope,
            $stateParams,
            $location,
            $timeout,
            Page,
            RequestOffer,
            Presentation,
            RequestOfferPart,
            AuthenticationService,
            $translate,
            GlobalConstants,
            ogScrollService,
            Activity,
            ChatMessageCountStore,
            $filter,
            ModalService,
            EventEnum,
            $state,
            Notification,
            DateService,
            FilledRequestOfferPartComment,
            ParticipantActivity,
            ParticipantSummary,
            EventFinalDetails,
            EventSchedule,
            TimezoneService,
            OfferInfoStatus,
            DynamicFormUtilService,
            RequestOfferStatusUtils,
            ChatMessage,
            ActiveTabService,
            BrowserDetectService,
            ParticipantService,
            TooltipValueService,
            FieldChangedSvc,
            GlobalOption,
            ClientService,
            PopoverTypeEnum,
            $sce,
            CommonFunctions
        ) {
            var vm = this;
            vm.viewModal = false;
            vm.DAY_DROPDOWN_LIST = DAY_DROPDOWN_LIST;
            vm.VAT_DROPDOWN_LIST = GlobalConstants.VAT_DROPDOWN_LIST;
            vm.DEFAULT_VAT = GlobalConstants.DEFAULT_VAT;
            vm.showSuccessMessage = false;

            vm.acceptAllCreatorChanges = acceptAllCreatorChangesFn;
            vm.acceptOfferPart = acceptOfferPartFn;
            vm.activateParticipantTab = activateParticipantTabFn;
            vm.activateParticipantSummaryTab = activateParticipantSummaryTabFn;
            vm.addANewOfferPart = addANewOfferPartFn;
            vm.addHints = addHintsFn;
            vm.addHintsForParticipantExport = addHintsForParticipantExportFn;
            vm.allowedToCancelBookedOffer = allowedToCancelBookedOfferFn;
            vm.applyBookedLockedStateChanges = applyBookedLockedStateChangesFn;
            vm.applyRowBgColor = applyRowBgColorFn;
            vm.applySupplierEditing = applySupplierEditingFn;
            vm.calculateTotalPrice = calculateTotalPriceFn;
            vm.getOfferCanceledAmountWithCurrency = getOfferCanceledAmountWithCurrencyFn;
            vm.calculateSafeCancellationDate = calculateSafeCancellationDateFn;
            vm.calculateExpirationDate = calculateExpirationDateFn;
            vm.cancelBookedLockedStateChanges = cancelBookedLockedStateChangesFn;
            vm.cancelConfirmation = cancelConfirmationFn;
            vm.canRespondToOffer = canRespondToOfferFn;
            vm.canSendOffer = canSendOfferFn;
            vm.checkIfFieldValueChanged = checkIfFieldValueChangedFn;
            // vm.checkIfParticipantUpdating = checkIfParticipantUpdatingFn;
            vm.checkIfAmountTypeHasChanged = checkIfAmountTypeHasChangedFn;
            vm.clearAttachedFiles = clearAttachedFilesFn;
            vm.clearCovidMeasures = clearCovidMeasuresFn;
            vm.closeDeletePartDialog = closeDeletePartDialogFn;
            vm.confirmDeclineOffer = confirmDeclineOfferFn;
            vm.confirmDeletePart = confirmDeletePartFn;
            vm.confirmBookedOffer = confirmBookedOfferFn;
            vm.confirmDeleteOfferPart = confirmDeleteOfferPartFn;
            vm.createPart = createPartFn;
            vm.openConfirmationModal = openConformationModalFn;
            vm.declineAllCreatorChanges = declineAllCreatorChangesFn;
            vm.declineOffer = declineOfferFn;
            vm.declineOfferPart = declineOfferPartFn;
            vm.deleteOfferPart = deleteOfferPartFn;
            vm.deletePart = deletePartFn;
            vm.disabled = disabledFn;
            vm.disableOfferPartInfo = disableOfferPartInfoFn;
            vm.enableFoldOut = enableFoldOutFn;
            vm.disablePriceEdit = disablePriceEditFn;
            vm.disableVatEdit = disableVatEditFn;
            vm.disableParticipantListLoadingForSupplier = disableParticipantListLoadingForSupplierFn;
            vm.disableSendActionForSupplier = disableSendActionForSupplierFn;
            vm.disabledExpirationDateEdit = disabledExpirationDateEditFn;
            vm.disabledCanceledPeriodDateEdit = disabledCanceledPeriodDateEditFn;
            vm.checkCancellationPeriodChange = checkCancellationPeriodChangeFn;
            vm.checkExpirationDateChange = checkExpirationDateChangeFn;
            vm.displayOfferPartChangeSummary = displayOfferPartChangeSummaryFn;
            vm.displayOfferPartChangeToolTip = displayOfferPartChangeToolTipFn;
            vm.displayOfferPartChangeToolTipForName = displayOfferPartChangeToolTipForNameFn;
            vm.displayOfferPartChangeToolTipForAmountType = displayOfferPartChangeToolTipForAmountTypeFn;
            vm.displayFieldChangedForDate = displayFieldChangedForDateFn;
            vm.doStrikeThrough = doStrikeThroughFn;
            vm.editBookingTerms = editBookingTermsFn;
            vm.enableSelectedCurrencyUpdate = enableSelectedCurrencyUpdateFn;
            vm.fullBookingTerms = fullBookingTermsFn;
            vm.editPresentation = editPresentationFn;
            vm.enableOfferPartInfo = enableOfferPartInfoFn;
            vm.exitOfferGuide = exitOfferGuideFn;
            vm.fieldChangedForDate = fieldChangedForDateFn;
            vm.fieldChangedForPrice = fieldChangedForPriceFn;
            vm.fieldChangedForPriceInclVat = fieldChangedForPriceInclVatFn;
            vm.getDetailChangesValue = getDetailChangesValueFn;
            vm.getQuestionLabel = getQuestionLabelFn;
            vm.getUnreadMessageCount = getUnreadMessageCountFn;
            vm.getAmount = amountAndPriceService.getAmount;
            vm.viewAmountLocalisedWithCurrency = viewAmountLocalisedWithCurrencyFn;
            vm.getDifference = getDifferenceFn;
            vm.getRequestOfferPartOptionDifference = getRequestOfferPartOptionDifferenceFn;
            vm.getFormatedDate = getFormatedDateFn;
            vm.getTotalPrice = amountAndPriceService.getTotalPrice;
            vm.getRequestPartOptionTotalPrice = getRequestPartOptionTotalPriceFn;
            vm.getPrice = amountAndPriceService.getPrice;
            vm.getPriceExclVat = amountAndPriceService.getPriceExclVat;
            vm.getPriceInclVat = amountAndPriceService.getPriceInclVat;
            vm.getPriceWithVat = amountAndPriceService.getPriceWithVat;
            vm.getRequestOfferPartOptionPriceWithVat = getRequestOfferPartOptionPriceWithVatFn;
            vm.getDateSpan = DateService.getDateSpan;
            vm.getNotificationData = getNotificationData;
            vm.getOldValueForChangedTitle = getOldValueForChangedTitleFn;
            vm.getOriginalOfferPart = getOriginalOfferPartFn;
            vm.cancellationFeeSort = cancellationFeeSortFn;
            vm.getNotificationClassName = getNotificationClassNameFn;
            vm.getOfferPartTitle = getOfferPartTitleFn;
            vm.getOfferPartAdditionalQuestionClass = getOfferPartAdditionalQuestionClassFn;
            vm.getUnit = getUnitFn;
            vm.getTooltipValueName = getTooltipValueNameFn;
            vm.getTooltipValueAmount = getTooltipValueAmountFn;
            vm.getTooltipValueDate = getTooltipValueDateFn;
            vm.getTooltipValuePrice = getTooltipValuePriceFn;
            vm.getTooltipValueInfoMessageByStatus = getTooltipValueInfoMessageByStatusFn;
            vm.getTooltipValueAfterFinished = getTooltipValueAfterFinishedFn;
            vm.getTranslatedNotification = getTranslatedNotificationFn;
            vm.handleOnChangedCovidData = handleOnChangedCovidDataFn;
            vm.removeCovidFilesUploadCallback = removeCovidFilesUploadCallbackFn;
            vm.removeCovidFile = removeCovidFileFn;
            vm.hasValChanged = hasValChangedFn;
            vm.hasFieldValueChanged = hasFieldValueChangedFn;
            vm.hasAmountFieldValueChanged = hasAmountFieldValueChangedFn;
            vm.hasNameChanged = hasNameChangedFn;
            vm.hideAdditionalInfoIconForSupplier = hideAdditionalInfoIconForSupplierFn;
            vm.hideInfoIconForSupplier = hideInfoIconForSupplierFn;
            vm.init = initFn;
            vm.isDateLessThanCurrentDate = isDateLessThanCurrentDateFn;
            vm.isOfferPartInCreatorWaitingStates = isOfferPartInCreatorWaitingStatesFn;
            vm.isOfferPartInCreatorDeclinedStates = isOfferPartInCreatorDeclinedStatesFn;
            vm.isOfferPartInSupplierDeclinedStates = isOfferPartInSupplierDeclinedStatesFn;
            vm.isOfferPartInSupplierWaitingStates = isOfferPartInSupplierWaitingStatesFn;
            vm.isOfferPartInToAnswerStates = isOfferPartInToAnswerStatesFn;
            vm.listParticipantActivitiesForSupplier = listParticipantActivitiesForSupplierFn;
            vm.managePriceLock = managePriceLockFn;
            vm.makeNotificationSeen = makeNotificationSeenFn;
            vm.notDeliverableRequestOfferPart = notDeliverableRequestOfferPartFn;
            vm.offerPresentationDialog = offerPresentationDialogFn;
            vm.openInlineRowAddition = openInlineRowAdditionFn;
            vm.parseDateInLocaleFormat = parseDateInLocaleFormatFn;
            vm.parseDateInLocaleFormatTime = parseDateInLocaleFormatTimeFn;
            vm.parseDateInLocaleFormatWithoutTime = parseDateInLocaleFormatWithoutTimeFn;
            vm.participantTabLoaded = participantTabLoadedFn;
            vm.processCovidData = processCovidDataFn;
            vm.prolongOfferExpirationDate = prolongOfferExpirationDateFn;
            vm.prolongOfferCancelPeriodDate = prolongOfferCancelPeriodDateFn;
            vm.cancelCancelPeriodEndDate = cancelCancelPeriodEndDateFn;
            vm.cancelReservationCancelDays = cancelReservationCancelDaysFn;
            vm.reload = reloadFn;
            vm.responsibleChanged = responsibleChangedFn;
            vm.isOfferInAfterBookedStates = isOfferInAfterBookedStatesFn;
            vm.isOfferInFromBookedStates = isOfferInFromBookedStatesFn;
            vm.isOfferInBeforeBookedStates = isOfferInBeforeBookedStatesFn;
            vm.responsibleForBookingChanged = responsibleForBookingChangedFn;
            vm.responsibleForManagingChanged = responsibleForManagingChangedFn;
            vm.updateIncludeResponsibleBookingSupplierUser = updateIncludeResponsibleBookingSupplierUserFn;
            vm.changeHandOverDate = changeHandOverDateFn;
            vm.handleOnChanged = handleOnChangedFn;
            vm.openEditProfileModal = openEditProfileModalFn;
            vm.updateInternalReference = updateInternalReferenceFn;
            vm.closeInternalReferenceSection = closeInternalReferenceSectionFn;
            vm.revertToPreviousNotificationEmail = revertToPreviousNotificationEmailFn;
            vm.saveCovidAdaptationMeasures = saveCovidAdaptationMeasuresFn;
            vm.subsTractDateFromNow = subsTractDateFromNowFn;
            vm.subsTractNowFromDate = subsTractNowFromDateFn;
            vm.sendFinalPrices = sendFinalPricesFn;
            vm.deleteFileConfirmation = deleteFileConfirmationFn;
            vm.deleteFinalDetailsFile = deleteFinalDetailsFileFn;
            vm.sendCancellationFees = sendCancellationFeesFn;
            vm.isCancellationFees = isCancellationFeesFn;
            vm.isOfferInCanceledState = isOfferInCancelStateFn;
            vm.offerInFinishedState = offerInFinishedStateFn;
            vm.sendFinanceDepMessage = sendFinanceDepMessageFn;
            vm.sendOfferToCreator = sendOfferToCreatorFn;
            vm.splitAccommodationPart = splitAccommodationPartFn;
            vm.setMainThumbNailImage = setMainThumbNailImageFn;
            vm.setParticipantsNotificationType = setParticipantsNotificationTypeFn;
            vm.setNewPartPrice = setNewPartPriceFn;
            vm.resetNewPartPriceInclVat = resetNewPartPriceInclVatFn;
            vm.getPriceFromPartOption = getPriceFromPartOptionFn;
            vm.showAddRowBtn = showAddRowBtnFn;
            vm.showAmountChangeToolTip = showAmountChangeToolTipFn;
            vm.showBillingInfo = showBillingInfoFn;
            vm.showBookingTerms = showBookingTermsFn;
            vm.showDeleteRowBtn = showDeleteRowBtnFn;
            vm.getConfirmationTriggerType = getConfirmationTriggerTypeFn;
            vm.showCommission = showCommissionFn;
            vm.showSupplierBillingAddress = showSupplierBillingAddressFn;
            vm.showInfoTooltip = showInfoTooltipFn;
            vm.showMessages = showMessagesFn;
            vm.showNotification = showNotificationFn;
            vm.showPresentation = showPresentationFn;
            vm.showPriceChangeToolTip = showPriceChangeToolTipFn;
            vm.showPriceChangeToolTipForOfferByStatus = showPriceChangeToolTipForOfferByStatusFn;
            vm.showSupplierOptions = showSupplierOptionsFn;
            vm.showReEditPriceBtn = showReEditPriceBtnFn;
            vm.shouldShowBillingInfo = shouldShowBillingInfoFn;
            vm.shouldHideOfferPartDetails = shouldHideOfferPartDetailsFn;
            vm.supplierAcceptsRequestOfferPart = supplierAcceptsRequestOfferPartFn;
            vm.supplierDeclineRequestOfferPart = supplierDeclineRequestOfferPartFn;
            vm.supplierLogin = supplierLoginFn;
            vm.toggleShowNotificationSection = toggleShowNotificationSectionFn;
            vm.trustAsHtml = trustAsHtmlFn;
            vm.updateFinalPriceAndAmount = updateFinalPriceAndAmountFn;
            vm.getTooltipValuePartSpanSeveralDays = getTooltipValuePartSpanSeveralDaysFn;

            vm.updateCancelPeriodEndDate = updateCancelPeriodEndDateFn;
            vm.updateNotificationSettingsForOffer = updateNotificationSettingsForOfferFn;
            vm.updateNotificationEmailForOffer = updateNotificationEmailForOfferFn;
            vm.updateReservationCancelDays = updateReservationCancelDaysFn;
            vm.updateOfferPart = updateOfferPartOnClientSideOnlyFn;
            vm.updatePart = updatePartFn;
            vm.uploadFinalDetailsFiles = uploadFinalDetailsFilesFn;
            vm.checkAccommodationParts = checkAccommodationPartsFn;
            vm.getAccommodationParts = getAccommodationPartsFn;
            vm.updateROPPrice = updateROPPriceFn;
            vm.updateROPPriceInclVat = updateROPPriceInclVatFn;
            vm.updateROPVat = updateROPVatFn;
            vm.canSupplierEditPrice = canSupplierEditPriceFn;
            vm.updateSelectedCurrency = updateSelectedCurrencyFn;
            vm.undoOfferPartDeletion = undoOfferPartDeletionFn;
            vm.viewMessage = viewMessageFn;
            vm.updateAmount = updateAmountFn;
            vm.isDeliverable = isDeliverableFn;
            vm.shouldFillInCancelFee = shouldFillInCancelFeeFn;
            vm.canConfirmFeeReceived = canConfirmFeeReceivedFn;
            vm.getMessageOfSupplier = getMessageOfSupplierFn;
            vm.refreshHints = refreshHintsFn;
            vm.getAllActivities = getAllActivitiesFn;
            vm.getFinalDetails = getFinalDetailsFn;
            vm.isFinalDetailsChanged = isFinalDetailsChangedFn;
            vm.hasFinalDetailsValueChanged = hasFinalDetailsValueChangedFn;
            vm.hideUpdatedChanges = hideUpdatedChangesFn;
            vm.setActive = setActiveFn;
            vm.getActiveTab = getActiveTab;
            vm.pageChanged = pageChangedFn;
            vm.loadNextPage = loadNextPageFn;
            vm.getPaginatedActivities = getPaginatedActivitiesFn;
            vm.paginatedActivityStreamCallback = paginatedActivityStreamCallbackFn;
            vm.resetActivitiesList = resetActivitiesListFn;
            vm.updateCurrency = updateCurrencyFn;
            vm.exportOfferSummaryToPDF = exportOfferSummaryToPDFFn;
            vm.exportOfferForSupplier = exportOfferForSupplierFn;
            vm.exportFinalDetailsToPDF = exportFinalDetailsToPDFFn;
            vm.changeStatusToSent = changeStatusToSentFn;
            vm.checkAdditionalInfoChanged = checkAdditionalInfoChangedFn;
            vm.expandBtn = expandBtnFn;
            vm.revertFinalPriceChanges = revertFinalPriceChangesFn;
            vm.editToggle = editToggleFn;
            vm.checkCancelationFeeField = checkCancelationFeeFieldFn;
            vm.offerPartOptionsWithActionsCallback = offerPartOptionsWithActionsCallbackFn;
            vm.openContactUsModal = openContactUsModalFn;
            vm.canAddNewResource = canAddNewResourceFn;
            vm.checkForVatLimit = checkForVatLimitFn;
            vm.getVatFromPartOption = getVatFromPartOptionFn;
            vm.disableAccommodationPart = disableAccommodationPartFn;
            vm.consistSupplierSuggestionInBooked = consistSupplierSuggestionInBookedFn;
            vm.readonlySupplierPrice = readonlySupplierPriceFn;
            vm.isOfferInEditedByCreatorState = isOfferInEditedByCreatorStateFn;
            vm.offerInAllFinishedState = offerInAllFinishedStateFn;
            vm.redirectToOfferMessageSection = redirectToOfferMessageSectionFn;
            vm.checkIfOfferHasBeenAlreadyBooked = checkIfOfferHasBeenAlreadyBookedFn;
            vm.updateVatIncludedInPrice = updateVatIncludedInPriceFn;
            vm.updateROPPaidOnsite = updateROPPaidOnsiteFn;
            vm.isThereDifferenceInPaidOnsite = isThereDifferenceInPaidOnsiteFn;
            vm.hasUpdatedPaidOnsiteColumn = hasUpdatedPaidOnsiteColumnFn;
            vm.isOfferInApprovedState = isOfferInApprovedStateFn;
            vm.isOfferInFinishedOrApprovedState = isOfferInFinishedOrApprovedStateFn;
            vm.disablePaidOnsiteEdit = disablePaidOnsiteEditFn;
            vm.setOfferPartColWidth = setOfferPartColWidthFn;
            vm.isThereSentNotification = isThereSentNotificationFn;
            vm.getConferenceActivityLocation = getConferenceActivityLocationFn;
            vm.updateOfferDiscount = updateOfferDiscountFn;
            vm.calculateDiscountedPrice = calculateDiscountedPriceFn;

            vm.isThereConference = false;
            vm.isThereConferenceCategory = false;
            vm.noOfchanges = 0;
            vm.finalDetailsChangeList = [];
            vm.partCommentDataList = [];
            vm.additionalInfoList = [];
            vm.eventFinalDetails = [];
            vm.showAbsoluteDate = true;
            vm.hintTargetId = undefined;
            vm.showPriceEdit = false;
            vm.showDiffColumn = false;
            vm.showNotificationSection = true;
            vm.showInternalReferenceSection = false;
            vm.hasHandOverDateChanged = false;
            //var statusListToNotify = ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR',
            // 'APPROVED','BOOKED_WAITING_FOR_CONFIRMATION'];

            vm.activities = [];
            vm.activitiesLoading = false;
            vm.guideTypeExitedList = [];
            vm.participantTabActivated = false;
            vm.currentPage = 1;
            vm.numPerPage = 6;
            vm.languageSelected = $rootScope.langSelected;
            vm.additionalInfoChanges = [];
            vm.seeShowMoreBtn = false;
            vm.categoryIds = [];
            vm.sendOfferToCreatorConfirm = false;
            vm.isUsingClientBillingAddressAndIsEL = false;
            vm.isUsingClientBillingAddress = false;
            vm.paidOnsitePrice = 0;
            vm.outstandingAmount = 0;
            vm.updateOfferAgreementNumber = false;
            vm.showColorMeaning = false;

            const INITIAL_OFFSET = 0;
            const MAX_ACTIVITIES_COUNT_PER_FETCH = 5;

            var activitySearchParams = { offset: INITIAL_OFFSET, max: MAX_ACTIVITIES_COUNT_PER_FETCH };

            const GUIDE_TYPES = {
                participant_tab_header: GlobalConstants.SHOW_PARTICIPANT_INFO,
                participant_dropdown_export: GlobalConstants.SHOW_EXPORT_INFO,
            };

            ScopeFunctions.addToScope($scope);
            vm.init('top');
            $rootScope.bodyClassForController($scope, 'supplierscreen');

            var confirmationNotification = $location.search().confirmation;

            if (confirmationNotification) {
                $rootScope.dialog('alert', { message: confirmationNotification });
                $location.search('').replace();
            }

            function acceptAllCreatorChangesFn() {
                vm.supplierActionOnCreatorChanges = 'ACCEPT_ALL';
                insertAllSupplierWaitingRowsAsAccepted();
                $rootScope.$broadcast('update:accept:all:part:options', {
                    offerPartOptionsWithActions: vm.offerPartOptionsWithActions,
                });
                vm.btnMap = { name: 'requestOfferPart.button.accept.all.changes', ngclass: 'spAccept' };
                var element = jQuery('#supplier-all-options');
                element.css('visibility', 'hidden');
                element.removeClass('open');
            }

            function checkForVatLimitFn(offerPart) {
                if (offerPart.vat.length >= 3 && !offerPart.vat.includes('.')) {
                    offerPart.vat = Math.floor(offerPart.vat / 10);
                }
            }

            function acceptOfferPartFn(offerPart) {
                var offerPartMap = { offerPart: offerPart, action: 'ACCEPTED_BY_SUPPLIER' };
                removeOfferPartFromMap(offerPart);
                vm.offerPartsWithActions.push(offerPartMap);

                var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                reassignOriginalValuesToOfferPart(offerPart, originalOfferPart);
                if (originalOfferPart.status.name == 'EDITED_WAITING_FOR_SUPPLIER') {
                    offerPart.status.name = 'ACCEPTED';
                }
                if (originalOfferPart.status.name == 'ADDED_WAITING_FOR_SUPPLIER') {
                    offerPart.status.name = 'ACCEPTED';
                }
                if (originalOfferPart.status.name == 'DELETED_WAITING_FOR_SUPPLIER') {
                    offerPart.status.name = 'NOT_DELIVERABLE';
                }
                vm.flagForAcceptedWithChanges = undefined;

                if (vm.supplierActionOnCreatorChanges == 'DECLINE_ALL') {
                    vm.btnMap = { name: 'requestOfferPart.button.action.all', ngclass: 'spAction' };
                }
            }

            function activateParticipantTabFn() {
                vm.setActive('participants');
                let _currentUrl = $state.current;
                if (_currentUrl !== 'offer.detail') {
                    $state.go('offer.detail', { offerId: vm.offerId });
                }
                vm.participantTabActivated = true;
                $('#supplier-tabs a[href="#participants"]').tab('show');
                if (vm.guideType !== GlobalConstants.SHOW_NONE) {
                    $timeout(() => {
                     //   addHintsForParticipantExportFn();
                    }, 300);
                }
            }

            function activateParticipantSummaryTabFn() {
                vm.setActive('participants_summary');
                let _currentUrl = $state.current;
                if (_currentUrl !== 'offer.detail') {
                    $state.go('offer.detail', { offerId: vm.offerId });
                }
                $('#supplier-tabs a[href="#participants_summary"]').tab('show');
                getParticipantSummary();
                getParticipantActivityNotificationSettings();
            }

            function getParticipantSummary() {
                $scope.startProgress();
                ParticipantSummary.getSummary(
                    vm.eventId,
                    function (response) {
                        vm.summary = response;
                        ogScrollService.scrollToElement('participants_summary');
                        $scope.endProgress();
                    },
                    $scope.endWithErrorOverlay
                );
            }

            function getAllActivitiesFn() {
                vm.setActive('all_activities');
                let _currentUrl = $state.current;
                if (_currentUrl !== 'offer.detail') {
                    $state.go('offer.detail', { offerId: vm.offerId });
                }
                $('#supplier-tabs a[href="#all_activities"]').tab('show');
                vm.resetActivitiesList();
                vm.getPaginatedActivities();
            }

            function getFinalDetailsFn() {
                angular.forEach(vm.categories, (category) => {
                    if (
                        !vm.isThereConferenceCategory &&
                        category.nameCode &&
                        category.nameCode === 'common.translate.category.conference.room'
                    ) {
                        vm.isThereConferenceCategory = true;
                    }
                });
                if (!vm.isThereConferenceCategory) {
                    angular.forEach(vm.offer.requestOfferParts, (part) => {
                        if (!vm.isThereConference && part.nameCode === 'common.translate.offer.part.type.conference.room') {
                            vm.isThereConference = true;
                        }
                    });
                }
                vm.setActive('final_details');
                let params = {
                    eventId: vm.eventId,
                    offerId: vm.offerId,
                    isThereConference: vm.isThereConference || vm.isThereConferenceCategory,
                };
                EventFinalDetails.getEventFinalDetails(params, function (response) {
                    vm.eventFinalDetails = response;
                    isFinalDetailsChangedFn(response.oldFinalDetailsJson, response.newFinalDetailsJson);
                });
                EventSchedule.getScheduleForSupplier(vm.eventId, vm.offerId, (response) => {
                    vm.scheduleForSupplier = response;
                });
            }

            function difference(o1, o2) {
                var diff = {};
                var tmp = null;
                if (JSON.stringify(o1) === JSON.stringify(o2)) return;

                for (var k in o1) {
                    if (Array.isArray(o1[k]) && Array.isArray(o2[k])) {
                        tmp = o1[k].reduce(function (p, c, i) {
                            var _t = difference(c, o2[k][i]);
                            if (_t) p.push(_t);
                            return p;
                        }, []);
                        if (Object.keys(tmp).length > 0) diff[k] = tmp;
                    } else if (typeof o1[k] === 'object' && typeof o2[k] === 'object') {
                        tmp = difference(o1[k], o2[k]);
                        if (tmp && Object.keys(tmp) > 0) diff[k] = tmp;
                    } else if (o1[k] !== o2[k]) {
                        diff[k] = o2[k];
                    }
                }
                return diff;
            }

            function isFinalDetailsChangedFn(oldFinalDetailsJson, newFinalDetailsJson) {
                if (!!vm.eventFinalDetails.showUpdatedChangesToSupplier) {
                    if (!!oldFinalDetailsJson && !!newFinalDetailsJson) {
                        var _oldFinalDetails = JSON.parse(oldFinalDetailsJson);
                        var _newFinalDetails = JSON.parse(newFinalDetailsJson);
                        var _difference = difference(_oldFinalDetails, _newFinalDetails);
                        var length = 0;
                        if (_difference.finalDetails) {
                            angular.forEach(_difference.finalDetails, (finalDetails) => {
                                angular.forEach(Object.keys(finalDetails), (key) => {
                                    vm.finalDetailsChangeList.push(key);
                                    length += 1;
                                });
                            });
                        }
                        if (_difference.partCommentData) {
                            angular.forEach(_difference.partCommentData, (partCommentData) => {
                                angular.forEach(Object.keys(partCommentData), (key) => {
                                    vm.partCommentDataList.push(key);
                                    length += 1;
                                });
                            });
                        }
                        if (_difference.additionalInfo) {
                            angular.forEach(_difference.additionalInfo, (additionalInfo) => {
                                angular.forEach(Object.keys(additionalInfo), (key) => {
                                    vm.additionalInfoList.push(key);
                                    length += 1;
                                });
                            });
                        }
                        vm.noOfchanges = length;
                    }
                }
            }

            function hasFinalDetailsValueChangedFn(model) {
                if (!!vm.eventFinalDetails.showUpdatedChangesToSupplier) {
                    return vm.finalDetailsChangeList.indexOf(model) !== -1;
                }
                return false;
            }

            function hideUpdatedChangesFn() {
                $scope.startProgress(vm.requestId);
                let params = {
                    eventId: vm.eventId,
                    offerId: vm.offerId,
                    isThereConference: vm.isThereConference || vm.isThereConferenceCategory,
                };
                EventFinalDetails.hideUpdatedChanges(params, (response) => {
                    vm.eventFinalDetails = response;
                    $scope.endProgress(vm.requestId);
                });
            }

            function pageChangedFn() {
                setPaginationParams();
                vm.loadNextPage();
            }

            function setPaginationParams() {
                vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                vm.params.max = vm.numPerPage;
            }

            function loadNextPageFn(refresh) {
                if (vm.loading) {
                    return;
                }
                if (!refresh && vm.totalActivitiesCount !== -1) {
                    if (vm.activities.length >= vm.totalActivitiesCount) {
                        return;
                    }
                }
                vm.loading = true;
                vm.getPaginatedActivities();
            }

            function expandBtnFn() {
                var text = jQuery('.expandable-box .text');
                var readMore = jQuery('.expandable-box .read-more');
                var btnText = document.getElementById('readBtn');
                var textHeight = text[0].scrollHeight;
                var newHeight = 0;
                if (text.hasClass('active')) {
                    newHeight = vm.dynamicTextHeight;
                    btnText.innerHTML = $translate.instant('common.read.more');
                    vm.showMore = false;
                    text.removeClass('active');
                    readMore.css({ padding: '15% 0px 0px' });
                } else {
                    newHeight = textHeight;
                    btnText.innerHTML = $translate.instant('common.read.less');
                    vm.showMore = true;
                    text.addClass('active');
                    readMore.css({ padding: '0px' });
                }
                text.animate(
                    {
                        'max-height': newHeight,
                    },
                    500
                );
            }

            function revertFinalPriceChangesFn() {
                vm.paidOnsiteDisabled = true;
                $scope.startProgress('top');
                RequestOffer.revertFinalPriceChanges(
                    vm.offerId,
                    (res) => {
                        $rootScope.$broadcast('revert.final.prices.by.supplier');
                        if (res) {
                            vm.reload();
                        }
                    },
                    $scope.endWithAlert
                );
            }

            function getPaginatedActivitiesFn() {
                var params = {
                    eventId: vm.eventId,
                    page: vm.params.offset,
                    pageSize: vm.numPerPage,
                    type: 'all',
                };
                if (vm.level === 'EVENT_USERS') {
                    params.eventOwnerId = vm.eventOwnerId;
                } else {
                    params.requestId = vm.requestId;
                    params.offerId = vm.offerId;
                }
                $scope.startProgress('activities');
                vm.loading = true;
                Activity.getAllActivities(
                    params,
                    vm.paginatedActivityStreamCallback,
                    $scope.endWithErrorOverlay('activities')
                );
            }

            function paginatedActivityStreamCallbackFn(response) {
                $scope.endProgress('activities');
                vm.activities = response.list;
                vm.streamSpinner = false;
                vm.messageSpinner = false;
                vm.loading = false;
                vm.totalActivitiesCount = response.totalCount;
                if (vm.activities.length !== 0 && vm.activities.length % 20 === 0) {
                    vm.restrictAutoLoad = true;
                }
                vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                /*if(vm.showActivityMessageId) {
             setCreatorChatMessages(vm.showActivityMessageId);
             } else {
             if(vm.stateMessageId) {
             var selectedMessage = _.find(vm.activities, (act) => {
             let _find = act.id + "" === vm.stateMessageId + "";
             if(_find) {
             return _find;
             } else {
             var childMessage = _.find(act.childMessages, (ch) => {
             return ch.id + "" === vm.stateMessageId + "";
             });
             return !!childMessage;
             }
             });
             if(selectedMessage) {
             vm.showMessageDetails(selectedMessage);
             }
             }
             }*/
            }

            function resetActivitiesListFn() {
                vm.params = { offset: 0, max: vm.numPerPage };
                vm.loading = false;
                vm.totalActivitiesCount = -1; //default value
                vm.activities = [];
            }

            function addHintsFn() {
                vm.intro = introJs();
                let hints = [];
                hints.push(getHintForParticipantTab());
                vm.intro.setOptions({ hints: hints, hintButtonLabel: `${$translate.instant('common.introjs.got.it')}` });

                vm.intro.onhintsadded(function () {
                    console.log('Hint loaded');
                });

                vm.intro.onhintclick(function (hintElement, item, stepId) {
                    vm.hintTargetId = item.targetElement.id;
                });

                vm.intro.onhintclose(function () {
                    if (vm.hintTargetId) {
                        vm.exitOfferGuide(vm.hintTargetId);
                    }
                    vm.intro.removeHints();
                });

                vm.intro.addHints();
            }

            function exitOfferGuideFn(elementId) {
                let type = GUIDE_TYPES[elementId];
                vm.guideTypeExitedList.push(type);
                OfferInfoStatus.updateOfferInfoStatus(
                    vm.offerId,
                    { guideTypeExitedList: vm.guideTypeExitedList },
                    function (response) {
                        vm.guideType = response.type.name;
                        vm.hintTargetId = undefined;
                    }
                );
            }

            function addHintsForParticipantExportFn() {
                if (vm.intro) {
                    vm.intro.removeHints();
                    vm.intro = null;
                }
                vm.intro = introJs();
                let hints = [];
                if ([GlobalConstants.SHOW_ALL, GlobalConstants.SHOW_PARTICIPANT_INFO].indexOf(vm.guideType) > -1) {
                    hints.push(getHintForParticipantTab());
                }
                if ([GlobalConstants.SHOW_ALL, GlobalConstants.SHOW_EXPORT_INFO].indexOf(vm.guideType) > -1) {
                    hints.push(getHintForExportTab());
                }

                vm.intro.setOptions({
                    hints: hints,
                    hintButtonLabel: `${$translate.instant('common.introjs.got.it')}`,
                });

                vm.intro.onhintsadded(function () {
                    console.log('Hint loaded for participant export');
                });

                vm.intro.onhintclick(function (hintElement, item, stepId) {
                    vm.hintTargetId = item.targetElement.id;
                });

                vm.intro.onhintclose(function (stepId) {
                    if (vm.hintTargetId) {
                        vm.exitOfferGuide(vm.hintTargetId);
                    }
                    vm.intro.removeHint(stepId);
                });

                vm.intro.addHints();
                vm.intro.showHints();
            }

            function hideHintsOnTabSwitchFn() {
                if (vm.guideType != GlobalConstants.SHOW_NONE && vm.participantTabActivated) {
                    let exportHintIndex = getHintIndexByElementId('participant_dropdown_export');
                    if (exportHintIndex > -1) {
                        vm.intro.removeHint(exportHintIndex);
                    }
                }
            }

            function getHintIndexByElementId(elementID) {
                return vm.intro._introItems.findIndex((item) => {
                    return (
                        (item.targetElement && item.targetElement.id == elementID) ||
                        (item.element && item.element.id == elementID)
                    );
                });
            }

            function addANewOfferPartFn(offerPart) {
                if (vm.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
                    offerPart.status = { name: 'ADDED_BY_SUPPLIER' };
                    offerPart.newPrice = offerPart.price;
                    offerPart.newPriceInclVat = offerPart.priceInclVat;
                    offerPart.deletable = true;
                    offerPart.dummyReferenceId = Math.random();
                    offerPart.dummyOfferPartId = offerPart.dummyReferenceId;
                    let _isAccommodation = offerPart.offerPartCategory === 'ACCOMMODATION';
                    if (_isAccommodation) {
                        vm.splitAccommodationPart(offerPart);
                    } else {
                        let offerPartMap = { offerPart: offerPart, action: 'ADDED_BY_SUPPLIER' };
                        vm.offerPartsWithActions.push(offerPartMap);
                        vm.offer.requestOfferParts.push(offerPart);
                        checkAndAddNewOfferPart(offerPart);
                    }
                    vm.calculateTotalPrice();
                } else {
                    vm.createPart(offerPart);
                }
            }

            function checkAndAddNewOfferPart(offerPart) {
                let _date = vm.getFormatedDate(offerPart.dateCreated);
                if (offerPart && !offerPart.timeless) {
                    let _timedPart = vm.filteredTimedOfferParts.find((part) => {
                        let _defaultDate = vm.getFormatedDate(part.config.defaultDate);
                        if (_defaultDate === _date) {
                            part.partData[0].parts.push(offerPart);
                            return part;
                        }
                    });
                    if (!_timedPart) {
                        let _newTimedOfferPart = {
                            config: {
                                defaultDate: offerPart.dateCreated,
                            },
                            day: AccommodationUtil.getDayIndex(offerPart.dateTimeFrom) + 1,
                            hasOfferParts: true,
                            partData: [
                                {
                                    parts: [offerPart],
                                },
                            ],
                        };
                        vm.filteredTimedOfferParts.push(_newTimedOfferPart);
                    }
                } else {
                    let _timelessPart = vm.filteredTimelessOfferParts.find((part) => {
                        let _defaultDate = vm.getFormatedDate(part.config.defaultDate);
                        if (_defaultDate === _date) {
                            part.partData[0].parts.push(offerPart);
                            return part;
                        }
                    });
                    if (!_timelessPart) {
                        let _newTimelessOfferPart = {
                            config: {
                                defaultDate: offerPart.dateCreated,
                            },
                            hasOfferParts: true,
                            partData: [
                                {
                                    parts: [offerPart],
                                },
                            ],
                        };
                        vm.filteredTimelessOfferParts.push(_newTimelessOfferPart);
                    }
                }
            }

            function splitAccommodationPartFn(offerPart) {
                let _currentIndex = AccommodationUtil.getDayIndex(offerPart.dateTimeFrom);
                let _days = AccommodationUtil.getDateDiff(offerPart.dateTimeFrom, offerPart.dateTimeTo);
                let _upperIndex = _currentIndex + _days;
                if (_upperIndex <= _currentIndex) {
                    _upperIndex = _currentIndex + 1;
                }
                for (let _i = _currentIndex; _i < _upperIndex; _i++) {
                    let _currentPart = angular.copy(offerPart);
                    let _startDate = AccommodationUtil.getDateTimeFrom(_i);
                    let _endDate = AccommodationUtil.getDateTimeTo(_i);
                    _currentPart.dateTimeFrom = _startDate;
                    _currentPart.dateTimeTo = _endDate;
                    _currentPart.dummyReferenceId = Math.random();
                    _currentPart.dummyOfferPartId = _currentPart.dummyReferenceId;
                    let offerPartMap = { offerPart: _currentPart, action: 'ADDED_BY_SUPPLIER' };
                    vm.offerPartsWithActions.push(offerPartMap);
                    vm.offer.requestOfferParts.push(_currentPart);
                    checkAndAddNewAccommodationPart(_currentPart, _i);
                }
            }

            function checkAndAddNewAccommodationPart(offerPart, _index) {
                let _date = vm.getFormatedDate(offerPart.dateCreated);
                if (_index < 0) {
                    let _beforeAccommodationPart = vm.filteredBeforeEventAccommodations.find((part) => {
                        let _defaultDate = vm.getFormatedDate(part.config.defaultDate);
                        if (_defaultDate === _date) {
                            part.partData[0].accommodations.unshift(offerPart);
                            return part;
                        }
                    });
                    if (!_beforeAccommodationPart) {
                        let _newBeforeAccommodationPart = {
                            config: {
                                defaultDate: offerPart.dateCreated,
                            },
                            hasOfferParts: true,
                            partData: [
                                {
                                    accommodations: [offerPart],
                                },
                            ],
                        };
                        vm.filteredBeforeEventAccommodations.unshift(_newBeforeAccommodationPart);
                    }
                } else {
                    let _timedAccommodationPart = vm.filteredTimedOfferParts.find((part) => {
                        let _defaultDate = vm.getFormatedDate(part.config.defaultDate);
                        if (_defaultDate === _date) {
                            part.partData[0].accommodations.push(offerPart);
                            return part;
                        }
                    });
                    if (!_timedAccommodationPart) {
                        let _afterAccommodationPart = vm.filteredAfterEventAccommodations.find((part) => {
                            let _defaultDate = vm.getFormatedDate(part.config.defaultDate);
                            if (_defaultDate === _date) {
                                part.partData[0].accommodations.push(offerPart);
                                return part;
                            }
                        });
                        if (!_afterAccommodationPart) {
                            let _newAfterAccommodationPart = {
                                config: {
                                    defaultDate: offerPart.dateCreated,
                                },
                                hasOfferParts: true,
                                partData: [
                                    {
                                        accommodations: [offerPart],
                                    },
                                ],
                            };
                            vm.filteredAfterEventAccommodations.push(_newAfterAccommodationPart);
                        }
                    }
                }
            }

            function allowedToCancelBookedOfferFn() {
                if (vm.offer && vm.offer.statusDetailed) {
                    return vm.offer.statusDetailed.name == 'BOOKED_LOCKED_BY_SUPPLIER';
                }
            }

            function applyBookedLockedStateChangesFn(offer) {
                var settings = {
                    message: 'request.offer.part.changes.apply.notification',
                    okCallBack: function () {
                        RequestOffer.applyBookedLockedStateChanges(
                            offer.id,
                            function (response) {
                                if (response) {
                                    vm.init();
                                    $scope.$broadcast('update.offer.part.option', {});
                                }
                            },
                            $rootScope.endWithAlert
                        );
                    },
                };
                $rootScope.$broadcast('dialog:confirmation:open', settings);
            }

            function applyRowBgColorFn(offer, part) {
                if (vm.isOfferInCanceledState(offer)) {
                    return part.status.name;
                }
                //BG color display only if offer has been already answered
                // if (!vm.offer.alreadyAnswered) {
                //     return null;
                // }
                return tooltipUtil.getPartNotificationState(offer, part);
            }

            function applySupplierEditingFn() {
                angular.forEach(vm.offer.requestOfferParts, function (n) {
                    if (vm.isOfferPartInSupplierWaitingStates(n) && n.hasRequestOfferPartOptions) {
                        vm.acceptOfferPart(n);
                    }
                });
                if (vm.disableSendActionForSupplier()) {
                    $rootScope.$broadcast('dialog:alert:open', {
                        message: $translate.instant('requestOfferPart.changed.creator.action.notification'),
                    });
                } else {
                    let _offerPartsAndOptionsWithActions = {
                        offerPartsWithActions: vm.offerPartsWithActions,
                        offerPartOptionsWithActions: vm.offerPartOptionsWithActions,
                    };
                    if (vm.offerPartsWithActions.length > 0 || vm.offerPartOptionsWithActions.length > 0) {
                        vm.disableSendAnswerBtn = true;
                        $scope.startProgress();
                        RequestOfferPart.applySupplierActionsOnCreatorChanges(
                            vm.offer.id,
                            _offerPartsAndOptionsWithActions,
                            function (response) {
                                if (response) {
                                    vm.reload();
                                    $scope.$broadcast('apply.supplier.action.on.creator.changes', {
                                        offerPartOptionsWithActions: vm.offerPartOptionsWithActions,
                                    });
                                    //$scope.$broadcast('update.offer.part.option', {});
                                }
                            },
                            function (name, errorDetails) {
                                vm.disableSendAnswerBtn = false;
                                $scope.endWithAlert(name, errorDetails);
                            }
                        );
                    }
                }
            }

            function calculateOriginalTotalPrice() {
                vm.originalTotalPrice = amountAndPriceService.getOfferOriginalPrice(
                    vm.offer.requestOfferParts,
                    vm.includeVat
                );
            }

            function calculateTotalPriceFn() {
                vm.totalPrice = amountAndPriceService.getOfferTotalPrice(
                    vm.offer,
                    vm.offer.requestOfferParts,
                    vm.includeVat
                );
                vm.totalPriceLocalisedWithCurrency = viewAmountLocalisedWithCurrencyFn(vm.totalPrice, vm.selectedCurrency);
                if (vm.supplierDiscountEnabled) {
                    vm.calculateDiscountedPrice(vm.totalPrice);
                }
                return vm.totalPrice;
            }

            function getOfferCanceledAmountWithCurrencyFn() {
                let canceledAmount = amountAndPriceService.getOfferCanceledAmount(
                    vm.offer,
                    vm.offer.requestOfferParts,
                    vm.includeVat
                );
                return viewAmountLocalisedWithCurrencyFn(canceledAmount, vm.selectedCurrency);
            }

            function cancelBookedLockedStateChangesFn(offer) {
                var settings = {
                    message: 'request.offer.part.changes.cancel.notification',
                    okCallBack: function () {
                        RequestOffer.discardOwnChangesBySupplier(
                            [offer.id],
                            () => {
                                initFn();
                                $scope.$broadcast('update.offer.part.option', {});
                            },
                            errorHandler
                        );
                    },
                };
                $rootScope.$broadcast('dialog:confirmation:open', settings);
            }

            function cancelConfirmationFn() {
                $scope.startProgress();
                RequestOffer.invalidateConfirmation(
                    { offerId: vm.offerId },
                    (res) => {
                        if (res) {
                            vm.reload();
                        }
                    },
                    $scope.endWithAlert
                );
            }

            function canRespondToOfferFn() {
                return vm.isUserEnabled;
            }

            function canSendOfferFn() {
                return vm.offer && vm.offer.status && vm.offer.status.name === 'SENT';
            }

            function checkIfFieldValueChangedFn(offerPart, originalOfferPart) {
                if (!offerPart.id) {
                    return true;
                }
                if (
                    offerPart.name !== originalOfferPart.name ||
                    offerPart.description !== originalOfferPart.description ||
                    offerPart.amount !== originalOfferPart.amount ||
                    offerPart.amountType !== originalOfferPart.amountType ||
                    offerPart.amountType !== originalOfferPart.amountType.name ||
                    offerPart.dateTimeFrom !== originalOfferPart.dateTimeFrom ||
                    offerPart.dateTimeTo !== originalOfferPart.dateTimeTo ||
                    (offerPart.price && offerPart.price !== 0 && offerPart.price !== originalOfferPart.price)
                ) {
                    return true;
                } else {
                    return false;
                }
            }

            function checkIfAmountTypeHasChangedFn(offerPart) {
                let oldType = amountAndPriceService.getOldAmountType(vm.offer, offerPart);
                let newType = offerPart.amountType.name;
                if (offerPart.hasAdditionalQuestions) {
                    return false;
                }
                if (!oldType) {
                    return false;
                }
                if (oldType === newType) {
                    return false;
                }
                if (oldType === 'SAME_AS_EVENT' && newType === 'PEOPLE') {
                    return false;
                }

                if (newType === 'SAME_AS_EVENT' && oldType === 'PEOPLE') {
                    return false;
                }
                return hasFieldValueChangedFn(oldType, offerPart.amountType.name);
            }

            function setActiveFn(tab) {
                vm.finalPriceAccepted = false;
                if (tab !== 'participants') {
                    hideHintsOnTabSwitchFn();
                }
                ActiveTabService.setOfferDetailTab(tab);
            }

            function getActiveTab() {
                return ActiveTabService.getOfferDetailTab();
            }

            // function checkIfParticipantUpdatingFn(eventId) {
            //
            // }

            function closeDeletePartDialogFn() {
                vm.selectedPart = undefined;
                //  jQuery('#delete-part-modal').foundation('reveal', 'close'); //UNUSED
            }

            function clearAttachedFilesFn() {
                vm.attachedFiles = { uploads: null, links: null };
            }

            function clearCovidMeasuresFn() {
                vm.clearAttachedFiles();
                vm.hasCovidPreventionMeasures = false;
                vm.covidPreventionMeasures = '';
                vm.clearAll = true;
            }

            function processCovidDataFn(response) {
                vm.clearAttachedFiles();
                vm.clearAll = true;
                vm.hasCovidPreventionMeasures = response.hasCovidPreventionMeasures;
                vm.covidPreventionMeasures = response.covidPreventionMeasures;
                vm.covidMeasuresFiles = response.attachedCovidFiles;
                let covidData = {
                    hasCovidPreventionMeasures: vm.hasCovidPreventionMeasures,
                    covidPreventionMeasures: vm.covidPreventionMeasures,
                    covidMeasuresFiles: vm.covidMeasuresFiles,
                };
                FieldChangedSvc.reset();
                FieldChangedSvc.init(covidData);
                vm.disableCovidButtons = true;
                $scope.endProgress();
            }

            function confirmDeletePartFn(event, part) {
                vm.selectedPart = part;
                return false;
            }

            function confirmBookedOfferFn() {
                $state.go('offer.confirmation', {
                    eventId: vm.eventId,
                    requestId: vm.requestId,
                    offerId: vm.offer.id,
                    offer: vm.offer,
                    billingAddress: vm.creatorBillingAddress,
                });
            }

            function createPartFn(data) {
                $scope.startProgress();
                RequestOfferPart.createByRequestOfferId(
                    vm.offer.id,
                    data,
                    function (response) {
                        if (response) {
                            vm.reload();
                        }
                    },
                    $scope.endWithAlert
                );
            }

            function confirmDeclineOfferFn() {
                if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                    return;
                }
                var settings = {
                    message: 'request.offer.decline.notification',
                    okCallBack: vm.declineOffer,
                    cancelCallBack: vm.closeDeclineOfferDialog,
                };
                $rootScope.$broadcast('dialog:confirmation:open', settings);
                return false;
            }

            function declineAllCreatorChangesFn() {
                vm.supplierActionOnCreatorChanges = 'DECLINE_ALL';
                insertAllSupplierWaitingRowsAsDeclined();
                $rootScope.$broadcast('update:decline:all:part:options', {
                    offerPartOptionsWithActions: vm.offerPartOptionsWithActions,
                });
                vm.btnMap = { name: 'requestOfferPart.button.decline.all.changes', ngclass: 'spDecline' };
                var element = jQuery('#supplier-all-options');
                element.css('visibility', 'hidden');
                element.removeClass('open');
            }

            function declineOfferFn(message) {
                $scope.startProgress();
                RequestOffer.sendOfferToCustomer(
                    {
                        offerId: vm.offerId,
                        email: vm.offer.responsibleUserEmail,
                        type: 'SUPPLIER_OFFER_DECLINE',
                        responsibleSupplierUserId: vm.offer.responsibleSupplierUser.id,
                        status: 'DECLINED',
                        message: message,
                        agreementPrices: vm.agreementPrices,
                    },
                    statusUpdated,
                    $scope.endWithAlert
                );
            }

            function declineOfferPartFn(offerPart) {
                var offerPartMap = { offerPart: offerPart, action: 'DECLINED_BY_SUPPLIER' };
                removeOfferPartFromMap(offerPart);
                vm.offerPartsWithActions.push(offerPartMap);

                var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                reassignOriginalValuesToOfferPart(offerPart, originalOfferPart);
                if (originalOfferPart.status.name == 'EDITED_WAITING_FOR_SUPPLIER') {
                    offerPart.status.name = 'DECLINED';
                }
                if (originalOfferPart.status.name == 'ADDED_WAITING_FOR_SUPPLIER') {
                    offerPart.status.name = 'ADDED_WAITING_FOR_SUPPLIER_DECLINED';
                }
                if (originalOfferPart.status.name == 'DELETED_WAITING_FOR_SUPPLIER') {
                    offerPart.status.name = 'DELETED_WAITING_FOR_SUPPLIER_DECLINED';
                }
                vm.flagForAcceptedWithChanges = undefined;

                if (vm.supplierActionOnCreatorChanges == 'ACCEPT_ALL') {
                    vm.btnMap = { name: 'requestOfferPart.button.action.all', ngclass: 'spAction' };
                }
                if (offerPart.isSuggestionEnabled) {
                    let _offerPartOptionsAdded = _.filter(vm.offerPartOptionsWithActions, (offerPartOptionWithActions) => {
                        return offerPartOptionWithActions.offerPartOption.requestOfferPartId === offerPart.id;
                    });
                    if (_offerPartOptionsAdded.length > 0) {
                        angular.forEach(_offerPartOptionsAdded, (_offerPartOptionAdded) => {
                            removeOfferPartOptionFromMap(_offerPartOptionAdded);
                        });
                    }
                    $scope.$broadcast('decline.offer.part.with.option.added', { offerPartId: offerPart.id });
                }
            }

            function removeOfferPartOptionFromMap(offerPartOption) {
                angular.forEach(vm.offerPartOptionsWithActions, function (rowAndAction, i) {
                    if (rowAndAction.offerPartOption.id === offerPartOption.id) {
                        vm.offerPartOptionsWithActions.splice(i, 1);
                    }
                });
            }

            function deleteOfferPartFn(offerPart) {
                if (offerPart.id && !offerPart.dummyReferenceId) {
                    var offerPartMap = { offerPart: offerPart, action: 'DELETED_BY_SUPPLIER' };
                    removeOfferPartFromMap(offerPart);
                    vm.offerPartsWithActions.push(offerPartMap);

                    var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                    reassignOriginalValuesToOfferPart(offerPart, originalOfferPart);

                    angular.forEach(vm.offer.requestOfferParts, function (part) {
                        if (part.id === offerPart.id) {
                            part.oldName = originalOfferPart.oldName;
                            part.oldAmount = originalOfferPart.oldAmount;
                            part.oldAmountType = originalOfferPart.oldAmountType;
                            part.oldDateTimeFrom = originalOfferPart.oldDateTimeFrom;
                            part.oldDateTimeTo = originalOfferPart.oldDateTimeTo;
                            part.oldPrice = originalOfferPart.oldPrice;
                            part.status.name = 'DELETED_WAITING_FOR_CREATOR';
                        }
                    });

                    vm.ROPUndoDeleteId = 'undelete' + offerPart.id;
                } else {
                    angular.forEach(vm.offerPartsWithActions, function (rowAndAction, i) {
                        if (offerPart.dummyReferenceId) {
                            if (rowAndAction.offerPart.dummyReferenceId === offerPart.dummyReferenceId) {
                                vm.offerPartsWithActions.splice(i, 1);
                            }
                        }
                    });
                    angular.forEach(vm.offer.requestOfferParts, function (part, i) {
                        if (offerPart.id) {
                            if (part.id === offerPart.id) {
                                vm.offer.requestOfferParts.splice(i, 1);
                            }
                        } else if (offerPart.dummyReferenceId) {
                            if (part.dummyReferenceId === offerPart.dummyReferenceId) {
                                vm.offer.requestOfferParts.splice(i, 1);
                            }
                        }
                    });
                }
            }

            function deletePartFn() {
                //if (vm.selectedPart) {
                //            Part['delete']({eventId: vm.eventId, requestId: vm.requestId, partId: vm.selectedPart.id},
                // function(data) {
                //                vm.getParts();
                //            });
                //}
                vm.closeDeletePartDialog();
            }

            function disabledFn(item) {
                if (!vm.offer) {
                    return !vm.offer;
                } else if (vm.offer.request.userRole) {
                    return vm.offer.request.userRole[item] == $rootScope.rights.NONE;
                }
            }

            function disableOfferPartInfoFn(offerPart) {
                if (CommonFunctions.hasIntersectValue([vm.offer.statusDetailed.name], DISPLAY_TOOLTIP_STATUS)) {
                    if (offerPart.status.name == 'ADDED_BY_SUPPLIER') {
                        vm.ROPInfo = undefined;
                        return;
                    }
                    if (
                        vm.isOfferPartInSupplierWaitingStates(offerPart) ||
                        vm.isOfferPartInCreatorWaitingStates(offerPart) ||
                        vm.isOfferPartInCreatorDeclinedStates(offerPart) ||
                        vm.showPriceChangeToolTipForOfferByStatus(offerPart, [
                            'BOOKED_LOCKED_BY_SUPPLIER',
                            'BOOKED_WAITING_FOR_CREATOR',
                            'BOOKED_WAITING_FOR_SUPPLIER',
                        ]) ||
                        vm.displayOfferPartChangeToolTip(
                            offerPart,
                            vm.getOldValueForChangedTitle(offerPart, 'AMOUNT'),
                            offerPart.amount
                        ) ||
                        vm.displayOfferPartChangeToolTipForAmountType(offerPart) ||
                        vm.displayFieldChangedForDate(offerPart) ||
                        vm.displayOfferPartChangeToolTipForName(
                            offerPart,
                            offerPart.oldName,
                            offerPart.oldNameCode,
                            offerPart.name,
                            offerPart.nameCode
                        ) ||
                        vm.showPriceChangeToolTip(offerPart) ||
                        offerPart.status.name === 'ADDED_WAITING_FOR_SUPPLIER_DECLINED'
                    ) {
                        vm.ROPInfo = undefined;
                    }
                }
            }

            function enableFoldOutFn(offer, offerPart) {
                if (!CommonFunctions.hasIntersectValue([offer.status.name], ['ANSWERED'])) {
                    $rootScope.ROPEditId = 'o' + offerPart.id;
                }
                if (canAddNewResourceFn(offerPart)) {
                    $rootScope.ROPOEditId = 'o' + offerPart.id;
                }
            }

            function disablePriceEditFn(offer, part) {
                if (offer.status.name === 'FINISHED') {
                    return vm.partPricesDisabled;
                } else if (
                    CommonFunctions.hasIntersectValue(
                        [offer.status.name],
                        ['FINISHED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_SUPPLIER']
                    )
                ) {
                    return false; //enable edit
                } else if (vm.isOfferInCanceledState(offer)) {
                    if (part.status.name === 'CANCELED') {
                        return true;
                    }
                } else {
                    if (part.status.name === 'DELETED_BY_CREATOR') {
                        return true;
                    }
                    return vm.partPricesDisabled || !part.editable;
                }
            }

            function disablePaidOnsiteEditFn(offer, part) {
                if (offer.status.name === 'FINISHED') {
                    return false;
                } else if (
                    CommonFunctions.hasIntersectValue(
                        [offer.status.name],
                        ['FINISHED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_SUPPLIER']
                    )
                ) {
                    return vm.paidOnsiteDisabled;
                } else if (vm.isOfferInCanceledState(offer)) {
                    if (part.status.name === 'CANCELED') {
                        return true;
                    }
                } else if (offer.status.name === 'APPROVED') {
                    return true;
                } else {
                    if (part.status.name === 'DELETED_BY_CREATOR') {
                        return true;
                    }
                    return false;
                }
            }

            function enableSelectedCurrencyUpdateFn() {
                return vm.offer && vm.offer.status.name == 'SENT';
            }

            function disableVatEditFn(offer, part) {
                if (CommonFunctions.hasIntersectValue([offer.status.name], DISABLED_VAT_EDIT_OFFER_STATUS)) {
                    return true;
                } else {
                    if (
                        part.status.name === 'DELETED_BY_CREATOR' ||
                        part.status.name === 'CANCELED' ||
                        part.status.name === 'FINISHED'
                    ) {
                        return true;
                    }
                    return false;
                }
            }

            function disableSendActionForSupplierFn() {
                var sendButtonDisabled = false;
                angular.forEach(vm.offer.requestOfferParts, function (part) {
                    if (vm.isOfferPartInToAnswerStates(part)) {
                        var rowIsInRowAndActionMap = false;
                        angular.forEach(vm.offerPartsWithActions, function (offerPartWithAction) {
                            if (!rowIsInRowAndActionMap) {
                                if (part.id === offerPartWithAction.offerPart.id) {
                                    rowIsInRowAndActionMap = true;
                                }
                            }
                        });

                        if (part.hasRequestOfferPartOptions) {
                            angular.forEach(part.offerPartOptions, (offerPartOption) => {
                                angular.forEach(vm.offerPartOptionsWithActions, function (offerPartOptionWithAction) {
                                    if (!rowIsInRowAndActionMap) {
                                        if (offerPartOption.id === offerPartOptionWithAction.offerPartOption.id) {
                                            rowIsInRowAndActionMap = true;
                                        }
                                    }
                                });
                            });
                        }
                        if (!rowIsInRowAndActionMap) {
                            sendButtonDisabled = true;
                        }
                    }
                });
                return sendButtonDisabled;
            }

            function disabledExpirationDateEditFn() {
                if (vm.prolongOfferForReservationDate) {

                    if((vm.expiredBeforeDays || vm.expireAfterDays == 0) && vm.offer.reservationCancelDays){
                        vm.offer.expirationDate = new Date().getTime() + vm.offer.reservationCancelDays * 24 * 60 * 60 * 1000;
                        calculateOfferExpirationDays();
                    }
                    return false;
                }
                if (vm.offer && vm.offer.statusDetailed.name !== 'SENT') {
                    return true;
                }
                if (vm.offer && vm.offer.statusDetailed.name === 'SENT' && vm.offer.alreadyAnswered) {
                    return true;
                }
                return false;
            }

            function checkExpirationDateChangeFn() {
                if (!vm.offer.reservationCancelDays) {
                    return true;
                }


                let expirationDate = clearTime(new Date()).getTime() + vm.offer.reservationCancelDays * 24 * 60 * 60 * 1000;
                if(vm.originalExpirationDate < expirationDate){
                    return false;
                }
                if (vm.offer.reservationCancelDays < vm.originalReservationCancelDays) {
                    return true;
                }
                return vm.originalReservationCancelDays === parseInt(vm.offer.reservationCancelDays);

            }

            function disabledCanceledPeriodDateEditFn() {
                if (vm.prolongOfferForCancelPeriodDate) {
                    return false;
                }
                if (vm.offer && vm.offer.statusDetailed.name !== 'SENT') {
                    return true;
                }
                if (vm.offer && vm.offer.statusDetailed.name === 'SENT' && vm.offer.alreadyAnswered) {
                    return true;
                }
                return false;
            }

            function checkCancellationPeriodChangeFn() {
                if (!vm.offer.cancelPeriod) {
                    return true;
                }
                if (vm.offer.cancelPeriod > vm.originalCancelPeriod) {
                    return true;
                }
                return vm.originalCancelPeriod === parseInt(vm.offer.cancelPeriod);
            }

            function displayOfferPartChangeSummaryFn(offerPart) {
                return vm.ROPInfo === 'info' + offerPart.id || vm.ROPInfo === 'info' + offerPart.name;
            }

            function displayOfferPartChangeToolTipFn(offerPart, oldValue, newValue) {
                if (hasFieldValueChangedFn(oldValue, newValue)) {
                    return !hideToolTipForGivenPartStatuses(offerPart.status);
                }
                return false;
            }

            function displayOfferPartChangeToolTipForNameFn(offerPart, oldName, oldNameCode, newName, newNameCode) {
                if (vm.hasNameChanged(oldName, oldNameCode, newName, newNameCode)) {
                    return !hideToolTipForGivenPartStatuses(offerPart.status);
                }
                return false;
            }

            function displayOfferPartChangeToolTipForAmountTypeFn(offerPart) {
                if (checkIfAmountTypeHasChangedFn(offerPart)) {
                    return !hideToolTipForGivenPartStatuses(offerPart.status);
                }
                return false;
            }

            function displayFieldChangedForDateFn(offerPart) {
                if (fieldChangedForDateFn(offerPart)) {
                    return !hideToolTipForGivenPartStatuses(offerPart.status);
                }
                return false;
            }

            function getTooltipValuePartSpanSeveralDaysFn() {
                return $translate.instant('common.tooltip.part.span.for.several.days');
            }

            function getTooltipValueNameFn(offerPart) {
                return TooltipValueService.getTooltipValueName(offerPart);
            }

            function getTooltipValueAmountFn(offerPart) {
                let oldAmount = this.getOldValueForChangedTitle(offerPart, 'AMOUNT');
                return TooltipValueService.getTooltipValueAmount(offerPart, oldAmount);
            }

            function getTooltipValueDateFn(offerPart) {
                let params = {
                    fromDate: offerPart.oldDateTimeFrom,
                    toDate: offerPart.oldDateTimeTo,
                    baseDate: this.event.fromDate,
                    absolute: true,
                    showTime: true,
                    isInvitation: false,
                };
                return TooltipValueService.getTooltipValueDate(params);
            }

            function getTooltipValuePriceFn(offerPart) {
                let oldPriceString = this.viewAmountLocalisedWithCurrency(
                    this.getOldValueForChangedTitle(offerPart, 'PRICE'),
                    this.offer.currency
                );
                return TooltipValueService.getTooltipValuePrice(oldPriceString);
            }

            function getTooltipValueInfoMessageByStatusFn(status) {
                return TooltipValueService.getInfoMessageByStatus(status);
            }

            function getTooltipValueAfterFinishedFn(offerPart, type) {
                let oldString;
                let newString;
                switch (type) {
                    case 'AMOUNT':
                        oldString = this.getOldValueForChangedTitle(offerPart, type);
                        newString = amountAndPriceService.getAmount(this.offer, offerPart);
                        break;
                    case 'PRICE':
                        oldString = this.viewAmountLocalisedWithCurrency(
                            this.getOldValueForChangedTitle(offerPart, type),
                            this.offer.currency
                        );
                        newString = this.viewAmountLocalisedWithCurrency(
                            this.getPrice(this.offer, offerPart),
                            this.offer.currency
                        );
                        break;
                }
                return TooltipValueService.getTooltipValueAfterFinished(oldString, newString, type);
            }

            function getTranslatedNotificationFn(notification) {
                let p0, p1;
                if (notification.type === 'REQUEST_OFFER_SENT') {
                    p0 = notification.customerName;
                    p1 = notification.companyName;
                }
                let translated = $translate.instant(notification.message, {
                    p0: p0,
                    p1: p1,
                });
                return translated;
            }

            function getParticipantActivityNotificationSettings() {
                RequestOffer.getNotificationSettings(vm.offerId, function (response) {
                    vm.originalNotificationEmail = response.participantActivityNotificationEmail;
                    vm.notificationEmail = response.participantActivityNotificationEmail;
                    vm.notificationEmailSet = true;
                    vm.setParticipantsNotificationType(response.notificationFrequency.name);
                });
            }

            function hideToolTipForGivenPartStatuses(status) {
                if (vm.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
                    if (_.contains(HIDE_CHANGE_TOOLTIP_PART_STATUS, status.name.trim())) {
                        return true;
                    }
                }
                return false;
            }

            function doStrikeThroughFn(offerPart) {
                if (CommonFunctions.hasIntersectValue(DRAW_STRIKE_THROUGH_LINE, offerPart.status.name)) {
                    return true;
                }
                if (offerPart.status.name === 'CANCELED' && vm.isOfferInCanceledState(vm.offer)) {
                    return true;
                }
                if (offerPart.status.name === 'DELETED_WAITING_FOR_SUPPLIER') {
                    return vm.offer.statusDetailed.name != 'BOOKED_LOCKED_BY_CREATOR';
                }
            }

            function editBookingTermsFn() {
                if(vm.viewModal) {
                    return;
                }
                vm.viewModal = true;
                let bookingTermEditModalData = bookingTermsEditModal;
                let data = {
                    offer: vm.offer,
                    isUsingClientBillingAddressAndIsEL: vm.isUsingClientBillingAddressAndIsEL,
                    clientName: vm.clientName,
                    clientDisplayName: vm.clientDisplayName
                };
                bookingTermEditModalData.inputs = { data };
                ModalService.showModal(bookingTermEditModalData).then((modal) => {
                    vm.viewModal = false;
                    modal.element.modal();
                    modal.close.then(() => {
                        console.log('modal is closed!');
                    });
                });
            }

            function fullBookingTermsFn(isUsingClientBillingAddressAndIsEL = false) {
                if(vm.viewModal) {
                    return;
                }
                vm.viewModal = true;
                let fullBookingTermEditModal = fullBookingTermsEditModal;
                let data = {
                    bookingTerms: vm.bookingTerms,
                    isUsingClientBillingAddressAndIsEL: isUsingClientBillingAddressAndIsEL,
                };
                fullBookingTermEditModal.inputs = { data };
                ModalService.showModal(fullBookingTermEditModal).then((modal) => {
                    vm.viewModal = false;
                    modal.element.modal();
                    modal.close.then(() => {
                        console.log('modal is closed!');
                    });
                });
            }

            function openConformationModalFn() {
                if (!AuthenticationService.checkIfUserVerifiedAndAlert() || vm.viewModal) {
                    return;
                }
                vm.viewModal = true;
                let confirmationModalReference = conformationModal;
                let data = {
                    message: 'dialog.notification.conformation',
                    canAddCustomMessage: true,
                    okCallBack: function (response) {
                        vm.declineOffer(response);
                    },
                    okLabel: 'dialog.notification.confirm',
                    isCreatorInterestedInAlternateDates: vm.event.interestedInOtherDates,
                };
                confirmationModalReference.inputs = { data };
                ModalService.showModal(confirmationModalReference).then((modal) => {
                    vm.viewModal = false;
                    modal.element.modal();
                    modal.close.then(() => {
                        console.log('modal is closed!');
                    });
                });
            }

            function editPresentationFn() {
                $location.url('/supplier/' + vm.supplierId + '/offer/' + vm.offerId);
            }

            function enableOfferPartInfoFn(offerPart) {
                if (
                    vm.shouldHideOfferPartDetails(offerPart) &&
                    vm.additionalInfoChanges.length < 1 &&
                    vm.shouldShowBillingInfo(vm.offer.statusDetailed.name)
                ) {
                    return;
                }
                if (CommonFunctions.hasIntersectValue([vm.offer.statusDetailed.name], DISPLAY_TOOLTIP_STATUS)) {
                    if (offerPart.status.name === 'ADDED_BY_SUPPLIER' && !offerPart.id) {
                        //newly added offerpart has no id, so as to make hover result particular to that row only
                        vm.ROPInfo = 'info' + offerPart.name;
                        return;
                    }
                    if (
                        vm.isOfferPartInSupplierWaitingStates(offerPart) ||
                        vm.isOfferPartInCreatorWaitingStates(offerPart) ||
                        vm.isOfferPartInCreatorDeclinedStates(offerPart) ||
                        vm.showPriceChangeToolTipForOfferByStatus(offerPart, [
                            'BOOKED_LOCKED_BY_SUPPLIER',
                            'BOOKED_WAITING_FOR_CREATOR',
                            'BOOKED_WAITING_FOR_SUPPLIER',
                        ]) ||
                        vm.displayOfferPartChangeToolTip(
                            offerPart,
                            vm.getOldValueForChangedTitle(offerPart, 'AMOUNT'),
                            offerPart.amount
                        ) ||
                        vm.displayOfferPartChangeToolTipForAmountType(offerPart) ||
                        vm.displayFieldChangedForDate(offerPart) ||
                        vm.displayOfferPartChangeToolTipForName(
                            offerPart,
                            offerPart.oldName,
                            offerPart.oldNameCode,
                            offerPart.name,
                            offerPart.nameCode
                        ) ||
                        vm.showPriceChangeToolTip(offerPart) ||
                        offerPart.status.name === 'ADDED_WAITING_FOR_SUPPLIER_DECLINED'
                    ) {
                        vm.ROPInfo = 'info' + offerPart.id;
                    }
                }
            }

            function fieldChangedForDateFn(offerPart) {
                return (
                    offerPart.status.name != 'ADDED_WAITING_FOR_CREATOR' &&
                    (hasFieldValueChangedFn(offerPart.oldDateTimeFrom, offerPart.dateTimeFrom) ||
                        hasFieldValueChangedFn(offerPart.oldDateTimeTo, offerPart.dateTimeTo))
                );
            }

            function hasFieldValueChangedFn(oldValue, newValue) {
                //If have more than one suppliers and only one supplier answered, oldvalue != newValue but should not display it
                if (vm.offer.status.name === 'ANSWERED') {
                    return false;
                }
                //DO NOT CHECK if it has not been answered already
                if (vm.offer.alreadyAnswered) {
                    return OfferPartUtilFunctions.checkOfferPartHasOldValueAndChanged(oldValue, newValue);
                }
                return false;
            }

            function hasAmountFieldValueChangedFn(oldValue, newValue) {
                //If have more than one suppliers and only one supplier answered, oldvalue != newValue but should not display it
                if (vm.offer.status.name === 'ANSWERED') {
                    return false;
                }
                //DO NOT CHECK if it has not been answered already
                if (vm.offer.alreadyAnswered) {
                    return OfferPartUtilFunctions.checkOfferPartAmountHasOldValueAndChanged(oldValue, newValue);
                }
                return false;
            }

            function hasNameChangedFn(oldName, oldNameCode, newName, newNameCode) {
                var nameCodeChanged = oldNameCode != null && vm.hasFieldValueChanged(oldNameCode, newNameCode);
                if (nameCodeChanged) {
                    return true;
                }
                return oldNameCode == null && vm.hasFieldValueChanged(oldName, newName);
            }

            function fieldChangedForPriceFn(offerPart) {
                if (offerPart.status.name === 'ADDED_BY_SUPPLIER') {
                    return false;
                }
                var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                if (
                    originalOfferPart &&
                    originalOfferPart.status.name === 'EDITED_WAITING_FOR_CREATOR_DECLINED' &&
                    offerPart.status.name === 'EDITED_WAITING_FOR_CREATOR'
                ) {
                    return offerPart.price !== originalOfferPart.price;
                } else if (
                    originalOfferPart &&
                    originalOfferPart.status.name === 'ACCEPTED' &&
                    offerPart.status.name === 'EDITED_WAITING_FOR_CREATOR'
                ) {
                    return offerPart.oldPrice > 0 && offerPart.price !== offerPart.oldPrice;
                } else if (vm.isOfferPartInCreatorWaitingStates(offerPart)) {
                    if (offerPart.status.name !== 'ADDED_WAITING_FOR_CREATOR') {
                        return offerPart.price !== offerPart.oldPrice;
                    }
                } else {
                    return offerPart.oldPrice > 0 && offerPart.price !== offerPart.oldPrice;
                }
            }

            function fieldChangedForPriceInclVatFn(offerPart) {
                if (offerPart.status.name === 'ADDED_BY_SUPPLIER') {
                    return false;
                }
                var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                let _oldPriceInclVat = amountAndPriceService.getOldPriceInclVat(vm.offer, offerPart);
                if (
                    originalOfferPart &&
                    originalOfferPart.status.name === 'EDITED_WAITING_FOR_CREATOR_DECLINED' &&
                    offerPart.status.name === 'EDITED_WAITING_FOR_CREATOR'
                ) {
                    return offerPart.newPriceInclVat !== originalOfferPart.newPriceInclVat;
                } else if (
                    originalOfferPart &&
                    originalOfferPart.status.name === 'ACCEPTED' &&
                    offerPart.status.name === 'EDITED_WAITING_FOR_CREATOR'
                ) {
                    return _oldPriceInclVat > 0 && offerPart.newPriceInclVat !== _oldPriceInclVat;
                } else if (vm.isOfferPartInCreatorWaitingStates(offerPart)) {
                    if (offerPart.status.name !== 'ADDED_WAITING_FOR_CREATOR') {
                        return offerPart.newPriceInclVat !== _oldPriceInclVat;
                    }
                } else {
                    return _oldPriceInclVat > 0 && offerPart.newPriceInclVat !== _oldPriceInclVat;
                }
            }

            function getNotificationData() {
                Notification.listUnreadAllByOffer(
                    $stateParams.offerId,
                    function (data) {
                        vm.notifications = data;
                        vm.originalNotifications = angular.copy(data);
                        vm.refreshHints();
                    },
                    function (err) {
                        console.log(err); // TODO handle error!
                    }
                );
            }

            function getNotificationClassNameFn(notification) {
                switch (notification.type) {
                    case 'REQUEST_OFFER_BOOKED_WAITING_FOR_SUPPLIER':
                    case 'REQUEST_OFFER_BOOKED_WAITING_FOR_SUPPLIER_ALL_PARTS_DATE_CHANGED':
                        return 'alert alert-danger';
                    default:
                        return 'alert alert-warning';
                }
            }

            function getOfferPartTitleFn(part) {
                if (part && part.nameCode) {
                    switch (part.nameCode) {
                        case 'common.translate.offer.part.type.bus.(outward.journey)':
                        case 'common.translate.offer.part.type.bus.(home.journey)':
                        case 'common.translate.offer.part.type.bus.trip':
                            return $translate.instant('common.translate.request.bus.transport');
                            break;
                        default:
                            return $translate.instant(
                                'common.translate.conference01.supplier.label.conference.information'
                            );
                            break;
                    }
                }
            }

            function getOfferPartAdditionalQuestionClassFn(resourceData) {
                if (resourceData && resourceData.nameCode) {
                    switch (resourceData.nameCode) {
                        case 'common.translate.offer.part.type.bus.(outward.journey)':
                        case 'common.translate.offer.part.type.bus.(home.journey)':
                        case 'common.translate.offer.part.type.bus.trip':
                            return 'supplier-bus-details';
                            break;
                        default:
                            break;
                    }
                }
            }

            function setUnreadMessageCountFn() {
                ChatMessage.unReadMessageCountForSupplierRest(
                    vm.offerId,
                    function (resp) {
                        ChatMessageCountStore.setSupplierUnreadMessageCount(resp.count);
                    },
                    $scope.endWithAlert()
                );
            }

            function getOfferPartCommentFn(offerParts) {
                angular.forEach(offerParts, (part) => {
                    if (part.hasAdditionalQuestions) {
                        FilledRequestOfferPartComment.list(
                            'OFFER_PART_LEVEL',
                            part.nameCode,
                            part.id,
                            (resp) => {
                                part.list = resp;
                                angular.forEach(resp, (list) => {
                                    if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                                        let _listValuesCode = list.listValueCodes;
                                        let _listValues = $translate.instant(_listValuesCode);
                                        list.fieldValue = DynamicFormUtilService.getFieldValue(
                                            _listValues,
                                            $rootScope.langSelected,
                                            list.fieldValue
                                        );
                                        list.oldFieldValue = DynamicFormUtilService.getFieldValue(
                                            _listValues,
                                            $rootScope.langSelected,
                                            list.oldFieldValue
                                        );
                                    }
                                    if (list.fieldValue && list.oldFieldValue && list.fieldValue !== list.oldFieldValue) {
                                        vm.additionalInfoChanges.push(list);
                                    }
                                });
                            },
                            function (e) {
                                console.log('error ', e);
                            }
                        );
                    }
                });
            }

            function checkAdditionalInfoChangedFn(offerPart) {
                let hasAdditionalInfoChanged = false;
                angular.forEach(vm.additionalInfoChanges, (list) => {
                    if (!hasAdditionalInfoChanged && list.offerPart.id === offerPart.id) {
                        hasAdditionalInfoChanged = true;
                    }
                });
                return hasAdditionalInfoChanged;
            }

            function getUnreadMessageCountFn() {
                return ChatMessageCountStore.getSupplierUnreadMessageCount();
            }

            function getOldValueForChangedTitleFn(offerPart, type) {
                let value = 0;
                switch (type) {
                    case 'AMOUNT':
                        value = amountAndPriceService.getOldAmountValue(vm.offer, offerPart);
                        break;
                    case 'PRICE':
                        value = amountAndPriceService.getOldPriceValue(vm.offer, offerPart);
                        break;
                    case 'AMOUNT_TYPE':
                        value = amountAndPriceService.getOldAmountTypeValue(vm.offer, offerPart);
                        break;
                }
                return UtilFunctions.isUndefinedOrNull(value) ? 0 : value;
            }

            function getOriginalOfferPartFn(offerPart) {
                var originalOfferPart;
                if (!offerPart) {
                    return undefined;
                }
                angular.forEach(vm.originalOfferPartsList, function (n) {
                    if (offerPart.id === n.id) {
                        originalOfferPart = n;
                    }
                });
                if (vm.includeVat && vm.offer.vatIncludedInPrice && originalOfferPart && originalOfferPart.price) {
                    originalOfferPart.newPriceInclVat = amountAndPriceService.calculatePriceFromPriceAndVat(
                        originalOfferPart.price,
                        originalOfferPart.vat
                    );
                }
                return originalOfferPart;
            }

            function cancellationFeeSortFn(offerPart) {
                if (offerPart && offerPart.nameCode === 'common.translate.offer.part.type.cancellation.fee') {
                    return true;
                }
            }

            function getUnitFn(unit) {
                if (unit == 'PEOPLE') {
                    return 'common.people';
                } else {
                    return 'common.pieces';
                }
            }

            function hasValChangedFn(offerPart, type) {
                if (offerPart.status.name === 'ADDED_BY_SUPPLIER') {
                    return false;
                }
                if (vm.isOfferPartInSupplierWaitingStates(offerPart) || vm.isOfferPartInCreatorDeclinedStates(offerPart)) {
                    var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                    switch (type) {
                        case 'NAME':
                            return offerPart.name !== originalOfferPart.name;
                        case 'DESCRIPTION':
                            return offerPart.description !== originalOfferPart.description;
                        case 'AMOUNT':
                            return offerPart.amount !== originalOfferPart.amount;
                        case 'AMOUNT_TYPE':
                            return offerPart.amountType.name !== originalOfferPart.amountType.name;
                        case 'DATE_TIME':
                            return (
                                offerPart.dateTimeFrom !== originalOfferPart.dateTimeFrom ||
                                offerPart.dateTimeTo !== originalOfferPart.dateTimeTo
                            );
                        case 'PRICE':
                            return offerPart.newPrice !== originalOfferPart.price;
                        case 'PAID ONSITE':
                            return offerPart.finalPaidOnsite === true;
                        default:
                            return false;
                    }
                }
            }

            function hideAdditionalInfoIconForSupplierFn(offerPart) {
                return (
                    (offerPart.description == null || offerPart.description == '') &&
                    (offerPart.customerPrice == null || offerPart.customerPrice == '')
                );
                /*&& (offerPart.latitude == null || offerPart.latitude == '');*/
            }

            function hideInfoIconForSupplierFn(offerPart) {
                return (
                    offerPart.status.name == 'NOT_DELIVERABLE' ||
                    offerPart.status.name == 'ADDED_WAITING_FOR_CREATOR_DECLINED' ||
                    offerPart.status.name == 'ADDED_WAITING_FOR_SUPPLIER_DECLINED'
                );
            }

            function initFn(context, data, isExpanded) {
                vm.clearAll = false;
                vm.showMore = false;
                vm.totalPriceDisabled = false;
                vm.partPricesDisabled = false;
                vm.paidOnsiteDisabled = false;
                vm.blankTableHeader = false;
                vm.deleteColHeader = false;
                vm.disableSendAnswerBtn = false;
                vm.offerId = $stateParams.offerId;
                vm.showPage = $stateParams.showPage;
                vm.msgId = $stateParams.msgId;
                // vm.clearAttachedFiles();
                vm.getNotificationData();
                var currentTab = vm.getActiveTab();
                if (!currentTab) {
                    vm.setActive('offer');
                }
                $scope.startProgress('top');
                Page.supplierDetailPage(
                    { offerId: vm.offerId },
                    function (response) {
                        vm.offer = response.offer;
                        vm.offerPartSchedule = response.offer.offerPartSchedule;
                        vm.timedOfferParts = response.offer.offerPartSchedule.timedOfferParts;
                        vm.timelessOfferParts = response.offer.offerPartSchedule.timelessOfferParts;
                        vm.beforeEventAccommodations = response.offer.offerPartSchedule.beforeEventAccommodations;
                        vm.afterEventAccommodations = response.offer.offerPartSchedule.afterEventAccommodations;
                        vm.filteredTimedOfferParts = getPartData(vm.timedOfferParts);
                        processOfferParts(vm.filteredTimedOfferParts);
                        vm.filteredTimelessOfferParts = getPartData(vm.timelessOfferParts);
                        processOfferParts(vm.filteredTimelessOfferParts);
                        vm.filteredBeforeEventAccommodations = getPartData(vm.beforeEventAccommodations);
                        vm.filteredAfterEventAccommodations = getPartData(vm.afterEventAccommodations);
                        vm.offer.requestOfferParts = getAllRequestOfferPartsFromSchedule();

                        /*      if (!vm.offer.alreadyAnswered && !vm.isOfferInCanceledState(vm.offer)) {
                        vm.offer.cancelPeriod = null;
                    }*/
                        if (response.selectedUserGroupChanged) {
                            AuthenticationService.updateUserData(response.userData);
                        }
                        vm.requestId = vm.offer.request.id;
                        vm.supplierId = vm.offer.supplier.id;
                        vm.supplierName = vm.offer.supplierName;
                        vm.offer.request = response.request;
                        vm.categories = response.categories;
                        vm.centralBooking = response.centralBooking;
                        vm.supplierUserGroup = response.supplierUserGroup;
                        angular.forEach(vm.categories, (category) => {
                            vm.categoryIds.push(category.id);
                        });
                        vm.event = response.event;
                        vm.ownerGroup = response.ownerGroup;
                        vm.supplierDiscountEnabled = vm.ownerGroup.supplierDiscountEnabled;
                        vm.showDiscountedPrice = response.offer.discount > 0;
                        vm.hasNoDiscount = response.offer.discount == null;
                        UserGroupData.setUserGroupData(response.ownerGroup);
                        vm.includeVat = amountAndPriceService.shouldIncludeVatOnPrice();
                        vm.paidOnsiteColumn = true;
                        vm.eventId = vm.event.id;
                        processParts();
                        vm.partsLoaded = true;
                        vm.showParticipantsToSupplier = response.showParticipantsToSupplier;
                        vm.finalDetailsSharedDate = vm.offer.finalDetailsSharedDate;
                        vm.creatorBillingAddress = response.creatorBillingAddress;
                        isUsingClientBillingAddressAndIsELFn(vm.clientName, vm.ownerGroup.userGroupBillingAddressEnum.name);
                        vm.userGroupMembers = response.usersInGroup;
                        vm.currencyList = response.currencyList;
                        vm.selectedCurrency = response.selectedCurrency;
                        vm.selectedCurrencyId = response.selectedCurrency.id;
                        vm.originalHandOverDate = vm.offer.handOverDate;
                        vm.originalInternalReference = vm.offer.internalReference;
                        vm.originalCancelPeriod = vm.offer.cancelPeriod;
                        vm.originalReservationCancelDays = vm.offer.reservationCancelDays;
                        vm.originalExpirationDate = vm.offer.expirationDate;
                        vm.isOfferConfirmedBySupplier = vm.offer.isOfferConfirmedBySupplier;
                        if (vm.offer.cancelPeriod) {
                            vm.calculateSafeCancellationDate(vm.offer.cancelPeriod);
                        }
                        //to handle the case of selected currency soft deleted
                        checkAndIncludeSelectedCurrencyInCurrencyList(vm.selectedCurrency);

                        vm.setNewPartPrice();
                        setVatPartPriceFn();
                        setFinalPriceAndAmount(vm.offer.requestOfferParts);
                        vm.managePriceLock();
                        getMessageOfSupplierFn();
                        vm.offer.price = vm.calculateTotalPrice();
                        vm.offerCanceledAmount = vm.getOfferCanceledAmountWithCurrency();
                        calculateOriginalTotalPrice();
                        displayDiffCol(response.offer, vm.offer.requestOfferParts);
                        calculateOfferExpirationDays();
                        calculateSafeCancellationExpiryDays();
                        calculatePaidOnsitePriceFn();
                        calculateOutstandingAmount();
                        vm.supplierUserName = response.userData.email;
                        vm.presentation = response.presentation;
                        if (response.presentation) {
                            vm.presentationImages = response.presentation.presentationImages;
                        }
                        vm.isUserEnabled = response.isUserEnabled;
                        vm.confirmation = response.confirmation;
                        vm.offer.responsibleUserEmail = '';
                        vm.processCovidData(response.offer);
                        vm.setMainThumbNailImage();
                        vm.paidOnsiteDisabled = vm.offer.statusDetailed.name === 'FINISHED_WAITING_FOR_CREATOR';
                        if (context == 'top') {
                            $rootScope.$emit('event:user:action', {
                                action: 'supplier.open.request.offer',
                                data: {
                                    'Offer id': vm.offer.id,
                                },
                            });
                        }
                        if (response && response.offer && isExpanded) {
                            let offerPartToExpand = _.find(vm.offer.requestOfferParts, function (part) {
                                return part.id === data.id;
                            });
                            offerPartToExpand.expanded = true;
                        }

                        /*
                As per task:: https://app.asana.com/0/1122760533352173/1144188305782893

                if (vm.showParticipantsToSupplier) {
                  RequestOffer.getSupplierAgreeTermsAndConditions(vm.offer.id, function (response) {
                    if(!response.dataAccessHandler){
                        OgModal.open('supplier:terms', {offerId:vm.offer.id,langSelected: $rootScope.langSelected});
                    }

                  })
                }*/
                        vm.originalOfferPartsList = angular.copy(vm.offer.requestOfferParts);
                        vm.offerPartsWithActions = [];
                        vm.offerPartOptionsWithActions = [];
                        $rootScope.ROPEditId = undefined;
                        vm.btnMap = { name: 'requestOfferPart.button.action.all', ngclass: 'spAction' };
                        vm.finalPriceAndAmountEdit = CommonFunctions.hasIntersectValue(
                            [response.offer.status.name],
                            ['FINISHED', 'FINISHED_WAITING_FOR_SUPPLIER', 'CANCELED_AND_FINISHED']
                        );
                        vm.colspan = 1;
                        if (vm.showPage) {
                            switch (vm.showPage) {
                                case 'confirmation':
                                    vm.confirmBookedOffer();
                                    break;
                                case 'bookingTerm':
                                    vm.showBookingTerms();
                                    break;
                                case 'billingInfo':
                                    vm.showBillingInfo();
                                    break;
                                case 'messages':
                                    vm.setActive('messages');
                                    vm.viewMessage();
                                    break;
                                case 'participantActivities':
                                    vm.activateParticipantSummaryTab();
                                    break;
                                default:
                                    break;
                            }
                        }
                        if (currentTab === 'final_details') {
                            getFinalDetailsFn();
                        }
                        getOfferPartCommentFn(vm.offer.requestOfferParts);
                        // vm.checkIfParticipantUpdating(vm.eventId);
                        var participantTabLoaded = $scope.$watch('offerDetailCtrl.participantTabLoaded()', (newVal) => {
                            if (newVal) {
                                participantTabLoaded();
                                OfferInfoStatus.getOfferInfoStatus(vm.offerId, function (response) {
                                    vm.guideType = response.type.name;
                                    if (
                                        [GlobalConstants.SHOW_ALL, GlobalConstants.SHOW_PARTICIPANT_INFO].indexOf(
                                            vm.guideType
                                        ) > -1
                                    ) {
                                        vm.addHints();
                                    }
                                });
                            }
                        });
                        showRowColorMeaningFn();
                        if (vm.offer && vm.offer.supplierMessage) {
                            getSupplierMessageHeight();
                        }
                        if (
                            vm.offer &&
                            !vm.offer.alreadyAnswered &&
                            vm.offer.status.name === 'SENT' &&
                            vm.offer.agreementNumber
                        ) {
                            let data = {
                                contractInfo: $translate.instant(
                                    'common.translate.supplier.info.modal.customer.agreement.number',
                                    { p0: vm.offer.agreementNumber }
                                ),
                            };

                            let offerModalData = supplierAgreementReferenceInfoModal;
                            offerModalData.inputs = { data };
                            ModalService.showModal(offerModalData).then((modal) => {
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        }
                        if (
                            vm.offer &&
                            !vm.offer.alreadyAnswered &&
                            vm.offer.status.name === 'SENT' &&
                            vm.supplierDiscountEnabled
                        ) {
                            let data = {
                                supplierDiscountDescription: $translate.instant('common.translate.supplier.discount.info.modal.description')
                            };

                            let supplierDiscountModalData = supplierDiscountInfoModal;
                            supplierDiscountModalData.inputs = { data };
                            ModalService.showModal(supplierDiscountModalData).then((modal) => {
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        }
                        $scope.endProgress('top');
                    },
                    $scope.endWithError
                );
                setUnreadMessageCountFn();
                vm.clientName = ClientService.getClientName();
                vm.clientDisplayName = ClientService.getClientDisplayName();
                vm.client = ClientService.get();
            }

            function getPartData(offerPartsData) {
                if (offerPartsData) {
                    let filteredParts = [];
                    angular.forEach(offerPartsData, (parts) => {
                        let _filteredAccommodations = [];
                        let _fiteredParts = filterScheduledOfferParts(parts.partData[0].parts);
                        angular.copy(_fiteredParts, parts.partData[0].parts);
                        _filteredAccommodations = vm.getAccommodationParts(parts.partData[0]);
                        parts.partData[0].accommodations = _filteredAccommodations;
                        parts.hasOfferParts = _fiteredParts.length > 0 || _filteredAccommodations.length > 0;
                        filteredParts.push(parts);
                    });
                    return filteredParts;
                }
            }

            function filterScheduledOfferParts(parts) {
                var filterOfferParts = [];
                angular.forEach(parts, function (offerPart) {
                    if (
                        vm.offer.status.name === 'BOOKED_LOCKED_BY_CREATOR' &&
                        (offerPart.status.name === 'ADDED_WAITING_FOR_SUPPLIER' ||
                            offerPart.status.name === 'ADDED_BY_CREATOR')
                    ) {
                        return true;
                    } else {
                        filterOfferParts.push(offerPart);
                    }
                });
                return filterOfferParts;
            }

            function checkAccommodationPartsFn(partData) {
                if (
                    partData &&
                    (partData.singleRoom ||
                        partData.doubleRoom ||
                        (partData.otherAccommodations && partData.otherAccommodations.length > 0))
                ) {
                    return true;
                } else {
                    return false;
                }
            }

            function getAccommodationPartsFn(partData) {
                let accommodationParts = [];
                if (partData.singleRoom) {
                    accommodationParts.push(partData.singleRoom);
                }
                if (partData.doubleRoom) {
                    accommodationParts.push(partData.doubleRoom);
                }
                if (partData.otherAccommodations && partData.otherAccommodations.length > 0) {
                    angular.forEach(partData.otherAccommodations, (otherAccommodation) => {
                        accommodationParts.push(otherAccommodation);
                    });
                }
                let filteredAccommodationParts = filterScheduledOfferParts(accommodationParts);
                processOfferPartsSchedule(filteredAccommodationParts);
                return filteredAccommodationParts;
            }

            function getSupplierMessageHeight() {
                $timeout(() => {
                    let xElementHeight = document.getElementById('xElement').offsetHeight;
                    let text = jQuery('.expandable-box .text');
                    let textHeight = text[0].scrollHeight;
                    if (textHeight >= 257) {
                        vm.seeShowMoreBtn = true;
                    }
                    jQuery('.match-height-element').css('min-height', xElementHeight);
                    vm.dynamicTextHeight = xElementHeight - 51;
                    jQuery('.expandable-box .text').css('max-height', vm.dynamicTextHeight);
                });
            }

            function participantTabLoadedFn() {
                return document.querySelector('#participant_tab_header') && true;
            }

            function isOfferPartInCreatorWaitingStatesFn(offerPart) {
                var offerPartIsInCreatorWaitingStates = false;
                angular.forEach(GlobalConstants.CREATOR_WAITING_STATES, function (state) {
                    if (!offerPartIsInCreatorWaitingStates) {
                        if (offerPart.status.name == state) {
                            offerPartIsInCreatorWaitingStates = true;
                        }
                    }
                });
                return offerPartIsInCreatorWaitingStates;
            }

            function clearTime(date) {
                date.setHours(0, 0, 0, 0);
                return date;
            }

            function clearTimeFromTimestamp(timestamp) {
                var date = new Date(timestamp);
                date.setHours(0, 0, 0, 0);
                return date.getTime();
            }

            function isDateLessThanCurrentDateFn(date) {
                let now = new Date();
                if (date && clearTimeFromTimestamp(date) < clearTime(now).getTime()) {
                    return true;
                }
                return false;
            }

            function isOfferPartInCreatorDeclinedStatesFn(offerPart) {
                var offerPartIsInCreatorDeclinedStates = false;
                var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                angular.forEach(GlobalConstants.CREATOR_DECLINED_STATES, function (state) {
                    if (!offerPartIsInCreatorDeclinedStates) {
                        if (originalOfferPart && originalOfferPart.status.name === state) {
                            offerPartIsInCreatorDeclinedStates = true;
                        }
                    }
                });
                return offerPartIsInCreatorDeclinedStates;
            }

            function isOfferPartInSupplierDeclinedStatesFn(offerPart) {
                var offerPartIsInSupplierDeclinedStates = false;
                var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                angular.forEach(GlobalConstants.SUPPLIER_DECLINED_STATES, function (state) {
                    if (!offerPartIsInSupplierDeclinedStates) {
                        if (originalOfferPart.status.name === state) {
                            offerPartIsInSupplierDeclinedStates = true;
                        }
                    }
                });
                return offerPartIsInSupplierDeclinedStates;
            }

            function isOfferPartInSupplierWaitingStatesFn(offerPart) {
                if (offerPart.status.name === 'ADDED_BY_SUPPLIER') {
                    return false;
                }
                var offerPartIsInSupplierWaitingStates = false;
                var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                angular.forEach(GlobalConstants.SUPPLIER_WAITING_STATES, function (state) {
                    if (!offerPartIsInSupplierWaitingStates) {
                        if (originalOfferPart && originalOfferPart.status.name === state) {
                            offerPartIsInSupplierWaitingStates = true;
                        }
                    }
                });
                return offerPartIsInSupplierWaitingStates;
            }

            function isOfferPartInToAnswerStatesFn(offerPart) {
                if (offerPart.status.name === 'ADDED_BY_SUPPLIER') {
                    return false;
                }
                var offerPartIsInToAnswerStates = false;
                var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                angular.forEach(GlobalConstants.SUPPLIER_TO_ANSWER_STATES, function (state) {
                    if (!offerPartIsInToAnswerStates) {
                        if (originalOfferPart && originalOfferPart.status.name === state) {
                            offerPartIsInToAnswerStates = true;
                        }
                        if (originalOfferPart && originalOfferPart.hasRequestOfferPartOptions) {
                            angular.forEach(originalOfferPart.offerPartOptions, (offerPartOption) => {
                                if (offerPartOption && offerPartOption.status.name === state) {
                                    offerPartIsInToAnswerStates = true;
                                }
                            });
                        }
                    }
                });
                return offerPartIsInToAnswerStates;
            }

            function managePriceLockFn() {
                // TODO total price editing is now disabled. Re-enable this when enabling total price editing.
                if (false && vm.offer) {
                    var offerPrice = amountAndPriceService.round(parseFloat(vm.offer.price));
                    if (isNaN(offerPrice)) {
                        offerPrice = 0;
                    }
                    var partPriceSum = vm.calculateTotalPrice();
                    if (offerPrice == 0 && offerPrice == partPriceSum) {
                        vm.totalPriceDisabled = false;
                        vm.partPricesDisabled = false;
                    } else if (offerPrice != 0 && partPriceSum == 0) {
                        vm.totalPriceDisabled = false;
                        vm.partPricesDisabled = true;
                    } else if (partPriceSum != 0 && offerPrice == partPriceSum) {
                        vm.totalPriceDisabled = true;
                        vm.partPricesDisabled = false;
                    } else if (offerPrice == 0 && partPriceSum != 0) {
                        vm.totalPriceDisabled = true;
                        vm.partPricesDisabled = false;
                    }
                }
            }

            function makeNotificationSeenFn(notifications) {
                var requestObj = [];
                if (notifications && Array.isArray(notifications)) {
                    requestObj = notifications.map(function (d) {
                        return {
                            id: d.id,
                        };
                    });
                } else {
                    requestObj.push({ id: notifications && notifications.id });
                }
                Notification.setAsRead(
                    requestObj,
                    function () {
                        getNotificationData();
                    },
                    $scope.endWithErrorOverlay()
                );
            }

            function notDeliverableRequestOfferPartFn(offerPart) {
                if (
                    offerPart.deletable ||
                    (offerPart.status.name === 'ADDED_BY_SUPPLIER' &&
                        vm.offer.status.name === 'FINISHED_WAITING_FOR_SUPPLIER')
                ) {
                    vm.message = $translate.instant('request.offer.part.delete.notification');
                }
            }

            function getConfirmationTriggerTypeFn(offerPart) {
                let triggerType = PopoverTypeEnum.CLICK;
                if (
                    offerPart &&
                    offerPart.editable &&
                    (offerPart.editable.name === 'DENIED' || this.offer.statusDetailed.name === 'CANCELED')
                ) {
                    triggerType = PopoverTypeEnum.NONE;
                }
                return triggerType;
            }

            function confirmDeleteOfferPartFn(offerPart) {
                if (vm.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
                    vm.deleteOfferPart(offerPart);
                } else {
                    RequestOfferPart['delete'](
                        offerPart.id,
                        function () {
                            vm.reload();
                        },
                        function () {
                            var settingserr = {
                                message: 'requestOffer.error.delete.failed',
                            };
                            $rootScope.$broadcast('dialog:confirmation:open', settingserr);
                        }
                    );
                }
            }

            function offerPresentationDialogFn() {
                var data = {
                    supplierId: vm.supplierId,
                    offerId: parseInt(vm.offerId, 10),
                };
                $rootScope.dialog('request:offer:presentation', data);
            }

            function openContactUsModalFn() {
                if (!AuthenticationService.checkIfUserVerifiedAndAlert() || vm.viewModal) {
                    return;
                }
                vm.viewModal = true;
                let contactUsModalReference = contactUsModal;
                let data = {
                    offerId: vm.offerId,
                };
                contactUsModalReference.inputs = { data };
                ModalService.showModal(contactUsModalReference).then((modal) => {
                    vm.viewModal = false;
                    modal.element.modal();
                    modal.close.then(() => {
                        console.log('modal is closed!');
                    });
                });
            }

            function openInlineRowAdditionFn(offer) {
                $rootScope.ROPEditId = 0;
                $timeout(function () {
                    $rootScope.ROPEditId = 'a' + offer.id;
                }, 100);
            }

            function prolongOfferExpirationDateFn() {
                vm.prolongOfferForReservationDate = true;
            }

            function prolongOfferCancelPeriodDateFn() {
                vm.prolongOfferForCancelPeriodDate = true;
            }

            function processOfferParts(offerParts) {
                if (offerParts && offerParts.length > 0) {
                    offerParts.forEach((p) => {
                        processOfferPartsSchedule(p.partData[0].parts);
                    });
                } else {
                    return;
                }
            }

            function processOfferPartsSchedule(parts) {
                parts.forEach((p) => {
                    p.dummyOfferPartId = p.id;
                    if (
                        EDITED_BY_SUPPLIER_PART_STATE.indexOf(p.status.name) > -1 &&
                        p.nameCode !== 'common.translate.offer.part.type.cancellation.fee'
                    ) {
                        p.name = p.supplierName;
                        p.nameCode = p.supplierNameCode;
                        p.dateTimeFrom = p.supplierDateTimeFrom;
                        p.dateTimeTo = p.supplierDateTimeTo;
                        p.amount = p.supplierAmount;
                        p.multiplicator = p.supplierMultiplicator;
                        p.amountType = p.supplierAmountType;
                        p.partType = p.supplierPartType;
                    }
                });
            }

            function processParts() {
                vm.offer.requestOfferParts.forEach((p) => {
                    p.dummyOfferPartId = p.id;
                    if (
                        EDITED_BY_SUPPLIER_PART_STATE.indexOf(p.status.name) > -1 &&
                        p.nameCode !== 'common.translate.offer.part.type.cancellation.fee'
                    ) {
                        p.name = p.supplierName;
                        p.nameCode = p.supplierNameCode;
                        p.dateTimeFrom = p.supplierDateTimeFrom;
                        p.dateTimeTo = p.supplierDateTimeTo;
                        p.amount = p.supplierAmount;
                        p.multiplicator = p.supplierMultiplicator;
                        p.amountType = p.supplierAmountType;
                        p.partType = p.supplierPartType;
                    }
                });
            }

            function getAllRequestOfferPartsFromSchedule() {
                let _parts = [];
                angular.forEach(vm.filteredTimedOfferParts, (_part) => {
                    let _data = _part.partData[0];
                    _parts.push(..._data.accommodations);
                    _parts.push(..._data.parts);
                });

                angular.forEach(vm.filteredTimelessOfferParts, (_part) => {
                    let _data = _part.partData[0];
                    _parts.push(..._data.parts);
                });

                angular.forEach(vm.filteredBeforeEventAccommodations, (_part) => {
                    let _data = _part.partData[0];
                    _parts.push(..._data.accommodations);
                });

                angular.forEach(vm.filteredAfterEventAccommodations, (_part) => {
                    let _data = _part.partData[0];
                    _parts.push(..._data.accommodations);
                });
                return _parts;
            }

            function updateVatIncludedInPriceFn(vatIncludedInPrice = false) {
                $scope.startProgress('top');
                RequestOffer.updateVatIncludedInPrice(
                    {
                        offerId: vm.offerId,
                        vatIncludedInPrice: vatIncludedInPrice,
                    },
                    (res) => {
                        vm.offer.vatIncludedInPrice = res.vatIncludedInPrice;
                        if (!vm.offer.vatIncludedInPrice) {
                            vm.resetNewPartPriceInclVat();
                            $scope.$broadcast('disable.vat.included.in.price', {});
                        } else {
                            vm.setNewPartPrice();
                            $scope.$broadcast('enable.vat.included.in.price', {});
                        }
                    },
                    $scope.endProgress('top'),
                    $scope.endWithAlert
                );
            }

            function parseDateInLocaleFormatFn(date) {
                return TimezoneService.customDateFormat(
                    TimezoneService.toLocalTime(date),
                    DateService.getDateFormatByLocale()
                );
            }

            function parseDateInLocaleFormatWithoutTimeFn(date) {
                return TimezoneService.formatDate(TimezoneService.toLocalTime(date), DateService.getDateFormatByLocale());
            }

            function parseDateInLocaleFormatTimeFn(date) {
                return TimezoneService.customDateFormat(
                    TimezoneService.toLocalTime(date),
                    DateService.getDateTimeFormatByLocale()
                );
            }

            function subsTractDateFromNowFn(date) {
                if (!date) {
                    return;
                }
                let now = new Date();
                let ms = clearTime(now).getTime() - clearTimeFromTimestamp(date);
                var diff = new moment.duration(ms);
                return Math.ceil(diff.asDays());
            }

            function subsTractNowFromDateFn(date) {
                if (!date) {
                    return;
                }
                let now = new Date();
                let ms = clearTimeFromTimestamp(date) - clearTime(now).getTime();
                var diff = new moment.duration(ms);
                return Math.ceil(diff.asDays());
            }

            function calculateSafeCancellationDateFn(cancelPeriod) {
                if (!vm.offer) {
                    return;
                }
                var eventFromDate = vm.event.fromDate;
                var offerFirstDate = _.min(vm.offer.requestOfferParts, function (p) {
                    return p.dateTimeFrom;
                }).dateTimeFrom;
                if (eventFromDate) {
                    var cancelPeriodOffSet = cancelPeriod * (24 * 60 * 60 * 1000);
                    vm.offer.safeCancellationDate = eventFromDate - cancelPeriodOffSet + offerFirstDate;
                }
                calculateSafeCancellationExpiryDays();
            }

            function calculateOfferOriginalPriceFn() {
                var totalOriginalPrice = amountAndPriceService.getOfferOriginalPrice(vm.offer.requestOfferParts);
                vm.originalTotalPrice = originalTotalPrice;
            }

            function calculateSafeCancellationExpiryDays() {
                if (isDateLessThanCurrentDateFn(vm.offer.safeCancellationDate)) {
                    vm.safeCancellationDateExpiredBeforeDays = subsTractDateFromNowFn(vm.offer.safeCancellationDate);
                    vm.safeCancellationDateExpiredAfterDays = null;
                } else {
                    vm.safeCancellationDateExpiredAfterDays = subsTractNowFromDateFn(vm.offer.safeCancellationDate);
                    vm.safeCancellationDateExpiredBeforeDays = null;
                }
            }

            function calculateExpirationDateFn(reservationCancelDays) {
                if (!vm.offer) {
                    return;
                }
                vm.offer.expirationDate = new Date().getTime() + reservationCancelDays * 24 * 60 * 60 * 1000;
                calculateOfferExpirationDays();
            }

            function calculateOfferExpirationDays() {
                if (isDateLessThanCurrentDateFn(vm.offer.expirationDate)) {
                    vm.expiredBeforeDays = subsTractDateFromNowFn(vm.offer.expirationDate);
                    vm.expireAfterDays = null;
                } else {
                    vm.expireAfterDays = subsTractNowFromDateFn(vm.offer.expirationDate);
                    vm.expiredBeforeDays = null;
                }
            }

            function reloadFn(offerPart, isExpanded) {
                $scope.endProgress('top');
                vm.init('', offerPart, isExpanded);
            }

            function responsibleChangedFn() {
                $scope.startProgress();
                RequestOffer.updateSupplierResponsibleUser(
                    {
                        offerId: vm.offerId,
                        responsibleSupplierUserId: vm.offer.responsibleSupplierUser.id,
                    },
                    (res) => {
                        vm.offer.responsibleBookingSupplierUser = res.responsibleBookingSupplierUser;
                    },
                    $scope.endProgress,
                    $scope.endWithAlert
                );
            }

            function responsibleForBookingChangedFn() {
                $scope.startProgress('top');
                RequestOffer.updateBookingSupplierResponsibleUser(
                    {
                        offerId: vm.offerId,
                        responsibleBookingSupplierUserId: vm.offer.responsibleBookingSupplierUser.id,
                    },
                    (res) => {
                        vm.offer.responsibleBookingSupplierUser = res.responsibleBookingSupplierUser;
                        vm.offer.responsibleSupplierUser = res.responsibleBookingSupplierUser;
                    },
                    $scope.endProgress('top'),
                    $scope.endWithAlert
                );
            }

            function responsibleForManagingChangedFn() {
                $scope.startProgress('top');
                RequestOffer.updateManagingSupplierResponsibleUser(
                    {
                        offerId: vm.offerId,
                        responsibleManagingSupplierUserId: vm.offer.responsibleManagingSupplierUser.id,
                    },
                    (res) => {
                        vm.offer.responsibleManagingSupplierUser = res.responsibleManagingSupplierUser;
                    },
                    $scope.endProgress('top'),
                    $scope.endWithAlert
                );
            }

            function updateIncludeResponsibleBookingSupplierUserFn() {
                $scope.startProgress('top');
                RequestOffer.updateIncludeResponsibleBookingSupplierUser(
                    {
                        offerId: vm.offerId,
                        includeResponsibleBookingSupplierUser: vm.offer.includeResponsibleBookingSupplierUser,
                    },
                    (res) => {
                        vm.offer.includeResponsibleBookingSupplierUser = res.includeResponsibleBookingSupplierUser;
                        $scope.endProgress('top');
                    },
                    $scope.endWithAlert
                );
            }

            function changeHandOverDateFn() {
                $scope.startProgress('top');
                RequestOffer.updateHandOverDateAndSendEmail(
                    {
                        offerId: vm.offerId,
                        handOverDate: vm.offer.handOverDate,
                    },
                    (res) => {
                        vm.offer.handOverDate = res.handOverDate;
                        vm.originalHandOverDate = res.handOverDate;
                        vm.hasHandOverDateChanged = false;
                        $scope.endProgress('top');
                    },
                    $scope.endWithAlert
                );
            }

            function handleOnChangedFn(currentValue) {
                if (UtilFunctions.isDefinedAndNotNull(currentValue)) {
                    vm.hasHandOverDateChanged = vm.originalHandOverDate !== currentValue;
                }
            }

            function handleOnChangedCovidDataFn(modalName, currentValue) {
                FieldChangedSvc.checkOnChanged(modalName, currentValue);
                this.disableCovidButtons = !FieldChangedSvc.isFieldChanged();
            }

            function removeCovidFilesUploadCallbackFn(files) {
                if (!files.length > 0) {
                    vm.handleOnChangedCovidData('covidMeasuresFiles', null);
                }
            }

            function removeCovidFileFn(fileId) {
                $scope.startProgress();
                RequestOffer.deleteCovidFiles(
                    { offerId: vm.offerId, fileId: fileId },
                    (response) => {
                        vm.processCovidData(response);
                    },
                    $scope.endWithAlert
                );
            }

            function updateInternalReferenceFn() {
                $scope.startProgress('top');
                RequestOffer.updateInternalReference(
                    {
                        offerId: vm.offerId,
                        internalReference: vm.offer.internalReference,
                    },
                    (res) => {
                        vm.offer.internalReference = res.internalReference;
                        vm.showInternalReferenceSection = false;
                        $scope.endProgress('top');
                    },
                    $scope.endWithAlert
                );
            }

            function closeInternalReferenceSectionFn() {
                vm.offer.internalReference = vm.originalInternalReference;
                vm.showInternalReferenceSection = false;
            }

            function openEditProfileModalFn(responsibleManagingSupplierUser = false) {
                if(vm.viewModal) {
                    return;
                }
                vm.viewModal = true;
                let _centralBooking = vm.centralBooking;
                if (!responsibleManagingSupplierUser) {
                    _centralBooking = false;
                }
                let data = {
                    openGroupMembersTab: true,
                    openPresentationByDefault: false,
                    focusOnEmailField: true,
                    centralBooking: _centralBooking,
                    supplierUserGroup: vm.supplierUserGroup,
                };

                let profileSettingsModalData = profileSettingsModal;
                profileSettingsModalData.inputs = { data };

                ModalService.showModal(profileSettingsModalData).then((modal) => {
                    vm.viewModal = false;
                    modal.element.modal();
                    modal.close.then(() => {
                        console.log('modal is closed!');
                    });
                });
            }

            function isOfferInAfterBookedStatesFn() {
                return (
                    vm.offer &&
                    vm.offer.status &&
                    RequestOfferStatusUtils.OFFER_AFTER_BOOKED_TO_APPROVED.indexOf(vm.offer.status.name) > -1
                );
            }

            function isOfferInFromBookedStatesFn() {
                return (
                    vm.offer &&
                    vm.offer.status &&
                    RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(vm.offer.status.name) > -1
                );
            }

            function isOfferInBeforeBookedStatesFn() {
                return (
                    vm.offer &&
                    vm.offer.status &&
                    RequestOfferStatusUtils.ALL_BEFORE_BOOKED_STATUS.indexOf(vm.offer.status.name) > -1
                );
            }

            function saveCovidAdaptationMeasuresFn() {
                $scope.startProgress();
                RequestOffer.updateSupplierCovidAdaptationMeasures(
                    {
                        offerId: vm.offerId,
                        hasCovidPreventionMeasures: vm.hasCovidPreventionMeasures,
                        covidPreventionMeasures: vm.covidPreventionMeasures,
                        covidMeasureFiles: vm.attachedFiles.uploads,
                    },
                    (response) => {
                        vm.processCovidData(response);
                    },
                    $scope.endWithAlert
                );
            }

            function sendFinalPricesFn() {
                $scope.startProgress('top');
                RequestOffer.sendFinalPrice(
                    {
                        offerId: vm.offerId,
                    },
                    (res) => {
                        if (res) {
                            $scope.$broadcast('disable.offer.part.option.edit', {});
                            vm.reload();
                        }
                    },
                    $scope.endWithAlert
                );
            }

            function uploadFinalDetailsFilesFn(fileSelect) {
                vm.attachedFiles = fileSelect;
                if (vm.attachedFiles.uploads) {
                    $scope.startProgress('uploadFinalPriceFiles');
                    RequestOffer.uploadFinalPriceAttachments(
                        {
                            offerId: vm.offerId,
                            finalDetailsFiles: vm.attachedFiles.uploads,
                        },
                        (res) => {
                            vm.clearAll = true;
                            vm.offer.finalDetailsFiles = res.uplodedFiles;
                            $scope.endProgress('uploadFinalPriceFiles');
                        },
                        $scope.endWithAlert
                    );
                }
            }

            function deleteFileConfirmationFn(attachment) {
                let finalDetailFiles = vm.offer.finalDetailsFiles;
                for (let fileIndex in finalDetailFiles) {
                    if (finalDetailFiles[fileIndex].id === attachment.id) {
                        attachment.showDeleteConfirmation = !attachment.showDeleteConfirmation;
                        break;
                    } else if (finalDetailFiles[fileIndex].showDeleteConfirmation) {
                        finalDetailFiles[fileIndex].showDeleteConfirmation = false;
                        attachment.showDeleteConfirmation = !attachment.showDeleteConfirmation;
                        break;
                    }
                }
            }

            function deleteFinalDetailsFileFn(attachment) {
                $scope.startProgress('uploadFinalPriceFiles');
                attachment.showDeleteConfirmation = false;
                RequestOffer.deleteFinalDetailsFile(
                    { fileId: attachment.id },
                    (response) => {
                        vm.offer.finalDetailsFiles = response;
                        $scope.endProgress('uploadFinalPriceFiles');
                    },
                    $scope.endWithAlert
                );
            }

            function checkCancelationFeeFieldFn() {
                let cancelFeePart = _.find(vm.offer.requestOfferParts, function (part) {
                    return part.nameCode === 'common.translate.offer.part.type.cancellation.fee';
                });
                if (cancelFeePart && isNaN(parseFloat(cancelFeePart.finalPrice))) {
                    vm.disableFinalPriceBtn = true;
                } else {
                    vm.disableFinalPriceBtn = false;
                }
            }

            function sendCancellationFeesFn() {
                $scope.startProgress('top');
                RequestOffer.sendCancellationFees(
                    { offerId: vm.offerId },
                    (res) => {
                        if (res) {
                            vm.reload();
                        }
                    },
                    $scope.endWithAlert('top')
                );
            }

            function isCancellationFeesFn() {
                return (
                    ['CANCELED_AND_FINISHED', 'CANCELED_WAITING_FOR_SUPPLIER', 'CANCELED_WAITING_FOR_CREATOR'].indexOf(
                        vm.offer.status.name
                    ) > -1
                );
            }

            function isOfferInCancelStateFn(offer) {
                return offer && CommonFunctions.isItCanceledOffer(offer);
            }

            function isOfferInEditedByCreatorStateFn(offer, offerPart) {
                return (
                    offer &&
                    ['EDITED_BY_CREATOR'].indexOf(offer.status.name) > -1 &&
                    offerPart &&
                    offerPart.status.name === 'ADDED_BY_CREATOR'
                );
            }

            function offerInFinishedStateFn() {
                return CommonFunctions.hasIntersectValue(
                    [vm.offer.status.name],
                    ['FINISHED', 'FINISHED_WAITING_FOR_SUPPLIER', 'FINISHED_WAITING_FOR_CREATOR']
                );
            }

            function offerInAllFinishedStateFn() {
                return CommonFunctions.hasIntersectValue(
                    [vm.offer.status.name],
                    RequestOfferStatusUtils.ALL_FINISHED_STATUS
                );
            }

            function sendFinanceDepMessageFn() {
                $scope.startProgress('top');
                RequestOffer.createMessage(
                    {
                        message: vm.messageBody,
                        offerId: vm.offerId,
                        status: vm.offer.status.name,
                    },
                    function (response) {
                        getMessageOfSupplierFn();
                        RequestOffer.emailMessageToFinanceDept(
                            {
                                message: vm.messageBody,
                                senderName: vm.senderName,
                                senderPhone: vm.senderPhone,
                                offerId: vm.offerId,
                            },
                            function () {
                                vm.messageBody = null;
                                vm.senderName = null;
                                vm.senderPhone = null;
                                vm.showSuccessMessage = true;
                                $scope.endProgress('top');
                            },
                            $scope.endWithError('top')
                        );
                    },
                    $scope.endWithError('top')
                );
            }

            function getMessageOfSupplierFn() {
                RequestOffer.getv1offerRest(
                    vm.offerId,
                    function (response) {
                        vm.activityMessageList = angular.copy(response);
                    },
                    $scope.endWithErrorOverlay
                );
            }

            function sendOfferToCreatorFn() {
                if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                    return;
                }
                if (vm.offer.price < 0 || !vm.offer.reservationCancelDays || !vm.offer.cancelPeriod) {
                    $rootScope.$broadcast('dialog:alert:open', {
                        message: 'requestOffer.mandatory.fields.notification',
                    });
                    return;
                } else {
                    /*
                 - For proper reflection in UI, to mitigate value not reflecting because of multiple requests at the same time.
                 - 0.5 seconds delay has been set.
                 */
                    setTimeout(() => {
                        $scope.appendToProgress({ func: sendOfferToCreatorInner });
                    }, 500);
                }
            }

            function setMainThumbNailImageFn() {
                if (vm.presentation) {
                    if (vm.presentation.logoUrl != null) {
                        vm.thumbnailImage = vm.presentation.logoUrl;
                    } else {
                        vm.thumbnailImage = vm.presentationImages ? _.values(vm.presentationImages)[0] : null;
                    }
                }
            }

            function setNewPartPriceFn() {
                angular.forEach(vm.offer.requestOfferParts, function (item) {
                    item.newPrice = item.price;
                    if (vm.includeVat && vm.offer.vatIncludedInPrice && item.price) {
                        item.newPriceInclVat = amountAndPriceService.calculatePriceFromPriceAndVat(item.price, item.vat);
                    }
                    if (SHOW_SUPPLIER_PRICE_STATE.indexOf(vm.offer.status.name) > -1) {
                        item.newPrice = item.supplierPrice;
                        if (vm.includeVat && vm.offer.vatIncludedInPrice && item.supplierPrice) {
                            item.newPriceInclVat = amountAndPriceService.calculatePriceFromPriceAndVat(
                                item.supplierPrice,
                                item.vat
                            );
                        }
                    }
                    if (EDITED_BY_SUPPLIER_PART_STATE.indexOf(item.status.name) > -1) {
                        item.newPrice = item.supplierPrice;
                        if (vm.includeVat && vm.offer.vatIncludedInPrice && item.supplierPrice) {
                            item.newPriceInclVat = amountAndPriceService.calculatePriceFromPriceAndVat(
                                item.supplierPrice,
                                item.vat
                            );
                        }
                    }
                });
            }

            function resetNewPartPriceInclVatFn() {
                angular.forEach(vm.offer.requestOfferParts, function (item) {
                    item.newPriceInclVat = null;
                });
            }

            function getPriceFromPartOptionFn(offerPart) {
                let _offerPartOptions = _.filter(offerPart.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                let _totalPrice = 0;
                if (_offerPartOptions.length > 0) {
                    angular.forEach(_offerPartOptions, (_offerPartOption) => {
                        if (_offerPartOption.priceInclVat) {
                            _offerPartOption.priceInclVat = null;
                        }
                        _totalPrice += amountAndPriceService.getRequestOfferPartOptionPrice(
                            vm.offer,
                            _offerPartOption,
                            offerPart
                        );
                    });
                    return _totalPrice;
                } else {
                    return '0';
                }
            }

            function getVatFromPartOptionFn(offerPart) {
                if (isOfferInFromBookedStatesFn() && offerPart.offerPartOptions.length > 1) return '-';
                let _offerPartOptions = _.filter(offerPart.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                let _vat = 0;
                if (_offerPartOptions.length === 1 && _offerPartOptions[0].vat) {
                    return _offerPartOptions[0].vat;
                } else if (_offerPartOptions.length > 1) {
                    let _isEveryVatSame = _.every(_offerPartOptions, (_offerPartOption) => {
                        return _offerPartOption.vat === _offerPartOptions[0].vat;
                    });
                    return _isEveryVatSame ? _offerPartOptions[0].vat : '-';
                } else {
                    return '-';
                }
            }

            function setVatPartPriceFn() {
                angular.forEach(vm.offer.requestOfferParts, function (offerPart) {
                    if (_.isNull(offerPart.vat)) {
                        offerPart.vat = vm.DEFAULT_VAT;
                    }
                });
            }

            function showAddRowBtnFn(offer) {
                if (offer) {
                    if (
                        CommonFunctions.hasIntersectValue(
                            [offer.status.name],
                            ['FINISHED', 'FINISHED_WAITING_FOR_SUPPLIER', 'FINISHED_WAITING_FOR_CREATOR']
                        )
                    ) {
                        return true; // we have to give add new row functionality
                    } else {
                        return !(CommonFunctions.hasIntersectValue([offer.status.name], ['ANSWERED']) || !offer.editable);
                    }
                }
            }

            function showAmountChangeToolTipFn(offerPart) {
                var correctOfferStatus = CommonFunctions.hasIntersectValue(
                    [vm.offer.status.name],
                    ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR']
                );
                var correctFinishedCondition =
                    offerPart.finalAmount > 0 &&
                    offerPart.finalAmount != offerPart.amount &&
                    offerPart.status.name != 'EDITED_WAITING_FOR_CREATOR';
                return correctOfferStatus && correctFinishedCondition && !vm.finalPriceAndAmountEdit;
            }

            function showBillingInfoFn() {
                $rootScope.dialog('show:billing:info', {
                    billingInfo: vm.creatorBillingAddress,
                });
            }

            function showBookingTermsFn() {
                let data = {
                    offer: vm.offer,
                    clientName: vm.clientName,
                    isUsingClientBillingAddressAndIsEL: vm.isUsingClientBillingAddressAndIsEL,
                };
                $rootScope.dialog('booking:terms', data);
            }

            function showDeleteRowBtnFn(offerPart) {
                if (offerPart.status.name === 'ADDED_BY_SUPPLIER' && !vm.isOfferInCanceledState(vm.offer)) {
                    if (vm.colspan === 2) {
                        vm.colspan = 1;
                    }
                    return true;
                }
                return false;
            }

            function showInfoTooltipFn(offerPart) {
                if (vm.offer.statusDetailed.name == 'BOOKED_WAITING_FOR_CREATOR') {
                    return (
                        vm.isOfferPartInCreatorWaitingStates(offerPart) || vm.isOfferPartInSupplierDeclinedStates(offerPart)
                    );
                }
                return false;
            }

            function showMessagesFn() {
                if (vm.appMode == 'MESSAGES') {
                    $rootScope.$broadcast('dialog:app:panel:close');
                    vm.appMode = '';
                } else if (!vm.disabled('MESSAGES')) {
                    var params = {
                        eventId: vm.eventId,
                        isEditable: true,
                        offerId: vm.offerId,
                    };
                    $rootScope.$broadcast('dialog:app:panel:open', 'supplier-messages.html', params);
                    vm.appMode = 'MESSAGES';
                }
            }

            function showNotificationFn() {
                return vm.notifications && vm.notifications.length > 0;
            }

            function showPresentationFn() {
                Presentation.get(
                    { offerId: vm.offer.id },
                    function (presentation) {
                        $rootScope.dialog('supplier:presentation', {
                            presentation: presentation,
                            supplierId: vm.supplierId
                        });
                    },
                    $scope.endWithError
                );
            }

            function showPriceChangeToolTipFn(offerPart) {
                if (hideToolTipForGivenPartStatuses(offerPart.status)) {
                    return false;
                }

                let _showPriceForFinished = showPriceChangeToolTipForFinishedOffer(offerPart);
                let _oldPrice = offerPart.oldPrice;
                let _newPrice = offerPart.price || 0;
                let _isOldPriceExists = UtilFunctions.isDefinedAndNotNull(_oldPrice);
                let _changed = _oldPrice !== _newPrice;
                let _isOfferStatusMatched = CommonFunctions.hasIntersectValue([vm.offer.status.name], ['ANSWERED']);
                let _showPriceForAnswered = _isOldPriceExists && _changed && _isOfferStatusMatched;

                return _showPriceForFinished || _showPriceForAnswered;
            }

            function showPriceChangeToolTipForFinishedOffer(offerPart) {
                var correctOfferStatus = CommonFunctions.hasIntersectValue(
                    [vm.offer.status.name],
                    ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR']
                );
                var correctFinishedCondition =
                    offerPart.finalPrice !== offerPart.price && offerPart.status.name !== 'EDITED_WAITING_FOR_CREATOR';
                return correctOfferStatus && correctFinishedCondition && !vm.finalPriceAndAmountEdit;
            }

            function showPriceChangeToolTipForOfferByStatusFn(offerPart, statusList) {
                // if (displayOfferPartChangeSummaryFn(offerPart)) {
                //     return false;
                // }

                if (hideToolTipForGivenPartStatuses(offerPart.status)) {
                    return false;
                }
                let _isOfferStatusMatched = CommonFunctions.hasIntersectValue([vm.offer.status.name], statusList);
                let _oldPrice = offerPart.oldPrice || 0;
                let _newPrice = offerPart.status.name === 'EDITED_BY_SUPPLIER' ? offerPart.newPrice : offerPart.price || 0;
                let _valueChanged = _oldPrice !== _newPrice;
                return _isOfferStatusMatched && UtilFunctions.isGreaterOrEqualToZero(_oldPrice) && _valueChanged;
            }

            function showSupplierOptionsFn(offerPart) {
                if (offerPart.status.name === 'ADDED_BY_SUPPLIER') {
                    return false;
                }
                var suppOptionFlag;
                suppOptionFlag =
                    vm.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER' &&
                    (vm.isOfferPartInSupplierWaitingStates(offerPart) || vm.isOfferPartInCreatorDeclinedStates(offerPart));
                if (suppOptionFlag && vm.colspan === 2) {
                    vm.colspan = 1;
                }
                if (suppOptionFlag) {
                    vm.blankTableHeader = true;
                } else {
                    vm.blankTableHeader = false;
                }
                return suppOptionFlag;
            }

            function showReEditPriceBtnFn(offer) {
                if (offer) {
                    return (
                        !vm.finalPriceAndAmountEdit &&
                        CommonFunctions.hasIntersectValue(
                            [offer.status.name],
                            [
                                'FINISHED_WAITING_FOR_CREATOR',
                                'FINISHED_WAITING_FOR_SUPPLIER',
                                'CANCELED_WAITING_FOR_CREATOR',
                                'CANCELED_WAITING_FOR_SUPPLIER',
                            ]
                        )
                    );
                }
            }

            function showCommissionFn(statusDetailName) {
                var notToShowCommissionStatusList = [
                    'NEW',
                    'NEW_NO_SUPPLIER',
                    'SENT',
                    'EDITED_BY_CREATOR',
                    'CANCELED',
                    'MANUAL',
                    'INTERNAL',
                    'MANUALLY_BOOKED',
                    'EXPIRED',
                    'DECLINED',
                    'TEMPLATE',
                ];
                return !CommonFunctions.hasIntersectValue([statusDetailName], notToShowCommissionStatusList);
            }

            function showSupplierBillingAddressFn(statusDetailName) {
                var showSupplierBillingAddresStatusList = [
                    'FINISHED_WAITING_FOR_CREATOR',
                    'FINISHED_WAITING_FOR_SUPPLIER',
                    'APPROVED',
                ];
                return CommonFunctions.hasIntersectValue([statusDetailName], showSupplierBillingAddresStatusList);
            }

            function shouldShowBillingInfoFn(statusDetailedName) {
                return CommonFunctions.hasIntersectValue([statusDetailedName], RequestOfferStatusUtils.SHOW_BILLING_INFO);
            }

            function shouldHideOfferPartDetailsFn(offerPart) {
                if (
                    [
                        'APPROVED',
                        'CANCELED_AND_APPROVED',
                        'CANCELED_WAITING_FOR_CREATOR',
                        'CANCELED_WAITING_FOR_SUPPLIER',
                        'CANCELED_AND_FINISHED',
                        'DECLINED',
                    ].indexOf(vm.offer.statusDetailed.name) > -1
                ) {
                    return offerPart.hasRequestOfferPartOptions;
                }
                return offerPart.isSuggestionEnabled;
            }

            function supplierAcceptsRequestOfferPartFn(requestOfferPart) {
                // AM: disabled the cancel fee dialog for now to simplify the UX.
                // This will probably come back so please don't remove any code for it.
                if (false && vm.offer.lastDateChange && new Date().getTime() > vm.offer.lastDateChange) {
                    var data = {
                        ROpart: requestOfferPart,
                        offer: vm.offer,
                        updateView: vm.reload,
                    };
                    $rootScope.$broadcast('fee:dialog:open', data);
                } else {
                    $scope.startProgress();
                    RequestOfferPart.accept(
                        requestOfferPart.id,
                        {},
                        (res) => {
                            if (res) {
                                vm.reload();
                            }
                        },
                        $scope.endWithAlert
                    );
                }
            }

            function supplierDeclineRequestOfferPartFn(requestOfferPart) {
                RequestOfferPart.decline(
                    requestOfferPart.id,
                    {},
                    (res) => {
                        if (res) {
                            vm.reload();
                        }
                    },
                    $scope.endWithAlert
                );
            }

            function supplierLoginFn() {
                vm.supplierUserName = $('#supplierUserName').val();
                vm.supplierPassword = $('#supplierPassword').val();
                if (vm.supplierUserName && vm.supplierPassword) {
                    vm.doAfterLogin();
                }
            }

            function toggleShowNotificationSectionFn() {
                vm.showNotificationSection = !vm.showNotificationSection;
                vm.refreshHints();
            }

            function trustAsHtmlFn(string) {
                return $sce.trustAsHtml(string);
            }

            function refreshHintsFn() {
                if (vm.guideType != GlobalConstants.SHOW_NONE) {
                    $timeout(function () {
                        if (vm.intro) {
                            vm.intro.refresh();
                        }
                    }, 100);
                }
            }

            function updateCancelPeriodEndDateFn() {
                $scope.startProgress('top');
                RequestOffer.updateCancelPeriodEndDate(
                    {
                        offerId: vm.offer.id,
                        cancelPeriod: vm.offer.cancelPeriod,
                    },
                    function (resp) {
                        vm.offer.cancelPeriod = resp.cancelPeriod;
                        vm.originalCancelPeriod = resp.cancelPeriod;
                        vm.prolongOfferForCancelPeriodDate = false;
                        $scope.endProgress('top');
                    },
                    $scope.endWithError
                );
            }

            function cancelCancelPeriodEndDateFn() {
                vm.offer.cancelPeriod = vm.originalCancelPeriod;
                vm.prolongOfferForCancelPeriodDate = false;
            }

            function updateReservationCancelDaysFn() {
                $scope.startProgress('top');
                RequestOffer.update(
                    {
                        offerId: vm.offer.id,
                        reservationCancelDays: vm.offer.reservationCancelDays,
                    },
                    function () {
                        vm.prolongOfferForReservationDate = false;
                        vm.originalReservationCancelDays = vm.offer.reservationCancelDays;
                        vm.originalExpirationDate = vm.offer.expirationDate;
                        $scope.endProgress('top');
                    },
                    $scope.endWithError('top')
                );
            }

            function cancelReservationCancelDaysFn() {
                vm.offer.reservationCancelDays = vm.originalReservationCancelDays;
                vm.offer.expirationDate = vm.originalExpirationDate;
                vm.prolongOfferForReservationDate = false;
                calculateOfferExpirationDays();

            }

            function updateFinalPriceAndAmountFn() {
                vm.finalPriceAndAmountEdit = true;
                vm.partPricesDisabled = false;
                vm.finalPriceAccepted = false;
                vm.paidOnsiteDisabled = false;
                $scope.$broadcast('enable.offer.part.option.edit', {});
            }

            function changeStatusToSentFn() {
                $scope.startProgress('top');
                let params = { offerId: vm.offer.id };
                RequestOffer.changeStatusToSent(
                    params,
                    function () {
                        vm.reload();
                    },
                    $scope.endWithError('top')
                );
            }

            function setParticipantsNotificationTypeFn(type) {
                vm.participantsNotificationType = '';
                vm.participantsNotifications = {
                    onUpdate: false,
                    daily: false,
                    weekly: false,
                    never: false,
                };
                vm.participantsNotificationType = type;
                if (vm.participantsNotificationType == 'ON_UPDATE') {
                    vm.participantsNotifications.onUpdate = true;
                } else if (vm.participantsNotificationType == 'DAILY') {
                    vm.participantsNotifications.daily = true;
                }
                if (vm.participantsNotificationType == 'WEEKLY') {
                    vm.participantsNotifications.weekly = true;
                }
                if (vm.participantsNotificationType == 'NEVER') {
                    vm.participantsNotifications.never = true;
                }
            }

            function updateNotificationSettingsForOfferFn() {
                $scope.startProgress();
                RequestOffer.updateNotificationSettings(
                    { offerId: vm.offerId, type: vm.participantsNotificationType },
                    function (response) {
                        vm.setParticipantsNotificationType(response.notificationFrequency.name);
                        $scope.endProgress();
                    }
                );
            }

            function updateNotificationEmailForOfferFn() {
                RequestOffer.updateNotificationEmail(
                    {
                        offerId: vm.offerId,
                        email: vm.notificationEmail,
                    },
                    function (response) {
                        vm.notificationEmail = response.participantActivityNotificationEmail;
                        vm.notificationEmailSet = true;
                        vm.showNotificationEmailSection = false;
                    }
                );
            }

            //Need to check

            function updateOfferPartOnClientSideOnlyFn(offerPart) {
                var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                if (vm.isOfferPartInSupplierWaitingStates(originalOfferPart)) {
                    angular.forEach(vm.offer.requestOfferParts, function (part) {
                        if (part.id === offerPart.id) {
                            part = storeOfferPartValues(originalOfferPart, offerPart, part);
                            part.status.name = 'EDITED_BY_SUPPLIER';
                            if (vm.hasNameChanged(part.oldName, part.oldNameCode, part.name, part.nameCode)) {
                                part.nameCode = null;
                            }
                        }
                    });
                    if (vm.checkIfFieldValueChanged(offerPart, originalOfferPart)) {
                        let offerPartMap = { offerPart: offerPart, action: 'ACCEPTED_BY_SUPPLIER_WITH_CHANGES' };
                        removeOfferPartFromMap(offerPart);
                        vm.offerPartsWithActions.push(offerPartMap);
                        vm.flagForAcceptedWithChanges = 'edit' + offerPart.id;
                    } else {
                        removeOfferPartFromMap(offerPart);
                        vm.flagForAcceptedWithChanges = undefined;
                    }
                } else {
                    angular.forEach(vm.offer.requestOfferParts, function (part) {
                        if (part.id === offerPart.id) {
                            part = storeOfferPartValues(originalOfferPart, offerPart, part);
                            if (part.status.name === 'ADDED_WAITING_FOR_CREATOR_DECLINED') {
                                part.status.name = 'ADDED_WAITING_FOR_CREATOR';
                            } else {
                                part.status.name = 'EDITED_BY_SUPPLIER';
                            }
                            if (vm.hasNameChanged(part.oldName, part.oldNameCode, part.name, part.nameCode)) {
                                part.nameCode = null;
                            }
                        }
                    });
                    if (vm.checkIfFieldValueChanged(offerPart, originalOfferPart)) {
                        var offerPartMap = { offerPart: offerPart, action: 'EDITED_BY_SUPPLIER' };
                        removeOfferPartFromMap(offerPart);
                        vm.offerPartsWithActions.push(offerPartMap);
                        vm.flagForAcceptedWithChanges = 'edit' + offerPart.id;
                    } else {
                        removeOfferPartFromMap(offerPart);
                        vm.flagForAcceptedWithChanges = undefined;
                    }
                }
                vm.calculateTotalPrice();
                $rootScope.ROPEditId = 0;
            }

            function storeOfferPartValues(originalOfferPart, offerPart, part) {
                if (originalOfferPart.name !== offerPart.name) {
                    part.oldName = originalOfferPart.name;
                    part.name = offerPart.name;
                }
                if (originalOfferPart.description !== offerPart.description) {
                    part.oldDescription = originalOfferPart.description;
                    part.description = offerPart.description;
                }
                if (originalOfferPart.amount !== offerPart.amount) {
                    part.oldAmount = originalOfferPart.amount;
                    part.amount = offerPart.amount;
                }
                if (originalOfferPart.amountType.name !== offerPart.amountType.name) {
                    part.oldAmountType = { name: originalOfferPart.amountType.name };
                    part.amountType.name = offerPart.amountType.name;
                }
                if (originalOfferPart.dateTimeFrom !== offerPart.dateTimeFrom) {
                    part.oldDateTimeFrom = originalOfferPart.dateTimeFrom;
                    part.dateTimeFrom = offerPart.dateTimeFrom;
                }
                if (originalOfferPart.dateTimeTo !== offerPart.dateTimeTo) {
                    part.oldDateTimeTo = originalOfferPart.dateTimeTo;
                    part.dateTimeTo = offerPart.dateTimeTo;
                }
                if (
                    originalOfferPart.price !== offerPart.price ||
                    originalOfferPart.newPriceInclVat !== offerPart.newPriceInclVat
                ) {
                    part.oldPrice = originalOfferPart.newPrice ? originalOfferPart.newPrice : 0;
                    part.newPrice = offerPart.price;
                    part.price = offerPart.price;
                    if (vm.includeVat && vm.offer.vatIncludedInPrice) {
                        part.newPriceInclVat = offerPart.newPriceInclVat;
                    }
                }
                // if (originalOfferPart.vat !== offerPart.vat) {
                part.vat = offerPart.vat;
                // }
                if (offerPart.tempCommentBySupplier) {
                    offerPart['commentBySupplier'] = offerPart.tempCommentBySupplier;
                }
                return part;
            }

            function canSupplierEditPriceFn(offerPart, offer) {
                return (
                    (offer.statusDetailed.name === 'SENT' ||
                        (offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER' &&
                            offerPart.status.name === 'ADDED_WAITING_FOR_SUPPLIER')) &&
                    (!vm.finalPriceAndAmountEdit || offerPart.status.name === 'CANCELED')
                );
            }

            function readonlySupplierPriceFn(offerPart, offer) {
                return (
                    offer.statusDetailed.name !== 'SENT' &&
                    (!vm.finalPriceAndAmountEdit || offerPart.status.name === 'CANCELED') &&
                    !(
                        offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER' &&
                        offerPart.status.name === 'ADDED_WAITING_FOR_SUPPLIER'
                    )
                );
            }

            function updatePartFn(data, expanded) {
                if (vm.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
                    updateOfferPartOnClientSideOnlyFn(data);
                } else {
                    $scope.startProgress('top');
                    let _originalOfferPart = vm.getOriginalOfferPart(data);
                    if (data.tempCommentBySupplier) {
                        data['commentBySupplier'] = data.tempCommentBySupplier;
                        if (data.newPrice) {
                            data['price'] = data.newPrice;
                        }
                    }
                    if (
                        _originalOfferPart &&
                        _originalOfferPart.commentBySupplier !== data.commentBySupplier &&
                        data.newPrice
                    ) {
                        data['price'] = data.newPrice;
                    }
                    if (expanded) {
                        RequestOfferPart.update(
                            data.id,
                            data,
                            function (response) {
                                if (response) {
                                    vm.reload(data, expanded);
                                }
                            },
                            $scope.endWithAlert
                        );
                    } else {
                        RequestOfferPart.update(
                            data.id,
                            data,
                            function (response) {
                                if (response) {
                                    vm.reload();
                                }
                            },
                            $scope.endWithAlert
                        );
                    }
                }
            }

            // function updateRequestOfferSupplierFn() {
            //     vm.init();
            // }

            function updateROPVatFn(ROPart) {
                let vat = parseFloat(ROPart.vat);
                if (isNaN(vat)) {
                    vat = 0;
                }

                ROPart.vat = vat;
                if (!vm.offer.vatIncludedInPrice) {
                    ROPart.newPriceInclVat = null;
                    ROPart.finalPriceInclVat = null;
                    ROPart.price = ROPart.newPrice;
                    ROPart.supplierPrice = ROPart.newPrice;
                } else {
                    let _newPrice = parseFloat(ROPart.newPriceInclVat / (1 + ROPart.vat / 100));
                    ROPart.price = Number(Math.round(_newPrice + 'e' + 2) + 'e-' + 2);
                    ROPart.newPrice = Number(Math.round(_newPrice + 'e' + 2) + 'e-' + 2);
                    let _finalPrice = parseFloat(ROPart.finalPriceInclVat / (1 + ROPart.vat / 100));
                    ROPart.finalPrice = Number(Math.round(_finalPrice + 'e' + 2) + 'e-' + 2);
                }
                ROPart.changedField = 'VAT';
                vm.offer.price = vm.calculateTotalPrice();
                calculateOriginalTotalPrice();
                updateOfferPart(ROPart);
                calculatePaidOnsitePriceFn();
                calculateOutstandingAmount();
            }

            function updateROPPriceFn(ROPart) {
                let price;
                if (
                    ROPart.offer.status.name === 'CANCELED_AND_FINISHED' ||
                    ROPart.offer.status.name === 'CANCELED_WAITING_FOR_SUPPLIER'
                ) {
                    price = amountAndPriceService.round(parseFloat(ROPart.finalPrice));
                    if (isNaN(price)) {
                        ROPart.finalPrice = 0;
                    }
                } else {
                    price = amountAndPriceService.round(parseFloat(ROPart.newPrice));
                }
                if (isNaN(price)) {
                    price = 0;
                }
                ROPart.oldPrice = ROPart.price;
                ROPart.price = price;
                ROPart.supplierPrice = price;
                if (vm.includeVat && vm.offer.vatIncludedInPrice) {
                    ROPart.newPriceInclVat = amountAndPriceService.calculatePriceFromPriceAndVat(price, ROPart.vat);
                }
                ROPart.changedField = 'PRICE';
                vm.offer.price = vm.calculateTotalPrice();
                calculateOriginalTotalPrice();
                vm.managePriceLock();
                updateOfferPart(ROPart);
                calculatePaidOnsitePriceFn();
                calculateOutstandingAmount();
            }

            function updateROPPriceInclVatFn(ROPart) {
                let price;
                let _priceInclVat = ROPart.newPriceInclVat;
                let _finalPriceInclVat = ROPart.finalPriceInclVat;
                if (
                    ROPart.offer.status.name === 'CANCELED_AND_FINISHED' ||
                    ROPart.offer.status.name === 'CANCELED_WAITING_FOR_SUPPLIER'
                ) {
                    price = amountAndPriceService.getPriceWithOutVat(_finalPriceInclVat, ROPart.vat);
                    if (vm.offer.vatIncludedInPrice && vm.includeVat) {
                        ROPart.finalPrice = amountAndPriceService.getPriceWithOutVat(_finalPriceInclVat, ROPart.vat);
                    }
                    if (isNaN(price)) {
                        ROPart.finalPrice = 0;
                    }
                } else {
                    price = amountAndPriceService.getPriceWithOutVat(_priceInclVat, ROPart.vat);
                    if (
                        vm.offer.vatIncludedInPrice &&
                        vm.includeVat &&
                        RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf(vm.offer.status.name) > -1
                    ) {
                        ROPart.finalPrice = amountAndPriceService.getPriceWithOutVat(_finalPriceInclVat, ROPart.vat);
                    }
                }
                if (isNaN(price)) {
                    price = 0;
                }
                ROPart.oldPrice = ROPart.price;
                ROPart.price = price;
                ROPart.newPrice = price;
                ROPart.supplierPrice = price;
                ROPart.changedField = 'PRICE';
                vm.offer.price = vm.calculateTotalPrice();
                calculateOriginalTotalPrice();
                vm.managePriceLock();
                updateOfferPart(ROPart);
                calculatePaidOnsitePriceFn();
                calculateOutstandingAmount();
            }

            function updateROPPaidOnsiteFn(ROPart) {
                ROPart.finalPaidOnsite = !ROPart.finalPaidOnsite;
                ROPart.changedField = 'PAID ONSITE';
                updatePaidOnsitePrice(ROPart);
                calculateOutstandingAmount(ROPart);
                updateOfferPart(ROPart);
            }

            function updateSelectedCurrencyFn() {
                if (vm.enableSelectedCurrencyUpdate()) {
                    vm.selectedCurrency = _.findWhere(vm.currencyList, { id: vm.selectedCurrencyId });
                    vm.calculateTotalPrice();
                    vm.updateCurrency(vm.selectedCurrencyId);
                }
            }

            function updateCurrencyFn(currencyId) {
                RequestOffer.updateCurrency(vm.offerId, { currencyId: currencyId });
            }

            function updateOfferDiscountFn() {
                $scope.startProgress('top');
                RequestOffer.updateDiscount(vm.offerId, {discount: vm.offer.discount},
                    (res) => {
                        vm.offer.discount = res.discount;
                        if(vm.showDiscountedPrice) {
                            vm.hasNoDiscount = false;
                        }
                        vm.showDiscountedPrice = res.discount;
                        let _totalPrice = amountAndPriceService.getOfferTotalPrice(
                            vm.offer,
                            vm.offer.requestOfferParts,
                            vm.includeVat
                        );
                        calculateDiscountedPriceFn(_totalPrice);
                        $scope.endProgress('top');
                    },
                    $scope.endWithError('top')
                );
            }

            function undoOfferPartDeletionFn(offerPart) {
                removeOfferPartFromMap(offerPart);
                var originalOfferPart = vm.getOriginalOfferPart(offerPart);
                angular.forEach(vm.offer.requestOfferParts, function (part) {
                    if (part.id == offerPart.id) {
                        if (originalOfferPart.status.name == 'ADDED_WAITING_FOR_CREATOR_DECLINED') {
                            part.status.name = 'ADDED_WAITING_FOR_CREATOR_DECLINED';
                        } else if (originalOfferPart.status.name == 'DELETED_WAITING_FOR_CREATOR_DECLINED') {
                            part.status.name = 'DELETED_WAITING_FOR_CREATOR_DECLINED';
                        } else {
                            part.status.name = 'ACCEPTED';
                        }
                    }
                });
                vm.ROPUndoDeleteId = 'delete' + offerPart.id;
            }

            function viewMessageFn() {
                jQuery('.offerTabs>ul>li.current').removeClass('active');
                jQuery('.offerTabs>ul>li#message_tab_header').addClass('active');

                jQuery('#offers_tab_content>div.active').removeClass('active');
                jQuery('#offers_tab_content>div#messages').addClass('active');

                ogScrollService.scrollToElement('offer-tabs-header');
            }

            function canConfirmFeeReceivedFn() {
                return vm.offer && vm.offer.status && vm.offer.status.name == 'BOOKED';
            }

            function shouldFillInCancelFeeFn(offer) {
                var nowMillis = new Date().getTime();
                return offer && offer.status.name === 'BOOKED' && nowMillis > offer.lastDateChange;
            }

            function updateAmountFn(ROPart) {
                var finalAmount = parseInt(ROPart.finalAmount);
                if (isNaN(finalAmount)) {
                    finalAmount = 0;
                }
                ROPart.changedField = 'AMOUNT';
                vm.offer.price = vm.calculateTotalPrice();
                calculateOriginalTotalPrice();
                calculatePaidOnsitePriceFn();
                calculateOutstandingAmount();
                updateOfferPart(ROPart);
            }

            function isDeliverableFn(ROPart) {
                return ROPart.status.name != 'NOT_DELIVERABLE';
            }

            function setFinalPriceAndAmount(offerParts) {
                if (offerParts) {
                    angular.forEach(offerParts, function (item) {
                        //initially final price will not be set, so price is set to final price
                        item.finalPrice = item.finalPrice != null ? item.finalPrice : item.price;
                        item.finalAmount = item.finalAmount != null ? item.finalAmount : item.amount;
                        if (
                            (RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf(vm.offer.status.name) > -1 ||
                                RequestOfferStatusUtils.ALL_CANCELED_FINISHED_STATUS.indexOf(vm.offer.status.name) > -1) &&
                            vm.includeVat &&
                            vm.offer.vatIncludedInPrice
                        ) {
                            item.finalPriceInclVat = amountAndPriceService.calculatePriceFromPriceAndVat(
                                item.finalPrice,
                                item.vat
                            );
                        }
                        if (item.hasRequestOfferPartOptions) {
                            let _selectedRequestOfferPartOptions = _.filter(item.offerPartOptions, (offerPartOption) => {
                                return (
                                    offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE'
                                );
                            });
                            angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                                //initially final price will not be set, so price is set to final price
                                _selectedRequestOfferPartOption.finalPrice =
                                    _selectedRequestOfferPartOption.finalPrice != null
                                        ? _selectedRequestOfferPartOption.finalPrice
                                        : _selectedRequestOfferPartOption.price;
                                _selectedRequestOfferPartOption.finalAmount =
                                    _selectedRequestOfferPartOption.finalAmount != null
                                        ? _selectedRequestOfferPartOption.finalAmount
                                        : _selectedRequestOfferPartOption.amount;
                                if (
                                    (RequestOfferStatusUtils.ALL_FINISHED_STATUS.indexOf(vm.offer.status.name) > -1 ||
                                        RequestOfferStatusUtils.ALL_CANCELED_FINISHED_STATUS.indexOf(vm.offer.status.name) >
                                        -1) &&
                                    vm.includeVat &&
                                    vm.offer.vatIncludedInPrice
                                ) {
                                    item.finalPriceInclVat = amountAndPriceService.calculatePriceFromPriceAndVat(
                                        _selectedRequestOfferPartOption.finalPrice,
                                        _selectedRequestOfferPartOption.vat
                                    );
                                }
                            });
                        }
                    });
                }
            }

            var statusUpdated = function (data) {
                if (data.userData) {
                    vm.supplierPassword = null;
                    AuthenticationService.updateUserData(data.userData);
                }
                vm.reload();
                $('body').animate({ scrollTop: 0 }, 1000);
            };

            var sendOfferToCreatorInner = function () {
                if (!vm.offer.responsibleSupplierUser) {
                    $rootScope.$broadcast('dialog:alert:open', {
                        message: 'requestOffer.mandatory.responsible.notification',
                    });
                }
                vm.showColorMeaning = false;
                $scope.startProgress('top');
                RequestOffer.sendOfferToCustomer(
                    {
                        offerId: vm.offerId,
                        email: vm.offer.responsibleUserEmail,
                        type: 'SUPPLIER_OFFER_CONFIRM',
                        responsibleSupplierUserId: vm.offer.responsibleSupplierUser.id,
                        price: vm.offer.price,
                        reservationCancelDays: vm.offer.reservationCancelDays,
                        cancelPeriod: vm.offer.cancelPeriod,
                        parts: vm.offer.requestOfferParts,
                        status: 'ANSWERED',
                        currencyId: vm.selectedCurrencyId,
                        internalReference: vm.offer.internalReference,
                        agreementPrices: vm.agreementPrices,
                    },
                    function (data) {
                        $scope.endProgress('top');
                        statusUpdated(data);
                        $scope.$broadcast('update.offer.part.option', { status: 'ANSWERED' });
                        // show details filling part, if supplier is not registered properly
                        if (!vm.canRespondToOffer()) {
                            $rootScope.dialog('offer:confirmed', { type: 'SUPPLIER_OFFER_CONFIRM' });
                        }
                    },
                    $scope.endWithAlert
                );
            };

            function updateOfferPart(ROPart) {
                if (vm.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_SUPPLIER') {
                    updateOfferPartOnClientSideOnlyFn(ROPart);
                } else {
                    let oldPrice = ROPart.oldPrice;
                    // $scope.startProgress(vm.offer.id);
                    //amount/price disabled if offer status is finished
                    let _oldOfferStatus = angular.copy(vm.offer.status.name);
                    if (_oldOfferStatus === 'FINISHED') {
                        vm.partPricesDisabled = true;
                    }
                    if (vm.offer.statusDetailed.name === 'FINISHED_WAITING_FOR_CREATOR') {
                        vm.paidOnsiteDisabled = true;
                    }
                    vm.finalPriceAccepted = false;
                    if (!ROPart.id) {
                        return;
                    }
                    RequestOfferPart.update(
                        ROPart.id,
                        ROPart,
                        (res) => {
                            //Enable amount/price if offer status before was finished
                            if (_oldOfferStatus) {
                                vm.partPricesDisabled = false;
                                vm.paidOnsiteDisabled = false;
                            }
                            if (vm.offer.status.name === 'BOOKED') {
                                ROPart.status.name = 'EDITED_BY_SUPPLIER';
                                ROPart.editable = false;
                                ROPart.deletable = false;
                            }
                            if (
                                CommonFunctions.hasIntersectValue(
                                    [vm.offer.status.name],
                                    [
                                        'FINISHED_WAITING_FOR_CREATOR',
                                        'FINISHED_WAITING_FOR_SUPPLIER',
                                        'FINISHED',
                                        'CANCELED_AND_FINISHED',
                                        'CANCELED_WAITING_FOR_CREATOR',
                                    ]
                                )
                            ) {
                                // vm.reload(ROPart);
                                vm.offer.status.name = res.offer.status.name;
                                vm.offer.statusDetailed.name = res.offer.statusDetailed.name;
                                ROPart.status.name = res.status.name;
                                vm.ROPInfo = undefined;
                            }
                            displayDiffCol(vm.offer, vm.offer.requestOfferParts);
                            $scope.endProgress(vm.offer.id);
                            showRowColorMeaningFn();
                        },
                        function (err) {
                            ROPart.price = oldPrice;
                            ROPart.newPrice = oldPrice;
                            ROPart.finalPrice = oldPrice;
                            errorHandler(err);
                        }
                    );
                }
            }

            function errorHandler(error) {
                $scope.endWithAlert(error);
            }

            function removeOfferPartFromMap(offerPart) {
                angular.forEach(vm.offerPartsWithActions, function (rowAndAction, i) {
                    if (rowAndAction.offerPart.id == offerPart.id) {
                        vm.offerPartsWithActions.splice(i, 1);
                    }
                });
            }

            function isOfferInApprovedStateFn(offerPart) {
                return offerPart.offer.status.name === 'APPROVED';
            }

            function isOfferInFinishedOrApprovedStateFn(offer) {
                if (!offer) {
                    return false;
                }
                if (!offer.status) {
                    return false;
                }
                return RequestOfferStatusUtils.ALL_FINISHED_STATUS.includes(offer.status.name);
            }

            function reassignOriginalValuesToOfferPart(offerPart, originalOfferPart) {
                offerPart.name = originalOfferPart.name;
                offerPart.description = originalOfferPart.description;
                offerPart.amount = originalOfferPart.amount;
                offerPart.amountType.name = originalOfferPart.amountType.name;
                offerPart.dateTimeFrom = originalOfferPart.dateTimeFrom;
                offerPart.dateTimeTo = originalOfferPart.dateTimeTo;
                offerPart.newPrice = originalOfferPart.price;
                offerPart.price = originalOfferPart.price;
            }

            function insertAllSupplierWaitingRowsAsAccepted() {
                angular.forEach(vm.offer.requestOfferParts, function (n) {
                    if (vm.isOfferPartInSupplierWaitingStates(n)) {
                        let _offerPartWithChangesAccepted = _.find(vm.offerPartsWithActions, (offerPartsWithAction) => {
                            return (
                                offerPartsWithAction.offerPart.id === n.id &&
                                offerPartsWithAction.action === 'ACCEPTED_BY_SUPPLIER_WITH_CHANGES'
                            );
                        });
                        if (!_offerPartWithChangesAccepted) {
                            vm.acceptOfferPart(n);
                            $scope.$broadcast('update:accept:all', {});
                        } else {
                            $scope.$broadcast('update:accept:all:with:changes', { offerPartWithChangesAccepted: n });
                        }
                    }
                });
            }

            function insertAllSupplierWaitingRowsAsDeclined() {
                angular.forEach(vm.offer.requestOfferParts, function (n) {
                    if (vm.isOfferPartInSupplierWaitingStates(n)) {
                        let _offerPartWithChangesAccepted = _.find(vm.offerPartsWithActions, (offerPartsWithAction) => {
                            return (
                                offerPartsWithAction.offerPart.id === n.id &&
                                offerPartsWithAction.action === 'ACCEPTED_BY_SUPPLIER_WITH_CHANGES'
                            );
                        });
                        if (!_offerPartWithChangesAccepted) {
                            vm.declineOfferPart(n);
                            $scope.$broadcast('update:decline:all', {});
                        } else {
                            $scope.$broadcast('update:accept:all:with:changes', { offerPartWithChangesAccepted: n });
                        }
                    }
                });
            }

            function displayDiffCol(offer, parts) {
                vm.showDiffColumn =
                    amountAndPriceService.hasUpdatedFinalPriceOrAmount(offer, parts, vm.includeVat) ||
                    amountAndPriceService.hasUpdatedFinalPriceOrAmountForOptions(offer, parts, vm.includeVat);
            }

            function hasUpdatedPaidOnsiteColumnFn(offer) {
                return amountAndPriceService.hasUpdatedFinalPaidOnsite(offer, offer.offerParts);
            }

            function filterOfferParts(offer) {
                var filteredOfferParts = [];
                angular.forEach(vm.offer.requestOfferParts, function (offerPart) {
                    if (
                        offer.status.name === 'BOOKED_LOCKED_BY_CREATOR' &&
                        (offerPart.status.name === 'ADDED_WAITING_FOR_SUPPLIER' ||
                            offerPart.status.name === 'ADDED_BY_CREATOR')
                    ) {
                        return true;
                    } else {
                        filteredOfferParts.push(offerPart);
                    }
                });
                return filteredOfferParts;
            }

            function disableParticipantListLoadingForSupplierFn() {
                return (
                    vm.getActiveTab() != 'participants_summary' ||
                    (vm.getActiveTab() == 'participants_summary' && vm.activitiesLoading)
                );
            }

            function listParticipantActivitiesForSupplierFn() {
                if (vm.activitiesLoading || activitySearchParams.offset >= vm.activityTotalCount) {
                    return;
                }
                vm.activitiesLoading = true;
                ParticipantActivity.listActivitiesForSupplier(
                    vm.eventId,
                    vm.offerId,
                    activitySearchParams,
                    function (response) {
                        vm.activities = vm.activities.concat(response.list);
                        vm.activityTotalCount = response.totalCount;
                        activitySearchParams.offset = vm.activities.length;
                        vm.activitiesLoading = false;
                    },
                    $scope.endWithErrorOverlay
                );
            }

            function getDifferenceFn(offer, part) {
                return amountAndPriceService.getDifference(offer, part, vm.includeVat);
            }

            function getRequestOfferPartOptionDifferenceFn(offer, part) {
                let _totalDifference = 0;
                let _selectedOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedOfferPartOptions, (_selectedOfferPartOption) => {
                    _totalDifference += amountAndPriceService.getRequestPartOptionDifference(
                        offer,
                        _selectedOfferPartOption,
                        vm.includeVat
                    );
                });
                return _totalDifference;
            }

            function getRequestOfferPartOptionPriceWithVatFn(offer, part) {
                let _priceWithVat = 0;
                let _selectedOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedOfferPartOptions, (_selectedOfferPartOption) => {
                    if (vm.includeVat && vm.offer.vatIncludedInPrice) {
                        _selectedOfferPartOption.priceInclVat = amountAndPriceService.calculatePriceFromPriceAndVat(
                            _selectedOfferPartOption.price,
                            _selectedOfferPartOption.vat
                        );
                    }
                    _priceWithVat += amountAndPriceService.getRequestOfferPartOptionPriceInclVat(
                        offer,
                        _selectedOfferPartOption,
                        part
                    );
                });
                return amountAndPriceService.round(_priceWithVat);
            }

            function getDetailChangesValueFn(value, type) {
                if (value && type.toLowerCase() == 'date') {
                    let dateFormat = DateService.getDateFormatByLocale();
                    value = TimezoneService.formatDate(new Date(parseInt(value)), dateFormat);
                }
                return value;
            }

            function getQuestionLabelFn(item) {
                return ParticipantService.getQuestionLabel(item, $rootScope.langSelected);
            }

            function viewAmountLocalisedWithCurrencyFn(price, currency, digitsAfterDecimal = 0) {
                return amountAndPriceService.viewPrice(price, currency, digitsAfterDecimal);
            }

            function getRequestPartOptionTotalPriceFn(offer, offerPart, includeVat) {
                let _offerPartOptions = _.filter(offerPart.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                let _totalPrice = 0;
                angular.forEach(_offerPartOptions, (_offerPartOption) => {
                    if (vm.includeVat && vm.offer.vatIncludedInPrice) {
                        _offerPartOption.priceInclVat = amountAndPriceService.calculatePriceFromPriceAndVat(
                            _offerPartOption.price,
                            _offerPartOption.vat
                        );
                    }
                    _totalPrice += amountAndPriceService.getRequestPartOptionTotalPrice(
                        offer,
                        offerPart,
                        _offerPartOption,
                        includeVat
                    );
                });

                return amountAndPriceService.round(_totalPrice);
            }

            function revertToPreviousNotificationEmailFn() {
                vm.notificationEmail = vm.originalNotificationEmail;
                vm.notificationEmailSet = true;
                vm.showNotificationEmailSection = false;
            }

            function isThereSentNotificationFn() {
                let _index = _.findIndex(vm.notifications, (notification) => notification.type === 'REQUEST_OFFER_SENT');
                return _index > -1;
            }

            function exportFinalDetailsToPDFFn() {
                let isItIE = BrowserDetectService.isItIE();
                if (!!isItIE) {
                    jQuery('#eventFinalDetailsSupplier').printElement({
                        leaveOpen: false,
                        printMode: 'popup',
                        overrideElementCSS: ['/app/print-css/schedule-print.css'],
                    });
                } else {
                    jQuery('#eventFinalDetailsSupplier').printThis({
                        importCSS: false,
                        importStyle: false,
                        loadCSS: '/app/print-css/schedule-print.css',
                        printDelay: '3000',
                    });
                }
            }

            $scope.$watch('offer.price', function () {
                vm.managePriceLock();
            });

            var langChangeEvent = $rootScope.$on('lang:change', function (evt, data) {
                vm.lang = data.langShort;
                vm.notifications = angular.copy(vm.originalNotifications); //to refresh notification on language change;
            });

            var presentationImageUpdateEvent = $rootScope.$on('presentation:image:updated', function (event, data) {
                if (vm.presentation.id === data.id) {
                    vm.presentation = data;
                    vm.presentationImages = data.presentationImages;
                    vm.setMainThumbNailImage();
                }
            });

            var requestOfferStatusChangedEvent = $rootScope.$on(
                EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS,
                function () {
                    vm.init();
                }
            );

            var presentationUpdateEvent = $rootScope.$on('update:supplier:presentation', function (event, data) {
                vm.presentation = data.presentation;
                vm.presentationImages = data.presentationImages;
                vm.setMainThumbNailImage();
            });

            $scope.$on('requestoffer:presentation:dialog:update:offer', function () {
                Presentation.get(
                    { requestId: $stateParams.requestId, supplierId: vm.supplierId },
                    function (data) {
                        vm.presentation = data;
                        vm.presentationImages = data.presentationImages;
                    },
                    $scope.endWithAlert
                );
                vm.editPresentation();
            });

            var appPanelCloseEvent = $rootScope.$on('dialog:app:panel:close', function (/*evt, view, params*/) {
                vm.appMode = '';
            });

            function getHintForParticipantTab() {
                return {
                    element: document.querySelector('#participant_tab_header'),
                    hint: `<div class="arrow arrow-right">
                  <h6 class="onboarding-step">${$translate.instant('supplier.guide.step')} 1/2</h6>
                  <div class="text-center onboarding-tooltip-content">
                        ${$translate.instant('supplier.guide.step.one.content')}
                  </div>
                </div>`,
                    hintPosition: 'top-left',
                };
            }

            function getHintForExportTab() {
                return {
                    element: document.querySelector('#participant_dropdown_export'),
                    hint: `<div class="arrow arrow-right">
                  <h6 class="onboarding-step">${$translate.instant('supplier.guide.step')} 2/2</h6>
                  <div class="text-center onboarding-tooltip-content">
                        ${$translate.instant('supplier.guide.step.two.content')}
                  </div>
                </div>`,
                    hintPosition: 'middle-left',
                };
            }

            function getFormatedDateFn(date) {
                return moment(date).format('DD MMM');
            }

            function checkAndIncludeSelectedCurrencyInCurrencyList(selectedCurrency) {
                let currency = _.findWhere(vm.currencyList, { id: selectedCurrency.id });
                if (!currency) {
                    vm.currencyList.push(selectedCurrency);
                }
            }

            function isFormValueHasChangedFn() {
                if (vm.offer.price > 0) {
                    return true;
                }
                if (vm.offer.reservationCancelDays) {
                    return true;
                }
                if (vm.offer.cancelPeriod) {
                    return true;
                }
                return false;
            }

            function offerPartOptionsWithActionsCallbackFn(offerPartOptionsWithActions) {
                vm.offerPartOptionsWithActions.push(offerPartOptionsWithActions);
            }

            function canAddNewResourceFn(offerPart) {
                // return CONFERENCE_OFFERPART_NAMECODE.indexOf(offerPart.nameCode) > -1;
                return offerPart.isSuggestionEnabled === true;
            }

            function isSubState(fromState, toState) {
                return toState.name.startsWith(fromState.name) || fromState.name.includes(toState.name);
            }

            function exportOfferSummaryToPDFFn(offerId) {
                let _downloadUrl = 'api/v1/offer/' + offerId + '/exportOfferSummaryToPDF';
                DownloadUtilFunctions.downloadFile(_downloadUrl);
            }

            function exportOfferForSupplierFn(offerId) {
                let _downloadUrl = 'api/v1/offer/' + offerId + '/exportOfferForSupplier';
                DownloadUtilFunctions.downloadFile(_downloadUrl);
            }

            function editToggleFn() {
                vm.editable = !vm.editable;
            }

            function consistSupplierSuggestionInBookedFn(offerPart) {
                if (vm.isOfferInFromBookedStates() && offerPart.hasRequestOfferPartOptions) {
                    return '8';
                }
            }

            function isUsingClientBillingAddressAndIsELFn(clientName, userGroupBillingAddressEnum) {
                isUsingClientBillingAddress(userGroupBillingAddressEnum);
                vm.isUsingClientBillingAddressAndIsEL = (clientName !== 'Big Travel' && vm.isUsingClientBillingAddress);
            }

            function isUsingClientBillingAddress(userGroupBillingAddressEnum) {
                vm.isUsingClientBillingAddress = UtilFunctions.isClientBillingAddress(userGroupBillingAddressEnum);
                return vm.isUsingClientBillingAddress;
            }

            function disableAccommodationPartFn(data, offer) {
                if (data.category.name === 'ACCOMMODATION') {
                    return !(data && data.status.name === 'ADDED_BY_SUPPLIER');
                }
                return false;
            }

            function redirectToOfferMessageSectionFn() {
                $state.go('offer.detail.message', {openComposeMessageBox: true});
            }

            function checkIfOfferHasBeenAlreadyBookedFn() {
                return vm.offer && vm.offer.status && vm.offer.isOfferAlreadyBooked;
            }

            function updatePaidOnsitePrice(part) {
                let price = vm.getTotalPrice(vm.offer, part, vm.includeVat);
                if (part.finalPaidOnsite) {
                    vm.paidOnsitePrice += price;
                } else {
                    if (vm.paidOnsitePrice > 0) {
                        vm.paidOnsitePrice -= price;
                    }
                }
                if (vm.paidOnsitePrice > 0) {
                    vm.paidOnsitePrice = amountAndPriceService.round(vm.paidOnsitePrice);
                    vm.paidOnsitePriceLocalisedWithCurrency = viewAmountLocalisedWithCurrencyFn(
                        vm.paidOnsitePrice,
                        vm.selectedCurrency
                    );
                }
            }

            function calculatePaidOnsitePriceFn() {
                vm.paidOnsitePrice = amountAndPriceService.getTotalPaidOnsitePrice(
                    vm.offer,
                    vm.offer.requestOfferParts,
                    vm.includeVat
                );
                if (vm.paidOnsitePrice > 0) {
                    vm.paidOnsitePriceLocalisedWithCurrency = viewAmountLocalisedWithCurrencyFn(
                        vm.paidOnsitePrice,
                        vm.selectedCurrency
                    );
                }
            }

            function calculateOutstandingAmount() {
                vm.outstandingAmount = vm.totalPrice - vm.paidOnsitePrice;
                vm.outstandingAmount = amountAndPriceService.round(vm.outstandingAmount);
                vm.outstandingAmountLocalisedWithCurrency = viewAmountLocalisedWithCurrencyFn(
                    vm.outstandingAmount,
                    vm.selectedCurrency
                );
                if (vm.paidOnsitePrice === 0) {
                    vm.outstandingAmount = 0;
                }
            }

            function calculateDiscountedPriceFn(totalPrice) {
                vm.discountInOffer = vm.offer.discount;
                let _discountedPrice = amountAndPriceService.getDiscountedPrice(totalPrice, vm.offer.discount);
                vm.discountedPriceLocalisedWithCurrency = viewAmountLocalisedWithCurrencyFn(_discountedPrice, vm.selectedCurrency);
                let _totalPriceWithDiscount = totalPrice - _discountedPrice;
                vm.totalPriceWithDiscountLocalisedWithCurrency = viewAmountLocalisedWithCurrencyFn(_totalPriceWithDiscount, vm.selectedCurrency, 2);
            }

            function isThereDifferenceInPaidOnsiteFn(part) {
                return part.finalPaidOnsitePrice === false;
            }

            function setOfferPartColWidthFn(col) {
                switch (col) {
                    case 'name':
                        if (vm.isOfferInFinishedOrApprovedState(vm.offer) && vm.includeVat) {
                            return '23%';
                        } else if (vm.includeVat && !vm.isOfferInFinishedOrApprovedState(vm.offer)) {
                            return '24%';
                        } else if (vm.includeVat && vm.showDiffColumn) {
                            return '21%';
                        } else if (!vm.includeVat && vm.isOfferInFinishedOrApprovedState(vm.offer)) {
                            return '25%';
                        } else {
                            return '30%';
                        }
                        break;
                    case 'amount':
                        if (vm.includeVat && vm.showDiffColumn) {
                            return '8%';
                        } else if (vm.includeVat && !vm.showDiffColumn) {
                            return '10%';
                        } else if (!vm.includeVat && vm.showDiffColumn) {
                            return '10%';
                        } else {
                            return '14%';
                        }
                        break;
                    case 'date':
                        if (vm.isOfferInFinishedOrApprovedState(vm.offer) && vm.includeVat) {
                            return '17%';
                        } else {
                            return '18%';
                        }
                        break;
                    case 'excl.vat':
                        if (vm.includeVat) {
                            return '10%';
                        } else if (vm.isOfferInFinishedOrApprovedState(vm.offer) && !vm.includeVat) {
                            return '14%';
                        } else if (vm.isOfferInFinishedOrApprovedState(vm.offer) && !vm.includeVat && vm.showDiffColumn) {
                            return '12%';
                        } else {
                            return '16%';
                        }
                        break;
                    case 'incl.vat':
                        if (!vm.isOfferInFinishedOrApprovedState(vm.offer)) {
                            return '13%';
                        } else {
                            return '10%';
                        }
                        break;
                    case 'total.price':
                        if (vm.isOfferInFinishedOrApprovedState(vm.offer) && vm.includeVat) {
                            return '14%';
                        } else if (vm.isOfferInFinishedOrApprovedState(vm.offer) && vm.includeVat && vm.showDiffColumn) {
                            return '13%';
                        } else if (!vm.isOfferInFinishedOrApprovedState(vm.offer) && vm.includeVat) {
                            return '18%';
                        } else if (vm.isOfferInFinishedOrApprovedState(vm.offer) && !vm.includeVat) {
                            return '17%';
                        } else if (vm.isOfferInFinishedOrApprovedState(vm.offer) && !vm.includeVat && vm.showDiffColumn) {
                            return '15%';
                        } else {
                            return '22%';
                        }
                        break;
                    case 'onsite':
                        if (vm.includeVat && vm.showDiffColumn) {
                            return '8%';
                        } else if (!vm.includeVat) {
                            return '12%';
                        } else if (!vm.includeVat && vm.showDiffColumn) {
                            return '11%';
                        } else {
                            return '11%';
                        }
                        break;
                }
            }

            function getConferenceActivityLocationFn(lists) {
                let _location = _.find(lists, (list) => {
                    if (list.type.name === 'TEXT_WITH_GOOGLE_AUTOSUGGEST') {
                        return list;
                    }
                });
                if (_location) {
                    return _location.fieldValue;
                } else {
                    return '-';
                }
            }

            function showRowColorMeaningFn() {
                vm.showColorMeaning = false;
                let SHOW_COLOR_MEANING_OFFER_PART_STATUS = [];
                SHOW_COLOR_MEANING_OFFER_PART_STATUS = SHOW_COLOR_MEANING_OFFER_PART_STATUS.concat(
                    GlobalConstants.SUPPLIER_WAITING_STATES,
                    GlobalConstants.CREATOR_TO_ANSWER_STATES,
                    GlobalConstants.CREATOR_DECLINED_STATES,
                    'ADDED_BY_SUPPLIER',
                    'EDITED_BY_SUPPLIER',
                    'DELETED_BY_SUPPLIER'
                );
                if (vm.offer && vm.offer.requestOfferParts) {
                    angular.forEach(vm.offer.requestOfferParts, (part) => {
                        if (
                            vm.offer.status.name === 'CANCELED_AND_FINISHED' ||
                            vm.offer.status.name === 'CANCELED_AND_APPROVED'
                        ) {
                            vm.showColorMeaning = true;
                        }

                        if (
                            vm.offer.statusDetailed.name === 'EDITED_BY_CREATOR' ||
                            vm.offer.statusDetailed.name === 'BOOKED_LOCKED_BY_CREATOR'
                        ) {
                            vm.showColorMeaning = false;
                        }

                        if (!vm.showColorMeaning && SHOW_COLOR_MEANING_OFFER_PART_STATUS.indexOf(part.status.name) > -1) {
                            vm.showColorMeaning = true;
                        }

                        if (!vm.showColorMeaning && part.hasRequestOfferPartOptions) {
                            angular.forEach(part.offerPartOptions, (partOption) => {
                                if (SHOW_COLOR_MEANING_OFFER_PART_STATUS.indexOf(partOption.status.name) > -1) {
                                    vm.showColorMeaning = true;
                                }
                            });
                        }
                    });
                }
            }

            var $locationChangeStartUnbind = $scope.$on(
                '$stateChangeStart',
                function (event, toState, toParams, fromState, fromParams) {
                    if (
                        toState !== fromState &&
                        !isSubState(fromState, toState) &&
                        isFormValueHasChangedFn() &&
                        !vm.offer.alreadyAnswered &&
                        !vm.isOfferInCanceledState(vm.offer)
                    ) {
                        if (!vm.confirmLeave) {
                            event.preventDefault();
                            $rootScope.$broadcast('cancelStateChange');
                            $rootScope.$broadcast('dialog:confirmation:open', {
                                message: 'unsaved.edit.offer.page.message',
                                okCallBack: function () {
                                    vm.confirmLeave = true;
                                    $state.go(toState.name);
                                },
                            });
                        } else {
                            return true;
                        }
                    }
                }
            );

            let isPartOptionRadioValueUpdated = $rootScope.$on(
                'part:option:is:selected:radio:value:updated',
                (event, data) => {
                    let _part = _.find(vm.offer.requestOfferParts, (part) => {
                        return part.id === data.requestOfferPartId;
                    });
                    let _requestOfferPartOption = _.find(_part.offerPartOptions, (offerPartOption) => {
                        return offerPartOption.id === data.requestOfferPartOptionId;
                    });
                    if (_requestOfferPartOption) {
                        _requestOfferPartOption.isSelected = data.isSelected;
                        _requestOfferPartOption.price = data.price;
                        _requestOfferPartOption.vat = data.vat;
                    }
                    calculateTotalPriceFn();
                }
            );

            let isPartOptionAddedWithRadioValue = $rootScope.$on(
                'part:option:added:with:is:selected:radio:value',
                (event, data) => {
                    if (
                        vm.offer &&
                        vm.offer.status &&
                        RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(vm.offer.status.name) > -1
                    ) {
                        vm.reload();
                    } else {
                        let _part = _.find(vm.offer.requestOfferParts, (part) => {
                            return part.id === data.requestOfferPartId;
                        });
                        _part.offerPartOptions = data.offerPartOptions;
                        _part.hasRequestOfferPartOptions = true;
                        calculateTotalPriceFn();
                    }
                }
            );

            let isPartOptionAddedOnClientSideOnly = $rootScope.$on(
                'part:option:added:in:client:side:only',
                (event, data) => {
                    let offerPartMap = { offerPart: data.requestOfferPart, action: 'ACCEPTED_BY_SUPPLIER_WITH_CHANGES' };
                    removeOfferPartFromMap(data.requestOfferPart);
                    vm.offerPartsWithActions.push(offerPartMap);
                    vm.flagForAcceptedWithChanges = 'edit' + data.requestOfferPart.id;
                }
            );
            let isPartOptionAddedUpdatedOnClientSideOnly = $rootScope.$on(
                'part:option:added:updated:in:client:side:only',
                (event, data) => {
                    let _offerPart = _.find(vm.offer.requestOfferParts, (part) => part.id === data.requestOfferPart.id);
                    if (_offerPart) {
                        _offerPart.offerPartOptions = data.allOptionsForPart;
                        _offerPart.hasRequestOfferPartOptions = true;
                    }
                    vm.calculateTotalPrice();
                }
            );

            let isPartOptionDeletedWithRadioValue = $rootScope.$on(
                'part:option:deleted:with:is:selected:radio:value',
                (event, data) => {
                    let _part = _.find(vm.offer.requestOfferParts, (part) => {
                        return part.id === data.requestOfferPartId;
                    });
                    _part.offerPartOptions = data.offerPartOptions;
                    calculateTotalPriceFn();
                }
            );

            let isPartOptionUpdatedWithRadioValue = $rootScope.$on(
                'part:option:updated:with:is:selected:radio:value',
                (event, data) => {
                    let _part = _.find(vm.offer.requestOfferParts, (part) => {
                        return part.id === data.requestOfferPartId;
                    });
                    let _requestOfferPartOption = _.find(_part.offerPartOptions, (offerPartOption) => {
                        return offerPartOption.id === data.requestOfferPartOption.id;
                    });
                    _requestOfferPartOption.price = data.requestOfferPartOption.price;
                    _requestOfferPartOption.newPrice = data.requestOfferPartOption.price;
                    _requestOfferPartOption.supplierPrice = data.requestOfferPartOption.supplierPrice;
                    _requestOfferPartOption.amount = data.requestOfferPartOption.amount;
                    _requestOfferPartOption.amountType = data.requestOfferPartOption.amountType;
                    _requestOfferPartOption.supplierAmount = data.requestOfferPartOption.supplierAmount;
                    _requestOfferPartOption.supplierAmountType = data.requestOfferPartOption.supplierAmountType;
                    if (SHOW_SUPPLIER_PRICE_STATE.indexOf(vm.offer.status.name) > -1) {
                        _requestOfferPartOption.newPrice = data.requestOfferPartOption.supplierPrice;
                    }
                    if (EDITED_BY_SUPPLIER_PART_STATE.indexOf(_requestOfferPartOption.status.name) > -1) {
                        _requestOfferPartOption.newPrice = data.requestOfferPartOption.supplierPrice;
                    }
                    _requestOfferPartOption.vat = data.requestOfferPartOption.vat;
                    calculateTotalPriceFn();
                }
            );

            let isRequestOfferPartOptionUpdated = $rootScope.$on(
                'request:offer:part:option:updated:by:supplier',
                (event, data) => {
                    let _offer = data.requestOffer;
                    let _part = _.find(vm.offer.requestOfferParts, (part) => {
                        return part.id === data.requestOfferPartId;
                    });
                    let _requestOfferPartOption = _.find(_part.offerPartOptions, (offerPartOption) => {
                        return offerPartOption.id === data.requestOfferPartOption.id;
                    });
                    _requestOfferPartOption.supplierPrice = data.requestOfferPartOption.supplierPrice;
                    if (
                        _offer &&
                        _offer.status &&
                        RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(_offer.status.name) > -1
                    ) {
                        vm.reload();
                    }
                }
            );

            let isRequestOfferPartOptionDeleted = $rootScope.$on(
                'part:option:deleted:with:is:selected:radio:value',
                (event, data) => {
                    if (
                        vm.offer &&
                        vm.offer.status &&
                        RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(vm.offer.status.name) > -1
                    ) {
                        vm.reload();
                    }
                }
            );

            let isAccommodationOverlapped = $rootScope.$on('accommodation.overlapped.supplier', (event, data) => {
                this.accommodationOverlapped = data.accommodationOverlapped;
            });

            $scope.$on('$destroy', function () {
                requestOfferStatusChangedEvent();
                presentationImageUpdateEvent();
                presentationUpdateEvent();
                langChangeEvent();
                appPanelCloseEvent();
                isPartOptionRadioValueUpdated();
                isPartOptionAddedWithRadioValue();
                isPartOptionDeletedWithRadioValue();
                isPartOptionUpdatedWithRadioValue();
                isRequestOfferPartOptionUpdated();
                isRequestOfferPartOptionDeleted();
                isAccommodationOverlapped();
                isPartOptionAddedOnClientSideOnly();
                isPartOptionAddedUpdatedOnClientSideOnly();
                if (vm.guideType && vm.guideType !== GlobalConstants.SHOW_NONE) {
                    vm.intro.removeHints();
                }
            });
            $scope.$on('$destroy', function () {
                $locationChangeStartUnbind();
            });

            $scope.$on('lang:change', function (event, data) {
                vm.languageSelected = data.lang;
            });

        }]
})
