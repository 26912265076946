import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';
import { clearLocalToken } from "../../../../utils/index";

class TokenExpireModalCtrl {
    constructor(data, $scope, close, $element, $rootScope) {
        this._injected = {
            data,
            $scope,
            close,
            $element,
            $rootScope
        };

        ScopeFunctions.addToScope($scope);

        this.message = '';
        this.messageTitle = '';
        this.data = data;
        this.init(data);
    }

    init(data) {
        this.getTokenSessionExpireText();
    }

    ok() {
        const { close, $element } = this._injected;
        clearLocalToken();
        if (!this.data.apiUrl.includes("/api/login")) {
            window.location.href = this.data.redirectUrl;
        }
        $element.modal('hide');
        close(null, 500);
    }

    _close() {
        const { close, $element } = this._injected;
        if (!this.data.apiUrl.includes("/api/login")) {
            window.location.href = this.data.redirectUrl;
        }
        $element.modal('hide');
        close(null, 500);
    }

    getTokenSessionExpireText() {
        const {$rootScope} = this._injected;
        switch ($rootScope.langSelected) {
            case 'sv_SV':
                this.btnText = 'Logga in';
                this.message =
                    'Din session har löpt ut. Logga in igen för att fortsätta använda appen.';
                this.messageTitle = 'Notis';
                break;
            case 'nb_Nb':
                this.btnText = 'Logg inn';
                this.message =
                    'Din økt har utløpt. Vennligst logg inn på nytt for å fortsette å bruke appen.';
                this.messageTitle = 'Varsel';
                break;
            case 'da_DK':
                this.btnText = 'Log ind';
                this.message =
                    'Din session er udløbet. Venligst log ind igen for at fortsætte med at bruge appen.';
                this.messageTitle = 'Notifikation';
                break;
            case 'fi_FI':
                this.btnText = 'Kirjaudu sisään';
                this.message =
                    'Sessiosi on vanhentunut. Kirjaudu sisään uudelleen jatkaaksesi sovelluksen käyttöä.';
                this.messageTitle = 'Ilmoitus';
                break;
            default:
                this.btnText = 'Login';
                this.message =
                    'Your session has expired. Please log in again to continue using the app.';
                this.messageTitle = 'Notification';
                break;
        }
    }
}

TokenExpireModalCtrl.$inject = ['data', '$scope', 'close', '$element', '$rootScope', '$translate'];
export default TokenExpireModalCtrl;
