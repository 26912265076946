(function () {
    'use strict';
    /**
     * Used only for listener to determine the home page after login
     *
     */
    angular.module('navigation')
        .component('navigation', {
            controller: [
                '$scope',
                '$rootScope',
                '$location',
                '$state',
                'EventEnum',
                function NavigationController($scope, $rootScope, $location, $state, EventEnum) {
                    $rootScope.$on(EventEnum.USER_GROUP_CHANGED, function (evt, data) {
                        if (data) {
                            let redirectTo;
                            let _params = {};
                            if (data.supplier == null) {
                                if (data.childUserGroupCount > 0) {
                                    redirectTo = 'overview';
                                } else {
                                    redirectTo = 'events/list';
                                }
                            } else {
                                redirectTo = 'offers';
                                if (!!data.showPresentation) {
                                    _params = { showPresentation: true };
                                }
                            }

                            if (redirectTo) {
                                let oldUrl = $location.path();
                                /*reloading route only if user is switching from one creator group to another or
                       from one supplier group to another to reduce the number of calls to
                       eventsHomePage/suppliersHomePage*/
                                if ('/' + redirectTo === oldUrl) {
                                    $state.go($state.current, {}, { reload: true }); //second parameter is for $stateParams
                                } else {
                                    redirectTo = redirectTo === 'events/list' ? 'events' : redirectTo;
                                    $state.go(redirectTo, _params);
                                }
                            }
                        }
                    });
                },
            ]
        })
})();
