const FINISHED_OFFER_STATUSES = ['FINISHED', 'FINISHED_WAITING_FOR_CREATOR', 'FINISHED_WAITING_FOR_SUPPLIER'];
const CANCELED_OFFER_STATUSES = [
    'CANCELED_AND_FINISHED',
    'CANCELED_WAITING_FOR_CREATOR',
    'CANCELED_WAITING_FOR_SUPPLIER',
];
const SHOW_SUPPLIER_AMOUNT_STATE = ['SENT', 'EDITED_BY_CREATOR'];
import PriceCalculationUtil from '../../utils/price_calculate/PriceCalculationUtil';
import CurrencyUtil from '../../utils/CurrencyUtil';
import UserGroupData from '../../core/existingServices/data_store/UserGroupData';
import CommonFunctions from '../../utils/CommonFunctionUtil';
import _ from 'underscore';

class PriceCalculationSupplierUtil extends PriceCalculationUtil {
    constructor() {
        super();
        this.getAmount = this.getAmount.bind(this);
        this.getPrice = this.getPrice.bind(this);
        this.getOldPriceInclVat = this.getOldPriceInclVat.bind(this);
        this.getPriceExclVat = this.getPriceExclVat.bind(this);
        this.getPriceInclVat = this.getPriceInclVat.bind(this);
        this.getDifference = this.getDifference.bind(this);
        this._getDifferenceInclVat = this._getDifferenceInclVat.bind(this);
        this.getTotalPrice = this.getTotalPrice.bind(this);
        this._getTotalPriceInclVAT = this._getTotalPriceInclVAT.bind(this);
        this.getOfferTotalPrice = this.getOfferTotalPrice.bind(this);
        this.getRequestOfferPartOptionAmount = this.getRequestOfferPartOptionAmount.bind(this);
        this.getRequestOfferPartOptionPrice = this.getRequestOfferPartOptionPrice.bind(this);
        this.getRequestOfferPartOptionPriceInclVat = this.getRequestOfferPartOptionPriceInclVat.bind(this);
        this.getRequestOfferPartOptionPriceExclVat = this.getRequestOfferPartOptionPriceExclVat.bind(this);
        this._getRequestOfferPartOptionTotalPriceInclVAT = this._getRequestOfferPartOptionTotalPriceInclVAT.bind(this);
        this.getRequestPartOptionTotalPrice = this.getRequestPartOptionTotalPrice.bind(this);
        this._getRequestPartOptionTotalPriceInclVAT = this._getRequestPartOptionTotalPriceInclVAT.bind(this);
        this.getRequestOfferPartOptionPriceWithVat = this.getRequestOfferPartOptionPriceWithVat.bind(this);
        this.getRequestPartOptionDifference = this.getRequestPartOptionDifference.bind(this);
        this._getRequestPartOptionDifferenceInclVat = this._getRequestPartOptionDifferenceInclVat.bind(this);
        this._getRequestPartOptionDifferenceWithVat = this._getRequestPartOptionDifferenceWithVat.bind(this);
        this._getRequestPartOptionDifferenceWithoutVat = this._getRequestPartOptionDifferenceWithoutVat.bind(this);
        this._getOriginalRequestOfferPartOptionTotalPriceWithVat =
            this._getOriginalRequestOfferPartOptionTotalPriceWithVat.bind(this);
        this.getOfferOriginalPrice = this.getOfferOriginalPrice.bind(this);
        this._getCanceledAmountInclVAT = this._getCanceledAmountInclVAT.bind(this);
    }

    //TODO(SC) replace withVat params and use this method
    shouldIncludeVatOnPrice() {
        return UserGroupData.hasIncludeVatOnPriceTrue();
    }

    _offerAlreadyFinished(offer) {
        if (!offer) {
            return false;
        }
        return (
            CommonFunctions.hasIntersectValue([offer.status.name], FINISHED_OFFER_STATUSES) ||
            CommonFunctions.hasIntersectValue([offer.status.name], CANCELED_OFFER_STATUSES)
        );
    }

    getAmount(offer, part) {
        let _amount = 0;
        if (this._offerAlreadyFinished(offer)) {
            _amount = part.finalAmount >= 0 ? part.finalAmount : part.amount;
        } else {
            _amount = part.amount;
        }
        return +_amount;
    }

    getRequestOfferPartOptionAmount(offer, partOption, requestOfferPart) {
        let _amount = 0;
        if (partOption) {
            if (this._offerAlreadyFinished(offer)) {
                _amount = partOption.finalAmount >= 0 ? partOption.finalAmount : partOption.amount;
            } else {
                if (
                    SHOW_SUPPLIER_AMOUNT_STATE.indexOf(offer.status.name) > -1 &&
                    partOption.status.name !== 'EDITED_WAITING_FOR_SUPPLIER'
                ) {
                    _amount = partOption.supplierAmount ? partOption.supplierAmount : partOption.amount;
                } else {
                    _amount = partOption.amount;
                }
            }
        } else if (requestOfferPart) {
            if (this._offerAlreadyFinished(offer)) {
                _amount = requestOfferPart.finalAmount >= 0 ? requestOfferPart.finalAmount : requestOfferPart.amount;
            } else {
                _amount = requestOfferPart.amount;
            }
        } else {
            return +_amount;
        }
        return +_amount;
    }

    /*
   - Adding + is just making value to Numeric, same as doing Number(value).
   - Also removes leading zeros, i.e +0001234 = 1234;
   */
    getPrice(offer, part) {
        if (this._offerAlreadyFinished(offer)) {
            if (offer.vatIncludedInPrice) {
                return part && part.finalPriceInclVat >= 0
                    ? +part.finalPriceInclVat
                    : +this._getPriceFromPart(part, offer.vatIncludedInPrice);
            } else {
                return part && part.finalPrice >= 0
                    ? +part.finalPrice
                    : +this._getPriceFromPart(part, offer.vatIncludedInPrice);
            }
        } else {
            return +this._getPriceFromPart(part, offer.vatIncludedInPrice);
        }
    }

    getOldPriceInclVat(offer, part) {
        let _withoutVat = this.getOldPriceValue(offer, part);
        let _oldPrice = _withoutVat;
        if (this.shouldIncludeVatOnPrice()) {
            let _vat = 0;
            let _oldVatPercent = part.vat;
            if (_oldVatPercent > 0) {
                _vat = (_withoutVat * _oldVatPercent) / 100;
            }
            _oldPrice = _oldPrice + _vat;
        }
        return this.round(_oldPrice);
    }

    getPriceExclVat(offer, part) {
        if (this._offerAlreadyFinished(offer)) {
            if (offer.vatIncludedInPrice) {
                return part.finalPriceInclVat >= 0
                    ? +this.getPriceWithOutVat(part.finalPriceInclVat, part.vat)
                    : +this._getPriceFromPart(part);
            } else {
                return part.finalPrice >= 0 ? +part.finalPrice : +this._getPriceFromPart(part);
            }
        } else {
            return +this._getPriceFromPart(part);
        }
    }

    getRequestOfferPartOptionPrice(offer, partOption, requestOfferPart) {
        if (partOption) {
            if (this._offerAlreadyFinished(offer)) {
                if (offer.vatIncludedInPrice) {
                    return partOption.finalPriceInclVat >= 0
                        ? +partOption.finalPriceInclVat
                        : +this._getPriceFromPartOption(partOption, offer.vatIncludedInPrice);
                } else {
                    return partOption.finalPrice >= 0
                        ? +partOption.finalPrice
                        : +this._getPriceFromPartOption(partOption, offer.vatIncludedInPrice);
                }
            } else if (
                offer.status.name === 'SENT' &&
                requestOfferPart &&
                CommonFunctions.hasIntersectValue(
                    [requestOfferPart.status.name],
                    ['ACCEPTED', 'ADDED_WAITING_FOR_SUPPLIER', 'EDITED_WAITING_FOR_SUPPLIER']
                )
            ) {
                return +this._getSupplierPriceFromPartOption(partOption);
            } else {
                return +this._getPriceFromPartOption(partOption, offer.vatIncludedInPrice);
            }
        } else {
            if (this._offerAlreadyFinished(offer)) {
                if (offer.vatIncludedInPrice) {
                    return requestOfferPart.finalPriceInclVat >= 0
                        ? +requestOfferPart.finalPriceInclVat
                        : +this._getPriceFromPart(requestOfferPart, offer.vatIncludedInPrice);
                } else {
                    return requestOfferPart.finalPrice >= 0
                        ? +requestOfferPart.finalPrice
                        : +this._getPriceFromPart(requestOfferPart, offer.vatIncludedInPrice);
                }
            } else {
                return +this._getPriceFromPart(requestOfferPart, offer.vatIncludedInPrice);
            }
        }
    }

    getRequestOfferPartOptionPriceExclVat(offer, partOption, requestOfferPart) {
        if (partOption) {
            if (this._offerAlreadyFinished(offer)) {
                return partOption.finalPrice >= 0
                    ? +partOption.finalPrice
                    : +this._getPriceExclVatFromPartOption(partOption);
            } else if (
                offer.status.name === 'SENT' &&
                requestOfferPart &&
                CommonFunctions.hasIntersectValue(
                    [requestOfferPart.status.name],
                    ['ACCEPTED', 'ADDED_WAITING_FOR_SUPPLIER', 'EDITED_WAITING_FOR_SUPPLIER']
                )
            ) {
                return +this._getSupplierPriceFromPartOption(partOption);
            } else {
                return +this._getPriceExclVatFromPartOption(partOption);
            }
        } else {
            if (this._offerAlreadyFinished(offer)) {
                return requestOfferPart.finalPrice >= 0
                    ? +requestOfferPart.finalPrice
                    : +this._getPriceFromPart(requestOfferPart, offer.vatIncludedInPrice);
            } else {
                return +this._getPriceFromPart(requestOfferPart, offer.vatIncludedInPrice);
            }
        }
    }

    getRequestOfferPartOptionPriceInclVat(offer, partOption, requestOfferPart) {
        if (partOption) {
            if (this._offerAlreadyFinished(offer)) {
                if (offer.vatIncludedInPrice) {
                    return partOption.finalPriceInclVat >= 0
                        ? +partOption.finalPriceInclVat
                        : +this._getPriceFromPartOption(partOption, offer.vatIncludedInPrice);
                } else {
                    return partOption.finalPrice >= 0
                        ? +partOption.finalPrice
                        : +this._getPriceFromPartOption(partOption, offer.vatIncludedInPrice);
                }
            } else if (
                offer.status.name === 'SENT' &&
                requestOfferPart &&
                CommonFunctions.hasIntersectValue(
                    [requestOfferPart.status.name],
                    ['ACCEPTED', 'ADDED_WAITING_FOR_SUPPLIER', 'EDITED_WAITING_FOR_SUPPLIER']
                )
            ) {
                return +this._getSupplierPriceFromPartOptionInclVat(partOption, true);
            } else {
                return +this._getPriceFromPartOption(partOption, true);
            }
        } else {
            if (this._offerAlreadyFinished(offer)) {
                if (offer.vatIncludedInPrice) {
                    return requestOfferPart.finalPriceInclVat >= 0
                        ? +requestOfferPart.finalPriceInclVat
                        : +this._getPriceFromPart(requestOfferPart, offer.vatIncludedInPrice);
                } else {
                    return requestOfferPart.finalPrice >= 0
                        ? +requestOfferPart.finalPrice
                        : +this._getPriceFromPart(requestOfferPart, offer.vatIncludedInPrice);
                }
            } else {
                return +this._getPriceFromPart(requestOfferPart, offer.vatIncludedInPrice);
            }
        }
    }

    getPriceWithVat(offer, part) {
        let _price = this.getPrice(offer, part);
        let _vat = 0;
        if (part.vat > 0) {
            _vat = (_price * part.vat) / 100;
        }
        _price = parseFloat(_price) + parseFloat(_vat);
        if (isNaN(_price)) {
            _price = 0;
        }
        return Number(Math.round(_price + 'e' + 2) + 'e-' + 2);
    }

    getPriceInclVat(offer, part) {
        let _price = this.getPrice(offer, part);
        _price = parseFloat(_price);
        if (isNaN(_price)) {
            _price = 0;
        }
        return Number(Math.round(_price + 'e' + 2) + 'e-' + 2);
    }

    getRequestOfferPartOptionPriceWithVat(offer, partOption, part) {
        let _price = this.getRequestOfferPartOptionPrice(offer, partOption, part);
        let _vat = 0;
        if (partOption && partOption.vat > 0) {
            _vat = (_price * partOption.vat) / 100;
        }
        _price = parseFloat(_price) + parseFloat(_vat);
        return Number(Math.round(_price + 'e' + 2) + 'e-' + 2);
    }

    getPriceWithOutVat(priceInclVat, vat) {
        let _price = priceInclVat;
        if (vat && vat > 0) {
            _price = parseFloat(priceInclVat / (1 + vat / 100));
        }
        if (isNaN(_price)) {
            _price = 0;
        }
        return Number(Math.round(_price + 'e' + 2) + 'e-' + 2);
    }

    getDifference(offer, part, withVat) {
        if (withVat) {
            if (offer.vatIncludedInPrice) {
                if (!part.newPriceInclVat) {
                    part.newPriceInclVat = this.calculatePriceFromPriceAndVat(part.price, part.vat);
                }
                return this._getDifferenceInclVat(offer, part);
            } else {
                return this._getDifferenceWithVat(offer, part);
            }
        } else {
            return this._getDifferenceWithoutVat(offer, part);
        }
    }

    getDifferenceInPaidOnsite(part) {
        return part.finalPaidOnsite === false;
    }

    getRequestPartOptionDifference(offer, partOption, withVat) {
        if (withVat) {
            if (offer.vatIncludedInPrice) {
                if (!partOption.priceInclVat) {
                    partOption.priceInclVat = this.calculatePriceFromPriceAndVat(partOption.price, partOption.vat);
                }
                return this._getRequestPartOptionDifferenceInclVat(offer, partOption);
            } else {
                return this._getRequestPartOptionDifferenceWithVat(offer, partOption);
            }
        } else {
            return this._getRequestPartOptionDifferenceWithoutVat(offer, partOption);
        }
    }

    _getDifferenceWithVat(offer, part) {
        let _currentTotal = this._getTotalPriceWithVAT(offer, part);
        let _originalTotalWithVat = this._getOriginalTotalPriceWithVat(part);
        var _difference = _currentTotal - _originalTotalWithVat;
        if (isNaN(_difference)) {
            _difference = 0;
        }
        return this.round(_difference);
    }

    _getDifferenceInclVat(offer, part) {
        let _currentTotal = this._getTotalPriceInclVAT(offer, part);
        let _originalTotalWithVat = this._getOriginalTotalPriceInclVat(part, offer);
        var _difference = _currentTotal - _originalTotalWithVat;
        if (isNaN(_difference)) {
            _difference = 0;
        }
        return this.round(_difference);
    }

    _getRequestPartOptionDifferenceWithVat(offer, partOption) {
        let _currentTotal = this._getRequestPartOptionTotalPriceWithVAT(offer, partOption);
        let _originalTotalWithVat = this._getOriginalRequestOfferPartOptionTotalPriceWithVat(partOption);
        var _difference = _currentTotal - _originalTotalWithVat;
        if (isNaN(_difference)) {
            _difference = 0;
        }
        return this.round(_difference);
    }

    _getRequestPartOptionDifferenceInclVat(offer, partOption) {
        let _currentTotal = this._getRequestPartOptionTotalPriceInclVAT(offer, partOption);
        let _originalTotalWithVat = this._getOriginalRequestOfferPartOptionTotalPriceInclVat(partOption, offer);
        var _difference = _currentTotal - _originalTotalWithVat;
        if (isNaN(_difference)) {
            _difference = 0;
        }
        return this.round(_difference);
    }

    _getDifferenceWithoutVat(offer, part) {
        let _currentTotal = this._getTotalPriceWithoutVat(offer, part);
        let _originalTotal = this._getOriginalTotalPrice(part);
        var _difference = _currentTotal - _originalTotal;
        if (isNaN(_difference)) {
            _difference = 0;
        }
        return this.round(_difference);
    }

    _getRequestPartOptionDifferenceWithoutVat(offer, partOption) {
        let _currentTotal = this._getRequestPartOptionTotalPriceWithoutVat(offer, partOption);
        let _originalTotal = this._getOriginalRequestOfferPartOptionTotalPrice(partOption);
        var _difference = _currentTotal - _originalTotal;
        if (isNaN(_difference)) {
            _difference = 0;
        }
        return this.round(_difference);
    }

    getAmountLocalisedWithCurrency(price, currency, digitsAfterDecimal = 0) {
        return CurrencyUtil.getAmountLocalisedWithCurrency(price, currency, digitsAfterDecimal);
    }

    viewPrice(price, currency, digitsAfterDecimal = 0) {
        return CurrencyUtil.viewPrice(price, currency, digitsAfterDecimal);
    }

    getTotalPrice(offer, part, includeVat) {
        if (includeVat) {
            if (offer.vatIncludedInPrice) {
                return this._getTotalPriceInclVAT(offer, part);
            } else {
                return this._getTotalPriceWithVAT(offer, part);
            }
        } else {
            return this._getTotalPriceWithoutVat(offer, part);
        }
    }

    getRequestPartOptionTotalPrice(offer, requestOfferPart, partOption, includeVat) {
        if (includeVat) {
            if (offer.vatIncludedInPrice) {
                return this._getRequestOfferPartOptionTotalPriceInclVAT(offer, partOption, requestOfferPart);
            } else {
                return this._getRequestPartOptionTotalPriceWithVAT(offer, partOption, requestOfferPart);
            }
        } else {
            return this._getRequestPartOptionTotalPriceWithoutVat(offer, partOption, requestOfferPart);
        }
    }

    _getRequestPartOptionTotalPriceWithoutVat(offer, partOption, requestOfferPart) {
        let _amount = this.getRequestOfferPartOptionAmount(offer, partOption, requestOfferPart);
        let _price = this.getRequestOfferPartOptionPrice(offer, partOption, requestOfferPart);
        return this._calculateTotalPrice(_amount, _price);
    }

    _getRequestPartOptionTotalPriceWithVAT(offer, partOption, requestOfferPart) {
        let _amount = this.getRequestOfferPartOptionAmount(offer, partOption, requestOfferPart);
        let _totalPrice = this.getRequestOfferPartOptionPriceWithVat(offer, partOption, requestOfferPart) * _amount;
        if (isNaN(_totalPrice)) {
            _totalPrice = 0;
        }
        return this.round(_totalPrice);
    }

    _getRequestPartOptionTotalPriceInclVAT(offer, partOption, requestOfferPart) {
        let _amount = this.getRequestOfferPartOptionAmount(offer, partOption, requestOfferPart);
        let _totalPrice = this.getRequestOfferPartOptionPriceInclVat(offer, partOption, requestOfferPart) * _amount;
        if (isNaN(_totalPrice)) {
            _totalPrice = 0;
        }
        return this.round(_totalPrice);
    }

    _getTotalPriceWithoutVat(offer, part) {
        let _amount = this.getAmount(offer, part);
        let _price = this.getPrice(offer, part);
        return this._calculateTotalPrice(_amount, _price);
    }

    _getRequestOfferPartOptionTotalPriceWithoutVAT(offer, partOption, part) {
        let _amount = this.getRequestOfferPartOptionAmount(offer, partOption, part);
        let _price = this.getRequestOfferPartOptionPrice(offer, partOption, part);
        return this._calculateTotalPrice(_amount, _price);
    }

    _getTotalPriceWithVAT(offer, part) {
        let _amount = this.getAmount(offer, part);
        let _totalPrice = this.getPriceWithVat(offer, part) * _amount;
        if (isNaN(_totalPrice)) {
            _totalPrice = 0;
        }
        return this.round(_totalPrice);
    }

    _getTotalPriceInclVAT(offer, part) {
        let _amount = this.getAmount(offer, part);
        let _totalPrice = this.getPrice(offer, part) * _amount;
        if (isNaN(_totalPrice)) {
            _totalPrice = 0;
        }
        return this.round(_totalPrice);
    }

    _getRequestOfferPartOptionTotalPriceWithVAT(offer, partOption, part) {
        let _amount = this.getRequestOfferPartOptionAmount(offer, partOption);
        let _totalPrice = this.getRequestOfferPartOptionPriceWithVat(offer, partOption, part) * _amount;
        if (isNaN(_totalPrice)) {
            _totalPrice = 0;
        }
        return this.round(_totalPrice);
    }

    _getRequestOfferPartOptionTotalPriceInclVAT(offer, partOption, requestOfferPart) {
        let _amount = this.getRequestOfferPartOptionAmount(offer, partOption);
        let _totalPrice = this.getRequestOfferPartOptionPriceInclVat(offer, partOption, requestOfferPart) * _amount;
        if (isNaN(_totalPrice)) {
            _totalPrice = 0;
        }
        return this.round(_totalPrice);
    }

    _getOfferTotalPriceWithoutVAT(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    _totalPrice += parseFloat(
                        this._getRequestOfferPartOptionTotalPriceWithoutVAT(offer, _selectedRequestOfferPartOption, part)
                    );
                });
            } else {
                _totalPrice += parseFloat(this._getTotalPriceWithoutVat(offer, part));
            }
        }
        return this.round(_totalPrice);
    }

    _getCanceledAmountWithoutVat(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name === 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    _totalPrice += parseFloat(
                        this._getRequestOfferPartOptionTotalPriceWithoutVAT(offer, _selectedRequestOfferPartOption)
                    );
                });
            } else {
                _totalPrice += parseFloat(this._getTotalPriceWithoutVat(offer, part));
            }
        }
        return this.round(_totalPrice);
    }

    _getOfferTotalPriceWithVAT(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    _totalPrice += parseFloat(
                        this._getRequestOfferPartOptionTotalPriceWithVAT(offer, _selectedRequestOfferPartOption, part)
                    );
                });
            } else {
                _totalPrice += parseFloat(this._getTotalPriceWithVAT(offer, part));
            }
        }
        return this.round(_totalPrice);
    }

    _getOfferTotalPriceInclVAT(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    _selectedRequestOfferPartOption.priceInclVat = this.calculatePriceFromPriceAndVat(
                        _selectedRequestOfferPartOption.price,
                        _selectedRequestOfferPartOption.vat
                    );
                    _selectedRequestOfferPartOption.finalPriceInclVat = this.calculatePriceFromPriceAndVat(
                        _selectedRequestOfferPartOption.finalPrice,
                        _selectedRequestOfferPartOption.vat
                    );
                    _totalPrice += parseFloat(
                        this._getRequestOfferPartOptionTotalPriceInclVAT(offer, _selectedRequestOfferPartOption, part)
                    );
                });
            } else {
                _totalPrice += parseFloat(this._getTotalPriceInclVAT(offer, part));
            }
        }
        return this.round(_totalPrice);
    }

    _getOfferTotalPaidOnsitePriceInclVAT(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    _selectedRequestOfferPartOption.priceInclVat = this.calculatePriceFromPriceAndVat(
                        _selectedRequestOfferPartOption.price,
                        _selectedRequestOfferPartOption.vat
                    );
                    _selectedRequestOfferPartOption.finalPriceInclVat = this.calculatePriceFromPriceAndVat(
                        _selectedRequestOfferPartOption.finalPrice,
                        _selectedRequestOfferPartOption.vat
                    );
                    if (_selectedRequestOfferPartOption.finalPaidOnsite) {
                        _totalPrice += parseFloat(
                            this._getRequestOfferPartOptionTotalPriceInclVAT(
                                offer,
                                _selectedRequestOfferPartOption,
                                part
                            )
                        );
                    }
                });
            } else {
                if (part.finalPaidOnsite) {
                    _totalPrice += parseFloat(this._getTotalPriceInclVAT(offer, part));
                }
            }
        }

        return this.round(_totalPrice);
    }

    _getCanceledAmountWithVAT(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name === 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    _totalPrice += parseFloat(
                        this._getRequestOfferPartOptionTotalPriceWithVAT(offer, _selectedRequestOfferPartOption)
                    );
                });
            } else {
                _totalPrice += parseFloat(this._getTotalPriceWithVAT(offer, part));
            }
        }
        return this.round(_totalPrice);
    }

    _getCanceledAmountInclVAT(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name === 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    _selectedRequestOfferPartOption.priceInclVat = this.calculatePriceFromPriceAndVat(
                        _selectedRequestOfferPartOption.price,
                        _selectedRequestOfferPartOption.vat
                    );
                    _totalPrice += parseFloat(
                        this._getRequestOfferPartOptionTotalPriceInclVAT(offer, _selectedRequestOfferPartOption, part)
                    );
                });
            } else {
                _totalPrice += parseFloat(this._getTotalPriceInclVAT(offer, part));
            }
        }
        return this.round(_totalPrice);
    }

    getOfferTotalPrice(offer, partsInOffer, includeVat) {
        if (includeVat) {
            if (offer.vatIncludedInPrice) {
                return this._getOfferTotalPriceInclVAT(offer, partsInOffer);
            } else {
                return this._getOfferTotalPriceWithVAT(offer, partsInOffer);
            }
        } else {
            return this._getOfferTotalPriceWithoutVAT(offer, partsInOffer);
        }
    }

    getOfferCanceledAmount(offer, parts, includeVat) {
        if (includeVat) {
            if (offer.vatIncludedInPrice) {
                return this._getCanceledAmountInclVAT(offer, parts);
            } else {
                return this._getCanceledAmountWithVAT(offer, parts);
            }
        } else {
            return this._getCanceledAmountWithoutVat(offer, parts);
        }
    }

    getOfferOriginalPrice(parts, includeVat) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(parts);
        for (let part of _deliverableParts) {
            let _withoutVat = 0, _withoutVatOfferPartOption = 0, _vat = 0;
            if (!part.hasRequestOfferPartOptions) {
                _withoutVat = this._getOriginalTotalPrice(part);
                if (includeVat) {
                    if (part.vat > 0) {
                        _vat = (_withoutVat * part.vat) / 100;
                    }
                }
            } else {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    let _price = this._getOriginalRequestOfferPartOptionTotalPrice(_selectedRequestOfferPartOption);
                    let _vat = 0;
                    if (includeVat) {
                        if (_selectedRequestOfferPartOption.vat > 0) {
                            _vat = (_price * _selectedRequestOfferPartOption.vat) / 100;
                        }
                        _withoutVatOfferPartOption += _price + _vat;
                    } else {
                        _withoutVatOfferPartOption += _price + _vat;
                    }
                });
            }
            _totalPrice += _withoutVat + _vat + _withoutVatOfferPartOption;
        }
        return this.round(_totalPrice);
    }

    hasUpdatedFinalPriceOrAmount(offer, parts, includeVat) {
        if (!offer) {
            return false;
        }

        if (!this._offerAlreadyFinished(offer)) {
            return false;
        }

        for (let part of parts) {
            if (!part.hasRequestOfferPartOptions) {
                if (this.getDifference(offer, part, includeVat)) {
                    return true;
                }
            }
        }

        return false;
    }

    hasUpdatedFinalPaidOnsite(offer, parts) {
        if (!offer) {
            return false;
        }

        if (!this._offerAlreadyFinished(offer)) {
            return false;
        }

        let changeInPaidOnsite = false;
        for (let part of parts) {
            changeInPaidOnsite = changeInPaidOnsite || this.getDifferenceInPaidOnsite(part);
        }
        return changeInPaidOnsite;
    }

    hasUpdatedFinalPriceOrAmountForOptions(offer, parts, includeVat) {
        if (!offer) {
            return false;
        }

        if (!this._offerAlreadyFinished(offer)) {
            return false;
        }

        for (let part of parts) {
            if (this.hasUpdatedRequestOfferPartOptionFinalPriceOrAmount(offer, part, includeVat)) {
                return true;
            }
        }

        return false;
    }

    hasUpdatedRequestOfferPartOptionFinalPriceOrAmount(offer, part, includeVat) {
        if (!offer) {
            return false;
        }

        if (!this._offerAlreadyFinished(offer)) {
            return false;
        }

        if (part.hasRequestOfferPartOptions) {
            for (let partOption of part.offerPartOptions) {
                if (this.getRequestPartOptionDifference(offer, partOption, includeVat)) {
                    return true;
                }
            }
        }

        return false;
    }

    showPaidOnsiteColumn(offer) {
        if (!offer) {
            return false;
        }
        if (FINISHED_OFFER_STATUSES.includes(offer.status.name)) {
            return true;
        }
        return false;
    }

    getTotalPaidOnsitePrice(offer, partsInOffer, includeVat) {
        if (includeVat) {
            if (offer.vatIncludedInPrice) {
                return this._getOfferTotalPaidOnsitePriceInclVAT(offer, partsInOffer);
            } else {
                return this._getTotalPaidOnsitePriceWithVAT(offer, partsInOffer);
            }
        } else {
            return this._getTotalPaidOnsitePriceWithoutVAT(offer, partsInOffer);
        }
    }

    _getTotalPaidOnsitePriceWithVAT(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.finalPaidOnsite) {
                _totalPrice += parseFloat(this._getTotalPriceWithVAT(offer, part));
            }
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    if (_selectedRequestOfferPartOption.finalPaidOnsite) {
                        _totalPrice += parseFloat(
                            this._getRequestOfferPartOptionTotalPriceWithVAT(offer, _selectedRequestOfferPartOption)
                        );
                    }
                });
            }
        }
        return this.round(_totalPrice);
    }

    _getTotalPaidOnsitePriceWithoutVAT(offer, partsInOffer) {
        var _totalPrice = 0;
        let _deliverableParts = this._getDeliverableParts(partsInOffer);
        _deliverableParts = _.filter(_deliverableParts, function (part) {
            return part && part.status.name !== 'CANCELED';
        });
        for (let part of _deliverableParts) {
            if (part.finalPaidOnsite) {
                _totalPrice += parseFloat(this._getTotalPriceWithoutVat(offer, part));
            }
            if (part.hasRequestOfferPartOptions) {
                let _selectedRequestOfferPartOptions = _.filter(part.offerPartOptions, (offerPartOption) => {
                    return offerPartOption.isSelected === true && offerPartOption.status.name !== 'NOT_DELIVERABLE';
                });
                angular.forEach(_selectedRequestOfferPartOptions, (_selectedRequestOfferPartOption) => {
                    if (_selectedRequestOfferPartOption.finalPaidOnsite) {
                        _totalPrice += parseFloat(
                            this._getRequestOfferPartOptionTotalPriceWithoutVAT(offer, _selectedRequestOfferPartOption)
                        );
                    }
                });
            }
        }
        return this.round(_totalPrice);
    }

    getDiscountedPrice(totalPrice, discount) {
        let _discountPrice = totalPrice * (discount / 100);
        return Number(Math.round(_discountPrice + 'e' + 2) + 'e-' + 2);
    }
}

const calculator = new PriceCalculationSupplierUtil();
export default calculator;
