import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";
(function () {
    'use strict';
    angular.module('participant-ratings').component('participantRatings', {
        templateUrl: './app/components/modules/participant/participant_details/ratings/ratings.view.html',
        controllerAs: 'participantRatingsCtrl',
        controller: [
            '$scope',
            '$stateParams',
            '$timeout',
            'Survey',
            'ogScrollService',
            function ParticipantRatingsController(
                $scope,
                $stateParams,
                $timeout,
                Survey,
                ogScrollService
            ) {
                var vm = this;
                vm.closeSuccessMessage = closeSuccessMessageFn;
                vm.editRating = editRatingFn;
                vm.init = initFn;
                vm.submitSurvey = submitSurvey;

                vm.editMode = true;
                vm.showSuccessMessage = false;
                vm.surveyError = false;

                ScopeFunctions.addToScope($scope);
                vm.init();

                function closeSuccessMessageFn() {
                    vm.showSuccessMessage = false;
                }

                function editRatingFn() {
                    vm.editMode = true;
                    closeSuccessMessageFn();
                }

                function initFn() {
                    vm.survey = {};
                    $scope.startProgress();
                    Survey.forParticipant(
                        { hash: $stateParams.hash },
                        (response) => {
                            if (response.survey) {
                                vm.survey = response.survey;
                                if (response.survey.rated) {
                                    vm.editMode = false;
                                }
                            } else {
                                vm.surveyError = true;
                            }
                            $scope.endProgress();
                        },
                        $scope.endWithAlert
                    );
                }

                function scrollTo(id) {
                    $timeout(function () {
                        ogScrollService.scrollToElement(id);
                    }, 0);
                }

                function submitSurvey() {
                    $scope.startProgress();
                    Survey.updateAllSurveysForAUser(vm.survey, function (response) {
                        vm.survey = response;
                        vm.editMode = false;
                        vm.showSuccessMessage = true;
                        $scope.endProgress();
                        scrollTo('successMessage');
                    });
                }
            }
        ]

    });
})();
