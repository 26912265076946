/**
 * Created by amol on 10/17/2019.
 */

import controller from './og_event_process_sidebar.controller';

(function () {
    'use strict';
    angular.module('ogEventProcessSidebar').directive('ogEventProcessSidebar', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    showEventProcessSidebarCallback: '&',
                    animateInviteUser: '&',
                    openOfferPreviewModalCallback: '&',
                    newEventShowGuideCallback: '=',
                },
                templateUrl: './app/components/directives/event_process_sidebar/og_event_process_sidebar.view.html',
                controller,
                controllerAs: 'EventProcessSidebarCtrl',
                bindToController: true,
            };
        },
    ]);
})();
