import AccommodationUtil from '../../../utils/accommodation/AccommodationUtil';
import amountAndPriceSupplierService from '../../../utils/price_calculate/PriceCalculationSupplierUtil';
import googleAddressUtil from '../../../utils/GoogleAddressUtil';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import {UtilFunctions} from "../../../utils/global/utilFunctions";
import _ from 'underscore';

const BUSS_PART_CODES = [
    'common.translate.offer.part.type.bus.trip',
    'common.translate.offer.part.type.bus.(home.journey)',
    'common.translate.offer.part.type.bus.(outward.journey)',
];

(function () {
    'use strict';

    angular.module('ogRequestOfferPartAddEditInline').directive('ogRequestOfferPartAddEditInline', [
        '$log',
        '$routeParams',
        '$rootScope',
        '$timeout',
        'ValidationService',
        'RequestOfferPart',
        'Event',
        '$translate',
        'Request',
        'EventEnum',
        'GlobalConstants',
        'FilledRequestOfferPartComment',
        'DynamicFormUtilService',
        'RequestOfferStatusUtils',
        'CommonFile',
        'CommonFunctions',
        function (
            $log,
            $routeParams,
            $rootScope,
            $timeout,
            ValidationService,
            RequestOfferPart,
            Event,
            $translate,
            Request,
            EventEnum,
            GlobalConstants,
            FilledRequestOfferPartComment,
            DynamicFormUtilService,
            RequestOfferStatusUtils,
            CommonFile,
            CommonFunctions
        ) {
            return {
                restrict: 'EA',
                scope: {
                    event: '=',
                    offerPart: '=',
                    selectRequest: '=',
                    saveCallback: '=',
                    layout: '=',
                    originalOfferPart: '=',
                    offer: '=',
                    accommodationCallback: '=',
                    categoryIds: '=',
                    includeVat: '=',
                    offerPreviewModalCloseCallback: '=',
                    disableAccommodationPart: '=',
                },
                /* jshint ignore:start */
                templateUrl:
                    './app/components/directives/request_offer_part_add_edit_inline/request_offer_part_add_edit_inline.directive.view.html',
                /* jshint ignore:end */
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.hoursToAdd = 0; // Add default hours from 'from-date' to 'to-date' in dateTime pair.
                        ScopeFunctions.addToScope($scope);
                        $scope.form = 'RequestOfferPart';
                        $scope.VAT_DROPDOWN_LIST = GlobalConstants.VAT_DROPDOWN_LIST;
                        $scope.DEFAULT_VAT = GlobalConstants.DEFAULT_VAT;
                        $scope.isAccommodation = false;
                        $scope.remoteUrl = CommonFile.getBaseUrl() + 'api/v1/offerPartType';
                        if ($scope.categoryIds) {
                            $scope.remoteUrl = $scope.remoteUrl + '?categoryIds=' + $scope.categoryIds;
                        }

                        $scope.checkIfAdditionalInfoChanges = function (val) {
                            $scope.additionalInfoUpdated = val;
                        };

                        $scope.amountTypes = [
                            { type: 'PIECES', name: $translate.instant('amount.type.PIECES') },
                            { type: 'PEOPLE', name: $translate.instant('amount.type.PEOPLE') },
                            { type: 'SAME_AS_EVENT', name: $translate.instant('amount.type.SAME_AS_EVENT') },
                        ];

                        $scope.init = function () {
                            $scope.syncDates = true;
                            $scope.isNewRow = false;
                            if ($scope.offerPart == null) {
                                $scope.data = {
                                    dateTimeFrom: 0,
                                    dateTimeTo: 0,
                                    dateCreated: 0,
                                    vat: $scope.DEFAULT_VAT,
                                };
                                $scope.isNewRow = true;
                                $scope.data.amountType = { name: 'PIECES' }; // set PIECES as default while creating new
                                $scope.data.timeless = false;
                            } else {
                                $scope.originalData = angular.copy($scope.offerPart);
                                $scope.data = angular.copy($scope.offerPart);
                                $scope.data.price = UtilFunctions.isGreaterOrEqualToZero($scope.data.newPrice)
                                    ? $scope.data.newPrice
                                    : $scope.data.price;
                                if (
                                    $scope.includeVat &&
                                    $scope.offer.vatIncludedInPrice &&
                                    $scope.layout === 'supplier'
                                ) {
                                    $scope.data.priceInclVat =
                                        amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                            $scope.data.price,
                                            $scope.data.vat
                                        );
                                    $scope.data.newPriceInclVat =
                                        amountAndPriceSupplierService.calculatePriceFromPriceAndVat(
                                            $scope.data.price,
                                            $scope.data.vat
                                        );
                                    $scope.originalData.priceInclVat = $scope.data.priceInclVat;
                                    $scope.originalData.newPriceInclVat = $scope.data.newPriceInclVat;
                                }
                            }
                            if ($scope.data && $scope.data.name) {
                                $scope.data.name = CommonFunctions.getTranslatedTextFromCode(
                                    $scope.data.name,
                                    $scope.data.nameCode
                                );
                            }
                            $scope.isTimePairValid = true;
                        };

                        $scope.canceled = function () {
                            $rootScope.offerPartEditId = 0;
                            $rootScope.ROPEditId = 0;
                            $scope.offerPart = $scope.originalData;
                        };

                        $scope.checkIfAmountTypeChanged = function () {
                            if ($scope.data.amountType.name == 'SAME_AS_EVENT') {
                                $scope.data.amount = $scope.event.participantAmount;
                            }
                        };

                        $scope.checkAndSave = () => {
                            if (
                                $scope.layout !== 'supplier' && $scope.data.list &&
                                $scope.data.list.length > 0 &&
                                BUSS_PART_CODES.indexOf($scope.data.list[0].code) > -1
                            ) {
                                let filledCommentList = $scope.data.list;
                                const service = new google.maps.DistanceMatrixService();
                                // build request
                                const request = googleAddressUtil.prepareDistanceCalRequest(filledCommentList);

                                service.getDistanceMatrix(request).then((response) => {
                                    // put response
                                    $scope.data.distance = googleAddressUtil.parseAddressesDistance(response);
                                    $scope.save();
                                });
                            } else {
                                $scope.save();
                            }
                        };

                        $scope.save = function () {
                            $scope.isAccommodationTimeIntersect();
                            if (!$scope.accommodationOverlapped) {
                                $scope.inProgress = true;
                                if (
                                    $scope.includeVat &&
                                    $scope.offer.vatIncludedInPrice &&
                                    $scope.layout === 'supplier'
                                ) {
                                    $scope.data.price = amountAndPriceSupplierService.getPriceWithOutVat(
                                        $scope.data.priceInclVat,
                                        $scope.data.vat
                                    );
                                    $scope.data.newPriceInclVat = $scope.data.priceInclVat;
                                }
                                var data = angular.copy($scope.data);
                                data.dateCreated = $scope.event.fromDate + data.dateTimeFrom;
                                data.requestOffer = $scope.requestOffer;
                                $rootScope.offerPartEditId = 0;
                                $rootScope.ROPEditId = 0;
                                $scope.saveCallback(data);
                            } else {
                                if ($scope.layout === 'supplier') {
                                    $scope.$emit('accommodation.overlapped.supplier', {
                                        accommodationOverlapped: $scope.accommodationOverlapped,
                                    });
                                } else {
                                    $scope.$emit('accommodation.overlapped', {
                                        accommodationOverlapped: $scope.accommodationOverlapped,
                                    });
                                }
                            }
                            //$scope.data = null;
                        };

                        $scope.isAccommodationTimeIntersect = function () {
                            let _selectedOfferPartOptions = _.filter($scope.offer.offerParts, (part) => {
                                return part.status.name !== 'NOT_DELIVERABLE';
                            });
                            let _accommodationParts = _.filter(_selectedOfferPartOptions, (part) => {
                                return (
                                    (part.category && part.category.name === 'ACCOMMODATION') ||
                                    part.offerPartCategory === 'ACCOMMODATION'
                                );
                            });
                            let _rooms = _.filter(_accommodationParts, (_part) => {
                                return (
                                    _part.name === $scope.data.name ||
                                    (_part.nameCode && _part.nameCode === $scope.data.nameCode)
                                );
                            });
                            let _existingIndex = _.findIndex(_rooms, { id: $scope.data.id });
                            if (_existingIndex > -1) {
                                _.extend(_.findWhere(_rooms, { id: $scope.data.id }), $scope.data);
                            } else {
                                _rooms.push($scope.data);
                            }
                            let _accommodations = _.sortBy(_rooms, (part) => {
                                return part.dateTimeFrom;
                            });
                            $scope.accommodationOverlapped = false;
                            for (let i = 0; i < _accommodations.length; i++) {
                                let _current = _accommodations[i];
                                let _next = i < _accommodations.length - 1 ? _accommodations[i + 1] : null;
                                if (
                                    _next &&
                                    _next.dateTimeFrom &&
                                    _next.dateTimeFrom < _current.dateTimeTo &&
                                    !$scope.accommodationOverlapped
                                ) {
                                    $scope.accommodationOverlapped = true;
                                    return;
                                }
                            }
                        };

                        $scope.notifyBeforeUpdate = function (requestId) {
                            if (requestId > 0) {
                                $scope.startProgress(requestId);
                                Request.getStructure(
                                    requestId,
                                    function (data) {
                                        if (data.requestOffers && data.requestOffers[0].offerParts) {
                                            if (
                                                CommonFunctions.accessDeniedToUpdate(
                                                    data.requestOffers[0].offerParts[0]
                                                )
                                            ) {
                                                let settings = {
                                                    message: 'request.offer.part.adding.new.row.alert.denied',
                                                    okCallBack: $scope.closeOpenedRow,
                                                    cancelCallBack: $scope.closeOpenedRow,
                                                };
                                                $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, settings);
                                            } else if (
                                                CommonFunctions.doAlertBeforeUpdate(data.requestOffers[0].offerParts[0])
                                            ) {
                                                let settings = {
                                                    message: 'request.offer.part.adding.new.row.alert',
                                                    cancelCallBack: $scope.closeOpenedRow,
                                                };
                                                $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, settings);
                                            }
                                        }
                                        $scope.endProgress(requestId);
                                    },
                                    $scope.endWithAlert(requestId)
                                );
                            }
                        };

                        $scope.closeOpenedRow = function () {
                            $rootScope.offerPartEditId = 0;
                            $rootScope.ROPEditId = 0;
                        };

                        $scope.checkForVatValueLimit = function (data) {
                            if (data.vat && data.vat.length >= 3 && !data.vat.includes('.')) {
                                data.vat = Math.floor(data.vat / 10);
                            }
                            if (data.vat == '' || data.vat == null) {
                                data.vat = 0;
                            }
                        };

                        $scope.$watch('data.request', function (value) {
                            if (value == 0) {
                                $scope.showNewRequestName = true;
                                $scope.data.newRequestName = '';
                            } else {
                                $scope.showNewRequestName = false;
                            }
                        });

                        $scope.$watch('data.name', function (value) {
                            if ($scope.offerPart && $scope.data) {
                                var offerPartTranslatedName = CommonFunctions.getTranslatedTextFromCode(
                                    $scope.offerPart.name,
                                    $scope.offerPart.nameCode
                                );
                                $scope.data.nameCode =
                                    value == offerPartTranslatedName ? $scope.offerPart.nameCode : null;
                            }
                        });

                        $scope.getAdditionalDetails = function (nameCode, partId) {
                            if ($scope.layout === 'supplier') {
                                return;
                            }
                            if (!nameCode) {
                                if ($scope.data) {
                                    $scope.data.list = null;
                                }
                            }
                            FilledRequestOfferPartComment.list(
                                'OFFER_PART_LEVEL',
                                nameCode,
                                partId,
                                (resp) => {
                                    $scope.data.list = resp;
                                    angular.forEach($scope.data.list, (list) => {
                                        if (
                                            ['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(
                                                list.type.name
                                            ) > -1
                                        ) {
                                            let _listValuesCode = list.listValueCodes;
                                            let _listValues = $translate.instant(_listValuesCode);
                                            let _fieldValue;
                                            if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                                                _fieldValue = false;
                                            } else {
                                                _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                                            }
                                            list.fieldValue = DynamicFormUtilService.getFieldValue(
                                                _listValues,
                                                $rootScope.langSelected,
                                                _fieldValue
                                            );
                                        }
                                        if (['ICON_RADIO'].indexOf(list.type.name) > -1) {
                                            let _iconList = list.iconList;
                                            angular.forEach(_iconList, function (icon) {
                                                icon.name = $translate.instant(icon.nameCode);
                                            });
                                        }
                                    });
                                },
                                function (e) {
                                    console.log('error ', e);
                                }
                            );
                        };

                        $scope.partTypeChanged = function () {
                            var typeId = $scope.data.partType.id;
                            if (typeId != '') {
                                var type;
                                if (type) {
                                    $scope.data.name = type.name;
                                    $scope.data.description = type.description;
                                    $scope.data.amountType = type.amountType;
                                }
                            }
                        };

                        $scope.updateVat = function (vat) {
                            $scope.data.vat = vat;
                        };

                        $scope.updateSelectedPartName = function (
                            name,
                            nameCode,
                            offerPartCategory = null,
                            isSuggestionEnabled = false
                        ) {
                            $scope.data.name = name;
                            $scope.data.nameCode = nameCode;
                            $scope.data.translatedName = name;
                            $scope.data.offerPartCategory = offerPartCategory;
                            $scope.data.isSuggestionEnabled = isSuggestionEnabled;
                            $scope.getAdditionalDetails(nameCode, this.data.id);
                            if (offerPartCategory == 'ACCOMMODATION') {
                                $scope.hoursToAdd = 0;
                                let _startIndex = AccommodationUtil.getDayIndex($scope.data.dateTimeFrom);
                                let _fromDate = AccommodationUtil.getDateTimeFrom(_startIndex);
                                let _endDate = AccommodationUtil.getDateTimeTo(_startIndex);
                                $scope.data.dateTimeFrom = _fromDate;
                                $scope.data.dateTimeTo = _endDate;
                                $scope.isAccommodation = true;
                            } else {
                                $scope.hoursToAdd = 1;
                                $scope.isAccommodation = false;
                            }
                        };

                        $scope.init();

                        /* PART TYPE FIELD */

                        $scope.blurred = true;
                        $scope.selectedIndex = -1;

                        $scope.onPartTypeClicked = function (index) {
                            $scope.selectedIndex = index;
                            $scope.onSelectOrClear();
                        };

                        $scope.onPartTypeKeyDown = function ($event) {
                            if ($event.which == 38 && $scope.selectedIndex > 0) {
                                $scope.selectedIndex--;
                                $event.stopPropagation();
                            } else if ($event.which == 13 || $event.which == 9) {
                                $scope.onSelectOrClear();
                                return true;
                            } else if ($event.which != 38 && $event.which != 40 && $event.which != 9) {
                                $scope.searching = false;
                                $scope.selectedIndex = -1;
                            }
                        };

                        $scope.isOfferInCanceledState = function (offer) {
                            return CommonFunctions.isItCanceledOffer(offer);
                        };

                        //TODO(KB) this method seems to be never reachable, check and remove
                        $scope.onSelectOrClear = function () {
                            if ($scope.selectedIndex < 0) {
                                $scope.data.partType = null;
                            }

                            $scope.blurred = true;
                            $timeout(function () {
                                jQuery('#requestOfferpartAmount').focus();
                            });
                        };

                        var partTypeClicked = false;

                        jQuery('#typeahead-part-type-input').blur(function () {
                            $timeout(function () {
                                if (!$scope.blurred && partTypeClicked == false) {
                                    $scope.blurred = true;
                                }

                                partTypeClicked = false;
                            }, 200);
                        });

                        jQuery('#typeahead-part-type-input').focus(function () {
                            $timeout(function () {
                                if ($scope.blurred) {
                                    $scope.blurred = false;
                                }
                            });
                        });

                        $scope.isAmountInvalid = function () {
                            if ($scope.data && !$scope.data.amount) {
                                return true;
                            }
                        };

                        $scope.saveDisabled = function () {
                            if (!$scope.isTimePairValid && !$scope.data.timeless) {
                                return true;
                            }
                            if ($scope.data && $scope.data.editable && $scope.data.editable.name === 'DENIED') {
                                return true;
                            }
                            if ($scope.additionalInfoUpdated) {
                                if (!$scope.checkAdditionalQuestionRequiredFields()) {
                                    return false;
                                }
                            }
                            if ($scope.data && $scope.data.list) {
                                return DynamicFormUtilService.isRequiredQuestionUnAnswered(this.data.list);
                            }
                            if ($scope.data && $scope.data.id) {
                                var nameCodeChanged =
                                    $scope.originalData.nameCode != null &&
                                    $scope.data.nameCode != $scope.originalData.nameCode;
                                var nameChanged =
                                    $scope.originalData.nameCode == null &&
                                    $scope.data.name != $scope.originalData.name;
                                var dataHasChanged =
                                    nameCodeChanged ||
                                    nameChanged ||
                                    $scope.data.amount != $scope.originalData.amount ||
                                    $scope.data.commentByCreator != $scope.originalData.commentByCreator ||
                                    $scope.data.amountType.name != $scope.originalData.amountType.name ||
                                    $scope.data.dateTimeFrom != $scope.originalData.dateTimeFrom ||
                                    $scope.data.dateTimeTo != $scope.originalData.dateTimeTo ||
                                    $scope.data.timeless != $scope.originalData.timeless ||
                                    ($scope.layout == 'supplier' && $scope.data.price != $scope.originalData.price) ||
                                    ($scope.layout == 'supplier' &&
                                        $scope.data.priceInclVat != $scope.originalData.priceInclVat) ||
                                    ($scope.layout == 'supplier' && $scope.data.vat != $scope.originalData.vat);
                                return !dataHasChanged;
                            } else {
                                //if there is no participantAmount and multiplicator = people (same as the event participant amount)
                                // save button was disabled issue fixed
                                if (
                                    $scope.data &&
                                    $scope.data.name &&
                                    ($scope.data.request || !$scope.selectRequest) &&
                                    ($scope.event.participantAmount &&
                                        $scope.data.amountType &&
                                        $scope.data.amountType.name != 'SAME_AS_EVENT'
                                        ? $scope.data.amount
                                        : true)
                                ) {
                                    if ($scope.showNewRequestName) {
                                        if (!$scope.data.newRequestName) {
                                            return true;
                                        }
                                    }
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        };

                        $scope.checkAdditionalQuestionRequiredFields = function () {
                            if ($scope.data && $scope.data.list) {
                                let isRequiredFieldUnanswered = false;
                                angular.forEach($scope.data.list, (list) => {
                                    if (list.type.name !== 'TEXT_WITH_AMOUNT_TYPE') {
                                        let _strLogicToShow = list.showHide;
                                        if (!_strLogicToShow) {
                                            if (list.required && !list.fieldValue) {
                                                isRequiredFieldUnanswered = true;
                                            }
                                        } else {
                                            let stringArray = _strLogicToShow.split('~');
                                            let shouldShow = stringArray[0] === 'SHOWON';
                                            let shouldHide = stringArray[0] === 'HIDEON';
                                            let _reference = stringArray[1];
                                            if (shouldShow) {
                                                let _question = _.find($scope.data.list, (item) => {
                                                    return item.field === _reference;
                                                });
                                                if (_question.fieldValue) {
                                                    if (list.required && !list.fieldValue) {
                                                        isRequiredFieldUnanswered = true;
                                                    }
                                                }
                                            }
                                            if (shouldHide) {
                                                let _question = _.find($scope.data.list, (item) => {
                                                    return item.field === _reference;
                                                });
                                                if (!_question.fieldValue) {
                                                    if (list.required && !list.fieldValue) {
                                                        isRequiredFieldUnanswered = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                });
                                return isRequiredFieldUnanswered;
                            }
                        };

                        $scope.getOfferPartAdditionalQuestionClass = function (resourceData) {
                            if (resourceData && resourceData.nameCode) {
                                switch (resourceData.nameCode) {
                                    case 'common.translate.offer.part.type.bus.(outward.journey)':
                                    case 'common.translate.offer.part.type.bus.(home.journey)':
                                    case 'common.translate.offer.part.type.bus.trip':
                                        return 'bus-transport-form';
                                        break;
                                    default:
                                        break;
                                }
                            }
                        };

                        $scope.isOfferInFromBookedState = function (offer) {
                            return (
                                offer &&
                                offer.status &&
                                RequestOfferStatusUtils.OFFER_FROM_BOOKED_TO_APPROVED.indexOf(offer.status.name) > -1
                            );
                        };

                        $scope.openAccommodationModal = function () {
                            $scope.accommodationCallback();
                        };
                    },
                ],
                link: function () { },
            };
        },
    ]);
})();
