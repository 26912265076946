import controller from './og_offer_price_compare.controller.js';
(function () {
    'use strict';
    angular.module('ogOfferPriceCompare').directive('ogOfferPriceCompare', [
        function () {
            return {
                scope: {
                    offerParts: '=',
                    requestOffers: '=',
                    selectedOffer: '=',
                },
                templateUrl: './app/components/directives/offer_price_compare/og_offer_price_compare.html',
                restrict: 'AE',
                controller,
                controllerAs: 'offerPriceCompareController',
                bindToController: true,
            };
        },
    ]);
})();
