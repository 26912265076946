import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';


angular.module('shared.event.presentation').component('eventPresentation', {
    templateUrl: './app/components/modules/shared/event_presentation/event_presentation.view.html',
    controller: [
        '$q',
        '$scope',
        '$rootScope',
        '$state',
        '$stateParams',
        '$location',
        'EventPresentation',
        'Survey',
        'EventSite',

        function EventPresentationRoController(
            $q,
            $scope,
            $rootScope,
            $state,
            $stateParams,
            $location,
            EventPresentation,
            Survey,
            EventSite
        ) {
            var vm = this;

            vm.goForRegistrationFromPublicSite = goForRegistrationFromPublicSiteFn;
            vm.setActiveTab = setActiveTabFn;
            vm.hideLandingPage = hideLandingPageFn;
            vm.activeTab = '';
            vm.hash = $stateParams.hash;
            vm.surveyRating = false;
            var fromPublicSite = true;
            vm.english = false;
            vm.swedish = false;
            vm.norwegian = false;
            vm.danish = false;
            vm.finnish = false;
            vm.showPresentationDetails = true;
            vm.init = initFn;
            vm.getEventDetails = getEventDetailsFn;

            getEventDetailsFn();

            /*angular.element('body').addClass('fullscreen');*/
            ScopeFunctions.addToScope($scope);

            function initFn() {
                if ($stateParams.participate == 'true') {
                    vm.acceptParticipation();
                }
                vm.showParticipateButton = $stateParams.participate != 'false';
                window.localStorage.removeItem('participantHash');
                $scope.startProgress();
                EventPresentation.getPresentationPageByUrlSlug(
                    {urlSlug: $stateParams.urlSlug, requestFrom: 'infoSite'},
                    function (response) {
                        updatePresentationDetails(response);
                        vm.selectedLang = response.locale;
                        $rootScope.switchLang(response.locale, true);
                        $scope.endProgress();
                    },
                    $scope.endWithErrorOverlay
                );
                setTab();
                isSurveyRated();
            }

            function getEventDetailsFn() {
                EventSite.getTypeFromUrlName(
                    {urlSlug: $stateParams.urlSlug},
                    function successCallback(res) {
                        $rootScope.switchLang(res.lang, true, $stateParams.hash);
                        vm.eventName = res.eventName;
                        vm.eventId = res.eventId;
                        vm.sharePublicly = res.sharePublicly;
                        vm.type = res.type;
                        if (window.localStorage[vm.eventId]) {
                            initFn();
                        } else {
                            vm.showLandingPage = true;
                        }
                    },
                    function errorCallback() {
                        $state.go('link.expired');
                    }
                );
            }

            function hideLandingPageFn(eventId) {
                vm.showLandingPage = false;
                window.localStorage.setItem(eventId, true);
                initFn();
            }

            function goForRegistrationFromPublicSiteFn() {
                setActiveTabFn('registration');
            }

            function isSurveyRated() {
                Survey.forParticipant(
                    {hash: vm.hash},
                    (response) => {
                        if (response.survey) {
                            vm.surveyRating = true;
                        } else {
                            vm.surveyRating = false;
                        }
                    },
                    $scope.endWithAlert
                );
            }

            function setTab() {
                switch ($state.current.name) {
                    case 'participant_details.registration':
                        vm.activeTab = 'registration';
                        break;
                    case 'participant_details.event_details':
                        vm.activeTab = 'event_details';
                        break;
                    case 'participant_details.ratings':
                        vm.activeTab = 'ratings';
                        break;
                    case 'participant_details.invitation':
                        vm.activeTab = 'invitation';
                        break;
                    default:
                        vm.activeTab = 'event_details';
                }
            }

            function updatePresentationDetails(response) {
                vm.eventPresentation = response.eventPresentationDetails.eventPresentation;
                vm.presentation = response.eventPresentationDetails.slide;
                vm.presentationImages = response.eventPresentationDetails.slide.presentationImages;
                vm.showMapInInvitation = {checked: response.eventPresentationDetails.eventPresentation.showMap};
                vm.invitationDetails = response.invitationDetails;
                vm.participantDefaultLocale = response.locale;
                vm.participantSupportLocale = response.participantSupportLocale.split('~');
                if (vm.participantDefaultLocale === 'en_US' || vm.participantSupportLocale[0] === 'en_US') {
                    vm.english = true;
                }
                if (vm.participantDefaultLocale === 'sv_SV' || vm.participantSupportLocale[1] === 'sv_SV') {
                    vm.swedish = true;
                }
                if (vm.participantDefaultLocale === 'nb_NB' || vm.participantSupportLocale[2] === 'nb_NB') {
                    vm.norwegian = true;
                }
                if (vm.participantDefaultLocale === 'da_DK' || vm.participantSupportLocale[3] === 'da_DK') {
                    vm.danish = true;
                }
                if (vm.participantDefaultLocale === 'fi_FI' || vm.participantSupportLocale[4] === 'fi_FI') {
                    vm.finnish = true;
                }
            }

            vm.switchLanguage = function (lang) {
                vm.selectedLang = lang;
                $rootScope.switchLang(lang, true, $stateParams.hash, fromPublicSite);
                $location.search('lang', null);
            };

            function generateHash() {
                var deferred = $q.defer();
                if (vm.hash === undefined || !vm.hash) {
                    EventPresentation.externalAccessBySlug(
                        {urlSlug: $stateParams.urlSlug},
                        (response) => {
                            var exAccess = angular.copy(response);
                            vm.hash = exAccess.hash;
                            deferred.resolve(response);
                        },
                        (err) => {
                            $scope.endWithErrorOverlay(err);
                            deferred.reject('PAGE_NOT_FOUND');
                        }
                    );
                }
                return deferred.promise;
            }

            function setActiveTabFn(tab) {
                if (vm.sharePublicly && vm.type === 'EVENT_PRESENTATION') {
                    vm.activeTab = tab;
                    vm.showPresentationDetails = true;
                    let url;
                    switch (tab) {
                        case 'registration':
                            vm.showPresentationDetails = false;
                            url = 'shared.presentation.registration';
                            break;

                        case 'event_details':
                            url = 'shared.presentation';
                            break;
                        default:
                            url = 'shared.presentation';
                    }
                    $state.go(url);
                } else {
                    generateHash().then(() => {
                        vm.activeTab = tab;
                        vm.showPresentationDetails = true;
                        let url;
                        switch (tab) {
                            case 'register':
                                url = 'participant_details.register';
                                break;
                            case 'registration':
                                url = 'participant_details.registration';
                                break;
                            case 'event_details':
                                url = 'participant_details.event_details';
                                break;
                            case 'ratings':
                                url = 'participant_details.ratings';
                                break;
                            case 'invitation':
                                url = 'participant_details.invitation';
                                break;
                            default:
                                url = 'participant_details.event_details';
                        }
                        $state.go(url, {hash: vm.hash});
                    });
                }
            }
        }]
})
