class OgRichTextEditorController {
    constructor($scope, $rootScope, GlobalConstants, FileService, $timeout, $element, $compile, $translate) {
        this._injected = { $scope, $rootScope, GlobalConstants, FileService, $timeout, $element, $compile, $translate };
        let language = this._determineLang();
        this.$onInit = function () {
            let plugins;
            if (this.imageUpload) {
                plugins = 'advlist autolink link lists charmap print preview image paste textcolor code';
            } else {
                plugins = 'advlist autolink link lists charmap print preview paste textcolor code';
            }

            this.tinymceOptions = {
                language: language,
                height: 300,
                inline: false,
                plugins: plugins,
                paste_retain_style_properties: 'all',
                paste_remove_styles: false,
                paste_remove_styles_if_webkit: false,
                toolbar: defaultToolbar,
                paste_data_images: true,
                file_picker_types: 'image',
                skin: 'lightgray',
                cache_suffix: '?v=4.1.6',
                theme: 'modern',
                file_picker_callback: imagePickerCallback,
                automatic_uploads: false,
                /*images_upload_url: 'api/v1/file/upload',*/
                //below config to fix the relative path issue
                //http://stackoverflow.com/questions/3796942/get-tinymce-to-use-full-image-url-instead-of-relative-one
                relative_urls: false,
                remove_script_host: false,
                convert_urls: true,
                browser_spellcheck: true,
                contextmenu: false,
                //Default image display in center
                /*content_style: ".mce-content-body>p{text-align: center;} .mce-content-body>p>img {display: inline-block;}",*/
                formats: {
                    alignleft: {
                        selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table',
                        styles: {
                            textAlign: 'left',
                        },
                    },
                    aligncenter: {
                        selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table',
                        styles: {
                            textAlign: 'center',
                            listStylePosition: 'inside',
                        },
                    },
                    alignright: {
                        selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table',
                        styles: {
                            textAlign: 'right',
                            listStylePosition: 'inside',
                        },
                    },
                    removeformat: [
                        {
                            selector: 'b,strong,em,i,font,u,strike,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins',
                            remove: 'all',
                            split: true,
                            block_expand: true,
                            expand: false,
                            deep: true,
                        },
                        {
                            selector: 'span',
                            attributes: ['style', 'class'],
                            remove: 'empty',
                            split: true,
                            expand: false,
                            deep: true,
                        },
                        { selector: '*', attributes: ['style', 'class'], split: false, expand: false, deep: true },
                    ],
                },
            };
        }
        let imagePickerCallback = (cb) => {
            let input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            // Note: In modern browsers input[type="file"] is functional without
            // even adding it to the DOM, but that might not be the case in some older
            // or quirky browsers like IE, so you might want to add it to the DOM
            // just in case, and visually hide it. And do not forget do remove it
            // once you do not need it anymore.

            input.onchange = function () {
                let file = this.files[0];
                //Below is custom code to display the upload status

                /**
                 * Code for upload status starts
                 */
                // get the popup. Assuming there is only one with this class active at the moment.
                let mceWindowElements = document.getElementsByClassName('mce-window');
                let mceWindowElementId = mceWindowElements[0].id;
                let imageImportBodyId = `${mceWindowElementId}-body`;
                let fileSelectContainerBody = document.getElementById(imageImportBodyId);
                let fileSelectContainerBodyElement = angular.element(fileSelectContainerBody);
                let uploadingText = $translate.instant('tinyMce.uploading');
                let uploadingTextElem =
                    angular.element(`<div style="padding: 0 20px 0 0; text-align: right; color: purple;">
                                                   ${uploadingText}
                                                  </div>`);
                fileSelectContainerBodyElement.prepend(uploadingTextElem);
                $compile(fileSelectContainerBody)($scope);
                /**
                 * Code for upload status ends
                 */
                FileService.uploadFile(
                    file,
                    (successResponse) => {
                        uploadingTextElem.remove();
                        cb(successResponse.url, { title: file.name });
                    },
                    (errorCallBack) => {
                        $scope.endProgress();
                        console.log(errorCallBack);
                    }
                );
            };
            input.click();
        };

        let defaultToolbar =
            'undo redo | styleselect fontselect fontsizeselect | bold italic forecolor backcolor | ' +
            'alignleft aligncenter alignright alignjustify | ' +
            'bullist numlist outdent indent | link image | code removeformat';

        $scope.$on('lang:change', () => {
            this.tinymceOptions.language = this._determineLang();
            $scope.$broadcast('$tinymce:refresh');
        });
    }

    _determineLang() {
        let { $rootScope, GlobalConstants } = this._injected;
        return GlobalConstants.LANG_SWEDISH === $rootScope.langSelected ? 'sv_SE' : GlobalConstants.LANG_ENGLISH;
    }
}

OgRichTextEditorController.$inject = [
    '$scope',
    '$rootScope',
    'GlobalConstants',
    'FileService',
    '$timeout',
    '$element',
    '$compile',
    '$translate',
];
export default OgRichTextEditorController;
