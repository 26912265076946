import controller from './og_offer_part_option_dropdown.controller';

(function () {
    'use strict';

    angular.module('ogOfferPartOptionDropdown').directive('ogOfferPartOptionDropdown', [
        function () {
            return {
                scope: {
                    placeholder: '@',
                    list: '=',
                    selected: '=',
                    isDisabled: '=',
                    required: '=',
                    remoteUrl: '=',
                    uniqueId: '=',
                    inputClass: '@',
                    minLength: '@',
                    updateSelectedPartNameCallback: '&',
                },
                templateUrl: './app/components/directives/og-offer-part-option-dropdown/og_offer_part_option_dropdown.html',
                restrict: 'AE',
                controller,
                controllerAs: 'OgOfferPartOptionDropdownCtrl',
                bindToController: true,
            };
        },
    ]);
})();
