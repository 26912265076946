import requestOfferPartOptionsUtil from '../../../utils/request_offer_parts/RequestOfferPartOptionsUtil';
import { ScopeFunctions } from '../../../utils/global/scopeFunctions';
import _ from 'underscore';

const EDITED_BY_CREATOR_PART_OPTION_STATE = ['EDITED_BY_CREATOR'];

(function () {
    'use strict';

    angular.module('ogRequestOfferPartOptionsCreator').directive('ogRequestOfferPartOptionsCreator', [
        '$log',
        '$routeParams',
        '$rootScope',
        '$timeout',
        'ModalService',
        'RequestOfferPartOption',
        'GlobalConstants',
        'RequestOfferStatusUtils',
        'RequestOffer',
        function (
            $log,
            $routeParams,
            $rootScope,
            $timeout,
            ModalService,
            RequestOfferPartOption,
            GlobalConstants,
            RequestOfferStatusUtils,
            RequestOffer
        ) {
            return {
                restrict: 'EA',
                scope: {
                    event: '=',
                    supplierId: '=',
                    offer: '=',
                    layout: '=',
                    offerPart: '=',
                    includeVat: '=',
                    selectedCurrency: '=',
                    offerPartOptionsWithActions: '=',
                    offerPartOptionsWithActionsCallback: '=',
                    showPaidOnSiteCol: '=',
                },
                templateUrl:
                    './app/components/directives/request_offer_part_options_creator/request_offer_part_options_creator.directive.view.html',
                controller: [
                    '$scope',
                    function ($scope) {
                        ScopeFunctions.addToScope($scope);
                        $scope.toggleNonSelectedItem = false;

                        $scope.init = function () {
                            RequestOfferPartOption.getAllOptionsForPart(
                                $scope.offerPart.id,
                                (resp) => {
                                    $scope.allOptionsForPart = requestOfferPartOptionsUtil.filterOfferPartOptions(
                                        $scope.offer,
                                        resp
                                    );
                                    $scope.allOptionsForPart.forEach((offerPartOption) => {
                                        if ($scope.offer.status.name === 'FINISHED_WAITING_FOR_SUPPLIER') {
                                            offerPartOption.finalPaidOnsite = false;
                                        }
                                    });
                                    $scope.originalOfferPartOptionsList = angular.copy(resp);
                                    $scope.setPartOptionNewPrice();
                                    $scope.setFinalPriceAndAmount();
                                    $scope.updateAllOfferPartOptionsAreSelected(true);
                                },
                                $scope.endWithErrorOverlay()
                            );
                        };

                        $scope.openInlineRowOptionAddition = function (offerPartId) {
                            $rootScope.ROPOAddEditId = 0;
                            $timeout(function () {
                                $rootScope.ROPOAddEditId = 'a' + offerPartId;
                            }, 100);
                        };

                        $scope.addANewOfferPartOption = function (requestOfferPartOption) {
                            RequestOfferPartOption.create(
                                $scope.supplierId,
                                requestOfferPartOption,
                                (resp) => {
                                    console.log('Response After Save>>>>', resp);
                                    $scope.close();
                                },
                                $scope.endWithErrorOverlay()
                            );
                        };

                        $scope.updateANewOfferPartOption = function (requestOfferPartOption) {
                            $scope.startProgress();
                            requestOfferPartOption.sectionUpdated = 'ALL';
                            let _data = {
                                requestOfferPartOptionId: requestOfferPartOption.id,
                                supplierId: $scope.supplierId,
                            };
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
                                $scope.updateOfferPartOptionOnClientSideOnly(requestOfferPartOption);
                                $scope.endProgress();
                            } else {
                                if ($scope.offer.statusDetailed.name === 'BOOKED_LOCKED_BY_SUPPLIER') {
                                    $scope.unlockBookedLockedBySupplierAndApplyCreatorChanges(
                                        $scope.offer,
                                        _data,
                                        requestOfferPartOption
                                    );
                                    $scope.endProgress();
                                } else {
                                    RequestOfferPartOption.update(
                                        _data,
                                        requestOfferPartOption,
                                        (resp) => {
                                            if (resp) {
                                                $rootScope.$broadcast('request:offer:part:option:updated', {
                                                    offer: resp.requestOffer,
                                                    offerPartOption: resp.requestOfferPartOption,
                                                });
                                                $scope.init();
                                                $scope.endProgress();
                                            }
                                        },
                                        $scope.endWithErrorOverlay()
                                    );
                                }
                            }
                        };

                        $scope.deleteOfferPartOption = function (requestOfferPartOption) {
                            $scope.startProgress();
                            let _data = {
                                requestOfferPartOptionId: requestOfferPartOption.id,
                                supplierId: $scope.supplierId,
                            };
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
                                $scope.updateOfferPartOptionOnClientSideOnly(requestOfferPartOption);
                                let offerPartOptionMap = { offerPartOption: requestOfferPartOption, action: 'DELETED_BY_CREATOR' };
                                $scope.removeOfferPartOptionFromMap(requestOfferPartOption);
                                requestOfferPartOption.status.name = 'DELETED_WAITING_FOR_SUPPLIER';
                                $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);
                                var originalOfferPartOption = $scope.getOriginalOfferPartOption(requestOfferPartOption);
                                $scope.reassignOriginalValuesToOfferPartOption(
                                    requestOfferPartOption,
                                    originalOfferPartOption
                                );

                                angular.forEach($scope.allOptionsForPart, function (partOption) {
                                    if (partOption.id === requestOfferPartOption.id) {
                                        partOption.oldAmount = originalOfferPartOption.oldAmount;
                                        partOption.oldAmountType = originalOfferPartOption.oldAmountType;
                                        partOption.oldDateTimeFrom = originalOfferPartOption.oldDateTimeFrom;
                                        partOption.oldDateTimeTo = originalOfferPartOption.oldDateTimeTo;
                                        partOption.oldPrice = originalOfferPartOption.oldPrice;
                                        partOption.status.name = 'DELETED_WAITING_FOR_SUPPLIER';
                                    }
                                });
                                $scope.endProgress();
                                $rootScope.$broadcast('request:offer:part:option:deleted:client:side:only:creator', {
                                    offerPartOption: requestOfferPartOption,
                                });
                            } else {
                                if ($scope.offer.statusDetailed.name === 'BOOKED_LOCKED_BY_SUPPLIER') {
                                    $scope.unlockBookedLockedBySupplierAndApplyCreatorChanges(
                                        $scope.offer,
                                        _data,
                                        requestOfferPartOption.id
                                    );
                                    $scope.endProgress();
                                } else {
                                    RequestOfferPartOption.delete(
                                        requestOfferPartOption.id,
                                        (resp) => {
                                            $rootScope.$broadcast('request:offer:part:option:deleted', {
                                                offer: resp.requestOffer,
                                                offerPartOptions: resp.allOptionsForPart,
                                            });
                                            $scope.allOptionsForPart = requestOfferPartOptionsUtil.filterOfferPartOptions(
                                                $scope.offer,
                                                resp.allOptionsForPart
                                            );
                                            $scope.setPartOptionNewPrice();
                                            $scope.originalOfferPartOptionsList = angular.copy(resp);
                                            $scope.endProgress();
                                        },
                                        $scope.endWithErrorOverlay()
                                    );
                                }
                            }
                        };

                        $scope.reassignOriginalValuesToOfferPartOption = function (
                            offerPartOption,
                            originalOfferPartOption
                        ) {
                            offerPartOption.amount = originalOfferPartOption.amount;
                            offerPartOption.amountType.name = originalOfferPartOption.amountType.name;
                            offerPartOption.dateTimeFrom = originalOfferPartOption.dateTimeFrom;
                            offerPartOption.dateTimeTo = originalOfferPartOption.dateTimeTo;
                            offerPartOption.newPrice = originalOfferPartOption.price;
                            offerPartOption.price = originalOfferPartOption.price;
                        };

                        $scope.unlockBookedLockedBySupplierAndApplyCreatorChanges = function (
                            offer,
                            _data,
                            requestOfferPartOption
                        ) {
                            RequestOffer.unlockBookedLockedBySupplier(
                                $scope.offer.id,
                                function () {
                                    RequestOfferPartOption.update(
                                        _data,
                                        requestOfferPartOption,
                                        (resp) => {
                                            $rootScope.$broadcast('request:offer:part:option:updated', {
                                                offer: resp.requestOffer,
                                            });
                                        },
                                        $scope.endWithErrorOverlay()
                                    );
                                },
                                $scope.endWithErrorOverlay()
                            );
                        };

                        $scope.updateOfferPartOptionOnClientSideOnly = function (offerPartOption) {
                            let originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                            if ($scope.isOfferPartOptionInCreatorWaitingStates(offerPartOption)) {
                                $scope.allOptionsForPart.forEach((n) => {
                                    if (n.id === offerPartOption.id) {
                                        n = $scope.storeOfferPartOptionValues(
                                            originalOfferPartOption,
                                            offerPartOption,
                                            n
                                        );
                                        if (n.status.name !== 'ADDED_WAITING_FOR_CREATOR') {
                                            n.status.name = 'EDITED_WAITING_FOR_SUPPLIER';
                                            offerPartOption.status.name = 'EDITED_WAITING_FOR_SUPPLIER';
                                        } else {
                                            n.status.name = 'ACCEPTED_BY_CREATOR_WITH_CHANGES';
                                            offerPartOption.status.name = 'ACCEPTED_BY_CREATOR_WITH_CHANGES';
                                        }
                                        $scope.$broadcast('request:offer:part:option:updated:client:side', {
                                            offerPartOption: n,
                                        });
                                    }
                                });
                                if ($scope.checkIfFieldValueChanged(offerPartOption, originalOfferPartOption)) {
                                    let offerPartOptionMap = {
                                        offerPartOption: offerPartOption,
                                        action: 'ACCEPTED_BY_CREATOR_WITH_CHANGES',
                                    };
                                    $scope.removeOfferPartOptionFromMap(offerPartOption);
                                    //$scope.offerPartOptionsWithActions.push(offerPartOptionMap);
                                    $scope.flagForAcceptedWithChanges = 'edit' + offerPartOption.id;
                                    $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);
                                    $scope.checkIfActionHasBeenTakenForAllSupplierChanges();
                                    $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);
                                } else {
                                    $scope.removeOfferPartOptionFromMap(offerPartOption);
                                    this.flagForAcceptedWithChanges = undefined;
                                }
                            } else {
                                $scope.allOptionsForPart.forEach((n) => {
                                    if (offerPartOption.id === n.id) {
                                        n = $scope.storeOfferPartOptionValues(
                                            originalOfferPartOption,
                                            offerPartOption,
                                            n
                                        );
                                        if ($scope.checkIfFieldValueChanged(offerPartOption, originalOfferPartOption)) {
                                            if (n.status.name === 'ADDED_WAITING_FOR_SUPPLIER_DECLINED') {
                                                n.status.name = 'ADDED_WAITING_FOR_SUPPLIER';
                                            } else {
                                                n.status.name = 'EDITED_WAITING_FOR_SUPPLIER';
                                            }
                                        } else {
                                            n.status.name = originalOfferPart.status.name;
                                        }
                                        $scope.$broadcast('request:offer:part:option:updated:client:side', {
                                            offerPartOption: n,
                                        });
                                    }
                                });
                                if ($scope.checkIfFieldValueChanged(offerPartOption, originalOfferPartOption)) {
                                    let offerPartOptionMap = {
                                        offerPartOption: offerPartOption,
                                        action: 'EDITED_BY_CREATOR',
                                    };
                                    $scope.removeOfferPartOptionFromMap(offerPartOption);
                                    $scope.offerPartOptionsWithActions.push(offerPartOptionMap);
                                    $scope.flagForAcceptedWithChanges = 'edit' + offerPartOption.id;
                                    $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);
                                } else {
                                    $scope.removeOfferPartOptionFromMap(offerPartOption);
                                    $scope.flagForAcceptedWithChanges = undefined;
                                }
                            }
                        };

                        $scope.isOfferPartOptionInCreatorWaitingStates = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_CREATOR') {
                                return false;
                            }
                            let offerPartOptionIsInCreatorWaitingStates = false;
                            let originalOfferPartOption = $scope.getOriginalOfferPartOption(offerPartOption);
                            GlobalConstants.CREATOR_WAITING_STATES.forEach((state) => {
                                if (!offerPartOptionIsInCreatorWaitingStates) {
                                    if (originalOfferPartOption.status.name === state) {
                                        offerPartOptionIsInCreatorWaitingStates = true;
                                    }
                                }
                            });
                            return offerPartOptionIsInCreatorWaitingStates;
                        };

                        $scope.storeOfferPartOptionValues = function (originalOfferPartOption, offerPartOption, n) {
                            if (n.amount !== offerPartOption.amount) {
                                n.oldAmount = originalOfferPartOption.amount;
                                n.amount = offerPartOption.amount;
                            }
                            if (n.amountType.name !== offerPartOption.amountType.name) {
                                n.oldAmountType = { name: originalOfferPartOption.amountType.name };
                                n.amountType.name = offerPartOption.amountType.name;
                            }
                            if (n.dateTimeFrom !== offerPartOption.dateTimeFrom) {
                                n.oldDateTimeFrom = originalOfferPartOption.dateTimeFrom;
                                n.dateTimeFrom = offerPartOption.dateTimeFrom;
                            }
                            if (n.dateTimeTo !== offerPartOption.dateTimeTo) {
                                n.oldDateTimeTo = originalOfferPartOption.dateTimeTo;
                                n.dateTimeTo = offerPartOption.dateTimeTo;
                            }
                            if (n.price !== offerPartOption.price) {
                                n.oldPrice = originalOfferPartOption.newPrice ? n.newPrice : 0;
                                n.newPrice = offerPartOption.price;
                                n.price = offerPartOption.price;
                            }

                            return n;
                        };

                        $scope.checkIfFieldValueChanged = function (offerPartOption, originalOfferPartOption) {
                            if (!offerPartOption.id) {
                                return true;
                            }
                            return (
                                offerPartOption.amount !== originalOfferPartOption.amount ||
                                offerPartOption.amountType !== originalOfferPartOption.amountType.name ||
                                offerPartOption.dateTimeFrom !== originalOfferPartOption.dateTimeFrom ||
                                offerPartOption.dateTimeTo !== originalOfferPartOption.dateTimeTo
                            );
                        };

                        $scope.setPartOptionNewPrice = function () {
                            angular.forEach($scope.allOptionsForPart, (item) => {
                                item.newPrice = item.price;
                                if (EDITED_BY_CREATOR_PART_OPTION_STATE.indexOf($scope.offer.status.name) > -1) {
                                    item.newPrice = item.creatorPrice;
                                }
                            });
                        };

                        $scope.setFinalPriceAndAmount = function () {
                            angular.forEach($scope.allOptionsForPart, (item) => {
                                //initially final price will not be set, so price is set to final price
                                item.finalPrice = item.finalPrice != null ? item.finalPrice : item.price;
                                item.finalAmount = item.finalAmount != null ? item.finalAmount : item.amount;
                                item.finalPaidOnsite = item.finalPaidOnsite != null ? item.finalPaidOnsite : false;
                            });
                        };

                        $scope.getOriginalOfferPartOption = function (offerPartOption) {
                            let originalOfferPartOption;
                            if (!offerPartOption) {
                                return undefined;
                            }
                            $scope.originalOfferPartOptionsList.forEach((n) => {
                                if (offerPartOption.id === n.id) {
                                    originalOfferPartOption = n;
                                }
                            });
                            return originalOfferPartOption;
                        };

                        $scope.isOfferInBeforeBookedState = function () {
                            return (
                                $scope.offer &&
                                $scope.offer.status &&
                                RequestOfferStatusUtils.ALL_BEFORE_BOOKED_STATUS.indexOf($scope.offer.status.name) > -1
                            );
                        };

                        $scope.showCreatorOptions = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_CREATOR' || $scope.layout !== 'creator') {
                                return false;
                            }
                            if ($scope.offer.statusDetailed.name === 'BOOKED_WAITING_FOR_CREATOR') {
                                return (
                                    $scope.isOfferPartInCreatorWaitingStates(offerPartOption) ||
                                    $scope.isOfferPartInSupplierDeclinedStates(offerPartOption)
                                );
                            }
                        };

                        $scope.isOfferPartInCreatorWaitingStates = function (offerPartOption) {
                            if (offerPartOption.status.name === 'ADDED_BY_CREATOR') {
                                return false;
                            }
                            let offerPartIsInCreatorWaitingStates = false;
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            GlobalConstants.CREATOR_WAITING_STATES.forEach((state) => {
                                if (!offerPartIsInCreatorWaitingStates) {
                                    if (originalOfferPart.status.name === state) {
                                        offerPartIsInCreatorWaitingStates = true;
                                    }
                                }
                            });
                            return offerPartIsInCreatorWaitingStates;
                        };

                        $scope.isOfferPartInSupplierDeclinedStates = function (offerPartOption) {
                            let offerPartIsInSupplierDeclinedStates = false;
                            let originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            GlobalConstants.SUPPLIER_DECLINED_STATES.forEach((state) => {
                                if (!offerPartIsInSupplierDeclinedStates) {
                                    if (originalOfferPart.status.name === state) {
                                        offerPartIsInSupplierDeclinedStates = true;
                                    }
                                }
                            });
                            return offerPartIsInSupplierDeclinedStates;
                        };

                        $scope.acceptOfferPartOption = function (offerPartOption) {
                            let offerPartOptionMap = {
                                offerPartOption: offerPartOption,
                                action: 'ACCEPTED_BY_CREATOR',
                            };
                            $scope.removeOfferPartOptionFromMap(offerPartOption);

                            var originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            //OfferPreviewModalCtrl.reassignOriginalValuesToOfferPart(offerPart, originalOfferPart);
                            if (originalOfferPart.status.name === 'EDITED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'ACCEPTED';
                            }
                            if (originalOfferPart.status.name === 'ADDED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'ACCEPTED';
                            }
                            if (originalOfferPart.status.name === 'DELETED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'NOT_DELIVERABLE';
                            }
                            $scope.flagForAcceptedWithChanges = undefined;
                            $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);

                            //$scope.checkIfActionHasBeenTakenForAllSupplierChanges();
                        };

                        $scope.declineOfferPartOption = function (offerPartOption) {
                            var offerPartOptionMap = {
                                offerPartOption: offerPartOption,
                                action: 'DECLINED_BY_CREATOR',
                            };
                            $scope.removeOfferPartOptionFromMap(offerPartOption);

                            var originalOfferPart = $scope.getOriginalOfferPartOption(offerPartOption);
                            //OfferPreviewModalCtrl.reassignOriginalValuesToOfferPart(offerPartOption, originalOfferPart);
                            if (originalOfferPart.status.name === 'EDITED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'DECLINED';
                            }
                            if (originalOfferPart.status.name === 'ADDED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'ADDED_WAITING_FOR_CREATOR_DECLINED';
                            }
                            if (originalOfferPart.status.name === 'DELETED_WAITING_FOR_CREATOR') {
                                offerPartOption.status.name = 'DELETED_WAITING_FOR_CREATOR_DECLINED';
                            }
                            $scope.flagForAcceptedWithChanges = undefined;
                            $scope.offerPartOptionsWithActionsCallback(offerPartOptionMap);

                            //$scope..checkIfActionHasBeenTakenForAllSupplierChanges();
                        };

                        $scope.checkIfActionHasBeenTakenForAllSupplierChanges = function () {
                            const acceptableOfferPartStatusesToRespondSupplier = [
                                'ACCEPTED',
                                'DECLINED',
                                'EDITED_WAITING_FOR_SUPPLIER',
                                'ADDED_WAITING_FOR_CREATOR_DECLINED',
                                'ACCEPTED_BY_CREATOR_WITH_CHANGES',
                                'NOT_DELIVERABLE',
                                'DELETED_WAITING_FOR_CREATOR_DECLINED',
                                'EDITED_WAITING_FOR_SUPPLIER_DECLINED',
                            ];
                            let offerCanBeSent = $scope.allOptionsForPart.every(
                                (offerPartOption) =>
                                    acceptableOfferPartStatusesToRespondSupplier.indexOf(offerPartOption.status.name) >
                                    -1
                            );
                            if (offerCanBeSent) {
                                $scope.$emit('action:taken:for:all:supplier:changes', { offer: $scope.offer });
                            }
                        };

                        $scope.removeOfferPartOptionFromMap = function (offerPartOption) {
                            angular.forEach($scope.offerPartOptionsWithActions, function (rowAndAction, i) {
                                if (rowAndAction.offerPartOption.id === offerPartOption.id) {
                                    $scope.offerPartOptionsWithActions.splice(i, 1);
                                }
                            });
                        };

                        $scope.toggleOfferPartOptionDisplay = function () {
                            $scope.toggleNonSelectedItem = !$scope.toggleNonSelectedItem;
                            angular.forEach($scope.offerPart.offerPartOptions, (item) => {
                                if (!item.isSelected) {
                                    let item2 = _.find($scope.allOptionsForPart, (o) => {
                                        return o.id === item.id;
                                    });
                                    item2.toggleNonSelectedItem = $scope.toggleNonSelectedItem;
                                }
                            });
                        };

                        $scope.updateAllOfferPartOptionsAreSelected = function (isCalledOnInit) {
                           if(isCalledOnInit) {
                               $scope.allOptionsSelected = $scope.allOptionsForPart.every(optionPart => optionPart.isSelected === true);
                           }
                           else {
                               RequestOfferPartOption.getAllOptionsForPart(
                                   $scope.offerPart.id,
                                   (resp) => {
                                       // allOptionsForPartCopied is just to keep track of allOptionsSelected variable and created just to avoid data mutation.
                                       $scope.allOptionsForPartCopied = requestOfferPartOptionsUtil.filterOfferPartOptions(
                                           $scope.offer,
                                           resp
                                       );
                                       $scope.allOptionsSelected = $scope.allOptionsForPartCopied.every(optionPart => optionPart.isSelected === true);
                                   }
                               );
                           }
                        }

                        $rootScope.$on('part:option:is:selected:value:changed', (event, data) => {
                            let _changedOfferPartOption = _.find($scope.allOptionsForPart, (o) => {
                                return o.id === data.offerPartOptionId;
                            });
                            if (_changedOfferPartOption && !data.isSelected) {
                                _changedOfferPartOption.toggleNonSelectedItem = $scope.toggleNonSelectedItem;
                            }
                            $scope.updateAllOfferPartOptionsAreSelected(false);
                        });

                        $scope.close = function () {
                            $rootScope.ROPOAddEditId = 0;
                            $rootScope.ROPOEditId = 0;
                            $rootScope.offerPartEditId = 0;
                        };

                        $scope.init();
                    },
                ],
                link: function () { },
            };
        },
    ]);
})();
