'use strict';
import { ScopeFunctions } from '../../../../../../utils/global/scopeFunctions';
import customMessageModal from '../../../../../modals/modals2.0/custom-message-modal';

// Register `participants.settings` component, along with its associated controller and template
angular.
    module('participants.settings').
    component('participantsettings', {
        templateUrl: './app/components/modules/event/event_detail/participants/settings/settings.view.html',
        controllerAs: 'participantSettingsCtrl',
        controller: [
            '$scope',
            '$stateParams',
            'Participant',
            'Event',
            'ParticipantActivity',
            '$sce',
            '$rootScope',
            '$state',
            '$timeout',
            'ModalService',
            'EventParticipantCustomQuery',
            'ActiveTabService',
            'ogScrollService',
            function ParticipantSettingsController(
                $scope,
                $stateParams,
                Participant,
                Event,
                ParticipantActivity,
                $sce,
                $rootScope,
                $state,
                $timeout,
                ModalService,
                EventParticipantCustomQuery,
                ActiveTabService,
                ogScrollService
            ) {
                var vm = this;
                vm.loadSettingsTab = loadSettingsTabFn;
                vm.getParticipantActivityNotificationSettings = getParticipantActivityNotificationSettingsFn;
                vm.getTermsAndConditions = getTermsAndConditionsFn;
                vm.trustAsHtml = trustAsHtmlFn;
                vm.getParticipantLanguage = getParticipantLanguageFn;
                vm.getRegistrationExpiryDate = getRegistrationExpiryDateFn;
                vm.revertToPreviousNotificationEmail = revertToPreviousNotificationEmailFn;
                vm.setParticipantsNotificationType = setParticipantsNotificationTypeFn;
                vm.updateNotificationSettingsForEvent = updateNotificationSettingsForEventFn;
                vm.updateRegistrationExpiryDate = updateRegistrationExpiryDateFn;
                vm.updateSettings = updateSettingsFn;
                vm.updateLanguage = updateLanguageFn;
                vm.updateMaxParticipant = updateMaxParticipantFn;
                vm.updateSMSService = updateSMSServiceFn;
                vm.getEventUsedSMSService = getEventUsedSMSServiceFn;
                vm.getMaxParticipants = getMaxParticipantsFn;
                vm.toogleEditTermsAndConditions = toogleEditTermsAndConditionsFn;
                vm.uploadTermsAndConditions = uploadTermsAndConditionsFn;
                vm.showDefaultPrivacyTerms = showDefaultPrivacyTermsFn;
                vm.setDefaultLanguage = setDefaultLanguageFn;
                vm.setSupportLanguage = setSupportLanguageFn;
                vm.getParticipantCustomQueries = getParticipantCustomQueriesFn;
                vm.setActive = setActiveFn;
                vm.editTermsAndConditions = false;
                vm.showLanguageAddedMessage = false;

                vm.eventId = $stateParams.eventId;
                vm.languageSelected = $rootScope.langSelected;
                vm.defaultLanguages = {
                    swedish: 'sv_SV',
                    english: '',
                    norwegian: '',
                    danish: '',
                    finnish: '',
                };
                vm.supportLanguages = {
                    swedish: false,
                    english: true,
                    norwegian: false,
                    danish: false,
                    finnish: false,
                };
                vm.defaultParticipantCount = {};
                vm.sms = {};

                ScopeFunctions.addToScope($scope);
                vm.loadSettingsTab();

                function loadSettingsTabFn() {
                    vm.setRegistrationExpiryDate = { checked: false };
                    vm.getRegistrationExpiryDate();
                    vm.getParticipantLanguage();
                    vm.getParticipantActivityNotificationSettings();
                    vm.getTermsAndConditions();
                    vm.getMaxParticipants();
                    vm.getEventUsedSMSService();
                    vm.getParticipantCustomQueries();
                }

                function getMaxParticipantsFn() {
                    Event.getMaxParticipant(
                        vm.eventId,
                        function (res) {
                            var response = angular.copy(res);
                            vm.defaultParticipantCount.participants = response.maxParticipants;
                            vm.defaultParticipantCount.oldValue = response.maxParticipants;
                        },
                        $scope.endWithErrorOverlay()
                    );
                }

                function getEventUsedSMSServiceFn() {
                    Event.getEventUsedSMSService(
                        vm.eventId,
                        function (res) {
                            var response = angular.copy(res);
                            vm.sms.eventUsedSMSService = response.eventUsedSMSService;
                            vm.sms.useSMS = response.useSMS;
                        },
                        $scope.endWithErrorOverlay()
                    );
                }

                function getParticipantActivityNotificationSettingsFn() {
                    Event.getNotificationSettings(vm.eventId, function (response) {
                        vm.originalNotificationEmail = angular.copy(response.participantActivityNotificationEmail);
                        vm.notificationEmail = response.participantActivityNotificationEmail;
                        vm.notificationEmailSet = true;
                        vm.originalNotificationType = angular.copy(response.notificationFrequency.name);
                        vm.setParticipantsNotificationType(response.notificationFrequency.name);
                    });
                }

                function getTermsAndConditionsFn() {
                    Event.getTermsAndConditions(
                        vm.eventId,
                        function (response) {
                            vm.termsAndConditions = response.termsAndConditions;
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function getParticipantCustomQueriesFn() {
                    EventParticipantCustomQuery.customSelectedQueries(
                        { eventId: vm.eventId },
                        function (response) {
                            vm.customSelectedQueryList = response;
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function trustAsHtmlFn(string) {
                    return $sce.trustAsHtml(string);
                }

                function getParticipantLanguageFn() {
                    Participant.getParticipantLanguage(
                        vm.eventId,
                        function (result) {
                            vm.originalDefaultLanguage = result.participantDefaultLocale;
                            vm.originalSupporrtLanguage = result.participantSupportLocale;
                            vm.setDefaultLanguage(result.participantDefaultLocale);
                            vm.setSupportLanguage(result.participantSupportLocale);
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function updateSettingsFn() {
                    let supportEnglish = '';
                    let supportSwedish = '';
                    let supportNorwegian = '';
                    let supportDanish = '';
                    let supportFinnish = '';
                    var defaultLanguage = vm.defaultLanguage;
                    var supportLanguage = '';
                    if (vm.supportLanguages.english) {
                        supportEnglish = 'en_US';
                    }
                    if (vm.supportLanguages.swedish) {
                        supportSwedish = 'sv_SV';
                    }
                    if (vm.supportLanguages.norwegian) {
                        supportNorwegian = 'nb_NB';
                    }
                    if (vm.supportLanguages.danish) {
                        supportDanish = 'da_DK';
                    }
                    if (vm.supportLanguages.finnish) {
                        supportFinnish = 'fi_FI';
                    }
                    supportLanguage = [supportEnglish, supportSwedish, supportNorwegian, supportDanish, supportFinnish].join(
                        '~'
                    );
                    vm.updateRegistrationExpiryDate();
                    vm.updateLanguage(defaultLanguage, supportLanguage);
                }

                function updateMaxParticipantFn() {
                    $scope.startProgress();
                    Event.updateMaxParticipant(
                        {
                            eventId: vm.eventId,
                            participants: vm.defaultParticipantCount.participants,
                        },
                        (response) => {
                            vm.defaultParticipantCount.participants = response.participants;
                            $scope.endProgress();
                        },
                        (err) => {
                            vm.defaultParticipantCount.participants = vm.defaultParticipantCount.oldValue;
                            $scope.endWithAlert(err);
                        }
                    );
                }

                function updateSMSServiceFn() {
                    $scope.startProgress();
                    Event.updateSMSService(
                        { eventId: vm.eventId, useSMS: vm.sms.useSMS },
                        (response) => {
                            vm.sms.useSMS = response.useSMS;
                            $scope.endProgress();
                        },
                        $scope.endWithAlert
                    );
                }

                function toogleEditTermsAndConditionsFn() {
                    vm.editTermsAndConditions = true;
                }

                function uploadTermsAndConditionsFn() {
                    vm.editTermsAndConditions = false;
                    $scope.startProgress();
                    Event.updateTermsAndConditions(
                        {
                            eventId: vm.eventId,
                            termsAndConditions: vm.termsAndConditions,
                        },
                        function (response) {
                            vm.termsAndConditions = response.termsAndConditions;
                            $scope.endProgress();
                        },
                        $scope.endWithErrorOverlay()
                    );
                }

                function isFormValueHasChangedFn() {
                    if (vm.originalData.registrationExpiryDate !== vm.data.registrationExpiryDate) {
                        return true;
                    }
                    if (vm.originalNotificationType !== vm.participantsNotificationType) {
                        return true;
                    }
                    if (vm.originalNotificationEmail !== vm.notificationEmail) {
                        return true;
                    }
                    return false;
                }

                var $locationChangeStartUnbind = $scope.$on(
                    '$stateChangeStart',
                    function (event, toState, toParams, fromState, fromParams) {
                        if (toState != fromState && isFormValueHasChangedFn()) {
                            if (!vm.confirmLeave) {
                                event.preventDefault();
                                $rootScope.$broadcast('cancelStateChange');
                                $rootScope.$broadcast('dialog:settings:confirmation:open', {
                                    message: 'unsaved.changes.confirm.leave',
                                    okCallBack: function () {
                                        vm.updateSettings();
                                        vm.confirmLeave = true;
                                        $state.go(toState.name);
                                    },
                                    cancelCallBack: function () {
                                        vm.confirmLeave = true;
                                        $state.go(toState.name);
                                    },
                                    closeCallBack: function () {
                                        $rootScope.$broadcast('keepSettingsFormActive');
                                    },
                                });
                                /*OgModal.open('settingsconfirmation', {
                                    message: 'unsaved.changes.confirm.leave',
                                    okCallBack: function () {
                                        vm.confirmLeave = true;
                                        $state.go(toState.name);
                                    },
                                    cancelCallBack: function () {
                                        $rootScope.$broadcast('keepSettingsFormActive');
                                    },
                                    closeCallBack: function () {
                                        $rootScope.$broadcast('keepSettingsFormActive');
                                       }
                                });*/
                            } else {
                                return true;
                            }
                        }
                    }
                );
                $scope.$on('$destroy', function () {
                    $locationChangeStartUnbind();
                });

                function showDefaultPrivacyTermsFn() {
                    $scope.startProgress();
                    let data = {
                        title: 'default.privacy.policy.title',
                        message: null,
                    };
                    let defaultPrivacyTerms = customMessageModal;
                    customMessageModal.inputs = { data };
                    ModalService.showModal(defaultPrivacyTerms).then((modal) => {
                        modal.element.modal();
                        modal.close.then(() => {
                            console.log('modal is closed!');
                        });
                        $scope.endProgress();
                    }),
                        function (error) {
                            $scope.endProgress();
                        };
                }

                /*Start Registration Expiry Date Section*/
                function getRegistrationExpiryDateFn() {
                    Event.getRegistrationExpiryDate(vm.eventId, function (data) {
                        vm.data = data;
                        vm.originalData = angular.copy(vm.data);
                        if (data.registrationExpiryDate) {
                            vm.data.registrationExpiryDate = data.registrationExpiryDate;
                        }
                        if (vm.data.registrationExpiryDate !== null) {
                            vm.setRegistrationExpiryDate.checked = true;
                        }
                        vm.originalSetRegistrationExpiryDate = angular.copy(vm.setRegistrationExpiryDate.checked);
                    });
                }

                function revertToPreviousNotificationEmailFn() {
                    vm.notificationEmail = vm.originalNotificationEmail;
                    vm.notificationEmailSet = true;
                    vm.showNotificationEmailSection = false;
                }

                function setParticipantsNotificationTypeFn(type) {
                    vm.participantsNotificationType = '';
                    vm.participantsNotifications = {
                        onUpdate: false,
                        daily: false,
                        weekly: false,
                        never: false,
                    };
                    vm.participantsNotificationType = type;
                    if (vm.participantsNotificationType == 'ON_UPDATE') {
                        vm.participantsNotifications.onUpdate = true;
                    } else if (vm.participantsNotificationType == 'DAILY') {
                        vm.participantsNotifications.daily = true;
                    }
                    if (vm.participantsNotificationType == 'WEEKLY') {
                        vm.participantsNotifications.weekly = true;
                    }
                    if (vm.participantsNotificationType == 'NEVER') {
                        vm.participantsNotifications.never = true;
                    }
                }

                function setDefaultLanguageFn(language) {
                    vm.defaultLanguage = '';
                    vm.defaultLanguages = {
                        english: '',
                        swedish: '',
                        norwegian: '',
                        danish: '',
                        finnish: '',
                    };
                    vm.defaultLanguage = language;
                    if (vm.defaultLanguage === 'en_US') {
                        vm.defaultLanguages.english = 'en_US';
                        vm.supportLanguages.english = true;
                    }
                    if (vm.defaultLanguage === 'sv_SV') {
                        vm.defaultLanguages.swedish = 'sv_SV';
                        vm.supportLanguages.swedish = true;
                    }
                    if (vm.defaultLanguage === 'nb_NB') {
                        vm.defaultLanguages.norwegian = 'nb_NB';
                        vm.supportLanguages.norwegian = true;
                    }
                    if (vm.defaultLanguage === 'da_DK') {
                        vm.defaultLanguages.danish = 'da_DK';
                        vm.supportLanguages.danish = true;
                    }
                    if (vm.defaultLanguage === 'fi_FI') {
                        vm.defaultLanguages.finnish = 'fi_FI';
                        vm.supportLanguages.finnish = true;
                    }
                }

                function setSupportLanguageFn(language) {
                    vm.supportLanguages = {
                        swedish: false,
                        english: false,
                        norwegian: false,
                        danish: false,
                        finnish: false,
                    };
                    var supportLanguages = language.split('~');
                    if (supportLanguages[0] === 'en_US') {
                        vm.supportLanguages.english = true;
                    }
                    if (supportLanguages[1] === 'sv_SV') {
                        vm.supportLanguages.swedish = true;
                    }
                    if (supportLanguages[2] === 'nb_NB') {
                        vm.supportLanguages.norwegian = true;
                    }
                    if (supportLanguages[3] === 'da_DK') {
                        vm.supportLanguages.danish = true;
                    }
                    if (supportLanguages[4] === 'fi_FI') {
                        vm.supportLanguages.finnish = true;
                    }
                }

                function updateNotificationSettingsForEventFn() {
                    $scope.startProgress();
                    Event.updateNotificationSettings(
                        {
                            eventId: vm.eventId,
                            type: vm.participantsNotificationType,
                            email: vm.notificationEmail,
                        },
                        function (response) {
                            vm.notificationEmail = response.participantActivityNotificationEmail;
                            vm.originalNotificationEmail = angular.copy(response.participantActivityNotificationEmail);
                            vm.originalNotificationType = angular.copy(response.notificationFrequency.name);
                            vm.setParticipantsNotificationType(response.notificationFrequency.name);
                            vm.notificationEmailSet = true;
                            vm.showNotificationEmailSection = false;
                            $scope.endProgress();
                        }
                    );
                }

                function updateRegistrationExpiryDateFn() {
                    var _data = angular.copy(vm.data);
                    if (vm.data && vm.data.registrationExpiryDate && !vm.setRegistrationExpiryDate.checked) {
                        _data.registrationExpiryDate = null;
                    }
                    $scope.startProgress();
                    Event.updateRegistrationExpiryDate(vm.eventId, _data, function (data) {
                        vm.updateNotificationSettingsForEvent();
                        vm.data = data;
                        vm.originalData = angular.copy(data);
                        $scope.endProgress();
                    });
                }

                function updateLanguageFn(defaultLanguage, supportLanguage) {
                    Participant.updateParticipantsLanguage(
                        {
                            eventId: vm.eventId,
                            defaultLocale: defaultLanguage,
                            supportLocale: supportLanguage,
                        },
                        function (result) {
                            let hasSupportLanguageAdded = false,
                                hasNewDefaultLanguageAdded = false;
                            if (vm.originalSupporrtLanguage !== supportLanguage) {
                                let result = supportLanguage
                                    .split('~')
                                    .filter((item) => !vm.originalSupporrtLanguage.split('~').includes(item));
                                if (result.indexOf('') === -1 && result.length > 0) {
                                    hasSupportLanguageAdded = true;
                                }
                            }
                            if (vm.originalDefaultLanguage !== defaultLanguage) {
                                if (supportLanguage.split('~').indexOf(defaultLanguage) === -1) {
                                    hasNewDefaultLanguageAdded = true;
                                }
                            }
                            if (
                                vm.customSelectedQueryList.length > 0 &&
                                (hasNewDefaultLanguageAdded || hasSupportLanguageAdded)
                            ) {
                                vm.showLanguageAddedMessage = true;
                                if (vm.showLanguageAddedMessage) {
                                    scrollTo();
                                }
                            }
                            vm.originalDefaultLanguage = result.participantDefaultLocale;
                            vm.originalSupporrtLanguage = result.participantSupportLocale;
                            vm.setDefaultLanguage(result.participantDefaultLocale);
                            vm.setSupportLanguage(result.participantSupportLocale);
                        },
                        $scope.endWithErrorOverlay
                    );
                }

                function scrollTo() {
                    $timeout(function () {
                        ogScrollService.scrollToElement('successAlertBox');
                    }, 500);
                }

                function setActiveFn() {
                    ActiveTabService.setParticipantsSubTab('design_invitation');
                    ActiveTabService.setInvitationTab('registration_form');
                }

                /*End Registration Expiry Date Section*/

                $scope.$on('lang:change', function (evt, data) {
                    vm.languageSelected = data.lang;
                });
            }
        ]
    });
