export default function (appModule) {
    appModule.config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('eventsite', {
                    url: '/eventsite/:urlSlug',
                    template: 'Loading...',
                    controller: [
                        '$q',
                        '$stateParams',
                        'EventSite',
                        '$state',
                        '$rootScope',
                        function ($q, $stateParams, EventSite, $state, $rootScope) {
                            EventSite.getTypeFromUrlName(
                                { urlSlug: $stateParams.urlSlug },
                                function successCallback(res) {
                                    //The locale of this page depends on the language config set for participants
                                    $rootScope.switchLang(res.lang, true, $stateParams.hash);
                                    if (res.type === 'EVENT_SUMMARY') {
                                        $state.go('shared.eventSummary', { urlSlug: $stateParams.urlSlug });
                                    } else if (res.type === 'EVENT_PRESENTATION') {
                                        $state.go('shared.presentation', { urlSlug: $stateParams.urlSlug });
                                        // window.open(res.url, '_self');
                                    } else {
                                        $state.go('link.expired');
                                    }
                                },
                                function errorCallback() {
                                    $state.go('link.expired');
                                }
                            );
                        },
                    ],
                })
                .state('eventsitenew', {
                    url: '/eventsitenew/:urlSlug',
                    template: 'Loading...',
                    controller: [
                        '$stateParams',
                        '$state',
                        function ($stateParams, $state) {
                            $state.go('shared.presentation', { urlSlug: $stateParams.urlSlug });
                        },
                    ],
                });
        }
    ]);
}