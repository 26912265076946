import UserGroupData from '../../../../core/existingServices/data_store/UserGroupData';
import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';

angular.module('shared.event.summary').component('sharedEventSummary', {
    templateUrl: './app/components/modules/shared/event_summary/event_summary.view.html',
    controller: ['$scope', '$stateParams', 'EventSummary',

        function SharedEventSummaryController($scope, $stateParams, EventSummary) {
            var vm = this;
            vm.init = initFn;
            ScopeFunctions.addToScope($scope);
            vm.init();

            function initFn() {
                $scope.startProgress();
                EventSummary.eventSummaryPageByUrlSlug(
                    { urlSlug: $stateParams.urlSlug },
                    function (response) {
                        vm.event = response.event;
                        UserGroupData.setUserGroupData(vm.event.ownerGroup);
                        vm.requests = vm.event.requests;
                        vm.decisionMaker = response.decisionMaker;
                        // vm.eventSchedule = response.eventSchedule;
                        vm.supplierLocations = response.supplierLocations;
                        vm.sharedPublicly = response.sharedPublicly;
                        vm.currentDecisionMaker = response.currentDecisionMaker;
                        $scope.endProgress();
                    },
                    $scope.endWithErrorOverlay
                );
            }
        }]
})
