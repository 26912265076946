/**
 * Created by kshitij on 8/7/17.
 */
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

class AccommodationCalendarBoxCtrl {
    constructor($scope, $sce) {
        this._injected = {
            $scope,
            $sce,
        };

        ScopeFunctions.addToScope($scope);
    }

    getNotificationHtml(notification) {
        const { $sce } = this._injected;
        if (notification) {
            notification = 1;
            return $sce.trustAsHtml('<span class="notification no-print">' + notification + '</span>');
        } else {
            return $sce.trustAsHtml('');
        }
    }

    isConfirmationPending() {
        if (this.room) {
            return this.room.supplierConfirmationPending || this.room.creatorConfirmationPending;
        }
    }

    getAccommodationColor() {
        if (this.room) {
            return this.room.color;
        }
    }

    openAccommodationModal(requestId) {
        this.openAccommodationModalCallback({ requestId: requestId });
    }

    range(n) {
        return UtilFunctions.getRange(n);
    }

    getIcon(n) {
        switch (n) {
            case 1:
                return 'assets/app/customer/event/calendar/single_room.svg';
                break;
            case 2:
                return 'assets/app/customer/event/calendar/double_room.svg';
                break;
            case 3:
                return 'assets/app/customer/event/calendar/suite_room.svg';
                break;
            default:
                break;
        }
    }
}
AccommodationCalendarBoxCtrl.$inject = ['$scope', '$sce'];
export default AccommodationCalendarBoxCtrl;
