/**
 * Created by kshitij on 1/25/17.
 */
import controller from './og_user_profile_image.controller.js';
(function () {
    'use strict';
    angular.module('ogUserProfileImage').directive('ogUserProfileImage', [
        function () {
            return {
                scope: {
                    user: '=',
                    image: '=',
                    height: '=',
                    fontSize: '=',
                    bgColor: '=',
                    position: '=',
                    type: '=',
                },
                templateUrl: './app/components/directives/user_profile_image/og_user_profile_image.directive.view.html',
                controller,
                controllerAs: 'OgUserProfileImageCtrl',
                bindToController: true,
            };
        },
    ]);
})();
