'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogImageCropModal', [
    '$rootScope',
    '$timeout',
    'CustomerUser',
    'AuthenticationService',
    'EventEnum',
    function ($rootScope, $timeout, CustomerUser, AuthenticationService, EventEnum) {
        return new OgModal({
            name: 'image:crop',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                    $scope.form = 'profilePictureForm';

                    $scope.crop = {};
                    $scope.crop.coordinates = null;

                    // this is css style for preview image on cropping i.e inline css of preview div
                    $scope.crop.previewStyle = {
                        width: '100px',
                        height: '100px',
                        overflow: 'hidden',
                        'border-radius': '50%',
                        'margin-left': '164px',
                        'margin-bottom': '5px',
                    };
                    $scope.crop.cropStyle = {
                        maxWidth: '445',
                        maxHeight: '445',
                        aspectRatio: 1,
                        bgColor: 'rgb(16, 16, 16)',
                        minSize: [100, 100],
                        setSelect: [0, 0, 100, 100],
                    };
                    $scope.crop.thumbnail = true;
                    $scope.initDialog = function () {
                        $scope.startProgress();
                        $scope.data = {};
                        $scope.data.profilePicture = undefined;
                        jQuery('#profileImage').val('');
                        $scope.endProgress();
                    };

                    var URL = window.URL || window.webkitURL,
                        blobURL;

                    $scope.$watch('data.profilePicture', function (value) {
                        if (URL) {
                            if (value) {
                                $scope.data.profilePicture = value;
                                if (/^image\/\w+$/.test($scope.data.profilePicture.type)) {
                                    blobURL = URL.createObjectURL($scope.data.profilePicture);
                                    $scope.crop.blobURL = blobURL;
                                } else {
                                    $scope.data.profilePicture = null;
                                }
                            }
                        }
                    });

                    $scope.checkCropDetails = function () {
                        if (!$scope.crop) {
                            return false;
                        }
                        if (!$scope.crop.coordinates[4] || !$scope.crop.coordinates[5]) {
                            return false;
                        }
                        return true;
                    };

                    $scope.setAsProfilePicture = function () {
                        if (!$scope.checkCropDetails()) {
                            $rootScope.$broadcast('dialog:alert:open', { message: 'image.crop.before.uploading' });
                        } else {
                            $scope.startProgress();
                            var formData = new FormData();
                            formData.append('profilePicture', $scope.data.profilePicture);
                            formData.append('dataX', $scope.crop.coordinates[0]);
                            formData.append('dataY', $scope.crop.coordinates[1]);
                            formData.append('dataWidth', $scope.crop.coordinates[4]);
                            formData.append('dataHeight', $scope.crop.coordinates[5]);
                            CustomerUser.updateUserProfilePicture(
                                formData,
                                function (data) {
                                    //jQuery('.img-container > img').cropper('destroy').cropper($scope.OPTIONS);
                                    $scope.endProgress();
                                    $scope.close();
                                    $rootScope.$broadcast(EventEnum.USER_PROFILE_PICTURE_CHANGED, data);
                                },
                                $scope.endWithError
                            );
                        }
                    };
                },
            });
        },
    ]);

