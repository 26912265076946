/* global angular */
'use strict';

angular.module('core.session').factory('SessionService', function () {
    var isModalOpen = false;

    function setIsModalOpen() {
        isModalOpen = true;
    }

    function getIsModalOpen() {
        return isModalOpen;
    }

    return {
        setIsModalOpen: function () {
            return setIsModalOpen();
        },
        getIsModalOpen: function () {
            return getIsModalOpen();
        },
    };
});
