'use strict';
angular.module('core.existingServices').factory('RemoteFilepicker', [
    '$rootScope',
    function ($rootScope) {
        var developerKey = 'AIzaSyAtlU6p08oPKX0oS3Dfxso3Ei9h7QYy6d4';
        var clientId = '397314256996-dmao593vt8heu4sll3mih6d397jvh55d.apps.googleusercontent.com';
        var scope = ['https://www.googleapis.com/auth/drive.readonly'];
        var pickerApiLoaded = false;
        var oauthToken;
        var successCall;
        var cancelCall;

        function onAuthApiLoad() {
            window.gapi.auth.authorize(
                {
                    client_id: clientId,
                    scope: scope,
                    immediate: false,
                },
                handleAuthResult
            );
        }

        function onPickerApiLoad() {
            pickerApiLoaded = true;
            createPicker();
        }

        function handleAuthResult(authResult) {
            if (authResult && !authResult.error) {
                oauthToken = authResult.access_token;
                createPicker();
            }
        }

        function createPicker() {
            if (pickerApiLoaded && oauthToken) {
                var picker = new google.picker.PickerBuilder()
                    .enableFeature(google.picker.Feature.NAV_HIDDEN)
                    .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                    .addView(google.picker.ViewId.DOCS)
                    .setOAuthToken(oauthToken)
                    .setDeveloperKey(developerKey)
                    .setCallback(pickerCallback)
                    .build();
                picker.setVisible(true);

                var elements = document.getElementsByClassName('picker-dialog');
                for (var i = 0; i < elements.length; i++) {
                    elements[i].style.zIndex = '2000';
                }
            }
        }

        function pickerCallback(data) {
            if (data.action == google.picker.Action.PICKED) {
                if (successCall) {
                    var returnValues = [];
                    angular.forEach(data.docs, function (n) {
                        angular.forEach($rootScope.sharedUsers, function (sharedUser) {
                            insertPermission(n.id, sharedUser.email, 'user', 'reader');
                        });
                        returnValues.push({
                            fileUrl: n.url,
                            name: n.name,
                            fileShareType: {
                                name: 'GOOGLE_DRIVE',
                            },
                        });
                    });
                    successCall(returnValues);
                }
            } else {
                if (cancelCall) {
                    cancelCall();
                }
            }
        }

        function insertPermission(fileId, value, type, role) {
            var body = {
                value: value,
                type: type,
                role: role,
            };
            var request = gapi.client.drive.permissions.insert({
                fileId: fileId,
                resource: body,
            });
            request.execute(function (resp) {
                if (resp.error) {
                    console.log('Error: ' + resp.error.message);
                }
            });
        }

        function onClientLoaded() {
            gapi.client.load('drive', 'v2');
        }

        function newGooglePicker(successCallback, cancelCallback) {
            successCall = successCallback;
            cancelCall = cancelCallback;
            gapi.load('auth', {callback: onAuthApiLoad});
            gapi.load('picker', {callback: onPickerApiLoad});
            gapi.load('client', {callback: onClientLoaded});
        }

        return {
            showGooglePicker: function (successCallback, cancelCallback) {
                newGooglePicker(successCallback, cancelCallback);
            },
            showDropboxPicker: function (successCallback, cancelCallback) {
                Dropbox.choose({
                    success: function (files) {
                        if (successCallback) {
                            var returnValues = [];
                            angular.forEach(files, function (n) {
                                returnValues.push({
                                    fileUrl: n.link,
                                    name: n.name,
                                    fileShareType: {
                                        name: 'DROPBOX',
                                    },
                                });
                            });
                            successCallback(returnValues);
                        }
                    },
                    cancel: cancelCallback,
                    linkType: 'preview',
                    multiselect: true,
                });
            },
        };
    },
]);

