(function () {
    'use strict';

    angular.module('ogCustomPopover').directive('ogCustomPopover', [
        '$compile',
        '$templateCache',
        'EventEnum',
        'TranslationService',
        function ($compile, $templateCache, EventEnum, TranslationService) {
            var getTemplate = function (contentType) {
                var temp = $templateCache.get(contentType + '.html');
                return temp;
            };
            return {
                restrict: 'A',
                transclude: true,
                template: '<span ng-transclude></span>',
                scope: {
                    items: '=?',
                    title: '@',
                    type: '@',
                    placement: '=',
                    saveCallBack: '=?',
                    callBack1: '=?',
                    callBack2: '=?',
                    callBack3: '=?',
                    closeCallBack: '=?',
                    supplierId: '=?',
                    error: '=?',
                    defaultOpenEditComment: '=?', //USED to make edit comment section opened by default
                    triggerType:
                        '@ogPopoverTriggerType' /*don't pass if it is default type pass none for no trigger type
          see example for conditional getConfirmationTriggerType
          method of components/modals/modals2.0/offer-preview/offer-preview-modal.controller.js*/,
                },
                controller: [
                    '$scope',
                    function ($scope) {
                        $scope.items = angular.isDefined($scope.items) ? $scope.items : [];
                        $scope.saveCallBack = angular.isDefined($scope.saveCallBack) ? $scope.saveCallBack : undefined;
                        $scope.callBack1 = angular.isDefined($scope.callBack1) ? $scope.callBack1 : undefined;
                        $scope.callBack2 = angular.isDefined($scope.callBack2) ? $scope.callBack2 : undefined;
                        $scope.callBack3 = angular.isDefined($scope.callBack3) ? $scope.callBack3 : undefined;
                        $scope.closeCallBack = angular.isDefined($scope.closeCallBack)
                            ? $scope.closeCallBack
                            : undefined;
                        if (angular.isDefined($scope.items.offerPart)) {
                            $scope.items.offerPart.commentByCreator = TranslationService.translate(
                                $scope.items.offerPart.commentByCreator,
                                $scope.items.offerPart.commentByCreatorCode
                            );
                        }
                        $scope.editable = $scope.defaultOpenEditComment ? $scope.defaultOpenEditComment : false;
                        $scope.init = function () {
                            if ($scope.items) {
                                if ($scope.items.offerPart && $scope.items.sharedUsers) {
                                    angular.forEach($scope.items.sharedUsers, function (n) {
                                        if ($scope.items.offerPart.responsible) {
                                            if (n.id == $scope.items.offerPart.responsible.id) {
                                                $scope.items.offerPart.responsible = n;
                                                return false;
                                            }
                                        }
                                    });
                                }
                            }
                        };

                        if ($scope.items) {
                            $scope.init();
                        }

                        $scope.save = function (data) {
                            if (angular.isDefined(data)) {
                                $scope.saveCallBack(data);
                            } else {
                                $scope.saveCallBack();
                            }
                            $scope.closePopOver();
                            $scope.close();
                        };

                        $scope.callBackOne = function (data) {
                            if (angular.isDefined(data)) {
                                $scope.callBack1(data);
                            } else {
                                $scope.callBack1();
                            }
                            $scope.closePopOver();
                            $scope.close();
                        };

                        $scope.callBackTwo = function (data) {
                            if (angular.isDefined(data)) {
                                $scope.callBack2(data);
                            } else {
                                $scope.callBack2();
                            }
                            $scope.closePopOver();
                            $scope.close();
                        };

                        $scope.callBackThree = function (data) {
                            if (data) {
                                $scope.callBack3(data);
                            } else {
                                $scope.callBack3();
                            }
                            $scope.closePopOver();
                            $scope.close();
                        };

                        $scope.supplierComment = function (offerPart) {
                            var match = false;
                            angular.forEach(offerPart, function (value, key) {
                                if (key == 'supplierId') {
                                    if (value == $scope.supplierId) {
                                        match = true;
                                        return true;
                                    }
                                }
                            });
                            return match;
                        };

                        $scope.editToggle = function () {
                            $scope.editable = !$scope.editable;
                        };

                        $scope.view = function (offerId, requestId) {
                            if ($scope.type == 'suppliers') {
                                $scope.$emit(EventEnum.VIEW_SUPPLIER_OFFER, { offerId: offerId, requestId: requestId });
                            }
                        };

                        $scope.getOfferPartAdditionalQuestionClass = function (resourceData) {
                            if (resourceData && resourceData.nameCode) {
                                switch (resourceData.nameCode) {
                                    case 'common.translate.offer.part.type.bus.(outward.journey)':
                                    case 'common.translate.offer.part.type.bus.(home.journey)':
                                    case 'common.translate.offer.part.type.bus.trip':
                                        return 'supplier-comment-bus-transport-form';
                                        break;
                                    default:
                                        break;
                                }
                            }
                        };
                    },
                ],
                link: function (scope, element) {
                    var popOverContent;
                    if (scope.type) {
                        var html = getTemplate(scope.type);
                        popOverContent = $compile(html)(scope);
                    }
                    var triggerType = 'click';
                    if (angular.isDefined(scope.triggerType)) {
                        triggerType = scope.triggerType;
                    }

                    var options = {
                        content: popOverContent,
                        placement: scope.placement,
                        html: true,
                        trigger: triggerType,
                        title: scope.title,
                    };
                    jQuery(element).popover(options);

                    element.data('bs.popover').tip().addClass('popover-container-sm');

                    scope.$watch('error', function (newVal, oldVal) {
                        if (scope.type == 'select_file_from' && newVal == 'file.upload.file.big.message') {
                            element.data('bs.popover').tip().addClass('max-size');
                        } else {
                            element.data('bs.popover').tip().removeClass('max-size');
                        }

                        if (newVal != oldVal && newVal) {
                            jQuery('.popover').show();
                            element.popover('toggle');
                        }
                    });

                    scope.close = function () {
                        element.click();
                    };

                    scope.closePopOver = function () {
                        jQuery('.popover').hide();
                        element.popover('toggle');
                    };
                },
            };
        },
    ]);
})();
