(function () {
    'use strict';

    angular.module('ogClientAppLogo').component('ogClientAppLogo', {
        templateUrl: './app/components/directives/client-app-logo/og.client.app.logo.view.html',
        controller: [
            '$scope',
            'AuthenticationService',
            'EventEnum',
            'ClientService',
            'CommonFile',
            function ($scope, AuthenticationService, EventEnum, ClientService, CommonFile) {
                let vm = this;

                vm.refreshUserData = refreshUserDataFn;
                vm.getClientData = getClientDataFn;
                vm.refreshUserData();
                vm.getClientData();

                function refreshUserDataFn() {
                    $scope.userData = AuthenticationService.getUserData();
                }

                function getClientDataFn() {
                    $scope.client = ClientService.get();
                }

                $scope.getImageUrl = function (fileId) {
                    return CommonFile.getCommonFile(fileId);
                };

                $scope.$on(EventEnum.USER_DATA_CHANGED, vm.refreshUserData);
                $scope.$on(EventEnum.USER_DATA_CHANGED, vm.getClientData);
            },
        ],
    });
})();
