'use strict';
angular.module('core.transport').factory('TransportService', [
    '$resource',
    function ($resource) {
        var transportRest = $resource(
            'api/v1/event/:eventId/addTransportRequest',
            {},
            {
                add: {method: 'POST', isArray: false},
            }
        );

        return {
            addTransport: function (jsonObject, success, error) {
                return transportRest.add(jsonObject, success, error);
            },
        };
    },
]);
