'use strict';
import './auth/login/login.module';
import './auth/login/login.component';

import './auth/register/register.module';
import './auth/register/register.component';

import './auth/change_password/change_password.module';
import './auth/change_password/change_password.component';

import './auth/register_invitation/register_invitation.module';
import './auth/register_invitation/register_invitation.component';

import './link/link_expired/link.expired.module';
import './link/link_expired/link.expired.component';

import './event/events/events.module';
import './event/events/events.component';

import './auth/account_confirm/account_confirm.module';
import './auth/account_confirm/account_confirm.component';

import './event/events/activities/activities.module';
import './event/events/activities/activities.component';

import './event/events/tasks/tasks.module';
import './event/events/tasks/tasks.component';

import './supplier/offers/offers.module'
import './supplier/offers/offers.component'

import './supplier/offer/offer_detail/offer_detail.module'
import './supplier/offer/offer_detail/offer_detail.component'

import './supplier/offer/offer_detail/message/message.module'
import './supplier/offer/offer_detail/message/message.component'

import './supplier/offer/offer_detail/final-details/finaldetails.module'
import './supplier/offer/offer_detail/final-details/finaldetails.component'

import './supplier/offer/thank_you/thank.you.confirmation.module'
import './supplier/offer/thank_you/thank.you.confirmatimation.component'

import './supplier/offer/confirm/offer_booking_confirm.module'
import './supplier/offer/confirm/offer_booking_confirm.component'

import './event/events/list/list.module';
import './event/events/list/list.component';

import './event/events/upcomingOffers/upcomingOffers.module';
import './event/events/upcomingOffers/upcomingOffers.component';

import './participant/participant_details/participant_details.module';
import './participant/participant_details/participant_details.component';

import './participant/invitation/invitation.module';
import './participant/invitation/invitation.component';

import './participant/participant_details/unsubscribed/unsubscribed.info.module';
import './participant/participant_details/unsubscribed/unsubscribed.info.component';

import './participant/participant_details/event_details/event_details.module';
import './participant/participant_details/event_details/event_details.component';

import './participant/participant_details/invitation/invitation.module';
import './participant/participant_details/invitation/invitation.component';

import './participant/participant_details/ratings/ratings.module';
import './participant/participant_details/ratings/ratings.component';

import './participant/participant_details/registration/registration.module';
import './participant/participant_details/registration/registration.component';

import './event/eventCreate/eventCreate.module';
import './event/eventCreate/eventCreate.component';

import './event/event_detail/event_detail.module';
import './event/event_detail/event_detail.component';

import './event/event_detail/overview/calendar/calendar.module';
import './event/event_detail/overview/calendar/calendar.component';

import './event/event_request_presentation/eventRequestPresentation.module';
import './event/event_request_presentation/eventRequestPresentation.component';

import './event/event_summary/eventSummary.module';
import './event/event_summary/eventSummary.component';

import './event/managers_overview/eventsManagersView.module';
import './event/managers_overview/eventsManagersView.component';

import './event/event_detail/budget/budget.module';
import './event/event_detail/budget/budget.component';

import './shared/event_presentation/event_presentation.module';
import './shared/event_presentation/event_presentation.component';

import './shared/create_event/create_event.module';
import './shared/create_event/create_event.component';

import './shared/event_summary/event_summary.module';
import './shared/event_summary/event_summary.component';

import './shared/public_registration/public.registration.module';
import './shared/public_registration/public.registration.component';

import './shared/create_event_expressBooking/create_event_expressBooking.module';
import './shared/create_event_expressBooking/create_event_expressBooking.component';

import './shared/public_landing/public_landing.module';
import './shared/public_landing/public_landing.component';

import './event/event_detail/participants/participants.module';
import './event/event_detail/participants/participants.component';

import './auth/mfa_verify/mfa_verify.module';
import './auth/mfa_verify/mfa_verify.component';

import './auth/mfa_enable/mfa_enable.module';
import './auth/mfa_enable/mfa_enable.component';

import './show_page/show_page.module';
import './show_page/show_page.component';

// Define the `core` module
angular.module('eventlogic.modules', [
    'login',
    'register',
    'registerInvitation',
    'link.expired',
    'events',
    'account.confirm',
    'first.login',
    'mfa.verify',
    'mfa.enable',
    'activity',
    'supplier.offers',
    'tasks',
    'supplier.offer.detail',
    'supplier.offer.detail.final.details',
    'supplier.offer.thank.you.confirmation',
    'supplier.offer.confirm',
    'list',
    'offer.detail.message',
    'upcoming.offers',
    'participant-details',
    'invitation-participant',
    'unsubscribed-info',
    'participant-registration',
    'participant-ratings',
    'participant-invitation',
    'event-details',
    'event.create',
    'event-detail',
    'calendar',
    'event.summary',
    'event.request.presentation',
    'budget',
    'events.managers.view',
    'shared.event.presentation',
    'shared.create.event',
    'shared.public.registration',
    'shared.event.summary',
    'shared.create.event.express,booking',
    'shared.public.landing',
    'participants',
    'change.password',
    'show.page'
]);
