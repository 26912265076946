
/* global angular */
'use strict';

angular.module('core.serverUrlService').service('ServerUrlService', ServerUrlServiceFn);
ServerUrlServiceFn.$inject = ['$rootScope'];

function ServerUrlServiceFn($rootScope) {
    function setPublicURL(url) {
        $rootScope.publicURL = url;
    }

    return {
        setPublicURL: function (url) {
            return setPublicURL(url);
        },
    };
}
