import amountAndPriceService from '../../../../utils/price_calculate/PriceCalculationCreatorUtil';
import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class UpdateRequestMarkupModalCtrl {
    constructor($scope, Request, $element, data, close, $rootScope, EventEnum) {
        this._injected = {$scope, Request, $element, data, close, $rootScope, EventEnum};

        ScopeFunctions.addToScope($scope);

        this.markupValue = parseFloat(data.markupValue);
        this.requestId = data.requestId;
    }

    updateRequestMarkupValue() {
        const { $scope, Request, $rootScope, EventEnum } = this._injected;
        let params = {
            markupValue: this.markupValue,
        };
        $scope.startProgress('request-markup-value');
        Request.updateRequestMarkupValue(
            this.requestId,
            params,
            (response) => {
                $rootScope.$broadcast(EventEnum.REQUEST_CHANGED_IN_BUDGET, { request: response });
                this.close();
                $scope.endProgress('request-markup-value');
            },
            $scope.endWithErrorOverlay('request-markup-value')
        );
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

UpdateRequestMarkupModalCtrl.$inject = ['$scope', 'Request', '$element', 'data', 'close', '$rootScope', 'EventEnum'];
export default UpdateRequestMarkupModalCtrl;
