import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

class ogAutocompleteAddressSearchBoxCtrl {
    constructor($scope, $element, $timeout) {
        this._injected = {$scope, $element, $timeout};

        this.handleOnChanged = this.handleOnChanged.bind(this);
        this.initGoogleAddressSearchBox();
        ScopeFunctions.addToScope($scope);
    }

    initGoogleAddressSearchBox() {
        window.googleMapsCallback(() => {
            // The use of unique Id for google places option causes error in LIVE
            // Apparently, google API tries to add the "autocomplete" effect before the element gets ready
            var options = {
                strictBounds: true,
                types: ['geocode'],
            };
            const autocomplete = new google.maps.places.Autocomplete($('#google_address_search_box')[0], options);
            google.maps.event.addListener(autocomplete, 'place_changed', () => {
                this.address.displayAddress = $('#google_address_search_box').val();
                this.handleOnChanged();
            });
        });
    }

    handleOnChanged() {
        if (this.addressChangeCallback) {
            this.addressChangeCallback();
        }
    }
}

ogAutocompleteAddressSearchBoxCtrl.$inject = ['$scope', '$element', '$timeout'];
export default ogAutocompleteAddressSearchBoxCtrl;
