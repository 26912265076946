(function () {
    'use strict';

    angular.module('ogTimePicker').directive('ogTimePicker', [
        '$rootScope',
        'TimezoneService',
        '$translate',
        '$timeout',
        function ($rootScope, TimezoneService, $translate, $timeout) {
            function getTimeFrom() {
                return jQuery('#timeFrom');
            }

            function getTimeTo() {
                return jQuery('#timeTo');
            }

            function initTimePickers() {
                var lang = $rootScope.langSelected;
                getTimeFrom().timepicker({ changeOnKeypress: false });
                getTimeTo().timepicker({ changeOnKeypress: false });
                setTimePickerFormat(lang);
            }

            function setupTimeTo(scope) {
                var baseDate = extractBaseDate(scope);
                var timeTo = new Date(TimezoneService.toLocalTime(scope.timeTo + baseDate));
                getTimeTo().timepicker('setTime', timeTo);
                if (scope.onchanged && typeof (scope.onchanged === 'function')) {
                    scope.onchanged(scope.modelNameTimeTo, scope.timeTo);
                }
                $timeout(function () {
                    getTimeTo().timepicker('setTime', timeTo);
                    updateTimeTo(scope);
                });
            }
            function setupTimeFrom(scope) {
                var baseDate = extractBaseDate(scope);
                var timeFrom = new Date(TimezoneService.toLocalTime(scope.timeFrom + baseDate));
                var timeTo = new Date(TimezoneService.toLocalTime(scope.timeTo + baseDate));
                getTimeFrom().timepicker('setTime', timeFrom);
                if (scope.onchanged && typeof (scope.onchanged === 'function')) {
                    scope.onchanged(scope.modelNameTimeFrom, scope.timeFrom);
                }
                $timeout(function () {
                    getTimeFrom().timepicker('setTime', timeFrom);
                    updateTimeFrom(scope);
                });
                if (timeFrom.getTime() && timeTo.getTime() && timeFrom.getTime() > timeTo.getTime()) {
                    setupToTimeSameAsFromTime(scope, timeFrom);
                }
            }

            function setTimePickerFormat(lang) {
                if (lang == 'en_US') {
                    getTimeFrom().timepicker('option', { timeFormat: 'g:ia' });
                    getTimeTo().timepicker('option', { timeFormat: 'g:ia' });
                } else {
                    getTimeFrom().timepicker('option', { timeFormat: 'H:i' });
                    getTimeTo().timepicker('option', { timeFormat: 'H:i' });
                }
            }

            function updateTimeFrom(scope) {
                var baseDate = extractBaseDate(scope);
                scope.timeFrom = TimezoneService.fromLocalTime(getFromTime(baseDate)).getTime() - baseDate;
                updateTimeTo(scope);
                $rootScope.safeApply(scope);
            }

            function updateTimeTo(scope) {
                var baseDate = extractBaseDate(scope);
                scope.timeTo = TimezoneService.fromLocalTime(getToTime(baseDate)).getTime() - baseDate;
                $rootScope.safeApply(scope);
            }

            function getFromTime(baseDate) {
                var fromDateTime = new Date(baseDate);
                if (fromDateTime) {
                    fromDateTime.setHours(0, 0, 0, 0);
                    fromDateTime.setSeconds(getTimeFrom().timepicker('getSecondsFromMidnight'));
                }
                return fromDateTime;
            }

            function getToTime(baseDate) {
                var toDateTime = new Date(baseDate);
                if (toDateTime) {
                    toDateTime.setHours(0, 0, 0, 0);
                    toDateTime.setSeconds(getTimeTo().timepicker('getSecondsFromMidnight'));
                }
                return toDateTime;
            }

            function setupToTimeSameAsFromTime(scope, fromDateTime) {
                if (scope.addHourToDate && fromDateTime) {
                    var addHour = parseInt(scope.addHourToDate);
                    getTimeTo().timepicker('setTime', moment(fromDateTime).add(addHour, 'hours').toDate());
                } else {
                    getTimeTo().timepicker('setTime', fromDateTime);
                }
            }

            function extractBaseDate(object) {
                var baseDate = object.baseDate;
                if (!baseDate) {
                    baseDate = 0;
                }
                return baseDate;
            }

            return {
                scope: {
                    timeFrom: '=',
                    timeTo: '=',
                    baseDate: '=ogBaseDate',
                    modelNameTimeFrom: '@',
                    modelNameTimeTo: '@',
                    onchanged: '=onchanged',
                    addHourToDate: '=',
                },
                transclude: true,
                priority: 10,
                templateUrl: './app/components/directives/time_picker/og_time_picker.directive.view.html',
                link: function (scope) {
                    scope.$watch('timeFrom', function () {
                        setupTimeFrom(scope);
                    });

                    scope.$watch('timeTo', function () {
                        setupTimeTo(scope);
                    });

                    //Watcher for the baseDate
                    scope.$watch('baseDate', function () {
                        setupTimeFrom(scope);
                        setupTimeTo(scope);
                    });

                    initTimePickers();

                    //prevent scrolling for parent element for from time
                    jQuery('#timeFrom').on('click', function () {
                        $('.ui-timepicker-wrapper').addClass('custom-ui');
                        uiTimePickerScrollTop();
                        setTimeout(() => {
                            uiTimePickerScrollTop();
                        }, 300);
                    });

                    //prevent scrolling for parent element for from time
                    jQuery('#timeTo').on('click', function () {
                        $('.ui-timepicker-wrapper').addClass('custom-ui');
                        uiTimePickerScrollTop();
                        setTimeout(() => {
                            uiTimePickerScrollTop();
                        }, 300);
                    });

                    function uiTimePickerScrollTop() {
                        $('.ui-timepicker-wrapper:visible').scrollTop(
                            $('.ui-timepicker-wrapper:visible .ui-timepicker-selected').position().top
                        );
                        $('.ui-timepicker-wrapper').on('DOMMouseScroll mousewheel', function (ev) {
                            var $this = $(this),
                                scrollTop = this.scrollTop,
                                scrollHeight = this.scrollHeight,
                                height = $this.innerHeight(),
                                delta = ev.originalEvent.wheelDelta,
                                up = delta > 0;

                            var prevent = function () {
                                ev.stopPropagation();
                                ev.preventDefault();
                                ev.returnValue = false;
                                return false;
                            };

                            if (!up && -delta > scrollHeight - height - scrollTop) {
                                $this.scrollTop(scrollHeight);
                                return prevent();
                            } else if (up && delta > scrollTop) {
                                $this.scrollTop(0);
                                return prevent();
                            }
                        });
                    }

                    //Event handling for the change in the from time pickers
                    jQuery('#timeFrom').on('change', function () {
                        updateTimeFrom(scope);
                    });

                    //Event handling for the change in the to time pickers
                    jQuery('#timeTo').on('change', function () {
                        updateTimeTo(scope);
                    });

                    //Trigger datetime format change on language change
                    scope.$on('lang:change', function (event, data) {
                        setTimePickerFormat(data.lang);
                    });
                },
            };
        },
    ]);
})();
