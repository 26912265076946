import _ from 'underscore';

class OfferStatusButtonCtrl {
    constructor($scope) {
        this._injected = { $scope };
    }

    changeOfferStatus(event, offer, newStatus) {
        if (event.currentTarget.tagName !== 'SPAN') {
            let data = { offer: offer, status: newStatus };
            this.changeOfferStatusCallback(data);
        }
    }

    offersInEditedByCreatorState() {
        let offers = _.filter(this.request.requestOffers, function (offer) {
            return offer.status.name === 'EDITED_BY_CREATOR';
        });
        return offers;
    }

    displayReasonOnDisabledButton() {
        let display = false;
        if (this.disableForEditedByCreatorState()) {
            display = true;
        }
        return display;
    }

    disableForEditedByCreatorState() {
        this.editedByCreatorOffers = this.offersInEditedByCreatorState();
        if (this.editedByCreatorOffers && this.editedByCreatorOffers.length > 1) {
            return true;
        }
        return false;
    }

    disabledButton(status) {
        if (this.offer.offerParts.length < 1 && status === 'SENT') {
            return true;
        }
        if (this.disabled) {
            return true;
        }

        if (this.disableForEditedByCreatorState()) {
            return true;
        }
        return false;
    }
}
OfferStatusButtonCtrl.$inject = ['$scope'];
export default OfferStatusButtonCtrl;
