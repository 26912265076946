'use strict';
angular.module('core.existingServices').factory('PagingService', [
    '$rootScope',
    '$timeout',
    function ($rootScope, $timeout) {
        // Delay between the Reader is called and a read request is sent.
        // In case the reader is called again during the time period then the timeout starts again.
        // So if calls are made in a sequence then a request is sent only after the last one.
        var READ_DELAY = 500; // milliseconds

        // Maximum length of read call sequence after which a request is sent even in the case that the
        // sequence is not over.
        // That means if there is a longer sequence a request is sent anyway even during the read sequence calls.
        var MAX_READ_DELAY = 2000; // milliseconds

        function Reader(params) {
            this.readFunction = params.readFunction;
            this.broadcastNamePrefix = params.broadcastNamePrefix;
            this.paramsFunction = params.paramsFunction;
            this.success = params.success;
            this.error = params.error;
            this.scope = params.scope;
            this.responseTimestamp = 0;
            this.requestTimestamp = 0;
            this.readSequenceStart = 0;
        }

        Reader.prototype.init = function () {
            if (this.paramsFunction) {
                this.scope.$on(
                    this.broadcastNamePrefix + '/page',
                    function (event, pagingArgs) {
                        this.read(this.paramsFunction(pagingArgs), this.success, this.error, pagingArgs);
                    }.bind(this)
                );
            }
        };

        Reader.prototype.reload = function () {
            this.scope.$broadcast(this.broadcastNamePrefix + '/reload');
        };

        Reader.prototype.read = function (params, success, error, pagingArgs) {
            var timeoutTimestamp = (this.timeoutTimestamp = new Date().getTime());
            if (this.readSequenceStart === 0) {
                this.readSequenceStart = timeoutTimestamp;
            }
            $timeout(
                function () {
                    var timeSinceSequenceStart = new Date().getTime() - this.readSequenceStart;
                    if (timeoutTimestamp == this.timeoutTimestamp || timeSinceSequenceStart > MAX_READ_DELAY) {
                        this.readSequenceStart = 0;
                        this.doRead(params, success, error, pagingArgs);
                    }
                }.bind(this),
                READ_DELAY
            );
        };

        Reader.prototype.doRead = function (params, success, error, pagingArgs) {
            if (!success) {
                success = this.success;
            }
            if (!error) {
                error = this.error;
            }
            if (!pagingArgs) {
                pagingArgs = this.pagingArgs;
            }

            var successCallback = function (response) {
                if (!response.timestamp || this.responseTimestamp < response.timestamp) {
                    this.responseTimestamp = response.timestamp;
                    success(response, pagingArgs);
                    if (pagingArgs && response.totalCount) {
                        pagingArgs.total = response.totalCount;
                    }
                    this.scope.$broadcast(this.broadcastNamePrefix + '/loaded', pagingArgs);
                }
                this.requestTimestamp = null;
            }.bind(this);

            var errorCallback = function (response) {
                this.scope.$broadcast(this.broadcastNamePrefix + '/error', pagingArgs);
                error(response);
                this.requestTimestamp = null;
            }.bind(this);

            this.scope.$broadcast(this.broadcastNamePrefix + '/loading');
            this.requestTimestamp = new Date().getTime();
            this.readFunction(params, successCallback, errorCallback);
        };

        return {
            createReader: function (params) {
                var reader = new Reader(params);
                reader.init();
                return reader;
            },
        };
    },
]);

