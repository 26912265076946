'use strict';
angular.module('core.existingServices').factory('LandingPage', [
    '$resource',
    function ($resource) {
        var v1LandingRest = $resource(
            'api/v1/landing/load',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );

        var v1LandingGeoTags = $resource(
            'api/v1/landing/landingGeoTags',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );

        var v1LandingActivities = $resource(
            'api/v1/landing/landingActivities',
            {},
            {
                list: {method: 'POST', isArray: false},
            }
        );

        var publicSearchQuery = $resource(
            'api/v1/landing/publicSearchQuery',
            {},
            {
                do: {method: 'POST', isArray: false},
            }
        );

        var getTagAndMergedCategoriesRest = $resource(
            'api/v1/landing/geoTagsAndMergedCategories',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            load: function (jsonObject, successCallback, errorCallback) {
                return v1LandingRest.list(jsonObject, successCallback, errorCallback);
            },

            listLandingGeoTags: function (jsonObject, successCallback, errorCallback) {
                return v1LandingGeoTags.list(jsonObject, successCallback, errorCallback);
            },

            listLandingActivities: function (jsonObject, successCallback, errorCallback) {
                return v1LandingActivities.list(jsonObject, successCallback, errorCallback);
            },
            sendQueryEmail: function (params, successCallback, errorCallback) {
                return publicSearchQuery.do(params, successCallback, errorCallback);
            },
            getTagAndMergedCategories: function (successCallback, errorCallback) {
                return getTagAndMergedCategoriesRest.get({}, successCallback, errorCallback);
            },
        };
    },
]);

