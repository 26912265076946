'use strict';
import {OgModal} from "../../utils/global/ogModal";
import CommonFunctions from '../../utils/CommonFunctionUtil'

angular.module('dialog').directive('ogAlertModal', [
    '$rootScope',
    '$timeout',
    function ($rootScope, $timeout) {
        return new OgModal({
            name: 'alert',
            type: 'custom',
            controller: function ($scope) {
                $scope.initDialog = function (settings) {
                    $scope.okCallBack = settings.okCallBack;
                    $scope.message = settings.message;
                        if ($scope.message) {
                            $scope.messages = [{ messageKey: $scope.message, params: settings.params }];
                        }
                        if (settings.response) {
                            if (!$scope.message) {
                                $scope.messages = CommonFunctions.errorMessages(settings.response, true);
                            }
                            $scope.error = settings.response;
                            if (settings.response.data) {
                                $scope.details = settings.response.data.details;
                                if ($scope.details) {
                                    $scope.details =
                                        'Message details:\n\n\n\n' +
                                        $scope.messages[0] +
                                        '\n\n' +
                                        JSON.stringify(settings.response.data.localisedMessages) +
                                        '\n\n' +
                                        $scope.details;
                                }
                            }
                        }
                        if (settings.size) {
                            $scope.sizeClass = settings.size;
                        } else {
                            $scope.sizeClass = 'tiny';
                        }
                        $scope.isHtml = !!settings.html;
                        $scope.showDetailClicks = 0;
                    };

                    $scope.clearDialog = function () {
                        $scope.error = null;
                        $scope.messages = null;
                        $scope.message = null;
                    };

                    $scope.ok = function () {
                        if ($scope.okCallBack) {
                            $scope.okCallBack();
                        }
                        $scope.close();
                    };

                    $scope.openDetail = function () {
                        $scope.showDetailClicks++;
                        if ($scope.showDetailClicks >= 5) {
                            $scope.showDetail = true;
                            $timeout(function () {
                                $('#detailArea').focus().select();
                            }, 50);
                        }
                    };

                    $scope.hideDetail = function () {
                        $scope.showDetail = false;
                    };

                    $scope.selectDetail = function () {
                        $('#detailArea').focus().select();
                    };
                },
            });
        },
    ]);
