import controller from './og_timeless_activity.controller';

(function () {
    'use strict';

    angular.module('ogTimelessActivity').directive('ogTimelessActivity', [
        function () {
            return {
                scope: {
                    offerPart: '=',
                    callBack: '&',
                },
                templateUrl: './app/components/directives/timeless_activity/og_timeless_activity.directive.view.html',
                restrict: 'AE',
                controller,
                controllerAs: 'timelessActivity',
                bindToController: true,
            };
        },
    ]);
})();
