import controller from './og_supplier_dropdown.controller';

(function () {
    'use strict';

    angular.module('ogSupplierDropdown').directive('ogSupplierDropdown', [
        function () {
            return {
                scope: {
                    placeholder: '@',
                    list: '=',
                    selected: '=',
                    inputClass: '@',
                    minLength: '@',
                    remoteUrl: '=',
                    updateSelectedSupplierNameCallback: '&',
                    selectAndSearch: '&',
                },
                templateUrl: './app/components/directives/og-supplier-dropdown/og_supplier_dropdown.html',
                restrict: 'AE',
                controller,
                controllerAs: 'ogSupplierDropdownCtrl',
                bindToController: true,
            };
        },
    ]);
})();
