import {getHttpConfig, getLocalToken, getLocalToken_temp} from "../../../../utils";

angular.module('first.login').component('firstLogin', {
    templateUrl: './app/components/modules/auth/first_time_login/first_time_login.template.html',
    controller: ['$http','$stateParams', '$rootScope', '$state', 'AuthenticationService', 'EventEnum', '$translate', 'CustomerUser', 'CommonFunctions','ClientService',
        function FirstTimeLoginController(
            $http,
            $stateParams,
            $rootScope,
            $state,
            AuthenticationService,
            EventEnum,
            $translate,
            CustomerUser,
            CommonFunctions,
            ClientService
        ) {
            var vm = this;

            vm.changePassword = changePasswordFn;
            vm.validatePassword = validatePasswordFn;
            vm.showPasswordStrength = showPasswordStrengthFn;
            vm.loginData = $stateParams.resp;
            vm.password1 = '';
            vm.password2 = '';
            vm.passwordChanged = window.localStorage.passwordChanged;
            vm.passwordsNotMatch = passwordsNotMatchFn;
            vm.switchToEn = switchToEnFn;
            vm.switchToSv = switchToSvFn;
            vm.switchToNb = switchToNbFn;
            vm.switchToDa = switchToDaFn;
            vm.switchToFi = switchToFiFn;
            vm.redirectMe = redirectMeFn;
            vm.generatedPassword = '';
            vm.getGeneratedPassword = getGeneratedPasswordFn;
            vm.fillPasswordFields = fillPasswordFieldsFn;
            init()

            if ($stateParams.locale) {
                if ($stateParams.locale === 'en') {
                    vm.switchToEn();
                } else if ($stateParams.locale === 'sv') {
                    vm.switchToSv();
                } else if ($stateParams.locale === 'nb') {
                    vm.switchToNb();
                } else if ($stateParams.locale === 'da') {
                    vm.switchToDa();
                } else {
                    vm.switchToFi();
                }
            } else {
                vm.activeEn = $rootScope.langSelected === 'en_US' ? 'active' : '';
                vm.activeSv = $rootScope.langSelected === 'sv_SV' ? 'active' : '';
                vm.activeNb = $rootScope.langSelected === 'nb_Nb' ? 'active' : '';
                vm.activeDk = $rootScope.langSelected === 'da_DK' ? 'active' : '';
                vm.activeFi = $rootScope.langSelected === 'fi_FI' ? 'active' : '';
            }

            function init(){
                var tmp_logged = window.localStorage.getItem('tempToken');
                if(!tmp_logged){
                    window.location.href = '/';
                } else if(!vm.loginData){
                    let _authToken = getLocalToken_temp();
                    $http
                        .get('login/getLoggedUser', {
                            headers: {
                                'X-Auth-Token': _authToken
                            }
                        }).then((resp) => {
                        vm.loginData = angular.copy(resp);
                        ClientService.setClientName(resp.clientName);
                    }, (err) => {
                        console.log('getLoggedUser error: ' + err);
                    });
                }else {

                }

            }

            function changePasswordFn() {
                if (!vm.passwordForm.$valid) {
                    CommonFunctions.touchFormFields(vm.passwordForm);
                    return;
                }
                if (vm.passwordsNotMatch() || !vm.validatePassword()) {
                    return;
                }
                let userData = vm.loginData.data
                vm.userEmail = userData.email;
                var data = {
                    id: userData.id,
                    password: vm.password1,
                    form: 'passwordForm',
                    email: vm.userEmail,
                };
                AuthenticationService.changeFirstTimePassword(data, function (data) {
                    if (data.success) {
                        window.localStorage.setItem('passwordChanged', true);
                        vm.passwordChanged = window.localStorage.getItem('passwordChanged');
                    }
                });
            }

            function validatePasswordFn() {
                if (!vm.password1) {
                    return true;
                }
                let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)');
                return regex.test(vm.password1);
            }

            function passwordsNotMatchFn() {
                return (vm.password1 || vm.password2) && vm.password1 !== vm.password2;
            }

            function showPasswordStrengthFn(password) {
                if (password) {
                    //Uppercase Words only
                    if (password.match(/[A-Z]/) != null) {
                        jQuery('#uppercase-text .progress-text').addClass('active');
                    } else {
                        jQuery('#uppercase-text .progress-text').removeClass('active');
                    }
                    //Lowercase words only
                    if (password.match(/[a-z]/) != null) {
                        jQuery('#lowercase-text .progress-text').addClass('active');
                    } else {
                        jQuery('#lowercase-text .progress-text').removeClass('active');
                    }
                    //Digits only
                    if (password.match(/[0-9]/) != null) {
                        jQuery('#number-in-text .progress-text').addClass('active');
                    } else {
                        jQuery('#number-in-text .progress-text').removeClass('active');
                    }
                    //Password length
                    if (password.length > 7) {
                        jQuery('#eight-characters-long .progress-text').addClass('active');
                    } else {
                        jQuery('#eight-characters-long .progress-text').removeClass('active');
                    }
                } else {
                    jQuery('.progress-bar-wrap .progress-bar-item .progress-text').each(function () {
                        jQuery(this).removeClass('active');
                    });
                }
            }

            // fetch the suggested password and set to scope
            function getGeneratedPasswordFn() {
                CustomerUser.getPassword(
                    function (result) {
                        vm.generatedPassword = result.generatedPassword;
                    },
                    function (error) {
                        console.log('>>> Error has occurred while getting generated password from API ' + error);
                    }
                );
            }

            //fills the password with the suggested password
            function fillPasswordFieldsFn() {
                vm.password1 = vm.generatedPassword;
                vm.password2 = vm.generatedPassword;
            }

            function switchToEnFn() {
                $rootScope.switchLang('en_US');
                vm.activeEn = 'active';
                vm.activeSv = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToSvFn() {
                $rootScope.switchLang('sv_SV');
                vm.activeSv = 'active';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToNbFn() {
                $rootScope.switchLang('nb_NB');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = 'active';
                vm.activeDk = '';
                vm.activeFi = '';
            }

            function switchToDaFn() {
                $rootScope.switchLang('nb_NB');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = 'active';
                vm.activeFi = '';
            }

            function switchToFiFn() {
                $rootScope.switchLang('fi_FI');
                vm.activeSv = '';
                vm.activeEn = '';
                vm.activeNb = '';
                vm.activeDk = '';
                vm.activeFi = 'active';
            }

            function redirectMeFn() {
                AuthenticationService.logout().then(redirectToHome);
            }

            function redirectToHome() {
                $state.go('auth.login');
            }
        }]
});

