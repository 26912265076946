'use strict';
angular.module('core.existingServices').factory('ActivityService', [
    '$resource',
    function ($resource) {
        var activityRest = $resource(
            'api/v1/activity/create',
            {},
            {
                add: {method: 'POST', isArray: false},
            }
        );

        var fetchActivities = $resource(
            'api/v1/activity/getAllActivities',
            {},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            addActivity: function (jsonObject, success, error) {
                return activityRest.add(jsonObject, success, error);
            },
            fetchActivity: function (jsonObject, success, error) {
                return fetchActivities.get(jsonObject, success, error);
            },
        };
    },
]);

