
export default function (appModule) {
    //used for the constants in a global scope
    appModule.constant('GlobalConstants', {
        CREATOR_WAITING_STATES: [
            'EDITED_WAITING_FOR_CREATOR',
            'ADDED_WAITING_FOR_CREATOR',
            'DELETED_WAITING_FOR_CREATOR',
        ],
        CREATOR_DECLINED_STATES: [
            'EDITED_WAITING_FOR_CREATOR_DECLINED',
            'ADDED_WAITING_FOR_CREATOR_DECLINED',
            'DELETED_WAITING_FOR_CREATOR_DECLINED',
        ],
        SUPPLIER_WAITING_STATES: [
            'EDITED_WAITING_FOR_SUPPLIER',
            'ADDED_WAITING_FOR_SUPPLIER',
            'DELETED_WAITING_FOR_SUPPLIER',
        ],
        SUPPLIER_DECLINED_STATES: [
            'EDITED_WAITING_FOR_SUPPLIER_DECLINED',
            'ADDED_WAITING_FOR_SUPPLIER_DECLINED',
            'DELETED_WAITING_FOR_SUPPLIER_DECLINED',
        ],
        CREATOR_TO_ANSWER_STATES: [
            'EDITED_WAITING_FOR_CREATOR',
            'ADDED_WAITING_FOR_CREATOR',
            'DELETED_WAITING_FOR_CREATOR',
            'EDITED_WAITING_FOR_SUPPLIER_DECLINED',
            'ADDED_WAITING_FOR_SUPPLIER_DECLINED',
            'DELETED_WAITING_FOR_SUPPLIER_DECLINED',
        ],
        SUPPLIER_TO_ANSWER_STATES: [
            'EDITED_WAITING_FOR_SUPPLIER',
            'ADDED_WAITING_FOR_SUPPLIER',
            'DELETED_WAITING_FOR_SUPPLIER',
            'EDITED_WAITING_FOR_CREATOR_DECLINED',
            'ADDED_WAITING_FOR_CREATOR_DECLINED',
            'DELETED_WAITING_FOR_CREATOR_DECLINED',
        ],
        SUPPLIER_MAX_SIZE: 24, //initial numbers of suppliers in supplier search,
        SUPPLIER_SIZE_PER_PAGE: 8, //numbers of supplier per fetch
        DEFAULT_MAP_ZOOM: 5,

        //Default Values for Scrolling Effect
        DEFAULT_SCROLL_OFFSET: 40,
        DEFAULT_SCROLL_DURATION: 2000,

        //Fade Out Time Constants
        FADEOUT_TIME: 4000,
        FADEOUT_TIMEOUT_DURATION: 4000,

        //Event Guide Views
        GUIDE_VIEWS: [
            { id: 1, name: 'EVENT_CREATED' },
            { id: 2, name: 'SEND_REQUEST' },
        ],
        /* language codes start - do not change*/
        LANG_ENGLISH: 'en_US',
        LANG_SWEDISH: 'sv_SV',
        /* language codes end - do not change*/

        /* Accommodation start time stamp should be 5PM*/
        ACCOMMODATION_DEFAULT_START_TIMESTAMP: 61200000,
        /* Accommodation end time stamp should be 10am*/
        ACCOMMODATION_DEFAULT_END_TIMESTAMP: 122400000,

        /* Offer guide types*/
        SHOW_PARTICIPANT_INFO: 'SHOW_PARTICIPANT_INFO',
        SHOW_EXPORT_INFO: 'SHOW_EXPORT_INFO',
        SHOW_ALL: 'SHOW_ALL',
        SHOW_NONE: 'SHOW_NONE',
        VAT_DROPDOWN_LIST: [0, 6, 12, 25], //0, 6, 12 and 25 %
        DEFAULT_VAT: 25,

        MILLISECONDS_IN_A_DAY: 86400000,
        TEN_AM_MILLISECONDS: 36000000,
        FIVE_PM_MILLISECONDS: 61200000,

        PRESENTATION: 'PRESENTATION',
        PROFILE: 'PROFILE',
        RESOURCE: 'RESOURCE',
        SUPPLIERPROFILE: 'SUPPLIERPROFILE',
    });
};
