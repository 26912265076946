'use strict';

angular.module('core.existingServices').factory('EventFortNoxSetting', [
    '$resource',
    function ($resource) {
        var eventFortNoxSettingRest = $resource(
            'api/v1/eventFortNoxSetting/:id',
            {id: '@id'},
            {
                list: {method: 'GET', isArray: false},
                get: {method: 'GET', isArray: false},
                update: {method: 'PUT', isArray: false},
                save: {method: 'POST', isArray: false},
                delete: {method: 'DELETE', isArray: false},
            }
        );

        var listAccountsAndCostCentersRest = $resource(
            'api/v1/eventFortNoxSetting/:eventId/listAccountsAndCostCenters',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        var eventFortNoxSettingGetRest = $resource(
            'api/v1/eventFortNoxSetting/:eventId',
            {eventId: '@eventId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            update: function (eventFortNoxSetting, successCallback, errorCallback) {
                return eventFortNoxSettingRest.update(
                    {id: eventFortNoxSetting.id},
                    eventFortNoxSetting,
                    successCallback,
                    errorCallback
                );
            },
            save: function (data, successCallback, errorCallback) {
                return eventFortNoxSettingRest.save({}, data, successCallback, errorCallback);
            },
            get: function (eventFortNoxSettingId, success, error) {
                return eventFortNoxSettingGetRest.get({eventId: eventFortNoxSettingId}, success, error);
            },
            listAccountsAndCostCentersRest: function (eventId, success, error) {
                return listAccountsAndCostCentersRest.get({eventId: eventId}, success, error);
            },
            delete: function (id, success, error) {
                return eventFortNoxSettingRest['delete']({id: id}, success, error);
            },
        };
    },
]);

