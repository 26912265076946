(function () {
    'use strict';
    angular.module('starRating').directive('starRating', function () {
        return {
            restrict: 'EA',
            template:
                '<ul class="star-rating" ng-class="{readonly: readonly}">' +
                '  <li ng-repeat="star in stars" class="star" ng-class="{filled: star.filled}" ' +
                'ng-style="fSize"' +
                ' ng-click="toggle($index)">' +
                '    <i class="fa fa-star"></i>' +
                '  </li>' +
                '</ul>',
            scope: {
                ratingValue: '=ngModel',
                max: '=?', // optional (default is 5)
                onRatingSelect: '=?',
                readonly: '=?',
                size: '=?',
            },
            link: function (scope) {
                if (scope.max == undefined) {
                    scope.max = 5;
                }
                if (scope.size == undefined) {
                    scope.fSize = { 'font-size': 10 };
                } else {
                    scope.fSize = { 'font-size': scope.size };
                }

                function updateStars() {
                    scope.stars = [];
                    for (var i = 0; i < scope.max; i++) {
                        scope.stars.push({
                            filled: i < scope.ratingValue,
                        });
                    }
                }

                scope.toggle = function (index) {
                    if (scope.readonly == undefined || scope.readonly === false) {
                        scope.ratingValue = index + 1;
                        if (typeof scope.onRatingSelect == 'function') {
                            scope.onRatingSelect({
                                rating: index + 1,
                            });
                        }
                    }
                };
                scope.$watch('ratingValue', function () {
                    //if (newValue) { //removed because stars are not being displayed when ratingValue is 0 or not set
                    updateStars();
                    //}
                });
            },
        };
    });
})();
