import {ScopeFunctions} from 'src/app/utils/global/scopeFunctions'
import {OgModal} from "../../../utils/global/ogModal";

(function () {
    /* global angular */
    'use strict';

    angular.module('ogTasksStream').directive('ogTasksStream', function () {
        return {
            restrict: 'EA',
            templateUrl: './app/components/directives/tasks_stream/og_task_stream.directive.view.html',
            replace: true,
            controllerAs: 'tasks',
            bindToController: true,
            controller: [
                '$rootScope',
                '$scope',
                '$stateParams',
                'Activity',
                'AuthenticationService',
                function ($rootScope, $scope, $stateParams, Activity, AuthenticationService) {
                    var vm = this;

                    vm.clearAttachedFiles = clearAttachedFilesFn;
                    vm.errorCallback = errorCallbackFn;
                    vm.getTasksPaginated = getTasksPaginatedFn;
                    vm.init = initFn;
                    vm.loadNextPage = loadNextPageFn;
                    vm.pageChanged = pageChangedFn;
                    vm.paginatedActivityStreamCallback = paginatedActivityStreamCallbackFn;
                    vm.resetActivitiesList = resetActivitiesListFn;
                    vm.taskTypeChanged = taskTypeChangedFn;
                    vm.updateMessage = updateMessageFn;

                    vm.showTaskFilterArray = [
                        { id: 0, tag: 'showMineTask' },
                        { id: 1, tag: 'showAllTask' },
                    ];

                    vm.showTaskTypeFilterArray = [
                        { id: 0, name: 'ALL', value: 'common.all' },
                        { id: 1, name: 'PENDING', value: 'common.pending' },
                        { id: 2, name: 'COMPLETED', value: 'common.complete' },
                    ];

                    vm.showTaskFilter = vm.showTaskFilterArray[1].tag;
                    vm.taskType = vm.showTaskTypeFilterArray[0];

                    vm.currentPage = 1;
                    vm.numPerPage = 6;
                    ScopeFunctions.addToScope($scope);
                    resetPaginationParams();
                    vm.resetActivitiesList();
                    vm.init();

                    function clearAttachedFilesFn() {
                        vm.attachedFiles = { uploads: null, links: null };
                    }

                    function errorCallbackFn(error) {
                        vm.error = error;
                        vm.streamSpinner = false;
                        vm.messageSpinner = false;
                    }

                    function getTasksPaginatedFn() {
                        var params = {
                            eventId: vm.eventId,
                            showMyTasks: vm.showMyTasks,
                            taskType: vm.taskType.name,
                            page: vm.params.offset,
                            pageSize: vm.numPerPage,
                            type: 'task',
                        };
                        if (vm.level != 'EVENT') {
                            params.requestId = vm.requestId;
                            params.offerId = vm.offerId;
                        }
                        Activity.list(params, vm.paginatedActivityStreamCallback, vm.errorCallback);
                    }

                    function getUserList() {
                        vm.sharedEventUsers = $rootScope.sharedUsers;
                        vm.initSelectedTaskUsers();
                        Event.getUsers({ eventId: vm.eventId }, function (response) {
                            vm.sharedEventUsers = response;
                            vm.initSelectedTaskUsers();
                        });
                    }

                    function initFn() {
                        vm.clearAttachedFiles();
                        vm.messageSpinner = false;
                        vm.eventId = vm.eventId || $stateParams.eventId;
                        if (vm.eventId) {
                            getUserList();
                        }
                        updateLoggedUserStatus();
                        vm.getTasksPaginated();
                    }

                    function loadNextPageFn() {
                        vm.getTasksPaginated();
                    }

                    function pageChangedFn() {
                        setPaginationParams();
                        vm.loadNextPage();
                    }

                    function paginatedActivityStreamCallbackFn(response) {
                        vm.activities = response.list;
                        vm.streamSpinner = false;
                        vm.messageSpinner = false;
                        vm.totalActivitiesCount = response.totalCount;
                        vm.page = vm.page + 1;
                        if (vm.activities.length !== 0 && vm.activities.length % 20 === 0) {
                            vm.restrictAutoLoad = true;
                        }
                        vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                    }

                    function resetActivitiesListFn() {
                        resetPaginationParams();
                        vm.page = 0;
                        vm.restrictAutoLoad = false;
                        vm.totalActivitiesCount = -1; //default value
                        //vm.activities = [];
                    }

                    function resetPaginationParams() {
                        vm.params = { offset: 0, max: vm.numPerPage };
                    }

                    function setPaginationParams() {
                        vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                        vm.params.max = vm.numPerPage;
                    }

                    function taskTypeChangedFn() {
                        vm.resetActivitiesList();
                        vm.loadNextPage();
                    }

                    function updateLoggedUserStatus() {
                        vm.loggedUserStatus = AuthenticationService.getLoggedUserStatus();
                    }

                    function updateMessageFn(message) {
                        OgModal.open('task:edit', {
                            message: message,
                            changeCallback: vm.getTasksPaginated,
                        });
                    }

                    $scope.$on('tasks.showTaskFilter/changed', function (event, value) {
                        angular.forEach(vm.showTaskFilterArray, function (item) {
                            if (item.tag == value) {
                                vm.showMyTasks = value == 'showMineTask';
                                vm.resetActivitiesList();
                                vm.loadNextPage();
                            }
                        });
                    });
                },
            ],
            link: function (scope) {
                if (scope.activityFilter == 'task') {
                    scope.isTask = true;
                }
            },
        };
    });
})();
