import { environment } from "../../../../environments/environment";

/**
 * Created by sampura on 1/23/20.
 */
export default function (appModule) {
    appModule.factory("ApiInterceptor", function ($rootScope, $q) {
        const BASE_URL = environment.apiUrl;
        //   const STAGE_URL = "https://qat-bigtravel.eventlogic.se/";

        return {
            request: function (config) {
                var url = config.url ? config.url : '';
                url = url.replace(/ /g, '');
                if (url.indexOf("api/") === 0 || url.indexOf("login/") === 0) {
                    config.url = BASE_URL + url;
                }
                return config || $q.when(config);
            }
        };
    });
}