'use strict';
import {OgModal} from "../../utils/global/ogModal";
import {ScopeFunctions} from "../../utils/global/scopeFunctions";

angular.module('dialog').directive('ogPresentationModal', [
    '$log',
    '$rootScope',
    'Presentation',
    'CommonPresentation',
    function ($log, $rootScope, CommonPresentation) {
        return new OgModal({
            name: 'presentation',
            type: 'custom',
            controller: function ($scope) {
                ScopeFunctions.addToScope($scope);
                $scope.initDialog = function (data) {
                        $scope.supplierId = data.supplierId;
                        $scope.presentation = data.presentation;
                        if ($scope.presentation === null && $scope.supplierId !== null) {
                            $scope.startProgress();
                            CommonPresentation.get(
                                { supplierId: $scope.supplierId },
                                function (result) {
                                    $scope.presentation = result;
                                    $scope.endProgress();
                                },
                                $scope.endWithErrorOverlay
                            );
                        }
                    };

                    $scope.clearDialog = function () {
                        $scope.supplierId = null;
                        $scope.presentation = null;
                    };
                },
            });
        },
    ]);

