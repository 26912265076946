import {ScopeFunctions} from '../../../../utils/global/scopeFunctions';

class ResourcePresentationModalCtrl {
    constructor(
        $scope,
        $rootScope,
        $translate,
        $element,
        data,
        close,
        SupplierResources,
        FilledSupplierResourceField,
        DynamicFormUtilService,
        CommonFile
    ) {
        this._injected = {
            $scope,
            $rootScope,
            $translate,
            $element,
            data,
            close,
            SupplierResources,
            FilledSupplierResourceField,
            DynamicFormUtilService,
            CommonFile,
        };
        ScopeFunctions.addToScope($scope);
        this.isResourceConferenceActivity = false;
        this.resourceName = data.resourceName;
        this.offerPartTypeNameCode = data.offerPartTypeNameCode;
        this.resourceId = data.resourceId;
        this.supplierId = data.supplierId;
        this.layout = data.layout;
        this.requestOfferPartOptionId = data.requestOfferPartOptionId;
        this.requestOfferPartOptionDummyId = data.requestOfferPartOptionDummyId;

        this.attachedFiles = [];
        if (this.layout === 'creator') {
            this.displaySubTab = 'VIEW';
        } else {
            this.displaySubTab = 'EDIT';
        }
        this.initFn();
    }

    initFn() {
        const { $scope, SupplierResources } = this._injected;
        $scope.startProgress();
        if (this.resourceId) {
            SupplierResources.getResource(
                this.resourceId,
                (data) => {
                    this.resource = data;
                    if (
                        this.resource.offerPartTypeNameCode === 'common.translate.offer.part.type.conference.room' ||
                        this.resource.offerPartTypeNameCode === 'common.translate.offer.part.type.conference.activities'
                    ) {
                        this.isResourceConferenceActivity = true;
                    }
                    this.getFilledSupplierResourceField(this.resource);
                    $scope.endProgress();
                },
                $scope.endWithErrorOverlay()
            );
        } else {
            let _data = {
                newResourceName: this.resourceName,
                nameCode: this.offerPartTypeNameCode,
                supplierId: this.supplierId,
                resourceStatus: 'PRIVATE',
            };
            SupplierResources.createNewResource(
                _data,
                (res) => {
                    this.resource = res;
                    if (
                        this.resource.offerPartTypeNameCode === 'common.translate.offer.part.type.conference.room' ||
                        this.resource.offerPartTypeNameCode === 'common.translate.offer.part.type.conference.activities'
                    ) {
                        this.isResourceConferenceActivity = true;
                    }
                    this.getFilledSupplierResourceField(this.resource);
                    $scope.endProgress();
                },
                $scope.endWithError
            );
        }
    }

    getFilledSupplierResourceField(resource) {
        const { FilledSupplierResourceField, $scope, DynamicFormUtilService, $rootScope, $translate } = this._injected;
        this.filledSupplierResourceFieldList = [];
        FilledSupplierResourceField.list(
            'ALL',
            resource.offerPartTypeNameCode,
            resource.id,
            resource.supplier.id,
            (resp) => {
                this.list = resp;
                angular.forEach(this.list, (list) => {
                    if (['RADIO', 'DROPDOWN', 'MULTICHECKBOX', 'ICON_RADIO'].indexOf(list.type.name) > -1) {
                        let _listValuesCode = list.listValueCodes;
                        let _listValues = $translate.instant(_listValuesCode);
                        let _fieldValue;
                        if (list.type.name === 'MULTICHECKBOX' && list.fieldValue === 'false') {
                            _fieldValue = false;
                        } else {
                            _fieldValue = list.fieldValue ? list.fieldValue : list.defaultValue;
                        }
                        list.fieldValue = DynamicFormUtilService.getFieldValue(
                            _listValues,
                            $rootScope.langSelected,
                            _fieldValue
                        );
                    }
                    if (['ICON_RADIO', 'ICON_MULTI_CHECKBOX'].indexOf(list.type.name) > -1) {
                        let _iconList = list.iconList;
                        angular.forEach(_iconList, function (icon) {
                            icon.name = $translate.instant(icon.nameCode);
                        });
                    }
                });
                let _resourceArray = { resource: resource, list: this.list };
                this.filledSupplierResourceFieldList.push(_resourceArray);
                $scope.endProgress();
            },
            function (e) {
                console.log('error ', e);
                $scope.endWithError();
            }
        );
    }

    checkSupplierResourceImage(resource) {
        if (resource && resource.resourceImages) {
            return Object.keys(resource.resourceImages).length > 0;
        }
        return;
    }

    getSupplierResourceImage(image) {
        // let url = 'api/v1/file/getCommonFile/';
        let imageUrl = '0';
        if (image) {
            imageUrl = image;
        }
        return CommonFile(imageUrl);
    }

    setCarouselFullSize(index) {
        this.showOverlayedCarousel = index;
    }

    resetCarouselFullSize(user) {
        this.showOverlayedCarousel = null;
    }

    enableShowMoreBtn(str) {
        if (str) {
            return str.length > 183;
        }
        return false;
    }

    expandTextBtn(index) {
        const { $translate } = this._injected;
        var text = jQuery('#supplier-resource-description-section_' + index);
        var textHeight = text[0].scrollHeight;
        var btnText = document.getElementById('readBtn_' + index);
        var newHeight = 0;
        if (text.hasClass('active')) {
            newHeight = '40px';
            btnText.innerHTML = $translate.instant('common.read.more');
            text.removeClass('active');
        } else {
            newHeight = textHeight;
            btnText.innerHTML = $translate.instant('common.read.less');
            text.addClass('active');
        }
        text.animate(
            {
                'max-height': newHeight,
            },
            500
        );
    }

    getSupplierResourceFormClass(resourceData) {
        if (resourceData && resourceData.offerPartTypeNameCode) {
            switch (resourceData.offerPartTypeNameCode) {
                case 'common.translate.offer.part.type.conference.room':
                    return 'conference-room-form';
                    break;
                default:
                    return 'conference-room-form';
                    break;
            }
        }
    }

    setPresenationTabActive(tabName) {
        this.displaySubTab = tabName;
    }

    close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

ResourcePresentationModalCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$translate',
    '$element',
    'data',
    'close',
    'SupplierResources',
    'FilledSupplierResourceField',
    'DynamicFormUtilService',
    'CommonFile',
];
export default ResourcePresentationModalCtrl;
