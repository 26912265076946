/* global angular */
'use strict';

angular.module('core.supplierDetail').factory('SupplierDetailService', function () {
    var showSupplierDetailIndex;
    var displaySearchFilterInMap = true;
    var searchByEmailEnabled = false;

    function setSupplierDetailStatus(show) {
        showSupplierDetailIndex = show;
    }

    function getSupplierDetailStatus() {
        return parseInt(showSupplierDetailIndex) > -1;
    }

    function setSearchByEmailEnabled(v) {
        searchByEmailEnabled = v;
    }

    function getSearchByEmailEnabled() {
        return searchByEmailEnabled;
    }

    function setDisplaySearchFilterInMap(yes) {
        displaySearchFilterInMap = yes;
    }

    function getDisplaySearchFilterInMap() {
        return displaySearchFilterInMap;
    }

    function getSupplierDetailIndex() {
        return showSupplierDetailIndex;
    }

    return {
        setSupplierDetailStatus: function (show) {
            return setSupplierDetailStatus(show);
        },
        getSupplierDetailStatus: function () {
            return getSupplierDetailStatus();
        },
        setSearchByEmailEnabled: function (val) {
            return setSearchByEmailEnabled(val);
        },
        getSearchByEmailEnabled: function () {
            return getSearchByEmailEnabled();
        },
        getSupplierDetailIndex: function () {
            return getSupplierDetailIndex();
        },
        setDisplaySearchFilterInMap: function (show) {
            return setDisplaySearchFilterInMap(show);
        },
        getDisplaySearchFilterInMap: function () {
            return getDisplaySearchFilterInMap();
        },
    };
});
