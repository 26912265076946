import offerPreviewModal from '../../../modals/modals2.0/offer-preview';
import selectSupplierModal from '../../../modals/modals2.0/select-supplier';
import eventCreatedWelcomeModal from '../../../modals/modals2.0/event-created-welcome';
import openYoutubeModal from '../../../modals/modals2.0/open-youtube-video';
import UserGroupData from '../../../../core/existingServices/data_store/UserGroupData';
import EventData from '../../../../core/existingServices/data_store/EventDetailsData';
import editEventModal from '../../../modals/modals2.0/edit-event';
import GoogleAddressUtil from '../../../../utils/GoogleAddressUtil';
import { ScopeFunctions } from "../../../../utils/global/scopeFunctions";
import { OgModal } from "../../../../utils/global/ogModal";
import _ from 'underscore';

(function () {
    'use strict';
    angular.module('event-detail').component('eventDetail', {
        templateUrl: './app/components/modules/event/event_detail/event_detail.view.html',
        controllerAs: 'eventDetailCtrl',
        bindings: {
            resolvedEventDetails: '<'
        },
        controller: [
            '$scope',
            '$rootScope',
            '$stateParams',
            '$location',
            '$translate',
            'Page',
            'Event',
            'Request',
            'RequestOffer',
            'AuthenticationService',
            '$q',
            '$timeout',
            'ogScrollService',
            'EventEnum',
            'UserInfoStatus',
            'GlobalConstants',
            '$state',
            'NotificationService',
            'Activity',
            'ActiveTabService',
            'ChatMessageCountStore',
            'ModalService',
            'Notification',
            'EventGuideDataStore',
            'DateService',
            'ogMatchHeightService',
            'ChatMessage',
            'RequestOfferStatusUtils',
            'GlobalOption',
            'ProcessLog',
            'CommonFunctions',
            function EventController(
                $scope,
                $rootScope,
                $stateParams,
                $location,
                $translate,
                Page,
                Event,
                Request,
                RequestOffer,
                AuthenticationService,
                $q,
                $timeout,
                ogScrollService,
                EventEnum,
                UserInfoStatus,
                GlobalConstants,
                $state,
                NotificationService,
                Activity,
                ActiveTabService,
                ChatMessageCountStore,
                ModalService,
                Notification,
                EventGuideDataStore,
                DateService,
                ogMatchHeightService,
                ChatMessage,
                RequestOfferStatusUtils,
                GlobalOption,
                ProcessLog,
                CommonFunctions
            ) {
                {
                    var vm = this;
                    vm.viewModal = false;
                    vm.autoStartIntro = false;
                    vm.displayAddContentSideBar = false;
                    // vm.showEventProcessSidebar = false;
                    vm.addNewEmptyRequest = addNewEmptyRequestFn;
                    vm.openAddSupplierModal = openAddSupplierModalFn;
                    vm.canDeleteOffer = canDeleteOfferFn;
                    vm.canRemoveSupplier = canRemoveSupplierFn;
                    vm.confirmDeleteOffer = confirmDeleteOfferFn;
                    vm.confirmRemoveSupplier = confirmRemoveSupplierFn;
                    vm.createTemplate = createTemplateFn;
                    vm.disabled = disabledFn;
                    vm.displayContentAddSideBar = displayContentAddSideBarFn;
                    vm.displayNotificationByType = displayNotificationByTypeFn;
                    vm.displayGuideNotification = displayGuideNotificationFn;
                    vm.getActiveTab = getActiveTab;
                    vm.getDateFormat = getDateFormatFn;
                    vm.getTotalItemLengthInMap = getTotalItemLengthInMapFn;
                    vm.getUnreadMessageCount = getUnreadMessageCountFn;
                    vm.displayEventGuideTooltip = displayEventGuideTooltipFn;
                    vm.closeEventGuideTooltip = closeEventGuideTooltipFn;
                    vm.getDateSpan = DateService.getDateSpan;
                    // Duplicated in supplier offer controller as well
                    vm.getResponsibleUser = getResponsibleUserFn;
                    vm.showEventGuide = showEventGuideFn;

                    //Notification functions
                    vm.get_ANSWERED = get_ANSWEREDFn;
                    vm.get_BOOKED_LOCKED_BY_CREATOR = get_BOOKED_LOCKED_BY_CREATORFn;
                    vm.get_BOOKED_WAITING_FOR_CREATOR = get_BOOKED_WAITING_FOR_CREATORFn;
                    vm.get_FINISHED_WAITING_FOR_CREATOR = get_FINISHED_WAITING_FOR_CREATORFn;
                    vm.get_CANCELED_WAITING_FOR_CREATOR = get_CANCELED_WAITING_FOR_CREATORFn;
                    vm.get_REQUEST_OFFER_NEW = get_REQUEST_OFFER_NEWFn;
                    vm.getNotificationLength = getNotificationLengthFn;

                    vm.reFetchEventDetails = reFetchEventDetailsFn;
                    vm.init = initFn;
                    vm.isUndefinedOrNull = isUndefinedOrNullFn;
                    vm.makeNotificationSeen = makeNotificationSeenFn;
                    vm.makeGuideNotificationSeen = makeGuideNotificationSeenFn;
                    vm.nextViewForGuide = nextViewForGuideFn;
                    vm.openAddCollaboratorModal = openAddCollaboratorModalFn;
                    vm.openEditEventModal = openEditEventModalFn;
                    vm.openEventDeleteConfirmationModal = openEventDeleteConfirmationModalFn;
                    vm.openRequestDeleteConfirmationModal = openRequestDeleteConfirmationModalFn;
                    vm.openSummaryTab = openSummaryTabFn;
                    vm.openYoutubeVideoModal = openYoutubeVideoModalFn;
                    vm.removeSupplierFromEvent = removeSupplierFromEventFn;
                    vm.registerEventOffer = registerEventOfferFn;
                    vm.setActive = setActiveFn;
                    vm.setActiveTabs = setActiveTabsFn;
                    vm.setSelectedOfferList = setSelectedOfferListFn;
                    vm.setDisplayNotificationTab = setDisplayNotificationTabFn;
                    vm.shareEvent = shareEventFn;
                    vm.sendRequestOfferWithMessage = sendRequestOfferWithMessageFn;
                    vm.sendOffers = sendOffersFn;
                    vm.toggleShowNotificationSection = toggleShowNotificationSectionFn;
                    vm.toggleShowEventProcessSidebar = toggleShowEventProcessSidebarFn;
                    vm.highlightAddCollaboratorButton = highlightAddCollaboratorButtonFn;
                    vm.closeNotification = closeNotificationFn;
                    vm.updateSelectedOfferList = updateSelectedOfferListFn;
                    vm.viewRequest = viewRequestFn;
                    vm.allDivLoaded = allDivLoadedFn;
                    vm.startIntroJs = startIntroJsFn;
                    vm.viewOffer = viewOfferFn;
                    vm.openOfferModalForFinalDetails = openOfferModalForFinalDetailsFn;
                    vm.openOfferPreviewModal = openOfferPreviewModalFn;
                    vm.getTranslatedName = getTranslatedNameFn;
                    vm.getAllActivities = getAllActivitiesFn;
                    vm.updateSelectedOffer = updateSelectedOfferFn;
                    vm.isOfferInCanceledState = isOfferInCanceledStateFn;
                    vm.isOfferIsInDeclinedState = isOfferIsInDeclinedStateFn;
                    vm.isCloseRunning = isCloseRunningFn;
                    vm.deleteProcessLog = deleteProcessLogFn;
                    //Notification
                    vm.activeTab = '';
                    vm.showNotification = false;
                    vm.showAllNotification = false;
                    vm.showSentNotification = false;
                    vm.supplierChangesAnsweredButUnsent = false;
                    vm.isProcessRunning = false;
                    vm.isThereAnyErrorWhileCancellingEvent = false;
                    vm.request = {};
                    vm.registeredEventOffers = [];

                    //Updating offer and price for Budget
                    vm.selectedOfferList = {};
                    vm.totalPrice = 0;
                    vm.guideView = GlobalConstants.GUIDE_VIEWS[0];
                    var eventId = $stateParams.eventId;
                    var requestId = $stateParams.requestId;
                    var offerId = $stateParams.offerId;
                    ScopeFunctions.addToScope($scope);

                    vm.isNewEvent = false;
                    vm.$onInit = function () {
                        vm.resolvedEventDetailsData = vm.resolvedEventDetails;
                        vm.init();
                    }

                    // Don't show guide for empty event
                    if (EventGuideDataStore.showOnBoardingGuide) {
                        if (EventData.hasRequest()) {
                            initOnBoarding();
                        } else {
                            EventGuideDataStore.setShowOnBoardingGuide(false);
                        }
                    }

                    if (EventGuideDataStore.showEventProgressGuide) {
                        vm.isNewEvent = true;
                        setTimeout(function () {
                            EventGuideDataStore.setShowEventProgressGuide(false);
                        }, 100);
                    }

                    function initFn() {
                        isCloseRunningFn();
                        if (requestId && offerId) {
                            //when redirecting from email from supplier
                            updateEventDetails(vm.resolvedEventDetailsData, false, true);
                        } else {
                            updateEventDetails(vm.resolvedEventDetailsData);
                        }
                        updateTab();
                        if ($stateParams.requestId && EventGuideDataStore.showAddSupplierModal) {
                            EventGuideDataStore.setShowAddSupplierModal(false);
                            vm.openAddSupplierModal(vm.event, $stateParams.requestId);
                        }
                        setUnreadMessageCountFn();
                        vm.initialBounds = {};
                        vm.initialLocation = {};
                        if (vm.event.eventAddress && vm.event.eventAddress.displayAddress) {
                            //todo(SC) for existing event address with wrong data , Need to replace geocoder.geocode({'address': vm.event.eventAddress.displayAddress} by geocoder.geocode({'address': _address}
                            // let _address = googleAddressUtil.prepareGeocodeParameters(vm.event.eventAddress);

                            let _correctedDisplayAddress = GoogleAddressUtil.getCorrectedDisplayAddressManually(
                                vm.event.eventAddress.displayAddress
                            );
                            $timeout(() => {
                                window.googleMapsCallback(() => {
                                    var geocoder = new google.maps.Geocoder();
                                    geocoder.geocode({ address: _correctedDisplayAddress }, (results, status) => {
                                        console.log(_correctedDisplayAddress, '', results[0]);
                                        if (status === google.maps.GeocoderStatus.OK) {
                                            var place = GoogleAddressUtil.findPlaceFromResults(results);
                                            let _bounds = place.geometry.bounds;
                                            if (_bounds) {
                                                let _swLat = _bounds.getSouthWest().lat();
                                                let _swLng = _bounds.getSouthWest().lng();
                                                let _neLat = _bounds.getNorthEast().lat();
                                                let _neLng = _bounds.getNorthEast().lng();
                                                vm.initialBounds = {
                                                    minLng: _swLng,
                                                    maxLng: _neLng,
                                                    minLat: _swLat,
                                                    maxLat: _neLat,
                                                };
                                                if (place.geometry.location) {
                                                    let _lat = place.geometry.location.lat();
                                                    let _lng = place.geometry.location.lng();
                                                    vm.initialLocation = { lat: _lat, lng: _lng };
                                                }
                                            } else if (place.geometry.location) {
                                                let _lat = place.geometry.location.lat();
                                                let _lng = place.geometry.location.lng();
                                                vm.initialLocation = { lat: _lat, lng: _lng };
                                                vm.initialBounds = {};
                                            }
                                        }
                                    });
                                });
                            });
                        }
                        $rootScope.$emit('event:user:action', {
                            action: 'open.event',
                            data: {
                                'Event name': vm.event.name,
                                'Event id': vm.event.id,
                            },
                        });
                        vm.showEventProcessSidebar = false;
                    }

                    function initOnBoarding() {
                        let showIntroJsGuide = function (showGuide) {
                            if (!showGuide) {
                                EventGuideDataStore.setShowOnBoardingGuide(false);
                            } else {
                                if (showGuide) {
                                    vm.startIntroJs();
                                }
                            }
                        };
                        if(vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        let eventCreatedWelcomeModalData = eventCreatedWelcomeModal;
                        var watchDivLoaded = $scope.$watch('eventDetailCtrl.allDivLoaded()', (newVal) => {
                            if (newVal) {
                                // clear the watch after single use
                                // http://stackoverflow.com/questions/14957614/angularjs-clear-watch
                                watchDivLoaded();
                                let loadEventCreatedWelcomeModal = function () {
                                    ModalService.showModal(eventCreatedWelcomeModalData).then((modal) => {
                                        vm.viewModal = false;
                                        modal.element.modal();
                                        modal.close.then((showGuide) => {
                                            showIntroJsGuide(showGuide);
                                        });
                                    });
                                };
                                //timeout added to fix issue https://app.asana.com/0/14368931590528/256120543796364
                                $timeout(function () {
                                    loadEventCreatedWelcomeModal();
                                });
                            }
                        });
                    }

                    function isOfferInCanceledStateFn(offer) {
                        return 'CANCELED' === offer.status.name;
                    }

                    function isOfferIsInDeclinedStateFn(offer) {
                        return 'DECLINED' === offer.status.name;
                    }

                    function reFetchEventDetailsFn() {
                        if (requestId && offerId) {
                            //when redirecting from email from supplier
                            getEventDetailPage(eventId, false, true);
                        } else {
                            getEventDetailPage(eventId);
                        }
                        updateTab();
                    }

                    function updateTab() {
                        switch ($state.current.name) {
                            case 'event.detail.overview':
                                ActiveTabService.setEventDetailTab('overview');
                                break;
                            case 'event.detail.budget':
                            case 'event.detail.budget.list':
                            case 'event.detail.budget.markup':
                            case 'event.detail.budget.client_election':
                                ActiveTabService.setEventDetailTab('budget');
                                break;
                            case 'event.detail.message':
                                ActiveTabService.setEventDetailTab('message');
                                break;
                            case 'event.detail.participants.list':
                            case 'event.detail.participants.summary':
                            case 'event.detail.participants.design_invitation':
                            case 'event.detail.participants.design_invitation.design-form':
                            case 'event.detail.participants.design_invitation.design-registration_form':
                            case 'event.detail.participants.design_invitation.design-event-infosite':
                            case 'event.detail.participants.tickets':
                            case 'event.detail.participants.design-badge':
                            case 'event.detail.participants.design_invitation.design-badge':
                            case 'event.detail.participants.settings':
                            case 'event.detail.participants.mails':
                                ActiveTabService.setEventDetailTab('participants');
                                break;
                            case 'event.detail.task':
                                ActiveTabService.setEventDetailTab('task');
                                break;
                            case 'event.detail.notes':
                                ActiveTabService.setEventDetailTab('notes');
                                break;
                            case 'event.detail.rating.creator':
                            case 'event.detail.rating.participants':
                                ActiveTabService.setEventDetailTab('rating');
                                break;
                            case 'event.detail.activities':
                                ActiveTabService.setEventDetailTab('activities');
                                break;
                        }
                    }

                    function makeNotificationSeenFn(notifications) {
                        var requestObj = [];
                        if (notifications && Array.isArray(notifications)) {
                            requestObj = notifications.map(function (d) {
                                return {
                                    id: d.id,
                                };
                            });
                        } else {
                            requestObj.push({ id: notifications && notifications.id });
                        }
                        Notification.setAsRead(
                            requestObj,
                            function () {
                                NotificationService.setNotificationData(eventId);
                            },
                            $scope.endWithErrorOverlay()
                        );
                    }

                    function makeGuideNotificationSeenFn() {
                        EventGuideDataStore.setEventGuideNotification(false);
                        vm.showAllNotification = false;
                    }

                    function addNewEmptyRequestFn() {
                        OgModal.open('request:add');
                        $rootScope.$emit('event:user:action', { action: 'add.empty.module' });
                    }

                    function openAddSupplierModalFn(event, requestId, step, unsentOffers) {
                        var requestObj = CommonFunctions.findItemInListById(vm.requests, requestId);
                        var canceledOffers = requestObj.requestOffers.filter(function (obj) {
                            return vm.isOfferInCanceledState(obj);
                        });
                        var declinedOffers = requestObj.requestOffers.filter(function (obj) {
                            return vm.isOfferIsInDeclinedState(obj);
                        });
                        if(vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        let data = {
                            eventId: event.id,
                            geoTagId: event.geoTagId,
                            requestId: requestId,
                            step: step,
                            unsentOffers: unsentOffers,
                            canceledOffers: canceledOffers,
                            declinedOffers: declinedOffers,
                            eventAddress: event.eventAddress,
                            hasContractedSupplier: vm.hasContractedSupplier,
                            showContractedSuppliersOnly: vm.showContractedSuppliersOnly,
                        };

                        let selectSupplierModalData = selectSupplierModal;
                        selectSupplierModalData.inputs = { data };
                        ModalService.showModal(selectSupplierModalData).then((modal) => {
                            vm.viewModal = false;
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                    }

                    function canDeleteOfferFn(requestOffers, offer) {
                        return offer && offer.deletable && requestOffers.length > 1;
                    }

                    function canRemoveSupplierFn(requestOffers, offer) {
                        return offer && offer.deletable && requestOffers.length == 1 && offer.supplier && offer.supplier.id;
                    }

                    function confirmDeleteOfferFn(offer) {
                        vm.selectedOffer = offer;
                        var settings = {
                            message: 'request.offer.delete.notification',
                            okCallBack: vm.removeSupplierFromEvent,
                        };
                        $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, settings);
                        CommonFunctions.stopPropagation();
                        return false;
                    }

                    function confirmRemoveSupplierFn(offer) {
                        vm.selectedOffer = offer;
                        var settings = {
                            message: 'request.offer.supplier.remove.notification',
                            okCallBack: vm.removeSupplierFromEvent,
                        };
                        $rootScope.$broadcast(EventEnum.OPEN_CONFIRMATION_DIALOG, settings);
                        CommonFunctions.stopPropagation();
                        return false;
                    }

                    function createTemplateFn() {
                        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                            return;
                        }

                        if (!disabledFn('SHARE')) {
                            OgModal.open('share:event', { event: vm.event });
                        }
                    }

                    function disabledFn(item) {
                        return !vm.event || !vm.event.userRole || vm.event.userRole[item] == $rootScope.rights.NONE;
                    }

                    function displayContentAddSideBarFn() {
                        vm.displayAddContentSideBar = true;
                    }

                    function displayNotificationByTypeFn(type) {
                        if (vm.showAllNotification) {
                            return true;
                        }
                        if (vm.showNotification && vm.displayNotificationTab == type) {
                            return true;
                        }
                        return false;
                    }

                    function displayGuideNotificationFn() {
                        if (EventGuideDataStore.showEventGuideNotification) {
                            return true;
                        }
                        return false;
                    }

                    function getActiveTab() {
                        return ActiveTabService.getEventDetailTab();
                    }

                    function getDateFormatFn() {
                        return $rootScope.langSelected == 'sv_SV' ? 'yy-mm-dd' : 'dd/mm/yy';
                    }

                    function getTotalItemLengthInMapFn(object) {
                        var length = 0;
                        angular.forEach(object, function (value, key) {
                            if (object[key]) {
                                length += object[key].length;
                            }
                        });
                        return length;
                    }

                    function setUnreadMessageCountFn() {
                        ChatMessage.unReadMessageCountForCreatorRest(
                            eventId,
                            function (resp) {
                                ChatMessageCountStore.setCreatorUnreadMessageCount(resp.count);
                                ChatMessageCountStore.setCreatorHomePageUnreadMessageCount(resp.homePageCount);
                            },
                            $scope.endWithAlert()
                        );
                    }

                    function getUnreadMessageCountFn() {
                        return ChatMessageCountStore.getCreatorUnreadMessageCount();
                    }

                    function getResponsibleUserFn() {
                        // checks if event responsibleUser is null before defining name attribute on it
                        if (vm.event.responsibleUser) {
                            return vm.event.responsibleUser.name;
                        }
                    }

                    function get_ANSWEREDFn() {
                        return NotificationService.get_REQUEST_OFFER_ANSWERED();
                    }

                    function get_BOOKED_LOCKED_BY_CREATORFn() {
                        return NotificationService.get_REQUEST_OFFER_BOOKED_LOCKED_BY_CREATOR();
                    }

                    function get_BOOKED_WAITING_FOR_CREATORFn() {
                        return NotificationService.get_REQUEST_OFFER_BOOKED_WAITING_FOR_CREATOR();
                    }

                    function getNotificationLengthFn() {
                        let length = NotificationService.getLengthWithoutCreatorLocked();
                        if (displayGuideNotificationFn()) {
                            length = length + 1;
                        }
                        return length;
                    }

                    function get_FINISHED_WAITING_FOR_CREATORFn() {
                        return NotificationService.get_REQUEST_OFFER_FINISHED_WAITING_FOR_CREATOR();
                    }

                    function get_CANCELED_WAITING_FOR_CREATORFn() {
                        return NotificationService.get_REQUEST_OFFER_CANCELED_WAITING_FOR_CREATOR();
                    }

                    function get_REQUEST_OFFER_NEWFn() {
                        return NotificationService.get_REQUEST_OFFER_NEW();
                    }

                    function isUndefinedOrNullFn(val) {
                        return UtilFunctions.isUndefinedOrNull(val);
                    }

                    function nextViewForGuideFn() {
                        vm.guideView = GlobalConstants.GUIDE_VIEWS[1];
                        gotoElement('tab-header');
                    }

                    function openAddCollaboratorModalFn() {
                        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                            return;
                        }
                        OgModal.open('add:collaborators', { event: vm.event });
                    }

                    function openEditEventModalFn() {
                        if (!vm.disabled('EDIT_DETAILS')) {
                            if(vm.viewModal) {
                                return
                            }
                            vm.viewModal = true;
                            let data = {
                                eventId: eventId,
                                requests: vm.requests,
                            };

                            let _editEventModal = editEventModal;
                            _editEventModal.inputs = { data };
                            ModalService.showModal(_editEventModal).then((modal) => {
                                vm.viewModal = false;
                                modal.element.modal();
                                modal.close.then(() => {
                                    console.log('modal is closed!');
                                });
                            });
                        }
                    }

                    function openEventDeleteConfirmationModalFn() {
                        if (!vm.disabled('DELETE')) {
                            if (isEventDeletable()) {
                                $rootScope.$broadcast('dialog:event:delete:confirmation:open', {
                                    message: 'event.delete.confirm',
                                    okCallBack: deleteEvent,
                                });
                            } else {
                                $rootScope.$broadcast('dialog:alert:open', { message: 'event.cannot.delete' });
                            }
                        }
                    }

                    function openRequestDeleteConfirmationModalFn(request) {
                        vm.request = request;
                        if (isRequestDeletable(request.requestOffers)) {
                            OgModal.open('confirmation', { message: 'request.delete.confirm', okCallBack: deleteRequest });
                        } else {
                            OgModal.open('alert', { message: 'request.cannot.delete' });
                        }
                    }

                    function openSummaryTabFn() {
                        if (vm.requests && vm.requests.length > 0) {
                            window.open('/event/' + eventId + '/summary', '_blank');
                        } else {
                            OgModal.open('alert', { message: 'event.presentation.empty' });
                        }
                    }

                    function openYoutubeVideoModalFn(videoId, code) {
                        if(vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        var title = $translate.instant(code);
                        let data = { videoId: videoId, title: title };
                        let openYoutubeVideo = openYoutubeModal;
                        openYoutubeVideo.inputs = { data };
                        ModalService.showModal(openYoutubeVideo).then((modal) => {
                            vm.viewModal = false;
                            $rootScope.$emit('event:user:action', {
                                action: 'guide.video.played',
                                data: {
                                    'video Id': videoId,
                                    'video title': title,
                                },
                            });
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                    }

                    function removeSupplierFromEventFn() {
                        if (vm.selectedOffer) {
                            RequestOffer.delete(vm.selectedOffer.id, supplierRemovedBroadCast, $scope.endWithErrorOverlay());
                        }
                    }

                    function registerEventOfferFn(supplier) {
                        var existing = false;
                        angular.forEach(vm.registeredEventOffers, function (n) {
                            if (supplier.id === n.id) {
                                existing = true;
                            }
                        });

                        if (!existing) {
                            vm.registeredEventOffers.push(supplier);
                        }
                    }

                    function setActiveFn(tab) {
                        ActiveTabService.setEventDetailTab(tab);
                    }

                    function setActiveTabsFn(tab) {
                        ActiveTabService.setEventsTab(tab);
                    }

                    function setSelectedOfferListFn(requestId, offer) {
                        if (requestId && offer) {
                            vm.selectedOfferList[requestId] = offer;
                        }
                    }

                    function setDisplayNotificationTabFn(type) {
                        if (vm.showNotification && vm.displayNotificationTab == type) {
                            vm.showNotification = false;
                            vm.showAllNotification = false;
                            return;
                        }
                        vm.showAllNotification = false;
                        vm.showNotification = true;
                        vm.displayNotificationTab = type; //
                    }

                    function shareEventFn() {
                        if (!AuthenticationService.checkIfUserVerifiedAndAlert()) {
                            return;
                        }

                        if (!vm.disabled('SHARE')) {
                            OgModal.open('share:event', { event: vm.event });
                        }
                    }

                    function sendOffersFn(requestId, notification) {
                        let offerObj = prepareRequestOfferToSend(notification);
                        let offerInList = [offerObj];
                        const SELECT_SUPPLIER_STEP_TWO = 2;
                        vm.openAddSupplierModal(vm.event, requestId, SELECT_SUPPLIER_STEP_TWO, offerInList);
                    }

                    function sendRequestOfferWithMessageFn(requestOffer, supplierMessage) {
                        prepareRequestOfferToSend(requestOffer);
                        let offerObj = setSupplierMessageOnOffer(supplierMessage, requestOffer);
                        let offerInList = [offerObj];
                        OgModal.open('message:supplier', {
                            responsibleUser: vm.getResponsibleUser(),
                            isFromNotification: true,
                            eventId: eventId,
                            offerList: offerInList,
                        });
                    }

                    function prepareRequestOfferToSend(notification) {
                        let offer = notification.offer;
                        offer.request = notification.request;
                        offer.eventId = eventId;
                        offer.status = 'SENT';
                        return offer;
                    }

                    function setSupplierMessageOnOffer(message, requestOffer) {
                        requestOffer.supplierMessage = message;
                        return requestOffer;
                    }

                    function prepareParamsForUnsentOffers(unsentOffers, supplierMessage) {
                        if (!supplierMessage) {
                            $scope.startProgress();
                            GlobalOption.get(
                                'DEFAULT_REQUEST_MESSAGE',
                                (resp) => {
                                    supplierMessage = resp[$rootScope.langSelected]
                                        ? resp[$rootScope.langSelected]
                                        : $translate.instant('default.message.to.supplier', { p0: vm.getResponsibleUser() });
                                    $scope.endProgress();
                                },
                                $scope.endWithError
                            );
                        }
                        return unsentOffers.map(function (val) {
                            return angular.extend({}, val, {
                                request: val.request,
                                supplierMessage: supplierMessage,
                                eventId: eventId,
                                status: 'SENT',
                            });
                        });
                    }

                    function prepareUnsentOfferList(supplierMessage) {
                        var unsentOfferNotifications = vm.get_REQUEST_OFFER_NEW();
                        prepareParamsForUnsentOffers(unsentOfferNotifications, supplierMessage);
                    }

                    function toggleShowNotificationSectionFn() {
                        vm.showNotification = !vm.showNotification;
                        vm.showAllNotification = vm.showNotification;
                    }

                    function toggleShowEventProcessSidebarFn(show) {
                        vm.showEventProcessSidebar = show;
                    }

                    function showEventGuideFn() {
                        vm.showEventProcessSidebar = true;
                    }

                    function highlightAddCollaboratorButtonFn(animate) {
                        var selector = jQuery('#add-collaborator');
                        if (selector.hasClass('button-pulse-invite-user')) {
                            selector.removeClass('button-pulse-invite-user');
                        }
                        if (animate) {
                            setTimeout(function () {
                                selector.addClass('button-pulse-invite-user');
                            }, 1);

                            // vm.animateAddCollaboratorButton = animate;
                        }
                    }

                    function closeNotificationFn() {
                        vm.showAllNotification = false;
                        vm.showNotification = false;
                    }

                    function updateSelectedOfferListFn(requestId, offer) {
                        vm.selectedOfferList[requestId] = offer;
                    }

                    function viewRequestFn(requestId) {
                        return viewOfferRequest(null, requestId);
                    }

                    function viewOfferRequest(offerId, requestId) {
                        vm.viewOffer(offerId, requestId);
                    }

                    function readData(eventId, scrollToLast, scrollToOffer) {
                        if (!scrollToLast) {
                            scrollToLast = false;
                        }
                        $scope.startProgress();
                        getEventDetailPage(eventId, scrollToLast, scrollToOffer);
                    }

                    /*Delete section start*/
                    function deleteEvent() {
                        Event['delete'](
                            eventId,
                            function (/*data*/) {
                                $location.url('/events');
                            },
                            function (data) {
                                $rootScope.alert(data);
                            }
                        );
                    }

                    function deleteRequest() {
                        $scope.startProgress({ name: vm.request.id, message: 'default.deleting' });
                        Request['delete'](
                            eventId,
                            vm.request.id,
                            {},
                            function () {
                                getEventDetailPage(eventId, false);
                                $rootScope.$broadcast(EventEnum.SUPPLIER_REMOVED_FROM_PANEL);
                            },
                            $scope.endWithErrorOverlay(vm.request.id)
                        );
                    }

                    function isEventDeletable() {
                        return !isAnyRequestOfferBookedInEvent() && !vm.disabled('DELETE');
                    }

                    function isRequestDeletable(offers) {
                        return vm.request.isInternal || !isAnyRequestOfferBookedInRequest(offers);
                    }

                    function isAnyRequestOfferBooked(offers) {
                        if (!offers) {
                            return false;
                        }
                        var length = offers.length;
                        for (var i = 0; i < length; i++) {
                            var offer = offers[i];
                            var status = offer.status;
                            var statusDetailed = offer.statusDetailed;
                            if (
                                status &&
                                statusDetailed &&
                                statusDetailed.name != 'INTERNAL' &&
                                (status.name === 'BOOKED' ||
                                    status.name === 'BOOKED_LOCKED_BY_CREATOR' ||
                                    status.name === 'BOOKED_LOCKED_BY_SUPPLIER' ||
                                    status.name === 'BOOKED_WAITING_FOR_CREATOR' ||
                                    status.name === 'BOOKED_WAITING_FOR_SUPPLIER' ||
                                    status.name === 'FEE_RECEIVED')
                            ) {
                                return offer;
                            }
                        }
                        return false;
                    }

                    function isAnyRequestOfferBookedInEvent() {
                        if (!vm.requests) {
                            return false;
                        }
                        var length = vm.requests.length;
                        for (var i = 0; i < length; i++) {
                            var request = vm.requests[i];
                            if (!request.isInternal && isAnyRequestOfferBooked(request.requestOffers)) {
                                return true;
                            }
                        }
                        return false;
                    }

                    function isAnyRequestOfferBookedInRequest(offers) {
                        return isAnyRequestOfferBooked(offers);
                    }

                    /*Delete section end*/

                    /*Scroll section start*/
                    function gotoElement(elementID) {
                        ogScrollService.scrollToElement(elementID);
                    }

                    //TODO check if this has been used
                    function scrollTo(request) {
                        var eventDetailElement = jQuery('.event-detail'),
                            position = jQuery('#request-' + request.id).position(),
                            maxScrollTop =
                                eventDetailElement.children('.scrolling-content')[0].scrollHeight - eventDetailElement.height(),
                            dest = Math.min(Math.max(0, position.top), maxScrollTop);
                        eventDetailElement.animate({ scrollTop: dest }, 0.3 * Math.abs(dest - eventDetailElement.scrollTop()));
                    }

                    /*Scroll section end*/

                    function supplierRemovedBroadCast(response) {
                        getEventDetailPage(eventId, false);
                        $rootScope.$broadcast(EventEnum.SUPPLIER_REMOVED_FROM_PANEL, response);
                    }

                    function getEventDetailPage(eventId, scrollToLast, scrollToOffer) {
                        Page.eventDetailPage(
                            { eventId: eventId, requestId: requestId },
                            function (response) {
                                updateEventDetails(response, scrollToLast, scrollToOffer);
                            },
                            $scope.endWithAlert
                        );
                    }

                    function updateEventDetails(eventDetails, scrollToLast, scrollToOffer) {
                        vm.event = eventDetails.event;
                        vm.requests = vm.event.requests;
                        vm.userInfoStatus = eventDetails.userInfoStatus;
                        vm.hasContractedSupplier = eventDetails.hasContractedSupplier;
                        vm.isEventDateApproaching = eventDetails.isEventDateApproaching;
                        vm.showContractedSuppliersOnly = eventDetails.showContractedSuppliersOnly;
                        vm.extraRequirements = eventDetails.extraRequirements;
                        vm.hasEventProgressData = eventDetails.hasEventProgressGuide;
                        var selectedUserGroupChanged = eventDetails.selectedUserGroupChanged;
                        if (selectedUserGroupChanged) {
                            AuthenticationService.updateUserData(eventDetails.userData);
                        }
                        UserGroupData.setUserGroupData(vm.event.ownerGroup);
                        NotificationService.setNotificationData(eventId);
                        EventData.setEventDetails(eventDetails.event);
                        getUserList();
                        $scope.endProgress();
                        displayFadeOutMessage('#contentGuideMessage');

                        if (vm.showSentNotification) {
                            displayFadeOutMessage('#showSentNotification');
                        }

                        //TODO(SC) Remove this scrollTOLast option
                        if (scrollToLast && vm.requests.length > 0) {
                            var lastRequest = vm.requests[vm.requests.length - 1];
                            $timeout(function () {
                                scrollTo(lastRequest);
                            });
                        } else if (scrollToOffer) {
                            $timeout(function () {
                                vm.viewOffer(offerId, requestId);
                            });
                        }
                    }

                    function displayFadeOutMessage(elementID) {
                        $timeout(function () {
                            jQuery(elementID).fadeOut(GlobalConstants.FADEOUT_TIME, function () {
                                $(this).remove();
                            });
                        }, GlobalConstants.FADEOUT_TIMEOUT_DURATION);
                    }

                    function getAllActivitiesFn() {
                        var params = {
                            eventId: eventId,
                            type: 'all',
                            page: 0,
                            pageSize: 6,
                        };
                        Activity.getAllActivities(params, function (response) {
                            console.log('Response>', response);
                        });
                    }

                    //TODO check and replace this method by openOfferPreviewModalFn
                    function viewOfferFn(offerId, requestId, linkToMessage) {
                        if(vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        let requestObj = CommonFunctions.findItemInListById(vm.requests, requestId);
                        let offerObj = CommonFunctions.findItemInListById(requestObj.requestOffers, offerId);
                        if (!offerObj) {
                            return false;
                        }
                        let data = {
                            offer: offerObj,
                            request: requestObj,
                            callback: vm.updateSelectedOffer,
                            event: vm.event,
                            isMessageLink: linkToMessage,
                            activeTab: 'OFFER_CONTENT',
                        };

                        let offerModalData = offerPreviewModal;
                        offerModalData.inputs = { data };
                        ModalService.showModal(offerModalData).then((modal) => {
                            vm.viewModal = false;
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                    }

                    function openOfferModalForFinalDetailsFn() {
                        setTimeout(function () {
                            for (i = 0; i < vm.requests.length; i++) {
                                let _offer = _.find(vm.requests[i].requestOffers, (offer) => {
                                    return (
                                        offer.finalDetailsSharedDate === false &&
                                        RequestOfferStatusUtils.ALL_BOOKED_STATUS.indexOf(offer.status.name) > -1
                                    );
                                });
                                if (_offer) {
                                    openOfferPreviewModalFn(_offer, vm.requests[i], vm.event, 'FINAL_DETAILS');
                                }
                            }
                        }, 100);
                    }

                    function openOfferPreviewModalFn(offer, request, event, activeTab) {
                        let isThereAccommodation = false,
                            isThereConference = false,
                            isThereConferenceCategory = false;
                        angular.forEach(offer.offerParts, (part) => {
                            if (!isThereAccommodation && part.category && part.category.name === 'ACCOMMODATION') {
                                isThereAccommodation = true;
                            }
                        });
                        angular.forEach(request.categories, (category) => {
                            if (
                                !isThereConferenceCategory &&
                                category.nameCode &&
                                category.nameCode === 'common.translate.category.conference.room'
                            ) {
                                isThereConferenceCategory = true;
                            }
                        });
                        if (!isThereConferenceCategory) {
                            angular.forEach(offer.offerParts, (part) => {
                                if (
                                    !isThereConference &&
                                    part.partType &&
                                    part.partType.nameCode === 'common.translate.offer.part.type.conference.room'
                                ) {
                                    isThereConference = true;
                                }
                            });
                        }
                        if(vm.viewModal) {
                            return;
                        }
                        vm.viewModal = true;
                        let data = {
                            offer: offer,
                            request: request,
                            event: event,
                            callback: vm.updateSelectedOffer,
                            activeTab: activeTab,
                            isThereAccommodation: isThereAccommodation,
                            isThereConference: isThereConference,
                        };
                        let offerModalData = offerPreviewModal;
                        offerModalData.inputs = { data };
                        ModalService.showModal(offerModalData).then((modal) => {
                            vm.viewModal = false;
                            modal.element.modal();
                            modal.close.then(() => {
                                console.log('modal is closed!');
                            });
                        });
                    }

                    function updateSelectedOfferFn(offer) {
                        let requestObj = CommonFunctions.findItemInListById(vm.requests, offer.request.id);
                        let offerObj = CommonFunctions.findItemInListById(requestObj.requestOffers, offer.id);
                        if (!offerObj) {
                            return;
                        }
                        offerObj['confirmWithSupplier'] = offer.confirmWithSupplier;
                    }

                    function getRequests(scrollToLast) {
                        if (!scrollToLast) {
                            scrollToLast = false;
                        }
                        Event.getRequests(
                            vm.event.id,
                            (response) => {
                                EventData.setRequests(response.requests);
                                vm.requests = response.requests;
                                if (scrollToLast && vm.requests.length > 0) {
                                    var lastRequest = vm.requests[vm.requests.length - 1];
                                    $timeout(function () {
                                        vm.viewRequest(lastRequest.id);
                                    });
                                }
                                $timeout(function () {
                                    if (vm.displayAddContentSideBar) {
                                        ogMatchHeightService.matchContentHeightWithSidebar();
                                    }
                                });
                            },
                            $rootScope.alert
                        );
                    }

                    function allDivLoadedFn(skipNo4 = false, hasRequest = true) {
                        return (
                            document.querySelector('#onboarding-step-request-content') &&
                            document.querySelector('#onboarding-step-calendar-0') &&
                            (!hasRequest || document.querySelector('.fc-time-grid-event')) &&
                            (skipNo4 || document.querySelector('#onboarding-step-request-button')) &&
                            document.querySelector('#onboarding-step-addcontent') &&
                            true
                        );
                    }

                    function startIntroJsFn(skipNo4 = false, hasRequest = true) {
                        vm.introElementKeys = [
                            '#onboarding-step-request-content',
                            '#onboarding-step-calendar-0',
                            '.fc-time-grid-event',
                            '#onboarding-step-request-button',
                            '#onboarding-step-addcontent',
                        ];
                        vm.introElementsMap = {
                            '#onboarding-step-request-content': {
                                element: '#onboarding-step-request-content',
                                intro: `<div class="arrow arrow-right">
                          <h6 class="onboarding-step">${$translate.instant('event.onboarding.step')}</h6>
                          <h2 style="font-size: 30px;font-family: 'proxima_novabold'; text-align: center;
                           margin:10px 0 0 0; color: white; ">
                          ${$translate.instant('event.onboarding.step.1.header')}</h2>
                          <div class="text-center onboarding-tooltip-content" style="font-size: 18px;
                                padding: 0 0 20px 0;">
                                ${$translate.instant('event.onboarding.step.1.content')}
                          </div>
                        </div>`,
                                position: 'top',
                            },
                            '#onboarding-step-calendar-0': {
                                element: '#onboarding-step-calendar-0',
                                intro: `<div class="arrow arrow-right">
                          <h6 class="onboarding-step">${$translate.instant('event.onboarding.step')}</h6>
                          <div class="text-center onboarding-tooltip-content">
                          ${$translate.instant('event.onboarding.step.2.content')}
                          </div>
                        </div>`,
                                position: 'top',
                            },
                            '.fc-time-grid-event': {
                                element: '.fc-time-grid-event',
                                intro: `<div class="arrow arrow-right">
                          <h6 class="onboarding-step">${$translate.instant('event.onboarding.step')}</h6>
                          <div class="text-center onboarding-tooltip-content">
                            <img src="assets/onboarding/cal_resize_${$rootScope.langSelected}.png" alt=""/>
                            <div style="padding-top: 20px; font-size: 18px;">
                            ${$translate.instant('event.onboarding.step.3.content')}
                            </div>
                          </div>
                        </div>`,
                                position: 'right',
                            },
                            '#onboarding-step-request-button': {
                                element: '#onboarding-step-request-button',
                                intro: `<div class="arrow arrow-right">
                          <h6 class="onboarding-step">${$translate.instant('event.onboarding.step')}</h6>
                          <div class="text-center onboarding-tooltip-content">
                          ${$translate.instant('event.onboarding.step.4.content')}
                          </div>
                        </div>`,
                                position: 'left',
                            },
                            '#onboarding-step-addcontent': {
                                element: '#onboarding-step-addcontent',
                                intro: `<div class="arrow arrow-right">
                          <h6 class="onboarding-step">${$translate.instant('event.onboarding.step')}</h6>
                          <div class="text-center onboarding-tooltip-content">
                          ${$translate.instant('event.onboarding.step.5.content')}
                          </div>
                        </div>`,
                                position: 'left',
                            },
                        };

                        vm.IntroOptions = {
                            steps: [],
                            showStepNumbers: false,
                            showBullets: false,
                            exitOnOverlayClick: false,
                            exitOnEsc: false,
                            nextLabel: $translate.instant('event.onboarding.nextLabel'),
                            skipLabel: $translate.instant('event.onboarding.skipLabel'),
                            prevLabel: $translate.instant('event.onboarding.prevLabel'),
                            doneLabel: $translate.instant('event.onboarding.doneLabel'),
                        };

                        angular.forEach(vm.introElementKeys, function (key) {
                            if (
                                !(skipNo4 && key === '#onboarding-step-request-button') &&
                                !(!hasRequest && key === '.fc-time-grid-event')
                            ) {
                                var elements = vm.introElementsMap[key];
                                vm.IntroOptions.steps.push(elements);
                            }
                        });

                        $timeout(function () {
                            // CallMe is used to open the introjs
                            vm.CallMe();
                            EventGuideDataStore.setShowOnBoardingGuide(false);
                        }, 100);
                    }

                    function getTranslatedNameFn(name, nameCode) {
                        return CommonFunctions.getTranslatedTextFromCode(name, nameCode);
                    }

                    function isCloseRunningFn() {
                        ProcessLog.get({ referenceId: eventId, name: 'EVENT_CLOSE' }, (resp) => {
                            vm.eventCloseProcess = resp;
                            if (resp && resp.status && (resp.status.name == 'RUNNING' || resp.status.name == 'RUNNING_RUNNING_WITH_MESSAGE')) {
                                vm.isProcessRunning = true;
                            }
                            if (resp && resp.status && resp.status.name == 'GOT_ERROR') {
                                vm.isThereAnyErrorWhileCancellingEvent = true;
                                vm.isProcessRunning = false;
                            }
                            if (resp && resp.status && resp.status.name == 'UP_TO_DATE') {
                                vm.isProcessRunning = false;
                                vm.isThereAnyErrorWhileCancellingEvent = false;
                                if (new Date(resp.lastUpdated) > new Date(resp.dateCreated)) {
                                    $rootScope.$broadcast(EventEnum.EVENT_DETAIL_PAGE_RELOAD);
                                    $rootScope.$broadcast(EventEnum.EVENT_AND_REQUESTS_RELOAD);
                                }
                            }
                            if (vm.isProcessRunning) {
                                setTimeout(() => {
                                    isCloseRunningFn();
                                }, 10000);
                            }
                        });
                    }

                    function deleteProcessLogFn() {
                        $scope.startProgress();
                        let processId = vm.eventCloseProcess.id;
                        ProcessLog['delete'](
                            processId,
                            function () {
                                $scope.endProgress();
                                vm.isThereAnyErrorWhileCancellingEvent = false;
                                vm.eventCloseProcess = null;
                            },
                            $scope.endWithErrorOverlay()
                        );
                    }

                    function displayEventGuideTooltipFn() {
                        return EventGuideDataStore.showEventProgressGuideTooltip;
                    }

                    function closeEventGuideTooltipFn() {
                        EventGuideDataStore.setShowEventProgressGuideTooltip(false);
                    }

                    let processRunningStatusCheck = $rootScope.$on(
                        EventEnum.REQUEST_OFFERS_CLOSE_PROCESS_STATUS_CHECK,
                        function () {
                            isCloseRunningFn();
                        }
                    );
                    $rootScope.$on('$destroy', processRunningStatusCheck);

                    $scope.$on('message:all:suppliers', function (event, data) {
                        var offers = prepareUnsentOfferList(data.supplierMessage);
                        RequestOffer.sendRequestOffers({ offerList: offers }, function () {
                            vm.reFetchEventDetails();
                            vm.showSentNotification = true;
                        });
                    });

                    $scope.$on('action:taken:for:all:supplier:changes', function (event, data) {
                        angular.forEach(vm.requests, function (request) {
                            angular.forEach(request.requestOffers, function (requestOffer) {
                                if (requestOffer.id == data.offer.id) {
                                    requestOffer.creatorHasTakenActionForSupplierChanges = true;
                                }
                            });
                        });
                    });

                    $scope.$on('offer:agreement:number:updated', function (event, data) {
                        angular.forEach(vm.requests, function (request) {
                            angular.forEach(request.requestOffers, function (requestOffer) {
                                if (requestOffer.id === data.offerId) {
                                    requestOffer.agreementNumber = data.agreementNumber;
                                }
                            });
                        });
                    });

                    var requestOfferStatusChangedEvent = $rootScope.$on(
                        EventEnum.REQUEST_OFFER_STATUS_CHANGED_SUCCESS,
                        function (evt, data) {
                            Request.getStructureForDetail(
                                { requestId: data.requestId, offerId: data.offerId },
                                function (response) {
                                    angular.forEach(vm.requests, function (request, key) {
                                        if (request.id == response.id) {
                                            vm.requests[key] = response;
                                        }
                                    });
                                    NotificationService.setNotificationData(eventId);
                                },
                                $scope.endWithAlert
                            );
                        }
                    );

                    $scope.$on(EventEnum.EVENT_DETAIL_PAGE_RELOAD, function () {
                        getEventDetailPage(eventId, false);
                    });

                    $scope.$on(EventEnum.EVENT_AND_REQUESTS_RELOAD, function () {
                        getRequests();
                    });

                    $scope.$on(EventEnum.USER_IN_GROUP_DATA_CHANGED, function () {
                        readData(eventId);
                    });

                    $scope.$on('event:update:event', function (event, data) {
                        vm.event = data;
                    });

                    $scope.$on(EventEnum.VIEW_SUPPLIER_OFFER, function (event, data) {
                        viewOfferFn(data.offerId, data.requestId);
                    });

                    function getUserList() {
                        Event.getUsers({ eventId: eventId }, function (response) {
                            vm.collaborators = response;
                            $rootScope.sharedUsers = response;
                        });
                    }

                    vm.addRequest = function (requestData) {
                        var deferred = $q.defer();
                        requestData.event = {
                            class: 'se.eventlogic.centevent.Event',
                            id: eventId,
                        };
                        // Override participantAmount here for now
                        requestData.participantAmount = 0;
                        Request.save(requestData, function (/*response*/) {
                            getRequests(true);
                            deferred.resolve();
                        });

                        return deferred.promise;
                    };

                    $scope.$watch('eventDetailCtrl.displayAddContentSideBar', function (newVal, oldVal) {
                        if (newVal != oldVal) {
                            if (newVal) {
                                ogMatchHeightService.matchContentHeightWithSidebar();
                            } else {
                                ogMatchHeightService.resetContentHeight();
                            }
                        }
                    });

                    $scope.$watch('eventDetailCtrl.showNotification', function (newVal, oldVal) {
                        if (newVal != oldVal) {
                            $rootScope.$broadcast('notification:change');
                        }
                    });

                    $scope.$watch('eventDetailCtrl.showAllNotification', function (newVal, oldVal) {
                        if (newVal != oldVal) {
                            $rootScope.$broadcast('notification:change');
                        }
                    });

                    $scope.$on(EventEnum.ADD_REQUEST_TO_EVENT, function (evt, request, callback) {
                        vm.addRequest(request).then(callback);
                    });

                    let eventGuideIntro = $rootScope.$on(EventEnum.EVENT_GUIDE_INTRO_START, function (event, data) {
                        var watchDivLoaded = $scope.$watch(
                            'eventDetailCtrl.allDivLoaded(' + data.skipNo4 + ',' + data.hasRequest + ')',
                            (newVal) => {
                                if (newVal) {
                                    watchDivLoaded();
                                    vm.startIntroJs(data.skipNo4, data.hasRequest);
                                }
                            }
                        );
                    });

                    let showNotificationGuideForVideo = $rootScope.$on(
                        EventEnum.EVENT_GUIDE_GET_STARTED_NOTIFICATION_OPEN,
                        function () {
                            vm.setDisplayNotificationTab('EVENT_GUIDE');
                        }
                    );

                    //TODO(SC) REMOVE THIS , UNUSED CODE
                    $scope.$on('request:template:create', function (event, value) {
                        value.eventId = eventId;
                        Request.save(value, function (/*response*/) {
                            getRequests(true);
                            $scope.$broadcast('activityStream/changed', true);
                        });
                    });

                    $rootScope.$on('keepBadgeDesignActive', function (event) {
                        ActiveTabService.setEventDetailTab('participants');
                    });

                    $rootScope.$on('keepScheduleFormActive', function (event) {
                        ActiveTabService.setEventDetailTab('overview');
                    });

                    let openSupplierViewEvent = $rootScope.$on(EventEnum.OPEN_SUPPLIER_PREVIEW_MODAL, (evt, data) => {
                        viewOfferFn(data.offerId, data.requestId);
                    });

                    let eventAddressChanged = $rootScope.$on(
                        EventEnum.EVENT_ADDRESS_CHANGED,
                        function (event, data) {
                            vm.initialBounds = data.bounds;
                            vm.initialLocation = data.location;
                        }.bind(this)
                    );

                    $scope.$on('$destroy', eventAddressChanged);

                    $scope.$on('$destroy', openSupplierViewEvent);
                    $scope.$on('$destroy', eventGuideIntro);
                    $scope.$on('$destroy', showNotificationGuideForVideo);
                    $scope.$on('$destroy', requestOfferStatusChangedEvent);
                    $scope.$on('$destroy', processRunningStatusCheck); //should destroy the rootscope listening to avoid
                    // multiple execution from single broadcast
                }
            }
        ]
    });
})();
