/**
 * Created by kshitij on 10/16/17.
 */
import {ScopeFunctions} from '../../../utils/global/scopeFunctions';

class BadgeMenuItemCtrl {
    constructor($scope, ParticipantService) {
        this._injected = { $scope, ParticipantService };

        ScopeFunctions.addToScope($scope);

        $scope.$on(
            'lang:change',
            function (event, data) {
                this.lang = data.lang;
                this.getItemName(this.itemId, this.query, this.lang);
            }.bind(this)
        );
    }

    isItemAdded(field) {
        if (this.isFieldAddedCallback) {
            this.isFieldAddedCallback(field);
        }
    }

    getItemName(itemId, query, lang) {
        let { $scope, ParticipantService } = this._injected;
        let itemName;
        if (itemId === 'fullName') {
            switch (lang) {
                case 'sv_SV':
                    itemName = 'Förnamn & efternamn';
                    break;
                case 'nb_NB':
                    itemName = 'For- og etternavn';
                    break;
                case 'da_DK':
                    itemName = 'For- & efternavn';
                    break;
                case 'fi_FI':
                    itemName = 'Etu- & sukunimi';
                    break;
                default:
                    itemName = 'First & Last Name';
            }
        } else {
            itemName = ParticipantService.getQuestionLabel(query, lang);
        }
        return itemName;
    }

    addField(type, query) {
        this.addDesignFieldsCallback(type, query);
    }
}

BadgeMenuItemCtrl.$inject = ['$scope', 'ParticipantService'];
export default BadgeMenuItemCtrl;
