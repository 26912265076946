class OgInputDropDownController {
    constructor($scope, $document, $element) {
        this.listVisible = false;
        this.isPlaceholder = true;

        $scope.$watch('ogDropdownCtrl.selected', (newval) => {
            if (newval) {
                this.isPlaceholder = this.selected[this.property] === undefined;
                this.display = this.selected[this.property];
            } else {
                this.isPlaceholder = true;
            }
        });

        let documentClick = function (event) {
            if ($element.find(event.target).length > 0) {
                return;
            }
            this.listVisible = false;
        }.bind(this);

        $document.on('click', documentClick);

        //unbind the click listener
        $scope.$on('$destroy', function () {
            $document.off('click', documentClick);
        });
    }

    select(item) {
        this.isPlaceholder = false;
        this.selected = item;
        this.listVisible = false;
        if (angular.isDefined(this.onChange)) {
            if (this.callBackParams) {
                this.onChange(this.callBackParams, this.selected);
            } else {
                this.onChange(this.selected);
            }
        }
    }

    clearFieldCallbackFn() {
        this.clearFieldCallback();
    }

    toggle() {
        this.listVisible = !this.listVisible;
    }
}
OgInputDropDownController.$inject = ['$scope', '$document', '$element'];
export default OgInputDropDownController;
