'use strict';
import {OgModal} from "../../utils/global/ogModal";

angular.module('dialog').directive('ogConfirmationModal', [
    function () {
        return new OgModal({
            name: 'confirmation',
            type: 'custom',
            controller: function ($scope) {
                $scope.initDialog = function (settings) {
                    $scope.okCallBack = settings.okCallBack;
                    $scope.cancelCallBack = settings.cancelCallBack;
                    $scope.closeCallBack = settings.closeCallBack;
                    $scope.message = settings.message;
                    $scope.messageArgs = settings.messageArgs;
                    $scope.okLabel = settings.okLabel;
                    $scope.cancelLabel = settings.cancelLabel;
                    $scope.isHtml = !!settings.html;
                };

                $scope.clearDialog = function () {
                    $scope.okCallBack = null;
                    $scope.cancelCallBack = null;
                    $scope.closeCallBack = null;
                    $scope.okLabel = null;
                    $scope.cancelLabel = null;
                    $scope.message = null;
                    $scope.messageArgs = null;
                };

                $scope.ok = function () {
                    var okCallBack = $scope.okCallBack;
                    $scope.close();
                    if (okCallBack) {
                        okCallBack();
                    }
                };

                $scope.cancel = function () {
                    var cancelCallBack = $scope.cancelCallBack;
                    $scope.close();
                    if (cancelCallBack) {
                        cancelCallBack();
                    }
                };

                $scope.closeModal = function () {
                    var closeCallBack = $scope.closeCallBack;
                    if (closeCallBack) {
                        closeCallBack();
                    }
                };
            },
        });
    },
]);

