import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import _ from 'underscore';

class QueryDisplaySettingsModalCtrl {
    constructor(
        data,
        $rootScope,
        $scope,
        close,
        $element,
        ParticipantBasicQuery,
        EventParticipantCustomQuery,
        ParticipantService,
        ParticipantUtilService
    ) {
        this._injected = {
            data,
            $rootScope,
            $scope,
            close,
            $element,
            ParticipantBasicQuery,
            EventParticipantCustomQuery,
            ParticipantService,
            ParticipantUtilService,
        };

        this.query = data.query;
        this.queryList = data.queryList;
        this.type = data.type; //BASIC or CUSTOM

        ScopeFunctions.addToScope($scope);

        this.getShowHideLogic();
        this.getShowHideLogicForBasicQuery = this.getShowHideLogicForBasicQuery.bind(this);
        this.getShowHideLogicForCustomQuery = this.getShowHideLogicForCustomQuery.bind(this);
    }

    getQueryNameBySelectedLanguage(query) {
        const {$rootScope, ParticipantService} = this._injected;
        if (query) {
            return ParticipantService.getQuestionLabel(query, $rootScope.langSelected);
        }
        return null;
    }

    getOptionValues(options) {
        const { $rootScope } = this._injected;
        let lang = $rootScope.langSelected;
        return this._getOptions(options, lang);
    }

    _getOptions(options, lang) {
        if (options && options.indexOf('~') >= 0) {
            let _options = options.split('~');
            let _englishOptions = _options ? _options[0] : null;
            let _swedishOptions = _options.length > 1 ? _options[1] : null;
            let _norwegianOptions = _options.length > 2 ? _options[2] : null;
            let _danishOptions = _options.length > 3 ? _options[3] : null;
            let _finnishOptions = _options.length > 4 ? _options[4] : null;
            if (lang === 'en_US') {
                return _englishOptions
                    ? _englishOptions
                    : _swedishOptions
                        ? _swedishOptions
                        : _norwegianOptions
                            ? _norwegianOptions
                            : _danishOptions;
            }
            if (lang === 'sv_SV') {
                return _swedishOptions
                    ? _swedishOptions
                    : _englishOptions
                        ? _englishOptions
                        : _norwegianOptions
                            ? _norwegianOptions
                            : _danishOptions;
            }
            if (lang === 'nb_NB') {
                return _norwegianOptions
                    ? _norwegianOptions
                    : _englishOptions
                        ? _englishOptions
                        : _swedishOptions
                            ? _swedishOptions
                            : _danishOptions;
            }
            if (lang === 'da_DK') {
                return _danishOptions
                    ? _danishOptions
                    : _englishOptions
                        ? _englishOptions
                        : _swedishOptions
                            ? _swedishOptions
                            : _norwegianOptions;
            }
            if (lang === 'fi_FI') {
                return _finnishOptions
                    ? _finnishOptions
                    : _danishOptions
                        ? _danishOptions
                        : _englishOptions
                            ? _englishOptions
                            : _swedishOptions
                                ? _swedishOptions
                                : _norwegianOptions;
            }
        } else {
            return options;
        }
    }

    getShowHideLogic() {
        if (this.type === 'BASIC') {
            this.getShowHideLogicForBasicQuery();
        } else if (this.type === 'CUSTOM') {
            this.getShowHideLogicForCustomQuery();
        }
    }

    getShowHideLogicForBasicQuery() {
        const { $scope, ParticipantBasicQuery } = this._injected;
        $scope.startProgress('query-display-settings');
        ParticipantBasicQuery.getShowHideLogic(
            this.query.eventParticipantBasicQuery.id,
            (response) => {
                this.setDisplaySettings(response);
                $scope.endProgress('query-display-settings');
            },
            $scope.endWithErrorOverlay('query-display-settings')
        );
    }

    getShowHideLogicForCustomQuery() {
        const { $scope, EventParticipantCustomQuery } = this._injected;
        $scope.startProgress('query-display-settings');
        EventParticipantCustomQuery.getShowHideLogic(
            this.query.id,
            (response) => {
                this.setDisplaySettings(response);
                $scope.endProgress('query-display-settings');
            },
            $scope.endWithErrorOverlay('query-display-settings')
        );
    }

    setShowOrHide(type) {
        this.showOrHideButtonType = {};
        this.showOrHide = {
            onShow: false,
            onHide: false,
        };
        this.showOrHideButtonType = type;
        if (this.showOrHideButtonType === 'ON_SHOW') {
            this.showOrHide.onShow = true;
        } else if (this.showOrHideButtonType === 'ON_HIDE') {
            this.showOrHide.onHide = true;
        }
    }

    setDisplaySettings(response) {
        const { $rootScope, ParticipantUtilService } = this._injected;
        var lang = $rootScope.langSelected;
        this.selectedQuery = response.query;
        this.originalDependentQuery = response.dependentQuery;
        // this.dependentQuery = response.dependentQuery;
        this.otherQueriesInEvent = response.otherQueriesInEvent;
        if (response.dependentQuery) {
            this.dependentQuery = _.find(response.otherQueriesInEvent, (query) => {
                return response.dependentQuery.id === query.id;
            });
            if (this.dependentQuery.type.name === 'CHECKBOX') {
                response.value === 'true' ? (this.value = true) : (this.value = false);
            } else if (this.dependentQuery.type.name === 'DROPDOWN') {
                var value = response.value.split('^');
                if (lang === 'en_US') {
                    this.value = value[0];
                } else if (lang === 'sv_SV') {
                    this.value = value[1];
                } else if (lang === 'nb_NB') {
                    this.value = value[2];
                } else if (lang === 'da_DK') {
                    this.value = value[3];
                } else {
                    this.value = value[4];
                }
            } else if (this.dependentQuery.type.name === 'MULTISELECT') {
                this.value = response.value.split('^');
            } else if (_.contains(['NUMBER', 'TEXTAREA'], this.dependentQuery.type.name.trim())) {
                this.value = response.value;
            } else {
                this.value = ParticipantUtilService.getFieldValue(
                    this.dependentQuery.optionValues,
                    lang,
                    response.value
                );
            }
        }
        response.show ? this.setShowOrHide('ON_SHOW') : this.setShowOrHide('ON_HIDE');
    }

    deleteSettings() {
        this.update(true);
    }

    update(remove = false) {
        const { $rootScope, ParticipantUtilService } = this._injected;
        var lang = $rootScope.langSelected;
        let showOrHide = this.showOrHide.onShow;
        var value = this.value;
        if (this.dependentQuery.type.name === 'DROPDOWN') {
            if (this.dependentQuery.optionValues) {
                let _engOpts = this._getOptions(this.dependentQuery.optionValues, 'en_US');
                let _svOpts = this._getOptions(this.dependentQuery.optionValues, 'sv_SV');
                let _nbOpts = this._getOptions(this.dependentQuery.optionValues, 'nb_NB');
                let _daOpts = this._getOptions(this.dependentQuery.optionValues, 'da_DK');
                let _fiOpts = this._getOptions(this.dependentQuery.optionValues, 'fi_FI');

                let _engValue = ParticipantUtilService.getFieldValue(this.dependentQuery.optionValues, 'en_US', value);
                let _svValue = ParticipantUtilService.getFieldValue(this.dependentQuery.optionValues, 'sv_SV', value);
                let _nbValue = ParticipantUtilService.getFieldValue(this.dependentQuery.optionValues, 'nb_NB', value);
                let _daValue = ParticipantUtilService.getFieldValue(this.dependentQuery.optionValues, 'da_DK', value);
                let _fiValue = ParticipantUtilService.getFieldValue(this.dependentQuery.optionValues, 'fi_FI', value);

                let _eng = _.find(_engOpts.split('#'), (eng) => {
                    return eng.toLowerCase() === _engValue.toString().toLowerCase();
                });
                let _sv = _.find(_svOpts.split('#'), (sv) => {
                    return sv.toLowerCase() === _svValue.toString().toLowerCase();
                });
                let _nb = _.find(_nbOpts.split('#'), (nb) => {
                    return nb.toLowerCase() === _nbValue.toString().toLowerCase();
                });
                let _da = _.find(_daOpts.split('#'), (da) => {
                    return da.toLowerCase() === _daValue.toString().toLowerCase();
                });
                let _fi = _.find(_fiOpts.split('#'), (fi) => {
                    return fi.toLowerCase() === _fiValue.toString().toLowerCase();
                });
                value = _eng + '^' + _sv + '^' + _nb + '^' + _da + '^' + _fi;
            }
        }
        let data = { show: showOrHide, query: this.dependentQuery, value: value, remove: remove, prefLang: lang };
        if (this.type === 'BASIC') {
            this.updateShowHideLogicForBasicQuery(data);
        } else if (this.type === 'CUSTOM') {
            this.updateShowHideLogicForCustomQuery(data);
        }
        this._close();
    }

    updateShowHideLogicForBasicQuery(data) {
        const { $scope, ParticipantBasicQuery } = this._injected;
        $scope.startProgress('query-display-settings');
        ParticipantBasicQuery.updateShowHideLogic(
            this.query.eventParticipantBasicQuery.id,
            data,
            ((response) => {
                this.setDisplaySettings(response);
                $scope.endProgress('query-display-settings');
            }).bind(this),
            $scope.endWithErrorOverlay('query-display-settings')
        );
    }

    updateShowHideLogicForCustomQuery(data) {
        const { $scope, EventParticipantCustomQuery } = this._injected;
        $scope.startProgress('query-display-settings');
        EventParticipantCustomQuery.updateShowHideLogic(
            this.query.id,
            data,
            (response) => {
                this.setDisplaySettings(response);
                $scope.endProgress('query-display-settings');
            },
            $scope.endWithErrorOverlay('query-display-settings')
        );
    }

    _close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }
}

QueryDisplaySettingsModalCtrl.$inject = [
    'data',
    '$rootScope',
    '$scope',
    'close',
    '$element',
    'ParticipantBasicQuery',
    'EventParticipantCustomQuery',
    'ParticipantService',
    'ParticipantUtilService',
];
export default QueryDisplaySettingsModalCtrl;
