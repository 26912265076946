import {ScopeFunctions} from "../../../../../utils/global/scopeFunctions";
(function () {
    'use strict';
    angular.module('notes').component('notes', {
        templateUrl: './app/components/modules/event/event_detail/notes/notes.view.html',
        controllerAs: 'notesCtrl',
        controller: ['$scope', '$log', '$rootScope', '$stateParams', 'Note', 'EventEnum', 'RequestOffer', 'CommonFunctions',
        function NotesController($scope, $log, $rootScope, $stateParams, Note, EventEnum, RequestOffer, CommonFunctions){
            var vm = this;

            vm.containsMessage = containsMessageFn;
            vm.createNote = createNoteFn;
            vm.errorCallback = errorCallbackFn;
            vm.getLastNotes = getLastNotesFn;
            vm.getNoteStream = getNoteStreamFn;
            vm.getPaginatedNotes = getPaginatedNotesFn;
            vm.init = initFn;
            vm.loadNextPage = loadNextPageFn;
            vm.noteStreamCallback = noteStreamCallbackFn;
            vm.pageChanged = pageChangedFn;
            vm.paginatedNoteStreamCallback = paginatedNoteStreamCallbackFn;
            vm.resetNotesList = resetNotesListFn;
            vm.sendMessage = sendMessageFn;
            vm.sendMessageCallback = sendMessageCallbackFn;
            vm.toggleNotesEditView = toggleNotesEditViewFn;
            vm.translateTextByCode = translateTextByCodeFn;
            vm.updateNote = updateNoteFn;
            vm.updateNotes = updateNotesFn;
            vm.visibleToSupplier = visibleToSupplierFn;

            vm.currentPage = 1;
            vm.notes = [];
            vm.numPerPage = 6;
            vm.noteForm = {};
            vm.streamLoaded = false;

            var eventId;
            var requestId;
            var offerId;

            ScopeFunctions.addToScope($scope);
            resetPaginationParams();
            vm.init();
            vm.resetNotesList();

            function containsMessageFn(message) {
                var contains = false;
                angular.forEach(vm.notes, function (item) {
                    if (item.id === message.id) {
                        contains = true;
                    }
                });
                return contains;
            }

            function createNoteFn() {
                if (!vm.noteForm.newNoteForm.$valid) {
                    CommonFunctions.touchFormFields(vm.noteForm.newNoteForm);
                    return;
                }
                var messageBody = vm.messageBody;
                if (tinyMCE && false) {
                    messageBody = tinyMCE.get('messageBody').getContent();
                }
                let htmlCleaned = cleanHTML(messageBody);
                vm.sendMessage(htmlCleaned);
            }

            function cleanHTML(str) {
                return str
                    .replace(/&/g, '&amp;')
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&#39;')
                    .replace(/\//g, '&#x2F;');
            }

            function errorCallbackFn(error) {
                vm.error = error;
                vm.streamSpinner = false;
                vm.messageSpinner = false;
            }

            function getLastNotesFn() {
                Note.list(
                    {
                        eventId: vm.eventId,
                        requestId: vm.requestId,
                        offerId: vm.offerId,
                        type: 'NOTE',
                        page: vm.params.offset,
                        pageSize: vm.numPerPage,
                    },
                    function (response) {
                        vm.notes = response.list;
                    },
                    vm.errorCallback
                );
            }

            function getNoteStreamFn() {
                vm.streamSpinner = true;
                vm.updateNotes();
            }

            function getPaginatedNotesFn() {
                var params = {
                    eventId: vm.eventId,
                    page: vm.params.offset,
                    pageSize: vm.numPerPage,
                    type: 'NOTE',
                };
                if (vm.level != 'EVENT') {
                    params.requestId = vm.requestId;
                    params.offerId = vm.offerId;
                }
                $scope.startProgress('notes');
                Note.list(params, vm.paginatedNoteStreamCallback, vm.errorCallback);
            }

            function initFn() {
                $scope.startProgress('notes');
                vm.isEditable = true;
                vm.messageSpinner = false;
                vm.requestId = $stateParams.requestId;
                vm.offerId = $stateParams.offerId;
                vm.eventId = $stateParams.eventId;
                vm.offers = vm.offers || [];

                //The below code is repeated also in message.controller. Please make this block of code generic.
                RequestOffer.requestOffersForMessageByEventId(
                    vm.eventId,
                    function (response) {
                        vm.offers = response;
                        $scope.endProgress('notes');
                    },
                    vm.errorCallback
                );

                eventId = vm.eventId;
                requestId = vm.requestId;
                offerId = vm.offerId;

                if (!$stateParams.eventId) {
                    vm.showEventLink = true;
                }
                $rootScope.$on('note:s:offer:id', function (evt, data) {
                    vm.offerId = data.offerId;
                });
                vm.getPaginatedNotes();
            }

            function loadNextPageFn() {
                if (vm.loading) {
                    return;
                }
                if (vm.totalNotesCount !== -1) {
                    if (vm.notes.length >= vm.totalNotesCount) {
                        return;
                    }
                }
                vm.loading = true;
                vm.getPaginatedNotes();
            }

            function noteStreamCallbackFn(response) {
                vm.streamSpinner = false;
                vm.resetNotesList();
                vm.notes = response.list;
                vm.currentPage = 1;

                vm.messageSpinner = false;
                vm.streamLoaded = true;
            }

            function paginatedNoteStreamCallbackFn(response) {
                vm.notes = response.list;
                vm.streamSpinner = false;
                vm.messageSpinner = false;
                vm.streamLoaded = true;
                vm.loading = false;
                vm.totalNotesCount = response.totalCount;
                vm.page = vm.page + 1;
                vm.totalCount = Math.ceil(response.totalCount / vm.numPerPage);
                $scope.endProgress('notes');
            }

            function resetNotesListFn() {
                vm.loading = false;
                vm.page = 0;
                vm.totalNotesCount = -1; //default value
                vm.notes = [];
            }

            function sendMessageFn(messageBody) {
                if (messageBody && messageBody.length > 0) {
                    var compiledMessageBody = messageBody;
                    vm.messageSpinner = true;
                    Note.save(
                        {
                            eventId: vm.eventId,
                            requestId: vm.requestId,
                            offerId: vm.offerId,
                            messageBody: compiledMessageBody,
                        },
                        vm.sendMessageCallback,
                        vm.errorCallback
                    );
                }
            }

            function sendMessageCallbackFn() {
                vm.messageSpinner = false;
                vm.messageBody = '';
                if (tinyMCE && false) {
                    var editor = tinyMCE.get('messageBody');
                    if (editor) {
                        editor.setContent('');
                    }
                }
                vm.getNoteStream();
                vm.noteForm.newNoteForm.$setPristine();
                vm.noteForm.newNoteForm.$setUntouched();
            }

            function updateNotesFn() {
                resetPaginationParams();
                var params = {
                    eventId: vm.eventId,
                    type: 'NOTE',
                    page: vm.params.offset,
                    pageSize: vm.numPerPage,
                };
                if (vm.level != 'EVENT') {
                    params.requestId = vm.requestId;
                    params.offerId = vm.offerId;
                }
                Note.list(params, vm.noteStreamCallback, vm.errorCallback);
            }

            function visibleToSupplierFn() {
                return vm.isSupplier || (vm.offer && vm.offer.supplier);
            }

            function pageChangedFn() {
                setPaginationParams();
                vm.getPaginatedNotes();
            }

            function toggleNotesEditViewFn(event, note) {
                if (vm.noteId == 'n' + note.id) {
                    vm.noteId = 0;
                    if (event.currentTarget.id == 'update-note-button') {
                        vm.updateNote(note);
                    }
                } else {
                    vm.noteId = 'n' + note.id;
                }
            }

            function translateTextByCodeFn(text, code) {
                return CommonFunctions.getTranslatedTextFromCode(text, code);
            }

            function updateNoteFn(note) {
                Note.update(
                    { note: note },
                    function (data) {
                        angular.forEach(vm.notes, function (note, index) {
                            if (note.id == data.id) {
                                vm.notes.splice(index, 1, data);
                            }
                        });
                    },
                    $rootScope.endWithAlert
                );
            }

            function resetPaginationParams() {
                vm.params = { offset: 0, max: vm.numPerPage };
            }

            function setPaginationParams() {
                vm.params.offset = (vm.currentPage - 1) * vm.numPerPage;
                vm.params.max = vm.numPerPage;
            }

            var noteTabClickedEvent = $rootScope.$on(EventEnum.NOTE_TAB_CLICKED_ON_HOME_PAGE, function (data) {
                vm.eventId = data.eventId;
                vm.offers = data.offers;
                vm.init();
            });

            $scope.$on('activityStream/changed', function () {
                vm.resetNotesList();
                vm.loadNextPage();
            });

            $scope.$watch('notesCtrl.offerId', function (a, b) {
                if (typeof a == 'undefined' && typeof b == 'undefined') {
                    return;
                }
                if (a === '') {
                    vm.level = 'EVENT';
                    vm.privateMessage = true;
                    vm.offer = null;
                } else {
                    vm.level = 'OFFER';
                    vm.privateMessage = false;

                    angular.forEach(vm.offers, function (offer) {
                        if (offer.id == a) {
                            vm.offer = offer;
                        }
                    });
                }
                vm.resetNotesList();
                vm.loadNextPage();

                $scope.$on('$destroy', noteTabClickedEvent);
            });
        }]
    });
})();
