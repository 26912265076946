(function () {
    'use strict';

    angular.module('ogCategoryTypeahead').directive('ogCategoryTypeahead', [
        '$timeout',
        '$document',
        'SearchService',
        '$translate',
        function ($timeout, $document, SearchService, $translate) {
            function filterTreeRecursive(nodes, searchString, depth) {
                if (searchString == null) {
                    searchString = '';
                }
                //if(searchString.trim() == "") return [];
                if (depth == null) {
                    depth = 0;
                }

                searchString = searchString.toUpperCase();
                var result = [];
                if (nodes) {
                    for (var i = 0; i < nodes.length; i++) {
                        var childCategories = [];
                        if (nodes[i].hasOwnProperty('childCategories') && nodes[i].childCategories.length > 0) {
                            childCategories = filterTreeRecursive(nodes[i].childCategories, searchString, depth + 1);
                        }

                        var index = nodes[i].name.toUpperCase().indexOf(searchString);

                        if (index >= 0 || childCategories.length > 0) {
                            var obj = {
                                object: { id: nodes[i].id, name: nodes[i].name },
                                html:
                                    index < 0
                                        ? nodes[i].name
                                        : getTranslatedName(nodes[i].name, nodes[i].nameCode, 0, index) +
                                          '<b>' +
                                          getTranslatedName(
                                              nodes[i].name,
                                              nodes[i].nameCode,
                                              index,
                                              index + searchString.length
                                          ) +
                                          '</b>' +
                                          getTranslatedName(
                                              nodes[i].name,
                                              nodes[i].nameCode,
                                              index + searchString.length
                                          ),
                                class: 'level-' + depth,
                            };
                            result.push(obj);

                            for (var c = 0; c < childCategories.length; c++) {
                                result.push(childCategories[c]);
                            }
                        }
                    }
                }
                return result;
            }

            function getTranslatedName(name, nameCode, startIndex, endIndex) {
                var translatedName = name;
                if (nameCode) {
                    translatedName = $translate.instant(nameCode);
                }
                return translatedName.substring(startIndex, endIndex);
            }

            return {
                restrict: 'AE',
                scope: {
                    placeholder: '@',
                    categories: '=',
                    allowFreetext: '@',
                    icon: '@',
                    onChange: '&',
                    preselectId: '=',
                    preselect: '=',
                    open: '=',
                },
                templateUrl: './app/components/directives/category_typeahead/og_category_typeahead.view.html',
                link: function ($scope, element) {
                    $scope.searching = false;
                    $scope.blurred = true;
                    $scope.selectedIndex = -1;

                    $scope.filteredCategories = [];

                    $scope.$watch('model', function () {
                        $scope.selectedIndex = -1;
                        $scope.filteredCategories = filterTreeRecursive($scope.categories, $scope.model);
                        SearchService.setSearchText($scope.model); //set search text parameter in public search controller
                    });

                    var allowFreetext = !(
                        $scope.allowFreetext.toLowerCase() == 'false' || $scope.allowFreetext === false
                    )
                        ? true
                        : false;

                    function preselect(id, categories) {
                        if (categories) {
                            angular.forEach(categories, function (category) {
                                if (category.id == id) {
                                    $scope.model = category.name;
                                    $scope.selected = category.name;
                                    $scope.searching = true;
                                }
                                preselect(id, category.childCategories);
                            });
                        }
                    }

                    function preselectWatch() {
                        if ($scope.preselectId && $scope.categories) {
                            $scope.model = '';
                            $scope.searching = false;
                            preselect($scope.preselectId, $scope.categories);
                        }
                    }

                    $scope.$watch('preselectId', preselectWatch);
                    $scope.$watch('categories', preselectWatch);

                    $scope.$watch('preselect', function (value) {
                        if (value != null && value != '') {
                            $scope.model = value;
                            $scope.searching = true;
                        }
                    });

                    $scope.onSearchOrClear = function () {
                        if (!allowFreetext && $scope.selectedIndex < 0) {
                            for (var i = 0; i < $scope.filteredCategories.length; i++) {
                                var obj = $scope.filteredCategories[i].object;
                                if (obj.id > 0 && obj.name.toLowerCase().trim() == $scope.model.toLowerCase().trim()) {
                                    $scope.selectedIndex = 1;
                                }
                            }
                        }

                        if ($scope.searching) {
                            $scope.model = '';
                            $scope.searching = false;
                            $scope.onChange({ search: null });
                        } else if ($scope.selectedIndex >= 0) {
                            let obj = $scope.filteredCategories[$scope.selectedIndex].object;
                            $scope.searching = true;
                            $scope.model = obj.name;
                            $scope.onChange({ search: { type: 'category', category: obj } });
                        } else if ($scope.model != '' && allowFreetext) {
                            $scope.searching = true;
                            $scope.onChange({ search: { type: 'text', text: $scope.model } });
                        } else {
                            $scope.model = '';
                            $scope.searching = false;
                            $scope.onChange({ search: null });
                        }
                        $scope.selected = $scope.model;
                        $scope.blurred = !$scope.searching;

                        if ($scope.searching) {
                            jQuery(element).find('input').blur();
                        } else {
                            jQuery(element).find('input').focus();
                        }
                    };

                    $scope.onCategoryClicked = function (index) {
                        $scope.selectedIndex = index;
                        $scope.onSearchOrClear();
                    };

                    $scope.onKeyDown = function ($event) {
                        //console.log($scope.categories);

                        if ($event.keyCode == 38 && $scope.selectedIndex > 0) {
                            $scope.selectedIndex--;
                            $event.stopPropagation();
                        } else if (
                            $event.keyCode == 40 &&
                            $scope.selectedIndex < $scope.filteredCategories.length - 1
                        ) {
                            $scope.selectedIndex++;
                            $event.stopPropagation();
                        } else if ($event.keyCode == 13) {
                            $scope.onSearchOrClear();
                        } else if ($event.keyCode != 38 && $event.keyCode != 40) {
                            $scope.searching = false;
                            $scope.selectedIndex = -1;
                        }
                    };

                    $scope.isCurrent = function (index) {
                        return $scope.current == index;
                    };

                    $scope.setCurrent = function (index) {
                        $scope.current = index;
                    };

                    var categoryClicked = false;
                    $document.bind('click', function (event) {
                        categoryClicked = element.find(event.target).length > 0;

                        if (categoryClicked) {
                            return;
                        }

                        $scope.blurred = true;
                    });

                    jQuery(element)
                        .find('input')
                        .focus(function () {
                            $timeout(function () {
                                if ($scope.blurred) {
                                    $scope.blurred = false;
                                }
                            });
                        });
                },
            };
        },
    ]);
})();
