import googleAddressUtil from '../../../../utils/GoogleAddressUtil';
import { ScopeFunctions } from '../../../../utils/global/scopeFunctions';
import _ from 'underscore';

const HEADER_TEXT_CONTENT_MAP = {
    first_step: ['create.event.from.public.header.first_step.0', 'create.event.from.public.header.first_step.1'],
    second_step: ['create.event.from.public.header.second_step.0', 'create.event.from.public.header.second_step.1'],
    third_step: ['create.event.from.public.header.third_step.0', 'create.event.from.public.header.third_step.1'],
    fourth_step: ['create.event.from.public.header.fourth_step.0', 'create.event.from.public.header.fourth_step.1'],
    fifth_step: ['create.event.from.public.header.fifth_step.0', 'create.event.from.public.header.fifth_step.1'],
    sixth_step: ['create.event.from.public.header.sixth_step.0', 'create.event.from.public.header.sixth_step.1'],
};

const PROGRESS_BAR_MESSAGE = {
    first_step: { name: 'create.event.header.progress.first_step', value: 2 },
    second_step: { name: 'create.event.header.progress.second_step', value: 25 },
    third_step: { name: 'create.event.header.progress.third_step', value: 50 },
    fourth_step: { name: 'create.event.header.progress.fourth_step', value: 65 },
    fifth_step: { name: 'create.event.header.progress.fifth_step', value: 80 },
    sixth_step: { name: 'create.event.header.progress.sixth_step', value: 95 },
    seventh_step: { name: 'create.event.header.progress.seventh_step', value: 100 },
};

let FOCUS_MAP = {
    SUB_CATEGORY: false,
    DATE_PICKER: false,
    PARTICIPANT: false,
    OVERNIGHT_GUEST: false,
    USERNAME: false,
    PASSWORD: false,
    EXTRA_REQUIREMENTS: false,
    EVENT_NAME: false,
};

class createEventPublicMoCtrl {
    constructor(
        $scope,
        $element,
        $stateParams,
        close,
        Category,
        Event,
        RequestOfferPart,
        $location,
        $timeout,
        TimezoneService,
        SearchService,
        $state,
        EventGuideDataStore,
        CustomerUser,
        $rootScope,
        DateService,
        AuthenticationService,
        ClientService,
        EventEnum,
        AuthRegister,
        $translate,
        CommonFile
    ) {
        this._injected = {
            $scope,
            $element,
            $stateParams,
            close,
            Category,
            Event,
            RequestOfferPart,
            $location,
            $timeout,
            TimezoneService,
            SearchService,
            $state,
            EventGuideDataStore,
            CustomerUser,
            $rootScope,
            DateService,
            AuthenticationService,
            ClientService,
            EventEnum,
            AuthRegister,
            $translate,
            CommonFile,
        };

        ScopeFunctions.addToScope($scope);

        $scope.progressState = 0;
        $scope.lang = $rootScope.langSelected;

        this.getDateSpan = DateService.getDateSpan;
        this.hasAcceptedTerms = true;
        this.displayCreateEventForm = true;
        this.headerText = HEADER_TEXT_CONTENT_MAP.first_step;
        this.parentUserGroups = null;
        this.parentGroupId = null;
        let hideDatePicker = function () {
            if (this.showCalendar) {
                this.actionOnDatePickerClose();
            }
        }.bind(this);

        $element.on('click', function (event) {
            $timeout(function () {
                var clickedElement = event.target;
                if (!clickedElement) {
                    return;
                }
                var elementClasses = clickedElement.classList;
                var calendarClicked =
                    elementClasses.contains('picker-navigate') || elementClasses.contains('picker-day');
                if (calendarClicked) {
                    return;
                }
                var datePickerContainerClicked = $(event.target).closest('#eventMultipleDatePickerContainer').length;
                if (!datePickerContainerClicked) {
                    hideDatePicker();
                }
            });
        });

        $scope.startProgress('create-event-public-modal');
        Category.eventTypeList(
            {},
            (response) => {
                this.eventCategoryList = response;
                this.getClientBannerImgId();
                $scope.endProgress('create-event-public-modal');
            },
            $scope.endWithErrorOverlay('create-event-public-modal')
        );

        this.currentTab = 1;
        this.FirstStepForm = 'FirstStepForm';
        this.SecondStepForm = 'SecondStepForm';
        this.ThirdStepForm = 'ThirdStepForm';
        this.RegistrationForm = 'RegistrationForm';

        //this.headerText = 'Hej ' + $scope.userData;
        this.eventFromAndToDates = [];
        this.selectFromAndToDate = function (event, data) {
            this.progressbar = PROGRESS_BAR_MESSAGE.fourth_step;
            this.eventFromAndToDates = [];
            /*see http://underscorejs.org/#times*/
            _(this.numberOfEventDays).times((index) => {
                this.eventFromAndToDates.push(moment(data.date).add(index, 'days'));
            });
            this.showHidDatePicker();
            $scope.progressState = 3;
        }.bind(this);

        this.hoverOnDay = function (event, data) {
            /*see http://arca-computing.github.io/MultipleDatePicker/#highlight-days*/
            this.highlightDays = [];
            _(this.numberOfEventDays).times((index) => {
                this.highlightDays.push({
                    date: moment(data.date).add(index, 'days').valueOf(),
                    css: 'hover-highlight',
                });
            });
        }.bind(this);

        this.progressbar = PROGRESS_BAR_MESSAGE.first_step;
        this.noSingleBedRoomReserved = 0;
        this.calcBedroom = () => {
            this.noDoubleBedRoom = this.slider.value;
            this.noSingleBedRoom = this.noSingleBedRoomReserved + (this.slider.options.ceil - this.slider.value) * 2;
        };

        this.slider = {
            value: 0,
            options: {
                floor: 0,
                ceil: 0,
                step: 1,
                translate: () => '',
                onChange: () => {
                    this.calcBedroom();
                },
            },
        };

        $scope.$watch('createEventPublicMoCtrl.noOfOvernightGuests', (newval) => {
            if (newval) {
                this.slider.options.ceil = parseInt(newval / 2);
                this.slider.value = 0;
                if (newval % 2 === 1) {
                    this.noSingleBedRoomReserved = 1;
                } else {
                    this.noSingleBedRoomReserved = 0;
                }
                this.calcBedroom();
            }
        });

        $scope.$watch('createEventPublicMoCtrl.eventCategory', (newval) => {
            if (newval) {
                this.getSubTypeList();
                $scope.progressState = 1;
            }
        });
        $scope.$watch('createEventPublicMoCtrl.eventSubCategory', (newval) => {
            if (newval) {
                this.searchEventTemplates();
                $scope.progressState = 2;
                $scope.locationDropdown = false;
            }
        });

        this.createEventFromTemplate = this.createEventFromTemplate.bind(this);
    }

    getClientBannerImgId() {
        const { $scope, ClientService, CommonFile } = this._injected;
        $scope.startProgress();
        $scope.clientBannerImgId = ClientService.getClientBannerImgId();
        this.client = ClientService.get();
        if ($scope.clientBannerImgId) {
            $scope.url = CommonFile.getBaseUrl() + 'api/v1/file/getCommonFile/' + $scope.clientBannerImgId;
        }
        $scope.endProgress();
    }

    disabledDatePicker() {
        return !this.numberOfEventDays;
    }

    resetSelectedValues() {
        this.eventSubCategory = null; //Reset selected SubCategory when the event type changes.
        this.eventFromAndToDates = []; //Reset Date if it is in step3 => step 2
        this.eventFromAndToDateStr = null; //Reset String
        this.selectedTemplate = null;
        this.accommodations = null;
        this.numberOfEventDays = null;
        this.noOfOvernightGuests = null;
    }

    getSubTypeList() {
        this.progressbar = PROGRESS_BAR_MESSAGE.second_step;
        this.headerText = HEADER_TEXT_CONTENT_MAP.second_step;
        if (this.eventCategory.isTemplate) {
            this.getEventTemplateList();
        } else {
            this.getSubCategoryList();
        }
    }

    getSubCategoryList() {
        let { Category, $scope } = this._injected;
        $scope.startProgress();
        this.currentTab = 1; //Go to step 1
        this.resetSelectedValues(); //Reset all values while selecting from step 3
        Category.eventSubTypeList(this.eventCategory.id, (response) => {
            //If there is no sub category list, should find template from categoryId only
            if (response.length < 1) {
                this.findEventTemplateFromCategoryIds();
            }
            this.eventSubCategoryList = response;
            this.setFocusOn('SUB_CATEGORY');
            $scope.endProgress();
        });
    }

    getEventTemplateList() {
        const { Event, $scope } = this._injected;
        let params = { privateOnly: true };
        $scope.startProgress('create-event-public-modal');
        Event.searchTemplates(
            params,
            (resp) => {
                this.eventSubCategoryList = resp.list;
                this.setFocusOn('SUB_CATEGORY');
                $scope.endProgress('create-event-public-modal');
            },
            $scope.endWithErrorOverlay('create-event-public-modal')
        );
    }

    setFocusOn(focusOn) {
        this.clearFocus();
        FOCUS_MAP[focusOn] = true;
    }

    clearFocus() {
        for (var key in FOCUS_MAP) {
            FOCUS_MAP[key] = false;
        }
    }

    getFocusOn(prop) {
        return FOCUS_MAP[prop];
    }

    searchEventTemplates() {
        this.setFocusOn('DATE_PICKER');
        this.showCalendar = true;
        this.progressbar = PROGRESS_BAR_MESSAGE.third_step;
        this.findEventTemplateFromCategoryIds();
    }

    showHidDatePicker() {
        this.showCalendar = !this.showCalendar;
        if (!this.showCalendar) {
            this.actionOnDatePickerClose();
        }
    }

    actionOnDatePickerClose() {
        this.refreshDateText();
        this.clearFocus();
        if (this.eventFromAndToDateStr) {
            this.setFocusOn('PARTICIPANT');
            this.progressbar = PROGRESS_BAR_MESSAGE.fifth_step;
            this.currentTab = this.currentTab < 2 ? 2 : this.currentTab;
            CommonFunctions.setSameHeight('create-event-details');
            this.headerText = HEADER_TEXT_CONTENT_MAP.fourth_step;
        }
    }

    refreshDateText() {
        let { TimezoneService } = this._injected;
        let _minDate = _.chain(this.eventFromAndToDates)
            .map((d) => moment(d).valueOf())
            .min();
        let _maxDate = _.chain(this.eventFromAndToDates)
            .map((d) => moment(d).valueOf())
            .max();
        let minDate = TimezoneService.fromLocalTime(_minDate).getTime();
        let maxDate = TimezoneService.fromLocalTime(_maxDate).getTime();
        if (this.isValidDate(minDate) && this.isValidDate(maxDate)) {
            this.eventFromAndToDateStr = this.getDateSpan(minDate, maxDate, true);
        }
    }

    isValidDate(value) {
        var dateWrapper = new Date(value);
        return !isNaN(dateWrapper.getDate());
    }

    findEventTemplateFromCategoryIds() {
        let categoryId = null;
        let params = {};
        if (this.eventCategory.isTemplate) {
            params.findByTemplateId = true;
            params.templateId = this.eventSubCategory.id;
        } else {
            if (this.eventSubCategory) {
                categoryId = this.eventSubCategory.id;
            } else if (this.eventCategory) {
                categoryId = this.eventCategory.id;
            }
            params.categoryId = categoryId;
        }

        const { Event, $scope } = this._injected;
        $scope.startProgress('create-event-public-modal');
        Event.findTemplate(
            params,
            (resp) => {
                this.selectedTemplate = resp.event;
                this.accommodations = resp.accommodations;
                this.numberOfEventDays = resp.numberOfEventDays;
                if (this.selectedTemplate && this.accommodations && this.accommodations.length < 1) {
                    this.selectedTemplate.escapeAccommodation = true;
                }
                if (this.selectedTemplate) {
                    this.selectedTemplate.participantAmount = null; //Participant amount should not be assigned
                    this.selectedTemplate.name = null; //Participant name should not be assigned
                }
                $scope.endProgress('create-event-public-modal');
            },
            $scope.endWithErrorOverlay('create-event-public-modal')
        );
        this.headerText = HEADER_TEXT_CONTENT_MAP.third_step;
    }

    participantAmountChanged() {
        if (this.enableAccommodationSlider()) {
            this.noOfOvernightGuests = this.selectedTemplate ? this.selectedTemplate.participantAmount : 0;
            this.setFocusOn('OVERNIGHT_GUEST');
        }
    }

    focusOnParticipant() {
        this.getFocusOn('PARTICIPANT');
    }

    checkLoggedAndCreateEvent() {
        const { $rootScope, Event, $scope } = this._injected;
        $rootScope.$emit('event:user:action', { action: 'create.event.express.booking' });
        if (!this.SecondStepForm.$valid) {
            CommonFunctions.touchFormFields(this.SecondStepForm);
            return;
        }
        this.selectedTemplate.fromDate = this._calculateEventFromDate();
        this.selectedTemplate.toDate = this._calculateEventToDate();
        this.selectedTemplate.singleBedRooms = this.noSingleBedRoom;
        this.selectedTemplate.doubleBedRooms = this.noDoubleBedRoom;
        this.selectedTemplate.expressBooking = true;
        this.selectedTemplate.eventCategoryName = this.eventCategory.name;
        this.selectedTemplate.eventCategoryNameCode = this.eventCategory.nameCode;
        this.selectedTemplate.eventSubCategoryName = this.eventSubCategory.name;
        this.selectedTemplate.eventSubCategoryNameCode = this.eventSubCategory.nameCode;
        if (this.selectedTemplate.id) {
            this.selectedTemplate.templateId = this.selectedTemplate.id;
        }
        this.selectedTemplate.parentGroupId = this.parentGroupId;
        $scope.startProgress('create-event-public-modal');
        Event.sendExpressBookingEmailAndCreateEvent(
            this.selectedTemplate,
            (response) => {
                this.progressbar = PROGRESS_BAR_MESSAGE.seventh_step;
                this.headerText = HEADER_TEXT_CONTENT_MAP.sixth_step;
                this.showCalendar = false;
                this.currentTab = 4;
                this.displayCreateEventForm = false;
                $scope.endProgress('create-event-public-modal');
                // this._close();
                // $timeout(() => {
                //   $state.go('shared.expressBookingSuccess', {eventId: response.id});
                // }, 1000);
            },
            $scope.endWithErrorOverlay('create-event-public-modal')
        );
    }

    _changeCurrentSupplierGroupToDefaultGroupAndCreateEvent() {
        const { AuthenticationService } = this._injected;
        let userGroups = AuthenticationService.getUserGroups();
        let switchToCreatorGroup = _.find(userGroups, (item) => {
            return !item.supplier;
        });
        if (switchToCreatorGroup && switchToCreatorGroup.id) {
            AuthenticationService.switchUserGroupPromise(switchToCreatorGroup.id).then(() => {
                this.createEventFromTemplate();
            });
        } else {
            console.log('Cound not get users creator group ');
        }
    }

    formValidateAndThenLoginAndCreateEvent() {
        if (!this.ThirdStepForm.$valid) {
            CommonFunctions.touchFormFields(this.ThirdStepForm);
            return;
        }
        this._logInAndCreateEvent(this.email, this.password);
    }

    _logInAndCreateEvent(username, password) {
        const { AuthenticationService, $rootScope, $stateParams } = this._injected;
        AuthenticationService.login(username, password).then((data) => {
            console.log(' logged in success ');
            if (data.success) {
                let lang = $stateParams.locale;
                if (lang) {
                    if (lang === 'en') {
                        $rootScope.switchLang('en_US');
                    } else if (lang === 'sv') {
                        $rootScope.switchLang('sv_SV');
                    }
                }
                if (AuthenticationService.isLoggedSupplier()) {
                    this._changeCurrentSupplierGroupToDefaultGroupAndCreateEvent();
                } else {
                    $rootScope.$emit('event:user:action', { action: 'user.loggedin', data: data });
                    this.createEventFromTemplate();
                }
            } else {
                this.loggInError = true;
                this.errorCode = data.errorCode;
            }
        });
    }

    createEventFromTemplate() {
        this.selectedTemplate.fromDate = this._calculateEventFromDate();
        this.selectedTemplate.toDate = this._calculateEventToDate();
        //Event.js resource create event from template if templateId
        //If it has id it will create event from template otherwise it will create new one event template
        if (this.selectedTemplate.id) {
            this.selectedTemplate.templateId = this.selectedTemplate.id;
        }
        this.selectedTemplate.singleBedRooms = this.noSingleBedRoom;
        this.selectedTemplate.doubleBedRooms = this.noDoubleBedRoom;
        this.selectedTemplate.eventCategoryName = this.eventCategory.name;
        this.selectedTemplate.eventSubCategoryName = this.eventSubCategory.name;
        this.selectedTemplate.expressBooking = true;

        let _displayAddress = angular.copy(this.selectedTemplate.eventAddress.displayAddress);
        if (_displayAddress) {
            window.googleMapsCallback(() => {
                var geocoder = new google.maps.Geocoder();
                geocoder.geocode({ address: _displayAddress }, (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        var place = results[0];
                        this.selectedTemplate.eventAddress = googleAddressUtil.parseGoogleAddress(place);
                        this.selectedTemplate.eventAddress.displayAddress = _displayAddress;
                    }
                    this.createEvent();
                });
            });
        } else {
            this.createEvent();
        }
    }

    createEvent() {
        const { Event, $timeout, SearchService, $scope, $state, EventGuideDataStore, $rootScope } = this._injected;

        $scope.startProgress('create-event-public-modal');
        Event.create(
            this.selectedTemplate,
            (response) => {
                this.progressbar = PROGRESS_BAR_MESSAGE.seventh_step;
                this.headerText = HEADER_TEXT_CONTENT_MAP.sixth_step;
                this.showCalendar = false;
                this.currentTab = 4;
                this.displayCreateEventForm = false;
                EventGuideDataStore.setShowOnBoardingGuide(false); //show onboarding guided
                EventGuideDataStore.setEventGuideNotification(false); //show event guide notification
                EventGuideDataStore.setShowEventProgressGuideTooltip(true); //show event progress guide tooltip
                $rootScope.$emit('event:user:action', { action: 'create.event', data: response });
                $scope.endProgress('create-event-public-modal');
                // $timeout(() => {
                //   $state.go('shared.expressBookingSuccess', { eventId: response.id });
                // }, 1000);
            },
            $scope.endWithErrorOverlay('create-event-public-modal')
        );
    }

    _calculateEventFromDate() {
        const { TimezoneService } = this._injected;
        let minDate = _.chain(this.eventFromAndToDates)
            .map((d) => moment(d).valueOf())
            .min()
            .value();
        return TimezoneService.fromLocalTime(minDate).getTime();
    }

    _calculateEventToDate() {
        const { TimezoneService } = this._injected;
        let maxDate = _.chain(this.eventFromAndToDates)
            .map((d) => moment(d).valueOf())
            .max()
            .value();
        return TimezoneService.fromLocalTime(maxDate).getTime();
    }

    enableAccommodationSlider() {
        if (!this.selectedTemplate) {
            return false;
        }
        if (!this.accommodations) {
            return false;
        }
        if (this.selectedTemplate.escapeAccommodation) {
            return false;
        }
        if (!this.selectedTemplate.requests) {
            return false;
        }
        if (this.selectedTemplate.requests.length < 1) {
            return false;
        }
        return true;
    }

    nextQuestion() {
        if (!this.FirstStepForm.$valid) {
            CommonFunctions.touchFormFields(this.FirstStepForm);
            return;
        } else {
            this.progressbar = PROGRESS_BAR_MESSAGE.sixth_step;
            this.headerText = HEADER_TEXT_CONTENT_MAP.fifth_step;
            this.showCalendar = false;
            this.currentTab = 3;
            CommonFunctions.setSameHeight('create-event-details');
            const { $timeout } = this._injected;
            $timeout(
                function () {
                    this.locationDropdown = true;
                }.bind(this),
                500
            );
        }
    }

    registerAndCreateEvent() {
        if (!this.RegistrationForm.$valid) {
            CommonFunctions.touchFormFields(this.RegistrationForm);
            return;
        }
        if (this.passwordsNotMatch() || !this.user.email) {
            return;
        }
        const { $rootScope, $scope, AuthRegister } = this._injected;
        let data = this.user;
        data.username = data.email;
        $scope.startProgress('create-event-public-modal');
        AuthRegister.registerUser(
            data,
            (data) => {
                console.log(' register success ');
                this._logInAndCreateEvent(data.user.email, this.user.password);
                $rootScope.$emit('event:user:action', { action: 'user.registered', data: data });
                $scope.endProgress('create-event-public-modal');
            },
            (err) => {
                this.errorCode = CommonFunctions.errorMessages(err);
                this.errorOnRegistration = true;
                //Error increases height hence this logic called again
                CommonFunctions.setSameHeight('auth_equal_height');
                $scope.endProgress('create-event-public-modal');
                console.log(err, 'Error ');
            }
        );
    }

    loginWithExternalServiceAndCreateEvent(service) {
        let eventData = {
            templateId: this.selectedTemplate.id,
            participantAmount: this.selectedTemplate.participantAmount,
            doubleBedRooms: this.noDoubleBedRoom,
            singleBedRooms: this.noSingleBedRoom,
            escapeAccommodation: this.selectedTemplate.escapeAccommodation,
            name: this.selectedTemplate.name,
            fromDate: this._calculateEventFromDate(),
            toDate: this._calculateEventToDate(),
        };
        eventData.smartAccommodationCreation = true;
        const url = 'oauth/' + service + '/authenticate?state=' + JSON.stringify(eventData);
        window.location.href = url;
    }

    validatePassword() {
        if (this.user) {
            if (!this.user.password) {
                return true;
            }
            let regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}$)');
            return regex.test(this.user.password);
        }
    }

    passwordsNotMatch() {
        if (this.user && (this.user.password || this.user.password2) && this.user.password != this.user.password2) {
            //Error increases height hence this logic called again
            // CommonFunctions.setSameHeight('auth_equal_height');
            return true;
        }
    }

    showPasswordStrength(password) {
        if (password) {
            //Uppercase Words only
            if (password.match(/[A-Z]/) != null) {
                jQuery('#uppercase-text .progress-text').addClass('active');
            } else {
                jQuery('#uppercase-text .progress-text').removeClass('active');
            }
            //Lowercase words only
            if (password.match(/[a-z]/) != null) {
                jQuery('#lowercase-text .progress-text').addClass('active');
            } else {
                jQuery('#lowercase-text .progress-text').removeClass('active');
            }
            //Digits only
            if (password.match(/[0-9]/) != null) {
                jQuery('#number-in-text .progress-text').addClass('active');
            } else {
                jQuery('#number-in-text .progress-text').removeClass('active');
            }
            //Password length
            if (password.length > 7) {
                jQuery('#eight-characters-long .progress-text').addClass('active');
            } else {
                jQuery('#eight-characters-long .progress-text').removeClass('active');
            }
        } else {
            jQuery('.progress-bar-wrap .progress-bar-item .progress-text').each(function () {
                jQuery(this).removeClass('active');
            });
        }
    }

    // fetch the suggested password and set to scope
    getGeneratedPassword() {
        const { $scope, CustomerUser } = this._injected;
        CustomerUser.getPassword(
            function (result) {
                $scope.generatedPassword = result.generatedPassword;
            },
            function (error) {
                console.log('>>> Error has occurred while getting generated password from API ' + error);
            }
        );
    }

    //fills the password with the suggested password
    fillPasswordFields() {
        const { $scope } = this._injected;
        vm.data.password = $scope.generatedPassword;
        vm.data.password2 = $scope.generatedPassword;
    }

    closeAndRedirect() {
        const { $timeout, $rootScope, $state, $stateParams } = this._injected;
        this._close();
        let currentState = $state.current.name;
        let redirectUrl = $stateParams.redirectUrl;
        if (!redirectUrl) {
            redirectUrl = $rootScope.publicURL + 'expressbokning';
        }
        if (currentState === 'shared.eventCreateExpressBooking') {
            $timeout(() => {
                window.location.href = redirectUrl;
            }, 1000);
        }
    }

    _close() {
        const { close, $element } = this._injected;
        $element.modal('hide');
        close(null, 500);
    }

    fetchCompanyName(userEmail) {
        const { CustomerUser } = this._injected;
        const regExp = /\S+@\S+\.\S+/;
        if (regExp.test(userEmail)) {
            CustomerUser.fetchCompanyName(
                { userEmail: userEmail },
                function (result) {
                    this.parentUserGroups = result.userGroups;
                    if (result.userGroups.length === 0) {
                        this.parentGroupId = null;
                    }
                    if (result.userGroups.length === 1) {
                        this.parentGroupId = result.userGroups[0].id;
                    }
                }.bind(this),
                function (error) {
                    console.log('error while fetching company names with associated domain = ', error);
                }
            );
        } else {
            this.parentGroupId = null;
            this.parentUserGroups = null;
        }
    }
    handlePaste() {
        setTimeout(() => {
            this.fetchCompanyName(this.selectedTemplate.email);
        }, 200);
    }
    handleCut() {
        this.parentGroupId = null;
        this.parentUserGroups = null;
    }
}
createEventPublicMoCtrl.$inject = [
    '$scope',
    '$element',
    '$stateParams',
    'close',
    'Category',
    'Event',
    'RequestOfferPart',
    '$location',
    '$timeout',
    'TimezoneService',
    'SearchService',
    '$state',
    'EventGuideDataStore',
    'CustomerUser',
    '$rootScope',
    'DateService',
    'AuthenticationService',
    'ClientService',
    'EventEnum',
    'AuthRegister',
    '$translate',
    'CommonFile',
];
export default createEventPublicMoCtrl;
