'use strict';
angular.module('core.supplierCategory').factory('SupplierCategory', [
    '$resource',
    function ($resource) {
        var v1SupplierCategoryRest = $resource(
            'api/v1/supplier/category/supplier/:supplierId/category/:categoryId',
            {supplierId: '@supplierId', categoryId: '@categoryId'},
            {
                get: {method: 'GET', isArray: false},
            }
        );

        return {
            get: function (jsonObject, successCallback, errorCallback) {
                return v1SupplierCategoryRest.get(jsonObject, successCallback, errorCallback);
            },
        };
    },
]);
