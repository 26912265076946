'use strict';

import { ScopeFunctions } from '../../../../../utils/global/scopeFunctions';

// Register `messages` component, along with its associated controller and template
angular.
    module('messages').
    component('messages', {
        templateUrl: './app/components/modules/event/events/messages/messages.template.html',
        controllerAs: 'messagesCtrl',
        controller: [
            '$scope', 'ActiveTabService',
            function MessagesController(
                $scope, ActiveTabService
            ) {
                var vm = this;
                ScopeFunctions.addToScope($scope);
                ActiveTabService.setEventsTab('MESSAGES');
                console.log(ActiveTabService, "Message Component");
            }
        ]
    });
