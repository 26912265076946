/**
 * Created by kshitij on 7/28/17.
 */

import controller from './og_accommodation.controller';

(function () {
    'use strict';
    angular.module('accommodation').directive('ogAccommodation', [
        function () {
            return {
                restrict: 'EA',
                replace: true,
                scope: {
                    eventId: '=',
                    index: '=',
                    type: '=',
                    accommodationDay: '=',
                    item: '=',
                    accommodation: '=',
                    customPart: '=',
                    changeForAll: '=',
                    beforeAfterEventAccommodationCallback: '&',
                    hasActionRequiredPart: '=',
                    cancelChangeForAllDaysCallback: '&',
                    synchronizeSingleRoomsCallback: '&',
                    synchronizeDoubleRoomsCallback: '&',
                    synchronizeSuiteRoomsCallback: '&',
                    synchronizeTripleRoomsCallback: '&',
                    synchronizeQuadRoomsCallback: '&',
                    synchronizeOtherRoomsCallback: '&',
                    loadOnSuccessCallback: '&',
                    fieldChanged: '=',
                    actionRequiredCallback: '&',
                    deleteCallback: '&',
                    updateRoomNameCallback: '&',
                },
                templateUrl: './app/components/directives/accommodation/og_accommodation.view.html',
                controller,
                controllerAs: 'AccommodationCtrl',
                bindToController: true,
            };
        },
    ]);
})();
